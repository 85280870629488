import { Fragment, useEffect } from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { useState } from "react";

// components
import SelectBox from "@/components/atoms/selectBox/index";
import { ReactComponent as RefreshIcon } from "@/assets/icons/refresh.svg";
import TextInput from "@/components/atoms/input/TextInput";
import Button from "@/components/atoms/button";
import SearchInput from "@/components/molecules/basedata-manage/SearchInput";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";

// types
import type { SearchSeriesResult, BaseDataSeries } from "@/types/searchSeries";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { excelUploadActions, getExcelUploadData } from "@/pages/settlement/basedata-manage/excelUploadManagement/exceluploadSlice";
import { resetDataTable } from "@/pages/common/dataTableSlice";

// hooks
import useAlert from "@/modules/hooks/useAlert";
import usePlatformSetting from "@/components/molecules/sales-entry/common/hooks/usePlatformSetting";
import useLoading from "@/modules/hooks/useLoading";


function SearchFilterContainer() {
    const [platformId, contentName, seriesName, tooltipSeriesName, series_id_list, platformList, updatedRows] = useSelectorTyped((state: RootState) => [
        state.excelupload.platformId,
        state.excelupload.contentName,
        state.excelupload.seriesName,
        state.excelupload.tooltipSeriesName,
        state.excelupload.series_id_list,
        state.platform.platformList,
        state.dataTable.updatedRows,
    ]);
    const { loadingOn, loadingOff } = useLoading();
    const { initialPlatformId } = usePlatformSetting({ platformId: 0 });
    const [show, setShow] = useState(false);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    useEffect(() => {

    }, [tooltipSeriesName])

    const initFilterHandler = () => {
        dispatch(excelUploadActions.reset());
        dispatch({type: "excelUpload/setPlatformId", payload: initialPlatformId });
    };

    function onChangePlatform(value:any) {
        dispatch({type: "excelUpload/setPlatformId", payload: value.platform_id })
    }

    function onChangeContentName(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch({type: "excelUpload/setContentName", payload: e.target.value})
    }

    async function searchValidate() {
        if (!platformId) {
            return alert("warning", "주의", "판매플랫폼을 선택해주세요.");
        } else if (updatedRows.length) {
            let answer = await alert("confirm", "주의", "저장되지 않은 변경사항이 있습니다. 계속 하시겠습니까?");
            if (answer === "Y") {
                dispatch(resetDataTable());
                await find();
            }
        } else {
            find();       
        }
    }

    async function find() {
        try {
            let platform_name = (platformList.find(p => p.platform_id === Number(platformId)))?.platform_name;
            if (!platform_name) {
                return await alert("warning", "플랫폼 미설정", "판매플랫폼이 선택되지 않았습니다.");
            }

            loadingOn();
            let apiParams = {
                platform_id: platformId,
                platform_name: platform_name,
                name: contentName || null,
                series_id_list: series_id_list.length ? series_id_list : null,
            }
            await dispatch(getExcelUploadData(apiParams)).unwrap();
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }

    const keyPressHandler = (e: any) => {
        if (e.keyCode === 229) return;
        if (e.key === "Enter") {
            find();
        }
    };

    function seriesSearchModal() {
        setShow(true);
    }

    function closeModal() {
        setShow(false);
    }

    // 시리즈 모달에서 시리즈 선택
    const applySeries = (data: SearchSeriesResult) => {
        const selectedSeries = data.selectedSeries as BaseDataSeries[];

        dispatch(excelUploadActions.setExcelUploadDatas({
            key: "series_id_list", value: selectedSeries.map((series:any) => series.id)
        }))
        if (selectedSeries.length > 1) {
            dispatch(excelUploadActions.setExcelUploadDatas({
                key: "seriesName", value: selectedSeries[0].series_name + ` (외 ${selectedSeries.length-1}건)`
            }));
            dispatch(excelUploadActions.setTooltipSeriesName(selectedSeries.map((s:any) => s.series_name).join("\n")));
        } else {
            dispatch(excelUploadActions.setExcelUploadDatas({
                key: "seriesName", value: selectedSeries[0].series_name
            }));
            dispatch(excelUploadActions.setTooltipSeriesName(selectedSeries[0].series_name));
        }
    }

    return (
        <>
            <SearchFilterWrapper>
                <RowWrapper
                    label="판매플랫폼"
                    filterContent={[
                        <SelectBox
                            labelKey="platform_name"
                            valueKey="platform_id"
                            selected={platformId || initialPlatformId}
                            options={platformList}
                            onClickValueFunc={onChangePlatform}
                            customStyle={{ height: "32px" }}
                        />
                    ]}
                />
                <RowWrapper
                    label="시리즈명"
                    filterContent={[
                        <SearchInput
                            type="readOnlyTooltip"
                            value={seriesName || ""}
                            placeholder="시리즈명"
                            onClickHandler={seriesSearchModal}
                            customStyle={{ width: "200px", fontSize: "12px", fontWeight: "400", padding: "10px 25px 10px 10px" }}
                            tooltipText={tooltipSeriesName}
                        />,
                        <InitFilter onClickHandler={initFilterHandler} />
                    ]}
                    contentStyle={{ justifyContent: "space-between"}}
                />
                <RowWrapper
                    label="작품명"
                    filterContent={[
                        <TextInput
                            value={contentName}
                            placeholder="작품명"
                            onChangeHandler={onChangeContentName}
                            onKeyDownHandler={keyPressHandler}
                            customStyle={{ width: "200px", height: "32px", fontSize: "12px", fontWeight: "400", color: colors.gray600 }}
                        />,
                        <Button label="검색" customStyle={{ margin: "0 0 0 8px"}} onClickHandler={searchValidate}/>
                    ]}
                />
            </SearchFilterWrapper>
            <ModalPortal>
                <SearchSeriesModal
                    from="basedata"
                    show={show}
                    close={closeModal}
                    platformId={platformId}
                    withRatio={false}
                    multiSelect
                    applySeries={applySeries}
                />
            </ModalPortal>
        </>
    );
}

export default SearchFilterContainer;

function RowWrapper({ label, filterContent, contentStyle }: IRowWrapperProps) {
    return (
        <StyledRowWrapper>
            <Label>{label}</Label>
            <Content justifyContent={contentStyle?.justifyContent}>
                {filterContent.map((content, idx) => (
                    <Fragment key={idx}>
                        {content}
                    </Fragment>
                ))}
            </Content>
        </StyledRowWrapper>
    );
}

function InitFilter({ onClickHandler }: { onClickHandler: () => void }) {
    return (
        <StyledWrapper onClick={onClickHandler}>
            <StyledText>필터 초기화</StyledText>
            <RefreshIcon fill={colors.gray600}/>
        </StyledWrapper>
    );
}

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 32px;
    padding: 9px 8px;
    cursor: pointer;
`;

const StyledText = styled.div`
    font-size: 14px;
    color: ${colors.gray600};
`;

const SearchFilterWrapper = styled.div`
    width: 1200px;
    border-radius: 4px;
    margin-top: 24px;
`;

const StyledRowWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 60px;
    height: auto;
    position: relative;
`;
const Label = styled.div`
    height: 100%;
    position: absolute;

    display: flex;
    align-items: center;
    width: 200px;
    height: 100%;
    padding: 8px;
    background-color: ${colors.gray50};
    font-weight: 500;
    font-size: 14px;
`;

const Content = styled.div<ContentStyle>`
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-first"};
    height: 100%;

    left: 200px;
    position: relative;
    width: 1000px;
`;

interface IRowWrapperProps {
    label: string | JSX.Element;
    filterContent: JSX.Element[];
    contentStyle?: ContentStyle;
}

interface ContentStyle {
    justifyContent?: string;
}