import styled from "styled-components";
import Button from "@/components/atoms/button";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import UploadStampModal from "./UploadStampModal";
import AddBriefModal from "./AddBriefModal";
import { useState } from "react";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { issueMonthlyStatement, issueMgStatement } from "@/pages/settlement/statement/publisher/slice";
import { SpecificationType } from "@/pages/settlement/statement/publisher/types";

export default function ButtonContainer() {
    const { currAuthor, stampFiles, specificationType } = useAppSelector((state: RootState) => state.pubStatement);
    const [stampUploadModal, setStampUploadModal] = useState(false);
    const [addBriefModal, setAddBriefModal] = useState(false);
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const dispatch = useAppDispatch();

    const showStampUploadModal = () => {
        if (stampFiles.length) {
            return alert("warning", "주의", "하나의 인감파일만 업로드할수 있습니다.");
        } else {
            setStampUploadModal(true);
        }
    }

    const issue = async () => {
        try {
            const answer = await alert("confirm", "정산서 발급", "선택하신 작가에게 정산서가 (재)발급됩니다. 원하지 않으시면 '아니오'를 눌러주세요.");
            if (answer === "Y") {
                await loadingOn();
                if (specificationType === SpecificationType.Monthly) {
                    await dispatch(issueMonthlyStatement()).unwrap();
                } else {
                    await dispatch(issueMgStatement()).unwrap();
                }
                alert("info", "발급 완료", "모든 정산서가 발급되었습니다.");
            }
        } catch (err) {
            alert("error", "오류 발생", "정산서 발급중 오류가 발생하였습니다.");
        } finally {
            await loadingOff();
        }
    }

    return (
        <StyledContainer>
            <Button 
                type={currAuthor.user_id ? "main-outline" : "disabled"}
                label="인감 업로드"
                onClickHandler={showStampUploadModal}
                customStyle={{ margin: "0 8px 0 0"}}
            />
            <Button 
                type={currAuthor.user_id ? "main-outline" : "disabled"}
                label="적요 추가"
                onClickHandler={() => setAddBriefModal(true)}
                customStyle={{ margin: "0 8px 0 0"}}
            />
            <Button 
                type={currAuthor.user_id ? "main" : "disabled"}
                label="정산서 발급"
                onClickHandler={issue}
            />
            <ModalPortal>
                <UploadStampModal
                    from="statement-issue"
                    show={stampUploadModal}
                    close={() => setStampUploadModal(false)}
                />
            </ModalPortal>
            <ModalPortal>
                <AddBriefModal
                    from="statement-issue"
                    show={addBriefModal}
                    close={() => setAddBriefModal(false)}
                />
            </ModalPortal>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    justify-content: right;
    width: 100%;
    height: 32px;
`