import styled from "styled-components";
import DaumPostcode from "react-daum-postcode";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as ClearMark } from "@/assets/icons/clear.svg";

export default function Post({onCompletePost, onClose}:{ onCompletePost: Function, onClose: Function}) {
    const onComplete = (data:any) => {
        onCompletePost(data);
        onClose();
    };

    return (
        <PostContainer>
            <TopWrap>
                <TitleWrap>주소 찾기</TitleWrap>
                <CloseWrap onClick={() => onClose()}>
                    <ClearMark width="24px" height="24px" fill={colors.gray600} />
                </CloseWrap>
            </TopWrap>
            <DaumPostcode
                className="post"
                style={{
                    height: "466px",  
                }}
                autoClose
                onComplete={onComplete}
            />
        </PostContainer>
    )
}

const PostContainer = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    z-index: 999;
    width: 480px;
    height: 506px;
`

const TopWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background: white;
`

const CloseWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
    cursor: pointer;
`

const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 420px;
    padding-left: 20px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 700;
    cursor: default;
`