import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { useEffect, useRef } from "react";

export default function Privacy() {
    const titleText = ['1. 개인정보의 처리 목적', '2. 개인정보 파일 현황', '3. 개인정보의 처리 및 보유 기간', '4. 개인정보처리 위탁', '5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법',
                        '6. 처리하는 개인정보의 항목 작성', '7. 개인정보의 파기','8. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항', '9. 개인정보 보호책임자 작성', '10. 개인정보 처리방침 변경',
                        '11. 개인정보의 안정성 확보 조치', '12. 개인정보 열람청구', '13. 정보주체의 권익침해에 대한 구체 방법'];

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    const contentTab = useRef<HTMLDivElement[]>([]);

    const NavbarTitleStyle = {
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "21px",
        color: colors.gray800,
    }
    return(
        <PrivacyStyled>
            <Title>개인정보처리방침</Title>
            <NavBar>
                <Text text="<GLING>('gling.co.kr'이하 '글링')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다." customStyle={NavbarTitleStyle} />
                <NavBarContent>
                    {titleText.map((text, idx) => 
                        <NavBarText key={idx} onClick={() => { contentTab.current[idx].scrollIntoView()}}>{text}</NavBarText>
                    )}
                </NavBarContent>
                <Text text="<GLING>('글링') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다." customStyle={NavbarTitleStyle}></Text>
                <Text text="○ 본 방침은부터 2020년 1월 1일부터 시행됩니다." customStyle={NavbarTitleStyle}></Text>
            </NavBar>
            <PrivacyContent>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[0] = el as HTMLDivElement}}>{titleText[0]}</SubTitle>
                <UlStyled>
                    <LiStyled>&lt;GLING&gt;('gling.co.kr'이하 '글링')은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.</LiStyled>
                    <LiStyled>가. 홈페이지 회원가입 및 관리</LiStyled>
                    <LiStyled>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지,</LiStyled>
                    <LiStyled>만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.</LiStyled>
                    <LiStyled>나. 재화 또는 서비스 제공</LiStyled>
                    <LiStyled>서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증 등을 목적으로 개인정보를 처리합니다.</LiStyled>
                    <LiStyled>다. 마케팅 및 광고에의 활용</LiStyled>
                    <LiStyled>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악</LiStyled>
                    <LiStyled>또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</LiStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[1] = el as HTMLDivElement}}>{titleText[1]}</SubTitle>
                <UlStyled>
                    <SubContentStyled>
                        <SubContentTitle>1.개인정보 파일명 : 개인정보처리방침</SubContentTitle>
                        <SubContent>
                            <SubContentName>개인정보 항목</SubContentName>
                            <SubContentDetail>이메일, 휴대전화번호, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록</SubContentDetail>
                        </SubContent>
                        <SubContent>
                            <SubContentName>수집방법</SubContentName>
                            <SubContentDetail>홈페이지</SubContentDetail>
                        </SubContent>
                        <SubContent>
                            <SubContentName>보유근거</SubContentName>
                            <SubContentDetail>개인정보보호법제15조 (정보주체의 동의)</SubContentDetail>
                        </SubContent>
                        <SubContent>
                            <SubContentName>보유기간</SubContentName>
                            <SubContentDetail>5년</SubContentDetail>
                        </SubContent>
                    </SubContentStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[2] = el as HTMLDivElement}}>{titleText[2]}</SubTitle>
                <UlStyled>
                    <LiStyled>① &lt;GLING&gt;('글링')은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.</LiStyled>
                    <LiStyled>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</LiStyled>
                    <OlStyled>
                        <LiStyled>1.&lt;홈페이지 회원가입 및 관리&gt;</LiStyled>
                        <LiStyled>&lt;홈페이지 회원가입 및 관리&gt;와 관련한 개인정보는 수집.이용에 관한 동의일로부터 &lt;5년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</LiStyled>
                        <LiStyled>보유근거 : 개인정보보호법제15조 (정보주체의 동의)</LiStyled>
                        <LiStyled>2.&lt;제화 또는 서비스 제공&gt;</LiStyled>
                        <LiStyled>&lt;제화 또는 서비스 제공&gt;와 관련한 개인정보는 수집.이용에 관한 동의일로부터 &lt;5년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</LiStyled>
                        <LiStyled>보유근거 : 개인정보보호법제15조 (정보주체의 동의)</LiStyled>
                        <LiStyled>3.&lt;마케팅 및 광고에의 활용&gt;</LiStyled>
                        <LiStyled>&lt;마케팅 및 광고에의 활용&gt;와 관련한 개인정보는 수집.이용에 관한 동의일로부터 &lt;5년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</LiStyled>
                        <LiStyled>보유근거 : 개인정보보호법제15조 (정보주체의 동의)</LiStyled>
                    </OlStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[3] = el as HTMLDivElement}}>{titleText[3]}</SubTitle>
                <UlStyled>
                    <LiStyled>① &lt;GLING&gt;('글링')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</LiStyled>
                    <OlStyled>
                        <SubContentTitle>&lt;본인인증&gt;</SubContentTitle>
                        <SubContent>
                            <SubContentName>위탁받는 자 (수탁자) </SubContentName>
                            <SubContentDetail>(주)엔에치엔KCP</SubContentDetail>
                        </SubContent>
                        <SubContent>
                            <SubContentName>위탁하는 업무의 내용</SubContentName>
                            <SubContentDetail>회원제 서비스 이용에 따른 본인확인</SubContentDetail>
                        </SubContent>
                        <SubContent>
                            <SubContentName>위탁기간</SubContentName>
                            <SubContentDetail>지체없이 파기</SubContentDetail>
                        </SubContent>
                    </OlStyled>
                    <LiStyled>② &lt;GLING&gt;('gling.co.kr'이하 '글링')은(는) 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등</LiStyled>
                    <LiStyled>책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</LiStyled>
                    <LiStyled>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</LiStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[4] = el as HTMLDivElement}}>{titleText[4]}</SubTitle>
                <UlStyled>
                    <LiStyled>이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</LiStyled>
                    <OlStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;① 정보주체는 &lt;GLING&gt;에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;② 제1항에 따른 권리 행사는&lt;GLING&gt;에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 &lt;GLING&gt;은(는) 이에 대해 지체 없이 조치하겠습니다.</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;⑥ &lt;GLING&gt;은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</LiStyled>
                    </OlStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[5] = el as HTMLDivElement}}>{titleText[5]}</SubTitle>
                <UlStyled>
                    <LiStyled>① &lt;GLING&gt;('gling.co.kr'이하 '글링')은(는) 다음의 개인정보 항목을 처리하고 있습니다.</LiStyled>
                    <OlStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. &lt;홈페이지 회원가입 및 관리&gt;</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보</LiStyled>
                    </OlStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[6] = el as HTMLDivElement}}>{titleText[6]}</SubTitle>
                <UlStyled>
                    <LiStyled>&lt;GLING&gt;('글링')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</LiStyled>
                    <OlStyled>
                        <LiStyled>-파기절차</LiStyled>
                        <LiStyled>이용자가 입력한 정보는 목적 달성 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. </LiStyled>
                        <LiStyled>이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</LiStyled>
                        <LiStyled>-파기기한</LiStyled>
                        <LiStyled>이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등</LiStyled>
                        <LiStyled> 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.</LiStyled>
                        <LiStyled>-파기방법</LiStyled>
                        <LiStyled>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</LiStyled>
                    </OlStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[7] = el as HTMLDivElement}}>{titleText[7]}</SubTitle>
                <UlStyled>
                    <LiStyled>① &lt;GLING&gt; 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다.</LiStyled>
                    <LiStyled>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. </LiStyled>
                    <OlStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. </LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. </LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</LiStyled>
                    </OlStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[8] = el as HTMLDivElement}}>{titleText[8]}</SubTitle>
                <UlStyled>
                    <LiStyled>① &lt;GLING&gt;('gling.co.kr'이하 '글링') 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</LiStyled>
                    <OlStyled display={"flex"}>
                        <InfoCard width={186} height={116} margin={"0px 22px 0px 0px"}>
                            <LiStyled>▶ 개인정보 보호책임자</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;성명 : 김학성</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;직책 : 대표이사</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;연락처 : help@gling.co.kr</LiStyled>
                        </InfoCard>
                        <InfoCard width={186} height={116} margin={"0px 0px 0px 22px"}>
                            <LiStyled>▶ 개인정보 보호 담당부서</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;부서명 : SW개발팀</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;담당자 : 고태훈</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;연락처 : help@gling.co.kr</LiStyled>
                        </InfoCard>
                    </OlStyled>
                    <LiStyled>② 정보주체께서는 &lt;GLING&gt;('gling.co.kr'이하 '글링') 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. </LiStyled>
                    <LiStyled>&lt;GLING&gt;('gling.co.kr'이하 '글링') 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</LiStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[9] = el as HTMLDivElement}}>{titleText[9]}</SubTitle>
                <UlStyled>
                    <LiStyled>이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.</LiStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[10] = el as HTMLDivElement}}>{titleText[10]}</SubTitle>
                <UlStyled>
                    <LiStyled>&lt;GLING&gt;('글링')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</LiStyled>
                    <OlStyled>
                        <LiStyled color={colors.gray800}>1. 정기적인 자체 검사 실시</LiStyled>
                        <LiStyled>개인정보 취급 관련 안정성 확보를 위해 정기적(연 1회)으로 자체 감사를 실시하고 있습니다.</LiStyled>
                        <LiStyled color={colors.gray800}>2. 개인정보 취급 직원의 최소화 및 교육</LiStyled>
                        <LiStyled>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</LiStyled>
                        <LiStyled color={colors.gray800}>3. 내부관리계획의 수립 및 시행</LiStyled>
                        <LiStyled>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</LiStyled>
                        <LiStyled color={colors.gray800}>4. 해킹 등에 대비한 기술적 대책</LiStyled>
                        <LiStyled>&lt;GLING&gt;('글링')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</LiStyled>
                        <LiStyled color={colors.gray800}>5. 개인정보의 암호화</LiStyled>
                        <LiStyled>이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</LiStyled>
                        <LiStyled color={colors.gray800}>6. 접속기록의 보관 및 위변조 방지</LiStyled>
                        <LiStyled>개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.</LiStyled>
                        <LiStyled color={colors.gray800}>7. 개인정보에 대한 접근 제한</LiStyled>
                        <LiStyled>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</LiStyled>
                    </OlStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[11] = el as HTMLDivElement}}>{titleText[11]}</SubTitle>
                <UlStyled>
                    <LiStyled>① 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. &lt;GLING&gt;(‘gling.co.kr'이하 '글링')은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</LiStyled>
                    <OlStyled>
                        <InfoCard width={221} height={116}>
                            <LiStyled>▶ 개인정보 열람청구 접수·처리 부서</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;부서명 : SW개발팀</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;담당자 : 고태훈</LiStyled>
                            <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;연락처 : help@gling.co.kr</LiStyled>
                        </InfoCard>
                    </OlStyled>
                    <LiStyled>② 정보주체께서는 제1항의 열람청구 접수․처리부서 이외에, 개인정보보호위원회의 ‘개인정보보호 종합지원 포털’ 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람청구를 하실 수 있습니다.</LiStyled>
                    <OlStyled>
                        <InfoCard width={732} height={37}>
                            <LiStyled>▶ 개인정보보호위원회 개인정보보호 종합지원 포털 → 개인정보 민원 → 개인정보 열람등 요구 (본인확인을 위하여 아이핀(I-PIN)이 있어야 함)</LiStyled>
                        </InfoCard>
                    </OlStyled>
                </UlStyled>
                <SubTitle ref={(el) => {(contentTab.current as HTMLDivElement[])[12] = el as HTMLDivElement}}>{titleText[12]}</SubTitle>
                <UlStyled>
                    <LiStyled>아래의 기관은 글링과는 별개의 기관으로서, &lt;GLING&gt;(‘gling.co.kr'이하 '글링')의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.</LiStyled>
                    <OlStyled>
                        <LiStyled>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소관업무 : 개인정보 침해사실 신고, 상담 신청</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;홈페이지 : privacy.kisa.or.kr</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;전화 : (국번없이) 118</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터</LiStyled>
                    </OlStyled>
                    <OlStyled>
                        <LiStyled>▶ 개인정보 분쟁조정위원회</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;홈페이지 : <a href="www.kopico.go.kr">www.kopico.go.kr</a></LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;전화 : (국번없이) 1833-6972</LiStyled>
                        <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층</LiStyled>
                    </OlStyled>
                    <OlStyled>
                        <LiStyled>▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (<a href="www.spo.go.kr">www.spo.go.kr</a>)</LiStyled>
                    </OlStyled>
                    <OlStyled>
                        <LiStyled>▶ 경찰청 사이버안전국 : 182 (<a href="http://cyberbureau.police.go.kr">http://cyberbureau.police.go.kr</a>)</LiStyled>
                    </OlStyled>
                </UlStyled>
            </PrivacyContent>
        </PrivacyStyled>
    )
}

const PrivacyStyled = styled.div`
    padding: 30px calc((100% - 1200px) / 2);
`

const Title = styled.h3`
    font-weight: 700;
    line-height: 35px;
`

const NavBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 16px;
    margin: 24px 0px;
    background-color: ${colors.gray50}
`

const NavBarContent = styled.div`
    display: flex;
    height: 137px;
    align-items: flex-start;
    margin: 16px 0px;
    flex-direction: column;
    flex-wrap: wrap
`

const NavBarText = styled.div`
    margin: 3px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${colors.black};
    &:hover {
        color: ${colors.blue500};
        text-decoration: underline;
        cursor: pointer;
    }
`

const PrivacyContent = styled.div`
`

const SubTitle = styled.div`
    font-weight: 700;
    line-height: 20px;
    margin: 16px 0px;
    scroll-margin: 30px;
`

const UlStyled = styled.ul`
`

const LiStyled = styled.li<StyleProps>`
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${(props) => props.color ? props.color : colors.gray600};
    a {
        color: ${(props) => props.color ? props.color : colors.gray600};
    }
`

const OlStyled = styled.ol<StyleProps>`
    margin: 16px 0px;
    display: ${(props) => props.display}
`

const SubContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
`

const SubContentTitle = styled.div`
    padding: 8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    color: ${colors.gray800};
    width: 900px;
    background-color: ${colors.gray50}
`

const SubContent = styled.div`
    display: flex;
    align-items: center;
`

const SubContentName = styled.div`
    width: 200px;
    height: 28px;
    padding: 8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    color: ${colors.gray800}
`

const SubContentDetail = styled.div`
    font-size: 12px;    
    line-height: 12px;
    font-weight: 500;
    color: ${colors.gray600}
`

const InfoCard = styled.div<StyleProps>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    margin: ${(props) => props.margin};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    background-color: ${colors.gray50};
`

interface StyleProps {
    width?: number;
    height?: number;
    margin?: string;
    display?: string;
}