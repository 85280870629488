// export type id = number;
// export type name = string;
// export type amount = number;
export namespace CommonType {
    // 주소
    export type Address = string;
    // 금액
    export type Amount = number;
    // count 가능한 숫자
    export type Count = number;
    // 날짜, 8자리(20220101)
    export type Date = string;
    // 일시
    export type Datetime = string;
    // 이메일
    export type Email = string;
    // 파일경로
    export type FilePath = string;
    // 고유 ID(publisher_id, series_id, author_id, ...)
    export type Id = number;
    // comma로 구분되는 id 리스트
    export type IdList = string;
    // IP 주소
    export type IpAddr = string;
    // ISBN
    export type Isbn = string;
    // True, False 플래그
    export type IsFlag = boolean;
    // 사람이름
    export type PersonName = string;
    // 전화번호
    export type Phone = string;
    // 수량
    export type Quantity = number;
    // 비율
    export type Ratio = number;
    // 토큰값
    export type Token = string;
    // URL 주소
    export type Url = string;
    // 년월
    export type Yearmon = string;

    // 권한 관련
    export namespace Auth {
        // CRUD 권한
        export type CRUDAuth = string;
        // 권한 여부
        export type HasAuth = boolean;
    }

    // 작가 관련
    export namespace Author {
        // 작가 이름
        export type Name = string;
    }

    // 문의하기, 공지 등 게시판 관련
    export namespace Board {
        // 카테고리
        export type Category = string;
        // 본문
        export type Content = string;
        // 제목
        export type Title = string;
    }    

    // 기타지급금 관련
    export namespace EtcSales {
        // 지급사유
        export type Reason = string;
    }

    // 엑셀 업로드 관련
    export namespace ExcelFields {
        // 순서 관련 변수
        export type Count = number;
        // 필드명(10)
        export type Field10 = string;
        // 필드명(50)
        export type Field50 = string;
        // 필드명(120)
        export type Field120 = string;
        // 파일 확장자
        export type FileType = string;
        // 일별/월별 구분
        export const PeriodType = {
            DAILY: "Daily",
            MONTHLY: "Monthly"
        } as const;
        export type PeriodType = typeof PeriodType[keyof typeof PeriodType];
    }

    // 로그 관련
    export namespace Log {
        // 행동 구분자
        export const Activity = {
            VIEW_CNT_UP: "VU",
            VIEW_CNT_DOWN: "VD",
            LIKE_CNT_UP: "LU",
            LIKE_CNT_DOWN: "LD",
            DISLIKE_CNT_UP: "DU",
            DISLIKE_CNT_DOWN: "DD",
        } as const;
        export type Activity = typeof Activity[keyof typeof Activity];
        // 로그 메세지
        export type Message = string;
        // 로그 경로
        export type Path = string;
        // Sql Query
        export type Query = string;
        // 로그 구분자
        export const TargetClass = {
            DAILY: "Daily",
            MONTHLY: "Monthly"
        } as const;
        export type TargetClass = typeof TargetClass[keyof typeof TargetClass];
        // 타겟 구분자
        export const TargetCode = {
            BOARD_VIEW: "BV",
            BOARD_LIKE: "BL",
            BOARD_DISLIKE: "BD",
            LOGIN: "LI",
            LOGOUT: "LO"
        } as const;
        export type TargetCode = typeof TargetCode[keyof typeof TargetCode];
        // 로그 타입
        export const Type = {
            NOTICE: "공지사항",
            QA: "문의게시판"
        } as const;
        export type Type = typeof Type[keyof typeof Type];
    }

    // 선인세 관련
    export namespace Mg {
        // 계약 구분(작가/작품)
        export const ContractType = {
            AUTHOR: "MG01", // 작가계약
            SERIES: "MG02"  // 작품계약
        } as const;
        export type ContractType = typeof ContractType[keyof typeof ContractType];
        // 비고
        export type Remark = string;
        // 선인세 구분(플랫폼/선인세)
        export const Type = {
            PLATFORM: "platform",
            PUBLISHER: "publisher"
        } as const;
        export type Type = typeof Type[keyof typeof Type];
    }
    
    // 플랫폼관련
    export namespace Platform {
        // 플랫폼 고유색상
        export type Color = string;
        // 플랫폼 이름
        export type Name = string;
        // 플랫폼 순서
        export type Order = number;
        // 플랫폼 정산월
        export type Paymonth = string;
    }
    
    // 프로모션 관련
    export namespace Promotion {
        // 프로모션 내용
        export type Desc = string;
        // 프로모션 이름
        export type Name = string;
    }
    
    // 출판사 관련
    export namespace Publisher {
        // 사업자등록번호
        export type BusinessNo = string;
        // 직급
        export type CompanyRank = string;
        // 출판사 소개
        export type Desc = string;
        // 출판사 이름
        export type Name = string;
    }
    
    // 시리즈 관련
    export namespace Series {
        // 작품명
        export type BookTitle = string;
        // 엑셀 연결 ID
        export type ExcelId = string;
        // 시리즈명
        export type Name = string;
    }
    
    // SNS 로그인 관련
    export namespace SocialLogin {
        // SNS 로그인 ID
        export type Id = string;
        // SNS 로그인 Provider
        export const Provider = {
            GOOGLE: "google",
            KAKAO: "kakao",
            NAVER: "naver"
        } as const;
        export type Provider = typeof Provider[keyof typeof Provider];
    }
    
    // 명세서 관련
    export namespace Statement {
        // Salt
        export type Salt = string;
        // Sub 키
        export type Subkey = string;
        // 명세서 타입
        export const Type = {
            MONTHLY: "MRS", // 월정산 명세서
            MG: "MGS"       // 선인세 명세서
        } as const;
        export type Type = typeof Type[keyof typeof Type];
    }
    
    // 세금 관련
    export namespace Tax {
        // 세목
        export type Desc = string;
        // 세금 구분
        export const Type = {
            DOMESTIC: "D", // 국내 거주자
            ABROAD: "A",   // 국외 거주자
            BUSINESS: "B", // 기타 사업자
            INTERNAL: "I", // 작가별 설정에 따름
            ETC: "E",      // 기타
            NONE: "N"      // 해당없음
        } as const;
        export type Type = typeof Type[keyof typeof Type];
    }
    
    // 업로더 로그 관련
    export namespace UploaderLog {
        // 로그메세지
        export type Message = string;
        // 플랫폼
        export type Platform = string;
        // 단계
        export type Step = string;
        // 일별/월별 구분
        export const Type = {
            DAILY: "D",
            MONTHLY: "M"
        } as const;
        export type Type = typeof Type[keyof typeof Type];
    }
    
    // 사용자 관련
    export namespace User {
        // 생일
        export type Birth = string;
        // KCP 고유 ID
        export type KcpDi = string;
        // 사용자 구분
        export type LevelCode = string;
        // 닉네임
        export type Nickname = string;
        // 비밀번호
        export type Password = string;
        // 성별
        export const Sex = {
            MALE: "M",
            FEMALE: "F"
        } as const;
        export type Sex = typeof Sex[keyof typeof Sex];
        // 사용자상태
        export const Status = {
            ACTIVE: "A",  // 활성 유저
            DORMANT: "D", // 휴면 계정
            WITHDRAW: "W" // 삭제 계정
        } as const;
        export type Status = typeof Status[keyof typeof Status];
    }
}

// Ebook Maker 관련
export namespace EbookMaker {
    // 저자명
    export type Author = string;
    // 판권지 타입
    export const CopyrightType = {
        INFILE: "infile",  // 본문내 포함
        MAKE: "make",  // 신규생성
        NONE: "none"   // 만들지 않음
    } as const;
    export type CopyrightType = typeof CopyrightType[keyof typeof CopyrightType];
    // 파일 수
    export type FileLength = number;
    // 입력파일 타입
    export const InfileType = {
        HWP: "hwp",
        HWPX: "hwpx",
        DOCX: "docx",
        TXT: "txt"
    } as const;
    export type InfileType = typeof InfileType[keyof typeof InfileType];
    // 제작 타입
    export const MakeType = {
        NONE: "", // 선택안함
        SPLIT: "split", // 통->분할
        MERGE: "merge", // 분할-> 통
        SEPARATE: "separate"  // 개별
    } as const;
    export type MakeType = typeof MakeType[keyof typeof MakeType];
    // 출력파일 타입
    export const OutfileType = {
        EPUB: "epub2",
        TXT: "txt",
        JPG: "jpg",
        PNG: "png"
    } as const;
    export type OutfileType = typeof OutfileType[keyof typeof OutfileType];
    // 출판사명
    export type Publisher = string;
    // 소제목타입
    export const SubtitleType = {
        INPAGE: "ingpage", // 원고파일 내 별도페이지로 포함
        INBODY: "inbody", // 원고파일 내 본문위 포함
        MAKE: "newpage", // 소제목 생성
        NONE: "none" // 만들지 않음
    } as const;
    export type SubtitleType = typeof SubtitleType[keyof typeof SubtitleType];
    // 소제목 생성 방식
    export const SubtitleMakeType = {
        NEWPAGE: "newpage",
        NEWBODY: "newbody"
    } as const;
    export type SubtitleMakeType = typeof SubtitleMakeType[keyof typeof SubtitleMakeType];
    // 작품명
    export type Title = string;
}

// 판권지 관련
export namespace Copyright {
    // 정렬
    export const Align = {
        LEFT: "left",
        CENTER: "center",
        RIGHT: "right"
    } as const;
    export type Align = typeof Align[keyof typeof Align];
    // 볼드체
    export type Bold = boolean;
    // 판권지 내용
    export type Content = string;
    // 이탤릭체
    export type Italic = boolean;
    // 링크주소
    export const Link = {
        NONE: "none",
        MAILTO: "mailto",
        HREF: "href"
    } as const;
    export type Link = typeof Link[keyof typeof Link];
    // 순서
    export type Order = number;
    // 폰트사이즈
    export const Size = {
        P: "P",
        H1: "H1",
        H2: "H2",
        H3: "H3",
        H4: "H4",
        H5: "H5",
        H6: "H6"
    } as const;
    export type Size = typeof Size[keyof typeof Size];
    // 템플릿명
    export type TemplateName = string;
    // 판권지 항목명
    export type Field = string;
    // 밑줄
    export type Underline = boolean;
}