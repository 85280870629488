import styled from "styled-components";
import PlanCard from "@/components/molecules/landing/pricing/PlanCard";
import {PriceType, Card} from "@/components/molecules/landing/pricing/types"
import { colors } from "@/assets/styles/theme";

export default function PlanCardContainer(props: {btnState: boolean}) {
    const yearPlanCardData: Card[] = [
        {type: PriceType.Free, planType: "Free 플랜", typeExplain: "Free 플랜 선택시", typeExplainColor: colors.gray300, typeExplainTextColor: colors.black,nowPriceText: "" , nowPrice: "0", nowPriceExplain: "", nowPriceTextColor: colors.black, explainText: "기본적인 판매 관리 기능을\n 사용하실 수 있습니다",
        explainTextArray: ["최대 100종 작품 관리 가능", "판매 데이터 입력 및 관리"]},
        {type: PriceType.Standard, planType: "STANDARD 플랜", typeExplain: "정가", typeExplainColor: colors.red500, typeExplainTextColor: colors.black, originalPrice: "5,400,000원", nowPriceText: "현재 할인가", nowPrice: "0", nowPriceExplain: "부가가치세 10% 미포함 금액",
        nowPriceTextColor: colors.red500, explainText: "더 많은 작품들을 관리하기 위한\n 손쉬운 기능들을 제공합니다",
        explainTextArray: ["FREE 플랜의 모든 기능", "최대 300종 작품 관리 기능", "판매 데이터를 손쉽게 볼 수 있는 대시보드", "작가 대상 정산서 발급 기능 제공", "손쉬운 E-Book 제작 툴 기능 제공", "유통/프로모션 정보 관리 기능 제공", "판매 데이터 자동 업로더 제공", "무제한 팀원 추가 및 권한 관리 기능 제공"]},
        {type: PriceType.Enterprise, planType: "ENTERPRISE 플랜", typeExplain: "ENTERPRISE 플랜 선택시", typeExplainColor: colors.information, typeExplainTextColor: colors.white, nowPriceText: "", nowPrice: "문의하기", nowPriceExplain: "", nowPriceTextColor: colors.information, explainText: "중대형 출판사에게 적합한 기능과\n 전담 기술 인력을 제공합니다.",
        explainTextArray: ["STANDARD 플랜의 모든 기능", "300종 이상 작품 보유 시 도입 문의", "전담 기술 매니저"]}
    ];

    const monthlyPlanCardData: Card[] = [
        {type: PriceType.Free, planType: "Free 플랜", typeExplain: "Free 플랜 선택시", typeExplainColor: colors.gray300, typeExplainTextColor: colors.black, nowPriceText: "" , nowPrice: "0", nowPriceExplain: "", nowPriceTextColor: colors.black, explainText: "기본적인 판매 관리 기능을\n 사용하실 수 있습니다",
        explainTextArray: ["최대 100종 작품 관리 가능", "판매 데이터 입력 및 관리"]},
        {type: PriceType.Standard, planType: "STANDARD 플랜", typeExplain: "정가", typeExplainColor: colors.red500, typeExplainTextColor: colors.black, originalPrice: "500,000원", nowPriceText: "현재 할인가", nowPrice: "0", nowPriceExplain: "부가가치세 10% 미포함 금액",
        nowPriceTextColor: colors.red500, explainText: "더 많은 작품들을 관리하기 위한\n 손쉬운 기능들을 제공합니다",
        explainTextArray: ["FREE 플랜의 모든 기능", "최대 300종 작품 관리 기능", "판매 데이터를 손쉽게 볼 수 있는 대시보드", "작가 대상 정산서 발급 기능 제공", "손쉬운 E-Book 제작 툴 기능 제공", "유통/프로모션 정보 관리 기능 제공", "판매 데이터 자동 업로더 제공", "무제한 팀원 추가 및 권한 관리 기능 제공"]},
        {type: PriceType.Enterprise, planType: "ENTERPRISE 플랜", typeExplain: "ENTERPRISE 플랜 선택시", typeExplainColor: colors.information, typeExplainTextColor: colors.white, nowPriceText: "", nowPrice: "문의하기", nowPriceExplain: "", nowPriceTextColor: colors.information, explainText: "중대형 출판사에게 적합한 기능과\n 전담 기술 인력을 제공합니다.",
        explainTextArray: ["STANDARD 플랜의 모든 기능", "300종 이상 작품 보유 시 도입 문의", "전담 기술 매니저"]}
    ];
    
    return (
        <StyledPlanContainer>
            {props.btnState 
            ? yearPlanCardData.map(s => (
                <PlanCard
                    planCardData={s}
                    key={yearPlanCardData.indexOf(s)}
                />)) 
            : monthlyPlanCardData.map(s => (
                <PlanCard 
                    planCardData={s} 
                    key={monthlyPlanCardData.indexOf(s)}
                />))}
        </StyledPlanContainer>
    )
}

const StyledPlanContainer = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 716px;
    top: 105px;
    padding: 30px calc((100% - 1200px) / 2);
`;

