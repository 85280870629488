import { useState, ChangeEvent, Dispatch, SetStateAction, useEffect, KeyboardEvent, useRef } from "react";
import styled from "styled-components";
import TextInput from "@/components/atoms/input/TextInput";
import { addComma, removeComma } from "@/modules/utils/filter";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import useAlert from "@/modules/hooks/useAlert";

export default function CustomInput({
    min,
    max,
    reset,
    setRange,
    setLeftValue,
    setRightValue,
    onApplyInput,
}: CustomInputProps) {
    const [btnActive, setBtnActive] = useState(false);
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const [errors, setErrors] = useState({
        min: "",
        max: "",
    });
    const isChanged = useRef(false);
    const { alert } = useAlert();
    const inputStyle = {
        width: "100px",
        height: "32px",
        fontSize: "12px",
    };

    // 필터 초기화
    useEffect(() => {
        if (reset) {
            setBtnActive(false);
            setMinValue("");
            setMaxValue("");
            setErrors({
                min: "",
                max: "",
            });
        }
    }, [reset]);

    // 최소, 최대값에 빈값 입력
    useEffect(() => {
        // if (leftValue === min && rightValue === max) return;
        if (!isChanged.current) return;
        if (!minValue && !maxValue && !btnActive) {
            setLeftValue(min);
            setRightValue(max);
            setRange({ min, max });
        }
    }, [minValue, maxValue, btnActive]);

    const clickBtn = () => setBtnActive((prev) => !prev);

    const changeMin = (e: ChangeEvent<HTMLInputElement>) => setMinValue(e.target.value);

    const changeMax = (e: ChangeEvent<HTMLInputElement>) => setMaxValue(e.target.value);

    const validateValue = (param: string) => {
        return (e: KeyboardEvent<HTMLInputElement>) => {
            isChanged.current = true;
            let v = e.currentTarget.value;
            let temp = v.replace(/[^\d]/g, "").replace(/(?!^)-/g, "");
            if (temp.length) temp = String(parseInt(temp.slice(0, 9), 10));

            if (param === "min") setMinValue(addComma(temp));
            if (param === "max") setMaxValue(addComma(temp));
            if (minValue && maxValue) {
                if (param === "min" && Number(temp) > Number(maxValue.replace(/[^\d-]/g, "").replace(/(?!^)-/g, ""))) {
                    setErrors({ min: "최소값은 최대값보다 작아야합니다.", max: "" });
                } else if (
                    param === "max" &&
                    Number(temp) < Number(minValue.replace(/[^\d-]/g, "").replace(/(?!^)-/g, ""))
                ) {
                    setErrors({ min: "", max: "최대값은 최소값보다 커야합니다." });
                } else {
                    setErrors({ min: "", max: "" });
                }
            } else {
                setErrors({ min: "", max: "" });
            }
        };
    };

    const clickApply = () => {
        if (errors.min || errors.max) {
            return alert("warning", "주의", "조건을 확인해주세요");
        }
        if (!minValue && !maxValue) {
            // 초기값이 아닐 때
            if (isChanged.current) {
                onApplyInput(0, 0);
            }
            return setBtnActive((prev) => !prev);
        }

        const newMin = Number(removeComma(minValue));
        const newMax = Number(removeComma(maxValue || max));

        setRange({ min: newMin, max: newMax });
        setLeftValue(newMin);
        setRightValue(newMax);
        onApplyInput(newMin, newMax);
        setBtnActive((prev) => !prev);
    };

    return (
        <CustomInputContainer>
            <OpenBtn active={btnActive} onClick={clickBtn}>
                직접 입력
            </OpenBtn>
            <InputSection active={btnActive}>
                <ValidateWrapper dataError={errors.min}>
                    <TextInput
                        value={minValue}
                        placeholder="최소"
                        onChangeHandler={changeMin}
                        onKeyUp={validateValue("min")}
                        customStyle={inputStyle}
                    />
                </ValidateWrapper>
                <Deco>~</Deco>
                <ValidateWrapper dataError={errors.max}>
                    <TextInput
                        value={maxValue}
                        placeholder="최대"
                        onChangeHandler={changeMax}
                        onKeyUp={validateValue("max")}
                        customStyle={inputStyle}
                    />
                </ValidateWrapper>
            </InputSection>
            <ApplyBtn active={btnActive} onClick={clickApply}>
                적용
            </ApplyBtn>
        </CustomInputContainer>
    );
}

const Button = styled.button<{ active: boolean }>`
    min-width: 86px;
    height: 32px;
    border: 1px solid ${({ theme: { colors } }) => colors.blue500};
    border-radius: 4px;
    background-color: ${({ theme: { colors } }) => colors.white};
    color: ${({ theme: { colors } }) => colors.blue500};
    font-size: 12px;
    cursor: pointer;
`;

const CustomInputContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 24px;
`;
const OpenBtn = styled(Button)`
    display: ${({ active }) => (active ? "none" : "block")};
    margin-right: 12px;
`;

const InputSection = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "flex" : "none")};
    align-items: center;
`;
const ApplyBtn = styled(Button)`
    display: ${({ active }) => (active ? "block" : "none")};
    margin-left: 12px;
`;

const Deco = styled.div`
    display: inline-block;
    color: ${({ theme }) => theme.colors.gray300};
    margin: 0 8px;
`;

interface CustomInputProps {
    min: number;
    max: number;
    reset: boolean | undefined;
    setRange: Dispatch<
        SetStateAction<{
            min: number;
            max: number;
        }>
    >;
    setLeftValue: Dispatch<SetStateAction<number>>;
    setRightValue: Dispatch<SetStateAction<number>>;
    onApplyInput: (leftValue: number, rightValue: number) => void;
}
