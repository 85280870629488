import styled from "styled-components";
import React, { useEffect } from "react";
import useLoading from "@/modules/hooks/useLoading";

export default function TotalSalesSkeleton() {
    const { loadingOn, loadingOff } = useLoading();
    useEffect(() => {
        loadingOn("total");
        return() => {
            loadingOff();
        }
    }, [])
    const headers = [{ width: "180px", hskWidth: "80px", skWidth: "44px" },
                     { width: "170px", hskWidth: "72px", skWidth: "44px" },
                     { width: "170px", hskWidth: "72px", skWidth: "50px" },
                     { width: "170px", hskWidth: "68px", skWidth: "30px" },
                     { width: "170px", hskWidth: "72px", skWidth: "40px" },
                     { width: "170px", hskWidth: "72px", skWidth: "35px" },
                     { width: "170px", hskWidth: "68px", skWidth: "54px" }]
    return (
        <React.Fragment>
            <Table>
                <colgroup
                    span={7}
                    style={{
                        minWidth: "50px",
                    }}
                ></colgroup>
                <Header>
                    <HeaderTr>
                        {headers.map((header, i) => (
                            <Th key={i} width={header.width} onClick={() => {}}>
                                <ThContent>
                                    <ThContentHeader>
                                        <SkeletonContainer width={header.hskWidth} height="20px">
                                            <SkeletonAnimation width={header.hskWidth} height="20px" />
                                        </SkeletonContainer>
                                    </ThContentHeader>
                                </ThContent>
                            </Th>
                        ))}
                    </HeaderTr>
                </Header>
                <Body>
                    {[0,1,2,3,4,5].map((item, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <Tr>
                                    {headers.map((h, i) => {
                                        return (
                                            <Td key={i}>
                                                <SkeletonContainer width={h.skWidth} height="20px">
                                                    <SkeletonAnimation width={h.skWidth} height="20px" />
                                                </SkeletonContainer>
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            </React.Fragment>
                        );
                    })}
                </Body>
            </Table>
        </React.Fragment>
    )
}

const SkeletonContainer = styled.div<{ width?: string, height?: string }>`
    width: ${({ width }) => width ? width : "320px"};
    height: ${({ height }) => height ? height : "14px"};
    background-image: -webkit-linear-gradient( lightgray ${({ height }) => height ? height : "14px"}, transparent 0 );
`

const SkeletonAnimation = styled.div<{ width?: string, height?: string }>`
    animation: placeHolderShimmer 1s linear forwards infinite;
    background: #f1f3f5;
    background-image: linear-gradient(to right, #e9ecef 0%, #dee2e6 20%, #e9ecef 40%, #e9ecef 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: ${({ height }) => height ? height : "14px"};
    position: relative;
    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0
        }
        100% {
            background-position: 468px 0
        }
    }
`

const Table = styled.table`
    width: 100%;
    font-size: 12px;
`;
const Header = styled.thead`
    border-top: 1px solid ${({ theme }) => theme.colors.gray600};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
    height: 48px;
    Tr {
        border-bottom: none;
    }
`;
const Body = styled.tbody`
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
`;
const Tr = styled.tr`
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
    nth-child(1) {
        background-color: ${({ theme }) => theme.colors.gray50};
    }

`;
const HeaderTr = styled(Tr)<{ noSort?: boolean }>`
    cursor: ${({ noSort }) => (noSort ? "auto" : "pointer")};
`;

const Th = styled.th<{ width?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 48px;
    padding: 14px 8px;
    text-align: left;
    vertical-align: middle;
    font-weight: 500;
`;
const ThContent = styled.div<{ textAligns?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ textAligns }) => (textAligns ? textAligns : "flex-start")};
`;
const ThContentHeader = styled.div`
    margin-right: 4px;
`;
const Td = styled.td<{ width?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 60px;
    padding: 14px 8px;
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;
`;