import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "@/modules/store";
import { getSalesPlatformList } from "@/pages/common/platformSlice";
import Daily from "@/components/templates/salesInquiry/publisher/Daily";
import Etc from "@/components/templates/salesInquiry/publisher/Etc";
import Mg from "@/components/templates/salesInquiry/publisher/Mg";
import Monthly from "@/components/templates/salesInquiry/publisher/Monthly";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import Text from "@/components/atoms/text";
import styled from "styled-components";
import TypeNavBar from "@/components/molecules/common/typeNavBar/TypeNavBar";
import useCheckToken from "@/modules/hooks/useCheckToken";


function SalesInquiry() {
    const [searchParams] = useSearchParams();
    const [template, setTemplate] = useState(<></>);
    const dispatch = useAppDispatch();
    const tokenStatus = useCheckToken();

    useEffect(() => {
        // if (tokenStatus) {
        switch (searchParams.get("type")) {
            case "Daily":
                setTemplate(<Daily />);
                break;
            case "Monthly":
                setTemplate(<Monthly />);
                break;
            case "Mg":
                setTemplate(<Mg />);
                break;
            case "Etc":
                setTemplate(<Etc />);
                break;
            default:
                break;
        // }
    }
    }, [searchParams, tokenStatus]);
    
    // 데이터 패치
    useLayoutEffect(() => {
        dispatch(getSalesPlatformList());
    }, []);

    // if (!tokenStatus) {
    //     return <></>
    // }
    return (
        <SectionLayout>
            <StyledTitleContainer>
                <Text text="판매 조회 및 변경" customStyle={textStyle} />
            </StyledTitleContainer>
            <TypeNavBar
                menus={["일별 판매 현황", "월 정산", "선인세", "기타 지급금"]}
                types={["Daily", "Monthly", "Mg", "Etc"]}
            />
            {template}
        </SectionLayout>
    );
}

const StyledTitleContainer = styled.div`
    margin-bottom: 20px;
`;
const textStyle = {
    fontSize: "20px",
    fontWeight: "700",
};

export default SalesInquiry;
