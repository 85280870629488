import moment from "moment";
import api from "@/api";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

// utils
import { addComma } from "@/modules/utils/filter";
import { arrayToArrayBuffer, blobSaveAs } from "@/modules/utils/file";
import { calcPeriod } from "@/modules/utils/date";

// types
import type { DailyState, ServerResult, UpdatedParams } from "./types";
import type { SortDirType } from "@/components/molecules/common/table/hooks/useDataTableSort";

const name = "inquiry/publisher/daily";
const initialState: DailyState = {
    items: [],
    total_amt: 0,
    total_cnt: 0,

    initialDataLoaded: false,
    fetchLoading: false,
    detailDataLoading: false,

    // 전체 데이터 조회
    changedParamsId: 0, // 전체 데이터 조회 params 변경시 업데이트
    startDate: calcPeriod("yesterday").startDate || "",  // 어제 날짜기준으로 판매 시작일 초기화
    endDate: calcPeriod("yesterday").endDate || "", // 어제 날짜기준으로 판매 종료일 초기화
    selectedPlatform: [],
    searchSeriesName: "",
    searchBookName: "",
    searchAuthorName: "",
    itemsPerPage: 10,    
    sortBy: "date",
    sortDesc: false,

    // 세부 데이터 조회 (page, itemsPerPage, tmpJsonTable)
    changedDetailParamsId: 0, // 세부 데이터 조회 params 변경시 업데이트
    tmpJsonTable: [],
    page: 1,

    // 자동완성
    atcmpList: [],

    // 기타
    reset: false,
    startDateError: "",
    endDateError: "",
};

// 데이터 조회 - 첫 조회 / 필터, 정렬 변경시
export const getDailyData = createAsyncThunk(`${name}/getDailyData`, async (_, { rejectWithValue, getState, signal }) => {
        try {
            const { inquiryDaily, pagination }: any = getState();
            const apiParams = {
                startDate: inquiryDaily.startDate,
                endDate: inquiryDaily.endDate,
                selectedPlatform: inquiryDaily.selectedPlatform,
                searchBookName: inquiryDaily.searchBookName,
                searchSeriesName: inquiryDaily.searchSeriesName,
                searchAuthorName: inquiryDaily.searchAuthorName,
                itemsPerPage: pagination.data['daily-inquiry'] ? pagination.data['daily-inquiry'].itemsPerPage : 10,
                sortBy: inquiryDaily.sortBy,
                sortDesc: inquiryDaily.sortDesc,
            }
            return (await api.post(`/api/settlement/newSalesInquiry/daily/publisher/select`, apiParams, { signal })).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 데이터 조회 - 보기옵션 / 페이지 변경 시
export const getDailyDetails = createAsyncThunk(`${name}/getDailyDetails`, async ({ page, itemsPerPage }:{ page:number, itemsPerPage:number }, { rejectWithValue, getState, signal }) => {
        try {
            const { inquiryDaily }: any = getState();
            const apiParams = {
                itemsPerPage: itemsPerPage ?? 10,
                page: page ?? 1,
                tmpJsonTable: inquiryDaily.tmpJsonTable,
            }
            return (await api.post(`/api/settlement/newSalesInquiry/daily/publisher/select/details`, apiParams, { signal })).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 데이터 저장
export const updateDailyData = createAsyncThunk(
    `${name}/updateDailyData`,
    async (apiParams: UpdatedParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSalesInquiry/daily/update`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 엑셀 다운로드
export const downloadExcel = createAsyncThunk(`${name}/downloadExcel`, async (_, { getState, rejectWithValue }) => {
    try {
        const { inquiryDaily }:any = getState();
        const apiParams = {
            startDate: inquiryDaily.startDate,
            endDate: inquiryDaily.endDate,
            selectedPlatform: inquiryDaily.selectedPlatform,
            searchBookName: inquiryDaily.searchBookName,
            searchSeriesName: inquiryDaily.searchSeriesName,
            searchAuthorName: inquiryDaily.searchAuthorName,
        }
        return (await api.post(`/api/settlement/newSalesInquiry/daily/publisher/excelDownload`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

// 전체 검색 (시리즈 + 저자명 + 작품명)
export const getAtcmpByAll = createAsyncThunk(`${name}/getAtcmpByAll`,
    async ({ keyword }: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/dailyAndMonthly/select`, 
                { keyword, from: 'daily' })).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 시리즈 검색
export const getAtcmpBySeries = createAsyncThunk(`${name}/getAtcmpBySeries`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/seriesName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 저자명 검색
export const getAtcmpByAuthor = createAsyncThunk(`${name}/getAtcmpByAuthor`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/authorName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 작품명 검색
export const getAtcmpByBookName = createAsyncThunk(`${name}/getAtcmpByBookName`,
    async ({ keyword }: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/bookName/select`, { keyword, from: 'daily'})).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

export const dailySlice = createSlice({
    name,
    initialState,
    reducers: {
        setStartDate(state, action: PayloadAction<string>) {
            state.startDate = action.payload;
        },
        setEndDate(state, action: PayloadAction<string>) {
            state.endDate = action.payload;
        },
        setStartDateError(state, action) {
            state.startDateError = action.payload;
        },
        setEndDateError(state, action) {
            state.endDateError = action.payload;
        },
        setPlatforms(state, action) {
            state.selectedPlatform = action.payload;
        },
        setSearchKeyword(state, action) {
            const { searchSeriesName = "", searchBookName = "", searchAuthorName = "" } = action.payload;
            state.searchSeriesName = searchSeriesName;
            state.searchBookName = searchBookName;
            state.searchAuthorName = searchAuthorName;
        },
        setItemsPerPage(state, action) {
            state.itemsPerPage = action.payload;
        },
        setItem: (state, action: PayloadAction<{ index:number; item:any }>) => {
            const { index, item } = action.payload;
            state.items[index] = item;
        },
        setItems(state, action) {
            state.items = action.payload;
        },
        setParamsId(state, action: PayloadAction<{ type:'all' | 'detail' }>){
            const { type } = action.payload;
            if (type === 'all') state.changedParamsId += 1;
            else state.changedDetailParamsId += 1;
        },
        setTmpJsonTable(state, { payload }) {
            state.tmpJsonTable = payload
        },
        setSortOptions(state, actions: PayloadAction<{ sortBy:string; sortDir:SortDirType }>) {
            const { sortBy, sortDir } = actions.payload;
            state.sortBy = sortBy;
            state.sortDesc = sortDir === 'desc';
        },
        setNoData(state) {
            state.items = [];
            state.total_cnt = 0;
            state.total_amt = 0;
        },
        setReset(state, action) {
            state.reset = action.payload;
        },
        resetStore() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDailyData.pending, (state) => {
                state.fetchLoading = true;
            })
            .addCase(getDailyData.fulfilled, (state, action) => {
                state.fetchLoading = false;
                state.initialDataLoaded = true;
                
                let {
                    total_cnt,
                    total_amt,
                    result: { items, details },
                }: ServerResult = action.payload.data;

                if (!items) {
                    state.items = [];
                    state.total_cnt = total_cnt;
                    state.total_amt = total_amt;
                    return;
                }

                for (let row of details) {
                    for (let i = 0; i < items.length; i++) {
                        if (
                            items[i].date === row.date &&
                            items[i].publisher_id === row.publisher_id &&
                            items[i].platform_id === row.platform_id &&
                            items[i].series_id === row.series_id
                        ) {
                            row.itemId = items[i].id;
                            row.amount = addComma(row.amount);
                            items[i].detail_series.push(row);
                        }
                    }
                }

                // amount에 comma 추가
                state.items = items.map((item) => ({ ...item, sum_amt: addComma(item.sum_amt), sum_qty: item.platform_id === 30 ? '정보없음' : item.sum_qty }));
                state.total_cnt = total_cnt;
                state.total_amt = Number(total_amt.toFixed(2));
            });

        builder
            .addCase(getDailyDetails.pending, (state) => {
                state.detailDataLoading = true;
            })
            .addCase(getDailyDetails.fulfilled, (state, action) => {
                state.detailDataLoading = false;
                const { result: details } = action.payload.data;
                const { page, itemsPerPage } = action.meta.arg;

                const startIdx = (page - 1) * itemsPerPage;
                const endIdx = page * itemsPerPage;
                
                let items = [...state.items.slice(startIdx, endIdx)];

                // 중복방지를 위해 기존 details 모두 삭제
                for (let item of items) {
                    item.detail_series = [];
                }
                
                // items에 detail data 추가
                for (let row of details) {
                    for (let i = 0; i < items.length; i++) {
                        if (
                            items[i].date === row.date &&
                            items[i].publisher_id === row.publisher_id &&
                            items[i].platform_id === row.platform_id &&
                            items[i].series_id === row.series_id
                        ) {
                            row.itemId = items[i].id;
                            row.amount = addComma(row.amount);
                            items[i].detail_series.push(row);
                        }
                    }
                }

                for (let item of items) {
                    for (let i = 0; i< state.items.length; i++) {
                        if (state.items[i].id === item.id) {
                            state.items[i] = item;
                            break;
                        } 
                    }
                };
            });
        
        builder.addCase(downloadExcel.fulfilled, (_, { payload }) => {
            if (!payload.data) return;

            const arrayBuffer = arrayToArrayBuffer(payload.data.data);
            blobSaveAs(arrayBuffer, 
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
                `(판매현황조회) 일 판매현황 - ${moment().format('YYYY년 MM월 DD일')}`);
        });

        // 자동완성 - 전체 검색
        builder.addCase(getAtcmpByAll.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
        // 자동완성 - 시리즈 검색
        builder.addCase(getAtcmpBySeries.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
        // 자동완성 - 저자명 검색
        builder.addCase(getAtcmpByAuthor.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
        // 자동완성 - 작품명 검색
        builder.addCase(getAtcmpByBookName.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
    },
});

export const inquiryDailyActions = dailySlice.actions;
export default dailySlice.reducer;
