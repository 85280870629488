import styled from "styled-components"
import NoticeCard from "@/components/molecules/home/update/NoticeCard"

interface Notice {
    id: number,
    title: string,
    content: string
}

interface CardsProps {
    slideSpot: number,
    noticeDatas: Array<Notice>
}

export default function NoticeCardsContainer({slideSpot, noticeDatas}: CardsProps) {
    return (
        <StyledContainer style={{ transform: `translateX(${slideSpot}px)`}}>
            {noticeDatas.map((data) => (
                <NoticeCard key={data.id} id={data.id} title={data.title} content={data.content}/>
            ))}
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width:1200px;
    transition: transform .3s;
    margin-left: 12px;

`