import styled from 'styled-components'
import FocusSubTitleWrapper from '@/components/molecules/introduce/focus/FocusSubTitleWrapper';
import IconButton from "@/components/molecules/common/banner-slider/IconButton";
import Text from '@/components/atoms/text';
import { colors } from '@/assets/styles/theme';
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn';

export default function FocusCard({ text, description, textStyle, descriptionStyle, icon}: CardProps) {
    const animatedItem = useScrollFadeIn('up', 1, 0);
    const Icon = icon;
    const isIncludeIcon = icon === undefined ? false : true;
    const backColor = textStyle.backgroundColor || "";
    
    const btnStyle = {
        width: "160px",
        height: "40px",
        fontSize: "16px",
        borderRadius: "4px",
        fontWeight: "500",
        color: colors.black
    }
    
    let buttonLabel = "글링 시작하기"

    const toSignInHandler = () => document.location.href = "/signin";
    
    const filterDescription = () => description.split("<br/>").map((text, idx) => <Text key={idx} text={text} customStyle={descriptionStyle}/>)

    return (
        <CardWrapper color={backColor} {...animatedItem}>
            <TextWrapper>
                <FocusSubTitleWrapper text={text} customStyle={textStyle}/>
                <FocusDescriptionWrapper>
                    {filterDescription()}
                </FocusDescriptionWrapper>
            </TextWrapper>
            <IconWrapper>
                {isIncludeIcon ? <Icon/> : <IconButton label={buttonLabel} customStyle={btnStyle} onClickHandler={toSignInHandler} />}
            </IconWrapper>
        </CardWrapper>
    )
}


const CardWrapper = styled.div`
    width: 580px;
    height: 200px;
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    border-radius: 8px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    background-color: ${props => (props.color || "white")};
`;

const TextWrapper = styled.div`
`

const FocusDescriptionWrapper = styled.div`
    margin-top: 24px;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`

interface StyleProps {
    fontWeight?: string;
    fontSize?: string;
    lineHeight?: string;
    marginTop?: string;
    width?: string;
    backgroundColor?: string;
    color?: string
}

interface CardProps {
    text: string,
    description: string,
    textStyle: StyleProps,
    descriptionStyle: {},
    icon: string
}