import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setDate, setPlatformId, resetStore } from "@/pages/settlement/sales-entry/daily/dailySlice";
import TemplateLayout from "@/components/molecules/sales-entry/common/TemplateLayout";
import DailyTableFilter from "@/components/molecules/sales-entry/daily/DailyTableFilter";
import DailyTableTop from "@/components/molecules/sales-entry/daily/DailyTableTop";
import DailyTable from "@/components/molecules/sales-entry/daily/DailyTable";

export default function Daily() {
    const { uploaderMsg } = useSelectorTyped((state: RootState) => state.entryDaily);
    const { platform, salesDate } = useSelectorTyped((state: RootState) => state.uploader);
    const dispatch = useAppDispatch();

    // 업로더
    useEffect(() => {
        if (platform) dispatch(setPlatformId(Number(platform)));
        if (salesDate) dispatch(setDate(salesDate));
    }, [platform, salesDate]);

    useEffect(() => {
        return () => {
            dispatch(resetStore());
        };
    }, []);

    return (
        <TemplateLayout>
            <input type="hidden" id="uploaderMsg" value={uploaderMsg} />
            <DailyTableFilter />
            <DailyTableTop />
            <DailyTable />
        </TemplateLayout>
    );
}
