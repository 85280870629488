import styled from "styled-components";

// components
import DepositFilterBox from "@/components/molecules/total-sales/DepositFilterBox";
import DepositTable from "@/components/molecules/total-sales/DepositTable";
import TotalSalesSkeleton from "@/components/molecules/common/loading/TotalSalesSkeleton";

// reducer
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState } from "@/modules/store";

export default function Deposit() {
    const { loading } = useSelectorTyped((state: RootState) => state.totalSales);

    return (
        <DepositWrap>
            <DepositFilterBox />
            {loading ? <TotalSalesSkeleton /> : <DepositTable />}
        </DepositWrap>
    )
}

const DepositWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`