import { RootState, useAppDispatch } from "@/modules/store";
import { alertActions } from "@/pages/common/alertSlice";
import { useEffect, useRef } from "react";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";

function useAlert() {
    const dispatch = useAppDispatch();
    let { show, state } = useSelectorTyped((state:RootState) => state.alert);
    const state_ref = useRef("less");

    useEffect(()=>{
        if(state === "resolve" || state === "reject") {
            state_ref.current = state;
        }
        return() => {
            state_ref.current = "less";
        }
    }, [state])

    const alert = async(type:"confirm"|"info"|"success"|"error"|"warning", title: string, text: string ) => {
        if (!show) {
            dispatch(alertActions.showAlert({
                type: type,
                title: title,
                text: text
            }));
            const wait = () => new Promise((resolve) => {
                state_ref.current = "wait";
                let checkSeriesMatch = setInterval(async () => {
                    if (state_ref.current === "resolve") {
                        await clearInterval(checkSeriesMatch);
                        state_ref.current = "less";
                        dispatch(alertActions.hideAlert());
                        return resolve("Y");
                    } else if (state_ref.current === "reject") {
                        await clearInterval(checkSeriesMatch);
                        state_ref.current = "less";
                        dispatch(alertActions.hideAlert());
                        return resolve("N");
                    }
                }, 200);
            });
            return await wait();
        }
    }
    
    return { alert }
}

export default useAlert;