import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { RootState, useAppDispatch, useAppSelector } from '@/modules/store';
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import usePagination from '@/components/molecules/common/pagination/usePagination';
import { getFaqList } from '@/pages/support/question/slice';
import useLoading from '@/modules/hooks/useLoading';
import useAlert from '@/modules/hooks/useAlert';
import { colors } from '@/assets/styles/theme'
import { questionActions } from '@/pages/support/question/slice';
import Pagination from '@/components/molecules/common/pagination/Pagination';
import QuestionButtonWrapper from '@/components/molecules/support/question/QuestionButtonWrapper';
import QuestionCardContainer from '@/components/molecules/support/question/QuestionCardContainer';


export default function QuestionContainer() {

    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const { setDataCount, setPage, page, itemsPerPage, pageCount, totalVisible} = usePagination({ from: "Question" });
    const { filteredData } = useAppSelector((state: RootState) => state.question);
    const [clickedItem, setClickedItem] = useState('전체');

    const termRef = useRef<HTMLInputElement>(null);

    const buttonOptions = [
        {category: '전체', value: 'all'},
        {category: '건의사항', value: 'suggestion'},
        {category: '정산', value: 'settlement'},
        {category: '기타', value: 'etc'}
    ];

    const buttonStyles = {
        width: "auto",
        padding: "5px 27px",
        margin: "0 16px 16px 0",
    }

    const buttonClickHandler = (button:any) => {
        setPage(1);
        setClickedItem(button.category);
        dispatch(questionActions.filterQuestion({category: button.category}));
    }

    const termfilterHandler = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (termRef.current && termRef.current.value === "") {
                dispatch(questionActions.filterQuestion({category: clickedItem}));
            } else if (termRef.current && termRef.current.value !== null) {
                dispatch(questionActions.searchFilter({category: clickedItem, term: termRef.current.value}));
                setPage(1);
            }
        }
    }

    const fetchData = async () => {
        try {
            loadingOn();
            await dispatch(getFaqList()).unwrap();
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    useEffect(() => {
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        setDataCount(filteredData.length);
    }, [filteredData, itemsPerPage, setDataCount]);

    return (
        <StyledContainer>
            <StyledTitleWrapper>
                자주 묻는 질문       
            </StyledTitleWrapper>
            <StyledInputContainer>
                <StyledInputWrapper
                    ref={termRef} 
                    placeholder='무엇이든 물어보세요!'
                    type="text"
                    onKeyDown={termfilterHandler}
                />
                <StyledIconWrapper>
                    <SearchIcon fill={colors.black} onClick={()=>{}}/>
                </StyledIconWrapper>
            </StyledInputContainer>
            <StyledButtonsContainer>
                <QuestionButtonWrapper
                    buttonOptions={buttonOptions}
                    buttonStyles={buttonStyles}
                    buttonClickHandler={buttonClickHandler}
                    clickedItem={clickedItem}
                />
            </StyledButtonsContainer>
            <StyledQuestionContainer>
                <QuestionCardContainer
                    filteredData={filteredData}
                    itemsPerPage={itemsPerPage}
                    page={page}
                />
            </StyledQuestionContainer>
            <PaginationWrapper>
                <Pagination
                    pageCount={pageCount}
                    totalVisible={totalVisible}
                    from="Question"
                />
            </PaginationWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 900px;
`;
const StyledTitleWrapper =styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-weight:700;
    font-size: 20px;
`;
const StyledInputContainer = styled.div`
    margin-top: 24px;
    position: relative;
`;
const StyledInputWrapper = styled.input`
    width: 100%;
    height: 60px;
    border: 2px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    padding: 16px;
    &::placeholder {
        color: ${({ theme }) => theme.colors.gray600};
    }
`;

const StyledIconWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 16px;
`;
const StyledButtonsContainer = styled.div`
    margin-top: 24px;
`;
const StyledQuestionContainer = styled.div`
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
`;



