import { colors } from "@/assets/styles/theme"
import { RootState } from '@/modules/store';
import useSelectorTyped from '@/modules/hooks/useSelectorTyped';
import Slider from "@/components/molecules/common/banner-slider/slider"
import Dashboard from "@/assets/images/introduce-image/home-slider-01.png" 
import Inquiry  from "@/assets/images/introduce-image/home-slider-02.png";
import Portfolio  from "@/assets/images/introduce-image/home-slider-03.png"
import AuthorDashboard from '@/assets/images/introduce-image/home-author-slider-01.png'
import AuthorInquiry from '@/assets/images/introduce-image/home-author-slider-02.png'
import AuthorPortfolio from '@/assets/images/introduce-image/home-author-slider-03.png'

const titleStyle = {
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "45px"
}
const descriptionStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "28px"
}
const backItems = ["#E0F7FA", "#FAF4D8", "#E6B6FA"];

export default function HomeSlider() {
    const { isLogin, isPublisher } = useSelectorTyped((state: RootState) => state.user);
    const isAuthor = isLogin && !isPublisher;

    const dataArr = [
        {
            id: 1,
            src: isAuthor ? AuthorDashboard : Dashboard,
            title: '대시보드',
            description: '지난 판매 데이터를 분석하여</br>차별화 된 전략을 세워보세요!',
            titleStyle: Object.assign({color: colors.black, ...titleStyle}),
            descriptionStyle: Object.assign({color: colors.black, ...descriptionStyle}),
            buttonPath: 'dashboard'
        },
        {
            id: 2,
            src: isAuthor ? AuthorInquiry : Inquiry,
            title: '판매 조회',
            description: '지금까지 판매 된 작품 데이터를</br>조회에서 한 번에 확인하세요!',
            titleStyle: Object.assign({color: colors.black, ...titleStyle}),
            descriptionStyle: Object.assign({color: colors.black, ...descriptionStyle}),
            buttonPath: 'settlement/sales-inquiry?type=Daily'
        },
        {
            id: 3,
            src: isAuthor ? AuthorPortfolio : Portfolio,
            title: '포트폴리오',
            description: '작품의 출간부터 프로모션까지</br> 글링에서 쉽게 관리하세요!',
            titleStyle: Object.assign({color: colors.black, ...titleStyle}),
            descriptionStyle: Object.assign({color: colors.black, ...descriptionStyle}),
            buttonPath: 'portfolio/list'
        }
    ];

    return (
        <Slider
            isFull={false}
            height="600"
            rendingData={dataArr}
            backItems={backItems}
        />
    )
}