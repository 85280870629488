import styled from "styled-components";
import Text from "@/components/atoms/text";
import { RootState, useAppSelector } from "@/modules/store";
import { CalendarEventData } from "@/pages/dashboard/publisher/types";
import { colors } from "@/assets/styles/theme";

export default function CalendarDescWrapper () {
    const { selectedDateEvent } = useAppSelector((state: RootState) => state.pubDashboard);

    const titleTextStyle = {
        fontWeight: "700",
        fontSize: "14px",
        padding: "18px 16px"
    }

    const noDescTextStyle = {
        fontSize: "14px",
        fontWeight: "400",
        color: colors.gray600
    }

    let calendarDescContent;
    if (selectedDateEvent.length) {
        calendarDescContent = (
            <StyledUl>
                {selectedDateEvent.map(i => (
                    <EventDescWrapper
                        key={selectedDateEvent.indexOf(i)}
                        data={i}
                    />
                ))}
            </StyledUl>
        )
    } else {
        calendarDescContent = (
            <StyledDiv>
                <Text text="일정이 없습니다" customStyle={noDescTextStyle} />
            </StyledDiv>
        )
    }

    return (
        <StyledCalendarDescWrapper>
            <Text text="캘린더 일정" customStyle={titleTextStyle} />
            {calendarDescContent}
        </StyledCalendarDescWrapper>
    )
}

function EventDescWrapper({ data }: EventDescWrapperProps) {
    let text = "";
    let color = "";
    switch (data.type) {
        case "launching":
            text = `[${data.platform_name}] ${data.content} 런칭일입니다.`;
            color = colors.success;
            break;
        case "promotion":
            text = `[${data.platform_name}] ${data.content} 프로모션 시작일입니다.`;
            color = colors.success;
            break;
        case "MGS":
            if (data.content[0] === "0") {
                text = `${data.content[1]}월 정산서가 발급되었습니다.`;
            } else {
                text = `${data.content}월 정산서가 발급되었습니다.`;
            }
            text = `선인세 명세서가 발급되었습니다. (${data.content}작가)`
            color = colors.information;
            break;
        case "MRS":
            if (data.content[0] === "0") {
                text = `${data.content[1]}월 정산서가 발급되었습니다.`;
            } else {
                text = `${data.content}월 정산서가 발급되었습니다.`;
            }
            color = colors.information;
            break;
        case "notice":
            text = "업데이트 공지사항이 있습니다."
            color = colors.error;
            break;
        default:
            break;
    }

    const textStyle = {
        fontWeight: "400",
        fontSize: "12px",
        padding: "18px 16px"
    }

    return (
        <StyledLi>
            <StyledEventMarker fillColor={color} />
            <Text text={text} customStyle={textStyle} />
        </StyledLi>
    )
}

const StyledCalendarDescWrapper = styled.div`
    height: 220px;
`

const StyledUl = styled.ul`
    max-height: 170px;
    overflow-y: auto;
`

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
`

const StyledLi = styled.li`
    display: flex;
    align-items: center;
    height: 48px;
    padding: 18px 16px;
`

const StyledEventMarker = styled.div<EventMarkerProps>`
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background-color: ${({ fillColor }) => fillColor ? fillColor : "black"};
`

interface EventMarkerProps {
    fillColor?: string;
}

interface EventDescWrapperProps {
    data: CalendarEventData
}