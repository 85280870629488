import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { initialItem, setAmount, setItem, setItems, setLastId, setTotal } from "@/pages/settlement/sales-entry/daily/dailySlice";

// components
import TextInput from "@/components/atoms/input/TextInput";
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import AddLine from "@/components/molecules/sales-entry/common/AddLine";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import SeriesNameField from "@/components/molecules/sales-entry/daily/SeriesNameField";

// custom hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";

// utils
import { amount, integer, text } from "@/modules/utils/validate_modify";
import { addComma, removeComma, removeComma as removeCommaUtil } from "@/modules/utils/filter";

// type
import { Item } from "@/pages/settlement/sales-entry/daily/types";
import useUtils from "@/components/molecules/sales-entry/daily/hooks/useReset";

export default function DailyTable() {
    const { items, lastId, platformId, afterExcelUpload, date } = useSelectorTyped((state: RootState) => state.entryDaily);
    const { platformQty } = useUtils(platformId, date);
    const dispatch = useAppDispatch();
    const { pageCount, totalVisible, setDataCount } = usePagination({ from: "daily" });
    const applyAddLine = (rowAdd: number) => {
        let tmpLastId = lastId;
        let tmpItems = [];
        for (let i = 0; i < rowAdd; i++) {
            const item: Item = { ...initialItem, id: (tmpLastId += 1) };
            tmpItems.push(item);
        }

        const count = items.length + tmpItems.length; 
        setDataCount(count);
        dispatch(setTotal(count));
        dispatch(setItems([...items, ...tmpItems]));
        dispatch(setLastId(tmpLastId));
    };

    // 판매금 합계
    const sumAmount = (amount: number | string) => {
        if (!afterExcelUpload || amount === 0 || amount === "0") return;
        let totalAmount = 0;
        items.forEach((i) => {
            totalAmount += Number(removeCommaUtil(i.amount)) || 0;
        });
        dispatch(setAmount(Number(totalAmount.toFixed(2))));
    };

    const columns = {
        book_name: ({ item, index }: TableItemProps) => {
            const validateBookName = (value: string) => {
                const res = text({ value, error: "bookNameError", mandatory: true, field: "작품명", max: 100 });
                dispatch(setItem({ index, item: { ...item, book_name: res.value, bookNameError: res.bookNameError }}));
            };
            return (
                <ValidateWrapper dataError={item["bookNameError"]}>
                    <TextInput
                        value={item["book_name"]}
                        placeholder="작품명"
                        onChangeHandler={(e) => validateBookName(e.target.value)}
                        customStyle={textInputStyle}
                    />
                </ValidateWrapper>
            );
        },
        series_name: ({ item, index }: TableItemProps) => (
            <SeriesNameField
                item={item}
                idx={index}
                platformId={platformId}
            />
        ),
        author_name: ({ item }: TableItemProps) => {
            return (
                <TextInput
                    type="readOnly"
                    value={item["author_name"]}
                    placeholder="저자명"
                    onChangeHandler={() => {}}
                    customStyle={textInputStyle}
                />
            );
        },
        quantity: ({ item, index }: TableItemProps) => {
            if (!Boolean(platformQty)) {
                return (
                    <TextInput
                        type="readOnly"
                        value="정보없음"
                        placeholder="정보없음"
                        onChangeHandler={()=>{}}
                        customStyle={textInputStyle}
                    />
                );
            }
            const validateQuantity = (value: string) => {
                const res = integer({
                    value,
                    error: "quantityError",
                    field: "판매량",
                    min: -9999999,
                    max: 9999999,
                    mandatory: true,
                });
                dispatch(setItem({ index, item: { ...item, quantity: res.value, quantityError: res.quantityError } }));
            };
            return (
                <ValidateWrapper dataError={item["quantityError"]}>
                    <TextInput
                        value={item["quantity"]}
                        placeholder="판매량"
                        onChangeHandler={(e) => validateQuantity(e.target.value)}
                        customStyle={textInputStyle}
                    />
                </ValidateWrapper>
            );
        },
        amount: ({ item, index }: TableItemProps) => {
            const validateAmount = (value: string) => {
                const res = amount({ value, error: "amountError", field: "판매금액", decimal: true, mandatory: true });
                dispatch(setItem({ index, item: { ...item, amount: res.value, amountError: res.amountError } }));
            };
            const blurInput = () => {
                dispatch(setItem({ index, item: { ...item, amount: addComma(item.amount) }}));
                sumAmount(item.amount);
            };
            const focusInput = () => {
                dispatch(setItem({ index, item: { ...item, amount: removeComma(item.amount) }}));
            }
            
            return (
                <ValidateWrapper dataError={item["amountError"]}>
                    <TextInput
                        value={item["amount"]}
                        placeholder="판매금액(원)"
                        onChangeHandler={(e) => validateAmount(e.target.value)}
                        onFocusHandler={focusInput}
                        onBlurHandler={blurInput}
                        customStyle={textInputStyle}
                    />
                </ValidateWrapper>
            );
        },
    };

    return (
        <>
            <TableWrapper>
                <DataTable
                    from="daily"
                    itemKey="id"
                    items={items}
                    headers={headers}
                    columns={columns}
                />
                <AddLine onApplyAddLine={applyAddLine} />
            </TableWrapper>
            <Pagination pageCount={pageCount} totalVisible={totalVisible} from="daily" />
        </>
    );
}

const headers = [
    { text: "작품명", value: "book_name", width: "300px" },
    { text: "시리즈명", value: "series_name", width: "300px" },
    { text: "저자명", value: "author_name", width: "180px" },
    { text: "판매량", value: "quantity", width: "160px" },
    { text: "판매금액(원)", value: "amount", width: "180px" },
];
const textInputStyle = {
    width: "100%",
    height: "32px",
};

const TableWrapper = styled.div`
    position: relative;
    width: 100%;
`;
