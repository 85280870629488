import { ChangeEvent, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { ReactComponent as Up } from "@/assets/icons/up-blue.svg";
import { ReactComponent as Down } from "@/assets/icons/down-blue.svg";

export default function AddLineModalBody({ rowNum, setRowNum, rowNumError, setRowNumError }: AddLineModalBodyProps) {
    const changeValue = (e: ChangeEvent<HTMLInputElement>) => changeRowNum(e.target.value);

    const clickUpIcon = () => {
        const nextNum = (Number(rowNum) + 1).toString();
        changeRowNum(nextNum);
    };

    const clickDownIcon = () => {
        if (Number(rowNum) - 1 < 0) return;
        const nextNum = (Number(rowNum) - 1).toString();
        changeRowNum(nextNum);
    };

    const changeRowNum = (rowNumValue: string) => {
        let v = rowNumValue.replace(/(^0+)/, "");
        v = v.replace(/[^0-9]/g, "");
        validateRowNum(v);
        setRowNum(v);
    };

    const validateRowNum = (rowNumValue: string) => {
        if (Number(rowNumValue) > 1000) {
            setRowNumError("범위 내 수를 입력하세요(1~1000)");
        } else {
            setRowNumError("");
        }
    };

    return (
        <BodyWrap>
            <FieldRow>
                <Text>
                    추가할 행 수를 입력해주세요 <Sub>(최대 1000)</Sub>
                </Text>
            </FieldRow>
            <FieldRow>
                <RowInputWrapper>
                    <ValidateWrapper dataError={rowNumError}>
                        <InputField type="number" value={rowNum} placeholder="0" onChange={changeValue} />
                    </ValidateWrapper>
                    <UpDownIconWrapper>
                        <UpIcon onClick={clickUpIcon} />
                        <DownIcon onClick={clickDownIcon} />
                    </UpDownIconWrapper>
                </RowInputWrapper>
            </FieldRow>
        </BodyWrap>
    );
}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 89px;
    padding: 0 20px;
`;
const FieldRow = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 8px 0px;
    /* height: 48px; */
`;
const Text = styled.div`
    font-size: 14px;
    font-weight: 400;
    text-align: left;
`;
const Sub = styled.sub`
    color: ${({ theme }) => theme.colors.gray600};
`;
const RowInputWrapper = styled.div`
    display: flex;
    position: relative;
`;
const InputField = styled.input`
    width: 320px;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    ::placeholder {
        color: ${({ theme }) => theme.colors.gray600};
    }
`;
const UpDownIconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
`;
const UpIcon = styled(Up)`
    margin-bottom: 4px;
    cursor: pointer;
`;
const DownIcon = styled(Down)`
    cursor: pointer;
`;

interface AddLineModalBodyProps {
    rowNum: string;
    setRowNum: Dispatch<SetStateAction<string>>;
    rowNumError: string;
    setRowNumError: Dispatch<SetStateAction<string>>;
}
