import { useEffect } from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";

// components
import SelectBox from "@/components/atoms/selectBox/index";
import Button from "@/components/atoms/button";
import Text from "@/components/atoms/text";

// types
import { ExcelUploadSeriesNameInfo } from "@/pages/settlement/basedata-manage/excelUploadManagement/types";

// reducer
import { useAppSelector, useAppDispatch, RootState } from "@/modules/store";
import { deleteExcelUploadData, getExcelUploadData, saveExcelUploadData } from "@/pages/settlement/basedata-manage/excelUploadManagement/exceluploadSlice";
import { resetDataTable } from "@/pages/common/dataTableSlice";

// hooks
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useLoading from "@/modules/hooks/useLoading";

function ExcelUploadOptionButtons() {
    let [totalCnt, selected, updatedRows, platformId, contentName, series_id_list, platformList] = useAppSelector((state: RootState) => [
        state.excelupload.totalCnt,
        state.dataTable.selectedItems as ExcelUploadSeriesNameInfo[],
        state.dataTable.updatedRows as ExcelUploadSeriesNameInfo[],
        state.excelupload.platformId,
        state.excelupload.contentName,
        state.excelupload.series_id_list,
        state.platform.platformList,
    ]);
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const dispatch = useAppDispatch();
    const { itemsPerPage, setItemsPerPage } = usePagination({ from: "excelupload" });

    useEffect(() => {
        return () => {};
    }, [dispatch]);

    async function updateValidate() {
        if (!updatedRows.length) return await alert("warning", "주의", "변경사항이 없습니다.");
        else seriesMatchingUpdate();
    }

    async function seriesMatchingUpdate() {
        try {
            let answer = await alert("confirm", "변경 확인", "변경하시겠습니까?");
            if (answer === "Y") {
                loadingOn();
                let apiParams = {
                    seriesMatchingData: updatedRows
                }
    
                dispatch(saveExcelUploadData(apiParams)).unwrap();
                let platform_name = (platformList.find(p => p.platform_id === Number(platformId)))?.platform_name;
                if (!platform_name) {
                    return await alert("warning", "재검색 오류", "판매플랫폼이 선택되지 않았습니다.");
                }
    
                await dispatch(getExcelUploadData({
                    platform_id: platformId,
                    platform_name: platform_name,
                    name: contentName || null,
                    series_id_list: series_id_list.length ? series_id_list : null,
                })).unwrap();
                dispatch(resetDataTable());
                await alert("success", "변경 완료", "변경이 완료되었습니다.");
            }
        } catch (err:any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    async function deleteData() {
        try {
            if (selected.length === 0) {
                return alert("warning", "주의", "선택된 항목이 없습니다.");
            }
            let answer = await alert("confirm", "삭제 확인", "삭제하시겠습니까?");
            if (answer === "Y") {
                loadingOn();
                let apiParams = {
                    seriesMatchingData: selected
                }
                await dispatch(deleteExcelUploadData(apiParams)).unwrap();

                let platform_name = (platformList.find(p => p.platform_id === platformId))?.platform_name;
                if (!platform_name) {
                    return await alert("warning", "재검색 오류", "판매플랫폼이 선택되지 않았습니다.");
                }

                await dispatch(getExcelUploadData({
                    platform_id: platformId,
                    platform_name: platform_name,
                    name: contentName || null,
                    series_id_list: series_id_list.length ? series_id_list : null,
                })).unwrap();
                dispatch(resetDataTable());
                await alert("success", "삭제 완료", "삭제가 완료되었습니다.");
            }
        } catch (err:any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    function selectItemPerPage(value:any) {
        setItemsPerPage(Number(value.value))
    }

    return (
        <OptionWrap>
            <TotalWrap>
                <Text
                    text="전체"
                    customStyle={{ fontSize: "14px", fontWeight: "500", color: colors.gray600, margin: "0 8px 0 0" }}/>
                <Text
                    text={totalCnt.toLocaleString()+"건"}
                    customStyle={{ fontSize: "14px", fontWeight: "700" }}/>
            </TotalWrap>
            <OptionRight>
                <SelectBox
                    labelKey="name"
                    valueKey="value"
                    selected={itemsPerPage}
                    options={options}
                    onClickValueFunc={selectItemPerPage}
                />
                <Button
                    type="red-outline"
                    label="선택 삭제"
                    customStyle={{ margin: "0 0 0 8px" }}
                    onClickHandler={deleteData}
                />
                <Button
                    label="변경사항 저장"
                    customStyle={{ margin: "0 0 0 8px" }}
                    onClickHandler={updateValidate}
                />
            </OptionRight>
        </OptionWrap>
    );
}

export default ExcelUploadOptionButtons;

const options = [{ name: "10개씩 보기", value: "10" },
                     { name: "30개씩 보기", value: "30" },
                     { name: "50개씩 보기", value: "50" },
                     { name: "100개씩 보기", value: "100" }];

const OptionWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
`;

const TotalWrap = styled.div`
    display: flex;
`

const OptionRight = styled.div`
    display: flex;
    align-items: center;
`;
