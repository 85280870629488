import styled from 'styled-components'
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn';
import Enter from '@/assets/images/introduce-image/02-1.png'
import Check from '@/assets/images/introduce-image/02-2.png'
import BaseData from '@/assets/images/introduce-image/02-3.png'
import TotalData from '@/assets/images/introduce-image/02-4.png'
import RoyaltyStatement from '@/assets/images/introduce-image/02-5.png'

export default function SettlementImageContainer({tab}:{tab:number}) {
    const fadeInitem = useScrollFadeIn('up', 1, 0, 0.1);
    const imgArray = [Enter, Check, BaseData, TotalData, RoyaltyStatement];

    return (
        <StyledWrapper {...fadeInitem}>
            {imgArray.map((imgsrc, idx)=> 
                <SettlementImageWrapper key={idx}>
                    {tab===idx && <img src={imgsrc} alt="settlementImg"/>}
                </SettlementImageWrapper>
            )}
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    margin-left: 212px;
    padding: 60px 0px;
`       

const SettlementImageWrapper = styled.div`

`