import { useEffect, useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import SetCoRatioModalBody from "@/components/molecules/common/modal/bodyModal/SetCoRatioModalBody";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";

export default function SetCoRatioModal({ show, from, close, index, coInfo, applyCoInfoFunc }:SetCoRatioProps) {
    let { alert } = useAlert();
    const [tmpCoInfo, setTmpCoInfo] = useState(coInfo);
    useEffect(()=>{
        setTmpCoInfo(coInfo);
    }, [coInfo]);

    const changeCoInfo = (co_info:any) => {
        setTmpCoInfo(co_info);
    }

    useEffect(() => {
        setTmpCoInfo(coInfo);
    }, [show])

    async function validateCoRatio() {
        let hasError = false;
        let ratioSum = 0;
        let tmp = JSON.parse(JSON.stringify(tmpCoInfo));
        for (let c of tmpCoInfo) {
            let index = tmpCoInfo.indexOf(c);
            // 빈 값 체크
            if (!c.coRatio) {
                tmp[index].coRatioError = "정산 비율을 입력해주세요";
                setTmpCoInfo(tmp);
                hasError = true;
            }
            // 에러 유무 체크
            if (c.ratioError) { 
                hasError = true;
            }
            // 합계 체크
            if (!isNaN(Number(c.coRatio))) {
                ratioSum += Number(c.coRatio);
            }
        }
			
		if (hasError) {
			return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
		} 
		if (ratioSum > 100) {
			return await alert("warning", "주의", "비율의 총합은 100이 넘을수 없습니다.")
		}
        applyCoInfo(tmpCoInfo);
    }

    function applyCoInfo(coInfo:any) {
        if (applyCoInfoFunc) {
            applyCoInfoFunc(coInfo, index);
        }
        close();
    }

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["공저간 비율 설정"]}
                customStyle={[{ width: "360px", height: "316px" }]}
                applyFunc={validateCoRatio}
            >
                <SetCoRatioModalBody
                    coInfo={tmpCoInfo}
                    changeCoInfoFunc={changeCoInfo}
                />
            </BaseModal>
        </>
    );
}

interface SetCoRatioProps extends BaseModalProps {
    index?: number;
    coInfo: any;
    applyCoInfoFunc?: Function;
}