import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { ReactComponent as ClearMark } from "@/assets/icons/clear.svg";
import theme from "@/assets/styles/theme";

import TextInput from "@/components/atoms/input/TextInput";
import Button from "@/components/atoms/button";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import SelectBox from "@/components/atoms/selectBox/index";
import { useEffect, useState } from "react";
import { authorActions } from "@/pages/settlement/basedata-manage/author/authorSlice";
import Text from "@/components/atoms/text";
import ModalTooltip from "@/components/molecules/common/tooltip/ModalTooltip";

import { colors } from "@/assets/styles/theme";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import NoData from "@/components/molecules/basedata-manage//NoData";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetCoAuthorModal from "@/components/molecules/common/modal/SetCoAuthorModal";
import { user, text, ratio, email, UserValidation, newAuthor, Validation } from "@/modules/utils/validate_modify";

export default function ExcelUploadAuthorModalBody({ from }:ExcelUploadAuthorBodyProps) {
    const dispatch = useAppDispatch();
    const [show, setShow] = useState(false);
    const { setDataCount, page, itemsPerPage, pageCount, totalVisible, setItemsPerPage } = usePagination({ from: "ExcelUploadAuthor" });
    let [ authorAddDatas, index, tmpJointArray ] = useAppSelector((state:RootState) => [
        state.author.authorAddDatas,
        state.author.index,
        state.author.tmpJointArray,
    ]);
    useEffect(()=> {
        return() => {
            dispatch(authorActions.resetEnrollAuthor());
        }
    }, [dispatch])

    useEffect(() => {
        setDataCount(authorAddDatas.length)
        setItemsPerPage(5);
    }, [authorAddDatas.length, setDataCount, setItemsPerPage, page, authorAddDatas])

    const changeValue = async(e: React.ChangeEvent<HTMLInputElement>, key: string, idx: number, item: any) => {
        let index = ((page-1) * itemsPerPage) + idx;
        let changeItem:any;
        let value = e.target.value;
        switch (key) {
            case "name":
                dispatch(authorActions.setEnrollDatas({
                    index: index, key: key, value: value
                }));
                const { authorError } = (await newAuthor({ value, error: "authorError", mandatory: true, id: item["id"]})) as Validation;
                dispatch(authorActions.setEnrollDatas({
                    index: index, key: "authorError", value: authorError
                }));
                break;
            case "joint":
                dispatch(authorActions.setEnrollDatas({
                    index: index, key: key, value: value
                }));
                const validateJoint = await user({value: value, error: "emailError", field: "이메일", mandatory: false}) as UserValidation;
                dispatch(authorActions.setEnrollDatas({
                    index: index, key: "emailError", value: validateJoint.emailError
                }));
                dispatch(authorActions.setEnrollDatas({
                    index: index, key: "jointArray", value: [{...item.jointArray[0], "email": value, "emailError": validateJoint.emailError, user_id: validateJoint.user_id ? validateJoint.user_id : null }]
                }));
                // changeItem = {...item, "joint": value, "emailError": validateJoint.emailError, "jointArray": [{...item.jointArray[0], [key]: value, "emailError": validateJoint.emailError, user_id: validateJoint.user_id}] }
                // dispatch(authorActions.setAuthorDatas({
                //     key: "authorAddDatas", index: index, value: changeItem
                // }));
                break;
            case "realName":
                const validateRealName = text({value: value, error: "realNameError", field: "본명", max: 20, mandatory: true}) as Validation;
                changeItem = {...item, [key]: validateRealName.value, "realNameError": validateRealName.realNameError, "jointArray": [{...item.jointArray[0], [key]: validateRealName.value, "realNameError": validateRealName.realNameError }] }
                dispatch(authorActions.setAuthorDatas({
                    key: "authorAddDatas", index: index, value: changeItem
                }));
                break;
            case "taxRatio":
                const validateTaxRatio = ratio({value: value, error: "taxRatioError", field: "적용세율", decimal: true, mandatory: true}) as Validation;
                changeItem = {...item, [key]: validateTaxRatio.value, "taxRatioError": validateTaxRatio.taxRatioError, "jointArray": [{...item.jointArray[0], [key]: validateTaxRatio.value, "taxRatioError": validateTaxRatio.taxRatioError }] }
                dispatch(authorActions.setAuthorDatas({
                    key: "authorAddDatas", index: index, value: changeItem
                }));
                break;
            case "stEmail":
                const validateStEmail = email({value: value, error: "stEmailError", field: "이메일", mandatory: false}) as Validation;
                changeItem = {...item, [key]: validateStEmail.value, "stEmailError": validateStEmail.stEmailError, "jointArray": [{...item.jointArray[0], [key]: validateStEmail.value, "stEmailError": validateStEmail.stEmailError }] }
                dispatch(authorActions.setAuthorDatas({
                    key: "authorAddDatas", index: index, value: changeItem
                }));
                break;
            default:
                break;
        }
        dispatch(authorActions.setAuthorDatas)
    }

    function changeTaxType(value:any, idx: number, item: any) {
        let index = ((page-1) * itemsPerPage) + idx;
        let taxRatio:string = "0"
        switch (value.value) {
            case "D":
                taxRatio = "3.3";
                break;
            case "A":
            case "B":
                taxRatio = "0.00";
                break;
            default:
                break;
        }
        dispatch(authorActions.setAuthorDatas({
            key: "authorAddDatas", index: index,
            value: {...item, "taxType": value.value, "taxTypeError": "", "taxRatio": taxRatio, "taxRatioError": "",
                    "jointArray": [{...item.jointArray[0], "taxType": value.value, "taxTypeError": "", "taxRatio": taxRatio, "taxRatioError": "", }]}
        }));
    }

    async function SetCoAuthor(item:any, idx:number) {
        let index = ((page-1) * itemsPerPage) + idx;
        dispatch(authorActions.setIndex(index));
        dispatch(authorActions.setTmpJointArray(item.jointArray));
        setShow(true);
    }

    async function deleteRow(idx:number) {
        dispatch(authorActions.deleteAddDataRow(idx));
    }

    function closeModal() {
        setShow(false);
    }

    const options = [{name: "개인(국내 거주자)", value: "D"},
                     {name: "개인(국내 비거주자)", value: "A"},
                     {name: "기타사업자", value: "B"}];

    return (
        <>
            <BodyWrap>
                <ListWrap>
                <FieldRow>
                    <ColumnName>
                        <Text text="저자명" customStyle={{ fontSize: "12px" }}/>
                    </ColumnName>
                    <ColumnName width="160px">
                        <Text text="글링 아이디(이메일)" customStyle={{ fontSize: "12px" }}/>
                    </ColumnName>
                    <ColumnName>
                        <Text text="본명" customStyle={{ fontSize: "12px", margin: "0 8px 0 0" }}/>
                        <ModalTooltip
                            id={"excelAuhtor0"}
                            iconWidth={20} 
                            iconHeight={20} 
                            textBoxWidth={240}
                            fill={colors.gray600} 
                            text="정산서에 표시되는 작가의 본명입니다." 
                        />    
                    </ColumnName>
                    <ColumnName width="220px">
                        <Text text="적용세율" customStyle={{ fontSize: "12px", margin: "0 8px 0 0" }}/>
                        <ModalTooltip
                            id={"excelAuhtor1"}
                            iconWidth={20} 
                            iconHeight={20} 
                            textBoxWidth={320}
                            fill={colors.gray600} 
                            text={`정산서 지급 시 적용할 원천징수 세율입니다.\n-국내 거주자 (국내에 주소를 두거나 183일 이상 체류 중인 개인) : 3.3%\n-국내 비거주자 (국내 거주자를 제외한 개인) : 국가별 상이\n-기타 사업자 (개인/법인 사업자) : 0%`}
                        />
                    </ColumnName>
                    <ColumnName width="160px">
                        <Text text="정산서 발급 이메일" customStyle={{ fontSize: "12px", margin: "0 8px 0 0" }}/>
                        <ModalTooltip
                            id={"excelAuhtor2"}
                            iconWidth={20} 
                            iconHeight={20} 
                            textBoxWidth={240}
                            fill={colors.gray600} 
                            text="정산서 메일 전달시 전달 받을 메일 주소입니다."
                        />
                    </ColumnName>
                    <ColumnName width="92px">
                        <Text text="공저 설정" customStyle={{ fontSize: "12px" }}/>
                    </ColumnName>
                    <IconWrap height="48px" disable={true} />
                </FieldRow>
                {
                authorAddDatas.length === 0 ?
                    <NoData />
                :
                authorAddDatas.slice((page - 1) * 5, page * 5).map((data, i) =>
                    <ItemRow key={i}>
                        <Item>
                            <ValidateWrapper dataError={data.authorError}>
                                <TextInput
                                    value={data.name || ""}
                                    placeholder="저자명"
                                    customStyle={{ width: "100px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                    onChangeHandler={(e) => changeValue(e, "name", i, data)}
                                />
                            </ValidateWrapper>
                        </Item>
                        <Item width="160px">
                            <ValidateWrapper dataError={data.emailError}>
                                <TextInput
                                    type={data.jointArray.length > 1 ? "readOnly" : "main"}
                                    value={data.joint || ""}
                                    placeholder={data.jointArray.length > 1 ? "공저별 설정" : "글링 아이디(이메일)"}
                                    customStyle={{ width: "140px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                    onChangeHandler={(e) => changeValue(e, "joint", i, data)}
                                />
                            </ValidateWrapper>
                        </Item>
                        <Item width="100px">
                            <ValidateWrapper dataError={data.realNameError}>
                                <TextInput
                                        type={data.jointArray.length > 1 ? "readOnly" : "main"}
                                        value={data.realName || ""}
                                        placeholder={data.jointArray.length > 1 ? "공저별 설정" : "본명"}
                                        customStyle={{ width: "80px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                        onChangeHandler={(e) => changeValue(e, "realName", i, data)}
                                />
                            </ValidateWrapper>
                        </Item>
                        <Item width="260px">
                            {
                                data.jointArray.length > 1 ?
                                (
                                    <SelectBox
                                        disabled={true}
                                        labelKey="name"
                                        valueKey="value"
                                        options={[{ name: "공저별 설정", value: "공저별 설정" }]}
                                        selected={"공저별 설정"}
                                        onClickValueFunc={()=>{}}
                                        customStyle={{ height: "32px", width: "140px", fontSize: "12px", fontWeight: "400" }}
                                    />
                                )
                                :
                                <>
                                    <ValidateWrapper dataError={data.taxTypeError}>
                                        <SelectBox
                                            labelKey="name"
                                            valueKey="value"
                                            selected={data.taxType}
                                            onClickValueFunc={(v:any) => changeTaxType(v, i, data)}
                                            // onChange={(e) => changeTaxType(e, i, data)}
                                            options={options}
                                            customStyle={{ width: "140px", height: "32px" }} />
                                    </ValidateWrapper>
                                    <ValidateWrapper dataError={data.taxRatioError}>
                                        <PercentInputText 
                                            type="number"
                                            inText="%"
                                            value={data.taxRatio || ""}
                                            customStyle={{width: "100px", height: "32px", padding: "0 18px 2px 0", fontSize: "12px", fontWeight: "400"}}
                                            disabled={String(data.taxType) !== "A" ? true : false}
                                            onChange={(e) => changeValue(e, "taxRatio", i, data)}
                                        />
                                    </ValidateWrapper>
                                </>
                            }
                        </Item>
                        <Item width="160px">
                            <ValidateWrapper dataError={data.stEmailError}>
                                <TextInput
                                    type={data.jointArray.length > 1 ? "readOnly" : "main"}
                                    value={data.stEmail || ""}
                                    placeholder={data.jointArray.length > 1 ? "공저별 설정" : "정산서 발급 이메일"}
                                    customStyle={{ width: "140px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                    onChangeHandler={(e) => changeValue(e, "stEmail", i, data)}
                                />
                            </ValidateWrapper>
                        </Item>
                        <Item width="72px" justifyContent="center" >
                            <ValidateWrapper dataError={data.jointError}>
                                <Button
                                    type="main-outline"
                                    label="설정"
                                    customStyle={{ width: "60px", height: "32px" }}
                                    onClickHandler={(e) => SetCoAuthor(data, i)}
                                />
                            </ValidateWrapper>
                        </Item>
                        { authorAddDatas.length === 1 ?
                        <IconWrap disable={true} />
                        :
                        <IconWrap onClick={() => deleteRow(i)}>
                            <ClearMark width="24px" height="24px" fill={theme.colors.gray600} />
                        </IconWrap>}
                    </ItemRow>
                    )
                }
                </ListWrap>
                {
                    authorAddDatas.length > 4 &&
                    <Pagination pageCount={pageCount} totalVisible={totalVisible} customStyle={{ margin: "0" }} from="ExcelUploadAuthor" />
                }
            </BodyWrap>
            <ModalPortal>
                <SetCoAuthorModal
                    show={show}
                    from="author-enroll"
                    close={closeModal}
                    index={index as number}
                />
            </ModalPortal>
        </>
    )

}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 0 25px;
    height: 388px;
    // max-height: 298px;
`

const ListWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 348px;
    min-height: 348px;
    width: 100%;
`

const ItemRow = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`

const Item = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "120px"};
    height: 60px;
    font-size: 12px;
    padding: 8px;
`

const IconWrap = styled.div<{ direction?: string, disable?: boolean, height?: string }>`
    display: flex;
    flex-direction: ${({ direction }) => direction ? direction : "row"};
    align-items: center;
    justify-content: center;
    width: 48px;
    height: ${({ height }) => height ? height : "60px"};
    font-size: 12px;
    padding: 8px;
    cursor: ${({ disable }) => disable ? "default" : "pointer"};
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
`

const ColumnName = styled.div<{ width?: string }>`
    display: flex;
    align-items: center;
    width: ${({ width }) => width ? width : "120px"};
    height: 48px;
    font-size: 12px;
    padding: 8px;
`

interface ExcelUploadAuthorBodyProps {
    from?: string;
}