import { useAppDispatch, RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { signupActions, checkId } from "@/pages/auth/signUp/signupSlice";
import { ChangeEvent, useState } from "react";
import Button from "@/components/atoms/button";
import IdWrap from "@/components/molecules/signup/IdWrap";
import PwWrap from "@/components/molecules/signup/PwWrap";
import useAlert from "@/modules/hooks/useAlert";

export default function SignUpForm() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const [user] = useSelectorTyped((state: RootState) => [state.signup.data, state.signup.step]);
    const [userData, setUserData] = useState({ id: user.id.substring(0, user.id.indexOf("@")), pw: user.pw, checkPw: user.checkPw });
    const [idError, setIdError] = useState("");
    const [pwError, setPwError] = useState("");
    const [checkPwError, setCheckPwError] = useState("");
    const [email, setEmail] = useState(user.id.substring(user.id.indexOf("@")+1) || "naver.com");

    async function changeHandlerID(e: ChangeEvent<HTMLInputElement>) {
        try {
            let value = e.target.value;
            let val = value + "@" + email;
            setUserData({...userData, id: value});
            if (/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/i.test(val)) {
                const checkYN = (await dispatch(checkId(val)).unwrap()).status;
                if (checkYN === "success") {
                    setIdError("중복된 아이디입니다.");
                } else {
                    setIdError("");
                }
            } else {
                setIdError("이메일 형식을 확인해주세요.");
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }
    function changeHandlerPW(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        setUserData({...userData, pw: value});
        if (value.length > 7 && value.length < 21 && /^.*(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(value)) {
            setPwError("");
        } else {
            setPwError("영문 / 숫자 포함 8~20자 조합으로 입력해주세요.");
        }
        if (!userData.checkPw || value === userData.checkPw) {
            setCheckPwError("");
        } else {
            setCheckPwError("비밀번호가 같지 않습니다");
        }
    }
    function changeHandlerCheckPW(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        setUserData({...userData, checkPw: value});
        if (value === userData.pw) {
            setCheckPwError("");
        } else {
            setCheckPwError("비밀번호가 같지 않습니다");
        }
    }

    async function changeEmail(changeE:string) {
        try {
            setEmail(changeE);
            if (!userData.id) {
                setIdError("이메일 주소를 정확히 입력해주세요.");
            } else {
                let value = userData.id;
                let val = value + "@" + changeE;
                if (/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/i.test(val)) {
                    const checkYN = (await dispatch(checkId(val)).unwrap()).status;
                    if (checkYN === "success") {
                        setIdError("중복된 아이디입니다.");
                    } else {
                        setIdError("");
                    }
                } else {
                    setIdError("이메일 형식을 확인해주세요.");
                }
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    const keyPressHandler = (key: any) => {
        if (key === "Enter") {
            next();
        }
    };

    async function next() {
        let hasError = false;
        if (!userData.id) {
            setIdError("이메일 주소를 입력해주세요.");
            hasError = true;
        }
        if (!userData.pw) {
            setPwError("비밀번호를 입력해주세요.");
            hasError = true;
        }
        if (!userData.checkPw) {
            setCheckPwError("비밀번호를 재입력해주세요.");
            hasError = true;
        }
        if (idError || pwError || checkPwError || hasError) {
            return await alert("warning", "주의", "유효하지 않은 항목이 있습니다."); 
        }
        dispatch(signupActions.updateUser({ id: userData.id+"@"+email, pw: userData.pw, checkPw: userData.checkPw }));
        dispatch(signupActions.updateMailing(userData.id+"@"+email))
        dispatch(signupActions.changeStep(1));
    }

    return (
        <>
            <IdWrap
                value={userData.id}
                email={email}
                errorMsg={idError}
                onChange={changeHandlerID}
                onChangeEmail={changeEmail}
                onKeyPress={keyPressHandler}
            />
            <PwWrap
                labelName="비밀번호"
                inputPlaceholder="8~20자리 영문 대·소문자, 숫자, 특수문자 조합"
                inputID="password"
                value={userData.pw}
                errorMsg={pwError}
                onChange={changeHandlerPW}
                onKeyPress={keyPressHandler}
            />
            <PwWrap
                labelName="비밀번호 확인"
                inputPlaceholder="비밀번호를 재입력해주세요"
                inputID="password"
                value={userData.checkPw}
                errorMsg={checkPwError}
                onChange={changeHandlerCheckPW}
                onKeyPress={keyPressHandler}
            />
            <Button
                type="main"
                label="다음"
                customStyle={{ width: "400px",
                                height: "60px",
                                margin: "24px 0 0 0",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={next}
            />
        </>
    );
}