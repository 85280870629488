import styled from 'styled-components'
import FreeTextContainer from '@/components/molecules/introduce/startCard/FreeTextContainer'
import { ReactComponent as Ticket} from '@/assets/icons/ticket.svg'

export default function FreeContainer() {

   return (
       <StyledContainer>
           <FlexBox>
                <FreeTextContainer/>
                <TicketWrapper>
                    <Ticket/>
                </TicketWrapper>
           </FlexBox>
       </StyledContainer>
   ) 
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px calc((100% - 1200px) / 2);
    height: 100%;
    background-color: ${({ theme }) => theme.colors.blue50};
`

const FlexBox = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
`

const TicketWrapper = styled.div`
`;