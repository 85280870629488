import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { WithdrawItems, totalSalesState, WithdrawSumData, Key } from "./types"
import api from "@/api";
import moment from "moment";

const initialState: totalSalesState = {
    loading: false,
    message: "",

    yearmon: moment().format("YYYYMM"),
    selectedPlatform: [],
    inputAuthor: "",
    depositItems: [],
    depositSumData: {
        etc: 0,
        mg_add: 0,
        mg_deduction: 0,
        sales: 0,
        st_sales: 0,
        total: 0,
    },
    withdrawItems: [],
    filterWithdrawItems: [],
    withdrawTotalItemCnt: 0,
    withdrawSumData: {
        amount_pu: 0,
        mg_deduction: 0,
        amount_au: 0,
        mg_add: 0,
        etc: 0,
        tax: 0,
        total: 0,
    },
    filterWithdrawSumData: {
        amount_pu: 0,
        mg_deduction: 0,
        amount_au: 0,
        mg_add: 0,
        etc: 0,
        tax: 0,
        total: 0,
    },
    onlyUser: false,
    searchUser: "",
    platformList: [],
    depositInitData: [],
    withdrawInitData: [],
}

// 플랫폼 리스트 가져오기
export const getPlatformList = createAsyncThunk("totalSales/getPlatformList", async (thunkAPI, { rejectWithValue }) => {
    try {

        const res = await api.post(`/api/settlement/common/platformSettings/publisher/select`);

        return res.data;

    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 입금 데이터 가져오기
export const depositInitData = createAsyncThunk("totalSales/depositInitData", async (apiParams: { totalDate: string, selectedPlatform: number[]}, { rejectWithValue }) => {
    try {
        // let apiParams = {
        //     totalDate: moment().format("YYYYMM"),
        //     selectedPlatform: platformList.map(p => p.platform_id)
        // }
        const res = await api.post(`/api/settlement/totalSales/deposit/search`, apiParams);
        
        return res.data;

    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 출금 데이터 가져오기
export const withdrawInitData = createAsyncThunk("totalSales/withdrawInitData", async (apiParams: { totalDate: string, selectedPlatform: number[]}, { rejectWithValue }) => {
    try {
        // let apiParams = {
        //     totalDate: moment().format("YYYYMM"),
        //     selectedPlatform: platformList.map(p => p.platform_id)
        // }
        const res = await api.post(`/api/settlement/newTotalSales/withdraw/search`, apiParams);

        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const totalSalesSlice = createSlice({
    name: "totalSales",
    initialState,
    reducers: {
        reset: () => {
            return {...initialState}
        },
        setTotalSalesDatas: (state, action: PayloadAction<Key>) => {
            let key = action.payload.key;
			let objectKey = action.payload.objectKey;
			let value = action.payload.value;
            if (objectKey) {
                state[key][objectKey] = value;
            } else {
                state[key] = value;
            }
        },
        selectedPlatform: (state, action: PayloadAction<number[]>) => {
            state.selectedPlatform = action.payload;
        },
        setOnlyUser: (state, action: PayloadAction<boolean>) => {
            state.onlyUser = action.payload;
        },
        setWithdrawData: (state, action: PayloadAction<{ sumData: WithdrawSumData, cnt: number, data: WithdrawItems[]}>) => {
            state.filterWithdrawItems = action.payload.data;
            state.filterWithdrawSumData = action.payload.sumData;
            state.withdrawTotalItemCnt = action.payload.cnt;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPlatformList.pending, (state, action) => {
			state.loading = true;
			state.message = "";
		});
		builder.addCase(getPlatformList.fulfilled, (state: any, action) => {
            if (action.payload.status === "success") {
				state.platformList = action.payload.data;
                state.selectedPlatform = action.payload.data;
				state.message = "";
			} else {
				state.message= "판매플랫폼을 불러오는 도중, 오류가 발생하였습니다.";
			}
			state.loading = false;
		})
		builder.addCase(getPlatformList.rejected, (state, action) => {
			state.loading = false;
			state.message= "판매플랫폼을 불러오는 도중, 오류가 발생하였습니다.";
		});

        builder.addCase(depositInitData.pending, (state, action) => {
			state.loading = true;
			state.message = "";
		});
		builder.addCase(depositInitData.fulfilled, (state: any, action) => {
            if (action.payload.status === "success") {
				state.depositItems = action.payload.data.sales;
                state.depositSumData = action.payload.data.sumData;
				state.hasError = false;
			} else {
				state.hasError = true;
			}
			state.loading = false;
		})
		builder.addCase(depositInitData.rejected, (state, action) => {
			state.loading = false;
			state.message= "입금데이터를 불러오는 도중, 오류가 발생하였습니다.";
		});
        builder.addCase(withdrawInitData.pending, (state, action) => {
			state.loading = true;
			state.message = "";
		});
		builder.addCase(withdrawInitData.fulfilled, (state: any, action) => {
            if (action.payload.status === "success") {
				state.withdrawItems = action.payload.data.sales;
                state.withdrawSumData = action.payload.data.sumData;
                state.withdrawTotalItemCnt = action.payload.data.sales.length;
				state.hasError = false;
			} else {
				state.hasError = true;
			}
			state.loading = false;
		})
		builder.addCase(withdrawInitData.rejected, (state, action) => {
			state.loading = false;
			state.message= "출금데이터를 불러오는 도중, 오류가 발생하였습니다.";
		});
    }
});

export const totalSalesActions = totalSalesSlice.actions;

export default totalSalesSlice.reducer;