import api from "@/api";
import { hangulParticle } from "./filter";

let newAuthorDebounce:any;
let userDebounce:any;

export const newAuthor = async(key:string, error:string, value:string, id:number, mandatory:boolean) => {
    let errorInfo = {
        [error]: "",
        value: value,
    };
    return new Promise((resolve) => {
        if (!errorInfo.value && mandatory) {
            if (newAuthorDebounce) clearTimeout(newAuthorDebounce);
            errorInfo[error] = "저자명을 입력해주세요.";
            return resolve(errorInfo);
        }
    
        if (newAuthorDebounce) clearTimeout(newAuthorDebounce);
    
         return newAuthorDebounce = setTimeout(async () => {
            const apiParams = {
                name: errorInfo.value
            }
    
            const { data } = await api.post('/api/settlement/common/validate/author/select', apiParams);
            if (data.status === "fail") {
                // return alert("error", "오류 발생", res.message);
            }
    
            if (data.data.length && id !== data.data[0].id) {
                errorInfo[error] = "이미 등록된 저자명입니다.";
                return resolve(errorInfo);
            } else {
                errorInfo[error] = "";
                return resolve(errorInfo);
            }
        }, 300);
    })
};

export const user = async(field:string, error:string, value:string, mandatory:boolean) => {
    let errorInfo = {
        [error]: "",
        value: value,
        user_id: "",
    };
    return new Promise((resolve) => {
        const exp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
        if (!value && mandatory) {
            errorInfo[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
            return resolve(errorInfo);
        } else if (!value && !mandatory) {
            errorInfo[error] = "";   
            return resolve(errorInfo);
        } else if (exp.test(value) === false ){           
            errorInfo[error] = "올바른 이메일 형식이 아닙니다.";   
            return resolve(errorInfo);
        } else {
            if (userDebounce) clearTimeout(userDebounce);
                    
            userDebounce = setTimeout(async () => {
                const apiParams = {
                    user_email: value
                }

                const { data } = await api.post('/api/settlement/baseData/validate/email/select', apiParams);
                if (data.data.status === "fail") {
                    // return alert("error", "오류 발생", res.message);
                }

                if(data.data.length !== 0) {        
                    errorInfo[error] = "";
                    errorInfo["user_id"] = data.data[0].user_id;
                    return resolve(errorInfo);
                } else {
                    errorInfo[error] = "글링 아이디가 등록되어 있지 않습니다.";    
                    return resolve(errorInfo);           
                }
            }, 500);
        };
    })
};