import styled from "styled-components"
import { ReactComponent as Search } from "@/assets/icons/search.svg";
import { ReactComponent as HomeSearch } from "@/assets/icons/home-search.svg";
import { colors } from "@/assets/styles/theme";

export default function QuestionSearchBar() {

    const moveQuestionPage = () => {
        window.location.href="/support/question"
    }

    return (
        <SearchBarWrapper>
            <SearchBar
                placeholder="검색어를 입력해주세요."
                onClick={moveQuestionPage}
            />
            <HomeSearchIconWrapper>
                <HomeSearch/>
            </HomeSearchIconWrapper>
            <SearchIconWrapper>
                <Search fill={colors.black}/>
            </SearchIconWrapper>
        </SearchBarWrapper>
    )
}

const SearchBarWrapper = styled.div`
    margin-top: 10px;
    position: relative;
    width: 100%;  

    ::before {
        background: url(search.svg);
    }
`;

const SearchBar = styled.input`
    display: flex;
    align-items: center;
    width: 100%;
    border: 2px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    padding: 16px;
`;

const HomeSearchIconWrapper = styled.div`
    position: absolute;
    left: 25%;
    transform: translateY(-140%);
`

const SearchIconWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
`