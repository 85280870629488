import { ReactElement } from "react";
import styled from "styled-components";

export default function TableContainer({ children, width }: TableContainerProps) {
    return <StyledContainer width={width}>{children}</StyledContainer>;
}

const StyledContainer = styled.div<{ width: string | undefined }>`
    position: relative;
    width: ${({ width }) => (width ? width : "100%")};
`;

interface TableContainerProps {
    children: ReactElement | ReactElement[];
    width?: string;
}
