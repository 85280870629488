import { useEffect } from "react";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getAuthorMonthlyData, setNoData } from "@/pages/settlement/saels-inquiry/author/monthly/monthlySlice";

// components
import TemplateLayout from "@/components/molecules/sales-inquiry/common/TemplateLayout";
import MonthlySearchFilter from "@/components/molecules/sales-inquiry/author/monthly/MonthlySearchFilter";
import MonthlyTableTop from "@/components/molecules/sales-inquiry/author/monthly/MonthlyTableTop";
import MonthlyTable from "@/components/molecules/sales-inquiry/author/monthly/MonthlyTable";

// custom hooks
import useMonthlyApi from "./hooks/useMonthly";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function Monthly() {
    const { selectedPlatform, selectedPublisher, selectedAuthor, selectedSeries, changedParamsId, initialDataLoaded } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorMonthly,
    );
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    useMonthlyApi();

    useEffect(() => {
        getMonthlyData();
    }, [changedParamsId]);

    const getMonthlyData = async () => {
        try {
            if (initialDataLoaded &&
                (!selectedPlatform.length || !selectedPublisher.length || !selectedAuthor.length || !selectedSeries.length)) 
            {
                return dispatch(setNoData());
            }

            loadingOn();
            await dispatch(getAuthorMonthlyData()).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    return (
        <TemplateLayout>
            <MonthlySearchFilter />
            <MonthlyTableTop />
            <MonthlyTable />
        </TemplateLayout>
    );
}
