import { useState, ChangeEvent, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { isArray } from "lodash";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import Checkbox from "@/components/atoms/input/Checkbox";
import SelectBox from "@/components/atoms/selectBox/index";
import AuthorSearch from "../../searchBar/authorSearch/AuthorSearch";
import DateSelector from "@/components/molecules/common/form/DateSelector";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import ModalSearchBar from "@/components/molecules/common/searchBar/modalSearchBar/ModalSearchBar";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";
import { CheckboxState, Data } from "../BatchDeleteMonthlyModal";
import { SearchSeriesResult } from "@/types/searchSeries";

export default function BatchDeleteMonthlyModalBody({ data, setData, checked, setChecked }: ModalBodyProps) {
    const platformList = useSelectorTyped((state: RootState) => state.platform.platformList);
    const [showModal, setShowModal] = useState(false);
    const [seriesName, setSeriesName] = useState("");
    const [authorName, setAuthorName] = useState("");

    const selectCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target.value as Partial<keyof CheckboxState>;

        if (target === "author") {
            setChecked((prev) => ({ ...prev, series: false, [target]: !prev[target] }));
            setSeriesName("");
        } else if (target === "series") {
            setChecked((prev) => ({ ...prev, author: false, [target]: !prev[target] }));
            setAuthorName("");
        } else {
            setChecked((prev) => ({ ...prev, [target]: !prev[target] }));
        }
    };

    const changeSalesMonth = (date: string) => setData((prev) => ({ ...prev, salesMonth: date }));

    const changeStMonth = (date: string) => setData((prev) => ({ ...prev, stMonth: date }));

    const changePlatform = (value: any) => setData((prev) => ({ ...prev, platformId: value.platform_id }));

    const handleSeriesModal = () => setShowModal((prev) => !prev);

    const applySeries = ({ selectedSeries }: SearchSeriesResult) => {
        if (isArray(selectedSeries)) return;
        setSeriesName(selectedSeries.series_name);
        setData((prev) => ({ ...prev, seriesId: selectedSeries.id }));
    };

    const selectAuthor = (idx: number, authorInfo: any) => {
        setAuthorName(authorInfo.author_name);
        setData((prev) => ({ ...prev, authorId: authorInfo.author_id }));
    };

    const deleteAuthor = () => {
        setAuthorName("");
        setData((prev) => ({ ...prev, authorId: 0 }));
    };

    return (
        <>
            <BodyWrap>
                {/* 정산월 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="stMonth"
                            active={checked.stMonth}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>정산월</LabelWrapper>
                    <ContentWrapper>
                        <DateSelector onChangeHandler={changeStMonth} disabled={!checked.stMonth} />
                    </ContentWrapper>
                </ItemRow>
                {/* 판매월 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="salesMonth"
                            active={checked.salesMonth}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>판매월</LabelWrapper>
                    <ContentWrapper>
                        <DateSelector onChangeHandler={changeSalesMonth} disabled={!checked.salesMonth} />
                    </ContentWrapper>
                </ItemRow>
                {/* 플랫폼 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="platform"
                            active={checked.platform}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>플랫폼</LabelWrapper>
                    <ContentWrapper>
                        <SelectBox
                            disabled={!checked.platform}
                            empty={true}
                            labelKey="platform_name"
                            valueKey="platform_id"
                            selected={data.platformId || 0}
                            options={platformList}
                            onClickValueFunc={changePlatform}
                            customStyle={{ fontSize: "12px" }}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 시리즈명 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="series"
                            active={checked.series}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>시리즈명</LabelWrapper>
                    <ContentWrapper>
                        <ModalSearchBar
                            type="disabledInput"
                            placeholder="시리즈명"
                            disabled={!checked.series}
                            value={seriesName}
                            onHandleModal={handleSeriesModal}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 저자명 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="author"
                            active={checked.author}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>저자명</LabelWrapper>
                    <ContentWrapper>
                        <AuthorSearch
                            from=""
                            idx={0}
                            placeholder="저자명"
                            value={authorName}
                            author={authorName}
                            disabled={!checked.author}
                            selectAuthorFunc={selectAuthor}
                            deleteAuthorFunc={deleteAuthor}
                            applyCoInfoFunc={() => {}}
                            customStyle={{ width: "180px" }}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 안내문구 */}
                <GuideText>*시리즈명과 저자명은 둘 중 하나만 선택 가능합니다</GuideText>
            </BodyWrap>
            {/* 시리즈 선택 모달 */}
            <ModalPortal>
                <SearchSeriesModal
                    from=""
                    show={showModal}
                    close={handleSeriesModal}
                    platformId={data.platformId}
                    applySeries={applySeries}
                    withRatio={false}
                    multiSelect={false}
                />
            </ModalPortal>
        </>
    );
}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    height: 358px;
    max-height: 358px;
    overflow: auto;
`;

const ItemRow = styled.div<{ border?: boolean; active?: boolean }>`
    display: flex;
    align-items: center;
    height: 60px;
    border-top: ${({ theme, border }) => (border ? `1px solid ${theme.colors.gray300}` : "")};
    border-bottom: ${({ theme, border }) => (border ? `1px solid ${theme.colors.gray300}` : "")};
    background-color: ${({ theme, active }) => (active ? theme.colors.gray50 : "")};
`;

const BaseWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    width: 60px;
    height: 60px;
`;
const CheckboxWrapper = styled(BaseWrapper)`
    justify-content: center;
`;

const LabelWrapper = styled(BaseWrapper)`
    align-items: center;
    margin-right: 8px;
`;

const ContentWrapper = styled(BaseWrapper)`
    width: 320px;
    margin-left: 8px;

    .series-searchBar {
        width: auto;
        input {
            width: 180px;
            padding: 8px 16px 8px 8px;
        }
    }
    .search-field {
        width: 180px;
    }
`;

const GuideText = styled(BaseWrapper)`
    justify-content: center;
    width: 100%;
    height: 32px;
    margin-top: 4px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray600};
`;

interface ModalBodyProps {
    data: Data;
    setData: Dispatch<SetStateAction<Data>>;
    checked: CheckboxState;
    setChecked: Dispatch<SetStateAction<CheckboxState>>;
}
