import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";

export default function DraggableTable({ list }: ComponentProps) {
    const dispatch = useAppDispatch();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragEnd = ({ active, over }: any) => {
        if (!over) {
            return;
        }

        if (active.id !== over.id) {
            const activeIndex = active.data.current.sortable.index;
            const overIndex = over.data.current?.sortable.index || 0;
            dispatch({ type: "ebookMaker/sortInFile", payload: { from: activeIndex, to: overIndex } });
        }
    };

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
        >
            <SortableContext items={list} strategy={rectSortingStrategy}>
                {list.map((item, idx) => (
                    <SortableItem key={item.id} item={item} idx={idx}/>
                ))}
            </SortableContext>
        </DndContext>
    )
}

interface ComponentProps {
    list: any[]; 
}