import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import styled from "styled-components";
import theme from "@/assets/styles/theme";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import Text from "@/components/atoms/text";

function SearchInput({
    inputType,
    type,
    value,
    onChangeHandler,
    onClickHandler,
    clickIconHandler,
    onKeyDownHandler,
    onFocusHandler,
    onBlurHandler,
    placeholder,
    customStyle,
    tooltipText,
}: TextInputProps) {
    useEffect(() => {

    }, [tooltipText])
    const [tooltip, setTooltip] = useState(false);
    const onMouseEnter = () => {
        setTooltip(true);
    }

    const onMouseLeave = () => {
        setTooltip(false);
    }

    switch (type) {
        case "readOnly":
            return (
                <StyledSearchBarWrapper width={customStyle?.width}>
                    <ReadOnlyInput
                        readOnly={true}
                        type={inputType ? inputType : "text"}
                        value={value}
                        onChange={onChangeHandler}
                        onClick={onClickHandler}
                        onKeyDown={onKeyDownHandler}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                        placeholder={placeholder}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        padding={customStyle?.padding}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderColor={customStyle?.borderColor}
                        borderRadius={customStyle?.borderRadius}
                        color={customStyle?.color}
                        textAlign={customStyle?.textAlign}
                        margin={customStyle?.margin}
                    />
                    <IconWrapper>
                        <SearchIcon size={24} fill={theme.colors.black} onClick={onClickHandler} />
                    </IconWrapper>
                </StyledSearchBarWrapper>
            )
        case "readOnlyTooltip":
            return (
                <StyledSearchBarWrapper width={customStyle?.width} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <ReadOnlyInput
                        readOnly={true}
                        type={inputType ? inputType : "text"}
                        value={value}
                        onChange={onChangeHandler}
                        onClick={onClickHandler}
                        onKeyDown={onKeyDownHandler}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                        placeholder={placeholder}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        padding={customStyle?.padding}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderColor={customStyle?.borderColor}
                        borderRadius={customStyle?.borderRadius}
                        color={customStyle?.color}
                        textAlign={customStyle?.textAlign}
                        margin={customStyle?.margin}
                    />
                    <IconWrapper>
                        <SearchIcon size={24} fill={theme.colors.black} onClick={onClickHandler} />
                    </IconWrapper>
                    {
                        (tooltip && tooltipText) ? 
                        <TooltipText>
                            <Text 
                                text={tooltipText} 
                                customStyle={{lineHeight: "21px", fontSize: "12px", fontWeight: "400",}}
                            />
                        </TooltipText>
                        :
                        <></>
                    }
                </StyledSearchBarWrapper>
            )
        case "blueIcon":
            return (
                <StyledSearchBarWrapper width={customStyle?.width}>
                    <BaseInput
                        type={inputType ? inputType : "text"}
                        value={value}
                        onChange={onChangeHandler}
                        onClick={onClickHandler}
                        onKeyDown={onKeyDownHandler}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                        placeholder={placeholder}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        padding={customStyle?.padding}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderColor={customStyle?.borderColor}
                        borderRadius={customStyle?.borderRadius}
                        color={customStyle?.color}
                        textAlign={customStyle?.textAlign}
                        margin={customStyle?.margin}
                    />
                    <IconWrapper>
                        <SearchIcon size={24} fill={theme.colors.blue500} onClick={clickIconHandler} />
                    </IconWrapper>
                </StyledSearchBarWrapper>
            )
        default:
            return (
                <StyledSearchBarWrapper width={customStyle?.width}>
                    <BaseInput
                        type={inputType ? inputType : "text"}
                        value={value}
                        onChange={onChangeHandler}
                        onClick={onClickHandler}
                        onKeyDown={onKeyDownHandler}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                        placeholder={placeholder}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        padding={customStyle?.padding}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderColor={customStyle?.borderColor}
                        borderRadius={customStyle?.borderRadius}
                        color={customStyle?.color}
                        textAlign={customStyle?.textAlign}
                        margin={customStyle?.margin}
                    />
                    <IconWrapper>
                        <SearchIcon size={24} fill={theme.colors.black} onClick={clickIconHandler} />
                    </IconWrapper>
                </StyledSearchBarWrapper>
            )
    }
}

export default SearchInput;

const BaseInput = styled.input<StyleProps>`
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "32px")};
    padding: ${({ padding }) => (padding ? padding : "10px")};
    margin: ${({ margin }) => (margin ? margin : "")};
    background-color: ${({ theme }) => theme.colors.white};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
    border: ${({ theme, borderColor }) => `1px solid ${borderColor ? borderColor : theme.colors.gray300}`};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "5px")};
    color: ${({ color, theme }) => (color ? color : theme.colors.black)};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
`;

const ReadOnlyInput = styled(BaseInput)`
    background-color: ${({ theme }) => theme.colors.gray50};
    cursor: default;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const StyledSearchBarWrapper = styled.div<{ width?: string }>`
    display: inline-flex;
    align-items: center;
    position: relative;
    width: ${({ width }) => (width ? width : "120px")};
`;
const IconWrapper = styled.div`
    position: absolute;
    right: 8px;
    cursor: pointer;
`;

const TooltipText = styled.div`
    position: absolute;
    width: 400px;
    max-height: 198px;
    padding: 3px 17px;
    z-index: 99;
    background-color: ${({ theme }) => theme.colors.blue50};
    top: 32px;
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    border-radius: 4px;
    overflow-y: auto;
    white-space: break-spaces;
`

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    borderColor?: string;
    borderRadius?: string;
    color?: string;
    textAlign?: string;
}

interface TextInputProps {
    type?: "main" | "readOnly" | "blueIcon" | "readOnlyTooltip";
    inputType?: string;
    value: number | string;
    placeholder?: string;
    disabled?: boolean;
    onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
    onClickHandler?: () => void;
    clickIconHandler?: () => void;
    onKeyDownHandler?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onFocusHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlurHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
    customStyle?: StyleProps;

    tooltipText?: string;
}
