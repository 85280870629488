import styled from "styled-components";
import Text from "@/components/atoms/text";
import DailySeriesAnalysisChartWrapper from "@/components/molecules/dashboard/publisher/DailySeriesAnalysisChartWrapper";

const DailyContentAnalysisChart = () => {
    return (
        <StyledChart>
            <TopLineWrapper />
            <DailySeriesAnalysisChartWrapper />
        </StyledChart>
    )
}

const StyledChart = styled.div`
    width: 360px;
    height: 350px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

function TopLineWrapper() {
    const textStyle = {
        fontSize: "16px",
        fontWeight: "700"
    }

    return (
        <StyledTopLineWrapper>
            <Text text="일일 작품 분석" customStyle={textStyle}/>
        </StyledTopLineWrapper>
    )
} 

const StyledTopLineWrapper = styled.div`
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
`

export default DailyContentAnalysisChart;