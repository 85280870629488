import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as NaverIcon } from "@/assets/icons/login/ic-naver.svg";
import { ReactComponent as KakaoIcon } from "@/assets/icons/login/ic-kakao.svg";
import { ReactComponent as GoogleIcon } from "@/assets/icons/login/ic-google.svg";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import { userActions } from "@/pages/common/userSlice";
import useAlert from "@/modules/hooks/useAlert";
import { useNavigate } from "react-router-dom";

declare global {
    interface Window {
        login?: Function;
        customAlert?: Function;
        confirmAlert?: Function;
    }
}

function SNSLoginContainer() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { alert } = useAlert();

    window.login = function(userData: any) {
        dispatch(userActions.signIn());
        dispatch(userActions.setUserInfo(userData));
        navigate("/");
    }
    window.customAlert = function(data: any) {
        return alert("error", data.title, data.message);
    }

    window.confirmAlert = async function(data: any) {
        const answer = await alert("confirm", data.title, data.message);

        if (answer === "Y") {
            dispatch({ type: "signup/setSocialData", payload: {
                provider: data.social_provider,
                id: data.social_id,
                email: data.email   
            }});
            navigate("/signup");
        }
    }

    return (
        <>
            <SnsLoginLine>
                <Line />
                <Text text="SNS 로그인" customStyle={{ fontSize: "16px", fontWeight: "400", color: colors.gray600, margin: "0 20px" }} />
                <Line />
            </SnsLoginLine>
            <SnsFormWrap>
                <SnsSigninForm snsName="naver" />
                <SnsSigninForm snsName="kakao" />
                <SnsSigninForm snsName="google" />
            </SnsFormWrap>
        </>
    );
}

function SnsSigninForm({ snsName }: { snsName: string }) {
    const { socketId } = useSelectorTyped((state:RootState) => state.socket);
    let snsLogo, name = "네이버", bgColor = "#1EC800";
    if (snsName === "google") {
        snsLogo = <GoogleIcon />
        bgColor = colors.white;
        name = "Google";
    } else if (snsName === "kakao") {
        snsLogo = <KakaoIcon />;
        bgColor = "#FEE500";
        name = "카카오";
    } else snsLogo = <NaverIcon />;

    function snsLogin(site:string) {
        const url = `/user/social/login` + 
                    `/${site}?sid=${socketId}` + 
                    `&caller=${window.location.origin}`;
        window.open(url, "login", "width=480,height=600");
    }
    
    return (
        <SnsLoginBtn bgColor={bgColor} onClick={()=> snsLogin(snsName)}>
            {snsLogo}
            <SnsLoginText sns={snsName}>{name}로 계속하기</SnsLoginText>
        </SnsLoginBtn>
    );
}

const SnsLoginLine = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`

const Line = styled.div`
    width: 140px;
    height: 1px;
    background: ${({ theme }) => theme.colors.gray300};
`

const SnsFormWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 160px;
`

const SnsLoginBtn = styled.div`
    display: flex;
    align-items: center;
    width: 400px;
    height: 48px;
    border: 1px solid ${({ bgColor }:{ bgColor?: string }) => bgColor === colors.white ? colors.gray300 : bgColor};
    border-radius: 4px;
    background: ${({ bgColor }:{ bgColor?: string }) => bgColor ? bgColor : "#1EC800"};
    cursor: pointer;
    :not(:last-of-type) { margin-bottom: 8px; }
    padding-left: 111px;
`

const SnsLoginText = styled.div`
    font-size: 14px;
    color: ${({ sns }:{ sns?: string }) => sns == "naver" ? colors.white : colors.black};
    margin-left: 24px;
    font-weight: 500;
`

export default SNSLoginContainer;
