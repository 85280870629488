import { useState, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components'
import Text from "@/components/atoms/text/index"
import InquiryWriteInput from '@/components/molecules/support/inquiry/InquiryWriteInput';
import { colors } from '@/assets/styles/theme';
import { useAppDispatch, useAppSelector, RootState } from '@/modules/store';
import { getAuthPublist, getInquiryDetail, InquiryActions, writeInquiry } from './slice';
import useLoading from "@/modules/hooks/useLoading";
import Button from '@/components/atoms/button';
import useAlert from '@/modules/hooks/useAlert';

export default function InquiryWrite() {
    const { loadingOn, loadingOff } = useLoading();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const writeId = searchParams.get('id');
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const [category, setCategory] = useState("건의사항");
    const [publisher, setPublisher] = useState('0');
    const [secret, setIsClickedSecret] = useState(false);

    const { authPublists } = useAppSelector((state: RootState) => state.inquiry);
    const titleInputRef = useRef<HTMLInputElement>(null);
    const contentInputRef = useRef<HTMLTextAreaElement>(null);

    const filteredPublists = authPublists.map((publisher) => ({publisher_name: publisher.publisher_name, publisher_id: publisher.publisher_id}));
    const [inquiryDetail] = useAppSelector((state: RootState) => [
        state.inquiry.inquiryDetail.detail,
    ]);
    
    const TitleStyle = {
        fontSize: "20px",
        fontWeight: "700",
        color: colors.black
    };

    const buttonLabel = writeId === null ? '글쓰기' : '수정하기'

    const goSecretWarning = async () => {
        await alert("info", "주의", "접근 권한이 없습니다");
        navigate('/support/inquiry');
    }

    const submitHandler = async() => {
        try {
            if (titleInputRef.current && titleInputRef.current.value.length < 2) {
                return await alert("info", "주의", "제목을 2글자이상 입력해주세요");
            };
            if ( contentInputRef.current?.value.length === 0) {
                return await alert("info", "주의", "내용을 입력해주세요");
            }
            let answer = await alert(
                "confirm",
                "저장 확인",
                "게시글을 저장 하시겠습니까?"
            );

            if (answer === "Y") {
                loadingOn();
                const url = writeId ? "support/board/update" : "support/board/insert";
                let apiParams = {
                    title: titleInputRef.current?.value,
                    content: contentInputRef.current?.value,
                    category,
                    publisher_id : Number(publisher),
                    secret,
                    id: writeId || '',
                    url,
                    class: "inquiry"
                };
                await dispatch(writeInquiry(apiParams)).unwrap();
                await alert("success", "저장완료", "게시글이 저장 되었습니다.");
                goBack();
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const categoryChangeHandler = (categorys: {value:string, name: string}) => {
        setCategory(categorys.value);
    } 
    
    const publisherChangeHandler = (publisher: {value:string, name: string}) => {
        setPublisher(publisher.value);
    }

    const secretChangeHandler = (secret: boolean) => {
        setIsClickedSecret(secret);
    }

    const goBack = () => {
        navigate('/support/inquiry');
    }

    const getPublist = async () => {
        try {
            loadingOn();
            await dispatch(getAuthPublist()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const fetchData = async () => {
        try {
            loadingOn();
            const apiParams = {
                inquiryId: Number(writeId),
                target_class: "문의게시판",
                target_cd: "BV",
                target_id: Number(writeId),
                log_activity: "VU",
                withCredentials: true,
                credentials: "include"
            } 
            await dispatch(getInquiryDetail(apiParams)).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    
    useEffect(()=> {
        getPublist();
    }, [dispatch]);

    useEffect(()=> {
        if (writeId) {    
            fetchData();
        }
        return () => {dispatch(InquiryActions.reset())}
    }, []);

    useEffect(() => {
        if (inquiryDetail.is_mine === undefined) {
            goSecretWarning();
        }
        if (writeId) {
            if (inquiryDetail.category !== "") {
                setCategory(inquiryDetail.category);
            }
            if (inquiryDetail.publisher_id !== -1) {
                setPublisher(String(inquiryDetail.publisher_id));
            }
            if (titleInputRef.current) {
                titleInputRef.current.value = inquiryDetail.title;
            }
            if (contentInputRef.current) {
                contentInputRef.current.value = inquiryDetail.content;
            }
        }
    }, [inquiryDetail]);

    return (
        <StyledContainer>
            <Text text="글쓰기" customStyle={TitleStyle}/>
            {authPublists.length > 0 && <InquiryWriteInput 
                AuthPublists={filteredPublists}
                secret={secret}
                onCategoryChange={categoryChangeHandler}
                onPublisherChange={publisherChangeHandler}
                onSecretChange={secretChangeHandler}
                category={category}
                publisher={publisher}
            />}
            <StyledTitleInput placeholder='제목을 입력해주세요' ref={titleInputRef}/>
            <StyledContentInput placeholder='질문을 입력해주세요.&#13;&#13;1. 문제 발생 일자&#13;2. 문의 내용' ref={contentInputRef}/>
            <StyledButtonContainer>
                <Button type="red-outline" label="뒤로가기"  onClickHandler={()=> navigate('/support/inquiry')}/>
                <Button label={buttonLabel} onClickHandler={submitHandler}/>
            </StyledButtonContainer>
        </StyledContainer>
    )
};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledTitleInput = styled.input`
    width: 100%;
    height: 48px;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;

    &::placeholder {
        color: ${({ theme }) => theme.colors.gray600};
        font-weight: 400;
    }
`;

const StyledContentInput = styled.textarea`
    width: 100%;
    min-height: 420px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    padding: 16px;

    &::placeholder {
        line-height: 28px;
    }

    &:focus {
        outline: 0;
    }
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;