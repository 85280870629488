import { useRef, useCallback, useEffect } from 'react';

export const useScrollFadeIn = (direction = 'up', duration = 1, delay = 0, threshold = 0.3) => {
    const dom = useRef<HTMLDivElement>(null);

    const handleDirection = (name:string) => {
        switch (name) {
            case 'up':
                return 'translate3d(0, 50%, 0)';
            case 'down':
                return 'translate3d(0, -50%, 0)';
            case 'left':
                return  'translate3d(50%, 0, 0)';
            case 'right':
                return  'translate3d(-50%, 0, 0)';
            default:
                return;
        };
    };

    const handleScroll = useCallback(([entry]: any)=> {
        const { current } = dom;
        //entry는 IntersectionObserver의 두번째 인자가 비율에 들어왔을 때의 객체값이 들어있음.
        //useRef사용 시 null체크 필수.
        
        if ( current && entry.isIntersecting) {
            current.style.transitionProperty = 'all';
            current.style.transitionDuration = `${duration}s`;
            current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)';
            current.style.transitionDelay = `${delay}s`;
            current.style.opacity = '1';
            current.style.transform = 'translate3d(0, 0, 0)';
        }
    }, [delay, duration]);

    useEffect(() => {
        let observer: IntersectionObserver;
        const { current } = dom;

        if (current) {
            //viewport 기준으로 threshold만큼의 비율이 보이면 콜백함수 실행
            observer = new IntersectionObserver(handleScroll, { threshold: threshold});
            observer.observe(current);
        
            return () => observer && observer.disconnect();
        };
    }, [handleScroll]);

    return {
        ref: dom,
        style: {
            opacity: 0,
            transform: handleDirection(direction),
        }
    }

}