import styled from "styled-components";
import { useState, useEffect } from "react";
import Button from "@/components/atoms/button";
import { RootState, useAppSelector } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { ReactComponent as Left } from "@/assets/icons/left.svg";
import { ReactComponent as Right } from "@/assets/icons/right.svg";
import { ReactComponent as ZoomIn } from "@/assets/icons/zoom-in.svg";
import { ReactComponent as ZoomOut } from "@/assets/icons/zoom-out.svg";
import { colors } from "@/assets/styles/theme";
import TextInput from "@/components/atoms/input/TextInput";
import useDownload  from "@/modules/hooks/useDownload";

export default function ButtonContainer({ pageNumber, pageLength, scale, pageHandler, scaleHandler, pdfUrl }: PdfStatusProps) {
    const { currAuthor } = useAppSelector((state: RootState) => state.pubStatement);
    const [tmpPageNum, setTmpPageNum] = useState(pageNumber);
    const [tmpScale, setTmpScale] = useState(scale);
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const download = useDownload();

    useEffect(() => {
        setTmpPageNum(pageNumber);
    }, [pageNumber])

    useEffect(() => {
        setTmpScale(scale);
    }, [scale])

    const fileDownload = async () => {
        try {
            if (currAuthor.user_id) {
                await loadingOn();
                // PDF 다운로드
                download(pdfUrl.replace("/file/", ""));
            }
        } catch (err) {
            alert("error", "오류 발생", "정산서 발급중 오류가 발생하였습니다.");
        } finally {
            await loadingOff();
        }
    }

    const pageChangeHandler = (e: any) => {
        let number = Number(e.target.value.replace(/[^0-9]/g, ""));
        if (number > pageLength) number = pageLength;
        setTmpPageNum(number);
    }

    const pageOnKeyPress = (e: any) => {
        if (e.key === "Enter") {
            if (tmpPageNum === 0) {
                pageHandler(1);
                setTmpPageNum(1);
            } else {
                pageHandler(tmpPageNum);
            }
        }
    }

    const prevPage = () => {
        if (leftActive) {
            pageHandler(pageNumber-1);
        }
    }

    const nextPage = () => {
        if (rightActive) {
            pageHandler(pageNumber+1);
        }
    }

    const scaleChangeHandler = (e: any) => {
        let number = Number(e.target.value.replace(/[^0-9]/g, ""));
        if (number > 1000) number = 1000;
        setTmpScale(number);
    }

    const scaleOnKeyPress = (e: any) => {
        if (e.key === "Enter") {
            if (tmpScale !== 0) {
                scaleHandler(tmpScale);
            }
        }
    }

    const zoomIn = () => {
        if (scale + 10 > 1000) {
            scaleHandler(1000);
        } else {
            scaleHandler(scale + 10);
        }
    }

    const zoomOut = () => {
        if (scale - 10 <= 0) {
            scaleHandler(1);
        } else {
            scaleHandler(scale - 10);
        }
    }

    const leftActive = pageNumber > 1;
    const rightActive = pageNumber < pageLength;

    return (
        <StyledContainer>
            <PageWrapper>
                <StyledLeft 
                    fill={leftActive ? colors.blue500 : colors.gray600} 
                    disabled={!leftActive}
                    onClick={prevPage}
                />
                <TextInput 
                    value={tmpPageNum}
                    onChangeHandler={pageChangeHandler}
                    onKeyPressHandler={pageOnKeyPress}
                    customStyle={{ width: "32px", height: "32px", textAlign: "center" }}
                />
                <PageLengthWrapper>
                    /&nbsp;&nbsp;&nbsp;{pageLength}
                </PageLengthWrapper>
                <StyledRight 
                    fill={rightActive ? colors.blue500 : colors.gray600} 
                    disabled={!rightActive}
                    onClick={nextPage}
                />
            </PageWrapper>
            <ScaleWrapper>
                <StyledZoomOut 
                    onClick={zoomOut}
                />
                <TextInput 
                    value={tmpScale}
                    onChangeHandler={scaleChangeHandler}
                    onKeyPressHandler={scaleOnKeyPress}
                    customStyle={{ width: "60px", height: "32px", padding: "0 12px 0 0", textAlign: "center" }}
                />
                <Percent>%</Percent>
                <StyledZoomIn 
                    onClick={zoomIn}
                />
            </ScaleWrapper>
            <Button 
                type={currAuthor.user_id ? "main" : "disabled"}
                label="다운로드"
                onClickHandler={fileDownload}
            />
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 32px;
`

const PageWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`

const ScaleWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`

const StyledLeft = styled(Left)<{ disabled?: boolean; }>`
    cursor: ${({ disabled }) => disabled ? "unset" : "pointer"};
    margin-right: 4px;
`

const StyledRight = styled(Right)<{ disabled?: boolean; }>`
    cursor: ${({ disabled }) => disabled ? "unset" : "pointer"};
    margin-left: 8px;
`

const PageLengthWrapper = styled.div`
    margin-left: 8px;
    font-size: 12px;
    font-weight: 400;
`

const Percent = styled.span`
    position: relative;
    right: 22px;
    font-size: 12px;
`

const StyledZoomOut = styled(ZoomOut)`
    margin-right: 10px;
    cursor: pointer;
`

const StyledZoomIn = styled(ZoomIn)`
    cursor: pointer;
`

interface PdfStatusProps {
    pageNumber: number;
    pageLength: number;
    scale: number;
    pageHandler: (page: number) => void;
    scaleHandler: (scale: number) => void;
    pdfUrl: string;
}