import styled from 'styled-components'
import Text from '@/components/atoms/text'
import Button from '@/components/atoms/button/index'
import { colors } from '@/assets/styles/theme';
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn';
import { useNavigate } from 'react-router-dom';

export default function StartGlingContainer() {
    const navigate = useNavigate();
    const fadeInCard = useScrollFadeIn('up', 1, 0, 0.1);
    const commonStyle = {
        fontWeight: "700",
        fontSize: "36px"
    };

    const texts = [
        {
            text: '글링은 정산관리를\u00a0',
            style: Object.assign({color: colors.black}, commonStyle)
        },
        {
            text: '더욱 쉽게\u00a0',
            style: Object.assign({color: colors.blue500}, commonStyle)
        },
        {
            text: '만듭니다',
            style: Object.assign({color: colors.black}, commonStyle)
        }
    ];

    const description = '글링으로 쉽고 정확한 정산을 시작하세요!<br/>합리적인 플랜으로 강력한 효과를 발휘할 수 있습니다.';
    const descriptionStyle = {
        fontWeight: "400",
        fontSize: "16px",
        color: colors.gray600,
        textAlign: "center"
    };

    const buttonStyles = {
        width: "200px",
        height: "60px",
        padding: "9px 38px",
        margin: "40px 0",
        borderRadius: "4px",
        fontSize: "20px"
    }

    const filteredText = texts.map((textObj, idx)=> <Text key={idx} text={textObj.text} customStyle={textObj.style}/>)
    const filteredDescription = description.split("<br/>").map((text, idx)=> <Text key={idx} text={text} customStyle={descriptionStyle} />)
    
    return (
        <StyledContainer>
            <CardContainer {...fadeInCard}>
                <TitleWrapper>
                    { filteredText }
                </TitleWrapper>
                <DescriptionWrapper>
                    { filteredDescription }
                </DescriptionWrapper>
                <ButtonWrapper>
                    <Button label="글링 시작하기" customStyle={buttonStyles} onClickHandler={() => navigate("/signin")}/>
                </ButtonWrapper>
            </CardContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 593px;
    padding: 30px 0;
    background: linear-gradient(143.85deg, #F8F9FA 33.3%, #F1F3F5 83.68%);
`

const CardContainer = styled.div`
    width: 800px;
    height: 480px;
    background-color: ${ colors.gray50 };
    box-shadow: 0px 16px 20px rgba(112, 144, 176, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const TitleWrapper = styled.div`
    display: flex;
    width: 563px;
`

const DescriptionWrapper = styled.div`
    padding-top: 24px;
    line-height: 28px;
`

const ButtonWrapper = styled.div`
`