import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CellPhone } from "@/assets/icons/mob-landing/cellphone.svg";
import { CellPhoneInner } from "@/assets/images/mob-landing";
import useFadeIn from "@/modules/hooks/useFadeIn";

export default function CellPhoneSection() {
    const [scrollY, setScrollY] = useState(0); // 스크롤에 따라 이미지 y좌표 변경
    const containerRef = useRef<HTMLDivElement>(null); // 스크롤 이벤트 시작 기준점
    const imgRef = useRef<HTMLImageElement>(null); // 이미지 크기 측정위해

    // 텍스트 부분에 fadein 애니메이션 
    const animationTarget = useRef<HTMLDivElement>(null);
    const { FadeInWrapper, active } = useFadeIn(animationTarget);

    const WIDTH = 268; // 핸드폰 이미지 원본 너비
    const HEIGHT = WIDTH * 490 / 242; // width 80%일 때 이미지 비율에 맞는 height 계산
    const PADDING = 400;
    const INNER_HEIGHT = 1825; // 이미지 원본 높이
    const CONTAINERHEIGHT = (imgRef.current ? imgRef.current.height : INNER_HEIGHT) + PADDING;

    useEffect(() => {
        return () => { window.removeEventListener("scroll", onScroll); }
    }, []);

    const onScroll = () => {
        const ContainerRect = containerRef.current?.getBoundingClientRect();
        const imgRect =  imgRef.current?.getBoundingClientRect();

        const containerAbsoluteTop = window.scrollY + (ContainerRect?.top || 0); // 스크롤 이벤트 시작 위치

        const startAt = containerAbsoluteTop;
        const endAt = containerAbsoluteTop + 173 + (imgRect ? imgRect.height : 0);

        // 스크롤 사정범위 안에 들어오면
        if (window.scrollY >= startAt && window.scrollY <= endAt) {
            const scrollableHeight = imgRect ? imgRect.height : INNER_HEIGHT;
            const movedScroll = window.scrollY - containerAbsoluteTop;
            const y = -(movedScroll / scrollableHeight * 100);
            setScrollY(prev => y > -70 ? y : prev);
        }
    };

    return (
        <Container ref={containerRef} height={CONTAINERHEIGHT}>
            <ScrollSection>
                <FadeInWrapper active={active} ref={animationTarget}>
                    <Title>{`출간부터\n정산까지`}</Title>
                    <Desc>글링에서 출간하고{`\n`}실시간으로 정산을 확인하세요</Desc>
                </FadeInWrapper>
                <CellPhoneBox height={HEIGHT}>
                    <ImgWrapper>
                        <StyledImg 
                            src={CellPhoneInner}
                            ref={imgRef} 
                            style={{ transform: `translate3d(0, ${scrollY+'%' || '0px'}, 0)` }}
                            onLoad={() => { window.addEventListener("scroll", onScroll); }} 
                        />
                    </ImgWrapper>
                    <StyledCellPhone />
                </CellPhoneBox>
            </ScrollSection>
        </Container>
    )
}

const Container = styled.div<{ height: number; }>`
    position: relative;
    height: ${({ height }) => height || 2500 }px;
    margin-top: 120px;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
`
const ScrollSection = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 40px;
    width: 100%;
`
const Title = styled.p`
    font-size: 24px;
    font-weight: 700;
    line-height: 30.05px;
    white-space: pre-wrap;
`
const Desc = styled.p`
    margin-top: 16px;
    line-height: 28px;
    white-space: pre-wrap;
`

const CellPhoneBox = styled.div<{ height: number; }>`
    position: relative;
    left: 0;
    width: 268px;
    height: ${({ height }) => height + 'px' || '660px'};
    margin-top: 57px;
`
const ImgWrapper = styled.div`
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
    height: 98%;
    border-radius: 58px;
    overflow: hidden;
    isolation: isolate; // ios overflow 버그
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { /* Chrome, Safari and Opera */
        display: none;
    }
`
const StyledImg = styled.img`
    width: calc(100% - 18px);
`
const StyledCellPhone = styled(CellPhone)`
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    pointer-events: none;
`