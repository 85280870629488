import styled from "styled-components";
import { useState } from "react";
import Text from "@/components/atoms/text";

export default function TextTooltip({ text, customStyle }: TooltipProps) {
    const [tooltip, setTooltip] = useState(false);

    const onMouseEnter = () => {
        setTooltip(true);
    };

    const onMouseLeave = () => {
        setTooltip(false);
    };

    const textStyle = {
        lineHeight: "21px",
        fontSize: "12px",
        fontWeight: "400",
    };

    return (
        <TextWrap onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <EllipsisText width={customStyle?.width}>{text}</EllipsisText>
            {tooltip && (
                <TooltipText>
                    <Text text={text} customStyle={textStyle} />
                </TooltipText>
            )}
        </TextWrap>
    );
}

const TextWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
`;

const EllipsisText = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: ${({ width }) => width || "160px"};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
`;

const TooltipText = styled.div`
    position: absolute;
    min-width: 200px;
    max-height: 200px;
    padding: 3px 17px;
    z-index: 99;
    background-color: ${({ theme }) => theme.colors.blue50};
    top: 120%;
    left: 0;
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    border-radius: 4px;
    overflow-y: auto;
`;

interface TooltipProps {
    text: string;
    customStyle?: StyleProps;
}

interface StyleProps {
    fontWeight?: string;
    fontSize?: string;
    lineHeight?: string;
    color?: string;
    margin?: string;
    textAlign?: string;
    padding?: string;
    height?: string;
    width?: string;
}
