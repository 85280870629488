import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import useWheel, { wheelType } from '@/modules/hooks/useWheel'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom';

const PageSlider = ({ anchors, children, duration }: PageProps) => {
    const [active, setActive] = useState(false);
    const [distance, setDistance] = useState(0);
    const [clickedId, setClickedId] = useState(0);
    const id = useRef<number>(0);
    const compOffset = useRef<Array<HTMLDivElement>>([]);
    const [wheelDir] = useWheel();
    const durations = Number(duration);
    let navigate = useNavigate();
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    const anchorsNum = anchors.length;
    const setOffset = () => {
        const firstComp = compOffset.current[0];
        const nextComp = compOffset.current[id.current];
        setDistance(-(nextComp.offsetTop + nextComp.offsetHeight - firstComp.offsetHeight));
    };

    const reSize = debounce(() => {
        setOffset();
        setInnerWidth(window.innerWidth);
    },100);
    
    const initState = () => {
        const hashValue =  window.location.hash;

        const index = anchors.indexOf(hashValue);
        if (index !== -1) {
            id.current = index;
            setClickedId(index);
            setOffset();
        }
    }

    
    const swipePage = (wheelDir:wheelType, index: number) => {
        switch(wheelDir) {
            case wheelType.down :
                if (id.current >= anchorsNum -1) return;
                else id.current++;
                break;
            case wheelType.up :
                if (id.current <= 0) return;
                else id.current--;
                break;
            case wheelType.pathChange:
                id.current = index;
                break;
            case wheelType.none:
                return;
            default: return;
        }
        
        navigate(`/${anchors[id.current]}`);
        setActive(true);
        setTimeout(()=>{setActive(false)}, durations);
        setOffset();
        setClickedId(id.current);
    }

    useEffect(()=> {
        window.addEventListener("resize", reSize);
        return () => window.removeEventListener("resize", reSize);
    });

    useEffect(()=> {
        initState();
        const onHashChange = () => {
            const index = anchors.indexOf(window.location.hash);
            if (index !== -1) swipePage(wheelType.pathChange, index);
        }
        window.addEventListener('hashchange', onHashChange);
        return () => window.removeEventListener('hashchange', onHashChange);
    }, [distance]);

    useEffect(() => {
        if (active) return;
        swipePage(wheelDir, id.current);
    }, [wheelDir]);

    const sideSliderRender = () => {
        let result = [];
        const changeSideSlider = (i:number) => {
            setClickedId(i);
            swipePage(wheelType.pathChange, i);
        };

        for (let i=0; i<anchorsNum; i++) {
            result.push(
                <SideSliderContent
                    key={i}
                    active={clickedId === i}
                    onClick={() => {changeSideSlider(i)}}
                />
            )
        }
        return result;
    }

    return (
        <>
            <SlideContent width={innerWidth} page={id.current}>
                <SideSlider
                >
                    {
                        sideSliderRender()
                    }
                </SideSlider>
                <SlideContainer active={active} distance={distance} duration={durations}>
                    {
                        children.map((child:React.ReactNode, idx:number) => (
                            <div className="pageSlideContent"
                                key = {idx}
                                ref = {(el: HTMLDivElement) => compOffset.current[idx] = el}
                            >
                                { child }
                            </div>
                        ))
                    }
                </SlideContainer>
            </SlideContent>
        </>
    )
};

export default PageSlider;

const SlideContent = styled.div<{ page?:number, width: number }>`
    height: ${({ page, width }) => page === 0 && width > 1200 || width > 1200 ? `calc((100vh - 72px))` : `calc((100vh - 84px))`};
    overflow-y: hidden;
    overflow-x: hidden;
`

const SlideContainer = styled.div<{active: boolean, distance: number, duration: number}>`
    transform: ${(props) => `translate3d(0, ${props.distance}px,0)`};
    transition: ${(props) => props.active && `${props.duration}ms ease-in-out`};
`;

const SideSlider = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 20px;
    height: 80px;
    position: fixed;
    z-index: 1;
    right: 100px;
    top: 50%;
    transform : translateY(-50%);
`

const SideSliderContent = styled.div<{active: boolean}>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({active, theme}) => active? theme.colors.blue500 : '#ECEAE0'};
    cursor: pointer;
`

interface PageProps {
    anchors: Array<string>,
    children: Array<React.ReactNode>,
    duration: string,
}
