import styled from "styled-components";
import { colors } from '@/assets/styles/theme';
import Text from "@/components/atoms/text";
import { useState } from "react"
import Button from "@/components/atoms/button";
import MemoizedCheckbox from "@/components/atoms/input/Checkbox";
import useAlert from "@/modules/hooks/useAlert";
import { deleteUser } from "@/pages/mypage/slice";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { useNavigate } from "react-router-dom";
import useLoading from "@/modules/hooks/useLoading";
import { userActions } from "@/pages/common/userSlice";

export default function DeleteUser() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const navigate = useNavigate();
    const { user_id } = useAppSelector((state: RootState) => state.user);

    const reasonArr = ['정산관리의 필요성을 느끼지 못해서', '출판사와의 계약 해지 등으로 더 이상 서비스를 이용할 필요가 없어서',
    '서비스를 이용하기 어려워서', '고객지원이 만족스럽지 못해서', '별 다른 이유가 없음'];
    const textArr = ['1. 지금까지 적은 글과 댓글이 사라져요.', '2. 더 이상 판매 현황을 알 수 없어요.',
    '3. 내 포트폴리오를 볼 수 없어요.', '4. 발급 된 정산서를 볼 수 없어요.', '5. 이전에 문의한 글을 볼 수 없어요.', '6. 삭제 된 정보를 복구할 수 없어요.']
    const checkText = {id: 0, text: '회원탈퇴 안내를 모두 확인하였으며 탈퇴에 동의합니다.'};

    const [isSelected, setIsSelected] = useState(0);
    const [isChecked, setIsChecked] = useState(false);

    const submitHandler = async () => {
        if(!isChecked) {
            await alert("warning", "경고", "회원 탈퇴 동의를 체크해주세요.");
            return;
        }

        const res = await alert("confirm", "탈퇴확인", "등록하신 모든 게시글과 댓글이 삭제됩니다.\n탈퇴하시겠습니까?");
        try {
            if (res === 'Y') {
                loadingOn();
                await dispatch(deleteUser(user_id));
                dispatch(userActions.deleteUserInfo());
                navigate("/");
            }
        } catch (error: any){
            await alert("error", "오류발생", error.message);
        } finally {
            loadingOff();
        }
    }

    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
    }

    const subTitleStyled = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
    }

    const subContentStyled = {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "24.5px",
    }

    const checkBoxTextStyled = {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '14px',
        margin: '13px 8px',
    }

    const buttonStyled = {
        width: '400px',
        height: '40px',
        margin: '0px 0px 8px 0px',
        borderRadius: '4px',
        fontWeight: '500',
        fontSize: '16px',
    }

    return (
        <ResignSectionLayout>
            <StyledSectionLayout>
                <StyledTitleContainer>
                    <Text text="회원탈퇴" customStyle={titleStyled} />
                </StyledTitleContainer>
                <SubContentContainer width="500px" height="236px">
                    <Text text="회원탈퇴 사유" customStyle={{...subTitleStyled, margin: "0px 0px 16px 0px"}} />
                    {reasonArr.map((d, i) => (
                        <CheckCircleContainer key={i}>
                                <StyledCheckCircle id={`${i}`} type='radio' checked={isSelected === i} onChange={() => setIsSelected(i)} />
                                <Mark />
                                <Text key={i} text={reasonArr[i]} customStyle={checkBoxTextStyled} />
                        </CheckCircleContainer>
                    ))}
                </SubContentContainer>
                <SubContentContainer width="600px" height="116px">
                    <Text text="탈퇴하려는 사유를 적어주세요." customStyle={subTitleStyled} />
                    <TextArea placeholder="글링에 하시고 싶은 말씀이 있으면 남겨주세요." />
                </SubContentContainer>
                <SubContentContainer width="250px" height="183px">
                    <Text text="글링을 탈퇴하면" customStyle={subTitleStyled} />
                    <SubContentBox>
                    {textArr.map((d, i) => (
                        <Text key={i} text={d} customStyle={subContentStyled} />
                    ))}
                    </SubContentBox>
                </SubContentContainer>
                <SubContentContainer width="400px" height="76px">
                    <Text text="회원 탈퇴 동의" customStyle={subTitleStyled} />
                    <CheckContainer>
                        <MemoizedCheckbox type="boolean" active={isChecked} onChangeHandler={() => setIsChecked(!isChecked)} />
                        <Text text={checkText.text} customStyle={checkBoxTextStyled} />
                    </CheckContainer>
                </SubContentContainer>
                <Button 
                    type="black-fill" 
                    label="탈퇴하기" 
                    customStyle={buttonStyled} 
                    onClickHandler={submitHandler} 
                />
            </StyledSectionLayout>
        </ResignSectionLayout>
    )
}

const ResignSectionLayout = styled.div`
    position: relative
`

const StyledSectionLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    position: absolute;
    left: 666px;
    top: 30px;
`

const StyledTitleContainer = styled.div`
    display: flex;
    margin: 0px 0px 24px 0px;
`

const SubContentContainer = styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    height: ${props => props.height};
    margin-bottom: 24px;
`

const TextArea = styled.textarea`
    background: ${colors.gray50};
    color: ${colors.gray300};
    width: 600px;
    height: 80px;
    font-size: 14px;
    font-weight: 400;
    line-height: 25.6px;
    margin-top: 16px;
    resize: none;
    border: 1px solid ${colors.gray300};
    padding: 8px;
`

const SubContentBox = styled.div`
    margin-top: 16px;
`

const CheckCircleContainer = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
`

const Mark = styled.span`
    display: inline-block;
    position: relative;
    border: 1px solid ${colors.gray300};
    width: 16px;
    height: 16px;
    left: 0;
    border-radius: 50%;
    margin-right: 5px;
    vertical-align: middle;
    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: ${colors.blue500};
        opacity: 0;
        left: 50%;
        top: 50%;
        position: absolute;
        transition: all 110ms;
    }
`

const StyledCheckCircle = styled.input`
    position: absolute;
    visibility: hidden;
    display: none;
    &:checked + ${Mark} {
        &::after {
            width: 10px;
            height: 10px;
            opacity: 1;
            left: 10.725%;
            top: 10.725%;
        }
    }
`

const CheckContainer = styled.label`
    display: flex;
    width: 400px;
    height: 40px;
    position: relative;
    align-items: center;
`

interface StyledProps {
    width?: string;
    height?: string;
}


