import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "@/api";
import { PubMypageState, Auth } from "./types";

const initialPubMypageState: PubMypageState = {
    isMaster: true,

    pubProfile: {
        publisher_name: "",
        publisher_ceo_name: "",
        publisher_email: "",
        publisher_pic_path: "",
        publisher_status: "",
        publisher_tel: "",
        publisher_url: "",
        publisher_addr: "",
        publisher_addr_detail: "",
        publisher_desc: "",
        publisher_reg: "",
        publisher_created_at: "",
        publisher_id: 0,
        user_id: "",
        publisher_zonecode: "",
    },

    user_id_string: "",
    pubProfileLoading: false,
    pubProfileUpdating: false,

    accontAuthAdmin: {
        data: [],
    },

    authData: {
        auth_ebook_maker: 0,
        auth_master: 0,
        auth_pubbook: 0,        
        auth_st_base_authorenroll: "",
        auth_st_base_authorsearch: "",
        auth_st_base_exceluploadmanagement: "",
        auth_st_base_platformmanagement: "",
        auth_st_base_seriesenroll: "",
        auth_st_base_seriessearch: "",
        auth_st_dashboard: 0,
        auth_st_entry_daily: "",
        auth_st_entry_etc: "",
        auth_st_entry_mg: "",
        auth_st_entry_monthly: "",
        auth_st_inquiry_daily: "",
        auth_st_inquiry_etc: "",
        auth_st_inquiry_mg: "",
        auth_st_inquiry_monthly: "",
        auth_st_inquiry_statement: 0,
        auth_st_issue_statement: 0,
        auth_st_total_sales: 0,
        auth_togo: 0,
        publisher_id: 0,
    },

    updatingAuthInfo: false,
}

export const getPubData = createAsyncThunk("publisher/profile/select", async(user_id: number, { rejectWithValue }) => {
    try {
        const res = await api.post(`/api/publisher/profile/select`, user_id);
        return (res.data);
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const updatePubData = createAsyncThunk("publisher/profile/update", async(apiParams: any, { rejectWithValue }) => {
    const apiData = {
        param: {
            publisher_name: apiParams.publisher_name,
            publisher_ceo_name: apiParams.publisher_ceo_name,
            publisher_email: apiParams.publisher_email,
            publisher_status: apiParams.publisher_status,
            publisher_tel: apiParams.publisher_tel,
            publisher_url: apiParams.publisher_url,
            publisher_addr: apiParams.publisher_addr,
            publisher_addr_detail: apiParams.publisher_addr_detail,
            publisher_desc: apiParams.publisher_desc,
            publisher_reg: apiParams.publisher_reg,
            publisher_created_at: apiParams.publisher_created_at,
            publisher_pic_path: apiParams.publisher_pic_path,
        },
        userInfo: {
            publisher_id: apiParams.publisher_id
        },
        data: apiParams.data,
    }
    try {
        const res = await api.post(`/api/publisher/profile/update`, apiData);
        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const accountAuthAdmin = createAsyncThunk("mypage/publisher/profile/mypage", async(user_id_string: string, { rejectWithValue }) => {
    const user_id = user_id_string;
    if (user_id !== "") {
        try {
            const res = await api.post(`/api/publisher/profile/mypage`, {user_id});
            return res.data
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    } else {
        throw rejectWithValue('fail');
    }
});

export const checkMaster = createAsyncThunk("mypage/publisher/profile/auth/check", async(user_id: number, { rejectWithValue }) => {
    const userInfo = user_id;
    try {
        const res = await api.post(`/api/publisher/profile/auth/check`, {userInfo});
        return res.data
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const getAuthInfo = createAsyncThunk("mypage/publisher/profile/mypage/getAuthInfo", async(user_id: number, { rejectWithValue }) => {
    const uId ={
        uId: user_id
    } 
    try{
        const res = await api.post(`/api/publisher/profile/mypage/getAuthInfo`, uId);
        return res.data
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const updateAuthInfo = createAsyncThunk("mypage/publisher/profile/mypage/updateAuth", async({ authData } : { authData: PubMypageState["authData"] }, { rejectWithValue }) => {
    try {
        const res = await api.post(`/api/publisher/profile/mypage/updateAuth`, authData);
        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const disConnect = createAsyncThunk('mypage/publisher/disconnect', async({ new_user_id_string, user_id } : { new_user_id_string: string, user_id: number }, { rejectWithValue }) => {
    const info = {
        user_id_string : new_user_id_string,
        user_id
    }
    try {
        const res = await api.post(`/api/publisher/profile/mypage/disconnect/user`, info);
        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const pubMyPageSlice = createSlice({
    name: "pubMyPage",
    initialState: initialPubMypageState,
    reducers: {
        tmpUpdatePublisher: (state, action: PayloadAction<{ apiData: any }>) => {
            state.pubProfile.publisher_pic_path = action.payload.apiData.publisher_pic_path;
            state.pubProfile.publisher_name = action.payload.apiData.publisher_name;
            state.pubProfile.publisher_reg = action.payload.apiData.publisher_reg;
            state.pubProfile.publisher_email = action.payload.apiData.email;
            state.pubProfile.publisher_ceo_name = action.payload.apiData.publisher_ceo_name;
            state.pubProfile.publisher_tel = action.payload.apiData.publisher_tel;
            state.pubProfile.publisher_url = action.payload.apiData.publisher_url;
            state.pubProfile.publisher_zonecode = action.payload.apiData.publisher_zonecode;
            state.pubProfile.publisher_addr = action.payload.apiData.publisher_addr;
            state.pubProfile.publisher_addr_detail = action.payload.apiData.publisher_addr_detail;
            state.pubProfile.publisher_desc = action.payload.apiData.publisher_desc
        },
        //계정 권한 설정 모달에서 변경시
        tmpUpdateAuthData: (state, action: PayloadAction<{ id: string, value?: string, numValue?: number }>) => {
			const id = `${action.payload.id}`;
			const value = action.payload.value;
            const numValue = action.payload.numValue;

            if(numValue === 0 || numValue === 1) {
                state.authData[id] = numValue;
            } else if (value) {
                state.authData[id] = value;
            }
	   	},
        //계정 권한 변경
        updateAccountAdmin: (state, action: PayloadAction<{ newData: Auth[] }>) => {
            state.accontAuthAdmin.data = action.payload.newData;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPubData.pending, (state, action) => {
            state.pubProfileLoading = true;
        });
        builder.addCase(getPubData.fulfilled, (state, action) => {
            state.pubProfileLoading = false;
            if (action.payload.status === 'success') {
                state.pubProfile = action.payload.data;
                state.user_id_string = action.payload.data.user_id;
            }
        });
        builder.addCase(getPubData.rejected, (state, action) => {
            state.pubProfileLoading = false;
        });
        builder.addCase(updatePubData.pending, (state, action) => {
            state.pubProfileUpdating = true;
            
        });
        builder.addCase(updatePubData.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.pubProfileUpdating = false;
            }
        });
        builder.addCase(updatePubData.rejected, (state, action) => {
            state.pubProfileUpdating = false;
        });

        builder.addCase(accountAuthAdmin.fulfilled, (state, action) => {
            if (action.payload && action.payload.status === 'success') {
                state.accontAuthAdmin.data = action.payload.data;
            } 
        });
        builder.addCase(getAuthInfo.fulfilled, (state, action) => {
            if(action.payload && action.payload.status === 'success') {
                const data = action.payload.data;
                state.authData = data;
            }
        });
        builder.addCase(updateAuthInfo.fulfilled, (state, action) => {
            if(action.payload && action.payload.status === 'success') {
                state.updatingAuthInfo = false;
            }
        });
        builder.addCase(checkMaster.fulfilled, (state,action) => {
            if(action.payload.status === 'success') {
                state.isMaster = action.payload.data === 1 ? true : false;
            }
        })
    }
});

export const pubMyPageActions = pubMyPageSlice.actions;
export default pubMyPageSlice.reducer;