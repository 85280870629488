import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState } from "@/modules/store";
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import Text from "@/components/atoms/text";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { addComma } from "@/modules/utils/filter";

export default function DailyTable() {
    const { items, fetchLoading } = useSelectorTyped((state: RootState) => state.inquiryAuthorDaily);
    const { platformNoQty } = useSelectorTyped((state: RootState) => state.inquiryAuthorCommon);
    const { pageCount, totalVisible } = usePagination({ from: "daily", tableData: items });

    // 테이블 데이터
    const headers = [
        { text: "판매일자", value: "date", width: "120px" },
        { text: "판매플랫폼", value: "platform_name", width: "120px" },
        { text: "출판사", value: "publisher_name", width: "120px" },
        { text: "작품명", value: "name", width: "240px" },
        { text: "시리즈명", value: "series_name", width: "240px" },
        { text: "저자명", value: "author_name", width: "120px" },
        { text: "판매량", value: "quantity", width: "120px" },
        { text: "판매금액(원)", value: "amount", width: "120px" },
    ];

    const columns = {
        quantity: ({ item }: TableItemProps) => {
            const text = platformNoQty.find(p => p === item.platform_name) ? '정보없음' : item.quantity;
            return <Text text={text} customStyle={tableStyle} />;
        },
        amount: ({ item }: TableItemProps) => {
            return <Text text={addComma(item.amount)} customStyle={tableStyle} />;
        },
    };

    return (
        <>
            <TableContainer>
                <DataTable
                    from="daily"
                    itemKey="id"
                    items={items}
                    loading={fetchLoading}
                    headers={headers}
                    columns={columns}
                    options={tableOptions}
                />
                <ScrollToTopBtn />
            </TableContainer>
            <Pagination pageCount={pageCount} totalVisible={totalVisible} from="daily" />
        </>
    );
}

const tableOptions = {
    showCheckbox: false,
};
const tableStyle = {
    textAlign: "right",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px",
};
