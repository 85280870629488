import * as XLSX from "xlsx";
import moment from "moment";
import useAlert from "./useAlert";

function useExcelDownload() {
    const today = moment().format("YYYY년 MM월 DD일");
    const { alert } = useAlert();

    const downloadExcel = async ({ json, excelFileName, sheetNames }: ExcelDownloadProps) => {
        const worksheet = XLSX.utils.json_to_sheet(json);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, sheetNames || "data");
        await XLSX.writeFile(workbook, `${excelFileName} - ${today}.xlsx`);
        return await alert("success", "다운로드 완료", "엑셀 다운로드가 완료되었습니다.");
    };

    return downloadExcel;
}

interface ExcelDownloadProps {
    json: any;
    excelFileName: string;
    sheetNames?: string;
}
export default useExcelDownload;
