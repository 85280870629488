import styled, { css } from "styled-components";
import TypeWrapper from "@/components/molecules/ebook-maker/copyright/TypeWrapper";
import TemplateInputContainer from "@/components/molecules/ebook-maker/copyright/TemplateInputContainer";
import TemplateListContainer from "@/components/molecules/ebook-maker/copyright/TemplateListContainer";
import TemplatePreviewContainer from "@/components/molecules/ebook-maker/copyright/TemplatePreviewContainer";
import Button from "@/components/atoms/button";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { getTemplateList } from "@/pages/ebook-maker/slice";
import StepBtnWrapper from "@/components/molecules/ebook-maker/copyright/StepBtnWrapper";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import AddContentModal from "@/components/molecules/ebook-maker/copyright/AddContentModal";
import { EbookMaker } from "@/types/dataType";
import { Step } from "@/pages/ebook-maker/types";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";

export default function Copyright({ stepHandler }: CopyrightProps) {
    const dispatch = useAppDispatch();
    const { copyrightType, bookInfo, completeStepIdx } = useAppSelector((state: RootState) => state.ebookMaker);
    const [showAddContent, setShowAddContent] = useState(false);
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();

    const fetchData = async () => {
        try {
            await loadingOn();
            await dispatch(getTemplateList(bookInfo)).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    useEffect(() => {
        if (completeStepIdx <= Step.Copyright) {
            fetchData();
        }
    }, [bookInfo, completeStepIdx, dispatch]);

    return (
        <CopyrightContainer>
            <TypeWrapper />
            <FlexBox blur={copyrightType !== EbookMaker.CopyrightType.MAKE}>
                <TemplateInputContainer />
                <RightBox>
                    <div>
                        <Button type="main" label="+ 항목추가" onClickHandler={() => setShowAddContent(true)} customStyle={{ width: "280px", height: "40px", fontSize: "16px" }} />
                        <TemplateListContainer />
                        <TemplatePreviewContainer />
                    </div>
                    {copyrightType === EbookMaker.CopyrightType.MAKE ? <div><StepBtnWrapper stepHandler={stepHandler} /></div> : <></>}
                </RightBox>
                {copyrightType !== EbookMaker.CopyrightType.MAKE ? <BlurBox /> : <></>}
            </FlexBox>
            {copyrightType !== EbookMaker.CopyrightType.MAKE ? <StepBtnWrapper stepHandler={stepHandler} /> : <></>}
            <ModalPortal>
                <AddContentModal
                    from="copyright"
                    show={showAddContent}
                    close={() => setShowAddContent(false)}
                />
            </ModalPortal>
        </CopyrightContainer>
    )
}
const CopyrightContainer = styled.div``

const FlexBox = styled.div<{ blur: boolean }>`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    ${({ blur }) => blur && css`
        height: 480px;
        overflow: hidden;
    `}
`

const BlurBox = styled.div`
    position: absolute;
    width: 1200px;
    height: 480px;
    background-color: blue;
    background: linear-gradient(to bottom, rgb(255, 255, 255, 0.5), rgb(255, 255, 255, 1));
`

const RightBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    width: 280px;
`

interface CopyrightProps {
    stepHandler: (step: number, disabled: boolean) => void;
}