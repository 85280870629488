import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { Oval } from "react-loader-spinner";

// components
import DateSelector from "@/components/molecules/common/form/DateSelector";
import Text from "@/components/atoms/text";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { totalSalesActions } from "@/pages/settlement/total-sales/slice";

// utils
import { round } from "@/modules/utils/filter";

export default function DefaultTotal() {
    const dispatch = useAppDispatch();
    const { depositSumData, withdrawSumData, loading } = useSelectorTyped((state: RootState) => state.totalSales);

    function changeDate(date:string) {
        dispatch(totalSalesActions.setTotalSalesDatas({
            key: "yearmon", value: date
        }));
    }

    return (
        <>
        <DateWrap>
            <Text text="기준월" customStyle={{ fontSize: "14px", fontWeight: "500", margin: "0 8px 0 0"}} />
            <DateSelector onChangeHandler={changeDate} yearCustom={{ width: "80px" }} monCustom={{ width: "60px" }} />
        </DateWrap>
        <Text text="월간 입출금 종합" customStyle={{ fontSize: "16px", fontWeight: "700", margin: "24px 0 0" }} />
        <SummaryWrap>
            {
                loading ?
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
                :
                <SummaryBox>
                    <Text text="월 입금 총계" customStyle={{ fontSize: "16px", fontWeight: "700" }} />
                    <Text text={round(depositSumData.total).toLocaleString() + " 원"} customStyle={{ fontSize: "24px", fontWeight: "700", color: colors.error }} />
                </SummaryBox>

            }
            {
                loading ?
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
                :
                <SummaryBox>
                    <Text text="월 출금 총계" customStyle={{ fontSize: "16px", fontWeight: "700" }} />
                    <Text text={round(withdrawSumData.total).toLocaleString() + " 원"} customStyle={{ fontSize: "24px", fontWeight: "700", color: colors.information }} />
                </SummaryBox>
            }
            {
                loading ?
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
                :
                <SummaryBox bold={true}>
                    <Text text="월간 입출금 종합" customStyle={{ fontSize: "16px", fontWeight: "700" }} />
                    <Text text={(round(depositSumData.total-withdrawSumData.total)).toLocaleString() + " 원"} customStyle={{ fontSize: "24px", fontWeight: "700", color: colors.black }} />
                </SummaryBox>
            }
        </SummaryWrap>
        </>
    );
}

const DateWrap = styled.div`
    display: flex;
    align-items: center;
`

const SummaryWrap = styled.div`
    display: flex;
    align-items: center;
    margin: 24px 0;
`

const SummaryBox = styled.div<{ bold?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 360px;
    height: 120px;
    margin: ${({ bold }) => bold ? "" : "0 60px 0 0"};
    border: ${({ bold }) => bold ? `2px solid ${colors.black}` : `1px solid ${colors.gray300}`};
    border-radius: 4px;
`

const LoaderWrapper = styled.div<{ bold?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 360px;
    height: 120px;
    margin: ${({ bold }) => bold ? "" : "0 60px 0 0"};
    border: ${({ bold }) => bold ? `2px solid ${colors.black}` : `1px solid ${colors.gray300}`};
    border-radius: 4px;
`