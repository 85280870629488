import { ChangeEvent } from 'react'
import styled from 'styled-components'
import _ from 'lodash';
import { useAppDispatch } from '@/modules/store';
import { text } from "@/modules/utils/validate_modify";
import { updateRow } from '@/pages/common/dataTableSlice';
import TextInput from "@/components/atoms/input/TextInput";
import SelectBox from '@/components/atoms/selectBox'
import ValidateWrapper from '@/components/atoms/input/ValidateWrapper'
import useCalculateAmount from "@/components/molecules/sales-entry/etc/hooks/useCalculateAmount";
import type { Item } from "@/components/molecules/common/table/DataTable";

export default function EtcTaxType({ item, changeItem }: EtcTaxType) {
    const dispatch = useAppDispatch();
    const { calculateAmount } = useCalculateAmount();

    const taxTypeOptions = [
        {
            name: "해당없음",
            value: "N",
        },
        {
            name: "원천세(작가별 설정)",
            value: "I",
        },
        {
            name: "기타",
            value: "E",
        },
    ];

    const changeTaxType = (value: any) => {
        const taxType = value.value;
        let newItem = _.cloneDeep(item);

        if (taxType === "E") {
            newItem.tax_amount = "0.00";
            for (let i of newItem.author_tax_info) {
                i.tax_amount = "";
            }
        } else {
            newItem.tax_amount = "";
        }

        newItem.tax_type = taxType;
        newItem.tax_desc = "";
        newItem.taxDescError = "";
        newItem.taxAmountError = "";

        // 세목 변경 후 지급금액 업데이트
        calculateAmount(newItem);
        changeItem(newItem);
        dispatch(updateRow({ itemKey: "etc_id", updatedRow: newItem }));

    };

    const changeTaxDesc = (e: ChangeEvent<HTMLInputElement>) => {
        const res = text({ field: "적용세목", value: e.target.value, error: "taxDescError", max: 30, mandatory: true });
        let newItem = _.cloneDeep(item);

        newItem.tax_desc = res.value;
        newItem.taxDescError = res.taxDescError;
        changeItem(newItem);
    };

    return (
        <StyledContainer>
            <SelectBox
                labelKey="name"
                valueKey="value"
                disabled={!item.authorInfo.author_id || (item.authorInfo.author_id && item.needCoRatio)}
                options={taxTypeOptions}
                selected={item.tax_type}
                customStyle={selectboxStyle}
                onClickValueFunc={changeTaxType}
            />
            <ValidateWrapper dataError={item.taxDescError}>
                <TextInput
                    type={item.tax_type === "E" ? "main" : "readOnly"}
                    value={item.tax_desc}
                    onChangeHandler={changeTaxDesc}
                    customStyle={textInputStyle}
                    placeholder="적용세목(30자 이내)"
                />
            </ValidateWrapper>
        </StyledContainer>
  )
}

const selectboxStyle = {
    width: "140px",
    fontSize: "12px",
};
const textInputStyle = {
    width: "100%",
    height: "32px",
    fontSize: "12px",
    padding: "8px",
};
const StyledContainer = styled.div`
    display: flex;
`

interface EtcTaxType {
    item: Item;
    changeItem: (item: Item) => void;
}