import { useState } from "react";
import { useAppDispatch } from "@/modules/store";
import { updateRow } from "@/pages/common/dataTableSlice";
import { setItem } from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";
import Button from "@/components/atoms/button";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import MgPaymentModal, { MgPaymentData } from "@/components/molecules/common/modal/sales-inquiry/MgPaymentModal";

export default function MgPayment({ item, index }: ColumnProps) {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);

    const handleModalState = () => setShowModal((prev) => !prev);

    const applyMgPayment = (data: MgPaymentData) => {
        dispatch(setItem({ index, item: data }));
        dispatch(updateRow({ itemKey: "mg_id", updatedRow: data }));
    };
    return (
        <>
            <Button
                type={"main-outline"}
                label="설정"
                customStyle={{
                    width: "60px",
                    height: "32px",
                }}
                onClickHandler={handleModalState}
            />
            <ModalPortal>
                <MgPaymentModal
                    from="mg"
                    show={showModal}
                    close={handleModalState}
                    item={item}
                    index={index}
                    onApply={applyMgPayment}
                />
            </ModalPortal>
        </>
    );
}

interface ColumnProps {
    item: any;
    index: number;
}
