import styled from "styled-components";

// components
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import TextInput from "@/components/atoms/input/TextInput";
import AddLine from "@/components/molecules/sales-entry/common/AddLine";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import SeriesNameField from "@/components/molecules/sales-entry/monthly/SeriesNameField";

// modules
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import { setItem, setItems, setLastId, setSummary } from "@/pages/settlement/sales-entry/monthly/monthlySlice";

// hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useUtils from "@/components/molecules/sales-entry/monthly/hooks/useUtils";

// utils
import { addComma, removeComma } from "@/modules/utils/filter";
import { amount, integer, text } from "@/modules/utils/validate_modify";

// type
import type { Item } from "@/pages/settlement/sales-entry/monthly/types";
import usePlatformSetting from "@/components/molecules/sales-entry/common/hooks/usePlatformSetting";

export default function MonthlyTable() {
    const { items, lastId, platformId, yearmon_st } = useSelectorTyped((state: RootState) => state.entryMonthly);
    const { platformQty } = usePlatformSetting({ uploadType: "monthly", platformId });
    const dispatch = useAppDispatch();
    const { calcAmountAu, sumAmountPl, sumAmountPu, validateAuthorRatio } = useUtils();
    const { pageCount, totalVisible, setDataCount } = usePagination({ from: "monthly" });

    const applyAddLine = (rowAdd: number) => {
        let tmpLastId = lastId;
        let tmpItems: Item[] = [];
        for (let i = 0; i < rowAdd; i++) {
            let item = {
                id: (tmpLastId += 1),
                series_id: 0,
                series_name: "",
                book_name: "",
                author_id: 0,
                author_name: "",
                quantity: "",
                amount_pl: "",
                amount_pu: "",
                author_ratio: 0,
                amount_au: "",
                ratio: 0,
                bookNameError: "",
                seriesError: "",
                quantityError: "",
                authorRatioError: "",
                amount_pl_error: "",
                amount_pu_error: "",
            };
            tmpItems.push(item);
        }

        const count = items.length + tmpItems.length;
        setDataCount(count);
        dispatch(setSummary({ total_cnt: count }));
        dispatch(setItems([...items, ...tmpItems]));
        dispatch(setLastId(tmpLastId));
    };

    const columns = {
        book_name: ({ item, index }: TableItemProps) => {
            const validateBookName = (value: string) => {
                const res = text({ field: "작품명", value, error: "bookNameError", max: 100, mandatory: true });
                dispatch(setItem({index, item: { ...item, book_name: res.value, bookNameError: res.bookNameError }}))
            };
            return (
                <ValidateWrapper dataError={item["bookNameError"]}>
                    <TextInput
                        value={item["book_name"]}
                        placeholder="작품명"
                        onChangeHandler={(e) => validateBookName(e.target.value)}
                        customStyle={textInputStyle}
                    />
                </ValidateWrapper>
            );
        },
        series_name: ({ item, index }: TableItemProps) => {
            return (
                <ValidateWrapper dataError={item["seriesError"]}>
                    <SeriesNameField
                        item={item}
                        idx={index}
                        platformId={platformId}
                        yearmonSt={yearmon_st}
                    />
                </ValidateWrapper>
            );
        },
        author_name: ({ item }: TableItemProps) => {
            return (
                <TextInput
                    type="readOnly"
                    value={item["author_name"]}
                    placeholder="저자명"
                    onChangeHandler={() => {}}
                    customStyle={textInputStyle}
                />
            );
        },
        quantity: ({ item, index }: TableItemProps) => {
            if (!platformQty) {
                return (
                    <TextInput
                        type="readOnly"
                        value="정보없음"
                        placeholder="정보없음"
                        onChangeHandler={() => {}}
                        customStyle={textInputStyle}
                    />
                )
            }
            const validateQuantity = (value: string) => {
                const res = integer({
                    value,
                    error: "quantityError",
                    field: "판매량",
                    min: -9999999,
                    max: 9999999,
                    mandatory: true,
                });
                dispatch(setItem({ index, item: { ...item, quantity: res.value, quantityError: res.quantityError }}));
            };
            return (
                <ValidateWrapper dataError={item["quantityError"]}>
                    <TextInput
                        value={item["quantity"]}
                        placeholder="판매량"
                        onChangeHandler={(e) => validateQuantity(e.target.value)}
                        customStyle={textInputStyle}
                    />
                </ValidateWrapper>
            );
        },
        amount_pl: ({ item, index }: TableItemProps) => {
            const validateAmount = (value: string) => {
                const res = amount({
                    field: "판매금액",
                    value,
                    error: "amount_pl_error",
                    decimal: true,
                    mandatory: true,
                });
                dispatch(setItem({ index, item: { ...item, amount_pl: res.value, amount_pl_error: res.amount_pl_error }}));
            };
            const focusInput = () => {
                dispatch(setItem({ index, item: { ...item, amount_pl: removeComma(item.amount_pl) }}));
            }
            const blurInput = () => {
                dispatch(setItem({ index, item: { ...item, amount_pl: addComma(item.amount_pl) }}));
                sumAmountPl(item.amount_pl);
            };

            return (
                <ValidateWrapper dataError={item["amount_pl_error"]}>
                    <TextInput
                        value={item["amount_pl"]}
                        placeholder="판매금액(원)"
                        onChangeHandler={(e) => validateAmount(e.target.value)}
                        onFocusHandler={focusInput}
                        onBlurHandler={blurInput}
                        customStyle={textInputStyle}
                    />
                </ValidateWrapper>
            );
        },
        amount_pu: ({ item, index }: TableItemProps) => {
            const validateAmount = (value: string) => {
                const res = amount({
                    field: "출판사정산금",
                    value,
                    error: "amount_pu_error",
                    decimal: true,
                    mandatory: true,
                });
                const newItem = { ...item, amount_pu: res.value, amount_pu_error: res.amount_pu_error };
                calcAmountAu(newItem);
                dispatch(setItem({ index, item: newItem }));
            };
            const focusInput = () => {
                dispatch(setItem({ index, item: { ...item, amount_pu: removeComma(item.amount_pu) }}));
            }
            const blurInput = () => {
                dispatch(setItem({ index, item: { ...item, amount_pu: addComma(item.amount_pu) }}));
                sumAmountPu(item.amount_pu);
            };

            return (
                <ValidateWrapper dataError={item["amount_pu_error"]}>
                    <TextInput
                        value={item["amount_pu"]}
                        placeholder="출판사정산금(원)"
                        onChangeHandler={(e) => validateAmount(e.target.value)}
                        onFocusHandler={focusInput}
                        onBlurHandler={blurInput}
                        customStyle={textInputStyle}
                    />
                </ValidateWrapper>
            );
        },
        author_ratio: ({ item, index }: TableItemProps) => {
            if (item.needRatio) {// 직접 수정 가능한 상태
                const changeRatio = (value:string) => {
                    dispatch(setItem({ index, item: {...item, author_ratio: value }}));
                }
                return (
                    <ValidateWrapper dataError={item["authorRatioError"]}>
                        <TextInput
                            value={item["author_ratio"] || ""}
                            placeholder="작가정산비율(%)"
                            onChangeHandler={(e) => changeRatio(e.target.value)}
                            onBlurHandler={() => validateAuthorRatio(item, index)}
                            onKeyPressHandler={(e) => e.key === 'Enter' && validateAuthorRatio(item, index)}
                            customStyle={textInputStyle}
                        />
                    </ValidateWrapper>
                );
            }
            return (
                <TextInput
                    type="readOnly"
                    value={item["author_ratio"] || ""}
                    placeholder="작가정산비율(%)"
                    onChangeHandler={() => {}}
                    customStyle={textInputStyle}
                />
            );
        },
        amount_au: ({ item }: TableItemProps) => {
            return (
                <TextInput
                    type="readOnly"
                    value={item["amount_au"]}
                    placeholder="작가정산금액(원)"
                    onChangeHandler={() => {}}
                    customStyle={textInputStyle}
                />
            );
        },
    };

    return (
        <>
            <TableWrapper>
                <DataTable
                    from="monthly"
                    itemKey="id"
                    items={items}
                    headers={headers}
                    columns={columns}
                />
                <AddLine onApplyAddLine={applyAddLine} />
            </TableWrapper>
            <Pagination from="monthly" pageCount={pageCount} totalVisible={totalVisible} />
        </>
    );
}

// 테이블 데이터
const headers = [
    { text: "작품명", value: "book_name", width: "220px" },
    { text: "시리즈명", value: "series_name", width: "220px" },
    { text: "저자명", value: "author_name", width: "120px" },
    { text: "판매량", value: "quantity", width: "140px" },
    { text: "판매금액(원)", value: "amount_pl", width: "140px" },
    { text: "출판사정산금(원)", value: "amount_pu", width: "140px" },
    { text: "작가정산비율(%)", value: "author_ratio", width: "120px" },
    { text: "작가정산금액(원)", value: "amount_au", width: "140px" },
];

const textInputStyle = {
    width: "100%",
    height: "32px",
};

const TableWrapper = styled.div`
    position: relative;
    width: 100%;
`;
