import styled from "styled-components";
import { useAppDispatch } from "@/modules/store";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";
import { colors } from "@/assets/styles/theme";
import { useState } from "react";
import useAlert from "@/modules/hooks/useAlert";
import { findEmail } from "@/pages/auth/authSlice";
import { useNavigate } from "react-router-dom";
import KcpForm from "@/components/molecules/signin/KcpForm";

export default function BeforeFind({ setFind }: {setFind: Function}) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { alert } = useAlert();
    const [authDone, setAuthDone] = useState<any>(null);

   async function setKcpParam(param:any) {
       try {
            let apiParams = {
                kcp_di : param.kcp_di
            };
    
            const data = (await dispatch(findEmail(apiParams)).unwrap()).data;
    
            if (!data || Object.keys(data).length === 0) {
                return await alert("warning", "등록되지 않은 회원정보", "가입정보를 찾을 수 없습니다.");
            } else if (data.sociallogin_provider) {
                // 소셜로그인 사용자의 경우 인증정보로 자동 로그인만 가능
                let answer = await alert("confirm", "로그인", `${data.sociallogin_provider}(으)로 가입된 계정이 있습니다. 로그인 하시겠습니까?`);
                    
                if (answer === "Y") {
                    navigate("/signin");
                }
                return;
            }
            // this.findId = data.user_email;
            setFind(data.user_email);
        } catch (err:any) {
            return await alert("error", "오류 발생", err.message);
        }
   }

    async function openAuthPopup() {
        try {
            setAuthDone(true);
            // setAuthDone(async (param:any) => {
            // //   this.kcp_di = param.kcp_di;
    
            //     // setKcp_di(param.kcp_di);
            //     let apiParams = {
            //         kcp_di : param.kcp_di
            //     };

            //     const data = (await dispatch(findEmail(apiParams)).unwrap()).payload.data;
            //     // let { status, message, data } = await this.$api({
            //     //     method : "post",
            //     //     url : "user/findEmail/select",
            //     //     data : apiParams
            //     // });
        
            //     if (!data || Object.keys(data).length === 0) {
            //         return await alert("warning", "등록되지 않은 회원정보", "가입정보를 찾을 수 없습니다.");
            //     } else if (data.sociallogin_provider) {
            //         // 소셜로그인 사용자의 경우 인증정보로 자동 로그인만 가능
            //         let answer = await alert("confirm", "로그인", `${data.sociallogin_provider}(으)로 가입된 계정이 있습니다. 로그인 하시겠습니까?`);
                        
            //         if (answer === "Y") {
            //             navigate("/login");
            //         }
            //         return;
            //     }
            //     // this.findId = data.user_email;
            //     setFind(data.user_email);
            // });
        } catch (err:any) {
            return await alert("error", "오류 발생", err.message);
        }
    }

    return (
        <>
            <BeforeFindWrap>
                <Button
                    type="main"
                    label="본인 인증으로 아이디 찾기"
                    customStyle={{ width: "400px",
                                    height: "60px",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    margin: "0 0 24px"
                                }}
                    onClickHandler={openAuthPopup}
                />
                <Text
                    text="*본인인증을 하지 않은 경우 아이디를 찾을 수 없습니다."
                    customStyle={{ fontSize: "14px", fontWeight: "500", color: colors.gray600 }}
                />
            </BeforeFindWrap>
            <KcpForm
                authDone={authDone}
                setResult={setKcpParam}
            />
        </>
    );
}

const BeforeFindWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 140px;
`;