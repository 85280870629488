import styled from 'styled-components'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TypeSidebar from '@/components/molecules/support/TypeSidebar'
import { Outlet } from 'react-router-dom';

const menu = [
    {
        name: "공지사항",
        type: "Notice"
    },
    {
        name: "이용 가이드",
        type: "Guide"
    },
    {
        name: "소프트웨어 다운로드",
        type: "Download"
    },
    {
        name: "자주 묻는 질문",
        type: "Question"
    },
    {
        name: "문의하기",
        type: "inquiry"
    }
]

function Support() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === "/support") {
            navigate(`/support/notice`);
        }
    }, []);

    return (
        <StyledContainer>
            <StyledTitleWrapper>고객센터</StyledTitleWrapper>
                <StyledFlexContainer>
                    <TypeSidebar
                        menu={menu}
                    />
                    <StyledTemplateContainer>
                        <Outlet/>
                    </StyledTemplateContainer>
                </StyledFlexContainer>
        </StyledContainer>);
}

const StyledContainer = styled.div`
    padding: 24px calc((100% - 1200px) / 2);
`

const StyledTitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.black};
`;

const StyledFlexContainer = styled.div`
    display: flex;
    margin-top: 24px;
`

const StyledTemplateContainer = styled.div`
    width: 100%;
    margin-left: 30px;
`;

export default Support;
