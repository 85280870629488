import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "@/api";

const name = "findpw";

interface SignUpState {
    loading: boolean;
    error: boolean;

    kcp_di: string;
    done: boolean;
}

const initialState: SignUpState = {
    loading: false,
    error: false,

    kcp_di: "",
    done: false,
};

// 비밀번호 변경
export const updatePw = createAsyncThunk(`${name}/updatePw`, async (apiParams: { user_pw: string, kcp_di: string }, { rejectWithValue }) => {
    try {
        return (await api.post('/api/user/password/auth/update', apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

const findpwSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        setKcpDi(state, action: PayloadAction<string>) {
            state.kcp_di = action.payload;
        },
        setDone(state, action: PayloadAction<boolean>) {
            state.done = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updatePw.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updatePw.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
            } else {
                // state.error = action.payload.message;
            }
            state.loading = false;
        });
        builder.addCase(updatePw.rejected, (state, action) => {
            console.error("오류 발생 :", action.error);
            state.loading = false;
        });
    },
});

export const findpwActions = findpwSlice.actions;
export default findpwSlice.reducer;
