import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";
import { PortfolioState, SortOptions, ViewOptions } from "./types";
import moment from "moment";

const initialState: PortfolioState = {
    loading: false,

    // 작품 목록
    sortOption: SortOptions.NEW,
    viewOption: ViewOptions.CARD,
    portfolioList: [],

    // 작품 등록, 수정
    coverFiles: [],
    seriesSearchResult: [],
    series_id: 0,
    series_name: "",
    publisher_name: "",
    author_name: "",
    isbnSearchResult: [],
    isbnList: [],
    isbnLabel: "",
    publishAt: "",
    salesPlatform: [],
    thumbnailActive: false,
    thumbnailTop: 0,
    promotionList: [],

    // 작품 상세
    contentBaseInfo: {
        author_name: "",
        content_id: 0,
        cover_path: null,
        isbn: null,
        publish_at: "",
        publisher_name: "",
        series_id: 0,
        series_name: "",
    },
    contentPlatformInfo: [],
    contentSalesInfo: {
        accumulatedSales: {
            amount: 0,
            quantity: 0
        },
        dailySalesHistory: {
            labels: [],
            salesHistory: [],
        },
        monthlySalesHistory: {
            labels: [],
            salesHistory: []
        }
    },
    latestPromotion: [],
    timelineData: {
        totalYearmonList: [],
        eachPlatformHistory: []
    },

    errorList: [],
    // 유통 중지 일괄 적용
    allDate: moment().format("YYYYMMDD"),
    allPlatform: [],
}

// 전체 작품 목록 조회
export const getPortfolioList = createAsyncThunk("portfolio/getPortfolioList", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/portfolio/publisher/content/list/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 작품 검색 (출판사)
export const searchPortfolioList = createAsyncThunk("portfolio/searchPortfolioList", async (data: any, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/portfolio/publisher/search/content/list`, {
            keyword: data.keyword,
            searchOption: data.searchOption
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// isbn 검색 모달 isbn 검색
export const searchIsbn = createAsyncThunk("portfolio/searchIsbn", async (keyword: string, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/portfolio/enroll/search/isbn?keyword=${keyword}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 포트폴리오 등록
export const enrollPortfolio = createAsyncThunk("portfolio/enrollPortfolio", async (formData: any, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/portfolio/content/enroll`, formData)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 포트폴리오 상세페이지 데이터 조회
export const getPortfolioDetail = createAsyncThunk("portfolio/getPortfolioDetail", async (content_id: number, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/portfolio/content/detail/select`, {
            content_id
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 포트폴리오 수정페이지 작품 데이터
export const getPortfolioEditData = createAsyncThunk("portfolio/getPortfolioEditData", async (content_id: number, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/portfolio/edit/getData`, {
            content_id
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 포트폴리오 수정
export const updatePortfolio = createAsyncThunk("portfolio/updatePortfolio", async (formData: any, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/portfolio/content/update`, formData)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 포트폴리오 삭제
export const deletePortfolio = createAsyncThunk("portfolio/deletePortfolio", async (content_id: number, { rejectWithValue, getState }) => {
    try {
        const { pubPortfolio }: any = getState();
        return (await api.post(`/api/portfolio/content/delete`, {
            content_id,
            cover_path: pubPortfolio.coverFiles[0]?.file
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 유통시작일 엑셀 업로드
export const circulationExcelUpload = createAsyncThunk("portfolio/excelUpload", async(apiParams:{ file: string, fileName: string }, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/portfolio/excelUpload/circulation/insert`, apiParams);

		return res.data;

	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

export const pubPortfolioSlice = createSlice({
    name: "pubPortfolio",
    initialState,
    reducers: {
        initState: (state, { payload }) => {
            Object.assign(state, initialState);
        },
        setSortOption: (state, { payload }) => {

            state.sortOption = payload;
        },
        setViewOption: (state, { payload }) => {
            state.viewOption = payload;
        },
        setCoverFiles: (state, { payload }) => {
            state.coverFiles = payload;
        },
        selectSeries: (state, { payload }) => {
            const { idx, checked } = payload;
            if (checked) {
                for (let s of state.seriesSearchResult) {
                    s.checked = false;
                }
                state.seriesSearchResult[idx].checked = true;
            } else {
                state.seriesSearchResult[idx].checked = false;
            }
        },
        initSeriesSearchResult: (state) => {
            state.seriesSearchResult = [];
        },
        setRetailInfo: (state, { payload }) => {
            state.series_id = payload.id;
            state.series_name = payload.series_name;
            state.publisher_name = payload.publisher_name;
            state.author_name = payload.author_name;
        },
        initIsbnSearchResult: (state) => {
            state.isbnSearchResult = [];
        },
        selectIsbn: (state, { payload }) => {
            const { idx, checked } = payload;
            state.isbnSearchResult[idx].checked = checked;
        },
        setIsbn: (state) => {
            const isbnList = [];
            for (let i of state.isbnSearchResult) {
                if (i.checked) {
                    isbnList.push(i.isbn);
                }
            }
            state.isbnList = isbnList;
            if (isbnList.length > 1) {
                state.isbnLabel = `${isbnList[0]} 외${isbnList.length-1}개`
            } else if (isbnList.length === 0) {
                state.isbnLabel = "";
            } else {
                state.isbnLabel = isbnList[0];
            }
        },
        editIsbn: (state, { payload }) => {
            state.isbnList = payload.map((p: any) => p.isbn);
            if (payload.length > 1) {
                state.isbnLabel = `${payload[0].isbn} 외${payload.length-1}개`
            } else if (payload.length === 0) {
                state.isbnLabel = "";
            } else {
                state.isbnLabel = payload[0].isbn;
            }
        },
        setPublishAt: (state, { payload }) => {
            state.publishAt = payload;
        },
        setSalesPlatform: (state, { payload }) => {
            state.salesPlatform = payload;
        },
        setThumbnailActive: (state, { payload }) => {
            state.thumbnailActive = payload;
        },
        setThumbnailTop: (state, { payload }) => {
            state.thumbnailTop = payload;
        },
        setPromotionList: (state, { payload }) => {
            state.promotionList = payload;
        },
        setPromotionData: (state, { payload }) => {
            const { key, idx, value } = payload;
            state.promotionList[idx][key] = value;
        },
        setTimelineTotalYearmon: (state, { payload }) => {
            state.timelineData.totalYearmonList = payload;
        },
        setTimelineEachPlatformHistory: (state, { payload }) => {
            state.timelineData.eachPlatformHistory = payload;
        },
        setErrorList: (state, { payload }) => {
            state.errorList = payload;
        },
        setCirculationPeriod: (state, { payload }) => {
            state.salesPlatform[payload.index].circulation_period = payload.circulation_period;
        },
        setCirculationDate: (state, { payload }) => {
            const { index, idx, date, start } = payload;
            if (start) {
                state.salesPlatform[index].circulation_period[idx].start_circulate_at = date;
            } else {
                state.salesPlatform[index].circulation_period[idx].end_circulate_at = date;
            }
        },
        setAllDate: (state, { payload }) => {
            state.allDate = payload;
        },
        setAllPlatform: (state, { payload }) => {
            state.allPlatform = payload;
        },
    },
    extraReducers: {
        [getPortfolioList.pending.type]: (state, { payload }) => {
            state.loading = true;
        },
        [getPortfolioList.fulfilled.type]: (state, { payload }) => {
            state.loading = false;
            state.portfolioList = payload.data;
        },
        [searchIsbn.fulfilled.type]: (state, { payload }) => {
            const data = JSON.parse(payload.data);
            state.isbnSearchResult = data.docs.filter((d: any) => d.EA_ISBN !== "").map((d: any) => ({
                name: d.TITLE,
                author_name: d.AUTHOR,
                publisher: d.PUBLISHER,
                isbn: d.EA_ISBN,
                checked: false,
            }))
        },

        [getPortfolioDetail.fulfilled.type]: (state, { payload }) => {
            const { contentBaseInfo, contentPlatformInfo, contentSalesInfo, latestPromotion, timelineData } = payload.data;
            state.contentBaseInfo = contentBaseInfo;
            state.contentPlatformInfo = contentPlatformInfo;
            state.contentSalesInfo = contentSalesInfo;
            state.latestPromotion = latestPromotion;
            state.timelineData = timelineData;
        },

        [getPortfolioEditData.fulfilled.type]: (state, { payload }) => {
            const { contentBaseInfo, contentPlatformInfo, contentPromotion } = payload.data;
            state.coverFiles = [{ 
                file: contentBaseInfo.cover_path,
                name: "",
            }];
            state.series_id = contentBaseInfo.series_id;
            state.series_name = contentBaseInfo.series_name;
            state.publisher_name = contentBaseInfo.publisher_name;
            state.author_name = contentBaseInfo.author_name;
            state.isbnList = contentBaseInfo.isbn;
            if (contentBaseInfo.isbn.length > 1) {
                state.isbnLabel = `${contentBaseInfo.isbn[0]} 외${contentBaseInfo.isbn.length-1}개`
            } else if (contentBaseInfo.isbn.length === 0) {
                state.isbnLabel = "";
            } else {
                state.isbnLabel = contentBaseInfo.isbn[0];
            }
            state.publishAt = contentBaseInfo.publish_at;
            state.salesPlatform = contentPlatformInfo;
            for (let c of contentPromotion) {
                if (c.promotion_end_at) c.promotion_no_end_date = false;
                else c.promotion_no_end_date = true;
            }
            state.promotionList = contentPromotion;
            state.thumbnailActive = false;
            state.thumbnailTop = contentBaseInfo.thumbnail_top;
        },

        [searchPortfolioList.pending.type]: (state) => {
            state.loading = true;
        },
        [searchPortfolioList.fulfilled.type]: (state, { payload }) => {
            state.portfolioList = payload.data;
            state.loading = false;
        },
    }
});

export default pubPortfolioSlice.reducer;