import styled from "styled-components";
import SelectBox from "@/components/atoms/selectBox/index";
import { ErrorData, SortOptions, ViewOptions } from "@/pages/portfolio/publisher/types";
import { ReactComponent as Card } from "@/assets/icons/portfolio/card.svg";
import { ReactComponent as List } from "@/assets/icons/portfolio/list.svg";
import { colors } from "@/assets/styles/theme";
import Button from "@/components/atoms/button";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { useNavigate } from "react-router-dom";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import ExcelUploadPortfolioCirculationModal from "@/components/molecules/common/modal/ExcelUploadPortfolioCirculationModal";
import { useState } from "react";
import { circulationExcelUpload, getPortfolioList } from "@/pages/portfolio/publisher/slice";
import useAlert from "@/modules/hooks/useAlert";
import { fileAction } from "@/pages/common/fileSlice";
import CirculationErrListModal from "@/components/molecules/common/modal/CirculationErrListModal";
import useLoading from "@/modules/hooks/useLoading";

export default function FilterContainer() {
    const { sortOption, viewOption } = useAppSelector((state: RootState) => state.pubPortfolio);
    const { file } = useAppSelector((state:RootState) => state.file);
    const [showExcelUpload, setShowExcelUpload] = useState(false);
    const [showErrList, setShowErrList] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    const sortOptions = [
        { name: "등록순", value: SortOptions.NEW },
        { name: "가나다순", value: SortOptions.ABC },
    ]

    const changeSortOption = (value:any) => {
        dispatch({ type: "pubPortfolio/setSortOption", payload: value.value });
    }

    const goEnrollPage = () => {
        navigate("/portfolio/enroll");
    }

    const setViewOption = (option: ViewOptions) => {
        dispatch({ type: "pubPortfolio/setViewOption", payload: option });
    }

    const excelUpload = async () => {
        try {
            loadingOn();
            let apiParams = {
                file: file.file,
                fileName: file.fileName
            }
            const errList:ErrorData[] = (await dispatch(circulationExcelUpload(apiParams)).unwrap()).data;
            loadingOff();

            // 업로드 실패한 데이터들 존재
            if (errList && errList.length) {
                dispatch({ type: "pubPortfolio/setErrorList", payload: errList });
                return setShowErrList(true);
            }
            loadingOn();
            await dispatch(fileAction.setFile({ file: "", fileName: "" }));
            await dispatch(getPortfolioList()).unwrap();
            await alert("success", "엑셀업로드 완료", "업로드를 완료하였습니다.");

        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }

    return (
        <StyledContainer>
            <SelectBox
                empty={true}
                labelKey="name"
                valueKey="value"
                selected={sortOption}
                options={sortOptions}
                onClickValueFunc={changeSortOption}
                customStyle={{}}
            />
            <RightWrapper>
                <Card 
                    fill={viewOption === ViewOptions.CARD ? colors.blue500 : colors.gray600} 
                    onClick={() => setViewOption(ViewOptions.CARD)}
                />
                <List 
                    fill={viewOption === ViewOptions.LIST ? colors.blue500 : colors.gray600} 
                    onClick={() => setViewOption(ViewOptions.LIST)}
                />
                <Button 
                    type="main-outline"
                    label="엑셀 업로드"
                    onClickHandler={() => setShowExcelUpload(true)}
                    customStyle={{ width: "100px", height: "32px", margin: "0 0 0 8px" }}
                />
                <Button 
                    type="main"
                    label="작품 등록"
                    onClickHandler={goEnrollPage}
                    customStyle={{ width: "100px", height: "32px", margin: "0 0 0 8px" }}
                />
            </RightWrapper>
            <ModalPortal>
                <ExcelUploadPortfolioCirculationModal
                    from="portfolio"
                    show={showExcelUpload}
                    close={() => setShowExcelUpload(false)}
                    circulationExcelUpload={excelUpload}
                />
            </ModalPortal>
            <ModalPortal>
                <CirculationErrListModal
                    from="portfolio"
                    show={showErrList}
                    close={() => setShowErrList(false)}
                />
            </ModalPortal>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    margin-top: 24px;
`

const RightWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        cursor: pointer;
    }
`