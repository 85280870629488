import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from 'styled-components'
import PageTool from "@/components/molecules/support/guide/PageTool";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Guide() {

    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [ratio, setRatio] = useState(1);
    const [object, setObject] = useState({
        width: 900,
        height: 500,
        scale: 1
    });
    const percentInputRef = useRef<HTMLInputElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isDrag, setIsDrag] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);

    const centerWidth = 900;
    const centerHeight = 500;
    const centerScale = 1;
    

    function onDocumentLoadSuccess({ numPages }: {numPages:number}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const download = () => {
        let fileUrl = `/file/download/support/guide`;
        let link = document.createElement("a");
        if (typeof link.download === "string") {
            document.body.appendChild(link);
            link.download = 'pdf 다운로드';
            link.href = fileUrl;
            link.click();
            document.body.removeChild(link);
        }
    }

    const pageNumInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(e.target.value)) ){
            return;
        }
        const pageNum = Number(e.target.value);
        if (pageNum > 30) {
            setPageNumber(()=> 30);
        } else {
            setPageNumber(() => Number(e.target.value));
        }
    }

    const pageRatioChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (percentInputRef.current != null) {
            if (isNaN(Number(percentInputRef.current.value))) return;
            let filteredRatio = Number(percentInputRef.current.value)/100;
            if (filteredRatio < 0) {
                filteredRatio = 0;
            }
            setRatio(()=> filteredRatio);
        }
    }

    const zoomInHandler = () => {
        let currentRatio = ratio + 0.1;
        setRatio((prev) => (prev + 0.1));
        setObject(()=> ({width: centerWidth * currentRatio, height: centerHeight * currentRatio, scale: centerScale * currentRatio}));
    }

    const zoomOutHandler = () => {
        let currentRatio = ratio - 0.1;
        setRatio((prev) => (prev - 0.1));
        setObject(()=> ({width: centerWidth * currentRatio, height: centerHeight * currentRatio, scale: centerScale * currentRatio}));
    }

    const pageChangeHandler = (e: React.WheelEvent<HTMLDivElement>) => {
        if (e.deltaY < 0 ) {
            if(pageNumber === 1) return;
            setPageNumber((prev)=> prev - 1);
        } else {
            if(pageNumber === 30) return;
            setPageNumber((prev)=> prev + 1);
        }
    }

    const onDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (scrollRef.current != null){
            setIsDrag(true);
            setStartX(e.pageX + scrollRef.current.scrollLeft);
            setStartY(e.pageY + scrollRef.current.scrollTop);
        }
    }

    const onDragEnd = () => {
        setIsDrag(false);
    }

    const onDragMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDrag) {
            if (scrollRef.current != null) {
                const { scrollWidth, clientWidth, scrollHeight, clientHeight, scrollLeft, scrollTop } = scrollRef.current;
                scrollRef.current.scrollLeft = startX - e.pageX;
                scrollRef.current.scrollTop = startY - e.pageY;
                if( scrollLeft === 0) {
                    setStartX(e.pageX);
                } else if (scrollWidth <= clientWidth + scrollLeft) {
                    setStartX(e.pageX + scrollLeft);
                }

                if (scrollTop === 0) {
                    setStartY(e.pageY);
                } else if (scrollHeight <= clientHeight + scrollTop) {
                    setStartY(e.pageY + scrollTop);
                }
            }            
        }
    }
 
    useEffect(() => { 
        setObject(() => ({width: centerWidth*ratio, height: centerHeight*ratio, scale: centerScale*ratio}))
    }, [ratio]);

    return(
        <>
            <PageTool
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                pageNumInputChangeHandler={pageNumInputChangeHandler}
                numPages={numPages}
                zoomOutHandler={zoomOutHandler}
                percentInputRef={percentInputRef}
                pageRatioChangeHandler={pageRatioChangeHandler}
                zoomInHandler={zoomInHandler}
                ratio={ratio}
                download={download}
            />
            <StyledPdfContainer 
                onWheel={pageChangeHandler} 
                ref ={scrollRef} 
                onMouseDown={onDragStart}
                onMouseMove={onDragMove}
                onMouseUp={onDragEnd}
                onMouseLeave={onDragEnd}
            >
                <Document
                    file={`/file/download/support/guide`}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page 
                        width={object.width} 
                        height={object.height} 
                        pageNumber={pageNumber} 
                        scale={object.scale}
                    />
                </Document>
            </StyledPdfContainer>
        </>
        )
}

const StyledPdfContainer = styled.div`
    width: 900px;
    height: 500px;
    margin-top: 24px;
    position: relative;
    overflow : hidden;
`