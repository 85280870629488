import styled from "styled-components";
import { EbookMaker } from "@/types/dataType";
import { ReactComponent as Inpage } from "@/assets/icons/ebook-maker/inpage.svg";
import { ReactComponent as InpageGray } from "@/assets/icons/ebook-maker/inpage-gray.svg";
import { ReactComponent as Inbody } from "@/assets/icons/ebook-maker/inbody.svg";
import { ReactComponent as InbodyGray } from "@/assets/icons/ebook-maker/inbody-gray.svg";
import { ReactComponent as Make } from "@/assets/icons/ebook-maker/make.svg";
import { ReactComponent as MakeGray } from "@/assets/icons/ebook-maker/make-gray.svg";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { convert2Json } from "@/pages/ebook-maker/slice";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function SubtitleTypeCard({ type, label, desc }: CardProps) {
    const { subtitleType } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    const isActive = subtitleType === type;
    
    let icon = <></>
    switch (type) {
        case EbookMaker.SubtitleType.INPAGE:
            icon = isActive ? <Inpage /> : <InpageGray />;
            break;
        case EbookMaker.SubtitleType.INBODY:
            icon = isActive ? <Inbody /> : <InbodyGray />;
            break;
        case EbookMaker.SubtitleType.MAKE:
            icon = isActive ? <Make /> : <MakeGray />;
            break;
        default:
            break;
    }

    const selectSubtitleType = async () => {
        try {
            loadingOn();
            await dispatch(convert2Json(type)).unwrap();
            dispatch({ type: "ebookMaker/setSubtitleType", payload: type });
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    let coloredBox;
    if (type === EbookMaker.SubtitleType.NONE) {
        coloredBox = (
            <ColoredBox onClick={selectSubtitleType} isActive={isActive}>
                <Text text="소제목 없음" customStyle={{ fontSize: "12px", fontWeight: "400", color: colors.gray600, margin: "0 8px 0 0" }} />
                <Clear fill={colors.gray600} width={18} height={18} />
            </ColoredBox>
        )
    } else {
        coloredBox = (
            <ColoredBox onClick={selectSubtitleType} isActive={isActive}>
                <ImgWrapper>
                    {icon}
                </ImgWrapper>
                <DescWrapper>
                    {desc}
                </DescWrapper>
            </ColoredBox>
        )
    }

    return (
        <CardWrapper type={type}>
            {coloredBox}
            <LabelWrapper isActive={isActive} >{label}</LabelWrapper>
        </CardWrapper>
    )
}

const CardWrapper = styled.div<{ type: string; }>`
    width: ${({ type }) => type === EbookMaker.SubtitleType.NONE ? "180px" : "300px"};
    height: 160px;
`

const ColoredBox = styled.div<{ isActive: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    background-color: ${({ isActive }) => isActive ? colors.blue50 : colors.gray50};
    border: 1px solid ${({ isActive }) => isActive ? colors.gray500 : colors.gray600};
    border-radius: 4px;
    cursor: pointer;
`

const ImgWrapper = styled.div`
    width: 120px;
    height: 120px;
`

const DescWrapper = styled.div`
    width: 180px;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    white-space: pre-line;
    color: ${colors.gray600};
`

const LabelWrapper = styled.div<{ isActive: boolean; }>`
    padding: 11px 0;
    text-align: center;
    font-size: 14px;
    color: ${({ isActive }) => isActive ? colors.blue500 : colors.gray600};
    font-weight: 500;
`

interface CardProps {
    type: string;
    label: string;
    desc: string;
}