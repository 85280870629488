import { createSlice } from "@reduxjs/toolkit";

interface FileState {
    file: { file: string, fileName: string };
}

const initialFileState: FileState = {
    file: { file: "", fileName: "" }
};

const fileSlice = createSlice({
    name: "file",
    initialState: initialFileState,
    reducers: {
        setFile(state, action) {
            state.file = action.payload;
        },
    },
});

export const fileAction = fileSlice.actions;
export default fileSlice.reducer;
