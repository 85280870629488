import { RootState, useAppSelector } from "@/modules/store";
import { SortOptions, ViewOptions } from "@/pages/portfolio/publisher/types";
import styled from "styled-components";
import CardViewContainer from "./CardViewContainer";
import ListViewContainer from "./ListViewContainer";
import { useState, useEffect } from "react";
import { Portfolio } from "@/pages/portfolio/publisher/types";
import Text from "@/components/atoms/text";

export default function SeriesListContainer({ page, itemPerPage }: ComponentProps) {
    const { sortOption, viewOption, portfolioList } = useAppSelector((state: RootState) => state.pubPortfolio);
    const [currList, setCurrList] = useState<Portfolio[]>([]);

    useEffect(() => {
        let tmpList = JSON.parse(JSON.stringify(portfolioList));
        if (sortOption === SortOptions.ABC) {
            tmpList = tmpList.sort((a: any, b: any) => {
                if (a.title > b.title) return 1;
                if (a.title < b.title) return -1;
                return 0;
            });
        } else {
            tmpList = tmpList.sort((a: any, b: any) => {
                if (a.created_at > b.created_at) return -1;
                if (a.created_at < b.created_at) return 1;
                return 0;
            });
        }

        tmpList = tmpList.splice((page-1) * itemPerPage, itemPerPage)
        setCurrList(tmpList)
    }, [page, portfolioList, sortOption])

    return (
        <StyledContainer>
            { currList.length === 0 ?
                <NoData>
                    <Text text="검색결과가 없습니다." customStyle={{ fontSize: "14px", fontWeight: "600" }} />
                </NoData>
            :
                viewOption === ViewOptions.CARD 
                    ? <CardViewContainer currList={currList} /> 
                    : <ListViewContainer currList={currList} />
                }
            
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    margin-top: 24px;
    width: 100%;
`

const NoData = styled.div`
    display: flex;
    width: 100%;
    height: 272px;
    justify-content: center;
    align-items: center;
`

interface ComponentProps {
    page: number;
    itemPerPage: number;
}