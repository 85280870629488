import { useEffect } from "react";
import { useAppDispatch } from "@/modules/store";
import { resetStore } from "@/pages/settlement/sales-entry/mg/mgSlice";
import MgButtons from "@/components/molecules/sales-entry/mg/MgButtons";
import MgEnrollFilter from "@/components/molecules/sales-entry/mg/MgEnrollFilter";
import MgTable from "@/components/molecules/sales-entry/mg/MgTable";

export default function Mg() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetStore());
        };
    }, []);

    return (
        <div>
            <MgEnrollFilter />
            <MgButtons />
            <MgTable />
        </div>
    );
}
