import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setParamsId } from "@/pages/settlement/saels-inquiry/author/etc/etcSlice";

function useEtcApi() {
    const {
        reason,
        startDate,
        endDate,
        startAmount,
        endAmount,
        selectedAuthor,
        selectedPublisher,
        startDateError,
        endDateError,
        initialDataLoaded,
        fetchLoading,
    } = useSelectorTyped((state: RootState) => state.inquiryAuthorEtc);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!initialDataLoaded && fetchLoading) return;
        if (startDateError !== "" || endDateError !== "") return;

        dispatch(setParamsId());
        return () => { }

    }, [
        startDate,
        endDate,
        selectedAuthor,
        selectedPublisher,
        startAmount,
        endAmount,
        reason
    ]);
}

export default useEtcApi;
