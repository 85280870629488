import styled from "styled-components";
import ApexCharts from 'react-apexcharts';
import { Oval } from "react-loader-spinner";
import { useAppSelector, RootState } from "@/modules/store";
import { colors } from '@/assets/styles/theme';

function SalesAnalysisWrapper() {
    const { 
        dailySalesAnalysis, monthlySalesAnalysis, salesAnalysIsLoading, analysisType
    } = useAppSelector((state: RootState) => state.authorDashboard);

    if (salesAnalysIsLoading) {
        return (
            <div>
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
            </div>
        )
    }

    let salesAnalysis;
    if (analysisType === "daily") {
        salesAnalysis = dailySalesAnalysis;
    } else {
        salesAnalysis = monthlySalesAnalysis;
    }

    const salesAnalysisData: ChartOptions = {
        series: [
            {
                name: '이번주',
                type: 'column',
                data: salesAnalysis.map(d => d.amount)
            }, 
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                foreColor: colors.gray600,
                toolbar: {
                    show: false
                },
                events: {
                    dataPointSelection: function(event: Event, chartContext: any, config: any) {
                    },
                }
            },
            colors: [function({ value, dataPointIndex, w}: any) {
                if (dataPointIndex === salesAnalysis.length-1) {
                    return colors.blue500;
                } else {
                    return colors.blue50;
                }
            }],
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8
                    }
                }
            },
            stroke: {
                width: [0, 4, 5],
                curve: 'smooth',
            },
            plotOptions: {
                bar: {
                    columnWidth: '38%',
                    borderRadius: 25,
                }
            },
            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            labels: salesAnalysis.map(d => d.label),
            markers: {
                size: 0
            },
            xaxis: {
                type: 'string',
                crosshairs: {
                    show: false
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                labels: {
                    formatter: function(val: number) {
                        return `${(val / 10000).toFixed(0)}만원`
                    }
                }
            },
            tooltip: {
                shared: false,
                intersect: true,
                followCursor: false,
                custom: function({ series, seriesIndex, dataPointIndex, w }: any) {
                    return `
                        <div class="tooltip-box">
                            <span>${series[seriesIndex][dataPointIndex].toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원"}</span>
                        </div>
                    `
                },
            },
            grid: { show: false },
        },
    };

    return (
        <div>
            <StyledCharts
                options={salesAnalysisData.options}
                series={salesAnalysisData.series}
                types="line"
                width={800}
                height={480}
            />
        </div>
    )
}

const StyledCharts = styled(ApexCharts)`
    margin-top: 10px;
    .tooltip-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.blue50};
        border: 1px solid ${colors.blue500};
        border-radius: 4px;
        width: 85px;
        height: 32px;
        font-size: 10px;
    }
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 150px;
`

interface ChartOptions {
    series: Array<SeriesType>;
    options: object;
}

interface SeriesType {
    name: string;
    type: string;
    data: Array<number>
}

export default SalesAnalysisWrapper;