import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

// components
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";
import ModalSearchBar from "@/components/molecules/common/searchBar/modalSearchBar/ModalSearchBar";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";

// modules
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import { series, Validation } from "@/modules/utils/validate_modify";
import {
    setItemValue,
    setItem,
    setItems,
    setNonMatchedData,
    setMatchedData,
    setAddedSeriesRatioList,
} from "@/pages/settlement/sales-entry/monthly/monthlySlice";

// utils
import useUtils from "@/components/molecules/sales-entry/monthly/hooks/useUtils";
import usePlatformSetting from "@/components/molecules/sales-entry/common/hooks/usePlatformSetting";
import useAlert from "@/modules/hooks/useAlert";

// type
import type { SearchSeriesResult, SelectedSeries } from "@/types/searchSeries";
import type { CommonType } from "@/types/dataType";

export default function SeriesNameField({ item, idx }: SeriesNameFieldProps) {
    const { items, platformId, yearmon_st, nonMatchedData, matchedData, afterExcelUpload, addedSeriesRatioList } =
        useSelectorTyped((state: RootState) => state.entryMonthly);
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);
    const { calcAmountAu, addSeriesRatio } = useUtils();
    const { platformName } = usePlatformSetting({ platformId });
    const { alert } = useAlert();
    const handleModal = useCallback(() => setShowModal((prev) => !prev), []);

    // 시리즈 모달에서 시리즈 선택
    const applySeries = (data: SearchSeriesResult) => {
        const { selectedSeries } = data;
        const value = selectedSeries as SelectedSeries;

        let newItems = items.map((item, index) => {
            // 선택한 작품 & 선택한 작품과 같은 isbn 가진 작품들 정보 변경
            if (index === idx || item.seriesNo && item.seriesNo === items[idx].seriesNo) {
                let newItem = {...item};

                newItem.series_id = value.id;
                newItem.series_name = value.series_name;
                newItem.author_id = value.author_id;
                newItem.author_name = value.author_name;
                newItem.author_ratio = value.ratio;
                newItem.seriesError = "";
                newItem.authorRatioError = "";
                return newItem;
            }

            return item;
        });

        // 작가 정산금 추가
        calcAmountAu(newItems[idx]);
        
        // 변경한 시리즈 정산비율 추가
        addSeriesRatio({
            ratio: newItems[idx].ratio,
            series_id: newItems[idx].id,
            platform_id: newItems[idx].platform_id
        });

        dispatch(setMatchedData(matchedData.filter((l) => item.seriesNo !== l.seriesNo)));
        dispatch(setNonMatchedData(nonMatchedData.filter((l) => item.seriesNo !== l.seriesNo)));
        dispatch(setItems(newItems));
    };

    const applySeriesRatio = (data: any) => {
        let newRatioList = [...addedSeriesRatioList];
        for (let i = 0; i < newRatioList.length; i++) {
            if (newRatioList[i].series_id === data.series_id) {
                newRatioList[i] = { ...newRatioList[i], ratio: data.ratio };
                dispatch(setAddedSeriesRatioList(newRatioList));
                return;
            }
        }
        dispatch(setAddedSeriesRatioList([...addedSeriesRatioList, data]));
    };

    const changeSeries = (keyword:string) => {
        dispatch(setItemValue({ index:idx, key:'series_name', value:keyword }));
    }

    const validateSeries = async () => {
        try {
            const res = (await series({
                value: item.series_name,
                error: "seriesError",
                platform: platformId,
                mandatory: true,
                yearmonSt: yearmon_st,
            })) as Validation;

            let newItem = {...item};
            if (res.value) {
                const { series_id, series_name, author_id, author_name, ratio } = res.value;

                newItem.series_id = series_id;
                newItem.series_name = series_name;
                newItem.author_id = author_id;
                newItem.author_name = author_name;
                newItem.author_ratio = ratio;
                newItem.seriesError = res.seriesError;

                if (ratio === null) {
                    newItem.needRatio = true;
                    newItem.authorRatioError = "작가 정산비율을 입력해주세요.";
                } else {
                    newItem.needRatio = false;
                    newItem.authorRatioError = "";
                }

                // 작가 정산금 추가
                calcAmountAu(newItem);
                dispatch(setItem({ index:idx, item:newItem }));
            
                if (afterExcelUpload) {
                    let newItems = items.map((item, index) => {
                        if (index === idx) return newItem;
                        // 선택한 작품과 같은 isbn 가진 작품들도 변경
                        if (item.seriesNo && item.seriesNo === items[idx].seriesNo) {
                            let newItem = {...item};
                            
                            newItem.needRatio = false;
                            newItem.series_id = series_id;
                            newItem.series_name = series_name;
                            newItem.author_id = author_id;
                            newItem.author_name = author_name;
                            newItem.author_ratio = ratio;
                            newItem.seriesError = "";

                            if (ratio === null) {
                                newItem.needRatio = true;
                                newItem.authorRatioError = "작가 정산비율을 입력해주세요.";
                            } else {
                                newItem.needRatio = false;
                                newItem.authorRatioError = "";
                            }
                            // 작가 정산금 추가
                            calcAmountAu(newItem);
                            return newItem;
                        }
                        return item;
                    })

                    dispatch(setItems(newItems));
                    dispatch(setMatchedData(matchedData.filter((l) => item.seriesNo !== l.seriesNo)));
                    dispatch(setNonMatchedData(nonMatchedData.filter((l) => item.seriesNo !== l.seriesNo)));
                }
            } 
            else {
                newItem.series_id = 0;
                newItem.series_name = item.series_name;
                newItem.author_id = 0;
                newItem.author_name = "";
                newItem.author_ratio = "";
                newItem.seriesError = res.seriesError;

                dispatch(setItem({ index:idx, item:newItem }));
            }
        } catch (err) {
            if (err) alert("error", "오류 발생", "시리즈 조회 중 오류가 발생하였습니다.");
        }
    };

    return (
        <>
            <ValidateWrapper dataError={item["seriesError"]}>
                <ModalSearchBar
                    type="default"
                    placeholder="시리즈명"
                    value={item["series_name"]}
                    className="search-series-modal"
                    onHandleModal={handleModal}
                    onChange={changeSeries}
                    onKeyUp={validateSeries}
                    customStyle={{ width: "100%" }}
                />
            </ValidateWrapper>
            <ModalPortal>
                <SearchSeriesModal
                    from=""
                    show={showModal}
                    close={handleModal}
                    platformId={platformId}
                    platformName={platformName}
                    withRatio={true}
                    multiSelect={false}
                    applySeries={applySeries}
                    applySeriesRatio={applySeriesRatio}
                />
            </ModalPortal>
        </>
    );
}

interface SeriesNameFieldProps {
    item: any;
    idx: number;
    platformId: CommonType.Id;
    yearmonSt: CommonType.Date;
}
