import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as LeftIC } from "@/assets/icons/left.svg";
import usePagination from "@/components/molecules/common/pagination/usePagination";

function Pagination({ from, pageCount = 1, totalVisible = 10, customStyle }: PaginationProps) {
    const { setPage, page } = usePagination({ from });

    const clickPage = (id: number) => () => setPage(id);

    const clickPageButton = (dir: "left" | "right") => () => {
        if (dir === "left") page > 1 && setPage(page - 1);
        else page < pageCount && setPage(page + 1);
    };
    
    return (
        <Container margin={customStyle?.margin}>
            <PrevButton
                $activated={page > 1}
                fill={page > 1 ? colors.blue500 : colors.gray600}
                onClick={clickPageButton("left")} 
            />
            <PageList
                pageCount={pageCount}
                totalVisible={totalVisible}
                currentPage={page}
                onClickPage={clickPage}
            />
            <NextButton 
                $activated={page < pageCount}
                fill={page < pageCount ? colors.blue500 : colors.gray600}
                onClick={clickPageButton("right")} 
            />
        </Container>
    );
}

function PageList({ pageCount, totalVisible, currentPage, onClickPage }: PageListProps) {
    // case 1) 줄임표 없이 전부 표시 
    if (pageCount <= totalVisible) {
        const pageArray = Array.from({ length: pageCount }, (_,i) => i + 1);
        return (
            <PageWrapper>{pageArray.map(page => (
                <Page
                    key={page} 
                    active={page === currentPage}
                    onClick={onClickPage(page)}
                >
                    <span>{page}</span>
                </Page>
            ))}
            </PageWrapper>
        )
    }

    let pageList:(string|number)[] = [];
    const ellipsis = "...";
    const half = totalVisible / 2;
    const halfFloor = Math.floor(totalVisible / 2); 
    
    // case 2) 가운데에 줄임표 ex) 1,2,3 ... 7,8,9
    if (currentPage < halfFloor || currentPage > pageCount - half + 2) {
        for (let i = 0, j = totalVisible - 1; i < totalVisible; i++, j--) {
            if (i < halfFloor) pageList[i] = i + 1;
            else if (i === halfFloor) pageList[i] = ellipsis;
            else pageList[i] = pageCount - j;
        }
    } 
    // case 3) 오른쪽에 줄임표 ex) 1,2,3,4,5 ... 9
    else if (currentPage <= Math.ceil((totalVisible + 1)/2)) {
        for (let i = 0; i < totalVisible; i++) {
            if (i < totalVisible - 2) pageList[i] = i + 1;
            else if (i === totalVisible - 2) pageList[i] = ellipsis;
            else pageList[i] = pageCount;
        }
    } 
    // case 4) 왼쪽에 줄임표 ex) 1 ... 5,6,7,8,9
    else if (currentPage > pageCount - 3) {
        pageList = [1, ellipsis];
        for (let i = 0; i < totalVisible - 2; i++) {
            pageList[totalVisible - i - 1] = pageCount - i;
        }
    } 
    // case 5) 양쪽에 줄임표 ex) 1 ... 4,5,6 ... 9
    else {
        pageList = [1, ellipsis];
        const left = Math.ceil((totalVisible - 5) / 2);
        for (let i = 2, j = 0; i < totalVisible - 2; i++, j++) {
            pageList[i] = currentPage - left + j;
        }
        pageList = [...pageList, ellipsis, pageCount]
    }

    return (
        <PageWrapper>
            {pageList.map((page, index) => {
                if (typeof page === 'string') return <Ellipsis key={`ellipsis${index}`}>{page}</Ellipsis>
                else return (
                    <Page
                        key={page} 
                        active={page === currentPage} 
                        onClick={onClickPage(page)}
                    >
                        <PageNum>{page}</PageNum>
                    </Page>
                )
            })}
        </PageWrapper>
    );
}

const Container = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    height: 40px;
    margin: ${({ margin }) => (margin ? margin : "40px 0 0 0")};
    cursor: pointer;
`;
const PrevButton = styled(LeftIC)<{ $activated: boolean }>`
    margin-right: 8px;
    cursor: ${({ $activated }) => $activated ? 'pointer' : 'auto'};
`
const NextButton = styled(LeftIC)<{ $activated: boolean }>`
    margin-left: 8px;
    transform: rotate(180deg);
    cursor: ${({ $activated }) => $activated ? 'pointer' : 'auto'};
`
const PageWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
`;
const Ellipsis = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: ${({ theme }) => theme.colors.gray500};
`
const Page = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${({ active, theme }) => active && theme.colors.blue500};
    color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.gray600)};
`;
const PageNum = styled.p`
    font-size: 14px;
    transform: translate(0px, -1px);
`

interface PaginationProps {
    from: string;
    pageCount: number;
    totalVisible: number;
    customStyle?: StyleProps;
}
interface PageListProps {
    pageCount: number;
    totalVisible: number;
    currentPage: number;
    onClickPage: (id: number) => () => void;
}
interface StyleProps {
    margin?: string;
}

export default Pagination;