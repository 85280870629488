import styled from "styled-components";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import useAlert from "@/modules/hooks/useAlert";
import React, { useEffect, useState, useRef } from "react";
import useLoading from "@/modules/hooks/useLoading";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { colors } from "@/assets/styles/theme";
import Checkbox from "@/components/atoms/input/Checkbox";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { searchIsbn } from "@/pages/portfolio/publisher/slice";
import { IsbnSearchResult } from "@/pages/portfolio/publisher/types";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import Pagination from "@/components/molecules/common/pagination/Pagination";

export default function SearchIsbnModal({ show, close, from }: ComponentProps) {
    const { isbnSearchResult } = useAppSelector((state: RootState) => state.pubPortfolio);
    const { setDataCount, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "searchIsbn", initialValue: {
        itemsPerPage: 5,
        totalVisible: 6,
        page: 1,
    }});
    const [keyword, setKeyword] = useState("");
    const [allCheck, setAllCheck] = useState(false);
    const [currList, setCurrList] = useState<IsbnSearchResult[]>([]);
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const dispatch = useAppDispatch();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const tmpList = isbnSearchResult.slice().splice((page-1) * itemsPerPage, itemsPerPage);
        setCurrList(tmpList);
        if (isbnSearchResult.length) {
            setDataCount(isbnSearchResult.length);
        }
    }, [isbnSearchResult]);

    useEffect(() => {
        const tmpList = isbnSearchResult.slice().splice((page-1) * itemsPerPage, itemsPerPage);
        setCurrList(tmpList)
        validatAllCheck(tmpList);
    }, [page])

    useEffect(() => {
        const ret = currList.filter(c => c.checked);
        if (ret.length === 5) setAllCheck(true);
        else setAllCheck(false);
    }, [currList]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        } 
    });

    const validatAllCheck = (tmpList: IsbnSearchResult[]) => {
        const ret = tmpList.filter(c => c.checked);
        if (ret.length === itemsPerPage) {
            setAllCheck(true)
        } else {
            setAllCheck(false);
        }
    }

    const apply = async () => {
        try {
            const selected = isbnSearchResult.find(s => s.checked);
            if (!selected) return alert("warning", "확인", "isbn을 선택해주세요.");

            const answer = await alert("confirm", "적용 확인", "적용 하시겠습니까?");
            if (answer === "Y") {
                dispatch({ type: "pubPortfolio/setIsbn" });
                reset();
                close();
            }
        } catch (err) {
            alert("error", "오류 발생", "적용중 오류가 발생하였습니다.");
        }
    }

    const reset = () => {
        setKeyword("");
        setAllCheck(false);
        setCurrList([]);
        dispatch({ type: "pubPortfolio/initIsbnSearchResult" });
    }

    const changeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    }

    const selectedIsbn = (checked: boolean, data: IsbnSearchResult) => {
        const idx = isbnSearchResult.indexOf(data);
        dispatch({ type: "pubPortfolio/selectIsbn", payload: {
            idx,
            checked   
        }});
    }

    const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAllCheck(e.target.checked);
        const from = (page - 1) * 5;
        for (let idx = from; idx < from + 5; idx++) {
            dispatch({ type: "pubPortfolio/selectIsbn", payload: {
                idx,
                checked: e.target.checked  
            }});
        }
    }

    const search = async (type: string) => {
        try {
            await loadingOn();
            await dispatch(searchIsbn(keyword)).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["ISBN 검색"]}
            customStyle={[{ width: "960px", height: "460px" }]}
            applyFunc={apply}
            reset={reset}
        >
            <BodyWrapper>
                <SearchInputWrapper>
                    <SearchInput 
                        ref={inputRef}
                        value={keyword}
                        onChange={changeKeyword}
                        placeholder="검색어를 입력해주세요."
                        onKeyPress={(e) => e.key === "Enter" ? search("init") : () => {}}
                    />
                    <IconWrapper>
                        <SearchIcon 
                            size={24} 
                            fill={colors.blue500} 
                            onClick={search}
                        />
                    </IconWrapper>
                </SearchInputWrapper>
                <Table>
                    <Thead>
                        <Tr>
                            <Th width="48px" align="center">
                                <Checkbox
                                    type="boolean"
                                    active={allCheck}
                                    onChangeHandler={(e) => handleAllCheck(e)}
                                />
                            </Th>
                            <Th width="320px">시리즈명</Th>
                            <Th width="180px">저자명</Th>
                            <Th width="180px">출판사</Th>
                            <Th width="192px">ISBN</Th>
                        </Tr>
                    </Thead>
                    {currList.length ? 
                        <Tbody>
                            {currList.map((c, i) => (
                                <Tr key={i}>
                                    <Td width="48px" align="center">
                                        <Checkbox
                                            type="boolean"
                                            active={c.checked}
                                            onChangeHandler={(e) => selectedIsbn(e.target.checked, c)}
                                        />
                                    </Td>
                                    <Td width="320px">
                                        {c.name}
                                    </Td>
                                    <Td width="180px">
                                        {c.author_name}
                                    </Td>
                                    <Td width="180px">
                                        {c.publisher}
                                    </Td>
                                    <Td width="192px">
                                        {c.isbn}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody> : 
                        <Nodata />
                    }
                </Table>
                <PaginationWrapper>
                    <Pagination 
                        pageCount={pageCount} 
                        totalVisible={totalVisible} 
                        from="searchIsbn" 
                        customStyle={{ margin: "8px 0 0 0" }}
                    />
                </PaginationWrapper>
            </BodyWrapper>
        </BaseModal>
    )
}

const SearchInputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 920px;
    height: 40px;
    background-color: ${colors.white};
    padding-left: 8px;
    border-radius: 4px;
    border: 1px solid ${colors.blue500};
    margin: auto;
`

const SearchInput = styled.input`
    font-size: 12px;
    width: 880px;
    border: unset;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 40px;
    height: 100%;
    padding: 8px;
    
    svg {
        cursor: pointer;
    }
`

const BodyWrapper = styled.div`
    padding: 0 20px;
`

const Table = styled.table`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 288px;
    min-height: 288px;
    max-height: 288px;
`;

const Thead = styled.thead`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid ${colors.gray300};
`;

const Tr = styled.tr`
    display: flex;
    height: 48px;
    width: 100%;
`;

const Th = styled.th<{ width?: string; align?: string; }>`
    display: flex;
    align-items: center;
    justify-content: ${({ align }) => align ? align : "unset"};
    height: 100%;
    width: ${({ width }) => (width ? width : "120px")};
    font-size: 12px;
    font-weight: 500;
`;

const Tbody = styled.tbody`
    align-items: center;
    width: 100%;
    height: 240px;
`;

const Nodata = styled(Tbody)`
    background-color: ${colors.gray50};
`

const Td = styled.td<{ width: string; align?: string; }>`
    display: flex;
    align-items: center;
    justify-content: ${({ align }) => align ? align : "unset"};
    height: 100%;
    width: ${({ width }) => width};
    font-size: 12px;
    font-weight: 400;
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
`

interface ComponentProps {
    show: boolean;
    close: () => void;
    from: string;
}