import { useState } from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import styled from "styled-components";

import { ReactComponent as Logo } from "@/assets/icons/mob-landing/footer-gling-logo.svg";
import { ReactComponent as DownIC } from "@/assets/icons/down.svg";

export default function MobFooter() {
    const [showInfo, setShowInfo] = useState(false);
    
    const clickInfoTab = () => setShowInfo(prev => !prev);

    return (
        <Contaienr show={showInfo}>
            <Logo />
            <TabBar>
                <BizInfoTab onClick={clickInfoTab}>
                    <BizInfoText>사업자 정보</BizInfoText>
                    <StyledDownIC style={{ transform: showInfo && `rotate(180deg)`}} fill="white"/>
                </BizInfoTab>
                {/* {tabs.map(tab => <OtherTabs onClick={goScreen(tab.route)}>{tab.name}</OtherTabs>)} */}
            </TabBar>

            <Transition in={showInfo} timeout={{ appear: 0, enter: 0, exit: 300 }} unmountOnExit>
                {(mountState) => (
                    <BizInfoBox show={showInfo} mountState={mountState}>
                        <InfoTextBox>
                            <Bold>뷰컴즈 주식회사</Bold>
                        </InfoTextBox>
                        <InfoTextBox>
                            <SpanBox><Bold>대표</Bold> : 김학성</SpanBox>
                            <SpanBox><Bold>전화</Bold> : 1811-8389</SpanBox>
                            <SpanBox><Bold>이메일</Bold> : help@gling.co.kr</SpanBox> 
                        </InfoTextBox>
                        <InfoTextBox>
                            <SpanBox><Bold>사업자 등록번호</Bold> : 492-88-01088</SpanBox>
                            <SpanBox><Bold>통신판매업신고번호</Bold> : 2022-서울영등포-1768</SpanBox>
                        </InfoTextBox>
                        <InfoTextBox>
                            <Bold>주소</Bold> : 서울시 영등포구 당산로 171 금강펜테리움IT타워 1301호
                        </InfoTextBox>
                    </BizInfoBox>
                )}
             </Transition>

            <Hr />
            <Copyright>Copyright © viewcommz Corp, All Rights Reserved.</Copyright>
        </Contaienr>
    )
}

const tabs = [
    { name: '이용약관', route: 'info/agreement'},
    { name: '이용약관', route: 'info/agreement'},
    { name: '이용약관', route: 'info/agreement'},
]

const Contaienr = styled.div<{ show: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 160px;
    padding: 24px 20px;
    color: ${({ theme }) =>theme.colors.white};
    background-color: ${({ theme }) => theme.colors.gray900};
`
const TabBar = styled.div`
    display: flex;
    margin: 8px 0px;
`
const BizInfoTab = styled.div`
    display: flex;
    align-items: center;
`
const BizInfoText = styled.p`
    font-size: 14px;
    font-weight: 700;
`
const StyledDownIC = styled(DownIC)`
    margin-left: 8px;
`
const OtherTabs = styled.div`
    font-size: 14px;
    font-weight: 500;
    & + & {
        margin-left: 16px;
    }
`

const BizInfoBox = styled.div<{ show: boolean; mountState: TransitionStatus }>`
    line-height: 21px;
    display: ${({ show, mountState }) => mountState === 'exited' && (show === false ? 'none' : 'block')};
    max-height: ${({ show, mountState }) => {
        switch (mountState) {
            case "entered":
                return show === true ? `200px` : "0px";
            case "exiting":
                return show === false ? "0px" : `200px`;
            default:
                return "0px";
        }
    }};
    transition: all 0.3s cubic-bezier(0.4, 0, 0.6, 1);
    overflow: hidden;

`

const Hr = styled.hr`
    width: 100%;
    border-color: ${({ theme }) => theme.colors.gray600};
`
const Copyright = styled.p`
    font-size: 10px;
    margin-top: 8px;
`

// 사업자 정보
const InfoTextBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
`
const Bold = styled.span`
    font-weight: 700;
`
const SpanBox = styled.span`
    white-space: pre-wrap;
    & + & {
        &::before {
            content: " | ";
        }
    }
`