export interface StatementState {
    specificationType: SpecificationType;
    yearmon: string;
    publisherInfo: PublihserInfo;
    platformList: number[];
    monthList: string[];
    allCheck: boolean;
    authorList: Author[];
    currAuthor: Author;
    salesList: Sales[];
    mgList: Mg[];
    etcList: Etc[];
    totalData: TotalData;
    taxList: Tax[]; 
    briefs: string[];
    stampFiles: FileType[];
    stampSize: {
        width: number;
        height: number;
    }
    stampPosition: {
        x: number;
        y: number;
    },
    mgPayment: {
        co_ratio: number;
        contract_amount: number;
        date: string;
        mg_ratio: number;
        out_date: string;
        payment_amount: number;
        platform_name: string;
        remain_amount: number;
        series_name: string;
    }
}

// 정산서 발급 / 조회
export enum StatementType {
    Issue = "issue",
    Inquiry = "inquiry"
}

// 명세서 구분 (월정산 명세서 / 선인세 지급 명세서)
export enum SpecificationType {
    Monthly = "monthly",
    Mg = "mg"
}

export interface Author {
    author_names: string;
    user_id: number;
    user_name: string;
    checked: boolean;
    out_date?: string;
    author_name?: string;
    id?: number;
    author_id?: number;
}

interface PublihserInfo {
    publisher_addr: string;
    publisher_ceo_name: string;
    publisher_email: string;
    publisher_name: string;
    publisher_pic_path: string;
    publisher_reg: string;
    publisher_tel: string;
    publisher_url: string;
}

interface Sales {
    amount_au: number;
    amount_pl: number;
    amount_pu: number;
    author_id: number;
    author_tax_type: string;
    co_ratio: number;
    name: string;
    platform_name: string;
    ratio: number;
    series_ie: number;
    series_name: string;
    tax_ratio: number;
    yearmon: string;
    withMg?: boolean;
}

interface Tax {
    tax_amount: number;
    tax_desc: string;
    tax_type: string;
}

interface TotalData {
    etcSubTotal: number;
    salesSubTotal: number;
    taxSubTotal: number;
    total: number;
}

interface Etc {
    amount: number;
    author_id: number;
    author_tax_ratio: number;
    author_tax_type: string;
    co_ratio: number;
    date: string;
    etc_id: number;
    etc_tax_type: string;
    platform_name: string;
    pre_amount: number;
    reason: string;
    st_ratio: number;
    tax_desc: string;
}

interface Mg {
    amount: number;
    author_id: number;
    co_ratio: number | null;
    curr_deduction_price: number;
    date: string;
    deduction_price: number;
    platform_name: string;
    pre_deduction_price: number;
    remain_price: number;
    series_id: number[];
    series_name: string;
    tax_ratio: number;
    tax_type: string;
}

export interface FileType {
    name: string;
    file: string;
}