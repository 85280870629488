import { useAppDispatch } from "@/modules/store";
import { setDate, setPlatformId, resetItems, resetStore } from "@/pages/settlement/sales-entry/daily/dailySlice";
import { resetDataTable } from "@/pages/common/dataTableSlice";
import usePlatformSetting from "@/components/molecules/sales-entry/common/hooks/usePlatformSetting";
import { getToday } from "@/modules/utils/date";

const useUtils = (platformId: number, date: string) => {
    const dispatch = useAppDispatch();
    const { entrySalesPlatformList, platformList, platformForm, initialPlatformId, platformQty } = usePlatformSetting({ uploadType: "daily", platformId, date: date });

    const initialize = () => {
        dispatch(setDate(getToday()));
        dispatch(setPlatformId(initialPlatformId));
    };

    const reset = (withCondition?: boolean) => {
        dispatch(resetItems());
        dispatch(resetDataTable());

        if (withCondition) {
            dispatch(resetStore());
            dispatch(setPlatformId(initialPlatformId));
            dispatch(setDate(getToday()));
        }
    };

    return {
        platformForm,
        initialPlatformId,
        platformList,
        initialize,
        reset,
        entrySalesPlatformList,
        platformQty,
    };
};

export default useUtils;
