import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as CompanyIcon } from "@/assets/icons/company.svg";
import { ReactComponent as PersonalIcon } from "@/assets/icons/personal.svg";
import { useAppDispatch } from "@/modules/store";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { signupActions } from "@/pages/auth/signUp/signupSlice";

export default function JoinWay() {
    return (
        <SignUpFormWrapper>
            <SignForm name="personal" />
            <SignForm name="company" />
        </SignUpFormWrapper>
    );
}

function SignForm({ name }: { name: string }) {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [color, setColor] = useState(colors.blue50);
    let logo, title, comment, path:string;

    if (name === "company") {
        logo = <CompanyIcon />;
        title = "출판사 회원";
        comment = "출판사 직원이신가요?";
        path = "/signup/publisher";
    } else {
        logo = <PersonalIcon fill={color} />;
        title = "개인 회원";
        comment = "작가님이신가요?";
        path = "/signup/author";
    }

    function clickJoin() {
        if (name === "company") dispatch(signupActions.setPublisherJoin(true));
        else dispatch(signupActions.setPublisherJoin(false));
        navigate(path)
    }

    return(
        <FormWrap onMouseLeave={() => name === "personal" ? setColor(colors.blue50) : {}} onMouseEnter={() => name === "personal" ? setColor(colors.white) : {}} onClick={clickJoin} >
            <LogoWrap>{logo}</LogoWrap>
            <Text text={title} customStyle={{ fontSize: "24px", fontWeight: "700", lineHeight: "30px", margin: "0 0 8px 0" }} />
            <Text text={comment} customStyle={{ fontSize: "14px", lineHeight: "24px", fontWeight: "400" }} />
            {name === "company" &&
                <AbsoluteText>*사업자 등록번호 ‘10자리’가 필요합니다.</AbsoluteText>}
        </FormWrap>
    )
}

const SignUpFormWrapper = styled.form`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
`;

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 400px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    cursor: pointer;
    :nth-child(1) {
        margin-right: 24px;
    }
    :hover {
        background: ${({ theme }) => theme.colors.blue50};
        border: 1px solid ${({ theme }) => theme.colors.blue500};
    }
`

const LogoWrap = styled.div`
    margin-bottom: 40px;
`

const AbsoluteText = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray600};
    margin: 16px 0 0 0;
    text-align: center;
    position: absolute;
`