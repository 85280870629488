import styled from "styled-components";
import { useState, useRef, useEffect, ReactComponentElement } from "react";
import { RootState, useAppSelector } from "@/modules/store";
import { ReactComponent as Letter } from "@/assets/icons/letter.svg";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { Oval } from "react-loader-spinner";
import PdfStatusContainer from "./PdfStatusContainer";
import Draggable from "react-draggable";
import { pdfjs, Document, Page } from "react-pdf";
import { SpecificationType } from "@/pages/settlement/statement/author/types";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function PdfViewer() {
    const [pageLength, setPageLength] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(100);
    const { yearmon, currPublisher, specificationType } = useAppSelector((state: RootState) => state.authorStatement);
    let pdfUrl = "";
    if (specificationType === SpecificationType.Monthly) {
        pdfUrl = `/file/download/royalty-statement/author?month=${yearmon}&publisher=${currPublisher.publisher_id}`;
    } else {
        pdfUrl = `/file/download/mg-statement/author?month=${yearmon}&id=${currPublisher.id}&publisher=${currPublisher.publisher_id}`
    }
    const pdfWrapper = useRef<HTMLDivElement>();

    const preventDefault = (e: any) => {
        e.preventDefault();
    }

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setPageLength(numPages);
    }

    const mouseWheelPage = (e: any) => {
        if (e.ctrlKey) {
            // 확대, 축소
            if (e.deltaY < 0) {
                setScale(scale + 10);
            } else {
                setScale(scale - 10);
            }
        } else {
            // 다음, 이전 페이지
            if ((e.deltaY < 0) && (pageNumber-1 > 0)) {
                setPageNumber(pageNumber-1);
            } else if ((e.deltaY >= 0) && (pageNumber+1 <= pageLength)) {
                setPageNumber(pageNumber+1);
            }
        }

    }

    const pageHandler = (page: number) => {
        setPageNumber(page);
    }

    const scaleHandler = (scale: number) => {
        setScale(scale);
    }

    const loadingComponent = (
        <LoaderWrapper>
            <Oval color={colors.blue600} secondaryColor={colors.blue100} />
        </LoaderWrapper>
    )

    useEffect(() => {
        setPageNumber(1);
        if (pdfWrapper && pdfWrapper.current) {
            pdfWrapper.current.addEventListener("wheel", preventDefault);
        }

        return () => {
            if (pdfWrapper && pdfWrapper.current) {
                pdfWrapper.current.removeEventListener("wheel", preventDefault);
            }
        }
    }, [currPublisher])

    return (
        <StyledContainer>
            <PdfStatusContainer 
                pageNumber={pageNumber}
                pageLength={pageLength}
                scale={scale}
                pageHandler={pageHandler}
                scaleHandler={scaleHandler}
                pdfUrl={pdfUrl}
            />
            {currPublisher.publisher_id ? 
                <PdfWrapper 
                    ref={pdfWrapper} 
                    onWheel={mouseWheelPage}
                >
                    <Draggable>
                        <div>
                            <Document 
                                file={pdfUrl} 
                                loading={loadingComponent}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page 
                                    width={920}
                                    loading={loadingComponent}
                                    pageNumber={pageNumber} 
                                    scale={scale/100}
                                />
                            </Document>
                        </div>
                    </Draggable>
                </PdfWrapper> :
                <NoPdfWrapper>
                    <Letter />
                    <Text text="발급된 정산서가 없습니다." customStyle={{ fontSize: "28px", fontWeight: "700", margin: "54px 0 0 0" }} />
                    <Text text="불러올 수 있는 데이터가 없습니다. 다시 한 번 확인해주세요." customStyle={{ fontSize: "16px", fontWeight: "400", margin: "24px 0 0 0", color: colors.gray500 }} />
                </NoPdfWrapper>
            }
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 960px;
    height: 1340px;
`

const PdfWrapper = styled.div<{ ref?: any }>`
    display: inline-block;
    width: 100%;
    height: 1340px;
    padding: 18px;
    background-color: ${colors.gray300};
    margin-top: 14px;
    overflow: hidden;
    cursor: grab;
`

const NoPdfWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 14px;
    background-color: ${colors.gray50};
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 600px;
`
