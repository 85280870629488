import styled from "styled-components";
import Text from "@/components/atoms/text";
import DailySeriesAnalysisChartWrapper from "@/components/molecules/dashboard/author/DailySeriesAnalysisChartWrapper";
import { useAppSelector, RootState } from "@/modules/store";
import { Oval } from "react-loader-spinner";
import { colors } from "@/assets/styles/theme";

export default function DailyContentAnalysisChart() {
    const { dailySeriesAnalysisLoading } = useAppSelector((state: RootState) => state.authorDashboard);

    if (dailySeriesAnalysisLoading) {
        return (
            <StyledContainer>
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
            </StyledContainer>
        )
    }

    return (
        <StyledContainer>
            <TopLineWrapper />
            <DailySeriesAnalysisChartWrapper />
        </StyledContainer>
    )
}

function TopLineWrapper() {
    const textStyle = {
        fontSize: "16px",
        fontWeight: "700"
    }

    return (
        <StyledTopLineWrapper>
            <Text text="일일 작품 분석" customStyle={textStyle}/>
        </StyledTopLineWrapper>
    )
} 

const StyledContainer = styled.div`
    width: 360px;
    height: 350px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

const StyledTopLineWrapper = styled.div`
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`