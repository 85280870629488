import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setParamsId } from "@/pages/settlement/saels-inquiry/publisher/etc/etcSlice";

function useEtcApi() {
    const {
        startDate,
        endDate,
        selectedPlatform,
        author_name,
        startAmount,
        endAmount,
        reason,
        startDateError,
        endDateError,
        initialDataLoaded,
        fetchLoading,
    } = useSelectorTyped((state: RootState) => state.inquiryEtc);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!initialDataLoaded && fetchLoading) return;
        if (startDateError !== "" || endDateError !== "") return;

        dispatch(setParamsId());
        return () => { }

    }, [
        startDate,
        endDate,
        selectedPlatform,
        author_name,
        startAmount,
        endAmount,
        reason,
        dispatch
    ]);
}

export default useEtcApi;
