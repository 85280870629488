import styled from "styled-components";
import _ from "lodash";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { updateRow } from "@/pages/common/dataTableSlice";
import { initialItem, setItem, setItems, setLastId, setPlatformId } from "@/pages/settlement/sales-entry/etc/etcSlice";
import Text from "@/components/atoms/text";
import TextInput from "@/components/atoms/input/TextInput";
import SelectBox from "@/components/atoms/selectBox/index";
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import AddLine from "../common/AddLine";
import Tooltip from "../../common/tooltip/Tooltip";
import CustomDatePicker from "../../common/date-picker/CustomDatePicker";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import EtcAuthorSearchBox from "@/components/molecules/sales-entry/etc/EtcAuthorSearch";
import EtcTaxAmount from "./EtcTaxAmount";
import EtcTaxType from "./EtcTaxType";
import EtcReason from "./EtcReason";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import usePlatformSetting from "../common/hooks/usePlatformSetting";
import useCalculateAmount from "@/components/molecules/sales-entry/etc/hooks/useCalculateAmount";
import { amount, ratio } from "@/modules/utils/validate_modify";
import { addComma, removeComma } from "@/modules/utils/filter";
import type { Item } from "@/pages/settlement/sales-entry/etc/types";


export default function EtcTable() {
    const { items, lastId, platformId } = useSelectorTyped((state: RootState) => state.entryEtc);
    const dispatch = useAppDispatch();
    const { pageCount, totalVisible } = usePagination({ from: "etc", tableData: items });
    const initPlatformId = (id: number) => dispatch(setPlatformId(id));
    const { selectBoxOptions } = usePlatformSetting({ platformId, initPlatformId });
    const { calculateAmount } = useCalculateAmount();
    
    const applyAddLine = (rowAdd: number) => {
        let tmpLastId = lastId;
        let tmpItems: Item[] = [];
        for (let i = 0; i < rowAdd; i++) {
            let item: Item = { ...initialItem, id: (tmpLastId += 1) };
            tmpItems.push(item);
        }
        dispatch(setItems([...items, ...tmpItems]));
        dispatch(setLastId(tmpLastId));
    };

    const columns = {
        // 플랫폼
        platform_id: ({ item, index }: TableItemProps) => {
            const changePlatform = (v: any) => {
                const newItem = { ...item, platform_id: v.platform_id };
                dispatch(setItem({ index, item: newItem }));
                dispatch(updateRow({ itemKey: "etc_id", updatedRow: newItem }));
            };
            return (
                <SelectBox
                    valueKey="platform_id"
                    labelKey="platform_name"
                    options={selectBoxOptions}
                    selected={item["platform_id"] || null}
                    onClickValueFunc={changePlatform}
                />
            );
        },
        // 입금일자
        in_date: ({ item, index }: TableItemProps) => {
            const changeDate = (date:string) => {
                dispatch(setItem({ index, item: { ...item, in_date: date } }));
            }

            return (
                <CustomDatePicker
                    settingDate={item["in_date"]}
                    unsetButton
                    onChange={changeDate}
                    customStyle={datePickerStyle}
                />
            );
        },
        // 지급일자
        date: ({ item, index }: TableItemProps) => {
            const changeDate = (date: string) => {
                dispatch(setItem({ index, item: { ...item, date, dateError: "" } }));
            };
            return (
                <ValidateWrapper dataError={item["dateError"]}>
                    <CustomDatePicker 
                        settingDate={item["date"]}
                        onChange={changeDate}
                        customStyle={datePickerStyle}
                    />
                </ValidateWrapper>
            );
        },
        // 저자명
        authorInfo: ({ item, index }: TableItemProps) => (
            <EtcAuthorSearchBox item={item} index={index} />
        ),
        // 금액
        pre_amount: ({ item, index }: TableItemProps) => {
            const changeAmount = (value: string) => {
                const res = amount({ field: "금액", value, error: "preAmountError", decimal: true, mandatory: true });
                let newItem = _.cloneDeep(item);
                newItem.pre_amount = res.value;
                newItem.preAmountError = res.preAmountError;

                calculateAmount(newItem);
                dispatch(setItem({ index, item: newItem }));
            };
            const handleComma = (type:string) => () => {
                const newItem = { ...item, pre_amount: type === 'remove' ? removeComma(item.pre_amount) : addComma(item.pre_amount) };
                dispatch(setItem({ index, item: newItem }));
            }
            
            return (
                <ValidateWrapper dataError={item["preAmountError"]}>
                    <TextInput
                        type={item.needCoRatio ? "readOnly" : "main"}
                        placeholder="금액(원)"
                        value={item["pre_amount"]}
                        onChangeHandler={(e) => changeAmount(e.target.value)}
                        onFocusHandler={handleComma("remove")}
                        onBlurHandler={handleComma("")}
                        customStyle={inputStyle}
                    />
                </ValidateWrapper>
            );
        },
        // 정산 비율
        st_ratio: ({ item, index }: TableItemProps) => {
            const changeStRatio = (value: string) => {
                const res = ratio({ field: "정산비율", value, error: "stRatioError", mandatory: true, decimal: true });
                let newItem = _.cloneDeep(item);
                newItem.st_ratio = res.value;
                newItem.stRatioError = res.stRatioError;

                calculateAmount(newItem);
                dispatch(setItem({ index, item: newItem }));
            };
            const handleComma = (type:string) => () => {
                const newItem = { ...item, st_ratio: type === 'remove' ? removeComma(item.st_ratio) : addComma(item.st_ratio) };
                dispatch(setItem({ index, item: newItem }));
            }

            return (
                <ValidateWrapper dataError={item["stRatioError"]}>
                    <TextInput
                        type={item.needCoRatio ? "readOnly" : "main"}
                        placeholder="정산비율"
                        value={item["st_ratio"]}
                        onChangeHandler={(e) => changeStRatio(e.target.value)}
                        onFocusHandler={handleComma("remove")}
                        onBlurHandler={handleComma("")}
                        customStyle={inputStyle}
                    />
                </ValidateWrapper>
            );
        },
        // 적용 세목
        tax_type: ({ item, index }: TableItemProps) => {
            const dispatchItem = (newItem:any) => dispatch(setItem({ index, item: newItem }));
            return <EtcTaxType item={item} changeItem={dispatchItem} />
        },
        // 적용 세액
        tax_amount: ({ item, index }: TableItemProps) => (
            <EtcTaxAmount item={item} index={index} />
        ),
        // 지급 금액
        amount: ({ item, index }: TableItemProps) => {
            const changeAmount = (amount: string) => {
                dispatch(setItem({ index, item: { ...item, amount } }));
            };
            return (
                <TextInput
                    type="readOnly"
                    placeholder="지급금액(원)"
                    value={item["amount"]}
                    onChangeHandler={(e) => changeAmount(e.target.value)}
                    customStyle={inputStyle}
                />
            )
        },
        // 지급 사유
        reason: ({ item, index }: TableItemProps) => {
            const dispatchItem = (newItem:any) => dispatch(setItem({ index, item: newItem }));
            return <EtcReason item={item} changeItem={dispatchItem} />
        }
    };

    return (
        <>
            <TableWrapper>
                <DataTable
                    from="etc"
                    itemKey="id"
                    items={items}
                    headers={headers}
                    customHeaders={customHeaders}
                    columns={columns}
                />
                <AddLine onApplyAddLine={applyAddLine} />
            </TableWrapper>
            <Pagination from="etc" pageCount={pageCount} totalVisible={totalVisible} />
        </>
    );
}

const customHeaders = {
    name: () => (
        <CustomHeaderWrapper>
            <Text text="적용세액" customStyle={textStyle} />
            <Tooltip
                iconWidth={20}
                iconHeight={20}
                textBoxWidth={280}
                fill={colors.gray600}
                text="원천세(국내 거주 개인)의 경우 각 항목에 대한 예상 세액으로\n'+
                '실제 정산서 발급 시와는 다를 수 있습니다."
            />
        </CustomHeaderWrapper>
    ),
};

// 테이블 데이터
const headers = [
    { text: "플랫폼", value: "platform_id", width: "120px" },
    { text: "입금 일자", value: "in_date", width: "140px" },
    { text: "지급 일자", value: "date", width: "140px" },
    { text: "저자명", value: "authorInfo", width: "200px" },
    { text: "금액(원)", value: "pre_amount", width: "120px" },
    { text: "정산 비율(%)", value: "st_ratio", width: "100px" },
    { text: "적용 세목", value: "tax_type", width: "300px" },
    { text: "적용 세액", value: "tax_amount", width: "120px" },
    { text: "예상 지급 금액(원)", value: "amount", width: "140px" },
    { text: "지급 사유", value: "reason", width: "160px" },
];

const inputStyle = {
    width: "100%",
    height: "32px",
    textAlign: "right",
    fontSize: "12px",
};
const textStyle = {
    margin: "0 8px 0 0",
    fontSize: "12px",
};

const datePickerStyle = {
    width: "110px",
};
const TableWrapper = styled.div`
    position: relative;
    width: 1620px;
`;
const CustomHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;