import styled from "styled-components";
import Button from "@/components/atoms/button";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { EbookMaker } from "@/types/dataType";

export default function CopyrightTypeWrapper() {
    const { copyrightType } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const btnLabelList = [
        { label: "만들지 않음", value: EbookMaker.CopyrightType.NONE },
        { label: "신규 생성", value: EbookMaker.CopyrightType.MAKE },
        { label: "본문 내 포함", value: EbookMaker.CopyrightType.INFILE }
    ]

    const clickTypeBtn = (value: string) => {
        dispatch({ type: "ebookMaker/setCopyrightType", payload: value });
    }

    return (
        <StyledWrapper>
            <LabelWrapper>판권지 포함 여부</LabelWrapper>
            <BtnWrapper>
                {btnLabelList.map(b => (
                    <Button
                        type={copyrightType === b.value ? "main-outline" : "gray-outline"}
                        label={b.label}
                        onClickHandler={() => clickTypeBtn(b.value)}
                        customStyle={{ width: "120px", margin: "0 8px 0 0" }}
                        key={b.value}
                    />
                ))}
            </BtnWrapper>
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    display: flex;
    height: 60px;
    margin-top: 24px;
`

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 280px;
    height: 60px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
    padding: 21px 24px;
    font-size: 14px;
    font-weight: 500;
`

const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    margin-left: 20px;
    padding-left: 20px;
`