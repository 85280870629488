import styled from "styled-components";
import SelectBox from "@/components/atoms/selectBox/index";
import Text from "@/components/atoms/text";
import LaunchingSeriesChartWrapper from "@/components/molecules/dashboard/publisher/LaunchingSeriesChartWrapper";
import theme from "@/assets/styles/theme";
import { Oval } from "react-loader-spinner";
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";
import { getLatestLaunchingSeries } from "@/pages/dashboard/publisher/slice";
import Tooltip from "@/components/molecules/common/tooltip/Tooltip";

export default function LaunchingSeriesChartContainer() {
    const { launchingSeriesLoading } = useAppSelector((state: RootState) => state.pubDashboard);

    if (launchingSeriesLoading) {
        return (
            <StyledContainer>
                <LoaderWrapper>
                    <Oval color={theme.colors.blue600} secondaryColor={theme.colors.blue100} />
                </LoaderWrapper>
            </StyledContainer>
        )
    }

    return (
        <StyledContainer>
            <TopLineWrapper />
            <LaunchingSeriesChartWrapper />
        </StyledContainer>
    )
}

function TopLineWrapper() {
    const { launchingPeriod } = useAppSelector((state: RootState) => state.pubDashboard);
    const dispatch = useAppDispatch();

    const periodList = [
        { name: "30일", value: 30 },
        { name: "14일", value: 14 },
        { name: "7일", value: 7 },
    ]

    const textStyle = {
        fontSize: "16px",
        fontWeight: "700"
    }

    const selectPeriod = (value: any) => {
        dispatch({ type: "pubDashboard/setLaunchingPeriod", payload: value.value });
        dispatch(getLatestLaunchingSeries(value.value));
    }

    return (
        <StyledTopLineWrapper>
            <FlexBox>
                <Text text="최근 런칭 작품" customStyle={textStyle}/>
                <Tooltip 
                    iconWidth={24} 
                    iconHeight={24} 
                    fill={theme.colors.gray600} 
                    text={`${launchingPeriod}일 이내 런칭 작품의 판매현황을 보여주는 차트입니다.`} 
                />
            </FlexBox>
            <SelectBox
                labelKey="name"
                valueKey="value"
                options={periodList}
                selected={launchingPeriod}
                onClickValueFunc={selectPeriod}
            />
        </StyledTopLineWrapper>
    )
} 

const StyledContainer = styled.div`
    width: 360px;
    height: 350px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

const StyledTopLineWrapper = styled.div`
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
`

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 128px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`