import SectionLayout from "../SectionLayout";
import StartGlingContainer from "@/components/molecules/introduce/startCard/StartGlingContainer";
import FreeContainer from "@/components/molecules/introduce/startCard/FreeContainer";
import styled from 'styled-components'
export default function StartFreeContainer() {
    return (
        <StyledContainer>
            <StartGlingContainer/>
            <FreeContainer/>
        </StyledContainer>

    )
}


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 1200px;
    height: 100vh;
    padding-top: 72px;
    overflow: hidden;
    justify-content: center;
    background: linear-gradient(143.85deg, #F8F9FA 33.3%, #F1F3F5 83.68%);
`