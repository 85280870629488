import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from '@/assets/styles/theme';
import { useState, ChangeEvent } from "react";
import useAlert from "@/modules/hooks/useAlert";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import { updatePassword } from "@/pages/mypage/slice";
import Button from "@/components/atoms/button";
import PwWrap from "@/components/molecules/signup/PwWrap";
import useLoading from "@/modules/hooks/useLoading";

export default function ChangePassword() {
    const { alert } = useAlert();
    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();

    const { user_id } = useAppSelector((state: RootState) => state.user);
    const [inputs, setInputs] = useState({
        nowPassword: "",
        newPassword: "",
        newPasswordCheck: ""
    });
    const [nowPwError, setNowPwError] = useState("");
    const [pwError, setPwError] = useState("");
    const [checkPwError, setCheckPwError] = useState("");

    const changeHandlerNowPw = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        setInputs({
            ...inputs,
            nowPassword: value
        });

        if (value.length > 7 && value.length < 21 && /^.*(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(value)) {
            setNowPwError("");
        } else {
            setNowPwError("비밀번호는 영문 / 숫자 포함 8~20자 조합입니다.");
        }
    }

    const changeHandlerPW = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        setInputs({
            ...inputs,
            newPassword: value
        });

        if (value.length > 7 && value.length < 21 && /^.*(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(value)) {
            setPwError("");
        } else {
            setPwError("영문 / 숫자 포함 8~20자 조합으로 입력해주세요.");
        }

        if (!inputs.newPassword || value === inputs.newPasswordCheck) {
            setCheckPwError("");
        } else {
            setCheckPwError("비밀번호가 같지 않습니다");
        }
    }

    const changeHandlerCheckPW = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        setInputs({
            ...inputs,
            newPasswordCheck: value
        });

        if (value === inputs.newPassword) {
            setCheckPwError("");
        } else {
            setCheckPwError("비밀번호가 같지 않습니다");
        }
    }

    const validateData = () => {
        let hasError = false;

        if(!inputs.nowPassword) {
            setNowPwError("현재 비밀번호를 입력해주세요.");
            hasError = true;
        }
        if(!inputs.newPassword) {
            setPwError("새 비밀번호를 입력해주세요.");
            hasError = true;
        }
        if(!inputs.newPasswordCheck) {
            setCheckPwError("새 비밀번호를 재입력해주세요.");
            hasError = true;
        }

        if (nowPwError || pwError || checkPwError || hasError) {
            return alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
        }
        if (inputs.newPassword !== inputs.newPasswordCheck) {
            return alert("warning", "주의", "새 비밀번호와 비밀번호 확인이 일치하지 않습니다.");
        }
        if (inputs.nowPassword === inputs.newPassword) {
            return alert("warning", "주의", "현재 비빌번호와 새 비밀번호가 동일합니다.");
        }

        submitHandler();
    }

    const submitHandler = async () => {
        try {
            loadingOn();
            const apiParams = {
                userInfo: { user_id },
                user_pw : inputs.newPassword,
                user_original_pw: inputs.nowPassword
            }
            const { status, message } = await dispatch(updatePassword(apiParams)).unwrap();
            if (status === 'fail') {
                return alert("warning", "주의", message);
            }
            await alert("success", "비밀번호 변경 완료", "비밀번호가 변경되었습니다.");

            setInputs({
                nowPassword: "",
                newPassword: "",
                newPasswordCheck: ""
            });
        } catch (err: any) {
            alert("error", "오류 발생", "비밀번호 변경 중 오류가 발생하였습니다.");
        } finally {
            loadingOff();
        }
    }

    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    }

    const explainTextStyled = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24.5px",
        color: colors.black,
    }

    const buttonStyled = {
        width: '400px',
        height: '40px',
        margin: '0px 0px 8px 0px',
        borderRadius: '4px',
        fontWeight: '500',
        fontSize: '16px',
    }

    return (
        <MypageSectionLayout>
            <StyledTitleContainer>
                <Text text="비밀번호 변경" customStyle={titleStyled} />
            </StyledTitleContainer>
            <ExplainContainer>
                <Text text="숫자와 특수문자를 사용한 비밀번호를 통해" customStyle={explainTextStyled} />
                <Text text="내 정보를 안전하게 보호하세요!" customStyle={explainTextStyled} />
            </ExplainContainer>
            <PwWrap 
                labelName="현재 비밀번호 입력" 
                inputPlaceholder="현재 비밀번호 입력" 
                inputID="password"
                value={inputs.nowPassword} 
                errorMsg={nowPwError} 
                onChange={changeHandlerNowPw}
                onKeyPress={changeHandlerNowPw}
            />
            <PwWrap 
                labelName="새 비밀번호 입력" 
                inputPlaceholder="새 비밀번호 입력" 
                inputID="password"
                value={inputs.newPassword} 
                errorMsg={pwError} 
                onChange={changeHandlerPW}
                onKeyPress={changeHandlerPW}
            />
            <PwWrap 
                labelName="새 비밀번호 확인" 
                inputPlaceholder="새 비밀번호 확인" 
                inputID="password"
                value={inputs.newPasswordCheck} 
                errorMsg={checkPwError} 
                onChange={changeHandlerCheckPW}
                onKeyPress={changeHandlerCheckPW}
            />
            <Button 
                label="변경하기"
                onClickHandler={validateData} 
                customStyle={buttonStyled}
            />
        </MypageSectionLayout>
    )
}


const MypageSectionLayout = styled.div`
`

const StyledTitleContainer = styled.div`
    display: flex;
    margin: 0px 0px 24px 0px;
`

const ExplainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 0px 24px 0px;
`