import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { ReactComponent as ClearMark } from "@/assets/icons/clear.svg";
import theme from "@/assets/styles/theme";

import TextInput from "@/components/atoms/input/TextInput";
import Button from "@/components/atoms/button";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import { useEffect, useState } from "react";
import Text from "@/components/atoms/text";
import ModalTooltip from "@/components/molecules/common/tooltip/ModalTooltip";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import AuthorSearch from "@/components/molecules/basedata-manage/AuthorSearch";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { seriesActions } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import NoData from "@/components/molecules/basedata-manage/NoData";
import ModalPortal from "@/components/molecules/common/modal//Portal";
import SetSettlementRatioModal from "@/components/molecules/common/modal/SetSettlementRatioModal";
import { ratio, newSeries, Validation } from "@/modules/utils/validate_modify";

export default function ExcelUploadSeriesModalBody({ from }:ExcelUploadSeriesBodyProps) {
    const dispatch = useAppDispatch();
    const { page, itemsPerPage, pageCount, totalVisible, setItemsPerPage, setDataCount } = usePagination({ from: "ExcelUploadSeries" });

    let [ seriesAddDatas, ] = useAppSelector((state:RootState) => [
        state.series.seriesAddDatas
    ]);
    const [showSetSettlementRatioModal, setShowSetSettlementRatioModal] = useState(false);

    useEffect(()=> {
        return() => {
            dispatch(seriesActions.resetEnrollSeries());
        }
    }, [dispatch])

    useEffect(() => {
        setDataCount(seriesAddDatas.length)
        setItemsPerPage(5);
    }, [seriesAddDatas.length, setDataCount, setItemsPerPage])

    const changeValue = async(e: React.ChangeEvent<HTMLInputElement>, key: string, idx: number, item: any) => {
        let index = ((page-1) * itemsPerPage) + idx;
        let value = e.target.value;
        switch (key) {
            case "ratioAll":
                const res = ratio({ value, error: "ratioError", mandatory: false, field: "정산비율", decimal: false }) as Validation;
                let ratios = JSON.parse(JSON.stringify(seriesAddDatas[index].ratios));
                for (let l of ratios) {
                    l.ratio = res.value;
                    if (l.period.length > 0) {
                        l.period[0].ratio = res.value;
                    } else {
                        l.period.push({
                            ratio: res.value,
                            startYearmon: "000000",
                            endYearmon: "999999",
                        })
                    }
                }
                let updatedRow = {
                    ...seriesAddDatas[index],
                    'ratioError': res.ratioError,
                    'ratioAll': res.value,
                    'ratios': seriesAddDatas[index].ratios.map(r =>
                                { return {...r, 'ratio': res.value, 'period': ratios[seriesAddDatas[index].ratios.indexOf(r)].period } }
                            )
                }
                dispatch(seriesActions.setSeriesDatas({
                    key: "seriesAddDatas", index: index, value: updatedRow
                }))
                break;
            case "name":
                dispatch(seriesActions.setEnrollSeriesDatas({
                    index: index, key: key, value: value
                }))
                const validateSeriesName = (await newSeries({ value, error: "seriesError", mandatory: true, id: item["id"] })) as Validation;
                dispatch(seriesActions.setEnrollSeriesDatas({
                    index: index, key: "seriesError", value: validateSeriesName.seriesError
                }))
                break;
            default:
                break;
        }
    }

    async function deleteRow(idx:number) {
        let index = ((page-1) * itemsPerPage) + idx;
        dispatch(seriesActions.deleteAddDataRow(index));
    }

    function setSettlementRatioModal(idx:number) {
        let index = ((page-1) * itemsPerPage) + idx;
        setShowSetSettlementRatioModal(true);
        dispatch(seriesActions.setSeriesDatas({
            key: "index", value: index
        }))
        dispatch(seriesActions.setSeriesDatas({
            key: "ratios", value: seriesAddDatas[index].ratios
        }))
        // dispatch(modalAction.setModalData({ key: "ratios", value: seriesAddDatas[idx].ratios }));
        // dispatch(modalAction.setModalData({ key: "from", value: "base-series-excelUpload" }));
        // dispatch(seriesAction.setIndex(idx));
        // dispatch(modalAction.pushModal({ name: "SetSettlementRatio" }))
    }

    function closeModal() {
        setShowSetSettlementRatioModal(false);
    }

    function selectAuthor(index: number, author: any) {
        let updatedRow = {
            ...seriesAddDatas[index],
            'author': author,
            'authorError': "",
        } 
        dispatch(seriesActions.setSeriesDatas({
            key: "seriesAddDatas", index: index, value: updatedRow
        }));
    }

    function setCoRatio(idx:number, co_info: any) {
        dispatch(seriesActions.setIndex(idx));
        dispatch(seriesActions.setSeriesDatas({
            // key: "tmpCoInfo", value: author.co_info
            key: "tmpCoInfo", value: co_info
        }));
    }

    function deleteAuthor(idx: number) {
        let updatedRow = {
            ...seriesAddDatas[idx],
            'author': {
                author_id: 0,
                author_name: "",
                co_info: [],
            },
            'authorError': "저자명을 선택해주세요.",
        } 
        dispatch(seriesActions.setSeriesDatas({
            key: "seriesAddDatas", index: idx, value: updatedRow
        }));
    }

    function applyCoInfo(co_info:any, idx:number) {
        let index = ((page-1)*itemsPerPage)+idx;
        let updatedRow = {
            ...seriesAddDatas[index],
            'author': {
                ...seriesAddDatas[index].author,
                "co_info": co_info,
            },
            "authorError": ""
        } 
        dispatch(seriesActions.setSeriesDatas({
            key: "seriesAddDatas", index: index, value: updatedRow
        }));
    }

    return (
        <>
            <BodyWrap>
                <FieldRow>
                    <ColumnName>
                        <Text text="시리즈명" customStyle={{ fontSize: "12px" }}/>
                    </ColumnName>
                    <ColumnName>
                        <Text text="저자명" customStyle={{ fontSize: "12px" }}/>
                    </ColumnName>
                    <ColumnName width="130px">
                        <Text text="정산비율" customStyle={{ fontSize: "12px", margin: "0 8px 0 0" }}/>
                        <ModalTooltip
                            id={"excelSeries0"}
                            iconWidth={20} 
                            iconHeight={20} 
                            textBoxWidth={240}
                            fill={theme.colors.gray600} 
                            text="출판사가 작가에게 지급하는 비율입니다."
                        />    
                    </ColumnName>
                    <ColumnName width="142px" justifyContent="center">
                        <Text text="비율 상세설정" customStyle={{ fontSize: "12px" }}/>
                    </ColumnName>
                    <IconWrap height="48px" disable={true} />
                </FieldRow>
                {
                seriesAddDatas.length === 0 ?
                    <NoData />
                :
                seriesAddDatas.slice((page - 1) * 5, page * 5).map((data, i) => 
                    <ItemRow key={i}>
                        <Item>
                            <ValidateWrapper dataError={data.seriesError}>
                                <TextInput
                                    value={data.name}
                                    placeholder="시리즈명"
                                    customStyle={{ width: "280px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                    onChangeHandler={(e) => changeValue(e, "name", i, data)}
                                />
                            </ValidateWrapper>
                        </Item>
                        <Item>
                            <ValidateWrapper dataError={data.authorError}>
                                <AuthorSearch
                                    idx={((page-1)*itemsPerPage) + i}
                                    from="series-enroll"
                                    placeholder="저자명"
                                    value={data.author.author_name}
                                    author={data.author}
                                    customStyle={{ width: "160px", height: "32px", fontSize: "12px", fontWeight: "400" }}
                                    buttonCustomStyle={{ type: "main-outline", label: "공저간 비율 설정", customStyle: { width: "120px", height: "32px", fontSize: "14px", fontWeight: "500", color: theme.colors.blue500}}}
                                    selectAuthorFunc={selectAuthor}
                                    deleteAuthorFunc={deleteAuthor}
                                    applyCoInfoFunc={applyCoInfo}
                                />
                            </ValidateWrapper>
                        </Item>
                        <Item width="130px">
                            {
                                data.hasDetailOptions ?
                                <TextInput
                                    type="readOnly"
                                    value="플랫폼 별 설정"
                                    onChangeHandler={(e) => {}}
                                    customStyle={{ width: "110px", height: "32px", fontSize: "12px", fontWeight: "400" }}
                                />
                                :
                                <ValidateWrapper dataError={data.ratioError}>
                                    <PercentInputText
                                        placeholder="정산비율"
                                        type={data.ratioAll === "" ? "text" : "number"}
                                        value={data.ratioAll || ""}
                                        inText="%"
                                        customStyle={{ width: "110px", height: "32px", fontSize: "12px", fontWeight: "400", padding: "0 18px 2px 10px" }}
                                        onChange={(e) =>changeValue(e, "ratioAll", i, data)}
                                    />
                                </ValidateWrapper>

                            }
                        </Item>
                        <Item width="142px" justifyContent="center" >
                            <Button
                                type="main-outline"
                                label="상세설정"
                                customStyle={{ width: "120px", height: "32px" }}
                                onClickHandler={(e) => setSettlementRatioModal(i)}
                            />
                        </Item>
                        { seriesAddDatas.length === 1 ?
                        <IconWrap disable={true} />
                        :
                        <IconWrap onClick={() => deleteRow(i)}>
                            <ClearMark width="24px" height="24px" fill={theme.colors.gray600} />
                        </IconWrap>}
                    </ItemRow>
                    )
                }
                {
                    seriesAddDatas.length > 4 &&
                    <Pagination pageCount={pageCount} totalVisible={totalVisible} customStyle={{ margin: "0" }} from="ExcelUploadSeries"/>
                }
            </BodyWrap>
            <ModalPortal>
                <SetSettlementRatioModal
                    from={from}
                    show={showSetSettlementRatioModal}
                    close={closeModal}
                />
            </ModalPortal>
        </>
    )

}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 0 20px;
    height: 388px;
    // max-height: 298px;
`
const ItemRow = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`

const Item = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "300px"};
    height: 60px;
    font-size: 12px;
    padding: 8px;
`

const IconWrap = styled.div<{ direction?: string, disable?: boolean, height?: string }>`
    display: flex;
    flex-direction: ${({ direction }) => direction ? direction : "row"};
    align-items: center;
    justify-content: center;
    width: 48px;
    height: ${({ height }) => height ? height : "60px"};
    font-size: 12px;
    padding: 8px;
    cursor: ${({ disable }) => disable ? "default" : "pointer"};
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
`

const ColumnName = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "300px"};
    height: 48px;
    font-size: 12px;
    padding: 8px;
`

interface ExcelUploadSeriesBodyProps {
    from: string;
}