import styled from "styled-components";
import Text from "@/components/atoms/text";
import TicketBg from "@/assets/images/ticket-bg.png"
import { colors } from "@/assets/styles/theme";
import { NavLink } from "react-router-dom";
import {PriceType, Card} from "@/components/molecules/landing/pricing/types"

export default function PlanCard(props: PlanCardProps) {
    const textStyle = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
        color: colors.black,
        textAlian: "center"
    }

    const explainTextStyle = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
        color: `${props.planCardData.typeExplainTextColor}`,
        textAlian: "center",
        margin: "0px 30px 0px 30px"
    }

    const nowPriceStyle = {
        fontWeight: "700",
        fontSize: "72px",
        lineHeight: "60px",
        color: `${props.planCardData.nowPriceTextColor}`
    }

    const moneyUnitStyle = {
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "24px",
        color: colors.gray600,
    }

    const nowPriceTextStyle = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "16px",
        color: colors.black
    }
    
    const nowPriceExplanStyle = {
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "10px",
        color: colors.gray600
    }

    const showNowPrice = (type: number) => {
        switch(type) {
            case PriceType.Free:
                return (<NowPriceTextBox>
                    <Text text={props.planCardData.nowPrice} customStyle={nowPriceStyle} />
                    <Text text='원' customStyle={moneyUnitStyle} />
                </NowPriceTextBox>)
            case PriceType.Standard:
                return (
                    <NowPriceBox>
                        <NowPriceExplain>
                            <Text text={props.planCardData.nowPriceText} customStyle={nowPriceTextStyle}></Text>
                            <NowPriceTextBox>
                                <Text text={props.planCardData.nowPrice} customStyle={nowPriceStyle} />
                                <Text text='원' customStyle={moneyUnitStyle} />
                            </NowPriceTextBox>
                        </NowPriceExplain>
                        <Text text={props.planCardData.nowPriceExplain} customStyle={nowPriceExplanStyle}></Text>
                    </NowPriceBox>
                    )
                
            case PriceType.Enterprise:
                return (<NowPriceTextBox style={{alignItems:"center"}}>
                    <CustomNav title={props.planCardData.nowPrice} to="/consult" color={props.planCardData.nowPriceTextColor}></CustomNav>
                </NowPriceTextBox>)
        }
    }

    return (
        <StyledCard>
            <ExplainSummary>
                <PlanType>
                    <Text text={props.planCardData.planType} customStyle={textStyle}></Text>
                </PlanType>
                <PriceExplain backgroundColor={props.planCardData.typeExplainColor}>
                    <Text text={props.planCardData.typeExplain} customStyle={explainTextStyle}></Text>
                    {props.planCardData.originalPrice ? <Text text={props.planCardData.originalPrice} customStyle={explainTextStyle}></Text> : <></>}
                </PriceExplain>
                <PriceBox >
                    {/* <Text text={props.planCardData.nowPrice} customStyle={nowPriceTextStyle} />원   */}
                    {showNowPrice(props.planCardData.type)}
                </PriceBox>
            </ExplainSummary>
            <ExplainDetail>
                <DetailTypeBox>
                    <DetailTypeTextarea>
                        {props.planCardData.explainText.split('\n').map((text, index) => 
                            <Text key={index} text={text} customStyle={textStyle}></Text>
                        )}
                    </DetailTypeTextarea>
                </DetailTypeBox>
                <DetailContent>
                    {props.planCardData.explainTextArray.map((d, index) => (
                        <DetialContentItem key={index} >{d}</DetialContentItem>
                    ))}
                </DetailContent>
            </ExplainDetail>
        </StyledCard>
    )
}

export function CustomNav({ title, to, color } : {title?: string, to: string, color: string }) {
    const navStyle = {
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "24px",
        textDecoration: "underline",
        color: color
    }
    return (
        <NavLink to={to} style={navStyle}>
            {title}
        </NavLink>
    )
}

const StyledCard = styled.li`
    display: flex;
    flex-direction: column;
    margin-top: 60px;
`

const ExplainSummary = styled.div`
    display: flex;
    flex-direction: column;
    width: 380px;
    height: 300px;
    margin: 16px 0px;
`

const PlanType = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;   
    width: 380px;
    height: 60px;
    background-color:${colors.white};
    order: 0;
`

const PriceExplain = styled.div<ColorProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 60px;
    order: 1;
    background-color: ${(props) => props.backgroundColor || colors.white};
`

const PriceBox = styled.div`
    postion: relative;
    order: 2;
    height: 180px;
    background-image: url(${TicketBg});
    display: flex;
    justify-content: center;
    align-items: center;
`

const NowPriceBox = styled.div`
    width: 241.5px;
    height: 86px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
`

const NowPriceTextBox= styled.div`
    width: 75px;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
`

const NowPriceExplain = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 241.5px;
    height: 60px;
`

const ExplainDetail = styled.div`
    display: flex;
    flex-direction: column;
    width: 380px;
    height: 400px;
    position: relative;
`

const DetailTypeBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:${colors.white};
    width: 380px;
    height: 100px;
`

const DetailTypeTextarea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const DetailContent = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 19px 20px;
    display: flex;
    width: 380px;
    height: 300px;
    background-color:${colors.gray50};
`

const DetialContentItem = styled.li`
    list-style: inside;
    color: ${colors.gray600};
    margin-bottom: 10px
`

interface ColorProps {
    backgroundColor?: string
}

interface PlanCardProps {
    planCardData: Card;
}
