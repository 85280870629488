import styled from "styled-components";
import { ReactComponent as QuestionMark } from "@/assets/icons/ic-question-mark.svg";
import { useEffect } from "react";

export default function ModalTooltip({ id, scrollId, iconWidth, iconHeight, textBoxWidth, fill, text }: TooltipProps) {
    useEffect(() => {
        scrollListener();
    }, [])

    const scrollListener = () => {
        let target = document.getElementById(`${scrollId}`);
        if (target) target.addEventListener("scroll", () => {
            onMouseLeave();
        });
    }

    const onMouseEnter = () => {
        let target = document.getElementById(`guide-tooltip-wrap-${id}`);
        if (target) {
            let targetTop = target.getBoundingClientRect().top;
            let targetRight = target.getBoundingClientRect().right;
            let layout = document.getElementById(`modal-root`)
            let tooltip = document.createElement("span");
            let content = document.createTextNode(text);
            tooltip.appendChild(content);
            tooltip.setAttribute('id', 'guide-tooltip');

            tooltip.style.top = targetTop - 10 + "px";
            tooltip.style.left = targetRight + 16 + "px";
    
            if (layout) layout.insertBefore(tooltip, null);
        }
    };

    const onMouseLeave = () => {
        let tooltip = document.getElementById("guide-tooltip");
        if (tooltip) tooltip.remove();
    };

    return (
        <TooltipWrapper>
            <QuestionMarkWrapper id={`guide-tooltip-wrap-${id}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <QuestionMark width={iconWidth} height={iconHeight} fill={fill} />
            </QuestionMarkWrapper>
        </TooltipWrapper>
    );
}

const TooltipWrapper = styled.div`
    position: relative;
    display: flex;
`;

const QuestionMarkWrapper = styled.div``;

interface TooltipProps {
    id: string;
    scrollId?: string;
    iconWidth?: number;
    iconHeight?: number;
    textBoxWidth?: number;
    fill?: string;
    text: string;
}