import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import SelectBox from "@/components/atoms/selectBox";
import MultiSearchBar from "@/components/molecules/common/searchBar/multiSearch/MultiSearchBar";
import useSearchHistory from "@/components/molecules/common/searchBar/multiSearch/useSearchHistory";
import {
    getAtcmpByAll,
    getAtcmpByReason,
    getAtcmpByAuthor,
    setFilterOption,
} from "@/pages/settlement/saels-inquiry/publisher/etc/etcSlice";
import type { SearchedKeyword } from "@/types/autoComplete";

export default function EtcSearchBar() {
    const [searchType, setSearchType] = useState("all");
    const { fetchLoading, reset, atcmpList } = useSelectorTyped(
        (state: RootState) => state.inquiryEtc,
    );
    const { searchHistory } = useSelectorTyped((state: RootState) => state.searchHistory);

    const dispatch = useAppDispatch();
    const { getAtcmp, getHistory, updateHistory, deleteHistory } = useSearchHistory({ template: "inquiry-etc" });

    useEffect(() => {
        if (reset) {
            setSearchType("all");
            dispatch(setFilterOption({ author_name: "", reason: "" }));
        }
    }, [reset]);

    const changeSearchType = (value: any) => setSearchType(value.value);

    const setSearchedKeyword = async (item: SearchedKeyword) => {
        // 타입별 검색 또는 직접입력
        if (typeof item === "string") {
            if (searchType === "author") {
                dispatch(setFilterOption({ author_name: item, reason: "" }));
            } else if (searchType === "reason") {
                dispatch(setFilterOption({ reason: item, author_name: "" }));
            } else {
                dispatch(setFilterOption({ author_name: item, reason: item }));
            }

            if (!item) return;
            await updateHistory(item);
            return await getHistory();
        }
        if (item.type === "author") {
            dispatch(setFilterOption({ author_name: item.value, reason: "" }));
        }
        if (item.type === "reason") {
            dispatch(setFilterOption({ reason: item.value, author_name: "" }));
        }
        await updateHistory(item.value);
        await getHistory();
    };

    const getAtcmpList = (keyword: string) => {
        switch (searchType) {
            case "all":
                searchByAll(keyword);
                break;
            case "author":
                searchByAuthor(keyword);
                break;
            case "reason":
                searchByReason(keyword);
                break;
        }
    };

    // 전체 자동완성
    const searchByAll = (keyword: string) => getAtcmp(getAtcmpByAll({ keyword }));

    // 저자명 자동완성
    const searchByAuthor = (keyword: string) => getAtcmp(getAtcmpByAuthor({ keyword }));

    // 지급사유 자동완성
    const searchByReason = (keyword: string) => getAtcmp(getAtcmpByReason({ keyword }));

    return (
        <>
            <SelectBox
                labelKey="name"
                valueKey="value"
                options={[
                    { name: "전체", value: "all" },
                    { name: "저자명", value: "author" },
                    { name: "지급사유", value: "reason" },
                ]}
                selected={searchType}
                onClickValueFunc={changeSearchType}
                customStyle={{
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "400",
                    margin: "0px 8px 0px 0px",
                }}
            />
            <MultiSearchBar
                type={searchType === "all" ? "all" : "single"}
                atcmpList={atcmpList}
                searchHistory={searchHistory}
                reset={reset}
                dataLoading={fetchLoading && !reset}
                onKeyUp={getAtcmpList}
                onSelected={setSearchedKeyword}
                onClearHistory={deleteHistory}
            />
        </>
    );
}
