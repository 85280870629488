import moment from "moment";
import useAlert from "@/modules/hooks/useAlert";
import { useEffect } from "react";
import api from "@/api";

declare global {
    interface Window {
        auth_data: any
    }
}

const siteCd = "A8JDT";

export default function KcpForm({ authDone, setResult }: {authDone: boolean, setResult: Function }) {
    const { alert } = useAlert();

    useEffect(() => {
        if (authDone) openAuthPopup();
    }, [authDone])

    async function openAuthPopup() {
        try {
            // if (!ok) {
            // // if (this.authMsg && !ok) {
            //     let answer = await this.$alert("confirm", "본인인증", this.authMsg);
    
            //     if (answer == "Y") {
            //         return this.openAuthPopup(true);
            //     } else return;
            // }
            let auth_form:any = document.getElementsByName("form_auth")[0];
    
            auth_form.ordr_idxx.value = Date.now() + "GLING";
            let width = 410,
                height = 500,
                auth_popup = window.open("", "auth_popup",
                    `width=${width},height=${height},toolbar=no,status=no,statusbar=no,menubar=no,scrollbars=no,resizable=no,left=${
                        window.screen.width / 2 - width / 2
                    }, top=${window.screen.height / 2 - height / 2}`
                );
            auth_form.target = "auth_popup";
            auth_form.action = "/kcp/kcp_req.jsp";
            auth_form.submit();

            window.auth_data = async (frm:any) => {
                if (frm.data) {
                    let authData = frm.data.value.split("|");
                    let user_birth_dt = `${authData[2].slice(2, 6)}-${authData[2].slice(
                        6,
                        8
                    )}-${authData[2].slice(8)}`;
                    let param = {
                        user_name: authData[0],
                        user_birth_dt,
                        user_sex: authData[2].charAt(1) == "1" ? "M" : "F",
                        user_mobile: authData[1],
                        user_adult:
                        moment().diff(user_birth_dt, "years") > 20 ? true : false,
                        kcp_di: authData[3],
                    };
    
                    // 중복 가입 확인
                    let joined = false;
    
                    let apiParams = {
                        kcp_di: param.kcp_di
                    };
    
                    // let res = (await dispatch(findEmail(apiParams)).unwrap()).payload.data;
                    let res = (await api.post(`/api/user/findEmail/select`, apiParams)).data;
                    if (res.status == "success") {
                        joined = true;
                    }
    
                    await setResult(param, joined)
                } else {
                    return await alert("error", "오류 발생", frm.res_msg.value);
                }
            };
            return auth_popup;
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    return (
        <form name="form_auth">
            {/* 요청 변수 */}
            <input type="hidden" name="site_cd" value={siteCd} />
            <input type="hidden" name="ordr_idxx" />
            <input type="hidden" name="req_tx" value="cert" />
            <input type="hidden" name="cert_method" value="01" />
            <input type="hidden" name="user_name" />
            <input type="hidden" name="up_hash" />
            <input type="hidden" name="veri_up_hash" />
            <input type="hidden" name="cert_otp_use" value="Y" />
            <input type="hidden" name="cert_able_yn" />
            <input type="hidden" name="web_siteid" value="J19050703417" />
            <input type="hidden" name="param_opt_1" value="opt1" />
            <input type="hidden" name="param_opt_2" value="opt2" />
            <input type="hidden" name="param_opt_3" value="opt3" />
            <input type="hidden" name="Ret_URL" value={`${window.location.origin}/kcp/kcp_req.jsp`} />
            <input type="hidden" name="web_siteid_hashYN" />
            <input type="hidden" name="cert_enc_use_ext" value="Y" />
            {/* 반환 변수 */}
            <input type="hidden" name="res_cd" />
            <input type="hidden" name="res_msg" />
            <input type="hidden" name="year" />
            <input type="hidden" name="month" />
            <input type="hidden" name="day" />
            <input type="hidden" name="sex_code" />
            <input type="hidden" name="local_code" />
            <input type="hidden" name="user_name_url_yn" value="Y" />
            <input type="hidden" name="user_name_url" />
        </form>
    );
}