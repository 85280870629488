import { useState, useEffect } from "react";
import { colors } from "@/assets/styles/theme";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setFilterOption, setError, resetStore } from "@/pages/settlement/saels-inquiry/author/etc/etcSlice";

// components
import FilterContainer from "@/components/molecules/common/searchFilter/FilterContainer";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import EtcSearchBar from "./EtcSearchBar";
import DateRangePicker from "@/components/molecules/sales-inquiry/common/DateRangePicker";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import Slider from "@/components/molecules/common/slider/Slider";

// custom hooks
import useValidateDate from "@/modules/hooks/useValidateDate";
import useTagController from "../useTagController";
import useFilterSetting from "../hooks/useFilterSetting";

// utils
import { calcPeriod } from "@/modules/utils/date";

export default function EtcSearchFilter() {
    const { startDate, endDate, startDateError, endDateError, sliderMinValue, sliderMaxValue } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorEtc,
    );
    const dispatch = useAppDispatch();
    const { publisherList, authorList, publisherIds, authorIds } = useFilterSetting();
    const [reset, setReset] = useState(false);
    const { validateStartDate, validateEndDate } = useValidateDate();
    const { showMoreTag, handleClickTag } = useTagController(["publisher", "author"]);
    const { startDate: initialStartDate, endDate: initialEndDate } = calcPeriod("thisMonth");

    useEffect(() => {
        return () => { dispatch(resetStore()); };
    }, []);

    useEffect(() => {
        dispatch(
            setFilterOption({
                selectedPublisher: publisherIds,
                selectedAuthor: authorIds,
            }),
        );
    }, [publisherList, authorList]);

    // 리셋
    useEffect(() => {
        if (reset) setReset(false);
    }, [reset]);

    const clickResetButton = () => {
        setReset(true);
        dispatch(
            setFilterOption({
                reason: "",
                startDate: initialStartDate,
                endDate: initialEndDate,
                selectedPublisher: publisherIds,
                selectedAuthor: authorIds,
                startAmount: 0,
                endAmount: 0,
            }),
        );
    };

    const changePublisher = (ids: number[]) => dispatch(setFilterOption({ selectedPublisher: ids }));

    const changeAuthorTag = (ids: number[]) => dispatch(setFilterOption({ selectedAuthor: ids }));

    const changeStartDate = (startDate: string) => {
        const { startDateError, endDateError } = validateStartDate(startDate, endDate);
        dispatch(setFilterOption({ startDate }));
        dispatch(setError({ startDateError, endDateError }));
    };

    const changeEndDate = (endDate: string) => {
        const { startDateError, endDateError } = validateEndDate(endDate, startDate);
        dispatch(setFilterOption({ endDate }));
        dispatch(setError({ startDateError, endDateError }));
    };

    const changeAmountSlider = (startAmount: number, endAmount: number) => {
        dispatch(setFilterOption({ startAmount, endAmount }));
    };

    return (
        <FilterContainer>
            <FilterRow
                label={"검색"}
                filterContent={[<EtcSearchBar reset={reset} />]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="지급 일"
                filterContent={[
                    <DateRangePicker
                        type="default"
                        changeStartDate={changeStartDate}
                        changeEndDate={changeEndDate}
                        startDateToSet={startDate}
                        endDateToSet={endDate}
                        startDateError={startDateError}
                        endDateError={endDateError}
                    />,
                    <ResetFilter onClick={clickResetButton} />,
                ]}
            />
            <FilterRow
                label="저자명"
                plusIcon={{ showPlusIcon: showMoreTag.author, onClick: () => handleClickTag("author") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={authorList}
                        keyName={"id"}
                        valueName={"name"}
                        onChange={changeAuthorTag}
                        showMore={showMoreTag.author}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="출판사"
                plusIcon={{ showPlusIcon: showMoreTag.publisher, onClick: () => handleClickTag("publisher") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={publisherList}
                        keyName={"publisher_id"}
                        valueName={"publisher_name"}
                        onChange={changePublisher}
                        showMore={showMoreTag.publisher}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="지급 금액"
                filterContent={[
                    <Slider min={sliderMinValue} max={sliderMaxValue} reset={reset} onChange={changeAmountSlider} />,
                ]}
            />
        </FilterContainer>
    );
}
