import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import StepBox from "@/components/molecules/signup/StepBox";
import SignUpForm from "@/components/molecules/signup/SignUpForm";
import BaseInfoForm from "@/components/molecules/signup/BaseInfoForm";
import Done from "@/components/molecules/signup/Done";
import { useEffect, useState } from "react";

export default function SignUpAuthor() {
    const dispatch = useAppDispatch();
    const { step, fromSocial } = useAppSelector((state: RootState) => state.signup);
    const [template, setTemplate] = useState(<></>);

    useEffect(() => {
        return () => {
            dispatch({ type: "signup/reset" });
        }
    }, [])

    useEffect(()=>{
        switch (step) {
            case 0:
                if (fromSocial) dispatch({ type: "signup/changeStep", payload: 1 });
                setTemplate(<SignUpForm />);
                break;
            case 1:
                setTemplate(<BaseInfoForm />);
                break;
            case 2:
                setTemplate(<Done />);
                break;
        }
    }, [step])

    return (
        <SignFormContainer>
            <h2 className="title">회원가입</h2>
            <StepBox />
            {template}
        </SignFormContainer>
    );
}

const SignFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    margin: 0 auto;
    padding-top: 80px;
    .title {
        font-weight: 700;
        font-size: 28px;
        text-align: center;
        margin-bottom: 40px;
    }
`;