import { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { useAppDispatch } from "@/modules/store";

import Button from "@/components/atoms/button";
import TextInput from "@/components/atoms/input/TextInput";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetCoAuthorTaxModal, { CoAuthorInfo } from "@/components/molecules/common/modal/sales-inquiry/SetCoAuthorTaxModal";
import useCalculateAmount from "./hooks/useCalculateAmount";
import { amount } from "@/modules/utils/validate_modify";
import { addComma, removeComma } from "@/modules/utils/filter";
import type { Item } from "@/components/molecules/common/table/DataTable";
import { setItem } from "@/pages/settlement/sales-entry/etc/etcSlice";

export default function EtcTaxAmount({ item, index }: EtcTaxAmountProps) {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);
    const { calculateAmount } = useCalculateAmount();
    const inputStyle = {
        fontSize: "12px",
        textAlign: "right",
        width: "100%",
        height: "32px",
    };
    const handleModalState = () => setShowModal((prev) => !prev);

    const applyCoAuthorTax = (data: CoAuthorInfo[]) => {
        let newItem = { ...item };
        newItem.author_tax_info = data; //data.coAuthorInfo;
        newItem.taxAmountError = "";

        calculateAmount(newItem);
        dispatch(setItem({ index, item: newItem }));
    };

    const changeTaxAmount = (value: string) => {
        const res = amount({ field: "적용세액", value, error: "taxAmountError", mandatory: true, decimal: true });
        let newItem = _.cloneDeep(item);
        newItem.tax_amount = res.value;
        newItem.taxAmountError = res.taxAmountError;

        calculateAmount(newItem);
        dispatch(setItem({ index, item: newItem }));
    };

    const handleComma = (type:string) => () => {
        const newItem = { ...item, tax_amount: type === 'remove' ? removeComma(item.tax_amount) : addComma(item.tax_amount) };
        dispatch(setItem({ index, item: newItem }));
    }

    if (item.isCoAuthor && item["tax_type"] === "E") {
        return (
            <TaxAmountContainer>
                <ValidateWrapper dataError={item["taxAmountError"]} customStyle={{ width: "100%" }}>
                    <Button
                        type="main-outline"
                        label="공저별 설정"
                        customStyle={{ width: "100%" }}
                        onClickHandler={handleModalState}
                    />
                </ValidateWrapper>
                <ModalPortal>
                    <SetCoAuthorTaxModal
                        from=""
                        show={showModal}
                        close={handleModalState}
                        coAuthorInfo={item && item.author_tax_info}
                        onApply={applyCoAuthorTax}
                    />
                </ModalPortal>
            </TaxAmountContainer>
        );
    }

    return (
        <TaxAmountContainer>
            <ValidateWrapper dataError={item["amountError"]}>
                <TextInput
                    type={item["tax_type"] === "E" ? "main" : "readOnly"}
                    value={item["tax_amount"]}
                    placeholder="적용 세액(원)"
                    onChangeHandler={(e) => changeTaxAmount(e.target.value)}
                    onFocusHandler={handleComma("remove")}
                    onBlurHandler={handleComma("")}
                    customStyle={inputStyle}
                />
            </ValidateWrapper>
        </TaxAmountContainer>
    );
}

const TaxAmountContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

interface EtcTaxAmountProps {
    item: Item;
    index: number;
}