import QuestionCard from '@/components/molecules/support/question/QuestionCard'
import { Question } from '@/pages/support/question/slice';

interface Iprops {
    filteredData: Array<Question>,
    itemsPerPage: number,
    page: number
}

export default function QuestionCardContainer ({filteredData, itemsPerPage, page}: Iprops) {
    return (
        <>
            {
                filteredData.slice((page-1)* itemsPerPage, page * itemsPerPage).map((question, idx) => 
                    <QuestionCard
                        key={question.id}
                        id={question.id}
                        name={question.category}
                        title={question.question}
                        content={question.answer}
                        toggle={question.toggle}
                        onCardClick={()=>{}}
                    />
                )
            }
        </>
    )
}