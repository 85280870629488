import styled from 'styled-components'
import Text from "@/components/atoms/text";
import { useScrollFadeIn } from "@/modules/hooks/useScrollFadeIn";
import { colors } from '@/assets/styles/theme';
export default function SettlementTitle() {
    const FadeInItem = useScrollFadeIn('up', 1, 0); 
    const commonStyles = {
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "45px"
    };

    const defaultStyles = Object.assign({color: colors.black}, commonStyles);
    const highlightStyles = Object.assign({color: colors.blue500}, commonStyles);

    const texts: Text[] = [
        {
            text: '정산관리',
            style: highlightStyles
        },
        {
            text: '는\u00a0',
            style: defaultStyles
        },
        {
            text: 'All-in-One',
            style: highlightStyles
        },
        {
            text: '으로',
            style: defaultStyles
        }
    ];
    
    return (
        <TextWrapper {...FadeInItem}>
            {texts.map((textObj, idx) => 
                <Text key={idx} text={textObj.text} customStyle={textObj.style}/>)}
        </TextWrapper>
    )
}

const TextWrapper = styled.div`
    display:flex;
    width: 432px;
    margin-bottom:60px;
`

interface Text {
    text: string,
    style: {}
}
