import styled from "styled-components";
import Text from "@/components/atoms/text";


function PageTitle({ style, text }: PageTitleProps) {
    const textStyle = { 
        fontWeight: "700", 
        fontSize: "20px" 
    }

    return (
        <TitleWrapper
            style={style}
        >
            <Text text={text} customStyle={textStyle}/>
        </TitleWrapper>
    );
}

const TitleWrapper = styled.div<PageTitleWrapperStyleProps>`
    height: ${({ height }) => height ? height : "unset"};
    width: ${({ width }) => width ? width : "unset"};
    padding: ${({ padding }) => padding ? padding : "0"};
    margin: ${({ margin }) => margin ? margin : "0"};
`;

interface PageTitleProps {
    style?: PageTitleWrapperStyleProps;
    text: string;
}

interface PageTitleWrapperStyleProps {
    height?: string;
    width?: string;
    padding?: string;
    margin?: string;
}

export default PageTitle;
