import BaseModal from "@/components/molecules/common/modal/BaseModal";
import CirculationErrListModalBody from "@/components/molecules/common/modal/bodyModal/CirculationErrListModalBody";
import { BaseModalProps } from "@/pages/common/types";

export default function CirculationErrListModal({ show, from, close }:BaseModalProps) {
    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["엑셀파일 오류 목록"]}
                customStyle={[{ width: "480px", height: "400px" }]}
                footer={false}
            >
                <CirculationErrListModalBody />
            </BaseModal>
        </>
    );
}