import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { SeriesState, EnrollSeriesData, GetSeriesDataParams } from './types';
import { KeyValue, KeyIndexValue, KeyValueIndex } from '../types';
import api from "@/api";

// Define the initial state using that type
const initialState: SeriesState = {
    data: [],
    platform: [],
    loading: false,
    hasError : false,
    error: "",

    searchType: "all",
    seriesAddDatas: [{   // 시리즈명 
        name: "", 
        author: {
            author_id: 0,
            author_name: "",
            hasError: false,
            co_info: [],
        }, 
        ratioAll: "",
        ratios: [],
        seriesError: "",
        authorError: "",
        ratioError: "",                     
        hasDetailOptions: false,
        selected: false
    }],
    index: null,

    //  정산비율 상세 설정
    ratios: [],
    allRatios: {
        period: [{endYearmon: "999999", startYearmon: "000000", ratio: 0, endYearmonError: ""}],
        ratio: 0,
        platform_name: "전체",
		platform_id: 99999,
        ratioError: ""
    },
    allCheck: false,
    currIdx: 0,
	currPeriod: [],

    // 공저간 비율 설정
    tmpCoInfo: [{
        ae_id: 0,
        coRatio: null,
		ratioError: "",
        realName: "",
    }],

    // 자동완성
    atcmpList: [],
}

// 시리즈명 가져오기
export const getSeriesData = createAsyncThunk("series/getSeriesData", async (apiParams: GetSeriesDataParams|{}, { rejectWithValue }) => {
    try {
        if (!apiParams) {
            apiParams = {
                searchType: "all",
                searchKeyword: ""
            }
        }
        const res = await api.post(`/api/settlement/newBaseData/series/select`, apiParams);

        return res.data;
    } catch (err:any) {
        throw rejectWithValue(err.response.data);
    }
})

// 시리즈명 등록
export const enrollSeries = createAsyncThunk("series/enrollSeries", async (seriesAddDatas:EnrollSeriesData[], { rejectWithValue }) => {
    try {
        let apiParams = {
            items: seriesAddDatas
        }

        const res = await api.post(`/api/settlement/newBaseData/series/insert`, apiParams);

        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 시리즈명 삭제
export const deleteSeries = createAsyncThunk("series/deleteSeries", async (apiParams:{ selected: any }, { rejectWithValue }) => {
    try {
        const res = await api.post(`/api/settlement/baseData/series/delete`, apiParams);

        return res.data;
    } catch (err:any) {
        throw rejectWithValue(err.response.data);
    }
})

// 시리즈명 변경
export const updateSeries = createAsyncThunk("series/updateSeries", async (apiParams:{ updatedRows: any, platformList: number[] }, { rejectWithValue }) => {
    try {
        const res = await api.post(`/api/settlement/baseData/series/update`, apiParams);

        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 시리즈명 엑셀 업로드
export const excelUploadSeries = createAsyncThunk("series/excelUpload", async(apiParams:{ file: string, fileName: string }, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/settlement/newExcelUpload/uploadSeries/insert`, apiParams);

		return res.data;

	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

// 전체 검색 (시리즈 + 저자명 + 작품명)
export const getAtcmpByAll = createAsyncThunk(`series/getAtcmpByAll`,
    async ({ keyword }: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/series/select`, 
                { keyword })).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 시리즈 검색
export const getAtcmpBySeries = createAsyncThunk(`series/getAtcmpBySeries`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/seriesName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 저자명 검색
export const getAtcmpByAuthor = createAsyncThunk(`series/getAtcmpByAuthor`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/authorName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 포트폴리오 생성
export const enrollPortfolioSeries = createAsyncThunk("series/enrollPortfolio", async(apiParams:{ series_id: number }, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/portfolio/enroll/content/baseInfo`, apiParams);

		return res.data;

	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

export const seriesSlice = createSlice({
    name: 'series',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        reset() {
			return {...initialState};
		},
        setSeriesDatas: (state, action:PayloadAction<KeyIndexValue>) => {
            let key = action.payload.key;
			let value = action.payload.value;
			let index = action.payload.index;
            let objectKey = action.payload.objectKey;
            if (typeof index === "number") {
                if (objectKey) {
					state[key][index][objectKey] = value;
				} else {
					state[key][index] = value;
				}
            } else {
                state[key] = value;
            }
        },
        resetEnrollSeries: (state) => {
			state.seriesAddDatas = initialState.seriesAddDatas;
		},
		setSearchType: (state, action: PayloadAction<string>) => {
			state.searchType = action.payload;
		},
        setSeriesAuthor: (state, action: PayloadAction<number>) => {
            let idx = action.payload;
            if (state.index === null) {
                state.seriesAddDatas[0].author = {
                    author_id: 0,
                    author_name: "",
                    co_info: [],
                    hasError: false,
                }
            } else {
                state.data[idx].author = {
                    author_id: 0,
                    author_name: "",
                    co_info: [],
                    hasError: false,
                }
            }
        },
        setIndex: (state, action: PayloadAction<number|null>) => {
			state.index = action.payload;
		},
        setData: (state, action:PayloadAction<KeyValue>) => {
			let key = action.payload.key;
            let value = action.payload.value;
            let index = action.payload.index;
            state.data[index][key] = value;
		},
        setEnrollSeriesDatas: (state, action:PayloadAction<KeyValueIndex>) => {
			let key = action.payload.key;
			let value = action.payload.value;
			let index = action.payload.index;
			let objectKey = action.payload.objectKey;
            let objectIndex = action.payload.objectIndex;
            let periodIndex = action.payload.periodIndex;
            let periodKey = action.payload.periodKey;
			if (typeof index === "number") {
				if (objectKey) {
                    if (objectIndex === 0 || objectIndex) {
                        if ((periodIndex === 0 || periodIndex) && periodKey) {
                            state.seriesAddDatas[index][key][objectIndex][objectKey][periodIndex][periodKey] = value;
                        } else {
                            state.seriesAddDatas[index][key][objectIndex][objectKey] = value;
                        }
                    } else {
                        state.seriesAddDatas[index][key][objectKey] = value;
                    }
				} else {
					state.seriesAddDatas[index][key] = value;
				}
			}
		},
        deleteAddDataRow: (state, action:PayloadAction<number>) => {
			let index = action.payload;
			state.seriesAddDatas.splice(index, 1);
		},
        setRatios(state, action: PayloadAction<KeyValueIndex>) {
			let key = action.payload.key;
			let value = action.payload.value;
			let index = action.payload.index;
			let objectKey = action.payload.objectKey;
			let objectIndex = action.payload.objectIndex;
			if (typeof index === "number") {
				if (typeof objectIndex !== "undefined") {
					if (typeof objectKey !== "undefined") {
						state.ratios[index][key][objectIndex][objectKey] = value;
						// state.ratios[index].period[0].ratio = value;
					} else {
						state.ratios[index][key][objectIndex] = value;
					}
				} else {
					state.ratios[index][key] = value;
				}
			}
		},
        setPeriod(state, action: PayloadAction<KeyValueIndex>) {
			let key = action.payload.key;
			let value = action.payload.value;
			let index = action.payload.index;
			let objectKey = action.payload.objectKey;
			// let objectIndex = action.payload.objectIndex;
			if (typeof index !== "undefined") {
				if (typeof objectKey !== "undefined") {
					state.allRatios[key][index][objectKey] = value;
					// state.allRatios.period[0].ratio = value
				} else {
					state.allRatios[key][index] = value;
					// state.allRatios.period[0] = value
				}
			} else {
				state.allRatios[key] = value;
				// state.allRatios.period = value
			}
		},
    },
    extraReducers: (builder) => {
        builder.addCase(getSeriesData.pending, (state, action) => {
            state.loading = true;
            state.hasError = false;
        });
        builder.addCase(getSeriesData.fulfilled, (state: any, action) => {
            if (action.payload.status === "success") {
                state.platform = action.payload.data.platform;
                state.data = action.payload.data.totalData;
                state.hasError = false;
            } else {
                state.hasError = true;    
            }
            state.loading = false;
        })
        builder.addCase(getSeriesData.rejected, (state, action) => {
            state.loading = false;
            state.hasError = true;
        });
        builder.addCase(enrollSeries.fulfilled, (state: any, action) => {
            if (action.payload.status === "success") {
                state.seriesAddDatas = initialState.seriesAddDatas;
                state.hasError = false;
            } else {
                state.hasError = true;    
            }
            state.loading = false;
        });
        builder.addCase(enrollSeries.rejected, (state, action) => {
            state.loading = false;
            state.hasError = true;
        });
        builder.addCase(deleteSeries.fulfilled, (state: any, action) => {
            if (action.payload.status === "success") {
                state.hasError = false;
            } else {
                state.hasError = true;    
            }
            state.loading = false;
        });
        builder.addCase(updateSeries.fulfilled, (state: any, action) => {
            if (action.payload.status === "success") {
                state.hasError = false;
            } else {
                state.hasError = true;    
            }
            state.loading = false;
        });
        builder.addCase(excelUploadSeries.fulfilled, (state, action) => {
			if(action.payload.status === "success") {
				state.seriesAddDatas = action.payload.data;
			} else {
				state.hasError = true;
				state.loading = false;
			}
		});
        builder.addCase(enrollPortfolioSeries.fulfilled, (state, action) => {
			if(action.payload.status === "success") {
			} else {
				state.hasError = true;
				state.loading = false;
			}
		});

        // 자동완성 - 전체 검색
        builder.addCase(getAtcmpByAll.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
        // 자동완성 - 시리즈 검색
        builder.addCase(getAtcmpBySeries.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
        // 자동완성 - 저자명 검색
        builder.addCase(getAtcmpByAuthor.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
    }
})

export const seriesActions = seriesSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.author

export default seriesSlice.reducer