import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useAppDispatch, useAppSelector } from "@/modules/store";
import { setError, setFilterOption } from "@/pages/settlement/saels-inquiry/author/daily/dailySlice";
import { calcPeriod } from "@/modules/utils/date";

export default function DateButtons({ disabled }: DateButtonsProps) {
    const { filterReset } = useAppSelector((state) => state.inquiryAuthorDaily); 
    const dispatch = useAppDispatch();
    const [dateType, setDateType] = useState<DateType>("yesterday");

    useEffect(() => {
        if (filterReset) setDateType("yesterday");
        if (disabled) setDateType("none");
    }, [filterReset, disabled]);

    const changeDateType = (type: any) => {
        if (dateType === type) return;

        const { startDate, endDate } = calcPeriod(type);
        setDateType(type);
        dispatch(setFilterOption({ startDate, endDate }));
        dispatch(setError({ startDateError: "", endDateError: "" }));
    };

    return (
        <>
            <StyledDateButton active={dateType === "yesterday"} onClick={() => changeDateType("yesterday")}>
                어제
            </StyledDateButton>
            <StyledDateButton active={dateType === "lastWeek"} onClick={() => changeDateType("lastWeek")}>
                지난 7일
            </StyledDateButton>
            <StyledDateButton active={dateType === "thisMonth"} onClick={() => changeDateType("thisMonth")}>
                이번 달
            </StyledDateButton>
            <StyledDateButton active={dateType === "lastMonth"} onClick={() => changeDateType("lastMonth")}>
                지난 달
            </StyledDateButton>
        </>
    );
}

const StyledDateButton = styled.button<{ active: boolean }>`
    width: 60px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    background-color: ${({ theme }) => theme.colors.gray50};
    color: ${({ theme }) => theme.colors.gray600};
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;

    ${({ active }) =>
        active &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.blue500};
            background-color: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.blue500};
        `}
`;

interface DateButtonsProps {
    disabled?: boolean;
}
type DateType = "yesterday" | "lastWeek" | "thisMonth" | "lastMonth" | "none";