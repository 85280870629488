import { ReactElement } from "react";
import styled from "styled-components";

export default function TemplateLayout({ children }: TemplateLayoutProps) {
    return <StyledTemplateLayout>{children}</StyledTemplateLayout>;
}

const StyledTemplateLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface TemplateLayoutProps {
    children: ReactElement | ReactElement[];
}
