import styled, { css } from "styled-components";
import { useState } from "react";
import Text from "@/components/atoms/text";

export default function TextTooltip({ text, splitedText, customStyle }: TooltipProps) {
    const [tooltip, setTooltip] = useState(false);

    const onMouseEnter = () => {
        setTooltip(true);
    }

    const onMouseLeave = () => {
        setTooltip(false);
    }

    const textStyle = {
        lineHeight: "21px",
        fontSize: "12px",
        fontWeight: "400",
    }

    return (
        <TextWrap onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} customStyle={customStyle}>
            <EllipsisText>
                {text}
            </EllipsisText>
            {
                tooltip ? 
                <TooltipText customStyle={customStyle}>
                    {(splitedText).map((c, i) => (
                        <Text text={c} customStyle={textStyle} key={i} />
                    ))}
                </TooltipText>
                :
                <></>
            }
        </TextWrap>
    )
}

const TextWrap = styled.div<{customStyle?:StyleProps}>`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    ${({ customStyle }) =>customStyle && css`
        width: ${customStyle.width};
    `}
`

const EllipsisText = styled.div`
    width: 450px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
`

const TooltipText = styled.div<{customStyle?:StyleProps}>`
    position: absolute;
    width: 400px;
    max-height: 200px;
    padding: 3px 17px;
    z-index: 99;
    background-color: ${({ theme }) => theme.colors.blue50};
    right: 0;
    top: 27px;
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    border-radius: 4px;
    overflow-y: auto;
    ${({ customStyle }) =>customStyle && css`
        top: ${customStyle.top};
        left: ${customStyle.left};
        right: ${customStyle.right};
    `}
`

interface TooltipProps {
    text: string;
    splitedText: string[];
    customStyle?: StyleProps;
}
interface StyleProps {
    fontWeight?: string;
    fontSize?: string;
    lineHeight?: string;
    color?: string;
    margin?: string;
    textAlign?: string;
    padding?: string;
    height?: string;
    width?: string;
    top?: string;
    left?: string;
    right?: string;
}
