import styled, { css } from "styled-components";
import Text from "@/components/atoms/text";
import Checkbox from "@/components/atoms/input/Checkbox";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { Publisher, SpecificationType } from "@/pages/settlement/statement/author/types";
import Button from "@/components/atoms/button";
import useDownload from "@/modules/hooks/useDownload";
import useAlert from "@/modules/hooks/useAlert";

export default function PublisherListContainer() {
    const { publisherList, allCheck, currPublisher, specificationType, yearmon } = useAppSelector((state: RootState) => state.authorStatement);
    const dispatch = useAppDispatch();
    const download = useDownload();
    const { alert } = useAlert();

    const publisherNameStyle = {
        fontSize: "14px",
        fontWeight: "400",
        margin: "0 0 0 8px",
        lineHeight: "18px"
    }

    const checkPublisher = (e: any, idx: number) => {
        dispatch({ type: "authorStatement/checkPublisher", payload: {
            checked: e.target.checked,
            idx
        }})
    }

    const checkAll = (e: any) => {
        dispatch({ type: "authorStatement/checkAllPublisherList", payload: e.target.checked });
    }

    const selectPublisher = async (publisher: Publisher) => {
        dispatch({ type: "authorStatement/selectPublisher", payload: publisher });
    }

    const downloadAll = () => {
        const selectedPublisher = publisherList.filter(p => p.checked);
        if (selectedPublisher.length) {
            if (specificationType === SpecificationType.Monthly) {
                const selectedPublisherIds = selectedPublisher.map(p => p.publisher_id);
                const publisher_list = selectedPublisherIds.join(",");
                download(`download/royalty-statement/author/all?month=${yearmon}&publisherList=${publisher_list}`)
            } else {
                const key_list = selectedPublisher.map(p => `${p.id},${p.publisher_id}`).join("/");
                download(`download/mg-statement/author/all?month=${yearmon}&keyList=${key_list}`)
            }
        } else {
            alert("warning", "주의", "선택된 대상이 없습니다.");
        }
    }

    return (
        <StyledContainer>
            <Text text="조회대상" customStyle={{ fontSize: "14px", fontWeight: "500" }} />
            <ButtonWrapper>
                <Button 
                    type="main-outline" 
                    label="선택 다운로드" 
                    onClickHandler={downloadAll} 
                />
            </ButtonWrapper>
            <ListWrapper>
                <HeaderWrapper>
                    <Checkbox 
                        type="boolean"
                        active={allCheck}
                        onChangeHandler={checkAll}
                    />
                    <Text text="출판사 전체 선택" customStyle={publisherNameStyle} />
                </HeaderWrapper>
                <StyledUl>
                    {publisherList.map((p, idx) => (
                        <RowWrapper key={p.publisher_id} selected={p === currPublisher} onClick={() => selectPublisher(p)}>
                            <CheckboxWrapper>
                                <Checkbox 
                                    type="boolean"
                                    active={p.checked}
                                    onChangeHandler={(e) => checkPublisher(e, idx)}
                                />
                            </CheckboxWrapper>
                            <Text text={p.publisher_name} customStyle={publisherNameStyle} />
                        </RowWrapper>
                    ))}
                </StyledUl>
            </ListWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 220px;
    margin-top: 40px;
    margin-right: 20px;
`

const StyledUl = styled.ul`
    display: block;
    max-height: 800px;
    overflow-y: auto;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray400};
`

const RowWrapper = styled.li<{ selected?: boolean }>`
    display: flex;
    width: 100%;
    min-height: 40px;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue50};
        box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.colors.blue50};
    }

    ${({ selected }) => selected && css`
        background-color: ${({ theme }) => theme.colors.blue50};
    `}
`

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 16px;
`

const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 8px;
    border-top: 2px solid ${({ theme }) => theme.colors.gray400};
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray400};
    background-color: ${({ theme }) => theme.colors.gray100};
`

const ListWrapper = styled.div`
    margin-top: 17px;
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: 100%;
`