import useCustomSimpleTable from "@/components/molecules/common/table/hooks/useCustomSimpleTable";
import { useAppDispatch } from "@/modules/store";
import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { resetDataTable } from "@/pages/common/dataTableSlice";
import { ReactComponent as UpArrow } from "@/assets/icons/up_arrow.svg";
import usePagination from "@/components/molecules/common/pagination/usePagination";

function SimpleDataTable({
    itemKey = "id",
    items: _items,
    preItems,
    needHeader = false,
    headers,
    customHeaders = {}, // 헤더에 기본 문자열이 아닌 커스텀 컴포넌트가 들어가야할 경우
    columns = {},
    from = "",
    options = {
        noSort: false,
        movePage: false
    },
    type = "notice",
}: TableProps) {
    const { page, itemsPerPage } = usePagination({ from });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    /* 
    #=========================================#
    | 테이블 데이터 및 UI                     |
    #=========================================# 
    */
    
    const keys = { itemKey };
    const { createState, items, chunkItem, eventHandler } = useCustomSimpleTable(
        _items,
        itemsPerPage,
        page,
        keys,
    );

    const moveToDetailpage = (data: any) => { navigate(`/support/${type}/${data.id}`) };
    
    useEffect(() => {
        return () => {
            dispatch(resetDataTable());
        };
    }, []);

    useEffect(() => {
        if (items.length) createState(items);
    }, [itemsPerPage]);

    const headerLength = headers.length + 1;
    if (!_items.length && !preItems?.length) return <EmptyDataTable headers={headers} customHeaders={customHeaders} needHeader={needHeader} />;
    return (
        <Table>
            <colgroup
                span={headerLength}
                style={{
                    minWidth: "50px",
                }}
            ></colgroup>
            {needHeader &&
                <Header>
                    <HeaderTr noSort={options.noSort}>
                        {headers.map((header) => (
                            <Th key={header.text} width={header.width} onClick={() => {}}>
                                <ThContent textAligns={header.textAlign}>
                                    <ThContentHeader key={header.text}>
                                        {customHeaders[header.value] ? customHeaders[header.value]() : header.text}
                                    </ThContentHeader>
                                    <ThContentUpArrow orderBy={false}>
                                        <UpArrow />
                                    </ThContentUpArrow>
                                </ThContent>
                            </Th>
                        ))}
                    </HeaderTr>
                </Header>}
            <Body>
                {chunkItem[0] &&
                    chunkItem.map((item, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <Tr key={idx}
                                    onClick={options.movePage ? () => moveToDetailpage(item) : undefined}
                                >
                                {headers.map((h, i) => {
                                    let comp: ReactElement;
                                    if (
                                        (!item[h.value] && item[h.value] !== 0) ||
                                        typeof item[h.value] === "object"
                                    ) {
                                        comp = <div></div>;
                                    } else {
                                        comp = item[h.value];
                                    }
                                    return (
                                        <Td key={h.value} className={h.value}>
                                            {columns[h.value] ? columns[h.value](item, idx, eventHandler) : comp}
                                        </Td>
                                    );
                                })}
                                </Tr>
                            </React.Fragment>
                        );
                    })}
            </Body>
        </Table>
    );
}

/**
 * 데이터가 없을 때 보여지는 테이블 컴포넌트
 * @param headers
 * @returns Table
 */
function EmptyDataTable({ headers, customHeaders = {}, needHeader = false }: Pick<TableProps, "headers" | "customHeaders" | "needHeader">) {
    return (
        <Table>
            {needHeader && 
                <Header>
                    <Tr>
                        {headers?.length !== 0 && headers.map((header) => (
                            <Th key={header.text} width={header.width}>
                                {customHeaders[header.value] ? customHeaders[header.value]() : header.text}
                            </Th>
                        ))}
                    </Tr>
                </Header>}
            <Body>
                <Tr>
                    <EmptyTd colSpan={headers?.length + 1}>
                        <p>등록된 데이터가 없습니다.</p>
                    </EmptyTd>
                </Tr>
            </Body>
        </Table>
    );
}

const Table = styled.table`
    width: 100%;
    font-size: 12px;
    border-bottom: none;
`;

const Header = styled.thead`
    border-top: 1px solid ${({ theme }) => theme.colors.gray600};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
    height: 48px;
    Tr {
        border-bottom: none;
    }
`;

const Body = styled.tbody`
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
    border-top: 1px solid ${({ theme }) => theme.colors.gray600};
`;

const Tr = styled.tr<{ selectedRow?: boolean }>`
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
    :nth-last-child(1) {
        border-bottom: none;
    }
    background-color: ${({ selectedRow, theme }) => selectedRow && theme.colors.blue50};
    cursor: pointer;
`;

const HeaderTr = styled(Tr)<{ noSort?: boolean }>`
    cursor: ${({ noSort }) => (noSort ? "auto" : "pointer")};
`;

const Th = styled.th<{ width?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 48px;
    padding: 14px 8px;
    text-align: left;
    vertical-align: middle;
    font-weight: 500;    
`;

const ThContent = styled.div<{ textAligns?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ textAligns }) => (textAligns ? textAligns : "flex-start")};
`;

const ThContentHeader = styled.div`
    margin-right: 4px;
`;

const ThContentUpArrow = styled.div<{ orderBy: string | boolean }>`
    display: ${({ orderBy }) => (orderBy === "asc" || orderBy === "desc" ? "block" : "none")};
    transform: ${({ orderBy }) => (orderBy === "desc" ? "rotate(180deg)" : "none")};
`;

const Td = styled.td<{ width?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 60px;
    padding: 14px 8px;
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;
`;

const EmptyTd = styled(Td)`
    text-align: unset;
    font-size: 16px;
    height: 64px;
`;

type Validataion = "quantityError" | "amountError" | "ratioError" | "taxRatioError" | "realNameError" | "stEmailError";
type ErrorKey = "authorError" | "emailError" | "seriesError";

// TYPE DEFINITION
export interface TableEventHandler {
    changeItem: (id: number, item: { [key: string]: any }) => void;
    changeValue: (key: string, id: number, value: any) => void;
    addComma: (key: string, id: number) => void;
    removeComma: (key: string, id: number) => void;
    validateValue: (key: string, id: number, value: string, validationType: Validataion) => void;
    asyncValidateValue: (
        key: string,
        errorKey: ErrorKey,
        id: number,
        _value: string,
        item: { [key: string]: any },
        field: string,
    ) => void;
    validateItem: (
        key: string,
        errorKey: string,
        id: number,
        _value: string,
        item: { [key: string]: any },
        validationType: Validataion,
    ) => void;
}

export interface Columns {
    [stateKey: string]: any;
}

interface TableProps {
    // 프리렌더 여부 + 테이블 데이터
    preRender?: boolean;
    itemKey: string;
    items: { [key: string]: any }[];
    preItems?: { [key: string]: any }[];
    needHeader: boolean;
    headers: { text: string; value: string; width?: string; textAlign?: string }[];
    customHeaders?: {
        [headerKey: string]: () => ReactElement;
    };
    columns: {
        [columnKey: string]: (columns: any, idx: number, eventHandler: TableEventHandler) => ReactElement;
    };
    
    // 페이지네이션 연동
    from?: string;
    // 기타 옵션
    options?: {
        noSort?: boolean;
        movePage?: boolean;
    };
    type?: string;
    customStyles?: { [key: string]: string };
}

export default SimpleDataTable;