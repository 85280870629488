import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
// ===========================================================================
// Reset CSS
//
// http://meyerweb.com/eric/tools/css/reset/ 
//  v2.0 | 20110126
// License: none (public domain)
// ===========================================================================
button,
input,
optgroup,
select,
textarea {
  //font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

// 모달 툴팁
#guide-tooltip {
  display: flex;
  align-items: center;
  position: fixed;
  height: auto;
  width: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  padding: 8px 16px;
  z-index: 99999;
  background: #E0F7FA;
  border: 1px solid #00BCD4;
  font-size: 12px;
  white-space: pre-line;
  ::before {
    // 테두리
    border-top: 7px solid transparent;
    border-right: 10px solid #00BCD4;
    border-bottom: 7px solid transparent;
    content: '';
    display: block;
    left: -11px;
    top: 9px;
    position: absolute;
    z-index: 0;
  }
  ::after {
    border-top: 7px solid transparent;
    border-right: 10px solid #E0F7FA;
    border-bottom: 7px solid transparent;
    content: '';
    display: block;
    left: -10px;
    top: 9px;
    position: absolute;
    z-index: 1;
  }
}

// 저자명 검색 툴팁 스타일
#author-name-tooltip {
  display: flex;
  align-items: center;
  position: fixed;
  height: 32px;
  width: auto;
  padding: 10px;
  z-index: 99999;
  border-radius: 4px;
  background-color: #4dcfe1;
  font-size: 12px;
  box-shadow: 0px 6px 12px rgb(0 0 0 / 20%);
}

// 시리즈명 기간별 설정 툴팁 스타일
#period-summary-tooltip {
  display: flex;
  align-items: center;
  position: fixed;
  height: auto;
  width: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  padding: 8px 16px;
  z-index: 99999;
  background: #E0F7FA;
  border: 1px solid #00BCD4;
  font-size: 12px;
  white-space: pre-line;
}

// 스크롤
body {
  ::-webkit-scrollbar {
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background-color: #E0F7FA;
}
::-webkit-scrollbar-thumb {
  background-color: #00BCD4;
  border-radius: 10px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

textarea:focus {
  outline: none;
}

button,
input { /* 1 */
  overflow: visible;
}

button,
select { /* 1 */
  text-transform: none;
  outline: none;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
}

select::-ms-expand{
  display: none;/*for IE10,11*/
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button {
	border: unset;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	/* font: inherit; */
	vertical-align: baseline;
}
html {
	overflow-y: auto;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul, li {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input, input:hover, input:active {
	outline: 0;
} 

// ===========================================================================
// Custom CSS
// ===========================================================================
* {
  font-family: 'Spoqa Han Sans', sans-serif;
	box-sizing: border-box;
}
body {
  margin: 0;
	word-break: keep-all;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-family: 'Spoqa Han Sans', sans-serif; */
}

a {
	color: #121417;
  text-decoration: none;
}

h1 {
    font-size: 48px;
    letter-spacing: 0px;
    color: #121417;
    font-weight: 700;
}
h2 {
    font-size: 36px;
    letter-spacing: 0px;
    color: #121417;
    font-weight: 400;
}
h3 {
    font-size: 28px;
    letter-spacing: 0px;
    color: #121417;
    font-weight: 400;
}
h4 {
    font-size: 24px;
    letter-spacing: 0px;
    color: #121417;
    font-weight: 100;
}
h5 {
    font-size: 20px;
    letter-spacing: -20px;
    color: #121417;
    font-weight: 100;
}
h6 {
    font-size: 16px;
    color: #121417;
}
`;

export default GlobalStyle;
