import styled from "styled-components";
import Text from "@/components/atoms/text";

export default function DashboardTable ({ headers, items, handleClickRow }: TableProps) {
    let clickAble = false;
    if (handleClickRow) clickAble = true;

    const selectRow = (row: any) => {
        if (handleClickRow) {
            handleClickRow(row[1].value);
        }
    }

    const headerTextStyle = {
        fontSize: "12px",
        fontWeight: "500"
    }

    const bodyTextStyle = {
        fontSize: "12px",
        fontWeight: "400"
    }

    const sortedData = items.map(row => {
        let tmpArr = [];
        for (let h of headers) {
            for (let key in row) {
                if (key === h.value) {
                    tmpArr.push({
                        value: row[key],
                        width: h.width,
                        align: h.align ? h.align : "justify"
                    })
                    break;
                }
            }
        }
        return tmpArr;
    })

    return (
        <StyledTable>
            <StyledThead>
                <tr>
                    {headers.map(h => (
                        <StyledTh width={h.width} key={h.value}>
                            <Text 
                                text={h.text} 
                                customStyle={{
                                    ...headerTextStyle,
                                    textAlign: h.align
                                }}
                            />
                        </StyledTh>
                    ))}
                </tr>
            </StyledThead>
            <StyledTbody>
                {sortedData.map(row => (
                    <StyledTr key={sortedData.indexOf(row)} onClick={() => selectRow(row)} clickAble={clickAble}>
                        {row.map(cell => (
                            <StyledTd width={cell.width} key={row.indexOf(cell)} >
                                <Text 
                                    text={cell.value} 
                                    customStyle={{ 
                                        ...bodyTextStyle, 
                                        textAlign: cell.align,
                                    }}
                                />
                            </StyledTd>
                        ))}
                    </StyledTr>
                ))}
            </StyledTbody>
        </StyledTable>
    )
}

const StyledTable = styled.table`
    width: 100%;
    height: 348px;
`

const StyledThead = styled.thead`
    display: table;
    width: calc(100% - 8px);
    height: 48px;
`

const StyledTh = styled.th<CellStyleProps>`
    padding: 18px 8px;
    width: ${({ width }) => width};
    &:last-child {
        padding-right: 24px;
    }
`

const StyledTbody = styled.tbody`
    display: block;
    max-height: 300px;
    overflow-y: auto;
`

const StyledTr = styled.tr<StyleProps>`
    display: table;
    height: 60px;
    ${({ clickAble }) => {
        return clickAble ? `cursor: pointer; &:hover { background-color: #F5EEF8; }` : null
    }}
`

const StyledTd = styled.td<CellStyleProps>`
    padding: 24px 8px;
    width: ${({ width }) => width};
    &:last-child {
        padding-right: 24px;
    }
`

interface TableProps {
    headers: Array<HeadersType>;
    items: RowData[];
    handleClickRow?: (series_name: string) => void;
}

interface RowData {
    [key: string]: any;
}

interface HeadersType {
    text: string;
    value: string;
    width: string;
    align?: string;
}

interface CellStyleProps {
    width: string;
}

interface StyleProps {
    clickAble?: boolean;
}