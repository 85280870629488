import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getMgAtcmp, setFilterOption } from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";
import SelectBox from "@/components/atoms/selectBox";
import MultiSearchBar from "@/components/molecules/common/searchBar/multiSearch/MultiSearchBar";
import useSearchHistory from "@/components/molecules/common/searchBar/multiSearch/useSearchHistory";
import type { SearchedKeyword } from "@/types/autoComplete";

export default function MgSearchBar() {
    const { atcmpList, fetchLoading, reset } = useSelectorTyped((state: RootState) => state.inquiryMg);
    const { searchHistory } = useSelectorTyped((state: RootState) => state.searchHistory);
    const dispatch = useAppDispatch();
    const { getAtcmp, getHistory, updateHistory, deleteHistory } = useSearchHistory({ template: "inquiry-mg" });

    useEffect(() => {
        if (reset) { dispatch(setFilterOption({ target: "" })); }
    }, [reset]);

    // 자동완성 조회
    const getAtcmpList = (keyword: string) => getAtcmp(getMgAtcmp({ keyword }));

    const setSearchedKeyword = async (item: SearchedKeyword) => {
        // 타입별 검색 또는 직접입력
        if (typeof item === "string") {
            dispatch(setFilterOption({ target: item }));

            if (!item) return;
            await updateHistory(item);
            return await getHistory();
        }

        dispatch(setFilterOption({ target: item.value }));
        await updateHistory(item.value);
        await getHistory();
    };

    return (
        <>
            <SelectBox
                labelKey="name"
                valueKey="value"
                disabled
                options={[{ name: "계약대상", value: "series" }]}
                selected="series"
                onClickValueFunc={() => {}}
                customStyle={{
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "400",
                    margin: "0px 8px 0px 0px",
                }}
            />
            <MultiSearchBar
                type="all"
                atcmpList={atcmpList}
                searchHistory={searchHistory}
                reset={reset}
                dataLoading={fetchLoading && !reset}
                onKeyUp={getAtcmpList}
                onSelected={setSearchedKeyword}
                onClearHistory={deleteHistory}
            />
        </>
    );
}
