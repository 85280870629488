import { useState } from "react";
import styled from "styled-components";

// components
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";

// reducer
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { totalSalesActions } from "@/pages/settlement/total-sales/slice";

export default function DepositFilterBox() {
    const dispatch = useAppDispatch();
    const [showMore, setShowMore] = useState(false);
    let { platformList } = useAppSelector((state: RootState) => state.totalSales);

    const handlerClickPlusIcon = () => setShowMore((prev) => !prev);

    function setPlatform(platformList: number[]) {
        dispatch(totalSalesActions.selectedPlatform(platformList));
    }
    return (
        <FilterContainer>
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMore, onClick: handlerClickPlusIcon }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={platformList}
                        keyName={"platform_id"}
                        valueName={"platform_name"}
                        onChange={setPlatform}
                        showMore={showMore}
                    />
                ]}
            />
        </FilterContainer>
    )
}

const FilterContainer = styled.div`
    width: 1200px;
    border-radius: 4px;
    margin-bottom: 24px;
`