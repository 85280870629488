import styled from "styled-components";
import Button from "@/components/atoms/button";
import IconButton from "@/components/molecules/basedata-manage/IconButton";

import { RootState, useAppDispatch } from "@/modules/store";
import { enrollBulkExcelData, bulkActions } from "@/pages/settlement/basedata-manage/bulkEnroll/bulkenrollSlice";

import useSampleDown from "@/modules/hooks/useSampleDown";
import useAlert from "@/modules/hooks/useAlert";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import BulkErrListModal from "@/components/molecules/common/modal/BulkErrListModal";
import { useEffect, useRef, useState } from "react";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import ProgressBar from "@/components/molecules/common/loading/ProgressBar";
// import useLoading from "@/modules/hooks/useLoading";

export default function BulkEnrollButtons() {
    // const { progressOff, progressOn } = useLoading();
    const { alert } = useAlert();
    const { saveAs } = useSampleDown();
    const dispatch = useAppDispatch();
    let [file, type, sid] = useSelectorTyped((state:RootState) => [
        state.bulkenroll.file,
        state.bulkenroll.type,
        state.socket.socketId
    ])
    const [showErrList, setShowErrList] = useState(false);
    const ratioRef = useRef<boolean>(false);

    useEffect(() => {

    }, [ratioRef.current])

    async function enroll() {
        try {
            if (!file?.file && !file.fileName) {
                return await alert("warning", "주의", "업로드된 파일이 없습니다.");
            }
            let answer = await alert("confirm", "확인", "등록하시겠습니까?");
    
            if (answer === "Y") {
                ratioRef.current = true;
                const apiParams = {
                    file: file.file,
                    fileName: file.fileName,
                    sid: sid,
                    type: type,
                }
                let data = await (await dispatch(enrollBulkExcelData(apiParams)).unwrap()).data;
                // 로딩 삭제
                ratioRef.current = false;

                // 업로드 실패한 데이터들 존재
                if (data && data.length) {
                    await dispatch(bulkActions.setErrList(data));
                    return setShowErrList(true);
                }
    
                dispatch(bulkActions.reset());
                await alert("success", "등록 완료", "데이터가 등록되었습니다.");
            }
            // if (answer === "Y") {
            //     // let ratio = 0;
            //     // await progressOn()
            //     const apiParams = {
            //         file: file.file,
            //         fileName: file.fileName,
            //         sid:"",
            //         type: type,
            //     }
            //     let data = await (await dispatch(enrollBulkExcelData(apiParams)).unwrap()).data;
            //     // 업로드 실패한 데이터들 존재
            //     if (data && data.length) {
            //         await dispatch(bulkActions.setErrList(data));
            //         return setShowErrList(true);
            //     }
    
            //     dispatch(bulkActions.reset());
            //     await alert("success", "등록 완료", "데이터가 등록되었습니다.");
            // }
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            // await progressOff();
        }
    }

    async function sampleDownload(type:string) {
        let fileName = "";
        if (type === "monthly") {
            fileName = "(gling)월_정산_등록_양식(대량)";
        } else {
            fileName = "(gling)일별_판매_등록_양식(대량)";
        }
        await saveAs(`download/baseData/bulkEnroll/sample/${type}`, fileName);
    }

    function closeModal() {
        setShowErrList(false);
    }

    return (
        <>
            <ProgressBar ratio={ratioRef.current} />
            <ButtonWrap>
                <IconButton
                    type="download"
                    label="양식 다운로드"
                    onClickHandler={() => sampleDownload(type)}
                    customStyle={{ width: "120px", fontSize: "12px", margin: "0 9px 0 0" }}
                />
                <Button
                    label="등록"
                    onClickHandler={enroll}
                    customStyle={{ fontSize: "12px" }}
                />
            </ButtonWrap>
            <ModalPortal>
                <BulkErrListModal
                    from="bulk-enroll"
                    show={showErrList}
                    close={closeModal}
                />
            </ModalPortal>
        </>
    );
}

const ButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 24px 0;
`