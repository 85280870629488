import styled from "styled-components";

function PercentInputText({
    type,
    value,
    customStyle,
    placeholder,
    disabled,
    onChange,
    icon,
    inText,
    onBlur,
  }: InputProps) {

    return (
        <InputWrap width={customStyle?.width}>
            <StyledInput
                // type={type}
                width={customStyle?.width}
                height={customStyle?.height}
                padding={customStyle?.padding}
                margin={customStyle?.margin}
                fontSize={customStyle?.fontSize}
                fontWeight={customStyle?.fontWeight}
                textAlign={type === "number" ? "right" : customStyle?.textAlign}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
            >
            </StyledInput>
            <Icon src={icon}></Icon>
            <InText fontSize={customStyle?.fontSize} fontWeight={customStyle?.fontWeight}>
                {inText}
            </InText>
        </InputWrap>
    );
}

export default PercentInputText;

const InputWrap = styled.div<{ width?: string }>`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: ${({ width }) => width ? width : ""};
`;

const StyledInput = styled.input<StyleProps>`
    border: 1px solid red;
    width: ${({ width }) => width ? width : "290px"};
    height: ${({ height }) => height ? height : "32px"};
    padding: ${({ padding }) => padding ? padding : ""};
    margin: ${({ margin }) => margin ? margin : ""};
    font-size: ${({ fontSize }) => fontSize ? fontSize : "12px"};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : "400"};
    text-align : ${({ textAlign }) => textAlign ? textAlign : "left"};
    border: 1px solid #DEE2E6;
    border-radius: 4px;
`;

const Icon = styled.img`
    position: absolute;
    width: 15px;
    top: 6px;
    right: 6px;
    cursor: pointer;
`

const InText = styled.span<{ fontSize?: string, fontWeight?: string}>`
    font-size: ${({ fontSize }) => fontSize ? fontSize : "14px"};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : "400"};
    color: rgba(0,0,0,.6);
    position: absolute;
    right: 4px;
`

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontWeight?: string;
    fontSize?: string;
    textAlign?: string;
}

interface InputProps {
    type?: "text" | "number";
    value: number | string;
    customStyle?: StyleProps;
    placeholder?: string;
    icon?: string;
    inText?: string;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}