import { useEffect } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import EditCoRelatedDataModalBody from "@/components/molecules/common/modal/bodyModal/EditCoRelatedDataModalBody";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import { AuthorData } from "@/pages/settlement/basedata-manage/author/types";
import { authorActions, getAuthorData, updateAuthor } from "@/pages/settlement/basedata-manage/author/authorSlice";
import { BaseModalProps } from "@/pages/common/types";

export default function EditCoRelatedDataModal({ show, close, from }:EditCoRelatedDataProps) {
    let [coRelatedData, updatedRows] = useAppSelector((state:RootState) => [
        state.author.tmpCoRelatedData,
        state.dataTable.updatedRows as AuthorData[],
    ]);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    useEffect(() => {
    }, [coRelatedData]);

    async function allPageValidate() {
        let errPage = 0;
        let tmpCoRelatedData = JSON.parse(JSON.stringify(coRelatedData));
        for (let c of tmpCoRelatedData) {
            if (c.needInput) {
                errPage++;
                let sum = 0;
                let hasError = false;
                for (let n of c.new_co_info) {
                    if (!n.coRatio) n.coRatioError = "비율을 입력해주세요.";
                    if (n.coRatioError) hasError = true;
                    if (n.coRatio) sum += Number(n.coRatio);
                    if (c.type === "etc" && c.etc_tax_type === "E") {
                        if (!n.taxAmount) n.taxAmountError = "적용 세액을 입력해주세요.";
                        if (n.taxAmountError) hasError = true;
                    }
                }
                dispatch(authorActions.setAuthorDatas({
                    key: "tmpCoRelatedData", value: tmpCoRelatedData
                }))
                if (sum > 100) {
                    await alert("warning", "주의", `(${errPage}페이지) 비율의 총합은 100이 넘을 수 없습니다.`);
                    return false;
                }
                if (hasError) {
                    await alert("warning", "주의", `(${errPage}페이지) 유효하지 않은 항목이 있습니다.`);
                    return false;
                }
            }
        }
        return true;
    }

    async function applyEdit() {
        try {
            
            if (await allPageValidate()) {
                let answer = await alert("confirm", "확인", "적용하시겠습니까?");
                if (answer === "Y") {
                    let tmpUpdatedRows = JSON.parse(JSON.stringify(updatedRows));
                    for (let u of tmpUpdatedRows) {
                        u.coRelatedData = [];
                        for (let d of coRelatedData) {
                            if (u.id === d.author_id) {
                                switch (d.type) {
                                    case "series":
                                        u.coRelatedData.push({
                                            type: d.type,
                                            series_id: d.series_id,
                                            new_co_info: d.new_co_info
                                        })
                                        break;
                                    case "etc":
                                        u.coRelatedData.push({
                                            type: d.type,
                                            etc_id: d.etc_id,
                                            etc_tax_type: d.etc_tax_type,
                                            pre_amount: d.pre_amount,
                                            st_ratio: d.st_ratio,
                                            new_co_info: d.new_co_info          
                                        })
                                        break;
                                    case "mg":
                                        u.coRelatedData.push({
                                            type: d.type,
                                            mg_id: d.mg_id,
                                            new_co_info: d.new_co_info
                                        })
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                    }
                    let apiParams = {
                        updatedRows: tmpUpdatedRows
                    }
                    await dispatch(updateAuthor(apiParams)).unwrap();
                    await dispatch(getAuthorData({})).unwrap();
                    await alert("success", "수정 완료", "수정이 완료되었습니다.");
                    close();
                }
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    function setCoRelatedData(data: any[]) {
        // dispatch(authorActions.setTmpCoRelatedData({
        //     index: page, key: "new_co_info", objectIndex: index,
        //     value: {...coRelatedData[page]["new_co_info"], "coRatio": res.value, "coRatioError": res.ratioError}
        // }))
        console.log(data)
        dispatch(authorActions.setAuthorDatas({
            key: "tmpCoRelatedData", value: data
        }))
    }

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["공저 관련 정산 데이터 수정"]}
                customStyle={[{ width: "360px", height: "572px" }]}
                applyFunc={applyEdit}
            >
                <EditCoRelatedDataModalBody
                    coRelatedData={coRelatedData}
                    setCoRelatedData={setCoRelatedData}
                />
            </BaseModal>
        </>
    );
}

interface EditCoRelatedDataProps extends BaseModalProps {

}