import { useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import MgPaymentModalBody from "@/components/molecules/common/modal/bodyModal/MgPaymentModalBody";
import useDidUpdateEffect from "@/modules/hooks/useDidUpdateEffect";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";
import { CommonType } from "@/types/dataType";

export default function MgPaymentModal({ show, close, item, index, onApply }: EnrollAuthorModalProps) {
    const [paymentItems, setPaymentItems] = useState<PaymentItem[]>([]);
    const { alert } = useAlert();
    const mgId = item && item.mg_id;
    const mgRatio = item.mg_ratio || 0;
    const rowValue: PaymentItem = {
        id: null,
        mg_id: mgId,
        in_date: "",
        out_date: "",
        amount: 0,
        amount_out: 0,
        inDateError: "",
    };

    const apply = async () => {
        for (let item of paymentItems) {
            if (item.inDateError !== "") {
                return alert("warning", "주의", "입금일자가 입력되지 않았습니다.");
            }
        }
        const newItem = { ...item, payment: paymentItems };
        onApply(newItem, index);
        close();
    };

    useDidUpdateEffect(() => {
        if (item.payment.length) {
            let tmpPayment = [...item.payment];
            for (let i = 0; i < tmpPayment.length; i++) {
                tmpPayment[i] = {
                    ...tmpPayment[i],
                    amount_out: Math.round((tmpPayment[i].amount * mgRatio) / 100),
                    inDateError: "",
                };
            }
            setPaymentItems(tmpPayment);
        } else {
            setPaymentItems([rowValue]);
        }
    }, [show]);

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["선인세 입금/지급일 설정"]}
            customStyle={[{ width: "960px", height: "376px" }]}
            applyFunc={apply}
        >
            <MgPaymentModalBody
                mgId={mgId}
                mgRatio={mgRatio}
                rowValue={rowValue}
                paymentItems={paymentItems}
                setPaymentItems={setPaymentItems}
            />
        </BaseModal>
    );
}
interface EnrollAuthorModalProps extends BaseModalProps {
    item: {
        mg_id: CommonType.Id;
        mg_ratio: CommonType.Ratio;
        payment: {
            id: CommonType.Id;
            mg_id: CommonType.Id;
            amount: CommonType.Amount;
            in_date: CommonType.Date;
            out_date: CommonType.Date;
            amount_out?: CommonType.Amount | string;
            inDateError?: string;
        }[];
    };
    index: number;
    onApply: (data: MgPaymentData, index: number) => void;
}
export interface MgPaymentData {
    mg_id: CommonType.Id;
    mg_ratio: CommonType.Ratio;
    payment: PaymentItem[];
}
export interface PaymentItem {
    id: CommonType.Id | null;
    mg_id: CommonType.Id;
    amount: CommonType.Amount;
    in_date: CommonType.Date;
    out_date: CommonType.Date;
    amount_out?: CommonType.Amount | string;
    inDateError?: string;
}
