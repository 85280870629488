import styled from "styled-components";
import Text from "@/components/atoms/text";
import FileUpload from "../../excelUpload/FileUpload";
import { useState } from "react";
import useAlert from "@/modules/hooks/useAlert";

export default function ExcelUploadModalBody({ onDownloadSample, onUploadFile }: ExcelUploadModalBodyProps) {
    const [fileList, setFileList] = useState<File>({} as File);
    const { alert } = useAlert();

    const setFile = async (file: File) => {
        if (!file.name) return setFileList(file);
        const ext = file.name.split(".").pop() || "";
        const allowedExt = ["xlsx", "xls", "csv", "XLS"];
        if (!allowedExt.includes(ext)) {
            return await alert("warning", "주의", ".xlsx / .xls / .csv 파일만 업로드 가능합니다.");
        }
        setFileList(file);
        onUploadFile(file);
    };

    const changeFileList = async (files: FileList) => {
        if (files.length) {
            return await alert("warning", "주의", "한개의 파일만 업로드 가능합니다.");
        }
    };

    return (
        <AuthorExcelBodyWrap>
            <ExcelUploadWrap>
                <FileUpload file={fileList} setFile={setFile} setFileList={changeFileList} maxSize={50} />
            </ExcelUploadWrap>
            <DownGuideWrap>
                <Text
                    text="글링에서 사용하는 엑셀 양식이 필요하다면,"
                    customStyle={{ fontSize: "12px", fontWeight: "400", margin: "0 6px 0 0" }}
                />
                <UnderLineText onClick={onDownloadSample}>글링 양식 다운로드</UnderLineText>
            </DownGuideWrap>
        </AuthorExcelBodyWrap>
    );
}

const AuthorExcelBodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 480px;
    height: auto;
`;

const ExcelUploadWrap = styled.div`
    padding: 16px 20px;
`;

const DownGuideWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
`;

const UnderLineText = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.blue500};
    padding-bottom: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue500};
    cursor: pointer;
`;

interface ExcelUploadModalBodyProps {
    onUploadFile: (file: File) => void;
    onDownloadSample?: () => void;
}
