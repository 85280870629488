import { useEffect } from 'react'
import styled from 'styled-components'
import InquiryPost from '@/components/molecules/support/inquiry/InquiryPost'
import InquiryComment from '@/components/molecules/support/inquiry/InquiryComment'
import { getInquiryDetail, InquiryActions, replyAuth, AddCommentParams, addComment, removeComment } from '@/pages/support/inquiry/slice'
import { useAppDispatch, useAppSelector, RootState } from '@/modules/store'
import { useNavigate, useParams } from 'react-router-dom'
import useAlert from '@/modules/hooks/useAlert';
import useLoading from '@/modules/hooks/useLoading'

export default function InquiryDetail() {
    const { loadingOn, loadingOff } = useLoading();
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { alert } = useAlert();

    const [inquiryDetail, comments, auth, publisher_id, currentLoginedId] = useAppSelector((state: RootState) => [
        state.inquiry.inquiryDetail.detail,
        state.inquiry.inquiryDetail.comments,
        state.inquiry.inquiryDetail.auth,
        state.inquiry.inquiryDetail.detail.publisher_id,
        state.inquiry.inquiryDetail.detail.currentLoginedId
    ]);

    const secretBack = async () => {
        dispatch(InquiryActions.cleanAuth());
        await alert("info", "주의", "비밀글입니다.");
        navigate('/support/inquiry');
    };

    const saveComment = async (apiParams: AddCommentParams) => {
        try {
            loadingOn();
            await dispatch(addComment(apiParams)).unwrap();
            await alert("info", "저장 완료", `${auth === "q" ? "추가질문" : "답변"}이 저장 되었습니다.`)
        } catch (err:any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
            reloadDetail();
        }
    };

    const deleteCommentHandler = async (apiParams: {comment_id: number}) => {
        try {
            loadingOn();
            await dispatch(removeComment(apiParams)).unwrap();
            await alert("success", "삭제 완료", "삭제가 완료되었습니다.");
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
            await reloadDetail();
        }
    }

    const reloadDetail = async () => {
        try {
            loadingOn();
            const apiParams = {
                inquiryId: Number(params.id),
                target_class: "문의게시판",
                target_cd: "BV",
                target_id: Number(params.id),
                log_activity: "VU",
                withCredentials: true,
                credentials: "include"
            }
            await dispatch(getInquiryDetail(apiParams)).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    } 

    const fetchData = async () => {
        try {
            loadingOn();
            const apiParams = {
                inquiryId: Number(params.id),
                target_class: "문의게시판",
                target_cd: "BV",
                target_id: Number(params.id),
                log_activity: "VU",
                withCredentials: true,
                credentials: "include"
            }
            await dispatch(getInquiryDetail(apiParams)).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const replyAuthHandler = async () => {
        try {
            loadingOn();
            const apiParams = {
                withCredentials: true,
                credentials: "include",
                publisher_id: publisher_id
            };
            dispatch(replyAuth(apiParams));
        } catch (err: any) {
            alert("error", "에러 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    useEffect(()=> {
        fetchData();
        return () => {dispatch(InquiryActions.reset())}
    },[dispatch]);

    useEffect(() => {
        if (auth === 'q') {
            return
        } else if (publisher_id !== -1) {
            replyAuthHandler();
        }
    }, [dispatch, publisher_id]);

    useEffect(() => {
        if (auth === null) {
            secretBack();
        }
    }, [auth]);
    
    return (
        <StyledContainer>
            <InquiryPost inquiryDetail={inquiryDetail} id={params.id} auth={auth}/>
            <InquiryComment 
                userId={inquiryDetail.user_id} 
                comments={comments} auth={auth} 
                inquiryId={Number(params.id)}
                isMine={inquiryDetail.is_mine}
                saveComment={saveComment}
                deleteComment={deleteCommentHandler}
                reloadDetail={reloadDetail}
                currentLoginedId={currentLoginedId}
            />
        </StyledContainer>
    )
}

const StyledContainer = styled.div``;
