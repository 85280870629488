import { useEffect } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import ExcelUploadSeriesModalBody from "@/components/molecules/common/modal/bodyModal/ExcelUploadSeriesModalBody";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import { seriesActions, enrollSeries, getSeriesData } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import { BaseModalProps } from "@/pages/common/types";
import useAlert from "@/modules/hooks/useAlert";

export default function ExcelUploadSeriesModal({ show, close, from, seriesEnrollFunc }:ExcelUploadSeriesProps) {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    let [seriesAddDatas] = useAppSelector((state:RootState) => [
        state.series.seriesAddDatas
    ]);
    useEffect(() => {
    }, []);

    async function seriesEnroll() {
        try {
            if (seriesAddDatas.length === 0) return await alert("warning", "주의", "등록할 시리즈명이 없습니다.");
            let hasError = false;
            for (let i of seriesAddDatas) {
                let index = seriesAddDatas.indexOf(i);
                if (!i.name && !i.author && !i.ratioAll && !i.ratios[0].ratio ) continue;
                if (!i.name) {
                    dispatch(seriesActions.setEnrollSeriesDatas({
                        index: index, key: "seriesError", value: "시리즈명을 입력해주세요."
                    }));
                    hasError = true;
                }
                if (!i.author.author_name) {
                    dispatch(seriesActions.setEnrollSeriesDatas({
                        index: index, key: "authorError", value: "저자명을 입력해주세요."
                    }));
                    hasError = true;
                }
                if (i.author.co_info.length > 1 && i.author.co_info.every(c => !c.coRatio)) {
                    dispatch(seriesActions.setEnrollSeriesDatas({
                        index: index, key: "authorError", value: "공저간 비율을 설정해주세요."
                    }))
                    hasError = true;
                }
                if (i.seriesError !== "" || i.authorError !== "" || i.ratioError !== "") {
                    hasError = true;
                }
           }
           if (hasError) {
                return alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
            } else {
                await dispatch(enrollSeries(seriesAddDatas)).unwrap();
                await dispatch(getSeriesData({})).unwrap();
                await alert("success", "저장 완료", "저장이 완료되었습니다.");
                close();
            }
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        }
    }
    
    function reset() {
        dispatch(seriesActions.resetEnrollSeries());
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["엑셀 업로드 조회"]}
            customStyle={[{ width: "960px", height: "476px" }]}
            applyFunc={seriesEnroll}
            reset={reset}
        >
            <ExcelUploadSeriesModalBody
                from={from}
            />
        </BaseModal>
    );
}

interface ExcelUploadSeriesProps extends BaseModalProps {
    seriesEnrollFunc: Function;
}