import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from "@/api";

export interface releaseInfo {
    ver: string,
    url: urlList,
}

export interface urlList {
    dmg: string,
    exe: string
}

export interface GetDataParams {
    withCredentials: boolean,
    credentials: string
}

export const getReleaseInfo = createAsyncThunk("download/getReleaseInfo", async (apiParams:GetDataParams, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/download/getReleaseList`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})


const initialState: releaseInfo = {
    ver: '',
    url: {dmg: '', exe: ''},
}

export const downloadSlice = createSlice({
    name: 'download',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getReleaseInfo.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                const data = action.payload.data;
                state.url.dmg = data.urlList.dmg;
                state.url.exe = data.urlList.exe;
                state.ver = data.ver;
            } 
        });
    }
})

export const downloadActions = downloadSlice.actions;

export default downloadSlice.reducer; 