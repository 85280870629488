import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "@/api";

export interface consultData {
    name: string,
    companyName: string,
    phoneNumber: string,
    email: string,
    workNumber: string,
    message: string,
    consentValue: boolean,
}

export interface landingState {
    consultData: consultData;
    consultChanging: boolean
}

const initialState: landingState = {
    consultData: {
        name: "",
        companyName: "",
        phoneNumber: "",
        email: "",
        workNumber: "",
        message: "",
        consentValue: false, 
    },

    consultChanging: false
}


export const sendConsultEmail = createAsyncThunk("pricing/contact/sales", async(data: any, {rejectWithValue}) => {
    const body = {
        formData : {
            ...data
        }
    }
    try{
        const res = await api.post(`/api/pricing/contact/sales`, body);
        return res.data
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const landingSlice = createSlice({
    name: "landing",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(sendConsultEmail.pending, (state, action) => {
            state.consultChanging = false
        });
        builder.addCase(sendConsultEmail.fulfilled, (state, action) => {
            state.consultChanging = true;
        })
    }
});

export const landingActions = landingSlice.actions;
export default landingSlice.reducer;