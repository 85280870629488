import styled from "styled-components";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { colors } from "@/assets/styles/theme";
import { CirculationData, SalesPlatform } from "@/pages/portfolio/publisher/types";
import { useAppDispatch } from "@/modules/store";
import Text from "@/components/atoms/text";
import { ReactComponent as AddIcon } from "@/assets/icons/portfolio/ic-add-round.svg";
import useAlert from "@/modules/hooks/useAlert";
import CustomDatePicker from "@/components/molecules/portfolio/publisher/edit/CustomDatePicker";

export default function RetailDateContainer({ salesPlatformList }: ComponentProps) {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    const addPeriod = (index: number, circulation_period: CirculationData[]) => {
        const startCirculateAt = circulation_period[circulation_period.length-1].start_circulate_at;
        const endCirculateAt = circulation_period[circulation_period.length-1].end_circulate_at;
        if (!endCirculateAt) {
            return alert('warning', '경고', '진행중인 유통이 있습니다.');
        }
        if (!startCirculateAt) {
            return alert('warning', '경고', '유통 시작일을 선택해주세요.');
        }
        let circulation = (JSON.parse(JSON.stringify(circulation_period)));
        circulation.push({ start_circulate_at: "", end_circulate_at: null });
        dispatch({ type: "pubPortfolio/setCirculationPeriod", payload: { index: index, circulation_period: circulation }});
    }

    const deletePeriod = (platformIndex: number, index: number, circulation_period: CirculationData[]) => {
        if (circulation_period.length === 1) {
            return alert("warning", "경고", "유통 기간이 한 개 이상 존재해야 합니다.");
        }
        const circulation = JSON.parse(JSON.stringify(circulation_period));
        circulation.splice(index, 1);
        dispatch({ type: "pubPortfolio/setCirculationPeriod", payload: { index: platformIndex, circulation_period: circulation }});
    }

    const changeCirculationDate = (date: string, platformIndex: number, idx: number, start: boolean) => {
        if (date === "") {
            return dispatch({ type: "pubPortfolio/setCirculationDate", payload: {
                index: platformIndex, idx: idx, start: start, date: null
            } });    
        }
        dispatch({ type: "pubPortfolio/setCirculationDate", payload: {
            index: platformIndex, idx: idx, start: start, date: date
        }});
    }

    return (
        <Container>
            <FieldRow>
                <ColumnName>
                    <Text text="플랫폼" customStyle={{ fontSize: "12px" }}/>
                </ColumnName>
                <ColumnName>
                    <Text text="유통 시작일" customStyle={{ fontSize: "12px" }}/>
                </ColumnName>
                <ColumnName width="60px" />
                <ColumnName>
                    <Text text="유통 종료일" customStyle={{ fontSize: "12px" }}/>
                </ColumnName>
                <ColumnName width="60px" />
            </FieldRow>
            {salesPlatformList.map((p:any, i:number) => 
                <ItemRow key={i}>
                    <Item>
                        {p.platform_name}
                        <IconWrapper onClick={() => addPeriod(i, p.circulation_period)}>
                            <AddIcon />
                        </IconWrapper>
                    </Item>
                    <ItemRow dir="col">
                    {
                        p.circulation_period.map((d:CirculationData, idx:number) => (
                            <ItemRow key={idx}>
                                <Item>
                                    <CustomDatePicker
                                        settingDate={d.start_circulate_at}
                                        endAtDate={d.end_circulate_at}
                                        beforeEndDate={p.circulation_period.length-1 === idx && idx !== 0 ? p.circulation_period[idx-1].end_circulate_at : ""}
                                        onChange={(date) => changeCirculationDate(date, i, idx, true)}
                                        customStyle={{ width: "160px" }}
                                    />
                                </Item>
                                <Item width="60px" padding="0px" justifyContent="center">
                                    <Text
                                        text="~"
                                        customStyle={{ fontSize: "14px", fontWeight: "400"}}
                                    />
                                </Item>
                                <Item>
                                    <CustomDatePicker
                                        settingDate={d.end_circulate_at}
                                        unsetButton
                                        startAtDate={d.start_circulate_at}
                                        onChange={(date) => changeCirculationDate(date, i, idx, false)}
                                        customStyle={{ width: "160px" }}
                                        customLabel="유통 진행 중"
                                    />
                                </Item>
                                <IconWrap>
                                    <Clear 
                                        fill={colors.gray600} 
                                        width={24} 
                                        height={24}
                                        onClick={() => deletePeriod(i, idx, p.circulation_period)}
                                    />
                                </IconWrap>
                            </ItemRow>
                        ))
                    }
                    </ItemRow>
                </ItemRow>
                )
            }
        </Container>
    )
}

const Container = styled.ul`
    width: 670px;
    max-height: 288px;
    overflow: auto;
`

const IconWrapper = styled.div`
    margin-left: 8px;
    svg {
        cursor: pointer;
    }
`

const ItemRow = styled.div`
    display: flex;
    flex-direction: ${({ dir }:{ dir?: "col" }) => dir ? "column" : "row"};
`

const Item = styled.div<{ width?: string, justifyContent?: string, padding?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "180px"};
    height: 60px;
    font-size: 12px;
    padding: ${({ padding }) => padding ? padding : "0 0 0 8px"};
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    min-height: 48px;
`

const ColumnName = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "180px"};
    height: 48px;
    font-size: 12px;
    padding: 8px;
`

const IconWrap = styled.div<{ direction?: string, disable?: boolean, width?: string }>`
    display: flex;
    flex-direction: ${({ direction }) => direction ? direction : "row"};
    align-items: center;
    justify-content: center;
    width: ${({ width }) => width ? width : "60px"};
    height: 60px;
    font-size: 12px;
    padding: 8px;
    cursor: ${({ disable }) => disable ? "default" : "pointer"};
`

interface ComponentProps {
    salesPlatformList: SalesPlatform[];
}
