import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Text from "@/components/atoms/text";
import { colors } from '@/assets/styles/theme';
import { RootState, useAppSelector } from "@/modules/store";
import { ReactComponent as LockIcon } from "@/assets/icons/lock.svg";
import { useAppDispatch } from "@/modules/store";
import usePagination from '@/components/molecules/common/pagination/usePagination';
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import { useLayoutEffect, useState } from "react";
import SimpleDataTable from "@/components/molecules/common/table/SimpleDataTable"
import Button from "@/components/atoms/button";
import { getInquiryData, getUserInquiry, InquiryData } from "@/pages/support/inquiry/slice";

export default function InquiryList() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const { data, loading } = useAppSelector((state: RootState) => state.inquiry);
    const { user_id } = useAppSelector((state: RootState) => state.user);
    const { setDataCount, setPage, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "inquiryList" });

    const [isClicked, setClicked] = useState([true, false, false, false]);
    let [filterData, setFilterData] = useState<InquiryData[]>(data);
    const [isMyPage, setIsMyPage] = useState(window.location.pathname === '/mypage' ? true : false);
    
    const fetchData = async () => {
        try {
            loadingOn();
            if (isMyPage) {
                await dispatch(getUserInquiry(user_id)).unwrap()
            }else {
                await dispatch(getInquiryData()).unwrap();
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    useLayoutEffect(() => {
        setIsMyPage(window.location.pathname === '/mypage' ? true : false);
        fetchData();

    }, [dispatch, window.location.pathname, user_id]);

    useLayoutEffect(() => {
        setFilterData(data);
    },[data]);
    
    useLayoutEffect(() => {
        if(filterData?.length) {
            setDataCount(filterData.length);
        };
    },[data, itemsPerPage, setDataCount, filterData, isMyPage]);
    
    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    }

    const textStyled = {
        fontSize: "14px", 
        fontWeight: "400", 
        lineHeight: "24.5px"
    }

    const completeTextStyled = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "14px",
    }

    const buttonStyled = {
        width: '80px',
        height: '32px',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "14px",
        margin: "0px 16px 0px 0px",
    }

    const headers = [
        {text: '카테고리', value: "category", width: "80px"},
        {text: '출판사', value: "publisher_name", width: "80px"},
        {text: '제목', value: "title", width: "700px"},
        {text: '답변여부', value: "complete", width: "60px"},
    ]

    async function clickButton(label: string, num: number) {
        const newIcClicked = isClicked.map((d, i) => i === num ? d = true : d = false);
        setClicked(newIcClicked);
        setPage(1);

        if(label === 'all') {
            setFilterData(data)
        } else if(label === 'suggetion') {
            const newFilterData = data.filter(inquiry => inquiry.category === '건의사항');
            setFilterData(newFilterData);
        } else if(label === 'biz') {
            const newFilterData = data.filter(inquiry => inquiry.category === '정산');
            setFilterData(newFilterData)
        } else if(label === 'etc') {
            const newFilterData = data.filter(inquiry => inquiry.category === '기타');
            setFilterData(newFilterData);
        }
    }

    const columns = {
        category: (item: any, idx: number) => (
            <Text 
                key={(page - 1) * itemsPerPage + idx} 
                text={item["category"]} 
                customStyle={{ ...textStyled, width: "80px", color: colors.blue500 }} 
            />
        ),
        publisher_name: (item: any, idx: number) => (
            <Text 
                key={(page - 1) * itemsPerPage + idx} 
                text={item["publisher_name"]} 
                customStyle={{ ...textStyled, width: "80px" }}
            />
       ),
        title: (item: any, idx: number) => (
            <TitleTableStyled display="flex" width="600px">
                <Text 
                    key={(page - 1) * itemsPerPage + idx} 
                    text={item["title"]} 
                    customStyle={textStyled}
                />
                {item["secret"] ? <LockIcon style={{margin: "0px 0px 0px 8px"}} /> : null}
            </TitleTableStyled>
        ),
        complete: (item: any, idx: number) => (
            <Text 
                key={(page - 1) * itemsPerPage + idx} 
                text={item["complete"] === 0 ? "미답변" : (item["complete"] === 1) ? "답변완료" : "추가답변대기"} 
                customStyle={{ ...completeTextStyled, color: item["complete"] === 1 ? colors.blue500 : colors.gray600 }} 
            /> 
        )
    }

    const option = {
        movePage: true
    }

    return (
        <MypageSectionLayout>
            <StyledTitleContainer>
                <Text text={isMyPage ? "내가 남긴 문의" : "문의하기"} customStyle={titleStyled}/>
            </StyledTitleContainer>
            <NavBar>
                <Button type={isClicked[0] ? "black-outline" : "gray-outline"} label="전체" customStyle={buttonStyled} onClickHandler={() => clickButton("all", 0)}></Button>
                <Button type={isClicked[1] ? "black-outline" : "gray-outline"} label="건의사항" customStyle={buttonStyled} onClickHandler={() => clickButton("suggetion", 1)}></Button>
                <Button type={isClicked[2] ? "black-outline" : "gray-outline"} label="정산" customStyle={buttonStyled} onClickHandler={() => clickButton("biz", 2)}></Button>
                <Button type={isClicked[3] ? "black-outline" : "gray-outline"} label="기타" customStyle={buttonStyled} onClickHandler={() => clickButton("etc", 3)}></Button>
            </NavBar>
            {!loading &&
                <SimpleDataTable 
                    from="inquiryList"
                    headers={headers}
                    needHeader={false}
                    itemKey="inquiryList"
                    columns={columns}
                    items={filterData}
                    options={option}
                    type="inquiry"
                />
            }
            <PaginationStyled>
            <Pagination pageCount={pageCount} totalVisible={totalVisible} from="inquiryList" />
            </PaginationStyled>
            {!isMyPage && <ButtonStyled>
                <Button label="글쓰기" onClickHandler={()=>navigate('/support/inquiry/write?type=insert')} customStyle={{width: "100px", height: "32px"}} />
            </ButtonStyled> }
        </MypageSectionLayout>
    )
}

const MypageSectionLayout = styled.div`
    width: 900px;
`

const StyledTitleContainer = styled.div`
    display: flex;
    margin: 0px 0px 24px 0px;
`

const NavBar = styled.div`
    margin: 0px 0px 24px 0px;
`

const TitleTableStyled = styled.div<NavBarProps>`
    display: ${props => props.display};
    width: ${props => props.width};
`

const PaginationStyled = styled.div`
      display: flex;
      justify-content: center;
`;

const ButtonStyled = styled.div`
    display: flex;
    justify-content: flex-end;
`

interface NavBarProps {
    width?: string,
    display?: string
}