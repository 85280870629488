import { useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import styled from "styled-components";
import TextInput from "@/components/atoms/input/TextInput";
import useAlert from "@/modules/hooks/useAlert";
import { saveTemplate, getTemplateList } from "@/pages/ebook-maker/slice";
import useLoading from "@/modules/hooks/useLoading";

export default function AddTemplateModal({ show, close }: any) {
    const { contentList, bookInfo, templateList } = useAppSelector((state: RootState) => state.ebookMaker);
    const [templateName, setTemplateName] = useState("");
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading(); 

    const reset = () => {
        setTemplateName("");
    }

    const apply = async () => {
        try {
            const templateNameList = templateList.map(t => t.template_name.trim());
            if (templateNameList.includes(templateName.trim())) {
                return alert("warning", "주의", "중복된 템플릿 이름입니다.");
            }
            const answer = await alert("confirm", "저장 확인", `저장 하시겠습니까?`);
            if (answer === "Y") {
                loadingOn();
                await dispatch(saveTemplate({
                    name: templateName.trim(),
                    contentList 
                })).unwrap();
                await dispatch(getTemplateList(bookInfo)).unwrap();
                reset();
                close();
                alert("success", "저장 완료", "템플릿이 저장되었습니다.");
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message)
        } finally {
            loadingOff();
        }
    }

    const inputOnChange = (e: any) => {
        setTemplateName(e.target.value);
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["템플릿 추가"]}
            customStyle={[{ width: "360px", height: "180px" }]}
            applyFunc={() => {apply();}}
            reset={reset}
        >
           <ModalBodyWrapper>
                <LabelWrapper>템플릿 이름</LabelWrapper>
                <TextInput 
                    value={templateName}
                    onChangeHandler={inputOnChange}
                    placeholder="템플릿의 이름을 입력해주세요"
                    customStyle={{ width: "200px", height: "32px", fontSize: "12px", padding: "4px 8px" }}
                />
           </ModalBodyWrapper>
        </BaseModal>
    );
}

const ModalBodyWrapper = styled.div`
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: space-between;
`

const LabelWrapper = styled.div`
    display: flex;
    width: 120px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
`
