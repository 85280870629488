import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import {TableHeaderData, TableData} from "@/components/molecules/landing/pricing/types"

export default function FunctionTable(props: TableDataProps) {
    const tableData = props.tableData;
    const tableHeaderData = props.tableHeaderData;
    
    const textStyle = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        color: colors.white,
        width: '300px',
    }

    return (
        <StyledTableRow>
            <TableHeader>
                <Text text={tableHeaderData.type} customStyle={{...textStyle, margin: '10px'}} />
                <Text text={tableHeaderData.free} customStyle={{...textStyle, textAlign: 'center'}} />
                <Text text={tableHeaderData.standard}  customStyle={{...textStyle, textAlign: 'center'}}/>
                <Text text={tableHeaderData.enterprise}  customStyle={{...textStyle, textAlign: 'center'}}/>
            </TableHeader>
            {tableData.map((item, index) => (
                <TableCell key={index}>
                    <Text text={item.type} customStyle={{...textStyle, margin: '10px'}}></Text>
                    {item.freeText ? 
                    <Text text={item.freeText} customStyle={{...textStyle, textAlign: 'center'}}></Text>
                    :<Text text={item.free ? '✔' : '✖'} customStyle={{...textStyle, textAlign: 'center'}}></Text>
                    }
                    <Text text={item.standard ? '✔' : '✖'} customStyle={{...textStyle, textAlign: 'center'}}></Text>
                    <Text text={item.enterprise ? '✔' : '✖'} customStyle={{...textStyle, textAlign: 'center'}}></Text>
                </TableCell>
            ))}
        </StyledTableRow>
    )
};

const StyledTableRow = styled.div`
    display: flex;
    flex-direction: column;
    > * {
        &:not(:last-child) {
           border-bottom: 1px solid white;
        }
      }
    
`

const TableHeader = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
`

const TableCell = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
`

interface TableDataProps {
    tableData: TableData[];
    tableHeaderData: TableHeaderData;
}
