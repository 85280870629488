import styled from "styled-components";
import ApexCharts from "react-apexcharts";
import { RootState, useAppSelector } from "@/modules/store";
import { colors } from "@/assets/styles/theme";


export default function DailyContentAnalysisChartWrapper() {
    const { dailySeriesAnalysisData } = useAppSelector((state: RootState) => state.pubDashboard);
    const chartData: ChartData = [];
    let sum = 0;
    for (let d of dailySeriesAnalysisData) {
        if (dailySeriesAnalysisData.indexOf(d) < 5) {
            chartData.push({
                series_name: d.series_name,
                author_name: d.author_name,
                amount: d.amount
            });
        } else {
            sum += d.amount;
        }
    }
    chartData.push({
        series_name: "기타",
        author_name: "",
        amount: sum
    });

    const data: any = {
        series: chartData.map(c => c.amount),
        options: {
            chart: {
                height: 500,
                type: 'donut',
                toolbar: {
                    show: false
                }
            },
            // colors: ["#AF7AC5", "#868E96", "#DEE2E6", "#E9ECEF", "#F8F9FA"],
            colors: [colors.blue500, colors.gray600, colors.gray500, colors.gray400, colors.gray300, colors.gray100],
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8
                    }
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: "70%",
                        background: "transparent",
                        lables: {
                            show: false,
                            name: {
                                show: true,
                                fontSize: "22px",
                                formatter: function (val:any) {
                                    return val;
                                }
                            }
                        },
                        value: {
                            show: false
                        },
                        total: {
                            show: false
                        }
                    }
                }
            },
            labels: chartData.map(c => c.series_name),
            legend: {
                show: false
            },
            xaxis: {
                labels: {
                    show: false
                }
            },
            yaxis: {
                show: false
            },
            grid: { show: false },
            tooltip: {
                shared: false,
                intersect: true,
                followCursor: false,
                custom: function(data: any) {
                    const { series, seriesIndex, w } = data;
                    const color = w.globals.colors[seriesIndex];
                    return `
                        <div class="tooltip-box">
                            <div>${chartData[seriesIndex].series_name}</div>
                            <div>${chartData[seriesIndex].author_name ? `/ ${chartData[seriesIndex].author_name}` : ""}</div>
                            <div class="amount-wrap">
                                <span class="color-box" style="background-color: ${color};"></span>
                                <div>${series[seriesIndex].toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원"}</div>
                            </div>
                        </div>
                    `
                },
            }
        },
    }

    return (
        <ChartWrapper>
            <StyledCharts 
                options={data.options}
                series={data.series}
                type="donut"
                width={300}
                height={300}
            />
        </ChartWrapper>   
    )
}

const StyledCharts = styled(ApexCharts)`
    margin-left: 24px;
    .apexcharts-tooltip {
        box-shadow: 0px 0px 15px rgba(112, 144, 176, 0.2) !important;
    }
    .tooltip-box {
        display: flex;
        flex-direction: column;
        padding: 8px 10px;
        min-width: 120px;
        background: #FFFFFF;
        border-radius: 4px;
        color: #121417;
        font-size: 10px;
        line-height: 14px;
        .amount-wrap {
            display: flex;
            margin-top: 10px;
            align-items: center;
            .color-box {
                position: relative;
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
        }
    }
`

const ChartWrapper = styled.div`
`

interface ChartData2 {
    series_name: string;
    author_name: string;
    amount: number;
}
type ChartData = Array<ChartData2>