import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addComma } from "@/modules/utils/filter";
import { getThisMonth } from "@/modules/utils/date";
import type { MgState, UpdatedParams, Item, DeleteParams } from "./types";
import type { AtcmpParams } from "@/types/autoComplete";

const name = "inquiry/publisher/mg";
const initialState: MgState = {
    items: [],
    total_amt: 0,
    total_cnt: 0,

    initialDataLoaded: false,
    fetchLoading: false,

    changedParamsId: 0, // 데이터 조회 params 변경시 업데이트
    totalDate: getThisMonth() || "",
    selectedPlatform: [],
    contractStartDate: getThisMonth() || "",
    contractEndDate: getThisMonth() || "",
    mgType: "platform",
    complete: "all",
    contractType: "all",
    contractPrice: {
        start: 0,
        end: 0,
    },
    deductionPrice: {
        start: 0,
        end: 0,
    },
    remainPrice: {
        start: 0,
        end: 0,
    },
    target: "",

    sliderMaxValue: {
        contractPrice: 0,
        deductionPrice: 0,
        remainPrice: 0,
    },
    sliderMinValue: {
        contractPrice: 0,
        deductionPrice: 0,
        remainPrice: 0,
    },

    // 자동완성
    atcmpList: [],

    // 기타
    startDateError: "",
    endDateError: "",
    reset: false,
};

// 데이터 조회
export const getMgData = createAsyncThunk(`${name}/getMgData`, async (_, { rejectWithValue, getState, signal }) => {
    try {
        const { inquiryMg }: any = getState();
        const apiParams = {
            totalDate: inquiryMg.totalDate,
            selectedPlatform: inquiryMg.selectedPlatform,
            contractStartDate: inquiryMg.contractStartDate,
            contractEndDate: inquiryMg.contractEndDate,
            mgType: inquiryMg.mgType,
            complete: inquiryMg.complete,
            contractType: inquiryMg.contractType,
            contractPrice: inquiryMg.contractPrice,
            deductionPrice: inquiryMg.deductionPrice,
            remainPrice: inquiryMg.remainPrice,
            target: inquiryMg.target,
        }
        return (await api.post(`/api/settlement/newSalesInquiry/mg/publisher/select`, apiParams, { signal })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

// 데이터 업데이트
export const updateMgData = createAsyncThunk(
    `${name}/updateMgData`,
    async (apiParams: UpdatedParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSalesInquiry/mg/update`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 데이터 삭제
export const deleteData = createAsyncThunk(
    `${name}/deleteData`,
    async (apiParams: DeleteParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/salesInquiry/mg/delete`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 자동완성 조회 (계약대상)
export const getMgAtcmp = createAsyncThunk(
    `${name}/getMgAtcmp`,
    async (apiParams: AtcmpParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSearchKeyword/getAtcmp/mg/all/select", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const mgSlice = createSlice({
    name,
    initialState,
    reducers: {
        setFilterOption: (state, { payload }) => {
            const {
                target,
                totalDate,
                mgType,
                selectedPlatform,
                contractStartDate,
                contractEndDate,
                complete,
                contractType,
                contractPrice,
                remainPrice,
                deductionPrice,
            } = payload;

            state.target = target ?? state.target;
            state.totalDate = totalDate ?? state.totalDate;
            state.mgType = mgType ?? state.mgType;
            state.selectedPlatform = selectedPlatform ?? state.selectedPlatform;
            state.contractStartDate = contractStartDate ?? state.contractStartDate;
            state.contractEndDate = contractEndDate ?? state.contractEndDate;
            state.complete = complete ?? state.complete;
            state.contractType = contractType ?? state.contractType;
            state.contractPrice = contractPrice ?? state.contractPrice;
            state.deductionPrice = deductionPrice ?? state.deductionPrice;
            state.remainPrice = remainPrice ?? state.remainPrice;
        },
        setError: (state, { payload }) => {
            const { startDateError, endDateError } = payload;

            state.startDateError = startDateError ?? state.startDateError;
            state.endDateError = endDateError ?? state.endDateError;
        },
        setItem: (state, action: PayloadAction<{ index:number; item:any }>) => {
            const { index, item } = action.payload;
            state.items[index] = item;
        },
        setParamsId(state){
            state.changedParamsId += 1;
        },
        setNoData(state) {
            state.items = [];
            state.total_cnt = 0;
            state.total_amt = 0;
        },
        setReset(state, { payload }) {
            state.reset = payload;
        },
        resetStore: () => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        // 데이터 조회
        builder
            .addCase(getMgData.pending, (state) => {
                state.fetchLoading = true;
            })
            .addCase(getMgData.fulfilled, (state, action) => {
                const { result, total_cnt, total_amt, sliderMinValue, sliderMaxValue } = action.payload.data;
                state.fetchLoading = false;
                state.initialDataLoaded = true;

                if (!result.length) {
                    state.items = [];
                    state.total_cnt = 0;
                    state.total_amt = 0;
                    return;
                }

                for (let item of result as Item[]) {
                    item.contractPrice = addComma(item.contractPrice);
                }
                state.items = result;
                state.total_cnt = total_cnt;
                state.total_amt = Number(total_amt.toFixed(2));

                // 금액 필터 값 있을 때 기존 min, max값 유지
                if (
                    state.contractPrice.start ||
                    state.contractPrice.end ||
                    state.deductionPrice.start ||
                    state.deductionPrice.end ||
                    state.remainPrice.start ||
                    state.remainPrice.end
                )
                    return;

                state.sliderMaxValue = sliderMaxValue;
                state.sliderMinValue = sliderMinValue;
            });

        // 자동완성 - 전체
        builder.addCase(getMgAtcmp.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
    },
});

export const { setFilterOption, setError, setNoData, setItem, setParamsId, setReset, resetStore } = mgSlice.actions;
export default mgSlice.reducer;
