import { useState } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import useLoading from "@/modules/hooks/useLoading";
import { FileData } from "@/components/molecules/common/modal/ExcelUploadSalesModal";
import { Item } from "@/pages/settlement/sales-entry/monthly/types";
import useAlert from "@/modules/hooks/useAlert";
import {
    setItems,
    setLastId,
    setSummary,
    uploadExcelData,
    setNonMatchedData,
    setAfterExcelUpload,
    setUploaderMsg,
} from "@/pages/settlement/sales-entry/monthly/monthlySlice";
import { addComma } from "@/modules/utils/filter";
import { NonMatchedData } from "@/types/excelUpload";
import usePagination from "@/components/molecules/common/pagination/usePagination";

const useMonthlyExcelUpload = () => {
    const { lastId, platformId, yearmon, yearmon_st } = useSelectorTyped((state: RootState) => state.entryMonthly);
    const { platformList } = useSelectorTyped((state: RootState) => state.platform);
    const dispatch = useAppDispatch();
    const [showMatchingModal, setShowMatchingModal] = useState(false);
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();

    const applyExcelUpload = async (fileData: FileData) => {
        try {
            loadingOn();
            dispatch(setUploaderMsg(""));

            const { form } = fileData;
            let platformName = "";
            for (let i of platformList) {
                if (i.platform_id === platformId) {
                    platformName = i.platform_name;
                    break;
                }
            }
            const apiParams = {
                ...fileData,
                type: "Monthly",
                platformId,
                platformName,
                yearmon,
                yearmonSt: yearmon_st,
            };

            const { data } = await dispatch(uploadExcelData(apiParams)).unwrap();

            let totalAmountPl = 0;
            let totalAmountPu = 0;
            let tmpLastId = lastId;

            if (data.length) {
                for (let i of data) {
                    totalAmountPl += i.amount_pl;
                    totalAmountPu += i.amount_pu;
                    i.amount_pl = addComma(i.amount_pl);
                    i.amount_pu = addComma(i.amount_pu);
                    if (i.amount_au) i.amount_au = addComma(i.amount_au);
                    tmpLastId += 1;
                    i.id = tmpLastId;
                }
                dispatch(setItems(data));
                dispatch(setLastId(tmpLastId));

                if (form === "플랫폼") {
                    const filteredData = filterNonMatchedData(data);
                    if (filteredData.length) {
                        setShowMatchingModal(true);
                        dispatch(setNonMatchedData(filteredData));
                    } else {
                        dispatch(setUploaderMsg("EXCEL_UPLOADED"));
                    }
                }
                dispatch(setAfterExcelUpload(true));
            } else {
                // 엑셀 파일에 데이터가 없거나 판매일자가 없는 경우
                const items = [
                    {
                        id: (tmpLastId += 1),
                        platform_id: platformId,
                        book_name: "",
                        series_id: 0,
                        series_name: "",
                        author_name: "",
                        quantity: "",
                        amount_pl: "", // 판매금액 (플랫폼 판매금액)
                        amount_pu: "", // 출판사 정산금액
                        author_ratio: 0,
                        amount_au: 0,
                        bookNameError: "",
                        seriesError: "",
                        quantityError: "",
                        amount_pl_error: "",
                        amount_pu_error: "",
                    },
                ];

                dispatch(setItems(items));
                dispatch(setLastId(tmpLastId));
                dispatch(setUploaderMsg("NO_DATA"));
                return await alert("warning", "주의", "입력하신 판매 일에 데이터가 존재하지 않습니다.");
            }
            dispatch(
                setSummary({
                    total_amt_pl: Number(totalAmountPl.toFixed(2)),
                    total_amt_pu: Number(totalAmountPu.toFixed(2)),
                    total_cnt: data.length,
                }),
            );
        } catch (err) {
            dispatch(setUploaderMsg("ERROR"));
            return await alert(
                "error",
                "오류 발생",
                "업로드중 오류가 발생하였습니다.\n파일 형식 또는 내용이 선택한 플랫폼과 맞는지 확인해주세요.",
            );
        } finally {
            loadingOff();
        }
    };

    // 업로드된 엑셀 데이터중 시리즈 매칭 안된것들 선별
    const filterNonMatchedData = (data: Item[]) => {
        let filteredData: Partial<NonMatchedData>[] = [];
        data.forEach((row, idx) => {
            if (!row.series_name) {
                filteredData.push({
                    originIdx: idx,
                    isChecked: false,
                    ...row,
                });
            }
        });
        return filteredData;
    };

    return {
        showMatchingModal,
        setShowMatchingModal,
        applyExcelUpload,
    };
};

export default useMonthlyExcelUpload;
