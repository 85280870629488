import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { ReactComponent as AlignLeft } from "@/assets/icons/ebook-maker/alignLeft.svg";
import { ReactComponent as AlignCenter } from "@/assets/icons/ebook-maker/alignCenter.svg";
import { ReactComponent as AlignRight } from "@/assets/icons/ebook-maker/alignRight.svg";
import { ReactComponent as Bold } from "@/assets/icons/ebook-maker/bold.svg";
import { ReactComponent as Italic } from "@/assets/icons/ebook-maker/italic.svg";
import { ReactComponent as Link } from "@/assets/icons/ebook-maker/link.svg";
import { ReactComponent as SizeH1 } from "@/assets/icons/ebook-maker/sizeH1.svg";
import { ReactComponent as SizeH2 } from "@/assets/icons/ebook-maker/sizeH2.svg";
import { ReactComponent as SizeH3 } from "@/assets/icons/ebook-maker/sizeH3.svg";
import { ReactComponent as SizeH4 } from "@/assets/icons/ebook-maker/sizeH4.svg";
import { ReactComponent as SizeH5 } from "@/assets/icons/ebook-maker/sizeH5.svg";
import { ReactComponent as SizeH6 } from "@/assets/icons/ebook-maker/sizeH6.svg";
import { ReactComponent as SizeP } from "@/assets/icons/ebook-maker/sizeP.svg";
import { ReactComponent as Underline } from "@/assets/icons/ebook-maker/underline.svg";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { ReactComponent as More } from "@/assets/icons/more.svg";
import { useAppDispatch } from "@/modules/store";
import { Copyright } from "@/types/dataType";
import { useState } from "react";
import TextInput from "@/components/atoms/input/TextInput";
import { colors } from "@/assets/styles/theme";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetLinkOptionModal from "@/components/molecules/ebook-maker/copyright/SetLinkOptionModal";

export default function SortableItem ({ item }: any) {
    const [showSetLink, setShowSetLink] = useState(false);
    const dispatch = useAppDispatch();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: item.id });

    const deleteContent = () => {
        dispatch({ type: "ebookMaker/deleteCopyrightContent", payload: item.order - 1 });
    };

    if (item.isEmpty) {
        return (
            <Ul
                ref={setNodeRef} 
                {...attributes} 
                transform={transform}
                transition={transition}
            >
                <Li width="40px" textAlign="center">
                    <StyledMoreIcon fill={colors.gray600} {...listeners}/>
                </Li>
                <Li width="60px" textAlign="center" paddingLeft="0">{item.order}</Li>
                <Li width="120px"></Li>
                <Li width="630px"></Li>
                <Li width="50px">
                    <ClearWrapper>
                        <Clear onClick={() => deleteContent()} width={24} height={24} fill={colors.error} />
                    </ClearWrapper>
                </Li>
            </Ul>
        );
    }

    const inputOnChange = (e: any) => {
        dispatch({
            type: "ebookMaker/setCopyrightContent",
            payload: {
                index: item.order - 1,
                value: e.target.value,
            },
        });
    };

    const setOption = (type: string) => {
        if (type === "link") {
            // 링크 타입 모달 (메일로 연결, 하이퍼링크, 해당없음) 띄우기!
            setShowSetLink(true);
        } else {
            dispatch({
                type: "ebookMaker/setCopyrightContentBooleanOption",
                payload: {
                    index: item.order - 1,
                    type,
                },
            });
        }
    };

    const changeSize = (to: Copyright.Size) => {
        dispatch({
            type: "ebookMaker/setCopyrightContentCaseOption",
            payload: {
                index: item.order - 1,
                type: "size",
                to,
            },
        });
    };

    const changeAlign = (to: Copyright.Align) => {
        dispatch({
            type: "ebookMaker/setCopyrightContentCaseOption",
            payload: {
                index: item.order - 1,
                type: "align",
                to,
            },
        });
    };

    const sizeIcons: any = {
        P: <SizeP onClick={() => changeSize(Copyright.Size.H1)} />,
        H1: <SizeH1 onClick={() => changeSize(Copyright.Size.H2)} />,
        H2: <SizeH2 onClick={() => changeSize(Copyright.Size.H3)} />,
        H3: <SizeH3 onClick={() => changeSize(Copyright.Size.H4)} />,
        H4: <SizeH4 onClick={() => changeSize(Copyright.Size.H5)} />,
        H5: <SizeH5 onClick={() => changeSize(Copyright.Size.H6)} />,
        H6: <SizeH6 onClick={() => changeSize(Copyright.Size.P)} />,
    };

    const alignIcons: any = {
        left: <AlignLeft onClick={() => changeAlign(Copyright.Align.CENTER)} />,
        center: <AlignCenter onClick={() => changeAlign(Copyright.Align.RIGHT)} />,
        right: <AlignRight onClick={() => changeAlign(Copyright.Align.LEFT)} />,
    };

    const sizeIcon = sizeIcons[item.size];
    const alignIcon = alignIcons[item.align];

    return (
        <Ul 
            ref={setNodeRef} 
            {...attributes} 
            transform={transform}
            transition={transition}
        >
            <Li width="40px" textAlign="center">
                <StyledMoreIcon fill={colors.gray600} {...listeners}/>
            </Li>
            <Li width="60px" textAlign="center" paddingLeft="0">{item.order}</Li>
            <Li width="120px">{item.title}</Li>
            <Li width="630px">
                <ContentWrapper>
                    <TextInput
                        value={item.value}
                        onChangeHandler={inputOnChange}
                        customStyle={{ width: "280px", height: "32px", fontSize: "12px", padding: "5.5px 8px" }}
                    />
                    <OptionWrapper>
                        {sizeIcon}
                        {alignIcon}
                        <Bold onClick={() => setOption("bold")} fill={item.bold ? colors.blue500 : colors.gray400} />
                        <Italic onClick={() => setOption("italic")} fill={item.italic ? colors.blue500 : colors.gray400} />
                        <Underline
                            onClick={() => setOption("underline")}
                            fill={item.underline ? colors.blue500 : colors.gray400}
                        />
                        <Link
                            onClick={() => setOption("link")}
                            fill={item.link === Copyright.Link.NONE ? colors.gray400 : colors.blue500}
                        />
                    </OptionWrapper>
                </ContentWrapper>
            </Li>
            <Li width="50px">
                <ClearWrapper>
                    <Clear onClick={() => deleteContent()} width={24} height={24} fill={colors.error} />
                </ClearWrapper>
            </Li>
            <ModalPortal>
                <SetLinkOptionModal
                    from="copyright"
                    show={showSetLink}
                    close={() => setShowSetLink(false)}
                    link={item.link}
                    idx={item.order-1}
                />
            </ModalPortal>
        </Ul>
    );
};

const Ul = styled.ul<{ transform: any; transition: any; }>`
    transform: ${({ transform }) => CSS.Transform.toString(transform)};
    transition: ${({ transition }) => transition};
    user-select: none;
    box-sizing: border-box;
    
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
`

const Li = styled.li<{ width: string; textAlign?: string; paddingLeft?: string; }>`
    display: flex;
    width: ${({ width }) => width};
    height: 100%;
    justify-content: ${({ textAlign }) => (textAlign ? textAlign : "left")};;
    align-items: center;
    padding-left: ${({ paddingLeft }) => paddingLeft ? paddingLeft : "8px"};
    font-size: 14px;
`

const ClearWrapper = styled.div`
    padding: 8px 0;
    svg {
        cursor: pointer;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
`;

const OptionWrapper = styled.div`
    display: flex;
    margin-left: 32px;
    width: 296px;
    justify-content: space-between;
    svg {
        cursor: pointer;
    }
`;

const StyledMoreIcon = styled(More)`
    cursor: grab;
`