import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export default function NavBar({ data, selected }: NavBarProps) {
    return (
        <MenuWrapper>
            {data.map((d: NavBarEntry) => (
                <Container
                    key={d.type}
                    selected={d.type === selected}
                    to={`/settlement/statement?type=${d.type}`}
                >
                    {d.label}
                </Container>
            ))}
        </MenuWrapper>
    );
}

const MenuWrapper = styled.div`
    display: table;
    margin-top: 24px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.gray50};
`;

const Container = styled(Link)<{ selected: boolean }>`
    display: table-cell;
    width: 300px;
    height: 40px;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    ${({ theme }) => css`
        border: 1px solid ${theme.colors.gray300};
        border-bottom: 1px solid ${theme.colors.black};
        border-radius: 4px 4px 0 0;
        color: ${theme.colors.gray600};
    `}

    ${({ selected, theme }) => selected && css`
        background-color: ${theme.colors.white};
        border: 1px solid black;
        border-bottom: hidden;
        color: ${theme.colors.black};
        cursor: unset
    `} 
`;

interface NavBarProps {
    data: NavBarEntry[];
    selected: string | null;
}

interface NavBarEntry {
    label: string;
    type: string;
}