import { useAppSelector, RootState } from "@/modules/store";
import styled from "styled-components";
import { ReactComponent as Gling } from "@/assets/icons/gling_logo.svg";
import { colors } from "@/assets/styles/theme";

export default function CoverContainer() {
    const { contentBaseInfo } = useAppSelector((state: RootState) => state.authorPortfolio);
    const random = Math.random();
    
    return (
        <Container>
            {contentBaseInfo.cover_path ?
                <img src={`${contentBaseInfo.cover_path}?a=${random}`} /> :
                <NoImg>
                    <Gling width={132} height={60} fill={colors.gray300} />
                </NoImg>
            }
        </Container>
    )
}

const Container = styled.div`
    width: 380px;
    height: 554px;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`

const NoImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${colors.gray50};
`
