import styled from "styled-components";

// components
import Button from "@/components/atoms/button";

// reducer
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";
import { getPlatformData, savePlatformData } from "@/pages/settlement/basedata-manage/platform/platformSlice";

// hooks
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

function ButtonContainer({ customStyle }: {customStyle?: StyleProps}) {
    const { data } = useAppSelector((state:RootState) => state.basePlatformdata);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    async function savePlatform() {
        try {
            let usable = false;
            for (let i of data) {
                if (i.usable) usable = true;
                if (i.usable && !Boolean(i.paymonth)) return await alert("warning", "주의", "인세지급월이 입력 안 된 플랫폼이 있습니다.");
                if (i.valueError) return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
            }
            if (!usable) return await alert("info", "주의", "하나 이상의 판매플랫폼을 설정해주세요.");
    
            let answer = await alert("confirm", "저장 확인", "저장하시겠습니까?");
            if (answer === "Y") {
                loadingOn();
                await dispatch(savePlatformData(data)).unwrap();
                await dispatch(getPlatformData()).unwrap();
                await alert("success", "저장 완료", "저장이 완료되었습니다.");
            }
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }
        
    return (
        <Container
            height={customStyle?.height}
            justifyContent={customStyle?.justifyContent}
            alignItems={customStyle?.alignItems}
        >
            <Button label="저장" onClickHandler={savePlatform} />
        </Container>
    )
}

export default ButtonContainer;

interface StyleProps {
    justifyContent?: string;
    alignItems?: string;
    height?: string;
}

const Container = styled.div<StyleProps>`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    align-items: ${({ alignItems }) => alignItems ? alignItems : "center"};
    height: ${({ height }) => height ? height : "60px"};
`;