import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getThisMonth } from "@/modules/utils/date";
import type { MonthlyState } from "./types";

const name = "inquiry/author/monthly";
const initialState: MonthlyState = {
    items: [],
    total_amt: 0,
    total_cnt: 0,

    initialDataLoaded: false,
    fetchLoading: false,

    changedParamsId: 0,
    totalDate: getThisMonth() || "",
    selectedPlatform: [],
    selectedPublisher: [],
    selectedSeries: [],
    selectedAuthor: [],
    bookName: "",

    atcmpList: [],
};

// 월별 판매 현황
export const getAuthorMonthlyData = createAsyncThunk(`${name}/getAuthorMonthlyData`, async (_, { rejectWithValue, getState, signal }) => {
        try {
            const { inquiryAuthorMonthly }: any = getState();
            const apiParams = {
                totalDate: inquiryAuthorMonthly.totalDate,
                selectedPlatform: inquiryAuthorMonthly.selectedPlatform,
                selectedPublisher: inquiryAuthorMonthly.selectedPublisher,
                selectedSeries: inquiryAuthorMonthly.selectedSeries,
                selectedAuthor: inquiryAuthorMonthly.selectedAuthor,
                bookName: inquiryAuthorMonthly.bookName,
            }
            return (await api.post(`/api/settlement/newSalesInquiry/monthly/author/select`, apiParams, { signal })).data;

        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

// 작품명 검색
export const getAtcmpByBookName = createAsyncThunk(`${name}/getAtcmpByBookName`,
    async ({ keyword }: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/bookName/select`, { keyword, from: 'monthly'})).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

export const monthlySlice = createSlice({
    name,
    initialState,
    reducers: {
        setFilterOption(state, action: PayloadAction<Partial<MonthlyState>>) {
            const {
                bookName,
                totalDate,
                selectedPlatform,
                selectedPublisher,
                selectedSeries,
                selectedAuthor,
            } = action.payload;

            state.bookName = bookName ?? state.bookName;
            state.totalDate = totalDate ?? state.totalDate;
            state.selectedPlatform = selectedPlatform ?? state.selectedPlatform;
            state.selectedPublisher = selectedPublisher ?? state.selectedPublisher;
            state.selectedAuthor = selectedAuthor ?? state.selectedAuthor;
            state.selectedSeries = selectedSeries ?? state.selectedSeries;
        },
        setParamsId(state){
            state.changedParamsId += 1;
        },
        setNoData(state) {
            state.items = [];
            state.total_cnt = 0;
            state.total_amt = 0;
        },
        resetStore() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        // 월별 판매 현황 조회
        builder
            .addCase(getAuthorMonthlyData.pending, (state) => {
                state.fetchLoading = true;
            })
            .addCase(getAuthorMonthlyData.fulfilled, (state, action) => {
                state.fetchLoading = false;
                state.initialDataLoaded = true;

                const { result, total_cnt, total_amt } = action.payload.data;
                const thisMonth = getThisMonth();

                for (let item of result) {
                    item.st_month = thisMonth;
                }

                state.items = result;
                state.total_cnt = total_cnt;
                state.total_amt = Number(total_amt.toFixed(2));
            });

        // 자동완성 - 작품명 검색
        builder.addCase(getAtcmpByBookName.fulfilled, (state, { payload }) => {
            state.atcmpList = payload.data;
        });
    },
});

export const { setFilterOption, setParamsId, setNoData, resetStore } = monthlySlice.actions;
export default monthlySlice.reducer;
