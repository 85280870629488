import { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { resetDataTable } from "@/pages/common/dataTableSlice";
import {
    setItems,
    setPlatformId,
    resetItems,
    saveEtcSales,
    resetStore,
} from "@/pages/settlement/sales-entry/etc/etcSlice";
import Button from "@/components/atoms/button";
import SelectBox from "@/components/atoms/selectBox/index";
import ModalPortal from "../../common/modal/Portal";
import BatchEtcModal, { BatchEtcResult } from "../../common/modal/BatchEtcModal";
import ExcelUploadModal from "@/components/molecules/common/modal/ExcelUploadSalesModal";
import ResetFilter from "../../sales-inquiry/common/ResetFilter";
import useLoading from "@/modules/hooks/useLoading";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import usePlatformSetting from "../common/hooks/usePlatformSetting";
import useAlert from "@/modules/hooks/useAlert";
import useEtcExcelUpload from "./hooks/useEtcExcelUpload";

export default function EtcTableTop() {
    const { items, platformId } = useSelectorTyped((state: RootState) => state.entryEtc);
    const { selectedItems } = useSelectorTyped((state: RootState) => state.dataTable);
    const dispatch = useAppDispatch();
    const [showExcelModal, setShowExcelModal] = useState(false);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const { loadingOn, loadingOff } = useLoading();
    const { setItemsPerPage, itemsPerPage } = usePagination({ from: "etc" });
    const { platformList, platformForm, initialPlatformId } = usePlatformSetting({ platformId });
    const { applyExcelUpload } = useEtcExcelUpload();
    const { alert } = useAlert();

    // 모달 컨트롤
    const closeExcelModal = () => setShowExcelModal(false);

    const applyBatchEtc = (data: BatchEtcResult) => {
        let newItems = _.cloneDeep(items);
        for (let i of newItems) {
            if (data.platformId !== null) i.platform_id = data.platformId;
            if (data.inDate !== null) {
                i.in_date = data.inDate;
                i.inDateError = "";
            }
            if (data.outDate !== null) {
                i.date = data.outDate;
                i.dateError = "";
            }
        }
        dispatch(setItems(newItems));
    };

    // 이벤트
    const clickDeleteButton = async () => {
        if (selectedItems.length === 0) {
            return await alert("warning", "주의", "대상을 선택해주세요.");
        }
        const res = await alert("confirm", "삭제 확인", "삭제 하시겠습니까?");
        if (res === "Y") {
            loadingOn();

            const itemKeys = selectedItems.map((s) => s.id);
            dispatch(setItems(items.filter((item) => !itemKeys.includes(item.id))));

            // 전체 데이터 삭제시
            if (items.length === selectedItems.length) {
                dispatch(resetItems());
            }
            dispatch(resetDataTable());
            loadingOff();
            await alert("info", "삭제 완료", "삭제가 완료되었습니다.");
        }
    };

    const clickBatchApply = () => setShowBatchModal((prev) => !prev);

    const clickResetButton = async () => {
        const answer = await alert("confirm", "초기화 확인", "초기화 하시겠습니까?");
        if (answer === "Y") {
            dispatch(resetStore());
            dispatch(setPlatformId(initialPlatformId));
        }
    };

    const changeSelectBox = (v: any) => setItemsPerPage(Number(v.value));

    const clickExcelUpload = async () => {
        if (items.length && (items[0].authorInfo.author_name || items[0].pre_amount)) {
            let answer = await alert("confirm", "주의", "엑셀업로드시 기존 데이터는 모두 삭제됩니다.");
            if (answer === "Y") {
                dispatch(resetItems());
                setShowExcelModal(true);
            }
        } else {
            setShowExcelModal(true);
        }
    };

    const clickSaveBtn = () => {
        validateData();
    };

    const validateData = async () => {
        let hasError = false;
        let tmpItems = _.cloneDeep(items);
        for (let item of tmpItems) {
            if (!item.date) {
                item.dateError = "날짜를 선택해주세요.";
            }
            if (item.authorInfo.author_name === "") {
                item.authorError = "저자명을 입력해주세요.";
            }
            if (item.needCoRatio) {
                item.authorError = "공저간 비율을 설정해주세요.";
            }
            if (item.pre_amount === "") {
                item.preAmountError = "금액을 입력해주세요.";
            }
            if (item.st_ratio === "") {
                item.stRatioError = "정산비율을 입력해주세요.";
            }
            if (item.tax_type === "E" && item.tax_desc === "") {
                item.taxDescError = "적용 세목을 입력해주세요.";
            }
            if (item.tax_type === "E" && item.tax_amount === "") {
                item.taxAmountError = "적용 세액을 입력해주세요.";
            }
            if (item.isCoAuthor && item.tax_type === "E") {
                for (let i of item.author_tax_info) {
                    if (i.tax_amount === "") {
                        item.taxAmountError = "공저별 설정을 완료해주세요.";
                    }
                }
            }
            if (item.reason === "") {
                item.reasonError = "지급사유를 입력해주세요.";
            }
            if (
                item.dateError ||
                item.authorError ||
                item.preAmountError ||
                item.stRatioError ||
                item.taxDescError ||
                item.taxAmountError ||
                item.reasonError
            ) {
                hasError = true;
            }
        }

        dispatch(setItems(tmpItems));
        if (hasError) return await alert("warning", "주의", "조건을 확인해주세요.");
        saveData();
    };

    const saveData = async () => {
        let answer = await alert("confirm", "저장 확인", "저장 하시겠습니까?");
        if (answer === "Y") {
            try {
                loadingOn();
                const apiParams = { items };
                await dispatch(saveEtcSales(apiParams));
                await alert("info", "저장 완료", "저장이 완료되었습니다.");
                dispatch(resetItems());
                dispatch(resetDataTable());
            } catch (err: any) {
                await alert("error", "오류 발생", err.message);
            } finally {
                loadingOff();
            }
        }
    };

    return (
        <>
            <StyledTableTop>
                <TableOptionsContainer>
                    <Contents>
                        <Button
                            type="red-outline"
                            label="선택 삭제"
                            onClickHandler={clickDeleteButton}
                            customStyle={{ margin: "0 8px 0 0" }}
                        />
                        <Button
                            type="main-outline"
                            label="일괄 적용"
                            onClickHandler={clickBatchApply}
                            customStyle={{ margin: "0 8px 0 0" }}
                        />
                    </Contents>
                    <Contents>
                        <TableFilterWrapper>
                            <ResetFilter text="초기화" onClick={clickResetButton} />
                        </TableFilterWrapper>
                        <SelectBox
                            labelKey="name"
                            valueKey="value"
                            selected={itemsPerPage}
                            options={options}
                            onClickValueFunc={changeSelectBox}
                            customStyle={selectBoxStyle}
                        />
                        <Button
                            type="main-outline"
                            label="엑셀 업로드"
                            onClickHandler={clickExcelUpload}
                            customStyle={uploadBtnStyle}
                        />
                        <Button type="main" label="저장" onClickHandler={clickSaveBtn} customStyle={btnStyle} />
                    </Contents>
                </TableOptionsContainer>
            </StyledTableTop>
            {/* 일괄 업로드 모달 */}
            <ModalPortal>
                <BatchEtcModal
                    from="daily"
                    show={showBatchModal}
                    close={clickBatchApply}
                    platformList={platformList}
                    onApplyBatchEtc={applyBatchEtc}
                />
            </ModalPortal>
            {/* 엑셀 업로드 모달 */}
            <ModalPortal>
                <ExcelUploadModal
                    from="etc"
                    show={showExcelModal}
                    close={closeExcelModal}
                    platformForm={platformForm}
                    onApplyExcelUpload={applyExcelUpload}
                />
            </ModalPortal>
        </>
    );
}
const options = [
    { name: "10개씩 보기", value: 10 },
    { name: "30개씩 보기", value: 30 },
    { name: "50개씩 보기", value: 50 },
    { name: "100개씩 보기", value: 100 },
];

const uploadBtnStyle = {
    margin: "0 0 0 8px",
    width: "113px",
};
const btnStyle = {
    margin: "0 0 0 8px",
};
const selectBoxStyle = {
    fontSize: "12px",
    fontWeight: "400",
};

const StyledTableTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 24px 0;
`;
const TableOptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
`;
const Contents = styled.div`
    display: flex;
    align-items: center;
`;

const TableFilterWrapper = styled.div`
    .init-filter {
        position: relative;
        width: 80px;
    }
`;
