import styled from "styled-components";
import { useEffect } from "react";
import useLoading from "@/modules/hooks/useLoading";

export default function PortfolioSkeleton() {
    const { loadingOn, loadingOff } = useLoading();
    useEffect(() => {
        loadingOn("portfolio");
        return() => {
            loadingOff();
        }
    }, [])

    return (
        <StyledContainer>
            {[0,1,2,3,4,5,6,7,8,9,10,11].map((c, i) => (
                <CardWrapper key={i}>
                    <SkeletonContainer width="272px" height="200px">
                        <SkeletonAnimation width="272px" height="200px" />
                    </SkeletonContainer>
                    <InfoWrapper>
                        <TitleWrapper>
                            <SkeletonContainer width="108px" height="20px">
                                <SkeletonAnimation width="108px" height="20px" />
                            </SkeletonContainer>
                        </TitleWrapper>
                        <DescWrapper>
                            <div>
                                <SkeletonContainer width="88px" height="20px">
                                    <SkeletonAnimation width="88px" height="20px" />
                                </SkeletonContainer>
                            </div>
                            <PublishedAtWrapper>
                                <SkeletonContainer width="108px" height="20px">
                                    <SkeletonAnimation width="108px" height="20px" />
                                </SkeletonContainer>
                            </PublishedAtWrapper>
                        </DescWrapper>
                    </InfoWrapper>
                </CardWrapper>
            ))}        
        </StyledContainer>
    )
}

const SkeletonContainer = styled.div<{ width?: string, height?: string }>`
    width: ${({ width }) => width ? width : "320px"};
    height: ${({ height }) => height ? height : "14px"};
    background-image: -webkit-linear-gradient( lightgray ${({ height }) => height ? height : "14px"}, transparent 0 );
`

const SkeletonAnimation = styled.div<{ width?: string, height?: string }>`
    animation: placeHolderShimmer 1s linear forwards infinite;
    background: #f1f3f5;
    background-image: linear-gradient(to right, #e9ecef 0%, #dee2e6 20%, #e9ecef 40%, #e9ecef 100%);
    background-repeat: no-repeat;
    background-size: 800px 200px;
    height: ${({ height }) => height ? height : "14px"};
    position: relative;
    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0
        }
        100% {
            background-position: 468px 0
        }
    }
`
const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
`

const CardWrapper = styled.div`
    width: 272px;
    height: 272px;
    margin-bottom: 24px;
`

const ImgWrapper = styled.div`
    width: 100%;
    height: 200px;
    border: 1px solid blue;
`

const InfoWrapper = styled.div`
    width: 100%;
    height: 72px;
    padding: 16px 8px;
`

const TitleWrapper = styled.div`
    font-size: 16px;
    font-weight: 700;
`

const DescWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
`

const PublishedAtWrapper = styled.div`
    color: ${({ theme }) => theme.colors.gray600};
`