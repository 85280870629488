import styled from "styled-components";
import Text from "@/components/atoms/text";
import TextInput from "@/components/atoms/input/TextInput";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

export default function SubtitleInputContainer() {
    const { subtitleList } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const inputOnChange = (e: any, idx: number) => {
        dispatch({ type: "ebookMaker/setSubtitleInput", payload: {
            idx,
            value: e.target.value
        }})
    }

    return (
        <StyledContainer>
            <FieldWrapper>
                <Text text="소제목 입력" customStyle={{ fontSize: "14px" }} />
            </FieldWrapper>
            <ContentWrapper>
                {subtitleList.map((s, idx) => (
                    <RowWrapper key={idx}>
                        <Text text={`${s.order}번 소제목`} customStyle={{ fontSize: "12px", width: "70px" }} />
                        <TextInput 
                            value={s.subtitle}
                            onChangeHandler={(e) => inputOnChange(e, idx)}
                            placeholder="소제목을 입력해주세요"
                            customStyle={{ width: "300px", height: "32px" }}
                        />
                    </RowWrapper>
                ))}
            </ContentWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    height: 320px;
`

const FieldWrapper = styled.div`
    display: flex;
    width: 280px;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    margin-right: 20px;
`

const ContentWrapper = styled.div`
    width: 540px;
    max-height: 100%;
    background-color: ${({ theme }) => theme.colors.gray50};
    padding: 24px;
    overflow-y: auto;
`

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 16px;
`