import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import Text from "@/components/atoms/text/index";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { addComma } from "@/modules/utils/filter";

export default function EtcTable() {
    const { items, fetchLoading } = useSelectorTyped((state: RootState) => state.inquiryAuthorEtc);
    const { pageCount, totalVisible } = usePagination({ from: "etc", tableData: items });

    const headers = [
        { text: "플랫폼", value: "platform_name", width: "100px" },
        { text: "지급 일자", value: "date", width: "100px" },
        { text: "지급 출판사", value: "publisher_name", width: "100px" },
        { text: "저자", value: "author_name", width: "100px" },
        { text: "금액(원)", value: "pre_amount", width: "100px" },
        { text: "정산 비율(%)", value: "st_ratio", width: "120px", textAlign: "center" },
        { text: "적용 세목", value: "tax_type", width: "200px", textAlign: "center" },
        { text: "적용 세액(원)", value: "tax_amount", width: "100px" },
        { text: "지급 금액(원)", value: "amount", width: "100px" },
        { text: "지급 사유", value: "reason", width: "220px" },
    ];

    const columns = {
        platform_name: ({ item }: TableItemProps) => {
            return (
                <Text
                    text={item["platform_name"] || "해당없음"}
                    customStyle={{
                        fontSize: "12px",
                        fontWeight: "400",
                    }}
                />
            );
        },
        st_ratio: ({ item }: TableItemProps) => {
            return <Text text={item.st_ratio} customStyle={textCenterStyle} />;
        },
        tax_type: ({ item }: TableItemProps) => {
            const taxtype =
                item["tax_type"] === "N"
                    ? "해당없음"
                    : item["tax_type"] === "E"
                    ? "기타"
                    : item["author_tax_type"] === "D"
                    ? "개인(국내 거주자)"
                    : item["author_tax_type"] === "A"
                    ? "개인(국내 비거주자)"
                    : "기타사업자";

            return <Text text={taxtype} customStyle={textCenterStyle} />;
        },
        pre_amount: ({ item }: TableItemProps) => {
            return <Text text={addComma(item["pre_amount"])} customStyle={textStyle} />;
        },
        tax_amount: ({ item }: TableItemProps) => {
            return <Text text={addComma(item["tax_amount"])} customStyle={textStyle} />;
        },
        amount: ({ item }: TableItemProps) => {
            return <Text text={addComma(item["amount"])} customStyle={textStyle} />;
        },
    };

    return (
        <>
            <TableContainer>
                <DataTable
                    from="etc"
                    itemKey="etc_id"
                    items={items}
                    loading={fetchLoading}
                    headers={headers}
                    columns={columns}
                    options={tableOptions}
                />
                <ScrollToTopBtn />
            </TableContainer>
            <Pagination from="etc" pageCount={pageCount} totalVisible={totalVisible} />
        </>
    );
}

const tableOptions = {
    showCheckbox: false,
};
const textStyle = {
    textAlign: "right",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px",
};
const textCenterStyle = {
    ...textStyle,
    textAlign: "center",
};
