import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";
import moment from "moment";
import { DashboardState, GetCalendarEventParam } from "./types";

// Define the initial state using that type
const initialState: DashboardState = {
    // 출판사 리스트
    publisherList: [],
    selectedPublisherId: 0,

    // 매출 요약
    salesSummary: {
        yesterdaySales: {
            amount: "0원",
            ratio: 0
        },
        weekSales: "0원",
        currMonthSales: "0원",
        lastMonthSales: {
            amount: "0원",
            amountAfterTax: "0원"
        }
    },
    salesSummaryLoading: false,
    
    // 판매금액 분석
    analysisType: "daily",
    dailySalesAnalysis: [],
    monthlySalesAnalysis: [],
    salesAnalysIsLoading: false,

    // 캘린더 일정
    calendarLoading: false,
    calendarEvent: [],
    selectedDateEvent: [],

    // 일일 작품 분석
    dailySeriesAnalysisLoading: false,
    dailySeriesAnalysisData: [],

    // 일일 플랫폼 분석
    dailyPlatformAnalysisLoading: false,
    dailyPlatformAnalysisData: [],

    // 선인세 소진 현황
    mgDeductionHistoryLoading: false,
    mgDeductionHistoryData: [],
};

// 연동된 출판사 리스트
export const getRelatedPubList = createAsyncThunk("authorDashboard/getRelatedPubList", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/author/newDashboard/related/publisher/list`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 전일, 7일누적, 당월 누적, 전월 정산금 총계
export const getSalesSummary = createAsyncThunk("authorDashboard/getSalesSummary", async (publisher_id: number, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/author/newDashboard/sales/summary?publisherId=${publisher_id}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 판매 금액 분석 (일별, 월별)
export const getSalesAnalysis = createAsyncThunk("authorDashboard/getSalesAnalysis", async (publisher_id: number, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/author/newDashboard/sales/analysis?publisherId=${publisher_id}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 캘린더 일정
export const getCalendarEvent = createAsyncThunk("authorDashboard/getCalendarEvent", async (param: GetCalendarEventParam, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/author/newDashboard/calendar/event?yearmon=${param.yearmon}&publisherId=${param.publisher_id}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 일일 작품 분석
export const getDailySeriesAnalysis = createAsyncThunk("authorDashboard/getDailySeriesAnalysis", async (publisher_id: number, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/author/newDashboard/daily/series/analysis?publisherId=${publisher_id}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 일일 플랫폼 분석
export const getDailyPlatformAnalysis = createAsyncThunk("authorDashboard/getDailyPlatformAnalysis", async (publisher_id: number, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/author/newDashboard/daily/platform/analysis?publisherId=${publisher_id}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 선인세 소진현황
export const getMgDeductionHistory = createAsyncThunk("authorDashboard/getMgDeductionHistory", async (publisher_id: number, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/author/newDashboard/mg/deduction/history?publisherId=${publisher_id}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const authorDashboardSlice = createSlice({
    name: "authorDashboard",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAnalysisType: (state, action) => {
            state.analysisType = action.payload;
        },
        setSelectedDateEvent: (state, action) => {
            state.selectedDateEvent = [];
            for (let c of state.calendarEvent) {
                if (c.date === action.payload) {
                    state.selectedDateEvent.push(c);
                }
            }
        },
        setSelectedPublisherId: (state, action) => {
            state.selectedPublisherId = action.payload;
        }
    },
    extraReducers: {
        // 연동된 출판사 리스트
        [getRelatedPubList.fulfilled.type]: (state, { payload }) => {
            const tmp = payload.data;
            tmp.unshift({ publisher_id: 0, publisher_name: "전체", imgPath: "" });
            state.publisherList = tmp;
        },
        [getRelatedPubList.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
        },

        // 전일, 7일누적, 당월 누적, 전월 정산금 총계
        [getSalesSummary.pending.type]: (state, action) => {
            state.salesSummaryLoading = true;
        },
        [getSalesSummary.fulfilled.type]: (state, action) => {
            state.salesSummary = action.payload.data;
            state.salesSummaryLoading = false;
        },
        [getSalesSummary.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.salesSummaryLoading = false;
        },

        // 판매 금액 분석 (일별, 월별)
        [getSalesAnalysis.pending.type]: (state, action) => {
            state.salesAnalysIsLoading = true;
        },
        [getSalesAnalysis.fulfilled.type]: (state, action) => {
            state.dailySalesAnalysis = action.payload.data.dailySales;
            state.monthlySalesAnalysis = action.payload.data.monthlySales;
            state.salesAnalysIsLoading = false;
        },
        [getSalesAnalysis.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.salesAnalysIsLoading = false;
        },

        // 캘린더 이벤트
        [getCalendarEvent.pending.type]: (state, action) => {
            state.calendarLoading = true;
        },
        [getCalendarEvent.fulfilled.type]: (state, action) => {
            state.calendarEvent = action.payload.data;
            const today = moment().format("YYYYMMDD");
            state.selectedDateEvent = [];
            for (let d of action.payload.data) {
                if (d.date === today) {
                    state.selectedDateEvent.push(d);
                }
            }
            state.calendarLoading = false;
        },
        [getCalendarEvent.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.calendarLoading = false;
        },

        // 일일 작품 분석
        [getDailySeriesAnalysis.pending.type]: (state, action) => {
            state.dailySeriesAnalysisLoading = true;
        },
        [getDailySeriesAnalysis.fulfilled.type]: (state, action) => {
            state.dailySeriesAnalysisData = action.payload.data;
            state.dailySeriesAnalysisLoading = false;
        },
        [getDailySeriesAnalysis.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.dailySeriesAnalysisLoading = false;
        },

        // 일일 플랫폼 분석
        [getDailyPlatformAnalysis.pending.type]: (state, action) => {
            state.dailyPlatformAnalysisLoading = true;
        },
        [getDailyPlatformAnalysis.fulfilled.type]: (state, action) => {
            state.dailyPlatformAnalysisData = []
            state.dailyPlatformAnalysisData = action.payload.data;
            state.dailyPlatformAnalysisLoading = false;
        },
        [getDailyPlatformAnalysis.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.dailyPlatformAnalysisLoading = false;
        },

        // 플랫폼별 정산금
        [getMgDeductionHistory.pending.type]: (state, action) => {
            state.mgDeductionHistoryLoading = true;
        },
        [getMgDeductionHistory.fulfilled.type]: (state, action) => {
            state.mgDeductionHistoryData = action.payload.data;
            state.mgDeductionHistoryLoading = false;
        },
        [getMgDeductionHistory.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.mgDeductionHistoryLoading = false;
        },
    },
});

export const { setAnalysisType } = authorDashboardSlice.actions;

export default authorDashboardSlice.reducer;
