
import { ReactComponent as Enter } from "@/assets/icons/enter.svg";
import { ReactComponent as Dashboard } from "@/assets/icons/dashboard-landing.svg"; 
import { ReactComponent as Check } from "@/assets/icons/check.svg";
import { ReactComponent as Statement } from "@/assets/icons/royaltyStatement.svg";
import { ReactComponent as Portfolio } from "@/assets/icons/portfolio.svg"; 
import { ReactComponent as BaseData } from "@/assets/icons/base-data.svg";
import { ReactComponent as Ebook } from "@/assets/icons/Ebook-landing.svg";
import { colors } from "@/assets/styles/theme";

const titleStyle = {
    fontSize: "16px",
    fontWeight: "700",
    color: colors.black
}

const descriptionStyle = {
    fontSize: "14px",
    fontWeight: "400",
    color: colors.black
}

export const authorQuickDatas = [
    {
        id: 1,
        svg: Dashboard,
        title: '대시보드',
        description: '판매 현황 분석 데이터 제공!',
        titleStyle,
        descriptionStyle,
        buttonPath: 'dashboard'
    },
    {
        id: 2,
        svg: Check,
        title: '판매 조회',
        description: '판매 된 내역을 조회하세요!',
        titleStyle,
        descriptionStyle,
        buttonPath: 'settlement/sales-inquiry?type=Daily'
    },
    {
        id: 3,
        svg: Statement,
        title: '정산서',
        description: '이번 달 정산서를 확인하세요!',
        titleStyle,
        descriptionStyle,
        buttonPath: 'settlement/statement?type=issue'
    },
    {
        id: 4,
        svg: Portfolio,
        title: '내 포트폴리오',
        description: '내 작품 포트폴리오도 글링에서!',
        titleStyle,
        descriptionStyle,
        buttonPath: 'portfolio/list'
    }
]

export const publisherQuickDatas = [
    {
        id: 1,
        svg: Enter,
        title: '판매 입력',
        description: '판매 금액을 입력하세요!',
        titleStyle,
        descriptionStyle,
        buttonPath: 'settlement/sales-entry?type=Daily'
    },
    {
        id: 2,
        svg: Check,
        title: '판매 조회',
        description: '판매 된 내역을 조회하세요!',
        titleStyle,
        descriptionStyle,
        buttonPath: 'settlement/sales-inquiry?type=Daily'
    },
    {
        id: 3,
        svg: BaseData,
        title: '기초데이터 관리',
        description: '이번 달 정산서를 확인하세요!',
        titleStyle,
        descriptionStyle,
        buttonPath: 'settlement/basedata-manage?type=Author'
    },
    {
        id: 4,
        svg: Ebook,
        title: 'e-book',
        description: '쉽고 빠르게 e-book을 제작하세요!',
        titleStyle,
        descriptionStyle,
        buttonPath: 'ebook-maker'
    },
]