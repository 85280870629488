import { useEffect, useState } from "react";
import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setDate, setPlatformId } from "@/pages/settlement/sales-entry/daily/dailySlice";
import Text from "@/components/atoms/text";
import SelectBox from "@/components/atoms/selectBox/index";
import CustomDatePicker from "@/components/molecules/common/date-picker/CustomDatePicker";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import useAlert from "@/modules/hooks/useAlert";
import useUtils from "./hooks/useReset";

export default function TableFilter() {
    const { date, platformId, afterExcelUpload } = useSelectorTyped((state: RootState) => state.entryDaily);
    const { isAuto } = useSelectorTyped((state: RootState) => state.uploader);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { entrySalesPlatformList, platformList, initialPlatformId, initialize, reset } = useUtils(platformId, date);

    useEffect(() => {
        if (isAuto) return; //자동 업로더인경우

        initialize();
    }, [initialPlatformId, isAuto]);

    const changePlatform = (value: any) => dispatch(setPlatformId(value.platform_id));

    const changeDate = (date: string) => dispatch(setDate(date));

    const clickResetButton = async () => {
        const answer = await alert("confirm", "초기화 확인", "초기화 하시겠습니까?");
        if (answer === "Y") reset(true);
    };

    return (
        <FilterContainer>
            <LeftFilter>
                <Section>
                    <Text text="판매 플랫폼" customStyle={textStyle} />
                    <SelectBox
                        valueKey="platform_id"
                        labelKey="platform_name"
                        options={entrySalesPlatformList}
                        selected={platformId || initialPlatformId}
                        disabled={afterExcelUpload}
                        onClickValueFunc={changePlatform}
                    />
                </Section>
                <Section>
                    <Text text="판매 일" customStyle={textStyle} />
                    <CustomDatePicker settingDate={date} disabled={afterExcelUpload} onChange={changeDate} />
                </Section>
            </LeftFilter>
            <ResetFilter text="초기화" onClick={clickResetButton} />
        </FilterContainer>
    );
}

const textStyle = {
    fontSize: "14px",
    fontWeight: "500",
    margin: "0 8px 0 0",
};

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin: 24px 0;

    .init-filter {
        justify-content: space-around;
    }
`;
const LeftFilter = styled.div`
    display: flex;
`;
const Section = styled.div`
    display: flex;
    align-items: center;
    & + & {
        margin-left: 16px;
    }
`;
