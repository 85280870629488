import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@/api";
// import api from "@/api";
import { DailyDeleteState, DeleteByConditionParams, DeleteParams } from "./types";

const name = "inquiry/publisher/daily/delete";

const initialState: DailyDeleteState = {
    deleteLoading: false,
    deleteError: "",
    // API PARAMS
    startDate: "",
    endDate: "",
    platformId: 0,
    seriesId: 0,
    authorId: 0,

    // CONDITION DATA
    selectedCondition: {
        date: false,
        platform: false,
        seriesName: false,
        authorName: false,
    },
    validateError: {
        startDateError: "",
        endDateError: "",
    },
};

// 데이터 삭제
export const deleteData = createAsyncThunk(
    `${name}/deleteData`,
    async (apiParams: DeleteParams, { rejectWithValue }) => {
        try {
            const res = await api.post(`/api/settlement/newSalesInquiry/daily/delete`, apiParams);
            return res.data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 데이터 조건 삭제
export const batchDelete = createAsyncThunk(
    `${name}/batchDelete`,
    async (apiParams: DeleteByConditionParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSalesInquiry/daily/batch/remove`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const dailyDelete = createSlice({
    name,
    initialState,
    reducers: {
        setBatchDelete: (state, action) => {
            const {
                startDate,
                endDate,
                platformId,
                seriesId,
                authorId,
                selectedCondition,
                startDateError,
                endDateError,
            } = action.payload;
            state.startDate = startDate ?? state.startDate;
            state.endDate = endDate ?? state.endDate;
            state.platformId = platformId ?? state.platformId;
            state.seriesId = seriesId ?? state.seriesId;
            state.authorId = authorId ?? state.authorId;
            state.selectedCondition = selectedCondition ?? state.selectedCondition;
            state.validateError.startDateError = startDateError ?? state.validateError.startDateError;
            state.validateError.endDateError = endDateError ?? state.validateError.startDateError;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteData.pending, (state) => {
                state.deleteLoading = true;
            })
            .addCase(deleteData.fulfilled, (state, action) => {
                state.deleteLoading = false;
            });
        builder.addCase(batchDelete.pending, (state) => {}).addCase(batchDelete.fulfilled, (state) => {});
    },
});

export const { setBatchDelete } = dailyDelete.actions;
export default dailyDelete.reducer;
