import { useEffect, useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import SearchSeriesBody from "@/components/molecules/common/modal/bodyModal/SearchSeriesModalBody";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import EnrollSeriesRatioModal from "@/components/molecules/common/modal/EnrollSeriesRatioModal";
import useAlert from "@/modules/hooks/useAlert";
import type{ CommonType } from "@/types/dataType";
import type { BaseModalProps } from "@/pages/common/types";
import type { SearchSeriesResult, SelectedSeries } from "@/types/searchSeries";

export default function SearchSeriesModal({
    show,
    close,
    from,
    platformId,
    matchingIdx = 0,
    platformName = "",
    authorId,
    multiSelect,
    withRatio,
    stYearmon,
    applySeries,
    applySeriesRatio,
}: SearchSeriesProps) {
    const [selectedSeries, setSelectedSeries] = useState<SelectedSeries[]>([]);
    const [showModal, setShowModal] = useState(false);
    const { alert } = useAlert();

    useEffect(() => {
        return () => {
            setSelectedSeries([]);
        };
    }, [show]);

    const handleModal = () => setShowModal((prev) => !prev);

    const applyRatio = (ratio: CommonType.Ratio) => {
        // 단일 선택일 때만 정산비율 설정 가능
        setSelectedSeries((data) => data.map((d, idx) => (idx === 0 ? { ...d, ratio, needRatio: true } : d)));
        applySeriesRatio &&
            applySeriesRatio({
                ratio,
                series_id: selectedSeries[0].id,
                platform_id: platformId,
            });

        applySeries({
            selectedSeries: { ...selectedSeries[0], ratio, needRatio: true, platform_id: platformId },
            matchingIdx,
        });
        close();
    };

    const apply = async () => {
        if (!selectedSeries.length) {
            return await alert("warning", "주의", "시리즈를 선택해주세요.");
        }
        // 포트폴리오 중복 등록 검사
        if (from === 'portfolio' && selectedSeries[0].isDuplicate) {
            return alert("warning", "확인", "프토폴리오에 이미 등록된 시리즈입니다.");
        }
        // 정산비율 미설정이면 정산비율 입력 모달 오픈
        if (withRatio && selectedSeries[0].ratio === null && from !== "match") {
            return handleModal();
        }

        applySeries({
            selectedSeries: multiSelect ? selectedSeries : selectedSeries[0],
            matchingIdx,
        });
        close();
    };

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["시리즈 검색"]}
                applyFunc={apply}
                customStyle={[{ width: "600px", height: "488px" }]}
            >
                <SearchSeriesBody
                    from={from}
                    platformId={platformId}
                    authorId={authorId}
                    multiSelect={multiSelect}
                    withRatio={withRatio}
                    stYearmon={stYearmon}
                    selectedSeries={selectedSeries}
                    setSelectedSeries={setSelectedSeries}
                />
            </BaseModal>
            <ModalPortal>
                <EnrollSeriesRatioModal
                    from={from}
                    show={showModal}
                    close={handleModal}
                    platformName={platformName}
                    applySeriesRatio={applyRatio}
                />
            </ModalPortal>
        </>
    );
}

interface SearchSeriesProps extends BaseModalProps {
    platformId: number;
    withRatio: boolean;
    multiSelect: boolean;
    matchingIdx?: number;
    platformName?: string;
    authorId?: number;
    stYearmon?: string;
    applySeries: (data: SearchSeriesResult) => void;
    applySeriesRatio?: (data: any) => void;
}
