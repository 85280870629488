import { useAppDispatch } from "@/modules/store";
import { loadingActions } from "@/pages/common/loadingSlice";

const useLoading = () => {
    const dispatch = useAppDispatch();
    const loadingOn = (type?: "table" | "portfolio" | "total") => {
        if (type === "table" || type === "portfolio" || type === "total") {
            dispatch(loadingActions.setFullLoading(type))
        } else {
            dispatch(loadingActions.setFullLoading(true))
        }
    };
    const loadingOff = () => {
        dispatch(loadingActions.setFullLoading(false))
    };
    const progressOn = () => {dispatch(loadingActions.setProgress(true))};
    const progressOff = () => {dispatch(loadingActions.setProgress(false))};

    return {
        loadingOn,
        loadingOff,
        progressOn,
        progressOff,
    };
};

export default useLoading;
