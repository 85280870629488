import { RootState, useAppSelector } from "@/modules/store";
import { SortOptions, ViewOptions } from "@/pages/portfolio/author/types";
import styled from "styled-components";
import CardViewContainer from "./CardViewContainer";
import ListViewContainer from "./ListViewContainer";
import { useState, useEffect } from "react";
import { Portfolio } from "@/pages/portfolio/author/types";

export default function SeriesListContainer({ page, itemPerPage }: ComponentProps) {
    const { sortOption, viewOption, portfolioList } = useAppSelector((state: RootState) => state.authorPortfolio);
    const [currList, setCurrList] = useState<Portfolio[]>([]);

    useEffect(() => {
        let tmpList = JSON.parse(JSON.stringify(portfolioList));
        if (sortOption === SortOptions.ABC) {
            tmpList = tmpList.sort((a: any, b: any) => {
                if (a.title > b.title) return 1;
                if (a.title < b.title) return -1;
                return 0;
            });
        } else {
            tmpList = tmpList.sort((a: any, b: any) => {
                if (a.publish_at > b.publish_at) return -1;
                if (a.publish_at < b.publish_at) return 1;
                return 0;
            });
        }

        tmpList = tmpList.splice((page-1) * itemPerPage, itemPerPage)
        setCurrList(tmpList)
    }, [page, portfolioList, sortOption])

    return (
        <StyledContainer>
            {viewOption === ViewOptions.CARD 
                ? <CardViewContainer currList={currList} /> 
                : <ListViewContainer currList={currList} />
            }
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    margin-top: 24px;
    width: 100%;
`

interface ComponentProps {
    page: number;
    itemPerPage: number;
}