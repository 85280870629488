import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { EachPlatformHistory } from "@/pages/portfolio/publisher/types";

export default function TimelineRowWrapper({ history, idx, dotClickHandler, dotHoverHandler }: ComponentProps) {
    return (
        <RowWrapper key={history.platform_id}>
            <PlatformWrapper>
                <IconWrapper>
                    <img src={history.platform_icon_path} />
                </IconWrapper>
                {history.platform_name}
            </PlatformWrapper>
            {history.timelineData.map((t, tIdx) => (
                <TimelineDataWrapper key={tIdx}>
                    {t.isEmpty ? 
                        <ContentWrapper></ContentWrapper> :
                        <ContentWrapper>
                            <Dot
                                active={t.active}
                                color={history.platform_color}
                                onClick={() => dotClickHandler(t.data, history.platform_name, tIdx)}
                                onMouseEnter={() => dotHoverHandler(idx, tIdx, true)}
                                onMouseLeave={() => dotHoverHandler(idx, tIdx, false)}
                            >
                                {Boolean(t.active && t.data.length > 1 && t.data.find(d => d.isCirculate)) && 
                                    <DoubleDot /> 
                                }
                            </Dot>
                            {Boolean(t.hover || t.active) && 
                                <NameWrapper>
                                    {t.data.map((d, dIdx) => (
                                        <div key={dIdx}>
                                            {d.name}
                                        </div>
                                    ))}
                                </NameWrapper>
                            }
                        </ContentWrapper>
                    }
                </TimelineDataWrapper>
            ))}
            <EmptyLineWrapper>
                <ContentWrapper />
            </EmptyLineWrapper>
        </RowWrapper>
    )
}

const RowWrapper = styled.li`
    display: flex;
    height: 100px;
`

const PlatformWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 100px;
    font-size: 14px;
    font-weight: 500;
`

const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 8px;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`

const TimelineDataWrapper = styled.div`
    width: 130px;
    height: 50px;
    margin-top: 50px;
    border-top: 2px solid ${colors.gray300};
`

const EmptyLineWrapper = styled.div`
    width: 100%;
    height: 50px;
    margin-top: 50px;
    border-top: 2px solid ${colors.gray300};
`

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 130px;
    padding-right: 80px;
    &:first-child {
        margin-left: 30px;
    }
    &:last-child {
        margin-right: 0;
    }
`

const Dot = styled.div<{ active: boolean; color: string; }>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -8px;
    width: 16px;
    height: 16px;
    border-radius:100%;
    background-color: ${({ active, color }) => active ? color : colors.gray300};
    cursor: pointer;

    &:hover {
        box-shadow: 0px 0px 15px ${colors.blue500};
        background-color: ${colors.blue500};
    }
`

const DoubleDot = styled.div`
    width: 8px;
    height: 8px;
    border-radius:100%;
    background-color: ${colors.white};
    cursor: pointer;
`

const NameWrapper = styled.div<{ active?: boolean; }>`
    position: absolute;
    text-align: center;
    top: 12px;
    width: 100px;
    font-size: 12px;
    font-weight: 500;
    color: ${colors.black};
    line-height: 16px;
`

interface ComponentProps {
    history: EachPlatformHistory;
    idx: number;
    dotClickHandler: (detailData: any, platform_name: string, timelineIdx: number) => void;
    dotHoverHandler: (platformIdx: number, timelineIdx: number, value: boolean) => void;
}