import { useState, MouseEvent } from "react";
import styled from "styled-components";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState } from "@/modules/store";
import { ReactComponent as DownIcon } from "@/assets/icons/down.svg";

export default function SideMenu({ menu, onClick }: SideMenuProps) {
    const { isPublisher } = useSelectorTyped((state: RootState) => state.user);
    const [showMenu, setShowMenu] = useState(false);
    const pubMenuList = [
        { page: "dashboard", name: "대시보드" },
        {
            page: "settlement/salesEntry",
            name: "정산관리",
            subMenuList: [
                { page: "settlement/salesEntry", name: "판매 입력" },
                { page: "settlement/salesInquiry", name: "판매 조회/변경" },
                { page: "settlement/basedata", name: "기초데이터 관리" },
                { page: "settlement/totalSales", name: "입출금종합" },
                { page: "settlement/statement", name: "정산서" },
            ],
        },
        { page: "portfolio", name: "포트폴리오" },
        { page: "ebook", name: "E-BOOK 제작" },
        { page: "mypage", name: "마이페이지" },
        { page: "support", name: "고객센터" },
        // { page: "support", name: "판매 데이터 자동 업로더" },
    ];
    const menuList = [
        { page: "dashboard", name: "대시보드" },
        { page: "salesInquiry", name: "정산 조회" },
        { page: "statement", name: "정산서" },
        { page: "portfolio", name: "내 포트폴리오" },
        { page: "mypage", name: "마이페이지" },
        { page: "support", name: "고객센터" },
    ];

    const clickCollapsedMenu = () => setShowMenu((prev) => !prev);

    const clickSubMenu = (subMenu: Menu) => {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onClick(subMenu);
        };
    };

    if (isPublisher) {
        return (
            <MenuContainer>
                {pubMenuList.map((item, idx) => (
                    <MenuBox key={idx} onClick={() => onClick(item)}>
                        {item.page.includes("settlement") ? (
                            <>
                                <Menu active={menu.page.includes("settlement")} onClick={clickCollapsedMenu}>
                                    <span>정산관리</span>
                                    <StyledDownIcon disabled={!showMenu} />
                                </Menu>
                                <CollapsedMenu show={showMenu}>
                                    {item.subMenuList &&
                                        item.subMenuList.map((subMenu, idx) => (
                                            <SubMenu
                                                key={idx}
                                                active={menu.page === subMenu.page}
                                                onClick={clickSubMenu(subMenu)}
                                            >
                                                {subMenu.name}
                                            </SubMenu>
                                        ))}
                                </CollapsedMenu>
                            </>
                        ) : (
                            <Menu active={menu.page === item.page}>{item.name}</Menu>
                        )}
                    </MenuBox>
                ))}
            </MenuContainer>
        );
    }

    return (
        <MenuContainer>
            {menuList.map((item, idx) => (
                <MenuBox key={idx} onClick={() => onClick(item)}>
                    <Menu active={menu.page === item.page}>{item.name}</Menu>
                </MenuBox>
            ))}
        </MenuContainer>
    );
}

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    cursor: pointer;
`;
const MenuBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 270px;
`;
const Menu = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme, active }) => (active ? theme.colors.blue500 : theme.colors.gray600)};
`;
const StyledDownIcon = styled(DownIcon)<{ disabled: boolean }>`
    fill: ${({ disabled, theme }) => (disabled ? theme.colors.gray600 : theme.colors.blue500)};
    transform: ${({ disabled }) => (disabled ? "none" : "rotate(180deg)")};
    transition: 0.2s ease-in-out;
`;
const CollapsedMenu = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? "block" : "none")};
`;
const SubMenu = styled(Menu)`
    font-size: 14px;
    font-weight: ${({ active }) => (active ? "500" : "400")};
    color: ${({ theme }) => theme.colors.gray600};
`;

type Menu = { page: string; name: string };
interface SideMenuProps {
    menu: Menu;
    onClick: (menu: Menu) => void;
}
