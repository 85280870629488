import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";

const useFilterSetting = () => {
    const { platformList, publisherList, authorList, seriesList } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorCommon,
    );
    const platformIds = platformList && platformList.map((d) => d.id);
    const publisherIds = publisherList && publisherList.map((d) => d.publisher_id);
    const seriesIds = seriesList && seriesList.map((d) => d.id);
    const authorIds = authorList && authorList.map((d) => d.id);

    return {
        platformList,
        publisherList,
        authorList,
        seriesList,
        platformIds,
        publisherIds,
        seriesIds,
        authorIds,
    };
};
export default useFilterSetting;
