import styled from "styled-components";
import Text from "@/components/atoms/text";

import FunctionTable from "./FunctionTable";
import {TableHeaderData, TableData} from "@/components/molecules/landing/pricing/types"
import { colors } from "@/assets/styles/theme";

export default function FunctionContainer() {
    const commonFunction = '공통기능';
    const NavName = ['플랜별 상세 기능 비교','FREE', 'STANDARD', 'ENTERPRISE'];
    const commonTableHeader: TableHeaderData = {type: '최대관리 작품 수(단일 작품명 기준)', free: '~100', standard: '~300', enterprise: '300+'}
    const addTableHeader: TableHeaderData = {type: '팀원(관리계정)', free: '최대1명', standard: '무제한', enterprise: '무제한'}

    const commonTableData : TableData[] = [{type: '판매입력(일별판매)', free: true, standard: true, enterprise: true},
                            {type: '판매입력(월정산)', free: true, standard: true, enterprise: true},
                            {type: '판매입력(선인세)', free: true, standard: true, enterprise: true},
                            {type: '판매입력(기타지급금)', free: true, standard: true, enterprise: true},
                            {type: '판매현황조회/변경(일별판매)', free: true, standard: true, enterprise: true},
                            {type: '판매현황조회/변경(월정산)', free: true, standard: true, enterprise: true},
                            {type: '판매현황조회/변경(선인세)', free: true, standard: true, enterprise: true},
                            {type: '판매현황조회/변경(기타지급금)', free: true, standard: true, enterprise: true},
                            {type: '기초데이터관리', free: true, standard: true, enterprise: true},
                            {type: '고객센터를 통한 고객관리', free: true, standard: true, enterprise: true},
                            {type: '전담 기술 매니저', free: true, standard: true, enterprise: true}]

    const addFunction = '추가기능';
    const addTableData : TableData[] = [{type: '팀원 권한관리', freeText: '별도 요금 문의', standard: true, enterprise: true},
                        {type: '판매 데이터 대시보드', freeText: '별도 요금 문의', standard: true, enterprise: true},
                        {type: '정산서 발급', freeText: '별도 요금 문의', standard: true, enterprise: true},
                        {type: '수입 및 지급 총계 조회', freeText: '별도 요금 문의', standard: true, enterprise: true},
                        {type: 'E-book 제작 틀', freeText: '별도 요금 문의', standard: true, enterprise: true},
                        {type: '유통/프로모션 정보 관리', freeText: '별도 요금 문의', standard: true, enterprise: true},
                        {type: '판매데이터 자동 업로더', freeText: '별도 요금 문의', standard: true, enterprise: true}
                    ]
    const textStyle = {
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "45px",
        color: colors.white,
        margin: '30px 0px'
    }

    const planNameStyle = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "26px",
        color: colors.white
    }

    return (
        <StyledDetailPlanContainer>
            <NavbarContainer>
                <NameBox>
                    <Text text={NavName[0]} customStyle={planNameStyle}></Text>
                </NameBox>
                <TypeBox>
                    <Navitem backgroundColor={colors.gray300} color={colors.black}>{NavName[1]}</Navitem>
                    <Navitem backgroundColor={colors.red500} color={colors.black}>{NavName[2]}</Navitem>
                    <Navitem backgroundColor={colors.information} color={colors.white}>{NavName[3]}</Navitem>
                </TypeBox>
            </NavbarContainer>
            <CommonTable>
                <Text text={commonFunction} customStyle={textStyle}></Text> 
                <FunctionTable tableData={commonTableData} tableHeaderData={commonTableHeader}/>
            </CommonTable>
            <AddTable>
                <Text text={addFunction} customStyle={textStyle}></Text>
                <FunctionTable tableData={addTableData} tableHeaderData={addTableHeader}/>
            </AddTable>
        </StyledDetailPlanContainer>
    )
}

const StyledDetailPlanContainer = styled.div`
    padding: 0px calc((100% - 1200px) / 2);
    width: 100%;
`

const NavbarContainer = styled.div`
    display: flex;
    align-items: center;
    position: sticky;
    top: 95px;
    border-radius: 99px;
    background-color: ${colors.gray800};
    opacity: 0.8;
    backdrop-filter: blur(40px);  
`

const NameBox = styled.div`
    width: 300px;
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 16px
`

const TypeBox = styled.div`
    display: flex;
    align-items: center;
    width: 900px;
    height: 80px;
    justify-content: space-around;
`

const Navitem = styled.div<ColorProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 40px;
    border-radius: 99px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700px;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color}
`

const CommonTable= styled.div`
    display: flex;
    flex-direction: column;
`

const AddTable= styled.div`
    display: flex;
    flex-direction: column;
`

interface ColorProps {
    backgroundColor?: string;
    color?: string
}

