function useSampleDown() {
    const saveAs = (url: string, fileName: string) => {
        let link = document.createElement("a");
        if (typeof link.download === "string") {
            document.body.appendChild(link);
            link.download = fileName;
            link.href = `/file/${url}`;
            link.click();
            document.body.removeChild(link);
        } else {
            const downUrl = `/file/${url}`
            window.location.replace(downUrl);
        }
    }
    return { saveAs };
}

export default useSampleDown;
