import HomeSlider from "@/components/molecules/home/main/HomeSlider";
import QuickMenu from "@/components/molecules/home/quick-menu/QuickMenu";
import Question from "@/components/molecules/home/question/Question";
import Update from "@/components/molecules/home/update/Update";
import styled from 'styled-components'
function Home() {
    return (
            <HomeSection>
                <HomeSlider/>
                <QuickMenu/>
                <Question/>
                <Update/>
            </HomeSection>
    );
}

export default Home;

const HomeSection = styled.div`
    height: auto;
    margin: 0 auto;
    min-width: 1200px;
`;




