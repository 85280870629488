import { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "@/assets/styles/theme";
import { useAppDispatch } from "@/modules/store";
import { fileAction } from "@/pages/common/fileSlice";
import Text from "@/components/atoms/text";
import useAlert from "@/modules/hooks/useAlert";
import { ReactComponent as ClearMark } from "@/assets/icons/clear.svg";

export default function FileUpload({ file, setFile, setFileList, maxSize = 2, customStyle }: FileUploadProps) {
    const dispatch = useAppDispatch();
    const [active, setActive] = useState(false);
    const [zIndex, setZIndex] = useState(-1);
    const { alert } = useAlert();
    const singleFile = file as File;
    const multiFile = file as FileList;

    const deleteFile = () => {
        setFile({} as File);
    };

    const singleFileUpload = async (tmpFile: File) => {
        if (tmpFile.size > 1048576 * maxSize) {
            return await alert("info", "주의", `${maxSize}Mb 이하 파일만 등록 가능합니다.`);
        }
        setFile(tmpFile);
    };

    const multiFileUpload = async (tmpFileList: FileList) => {
        for (let tmpFile of tmpFileList) {
            if (tmpFile.size > 1048576 * maxSize) {
                return await alert("info", "주의", `${maxSize}Mb 이하 파일만 등록 가능합니다.`);
            }
        }
        setFileList(tmpFileList);
    };

    const changeInputFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files || "";
        if (!file) return;
        if (file.length === 1) singleFileUpload(file[0]);
        else multiFileUpload(file);
    };

    const onDragover = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setActive(true);
        setZIndex(9999);
        e.dataTransfer.dropEffect = "move";
    };
    const onDragleave = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setZIndex(9999);
        setActive(false);
    };
    const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        const targetFiles = e.dataTransfer.files;
        if (targetFiles.length === 1) singleFileUpload(targetFiles[0]);
        else multiFileUpload(targetFiles);

        setZIndex(-1);
        setActive(false);
    };

    // 변수명.. 다른걸로 하시는게?
    const onDragover2 = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "none";
        setZIndex(9999);
    };
    const onDragleave2 = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setZIndex(-1);
    };

    useEffect(() => {
        return () => {
            dispatch(fileAction.setFile({ name: "", file: "" }));
        };
    }, []);

    // 업로드된 파일이 있을 때
    if (multiFile.length) {
        const arrayFiles = [...multiFile];
        return (
            <AfterBoxWrap>
                <AfterBoxText>파일 업로드</AfterBoxText>
                {arrayFiles.map((file) => (
                    <FileWrap key={file.name}>
                        <Text text={file.name} customStyle={{ fontSize: "14px", fontWeight: "400" }} />
                        <ClearMark width={24} height={24} fill={theme.colors.gray600} onClick={() => deleteFile()} />
                    </FileWrap>
                ))}
            </AfterBoxWrap>
        );
    }
    if (singleFile.name) {
        return (
            <AfterBoxWrap>
                <AfterBoxText>파일 업로드</AfterBoxText>
                <FileWrap key={singleFile.name}>
                    <Text text={singleFile.name} customStyle={{ fontSize: "14px", fontWeight: "400" }} />
                    <ClearMark width={24} height={24} fill={theme.colors.gray600} onClick={() => deleteFile()} />
                </FileWrap>
            </AfterBoxWrap>
        );
    }
    return (
        <ExcelUploadBoxWrap>
            <ExcelPrevent zIndex={zIndex} onDragOver={onDragover2} onDragLeave={onDragleave2} />
            <BoxWrap
                onDrop={onDrop}
                onDragOver={onDragover}
                onDragLeave={onDragleave}
                draggable={true}
                active={active}
                width={customStyle?.width}
                height={customStyle?.height}
            >
                <BoxText active={active} width={customStyle?.width} height={customStyle?.height}>
                    파일을 끌어다 놓거나&nbsp;
                    {/* <label htmlFor="file-drop-container"> */}
                    <label htmlFor="file_up">
                        <input
                            type="file"
                            multiple
                            id="file_up"
                            // id="file-drop-container"
                            style={{ display: "none" }}
                            onChange={changeInputFile}
                        ></input>
                        <Text text="업로드" customStyle={{ fontSize: "14px", color: "#0077FF" }} />
                    </label>
                    를 누르세요
                </BoxText>
            </BoxWrap>
        </ExcelUploadBoxWrap>
    );
}

const ExcelUploadBoxWrap = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
`;

const ExcelPrevent = styled.div<{ zIndex?: number }>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    z-index: ${({ zIndex }) => (zIndex ? zIndex : "")};
`;

const BoxWrap = styled.div<{ active?: boolean; width?: string; height?: string }>`
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ height }) => (height ? height : "120px")};
    width: ${({ width }) => (width ? width : "440px")};
    background-color: ${({ active, theme }) => (active ? theme.colors.blue50 : theme.colors.gray50)};
    position: absolute;
`;

const BoxText = styled.div<{ active?: boolean; width?: string; height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed ${({ active, theme }) => (active ? theme.colors.blue500 : theme.colors.gray300)};
    height: ${({ height }) => (height ? Number(height.replace(/[^0-9]/g, "")) - 20 + "px" : "100px")};
    width: ${({ width }) => (width ? Number(width.replace(/[^0-9]/g, "")) - 20 + "px" : "420px")};
    font-size: 14px;
    color: ${({ active, theme }) => (active ? theme.colors.black : theme.colors.gray600)};
`;

const AfterBoxWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 108px;
`;

const AfterBoxText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    height: 48px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`;

const FileWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    height: 60px;
    width: 100%;
`;

interface StyleProps {
    width?: string;
    height?: string;
}

interface FileUploadProps {
    file: File | FileList;
    setFile: (file: File) => void;
    setFileList: (fileList: FileList) => void;
    maxSize?: number;
    customStyle?: StyleProps;
}

export interface UploadedFile {
    file: string;
    fileName: string;
}
