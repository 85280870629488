import { useEffect, useRef } from "react";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { inquiryDailyActions } from "@/pages/settlement/saels-inquiry/publisher/daily/dailySlice";

const useDailyApi = () => {
    const {
        items,
        startDate,
        endDate,
        selectedPlatform,
        searchSeriesName,
        searchBookName,
        searchAuthorName,
        startDateError,
        endDateError,
        sortBy,
        sortDesc,
        initialDataLoaded,
        fetchLoading,
    } = useSelectorTyped((state: RootState) => state.inquiryDaily);
    const dispatch = useAppDispatch();
    const { page, itemsPerPage } = usePagination({ from: "daily-inquiry" });
    const prevIpp = useRef(itemsPerPage);

    useEffect(() => {
        if (!initialDataLoaded && fetchLoading) return;
        if (startDateError !== "" || endDateError !== "") return;
        
        dispatch(inquiryDailyActions.setParamsId({ type:"all" }));
        return () => { }
    }, [        
        startDate,
        endDate,
        selectedPlatform,
        searchBookName,
        searchSeriesName,
        searchAuthorName,
        itemsPerPage,
        // table sort options 
        sortBy,
        sortDesc,
        dispatch,
    ])

    // 옵션(보기, 페이지) 변경시
    useEffect(() => {
        const startIdx = (page - 1) * itemsPerPage;
        const endIdx = page * itemsPerPage;
        if (!items.length) return;
        const targetItems = items.slice(startIdx, endIdx);

        // 이미 방문한 페이지일 때는 요청 X
        if (prevIpp.current === itemsPerPage) {
            const hasDetail = targetItems.every((item) => item.detail_series.length > 0);
            if (hasDetail) return;
        }
        prevIpp.current = itemsPerPage;
        
        const tmpJsonTable = targetItems.map((row) => ({
            date: row.date,
            publisher_id: row.publisher_id,
            platform_id: row.platform_id,
            series_id: row.series_id,
        }));

        dispatch(inquiryDailyActions.setTmpJsonTable(tmpJsonTable));
        dispatch(inquiryDailyActions.setParamsId({ type: "detail" }));
    }, [page, itemsPerPage, items, dispatch]);
}   

export default useDailyApi;