import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, ApiParams, ExcelUploadApiParams } from "./types";

const name = "salesEntry/etc";
export const initialItem = {
    id: 1,
    checked: false,
    in_date: "", // 입금일자
    inDateState: "해당없음", // 필요한가?
    date: "", // 지급일자
    platform_id: 0,
    authorInfo: {
        author_id: 0,
        author_name: "",
        co_info: [],
    },
    pre_amount: "",
    st_ratio: 100,
    tax_type: "N" as const,
    author_tax_info: [],
    tax_amount: "",
    tax_desc: "",
    amount: "",
    reason: "",
    isCoAuthor: false,
    needCoRatio: false,
    inDateError: "",
    dateError: "",
    authorError: "",
    preAmountError: "",
    stRatioError: "",
    taxAmountError: "",
    taxDescError: "",
    amountError: "",
    reasonError: "",
};
const initialState: State = {
    items: [initialItem],
    lastId: 1,
    platformId: 0,
};

// 엑셀 데이터 업로드
export const uploadEtcExcelData = createAsyncThunk(
    `${name}/uploadExcelData`,
    async (apiParams: ExcelUploadApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/excelUpload/uploadEtc/insert", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 기타지급금 데이터 등록
export const saveEtcSales = createAsyncThunk(
    `${name}/saveEtcSales`,
    async (apiParams: ApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSalesEntry/etc/insert", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const etcSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLastId: (state, action) => {
            state.lastId = action.payload;
        },
        setItem: (state, action: PayloadAction<{ index:number; item:any }>) => {
            const { index, item } = action.payload;
            state.items[index] = item;
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setPlatformId: (state, action) => {
            state.platformId = action.payload;
        },
        resetItems: (state) => {
            state.items = [initialItem];
        },
        resetStore: () => {
            return { ...initialState };
        },
    },
});

export const { setLastId, setItem, setItems, setPlatformId, resetItems, resetStore } = etcSlice.actions;
export default etcSlice.reducer;
