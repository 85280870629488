import { useEffect, useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import { useAppDispatch } from "@/modules/store";
import styled from "styled-components";
import Radio from "@/components/atoms/input/Radio";
import { Copyright } from "@/types/dataType"; 

export default function SetLinkOptionModal({ show, close, link, idx }: any) {
    const [linkType, setLinkType] = useState(link);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setLinkType(link);
    }, [link])

    const reset = () => {

    }

    const apply = () => {
        dispatch({ type: "ebookMaker/setCopyrightContentCaseOption", payload: {
            index: idx,
            type: "link",
            to: linkType
        }})
        close();
    }

    const changeType = (e: any) => {
        setLinkType(e.target.value);
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["연결 형태"]}
            customStyle={[{ width: "280px", height: "280px" }]}
            applyFunc={() => {apply();}}
            reset={reset}
        >
           <ModalBodyWrapper>
           <RowWrapper>
                <RadioWrapper>
                    <Radio
                        name="dataType"
                        checked={linkType === Copyright.Link.MAILTO}
                        value={Copyright.Link.MAILTO}
                        onChange={(e) => changeType(e)}
                        type="label"
                        label="메일로 연결"
                        customStyle={{ fontSize:"14px", margin: "0 24px 0 0"}}
                    />
                </RadioWrapper>
               </RowWrapper>
               <RowWrapper>
                    <Radio
                        name="dataType"
                        checked={linkType === Copyright.Link.HREF}
                        value={Copyright.Link.HREF}
                        onChange={(e) => changeType(e)}
                        type="label"
                        label="하이퍼링크"
                        customStyle={{ fontSize:"14px" }}
                    />
               </RowWrapper>
               <RowWrapper>
                    <Radio
                        name="dataType"
                        checked={linkType === Copyright.Link.NONE}
                        value={Copyright.Link.NONE}
                        onChange={(e) => changeType(e)}
                        type="label"
                        label="해당없음"
                        customStyle={{ fontSize:"14px" }}
                    />
               </RowWrapper>
           </ModalBodyWrapper>
        </BaseModal>
    );
}

const ModalBodyWrapper = styled.div`
    padding: 0 20px;
    width: 100%;
`

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
`

const RadioWrapper = styled.div`
    display: flex;
    align-items: center;
`
