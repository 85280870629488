import styled from "styled-components";
import { useEffect } from "react";
import LoadingPortal from "@/components/molecules/common/loading/Portal";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";

// export default function ProgressBar({ ratio }: { ratio : number | undefined}) {
export default function ProgressBar({ ratio }: { ratio : boolean}) {
    useEffect(() => {
    }, [ratio])

    return (
        <>
            { ratio ?
            <LoadingPortal>
                <ProgressBarContainer show={true}>
                    <ProgressBarWrap>
                        <TextWrap>
                            <Text text="파일을 업로드 하는 중입니다." customStyle={{ fontSize: "20px", fontWeight: "500", lineHeight: "16px", color: colors.gray600 }}/>    
                            {/* <Text text={`${ratio}%`} customStyle={{ fontSize: "20px", fontWeight: "500", lineHeight: "16px" }}/> */}
                        </TextWrap>
                        <SkeletonContainer>
                            <SkeletonAnimation />
                        </SkeletonContainer>
                    </ProgressBarWrap>
                </ProgressBarContainer>
            </LoadingPortal>
            :
            <></>
            }
        </>
    )
    // const [loading] = useAppSelector((state:RootState) => [state.loading.progress]);
    // const percent = useRef(0)
    // useEffect(() => {
    //     if (loading) {

    //     }
    // }, [loading])

    // return (
    //     <>
    //         {/* {loading ? */}
    //         {loading || typeof loading === "number" ?
    //         <LoadingPortal>
    //             <ProgressBarContainer show={loading || typeof loading === "number"}>
    //                 <ProgressBarWrap>
    //                     <TextWrap>
    //                         <Text text="파일을 불러오는 중입니다." customStyle={{ fontSize: "20px", fontWeight: "500", lineHeight: "16px", color: colors.gray600 }}/>    
    //                         <Text text={`${percent.current}%`} customStyle={{ fontSize: "20px", fontWeight: "500", lineHeight: "16px" }}/>
    //                     </TextWrap>
    //                     <Progress>
    //                         <ProgressFill width={String(45*percent.current)+"px"} />
    //                     </Progress>
    //                 </ProgressBarWrap>
    //             </ProgressBarContainer>
    //         </LoadingPortal>
    //         :
    //         <></>
    //         }
    //     </>
    // )
}

const SkeletonContainer = styled.div<{ width?: string, height?: string }>`
    width: ${({ width }) => width ? width : "450px"};
    height: ${({ height }) => height ? height : "8px"};
    background-image: -webkit-linear-gradient( lightgray ${({ height }) => height ? height : "8px"}, transparent 0 );
    border-radius: 4px;
`

const SkeletonAnimation = styled.div<{ width?: string, height?: string }>`
    animation: placeHolderShimmer 1s linear forwards infinite;
    background: #f1f3f5;
    // background-image: linear-gradient(to right, #80deea 0%, #00BCD4 20%, #80deea 40%, #80deea 100%);
    background-image: linear-gradient(90deg, rgba(178, 235, 242, 0) 22.21%, #B2EBF2 36.64%, #00BCD4 68.61%, rgba(0, 188, 212, 0) 88%);
    background-repeat: no-repeat;
    // background-size: 800px 104px;
    background-size: 200px 200px;
    height: ${({ height }) => height ? height : "8px"};
    position: relative;
    @keyframes placeHolderShimmer {
        0% {
            // background-position: -468px 0
            background-position: -304px 0
        }
        100% {
            // background-position: 468px 0
            background-position: 304px 0
        }
    }
    border-radius: 4px;
`

const ProgressBarContainer = styled.div<{ show: boolean|number }>`
    display: ${({ show }) => show ? "block" : "none"};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.2);
    opacity: 1;
    z-index: 999999;
`

const ProgressBarWrap = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999;

    width: 498px;
    height: 88px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 24px;
`

const TextWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`

// const Progress = styled.div`
//     width: 450px;
//     height: 8px;
//     background-color: ${({ theme }) => theme.colors.gray300};
//     border-radius: 10px;
// `
    
// const ProgressFill = styled.div<{ width: string }>`
//     height: 100%;
//     width: ${({ width }) => width};
//     background: linear-gradient(90deg, #B2EBF2 22%, #00BCD4 88%);
//     border-radius: 10px;
// `