import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { signupActions, userInsert } from "@/pages/auth/signUp/signupSlice";
import { ChangeEvent, useEffect, useState } from "react";
import Button from "@/components/atoms/button";
import styled from "styled-components";
import useAlert from "@/modules/hooks/useAlert";
import BaseInput from "@/components/molecules/signup/BaseInput";
import { ReactComponent as LogoProfileIcon } from "@/assets/icons/logo-profile.svg";
import { ReactComponent as AddRoundIcon } from "@/assets/icons/add_round_blue500.svg";
import { ReactComponent as CameraIcon } from "@/assets/icons/camera_blue500.svg";
import { colors } from "@/assets/styles/theme";
import { useNavigate } from "react-router-dom";
import TextInput from "@/components/atoms/input/TextInput";
import { base64Encode } from "@/modules/utils/file";
import useLoading from "@/modules/hooks/useLoading";

export default function PublisherInfoForm() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    let { publisherInfo, companyRegNo, step, kcp, data, check, fromSocial, socialData } = useAppSelector((state: RootState) => state.signup);
    const [publisher, setPublisher] = useState({
        publisher_id: 0,
        name: "",
        regNo: "",
        email: "",
        ceoName: "",
        tel: "",
        url: "",
        addr: "",
        addrDetail: "",
        desc: "",
        picPath: "",
        profileImg: null,
        jusoCallback: null,
        // isNew: true,
    })
    const [image, setImage] = useState<File|string>("");
    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        if (publisherInfo.publisher_name) {
            setPublisher({...publisher,
                "publisher_id": publisherInfo.publisher_id,
                "name": publisherInfo.publisher_name,
                "regNo": companyRegNo,
                "email": publisherInfo.publisher_email,
                "ceoName": publisherInfo.publisher_ceo_name,
                "tel": publisherInfo.publisher_tel,
                "url": publisherInfo.publisher_url,
                "addr": publisherInfo.publisher_addr,
                "addrDetail": publisherInfo.publisher_addr_detail,
                "desc": publisherInfo.publisher_desc,
                "picPath": publisherInfo.publisher_pic_path,
            });
            setIsNew(false);
        }
    }, [publisherInfo, companyRegNo])

    async function changeHandlerPublisherInfo(e: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLTextAreaElement>, key: string) {
        let value = e.target.value;
        setPublisher({...publisher, [key]: value});
    }

    const keyPressHandler = (key: any) => {
        if (key === "Enter") {
            next();
        }
    };

    async function next() {
        try {
            if (!publisher.name) {
                return await alert("warning", "주의", "출판사명을 입력해주세요.");
            }
            if (!publisher.email) {
                return await alert("warning", "주의", "출판사 이메일을 입력해주세요.");
            }
            if (!publisher.tel) {
                return await alert("warning", "주의", "대표 전화번호를 입력해주세요.");
            }
            
            let apiParams = {
                ...kcp,
                user_email: data.id,
                user_pw: data.pw,
                user_nickname: check.nickName,
                user_emailagree: check.email,
                mailing_address: check.mailing,
                sociallogin_provider: fromSocial ? socialData.provider : "",
                sociallogin_id: fromSocial ? socialData.id : "",
                profile_img: check.image ? await base64Encode(check.image as File) : "",
                publisherInfo: publisher,
                pubProfileImg: image ? await base64Encode(image as File) : "",
            }
            
            await loadingOn();
            await dispatch(userInsert(apiParams)).unwrap();
    
            dispatch(signupActions.changeStep(++step));
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    function prev() {
        dispatch(signupActions.changeStep(--step));
    }

    function imageUpload() {
        document.getElementById("selectImage")?.click();
    }

    async function selectImage(e: ChangeEvent<HTMLInputElement>) {
        const file = e.target.files && e.target.files[0];
        if (file) {
            if (file.size > 2097152.0) {
                return await alert("warning", "주의", "2Mb 이하 파일만 등록 가능합니다.");
            }
            if (file.type == "image/png" || file.type == "image/jpeg") {
                setImage(window.URL.createObjectURL(file));
            } else {
                setImage("");
                return await alert("warning", "주의", ".jpg 또는 .png 파일만 업로드 가능합니다.")
            }
        } else {
            setImage("");
        }
    }

    return (
        <>
            <ImageContainer>
                <label htmlFor="회사 로고">회사 로고</label>
                {image ?
                <ImageWrap isNew={isNew} >
                    <img className="profile" src={String(image)} onClick={() => isNew ? imageUpload() : {}} />
                    <CameraIcon className="camera-icon" width={40} height={40} onClick={() => isNew ? imageUpload() : {}} />
                </ImageWrap>
                :
                <ImageWrap isNew={isNew}>
                    <LogoProfileIcon className="persion-icon" fill={colors.gray300} onClick={() => isNew ? imageUpload() : {}} />
                    <AddRoundIcon className="add-icon" width={48} height={48} onClick={() => isNew ? imageUpload() : {}} />
                </ImageWrap>
                }
                <input id="selectImage" type="file" hidden onChange={(e) => selectImage(e)} accept="image/jpeg, image/png" />
            </ImageContainer>
            <BaseInput
                disabled={!isNew}
                label="출판사명*"
                value={publisher.name}
                placeholder="출판사명을 입력해주세요."
                errorMsg={""}
                onChange={(e) => changeHandlerPublisherInfo(e, "name")}
                onKeyPress={keyPressHandler}
                customStyle={{ margin: "0 0 8px 0" }}
            />
            <BaseInput
                disabled={true}
                label="사업자 등록번호*"
                value={companyRegNo}
                placeholder="사업자 등록번호를 입력해주세요."
                errorMsg=""
                onChange={()=>{}}
                onKeyPress={()=>{}}
                customStyle={{ margin: "0 0 8px 0" }}
            />
            <BaseInput
                disabled={!isNew}
                label="출판사 이메일*"
                value={publisher.email}
                placeholder="출판사 이메일을 입력해주세요."
                errorMsg=""
                onChange={(e) => changeHandlerPublisherInfo(e, "email")}
                onKeyPress={keyPressHandler}
                customStyle={{ margin: "0 0 8px 0" }}
            />
            <BaseInput
                disabled={!isNew}
                label="대표 이름"
                value={publisher.ceoName}
                placeholder="이름을 입력해주세요."
                errorMsg=""
                onChange={(e) => changeHandlerPublisherInfo(e, "ceoName")}
                onKeyPress={keyPressHandler}
                customStyle={{ margin: "0 0 8px 0" }}
            />
            <BaseInput
                disabled={!isNew}
                label="대표 전화번호*"
                value={publisher.tel}
                placeholder="전화번호를 입력해주세요."
                errorMsg=""
                onChange={(e) => changeHandlerPublisherInfo(e, "tel")}
                onKeyPress={keyPressHandler}
                customStyle={{ margin: "0 0 8px 0" }}
            />
            <BaseInput
                disabled={!isNew}
                label="홈페이지"
                value={publisher.url}
                placeholder="홈페이지 주소를 입력해주세요."
                errorMsg=""
                onChange={(e) => changeHandlerPublisherInfo(e, "url")}
                onKeyPress={keyPressHandler}
                customStyle={{ margin: "0 0 16px 0" }}
            />
            <BaseInput
                disabled={!isNew}
                label="주소"
                value={publisherInfo.publisher_zonecode}
                placeholder="우편번호"
                errorMsg=""
                onChange={()=>{}}
                onKeyPress={keyPressHandler}
                customStyle={{ margin: "0px" }}
            />
            <TextInput
                type="readOnly"
                value={publisherInfo.publisher_addr}
                placeholder="주소"
                onChangeHandler={()=>{}}
                customStyle={{ width: "400px", margin: "8px 0 0" }}
            />
            <TextInput
                type={!isNew ? "readOnly" : "main"}
                value={publisher.addrDetail}
                placeholder="상세주소"
                onChangeHandler={(e) => changeHandlerPublisherInfo(e, "addrDetail")}
                customStyle={{ width: "400px", margin: "8px 0 0" }}
            />
            <TextAreaWrap>
                <label className="publisher-intro" htmlFor="출판사 소개">출판사 소개</label>
                <textarea
                    readOnly={!isNew}
                    placeholder="출판사 소개를 입력해주세요."
                    value={publisher.desc}
                    onChange={(e) => changeHandlerPublisherInfo(e, "desc")}
                    style={{ background: !isNew ? "#f8f9fa" : "white", color: !isNew ? "#868e96" : colors.gray600 }}
                />
            </TextAreaWrap>
            <Button
                type="main"
                label="회원가입"
                customStyle={{ width: "400px",
                                height: "60px",
                                margin: "24px 0 0 0",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={next}
            />
            <Button
                type="red-outline"
                label="이전으로"
                customStyle={{ width: "400px",
                                height: "60px",
                                margin: "24px 0 60px",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={prev}
            />
        </>
    );
}

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 146px;
    margin-bottom: 24px;
    label {
        color: ${({ theme }) => theme.colors.gray600};
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        width: 100%;
    }
`

const ImageWrap = styled.div<{ isNew?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 120px;
    position: relative;
    .persion-icon {
        background: ${({ theme }) => theme.colors.gray50};
        border-radius: 50%;
        cursor: ${({ isNew }) => isNew ? "pointer" : "default"};
    }
    .add-icon {
        position: absolute;
        bottom: 10px;
        right: 130px;
        cursor: ${({ isNew }) => isNew ? "pointer" : "default"};
    }
    .profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        cursor: ${({ isNew }) => isNew ? "pointer" : "default"};
    }
    .camera-icon {
        position: absolute;
        bottom: 10px;
        right: 135px;
        background: ${({ theme }) => theme.colors.blue500};
        border-radius: 50%;
        cursor: ${({ isNew }) => isNew ? "pointer" : "default"};
    }
`

const TextAreaWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-top: 24px;
    .publisher-intro {
        color: ${({ theme }) => theme.colors.gray600};
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    textarea {
        width: 400px;
        height: 215px;
        border: 1px solid ${({ theme }) => theme.colors.gray300};
        resize: none;
        font-size: 12px;
        padding: 8px;
        line-height: 21px;
    }
`