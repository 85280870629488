import styled from "styled-components";
import { Oval } from "react-loader-spinner";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { useAppSelector, RootState } from "@/modules/store";

function SummaryCard(props: SummaryCardProps) {
    const { salesSummaryLoading } = useAppSelector((state: RootState) => state.pubDashboard);

    if (salesSummaryLoading) {
        return (
            <StyledCard>
                <LoaderWrapper>
                    <Oval 
                        color={colors.blue600} 
                        secondaryColor={colors.blue100} 
                        width="50"
                        height="50"
                    />
                </LoaderWrapper>
            </StyledCard>
        )
    }

    const typeStyle = {
        color: props.isFirst ? colors.blue500 : colors.gray600,
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "20.03px"
    }

    const amountStyle = {
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "25px",
        margin: "0 0 4px 0"
    }

    const descStyle = {
        fontSize: "12px",
        lineHeight: "12px",
        color: props.cardData.rate >= 0 ? colors.error : colors.information
    }

    return (
        <StyledCard>
            <LeftWrapper>
                <Text text={props.cardData.type} customStyle={typeStyle}/>
            </LeftWrapper>
            <div>
                <Text text={props.cardData.amount} customStyle={amountStyle}/>
                <Text text={props.cardData.desc} customStyle={descStyle}/>
            </div>
        </StyledCard>
    )
}

const StyledCard = styled.li`
    display: flex;
    align-items: center;
    width: 280px;
    height: 100px;
    background-color: ${colors.white};
    border-radius: 4px;
`

const LeftWrapper = styled.div`
    display: flex;
    padding-left: 21px;
    margin-right: 10px;
    width: 110px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
`

interface SummaryCardProps {
    cardData: Card;
    isFirst: boolean;
}

interface Card {
    type: string;
    amount: string;
    desc: string;
    rate: number;
}

export default SummaryCard;