import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale, ReactDatePickerCustomHeaderProps } from "react-datepicker";
import styled, { css } from "styled-components";
import ko from "date-fns/locale/ko";
import moment from "moment";
import _ from "lodash";
import useDidUpdateEffect from "@/modules/hooks/useDidUpdateEffect";
import Button from "@/components/atoms/button";
import { ReactComponent as ArrowDropDown } from "@/assets/icons/arrow_drop_down.svg";
import { ReactComponent as ArrowDropDownGray } from "@/assets/icons/arrow_drop_down_gray600.svg";
import { ReactComponent as ArrowLeft } from "@/assets/icons/left.svg";
import { ReactComponent as ArrowRight } from "@/assets/icons/right.svg";
import { ReactComponent as BlueArrowDropDown } from "@/assets/icons/arrow_drop_down_blue.svg";
import { CommonType } from "@/types/dataType";
import useAlert from "@/modules/hooks/useAlert";

registerLocale("ko", ko);

function CustomDatePicker({
    onChange,
    disabled = false,
    settingDate,
    customStyle,
    unsetButton = false,
    customLabel = "해당없음",
    startAtDate,
    endAtDate,
    beforeEndDate = "",
    beforeStartDate = "",
}: CustomDatePickerProps) {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [showModal, setShowModal] = useState(false); // 모달 오픈
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // 임시 선택 년도
    const [showHeader, setShowHeader] = useState<"default" | "yearAndMonth" | "yearScroll">("default"); // 헤더 타입
    const startRef = useRef<DatePicker>(null); // handle datepicker manually
    const scrollPos = useRef<HTMLDivElement>(null); // scroll header ref
    const targetYearPos = useRef<HTMLDivElement>(null); // scroll header에서 선택된 년도 offsetTop ref
    const years = _.range(moment(new Date()).year() - 100, moment(new Date()).year() + 100); // 커스텀 헤더 데이터
    const { alert } = useAlert();
    // 스크롤 헤더 offsetTop 초기화
    useEffect(() => {
        if (showHeader === "yearScroll" && scrollPos.current && targetYearPos.current) {
            scrollPos.current.scrollTo(0, targetYearPos.current.offsetTop - 120);
        }
        if (!showModal && showHeader !== "default") {
            applySettingDate();    
        }
        return () => {};
    }, [showHeader, showModal]);

    // 데이트 값 변경시 부모 컴포넌트에 변경된 값 전달
    useDidUpdateEffect(() => {
        const changedDate = moment(startDate).format("YYYYMMDD");
        if (changedDate === "Invalid date") {
            onChange("");
        } else {
            onChange(changedDate);
        }
    }, [startDate]);

    // 초기화
    useEffect(() => {
        if (settingDate === "") return;
        applySettingDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 부모 컴포넌트에서 받은 데이트 값으로 변경
    useEffect(() => {
        applySettingDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingDate]);

    // 모달 이벤트
    const clickCalendar = () => {
        startRef.current?.setOpen(!showModal);
        setShowModal((prev) => !prev);
    };

    const applySettingDate = () => {
        // 날짜 삭제
        if (!settingDate) return setStartDate(null);
        const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
        const convertedStartDate = regex.test(settingDate) ? settingDate : moment(settingDate).format("YYYY-MM-DD");
        setSelectedYear(moment(settingDate).year());
        setStartDate(new Date(convertedStartDate));
    };

    // 데이트 변경 이벤트
    const onStartChange = (date: Date) => {
        if (showHeader === "yearAndMonth") setShowHeader("default");
        if (showHeader === "default") {
            if (!settingDate) {
                setSelectedYear(Number(moment().format('YYYY')));
            } else {
                setSelectedYear(Number(moment(settingDate).format('YYYY')));
            }
            date.setFullYear(selectedYear);
            const changedDate = moment(date).format("YYYYMMDD");
            if (beforeEndDate >= changedDate) {
                return alert("warning", "경고", "이전 유통 종료일보다 나중인 날짜를 선택해주세요.");
            }
            if (beforeStartDate && beforeStartDate <= changedDate) {
                return alert("warning", "경고", "다음 유통 시작일보다 이전인 날짜를 선택해주세요.");
            }
            if (typeof startAtDate === "string" && startAtDate >= changedDate) {
                return alert("warning", "경고", "시작일자 이후의 날짜를 선택해주세요.");
            } else if (typeof endAtDate === "string" && endAtDate <= changedDate) {
                return alert("warning", "경고", "종료일자 이전의 날짜를 선택해주세요.");
            } else {
                setStartDate(date);
            }
        }
    };

    const unsetDateHandler = () => {
        setStartDate(null);
        clickCalendar();
    };

    // 헤더 이벤트
    const clickHeaderHandler = (from: string) => {
        switch (from) {
            case "default":
                setShowHeader("yearAndMonth");
                break;
            case "yearAndMonth":
                setShowHeader("yearScroll");
                break;
            case "yearScroll":
                setShowHeader("yearAndMonth");
                break;
        }
    };
    const changeYearHeader = (year: number) => {
        setSelectedYear(year);
        clickHeaderHandler("yearScroll");
    };

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps) => {
        const tmpYear = selectedYear || moment(date).year();
        const month = moment(date).month() + 1;

        const clickLeftBtn = () => {
            if (month === 1) setSelectedYear(moment(date).year() - 1);
            decreaseMonth();
        }

        const clickRightBtn = () => {
            if (month === 12) setSelectedYear(moment(date).year() + 1); 
            increaseMonth();
        }
        
        return (
            <HeaderContainer>
                {/* 기본 달력 */}
                <HeaderWrapper show={showHeader === "default"}>
                    <LeftButton onClick={clickLeftBtn} />
                    <Header onClick={() => clickHeaderHandler("default")}>
                        <DateContent>
                            <YearText>{tmpYear}년</YearText>
                            <MonthText>{month}월</MonthText>
                        </DateContent>
                        <IcBlueArrowDropDown />
                    </Header>
                    <RightButton onClick={clickRightBtn} />
                </HeaderWrapper>
                {/* 년도 & 달 선택 */}
                <HeaderWrapper show={showHeader === "yearAndMonth"}>
                    <LeftButton onClick={() => setSelectedYear((prev) => prev - 1)} />
                    <Header onClick={() => clickHeaderHandler("yearAndMonth")}>
                        <DateContent>{tmpYear}년</DateContent>
                        <IcBlueArrowDropDown />
                    </Header>
                    <RightButton onClick={() => setSelectedYear((prev) => prev + 1)} />
                </HeaderWrapper>
                {/* 스크롤로 년도 선택 */}
                <HeaderWrapper show={showHeader === "yearScroll"}>
                    <ScrollHeader ref={scrollPos}>
                        {years.map((year) => (
                            <ScrollHeaderContent
                                key={year}
                                ref={year === tmpYear ? targetYearPos : null}
                                active={year === tmpYear}
                                onClick={() => changeYearHeader(year)}
                            >
                                {year}년
                            </ScrollHeaderContent>
                        ))}
                    </ScrollHeader>
                </HeaderWrapper>
            </HeaderContainer>
        );
    };

    const CustomInput = React.forwardRef(
        ({ value, onClick }: { value: any; onClick: any }, ref: React.Ref<HTMLInputElement>) => {
            return (
                <InputWrapper
                    onClick={clickCalendar}
                    disabled={disabled}
                    width={customStyle?.width}
                    height={customStyle?.height}
                >
                    <StyledCustomInput
                        value={value}
                        readOnly
                        onClick={onClick}
                        disabled={disabled}
                        placeholder={unsetButton ? "유통 진행 중" : "날짜선택"}
                        
                    />
                    <IconWrapper>{disabled ? <ArrowDropDownGray /> : <ArrowDropDown />}</IconWrapper>
                </InputWrapper>
            );
        },
    );

    return (
        <DatePickerWrapper hideDayContents={showHeader === "yearScroll"} unsetButton={unsetButton}>
            <DatePicker
                locale="ko"
                selected={startDate}
                dateFormat="yyyy-MM-dd"
                onChange={onStartChange}
                ref={startRef}
                calendarClassName="custom-calendar"
                renderCustomHeader={CustomHeader}
                customInput={React.createElement(CustomInput)}
                showMonthYearPicker={showHeader === "yearAndMonth"}
                shouldCloseOnSelect={showHeader === "default"}
                onCalendarClose={() => setShowModal(false)}
                disabled={disabled}
                popperProps={{ strategy: "fixed" }}
                closeOnScroll={(e) => {e.target===document.getElementById("circulationStopBody")
                                       && clickCalendar(); return e.target===document.getElementById("circulationStopBody")}}
                >
                <UnsetDateButton show={unsetButton} label={customLabel} unsetDateHandler={unsetDateHandler} />
            </DatePicker>
        </DatePickerWrapper>
    );
}

// 날짜 삭제 버튼
function UnsetDateButton({ show, label, unsetDateHandler }: UnsetDateButtonProps) {
    const btnStyle = label === "유통 진행 중" ? {...unsetDateButtonStyle, width: "100px"} : unsetDateButtonStyle;
    return (
        <ButtonWrapper show={show} onClick={unsetDateHandler}>
            <Button type="main" label={label} customStyle={btnStyle} />
        </ButtonWrapper>
    );
}

const ButtonWrapper = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? "block" : "none")};
    text-align: right;
    margin: 8px 20px;
`;
const unsetDateButtonStyle = {
    width: "80px",
    height: "32px",
    fontSize: "14px",
};

const DatePickerWrapper = styled.div<{ hideDayContents: boolean; unsetButton: boolean }>`
    .react-datepicker-popper {
        z-index: 100;
    }
    .react-datepicker {
        display: flex;
        flex-direction: column;
        width: 268px;
        height: auto;
        min-height: ${({ unsetButton }) => (unsetButton ? "268px" : "222px")};
        border: none;
        box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

        .react-datepicker__month-container {
            width: 100%;
            height: 100%;
            .react-datepicker__header {
                width: 100%;
                background-color: ${({ theme }) => theme.colors.white};
                border: none;
            }

            .react-datepicker__month-text {
                display: inline-flex;
                margin: 2px;
                width: 31.3%;
                height: 38px;
                align-items: center;
                justify-content: center;
            }
            .react-datepicker__day--keyboard-selected,
            .react-datepicker__month-text--keyboard-selected,
            .react-datepicker__quarter-text--keyboard-selected,
            .react-datepicker__year-text--keyboard-selected {
                background-color: ${({ theme }) => theme.colors.blue500};
            }
            .react-datepicker__day--selected,
            .react-datepicker__day--in-selecting-range,
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--selected,
            .react-datepicker__month-text--in-selecting-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--selected,
            .react-datepicker__quarter-text--in-selecting-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--selected,
            .react-datepicker__year-text--in-selecting-range,
            .react-datepicker__year-text--in-range {
                background-color: ${({ theme }) => theme.colors.blue500};
            }
            .react-datepicker__day--outside-month {
                color: ${({ theme }) => theme.colors.gray500};
            }
        }
    }
    .custom-calendar {
        .react-datepicker__triangle {
            display: none;
        }
        .react-datepicker__day-names {
            display: ${({ hideDayContents }) => (hideDayContents ? "none" : "block")};
        }
        .react-datepicker__month {
            display: ${({ hideDayContents }) => (hideDayContents ? "none" : "block")};
        }
    }
`;

// Input Style
const InputWrapper = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    position: relative;
    width: ${({ width }) => width || "120px"};
    height: ${({ height }) => height || "32px"};
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.gray50 : theme.colors.white)};
    ::placeholder {
        color: black;
    }
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;
const StyledCustomInput = styled.input<{ disabled: boolean, placeholder: string }>`
    width: 100%;
    border: none;
    font-size: 12px;
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.gray50 : theme.colors.white)};
    color: ${({ disabled, theme }) => (disabled ? theme.colors.gray600 : theme.colors.black)};
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
    ::placeholder {
        color: ${({ placeholder, theme }) => (placeholder === "유통 진행 중" ? theme.colors.black : theme.colors.gray600)};
        font-weight: 400;
    }
`;
const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
`;
// CustomHeader Style
const HeaderContainer = styled.div``;
const HeaderWrapper = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? "flex" : "none")};
    align-items: center;
    justify-content: center;
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    margin: 0 32px;
`;
const DateContent = styled.div`
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
`;
const YearText = styled.span``;
const MonthText = styled.span`
    margin-left: 6px;
`;
const IcBlueArrowDropDown = styled(BlueArrowDropDown)`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;
const ScrollHeader = styled.div`
    width: 280px;
    height: 280px;
    overflow-y: scroll;
    background-color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-weight: 600;
    margin: 0 32px;
    cursor: pointer;
`;
const ScrollHeaderContent = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-weight: 400;
    ${({ active }) =>
        active &&
        css`
            font-size: 26px;
            color: ${({ theme }) => theme.colors.blue500};
            height: 60px;
            padding: 0 10px;
        `}
    cursor: pointer;
`;
const LeftButton = styled(ArrowLeft)`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.colors.gray300};
    }
`;
const RightButton = styled(ArrowRight)`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.colors.gray300};
    }
`;

interface CustomDatePickerProps {
    disabled?: boolean;
    settingDate?: CommonType.Date | null;
    customStyle?: StyleProps;
    unsetButton?: boolean;
    onChange: (date: CommonType.Date) => void;

    customLabel?: string;
    startAtDate?: string;
    endAtDate?: string|null;
    beforeEndDate?: string;
    beforeStartDate?: string;
}
interface StyleProps {
    width?: string;
    height?: string;
    disabled?: boolean;
}
interface UnsetDateButtonProps {
    show: boolean;
    label: string;
    unsetDateHandler: () => void;
}

export default CustomDatePicker;
