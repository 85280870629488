import styled from "styled-components";
import { ReactComponent as NodataMark } from "@/assets/graphic/folder.svg";
import theme from "@/assets/styles/theme";
import Text from "@/components/atoms/text";

export default function NoData({ customStyle }:NoDataProps) {
    return (
        <NoDataWrap width={customStyle?.width} height={customStyle?.height} >
            <NodataMark />
            <Text text="데이터가 없습니다." customStyle={{ fontSize: "16px", fontWeight: "700", margin: "24px 0 0" }}/>
            <Text text="불러올 수 있는 데이터가 없습니다. 다시 한 번 확인해주세요." customStyle={{ fontSize: "12px", fontWeight: "400", color: theme.colors.gray500, margin: "16px 0 0" }}/>
        </NoDataWrap>
    )

}

const NoDataWrap = styled.div<StyleProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => height ? height : "358px"};
    width: ${({ width }) => width ? width : "100%"};
    background-color: ${({ theme }) => theme.colors.gray50 };
`

interface StyleProps {
    width?: string;
    height?: string;
}

interface NoDataProps {
    customStyle?: StyleProps;
}