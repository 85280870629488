import { useState, ChangeEvent, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import Checkbox from "@/components/atoms/input/Checkbox";
import TextInput from "@/components/atoms/input/TextInput";
import ModalSearchBar from "@/components/molecules/common/searchBar/modalSearchBar/ModalSearchBar";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";
import type { SeriesInfo } from "@/components/molecules/common/modal/BatchModal";
import type { CommonType } from "@/types/dataType";
import type { SearchSeriesResult, SelectedSeries } from "@/types/searchSeries";

export default function BatchModalBody({
    from,
    platformId,
    platformName,
    stYearmon,
    selectedSeries,
    setSelectedSeries,
    seriesInfo,
    setSeriesInfo,
}: BatchModalBodyProps) {
    const [showModal, setShowModal] = useState(false);

    const handleModalState = () => setShowModal((prev) => !prev);

    const selectCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target.value === "bookName" ? "bookNameCheck" : "seriesCheck";
        setSeriesInfo((prev) => ({ ...prev, [target]: !prev[target] }));
    };

    const changeBookName = (keyword: string) => {
        setSeriesInfo((prev) => ({ ...prev, bookName: keyword }));
    };

    const applySeries = (data: SearchSeriesResult) => {
        const selectedSeries = data.selectedSeries as SelectedSeries;

        setSeriesInfo((prev) => ({
            ...prev,
            series: {
                id: selectedSeries.id,
                name: selectedSeries.series_name,
            },
            authorName: selectedSeries.author_name,
            ratio: selectedSeries.ratio,
        }));
        setSelectedSeries(selectedSeries.series_name);
    };

    return (
        <>
            <ModalBodyContainer>
                {/* 작품명 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="bookName"
                            active={seriesInfo.bookNameCheck}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>작품명</LabelWrapper>
                    <ContentWrapper>
                        <TextInput
                            type="main"
                            placeholder="작품명"
                            value={seriesInfo.bookName}
                            onChangeHandler={(e) => changeBookName(e.target.value)}
                            customStyle={{ width: "180px", height: "32px" }}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 시리즈명 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="seriesName"
                            active={seriesInfo.seriesCheck}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>시리즈명</LabelWrapper>
                    <ContentWrapper>
                        <ModalSearchBar
                            type="disabledInput"
                            placeholder="시리즈명"
                            value={selectedSeries}
                            onHandleModal={handleModalState}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 저자명 */}
                <ItemRow>
                    <CheckboxWrapper />
                    <LabelWrapper>저자명</LabelWrapper>
                    <ContentWrapper>
                        <TextInput
                            type="readOnly"
                            placeholder="저자명"
                            value={seriesInfo.authorName}
                            onChangeHandler={() => {}}
                            customStyle={{ width: "180px", height: "32px" }}
                        />
                    </ContentWrapper>
                </ItemRow>
            </ModalBodyContainer>
            <ModalPortal>
                <SearchSeriesModal
                    from={from}
                    show={showModal}
                    close={handleModalState}
                    platformId={platformId}
                    platformName={platformName}
                    withRatio={from === 'monthly'}
                    multiSelect={false}
                    stYearmon={stYearmon}
                    applySeries={applySeries}
                />
            </ModalPortal>
        </>
    );
}

const ModalBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    height: 180px;
    overflow: auto;
`;
const ItemRow = styled.div<{ border?: boolean; active?: boolean }>`
    display: flex;
    align-items: center;
    height: 60px;
    border-top: ${({ theme, border }) => (border ? `1px solid ${theme.colors.gray300}` : "")};
    border-bottom: ${({ theme, border }) => (border ? `1px solid ${theme.colors.gray300}` : "")};
    background-color: ${({ theme, active }) => (active ? theme.colors.gray50 : "")};
`;
const BaseWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    width: 60px;
    height: 60px;
`;
const CheckboxWrapper = styled(BaseWrapper)`
    justify-content: center;
`;

const LabelWrapper = styled(BaseWrapper)`
    align-items: center;
    margin-right: 8px;
    min-width: 60px;
`;

const ContentWrapper = styled(BaseWrapper)`
    width: 320px;
    margin-left: 8px;

    .series-searchBar {
        width: auto;
        input {
            width: 180px;
            padding: 8px 16px 8px 8px;
        }
    }
    .search-field {
        width: 180px;
    }
`;

interface BatchModalBodyProps {
    from: string;
    platformId: CommonType.Id;
    platformName?: CommonType.Platform.Name;
    stYearmon: CommonType.Date;
    selectedSeries: string;
    setSelectedSeries: Dispatch<SetStateAction<string>>;
    seriesInfo: SeriesInfo;
    setSeriesInfo: Dispatch<SetStateAction<SeriesInfo>>;
}
