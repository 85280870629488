import { useState, useRef } from "react";
import _ from "lodash";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import SeriesMatchingModalBody from "./bodyModal/SeriesMatchingModalBody";
import useAlert from "@/modules/hooks/useAlert";
import useDidUpdateEffect from "@/modules/hooks/useDidUpdateEffect";
import { BaseModalProps } from "@/pages/common/types";
import { MatchedData, NonMatchedData, SeriesMatchingResult } from "@/types/excelUpload";

export default function SeriesMatchingModal({
    show,
    close,
    withRatio,
    nonMatchedData,
    applySeriesMatching,
}: ModalProps) {
    let items = useRef(nonMatchedData as NonMatchedData[]); // 매칭할 데이터 원본
    let matchedData: MatchedData[] = []; // 매칭완료된 데이터
    const [matchingItems, setMatchingItems] = useState<NonMatchedData[]>([]); // 임시 매칭 데이터
    const [addedRatioList, setAddedRatioList] = useState<NonMatchedData[]>([]);
    const { alert } = useAlert();

    useDidUpdateEffect(() => {
        items.current = _.cloneDeep(nonMatchedData) as NonMatchedData[];
        setMatchingItems([]); // 엑셀업로드 새로할 경우 초기화
    }, [nonMatchedData]);

    const formCheck = () => {
        let tmpMatchedData = [];
        for (let row of items.current) {
            if (!row.isChecked) return false;
            let matchedInfo = {
                originIdx: row.originIdx,
                book_name: row.book_name,
                seriesNo: row.seriesNo,
                series: row.recommended_series_name,
                series_id: row.recommended_series_id,
                platform_id: row.platform_id,
                author: row.recommended_author,
                author_id: row.recommended_author_id,
                ratio: row.recommended_ratio,
            };
            tmpMatchedData.push(matchedInfo);
        }
        matchedData = tmpMatchedData;
        return true;
    };

    const apply = async () => {
        if (!formCheck()) return await alert("warning", "주의", "매칭되지 않은 작품이 있습니다.");

        const series_id_list = matchingItems.map((item) => item.recommended_series_id);

        // 새로 정산비율 입력된 시리즈들 정리
        let forAddRatioList = [];
        for (let a of addedRatioList) {
            if (series_id_list.includes(a.series_id)) {
                forAddRatioList.push(a);
            }
        }

        let answer = await alert("confirm", "적용 확인", "적용 하시겠습니까?");
        if (answer === "Y") {
            applySeriesMatching({
                matchedData,
                forAddRatioList,
            });

            // 데이터 리셋
            items.current = [];
            matchedData = [];
            setMatchingItems([]);
            setAddedRatioList([]);

            close();
        }
    };

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["작품 - 시리즈 매칭"]}
            customStyle={[{ width: "960px", height: "436px" }]}
            applyFunc={apply}
        >
            <SeriesMatchingModalBody
                items={items}
                matchingItems={matchingItems}
                setMatchingItems={setMatchingItems}
                withRatio={withRatio}
                addedRatioList={addedRatioList}
                setAddedRatioList={setAddedRatioList}
            />
        </BaseModal>
    );
}

interface ModalProps extends BaseModalProps {
    withRatio: boolean;
    nonMatchedData: Partial<NonMatchedData>[];
    applySeriesMatching: ({ matchedData, forAddRatioList }: SeriesMatchingResult) => void;
}
