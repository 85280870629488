import { useState, useCallback, useEffect } from 'react'

export enum wheelType {
    down,
    up,
    pathChange,
    none
}

const useWheel = () => {
    const [ wheelDir, setWheelDir ] = useState(wheelType.none);
    
    // deltaY는 아래로 스크롤 시 양수, 위로 스크롤 시 음수 값을 가진다.
    const wheelMovement = useCallback((e: any): void => {
        if (e.deltaY > 0) setWheelDir(wheelType.down);
        else setWheelDir(wheelType.up);
        setTimeout(()=>{setWheelDir(wheelType.none)},100);
    },[]);

    useEffect(()=> {
        if (window.innerWidth <= 600) return;
        window.addEventListener('wheel', wheelMovement);
        return () => window.removeEventListener('wheel', wheelMovement)
    }, [wheelMovement]);

    return [wheelDir]
}

export default useWheel