export const base64Encode = (file:File) => {
    return new Promise((resolve) => {
        let a = new FileReader();
        a.onload = (e:ProgressEvent<FileReader>) => resolve(e.target?.result);
        a.readAsDataURL(file);
    });
}
// json형태의 buffer를 arraybuffer로 변환
export const arrayToArrayBuffer = (array:Buffer) => {
    let length = array.length;
    let buffer = new ArrayBuffer(length);
    let view = new Uint8Array(buffer);
    for ( let i = 0; i < length; i++) {
        view[i] = array[i];
    }
    return buffer;
}
// blob형태로 만들어 다운로드 기능
export const blobSaveAs = (arraybuffer:ArrayBuffer, type:string, fileName:string) => {
    let link = document.createElement("a");
    var fileURL = window.URL.createObjectURL(new Blob([arraybuffer], {type: type}))
    link.href= fileURL;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click(); 
    window.URL.revokeObjectURL(fileURL);
}
