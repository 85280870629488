import styled from "styled-components";
import MainBanner from "@/assets/images/priceTitleImg.png"
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";

export default function TitleContainer() {
    const commonStyle = {
        fontWeight: "700",
        fontSize: "40px",
        lineHeight: "60px",
        margin: "0px 10px 0px 0px"
    }

    return (
        <StyledTitleContainer>
            <StyledTitle>
            <MainBannerImg src={MainBanner} />
                <MainContext>
                    <TextBox>
                        <Text text="글링으로" customStyle={{...commonStyle, color: colors.white}}></Text>
                        <Text text="쉽고 편리한" customStyle={{...commonStyle, color: colors.blue200}}></Text>
                    </TextBox>
                    <TextBox>
                        <Text text="정산관리를" customStyle={{...commonStyle, color: colors.blue200}}></Text>
                        <Text text="시작하세요!" customStyle={{...commonStyle, color: colors.white}}></Text>
                    </TextBox>
                </MainContext>
            </StyledTitle>
        </StyledTitleContainer>
    )
}

const StyledTitleContainer = styled.div`
    width: 100%;
    height: 750px;
    padding: 30px calc((100% - 1200px) / 2);
`;

const StyledTitle = styled.div`
    position: relative;
    
`

const MainBannerImg = styled.img`
    position: absolute;
    left: -50px;
    top: 30px;
`

const MainContext = styled.div`
    position: absolute;
    width: 471px;
    heigth: 120px;
    top: 272px;
`;

const TextBox = styled.div`
    display: flex;
`