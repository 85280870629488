import styled from "styled-components"
import ReactHtmlParser from 'html-react-parser'
import { colors } from "@/assets/styles/theme";
import Text from "@/components/atoms/text";
import { useNavigate } from "react-router-dom";

export default function NoticeCard({ id, title, content }: {id: number, title: string, content: string}) {
    const navigate = useNavigate();
    
    const titleStyle = {
        fontWeight: "700",
        fontSize: "16px",
        color: colors.black
    }

    return (
        <StyledContainer onClick={() => navigate(`/support/notice/${id}`)}>
            <TitleWrapper>
                <Text text={title} customStyle={titleStyle}/>
            </TitleWrapper>
            <DescriptionWrapper>
                {ReactHtmlParser(content)}
            </DescriptionWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 284px;
    height: 300px;
    padding: 16.5px 18.5px;
    margin-right: 18px;
    border-radius: 4px;
    box-shadow: 0px 6px 12px 0px rgba(112, 144, 176, 0.2);
    cursor: pointer;
`;

const TitleWrapper = styled.div`
    word-break: break-all;
    margin-bottom: 16px;
`;

const DescriptionWrapper  = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.black};
    word-wrap: break-word;
    white-space: pre-line;
    flex: 1;
    line-height: 20px;
    overflow: hidden;
`;