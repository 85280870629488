import styled from "styled-components";
import Checkbox from "@/components/atoms/input/Checkbox";
import type { TableProps } from "@/components/molecules/common/table/DataTable";

/**
 * 데이터가 없을 때 보여지는 테이블 컴포넌트
 * @param headers, customHeaders
 * @returns Table
 */
 function EmptyDataTable({ headers, customHeaders = {} }: Pick<TableProps, "headers" | "customHeaders">) {
    return (
        <Table>
            <Header>
                <Tr>
                    <CheckboxTh>
                        <Checkbox active={false} onChangeHandler={() => {}} />
                    </CheckboxTh>
                    {headers.map((header) => (
                        <Th key={header.text} width={header.width}>
                            {customHeaders[header.value] ? customHeaders[header.value]() : header.text}
                        </Th>
                    ))}
                </Tr>
            </Header>
            <Body>
                <Tr>
                    <EmptyTd colSpan={headers.length + 1}>
                        <p>등록된 데이터가 없습니다.</p>
                    </EmptyTd>
                </Tr>
            </Body>
        </Table>
    );
}

const Table = styled.table`
    width: 100%;
    font-size: 12px;
`;
const Header = styled.thead`
    border-top: 1px solid ${({ theme }) => theme.colors.gray600};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
    height: 48px;
    Tr {
        border-bottom: none;
    }
`;
const Body = styled.tbody`
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
`;
const Tr = styled.tr<{ hasDetails?: boolean; selectedRow?: boolean }>`
    cursor: ${({ hasDetails }) => (hasDetails ? "pointer" : "default")};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
    :nth-last-child(1) {
        border-bottom: none;
    }
    background-color: ${({ selectedRow, theme }) => selectedRow && theme.colors.blue50};
`;
const Th = styled.th<{ width?: string; sort?: boolean }>`
    width: ${({ width }) => width || "auto"};
    height: 48px;
    padding: 14px 8px;
    text-align: left;
    vertical-align: middle;
    font-weight: 500;
    cursor: ${({ sort }) => (sort === false ? "default" : "pointer")};
`;
const CheckboxTh = styled(Th)<{ show?: boolean }>`
    display: ${({ show }) => (show ? "table-cell" : "none")};
    width: 1%;
    max-width: 50px;
    text-align: center;
`;
const Td = styled.td<{ width?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 60px;
    padding: 14px 8px;
    text-align: left;
    white-space: nowrap;
`;
const EmptyTd = styled(Td)`
    text-align: unset;
    font-size: 16px;
    height: 64px;
    vertical-align: middle;
`;

export default EmptyDataTable;