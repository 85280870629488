import React from "react";
import styled from 'styled-components'
import Text from "@/components/atoms/text";

export default function PortfolioFunction({tab, id, label, customStyle, icon, onIdChange}: FunctionProps) {
    const Icon = icon;
    const isActive = tab === id ? true : false;
    return (
        <StyledContainer isActive={isActive} onClick={e => onIdChange(e,id)}>
            <IconWrapper>
                <Icon />
            </IconWrapper>
            <Text text={label} customStyle={customStyle} />
        </StyledContainer>
    )
}

const StyledContainer = styled.div<{isActive: boolean}>`
    width: 300px;
    height: 100px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 0px 99px 99px 0px;
    cursor: pointer;
    background-color: ${({ isActive, theme }) => isActive ? theme.colors.blue100 : ""};
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background-color: ${({ theme }) => theme.colors.blue50};
    border-radius: 20px;
    margin-right: 16px;
`;


interface FunctionProps {
    tab: number,
    id: number,
    label: string,
    customStyle: {},
    icon: any,
    onIdChange: (e: React.MouseEvent<HTMLDivElement>, id: number) => void
}