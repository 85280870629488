import { useEffect, useState } from "react";
import styled from "styled-components";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import Button from "@/components/atoms/button";
import SelectBox from "@/components/atoms/selectBox/index";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import BatchDeleteDailyModal from "@/components/molecules/common/modal/BatchDeleteDailyModal";
import BatchDeleteMonthlyModal from "@/components/molecules/common/modal/BatchDeleteMonthlyModal";
import useAlert from "@/modules/hooks/useAlert";

export default function TableOptions({
    from,
    reset,
    changeItemPerPage,
    saveData,
    deleteData,
    downloadExcel,
}: TableOptionsProps) {
    const { selectedItems, updatedRows } = useSelectorTyped((state: RootState) => state.dataTable);
    const [itemPerPage, setItemPerPage] = useState(10); // 셀렉트박스 selected
    const [showModal, setShowModal] = useState({
        batchDaily: false,
        batchMonthly: false,
    });
    const { alert } = useAlert();

    useEffect(() => {
        setItemPerPage(10);
        changeItemPerPage(10);
    }, [reset]);

    const changeSelectBox = (value: any) => {
        setItemPerPage(value.value);
        changeItemPerPage(value.value);
    };
    const clickDelete = async () => {
        if (!selectedItems.length) {
            return await alert("warning", "주의", "대상을 선택해주세요.");
        }
        let answer = await alert("confirm", "주의", "삭제 하시겠습니까?");
        if (answer === "Y") deleteData();
    };

    const handleBatchDaily = () => setShowModal((prev) => ({ ...prev, batchDaily: !prev.batchDaily }));

    const handleBatchMonthly = () => setShowModal((prev) => ({ ...prev, batchMonthly: !prev.batchMonthly }));

    const clickBatchDelete = () => {
        if (from === "daily") return setShowModal({ batchDaily: true, batchMonthly: false });
        if (from === "monthly") return setShowModal({ batchDaily: false, batchMonthly: true });
    };

    const clcikDownloadExcel = () => downloadExcel();

    const clickSave = async () => {
        if (!updatedRows.length) {
            return await alert("info", "알림", "변경사항이 없습니다.");
        }
        saveData();
    };

    return (
        <TableOptionsContainer>
            <SelectBox
                labelKey="name"
                valueKey="value"
                options={[
                    { name: "10개씩 보기", value: 10 },
                    { name: "30개씩 보기", value: 30 },
                    { name: "50개씩 보기", value: 50 },
                    { name: "100개씩 보기", value: 100 },
                ]}
                selected={itemPerPage}
                onClickValueFunc={changeSelectBox}
                customStyle={selectBoxStyle}
            />
            <ButtonWrapper>
                <Button type="red-outline" label="삭제" onClickHandler={clickDelete} />
                {(from === "daily" || from === "monthly") && (
                    <Button
                        type="red-outline"
                        label="조건 삭제"
                        onClickHandler={clickBatchDelete}
                        customStyle={btnStyle}
                    />
                )}
                <Button
                    type="main-outline"
                    label="엑셀 다운로드"
                    onClickHandler={clcikDownloadExcel}
                    customStyle={downloadBtnStyle}
                />
                {from !== "daily" && ( 
                    <Button
                        type="main" 
                        label="저장" 
                        onClickHandler={clickSave} 
                        customStyle={btnStyle} 
                    />
                )}
            </ButtonWrapper>
            <ModalPortal>
                <BatchDeleteDailyModal from="daily" show={showModal.batchDaily} close={handleBatchDaily} />
            </ModalPortal>
            <ModalPortal>
                <BatchDeleteMonthlyModal from="monthly" show={showModal.batchMonthly} close={handleBatchMonthly} />
            </ModalPortal>
        </TableOptionsContainer>
    );
}

const selectBoxStyle = {
    fontSize: "12px",
    fontWeight: "400",
};
const btnStyle = {
    margin: "0 0 0 8px",
};
const downloadBtnStyle = {
    margin: "0 0 0 8px",
    width: "113px",
};

const TableOptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const ButtonWrapper = styled.div`
    margin-left: 8px;
`;

interface TableOptionsProps {
    from: "daily" | "monthly" | "mg" | "etc";
    reset: boolean;
    changeItemPerPage: (itemPerPage: number) => void;
    saveData: () => void;
    deleteData: () => void;
    downloadExcel: () => void;
}
