import styled from 'styled-components'
import Text from '@/components/atoms/text'
import { colors } from '@/assets/styles/theme';
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn'

export default function ReviewTitle() {
    const fadeInTitle = useScrollFadeIn('up', 1, 0);
    const commonStyle = {
        fontWeight: 700,
        fontSize: "36px",
        lineHeight: "45px"
    }
    
    const texts: Text[] = [
    {
        text: '글링을 사용해본 사람',
        style: Object.assign({color: colors.blue500}, commonStyle)
    },
    {
        text: '은 이렇게 말합니다',
        style: Object.assign({color: colors.black}, commonStyle) 
    }
]

    return (
        <StyledWrapper {...fadeInTitle}>
            {texts.map((textObj, idx)=> 
                <Text key={idx} text={textObj.text} customStyle={textObj.style}/>
            )}
        </StyledWrapper>
    )

}

const StyledWrapper = styled.div`
    display: flex;
    width: 596px;
`

interface Text {
    text: string,
    style: {}
}