import styled from "styled-components";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import useAlert from "@/modules/hooks/useAlert";
import { useEffect, useRef, useState } from "react";
import { colors } from "@/assets/styles/theme";
import { useAppDispatch } from "@/modules/store";

export default function PromotionDescModal({ show, close, from, promotionIdx, promotionDesc }: ComponentProps) {
    const { alert } = useAlert();
    const dispatch = useAppDispatch();
    const [value, setValue] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        } 
    });

    useEffect(() => {
        setValue(promotionDesc);
    }, [promotionDesc]);

    const apply = async () => {
        try {
            dispatch({ type: "pubPortfolio/setPromotionData", payload: {
                key: "promotion_desc",
                idx: promotionIdx,
                value
            }});
            close();
            reset();
        } catch (err) {
            alert("error", "오류 발생", "적용중 오류가 발생하였습니다.");
        }
    }

    const reset = () => {
        setValue("");
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["프로모션 설명"]}
            customStyle={[{ width: "480px", height: "340px" }]}
            applyFunc={apply}
            reset={reset}
        >
            <BodyWrapper>
                <TextArea
                    ref={inputRef}
                    value={value}
                    onChange={(e: any) => setValue(e.target.value)}
                />
            </BodyWrapper>
        </BaseModal>
    )
}


const BodyWrapper = styled.div`
    padding: 0 20px;
    width: 100%;
`

const TextArea = styled.textarea<{ref: any;}>`
    border: 1px solid ${colors.gray500};
    width: 100% !important;
    height: 220px;
    overflow-y: auto;
    resize: none;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
`

interface ComponentProps {
    show: boolean;
    close: () => void;
    from: string;
    promotionIdx: number;
    promotionDesc: string;
}
