import { removeComma } from "@/modules/utils/filter";

const useCalculateAmount = () => {
    const calculateAmount = (item: any) => {
        if (
            item.authorInfo.author_id === "" ||
            item.pre_amount === "" ||
            item.pre_amount === "-" ||
            item.st_ratio === ""
        ) {
            item.amount = 0;
            item.tax_amount = 0;
            return;
        }
        let preAmt = item.pre_amount
            .toString()
            .replace(/[^\d-]/g, "")
            .replace(/(?!^)-/g, "");
        let stRatio = Number(item.st_ratio) / 100;
        let tmpStAmt = Math.round(preAmt * stRatio);
        let taxTotal = 0;

        if (preAmt < 0) {
            item.tax_amount = 0;
        } else {
            if (item.tax_type === "I") {
                // 원천세(작가별 설정에 따름)일 경우
                for (let i of item.author_tax_info) {
                    if (i.tax_type === "D") {
                        let incomeTax = Math.floor((((tmpStAmt * i.co_st_ratio) / 100) * 0.03) / 10) * 10;
                        let localTax = Math.floor(incomeTax / 100) * 10;
                        taxTotal += incomeTax < 1000 ? 0 : incomeTax + localTax;
                        i.tax_amount = incomeTax < 1000 ? 0 : incomeTax + localTax;
                    } else {
                        let tax = Math.floor((((tmpStAmt * i.co_st_ratio) / 100) * i.tax_ratio) / 100 / 10) * 10;
                        taxTotal += tax < 1000 ? 0 : tax;
                        i.tax_amount = tax < 1000 ? 0 : tax;
                    }
                }
                item.tax_amount = taxTotal.toLocaleString();
            } else if (item.isCoAuthor && item.tax_type === "E") {
                // 공저이면서, 적용세목 기타인 경우 =>
                for (let i of item.author_tax_info) {
                    taxTotal += Number(removeComma(i.tax_amount));
                }
            } else {
                taxTotal = item.tax_amount === "" ? 0 : Number(removeComma(item.tax_amount));
                if (!item.isCoAuthor && item.tax_type === "E") {
                    // 공저아니고, 적용세목 기타일 경우
                    item.author_tax_info[0].tax_amount = item.tax_amount;
                }
            }
        }
        item.amount = (tmpStAmt - taxTotal).toLocaleString();
    };

    return { calculateAmount };
};

export default useCalculateAmount;
