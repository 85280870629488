import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import Checkbox from "@/components/atoms/input/Checkbox";
import SelectBox from "@/components/atoms/selectBox/index";
import { CommonType } from "@/types/dataType";
import CustomDatePicker from "../../date-picker/CustomDatePicker";
import { BatchEtcResult, Checked } from "../BatchEtcModal";

export default function BatchEtcModalBody({
    platformList,
    batchEtcData,
    checked,
    setChecked,
    setBatchEtcData,
}: ModalBodyProps) {
    const changePlatform = (value: any) => {
        setBatchEtcData((prev) => ({ ...prev, platformId: value.platform_id }));
    };

    const changeInDate = (date: string) => {
        setBatchEtcData((prev) => ({ ...prev, inDate: date }));
    };

    const changeOutDate = (date: string) => {
        setBatchEtcData((prev) => ({ ...prev, outDate: date }));
    };

    return (
        <>
            <ModalBodyContainer>
                {/* 플랫폼 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            active={checked.platform}
                            onChangeHandler={() => setChecked((prev) => ({ ...prev, platform: !prev.platform }))}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>플랫폼</LabelWrapper>
                    <ContentWrapper>
                        <SelectBox
                            empty
                            disabled={!checked.platform}
                            labelKey="platform_name"
                            valueKey="platform_id"
                            options={platformList}
                            selected={batchEtcData.platformId}
                            onClickValueFunc={changePlatform}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 입금일자 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            active={checked.inDate}
                            onChangeHandler={() => setChecked((prev) => ({ ...prev, inDate: !prev.inDate }))}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>입금일자</LabelWrapper>
                    <ContentWrapper>
                        <CustomDatePicker disabled={!checked.inDate} onChange={changeInDate} />
                    </ContentWrapper>
                </ItemRow>
                {/* 지급일자 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            active={checked.outDate}
                            onChangeHandler={() => setChecked((prev) => ({ ...prev, outDate: !prev.outDate }))}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>지급일자</LabelWrapper>
                    <ContentWrapper>
                        <CustomDatePicker disabled={!checked.outDate} onChange={changeOutDate} />
                    </ContentWrapper>
                </ItemRow>
            </ModalBodyContainer>
        </>
    );
}
const ModalBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    height: 180px;
    overflow: auto;
`;
const ItemRow = styled.div<{ border?: boolean; active?: boolean }>`
    display: flex;
    align-items: center;
    height: 60px;
    border-top: ${({ theme, border }) => (border ? `1px solid ${theme.colors.gray300}` : "")};
    border-bottom: ${({ theme, border }) => (border ? `1px solid ${theme.colors.gray300}` : "")};
    background-color: ${({ theme, active }) => (active ? theme.colors.gray50 : "")};
`;
const BaseWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    width: 60px;
    height: 60px;
`;
const CheckboxWrapper = styled(BaseWrapper)`
    justify-content: center;
`;

const LabelWrapper = styled(BaseWrapper)`
    align-items: center;
    margin-right: 8px;
    min-width: 60px;
`;

const ContentWrapper = styled(BaseWrapper)`
    width: 320px;
    margin-left: 8px;

    .series-searchBar {
        width: auto;
        input {
            width: 180px;
            padding: 8px 16px 8px 8px;
        }
    }
    .search-field {
        width: 180px;
    }
`;

interface ModalBodyProps {
    platformList: {
        platform_id: CommonType.Id;
        platform_name: CommonType.Platform.Name;
    }[];
    batchEtcData: BatchEtcResult;
    checked: Checked;
    setChecked: Dispatch<SetStateAction<Checked>>;
    setBatchEtcData: Dispatch<SetStateAction<BatchEtcResult>>;
}
