import styled from "styled-components";
import Text from "@/components/atoms/text";
import { EbookMaker } from "@/types/dataType";
import SubtitleTypeCard from "./SubtitleTypeCard";

export default function SubtitleTypeContainer() {
    const typeList = [
        { type: EbookMaker.SubtitleType.INPAGE, label: "원고파일 내 포함(별도페이지)", desc: "이미 원고파일에 소제목이 들어가 있으며, 별도 페이지 (쪽나눔, Ctrl + Enter)로 되어있습니다." },
        { type: EbookMaker.SubtitleType.INBODY, label: "원고파일 내 포함(본문에 포함)", desc: "이미 원고파일에 소제목이 들어가 있으며, 본문 위에 포함되어 있습니다." },
        { type: EbookMaker.SubtitleType.MAKE, label: "소제목 생성", desc: "소제목을 직접 입력하고 생성합니다." },
        { type: EbookMaker.SubtitleType.NONE, label: "소제목 없음", desc: "" },
    ]

    return (
        <StyledContainer>
            <Text text="소제목 포함 및 생성 여부" customStyle={{ fontSize: "16px", fontWeight: "700", padding: "20px 0"}} />
            <CardContainer>
                {typeList.map((t, idx) => (
                    <SubtitleTypeCard 
                        key={idx} 
                        type={t.type}
                        label={t.label}
                        desc={t.desc}
                    />
                ))}
            </CardContainer>
        </StyledContainer>
    )
}


const StyledContainer = styled.div``

const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    height: 200px;
`
