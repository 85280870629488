import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from "@/api";

export interface NoticeData {
    id: number;
    title: string;
    content: string;
    publisher_id: string;
    publisher_name: string;
}

export interface NoticeDetailData {
    category: string;
    content: string;
    id: number;
    title: string;
    is_mine ?: boolean;
    publisher_name: string;
}

export interface authPublist {
    publisher_id: number,
    publisher_name: string,
}

export interface myPubInfo {
    publisher_name: string
}

export interface NoticeState {
    data: Array<NoticeData>;
    loading: boolean;
    noticeDetail: NoticeDetailData;
    auth?: string | null;
    isPubMaster: boolean;
    authPublists: Array<authPublist>;
    filteredData: Array<NoticeData>; 
    myPubInfo: myPubInfo;
}

export interface GetDataParams {
    categoryParams: string
}

export interface myPubInfoParams {
    withCredentials: boolean,
    credentials: string
}

export interface NoticeWriteParams {
    title?: string,
    content?: string,
    category: string,
    publisher_id: number,
    id?: number | string,
    url?: string,
    class: string
}

export interface NoticeRemoveParams {
    id?: number,
    class: string
}

export interface NoticeDetailParams {
    noticeId: number,
    isPublisher: boolean,
    target_class: string,
    target_cd: string,
    target_id: number,
    log_activity: string
}

export interface dataFormat {
    status: string,
    data: NoticeData[],
    message: string
}

export interface NoticePayload {
    data: dataFormat,
    category: string,
}

export const getNoticeData = createAsyncThunk("notice/getNoticeData", async (_, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/notice/list/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const getAuthPubList = createAsyncThunk("notice/getAuthPubList", async (_, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/settlement/publisher/list`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const checkPubMaster = createAsyncThunk("notice/isPubMaster", async (_, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/pubMaster/check`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const getMyPubInfo = createAsyncThunk("notice/getMyPubInfo", async (apiParams: myPubInfoParams, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/notice/myPubName/select`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const writeNotice = createAsyncThunk("notice/write", async (apiParams: NoticeWriteParams, { rejectWithValue }) => {
    try {
        if (apiParams.url === "support/board/insert") {
            const {url, ...updatedApiParams} = apiParams;
            updatedApiParams.id = undefined;
            return (await api.post(`/api/support/board/insert`, updatedApiParams)).data;
        } else {
            return (await api.post(`/api/support/board/update`, apiParams)).data;
        }
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const removeNotice = createAsyncThunk("notice/remove", async (apiParams: NoticeRemoveParams, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/board/remove`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const getNoticeDetail = createAsyncThunk("noticedetail/select", async (apiParams: NoticeDetailParams, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/noticeDetail/select`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})


const initialState: NoticeState = {
    data: [],
    loading: false,
    noticeDetail: {
        category: "",
        content: "",
        id: 0,
        title: "",
        is_mine: false,
        publisher_name: "",
    },
    auth: "",
    isPubMaster: false,
    authPublists: [],
    filteredData: [],
    myPubInfo: {publisher_name: ''},
}

export const noticeSlice = createSlice({
    name: 'notice',
    initialState,
    reducers: {
        reset() {
            return {...initialState};
        },
        setNoticeDetail(state, action) {
            state.noticeDetail = action.payload;
        },
        cleanAuth(state) {
            state.auth = '';
        },
        
    },
    extraReducers: (builder) => {
        builder.addCase(getNoticeData.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getNoticeData.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                state.data = action.payload.data;
                state.filteredData = action.payload.data;
            }
            state.loading = false;
        });
        builder.addCase(getAuthPubList.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                state.authPublists = action.payload.data;
            } 
        });
        builder.addCase(checkPubMaster.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                state.isPubMaster = action.payload.data;
            }
        });
        builder.addCase(getMyPubInfo.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                state.myPubInfo = action.payload.data;
            }
        });
        builder.addCase(getNoticeDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                state.auth = null;
            } else {
                state.noticeDetail = action.payload.data[0];
            }
        })
    }
})

export const noticeActions = noticeSlice.actions;

export default noticeSlice.reducer;
