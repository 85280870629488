import api from "@/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CommonType } from "@/types/dataType";

const name = "salesEntry/mg";
const initialSeriesItem = {
    id: 0,
    series_id: 0,
    series_name: "",
    seriesError: "시리즈명을 입력해주세요.",
};
const initialState: State = {
    lastId: 0,
    contractStartDate: "",
    contractType: "MG01",
    mgType: "platform",
    platformId: 0,
    mgRatio: "",
    remark: "",
    seriesList: [initialSeriesItem],
    authorInfo: {
        author_id: 0,
        author_name: "",
        co_info: [],
    },
    contractPrice: "",
    preDeduction: "",
    authorError: "",
    contractPriceError: "",
    mgRatioError: "",
    // 임시 state
    reset: false,
};

// 선인세 등록
export const enrollMgData = createAsyncThunk(
    `${name}/enrollMgData`,
    async (apiParams: ApiParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/salesEntry/royalty/insert`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const mgSlice = createSlice({
    name,
    initialState,
    reducers: {
        setEnrollData: (state, action) => {
            const {
                contractStartDate,
                mgType,
                platformId,
                contractType,
                authorInfo,
                contractPrice,
                mgRatio,
                preDeduction,
                remark,
            } = action.payload;
            if (contractStartDate !== undefined) state.contractStartDate = contractStartDate;
            if (mgType !== undefined) state.mgType = mgType;
            if (platformId !== undefined) state.platformId = platformId;
            if (contractType !== undefined) state.contractType = contractType;
            if (authorInfo !== undefined) state.authorInfo = authorInfo;
            if (contractPrice !== undefined) state.contractPrice = contractPrice;
            if (mgRatio !== undefined) state.mgRatio = mgRatio;
            if (preDeduction !== undefined) state.preDeduction = preDeduction;
            if (remark !== undefined) state.remark = remark;
        },
        setMgError: (state, action) => {
            const { authorError, contractPriceError, mgRatioError } = action.payload;

            if (authorError !== undefined) state.authorError = authorError;
            if (contractPriceError !== undefined) state.contractPriceError = contractPriceError;
            if (mgRatioError !== undefined) state.mgRatioError = mgRatioError;
        },
        setLastId: (state, action) => {
            state.lastId = action.payload;
        },
        setSeriesList: (state, action) => {
            state.seriesList = action.payload;
        },
        resetStore: () => {
            return { ...initialState };
        },
        // 임시
        setReset: (state, action) => {
            state.reset = action.payload;
        },
    },
    extraReducers: (builder) => {},
});

// interface MgData {
//     contractStartDate?: CommonType.Date; // 계약시작일
//     mgType?: CommonType.Mg.Type; // 선인세 구분
//     platformId?: CommonType.Id; // 판매플랫폼
//     contractType?: CommonType.Mg.ContractType; // 계약형태
//     authorInfo?: {
//         // 저자정보
//         author_id: CommonType.Id;
//         author_name: CommonType.Author.Name;
//         co_info: any[];
//     };
//     contractPrice?: CommonType.Amount; // 계약금액
//     mgRatio?: CommonType.Ratio; // 정산비율
//     preDeduction?: CommonType.Amount; // 초기 차감금액
//     remark?: CommonType.Mg.Remark; // 비고
// }

interface State {
    lastId: number;
    contractStartDate: CommonType.Date;
    contractType: CommonType.Mg.ContractType;
    mgType: CommonType.Mg.Type; // 선인세 구분
    platformId: CommonType.Id; // 판매플랫폼
    contractPrice: CommonType.Amount | string; // 계약금액
    mgRatio: CommonType.Ratio | string;
    preDeduction: CommonType.Amount | string; // 초기 차감금액
    remark: CommonType.Mg.Remark;
    seriesList: {
        id: CommonType.Id;
        series_id: CommonType.Id;
        series_name: CommonType.Series.Name;
        seriesError: string;
    }[];
    // 저자정보
    authorInfo: {
        author_id: CommonType.Id;
        author_name: CommonType.Author.Name;
        co_info: any[];
    };
    authorError: string;
    contractPriceError: string;
    mgRatioError: string;

    reset: boolean;
}

interface ApiParams {
    param: Param;
    co_info: State["authorInfo"]["co_info"];
    seriesLists: State["seriesList"];
    preDeduction: State["preDeduction"];
}

interface Param {
    date: CommonType.Date;
    contract_type: CommonType.Mg.ContractType;
    mg_type: CommonType.Mg.Type;
    platform_id: CommonType.Id;
    author_id: CommonType.Id;
    amount: CommonType.Amount;
    mg_ratio: CommonType.Ratio;
    remark: CommonType.Mg.Remark;
}

export const { setEnrollData, setMgError, setLastId, setSeriesList, resetStore, setReset } = mgSlice.actions;
export default mgSlice.reducer;
