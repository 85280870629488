import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import Text from "@/components/atoms/text";
import { useEffect } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import useCheckToken from "@/modules/hooks/useCheckToken";
import OptionContainer from "@/components/molecules/statement/author/OptionContainer";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { getMonthList, getPublisherList } from "./slice";
import styled from "styled-components";
import PdfViewer from "@/components/molecules/statement/author/PdfViewer";
import PublisherListContainer from "@/components/molecules/statement/author/PublisherListContainer";

export default function AuthorStatement() {
    const { specificationType } = useAppSelector((state: RootState) => state.authorStatement);
    const dispatch = useAppDispatch();
    const tokenStatus = useCheckToken();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    const dateChangeHandler = async () => {
        try {
            loadingOn();
            await dispatch(getPublisherList()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const getInitialData = async () => {
        try {
            loadingOn();
            await dispatch(getMonthList()).unwrap();
            await dispatch(getPublisherList()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    useEffect(() => {
        getInitialData();

        return () => {
            dispatch({ type: "authorStatement/initState" });
        }
    }, [])

    useEffect(() => {
        dispatch({ type: "authorStatement/setEmptyData"});
        getInitialData();
    }, [specificationType])

    if (!tokenStatus) {
        return <></>
    };
    
    return (
        <SectionLayout>
            <Text text="정산서" customStyle={{ fontSize: "20px", fontWeight: "700" }} />
            <OptionContainer dateChangeHandler={dateChangeHandler} />
            <DisplayBox>
                <PublisherListContainer />
                <RightBox>
                    <PdfViewer />
                </RightBox>
            </DisplayBox>
        </SectionLayout>
    );
}

const DisplayBox = styled.div`
    display: flex;
`

const RightBox = styled.div`
    width: 960px;
    margin-top: 24px;
`