import styled from 'styled-components'
import SectionLayout from '@/components/molecules/introduce/SectionLayout'
import ReviewTitle from "@/components/molecules/introduce/review/ReviewTitle"
import ReviewCards from '@/components/molecules/introduce/review/ReviewCards'

export default function ReviewContainer() {
    const dashImage = <StyledImg src={require('@/assets/images/introduce-image/dashboard-mobile.png')}/>
    return (
        <SectionLayout>
            <ReviewTitle/>
            <FlexBox>
                <ImageWrapper >
                    {dashImage}
                </ImageWrapper>
                <ReviewCards/>
            </FlexBox>
        </SectionLayout>
    )
}

const FlexBox = styled.div`
    display: flex;
    width: 1200px;
    height: 600px;
    margin-top: 86px;
`

const ImageWrapper = styled.div`
    padding: 50px 80px 50px 0px;
`

const StyledImg = styled.img`
    width: 218.93px;
    height: 483.94px;
`
