import { useState } from 'react'
import styled from 'styled-components'
import SectionLayout from '@/components/molecules/introduce/SectionLayout'
import SettlementTitle from '@/components/molecules/introduce/settlement/SettlementTitle'
import SettlementFunctionContainer from '@/components/molecules/introduce/settlement/SettlementFunctionContainer'
import SettlementImageContainer from '@/components/molecules/introduce/settlement/SettlementImageContainer'
import UnderlineText from '@/components/molecules/introduce/UnderlineText'
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn'

export default function SettlementContainer() {
    const [tab, setTab] = useState<number>(0);
    const fadeInText = useScrollFadeIn('up', 3, 0, 0.3);
    
    const idChangeHandler = (e:React.MouseEvent<HTMLDivElement>, id:number) => {
        setTab(()=>id);
    }

    const toPricingHandler = () => { window.location.href = '/pricing' }


    return (
        <SectionLayout>
            <SettlementTitle/>
            <FlexBox>
                <SettlementFunctionContainer
                    tab={tab}
                    onIdChange= {idChangeHandler}/>
                <SettlementImageContainer
                    tab={tab}/>
            </FlexBox>
            <StyledDetailWrapper {...fadeInText}>
                <UnderlineText label="정산관리 기능 자세히 보기" onClickHandler={toPricingHandler} />
            </StyledDetailWrapper>
        </SectionLayout>
    )    
}

const FlexBox = styled.div`
    display: flex;
`

const StyledDetailWrapper = styled.div`
    margin-top: 60px;
    cursor: pointer;
`
