import { useState, useEffect, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "@/modules/store";
import { getSalesPlatformList, salesPlatformActions } from "@/pages/common/platformSlice";
import { setIsAuto, setPlatform, setSalesDate, setSalesYearmon } from "@/pages/uploader/uploaderSlice";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import Text from "@/components/atoms/text/index";
import TypeNavBar from "@/components/molecules/common/typeNavBar/TypeNavBar";
import Daily from "@/components/templates/sales-entry/Daily";
import Monthly from "@/components/templates/sales-entry/Monthly";
import Mg from "@/components/templates/sales-entry/Mg";
import Etc from "@/components/templates/sales-entry/Etc";

export default function SalesEntry() {
    const [searchParams] = useSearchParams();
    const [template, setTemplate] = useState(<Daily />);
    const dispatch = useAppDispatch();
    const { resetStore } = salesPlatformActions;

    useEffect(() => {
        switch (searchParams.get("type")) {
            case "Daily":
                setTemplate(<Daily />);
                break;
            case "Monthly":
                setTemplate(<Monthly />);
                break;
            case "Mg":
                setTemplate(<Mg />);
                break;
            case "Etc":
                setTemplate(<Etc />);
                break;
            default:
                break;
        }
    }, [searchParams]);

    // 데이터 패치
    useLayoutEffect(() => {
        dispatch(getSalesPlatformList());
    }, []);

    useEffect(() => {
        // 자동 업로더
        const isAuto = searchParams.get("isAuto");
        const platform = searchParams.get("platform");
        const salesDate = searchParams.get("salesDate");
        const salesYearmon = searchParams.get("salesYearmon");

        if (isAuto) isAuto.toLowerCase() === "y" ? dispatch(setIsAuto(true)) : dispatch(setIsAuto(false));
        if (platform) dispatch(setPlatform(platform));
        if (salesDate) dispatch(setSalesDate(salesDate));
        if (salesYearmon) dispatch(setSalesYearmon(salesYearmon));

        return () => {
            dispatch(resetStore());
        };
    }, []);

    return (
        <SectionLayout>
            <Text text="판매 입력" customStyle={textStyle} />
            <TypeNavBar
                menus={["일별 판매 현황", "월 정산", "선인세", "기타 지급금"]}
                types={["Daily", "Monthly", "Mg", "Etc"]}
            />
            {template}
        </SectionLayout>
    );
}

const textStyle = {
    fontSize: "20px",
    fontWeight: "700",
    margin: "0 0 20px 0",
};
