import { useEffect } from "react";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getAuthorMgData, setNoData } from "@/pages/settlement/saels-inquiry/author/mg/mgSlice";

// components
import TemplateLayout from "@/components/molecules/sales-inquiry/common/TemplateLayout";
import MgSearchFilter from "@/components/molecules/sales-inquiry/author/mg/MgSearchFilter";
import MgTableTop from "@/components/molecules/sales-inquiry/author/mg/MgTableTop";
import MgTable from "@/components/molecules/sales-inquiry/author/mg/MgTable";

// custom hooks
import useAlert from "@/modules/hooks/useAlert";
import useMgApi from "./hooks/useMgApi";
import useLoading from "@/modules/hooks/useLoading";

export default function Mg() {
    const { selectedPlatform, selectedPublisher, initialDataLoaded, changedParamsId } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorMg);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    useMgApi();

    useEffect(() => {
        getMgData();
    }, [changedParamsId]);

    const getMgData = async () => {
        try {
            if (initialDataLoaded && (!selectedPlatform.length || !selectedPublisher.length)) {
                return dispatch(setNoData());
            }

            loadingOn();
            await dispatch(getAuthorMgData()).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    return (
        <TemplateLayout>
            <MgSearchFilter />
            <MgTableTop />
            <MgTable />
        </TemplateLayout>
    );
}
