import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";

// components
import Text from "@/components/atoms/text";

// reducer
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";

// utils
import { round } from "@/modules/utils/filter";

export default function DepositTable() {
    const [sum, setSum] = useState({
        sales: 0,
        st_sales: 0,
        mg_deduction: 0,
        mg_add: 0,
        etc: 0,
        total: 0,
    });
    let { depositItems, selectedPlatform } = useSelectorTyped((state: RootState) => state.totalSales);

    useEffect(() => {
        let tmpSum = {
            sales: 0,
            st_sales: 0,
            mg_deduction: 0,
            mg_add: 0,
            etc: 0,
            total: 0,
        }

        for (let i of depositItems.filter(i => selectedPlatform.includes(i.platform_id))) {
            tmpSum.sales += i.sales ? i.sales : 0;
            tmpSum.st_sales += i.st_sales ? i.st_sales : 0;
            tmpSum.mg_deduction += i.mg_deduction ? i.mg_deduction : 0;
            tmpSum.mg_add += i.mg_add ? i.mg_add : 0;
            tmpSum.etc += i.etc ? i.etc : 0;
            tmpSum.total += i.total ? i.total : 0;
        }
        setSum(tmpSum);
    }, [selectedPlatform, depositItems])

    const comma = (num:number|null|undefined) => {
        if (num === null || typeof num === "undefined") {
            return String(0)
        } else {
            return round(num).toLocaleString();
        }
    }

    return (
        <TableContainer>
            <StyledTheader>
                <StyledTr>
                    {headers.map((h, i) => (
                        <StyledTh
                            key={i}
                            width={h.customStyle?.width}
                            textAlign={i === 0 ? "flex-start" : "flex-end"}
                        >
                            {h.text}
                        </StyledTh>
                    ))}
                </StyledTr>
            </StyledTheader>
            <TBody>
                <Tr backGroundcolor={true}>
                    <Td width="180px">
                        <Text text="총 합계" customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px" }} />
                    </Td>
                    <Td justifyContent="flex-end">
                        <Text text={comma(sum.sales)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px" }} />
                    </Td>
                    <Td justifyContent="flex-end">
                        <Text text={comma(sum.st_sales)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px" }} />
                    </Td>
                    <Td justifyContent="flex-end">
                        <Text text={comma(sum.mg_deduction)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.error }} />
                    </Td>
                    <Td justifyContent="flex-end">
                        <Text text={comma(sum.mg_add)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px" }} />
                    </Td>
                    <Td justifyContent="flex-end">
                        <Text text={comma(sum.etc)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px" }} />
                    </Td>
                    <Td justifyContent="flex-end">
                        <Text text={comma(sum.total)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.blue500 }} />
                    </Td>
                </Tr>
                {depositItems &&
                depositItems.filter(i => selectedPlatform.includes(i.platform_id)).map((d, i) => (
                    <Tr key={i}>
                        <Td width="180px">
                            <Text text={d.platform_name} customStyle={{ fontSize: "12px", lineHeight: "12px" }}/>
                        </Td>
                        <Td justifyContent="flex-end">
                            <Text text={comma(d.sales)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px" }}/>
                        </Td>
                        <Td justifyContent="flex-end">
                            <Text text={comma(d.st_sales)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px" }}/>
                        </Td>
                        <Td justifyContent="flex-end">
                            <Text text={comma(d.mg_deduction)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", color: colors.error }}/>
                        </Td>
                        <Td justifyContent="flex-end">
                            <Text text={comma(d.mg_add)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px" }}/>
                        </Td>
                        <Td justifyContent="flex-end">
                            <Text text={comma(d.etc)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px" }}/>
                        </Td>
                        <Td justifyContent="flex-end">
                            <Text text={comma(d.total)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.blue500 }}/>
                        </Td>
                    </Tr>
                ))
                }
            </TBody>
        </TableContainer>
    )
}

const headers = [
    { text: '판매플랫폼', value: 'platform_name', customStyle: { width: "180px" } },
    { text: '도서판매금(원)', value: 'sales', customStyle: { width: "170px" } },
    { text: '출판사정산금(원)', value: 'st_sales', customStyle: { width: "170px" } },
    { text: '선인세차감(원)', value: 'mg_deduction', customStyle: { width: "170px" } },
    { text: '선인세추가(원)', value: 'mg_add', customStyle: { width: "170px" } },
    { text: '기타지급금(원)', value: 'etc', customStyle: { width: "170px" } },
    { text: '수익총계(원)', value: 'total', customStyle: { width: "170px" } },
]

const TableContainer = styled.table`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    table-layout: fixed;
`;

const StyledTheader = styled.thead`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray600};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
`;

const StyledTr = styled.tr`
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
`;

const StyledTh = styled.th<StyleProps>`
    display: flex;
    align-items: center;
    justify-content: ${({ textAlign }) => textAlign ? textAlign : "flex-start"};
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "100%")};
    padding: ${({ padding }) => (padding ? padding : "8px")};
    margin: ${({ margin }) => (margin ? margin : "")};
`;

const TBody = styled.tbody`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Tr = styled.tr<{ backGroundcolor?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    background-color: ${({ theme, backGroundcolor }) => backGroundcolor ? theme.colors.blue50 : theme.colors.white };
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
`;

const Td = styled.td<TdStyleProps>`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-first"};
    align-items: center;
    height: 100%;
    width: ${({ width }) => width ? width : "170px"};
    padding: ${({ padding }) => padding ? padding : "8px"};
`;

export interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    textAlign?: string;
}

interface TdStyleProps {
    justifyContent?: string;
    width?: string;
    padding?: string;
}