import styled from "styled-components";
import { ChangeEvent, useEffect, useState } from "react";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as VisabilityIcon } from "@/assets/icons/login/visability.svg";
import { ReactComponent as VisabilityOffIcon } from "@/assets/icons/login/visability_off.svg";

export default function PwWrap({
    errorMsg,
    labelName,
    inputPlaceholder,
    inputID = "test",
    value,
    onChange,
    onKeyPress,
}: {
    errorMsg: string;
    labelName: string;
    inputPlaceholder: string;
    inputID: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyPress: Function;
}) {
    useEffect(() => {}, [value]);
    const [secure, setSecure] = useState(true);
    function iconClick() {
        setSecure(!secure);
    }

    function keyPress(e: any) {
        if (e.keyCode === 229) return;
        if (e.key === "Enter") {
            onKeyPress("Enter");
        }
    }

    return (
        <InputBox>
            <label htmlFor={inputID} style={{ color: errorMsg ? colors.error : value ? colors.blue500 : colors.gray600 }}>{labelName}</label>
                <ValidateWrap data-error={errorMsg}>
                    <StyledSearchBarWrapper>
                    <IconInput
                        type={secure ? "password" : "text"}
                        placeholder={inputPlaceholder}
                        value={value}
                        onChange={onChange}
                        color={errorMsg ? colors.error : value ? colors.blue500 : colors.gray300}
                        onKeyPress={(e) => keyPress(e)}
                    />
                    <IconWrapper>
                        {
                            secure ?
                            <VisabilityOffIcon width="24" height="24" fill={errorMsg ? colors.error : value ? colors.blue500 : colors.gray600} onClick={iconClick} />
                            :
                            <VisabilityIcon width="24" height="24" fill={errorMsg ? colors.error : value ? colors.blue500 : colors.gray600} onClick={iconClick} />
                        }
                    </IconWrapper>
                </StyledSearchBarWrapper>
                </ValidateWrap>
        </InputBox>
    );
}

const ValidateWrap = styled.div`
    position: relative;
    margin-bottom: 24px;
    :after {
        font-size: 10px;
        color: ${({ theme }) => theme.colors.error};
        position: absolute;
        left: 0px;
        bottom: -14px;
        white-space: nowrap;
        content: attr(data-error);
    }
`

const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    label {
        font-size: 14px;
        font-weight: 500;
    }
    input {
        margin-top: 10px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 12px;
        padding: 8px;
    }
`;

const IconInput = styled.input<{ color?: string }>`
    width: 400px;
    height: 40px;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-weight: 500;
    border: ${({ theme, color }) => `1px solid ${color ? color : theme.colors.gray300}`};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.black};
`

const StyledSearchBarWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 400px;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 17px;
    right: 8px;
    cursor: pointer;
`;