import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SignUpState, PublisherInfo, Address } from "./types"
import api from "@/api";
import sha256 from "crypto-js/sha256";
import moment from "moment"

const name = "signup";

const initialState: SignUpState = {
    loading: false,
    error: false,

    data: {
        id: "",
        pw: "",
        checkPw: "",
    },
    check: {
        image: "",
        nickName: "",
        mailing: "",
        email: false,
        wete: true,
        wepr: true
    },
    companyRegNo: "",
    publisherInfo: {
        publisher_id: 0,
        publisher_name: "",
        publisher_reg: "",
        publisher_email: "",
        publisher_ceo_name: "",
        publisher_tel: "",
        publisher_url: "",
        publisher_zonecode: "",
        publisher_addr: "",
        publisher_addr_detail: "",
        publisher_desc: "",
        publisher_pic_path: "",
    },
    publisherJoin: undefined,
    step: 0,

    authDone: null,
    kcp: {},
    fromSocial: false,
    socialData: {
        provider: "",
        id: "",
        email: ""
    }
};

// 아이디 중복 확인
export const checkId = createAsyncThunk(`${name}/checkId`, async (email:string, { rejectWithValue }) => {
    try {
        return (await api.post('/api/user/emailValidate/select', {
            user_email: email
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 중복 가입 확인
export const findEmail = createAsyncThunk(`${name}/findEmail`, async (apiParams: any, { rejectWithValue }) => {
    try {
        return (await api.post('/api/user/findEmail/select', apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 사업자등록번호로 출판사 조회
export const regCheck = createAsyncThunk(`${name}/regCheck`, async (apiParams: { regNo:string }, { rejectWithValue }) => {
    try {
        return (await api.post('/api/publisher/profile/reg/select', apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 일반회원 가입
export const userInsert = createAsyncThunk(`${name}/userInsert`, async (apiParams: any, { rejectWithValue }) => {
    try {
        // let apiParams = {
        //     ...this.kcpParam,
        //     user_email: this.loginId,
        //     user_pw: this.loginPw,
        //     user_nickname: this.user_nickname,
        //     mailing_address: this.mailing_address,
        //     user_emailagree: this.check.email,
        //     sociallogin_provider: this.snsUser?.provider,
        //     sociallogin_id: this.snsUser?.id,
        //     profile_img: this.profileImg ? await this.$base64Encode(this.profileImg) : ""
        // };
        return (await api.post(`/api/user/userInfo/insert`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

const signupSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        reset() {
            return {...initialState};
        },
        setPublisherJoin(state, action: PayloadAction<boolean>) {
            state.publisherJoin = action.payload;
        },
        updateUser(state, action: PayloadAction<{ id: string, pw: string, checkPw: string }>) {
            state.data = {
                id: action.payload.id,
                pw: action.payload.pw,
                checkPw: action.payload.checkPw
            };
        },
        updateMailing(state, action: PayloadAction<string>) {
            state.check = {...state.check, "mailing": action.payload};
        },
        updateCheck(state, action: PayloadAction<{ image: string|File, nickName: string, mailing: string, email: boolean, wete: boolean, wepr: boolean }>) {
            state.check = {
                image: action.payload.image,
                nickName: action.payload.nickName,
                mailing: action.payload.mailing,
                email: action.payload.email,
                wete: action.payload.wete,
                wepr: action.payload.wepr,
            };
        },
        updateCompanyRegNo(state, action: PayloadAction<string>) {
            state.companyRegNo = action.payload;
        },
        updatePublisherInfo(state, action: PayloadAction<PublisherInfo>) {
            state.publisherInfo = action.payload;
        },
        changeStep(state, action: PayloadAction<number>) {
            state.step = action.payload;
        },
        clearInputData(state) {
            // state.data.inputID = "";
            // state.data.inputPW = "";
        },
        updateAddress(state, action: PayloadAction<Address>) {
            state.publisherInfo = {...state.publisherInfo,
                                   "publisher_addr": action.payload.address,
                                   "publisher_zonecode": action.payload.zonecode}
        },
        setKcp(state, action: PayloadAction<any>) {
            state.kcp = action.payload;
        },
        setSocialData(state, { payload }) {
            const { provider, id, email } = payload;
            state.fromSocial = true;
            state.socialData = {
                provider,
                id,
                email
            }
            state.data = {
                id: email,
                pw: `*${sha256(moment().format("YYYYMMDDHHmmss")).toString().substring(0,40)}`,
                checkPw: `*${sha256(moment().format("YYYYMMDDHHmmss")).toString().substring(0,40)}`,
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(checkId.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(checkId.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
            } else {
                // state.error = action.payload.message;
            }
            state.loading = false;
        });
        builder.addCase(checkId.rejected, (state, action) => {
            console.error("오류 발생 :", action.error);
            state.loading = false;
        });
        builder.addCase(findEmail.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(findEmail.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
            } else {
                // state.error = action.payload.message;
            }
            state.loading = false;
        });
        builder.addCase(findEmail.rejected, (state, action) => {
            console.error("오류 발생 :", action.error);
            state.loading = false;
        });
        builder.addCase(regCheck.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(regCheck.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
            } else {
            }
            state.loading = false;
        });
        builder.addCase(regCheck.rejected, (state, action) => {
            console.error("오류 발생 :", action.error);
            state.loading = false;
        });
    },
});

export const signupActions = signupSlice.actions;
export default signupSlice.reducer;
