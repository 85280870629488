import styled from "styled-components";
import ApexCharts from "react-apexcharts";
import Text from "@/components/atoms/text"
import { EachPlatformSalesData } from "@/pages/dashboard/publisher/types";
import { colors } from "@/assets/styles/theme";

export default function EachPlatformDailyRankWrapper({ platform_name, color, salesData }: PropsType) {
    const chartOptions = {
        chart: {
            height: 500,
            type: 'donut',
            toolbar: {
                show: false
            }
        },
        colors: [color, colors.gray600, colors.gray300, colors.gray50],
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.8
                }
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "70%",
                    background: "transparent",
                    lables: {
                        show: false,
                        name: {
                            show: true,
                            fontSize: "22px",
                            formatter: function (val:any) {
                                return val;
                            }
                        }
                    },
                    value: {
                        show: false
                    },
                    total: {
                        show: false
                    }
                }
            }
        },
        labels: salesData.map(s => s.series_name),
        legend: {
            show: false
        },
        xaxis: {
            labels: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        grid: { show: false },
        tooltip: {
            shared: false,
            intersect: true,
            followCursor: false,
            custom: function(data: any) {
                const { series, seriesIndex } = data;
                return `
                    <div class="tooltip-box">
                        <div>
                            ${salesData[seriesIndex].series_name}
                        </div>
                        <div>
                            ${salesData[seriesIndex].author_name ? `/ ${salesData[seriesIndex].author_name}` : ""}
                        </div>
                        <div class="amount-wrap">
                            <span class="color-box"></span>
                            <div>${series[seriesIndex].toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원"}</div>
                        </div>
                    </div>
                `
            },
        }
    }

    const data = salesData.map(s => s.amount);

    const textStyle = {
        fontSize: "14px",
        fontWeight: "500",
        padding: "5px 15px"
    }

    return (
        <StyledWrapper>
            <Text text={platform_name} customStyle={textStyle} />
            <StyledCharts 
                options={chartOptions}
                series={data}
                type="donut"
                width={200}
                height={200}
                platformcolor={color}
            />
        </StyledWrapper>
    )
}

const StyledCharts = styled(ApexCharts)`
    margin-left: 45px;
    margin-top: 15px;
    .apexcharts-tooltip {
        box-shadow: 0px 0px 15px rgba(112, 144, 176, 0.2); !important;
    }
    .tooltip-box {
        display: flex;
        flex-direction: column;
        padding: 8px 10px;
        min-width: 120px;
        background: #FFFFFF;
        border-radius: 4px;
        color: #121417;
        font-size: 10px;
        line-height: 14px;
        .amount-wrap {
            display: flex;
            margin-top: 10px;
            align-items: center;
            .color-box {
                position: relative;
                width: 10px;
                height: 10px;
                background-color: ${({ platformcolor }) => platformcolor ? platformcolor : ""};
                margin-right: 5px;
            }
        }
    }
`

const StyledWrapper = styled.div`
    width: 25%;
`

interface PropsType {
    platform_name: string;
    salesData: Array<EachPlatformSalesData>;
    color: string;
}