import { colors } from "@/assets/styles/theme";
import { RootState, useAppSelector } from "@/modules/store";
import styled from "styled-components";
import Text from "@/components/atoms/text";

export default function MgHistoryTableWrapper() {
    const { mgDeductionHistoryData } = useAppSelector((state: RootState) => state.authorDashboard);

    const noDataTextStyle = {
        fontSize: "16px",
        textAlign: "center",
        margin: "110px 0 0 0",
        color: colors.gray500
    }

    let dataExist = false;
    for (let m of mgDeductionHistoryData) {
        if (m.data.length) {
            dataExist = true;
            break;
        }
    }

    if (!dataExist) {
        return (
            <StyledWrapper>
                <Text text="선인세 소진현황이 없습니다." customStyle={noDataTextStyle} />
            </StyledWrapper>
        )
    }

    return (
        <StyledTable>
            <thead>
                <tr>
                    <StyledTh width="140px"></StyledTh>
                    <StyledTh width="160px" align="left">플랫폼</StyledTh>
                    <StyledTh width="270px" align="left">계약대상</StyledTh>
                    <StyledTh width="150px">계약 금액</StyledTh>
                    <StyledTh width="150px">누적 소진금액</StyledTh>
                    <StyledTh width="150px">이달 소진금액</StyledTh>
                    <StyledTh width="170px" align="right">잔액</StyledTh>
                </tr>
            </thead>
            <tbody>
                {mgDeductionHistoryData.map(t => (
                    t.data.map(d => (
                        <StyledTr key={d.platform_name + d.target} line={t.data.indexOf(d) === 0}>
                            {t.data.indexOf(d) === 0 ? <StyledTd rowSpan={t.data.length}>{t.month}</StyledTd> : <></>}
                            <StyledTd align="left">{d.platform_name}</StyledTd>
                            <StyledTd align="left">{d.target}</StyledTd>
                            <StyledTd>{d.contract_amount}</StyledTd>
                            <StyledTd>{d.pre_deduction_amount}</StyledTd>
                            <StyledTd color={colors.information}>{d.curr_deduction_amount}</StyledTd>
                            <StyledTd align="right" color={colors.error}>{d.remain_amount}</StyledTd>
                        </StyledTr>
                    ))
                ))}
            </tbody>
        </StyledTable>
    )
}

const StyledWrapper = styled.div`
    height: 160px;
`

const StyledTable = styled.table`
    width: 100%;
    font-size: 12px;
    font-weight: 500;
`

const StyledTh = styled.th<CellStyleProps>`
    padding: 18px 8px;
    text-align: ${({ align }) => align ? align : "center"};
    width: ${({ width }) => width ? width : "auto"};
    &:last-child {
        padding-right: 24px;
    }
`

const StyledTr = styled.tr<TrStyleProps>`
    border-top: ${({ line, theme }) => line ? `1px solid ${theme.colors.gray300}` : "unset"};
`

const StyledTd = styled.td<CellStyleProps>`
    padding: 18px 8px;
    width: ${({ width }) => width ? width : "auto"};
    text-align: ${({ align }) => align ? align : "center"};
    color: ${({ color }) => color ? color : "unset"};
    &:first-child {
        vertical-align: middle;
    }
`

interface CellStyleProps {
    width?: string;
    align?: string; 
    color?: string;
}

interface TrStyleProps {
    line: boolean;
}