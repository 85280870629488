import styled from "styled-components";
import { ReactComponent as QuestionMark } from "@/assets/icons/ic-question-mark.svg";
import { useState } from "react";
import Text from "@/components/atoms/text";

export default function Tooltip({ iconWidth, iconHeight, textBoxWidth, fill, text }: TooltipProps) {
    const [tooltip, setTooltip] = useState(false);

    const onMouseEnter = () => {
        setTooltip(true);
    };

    const onMouseLeave = () => {
        setTooltip(false);
    };

    const splitedText = text.split("\\n");

    const textStyle = {
        lineHeight: "21px",
        fontSize: "12px",
        fontWeight: "400",
    };

    return (
        <TooltipWrapper>
            <QuestionMarkWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <QuestionMark width={iconWidth} height={iconHeight} fill={fill} />
            </QuestionMarkWrapper>
            {Boolean(tooltip) && (
                <TextWrapper left={iconWidth} width={textBoxWidth}>
                    {splitedText.map((s) => (
                        <Text text={s} customStyle={textStyle} key={splitedText.indexOf(s)} />
                    ))}
                </TextWrapper>
            )}
        </TooltipWrapper>
    );
}

const TooltipWrapper = styled.div`
    position: relative;
    display: flex;
`;

const TextWrapper = styled.div<TextWrapperProps>`
    position: absolute;
    width: ${({ width }) => (width ? `${width}px` : "240px")};
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    padding: 4px 8px;
    border-radius: 4px;
    left: ${({ left }) => (left ? `${left+4}px` : "24px")};
    z-index: 99;
    background-color: ${({ theme }) => theme.colors.blue50};
`;

const QuestionMarkWrapper = styled.div``;

interface TooltipProps {
    iconWidth?: number;
    iconHeight?: number;
    textBoxWidth?: number;
    fill?: string;
    text: string;
}

interface TextWrapperProps {
    left?: number;
    width?: number;
}
