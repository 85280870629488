import styled from "styled-components";
import theme from "@/assets/styles/theme";
import { ReactComponent as DownloadIcon } from "@/assets/icons/download.svg";
import { ReactComponent as LeftIcon } from "@/assets/icons/login/left.svg";

function IconButton({ type, label, disabled, onClickHandler, customStyle }: ButtonProps) {
    switch (type) {
        case "download":
            return (
                <ButtonWrap>
                    <BaseButton
                        disabled={disabled}
                        onClick={onClickHandler}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderRadius={customStyle?.borderRadius}
                        color={theme.colors.red400}
                        border={theme.colors.red400}
                        padding="0 0 0 17.5px"
                        margin={customStyle?.margin}
                    >
                        {label}
                    </BaseButton>
                    <IconRightWrap>
                        <DownloadIcon size={24} fill={theme.colors.red400} onClick={onClickHandler} />
                    </IconRightWrap>
                </ButtonWrap>
            );
        case "left":
            return (
                <ButtonWrap>
                    <IconLeftWrap>
                        <LeftIcon size={24} fill={theme.colors.gray600} onClick={onClickHandler} />
                    </IconLeftWrap>
                    <BaseButton
                        disabled={disabled}
                        onClick={onClickHandler}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderRadius={customStyle?.borderRadius}
                        color={theme.colors.gray600}
                        border={theme.colors.gray600}
                        padding="8px 32.5px 8px 0"
                        margin={customStyle?.margin}
                        textAlign="right"
                    >
                        {label}
                    </BaseButton>
                </ButtonWrap>
            );
        default:
            return (
                <></>
            );
    }
}

export default IconButton;

const IconRightWrap = styled.div`
    position: absolute;
    top: 4px;
    right: 14.5px;
    cursor: pointer;
`;

const IconLeftWrap = styled.div`
    position: absolute;
    top: 18px;
    left: 30.5px;
    cursor: pointer;
`;

const ButtonWrap = styled.div<{ width?: string }>`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: ${({ width }) => width ? width : ""};
`;

const BaseButton = styled.button<StyleProps>`
    width: ${({ width }) => (width ? width : "100px")};
    height: ${({ height }) => (height ? height : "32px")};
    padding: ${({ padding }) => (padding ? padding : "0")};
    margin: ${({ margin }) => (margin ? margin : "0")};
    background-color: ${({ theme }) => theme.colors.white};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
    cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
    color: ${({ color, theme }) => (color ? color : theme.colors.black)};
    border: 1px solid ${({ border }) => (border ? border : theme.colors.black)};
    user-select: none;
    text-align: ${({ textAlign }) => (textAlign ? textAlign : "initial")};
`;

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    borderRadius?: string;
    color?: string;
    border?: string;
    textAlign?: string;
}

interface ButtonProps {
    type: "download" | "left";
    label: string;
    disabled?: boolean;
    onClickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    customStyle?: StyleProps;
}
