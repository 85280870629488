import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { EnrollAuthorJointData, EnrollAuthorData, JointData, AuthorData, AuthorState, GetDataParams, JointArray } from './types';
import { KeyValue, Key, KeyObject } from '@/pages/settlement/basedata-manage/types';
import api from "@/api";

export const initialState: AuthorState = {
	data: [],
	loading: false,
	error: "",
	hasError: false,
	authorSeriesResult: [],

	searchType: "all",

	// 저자 데이터
	authorAddDatas: [{
		id:0, 
		user_id: null,
		name:'', 
		joint:'', 
		realName: "",
		stEmail: "",
		taxType: "D",
		taxRatio: "3.3",
		jointArray:[{
			email: "",
			realName: "",
			taxType: "D",
			taxRatio: "3.3",
			taxRatioError: "",
			stEmail: "",
			emailError: "",
			stEmailError: "",
			realNameError: "",
			user_id: null
		}],
		taxTypeError: "",
		taxRatioError: "",
		authorError: "",
		emailError: "",
		realNameError: "",
		stEmailError: "",
		jointError: "",
		selected: false
	}],
	index: null,

	tmpJointArray: [{
		email: "",
		realName: "",
		realNameError: "",
		taxType: "D",
		taxRatio: "3.3",
		taxRatioError: "",
		stEmail: "",
		emailError: "",
		stEmailError: "",
		user_id: null
	}],
	deleted_ae_id: [],

	// 공저 설정
	needCoDataCheck: false,

	// 저자명 공저 수정
	tmpCoRelatedData: [],

	// 자동완성
    atcmpList: [],
}

// 저자 가져오기
export const getAuthorData = createAsyncThunk("author/getAuthorData", async (apiParams:GetDataParams|{},{ rejectWithValue }) => {
	try {
		if (!apiParams) {
			apiParams = {
				searchType: "all",
				searchKeyword: "",
			}
		}
		const res = await api.post(`/api/settlement/newBaseData/author/select`, apiParams);
		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data)
	}
})

// 시리즈명 등록한 저자있는지 검사
export const checkAuthorSeries = createAsyncThunk("author/checkAuthorSeries", async (apiParams:{ selected: AuthorData[] }, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/settlement/baseData/author/series/select`, apiParams);
		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

// 저자 삭제 
export const deleteAuthorData = createAsyncThunk("author/deleteAuthorData", async (apiParams:{ selected: AuthorData[] }, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/settlement/baseData/author/delete`, apiParams);
		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

// 저자명 등록 
export const enrollAuthor = createAsyncThunk("author/enrollAuthor", async (authorAddDatas:EnrollAuthorData[], { rejectWithValue }) => {
	try {
		let apiParams = {
			authorAddDatas: authorAddDatas
		}
		const res = await api.post(`/api/settlement/baseData/author/insert`, apiParams);
		return res.data;
	} catch (err:any) {
		throw rejectWithValue(err.response.data);
	}
})

// 저자명 조회/변경 화면 공저설정 등록시 기타지급금, 선인세 관련 데이터 체크
export const coAuthorCheck = createAsyncThunk("author/coAuthorCheck", async(apiParams:{author_id_list:(number|undefined)[]}, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/settlement/baseData/coAuthor/data/check`, apiParams);
		return res.data;
	} catch (err:any) {
		throw rejectWithValue(err.response.data);
	}
})

// 저자명 수정
export const updateAuthor = createAsyncThunk("author/update", async(apiParams:{ updatedRows:AuthorData[] }, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/settlement/newBaseData/author/update`, apiParams);
		return res.data;
	} catch (err:any) {
		throw rejectWithValue(err.response.data);
	}
})

// 전체 검색 (저자명 + 본명 + 글링아이디)
export const getAtcmpByAll = createAsyncThunk(`author/getAtcmpByAll`,
    async ({ keyword }: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/author/select`, 
                { keyword })).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 저자명 검색
export const getAtcmpByAuthor = createAsyncThunk(`author/getAtcmpByAuthor`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/authorName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 본명 검색
export const getAtcmpByRealName = createAsyncThunk(`author/getAtcmpByRealName`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/realName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 글링아이디 검색
export const getAtcmpByEmail = createAsyncThunk(`author/getAtcmpByEmail`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/email/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 저자명 검색 (저자명 검색 박스)
export const searchAuthor = createAsyncThunk("author/search", async(apiParams:{ keyword:string }, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/settlement/excelUpload/searchAuthor/select`, apiParams);
		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

// 저자명 엑셀 업로드
export const excelUploadAuthor = createAsyncThunk("author/excelUpload", async(apiParams:{ file: string, fileName: string }, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/settlement/excelUpload/uploadAuthor/insert`, apiParams);

		return res.data;

	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

export const authorSlice = createSlice({
	name: 'author',
	initialState,
	reducers: {
		reset() {
			return {...initialState};
		},
		setAuthorDatas: (state, action: PayloadAction<Key>) => {
			let key = action.payload.key;
			let index = action.payload.index;
			let value = action.payload.value;
			if (typeof index === "number") {
				state[key][index] = value;
			} else {
				state[key] = value;
			}
		},
		setDataValue: (state, action: PayloadAction<{ index:number; key:string; value:string }>) => {
			const {index, key, value} = action.payload;
			state.data[index][key] = value;
		}, 
		setDatas: (state, action: PayloadAction<KeyValue>) => {
			let key = action.payload.key;
			let index = action.payload.index;
			let value = action.payload.value;
			state.data[index][key] = value;
			if (key === "email" || key === "realName" || key === "taxType"
				|| key === "taxRatio" || key === "stEmail") {
				state.data[index].joint[0][key] = value;
			}
		},
		resetEnrollAuthor: (state) => {
			state.authorAddDatas = initialState.authorAddDatas;
		},
		setSearchType: (state, action: PayloadAction<string>) => {
			state.searchType = action.payload;
		},
		setEnrollDatas: (state, action: PayloadAction<KeyValue>) => {
			let key = action.payload.key;
			let value = action.payload.value;
			let index = action.payload.index;
			state.authorAddDatas[index][key] = value;
			if (index === 0) {
				if (key === "joint") {
					state.authorAddDatas[0].jointArray[0].email = value;
				} else if (key !== "jointArray") {
					state.authorAddDatas[0].jointArray[0][key] = value;
				}
			}
		},
		setIndex: (state, action: PayloadAction<number|null>) => {
			state.index = action.payload;
		},
		addJointRow: (state) => {
			state.tmpJointArray.push({
				email: "",
				realName: "",
				realNameError: "",
				taxType: "D",
				taxRatio: "3.3",
				taxRatioError: "",
				stEmail: "",
				emailError: "",
				stEmailError: "",
				user_id: null
			});
		},
		deleteJointRow: (state, action: PayloadAction<number>) => {
			// 공저 행 삭제 (한줄)
			let index = action.payload;
			if (state.tmpJointArray[index]?.ae_id) {
                state.deleted_ae_id.push(state.tmpJointArray[index].ae_id);
			}
            state.tmpJointArray.splice(index, 1);
            // this.needCoDataCheck = true;
		},
		setTmpJointArray: (state, action:PayloadAction<EnrollAuthorJointData[]|JointData[]>) => {
			state.tmpJointArray = action.payload;
		},
		setTmpJointArrayDatas: (state, action: PayloadAction<{ index: number, value: any|any[], key?: string}>) => {
			let index = action.payload.index;
			let key = action.payload.key;
			let value = action.payload.value;
			if (key) {
				state.tmpJointArray[index][key] = value;
			} else {
				state.tmpJointArray[index] = value;
			}
	   	},
		setJointArray: (state, action:PayloadAction<JointArray>) => {
			const jointAddData = action.payload.joint;
			const from = action.payload.from;
			const needCoDataCheck = action.payload.needCoDataCheck;
			const index = state.index as number;
			let email = "";
            let realName = "";
            let stEmail = "";
            let taxType:"D"|"A"|"B" = "D";
            let taxRatio:string|number = "";
			if (jointAddData.length === 1) {
				email = jointAddData[0].email;
				realName = jointAddData[0].realName;
				stEmail = jointAddData[0].stEmail;
				taxType = jointAddData[0].taxType;
				taxRatio = jointAddData[0].taxRatio;
			}

			if (from === "author-enroll") {
				state.authorAddDatas[index].jointArray = jointAddData as EnrollAuthorJointData[];
				state.authorAddDatas[index].joint = email;
				state.authorAddDatas[index].realName = realName;
				state.authorAddDatas[index].stEmail = stEmail;
				state.authorAddDatas[index].taxType = taxType;
				state.authorAddDatas[index].taxRatio = taxRatio as string;
				state.authorAddDatas[index].emailError = "";
				state.authorAddDatas[index].stEmailError = "";
				state.authorAddDatas[index].taxTypeError = "";
				state.authorAddDatas[index].taxRatioError = "";
				state.authorAddDatas[index].realNameError = "";
				state.authorAddDatas[index].jointError = "";
				state.authorAddDatas[index].needCoDataCheck = needCoDataCheck;
			} else if (from === "author-update") {
				state.data[index].joint = jointAddData as JointData[];
				state.data[index].email = email;
				state.data[index].realName = realName;
				state.data[index].stEmail = stEmail;
				state.data[index].taxType = taxType;
				state.data[index].taxRatio = taxRatio as number;
				state.data[index].emailError = "";
				state.data[index].stEmailError = "";
				state.data[index].realNameError = "";
				state.data[index].needCoDataCheck = needCoDataCheck;
				// state.data[state.index].taxTypeError = "";
				// state.data[state.index].taxRatioError = "";
			}
			state.tmpJointArray = [];
			state.deleted_ae_id = [];
		},
		deleteAddDataRow: (state, action:PayloadAction<number>) => {
			let index = action.payload;
			state.authorAddDatas.splice(index, 1);
		},
		setTmpCoRelatedData: (state, action:PayloadAction<KeyObject>) => {
			let key = action.payload.key;
			let index = action.payload.index;
			let value = action.payload.value;
			let objectIndex = action.payload.objectIndex;
			// let objectKey = action.payload.objectKey;
			if (typeof objectIndex === "number") {
				state.tmpCoRelatedData[index][key][objectIndex] = value;
			} else {
				state.tmpCoRelatedData[index][key] = value;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getAuthorData.pending, (state, action) => {
			// 프로미스반환전
			state.loading = true;
			state.hasError = false;
		});
		builder.addCase(getAuthorData.fulfilled, (state: any, action) => {
			//프로미스 이행된상태
			if (action.payload.status === "success") {
				state.data = action.payload.data;
				state.hasError = false;
			} else {
				state.hasError = true;
			}
			state.loading = false;
		})
		builder.addCase(getAuthorData.rejected, (state, action) => {
			//프로미스 거부된상태
			state.loading = false;
			state.hasError = true;
		});
		builder.addCase(deleteAuthorData.fulfilled, (state: any, action) => {
			if(action.payload.status === "success") {
				state.loading = false;
			} else {
				state.hasError = true;
				state.loading = false;
			}
		})
		builder.addCase(deleteAuthorData.rejected, (state, action) => {
			//프로미스 거부된상태
			state.loading = false;
			state.hasError = true;
		});
		builder.addCase(checkAuthorSeries.fulfilled, (state: any, action) => {
			if(action.payload.status === "success") {
			} else {
				state.hasError = true;	
				state.loading = false;
			}
		})
		builder.addCase(checkAuthorSeries.rejected, (state, action) => {
			state.loading = false;
			state.hasError = true;
		});
		builder.addCase(enrollAuthor.fulfilled, (state: any, action) => {
			if(action.payload.status === "success") {
				state.authorAddDatas = initialState.authorAddDatas;
				state.loading = false;
			} else {
				state.hasError = true;
				state.error = action.payload.message; 
				state.loading = false;
			}
		})
		builder.addCase(enrollAuthor.rejected, (state, action) => {
			state.loading = false;
			state.hasError = true;
		});
		builder.addCase(coAuthorCheck.fulfilled, (state: any, action) => {
			if(action.payload.status === "success") {
			} else {
				state.hasError = true;
				state.error = action.payload.message; 
				state.loading = false;
			}
		})
		builder.addCase(coAuthorCheck.rejected, (state, action) => {
			state.loading = false;
			state.hasError = true;
		});
		builder.addCase(updateAuthor.fulfilled, (state: any, action) => {
			if(action.payload.status !== "success") {
				state.hasError = true;
				state.error = action.payload.message; 
				state.loading = false;	
			}
		})
		builder.addCase(updateAuthor.rejected, (state, action) => {
			state.loading = false;
			state.hasError = true;
		});
		builder.addCase(searchAuthor.fulfilled, (state, action) => {
			if(action.payload.status === "success") {
			} else {
				state.hasError = true;
				state.error = action.payload.message; 
				state.loading = false;
			}
		});
		builder.addCase(excelUploadAuthor.fulfilled, (state, action) => {
			if(action.payload.status === "success") {
				state.authorAddDatas = action.payload.data;
			} else {
				state.hasError = true;
				state.error = action.payload.message; 
				state.loading = false;
			}
		});

		// 자동완성 - 전체 검색
        builder.addCase(getAtcmpByAll.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
		// 자동완성 - 저자명 검색
		builder.addCase(getAtcmpByAuthor.fulfilled, (state, action) => {
			state.atcmpList = action.payload.data;
		});
        // 자동완성 - 본명 검색
        builder.addCase(getAtcmpByRealName.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
		// 자동완성 - 글링아이디(이메일) 검색
        builder.addCase(getAtcmpByEmail.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
	}
})

export const authorActions = authorSlice.actions
export default authorSlice.reducer