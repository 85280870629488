import { useState, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { colors } from '@/assets/styles/theme';
import styled from 'styled-components'
import Text from '@/components/atoms/text';
import Button from '@/components/atoms/button';
import SelectBox from '@/components/atoms/selectBox';
import useLoading from '@/modules/hooks/useLoading';
import useAlert from '@/modules/hooks/useAlert'
import { useAppDispatch, useAppSelector, RootState } from '@/modules/store'
import { getMyPubInfo, getNoticeDetail, myPubInfo, writeNotice } from '@/pages/support/notice/slice';

interface PubSelectBoxProps {
    selectBoxStyle: {width: string, height: string},
    category: string,
    onCategoryChange: (publisher: {name: string, value: string}) => void,
    myPubInfo: myPubInfo
}

const PubSelectBox = ({selectBoxStyle, category, onCategoryChange, myPubInfo}: PubSelectBoxProps) => {
    const myPubInfoOptions = [
        {value: 'A', name: '전체'},
        {value: 'P', name: '출판사'},
        {value: 'W', name: '작가'},
        {value: 'S', name: myPubInfo.publisher_name}
    ];

    if (myPubInfo.publisher_name === '뷰컴즈') {
        return (
            <SelectBox
                labelKey="name"
                valueKey="value"
                options={myPubInfoOptions}
                customStyle={selectBoxStyle}
                selected={category}
                onClickValueFunc={onCategoryChange}
            />
        )
    } else if (myPubInfo.publisher_name !== '') {
        return (
            <SelectBox
                labelKey="name"
                valueKey="value"
                options={[{value: 'S', name: myPubInfo.publisher_name}]}
                customStyle={selectBoxStyle}
                selected={category}
                onClickValueFunc={onCategoryChange}
            />
        )
    } else {
        return (
            <></>
        )
    }
}


export default function NoticeWrite() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { myPubInfo, noticeDetail } = useAppSelector((state: RootState) => state.notice);
    const { isPublisher  } = useAppSelector((state: RootState) => state.user)
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const titleInputRef = useRef<HTMLInputElement>(null);
    const contentInputRef = useRef<HTMLTextAreaElement>(null);
    const [category, setCategory] = useState("S");
    const [searchParams] = useSearchParams();
    const writeId = Number(searchParams.get("id"));

    const TitleStyle = {
        fontSize: "20px",
        fontWeight: "700",
        color: colors.black
    };

    const textStyle = {
        fontWeight: "500",
        fontSize: "14px",
        color: colors.black
    }

    const selectBoxStyle = {
        width: "200px",
        height: "32px",
    }

    const goSecretWarning = async () => {
        await alert("info", "주의", "접근 권한이 없습니다");
        navigate('/support/notice');
    }

    const submitHandler = async () => {
        try {
            if (titleInputRef.current && titleInputRef.current?.value.length < 2) {
                return await alert("info", "주의", "제목을 2글자이상 입력해주세요");
            }
            if (contentInputRef.current?.value.length === 0) {
                return await alert("info", "주의", "내용을 입력해주세요");
            }
            let answer = await alert(
                "confirm",
                "저장 확인",
                "게시글을 저장 하시겠습니까?"
            );
    
            if (answer === "Y") {
                loadingOn();
                const url = writeId ? "support/board/update" : "support/board/insert";
                let apiParams = {
                    title: titleInputRef.current?.value,
                    content: contentInputRef.current?.value,
                    category,
                    publisher_id: 0,
                    id: writeId || '',
                    url,
                    class: 'notice'    
                }
                await dispatch(writeNotice(apiParams)).unwrap();
                await alert("success", "저장완료", "게시글이 저장 되었습니다.");
                navigate('/support/notice');
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const onCategoryChange = (publisher: {name: string, value: string}) => {
        setCategory(publisher.value);
    }

    const getMyPubInfoHandler = async () => {
        loadingOn();
        try {
            let apiParams = {
                withCredentials: true,
                credentials: "include"
            }
            await dispatch(getMyPubInfo(apiParams)).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const getModifyPost = async () => {
        loadingOn();
        try { 
            const apiParams = {
                noticeId: writeId,
                isPublisher,
                target_class: "공지사항",
                target_cd: "BV",
                target_id: writeId,
                log_activity: "VU",
            }
            await dispatch(getNoticeDetail(apiParams)).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    useEffect(()=> {
        getMyPubInfoHandler();
    }, [dispatch]);

    useEffect(()=> {
        if (writeId) {    
            getModifyPost();
        }
    }, [dispatch]);

    useEffect(() => {
        if (noticeDetail.is_mine === undefined) {
            goSecretWarning();
        }
        if (writeId && noticeDetail.is_mine !== false) {
            loadingOn();
            setCategory(noticeDetail.category);
    
            if (titleInputRef.current) {
                titleInputRef.current.value = noticeDetail.title;
            }
            if (contentInputRef.current) {
                contentInputRef.current.value = noticeDetail.content;
            }
            loadingOff();
        }
    }, [noticeDetail]);


    return (
        <StyledContainer>
            <Text text="글쓰기" customStyle={TitleStyle}/>
            <NoticeInputContainer>
                <Text text="카테고리" customStyle={textStyle}/>
                <PubSelectBox
                    selectBoxStyle={selectBoxStyle}
                    category={category}
                    onCategoryChange={onCategoryChange}
                    myPubInfo={myPubInfo}
                />
            </NoticeInputContainer>
            <StyledTitleInput
                placeholder='제목을 입력해주세요'
                ref={titleInputRef}
            />
            <StyledContentInput
                placeholder='질문을 입력해주세요'
                ref={contentInputRef}
            />
            <StyledButtonContainer>
                <Button type="red-outline" label="뒤로가기" onClickHandler={()=> navigate('/support/Notice')} />
                <Button label="글쓰기" onClickHandler={submitHandler}/>
            </StyledButtonContainer>
        </StyledContainer>
    )
};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const NoticeInputContainer = styled.div`
    display: flex;
    width: 260px;
    justify-content: space-between;
    align-items: center;
`;

const StyledTitleInput = styled.input`
    width: 100%;
    height: 48px;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    &::placeholder {
        color: ${({ theme }) => theme.colors.gray600};
        font-weight: 400;
    }
`;

const StyledContentInput = styled.textarea`
    width: 100%;
    min-height: 420px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    padding: 16px;
    &::placeholder {
        line-height: 28px;
    }
    &:focus {
        outline: 0;
    }
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;