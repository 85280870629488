import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import moment from "moment";

// components
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import InputText from "@/components/atoms/input/TextInput";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import Tooltip from "@/components/molecules/common/tooltip/Tooltip";
import AuthorSearch from "@/components/molecules/basedata-manage/AuthorSearch";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetSettlementRatioModal from "@/components/molecules/common/modal/SetSettlementRatioModal";
import Skeleton from "@/components/molecules/common/loading/TableSkeleton";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";

// reducer
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { seriesActions, enrollPortfolioSeries } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import { updateRow } from "@/pages/common/dataTableSlice";

// hooks
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";

// utils
import { ratio, newSeries, Validation } from "@/modules/utils/validate_modify";

function SeriesTableContainer() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const navigate = useNavigate();
    const [showSetSettlementRatioModal, setShowSetSettlementRatioModal] = useState(false);
    const { setDataCount, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "Series" });
    let { data, loading } = useAppSelector((state: RootState) => state.series);
    useEffect(()=> {
        if (data?.length || data?.length === 0) {
            setDataCount(data.length);
        }
    }, [data, setDataCount])

    async function goPortfolioEnroll(item:any) {
        if (item.content_id) { // 수정버튼 클릭시 : 스튜디오 작품 수정 페이지로 이동
            navigate(`/portfolio/detail?id=${item.content_id}`);
        } else { // 등록버튼 클릭시 : 작품등록 후 스튜디오 작품 수정 페이지로 이동
            try {
                const answer = await alert("confirm", "생성 확인", "등록된 시리즈의 포트폴리오가 생성됩니다.\n계속 하시겠습니까?");
                if (answer === "Y") {
                    const apiParams = {
                        series_id: item.series_id
                    }

                    const { data } = await dispatch(enrollPortfolioSeries(apiParams)).unwrap();

                    if (!data.content_id) {
                        return await alert("error", "오류 발생", "포트폴리오 생성중 오류가 발생하였습니다.");
                    }

                    navigate(`/portfolio/detail?id=${data.content_id}`);
                }
            } catch (err: any) {
                await alert("error", "오류 발생", err.message);
            }
        }
    }

    async function setSettlementRatioModal(idx:number) {
        let index = ((page-1) * itemsPerPage + idx);
        if (data[index].ratioError) {
            await alert("warning", "주의", "정산비율의 오류 메시지를 확인해주세요.")
        } else {
            let ratios = data[index].ratios.map(r => {
                return r.ratio ? r : {...r, "ratio": ""}
            })
            dispatch(seriesActions.setSeriesDatas({
                key: "ratios", value: ratios
            }))
            dispatch(seriesActions.setIndex(index));
            setShowSetSettlementRatioModal(true);
        }
    }

    function closeModal() {
        setShowSetSettlementRatioModal(false);
    }

    function selectAuthor(idx:number, author: any) {
        let index = ((page-1) * itemsPerPage + idx);

        let updatedRow = {
            ...data[index],
            'author': author,
            'authorError': "",
        }
        dispatch(seriesActions.setSeriesDatas({
            key: "data", index: index, value: updatedRow
        }));
        dispatch(updateRow({ itemKey: "series_id", updatedRow: updatedRow }));
    }

    function applyCoInfo(co_info:any, idx:number) {
        let index = ((page-1)*itemsPerPage)+idx;
        let updatedRow = {
            ...data[index],
            'author': {
                ...data[index].author,
                "co_info": co_info,
            },
        } 
        dispatch(seriesActions.setSeriesDatas({
            key: "data", index: index, value: updatedRow
        }));
        dispatch(updateRow({ itemKey: "series_id", updatedRow: updatedRow }));
    }

    function deleteAuthor(idx: number) {
        let updatedRow = {
            ...data[idx],
            'author': {
                author_id: 0,
                author_name: "",
                co_info: [],
            },
            'authorError': "저자명을 선택해주세요.",
        } 
        dispatch(seriesActions.setSeriesDatas({
            key: "data", index: idx, value: updatedRow
        }));
        dispatch(updateRow({ itemKey: "series_id", updatedRow: updatedRow }));
    }

    const customHeaders = {
        series_name: () => <Text text="시리즈명" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
        author: () => <Text text="저자명" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
        ratioAll: () => (
            <div style={{ display: "flex", alignItems: "center" }}>
                <Text
                    text="정산비율"
                    customStyle={{ height: "15px", fontSize: "12px", fontWeight: "500", margin: "0 8px 0 0" }}
                />
                <Tooltip
                    iconWidth={20}
                    iconHeight={20}
                    textBoxWidth={240}
                    fill={colors.gray600}
                    text="출판사가 작가에게 지급하는 비율입니다."
                />
            </div>
        ),
        created_at: () => <Text text="등록일시" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
        content_id: () => (
            <Text text="스튜디오" customStyle={{ fontWeight: "500", fontSize: "12px", textAlign: "center" }} />
        ),
    };

    const columns = {
        series_name: ({ item, index }: TableItemProps) => {
            const seriesNameValidate = async(value: string) => {
                dispatch(seriesActions.setSeriesDatas({ key: "data", index, objectKey: "series_name", value }));

                const res = (await newSeries({ value, error: "seriesError", mandatory: true, id: item["id"] })) as Validation;
                
                dispatch(seriesActions.setSeriesDatas({ key: "data", index, objectKey: "seriesError", value: res.seriesError }));
                dispatch(updateRow({ itemKey: "series_id", updatedRow: {...item, "series_name": value, "seriesError": res.seriesError }}));
            }
            return (
                <ValidateWrapper dataError={item["seriesError"]}>
                    <InputText
                        value={item["series_name"]}
                        onChangeHandler={(e) => {seriesNameValidate(e.target.value)}}
                        customStyle={{ width: "280px", height: "32px", padding: "10px", fontSize: "12px", fontWeight: "400" }}
                    />
                </ValidateWrapper>
            )
        },
        author: ({ item, index }: TableItemProps) => {
            return (
                <ValidateWrapper dataError={item["authorError"]}>
                    <AuthorSearch
                        from="series-update"
                        idx={index}
                        author={item["author"]}
                        value={item["author"]["author_name"]}
                        placeholder="저자명"
                        customStyle={{width: "160px", height: "32px", padding: "10px", fontSize: "12px", fontWeight: "400"}}
                        buttonCustomStyle={{ customStyle: { width: "120px", height: "32px", fontSize: "14px", fontWeight: "500", color: colors.gray600 }}}
                        selectAuthorFunc={selectAuthor}
                        deleteAuthorFunc={deleteAuthor}
                        applyCoInfoFunc={applyCoInfo}
                    />
                </ValidateWrapper>
            )
        },
        ratioAll: ({ item, index }: TableItemProps) => {
            const ratioAllValidate = (value: string) => {
                const res = ratio({ value, error: "ratioError", mandatory: false, field: "정산비율", decimal: false }) as Validation;
                let ratios = JSON.parse(JSON.stringify(item.ratios));
                for (let l of ratios) {
                    l.ratio = res.value;
                    if (l.period.length > 0) {
                        l.period[0].ratio = res.value;
                    } else {
                        l.period.push({
                            ratio: res.value,
                            startYearmon: "000000",
                            endYearmon: "999999",
                        })
                    }
                }
                const newItem = {...item, "ratioError": res.ratioError,
                                          "ratioAll": res.value,
                                          "ratios": item.ratios.map((r:any) => { return {...r, 'ratio': res.value,
                                                                     'period': ratios[item.ratios.indexOf(r)].period } })};

                dispatch(seriesActions.setSeriesDatas({ key: "data", index, value: newItem }));
                dispatch(updateRow({ itemKey: "series_id", updatedRow: {...item, "ratioError": res.ratioError,
                                                                                                    "ratioAll": res.value,
                                                                                                    "ratios": item.ratios.map((r:any) => { return {...r, 'ratio': res.value,
                                                                                                    'period': ratios[item.ratios.indexOf(r)].period } }
                )}}));
            }
            return (
                <>
                    {!item["hasDetailOption"] ? (
                        <ValidateWrapper customStyle={{ display: "inline-flex" }} dataError={item["ratioError"]}>
                            <PercentInputText
                                type="number"
                                placeholder={item["ratioAll"]}
                                value={item["ratioAll"] === null ? undefined : item["ratioAll"]}
                                inText="%"
                                onChange={(e) => {
                                    ratioAllValidate(e.target.value);
                                }}
                                customStyle={{
                                    width: "120px",
                                    height: "32px",
                                    padding: "10px 20px 10px 10px",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                }}
                            />
                        </ValidateWrapper>
                    ) : (
                        <InputText
                            type="readOnly"
                            value="플랫폼 별 설정"
                            onChangeHandler={() => {}}
                            customStyle={{
                                width: "120px",
                                height: "32px",
                                padding: "10px",
                                fontSize: "12px",
                                fontWeight: "400",
                            }}
                        />
                    )}
                    <Button
                        type="main-outline"
                        label="상세 설정"
                        customStyle={{
                            width: "80px",
                            height: "32px",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                        onClickHandler={(e) => setSettlementRatioModal(index)}
                    />
                </>
            )
        },
        created_at: ({ item }: TableItemProps) => (
            <Text
                text={moment(item["created_at"]).format("YYYY.MM.DD HH:mm")}
                customStyle={{ fontSize: "12px", fontWeight: "400" }}
            />
        ),
        content_id: ({ item }: TableItemProps) => (
            <>
                {item["content_id"] === null ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            type="main-outline"
                            label="생성"
                            customStyle={{
                                width: "80px",
                                height: "32px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                            onClickHandler={() => goPortfolioEnroll(item)}
                        />
                    </div>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            type="red-outline"
                            label="수정"
                            customStyle={{
                                width: "80px",
                                height: "32px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                            onClickHandler={() => goPortfolioEnroll(item)}
                        />
                    </div>
                )}
            </>
        ),
    };

    return (
        <React.Fragment>
            <StyledWrapper>
                {loading && data ?
                <Skeleton />
                :
                (data && data.length !==0 )&&
                <>
                    <TableContainer>
                        <DataTable
                            from="Series"
                            items={data}
                            itemKey="series_id"
                            headers={headers}
                            customHeaders={customHeaders}
                            columns={columns}
                        />
                        <ScrollToTopBtn />
                    </TableContainer>
                    <Pagination pageCount={pageCount} totalVisible={totalVisible} from="Series"/>    
                </>
                }
            </StyledWrapper>
            <ModalPortal>
                <SetSettlementRatioModal
                    from="series-update"
                    show={showSetSettlementRatioModal}
                    close={closeModal}
                />
            </ModalPortal>
        </React.Fragment>
    );
}

export default SeriesTableContainer;

const headers = [
    { text: "시리즈명", value: "series_name", width: "300px" },
    { text: "저자명", value: "author", width: "300px" },
    { text: "정산비율", value: "ratioAll", width: "240px", sort: false },
    { text: "등록일시", value: "created_at", width: "160px" },
    { text: "스튜디오", value: "content_id", width: "120px", textAlign: "center" },
];

const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
