import styled from "styled-components";
import MakeTypeContainer from "@/components/molecules/ebook-maker/form/MakeTypeContainer";
import FileTypeContainer from "@/components/molecules/ebook-maker/form/FileTypeContainer";
import BaseDataContainer from "@/components/molecules/ebook-maker/form/BaseDataContainer";
import FileUploadContainer from "@/components/molecules/ebook-maker/form/FileUploadContainer";
import ButtonContainer from "@/components/molecules/ebook-maker/form/ButtonContainer";

export default function Form({ stepHandler }: FormProps) {
    return (
        <FormContainer>
            <MakeTypeContainer />
            <FileTypeContainer />
            <BaseDataContainer />
            <FileUploadContainer />
            <ButtonContainer stepHandler={stepHandler} />
        </FormContainer>
    )
}


const FormContainer = styled.div``

interface FormProps {
    stepHandler: (step: number, disabled: boolean) => void;
}