import api from "@/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export interface Question {
    id: number,
    category: string,
    question: string,
    answer: string,
    toggle?: boolean,
}

export interface QuestionState {
    data: Array<Question>,
    filteredData: Array<Question>,
    loading: boolean,
    hasError: boolean,
}

export interface dataFormat {
    status: string,
    data: Question[],
    message: string
}

export interface QuestionPayload {
    data: dataFormat,
    category?: string 
}

export const getFaqList = createAsyncThunk("question/getFaqList", async (_, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/faq/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})



const initialState: QuestionState = {
    data: [],
    filteredData: [],
    loading: false,
    hasError: false
} 

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        filterQuestion (state, action) {
            let category = action.payload.category;
            if (category === "전체") {
                state.filteredData = state.data;
            } else {
                state.filteredData = state.data.filter((n) => n.category === category);
            }
        },
        searchFilter (state, action) {
            let term = action.payload.term;
            let updatedData;
            if (action.payload.category === "전체") {
                updatedData = state.data;
            } else {
                updatedData = state.data.filter((data) => data.category === action.payload.category);
            }
            state.filteredData = updatedData.filter((data)=> data.question.includes(term));
        },
        setToggle (state, action) {
            const questionId = action.payload;
            const findItem = state.filteredData.findIndex((data) => data.id === questionId);
            if (findItem !== -1) {
                state.filteredData[findItem].toggle = !state.filteredData[findItem].toggle;
            } 
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFaqList.fulfilled, (state, action) => {
            const payloadData = action.payload.data.map((question: Question)=> ({...question, toggle: false }));
            if (action.payload.status === "success") {
                state.data = payloadData;
                state.filteredData = payloadData;
            }
        });
    }
})

export const questionActions = questionSlice.actions;

export default questionSlice.reducer;