import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import SelectBox from "@/components/atoms/selectBox/index";
import Text from "@/components/atoms/text";
import { useState } from "react";
import { pubMyPageActions } from "@/pages/mypage/publisher/slice";

export default function SetAuthModalBody() {
    const dispatch = useAppDispatch();
    const authData = useAppSelector((state: RootState) => state.pubMyPage.authData);
    const options1 = [
        {name: "조회 및 변경 가능", value: "1111"},
        {name: "조회 가능", value: "1000"},
        {name: "권한 없음", value: "0000"}
    ]
    const options2 = [
        {name: "사용가능", value: "1"},
        {name: "사용불가", value: "0"},
    ]

    const [data, setData] = useState([
        {
            id: "auth_st_entry_daily",
            name: "판매입력 - 일별판매",
            defaultValue: authData.auth_st_entry_daily === '1111' ?  options1[0] 
            : (authData.auth_st_entry_daily === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_entry_daily, 
        },
        {
            id: "auth_st_entry_monthly",
            name: "판매입력 - 월정산",
            defaultValue: authData.auth_st_entry_monthly === '1111' ?  options1[0] 
            : (authData.auth_st_entry_monthly === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_entry_monthly,
        },
        {
            id: "auth_st_entry_mg",
            name: "판매입력 - 선인세",
            defaultValue: authData.auth_st_entry_mg === '1111' ?  options1[0] 
            : (authData.auth_st_entry_mg === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_entry_mg,
        },
        {
            id: "auth_st_entry_etc",
            name: "판매입력 - 기타지급금",
            defaultValue: authData.auth_st_entry_etc === '1111' ?  options1[0] 
            : (authData.auth_st_entry_etc === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_entry_etc,
        },
        {
            id: "auth_st_inquiry_daily",
            name: "판매조회 - 일별판매",
            defaultValue: authData.auth_st_inquiry_daily === '1111' ?  options1[0] 
            : (authData.auth_st_inquiry_daily === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_inquiry_daily,
        },
        {
            id: "auth_st_inquiry_monthly",
            name: "판매조회 - 월정산",
            defaultValue: authData.auth_st_inquiry_monthly === '1111' ?  options1[0] 
            : (authData.auth_st_inquiry_monthly === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_inquiry_monthly,
        },
        {
            id: "auth_st_inquiry_mg",
            name: "판매조회 - 선인세",
            defaultValue: authData.auth_st_inquiry_mg === '1111' ?  options1[0] 
            : (authData.auth_st_inquiry_mg === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_inquiry_mg,
        },
        {
            id: "auth_st_inquiry_etc",
            name: "판매조회 - 기타지급금",
            defaultValue: authData.auth_st_inquiry_etc === '1111' ?  options1[0] 
            : (authData.auth_st_inquiry_etc === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_inquiry_etc,
        },
        {
            id: "auth_st_base_authorenroll",
            name: "기초데이터 - 작가 등록",
            defaultValue: authData.auth_st_base_authorenroll === '1111' ?  options1[0] 
            : (authData.auth_st_base_authorenroll === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_base_authorenroll,
        },
        {
            id: "auth_st_base_seriesenroll",
            name: "기초데이터 - 시리즈명 등록",
            defaultValue: authData.auth_st_base_seriesenroll === '1111' ?  options1[0] 
            : (authData.auth_st_base_seriesenroll === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_base_seriesenroll,
        },
        {
            id: "auth_st_base_platformmanagement",
            name: "기초데이터 - 플랫폼 관리",
            defaultValue: authData.auth_st_base_platformmanagement === '1111' ?  options1[0] 
            : (authData.auth_st_base_platformmanagement === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_base_platformmanagement,
        },
        {
            id: "auth_st_base_exceluploadmanagement",
            name: "기초데이터 - 시리즈/작품명 연동",
            defaultValue: authData.auth_st_base_exceluploadmanagement === '1111' ?  options1[0] 
            : (authData.auth_st_base_exceluploadmanagement === '0000' ? options1[2] : options1[1]),
            value: authData.auth_st_base_exceluploadmanagement,
        },
    ]);

    const [numData, setNumData] = useState([
        {
            id: "auth_st_dashboard",
            name: "대시보드",
            defaultValue: authData.auth_st_dashboard === 0 ? options2[1] : options2[0],
            value: authData.auth_st_dashboard,
        },
        {
            id: "auth_ebook_maker",
            name: "전자책 제작",
            defaultValue: authData.auth_ebook_maker === 0 ? options2[1] : options2[0],
            value: authData.auth_ebook_maker,
        },
        {
            id: "auth_st_total_sales",
            name: "입출금 종합",
            defaultValue: authData.auth_st_total_sales === 0 ? options2[1] : options2[0],
            value: authData.auth_st_total_sales,
        },
        {
            id: "auth_st_inquiry_statement",
            name: "정산서 조회",
            defaultValue: authData.auth_st_inquiry_statement === 0 ? options2[1] : options2[0],
            value: authData.auth_st_inquiry_statement,
        },
        {
            id: "auth_st_issue_statement",
            name: "정산서 발급",
            defaultValue: authData.auth_st_issue_statement === 0 ? options2[1] : options2[0],
            value: authData.auth_st_issue_statement,
        },
    ])

    const changeValueType = async (id: string, value: any) => {
        const selectedValue = value.value;
        const index = data.findIndex(d => d.id === id);
        const newData = [...data];
        newData[index] = {...newData[index], value: selectedValue}

        setData(newData);
        dispatch(pubMyPageActions.tmpUpdateAuthData({id, value: selectedValue}));
    }
    
    const changeType = async(id: string, value: any) => {
        const selectedValue = Number(value.value);
        const index = numData.findIndex(d => d.id === id);
        const newNumData = [...numData];
        newNumData[index] = {...newNumData[index], value: selectedValue};
        
        setNumData(newNumData);
        dispatch(pubMyPageActions.tmpUpdateAuthData({id, numValue: selectedValue}));
    }

    const textStyled = {
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "15px"
    }

    return(
        <SetAuthModalBodyStyled>
            {data && data.map((d, i) => (
                <FieldRow key={i}>
                    <Text key={i} text={d.name} customStyle={textStyled} />
                    <SelectBoxStyled>
                        <SelectBox
                            labelKey="name"
                            valueKey="value"
                            selected={d.value}
                            options={options1}
                            onClickValueFunc={(value: any) => changeValueType(d.id, value)}
                        />
                    </SelectBoxStyled>
                </FieldRow>
            ))}
            {numData && numData.map((d, i) => (
                <FieldRow key={i}>
                    <Text key={i} text={d.name} customStyle={textStyled} />
                    <SelectBoxStyled>
                        <SelectBox
                            labelKey="name"
                            valueKey="value"
                            selected={d.value}
                            options={options2}
                            onClickValueFunc={(value: any) => changeType(d.id, value)}
                        />
                    </SelectBoxStyled>
                </FieldRow>
            ))}
        </SetAuthModalBodyStyled>
    )
}

const SetAuthModalBodyStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    overflow-x: hidden;
    height: 430px;
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    padding: 7.5px 0px;
    justify-content: space-between;
    width: 440px;
`

const SelectBoxStyled = styled.div`
    width: 200px;
    display: flex;
    alignItems: center;
`
