import styled from "styled-components";
import Button from "@/components/atoms/button";
import ReadOnlyInput from "@/components/molecules/portfolio/publisher/edit/ReadOnlyInput";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";
import SearchIsbnModal from "@/components/molecules/portfolio/publisher/edit/SearchIsbnModal";
import EditIsbnModal from "@/components/molecules/portfolio/publisher/edit//EditIsbnModal";
import { useEffect, useState } from "react";
import { useAppSelector, RootState, useAppDispatch } from "@/modules/store";
import { ReactComponent as AddIcon } from "@/assets/icons/add.svg";
import { ReactComponent as MinusIcon } from "@/assets/icons/minus.svg";
import { colors } from "@/assets/styles/theme";
import RetailDateContainer from "@/components/molecules/portfolio/publisher/edit/RetailDateContainer";
import Text from "@/components/atoms/text";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { SalesPlatform, Promotion, SeriesSearchResult } from "@/pages/portfolio/publisher/types";
import { updatePortfolio } from "@/pages/portfolio/publisher/slice";
import PlatformTagList from "@/components/molecules/portfolio/publisher/edit/PlatformTagList";
import { Platform } from "@/pages/common/platformSlice";
import { useSearchParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import type { SearchSeriesResult } from "@/types/searchSeries";
import CirculationStopModal from "@/components/molecules/common/modal/CirculationStopModal";

export default function BookInfoContainer() {
    const { series_id, series_name, publisher_name, author_name, isbnLabel, publishAt, isbnList, salesPlatform, coverFiles, promotionList, thumbnailTop } = useAppSelector((state: RootState) => state.pubPortfolio);
    const { platformList } = useAppSelector((state: RootState) => state.platform);
    const [searchSeriesModal, showSearchSeriesModal] = useState(false);
    const [searchIsbnModal, showSearchIsbnModal] = useState(false);
    const [editIsbnModal, showEditIsbnModal] = useState(false);
    const [tmpPlatformList, setTmpPlatformList] = useState<Platform[]>([]);
    const [showMore, setShowMore] = useState(false);
    const [circulationStopModal, showCirculationStopModal] = useState(false);
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const tmpList = JSON.parse(JSON.stringify(platformList));
        for (let s of salesPlatform) {
            for (let t of tmpList) {
                if (s.platform_id === t.platform_id) {
                    t.checked = true;
                    break;
                }
            }
        }
        setTmpPlatformList(tmpList);
    }, [platformList, salesPlatform])

    useEffect(() => {
        if (!publishAt) {
            dispatch({ type: "pubPortfolio/setPublishAt", payload: moment().format("YYYYMMDD") });
        }
    }, [publishAt])

    const setThumbnail = () => {
        if (!coverFiles.length) return alert("warning", "확인", "표지 이미지를 업로드해주세요.");
        dispatch({ type: "pubPortfolio/setThumbnailActive", payload: true });
    }

    const update = async () => {
        try {
            const { status, message } = editValidate(series_id, promotionList, salesPlatform);
            if (!status) return alert("warning", "주의", message);

            const answer = await alert("confirm", "등록 확인", "등록 하시겠습니까?");

            if (answer === "Y") {

                await loadingOn();
                const content_id = searchParams.get("id") || "";
                // AWS S3 업로드를 위해 Form 사용 
                let formData = new FormData();

                // 표지 이미지
                if (coverFiles.length && coverFiles[0].name) {
                    const bucket = `${process.env.NODE_ENV == "development" ? 'dev' : 'prod'}/images/portfolio/book_cover`
                    const ext = coverFiles[0].name.split(".").pop();
                    formData.append("bucket", bucket);

                    const splitedData = coverFiles[0].file.split(",");
                    const byteString = atob(
                        splitedData.length == 1 ? splitedData[0] : splitedData[1]
                    );
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);

                    const mimeType = coverFiles[0].file.split(",")[0]
                                                        .split(":")[1]
                                                        .split(";")[0];

                    for (var i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([ab], { type: mimeType });

                    formData.append("cover_file", blob, `${series_id}.${ext}`);
                }
                formData.append("cover_exist", coverFiles.length ? `${true}` : `${false}`);
                formData.append("thumbnail_top", `${Math.round(thumbnailTop * 100) / 100}`)

                // 유통 정보
                formData.append("series_id", `${series_id}`);

                // 출간일
                const minCirculateAt = Math.min(...salesPlatform.map(s => s.circulation_period.length && Number(s.circulation_period[0].start_circulate_at)));
                const publish_at = minCirculateAt === Infinity ? "" : String(minCirculateAt);

                formData.append("publish_at", publish_at);

                formData.append("isbn", JSON.stringify(isbnList));

                // 판매처 및 유통시작일
                formData.append("salesPlatform", JSON.stringify(salesPlatform));

                // 프로모션 정보
                formData.append("promotion", JSON.stringify(promotionList));

                formData.append("content_id", content_id);

                await dispatch(updatePortfolio(formData)).unwrap();

                await alert("confirm", '등록 완료', `작품 등록이 완료되었습니다.`);
                navigate(`/portfolio/detail?id=${content_id}`);
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }

    }

    // 시리즈 모달에서 시리즈 선택
    const applySeries = async (data: SearchSeriesResult) => {
        try {
            const selectedSeries = data.selectedSeries as SeriesSearchResult;
            dispatch({ type: "pubPortfolio/setRetailInfo", payload: selectedSeries });
        } catch (err) {
            alert("error", "오류 발생", "시리즈 적용 중 오류가 발생하였습니다.");
        }
    }

    return (
        <StyledContainer>
            <TopLineWrapper>
                <TitleWrapper>유통정보</TitleWrapper>
                <ButtonWrapper>
                    <Button 
                        type="red-outline"
                        label="유통 중지"
                        onClickHandler={() => showCirculationStopModal(true)}
                    />
                    <Button 
                        type="main-outline"
                        label="썸네일 설정"
                        onClickHandler={setThumbnail}
                    />
                    <Button 
                        type="main"
                        label="등록"
                        onClickHandler={update}
                    />
                </ButtonWrapper>
            </TopLineWrapper>
            <ContentWrapper>
                <RowWrapper>
                    <FieldNameWrapper>시리즈명</FieldNameWrapper>
                    <FieldContentWrapper>
                        <ReadOnlyInput 
                            type="search"
                            value={series_name}
                            placeholder="시리즈명"
                            width="280px"
                            onClickHandler={() => showSearchSeriesModal(true)}
                        />
                    </FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>출판사</FieldNameWrapper>
                    <FieldContentWrapper>
                        <ReadOnlyInput 
                            value={publisher_name}
                            placeholder="출판사"
                        />
                    </FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>저자명</FieldNameWrapper>
                    <FieldContentWrapper>
                        <ReadOnlyInput 
                            value={author_name}
                            placeholder="저자명"
                        />
                    </FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>ISBN</FieldNameWrapper>
                    <FieldContentWrapper>
                        <ReadOnlyInput 
                            value={isbnLabel}
                            placeholder="ISBN"
                        />
                        {Boolean(isbnLabel.length) &&
                            <Button
                                type="red-outline"
                                label="삭제"
                                onClickHandler={() => showEditIsbnModal(true)}
                                customStyle={{ margin: "0 0 0 8px" }}
                            />
                        }
                        <Button 
                            type="main"
                            label="추가"
                            onClickHandler={() => showSearchIsbnModal(true)}
                            customStyle={{ margin: "0 0 0 8px"}}
                        />
                    </FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>플랫폼</FieldNameWrapper>
                    <FieldContentWrapper>
                        <IconWrapper>
                            {showMore ? 
                                <MinusIcon fill={colors.blue500} onClick={() => setShowMore(false)} /> :
                                <AddIcon fill={colors.blue500} onClick={() => setShowMore(true)} />
                            }
                        </IconWrapper>
                        <PlatformTagList
                            items={tmpPlatformList}
                            keyName={"platform_id"}
                            valueName={"platform_name"}
                            showMore={showMore}
                        />
                    </FieldContentWrapper>
                </RowWrapper>
                <CirculationWrapper>
                    <FieldNameWrapper>유통 현황</FieldNameWrapper>
                    <FieldContentWrapper>
                        {salesPlatform.length ? 
                            <RetailDateContainer 
                                salesPlatformList={salesPlatform}
                            /> : 
                            <Text text="플랫폼을 선택해주세요." customStyle={{ fontSize: "14px", fontWeight: "400", color: colors.gray500 }} />
                        }
                    </FieldContentWrapper>
                </CirculationWrapper>
            </ContentWrapper>
            <ModalPortal>
                <SearchSeriesModal
                    from="portfolio"
                    show={searchSeriesModal}
                    close={() => showSearchSeriesModal(false)}
                    platformId={0} 
                    withRatio={false} 
                    multiSelect={false} 
                    applySeries={applySeries}
                />
            </ModalPortal>
            <ModalPortal>
                <SearchIsbnModal
                    show={searchIsbnModal}
                    close={() => showSearchIsbnModal(false)}
                    from="edit-portfolio"
                />
            </ModalPortal>
            <ModalPortal>
                <EditIsbnModal
                    show={editIsbnModal}
                    close={() => showEditIsbnModal(false)}
                    from="edit-portfolio"
                />
            </ModalPortal>
            <ModalPortal>
                <CirculationStopModal
                    from="edit-portfolio"
                    show={circulationStopModal}
                    close={() => showCirculationStopModal(false)}
                />
            </ModalPortal>
        </StyledContainer>
    )
}

const editValidate = (series_id: number, promotionList: Promotion[], salesPlatform: SalesPlatform[]) => {
    if (!series_id) {
        return {
            status: false,
            message: "시리즈를 선택해주세요."
        }
    }

    const salesPlatformIds = salesPlatform.map(s => s.platform_id);
    for (let p of promotionList) {
        if (!Number(p.platform_id)) {
            return {
                status: false,
                message: "플랫폼을 선택해주세요."
            }
        }

        if (!salesPlatformIds.includes(Number(p.platform_id))) {
            return {
                status: false,
                message: "유통정보에 등록되지 않은 플랫폼이 존재합니다.\n프로모션 정보를 확인해주세요."
            }
        }

        if (!p.promotion_name) {
            return {
                status: false,
                message: "프로모션명을 입력해주세요."
            }
        }

        if (!p.promotion_desc) {
            return {
                status: false,
                message: "프로모션 설명을 입력해주세요."
            }
        }

        if (!p.promotion_start_at) {
            return {
                status: false,
                message: "시작일을 선택해주세요."
            }
        }

        if (!p.promotion_no_end_date && !p.promotion_end_at) {
            return {
                status: false,
                message: "종료일을 선택해주세요."
            }
        }
    }

    for (let s of salesPlatform) {
        const error = s.circulation_period.find(c => !c.start_circulate_at);
        if (error) {
            return {
                status: false,
                message: "유통 시작일을 입력해주세요."
            }
        }
    }

    return {
        status: true,
        message: "",
    }
}

const base64Decode = (dataURI: any, mimeType?: any) => new Promise((resolve, reject) => {
    const splitedData = dataURI.split(",");
    const byteString = atob(
        splitedData.length == 1 ? splitedData[0] : splitedData[1]
    );
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    mimeType =
        mimeType ||
        dataURI
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeType });
    resolve(blob);
})

const StyledContainer = styled.div`
    width: 790px;
`

const TopLineWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 28px;
`

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
`

const ButtonWrapper = styled.div`
    display: flex;
    width: 316px;
    justify-content: space-between;
`

const ContentWrapper = styled.div``

const CirculationWrapper = styled.div`
    display: flex;
`

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    min-height: 60px;
`

const FieldNameWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    height: 60px;
`

const FieldContentWrapper = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div`
    position: absolute;

    svg {
        position: relative;
        right: 70px;
        cursor: pointer;
    }
`