import Button from "@/components/atoms/button";
import styled from "styled-components";
import Text from "@/components/atoms/text";
import { ReactComponent as SuccessIcon } from "@/assets/icons/success_round.svg";
import { useNavigate } from "react-router-dom";
import { colors } from "@/assets/styles/theme";
import IconButton from "@/components/molecules/basedata-manage/IconButton";

export default function Done() {
    const navigate = useNavigate();

    return (
        <>
            <IconWrap>
                <SuccessIcon width={60} height={60} fill={colors.blue500}/>
                <Text text="회원가입 완료" customStyle={{ fontSize: "24px", fontWeight: "700", lineHeight: "30px", color: colors.blue500, margin: "24px 0 0" }} />
            </IconWrap>
            <TextWrap>글링 회원가입이<br/>정상적으로 처리되었습니다.</TextWrap>
            <BtnWrap>
                <IconButton
                    type="left"
                    label="메인 페이지"
                    customStyle={{ width: "194px",
                                    height: "60px",
                                    fontSize: "20px",
                                    fontWeight: "500" }}
                    onClickHandler={() => navigate("/")}
                />
                <Button
                    type="main"
                    label="로그인"
                    customStyle={{ width: "194px",
                                    height: "60px",
                                    fontSize: "20px",
                                    fontWeight: "500" }}
                    onClickHandler={() => navigate("/signin")}
                />
            </BtnWrap>
        </>
    )
}

const IconWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TextWrap = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-top: 40px;
`

const BtnWrap = styled.div`
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
`