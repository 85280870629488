import styled from 'styled-components'
import Author from '@/assets/images/introduce-image/author-profile.svg'
import Viewcommz from '@/assets/images/introduce-image/viewcommz-profile.svg'
import ReviewCardContainer from '@/components/molecules/introduce/review/ReviewCardContainer';

export default function ReviewCards() {
    const reviewArr = [
        {
            image: Author,
            job: '작가',
            name: 'A',
            summary: '"매출 확인을 직접 할 수 있어 좋아요."',
            description: '매번 출판사에 매출을 물어보기가 불편했는데,<br/>매일 직접 판매현황과 추이를 확인할 수 있어 좋았습니다.'
        },
        {
            image: Author,
            job: '작가',
            name: 'B',
            summary: '"상세 내역까지 확인이 가능해요."',
            description: '플랫폼별은 물론 회차별이나 권별로 얼마나 팔렸는지 확인이 가능해요.<br/>연독률 등 상세 지표까지 가늠해볼 수 있어 큰 도움이 되었습니다.'
        },
        {
            image: Viewcommz,
            job: '출판사 뷰컴즈',
            name: '직원',
            summary: '"대부분의 업무를 할 수 있어요."',
            description: '전자책 제작도 편리하고<br/>진행중인 프로모션의 현황을 알수 있는게 좋아요.'
        }
    ]
    return (
        <StyledContainer>
            {reviewArr.map((card, idx) => 
                <ReviewCardContainer 
                    key={idx}
                    id={idx}
                    reviewCard={card}/>
            )}
        </StyledContainer>
    )    
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`