import { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Text from "@/components/atoms/text";
import theme from "@/assets/styles/theme";
import { ReactComponent as Logo } from "@/assets/icons/gling-biz-logo.svg";
import { ReactComponent as Down } from "@/assets/icons/down.svg";
import { ReactComponent as Up } from "@/assets/icons/up.svg";
import { colors } from "@/assets/styles/theme";

export default function Footer({ pathname }: { pathname: string }) {
    const [showInfo, setInfo] = useState(false);

    const text = "사업자 정보";
    const textStyle = {
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "17.5px",
        color: "black",
    };

    const titleInfoStyle = {
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "21px",
        color: "black",
    };

    const contentInfoStyle = {
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "12px",
        color: "black",
    };

    const changeUpDown = () => {
        if (showInfo) setInfo(false);
        else setInfo(true);
    };

    const policyTexts = ["이용약관", "개인정보처리방침", "이용가이드"];
    const policyUrl = ["info/agreement", "info/privacy", "guide"];

    const FilteredPolicyText = policyTexts.map((text, idx) => <CustomNav title={text} to={policyUrl[idx]} key={idx} />);

    const copyRight = "Copyright ⓒ viewcommz Corp, All Rights Reserved.";

    const copyRightStyle = {
        fontWeight: "400",
        fontSize: "10px",
        color: "#121417",
    };

    return (
        <StyledContainer showInfo={showInfo} pathName={pathname}>
            <StyledTitleWrapper>
                <Logo />
            </StyledTitleWrapper>
            <StyledDetailWrapper>
                <StyledInfomationWrapper onClick={changeUpDown}>
                    <Text text={text} customStyle={textStyle} />
                    {showInfo ? (
                        <Up onClick={changeUpDown} className="upDown" />
                    ) : (
                        <Down fill={colors.black} onClick={changeUpDown} className="upDown" />
                    )}
                </StyledInfomationWrapper>
                <StyledPolicyWrapper>{FilteredPolicyText}</StyledPolicyWrapper>
            </StyledDetailWrapper>
            {showInfo ? (
                <InfoWrapper>
                    <OneLineText>
                        <Text text="뷰컴즈 주식회사" customStyle={titleInfoStyle}></Text>
                    </OneLineText>
                    <OneLineText>
                        <Text text="대표" customStyle={titleInfoStyle}></Text>
                        <Text text="&nbsp;: 김학성 |&nbsp;" customStyle={contentInfoStyle}></Text>
                        <Text text="전화" customStyle={titleInfoStyle}></Text>
                        <Text text="&nbsp;: 1811-8389 |&nbsp;" customStyle={contentInfoStyle}></Text>
                        <Text text="이메일" customStyle={titleInfoStyle}></Text>
                        <Text text="&nbsp;: help@gling.co.kr" customStyle={contentInfoStyle}></Text>
                    </OneLineText>
                    <OneLineText>
                        <Text text="사업자 등록번호" customStyle={titleInfoStyle}></Text>
                        <Text text="&nbsp;: 492-88-01088 |&nbsp;" customStyle={contentInfoStyle}></Text>
                        <Text text=" 통신판매업신고번호" customStyle={titleInfoStyle}></Text>
                        <Text text="&nbsp;: 2022-서울영등포-1768" customStyle={contentInfoStyle}></Text>
                    </OneLineText>
                    <OneLineText>
                        <Text text="주소" customStyle={titleInfoStyle}></Text>
                        <Text
                            text="&nbsp;: 서울시 영등포구 당산로 171 금강펜테리움IT타워 1301호"
                            customStyle={contentInfoStyle}
                        ></Text>
                    </OneLineText>
                </InfoWrapper>
            ) : (
                <></>
            )}
            <StyledBorder />
            <Text text={copyRight} customStyle={copyRightStyle} />
        </StyledContainer>
    );
}

export function CustomNav({ title, to }: { title?: string; to: string }) {
    const navStyle = {
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "12px",
        textDecoration: "none",
        margin: "0px 0px 0px 18px",
        color: theme.colors.black,
    };
    return (
        <NavLink to={to} style={navStyle}>
            {title}
        </NavLink>
    );
}

const StyledContainer = styled.footer<{ showInfo: boolean; pathName: string }>`
    width: 100%;
    min-width: 1200px;
    height: ${(props) => (props.showInfo ? "260px" : "160px")};
    padding: 10px calc((100% - 1200px) / 2);
    display: flex;
    flex-direction: column;
    background-color: ${(props) => (props.pathName === "/dashboard" ? theme.colors.gray100 : theme.colors.gray50)};
    justify-content: center;
`;
const StyledTitleWrapper = styled.div``;

const StyledDetailWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0px;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px 0px;
`;

const OneLineText = styled.div`
    display: flex;
    align-items: center;
`;

const StyledInfomationWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 0;
    height: 24px;
    .upDown {
        margin-left: 8px;
    }
    cursor: pointer;
`;

const StyledPolicyWrapper = styled.div`
    display: flex;
`;

const StyledBorder = styled.div`
    width: 100%;
    margin: 8px 0;
    border-top: 1px solid ${theme.colors.gray300};
`;
