import api from "@/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CommonType } from "@/types/dataType";

const name = "common/search/series";
const initialState = {
    searchLoading: false,
};

export const getSeriesAll = createAsyncThunk(
    `${name}getSeriesAll`,
    async (apiParams: SearchAllApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/excelUpload/searchSeries/select", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

export const getSeriesByAuthor = createAsyncThunk(
    `${name}getSeriesByAuthor`,
    async (apiParams: SearchByAuthorApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/salesEntry/search/series/select", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

export const getSeriesForPortfolio = createAsyncThunk(
    `${name}getSeriesForPortfolio`,
    async (apiParams: SearchForPortfolioApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/portfolio/search/series", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

export const getSeriesForMg = createAsyncThunk(
    `${name}getSeriesForMg`,
    async (apiParams: SearchForMgApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/salesEntry/search/seriesAll/select", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const searchSeriesSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // getSeriesAll
        builder
            .addCase(getSeriesAll.pending, (state, action) => {
                state.searchLoading = true;
            })
            .addCase(getSeriesAll.fulfilled, (state, action) => {
                state.searchLoading = false;
            });

        // getSeriesByAuthor
        builder
            .addCase(getSeriesByAuthor.pending, (state, action) => {
                state.searchLoading = true;
            })
            .addCase(getSeriesByAuthor.fulfilled, (state, action) => {
                state.searchLoading = false;
            });

        // getSeriesForPortfolio
        builder
            .addCase(getSeriesForPortfolio.pending, (state, action) => {
                state.searchLoading = true;
            })
            .addCase(getSeriesForPortfolio.fulfilled, (state, action) => {
                state.searchLoading = false;
            });
    },
});

interface SearchAllApiParams {
    keyword: string;
    platform_id: CommonType.Id;
    stYearmon: CommonType.Date;
}
interface SearchByAuthorApiParams {
    keyword: string;
    author_id: CommonType.Id;
}
interface SearchForPortfolioApiParams {
    keyword: string;
}
interface SearchForMgApiParams {
    author_id: CommonType.Id;
    platform_id: CommonType.Id;
}

export const searchSeriesActions = searchSeriesSlice.actions;
export default searchSeriesSlice.reducer;
