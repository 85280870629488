import styled from "styled-components";
import UploadContainer from "@/components/molecules/common/upload";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { CoverFile } from "@/pages/portfolio/publisher/types";
import Button from "@/components/atoms/button";
import ReactCrop, { Crop, makeAspectCrop } from "react-image-crop";
import React, { useState } from "react";
import "react-image-crop/dist/ReactCrop.css";

export default function CoverContainer() {
    const { coverFiles, thumbnailActive, thumbnailTop } = useAppSelector((state: RootState) => state.pubPortfolio);
    const [crop, setCrop] = useState<Crop>();
    const [aspect, setAspect] = useState<number | undefined>(272 / 200);
    const [cropTop, setCropTop] = useState(0);
    const dispatch = useAppDispatch();

    let imgSrc = "";
    if (coverFiles.length) {
        if (coverFiles[0].name) {
            imgSrc = coverFiles[0].file;
        } else {
            const random = Math.random();
            imgSrc = `${coverFiles[0].file}?a=${random}`
        }
    } 

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (aspect) {
            const { width, height } = e.currentTarget;
            let tmpCrop = makeAspectCrop(
                { unit: '%', width: 100 },
                aspect,
                width,
                height,
            );
            tmpCrop.y = thumbnailTop;
            setCrop(tmpCrop);

        }
    }

    const onComplete = (c: any) => {
        setCropTop(c.y); // px단위 시작점 top 
    }

    const coverUpload = (fileList: CoverFile[]) => {
        setCrop(undefined);
        dispatch({ type: "pubPortfolio/setThumbnailTop", payload: 0})
        dispatch({ type: "pubPortfolio/setCoverFiles", payload: fileList });
    }

    const cancelSetThumbnail = () => {
        setCropTop(0);
        dispatch({ type: "pubPortfolio/setThumbnailActive", payload: false });
    }

    const applyThumbnail = () => {
        const topPercent = cropTop / 554 * 100;
        dispatch({ type: "pubPortfolio/setThumbnailActive", payload: false });
        dispatch({ type: "pubPortfolio/setThumbnailTop", payload: topPercent})
    }

    return (
        <StyledContainer>
            {imgSrc 
                ? <PreviewContainer>
                    {thumbnailActive 
                        ? <CropWrapper>
                            <ReactCrop
                                crop={crop}
                                aspect={aspect}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={onComplete}
                                locked={true}
                            >
                                <img 
                                    alt="Crop mme"
                                    src={imgSrc} 
                                    onLoad={onImageLoad} 
                                />
                            </ReactCrop>
                            <ButtonWrapper>
                                <Button
                                    type="gray-outline"
                                    label="취소"
                                    onClickHandler={cancelSetThumbnail}
                                    customStyle={{ margin: "0 8px 0 0"}}
                                />
                                <Button
                                    type="main"
                                    label="확인"
                                    onClickHandler={applyThumbnail}
                                />
                            </ButtonWrapper>
                          </CropWrapper>
                        : <ImgWrapper>
                            <img src={imgSrc} alt="preview" />
                            <DeleteBtnWrapper>
                                <Button
                                    type="red-outline"
                                    label="삭제"
                                    onClickHandler={() => coverUpload([])}
                                />
                            </DeleteBtnWrapper>
                          </ImgWrapper>

                    }
                  </PreviewContainer> 
                : <UploadContainer 
                      accept={["jpg", "png"]}
                      uploadHandler={coverUpload}
                      height={554}
                  />
            }
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 380px;
    margin-right: 30px;
`

const PreviewContainer = styled.div`
    position: relative;
    width: 100%;
    height: 554px;
`

const CropWrapper = styled.div`
    width: 100%;
    height: 100%;

    .ReactCrop {
        width: 100%;
        height: 100%;

        .ReactCrop__child-wrapper {
            width: 100%;
            height: 100%;
        }
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`

const ButtonWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 120px;
`

const ImgWrapper = styled.div`
    width: 100%;
    height: 100%;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`

const DeleteBtnWrapper = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 80px;
`