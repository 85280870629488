import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";
import { PortfolioState, SortOptions, ViewOptions } from "./types";

const initialState: PortfolioState = {
    loading: false,

    // 작품 목록
    sortOption: SortOptions.NEW,
    viewOption: ViewOptions.CARD,
    portfolioList: [],

    // 작품 상세
    contentBaseInfo: {
        author_name: "",
        content_id: 0,
        cover_path: null,
        isbn: null,
        publish_at: "",
        publisher_name: "",
        series_id: 0,
        series_name: "",
    },
    contentPlatformInfo: [],
    contentSalesInfo: {
        accumulatedSales: {
            amount: 0,
            quantity: 0
        },
        dailySalesHistory: {
            labels: [],
            salesHistory: [],
        },
        monthlySalesHistory: {
            labels: [],
            salesHistory: []
        }
    },
    latestPromotion: [],
    timelineData: {
        totalYearmonList: [],
        eachPlatformHistory: []
    },
}

// 전체 작품 목록 조회
export const getPortfolioList = createAsyncThunk("portfolio/getPortfolioList", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/portfolio/author/content/list/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 작품 검색 (작가)
export const searchPortfolioList = createAsyncThunk("portfolio/searchPortfolioList", async (data: any, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/portfolio/author/search/content/list`, {
            keyword: data.keyword,
            searchOption: data.searchOption
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 포트폴리오 상세페이지 데이터 조회
export const getPortfolioDetail = createAsyncThunk("portfolio/getPortfolioDetail", async (content_id: number, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/portfolio/content/detail/select`, {
            content_id
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const authorPortfolioSlice = createSlice({
    name: "authorPortfolio",
    initialState,
    reducers: {
        initState: (state, { payload }) => {
            Object.assign(state, initialState);
        },
        setSortOption: (state, { payload }) => {

            state.sortOption = payload;
        },
        setViewOption: (state, { payload }) => {
            state.viewOption = payload;
        },
        setTimelineTotalYearmon: (state, { payload }) => {
            state.timelineData.totalYearmonList = payload;
        },
        setTimelineEachPlatformHistory: (state, { payload }) => {
            state.timelineData.eachPlatformHistory = payload;
        },
    },
    extraReducers: {
        [getPortfolioList.pending.type]: (state, { payload }) => {
            state.loading = true;
        },
        [getPortfolioList.fulfilled.type]: (state, { payload }) => {
            state.loading = false;
            state.portfolioList = payload.data;
        },

        [getPortfolioDetail.fulfilled.type]: (state, { payload }) => {
            const { contentBaseInfo, contentPlatformInfo, contentSalesInfo, latestPromotion, timelineData } = payload.data;
            state.contentBaseInfo = contentBaseInfo;
            state.contentPlatformInfo = contentPlatformInfo;
            state.contentSalesInfo = contentSalesInfo;
            state.latestPromotion = latestPromotion;
            state.timelineData = timelineData;
        },

        [searchPortfolioList.pending.type]: (state) => {
            state.loading = true;
        },
        [searchPortfolioList.fulfilled.type]: (state, { payload }) => {
            state.portfolioList = payload.data;
            state.loading = false;
        }
    }
});

export default authorPortfolioSlice.reducer;