import React from 'react';
import styled from 'styled-components'
import PortfolioFunction from '@/components/molecules/introduce/portfolio/PortfolioFunction';
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn';
import { ReactComponent as Dashboard} from '@/assets/icons/dashboard-landing.svg'
import { ReactComponent as Portfolio} from '@/assets/icons/portfolio.svg'
import { ReactComponent as Ebook} from '@/assets/icons/Ebook-landing.svg'
import { colors } from "@/assets/styles/theme";
export default function PortfolioFunctionContainer({tab, onIdChange}: functionProps) {
    
    const textStyle = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
        color: colors.black
    }
    
    const functionText = [{
        id: 1,
        text: '대시보드',
        style: textStyle
    },
    {
        id: 2,
        text: '포트폴리오',
        style: textStyle
    },
    {
        id: 3,
        text: 'E-BOOK',
        style: textStyle
    }];
    
    const texts = functionText; 
    const svgArr = [Dashboard, Portfolio, Ebook];

    const fadeInFunction = useScrollFadeIn('up', 1, 0, 0.1);
    
    return (
        <StyledContainer {...fadeInFunction}>
            <FunctionWrapper>
                {texts.map((textObj, idx) => 
                    <PortfolioFunction
                        key={textObj.id}
                        tab={tab}
                        id={idx}
                        label={textObj.text}
                        customStyle={textObj.style}
                        icon={svgArr[idx]}
                        onIdChange={onIdChange}
                    />
                )}
            </FunctionWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FunctionWrapper = styled.div`  
`

interface functionProps {
    tab: number,
    onIdChange: (e: React.MouseEvent<HTMLDivElement>, id: number) => void;
}