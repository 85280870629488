import { useEffect, useState } from "react";

const useClickOutside = ({ ref }: ClickOutsideProps) => {
    const [isOutsideClicked, setIsOutsideClicked] = useState(false);

    const handleClickOutside = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsOutsideClicked(true);
        } else {
            setIsOutsideClicked(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return isOutsideClicked;
};

interface ClickOutsideProps {
    ref: any;
}
export default useClickOutside;
