import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setParamsId } from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";

function useMgApi() {
    const {
        totalDate,
        selectedPlatform,
        contractStartDate,
        contractEndDate,
        mgType,
        complete,
        contractType,
        contractPrice,
        deductionPrice,
        remainPrice,
        target,
        startDateError,
        endDateError,
        initialDataLoaded,
        fetchLoading,
    } = useSelectorTyped((state: RootState) => state.inquiryMg);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!initialDataLoaded && fetchLoading) return;
        if (startDateError !== "" || endDateError !== "") return;

        dispatch(setParamsId());
        return () => { }

    }, [
        totalDate,
        selectedPlatform,
        contractStartDate,
        contractEndDate,
        mgType,
        complete,
        contractType,
        contractPrice,
        deductionPrice,
        remainPrice,
        target,
        dispatch
    ]);
}

export default useMgApi;
