export interface StatementState {
    specificationType: SpecificationType;
    yearmon: string;
    monthList: string[];

    publisherList: Publisher[];
    allCheck: boolean;
    currPublisher: Publisher;
}

// 정산서 발급 / 조회
export enum StatementType {
    Issue = "issue",
    Inquiry = "inquiry"
}

// 명세서 구분 (월정산 명세서 / 선인세 지급 명세서)
export enum SpecificationType {
    Monthly = "monthly",
    Mg = "mg"
}

export interface Publisher {
    id: number;
    publisher_id: number;
    publisher_name: string;
    checked: boolean;
}
