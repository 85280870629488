import styled from "styled-components";
import Text from "@/components/atoms/text";
import SalesUpDownChartWrapper from "@/components/molecules/dashboard/publisher/SalesUpDownChartWrapper";
import SelectBox from "@/components/atoms/selectBox/index";
import { ReactComponent as Incline } from "@/assets/icons/incline.svg";
import { ReactComponent as Decline } from "@/assets/icons/decline.svg";
import { useAppSelector, useAppDispatch } from "@/modules/store";
import { RootState } from "@/modules/store";
import theme, { colors } from "@/assets/styles/theme";
import { Platform } from "@/pages/common/types";
import { getSalesUpDown } from "@/pages/dashboard/publisher/slice";
import { Oval } from "react-loader-spinner";
import Tooltip from "@/components/molecules/common/tooltip/Tooltip";

export default function SalesUpDownChartContainer () {
    const { upDownType, salesUpDownIsLoading } = useAppSelector((state: RootState) => state.pubDashboard);
    const dispatch = useAppDispatch();

    const changeType = (type: string) => {
        dispatch({ type: "pubDashboard/setUpDownType", payload: type })
    }

    if (salesUpDownIsLoading) {
        return (
            <StyledContainer>
                <LoaderWrapper>
                    <Oval color={theme.colors.blue600} secondaryColor={theme.colors.blue100} />
                </LoaderWrapper>
            </StyledContainer>
        )
    }

    return (
        <StyledContainer>
            <IconWrapper>
                <StyledInclineIcon onClick={() => changeType("incline")} width={30} height={30} fill={upDownType === "incline" ? "red" : ""}/>
                <StyledDeclineIcon onClick={() => changeType("decline")} width={30} height={30} fill={upDownType === "decline" ? "blue" : ""}/>
            </IconWrapper>
            <TopLineWrapper />
            <SalesUpDownChartWrapper />
        </StyledContainer>
    )
}

function TopLineWrapper() {
    const dispatch = useAppDispatch();
    const platformList = useAppSelector((state: RootState) => state.platform.platformList);
    const { salesUpDownPlatform } = useAppSelector((state: RootState) => state.pubDashboard);
    const { upDownType } = useAppSelector((state: RootState) => state.pubDashboard);
    
    const options = platformList.map((p: Platform) => {
        return {
            name: p.platform_name,
            value: p.platform_id
        }
    });
    options.unshift({ name: "전체", value: 0 });

    const selectPlatform = (value: any) => {
        dispatch({ type: "pubDashboard/setUpDownPlatform", payload: value.value });
        dispatch(getSalesUpDown(value.value));
    }

    const textStyle = {
        fontSize: "16px",
        fontWeight: "700"
    }

    return (
        <StyledTopLineWrapper>
            <FlexBox>
                <Text text={`판매 급${upDownType === "incline" ? "상승" : "하락"}`} customStyle={textStyle}/>
                <Tooltip 
                    iconWidth={24} 
                    iconHeight={24} 
                    fill={theme.colors.gray600} 
                    text="판매금액이 직전 일주일 평균보다 급상승한 작품들의 순위입니다." 
                />
            </FlexBox>
            <SelectBox
                labelKey="name"
                valueKey="value"
                options={options}
                selected={salesUpDownPlatform}
                onClickValueFunc={selectPlatform}
            />
        </StyledTopLineWrapper>
    )
} 

const StyledTopLineWrapper = styled.div`
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
`

const StyledContainer = styled.div`
    width: 360px;
    height: 350px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 110px;
`

const IconWrapper = styled.div`
    position: absolute;
`

const StyledInclineIcon = styled(Incline)`
    position: relative;
    top: 10px;
    left: -30px;
    cursor: pointer;
`

const StyledDeclineIcon = styled(Decline)`
    position: relative;
    top: 40px;
    left: -60px;
    cursor: pointer;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`