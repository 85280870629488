import BaseModal from "@/components/molecules/common/modal/BaseModal";
import SetSettlementRatioModalBody from "@/components/molecules/common/modal/bodyModal/SetSettlementRatioModalBody";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import { seriesActions } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";
import { updateRow } from "@/pages/common/dataTableSlice";

export default function SetSettlementRatioModal({ from, show, close }:SetSettlementRatioProps) {
    let [ratios, index, allRatios, allCheck, seriesAddDatas, data] = useAppSelector((state:RootState) => [
        state.series.ratios,
        state.series.index,
        state.series.allRatios,
        state.series.allCheck,
        state.series.seriesAddDatas,
        state.series.data,
    ]);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    async function setSettlementRatio() {
        // 저장 전 확인
        let standardRatio = null;
        let allSame = true;
        let tmpRatios = JSON.parse(JSON.stringify(ratios));
        if (allCheck) {
            if (allRatios.ratioError) return alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
        
            let answer = await alert("confirm", "전체 선택 확인", "모든 플랫폼을 전체 설정하신 정산비율로 변경하시겠습니까?");
            if (answer === "Y") {
                standardRatio = allRatios.ratio;
                for (let i = 0; i < tmpRatios.length; i++) {
                    tmpRatios[i].period = allRatios.period;
                    tmpRatios[i].ratio = allRatios.ratio;
                }

                if (allRatios.period && allRatios.period.length > 1) {
                    allSame = false;
                }
            }
        } else {
            let errCheck = false;
            for (let i = 0; i < tmpRatios.length; i++) {
                if (!tmpRatios[i].period || !tmpRatios[i].period.length) {
                    tmpRatios[i].period = [
                        {
                            startYearmon: "000000",
                            endYearmon: "999999",
                            ratio: tmpRatios[i].ratio,
                        },
                    ];
                }
    
                if (tmpRatios[i].ratioError) {
                    errCheck = true;
                }
            }
    
            if (errCheck) return alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
            // 플랫폼별 정산비율 다른지 체크
            for (let r of tmpRatios) {
                if (r.ratio) {
                    if (standardRatio === null) {
                        standardRatio = r.ratio;
                    } else {
                        if (r.ratio !== standardRatio) {
                            allSame = false;
                            break;
                        }
                    }
                } else {
                    if (r.period && r.period.length > 1) {
                        allSame = false;
                        break;
                    }
                }
            }
        }

        if (typeof index === "number") {
            if (from === "series-update") {
                let updatedRow:any;
                if (allSame) {
                    updatedRow = {...data[index], "ratios": tmpRatios, "hasDetailOption": false, "ratioAll": standardRatio};
                    dispatch(seriesActions.setSeriesDatas({
                        key: "data", index: index,
                        value: updatedRow
                    }));
                } else {
                    updatedRow = {...data[index], "ratios": tmpRatios, "hasDetailOption": true, "ratioAll": "플랫폼 별 설정"};
                    dispatch(seriesActions.setSeriesDatas({
                        key: "data", index: index,
                        value: updatedRow
                    }));
                }
                dispatch(updateRow({ itemKey: "series_id", updatedRow: updatedRow }));
            } else if (from === "series-enroll") {
                if (allSame) {
                    dispatch(seriesActions.setSeriesDatas({
                        key: "seriesAddDatas", index: index,
                        value: { ...seriesAddDatas[index], "ratios": tmpRatios, "hasDetailOption": false, "ratioAll": standardRatio }
                    }));
                } else {
                    dispatch(seriesActions.setSeriesDatas({
                        key: "seriesAddDatas", index: index,
                        value: { ...seriesAddDatas[index], "ratios": tmpRatios, "hasDetailOption": true, "ratioAll": "플랫폼 별 설정" }
                    }));
                }
            }
        }
        close();
    }

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["비율 상세설정"]}
                customStyle={[{ width: "480px", height: "436px" }]}
                applyFunc={setSettlementRatio}
            >
                <SetSettlementRatioModalBody
                    from={from}
                />
            </BaseModal>
        </>
    );
}

interface SetSettlementRatioProps extends BaseModalProps {
}