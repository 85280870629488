import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";
import moment from "moment";
import { DashboardState } from "./types";

// Define the initial state using that type
const initialState: DashboardState = {
    // 매출 요약
    salesSummary: {
        yesterdaySales: {
            amount: "0원",
            ratio: 0
        },
        weekSales: "0원",
        currMonthSales: "0원",
        lastMonthSales: "0원"
    },
    salesSummaryLoading: false,
    
    // 판매금액 분석
    analysisType: "daily",
    dailySalesAnalysis: [],
    monthlySalesAnalysis: [],
    salesAnalysIsLoading: false,

    // 캘린더 일정
    calendarLoading: false,
    calendarEvent: [],
    selectedDateEvent: [],

    // 판매 급상승 / 급하락
    salesUp: [],
    salesDown: [],
    salesUpDownPlatform: 0,
    salesUpDownChartData: [], 
    salesUpDownChartSeriesName: "",
    salesUpDownIsLoading: false,
    upDownType: "incline",

    // 최근 런칭 작품
    launchingSeriesLoading: false,
    launchingSeries: [],
    launchingPeriod: 30,

    // 일일 작품 분석
    dailySeriesAnalysisLoading: false,
    dailySeriesAnalysisData: [],

    // 일일 플랫폼 분석
    dailyPlatformAnalysisLoading: false,
    dailyPlatformAnalysisData: [],

    // 플랫폼별 일별 판매순위 top3
    eachPlatformDailyRankLoading: false,
    eachPlatformDailyRankData: [],

    // 플랫폼별 정산금
    eachPlatformSettlementLoading: false,
    eachPlatformSettlementData: [],
};

// 전일, 7일누적, 당월 누적, 전월 정산금 총계
export const getSalesSummary = createAsyncThunk("PubDashboard/getSalesSummary", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/sales/summary`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 판매 금액 분석 (일별, 월별)
export const getSalesAnalysis = createAsyncThunk("PubDashboard/getSalesAnalysis", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/sales/analysis`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 캘린더 일정
export const getCalendarEvent = createAsyncThunk("PubDashboard/getCalendarEvent", async (yearmon: string, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/calendar/event?yearmon=${yearmon}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 판매 급상승/하락 (전체)
export const getSalesUpDown = createAsyncThunk("PubDashboard/getSalesUpDown", async (platform_id: number, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/sales/upDown?platformId=${platform_id}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 최근 런칭 작품
export const getLatestLaunchingSeries = createAsyncThunk("PubDashboard/getLatestLaunchingSeries", async (period: number, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/latest/launching/series?period=${period}`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 일일 작품 분석
export const getDailySeriesAnalysis = createAsyncThunk("PubDashboard/getDailySeriesAnalysis", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/daily/series/analysis`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 일일 플랫폼 분석
export const getDailyPlatformAnalysis = createAsyncThunk("PubDashboard/getDailyPlatformAnalysis", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/daily/platform/analysis`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 플랫폼별 일별 판매순위 top3
export const getEachPlatformDailyRank = createAsyncThunk("PubDashboard/getEachPlatformDailyRank", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/each/platform/daily/rank`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 플랫폼별 정산금
export const getEachPlatformSettlement = createAsyncThunk("PubDashboard/getEachPlatformSettlement", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/publisher/newDashboard/each/platform/settlement`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const dashboardSlice = createSlice({
    name: "pubDashboard",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAnalysisType: (state, action) => {
            state.analysisType = action.payload;
        },
        setUpDownType: (state, action) => {
            state.upDownType = action.payload;
            if (state.upDownType === "incline") {
                state.salesUpDownChartData = state.salesUp.length ? state.salesUp[0].history : [];
            } else {
                state.salesUpDownChartData = state.salesDown.length ? state.salesDown[0].history : [];
            }
        },
        setUpDownChart: (state, action) => {
            if (state.upDownType === "incline") {
                for (let u of state.salesUp) {
                    if (u.series_name === action.payload) {
                        state.salesUpDownChartData = u.history;
                        state.salesUpDownChartSeriesName = action.payload;
                    }
                }
            } else {
                for (let d of state.salesDown) {
                    if (d.series_name === action.payload) {
                        state.salesUpDownChartData = d.history;
                        state.salesUpDownChartSeriesName = action.payload;
                    }
                }
            }
        },
        setUpDownPlatform: (state, action) => {
            state.salesUpDownPlatform = action.payload;
        },
        setLaunchingPeriod: (state, action) => {
            state.launchingPeriod = action.payload;
        },
        setSelectedDateEvent: (state, action) => {
            state.selectedDateEvent = [];
            for (let c of state.calendarEvent) {
                if (c.date === action.payload) {
                    state.selectedDateEvent.push(c);
                }
            }
        },
    },
    extraReducers: {
        // 전일, 7일누적, 당월 누적, 전월 정산금 총계
        [getSalesSummary.pending.type]: (state, action) => {
            state.salesSummaryLoading = true;
        },
        [getSalesSummary.fulfilled.type]: (state, action) => {
            state.salesSummary = action.payload.data;
            state.salesSummaryLoading = false;
        },
        [getSalesSummary.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.salesSummaryLoading = false;
        },

        // 판매 금액 분석 (일별, 월별)
        [getSalesAnalysis.pending.type]: (state, action) => {
            state.salesAnalysIsLoading = true;
        },
        [getSalesAnalysis.fulfilled.type]: (state, action) => {
            state.dailySalesAnalysis = action.payload.data.dailySales;
            state.monthlySalesAnalysis = action.payload.data.monthlySales;
            state.salesAnalysIsLoading = false;
        },
        [getSalesAnalysis.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.salesAnalysIsLoading = false;
        },

        // 캘린더 이벤트
        [getCalendarEvent.pending.type]: (state, action) => {
            state.calendarLoading = true;
        },
        [getCalendarEvent.fulfilled.type]: (state, action) => {
            state.calendarEvent = action.payload.data;
            const today = moment().format("YYYYMMDD");
            state.selectedDateEvent = [];
            for (let d of action.payload.data) {
                if (d.date === today) {
                    state.selectedDateEvent.push(d);
                }
            }
            state.calendarLoading = false;
        },
        [getCalendarEvent.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.calendarLoading = false;
        },

        // 판매 급 상승/하락
        [getSalesUpDown.pending.type]: (state, action) => {
            state.salesUpDownIsLoading = true;
        },
        [getSalesUpDown.fulfilled.type]: (state, action) => {
            state.salesUp = action.payload.data.salesUp;
            state.salesDown = action.payload.data.salesDown;
            if (state.upDownType === "incline") {
                state.salesUpDownChartData = state.salesUp.length ? state.salesUp[0].history : [];
                state.salesUpDownChartSeriesName = state.salesUp.length ? state.salesUp[0].series_name : "";
            } else {
                state.salesUpDownChartData = state.salesDown.length ? state.salesDown[0]?.history : [];
                state.salesUpDownChartSeriesName = state.salesDown.length ? state.salesDown[0]?.series_name : "";
            }
            state.salesUpDownIsLoading = false;
        },
        [getSalesUpDown.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.salesUpDownIsLoading = false;
        },

        // 최근 런칭 작품
        [getLatestLaunchingSeries.pending.type]: (state, action) => {
            state.launchingSeriesLoading = true;
        },
        [getLatestLaunchingSeries.fulfilled.type]: (state, action) => {
            state.launchingSeries = action.payload.data;
            state.launchingSeriesLoading = false;
        },
        [getLatestLaunchingSeries.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.launchingSeriesLoading = false;
        },

        // 일일 작품 분석
        [getDailySeriesAnalysis.pending.type]: (state, action) => {
            state.dailySeriesAnalysisLoading = true;
        },
        [getDailySeriesAnalysis.fulfilled.type]: (state, action) => {
            state.dailySeriesAnalysisData = action.payload.data;
            state.dailySeriesAnalysisLoading = false;
        },
        [getDailySeriesAnalysis.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.dailySeriesAnalysisLoading = false;
        },

        // 일일 플랫폼 분석
        [getDailyPlatformAnalysis.pending.type]: (state, action) => {
            state.dailyPlatformAnalysisLoading = true;
        },
        [getDailyPlatformAnalysis.fulfilled.type]: (state, action) => {
            state.dailyPlatformAnalysisData = action.payload.data;
            state.dailyPlatformAnalysisLoading = false;
        },
        [getDailyPlatformAnalysis.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.dailyPlatformAnalysisLoading = false;
        },

        // 플랫폼별 일별 판매순위 top3
        [getEachPlatformDailyRank.pending.type]: (state, action) => {
            state.eachPlatformDailyRankLoading = true;
        },
        [getEachPlatformDailyRank.fulfilled.type]: (state, action) => {
            state.eachPlatformDailyRankData = action.payload.data;
            state.eachPlatformDailyRankLoading = false;
        },
        [getEachPlatformDailyRank.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.eachPlatformDailyRankLoading = false;
        },

        // 플랫폼별 정산금
        [getEachPlatformSettlement.pending.type]: (state, action) => {
            state.eachPlatformSettlementLoading = true;
        },
        [getEachPlatformSettlement.fulfilled.type]: (state, action) => {
            state.eachPlatformSettlementData = action.payload.data;
            state.eachPlatformSettlementLoading = false;
        },
        [getEachPlatformSettlement.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
            state.eachPlatformSettlementLoading = false;
        },
    },
});

export const { setAnalysisType, setUpDownType, setUpDownPlatform } = dashboardSlice.actions;

export default dashboardSlice.reducer;
