import { useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import ReactHtmlParser from 'html-react-parser'
import Text from '@/components/atoms/text';
import { colors } from '@/assets/styles/theme'
import Button from '@/components/atoms/button';
import useAlert from '@/modules/hooks/useAlert'
import { InquiryCommentType, AddCommentParams } from '@/pages/support/inquiry/slice';
import { ReactComponent as Delete} from "@/assets/icons/delete.svg";

interface Iprops {
    userId: number,
    comments: Array<InquiryCommentType>,
    auth: string | null,
    inquiryId: number,
    isMine?: boolean,
    saveComment: (apiParams: AddCommentParams)=> void,
    deleteComment: (apiParams : {comment_id: number}) => void
    reloadDetail: () => void,
    currentLoginedId?: number
}

interface CardProps {
    inquiry_id: number,
    comment: InquiryCommentType,
    user_id: number,
    reloadDetail: () => void,
    deleteComment: (apiParams : {comment_id: number}) => void,
    currentLoginedId?: number
}

const isOverTen = (value: number) => {
    if (value < 10) {
        return `0${value}`
    } else return value;
}

const authCheck = (auth: string | null) => {
    switch (auth) {
        case 'a':
            return '답변을 입력해주세요';
        case 'q':
            return '추가 질문을 입력해주세요.'
        default:
            return '추가 질문은 질문자만 가능합니다.' 
    }
}

function CommentCard({ user_id, comment, deleteComment, currentLoginedId}: CardProps) {
    const commentStyle = {
        fontSize: "16px",
        fontWeight: "400",
        color: colors.gray600
    }
    const { alert } = useAlert();
    const date = new Date(comment.created_at);
    const filteredDate = `${date.getFullYear()}-${isOverTen(date.getMonth()+1)}-${isOverTen(date.getDate())}`;
    
    const name = user_id === comment.user_id ? "질문자" : "답변자";
    const visibleDelete = currentLoginedId === comment.user_id ? true : false;

    const deleteCommentHandler = async () => {
        let answer = await alert("confirm", "삭제 확인", "댓글을 삭제 하시겠습니까?");
        if (answer === "Y") {
            let apiParams = {
                comment_id: comment.id
            }
            deleteComment(apiParams);
        }
    }
    
    return (
        <>
            <UserInfoContainer>
                <UserName>
                    {name}
                </UserName>
                <Text text={filteredDate} customStyle={commentStyle}/>
            </UserInfoContainer>
            <StyledContent>
                <pre> {ReactHtmlParser(comment.comment_content)}</pre>
                {visibleDelete && <DeleteComment>
                    <DeleteLabel onClick={deleteCommentHandler}>
                        삭제
                    </DeleteLabel>
                    <IconWrapper>
                        <Delete/>
                    </IconWrapper>
                </DeleteComment>}
            </StyledContent>
        </>
    )
}


export default function InquiryComment({ userId, comments, auth, inquiryId, isMine, saveComment, deleteComment, reloadDetail, currentLoginedId}: Iprops) {

    const placeholderText = authCheck(auth);
    const disabled = auth === 'a' || auth === 'q' ? false : true;
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const navigate = useNavigate();
    const { alert } = useAlert();

    const goBack = () => {
        navigate('/support/Inquiry');
    }

    const saveCommentHandler = async () => {
        if (!textareaRef.current?.value) {
            return await alert("info", "주의", "댓글을 입력해주세요");
        }
        
        let answer = await alert(
            "confirm",
            "저장 확인",
            `${auth === 'a' ? '답변' : '추가 질문'}을 저장 하시겠습니까?`
        );
        if (answer === "Y") {
            let apiParams = {
                comment_content: textareaRef.current?.value,
                inquiry_id: inquiryId,
                is_mine: isMine
            };
            if (textareaRef.current) {
                textareaRef.current.value = '';
                saveComment(apiParams);
            }

        }
    }

    return (
        <>
            {comments.map((comment)=> 
                <CommentCard 
                    key={comment.id}
                    user_id={userId}
                    comment={comment}
                    inquiry_id={inquiryId}
                    reloadDetail={reloadDetail}
                    deleteComment={deleteComment}
                    currentLoginedId={currentLoginedId}
                />)}
            
            <AddCommentContainer>
                <CommentTextArea 
                    ref={textareaRef}
                    placeholder={placeholderText} 
                    disabled={disabled} 
                />
            </AddCommentContainer>
            <ButtonsContainer>
                <Button label="뒤로가기" type="red-outline" onClickHandler={goBack}/>
                <Button label="저장" onClickHandler={saveCommentHandler}/>
            </ButtonsContainer>
        </>
    )
}

const UserInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UserName = styled.div`
    width: 71px;
    height: 44px;
    border-radius: 99px;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.blue50};
    color: ${({ theme }) => theme.colors.blue500};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 24px 0px;
    color: ${({ theme }) => theme.colors.gray600};
    min-height: 100px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
    line-height: 28px;
`;

const DeleteComment = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 8px;
`;

const DeleteLabel = styled.div`
    cursor: pointer;
`;

const IconWrapper = styled.div`
    cursor: pointer;
`;

const AddCommentContainer = styled.div`
    width: 100%;
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    background-color: ${({ theme }) => theme.colors.gray50};
    height: 120px;
`;

const CommentTextArea = styled.textarea`
    width: 100%;
    padding: 16px;
    height: 100px;
    border: none;
    background-color: ${({ theme }) => theme.colors.gray50};
    resize: none;
    &:focus {
        outline: none;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`;
