import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { CommonType } from "@/types/dataType";
import { setPlatformId as setDailyPlatformId } from "@/pages/settlement/sales-entry/daily/dailySlice";
import { setPlatformId as setMonthlyPlatformId } from "@/pages/settlement/sales-entry/monthly/monthlySlice";
import moment from "moment";
import useAlert from "@/modules/hooks/useAlert";

const usePlatformSetting = ({ uploadType, platformId, initPlatformId, date }: SettingProps) => {
    const platformList = useSelectorTyped((state: RootState) => state.platform.platformList);
    const [platformForm, setPlatformForm] = useState(0);
    const [name, setName] = useState("");
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    // 판매량 제공 유무
    const [platformQty, setPlatformQty] = useState(true);

    // 기타지급금 "해당없음" 추가된 리스트
    const [selectBoxOptions, setSelectBoxOptions] = useState<any[]>([]);

    // 유통중지인 플랫폼 제외 리스트
    const [entrySalesPlatformList, setEntrySalesPlatformList] = useState<any[]>([]);

    useEffect(() => {
        if (platformList.length) {
            setSelectBoxOptions([{ platform_id: null, platform_name: "해당없음" }, ...platformList]);

            let filterPlatformList:any[] = [];
            if (date) {
                filterPlatformList = platformList.filter(p => p.circulation_stop_date === null || String(moment(date).format("YYYYMM")) < String(moment(p.circulation_stop_date).format("YYYYMM")));
                setEntrySalesPlatformList(filterPlatformList);
                if (filterPlatformList.findIndex((p) => p.platform_id === platformId) < 0) {
                    dispatch(setDailyPlatformId(filterPlatformList[0].platform_id));
                    dispatch(setMonthlyPlatformId(filterPlatformList[0].platform_id));
                    if (platformId) {
                        alert("info", "플랫폼 알림", "유통중지가 된 플랫폼입니다.");
                    }
                }
            }
        }
    }, [date, platformList]);

    useEffect(() => {
        if (!entrySalesPlatformList.length) return;
        if (!platformId && initPlatformId) initPlatformId(entrySalesPlatformList[0].platform_id);
        // 플랫폼 이름
        const platform = entrySalesPlatformList.filter((p) => p.platform_id === platformId)[0];
        const platformName = platform ? platform.platform_name : "";
        setName(platformName);

        // 업로드 양식 선택
        const selected = entrySalesPlatformList.find((p) => p.platform_id === platformId);
        if (uploadType === "daily") setPlatformForm(selected?.daily || 0);
        if (uploadType === "monthly") setPlatformForm(selected?.monthly || 0);
    }, [date, entrySalesPlatformList]);

    useEffect(() => {
        if (!platformList.length) return;
        if (!platformId && initPlatformId) initPlatformId(platformList[0].platform_id);

        // 플랫폼 이름
        const platform = platformList.filter((p) => p.platform_id === platformId)[0];
        const platformName = platform ? platform.platform_name : "";
        setName(platformName);
        setPlatformQty(platform ? Boolean(platform.quantity) : true);

        // 업로드 양식 선택
        const selected = platformList.find((p) => p.platform_id === platformId);
        if (uploadType === "daily") setPlatformForm(selected?.daily || 0);
        if (uploadType === "monthly") setPlatformForm(selected?.monthly || 0); 
    }, [platformId, platformList]);

    return {
        platformList,
        selectBoxOptions,
        initialPlatformId: platformList[0] && platformList[0].platform_id,
        platformForm,
        platformName: name,
        entrySalesPlatformList,
        platformQty,
    };
};

interface SettingProps {
    platformId: CommonType.Id;
    uploadType?: "daily" | "monthly";
    initPlatformId?: (id: CommonType.Id) => void;
    date?: string;
}

export default usePlatformSetting;
