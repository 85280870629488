import styled from "styled-components";
import DashboardTable from "@/components/molecules/dashboard/publisher/DashboardTable";
import { RootState, useAppSelector } from "@/modules/store";
import { colors } from '@/assets/styles/theme';
import { Oval } from "react-loader-spinner";
import Text from "@/components/atoms/text";

export default function LaunchingSeriesTableContainer() {
    const { launchingSeries, launchingSeriesLoading } = useAppSelector((state: RootState) => state.pubDashboard);

    if (launchingSeriesLoading) {
        return (
            <StyledContainer>
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
            </StyledContainer>
        )
    }

    const noDataTextStyle = {
        fontSize: "16px",
        textAlign: "center",
        margin: "160px 0 0 0",
        color: colors.gray500
    }

    if (!launchingSeries.length) {
        return (
            <StyledContainer>
                <Text text="최근 런칭작품이 존재하지 않습니다." customStyle={noDataTextStyle} />
            </StyledContainer>
        )
    }

    const headers = [
        { text: "순위", value: "rank", width: "70px", align: "center" },
        { text: "런칭일자", value: "publish_at", width: "80px", align: "center" },
        { text: "작품명", value: "series_name", width: "220px" },
        { text: "저자명", value: "author_name", width: "90px", align: "center" },
        { text: "최대매출", value: "platform_name", width: "100px", align: "center" },
        { text: "판매량", value: "quantity", width: "80px", align: "center" },
        { text: "판매금액(원)", value: "amount_label", width: "160px", align: "right" },
    ];

    return (
        <StyledContainer>
            <DashboardTable headers={headers} items={launchingSeries} />
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 808px;
    height: 348px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`
const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`
