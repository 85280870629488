import { useEffect, useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import EnrollAuthorBodyModal from "@/components/molecules/common/modal/bodyModal/EnrollAuthorBodyModal";
import EnrollAuthorExcelBody from "@/components/molecules/common/modal/bodyModal/EnrollAuthorExcelBody";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import { authorActions, enrollAuthor, excelUploadAuthor, getAuthorData } from "@/pages/settlement/basedata-manage/author/authorSlice";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import ExcelUploadAuthorModal from "@/components/molecules/common/modal/ExcelUploadAuthorModal";
import useLoading from "@/modules/hooks/useLoading";

export default function AuthorEnrollModal({ show, close, from }:AuthorEnrollProps) {
    const { loadingOn, loadingOff } = useLoading();
    let [authorAddDatas, file] = useAppSelector((state:RootState) => [
        state.author.authorAddDatas,
        state.file.file
    ]);
    const [showExcelUploadAuthor, setShowExcelUploadAuthor] = useState(false);
    function closeModal() {
        setShowExcelUploadAuthor(false);
    }
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    useEffect(() => {
    }, []);

    async function authorEnroll() {
        try {
            let hasError = false;
            if (authorAddDatas.length === 0) return await alert("warning", "주의", "등록할 저자명이 없습니다.");
            for (let i of authorAddDatas) {
                let index = authorAddDatas.indexOf(i);
                if (!i.name) {
                    dispatch(authorActions.setEnrollDatas({ index: index, key: "authorError", value: "저자명을 입력해주세요."}));
                    hasError = true;
                }
                if (!i.realName && i.jointArray.length === 1) {
                    dispatch(authorActions.setEnrollDatas({ index: index, key: "realNameError", value: "본명을 입력해주세요."}));
                    hasError = true;
                }
                if (i.authorError || i.realNameError || i.emailError || i.stEmailError || i.taxTypeError || i.taxRatioError || i.jointError) {
                    hasError = true;
                }
           }
           if (hasError) {
                return alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
            } else {
                loadingOn();
                await dispatch(enrollAuthor(authorAddDatas)).unwrap();
                await dispatch(getAuthorData({})).unwrap();
                await alert("success", "저장 완료", "저장이 완료되었습니다.");
                close();
                // await dispatch(modalAction.popModal());
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }

    async function excelUpload() {
        try {
            if (!file.file || !file.fileName) return await alert("warning", "주의", "파일을 업로드해주세요.");
            let answer = await alert("confirm", "업로드 확인", "업로드를 진행하시겠습니까?");
            if (answer === "Y") {
                loadingOn();
                let apiParams = {
                    file: file.file,
                    fileName: file.fileName
                }
                await dispatch(excelUploadAuthor(apiParams)).unwrap();
                setShowExcelUploadAuthor(true);
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }
    
    function reset() {
        dispatch(authorActions.resetEnrollAuthor());
    }

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["직접 입력", "엑셀 업로드"]}
                customStyle={[{ width: "480px", height: "480px" },
                            { width: "480px", height: "272px" }]}
                applyFunc={authorEnroll}
                applyFuncTab={excelUpload}
                reset={reset}
            >
                <EnrollAuthorBodyModal
                    from={from}
                />
                <EnrollAuthorExcelBody />
            </BaseModal>
            <ModalPortal>
                <ExcelUploadAuthorModal
                    from={from}
                    show={showExcelUploadAuthor}
                    close={closeModal}
                    authorEnrollFunc={authorEnroll}
                />
            </ModalPortal>
        </>
    );
}

interface AuthorEnrollProps extends BaseModalProps {

}