import { useState, useEffect } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import BatchModalBody from "./bodyModal/BatchModalBody";
import { BaseModalProps } from "@/pages/common/types";
import { CommonType } from "@/types/dataType";

export default function BatchModal({ show, close, from, platformId, platformName, stYearmon, onApplyBatch }: BatchProps) {
    const [selectedSeries, setSelectedSeries] = useState("");
    const initialSeriesInfo = {
        bookNameCheck: false,
        seriesCheck: false,
        bookName: "",
        series: {
            id: 0,
            name: "",
        },
        authorName: "",
        ratio: 0,
    };
    const [seriesInfo, setSeriesInfo] = useState<SeriesInfo>(initialSeriesInfo);

    useEffect(() => {
        return () => {
            setSeriesInfo(initialSeriesInfo);
            setSelectedSeries("");
        }
    }, [show])
    
    const apply = () => {
        onApplyBatch(seriesInfo);
        close();
    };

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["일괄적용"]}
                customStyle={[{ width: "360px", height: "268px" }]}
                applyFunc={apply}
            >
                <BatchModalBody
                    from={from}
                    platformId={platformId}
                    platformName={platformName}
                    stYearmon={stYearmon || ""}
                    selectedSeries={selectedSeries}
                    setSelectedSeries={setSelectedSeries}
                    seriesInfo={seriesInfo}
                    setSeriesInfo={setSeriesInfo}
                />
            </BaseModal>
        </>
    );
}

interface BatchProps extends BaseModalProps {
    platformId: CommonType.Id;
    platformName?: CommonType.Platform.Name;
    stYearmon?: CommonType.Date;
    onApplyBatch: (data: SeriesInfo) => void;
}
export interface SeriesInfo {
    bookNameCheck: boolean;
    seriesCheck: boolean;
    bookName: CommonType.Series.BookTitle;
    series: Series;
    authorName: CommonType.Author.Name;
    ratio: CommonType.Ratio;
}
interface Series {
    name: CommonType.Series.Name;
    id: CommonType.Id;
}
export interface Selected {
    bookName: boolean;
    seriesName: boolean;
}
