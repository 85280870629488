import BaseModal from "@/components/molecules/common/modal/BaseModal";
import ExcelUploadAuthorModalBody from "@/components/molecules/common/modal/bodyModal/ExcelUploadAuthorModalBody";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import { authorActions, enrollAuthor, getAuthorData } from "@/pages/settlement/basedata-manage/author/authorSlice";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";

export default function ExcelUploadAuthorModal({ show, close, from, authorEnrollFunc }:ExcelUploadAuthorProps) {
    const dispatch = useAppDispatch();
    let [authorAddDatas] = useAppSelector((state:RootState) => [
        state.author.authorAddDatas,
    ]);
    const { alert } = useAlert();

    async function authorEnroll() {
        try {
            let hasError = false;
            if (authorAddDatas.length === 0) return await alert("warning", "주의", "등록할 저자명이 없습니다.");
            for (let i of authorAddDatas) {
                let index = authorAddDatas.indexOf(i);
                if (!i.name) {
                    dispatch(authorActions.setEnrollDatas({ index: index, key: "authorError", value: "저자명을 입력해주세요."}));
                    hasError = true;
                }
                if (!i.realName && i.jointArray.length === 1) {
                    dispatch(authorActions.setEnrollDatas({ index: index, key: "realNameError", value: "본명을 입력해주세요."}));
                    hasError = true;
                }
                if (i.authorError || i.realNameError || i.emailError || i.stEmailError || i.taxTypeError || i.taxRatioError || i.jointError) {
                    hasError = true;
                }
           }
           if (hasError) {
                return alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
            } else {
                await dispatch(enrollAuthor(authorAddDatas)).unwrap();
                await dispatch(getAuthorData({})).unwrap();
                await alert("success", "저장 완료", "저장이 완료되었습니다.");
                close();
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }
    
    function reset() {
        dispatch(authorActions.resetEnrollAuthor());
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["엑셀 업로드 조회"]}
            customStyle={[{ width: "960px", height: "476px" }]}
            applyFunc={authorEnroll}
            reset={reset}
        >
           <ExcelUploadAuthorModalBody
                from={from}
           /> 
        </BaseModal>
    );
}

interface ExcelUploadAuthorProps extends BaseModalProps {
    authorEnrollFunc: Function;
}