import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import { batchDelete, setBatchDelete } from "@/pages/settlement/saels-inquiry/publisher/daily/dailyDeleteSlice";
import { getDailyData } from "@/pages/settlement/saels-inquiry/publisher/daily/dailySlice";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import BatchDeleteDailyModalBody from "./bodyModal/BatchDeleteDailyModalBody";
import useAlert from "@/modules/hooks/useAlert";
import { getToday } from "@/modules/utils/date";
import { BaseModalProps } from "@/pages/common/types";

export default function BatchDeleteDailyModal({ show, close }: BaseModalProps) {
    const { startDate, endDate, platformId, seriesId, authorId, validateError } = useAppSelector(
        (state: RootState) => state.inquiryDailyDelete,
    );
    const dispatch = useAppDispatch();
    const initialSelected = {
        date: false,
        platform: false,
        seriesName: false,
        authorName: false,
    };
    const [selected, setSelected] = useState(initialSelected);
    const { alert } = useAlert();

    useEffect(() => {
        const today = getToday();
        setSelected(initialSelected);
        dispatch(setBatchDelete({ startDate: today, endDate: today, platformId: 0, seriesId: 0, authorId: 0 }));
    }, [show]);

    const validateData = async () => {
        if (validateError.startDateError || validateError.endDateError) {
            return await alert("warning", "주의", "판매 일 조건을 확인해주세요.");
        }
        if (!selected.date && !selected.platform && !selected.seriesName && !selected.authorName) {
            return await alert("warning", "주의", "선택된 항목이 없습니다.");
        }
        if (selected.platform && !platformId) return await alert("warning", "주의", "플랫폼을 선택해주세요.");
        if (selected.seriesName && !seriesId) return await alert("warning", "주의", "시리즈명을 입력해주세요.");
        if (selected.authorName && !authorId) return await alert("warning", "주의", "저자명을 입력해주세요.");

        return await alert("confirm", "주의", `선택하신 조건에 해당하는 모든 데이터가 삭제됩니다.\n계속 하시겠습니까?`);
    };

    const apply = async () => {
        const answer = await validateData();
        if (answer !== "Y") return;

        try {
            const apiParams = {
                startDate: selected.date ? startDate : "",
                endDate: selected.date ? endDate : "",
                platformId: selected.platform ? platformId : 0,
                seriesId: selected.seriesName ? seriesId : 0,
                authorId: selected.authorName ? authorId : 0,
            };

            await dispatch(batchDelete(apiParams)).unwrap();
            await dispatch(getDailyData());
            await alert("info", "완료", "일괄 삭제가 완료되었습니다.");
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        }
        close();
    };

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["조건 삭제"]}
            customStyle={[{ width: "480px", height: "360px" }]}
            applyFunc={apply}
        >
            <BatchDeleteDailyModalBody selected={selected} setSelected={setSelected} />
        </BaseModal>
    );
}

export interface CheckboxState {
    date: boolean;
    platform: boolean;
    seriesName: boolean;
    authorName: boolean;
}
