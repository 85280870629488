import { useState } from "react";
import styled from "styled-components";
import moment from "moment";

// components
import Button from "@/components/atoms/button";
import SelectBox from "@/components/atoms/selectBox/index";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SeriesEnrollModal from "@/components/molecules/common/modal/SeriesEnrollModal";

// reducer
import { deleteSeries, getSeriesData, updateSeries } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import { useAppSelector, useAppDispatch, RootState } from "@/modules/store";

// hooks
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useLoading from "@/modules/hooks/useLoading";
import useExcelDownload from "@/modules/hooks/useExcelDownload";

export default function SeriesOptionButtons() {
    let [selected, data, updatedRows, platform_id_list] = useAppSelector((state:RootState) => [
        state.dataTable.selectedItems,
        state.series.data,
        state.dataTable.updatedRows,
        state.series.platform,
    ]);
    const [showSeriesEnroll, setShowSeriesEnroll] = useState(false);
    const { loadingOn, loadingOff } = useLoading();
    const dispatch = useAppDispatch();
    const { setItemsPerPage, itemsPerPage } = usePagination({ from: "Series" });
    const { alert } = useAlert();
    const downloadExcel = useExcelDownload();

    const deleteAuthor = async() => {
        try {
            if (selected.length === 0) {
                await alert("warning", "주의", "선택된 항목이 없습니다.");
            } else {
                let answer = await alert("confirm", "삭제 확인", 
                "해당 시리즈명으로 입력된 일별 판매현황, 월 정산, 선인세 데이터까지 모두 삭제됩니다. 삭제하시겠습니까?");
                if (answer === "Y") {
                    loadingOn();
                    let apiParams = {
                        selected: selected
                    }
                    await dispatch(deleteSeries(apiParams)).unwrap();
                    await dispatch(getSeriesData({})).unwrap();
                    await alert("success", "삭제 완료", "삭제가 완료되었습니다.");
                }
            }
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    };

    function selectItemPerPage(value:any) {
        setItemsPerPage(Number(value.value))
    }

    const excelDownload = (excelFileName:string) => {
        let rows:any[] = [];      
        let row:any = {};
        let items = [];
            
        if ( selected.length > 0 ) {
            items = selected;
        } else {
            items = data;
        }
        items.forEach( data => {
            row  = {            
                    "시리즈명" : data.series_name,
                    "저자명" : data.author.author_name,                       
            };               
            data.ratios.forEach((l:any) => {
                let platform_name = l.platform_name;                  
                row[platform_name] = l.ratio;
            });
            rows.push(row);
        });

        downloadExcel({ json: rows, excelFileName: excelFileName });
    }

    async function updateValidate() {
        if (!updatedRows.length) return await alert("warning", "주의", "변경된 내용이 없습니다.");

        for (let i of updatedRows) {
            if (i.seriesError || i.authorError || i.ratioError ) {
                return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
            }
        }
        update();
    }

    async function update() {
        try {
            let answer = await alert("confirm", "변경 확인", "변경하시겠습니까?");
    
            if (answer === "Y") {
                loadingOn();
                let apiParams = {
                    updatedRows: updatedRows,
                    platformList: platform_id_list
                }
                await dispatch(updateSeries(apiParams)).unwrap();
                await dispatch(getSeriesData({})).unwrap();
                await alert("success", "변경 완료", "변경이 완료되었습니다.");
            }
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }

    function closeModal() {
        setShowSeriesEnroll(false);
    }

    function enrollSeries() {
        setShowSeriesEnroll(true);
    }

    return (
        <>
            <OptionButtonsContainer>
                <Button
                    type="red-outline"
                    label="선택 삭제"
                    onClickHandler={deleteAuthor}
                />
                <RightOptionsWrap>
                    <SelectBox
                        labelKey="name"
                        valueKey="value"
                        selected={itemsPerPage}
                        options={options}
                        onClickValueFunc={selectItemPerPage}
                    />
                    <Button
                        type="main-outline"
                        label="시리즈명 등록"
                        customStyle={btnStyle}
                        onClickHandler={enrollSeries}
                    />
                    <Button
                        type="main-outline"
                        label="엑셀 다운로드"
                        customStyle={btnStyle}
                        onClickHandler={(e) => excelDownload(`(기초데이터 관리) 시리즈명 관리 - ${moment().format('YYYY년 MM월 DD일')}.xlsx`)}
                    />
                    <Button
                        type="main"
                        label="저장"
                        customStyle={btnStyle}
                        onClickHandler={(e) => updateValidate()}/>
                </RightOptionsWrap>
            </OptionButtonsContainer>
            <ModalPortal>
                <SeriesEnrollModal
                    show={showSeriesEnroll}
                    from="series-enroll"
                    close={closeModal}
                />
            </ModalPortal>
        </>
    );
}

const options = [{ name: "10개씩 보기", value: "10" },
                     { name: "30개씩 보기", value: "30" },
                     { name: "50개씩 보기", value: "50" },
                     { name: "100개씩 보기", value: "100" }];

const btnStyle = {
    margin: "0 0 0 8px"
    }

const OptionButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
`;

const RightOptionsWrap = styled.div`
    display: flex;
`;