import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as RefreshIcon } from "@/assets/icons/refresh.svg";

export default function InitFilter({ onClick, text, width }: InitFilterProps) {
    return (
        <StyledWrapper className="init-filter" width={width} onClick={onClick}>
            <StyledText>{text || "필터 초기화"}</StyledText>
            <RefreshIcon fill={colors.gray600} />
        </StyledWrapper>
    );
}

const StyledWrapper = styled.div<{ width?: string }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: ${({ width }) => width || "120px"};
    height: 32px;
    padding: 9px 8px;
    cursor: pointer;
`;
const StyledText = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray600};
`;

interface InitFilterProps {
    onClick: () => void;
    text?: string;
    width?: string;
}
