// 출판사 이미지
import { pubDashboard01, pubDashboard02, pubDashboard03, pubDashboard04,
    pubSalesEntry01, pubSalesEntry02, pubSalesEntry03, pubSalesEntry04, pubSalesEntry05, pubSalesEntry06, pubSalesEntry07, pubSalesEntry08,
    pubSalesInquiry01, pubSalesInquiry02, pubSalesInquiry03, pubSalesInquiry04, pubSalesInquiry05, pubSalesInquiry06, 
    pubBasedata01, pubBasedata02, pubBasedata03, pubBasedata04, pubBasedata05, pubBasedata06, pubBasedata07, 
    pubTotalSales01, pubTotalSales02, pubTotalSales03, pubStatement01, pubStatement02, 
    pubPortfolio01, pubPortfolio02, pubPortfolio03, pubPortfolio04, pubPortfolio05, pubPortfolio06, pubPortfolio07, pubPortfolio08, 
    pubEbook01, pubEbook02, pubEbook03, pubEbook04, pubEbook05, pubEbook06, 
    pubMypage01, pubMypage02, pubMypage03, pubMypage04, pubMypage05, 
    pubSupport01, pubSupport02, pubSupport03, pubSupport04, pubSupport05, pubSupport06,
} from "@/assets/images/guide/publisher/index";
// 작가 이미지
import { Dashboard01, Dashboard02, Dashboard03, 
   SalesInquiry01, SalesInquiry02, SalesInquiry03, SalesInquiry04, Statement01, 
   Portfolio01, Portfolio02, Portfolio03, Portfolio04, 
   Mypage01, Mypage02, Mypage03, Support01, Support02, Support03, Support04, Support05,
} from "@/assets/images/guide/author/index";

// 출판사
export const pubDashboardItems = [
    {
        title: "",
        src: [pubDashboard01],
        desc: [
            ["① 어제 | ", "어제 팔린 매출액을 보여줍니다."],
            ["② 7일 누적 | ", "일주일 동안 팔린 금액의 총합입니다."],
            ["③ 당월 누적 | ", "이번 달의 매출액 누적치입니다."],
            ["④ 전월 정산금 총계 | ", "저번 달의 매출액에서 세금 및 기타 비용을 제한 순 정산금입니다."],
            [
                "⑤ 판매 금액 분석 | ",
                "매일 팔린 데이터를 한 눈에 볼 수 있습니다. 월별을 누를 시 최근 몇 달 간의 판매 데이터를 볼 수 있습니다.",
            ],
            ["⑥ 캘린더 | ", "포트폴리오 관리의 일정을 캘린더에서 확인하실 수 있습니다."],
        ],
    },
    {
        title: "",
        src: [pubDashboard02],
        desc: [
            ["① 판매 급상승 | ", "전일대비 판매량이 급격하게 상승한 작품을 볼 수 있습니다."],
            ["② 판매 급하락 | ", "전일대비 판매량이 급격하게 하락한 작품을 볼 수 있습니다."],
            ["③ 최근 런칭 작품 | ", "최근 7일 /14일 /21일 / 30일 안에 런칭한 작품의 판매 데이터입니다."],
        ],
    },
    {
        title: "",
        src: [pubDashboard03],
        desc: [
            ["① 일일 작품 분석 | ", "하루 동안 가장 많이 팔린 작품 매출을 비교할 수 있습니다."],
            ["② 일일 플랫폼 분석 | ", "하루 동안 가장 많이 팔린 플랫폼 매출을 비교할 수 있습니다."],
        ],
    },
    {
        title: "",
        src: [pubDashboard04],
        desc: [
            ["① 플랫폼별 일별 판매순위 top | ", "하루 동안 각 플랫폼에서 가장 많이 팔린 작품을 볼 수 있습니다."], 
            ["② 플랫폼별 정산금 | ", "플랫폼 매출액에서 출판사 정산금 / 선인세 차감 / 선인세 추가 / 기타 지급금을 정산한 금액을 볼 수 있습니다."]
        ],
    },
];
export const pubSalesEntryItems = [
    {
        title: "1. 일별 판매 현황",
        src: [pubSalesEntry01, pubSalesEntry02],
        srcWidth: ["80%", "80%"],
        desc: [
            ["① 검색 필터 | ", "판매 일과 판매 플랫폼을 선택하여 판매를 입력합니다."],
            ["② 일괄 적용 | ", "시리즈명 (예 : 홍길동)과 작품명 (예 : 홍길동 1화)을 일괄 적용하는 기능입니다."],
            ["③ 엑셀 업로드 | ", "글링 양식 엑셀 파일에 입력한 데이터를 한 번에 올릴 수 있습니다."],
            ["④ 시리즈 검색 | ", "판매한 작품을 검색으로 쉽게 찾을 수 있습니다."],
            ["⑤ 행추가 | ", "기본 으로 있는 10개의 행 외에 자유롭게 행을 추가할 수 있는 기능입니다."],
        ],
    },
    {
        title: "2. 월 정산",
        src: [pubSalesEntry03, pubSalesEntry04],
        srcWidth: ["80%", "260px"],
        desc: [
            ["① 검색 필터 | ", "판매 월(실제 판매월)과 정산 월(플랫폼 정산 월), 판매 플랫폼을 선택하여 판매를 입력합니다."],
            ["② 출판사정산금(원) | ", "판매한 금액에서 출판사가 정산받는 금액입니다."],
            ["③ 작가정산비율(%) | ", "판매시 작가가 정산 받는 금액의 비율입니다."],
            ["④ 시리즈 검색 | ", "월 정산에선 정산비율도 볼 수 있습니다."],
        ]
    },
    {
        title: "3. 선인세",
        src: [pubSalesEntry05, pubSalesEntry06],
        srcWidth: ["80%", "320px"],
        desc: [
            ["① 계약시작일 | ", "선인세 계약시작일을 말합니다."],
            ["② 선인세 구분 |  ", "플랫폼에서 지급한 선인세인지 출판사에서 지급한 선인세인지 구분할 수 있습니다."],
            ["③ 계약형태 | ", "계약시 작품을 계약했는지, 아니면 작가로 계약했는지를 말합니다."],
            ["④ 계약금액(원) | ", "출판사와 작가 사이의 계약금액입니다."],
            ["⑤ 정산비율(%) | ", "선인세를 차감할 때 계산되는 작가 입장에서의 비율입니다."],
            ["⑥ 초기 차감금액(원) | ", "시스템 도입 이후 월정산 데이터 입력 하시기 이전 까지의 차감 완료된 금액입니다."],
            ["⑦ 저자명 | ", "글링에 등록 된 저자를 검색할 수 있습니다."],
            ["⑧ 공저간 비율 설정 | ", "공동 저자 일시 공저간 정산 비율을 설정할 수 있습니다."],
        ]
    },
    {
        title: "4. 기타 지급금",
        src: [pubSalesEntry07, pubSalesEntry08],
        srcWidth: ["100%", "260px"],
        desc: [
            ["① 플랫폼 | ", "기타 지급금을 입금하는 플랫폼입니다."],
            ["② 입금 일자 | ", "기타 지급금을 입급받은 날짜입니다."],
            ["③ 지급 일자 | ", "작가에게 기타 지급금을 지급한 날짜입니다."],
            ["④ 더보기 | ", "기타 지급금 더 보기를 클릭하여 적용 세목과 지급 사유를 적을 수 있습니다."],
        ]
    },
];
export const pubSalesInquiryItems = [
    {
        title: "1. 일별 판매 현황",
        src: [pubSalesInquiry01],
        srcWidth: ["80%"],
        desc: [
            ["① 검색 필터 | ", `(1)판매 일 : 어제, 지난 7일, 이번 달, 지난 달 같은 데이터부터 직접 선택까지 전부 가능합니다. 
                                (2)판매 플랫폼 : 현재 판매중인 플랫폼을 선택할 수 있습니다.`],
            ["② 조건 삭제 | ", "현재 조회 중인 데이터에서 조건에 해당하는 데이터만 일괄로 삭제할 수 있는 기능입니다."]
        ],
    },
    {
        title: "",
        src: [pubSalesInquiry02],
        srcWidth: ["330px"],
        desc: [["", ""]],
    },
    {
        title: "2. 월 정산",
        src: [pubSalesInquiry03],
        srcWidth: ["80%"],
        desc: [
            ["① 검색 필터 | ", `(1)정산 월 : 정산 받은 월을 검색할 수 있습니다.
                                (2)판매 플랫폼 : 현재 판매중인 플랫폼을 선택할 수 있습니다.`],
        ]
    },
    {
        title: "3. 선인세",
        src: [pubSalesInquiry04],
        srcWidth: ["80%"],
        desc: [
            ["① 검색 필터 | ", `(1)정산 월 : 정산 받은 월을 선택할 수 있습니다.
                                (2)선인세 구분 : 플랫폼에서 지급한 선인세인지 출판사에서 지급한 선인세인지 구분할 수 있습니다.
                                (3)계약 시작일 : 선인세 계약 시작일을 말합니다.
                                (4)소진여부 : 선인세를 전부 소진했는지 아닌지를 말합니다.
                                (5)계약형태 : 계약시 작품을 계약했는지, 아니면 작가로 계약했는지를 말합니다.
                                (6)계약금액 : 계약금액의 최소(min), 최대(max) 값을 정해 검색할 수 있습니다.
                                (7)소진금액 : 소진금액의 최소(min), 최대(max) 값을 정해 검색할 수 있습니다.
                                (8)잔여금액 : 소진금액의 최소(min), 최대(max) 값을 정해 검색할 수 있습니다.`],
        ]
    },
    {
        title: "",
        src: [pubSalesInquiry05],
        srcWidth: ["80%"],
        desc: [
            ["② 선인세 입금/지급일 설정 | ", `플랫폼에서 출판사로 선인세가 입금 된 날짜와 출판사에서 작가로 선인세가 
                                            지급되는 날짜를 설정하고 입금액과 지급액을 적습니다.`],
        ]
    },
    {
        title: "4. 기타 지급금",
        src: [pubSalesInquiry06],
        srcWidth: ["80%"],
        desc: [
            ["① 검색 필터 | ", `(1)지급 일 : 기타지급금을 지급한 날짜를 말합니다.
            (2)지급 금액 : 지급 금액의 최소(min), 최대(max) 값을 정해 검색할 수 있습니다.`],
        ]
    },
];
export const pubBasedataItems = [
    {
        title: "1. 저자명 관리",
        src: [pubBasedata01],
        desc: [
            ["① 공저 설정 | ", "공동 저자가 있을 경우,’ 글링 아이디(이메일), 본명, 적용세율, 정산서 발급 이메일’을 각자 설정 가능합니다."],
            ["② 저자명 등록 | ", "저자명 등록시 공동저자의 경우 공저 추가를 통해 공저를 설정할 수 있으며, 엑셀을 통해 한 번에 업로드가 가능합니다."]
        ],
    },
    {
        title: "",
        src: [pubBasedata02],
        srcWidth: ["320px"],
    },
    {
        title: "2. 시리즈명 관리",
        src: [pubBasedata03],
        desc: [
            ["① 공저간 비율 설정 | ", "공동저자가 있는 시리즈의 경우 공동 저자간의 정산비율을 설정할 수 있습니다."],
            ["② 시리즈명 등록 | ", "시리즈명 등록시 저자명 검색을 통해 저자를 선택하여 등록할 수 있습니다."],
        ]
    },
    {
        title: "",
        src: [pubBasedata04],
        srcWidth: ["400px"],
    },
    {
        title: "3. 판매플랫폼 관리",
        src: [pubBasedata05],
        desc: [
            ["① 사용여부 | ", "판매중인 플랫폼을 사용할 것인지 여부를 체크할 수 있습니다."],
            ["② 인세지급월 | ", "출판사가 판매월로부터 작가에게 정산금을 지급하기까지의 기간을 말합니다."],
        ]
    },
    {
        title: "4. 시리즈/작품명 연동",
        src: [pubBasedata06],
        desc: [
            ["", "시리즈명에 종속 된 작품명이 각각 다를 경우 검색을 통해 연동할 수 있습니다."],
        ]
    },
    {
        title: "5. 데이터 대량 등록",
        src: [pubBasedata07],
        desc: [["", "대량의 데이터를 한 번에 등록할 수 있는 기능입니다."]]
    },
];
export const pubTotalSalesItems = [
    {
        title: "",
        src: [pubTotalSales01],
        desc: [
            ["① 기준월 | ", "입출금의 기준이 되는 달을 설정합니다"],
            ["② 월 입금 총계 | ", "기준월 동안 입금 된 금액의 총계를 말합니다."],
            ["③ 월 출금 총계 | ", "기준월 동안 출금 된 금액의 총계를 말합니다."],
            ["④ 월간 입출금 총합 | ", "월 입금 총계에서 월 출금 총계를 뺀 금액을 말합니다."],
        ],
    },
    {
        title: "1. 입금",
        src: [pubTotalSales02],
        desc: [
            ["① 도서판매금 | ", "각 판매처에서 도서가 판매 된 금액입니다."],
            ["② 출판사 정산금 | ", "도서판매금에서 수수료를 뗀 나머지입니다."],
            ["③ 선인세 차감 | ", "선인세로 빠져나간 금액입니다."],
            ["④ 선인세 추가 | ", "추가로 들어온 선인세입니다."],
            ["⑤ 기타지급금 | ", "기타 지급된 금액입니다."],
            ["⑥ 수익총계 | ", "전부 계산한 총 금액을 말합니다."],
        ]
    },
    {
        title: "2. 출금",
        src: [pubTotalSales03],
        desc: [
            ["① 출판사 정산금 | ", "도서판매금에서 수수료를 뗀 나머지입니다."],
            ["② 선인세 차감 | ", "선인세로 빠져나간 금액입니다."],
            ["③ 작가정산금 | ", "작가에게 정산 된 금액입니다."],
            ["④ 선인세 지급 | ", "작가에게 지급 된 선인세입니다."],
            ["⑤ 세금 | ", "판매금액에 부여 된 세금입니다."],
            ["⑥ 지급총계 | ", "전부 계산한 총 금액을 말합니다."],
        ]
    },
];
export const pubStatementItems = [
    {
        title: "1. 정산서 발급",
        src: [pubStatement01],
        desc: [
            ["① 명세서 구분 | ", "(1)월 정산 명세서 : 이번 달 매출에 대한 정산 내역입니다. (2)선인세 지급 명세서 : 지급 된 선인세에 대한 명세서입니다."],
            ["② 발급대상 | ", "발급 받는 작가를 선택할 수 있습니다."],
            ["③ 인감 업로드 | ", "정산서에 들어갈 인감을 업로드할 수 있습니다."],
            ["④ 적요 추가 | ", "정산서에 내용을 추가할 수 있습니다."],
        ],
    },
    {
        title: "2. 정산서 조회",
        src: [pubStatement02],
        desc: [
            ["① 명세서 구분 | ", "(1)월 정산 명세서 : 이번 달 매출에 대한 정산 내역입니다. (2)선인세 지급 명세서 : 지급 된 선인세에 대한 명세서입니다."],
            ["② 조회대상 | ", "조회 할 작가를 선택할 수 있습니다."],
            ["③ 발급 취소 | ", "정산서 발급을 취소할 수 있습니다."],
            ["④ 선택 다운로드 | ", "선택 된 작가들의 정산서를 다운로드 할 수 있습니다."],
        ],
    },
];
export const pubPortfolioItems = [
    {
        title: "1. 포트폴리오",
        src: [pubPortfolio01],
    },
    {
        title: "",
        src: [pubPortfolio02],
        desc: [
            ["① 리스트 버튼 | ","버튼 클릭시 썸네일 위주의 리스트와 전체 표지 위주의 리스트를 볼 수 있습니다."], 
            ["② 포트폴리오 | ","제목과 저자, 출판사 그리고 출간 여부를 확인할 수 있습니다."],
        ],
    },
    {
        title: "2. 포트폴리오 작품 등록",
        src: [pubPortfolio03],
    },
    {
        src: [pubPortfolio04],
    },
    {
        src: [pubPortfolio05],
        desc: [
            ["① 표지 등록 | ","포트폴리오 표지를 등록할 수 있습니다."],
            ["② ISBN | ","국제표준도서번호(ISBN)를 검색하여 추가할 수 있습니다."],
            ["③ 출간일 | ","도서를 출간한 날짜입니다. "],
            ["④ 유통시작일| ","플랫폼별 유통이 시작 된 날짜입니다."],
            ["⑤ 프로모션 정보 | ","플랫폼별 프로모션 정보를 적을 수 있습니다."],
            ["⑥ 썸네일 설정 | ","조회시 보일 썸네일을 설정할 수 있습니다."],
        ],
    },
    {
        src: [pubPortfolio06],
        srcWidth: ["440px"],
    },
    {
        title: "3. 포트폴리오 작품 상세",
        src: [pubPortfolio07],
        desc: [
            ["① 누적 판매금액 | ","지금까지 누적 된 작품 판매 금액을 말합니다"],
            ["② 누적 판매량 | ","지금까지 누적 된 작품 판매량을 말합니다."],
            ["③ 판매추이 | ","작품의 판매추이 일별, 월별 그래프입니다."],
            ["④ 타임라인 | ","작품의 유통 시작일, 프로모션 정보를 한 눈에 볼 수 있는 타임라인입니다."],
        ],
    },
    {
        src: [pubPortfolio08],
    },
];
export const pubEbookItems = [
    {
        title: "1. 제작형태 선택",
        src: [pubEbook01],
        srcWidth: ["90%"],
        desc: [
            ["① 제작 형태 선택 | ",`(1) 통파일 > 분할파일 : 하나의 파일을 여러 개로 분할하여 생성합니다.
                                    (2) 분할파일 > 통파일 : 여러 개의 파일을 하나의 파일로 생성합니다.
                                    (3) 파일 별 개별 생성 : 하나 또는 여러 개의 파일 별로 각각 생성합니다.`
            ],
            ["② 파일 형식 선택 | ","출력할 E-BOOK 파일 형식을 선택할 수 있습니다."],
            ["③ 기본 정보 입력 | ","E-BOOK 기본 정보를 입력합니다."],
            ["④ 변환할 파일 업로드 | ","-BOOK으로 만들 파일을 업로드 합니다."],
            ["⑤ 미리보기 | 제작 중인 ","E-BOOK 정보를 미리 볼 수 있습니다."],
        ]
    },
    {
        title: "2. 판권지",
        src: [pubEbook02],
        srcWidth: ["90%"],
    },
    {
        src: [pubEbook03],
        srcWidth: ["500px"],
        desc: [
            ["① 판권지 포함 여부 | ", `(1) 만들지 않음 : 판권지를 만들지 않습니다. 
                                        (2) 신규 생성 : 판권지를 생성합니다 
                                        (3) 본문 내 포함 : 파일에 판권지가 이미 있습니다.`],
            ["② 항목 추가 | ", `(1) 항목 : 판권지 항목을 추가할 수 있습니다 
                                (2) 텍스트만 : 항목이 없이 추가가 됩니다. 
                                (3) 공백 : 내용 없이 추가됩니다.`],
            ["③ 템플릿 | ", "템플릿을 추가하거나 불러올 수 있습니다."],
            ["④ 미리보기 | ", "판권지의 모습을 미리 볼 수 있습니다."],
            ["⑤ CSS 형식 | ", `(1) H1 / P : 제목 크기 또는 본문 
                                (2) 정렬 : 좌측, 중앙 , 우측
                                (3) B : Bold, 굵은 글씨
                                (4) I : Italic, 기울인 글씨
                                (5) U : Underline, 밑줄
                                (6) Link : 링크 텍스트`]
        ]
    },
    {
        title: "3. 제작방식 선택",
        src: [pubEbook04],
        srcWidth: ["90%"],
    },
    {
        src: [pubEbook05],
        srcWidth: ["90%"],
        desc: [
            ["① 소제목 포함 및 생성 여부 | ", `(1) 원고파일 내 포함 (별도페이지) : 원고파일에 소제목이 들어가 있으며, 별도페이지로 되어있습니다.
                                                (2) 원고파일 내 포함 (본문에 포함) : 원고파일에 소제목이 들어가 있으며, 본문에 포함되어 있습니다.
                                                (3) 소제목 생성 : 소제목을 직접 입력하고 생성합니다
                                                (4) 소제목 없음 : 소제목이 없습니다.`],                    
            ["② 소제목 스타일 | ", `(1) 소제목 폰트 크기 : 폰트 크기를 선택할 수 있습니다
                                                (2) 소제목 스타일 : 기울이기, 밑줄 등을 선택할 수 있습니다.
                                                (3) 소제목 정렬 : 왼쪽, 중앙, 오른쪽 정렬 중 선택할 수 있습니다.`],
            ["③ 소제목 입력 | ", "소제목을 생성하는 경우 직접 소제목을 입력할 수 있습니다."],
            ["④ 표지 업로드 | ", "E-BOOK표지를 업로드 할 수 있습니다."],
            ["⑤ 스타일 업로드 | ", "css파일을 업로드 할 수 있습니다."],
        ]
    },
    {
        title: "4. 제작완료",
        src: [pubEbook06],
        srcWidth: ["90%"],
        desc: [["", "다운로드가 가능한 시간내에 다운로드 / 전체 다운로드를 해주세요."]]
    },
];
export const pubMypageItems = [
    {
        src: [pubMypage01],
        srcWidth: ["70%"]
    },
    {
        title: "1. 내 정보 수정",
        desc: [
            ["① 프로필 | ", "내 프로필을 설정할 수 있습니다."],
            ["② 별명 | ", "글링에서 불려지는 별명입니다."],
            ["③ 휴대폰 | ", "휴대폰 번호가 바뀌었을시 인증을 통해 갱신하실 수 있습니다."],
        ],
    },
    {
        title: "2. 비밀번호 변경",
        desc: [["", "현재 사용하고 있는 비밀번호를 변경할 수 있습니다."]]
    },
    {
        title: "3. 내가 남긴 문의",
        src: [pubMypage02],
        desc: [["", "내가 남긴 문의가 답변 완료인지, 답변 대기중인지 확인할 수 있습니다."]]
    },
    {
        title: "4. 출판사 프로필 관리",
        src: [pubMypage03],
        desc: [
            ["① 회사 로고 | ", "회사의 로고를 올려주세요."],
            ["② 사업자 등록번호 | ", "회사의 사업자 등록번호를 적어주세요."],
            ["③ 출판사 이메일 | ", "회사와 연락이 가능한 이메일을 적어주세요."],
            ["④ 홈페이지 | ", "회사 홈페이지가 있는 경우 올려주세요."],
            ["⑤ 주소 | ", "회사 주소를 적어주세요."],
        ]
    },
    {
        title: "5. 계정 / 권한 관리",
        src: [pubMypage04],
    },
    {
        src: [pubMypage05],
        srcWidth: ["280px"],
        desc: [
            ["① 계정 | ", "글링에 가입한 직원들의 계정입니다."],
            ["② 권한 설정 | ", "직원들의 글링 사용 권한을 설정할 수 있습니다."],
            ["③ 출판사 연결 해제 | ", "출판사 직원 계정을 연결 해제할 수 있는 기능입니다."],
        ],
    },
];
export const pubUploaderItems = [
    {
        title: "1. 공지사항",
        src: [pubSupport01],
        desc: [["", "글링의 공지사항과 출판사 별 공지사항을 확인할 수 있습니다."]]
    },
    {
        title: "2. 이용가이드",
        src: [pubSupport02],
        desc: [["", "글링을 이용하면서 궁금한 점들을 찾아볼 수 있습니다."]]
    },
    {
        title: "3. 소프트웨어 다운로드",
        src: [pubSupport03],
        desc: [["", "판매 데이터를 자동으로 업로드 해주는 소프트웨어를 다운로드 받을 수 있습니다."]]
    },
    {
        title: "4. 자주 묻는 질문",
        src: [pubSupport04],
        desc: [["", "글링 이용자들이 주로 궁금해하는 질문을 모아놓은 페이지입니다. "]]
    },
    {
        title: "5. 문의하기",
        src: [pubSupport05],
        desc: [["", "글링에 문의할 내용이 있을 시 문의하기를 이용할 수 있습니다."]]
    },
    {
        src: [pubSupport06],
        desc: [
            ["① 카테고리 | ", "문의할 영역을 고릅니다."],
            ["② 출판사 | ", "문의를 할 출판사를 고릅니다."],
            ["③ 비밀여부 | ", "글을 공개로 올릴지 비공개로 올릴지 선택할 수 있습니다."],
        ]
    },
];
export const pubSupportItems = [
    {
        title: "1. 공지사항",
        src: [pubSupport01],
        desc: [["", "글링의 공지사항과 출판사 별 공지사항을 확인할 수 있습니다."]]
    },
    {
        title: "2. 이용가이드",
        src: [pubSupport02],
        desc: [["", "글링을 이용하면서 궁금한 점들을 찾아볼 수 있습니다."]]
    },
    {
        title: "3. 소프트웨어 다운로드",
        src: [pubSupport03],
        desc: [["", "판매 데이터를 자동으로 업로드 해주는 소프트웨어를 다운로드 받을 수 있습니다."]]
    },
    {
        title: "4. 자주 묻는 질문",
        src: [pubSupport04],
        desc: [["", "글링 이용자들이 주로 궁금해하는 질문을 모아놓은 페이지입니다. "]]
    },
    {
        title: "5. 문의하기",
        src: [pubSupport05],
        desc: [["", "글링에 문의할 내용이 있을 시 문의하기를 이용할 수 있습니다."]]
    },
    {
        src: [pubSupport06],
        desc: [
            ["① 카테고리 | ", "문의할 영역을 고릅니다."],
            ["② 출판사 | ", "문의를 할 출판사를 고릅니다."],
            ["③ 비밀여부 | ", "글을 공개로 올릴지 비공개로 올릴지 선택할 수 있습니다."],
        ]
    },
];

// 작가
export const dashboardItems = [
    {
        title: "",
        src: [Dashboard01],
        desc: [
            ["① 어제 | ", "어제 팔린 매출액을 보여줍니다."],
            ["② 7일 누적 | ", "일주일 동안 팔린 금액의 총합입니다."],
            ["③ 당월 누적 | ", "이번 달의 매출액 누적치입니다."],
            ["④ 전월 정산금 총계 | ", "저번 달의 매출액에서 세금 및 기타 비용을 제한 순 정산금입니다."],
            ["⑤ 판매 금액 분석 | ", "매일 팔린 데이터를 한 눈에 볼 수 있습니다. 월별을 누를 시 최근 몇 달 간의 판매 데이터를 볼 수 있습니다."],
            ["⑥ 캘린더 | ", "포트폴리오 관리의 일정을 캘린더에서 확인하실 수 있습니다."],
        ],
    },
    {
        title: "",
        src: [Dashboard02],
        desc: [
            ["① 일일 작품 분석 | ", "하루 동안 가장 많이 팔린 작품 매출을 비교할 수 있습니다."],
            ["② 일일 플랫폼 분석 | ", "하루 동안 가장 많이 팔린 플랫폼 매출을 비교할 수 있습니다."],
        ],
    },
    {
        title: "",
        src: [Dashboard03],
        desc: [
            ["③ 선인세 소진 현황 | ", "3개월 간 선인세가 소진 된 현황을 보여줍니다."],
        ],
    },
];
export const salesInquiryItems = [
    {
        title: "1. 일별 판매 현황",
        src: [SalesInquiry01],
        desc: [
            ["① 검색 필터 | ", `(1) 판매 일 : 어제, 지난 7일, 이번 달, 지난 달 같은 데이터부터 직접 선택까지 전부 가능합니다. 
                            (2) 판매 플랫폼 : 현재 판매중인 플랫폼을 선택할 수 있습니다.
                            (3) 출판사 : 계약을 맺은 출판사를 선택할 수 있습니다.
                            (4) 저자명 : 출간한 저자명을 선택할 수 있습니다.`],
            ["② 엑셀 다운로드 | ", "현재 조회 중인 데이터를 엑셀로 다운로드 할 수 있습니다."],
        ],
    },
    {
        title: "2. 월 정산",
        src: [SalesInquiry02],
        desc: [
            ["① 검색 필터 | ", `(1)정산 월 : 정산 받은 월을 검색할 수 있습니다.
            (2)판매 플랫폼 : 현재 판매중인 플랫폼을 선택할 수 있습니다.`],
        ],
    },
    {
        title: "3. 선인세",
        src: [SalesInquiry03],
        desc: [
            ["① 검색 필터 | ", `(1)정산 월 : 정산 받은 월을 선택할 수 있습니다.
            (2)선인세 구분 : 플랫폼에서 지급한 선인세인지 출판사에서 지급한 선인세인지 구분할 수 있습니다.
            (3)계약 시작일 : 선인세 계약 시작일을 말합니다.
            (4)소진여부 : 선인세를 전부 소진했는지 아닌지를 말합니다.
            (5)계약형태 : 계약시 작품을 계약했는지, 아니면 작가로 계약했는지를 말합니다.
            (6)계약금액 : 계약금액의 최소(min), 최대(max) 값을 정해 검색할 수 있습니다.
            (7)소진금액 : 소진금액의 최소(min), 최대(max) 값을 정해 검색할 수 있습니다.
            (8)잔여금액 : 소진금액의 최소(min), 최대(max) 값을 정해 검색할 수 있습니다.`],
        ],
    },
    {
        title: "4. 기타지급금",
        src: [SalesInquiry04],
        desc: [
            ["① 검색 필터 | ", `(1)지급 일 : 기타지급금을 지급한 날짜를 말합니다.
            (2)지급 금액 : 지급 금액의 최소(min), 최대(max) 값을 정해 검색할 수 있습니다.`],
        ],
    },
];
export const statementItems = [
    {
        title: "",
        src: [Statement01],
        desc: [
            ["① 명세서 구분 | ", `(1)월 정산 명세서 : 이번 달 매출에 대한 정산 내역입니다. 
                                (2)선인세 지급 명세서 : 지급 된 선인세에 대한 명세서입니다. `],
            ["② 조회대상 | ", "조회 할 출판사를 선택할 수 있습니다."],
            ["③ 다운로드 | ", "정산서를 다운로드 할 수 있습니다."],
        ],
    },
];
export const portfolioItems = [
    {
        title: "1. 포트폴리오",
        src: [Portfolio01, Portfolio02],
        desc: [
            ["① 리스트 버튼 | ", "버튼 클릭시 썸네일 위주의 리스트와 전체 표지 위주의 리스트를 볼 수 있습니다."], 
            ["② 포트폴리오 | ", "제목과 저자, 출판사 그리고 출간 여부를 확인할 수 있습니다."],
        ],
    },
    {
        title: "2. 포트폴리오 상세",
        src: [Portfolio03],
        desc: [
            ["① 누적 판매금액 | ", "지금까지 누적 된 작품 판매 금액을 말합니다"],
            ["② 누적 판매량 | ", "지금까지 누적 된 작품 판매량을 말합니다."],
            ["③ 판매추이 | ", "작품의 판매추이 일별, 월별 그래프입니다."],
            ["④ 타임라인 | ", "작품의 유통 시작일, 프로모션 정보를 한 눈에 볼 수 있는 타임라인입니다."],
        ],
    },
    {
        title: "",
        src: [Portfolio04],
        desc: [["", ""]],
    },
];
export const mypageItems = [
    {
        title: "",
        src: [Mypage01],
        srcWidth:["70%"],
        desc: [["", ""]],
    },
    {
        title: "1. 내 정보 수정",
        desc: [
            ["① 프로필 | ", "내 프로필을 설정할 수 있습니다."],
            ["② 별명 | ", "글링에서 불려지는 별명입니다."],
            ["③ 휴대폰 | ", "휴대폰 번호가 바뀌었을시 인증을 통해 갱신하실 수 있습니다."]
        ],
    },
    {
        title: "2. 비밀번호 변경",
        desc: [
            ["", "현재 사용하고 있는 비밀번호를 변경할 수 있습니다."],
        ],
    },
    {
        title: "3. 내가 남긴 문의",
        src: [Mypage02],
        srcWidth:["70%"],
        desc: [
            ["", "내가 남긴 문의가 답변 완료인지, 답변 대기중인지 확인할 수 있습니다."],
        ],
    },
    {
        title: "4. 작가 정보",
        src: [Mypage03],
        srcWidth:["70%"],
        desc: [
            ["", "작가정보 페이지는 현재 출간 중인 작품명과 저자명 그리고 계약한 출판사의 정보를 볼 수 있습니다."],
        ],
    },
];
export const supportItems = [
    {
        title: "1. 공지사항",
        src: [Support01],
        srcWidth:["70%"],
        desc: [
            ["", "글링의 공지사항과 출판사 별 공지사항을 확인할 수 있습니다."],
        ],
    },
    {
        title: "2. 이용가이드",
        src: [Support02],
        srcWidth:["70%"],
        desc: [
            ["", "글링을 이용하면서 궁금한 점들을 찾아볼 수 있습니다."],
        ],
    },
    {
        title: "3. 자주 묻는 질문",
        src: [Support03],
        srcWidth:["70%"],
        desc: [
            ["", "글링 이용자들이 주로 궁금해하는 질문을 모아놓은 페이지입니다."],
        ],
    },
    {
        title: "4. 문의하기",
        src: [Support04],
        srcWidth:["70%"],
        desc: [
            ["", "글링에 문의할 내용이 있을 시 문의하기를 이용할 수 있습니다."],
        ],
    },
    {
        title: "",
        src: [Support05],
        srcWidth:["70%"],
        desc: [
            ["① 카테고리 | ", "문의할 영역을 고릅니다."],
            ["② 출판사 | ", "문의를 할 출판사를 고릅니다."],
            ["③ 비밀여부 | ", "글을 공개로 올릴지 비공개로 올릴지 선택할 수 있습니다."]
        ],
    },
];