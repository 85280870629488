import { useState, useRef, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import _ from "lodash";
import CustomInput from "./CustomInput";
import { addComma } from "@/modules/utils/filter";

// 10의 배수로 step값 변경
const calcStep = (max: number) => {
    if (max <= 100) return 1;
    // 수정해야
    const digitNumber = max / 100 < 100 ? 2 : Math.ceil(max / 100).toString().length;
    return 1 + new Array(digitNumber).join("0");
};

export default function Slider({ min, max, reset, onChange }: SliderProps) {
    const [range, setRange] = useState({ min: 0, max: 0 });
    const [leftValue, setLeftValue] = useState(min);
    const [rightValue, setRightValue] = useState(max);
    const ActivatedThumb = useRef("");
    const step = calcStep(range.max);

    // 초기화
    useEffect(() => {
        setLeftValue(min);
        setRightValue(max);
        setRange({ min, max });
    }, [min, max]);

    useEffect(() => {
        if (reset) {
            setLeftValue(min);
            setRightValue(max);
            setRange({ min, max });
        }
    }, [reset]);

    // 슬라이더로 값 변경시
    const applySliderValue = () => {
        if (leftValue !== rightValue) onChange(leftValue, rightValue);
    };
    // 인풋으로 값 변경시
    const applyInputValue = (leftValue: number, rightValue: number) => {
        if (leftValue !== rightValue) onChange(leftValue, rightValue);
    };

    const changeLeftSlider = (e: ChangeEvent<HTMLInputElement>) => {
        let left = Math.min(e.target.valueAsNumber, rightValue);
        // max값이 step값의 배수가 아닐 때 나머지값은 절삭되기 떄문에 max값으로 대체
        if (range.max - left < Number(step)) left = range.max;
        setLeftValue(left);
        ActivatedThumb.current = "left";
    };

    const changeRightSlider = (e: ChangeEvent<HTMLInputElement>) => {
        let right = Math.max(e.target.valueAsNumber, leftValue);
        if (range.max - right < Number(step)) right = range.max;
        setRightValue(right);
        ActivatedThumb.current = "right";
    };

    return (
        <SliderContainer>
            <LeftSlider
                min={range.min}
                max={range.max}
                step={step}
                value={leftValue}
                activated={ActivatedThumb.current}
                onChange={changeLeftSlider}
                onMouseUp={applySliderValue}
            />
            <RightSlider
                min={range.min}
                max={range.max}
                step={step}
                value={rightValue}
                onChange={changeRightSlider}
                onMouseUp={applySliderValue}
            />
            <CustomSlider leftValue={leftValue} rightValue={rightValue} min={range.min} max={range.max} />
            <CustomInput
                min={min}
                max={max}
                reset={reset}
                setRange={setRange}
                setLeftValue={setLeftValue}
                setRightValue={setRightValue}
                onApplyInput={applyInputValue}
            />
        </SliderContainer>
    );
}

// 실제 화면상에 보여지게 될 슬라이더
function CustomSlider({ leftValue, rightValue, min, max }: CustomSliderProps) {
    let left = 0,
        right = 97;
    if (leftValue !== rightValue) {
        left = Math.ceil(((leftValue - min) / (max - min)) * 97);
        right = Math.ceil(((rightValue - min) / (max - min)) * 97);
    }

    return (
        <SliderWrapper>
            <Track />
            <Range left={left} right={right}>
                <RangeValue>
                    <ValueBox>
                        <p>{addComma(leftValue)}</p>
                        <Sub>~</Sub>
                        <p>{addComma(rightValue)}</p>
                    </ValueBox>
                </RangeValue>
            </Range>
            <ThumbLeft value={addComma(leftValue)} left={left} />
            <ThumbRight value={addComma(rightValue)} right={right} />
        </SliderWrapper>
    );
}

const SliderContainer = styled.div`
    display: flex;
    align-items: center;
`;
const LeftSlider = styled.input.attrs({ type: "range" })<{ activated: string; disabled?: boolean }>`
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    width: 360px;
    height: 4px;
    z-index: ${({ activated }) => (activated === "left" ? 200 : 100)};
    opacity: 0;
    ::-webkit-slider-thumb {
        background-color: green;
        width: 20px;
        height: 20px;
        cursor: pointer;
        pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
        -webkit-appearance: none;
        :active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }
`;

const RightSlider = styled.input.attrs({ type: "range" })<{ disabled?: boolean }>`
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    width: 360px;
    height: 4px;
    z-index: 100;
    opacity: 0;

    ::-webkit-slider-thumb {
        background-color: red;
        width: 20px;
        height: 20px;
        cursor: pointer;
        pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
        -webkit-appearance: none;
        :active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }
`;

const SliderWrapper = styled.div<{ active?: boolean }>`
    position: relative;
    width: 360px;
    height: 4px;
    :before {
        content: "min";
        display: inline-block;
        position: absolute;
        padding-top: 10px;
        font-size: 10px;
        color: ${({ theme }) => theme.colors.gray600};
    }
    :after {
        content: "max";
        display: inline-block;
        position: absolute;
        right: 0;
        padding-top: 10px;
        font-size: 10px;
        text-align: right;
        color: ${({ theme }) => theme.colors.gray600};
    }
    filter: ${({ active }) => (active ? `grayscale(0.9) hue-rotate(30deg) opacity(0.5)` : `none`)};
`;
const BaseBar = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
`;
const Track = styled(BaseBar)`
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.gray300};
`;
const Range = styled(BaseBar)<{ left: number; right: number }>`
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.blue500};
    left: ${({ left }) => `${left}%` || 0};
    right: ${({ right }) => `${100 - right}%` || 0};
`;
const RangeValue = styled.div`
    position: absolute;
    min-width: 100px;
    width: auto;
    height: 20px;
    padding: 4px;
    background-color: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 99px;
    transform: translate(-50%, -140%);
    left: 50%;

    :after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        border-bottom: 4px solid transparent;
        border-top: 4px solid ${({ theme }) => theme.colors.blue500};
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
    }
`;
const ValueBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    font-size: 10px;
    font-weight: 500;
`;
const Sub = styled.div`
    margin: 0 2px;
`;
const Thumb = styled.div`
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    border: 2px solid #ffffff;
    border-radius: 50%;
    background-color: #00bcd4;
    z-index: 10;
    /* pointer-events: none; */
    cursor: pointer;
`;
const ThumbLeft = styled(Thumb)<{ value: string; left: number }>`
    left: ${({ left }) => `${left}%` || "0%"};
`;
const ThumbRight = styled(Thumb)<{ value: string; right: number }>`
    left: ${({ right }) => `${right}%` || "0%"};
`;

interface SliderProps {
    min: number;
    max: number;
    reset?: boolean;
    onChange: (left: number, right: number) => void;
}

interface CustomSliderProps {
    leftValue: number;
    rightValue: number;
    min: number;
    max: number;
}
