import styled from "styled-components";
import Button from "@/components/atoms/button";
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";
import { Step } from "@/pages/ebook-maker/types";

export default function ButtonContainer({ stepHandler }: { stepHandler: (step: number, disabled: boolean) => void; }) {
    const { resultFileList, tmpFolderName, time } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const downloadAll = () => {
        let url = "";
        let fileName = "";
        if (resultFileList.length === 1) {
            fileName = resultFileList[0];
            url = `download/newEpub?fileName=${fileName}&tmpFolder=${tmpFolderName}`;
        } else {
            fileName = "converted.zip";
            url = `download/newEpub/all?tmpFolder=${tmpFolderName}`;
        }

        let link = document.createElement("a");
        document.body.appendChild(link);
        link.download = fileName;
        link.href = `/file/${url}`;
        link.click();
        document.body.removeChild(link);
    }
    
    const initEbookMaker = () => {
        dispatch({ type: "ebookMaker/initState" });
        stepHandler(Step.Form, false);
    }

    return (
        <StyledContainer>
            <Button
                type="red-outline"
                label="처음으로"
                onClickHandler={initEbookMaker}
                customStyle={{ width: "120px" }}
            />
            {time > 0 ? 
                <Button 
                    type="main" 
                    label="전체 다운로드" 
                    onClickHandler={downloadAll} 
                    customStyle={{ width: "120px", margin: "0 0 0 12px" }}
                /> : 
                <></>
            }
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    margin-top: 24px;
    justify-content: right;
`