import styled from 'styled-components'
import React from "react";
import Text from "@/components/atoms/text/index"

interface TextStyle {
    fontSize: string,
    fontWeight: string,
    color: string
}

interface QuickProps {
    Svg: React.FunctionComponent,
    title: string,
    description: string,
    titleStyle: TextStyle,
    descriptionStyle: TextStyle,
    buttonPath: String
}

export default function QuickItem({Svg, title, description, titleStyle, descriptionStyle, buttonPath}: QuickProps) {
    
    const goPath = () => {
        window.location.href = `${buttonPath}`
    }
    
    return (
        <StyledContainer onClick={goPath}>
            <IconWrapper>
                <Svg/>
            </IconWrapper>
            <TextContainer>
                <Text text={title} customStyle={titleStyle}/>
                <Text text={description} customStyle={descriptionStyle}/>
            </TextContainer>
        </StyledContainer>
    )   
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.blue50};
    border-radius: 20px;
    padding: 4px;
`;

const TextContainer = styled.div`
    line-height: 28px;
`;