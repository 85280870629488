import styled from "styled-components";
import { useEffect } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { ReactComponent as ClearMark } from "@/assets/icons/clear.svg";
import { ReactComponent as AddMark } from "@/assets/icons/add-data.svg";
import theme from "@/assets/styles/theme";
import Text from "@/components/atoms/text";
import useAlert from "@/modules/hooks/useAlert";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import PercentInputText from "@/components/molecules/basedata-manage//PercentInputText";
import DateSelector from "@/components/molecules/basedata-manage/RatioPeriodModalDateSelector";
import moment from "moment";
import { seriesActions } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import { ratio, Validation } from "@/modules/utils/validate_modify";

export default function SetRatioPeriodModalBody({ from, close }:SetRatioPeriodBodyProps) {
    const dispatch = useAppDispatch();
    let [ period, currIdx ] = useAppSelector((state:RootState) => [
        state.series.currPeriod,
        state.series.currIdx
    ]);
    const { alert } = useAlert();
    useEffect(()=> {
    }, [period, currIdx])

    function ratioValidate(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        let value = e.target.value;
        const validateRatio = ratio({ value, error: "ratioError", mandatory: true, field: "정산비율", decimal: false }) as Validation;
        dispatch(seriesActions.setSeriesDatas({
            key: "currPeriod", value: validateRatio.value, index: index, objectKey: "ratio"
        }));
        dispatch(seriesActions.setSeriesDatas({
            key: "currPeriod", value: validateRatio.ratioError, index: index, objectKey: "ratioError"
        }));
    }

    function changeEndDate(date:string, index:number) {
        dispatch(seriesActions.setSeriesDatas({
            key: "currPeriod", value: date, index: index, objectKey: "endYearmon"
        }));

        const nextPeriod = period[index + 1];
        if (nextPeriod && nextPeriod.endYearmon) {
            dispatch(seriesActions.setSeriesDatas({
                key: "currPeriod", value: moment(date).add(1, "months").format("YYYYMM"), index: index+1, objectKey: "startYearmon"
            }));
        }
        periodValidate(date, index);
    }

    async function removePeriod(idx:number) {
        if (period.length === 2) {
            return await alert("warning", "경고", "2개 이상의 기간이 필요합니다.");
        }
        let tmpPeriod = JSON.parse(JSON.stringify(period));
        if (idx === tmpPeriod.length-1) {
            // 마지막 행인 경우
            tmpPeriod[idx-1].endYearmon = "999999";
            tmpPeriod[idx-1].endYearmonError = "";
        } else {
            tmpPeriod[idx+1].startYearmon = tmpPeriod[idx].startYearmon;
        }
        tmpPeriod.splice(idx, 1);
        dispatch(seriesActions.setSeriesDatas({
            key: "currPeriod", value: tmpPeriod
        }))
    }

   async function addPeriod() {
        let tmp = JSON.parse(JSON.stringify(period));
        for (let p of period) {
            if (p.endYearmonError) return await alert("warning", "확인", "조건을 확인해주세요.");
        }

        let tmpPeriod = tmp[tmp.length - 1];
        tmpPeriod.endYearmon = tmpPeriod.startYearmon;

        tmp.push({
            startYearmon: moment(tmpPeriod.endYearmon).add(1, "months").format("YYYYMM"),
            endYearmon: "999999",
            ratio: "",
            endYearmonError: "",
            ratioError: ""
        });

        dispatch(seriesActions.setSeriesDatas({
            key: "currPeriod", value: tmp
        }))
    }
    
    async function releasePeriod() {
        try {
            const answer = await alert("confirm", "확인", "가장 마지막 정산비율을 기준으로 정산비율이 일괄 적용됩니다.\n진행 하시겠습니까?");

            if (answer === "Y") {
                let tmpPeriod = [{
                    startYearmon: "000000",
                    endYearmon: "999999",
                    ratio: period[period.length-1].ratioError ? "" : period[period.length-1].ratio,
                    ratioError: ""
                }];
                if (typeof currIdx === "number") {
                    if (period[period.length-1].ratioError) {
                        dispatch(seriesActions.setRatios({
                            key: "ratio", value: "", index: currIdx
                        }));
                        dispatch(seriesActions.setRatios({
                            key: "period", value: tmpPeriod, index: currIdx
                        }))
                    } else {
                        dispatch(seriesActions.setRatios({
                            key: "ratioError", value: "", index: currIdx
                        }));
                        dispatch(seriesActions.setRatios({
                            key: "ratio", value: tmpPeriod[0].ratio, index: currIdx
                        }));
                        dispatch(seriesActions.setRatios({
                            key: "period", value: tmpPeriod, index: currIdx
                        }));
                    }
                } else {
                    // 비율 상세설정 allCheck(전체)인 경우
                    if (tmpPeriod[0].ratioError) {
                        dispatch(seriesActions.setPeriod({
                            key: "ratio", value: ""
                        }));
                        dispatch(seriesActions.setPeriod({
                            key: "period", value: tmpPeriod
                        }));
                    } else {
                        dispatch(seriesActions.setPeriod({
                            key: "ratioError", value: ""
                        }));
                        dispatch(seriesActions.setPeriod({
                            key: "ratio", value: tmpPeriod[0].ratio
                        }));
                        dispatch(seriesActions.setPeriod({
                            key: "period", value: tmpPeriod
                        }));
                    }
                }
                close();
                // dispatch(modalAction.popModal());
            }
        } catch (err) {
            alert("error", "오류 발생", "기간별 설정 해제중 오류가 발생하였습니다.");
        }
    }

    function periodValidate(date:string, index: number) {
        let tmpPeriod = JSON.parse(JSON.stringify(period));
        tmpPeriod[index].endYearmon = date;
        tmpPeriod[index+1].startYearmon = moment(date).add(1, "months").format("YYYYMM");
        for (let p of tmpPeriod) {
            const currIdx = tmpPeriod.indexOf(p);
            const nextPeriod = tmpPeriod[currIdx+1];
            let hasError = false;

            // 현재 종료월이 시작월 이후인지 확인
            if (p.startYearmon && Number(p.endYearmon) < Number(p.startYearmon)) {
                dispatch(seriesActions.setSeriesDatas({
                    key: "currPeriod", value: "시작월 이후의 월을 선택해주세요.", index: currIdx, objectKey: "endYearmonError"
                }))
                hasError = true;
            }

            // 현재 종료월이 다음기간 종료월 이전인지 확인
            if (nextPeriod && nextPeriod.endYearmon && Number(p.endYearmon) >= Number(nextPeriod.endYearmon)) {
                dispatch(seriesActions.setSeriesDatas({
                    key: "currPeriod", value: "다음 종료월 이전의 월을 선택해주세요.", index: currIdx, objectKey: "endYearmonError"
                }))
                // p.nuw = true;
                hasError = true;
            }

            if (!hasError) {
                dispatch(seriesActions.setSeriesDatas({
                    key: "currPeriod", value: "", index: currIdx, objectKey: "endYearmonError"
                }))
            }
        }
    }

    return (
        <BodyWrap id="setRatioPeriodModalBody">
            <FieldRow>
                <ColumnName>
                    <Text text="시작" customStyle={{ fontSize: "12px" }}/>
                </ColumnName>
                <ColumnName width="60px" />
                <ColumnName>
                    <Text text="종료" customStyle={{ fontSize: "12px" }}/>
                </ColumnName>
                <ColumnName>
                    <Text text="정산비율(%)" customStyle={{ fontSize: "12px", textAlign: "center" }}/>
                </ColumnName>
                <ColumnName width="60px" />
                <ColumnName width="60px" />
            </FieldRow>
            {period.map((p:any, i:number) => 
                <ItemRow key={i}>
                    <Item justifyContent="center">
                        {
                            p.startYearmon === "000000" ?
                            <Text
                                text="판매시작"
                                customStyle={{ fontSize: "12px", fontWeight: "400", color: theme.colors.gray600 }}
                            />
                            :
                            <>
                                <StartDateBox marginRight="8px" >
                                    <Text text={p.startYearmon.substr(0, 4)} customStyle={{ color: theme.colors.gray600, fontSize: "12px" }} />
                                </StartDateBox>
                                <StartDateBox width="80px">
                                    <Text  text={p.startYearmon.substr(4, 2)} customStyle={{ color: theme.colors.gray600, fontSize: "12px" }} />
                                </StartDateBox>
                            </>
                        }
                    </Item>
                    <Item width="60px" padding="0px" justifyContent="center">
                        <Text
                            text="~"
                            customStyle={{ fontSize: "14px", fontWeight: "400"}}
                        />
                    </Item>
                    <Item justifyContent="center">
                        {
                            p.endYearmon === "999999" ?
                            <Text
                                text="판매종료"
                                customStyle={{ fontSize: "12px", fontWeight: "400", color: theme.colors.gray600 }}
                            />
                            :
                            <ValidateWrapper dataError={p.endYearmonError}>
                                <DateSelector
                                    index={i}
                                    onChangeHandler={changeEndDate}
                                    initialDate={p.endYearmon}
                                />
                            </ValidateWrapper>
                        }
                    </Item>
                    <Item width="220px">
                        <ValidateWrapper dataError={p.ratioError} >
                            <PercentInputText
                                type="number"
                                value={p.ratio}
                                inText="%"
                                onChange={(e)=> ratioValidate(e, i)}
                                customStyle={{ width: "200px", height: "32px", padding: "0 20px 0 0" }}
                            />
                        </ValidateWrapper>
                    </Item>
                    <IconWrap onClick={() => removePeriod(i)}>
                        <ClearMark width="24px" height="24px" fill={theme.colors.gray600} />
                    </IconWrap>
                    { i === 0 &&
                        <IconWrap width="80px" direction="column" onClick={addPeriod}>
                            <AddMark width="24px" height="24px" fill={theme.colors.blue500} />
                            <Text text="구간 추가" customStyle={{ fontSize: "12px", fontWeight: "500", color: theme.colors.blue500 }}/>
                        </IconWrap>
                    }
                </ItemRow>
                )
            }
            <GuideWrap>
                <Text text="기간별 설정을 해지하겠습니까?" customStyle={{ fontSize: "12px", fontWeight: "400", margin: "0 6px 0 0" }} />
                <UnderLineText onClick={releasePeriod}>
                    기간별 설정 해제
                </UnderLineText>
            </GuideWrap>
        </BodyWrap>
    )

}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 320px;
    max-height: 320px;
    overflow: auto;
`

const ItemRow = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`

const Item = styled.div<{ width?: string, justifyContent?: string, padding?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "240px"};
    height: 60px;
    font-size: 12px;
    padding: ${({ padding }) => padding ? padding : "0 0 0 8px"};
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    min-height: 48px;
`

const ColumnName = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "240px"};
    height: 48px;
    font-size: 12px;
    padding: 8px;
`

const IconWrap = styled.div<{ direction?: string, disable?: boolean, width?: string }>`
    display: flex;
    flex-direction: ${({ direction }) => direction ? direction : "row"};
    align-items: center;
    justify-content: center;
    width: ${({ width }) => width ? width : "60px"};
    height: 60px;
    font-size: 12px;
    padding: 8px;
    cursor: ${({ disable }) => disable ? "default" : "pointer"};
`

const GuideWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-height: 32px;
`

const UnderLineText = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.blue500};
    padding-bottom: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue500};
    cursor: pointer;
`

const StartDateBox = styled.div<{ width?: string, marginRight?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => width ? width : "120px"};
    height: 32px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.gray50};
    margin-right: ${({ marginRight }) => marginRight ? marginRight : ""};
`

interface SetRatioPeriodBodyProps {
    from: string;
    close: Function;
}