import _ from "lodash";
import { colors } from "@/assets/styles/theme";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import { setItem, setItems } from "@/pages/settlement/sales-entry/etc/etcSlice";
import { updateRow } from "@/pages/common/dataTableSlice";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import AuthorSearch from "@/components/molecules/basedata-manage/AuthorSearch";
import useCalculateAmount from "./hooks/useCalculateAmount";
import { Item } from "@/components/molecules/common/table/DataTable";

export default function EtcAuthorSearch({ item, index }: EtcAuthorSearchProps) {
    const { items } = useSelectorTyped((state: RootState) => state.entryEtc);
    const dispatch = useAppDispatch();
    const { calculateAmount } = useCalculateAmount();

    const applyCoInfo = (co_info: any, idx: number) => {
        let newItems = _.cloneDeep(items);
        newItems[idx].authorInfo.co_info = co_info;

        for (let t of newItems[idx].author_tax_info) {
            for (let c of co_info) {
                if (t.ae_id === c.ae_id) {
                    t.co_st_ratio = c.coRatio;
                    break;
                }
            }
        }

        newItems[idx].needCoRatio = false;
        newItems[idx].authorError = "";
        
        calculateAmount(newItems[idx]);
        dispatch(setItems(newItems));
        dispatch(updateRow({ itemKey: "id", updatedRow: newItems[idx] }));
    };

    const selectAuthor = (idx: number, authorInfo: any) => {
        let newItem = { ...item };

        if (authorInfo.co_info.length > 1) {
            newItem.needCoRatio = true;
        } else {
            newItem.needCoRatio = false;
        }

        newItem.authorInfo = authorInfo;
        newItem.tax_type = "I";
        newItem.tax_desc = "";
        newItem.author_tax_info = authorInfo.co_info.map((c: any) => {
            return {
                ae_id: c.ae_id,
                tax_type: c.taxType,
                tax_ratio: c.taxRatio,
                tax_amount: "",
                co_st_ratio: authorInfo.co_info.length === 1 ? "100" : "",
                real_name: c.realName,
                user_id: c.user_id,
                taxAmountError: "",
            };
        });
        newItem.authorError = "";
        newItem.taxDescError = "";
        newItem.taxAmountError = "";
        if (authorInfo.co_info.length > 1) newItem.isCoAuthor = true;
        else newItem.isCoAuthor = false;

        calculateAmount(newItem);
        dispatch(setItem({ index, item: newItem }));
        dispatch(updateRow({ itemKey: "id", updatedRow: newItem }));
    };

    const deleteAuthor = (idx: number) => {
        let newItem = { ...item };
        let authorInfo = {
            author_id: 0,
            author_name: "",
            co_info: [],
        };
        newItem.authorInfo = authorInfo;
        newItem.author_id = 0;
        newItem.author_name = "";
        newItem.author_tax_info = [];
        newItem.author_tax_ratio = 0;
        newItem.author_tax_type = "";
        newItem.co_ratio = 0;
        newItem.authorError = "저자명을 입력해주세요";

        dispatch(setItem({ index, item: newItem }));
        dispatch(updateRow({ itemKey: "id", updatedRow: newItem }));
    };

    return (
        <ValidateWrapper dataError={item["authorError"]}>
            <AuthorSearch
                from="series-update"
                idx={index}
                author={item["authorInfo"]}
                value={item["authorInfo"]["author_name"]}
                placeholder="저자명"
                customStyle={{
                    width: "140px",
                    height: "32px",
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: "400",
                }}
                buttonCustomStyle={{
                    customStyle: {
                        width: "120px",
                        height: "32px",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: colors.gray600,
                    },
                }}
                selectAuthorFunc={selectAuthor}
                deleteAuthorFunc={deleteAuthor}
                applyCoInfoFunc={applyCoInfo}
            />
        </ValidateWrapper>
    );
}

interface EtcAuthorSearchProps {
    item: Item;
    index: number;
}
