import styled from 'styled-components'
import Text from '@/components/atoms/text';
import { colors } from '@/assets/styles/theme';

export default function ReviewDetailContainer({detailObj}: {detailObj: DetailProps}) {
    const descriptionStyle = {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "28px",
        color: colors.black
    }
    
    const filteredText = detailObj.description.split("<br/>").map((txt, idx)=> <Text key={idx} text={txt} customStyle={descriptionStyle}/>);
    
    return (
        <StyledContainer>
            <SummaryStyledWrapper>
                { detailObj.summary }
            </SummaryStyledWrapper>
            <DescriptionStyledWrapper>
                { filteredText }
            </DescriptionStyledWrapper>
        </StyledContainer>
    )
}


const StyledContainer = styled.div`
    width: 400px;
    margin-left: 24px;
    padding: 7.5px 0;
    color: ${colors.black};
`

const SummaryStyledWrapper = styled.div`
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 8px;
`

const DescriptionStyledWrapper = styled.div`
    padding: 16px;
    background-color: ${colors.gray50};
    border-radius: 8px;
`

interface DetailProps {
    summary: string,
    description: string
}