import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import Checkbox from "@/components/atoms/input/Checkbox";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import UploadContainer from "@/components/molecules/common/upload";

export default function CoverUploadContainer() {
    const { noCoverImg, coverFile } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const checkNoCoverImg = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "ebookMaker/setNoCoverImg", payload: e.target.checked });
    }

    const deleteFile = () => {
        dispatch({ type: "ebookMaker/deleteCoverFile" });
    }

    const uploadHandler = async (fileList: any[]) => {
        dispatch({ type: "ebookMaker/setCoverFile", payload: fileList[0]});
    }

    const checkboxLabelText = {
        fontSize: "13px",
        fontWeight: "400",
        padding: "0 0 2px 8px"
    }

    return (
        <StyledContainer>
            <FieldWrapper>
                <Text text="표지 업로드" customStyle={{ fontSize: "14px" }} />
            </FieldWrapper>
            <ContentWrapper>
                {coverFile.length ? (
                    <HasFileWrapper>
                        <PreviewWrapper>
                            <Text text="미리보기" customStyle={{ fontSize: "16px", fontWeight: "700" }} />
                            <ImgWrapper>
                                <img src={coverFile[0].file} alt="preview" />
                            </ImgWrapper>   
                        </PreviewWrapper>
                        <FileInfoWrapper>
                            <Text text="파일명" customStyle={{ fontSize: "14px", height: "28px" }} />
                            <FileNameWrapper>
                                <Text text={`${coverFile[0].name}`} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                <StyledClear onClick={deleteFile} fill={colors.gray600} width={24} height={24} />
                            </FileNameWrapper>
                        </FileInfoWrapper>
                    </HasFileWrapper>
                ) : (
                    <NoFileWrapper>
                        <UploadContainer
                            accept={["jpg", "png"]}
                            multiple={false}
                            height={148}
                            uploadHandler={uploadHandler}
                            disabled={noCoverImg}
                        />
                        <CheckboxWrapper>
                            <Checkbox type="boolean" active={noCoverImg} onChangeHandler={checkNoCoverImg} />
                            <Text text="표지없음" customStyle={checkboxLabelText} />
                        </CheckboxWrapper>
                    </NoFileWrapper>
                )}
            </ContentWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    height: 240px;
`

const FieldWrapper = styled.div`
    display: flex;
    width: 280px;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    margin-right: 20px;
`

const ContentWrapper = styled.div`
    width: 900px;
`

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 12px 24px;
`

const NoFileWrapper = styled.div`
    margin-top: 36px;
`

const HasFileWrapper = styled.div`
    margin-top: 12px;
    display: flex;
`

const PreviewWrapper = styled.div`
    width: 134px;
    height: 228px;
`

const ImgWrapper = styled.div`
    width: 134px;
    height: 200px;
    margin-top: 8px;
    background-color: ${colors.gray50};
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`

const FileInfoWrapper = styled.div`
    margin: 24px;
    padding: 24px;
    width: 380px;
    height: 200px;
`

const FileNameWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 340px;
    height: 40px;
`

const StyledClear = styled(Clear)`
    cursor: pointer;
`