import styled from "styled-components";
import SelectBox from "@/components/atoms/selectBox/index";
import { SortOptions, ViewOptions } from "@/pages/portfolio/author/types";
import { ReactComponent as Card } from "@/assets/icons/portfolio/card.svg";
import { ReactComponent as List } from "@/assets/icons/portfolio/list.svg";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

export default function FilterContainer() {
    const { sortOption, viewOption } = useAppSelector((state: RootState) => state.authorPortfolio);
    const dispatch = useAppDispatch();

    const sortOptions = [
        { name: "출간일순", value: SortOptions.NEW },
        { name: "가나다순", value: SortOptions.ABC },
    ]

    const changeSortOption = (value:any) => {
        dispatch({ type: "authorPortfolio/setSortOption", payload: value.value });
    }

    const setViewOption = (option: ViewOptions) => {
        dispatch({ type: "authorPortfolio/setViewOption", payload: option });
    }

    return (
        <StyledContainer>
            <SelectBox
                empty={true}
                labelKey="name"
                valueKey="value"
                selected={sortOption}
                options={sortOptions}
                onClickValueFunc={changeSortOption}
                customStyle={{}}
            />
            <RightWrapper>
                <Card 
                    fill={viewOption === ViewOptions.CARD ? colors.blue500 : colors.gray600} 
                    onClick={() => setViewOption(ViewOptions.CARD)}
                />
                <List 
                    fill={viewOption === ViewOptions.LIST ? colors.blue500 : colors.gray600} 
                    onClick={() => setViewOption(ViewOptions.LIST)}
                />
            </RightWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    margin-top: 24px;
`

const RightWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        cursor: pointer;
    }
`