import { configureStore, combineReducers, Reducer, AnyAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import signinReducer from "@/pages/auth/signIn/signinSlice";
import userReducer from "@/pages/common/userSlice";
import pubDashboardReducer from "@/pages/dashboard/publisher/slice";
import authorDashboardReducer from "@/pages/dashboard/author/slice";
import authorReducer from "@/pages/settlement/basedata-manage/author/authorSlice";
import platformReducer from "@/pages/common/platformSlice";
import seriesReducer from "@/pages/settlement/basedata-manage/series/seriesSlice";
import basePlatformReducer from "@/pages/settlement/basedata-manage/platform/platformSlice";
import exceluploadReducer from "@/pages/settlement/basedata-manage/excelUploadManagement/exceluploadSlice";
import bulkenrollReducer from "@/pages/settlement/basedata-manage/bulkEnroll/bulkenrollSlice";
import paginationReducer from "@/pages/common/paginationSlice";
import alertReducer from "@/pages/common/alertSlice";
import fileReducer from "@/pages/common/fileSlice";
import dataTableReducer from "@/pages/common/dataTableSlice";
import modalReducer from "@/pages/common/modalSlice";
import inquiryDailyReducer from "@/pages/settlement/saels-inquiry/publisher/daily/dailySlice";
import inquiryDailyDeleteReducer from "@/pages/settlement/saels-inquiry/publisher/daily/dailyDeleteSlice";
import inquiryMonthlyReducer from "@/pages/settlement/saels-inquiry/publisher/monthly/monthlySlice";
import inquiryMgReducer from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";
import inquiryEtcReducer from "@/pages/settlement/saels-inquiry/publisher/etc/etcSlice";
import inquiryAuthorDailyReducer from "@/pages/settlement/saels-inquiry/author/daily/dailySlice";
import inquiryAuthorMonthlyReducer from "@/pages/settlement/saels-inquiry/author/monthly/monthlySlice";
import inquiryAuthorMgReducer from "@/pages/settlement/saels-inquiry/author/mg/mgSlice";
import inquiryAuthorEtcReducer from "@/pages/settlement/saels-inquiry/author/etc/etcSlice";
import inquiryAuthorCommonReducer from "@/pages/settlement/saels-inquiry/author/commonSlice";
import entryDailyReducer from "@/pages/settlement/sales-entry/daily/dailySlice";
import entryMonthlyReducer from "@/pages/settlement/sales-entry/monthly/monthlySlice";
import entryMgReducer from "@/pages/settlement/sales-entry/mg/mgSlice";
import entryEtcReducer from "@/pages/settlement/sales-entry/etc/etcSlice";
import ebookMakerReducer from "@/pages/ebook-maker/slice";
import totalSalesReducer from "@/pages/settlement/total-sales/slice";
import loadingReducer from "@/pages/common/loadingSlice";
import pubStatementReducer from "@/pages/settlement/statement/publisher/slice";
import authorStatementReducer from "@/pages/settlement/statement/author/slice";
import socketReducer from "@/pages/common/socketSlice";
import signupReducer from "@/pages/auth/signUp/signupSlice";
import myPageReducer from "@/pages/mypage/slice";
import authorMyPageReducer from "@/pages/mypage/author/slice";
import pubMyPageReducer from "@/pages/mypage/publisher/slice";
import authCommonReducer from "@/pages/auth/authSlice";
import findpwReducer from "@/pages/auth/findPw/findpwSlice";
import pubPortfolioReducer from "@/pages/portfolio/publisher/slice";
import authorPortfolioReducer from "@/pages/portfolio/author/slice";
import searchHistoryReducer from "@/pages/common/search/searchHistorySlice";
import landingReducer from "@/pages/landing/slice";

import noticeReducer from "@/pages/support/notice/slice";
import downloadReducer from "@/pages/support/download/slice";
import questionReducer from "@/pages/support/question/slice";
import inquiryReducer from "@/pages/support/inquiry/slice";

import uploaderReducer from "@/pages/uploader/uploaderSlice";
import appFileReducer from "@/pages/app/appSlice";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const appReducer = combineReducers({
    signin: signinReducer,
    user: persistReducer(persistConfig, userReducer),
    pubDashboard: pubDashboardReducer,
    authorDashboard: authorDashboardReducer,
    author: authorReducer,
    platform: platformReducer,
    series: seriesReducer,
    basePlatformdata: basePlatformReducer,
    excelupload: exceluploadReducer,
    bulkenroll: bulkenrollReducer,
    pagination: paginationReducer,
    alert: alertReducer,
    file: fileReducer,
    dataTable: dataTableReducer,
    modal: modalReducer,
    inquiryDaily: inquiryDailyReducer,
    inquiryDailyDelete: inquiryDailyDeleteReducer,
    inquiryMonthly: inquiryMonthlyReducer,
    inquiryMg: inquiryMgReducer,
    inquiryEtc: inquiryEtcReducer,
    inquiryAuthorDaily: inquiryAuthorDailyReducer,
    inquiryAuthorMonthly: inquiryAuthorMonthlyReducer,
    inquiryAuthorMg: inquiryAuthorMgReducer,
    inquiryAuthorEtc: inquiryAuthorEtcReducer,
    inquiryAuthorCommon: inquiryAuthorCommonReducer,
    entryDaily: entryDailyReducer,
    entryMonthly: entryMonthlyReducer,
    entryMg: entryMgReducer,
    entryEtc: entryEtcReducer,
    ebookMaker: ebookMakerReducer,
    totalSales: totalSalesReducer,
    loading: loadingReducer,
    pubStatement: pubStatementReducer,
    authorStatement: authorStatementReducer,
    socket: socketReducer,
    signup: signupReducer,
    myPage: myPageReducer,
    pubMyPage: pubMyPageReducer,
    authorMyPage: authorMyPageReducer,
    auth: authCommonReducer,
    findpw: findpwReducer,
    pubPortfolio: pubPortfolioReducer,
    authorPortfolio: authorPortfolioReducer,
    searchHistory: searchHistoryReducer,
    landing: landingReducer,
    notice: noticeReducer,
    download: downloadReducer,
    question: questionReducer,
    inquiry: inquiryReducer,
    uploader: uploaderReducer,
    appFile: appFileReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type.includes("user/logout")) {
        storage.removeItem("persist:root");
        storage.setItem("persist:root", JSON.stringify(state.user))
        state = {...state} as RootState;
    }
    return appReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // serializableCheck: {
            //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            //     warnAfter: 128,
            // },
            serializableCheck: false,
            immutableCheck: false,
        }),
    devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
// custom redux hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
