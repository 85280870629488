import styled from 'styled-components'
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn';
import DashBoard from '@/assets/images/introduce-image/03.png'
import Portfolio from '@/assets/images/introduce-image/03-2.png'
import Ebook from '@/assets/images/introduce-image/03-3.png'

export default function PortfolioImageContainer({tab}: {tab:number}) {
    const imgArr = [DashBoard, Portfolio, Ebook];
    const fadeInImage = useScrollFadeIn('up', 1, 0, 0.1);

    return (
        <StyledWrapper {...fadeInImage}>
            {imgArr.map((imgsrc, idx)=>
                <PortfolioImageWrapper key={idx}>
                    {tab===idx && <img src={imgsrc} width="688px" alt="portfolidImg"/>}
                </PortfolioImageWrapper>
            )}
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    margin-left: 212px;
    padding: 60px 0px;
`;

const PortfolioImageWrapper = styled.div`
`;