/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import {
    setFilterOption,
    setError,
    setReset,
    resetStore,
} from "@/pages/settlement/saels-inquiry/publisher/etc/etcSlice";
import FilterContainer from "@/components/molecules/common/searchFilter/FilterContainer";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import EtcSearchBar from "./EtcSearchBar";
import DateRangePicker from "@/components/molecules/sales-inquiry/common/DateRangePicker";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import Slider from "@/components/molecules/common/slider/Slider";
import useValidateDate from "@/modules/hooks/useValidateDate";
import usePlatformSetting from "../hooks/usePlatformSetting";
import { calcPeriod } from "@/modules/utils/date";

export default function EtcSearchFilter() {
    const { tagFilterOptions, platformList, platformIds } = usePlatformSetting();
    const { startDate, endDate, startDateError, endDateError, sliderMinValue, sliderMaxValue, reset } =
        useSelectorTyped((state: RootState) => state.inquiryEtc);
    const dispatch = useAppDispatch();
    const [showMore, setShowMore] = useState(false);
    const { validateStartDate, validateEndDate } = useValidateDate();
    const { startDate: initialStartDate, endDate: initialEndDate } = calcPeriod("thisMonth");

    // 초기화
    useEffect(() => {
        dispatch(
            setFilterOption({
                startDate: initialStartDate,
                endDate: initialEndDate,
            }),
        );
        return () => {
            dispatch(resetStore());
        };
    }, []);

    // 플랫폼ID 초기화
    useEffect(() => {
        dispatch(setFilterOption({ selectedPlatform: platformIds }));
    }, [platformList]);

    useEffect(() => {
        if (reset) dispatch(setReset(false));
    }, [reset]);

    const clickPlusIcon = () => setShowMore((prev) => !prev);

    const clickResetButton = () => {
        dispatch(setReset(true));
        dispatch(
            setFilterOption({
                author_name: "",
                reason: "",
                startDate: initialStartDate,
                endDate: initialEndDate,
                selectedPlatform: platformIds,
                startAmount: 0,
                endAmount: 0,
            }),
        );
        dispatch(
            setError({
                startDateError: "",
                endDateError: "",
            }),
        );
    };

    const changeStartDate = (date: string) => {
        const { startDateError, endDateError } = validateStartDate(date, endDate);
        dispatch(setError({ startDateError, endDateError }));
        dispatch(setFilterOption({ startDate: date }));
    };

    const changeEndDate = (date: string) => {
        const { startDateError, endDateError } = validateEndDate(date, startDate);
        dispatch(setError({ startDateError, endDateError }));
        dispatch(setFilterOption({ endDate: date }));
    };

    const changePlatform = (ids: number[]) => dispatch(setFilterOption({ selectedPlatform: ids }));

    const changeAmount = (startAmount: number, endAmount: number) => {
        dispatch(setFilterOption({ startAmount, endAmount }));
    };

    return (
        <FilterContainer>
            <FilterRow
                label={"검색"}
                filterContent={[<EtcSearchBar />]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="지급 일"
                filterContent={[
                    <DateRangePicker
                        type="default"
                        changeStartDate={changeStartDate}
                        changeEndDate={changeEndDate}
                        startDateToSet={startDate}
                        endDateToSet={endDate}
                        startDateError={startDateError}
                        endDateError={endDateError}
                    />,
                    <ResetFilter onClick={clickResetButton} />,
                ]}
            />
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMore, onClick: clickPlusIcon }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={tagFilterOptions}
                        keyName={"platform_id"}
                        valueName={"platform_name"}
                        onChange={changePlatform}
                        showMore={showMore}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="지급 금액"
                filterContent={[
                    <Slider min={sliderMinValue} max={sliderMaxValue} reset={reset} onChange={changeAmount} />,
                ]}
            />
        </FilterContainer>
    );
}
