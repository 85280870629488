import styled from "styled-components";
import ApexCharts from 'react-apexcharts';
import { RootState, useAppSelector } from "@/modules/store";
import { colors } from "@/assets/styles/theme";
import { Oval } from "react-loader-spinner";
import Text from "@/components/atoms/text";

export default function DailyPlatformAnalysisWrapper() {
    const { dailyPlatformAnalysisData, dailyPlatformAnalysisLoading } = useAppSelector((state: RootState) => state.pubDashboard);
    
    if (dailyPlatformAnalysisLoading) {
        return (
            <StyledWrapper>
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
            </StyledWrapper>
        )
    }

    const noDataTextStyle = {
        fontSize: "16px",
        textAlign: "center",
        margin: "30px 0 0 0",
        color: colors.gray500
    }

    if (!dailyPlatformAnalysisData.length) {
        return (
            <StyledWrapper>
                <Text text="전일 판매내역이 존재하지 않습니다." customStyle={noDataTextStyle} />
            </StyledWrapper>
        )
    }

    const chartData: ChartData = [];
    let totalSum = 0;
    let etcSum = 0;
    for (let d of dailyPlatformAnalysisData) {
        totalSum += d.amount;
        if (dailyPlatformAnalysisData.indexOf(d) < 4) {
            chartData.push({
                name: d.platform_name,
                data: [d.amount],
                color: d.platform_color,
            })
        } else {
            etcSum += d.amount;
        }
    }
    chartData.push({
        name: "기타",
        data: [etcSum],
        color: colors.gray300,
    })

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            stackType: '100%',
            toolbar: {
                tools: {
                    download: false
                }
            }
        },
        colors: chartData.map(c => c.color),
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.8
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                // borderRadius: 15
            },
        },
        dataLables: {
            enabled: false
        },
        tooltip: {
            x: {
                show: false,
            },
            y: {
                formatter: function (val:any) {
                    return val.toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원"
                }
            },
            shared: false,
            intersect: true,
            followCursor: false,
            custom: function(data: any) {
                const { seriesIndex, w } = data;
                const color = w.globals.colors[seriesIndex];
                const ratio = Math.round(chartData[seriesIndex].data[0] / totalSum * 100);
                return `
                    <div class="tooltip-box">
                        <div class="flex-box">
                            <div>${chartData[seriesIndex].name}</div> 
                            <div>${ratio}%</div>
                        </div>
                        <div class="amount-wrap">
                            <span class="color-box" style="background-color: ${color};"></span>
                            <div>${chartData[seriesIndex].data[0].toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원"}</div>
                        </div>
                    </div>
                `
            },
        },
        xaxis: {
            labels: { show: false },
            axisBorder: { show: false },
            axisTicks: { show: false }
        },
        yaxis: {
            show: false
        },
        legend: {
            show: false,
        },
        grid: { show: false },
    };

    return (
        <StyledWrapper>
            <StyledChart 
                options={options}
                series={chartData}
                type="bar"
                height={80}
            />
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`

`

const StyledChart = styled(ApexCharts)`
    .apexcharts-tooltip {
        box-shadow: 0px 0px 15px rgba(112, 144, 176, 0.2); !important;
    }
    .tooltip-box {
        display: flex;
        flex-direction: column;
        padding: 8px 10px;
        min-width: 120px;
        background: #FFFFFF;
        border-radius: 4px;
        color: #121417;
        font-size: 10px;
        line-height: 14px;
        .amount-wrap {
            display: flex;
            margin-top: 10px;
            align-items: center;
            .color-box {
                position: relative;
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
        }
        .flex-box {
            display: flex;
            justify-content: space-between;
        }
    }
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`


type ChartData = Array<ChartDataEntry>

interface ChartDataEntry {
    name: string;
    data: Array<number>;
    color: string;
}
