/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

const useClickTag = (tagList: string[]) => {
    const [showMoreTag, setShowMoreTag] = useState<ShowMoreTag>({});

    const handleClickTag = (tagKey: string) => setShowMoreTag((prev) => ({ ...prev, [tagKey]: !prev[tagKey] }));

    useEffect(() => {
        if (tagList.length) {
            setShowMoreTag(tagList.reduce((acc: ShowMoreTag, curr) => ((acc[curr] = false), acc), {}));
        }
    }, []);

    return { showMoreTag, handleClickTag };
};

interface ShowMoreTag {
    [tagKey: string]: boolean;
}
export default useClickTag;
