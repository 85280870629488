import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setParamsId } from "@/pages/settlement/saels-inquiry/author/monthly/monthlySlice";

function useMonthlyApi() {
    const {
        totalDate,
        selectedPlatform,
        selectedPublisher,
        selectedSeries,
        selectedAuthor,
        bookName,
        initialDataLoaded,
        fetchLoading,
    } = useSelectorTyped((state: RootState) => state.inquiryAuthorMonthly);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!initialDataLoaded && fetchLoading) return;

        dispatch(setParamsId());
        return () => { }

    }, [
        totalDate,
        selectedPlatform,
        selectedPublisher,
        selectedSeries,
        selectedAuthor,
        bookName,
        dispatch
    ]);
}

export default useMonthlyApi;
