import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import TextInput from "@/components/atoms/input/TextInput";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import validate from "../../table/validateHandler";
import { addComma, removeComma } from "@/modules/utils/filter";
import { CoAuthorInfo } from "../sales-inquiry/SetCoAuthorTaxModal";

export default function SetCoAuthorTaxBody({ coAuthorItems, setcoAuthorItems }: SetCoAuthorTaxBodyProps) {
    const handleChangeTaxAmount = (index: number, v: string) => {
        if (v === "-" || (!isNaN(Number(v)) && Number(v) < 0)) {
            return setcoAuthorItems((prev) =>
                prev.map((p, idx) => (idx === index ? { ...p, taxAmountError: "0이상의 금액을 입력해주세요." } : p)),
            );
        }

        const { errorMsg, value } = validate(v, "amountError", "적용세액", "amount");
        setcoAuthorItems((prev) =>
            prev.map((p, idx) => (idx === index ? { ...p, taxAmountError: errorMsg, tax_amount: addComma(value) } : p)),
        );
    };
    const addCommaToTaxAmount = (index: number, v: string | number) => {
        setcoAuthorItems((prev) => prev.map((p, idx) => (idx === index ? { ...p, tax_amount: addComma(v) } : p)));
    };
    const removeCommaToTaxAmount = (index: number, v: string | number) => {
        setcoAuthorItems((prev) => prev.map((p, idx) => (idx === index ? { ...p, tax_amount: removeComma(v) } : p)));
    };

    return (
        <BodyWrap>
            <FieldRow>
                <ColumnName width="100px">본명</ColumnName>
                <ColumnName>적용 세액(원)</ColumnName>
            </FieldRow>
            {coAuthorItems.length &&
                coAuthorItems.map((author, idx) => (
                    <ItemRow key={idx}>
                        <Item width="100px">
                            <div>{author.real_name}</div>
                        </Item>
                        <Item>
                            <ValidateWrapper dataError={coAuthorItems[idx].taxAmountError}>
                                <TextInput
                                    value={author.tax_amount}
                                    placeholder="적용세액"
                                    onChangeHandler={(e) => handleChangeTaxAmount(idx, e.target.value)}
                                    onFocusHandler={() => removeCommaToTaxAmount(idx, author.tax_amount)}
                                    onBlurHandler={() => addCommaToTaxAmount(idx, author.tax_amount)}
                                    customStyle={textInputStyle}
                                />
                            </ValidateWrapper>
                        </Item>
                    </ItemRow>
                ))}
        </BodyWrap>
    );
}

const textInputStyle = {
    width: "100%",
    height: "32px",
    margin: "13px 8px",
};
const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 228px;
    max-height: 228px;
    overflow: auto;
`;
const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`;
const ColumnName = styled.div<{ width?: string; justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "flex-start")};
    width: ${({ width }) => (width ? width : "220px")};
    height: 48px;
    font-size: 12px;
    padding: 8px;
    font-weight: 500;
`;
const ItemRow = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`;
const Item = styled.div<{ width?: string; justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "flex-start")};
    width: ${({ width }) => (width ? width : "220px")};
    height: 60px;
    font-size: 12px;
    padding: 8px;
`;

interface SetCoAuthorTaxBodyProps {
    coAuthorItems: CoAuthorInfo[];
    setcoAuthorItems: Dispatch<SetStateAction<any[]>>;
}
