import styled from "styled-components";
import { ChangeEvent, useState, useRef, useEffect } from "react";
import { colors } from "@/assets/styles/theme";

// components
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetCoRatioModal from "@/components/molecules/common/modal/SetCoRatioModal";
import AuthorSearchBox from "@/components/molecules/basedata-manage/AuthorSearchBox";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { ReactComponent as CoAuthor } from "@/assets/icons/people.svg";

// reducer
import { useAppDispatch } from "@/modules/store";
import { searchAuthor } from "@/pages/settlement/basedata-manage/author/authorSlice";

// hooks
import useClickOutside from "@/modules/hooks/useClickOutside";
import useAlert from "@/modules/hooks/useAlert";

export default function AuthorSearch({
    idx, value, author, from,
    placeholder,
    customStyle,
    buttonCustomStyle,
    selectAuthorFunc,
    deleteAuthorFunc,

    applyCoInfoFunc,
}:AuthorSearchProps) {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const [boxOn, setBoxOn] = useState(false);
    const [searchResult, setSearchResult] = useState<{
        author_id: number,
        author_name: string,
        focus: boolean,
        co_info: {
            ae_id: number,
            coRatio: string,
            coRatioError: string,
            realName: string,
            taxRatio: number,
            taxType: "A"|"B"|"D",
            user_id: number|null,
        }[]}[]>([]);
    const [tmpCoInfo, setTmpCoInfo] = useState<{
        ae_id: number,
        coRatio: string,
        coRatioError: string,
        realName: string,
        taxRatio: number,
        taxType: "A"|"B"|"D",
        user_id: number|null,
    }[]>(author.co_info)
    const [keyword, setKeyword] = useState("");
    const [zIndex, setZIndex] = useState(1);
    let [focusIdx, setFocusIdx] = useState(-1);
    const [showSetCoRatioModal, setShowSetCoRatioModal] = useState(false);
    const authorSearchRef = useRef(null);
    const isOutsideClicked = useClickOutside({ ref: authorSearchRef });

    useEffect(() => {
        if (isOutsideClicked) init();
    }, [isOutsideClicked]);

    useEffect(() => {
        if (!boxOn) {
            let tooltip = document.getElementById("author-name-tooltip");
            if (tooltip) tooltip.remove();
        }
    }, [boxOn])

    useEffect(() => {
        setTmpCoInfo(author.co_info)
    }, [author.co_info])

    function init() {
        setKeyword("");
        setBoxOn(false);
        setZIndex(0);
        setFocusIdx(-1);
    }
    
    async function search() {
        try {
            let apiParams = {
                keyword: keyword
            }
            let result = (await dispatch(searchAuthor(apiParams)).unwrap()).data;
            setSearchResult(result);
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    function selectAuthor(selectA?:any) {
        let tmpAuthor;
        if (selectA) tmpAuthor = JSON.parse(JSON.stringify(selectA));
        else tmpAuthor = JSON.parse(JSON.stringify(searchResult.find(s => s.focus)));

        const ratio = Math.floor(100 / tmpAuthor.co_info.length * 100) / 100;
        for (let c of tmpAuthor.co_info) {
            // c.coRatio = ratio;
            c.coRatio = "";
        }
        setTmpCoInfo(tmpAuthor.co_info)
        selectAuthorFunc(idx, tmpAuthor);
        init();
    }


    function onChagneKeyword(e: ChangeEvent<HTMLInputElement>) {
        setKeyword(e.target.value);
    }

    const keyPressHandler = (e: any) => {
        if (e.keyCode === 229) return;
        if (e.key === "Enter") {
            if (focusIdx > -1) selectAuthor(searchResult[focusIdx]);
            else {
                if (!boxOn) setBoxOn(true);
                search();
            }
        } else if (e.key === "ArrowDown") {
            if (!boxOn) setBoxOn(true);
        }
    };

    function openSearchBox() {
        setBoxOn(true);
        setZIndex(99);
    }

    function keyDown(e: any) {
        if (e.key === "ArrowDown") {
            if (focusIdx < searchResult.length-1) {
                setFocusIdx(focusIdx+1);
                for (let i = 0; i < searchResult.length; i++) {
                    setSearchResult(searchResult.map((r, idx) => {
                        if (idx === focusIdx+1) {
                            return {...r, focus: true};
                        } else {
                            return {...r, focus: false};
                        }
                    }));
                }
            }
            let position = document.getElementById("searchResultList");
            if (focusIdx > 3 && position) {
                position.scrollTo(0, (32 * (focusIdx-3)));
                // position.scrollTo(0, position.scrollTop + 32);
            }
        } else if (e.key === "ArrowUp") {
            if (focusIdx > 0) {
                setFocusIdx(focusIdx-1);
                for (let i = 0; i < searchResult.length; i++) {
                    setSearchResult(searchResult.map((r, idx) => {
                        if (idx === focusIdx-1) {
                            return {...r, focus: true};
                        } else {
                            return {...r, focus: false};
                        }
                    }));
                }
                
            } else {
                setSearchResult(searchResult.map((r, idx) => {
                    return {...r, focus: false};
                }));
                setFocusIdx(-1);
            }
            let position = document.getElementById("searchResultList");
            if (position && position.scrollTop != 0) {
                position.scrollTo(0, position.scrollTop-32);
            }
        }
    }

    function openCoRatioModal() {
        // 공저간 비율 설정
        setBoxOn(false);
        // dispatch(seriesAction.setIndex(idx));
        // dispatch(seriesAction.setSeriesDatas({
        //     key: "tmpCoInfo", value: author.co_info
        // }));

        setShowSetCoRatioModal(true);
    }

    function closeModal() {
        setShowSetCoRatioModal(false);
    }

    function setCoInfoFunc(co_info:any) {
        setTmpCoInfo(co_info)
    }

    return (
        <>
            <AuthorSearchBoxWrap zIndex={zIndex} ref={authorSearchRef}>
                {
                    boxOn ?
                    <StyledSearchBarWrapper width={customStyle?.width}>
                        <BaseInput
                            value={keyword}
                            placeholder={placeholder}
                            onKeyDown={(e) => keyDown(e)}
                            onKeyPress={(e) => keyPressHandler(e)}
                            onChange={(e) => onChagneKeyword(e)}
                            // onKeyDown={keyPressHandler}
                            // onFocus={onFocusHandler}
                            // onBlur={onBlurHandler}
                            width={customStyle?.width}
                            height={customStyle?.height}
                            padding={customStyle?.padding}
                            fontSize={customStyle?.fontSize}
                            fontWeight={customStyle?.fontWeight}
                            borderColor={customStyle?.borderColor}
                            borderRadius={customStyle?.borderRadius}
                            color={customStyle?.color}
                            textAlign={customStyle?.textAlign}
                            margin={customStyle?.margin}
                        />
                        <IconWrapper>
                            <SearchIcon size={24} fill={colors.black} onClick={search} />
                        </IconWrapper>
                    </StyledSearchBarWrapper>
                    :
                    <StyledSearchBarWrapper width={customStyle?.width} onClick={openSearchBox}>
                        <BaseInput
                            value={value}
                            placeholder={placeholder}
                            onChange={() => {}}
                            onKeyPress={(e) => keyPressHandler(e)}
                            // onKeyDown={onKeyDownHandler}
                            // onFocus={onFocusHandler}
                            onFocus={()=> {
                                openSearchBox()
                            }}
                            // onBlur={onBlurHandler}
                            width={customStyle?.width}
                            height={customStyle?.height}
                            padding={customStyle?.padding}
                            fontSize={customStyle?.fontSize}
                            fontWeight={customStyle?.fontWeight}
                            borderColor={customStyle?.borderColor}
                            borderRadius={customStyle?.borderRadius}
                            color={customStyle?.color}
                            textAlign={customStyle?.textAlign}
                            margin={customStyle?.margin}
                        />
                        <IconWrapper>
                            <SearchIcon size={24} fill={colors.black} onClick={search} />
                        </IconWrapper>
                    </StyledSearchBarWrapper>
                }
                <AuthorSearchBox
                    boxOn={boxOn}
                    from={from}
                    author={author}
                    searchResult={searchResult}
                    idx={idx}
                    initFunc={init}
                    selectAuthorFunc={selectAuthorFunc}
                    deleteAuthorFunc={deleteAuthorFunc}
                    setCoInfoFunc={setCoInfoFunc}
                    customStyle={customStyle}
                />
                {
                    author.co_info.length > 1 ?
                    (
                        <CoAuthorIconBox active>
                            <CoAuthor fill={colors.blue500} onClick={openCoRatioModal}/>
                        </CoAuthorIconBox>
                    ) :
                    (
                        <CoAuthorIconBox>
                            <CoAuthor fill={colors.gray600} />
                        </CoAuthorIconBox>
                    )
                }
            </AuthorSearchBoxWrap>
            <ModalPortal>
                <SetCoRatioModal
                    from={from}
                    show={showSetCoRatioModal}
                    close={closeModal}
                    coInfo={tmpCoInfo}
                    index={idx}
                    applyCoInfoFunc={applyCoInfoFunc}
                />
            </ModalPortal>
        </>
    )
}

interface AuthorSearchProps {
    idx: number;
    value: string;
    author: any;
    from: string;
    placeholder?: string;
    customStyle?: StyleProps;
    buttonCustomStyle?: ButtonProps;

    // 저자명 선택 및 삭제
    selectAuthorFunc: Function;
    deleteAuthorFunc: Function;

    // coRatioModal Props
    applyCoInfoFunc: Function;
}

const AuthorSearchBoxWrap = styled.div<{ zIndex?: number }>`
    z-index: ${({ zIndex }) => zIndex ? zIndex : ""};
`

const BaseInput = styled.input<StyleProps>`
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "32px")};
    padding: ${({ padding }) => (padding ? padding : "10px")};
    margin: ${({ margin }) => (margin ? margin : "")};
    background-color: ${colors.white};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
    border: ${({ borderColor }) => `1px solid ${borderColor ? borderColor : colors.gray300}`};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "5px")};
    color: ${({ color }) => (color ? color : colors.black)};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
`;

const StyledSearchBarWrapper = styled.div<{ width?: string }>`
    display: inline-flex;
    align-items: center;
    position: relative;
    width: ${({ width }) => (width ? width : "120px")};
`;
const IconWrapper = styled.div`
    position: absolute;
    top: 4px;
    right: 8px;
    cursor: pointer;
`;
const CoAuthorIconBox = styled.div<{ active?: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    border: 1px solid ${({ active }) => active ? colors.blue500 : colors.gray300};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
`

interface ButtonProps {
    type?: "main" | "main-outline" | "red-outline" | "gray-outline" | "disabled" | "red-outline-icon" | "main-fill";
    label?: string;
    customStyle?: ButtonStyleProps;
}

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    borderColor?: string;
    borderRadius?: string;
    color?: string;
    textAlign?: string;
}

interface ButtonStyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    borderRadius?: string;
    color?: string;
}