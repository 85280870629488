import { useEffect } from "react";

// components
import AuthorSearchBar from "@/components/molecules/basedata-manage/AuthorSearchBar";
import OptionButtons from "@/components/molecules/basedata-manage/AuthorOptionButtons";
import AuthorTableContainer from "@/components/molecules/basedata-manage/AuthorTableContainer";

// reducer
import { useAppDispatch } from "@/modules/store";
import { authorActions, getAuthorData } from "@/pages/settlement/basedata-manage/author/authorSlice";

// hooks
import useAlert from "@/modules/hooks/useAlert";

export default function Author() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    useEffect(() => {
        getInitDatas();
        return () => {
            dispatch(authorActions.reset());
        };
    }, [dispatch])

    const getInitDatas = async() => {
        try {
            dispatch(getAuthorData({
                searchType: "all",
                searchKeyword: "",
            })).unwrap();
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    return (
        <>
            <AuthorSearchBar />
            <OptionButtons />
            <AuthorTableContainer />
        </>
    );
}