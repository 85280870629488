import { MouseEvent, Fragment } from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as AddIcon } from "@/assets/icons/add.svg";
import { ReactComponent as MinusIcon } from "@/assets/icons/minus.svg";

function FilterRow({ label, filterContent, plusIcon, customStyle }: FilterRowProps) {
    return (
        <StyledRowWrapper backgroundColor={customStyle?.backgroundColor}>
            {plusIcon ? (
                <LabelWidthPlusIcon label={label} showPlusIcon={plusIcon.showPlusIcon} onClick={plusIcon.onClick} />
            ) : (
                <Label>{label}</Label>
            )}
            <Content>
                {filterContent.map((content, idx) => (
                    <Fragment key={idx}>
                        {content}
                        <Margin />
                    </Fragment>
                ))}
            </Content>
        </StyledRowWrapper>
    );
}

function LabelWidthPlusIcon({ label, showPlusIcon, onClick }: PlusIcon) {
    let iconProps = {
        fill: colors.blue500,
        onClick: onClick,
    };
    let icon = showPlusIcon ? <MinusIcon {...iconProps} /> : <AddIcon {...iconProps} />;
    return (
        <Label>
            <LabelWithIconWrapper>
                <p>{label}</p>
                <IconWrapper>{icon}</IconWrapper>
            </LabelWithIconWrapper>
        </Label>
    );
}

// STYLE DEFINITION
const StyledRowWrapper = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 60px;
    height: auto;
    position: relative;
    background-color: ${({ backgroundColor, theme }) => backgroundColor && theme.colors.gray50};
`;
const Label = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    width: 200px;
    height: 100%;
    padding-left: 50px;
    background-color: ${({ theme }) => theme.colors.gray50};
    font-size: 14px;
    font-weight: 500;
`;
const Content = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: calc(100% - 200px);
    height: 100%;
    min-height: 60px;
    left: 200px;
    padding: 14px 0px 14px 8px;
`;
const Margin = styled.div`
    width: 20px;
`;

const LabelWithIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-right: 12px;
`;

const IconWrapper = styled.div`
    cursor: pointer;
`;

// TYPE DEFINITION
interface FilterRowProps {
    label: string;
    filterContent: JSX.Element[];
    plusIcon?: { showPlusIcon: boolean; onClick: () => void };
    customStyle?: StyleProps;
}
interface PlusIcon {
    label: string;
    showPlusIcon: boolean;
    onClick: () => void;
}
interface StyleProps {
    backgroundColor?: string;
}

export default FilterRow;
