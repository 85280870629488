import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

export default function PublisherTagWrapper({ publisher_id, publisher_name, imgPath }: PublisherTagProps) {
    const { selectedPublisherId } = useAppSelector((state: RootState) => state.authorDashboard);
    const dispatch = useAppDispatch();

    const active = publisher_id === selectedPublisherId;
    let publisherImg;
    if (publisher_id === 0) {
        publisherImg = <StyledDiv active={active}>ALL</StyledDiv>
    } else {
        // publisherImg = <StyledImg src={require(`@/assets/images/publisher-logo/${active ? imgPath : `${imgPath}-gray`}.png`)} />
        // const imgSrc = `https://s3.ap-northeast-2.amazonaws.com/gling.co.kr/dev/images/publisher_icon/${active ? imgPath : `${imgPath}-gray`}.png`
        let imgSrc = "";
        if (imgPath) {
            imgSrc = `/s3/images/publisher_icon/${active ? imgPath : `${imgPath}-gray`}.png`
        } else {
            imgSrc = "/file/download/static/user.jpg";
        }
        publisherImg = <StyledImg src={imgSrc} />
    }

    const selectPublisher = (publisher_id: number) => {
        dispatch({ type: "authorDashboard/setSelectedPublisherId", payload: publisher_id });
    }

    const textStyle = {
        color: active ? colors.black : colors.gray600,
        fontSize: "12px",
        fontWeight: "400",
        textAlign: "center"
    }

    return (
        <StyledWrapper onClick={() => selectPublisher(publisher_id)}>
            <PublisherImgWrapper >
                {publisherImg}
            </PublisherImgWrapper>
            <Text text={publisher_name} customStyle={textStyle} />
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    margin-left: 22px;
    cursor: pointer;
`

const StyledImg = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 99px;
`

const StyledDiv = styled.div<StyleProps>`
    width: 40px;
    height: 40px;
    background-color: ${({ theme, active }) => active ? theme.colors.blue500 : theme.colors.gray500};
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    padding: 12px 7px;
`

const PublisherImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 45px;
    height: 45px;
    margin-bottom: 4px;
`

interface PublisherTagProps {
    publisher_id: number;
    publisher_name: string;
    imgPath: string;
}

interface StyleProps {
    active: boolean;
}