import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components'
import { RootState } from '@/modules/store';
import useSelectorTyped from '@/modules/hooks/useSelectorTyped';

interface IProps {
    menu: Array<{name: string, type: string}>
}

export default function TypeSidebar({ menu }: IProps) {
    const { isPublisher } = useSelectorTyped((state: RootState) => state.user);
    const sideMenu = isPublisher ? menu : menu.filter(m => m.type !== 'Download');

    return (
        <MenuWrapper>
            {
            sideMenu.map((m, i) => (
                <NavLink 
                    key={i}
                    to={m.type === "Guide" ? `/guide` : `/support/${m.type}`}
                    className={({isActive}) => isActive ? "active" : ""}
                    >
                        {m.name}
                </NavLink>
            ))}
        </MenuWrapper>
    )
}

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 270px;
    a {
        cursor: pointer;
        padding: 10px 0;
        ${({ theme }) => css`
            color: ${theme.colors.gray600};
        `}
    }
    a.active {
        ${({ theme }) => css`
            color: ${theme.colors.blue500}
        `}
    }
`;
