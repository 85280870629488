import styled from 'styled-components'
import Text from '@/components/atoms/text';
import { colors } from '@/assets/styles/theme';
import SelectBox from '@/components/atoms/selectBox';
import Button from '@/components/atoms/button/index'
import { authPublist } from '@/pages/support/inquiry/slice'

interface Iprops {
    AuthPublists: Array<authPublist>,
    secret: boolean,
    onCategoryChange: (category: {name:string, value: string}) => void,
    onPublisherChange: (publisher: {value: string, name: string}) => void,
    onSecretChange: (secret: boolean) => void,
    category: string,
    publisher: string
}

export default function InquiryWriteInput({AuthPublists, onCategoryChange, onPublisherChange, onSecretChange, secret, category, publisher}: Iprops) {
    const categoryOptions = [
        {value: '건의사항', name: '건의사항'},
        {value: '정산',name: '정산'},
        {value: '기타',name: '기타'}
    ];

    const updatedAuthPublists = AuthPublists.map((publisher)=> ({value: publisher.publisher_id, name: publisher.publisher_name}));
    const filteredAuthPublists = [{value: '0', name: '글링'} ,...updatedAuthPublists];

    const textStyle = {
        fontWeight: "500",
        fontSize: "14px",
        color: colors.black
    }

    const selectBoxStyle = {
        width: "200px",
        height: "32px",
    }

    return (
        <StyledContainer>
            <StyledCategory>
                <Text text="카테고리" customStyle={textStyle}/>
                <SelectBox 
                    labelKey="name"
                    valueKey="value"
                    options={categoryOptions} 
                    customStyle={selectBoxStyle}
                    selected={category}
                    onClickValueFunc={onCategoryChange}
                />
            </StyledCategory>
            <StyledPublisher>
                <Text text="출판사" customStyle={textStyle}/>
                <SelectBox 
                    labelKey="name"
                    valueKey="value"
                    options={filteredAuthPublists} 
                    customStyle={selectBoxStyle}
                    selected={publisher}
                    onClickValueFunc={onPublisherChange}
                />
            </StyledPublisher>
            <StyledSecret>
                <Text text="비밀여부" customStyle={textStyle}/>
                <Button 
                    type={secret ? "black-outline" : "gray-outline"} 
                    label="비공개"
                    onClickHandler={()=>onSecretChange(true)}
                />
                <Button 
                    type={secret ? "gray-outline" : "black-outline"} 
                    label="공개"
                    onClickHandler={()=>onSecretChange(false)}/>
            </StyledSecret>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledCategory = styled.div`
    display: flex;
    width: 260px;
    justify-content: space-between;
    align-items: center;
`;

const StyledPublisher = styled.div`
    display: flex;
    width: 260px;
    justify-content: space-between;
    align-items: center;
`;

const StyledSecret = styled.div`
    display: flex;
    width: 268px;
    justify-content: space-between;
    align-items: center;
`;
