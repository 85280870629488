import styled from "styled-components";
import Button from "@/components/atoms/button";
import { Step } from "@/pages/ebook-maker/types";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import { EbookMaker } from "@/types/dataType";
import { validatePage } from "../common/NavBar";

export default function StepBtnWrapper({ stepHandler }: { stepHandler: (step: number, disabled: boolean) => void; }) {
    const { contentList, copyrightType, currStep } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    const json2xml = (json: any) => {
        let xml = "";
        if (json.isEmpty) {
            xml += "<div><br/></div>";
        } else {
            let tag = json.size.toLowerCase();
            if (tag === "p") tag = "div";
            xml += `<${tag} style="text-align: ${json.align}; text-decoration: ${json.underline ? 'underline' : 'none'};">`;

            // 항목
            if (json.title) {
                xml += `<strong>${json.title}</strong>&nbsp;&#124;&nbsp;`
            }

            if (json.bold) xml += "<strong>";
            if (json.italic) xml+= "<em>";
            if (json.link === "mailto") {
                xml += `<a href="mailto:${json.value}">`
            } else if (json.link === "href") {
                xml += `<a href="${json.value}">`
            }
            xml += `${json.value}`;
            if ((json.link === "mailto") || (json.link === "href")) {
                xml += `</a>`
            } 
            if (json.italic) xml+= "</em>";
            if (json.bold) xml += "</strong>";

            xml += `</${tag}>`;
        }

        return xml;
    }

    const convertJsonFormat = (json: any) => {
        const tmpJson = Object.assign({...json});

        let size;
        switch (tmpJson.size) {
            case "H1":
                size = 57.6;
                tmpJson.bold = true;
                break;
            case "H2":
                size = 43.2;
                tmpJson.bold = true;
                break;
            case "H3":
                size = 34.2;
                tmpJson.bold = true;
                break;
            case "H4":
                size = 27;
                tmpJson.bold = true;
                break;
            case "H5":
                size = 23.4;
                tmpJson.bold = true;
                break;
            case "H6":
                size = 19.8;
                tmpJson.bold = true;
                break;
            case "P":
                size = 23.4;
                break;
            default:
                size = 23.4;
                break;
        }

        let result: any = {
            tag: "p",
            _style: {
                "text-align": tmpJson.align,
                "text-decoration": tmpJson.underline ? 'underline' : 'none',
            },
            children: []
        }

        // 항목 있으면 적용
        if (tmpJson.title) {
            result.children.push({
                tag: "b",
                _text: `${tmpJson.title} | `,
                _style: {
                    size
                }
            })
        }

        if (tmpJson.bold && !tmpJson.italic) {
            result.children.push({
                tag: "b",
                _text: tmpJson.value,
                _style: {
                    size
                }
            })
        } else if (!tmpJson.bold && tmpJson.italic) {
            result.children.push({
                tag: "i",
                _text: tmpJson.value,
                _style: {
                    size
                }
            })
        } else if (tmpJson.bold && tmpJson.italic) {
            result.children.push({
                tag: "b",
                children: [{
                    tag: "i",
                    _text: tmpJson.value,
                    _style: {
                        size
                    }
                }]
            })
        } else if (!tmpJson.bold && !tmpJson.italic) {
            result.children.push({
                _text: tmpJson.value,
                _style: {
                    size
                }
            })
        }

        return result;
    }

    const goPrev = () => {
        stepHandler(Step.Form, false);
    }

    const goNext = () => {
        const param = { contentList };
        const validate = validatePage(currStep, param);

        if (validate.status === "fail") {
            return alert("warning", "주의", validate.message);
        }

        if (copyrightType === EbookMaker.CopyrightType.MAKE) {
            // toEpub용 형식
            let resultXml = "";
            for (let i of contentList) {
                let xml = json2xml(i);
                resultXml += xml;
            }
    
            // toImg용 형식
            let resultJson = [];
            for (let i of contentList) {
                let json = convertJsonFormat(i);
                resultJson.push(json);
            }
    
            dispatch({ type: "ebookMaker/setCopyrightHTML", payload: resultXml });
            dispatch({ type: "ebookMaker/setCopyrightJSON", payload: resultJson });
        }

        stepHandler(Step.Method, false);
    }

    return (
        <StyledWrapper>
            <Button type="red-outline" label="이전으로" onClickHandler={goPrev} customStyle={{ width: "120px" }} />
            <Button type="main" label="다음" onClickHandler={goNext} customStyle={{ width: "120px" }} />
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    display: flex;
    float: right;
    justify-content: space-between;
    width: 248px;
    height: 40px;
    margin-top: 16px;
`