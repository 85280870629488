import styled from "styled-components";
import { useAppDispatch } from "@/modules/store";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";
import { colors } from "@/assets/styles/theme";
import { useState, ChangeEvent } from "react";
import useAlert from "@/modules/hooks/useAlert";
import { findEmail } from "@/pages/auth/authSlice";
import { useNavigate } from "react-router-dom";
import IdWrap from "@/components/molecules/signup/IdWrap";
import KcpForm from "@/components/molecules/signin/KcpForm";
import { findpwActions } from "@/pages/auth/findPw/findpwSlice";


export default function BeforeFind({ setFind }: {setFind: Function}) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { alert } = useAlert();
    const [id, setId] = useState("");
    const [idError, setIdError] = useState("");
    const [email, setEmail] = useState("naver.com");
    const [authDone, setAuthDone] = useState<any>(null);

    async function changeHandlerID(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        let val = value + "@" + email;
        setId(value);
        if (/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/i.test(val)) {
            setIdError("");
        } else {
            setIdError("이메일 형식을 확인해주세요.");
        }
    }

    async function changeEmail(changeE:string) {
        setEmail(changeE);
        if (!id) {
            setIdError("이메일 주소를 정확히 입력해주세요.");
        } else {
            let val = id + "@" + changeE;
            if (/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/i.test(val)) {
                setIdError("");
            } else {
                setIdError("이메일 형식을 확인해주세요.");
            }
        }
    }

    const keyPressHandler = (key: any) => {
        if (key === "Enter") {
            // next();
        }
    };
    
    async function setKcpParam(param:any) {
        try {
            dispatch(findpwActions.setKcpDi(param.kcp_di));
            let apiParams = {
                kcp_di : param.kcp_di
            };
    
            const data = (await dispatch(findEmail(apiParams)).unwrap()).data;
    
            if (!data || Object.keys(data).length === 0) {
                return await alert("warning", "등록되지 않은 회원정보", "가입정보를 찾을 수 없습니다.");
            } else if (data.sociallogin_provider) {
                // 소셜로그인 사용자의 경우 인증정보로 자동 로그인만 가능
                let answer = await alert("confirm", "로그인", `${data.sociallogin_provider}(으)로 가입된 계정이 있습니다. 로그인 하시겠습니까?`);
                    
                if (answer === "Y") {
                    navigate("/signin");
                }
                return;
            }
            if (data.user_email === id + "@" + email) {
                setFind(data.user_email);
            } else {
                await alert("warning", "주의", "입력하신 이메일과 인증정보가 일치하지 않습니다.");
            }
        } catch (err:any) {
            return await alert("error", "오류 발생", err.message);
        }
    }

    async function openAuthPopup() {
        try {
            if (!id) return await alert("warning", "주의", "이메일을 입력해주세요.");
            if (idError) return await alert("warning", "주의", "이메일 형식을 확인해주세요.");
            setAuthDone(true);
        } catch (err:any) {
            return await alert("error", "오류 발생", err.message);
        }
    }

    return (
        <>
            <IdWrap
                value={id}
                email={email}
                errorMsg={idError}
                onChange={changeHandlerID}
                onChangeEmail={changeEmail}
                onKeyPress={keyPressHandler}
            />
            <BeforeFindWrap>
                <Button
                    type="main"
                    label="본인 인증으로 비밀번호 찾기"
                    customStyle={{ width: "400px",
                                    height: "60px",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    margin: "0 0 24px"
                                }}
                    onClickHandler={openAuthPopup}
                />
                <Text
                    text="*본인인증을 하지 않은 경우 비밀번호를 찾을 수 없습니다."
                    customStyle={{ fontSize: "14px", fontWeight: "500", color: colors.gray600 }}
                />
            </BeforeFindWrap>
            <KcpForm
                authDone={authDone}
                setResult={setKcpParam}
            />
        </>
    );
}

const BeforeFindWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 24px;
`;