import { useState, useEffect, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RootState, useAppDispatch } from "@/modules/store";
import {
    getAuthorList,
    getPlatformList,
    getPublisherList,
    getSeriesList,
    inquiryAuthorCommonActions,
} from "./commonSlice";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import Daily from "@/components/templates/salesInquiry/author/Daily";
import Monthly from "@/components/templates/salesInquiry/author/Monthly";
import Mg from "@/components/templates/salesInquiry/author/Mg";
import Etc from "@/components/templates/salesInquiry/author/Etc";
import styled from "styled-components";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import Text from "@/components/atoms/text";
import TypeNavBar from "@/components/molecules/common/typeNavBar/TypeNavBar";

export default function AuthorSalesInquiry() {
    const { platformList, publisherList, authorList, seriesList } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorCommon,
    );
    const [searchParams] = useSearchParams();
    const [template, setTemplate] = useState(<></>);
    const dispatch = useAppDispatch();

    useEffect(() => {
        switch (searchParams.get("type")) {
            case "Daily":
                setTemplate(<Daily />);
                break;
            case "Monthly":
                setTemplate(<Monthly />);
                break;
            case "Mg":
                setTemplate(<Mg />);
                break;
            case "Etc":
                setTemplate(<Etc />);
                break;
            default:
                break;
        }
    }, [searchParams]);

    // 데이터 패치
    useLayoutEffect(() => {
        dispatch(getPlatformList());
        dispatch(getPublisherList());
        dispatch(getAuthorList());
        dispatch(getSeriesList());
    }, []);

    useEffect(() => {
        if (platformList.length && publisherList.length && authorList.length && seriesList.length) {
            dispatch(inquiryAuthorCommonActions.setDataLoaded(true));
        }
    }, [platformList, publisherList, authorList, seriesList, dispatch]);

    return (
        <SectionLayout>
            <StyledTitleContainer>
                <Text text="판매 조회" customStyle={textStyle} />
            </StyledTitleContainer>
            <TypeNavBar
                menus={["일별 판매 현황", "월 정산", "선인세", "기타 지급금"]}
                types={["Daily", "Monthly", "Mg", "Etc"]}
            />
            {template}
        </SectionLayout>
    );
}
const StyledTitleContainer = styled.div`
    margin-bottom: 20px;
`;
const textStyle = {
    fontSize: "20px",
    fontWeight: "700",
};
