import { useEffect } from "react";

// components
import SearchFilterContainer from "@/components/molecules/basedata-manage/ExcelUploadSearchFilterContainer";
import ExcelUploadOptionButtons from "@/components/molecules/basedata-manage/ExcelUploadOptionButtons";
import ExcelUploadTableContainer from "@/components/molecules/basedata-manage/ExcelUploadTableContainer";

// reducer
import { useAppDispatch } from "@/modules/store";
import { getSalesPlatformList } from "@/pages/common/platformSlice";
import { excelUploadActions } from "@/pages/settlement/basedata-manage/excelUploadManagement/exceluploadSlice";

// hooks
import useAlert from "@/modules/hooks/useAlert";
import usePlatformSetting from "@/components/molecules/sales-entry/common/hooks/usePlatformSetting";
import useLoading from "@/modules/hooks/useLoading";

function ExcelUploadManagement() {
    const dispatch = useAppDispatch();
    const { initialPlatformId } = usePlatformSetting({ platformId: 0 });
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();

    useEffect(() => {
        initPlatformList();
        return () => {
            dispatch(excelUploadActions.reset());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch({type: "excelUpload/setPlatformId", payload: initialPlatformId });
    }, [initialPlatformId])

    const initPlatformList = async () => {
        try {
            loadingOn();
            await dispatch(getSalesPlatformList()).unwrap();
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }

    return (
        <>
            <SearchFilterContainer />
            <ExcelUploadOptionButtons />
            <ExcelUploadTableContainer />
        </>
    );
}

export default ExcelUploadManagement;