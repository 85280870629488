import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ServiceIcon1, ServiceIcon2, ServiceIcon3, ServiceIcon4,ServiceIcon5, ServiceIcon6, ServiceIcon7 } from "@/assets/images/mob-landing";
import useFadeIn from "@/modules/hooks/useFadeIn";

export default function ServiceSection() {
    const [idx, setIdx] = useState(0);
    const [isMaxNum, setIsMaxNum] = useState(false);;

    // interval 설정
    const timerId = useRef<ReturnType<typeof setTimeout>>();
    const delay = useRef(1500);
    const maxNum = Math.ceil((window.innerWidth - 20) / (200 + 16)); // 화면에 보여질 박스 최대 개수

    const containerRef = useRef<HTMLDivElement>(null);
    const { FadeInWrapper, active } = useFadeIn(containerRef);

    useEffect(() => {
        carousel();
        
        return () => { clearInterval(timerId.current) }
    }, [isMaxNum])

    const carousel = () => {
        // 1초마다 한개씩 옆으로 옮김 본래 갯수 + maxNum 인덱스가 되면 인덱스 0, transform 0으로
        if (timerId.current && isMaxNum) {
            delay.current = 0;
            clearInterval(timerId.current);
        } 

        timerId.current = setInterval(() => {
            setIdx(prev => {
                if (prev === maxNum + 7) {
                    setIsMaxNum(true);
                    return maxNum;
                }
                
                setIsMaxNum(false);
                delay.current = 1500;
                return prev + 1;
            });
        }, delay.current);
    }
    
    return (
        <Container ref={containerRef}>
            <FadeInWrapper active={active}>
                <SectionName>글링 서비스</SectionName>
                <Title>내 출간에 도움되는{`\n`}많은 서비스들</Title>
                <Desc>분석이 필요하면 대시보드로,{`\n`}원고를 적고 싶다면 글쓰기에서!</Desc>
            </FadeInWrapper>
            <Carousel>
                <CarousleIconList idx={idx} isMaxNum={isMaxNum}>
                {items.map(item => (
                    <CarouselIconBox key={item.id} color={item.color}>
                        <img src={item.icon} width="50%" />
                        <Text>{item.text}</Text>
                    </CarouselIconBox>
                ))}
                </CarousleIconList>
            </Carousel>
        </Container>
    )
}

const items = [
    { id: 1, icon: ServiceIcon1, text: '대시보드', color: '#748FFC' }, 
    { id: 2, icon: ServiceIcon2, text: '정산 조회', color: '#4DABF7' }, 
    { id: 3, icon: ServiceIcon3, text: '정산서', color: '#26C5DA' }, 
    { id: 4, icon: ServiceIcon4, text: '포트폴리오', color: '#69DB7C' }, 
    { id: 5, icon: ServiceIcon5, text: '작품출간', color: '#FFD43B' }, 
    { id: 6, icon: ServiceIcon6, text: '글쓰기', color: '#F783AC' }, 
    { id: 7, icon: ServiceIcon7, text: '채팅', color: '#FF922B' },
    // 반복재생을 위해 추가
    { id: 8, icon: ServiceIcon1, text: '대시보드', color: '#748FFC' }, 
    { id: 9, icon: ServiceIcon2, text: '정산 조회', color: '#4DABF7' }, 
    { id: 10, icon: ServiceIcon3, text: '정산서', color: '#26C5DA' }, 
    { id: 11, icon: ServiceIcon4, text: '포트폴리오', color: '#69DB7C' }, 
    { id: 12, icon: ServiceIcon5, text: '작품출간', color: '#FFD43B' }, 
    { id: 13, icon: ServiceIcon6, text: '글쓰기', color: '#F783AC' }, 
    { id: 14, icon: ServiceIcon7, text: '채팅', color: '#FF922B' },
];

const Container = styled.div`
    color: ${({ theme }) => theme.colors.white};
    margin-top: 122px;
`
const SectionName = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.blue500};
`
const Title = styled.p`
    font-size: 24px;
    font-weight: 700;
    line-height: 30.05px;
    white-space: pre-wrap;
    margin-top: 16px;
`
const Desc = styled.p`
    margin-top: 16px;
    line-height: 28px;
    white-space: pre-wrap;
`
const Carousel = styled.div`
    width: calc(100% + 20px);
    margin-top: 40px;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { /* Chrome, Safari and Opera */
        display: none;
    }
`
const CarousleIconList = styled.div<{ idx: number, isMaxNum: boolean }>`
    display: flex;
    transform: ${({ idx }) => `translateX(${- idx * 216}px)`};
    transition: ${({ isMaxNum }) => !isMaxNum && `transform ease-in-out 1s`};
`;
const CarouselIconBox = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 200px;
    min-height: 200px;
    padding-top: 40px;
    background-color: ${({ color, theme }) => color || theme.colors.white};
    border-radius: 16px;
    & + & {
        margin-left: 16px;
    }
`
const Text = styled.p`
    position: absolute;
    bottom: 30px;
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
`