/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer } from "react";
import { addComma, removeComma } from "@/modules/utils/filter";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import validate from "../validateHandler";
import asyncValidate from "../asyncValidateHandler";

const initialState: State = {
    items: [],
    chunkItem: [],
    itemKey: "",
    updatedRow: {},
    createState: () => {},
};

const customTableSlice = createSlice({
    name: "customDataTable",
    initialState,
    reducers: {
        createState: (
            state,
            action: PayloadAction<{
                items: Item[];
                itemPerPage: number;
                page: number;
                keys: Keys;
            }>,
        ) => {
            const { items, itemPerPage, page, keys } = action.payload;
            const { itemKey } = keys;
            const index = (page - 1) * itemPerPage;
            let chunkItem = [];

            for (let i = index; i < index + itemPerPage; i++) {
                if (!items[i]) break;
                chunkItem.push(items[i]);
            }
            state.items = items;
            state.chunkItem = chunkItem;
            state.itemKey = itemKey;
        },

        createChunk: (state, action: PayloadAction<{ itemPerPage: number; page: number }>) => {
            const { itemPerPage, page } = action.payload;
            const itemIndex = itemPerPage * (page - 1);

            let chunkItem = [];
            for (let i = itemIndex, length = itemIndex + itemPerPage; i < length; i++) {
                if (!state.items[i]) break;
                chunkItem.push(state.items[i]);
            }
            state.chunkItem = chunkItem;
        },

        reCreateState: (state, action: PayloadAction<{ items: Item[] }>) => {
            const { items } = action.payload;
            state.items = items.map((item, idx) => (idx < state.items.length ? state.items[idx] : item));
        },

        updateState: (state, action: PayloadAction<{ updatedRow: Item }>) => {
            const { updatedRow } = action.payload;
            state.items = state.items.map((item) =>
                item[state.itemKey] === updatedRow[state.itemKey] ? updatedRow : item,
            );
        },

        changeItem: (state, action: PayloadAction<{ id: any; item: { [key: string]: any } }>) => {
            const { id, item } = action.payload;
            state.chunkItem[id] = item;
            state.updatedRow = state.chunkItem[id];
        },

        changeValue: (
            state: State,
            action: PayloadAction<{
                key: string;
                id: any;
                value: string;
                validationType?: Validation;
                errorMsg?: string;
            }>,
        ) => {
            const { key, value, id, validationType, errorMsg } = action.payload;

            if (validationType) {
                state.chunkItem = state.chunkItem.map((c, idx) =>
                    idx === id ? { ...c, [key]: value, [validationType]: errorMsg } : c,
                );
            } else {
                state.chunkItem = state.chunkItem.map((c, idx) => (idx === id ? { ...c, [key]: value } : c));
            }
            state.updatedRow = state.chunkItem[id];
        },
        /**
         * @param key: column name
         * @param id: row index (expanded의 경우 {itemIdx: number, idx: number})
         */
        addComma: (state: State, action: PayloadAction<{ key: string; id: number }>) => {
            const { key, id } = action.payload;
            const { chunkItem } = state;
            // item 수정
            const newItem = addComma(chunkItem[id][key]);
            chunkItem[id] = { ...chunkItem[id], [key]: newItem };
        },
        removeComma: (state: State, action: PayloadAction<{ key: string; id: number }>) => {
            const { key, id } = action.payload;
            const { chunkItem} = state;
            const newItem = removeComma(chunkItem[id][key]);
            chunkItem[id] = { ...chunkItem[id], [key]: newItem };
        },
    },
});

const {
    createState: createStateAction,
    createChunk: createChunkAction,
    reCreateState: reCreateStateAction,
    updateState: updatedStateAction,
    changeValue: changeValueAction,
    changeItem: changeItemAction,
    addComma: addCommaAction,
    removeComma: removeCommaAction,
} = customTableSlice.actions;
const reducer = customTableSlice.reducer;

const useCustomTable = (
    items: Item[],
    itemPerPage: number,
    page: number,
    keys: Keys,
    preRender?: boolean,
    preItems?: Item[],
) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    // DISPATCH ACTIONS
    const createState = (items: Item[]) =>
        dispatch(createStateAction({ items, itemPerPage, page, keys }));
    const createChunk = () => dispatch(createChunkAction({ itemPerPage, page }));
    const reCreateChunk = (items: Item[]) => dispatch(reCreateStateAction({ items }));
    const updateState = (updatedRow: Item) => dispatch(updatedStateAction({ updatedRow }));
    const changeValue = (key: string, id: number, value: string) => dispatch(changeValueAction({ key, value, id }));
    const changeItem = (id: any, item: { [key: string]: any }) => dispatch(changeItemAction({ id, item }));

    // 필터링
    const validateValue = (key: string, id: number, _value: string, validationType: Validation) => {
        const { [validationType]: errorMsg, value } = validate(_value, validationType);

        dispatch(changeValueAction({ key, value, id, validationType, errorMsg }));
    };
    
    const validateItem = (
        key: string,
        errorKey: string,
        id: number,
        _value: string,
        item: { [key: string]: any },
        validationType: Validation,
    ) => {
        const { [validationType]: errorMsg, value } = validate(_value, validationType);
        
        let changeItem = {
            ...item,
            [key]: value,
            [errorKey]: errorMsg,
            joint: [{ ...item.joint[0], [key]: _value, [errorKey]: errorMsg }],
        };
        dispatch(changeItemAction({ id, item: changeItem }));
    };

    const asyncValidateValue = async (
        key: string,
        errorKey: ErrorKey,
        id: number,
        _value: string,
        item: { [key: string]: any },
        field: string,
    ) => {
        dispatch(changeValueAction({ key: key, value: _value, id: id }));
        const result: any = await asyncValidate(key, errorKey, _value, item, field);
        const errorMsg = result[errorKey];
        switch (errorKey) {
            case "emailError":
                let changeItem = {
                    ...item,
                    [key]: _value,
                    [errorKey]: errorMsg,
                    joint: [{ ...item.joint[0], [key]: _value, [errorKey]: errorMsg, user_id: result.user_id }],
                };
                
                dispatch(changeItemAction({ id: id, item: changeItem }));
                break;
            default:
                dispatch(changeValueAction({ key: errorKey, value: errorMsg, id: id }));
                break;
        }
    };

    const addComma = (key: string, id: number) => dispatch(addCommaAction({ key, id }));
    const removeComma = (key: string, id: number) => dispatch(removeCommaAction({ key, id }));

    // SET STATE & CHUNK
    useEffect(() => {
        if (!preRender) {
            createState(items);
        } else {
            items.length && reCreateChunk(items);
        }
    }, [items]);

    useEffect(() => {
        if (preRender && preItems) {
            !state.chunkItem[0] && createState(preItems);
        }
    }, [preItems]);

    useEffect(() => {
        createChunk();
    }, [page]);

    return {
        createState,
        items: state.items,
        chunkItem: state.chunkItem,
        updateState,
        eventHandler: {
            changeItem,
            changeValue,
            validateValue,
            addComma,
            removeComma,
            asyncValidateValue,
            validateItem,
        },
        updatedRow: state.updatedRow,
    };
};

// TYPE DEFINITION
interface Item {
    [key: string]: any;
}
interface State {
    items: { [key: string]: any }[];
    chunkItem: { [key: string]: any }[];
    itemKey: string;
    // checkbox data
    updatedRow: { [key: string]: any }; 
    createState: (item: Item[]) => void;
}
export interface Keys {
    itemKey: string;
}

type Validation = "quantityError" | "amountError" | "ratioError" | "taxRatioError" | "realNameError" | "stEmailError";
type ErrorKey = "authorError" | "emailError" | "seriesError";
export default useCustomTable;