import styled from "styled-components";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import DateSelector from "@/components/molecules/common/form/DateSelector";
import CustomDatePicker from "@/components/molecules/common/date-picker/CustomDatePicker";

export default function DateRangePicker({
    type,
    disabled,
    changeStartDate,
    changeEndDate,
    startDateToSet,
    endDateToSet,
    startDateError = "",
    endDateError = "",
}: DateRangePickerProps) {
    switch (type) {
        case "yearAndMonth":
            return (
                <>
                    <ValidateWrapper dataError={startDateError}>
                        <DateSelector onChangeHandler={changeStartDate} initialDate={startDateToSet} />
                    </ValidateWrapper>
                    <Deco>~</Deco>
                    <ValidateWrapper dataError={endDateError}>
                        <DateSelector onChangeHandler={changeEndDate} initialDate={endDateToSet} />
                    </ValidateWrapper>
                </>
            );
        default:
            return (
                <>
                    <ValidateWrapper dataError={startDateError}>
                        <CustomDatePicker disabled={disabled} onChange={changeStartDate} settingDate={startDateToSet} />
                    </ValidateWrapper>
                    <Deco>~</Deco>
                    <ValidateWrapper dataError={endDateError}>
                        <CustomDatePicker disabled={disabled} onChange={changeEndDate} settingDate={endDateToSet} />
                    </ValidateWrapper>
                </>
            );
    }
}
const Deco = styled.div`
    display: inline-block;
    margin: 0 8px;
`;
interface DateRangePickerProps {
    type: "default" | "yearAndMonth";
    disabled?: boolean;
    startDateError: string;
    endDateError: string;
    changeStartDate: (date: string) => void;
    changeEndDate: (date: string) => void;
    startDateToSet?: string;
    endDateToSet?: string;
}
