import { useState } from "react";
import styled from "styled-components";
import moment from "moment";

// components
import Button from "@/components/atoms/button";
import SelectBox from "@/components/atoms/selectBox/index";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import AuthorEnrollModal from "@/components/molecules/common/modal/AuthorEnrollModal";
import EditCoRelatedDataModal from "@/components/molecules/common/modal/EditCoRelatedDataModal";

// reducer
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";
import { AuthorData } from "@/pages/settlement/basedata-manage/author/types";
import { coAuthorCheck, getAuthorData, updateAuthor, checkAuthorSeries, deleteAuthorData, authorActions } from "@/pages/settlement/basedata-manage/author/authorSlice";

// hooks
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useLoading from "@/modules/hooks/useLoading";
import useExcelDownload from "@/modules/hooks/useExcelDownload";

function OptionButtons() {
    const { loadingOn, loadingOff } = useLoading();
    let [data, updatedRows, selected] = useAppSelector((state:RootState) => [
        state.author.data,
        state.dataTable.updatedRows as AuthorData[],
        state.dataTable.selectedItems as AuthorData[],
    ]);
    const { itemsPerPage, setItemsPerPage } = usePagination({ from: "Author" });
    const { alert } = useAlert();
    const dispatch = useAppDispatch();
    const [showAuthorEnroll, setShowAuthorEnroll] = useState(false);
    const [showEditCoRelatedData, setShowEditCoRelatedData] = useState(false);
    const downloadExcel = useExcelDownload();

    function selectItemPerPage(value:any) {
        setItemsPerPage(value.value);
    }

    const deleteAuthor = async() => {
        try {
            if (!selected.length) return await alert("warning", "주의", "선택된 항목이 없습니다.");
    
            let answer = await alert("confirm", "삭제 확인", "정말 삭제하시겠습니까?");
            if (answer === "Y") {
                // 시리즈명 등록한 저자있는지 검사
                let apiParams = {
                    selected: selected
                }
                const authorSeriesResult:AuthorData[] = await (await dispatch(checkAuthorSeries(apiParams)).unwrap()).data;
                if (authorSeriesResult.length === 0) {
                    // 삭제
                    let answer = await alert("confirm", "삭제 확인",
                            "해당 저자명으로 입력된 기타지급금, 선인세 데이터까지 모두 삭제됩니다.\n정말 삭제하시겠습니까?");
                    if (answer === "Y") {
                        loadingOn();
                        await dispatch(deleteAuthorData(apiParams)).unwrap();
                        await dispatch(getAuthorData({})).unwrap();
                        await alert("success", "삭제 완료", "삭제가 완료되었습니다.");
                    }
                } else {
                    // 선택한 저자명 중 하나라도 등록한 시리즈명이 있는 경우
                    let nickname = authorSeriesResult.map(d => `'${d.name}'`);
                        return await alert("warning", "주의", `${nickname} 저자명으로 등록된 시리즈를 먼저 삭제해주세요.`);
                }
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    };

    const excelDownload = (json:Array<AuthorData>, excelFileName:string) => {
        let rows:Array<{
            "저자명": string;
            "글링 아이디": string;
            "본명": string;
            "적용세율": string;
            "정산서 발급 이메일": string;
            "등록일시": string;
        }> = []; 
        json.forEach(data => {
            let taxType = "";
            let row;
            for (let j of data.joint) {
                if (j.taxType === "D") taxType = "개인(국내 거주자)";
                else if (j.taxType === "A") taxType = "개인(국내 비거주자)";
                else if (j.taxType === "B") taxType = "기타사업자";
                row  = {
                    '저자명' : data.name,
                    '글링 아이디' : j.email,
                    '본명' : j.realName,
                    '적용세율' : taxType+'-'+j.taxRatio+'%',
                    '정산서 발급 이메일' : j.stEmail,
                    '등록일시' : moment(data.created_at).format('YYYY.MM.DD'),
                };
                rows.push(row);
            }
        });
        downloadExcel({ json: rows, excelFileName: excelFileName })
    }

    async function coDataCheck() {
        try {
            const author_id_list = updatedRows.map(u => u.needCoDataCheck ? u.id : undefined);

            const apiParams = {
                author_id_list
            };
            let coRelatedData = (await dispatch(coAuthorCheck(apiParams)).unwrap()).data;

            return coRelatedData;

        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    async function updateValidate() {
        try {
            if (!updatedRows.length) return await alert("warning", "주의", "변경된 내용이 없습니다.");

            let check = updatedRows.every(row => !row.authorError && !row.realNameError && !row.emailError && !row.stEmailError);
            if (!check) {
                return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
            }
            
            let answer = await alert("confirm", "수정 확인", "수정하시겠습니까?");
            if (answer === "Y") {
                loadingOn();
                const coRelatedData = await coDataCheck();
                if (coRelatedData.length) {
                    for (let c of coRelatedData) {
                        for (let u of updatedRows) {
                            if (c.author_id === u.id) {
                                let new_co_info = u.joint.map(j => {
                                    return {
                                            ae_id: j.ae_id,
                                            realName: j.realName,
                                            taxType: j.taxType,
                                            taxRatio: j.taxRatio,
                                            coRatio: "",
                                            coRatioError: "",
                                            taxAmount: "",
                                            taxAmountError: ""
                                    }
                                })
                                c.new_co_info = JSON.parse(JSON.stringify(new_co_info));
                                break;
                            }
                        }
                    }
                    /* 
                        공저아닌 단일저자인 경우(기타지급금 적용세목 기타인 경우 제외)를 제외한 
                        비율 및 금액을 입력받을 데이터들 분류
                    */
                    let needEdit = false;
                    for (let c of coRelatedData) {
                        if (c.new_co_info.length > 1 || 
                                (c.new_co_info.length === 1 && c.type === "etc" && c.etc_tax_type === "E")
                        ) {
                            c.needInput = true;
                            needEdit = true;
                            if (c.new_co_info.length === 1) {
                                for (let n of c.new_co_info) {
                                    n.coRatio = "100";
                                }
                            }
                        } else {
                            for (let n of c.new_co_info) {
                                n.coRatio = "100";
                            }
                        }
                    } 
                    if (needEdit) {
                        dispatch(authorActions.setAuthorDatas({
                            key: "tmpCoRelatedData", value: coRelatedData
                        }))
                        setShowEditCoRelatedData(true);
                    } else {
                        await applyCoRelatedData(coRelatedData);
                    }
                } else {
                    let apiParams = {
                        updatedRows: updatedRows
                    }
                    await dispatch(updateAuthor(apiParams)).unwrap();
                    await dispatch(getAuthorData({})).unwrap();
                    await alert("success", "수정 완료", "수정이 완료되었습니다.");
                }
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff()
        }
    }

    async function applyCoRelatedData(data:any[]) {
        try {
            let tmpUpdatedRows = JSON.parse(JSON.stringify(updatedRows));
            for (let u of tmpUpdatedRows) {
                u.coRelatedData = [];
                for (let d of data) {
                    if (u.id === d.author_id) {
                        switch (d.type) {
                            case "series":
                                u.coRelatedData.push({
                                    type: d.type,
                                    series_id: d.series_id,
                                    new_co_info: d.new_co_info
                                })
                                break;
                            case "etc":
                                u.coRelatedData.push({
                                    type: d.type,
                                    etc_id: d.etc_id,
                                    etc_tax_type: d.etc_tax_type,
                                    pre_amount: d.pre_amount,
                                    st_ratio: d.st_ratio,
                                    new_co_info: d.new_co_info          
                                })
                                break;
                            case "mg":
                                u.coRelatedData.push({
                                    type: d.type,
                                    mg_id: d.mg_id,
                                    new_co_info: d.new_co_info
                                })
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            let apiParams = {
                updatedRows: tmpUpdatedRows
            }
            await dispatch(updateAuthor(apiParams)).unwrap();
            await dispatch(getAuthorData({})).unwrap();
            await alert("success", "수정 완료", "수정이 완료되었습니다.");
        } catch (err:any) {
            await alert("error", "오류 발생", err.message);
        } 
    }

    function closeEditCoRelatedDataModal() {
        setShowEditCoRelatedData(false);
    }

    function closeModal() {
        setShowAuthorEnroll(false);
    }

    function enrollAuthor() {
        setShowAuthorEnroll(true);
    }

    return (
        <>
            <OptionButtonsContainer>
                <Button
                    type="red-outline"
                    label="선택 삭제"
                    onClickHandler={deleteAuthor}
                />
                <RightOptionsWrap>
                    <SelectBox
                        labelKey="name"
                        valueKey="value"
                        selected={itemsPerPage}
                        options={options}
                        onClickValueFunc={selectItemPerPage}
                    />
                    <Button
                        type="main-outline"
                        label="저자명 등록"
                        customStyle={btnStyle}
                        onClickHandler={enrollAuthor}
                    />
                    <Button
                        type="main-outline"
                        label="엑셀 다운로드"
                        customStyle={btnStyle} onClickHandler={(e) => excelDownload(data, `(기초데이터 관리) 저자명 관리 - ${moment().format('YYYY년 MM월 DD일')}.xlsx`)}
                    />
                    <Button
                        type="main"
                        label="저장"
                        customStyle={btnStyle}
                        onClickHandler={(e) => updateValidate()}
                    />
                </RightOptionsWrap>
            </OptionButtonsContainer>
            <ModalPortal>
                <AuthorEnrollModal
                    from="author-enroll"
                    show={showAuthorEnroll}
                    close={closeModal}
                />
            </ModalPortal>
            <ModalPortal>
                <EditCoRelatedDataModal
                    from="author-enroll"
                    show={showEditCoRelatedData}
                    close={closeEditCoRelatedDataModal}
                />
            </ModalPortal>
        </>
    );
}

export default OptionButtons;

const options = [{ name: "10개씩 보기", value: "10" },
                     { name: "30개씩 보기", value: "30" },
                     { name: "50개씩 보기", value: "50" },
                     { name: "100개씩 보기", value: "100" }];

const btnStyle = {
    margin: "0 0 0 8px"
}

const OptionButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
`;

const RightOptionsWrap = styled.div`
    display: flex;
`;