import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AlertState {
    show: boolean;
    type?: "confirm" | "info" | "success" | "error" | "warning";
    title: string;
    text: string;
    state: "less" | "wait" | "resolve" | "reject";
    /**  
     * less 아무 것도 없는 상태
     * wait 사용자 대기 중
     * resolve 확인 또는 예
     * reject 아니오 
    */
}
interface IPayload {
    type?: "confirm" | "info" | "success" | "error" | "warning";
    title: string;
    text: string;
}
const initialState: AlertState = {
    show: false,
    type: "info",
    title: "",
    text: "",
    state: "less",
};

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        hideAlert(state) {
            state.show = false;
            state.type = "info";
            state.title = "";
            state.text = "";
            state.state = "less";
        },
        showAlert(state, action: PayloadAction<IPayload>) {
            state.show = true;
            state.type = action.payload.type ? action.payload.type : "info";
            state.title = action.payload.title;
            state.text = action.payload.text;
        },
        setAlertState(state, action: PayloadAction<"less" | "wait" | "resolve" | "reject">) {
            state.state = action.payload;
        },
    },
});

export const alertActions = alertSlice.actions;
export default alertSlice.reducer;