import moment from "moment";

export const getThisMonth = () => moment().format("YYYYMM");
export const getToday = () => moment().format("YYYYMMDD");

// 시작일
export const getStartDay = (date: string) => {
    const thisMonth = date || getThisMonth();
    return `${thisMonth}01`;
};
// 막일
export const getEndDay = (date: string) => {
    const thisMonth = date || getThisMonth();
    const year = Number(thisMonth.slice(0, 4));
    const month = Number(thisMonth.slice(4, 6));
    const lastDay = new Date(year, month, 0).getDate();
    return `${thisMonth}${lastDay}`;
};
export const calcPeriod = (type: CalcPeriodProps) => {
    let today = new Date().toISOString();
    let startDate = new Date(Number(today.substring(0, 4)), Number(today.substring(5, 7)) - 1, 2)
        .toISOString()
        .substring(0, 10);
    let endDate = new Date(Number(today.substring(0, 4)), Number(today.substring(5, 7)), 1)
        .toISOString()
        .substring(0, 10);

    switch (type) {
        case "today":
            startDate = endDate = moment(today).format("YYYYMMDD");
            return { startDate, endDate };
        case "yesterday":
            startDate = endDate = moment(today).subtract(1, "days").format("YYYYMMDD");
            return { startDate, endDate };
        case "lastWeek":
            startDate = moment(today).subtract(7, "days").format("YYYYMMDD");
            endDate = moment(today).subtract(1, "days").format("YYYYMMDD");
            return { startDate, endDate };
        case "thisMonth":
            startDate = moment(startDate).format("YYYYMMDD");
            endDate = moment(endDate).format("YYYYMMDD");
            return { startDate, endDate };
        case "lastMonth":
            startDate = moment(startDate).subtract(1, "months").format("YYYYMMDD");
            endDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 0)).format("YYYYMMDD"); 
            return { startDate, endDate };
        default:
            return { startDate: "", endDate: "" };
    }
};

type CalcPeriodProps = "today" | "yesterday" | "lastWeek" | "thisMonth" | "lastMonth";
