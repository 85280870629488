import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

import TextInput from "@/components/atoms/input/TextInput";
import Button from "@/components/atoms/button";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import SelectBox from "@/components/atoms/selectBox/index";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { authorActions, initialState } from "@/pages/settlement/basedata-manage/author/authorSlice";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { user, text, ratio, email, UserValidation, newAuthor, Validation } from "@/modules/utils/validate_modify";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetCoAuthorModal from "@/components/molecules/common/modal/SetCoAuthorModal";
import { useEffect, useState } from "react";
import ModalTooltip from "@/components/molecules/common/tooltip/ModalTooltip";

function EnrollAuthorBodyModal({ from }:EnrollAuthorBodyModalProps) {
    const dispatch = useAppDispatch();
    let { authorAddDatas, tmpJointArray } = useAppSelector((state:RootState) => state.author);
    const [show, setShow] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(authorActions.setAuthorDatas({
                key: "authorAddDatas", value: initialState.authorAddDatas
            }))
        }
    }, [])

    const changeValue = async(e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const item = {...authorAddDatas[0]};
        let value = e.target.value;
        switch (key) {
            case "name":
                dispatch(authorActions.setEnrollDatas({
                    index: 0, key: key, value: value
                }))
                const { authorError } = (await newAuthor({ value, error: "authorError", mandatory: true, id: item["id"]})) as Validation;
                dispatch(authorActions.setEnrollDatas({
                    index: 0, key: "authorError", value: authorError
                }))
                break;
            case "joint":
                dispatch(authorActions.setEnrollDatas({
                    index: 0, key: key, value: value
                }))
                const validateJoint = await user({value: value, error: "emailError", field: "이메일", mandatory: false}) as UserValidation;
                dispatch(authorActions.setEnrollDatas({
                    index: 0, key: "emailError", value: validateJoint.emailError
                }))
                dispatch(authorActions.setEnrollDatas({
                    index: 0, key: "jointArray", value: [{...item.jointArray[0], "email": value, "emailError": validateJoint.emailError, "user_id": validateJoint.user_id ? validateJoint.user_id : null }]
                }))
                // dispatch(authorActions.setAuthorDatas({
                //     key: "authorAddDatas", index: 0,
                //     value: {...item, "joint": validateJoint.value, "emailError": validateJoint.emailError, "jointArray": [{...item.jointArray[0], "email": validateJoint.value, "emailError": validateJoint.emailError, "user_id": validateJoint.user_id }]}
                // }))
                break;
            case "realName":
                const validateRealName = text({value: value, error: "realNameError", field: "본명", max: 20, mandatory: true}) as Validation;
                dispatch(authorActions.setAuthorDatas({
                    key: "authorAddDatas", index: 0,
                    value: {...item, [key]: validateRealName.value, "realNameError": validateRealName.realNameError, "jointArray": [{...item.jointArray[0], "realName": validateRealName.value, "realNameError": validateRealName.realNameError }]}
                }))
                break;
            case "taxRatio":
                const validateTaxRatio = ratio({value: value, error: "taxRatioError", field: "적용세율", decimal: true, mandatory: true}) as Validation;
                dispatch(authorActions.setAuthorDatas({
                    key: "authorAddDatas", index: 0,
                    value: {...item, [key]: validateTaxRatio.value, "taxRatioError": validateTaxRatio.taxRatioError, "jointArray": [{...item.jointArray[0], "taxRatio": validateTaxRatio.value, "taxRatioError": validateTaxRatio.taxRatioError }]}
                }))
                break;
            case "stEmail":
                const validateStEmail = email({value: value, error: "stEmailError", field: "이메일", mandatory: false}) as Validation;
                dispatch(authorActions.setAuthorDatas({
                    key: "authorAddDatas", index: 0,
                    value: {...item, [key]: validateStEmail.value, "stEmailError": validateStEmail.stEmailError, "jointArray": [{...item.jointArray[0], "stEmail": validateStEmail.value, "stEmailError": validateStEmail.stEmailError }]}
                }))
                break;
            default:
                break;
        }
    }

    function changeTaxType(value:any) {
        const item = {...authorAddDatas[0]};
        let taxRatio:string = "0"
        switch (value.value) {
            case "D":
                taxRatio = "3.3";
                break;
            case "A":
            case "B":
                taxRatio = "0.00";
                break;
            default:
                break;
        }
        dispatch(authorActions.setAuthorDatas({
            key: "authorAddDatas", index: 0,
            value: {...item, "taxType": value.value, "taxRatio": taxRatio, "taxRatioError": "", "jointArray": [{...item.jointArray[0], "taxType": value.value, "taxRatio": taxRatio, "taxRatioError": "" }]}
        }))
    }

    function closeModal() {
        setShow(false);
    }

    function setCoAuthor() {
        dispatch(authorActions.setIndex(0));
        dispatch(authorActions.setTmpJointArray(authorAddDatas[0].jointArray));
        setShow(true);
    }

    const options = [{name: "개인(국내 거주자)", value: "D"},
                     {name: "개인(국내 비거주자)", value: "A"},
                     {name: "기타사업자", value: "B"}];

    return (
        <>
            { (authorAddDatas && authorAddDatas.length > 0 ) &&
            <BodyWrap>
                <ItemRow>
                    <RowName>저자명</RowName>
                    <ValidateWrapper dataError={authorAddDatas[0].authorError}>
                        <TextInput
                            value={authorAddDatas[0].name}
                            placeholder="저자명"
                            customStyle={{ width: "260px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                            onChangeHandler={(e) => changeValue(e, "name")}
                            onBlurHandler={() => {(document.activeElement as HTMLElement).blur()}}
                        />
                    </ValidateWrapper>
                </ItemRow>
                <ItemRow>
                    <RowName>글링 아이디(이메일)</RowName>
                    <ValidateWrapper dataError={authorAddDatas[0].emailError}>
                        <TextInput
                            type={authorAddDatas[0].jointArray.length > 1 ? "readOnly" : "main"}
                            value={authorAddDatas[0].joint}
                            placeholder={authorAddDatas[0].jointArray.length > 1 ? "공저 별 설정" : "글링 아이디(이메일)"}
                            customStyle={{ width: "260px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                            onChangeHandler={(e) => changeValue(e, "joint")}
                        />
                    </ValidateWrapper>
                </ItemRow>
                    <ItemRow>
                        <RowName>
                            <Text text="본명" customStyle={{ fontSize: "14px", fontWeight: "400", margin: "0 8px 0 0"}}/>
                            <ModalTooltip
                                id={"enrollAuhtor0"}
                                iconWidth={20} 
                                iconHeight={20}
                                textBoxWidth={240}
                                fill={colors.gray600}
                                text="정산서에 표시되는 작가의 본명입니다." 
                            />
                        </RowName>
                        <ValidateWrapper dataError={authorAddDatas[0].realNameError}>
                            <TextInput
                                type={authorAddDatas[0].jointArray.length > 1 ? "readOnly" : "main"}
                                value={authorAddDatas[0].realName}
                                placeholder={authorAddDatas[0].jointArray.length > 1 ? "공저 별 설정" : "저자명"}
                                customStyle={{ width: "260px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                onChangeHandler={(e) => changeValue(e, "realName")}
                            />
                        </ValidateWrapper>
                </ItemRow>
                <ItemRow>
                    <RowName>
                        <Text text="적용세율" customStyle={{ fontSize: "14px", fontWeight: "400", margin: "0 8px 0 0"}}/>
                        <ModalTooltip
                            id={"enrollAuhtor1"}
                            iconWidth={20}
                            iconHeight={20}
                            textBoxWidth={320}
                            fill={colors.gray600}
                            text={`정산서 지급 시 적용할 원천징수 세율입니다.\n-국내 거주자 (국내에 주소를 두거나 183일 이상 체류 중인 개인) : 3.3%\n-국내 비거주자 (국내 거주자를 제외한 개인) : 국가별 상이\n-기타 사업자 (개인/법인 사업자) : 0%`}
                        />
                    </RowName>
                    {
                        authorAddDatas[0].jointArray.length > 1 ?
                        <SelectBox
                            disabled={true}
                            labelKey="name"
                            valueKey="value"
                            selected={""}
                            onClickValueFunc={()=>{}}
                            options={[{name: "공저별 설정", value: ""}]}
                            customStyle={{ height: "32px", width: "140px", fontSize: "12px", fontWeight: "400" }}
                        />
                        :
                        <>
                            <SelectBox
                                labelKey="name"
                                valueKey="value"
                                selected={authorAddDatas[0].taxType}
                                onClickValueFunc={changeTaxType}
                                options={options}
                                customStyle={{ width: "140px", height: "32px"}}
                            />
                            <ValidateWrapper dataError={authorAddDatas[0].taxRatioError}>
                                <PercentInputText 
                                    type="number"
                                    inText="%"
                                    value={authorAddDatas[0].taxRatio}
                                    customStyle={{width: "120px", height: "32px", padding: "0 18px 2px 0", fontSize: "12px", fontWeight: "400"}}
                                    disabled={String(authorAddDatas[0].taxType) !== "A" ? true : false}
                                    onChange={(e) => changeValue(e, "taxRatio")}
                                />
                            </ValidateWrapper>
                        </>
                    }
                </ItemRow>
                <ItemRow>
                    <RowName>
                        <Text text="정산서 발급 이메일" customStyle={{ fontSize: "14px", fontWeight: "400", margin: "0 8px 0 0"}}/>
                        <ModalTooltip
                            id={"enrollAuhtor2"}
                            iconWidth={20}
                            iconHeight={20}
                            textBoxWidth={240}
                            fill={colors.gray600}
                            text="정산서 메일 전달시 전달 받을 메일 주소입니다."
                        />
                    </RowName>
                    <ValidateWrapper dataError={authorAddDatas[0].stEmailError}>
                        <TextInput
                            type={authorAddDatas[0].jointArray.length > 1 ? "readOnly" : "main"}
                            value={authorAddDatas[0].stEmail}
                            placeholder={authorAddDatas[0].jointArray.length > 1 ? "공저 별 설정" : "정산서 발급 이메일"}
                            customStyle={{ width: "260px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                            onChangeHandler={(e) => changeValue(e, "stEmail")}
                        />
                    </ValidateWrapper>
                </ItemRow>
                <ItemRow>
                    <RowName>공저 추가</RowName>
                    <Button
                        label="공저추가"
                        type="main-outline"
                        onClickHandler={setCoAuthor}
                    />
                </ItemRow>
            </BodyWrap>
            }
            <ModalPortal>
                <SetCoAuthorModal
                    show={show}
                    from="author-enroll"
                    close={closeModal}
                    index={0}
                />
            </ModalPortal>
        </>
    )

}

export default EnrollAuthorBodyModal;

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 390px;
`
const ItemRow = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`

const RowName = styled.div`
    display: flex;
    align-items: center;
    width: 160px;
    font-size: 14px;
    padding: 17px 8px;
`

interface EnrollAuthorBodyModalProps {
    from?: string;
}