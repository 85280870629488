import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import useCheckToken from "@/modules/hooks/useCheckToken";
import styled from "styled-components";
import CoverContainer from "@/components/molecules/portfolio/publisher/enroll/CoverContainer";
import BookInfoContainer from "@/components/molecules/portfolio/publisher/enroll/BookInfoContainer";
import { useEffect } from "react";
import { useAppDispatch } from "@/modules/store";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";
import { getSalesPlatformList } from "@/pages/common/platformSlice";
import PromotionTable from "@/components/molecules/portfolio/publisher/enroll/PromotionTable";

export default function PortfolioEnroll() {
    const tokenStatus = useCheckToken();
    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();

    const getPageData = async () => {
        try {
            await loadingOn();
            await dispatch(getSalesPlatformList()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    useEffect(() => {
        getPageData();
        
        return () => {
            dispatch({ type: "pubPortfolio/initState" });
        }
    }, [])

    if (!tokenStatus) {
        return <></>
    };
    
    return (
        <SectionLayout>
            <TitleWrapper>작품 등록</TitleWrapper>
            <UpperContainer>
                <CoverContainer />
                <BookInfoContainer />
            </UpperContainer>
            <LowerContainer>
                <PromotionTable />
            </LowerContainer>
        </SectionLayout>
    )
}

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
`

const UpperContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 12px;
`

const LowerContainer = styled.div`

`