import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";

import Text from "@/components/atoms/text";
import { User } from "@/types/user";
import { RootState } from "@/modules/store";

export default function TitleContainer() {
    const user: User = useSelector((state: RootState) => state.user);

    const days = ["일", "월", "화", "수", "목", "금", "토"]
    const yesterday = moment();
    const dateFormat = yesterday.format("YYYY년 MM월 DD일");
    const day = days[Number(yesterday.format("e"))];

    let text1 = "";
    if (user.isPublisher) {
        text1 = `출판사 ${user.user_nickname}의`;
    } else {
        text1 = `${user.user_nickname} 작가님의`
    }
    const text2 = `${dateFormat} ${day}요일 매출현황입니다`;

    const textStyle = {
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "30.05px"
    }

    return (
        <StyledTitleContainer>
            <Text text={text1} customStyle={textStyle}/>
            <Text text={text2} customStyle={textStyle}/>
        </StyledTitleContainer>
    )
}

const StyledTitleContainer = styled.div`
    margin-bottom: 20px;
`