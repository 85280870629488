import styled from 'styled-components'
import Text from '@/components/atoms/text'

export default function FocusSubTitleWrapper({ text, customStyle }:textProps) {
    let updatedStyles = {...customStyle};
    delete updatedStyles.marginTop;
    delete updatedStyles.width;

    let isIncludeSpace =  text.includes("<br/>");
    
    if (!isIncludeSpace) { 
        return (
            <StyledWrapper marginTop={customStyle.marginTop} width={customStyle.width}>
                <Text text={text} customStyle={updatedStyles}/>
            </StyledWrapper>
        )
    }

    return (
        <StyledWrapper marginTop={customStyle.marginTop} width={customStyle.width}>
            {isIncludeSpace && text.split("<br/>").map((i,idx) => 
                <Text 
                    key= {idx}
                    text={i}
                    customStyle={updatedStyles}
                />
            )}
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div<StyleProps>`
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : "40px")};
    width: ${({ width }) => (width ? width : "160px")};
`
interface StyleProps {
    fontWeight?: string;
    fontSize?: string;
    lineHeight?: string;
    marginTop?: string;
    width?: string;
    color?: string;
}


interface textProps {
    text: string,
    customStyle: StyleProps
}
