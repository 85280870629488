import styled from "styled-components";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { Rnd } from "react-rnd";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { ReactComponent as Letter } from "@/assets/icons/letter.svg";
import Text from "@/components/atoms/text";

export default function MonthlyPreview() {
    const { authorList, publisherInfo, yearmon, currAuthor, salesList, totalData, etcList, mgList, taxList, briefs, stampFiles, stampSize, stampPosition } = useAppSelector((state: RootState) => state.pubStatement);
    const [html, setHtml] = useState("<div className='nodata'>왼쪽 리스트에서 작가를 선택해주세요.</div>");
    const dispatch = useAppDispatch();

    useEffect(() => {
        render();
    }, [currAuthor, salesList, mgList, etcList, totalData, taxList, briefs]);

    const render = () => {
        // 임의로 각 행의 높이를 계산하여 용지 사이즈 초과시 다음 페이지로 렌더링
        let height = 0;

        let tmpHtml = "";

        // 기본 정보 및 판매 header 설정
        tmpHtml += `
            <div class="sp-viewer">
            <table><tr>
            <td class="sp-title" rowspan=3 colspan=4>정산금 명세서</td>
            <td class="sp-header header-label border-bottom">공급자 상호</td>
            <td class="sp-header border-bottom">${ publisherInfo.publisher_name }</td>
            <td class="sp-header center header-label border-bottom">대표자</td>
            <td class="sp-header ceo-name right border-bottom"><div>&nbsp;${ publisherInfo.publisher_ceo_name }</div><div>(인)</div></td>
            </tr><tr>
            <td class="sp-header header-label border-bottom">사업자번호</td>
            <td class="sp-header border-bottom" colspan=4>${ publisherInfo.publisher_reg }</td>
            </tr><tr>
            <td class="sp-header header-label border-bottom">주소</td>
            <td class="sp-header border-bottom" colspan=4>${ publisherInfo.publisher_addr }</td>
            </tr><tr>
            <td class="sp-header header-label">정산월</td>
            <td class="sp-header" colspan=3>${ `${yearmon.substring(0,4)}년 ${yearmon.substring(4,6)}월` }</td>
            <td class="sp-header header-label border-bottom">전화</td>
            <td class="sp-header border-bottom" colspan=4>${ publisherInfo.publisher_tel }</td>
            </tr><tr>
            <td class="sp-header header-label">계약자명(필명)</td>
        `;

        if (currAuthor.user_name) {
            tmpHtml += `<td class="sp-header author-name" colspan=3>${ currAuthor.user_name }(${ currAuthor.author_names })</td>`;
        } else {
            tmpHtml += `<td class="sp-header author-name" colspan=3>${ currAuthor.author_names }</td>`;
        }
        tmpHtml += `
            <td class="sp-header header-label border-bottom">E-mail</td>
            <td class="sp-header border-bottom" colspan=4>${ publisherInfo.publisher_email }</td>
            </tr><tr>
            <td class="sp-blank" colspan=8></td>
            </tr><tr>
            <td class="sp-content center content-label sp-border top-bold bottom">시리즈명</td>
            <td class="sp-content center content-label sp-border top-bold bottom">판매플랫폼</td>
            <td class="sp-content center content-label sp-border top-bold bottom">판매월</td>
            <td class="sp-content center content-label sp-border top-bold bottom">판매 금액</td>
            <td class="sp-content center content-label sp-border top-bold bottom">플랫폼 수수료</td>
            <td class="sp-content center content-label sp-border top-bold bottom">출판사 수수료</td>
            <td class="sp-content center content-label sp-border top-bold bottom">작가 정산률</td>
            <td class="sp-content center content-label sp-border top-bold bottom">정산 금액</td>
            </tr>
        `;
        height += 73.12;

        // 판매금
        if (salesList.length) {
            for (let s of salesList) {
                if (height + 6.61 > 297) {
                    tmpHtml += `</table></div><div class="sp-viewer"><table>`;
                    height = 30; //기본 padding 높이
                }
                tmpHtml += `
                    <tr>
                    <td class="sp-content series-name">${ s.series_name }</td>
                    <td class="sp-content center">${ s.platform_name }</td>
                    <td class="sp-content center">${ s.yearmon ? `${s.yearmon.substring(0,4)}/${s.yearmon.substring(4,6)}` : "" }</td>
                    <td class="sp-content right">${ addComma(s.amount_pl) }</td>
                    <td class="sp-content right">${ addComma(Math.round(s.amount_pl - s.amount_pu)) }</td>
                    <td class="sp-content right">${ addComma(Math.round(s.amount_pu * ((100 - s.ratio)) / 100)) }</td>
                    <td class="sp-content center">
                        ${ addComma(s.ratio) }%
                        ${ s.co_ratio !== 100 ? `<span>(공저간 ${addComma(s.co_ratio)}%)</span>` : ""}
                    </td>
                    <td class="sp-content right">${ s.withMg ? s.amount_au : addComma(Math.round(s.amount_au)) }</td>
                    </tr>
                `;
                height += 6.61;
            }
        } else {
            if (height + 6.61 > 297) {
                tmpHtml += `</table></div><div class="sp-viewer"><table>`;
                height = 30; //기본 padding 높이
            }
            tmpHtml += `<tr><td class="sp-content center" colspan=8>해당사항 없음</td></tr>`;
            height += 6.61;
        }

        // 판매금 subtotal
        if (height + 7.40 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        }
        tmpHtml += `<tr>
            <td class="sp-subtotal sp-border top-dotted bottom-bold" colspan=6>판매금 소계(A)</td>
            <td class="sp-subtotal right sp-border top-dotted bottom-bold" colspan=2>${ addComma(totalData.salesSubTotal) }</td>
            </tr>`;
        height += 7.40;

        // 기타지급금 header
        if (height + 8.73 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        }
        tmpHtml += `<tr>
            <td class="sp-content center content-label sp-border bottom" colspan=4>지급항목</td>
            <td class="sp-content center content-label sp-border bottom" colspan=1.25>플랫폼</td>
            <td class="sp-content center content-label sp-border bottom" colspan=1.25>금액</td>
            <td class="sp-content center content-label sp-border bottom" colspan=1.25>작가 정산율</td>
            <td class="sp-content center content-label sp-border bottom" colspan=1.25>정산 금액</td>
            </tr>`;
        height += 8.73;

        // 기타지급금
        if (etcList.length) {
            for (let e of etcList) {
                if (height + 6.61 > 297) {
                    tmpHtml += `</table></div><div class="sp-viewer"><table>`
                    height = 30; //기본 padding 높이
                }
                tmpHtml += `<tr>
                    <td class="sp-content" colspan=4>${ e.reason }</td>
                    <td class="sp-content center" colspan=1.25>${ e.platform_name }</td>
                    <td class="sp-content center" colspan=1.25>${ addComma(e.pre_amount) }</td>
                    <td class="sp-content center" colspan=1.25>
                        ${ e.st_ratio }%${e.co_ratio !== 100 ? `<span>(공저간 ${addComma(e.co_ratio)}%)</span>` : ""}
                    </td>
                    <td class="sp-content right" colspan=1.25>${ addComma(Math.round(e.pre_amount * e.st_ratio * e.co_ratio / 10000)) }</td>
                    </tr>`;
                height += 6.61;
            }
        } else {
            if (height + 6.61 > 297) {
                tmpHtml += `</table></div><div class="sp-viewer"><table>`
                height = 30; //기본 padding 높이
            }
            tmpHtml += `<tr>
                <td class="sp-content center" colspan=8>해당사항 없음</td>
                </tr>`;
            height += 6.61;
        }

        // 기타지급금 subtotal
        if (height + 7.40 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        }
        tmpHtml += `<tr>
            <td class="sp-subtotal sp-border top-dotted bottom-bold" colspan=6>기타지급금 소계(B)</td>
            <td class="sp-subtotal right sp-border top-dotted bottom-bold" colspan=2>${ addComma(totalData.etcSubTotal) }</td>
        </tr>`;
        height += 7.40;

        // 기타경비 header
        if (height + 8.73 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        }
        tmpHtml += `<tr>
            <td class="sp-content center content-label sp-border bottom" colspan=7>적요</td>
            <td class="sp-content center content-label sp-border bottom" >공제세액</td>
            </tr>`;
        height += 8.73;

        // 기타경비
        if (!taxList.length) {
            if (height + 6.61 > 297) {
                tmpHtml += `</table></div><div class="sp-viewer"><table>`
                height = 30; //기본 padding 높이
            }
            tmpHtml += `<tr>
                <td class="sp-content center" colspan=8>해당사항 없음</td>
                </tr>`;
            height += 6.61;
        }
        for (let i of taxList) {
            if (height + 6.61 > 297) {
                tmpHtml += `</table></div><div class="sp-viewer"><table>`
                height = 30; //기본 padding 높이
            }
            tmpHtml += `<tr>
                <td class="sp-content" colspan=7>${i.tax_desc}</td>
                <td class="sp-content right">${ addComma(i.tax_amount) }</td>
                </tr>`;
            height += 6.61;
        }

        // 기타경비 subtotal
        if (height + 7.40 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        }
        tmpHtml += `<tr>
            <td class="sp-subtotal sp-border top-dotted" colspan=6>경비 소계(C)</td>
            <td class="sp-subtotal right sp-border top-dotted" colspan=2>${ addComma(totalData.taxSubTotal) }</td>
            </tr>`;
        height += 7.40;

        // 정산금 total
        if (height + 7.94 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        }
        tmpHtml += `<tr>
            <td class="sp-total sp-border top-bold bottom-bold" colspan=6>정산 금액(A+B-C)</td>
            <td class="sp-total right sp-border top-bold bottom-bold" colspan=2>${ addComma(totalData.total) }</td>
            </tr>`;
        height += 7.94;

        // 여백
        if (height + 5.29 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        } else {
            tmpHtml += `<tr>
                <td class="sp-blank" colspan=8></td>
                </tr>`;
            height += 5.29;
        }

        // 선인세
        if (height + 8.73 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        }

        tmpHtml += `<tr>
            <td class="sp-subtitle sp-border top-bold" colspan=10>선인세 현황</td>
            </tr>` 
        height += 7.40;
        tmpHtml += `<tr>
            <td class="sp-content center content-label sp-border top bottom">시리즈명</td>
            <td class="sp-content center content-label sp-border top bottom">계약플랫폼</td>
            <td class="sp-content center content-label sp-border top bottom">계약시작일</td>
            <td class="sp-content center content-label sp-border top bottom">계약금액(㉠)</td>
            <td class="sp-content center content-label sp-border top bottom">누적 소진금액(㉡)</td>
            <td class="sp-content center content-label sp-border top bottom">이달 소진금액(㉢)</td>
            <td class="sp-content center content-label sp-border top bottom" colspan=2>잔여금액(㉠-㉡-㉢)</td>
            </tr>`;
        height += 8.73;
        if (mgList.length) {
            for (let m of mgList) {
                if (height + 6.61 > 297) {
                    tmpHtml += `</table></div><div class="sp-viewer"><table>`
                    height = 30; //기본 padding 높이
                }
                tmpHtml += `<tr>
                    <td class="sp-content"><span class="series-name">${ m.series_name }</span></td>
                    <td class="sp-content center">${ m.platform_name }</td>
                    <td class="sp-content center">${ `${m.date.substring(0,4)}.${m.date.substring(4,6)}.${m.date.substring(6,8)}` }</td>
                    <td class="sp-content right">${ addComma(m.amount) }</td>
                    <td class="sp-content right">${ addComma(m.pre_deduction_price) }</td>
                    <td class="sp-content right">${ addComma(m.curr_deduction_price) }</td>
                    <td class="sp-content right" colspan=2">${ addComma(m.remain_price) }</td>
                    </tr>`;
                height += 6.61;
            }
        } else {
            if (height + 6.61 > 297) {
                tmpHtml += `</table></div><div class="sp-viewer"><table>`
                height = 30; //기본 padding 높이
            }
            tmpHtml += `<tr>
                <td class="sp-content center" colspan=8>해당사항 없음</td>
                </tr>`;
            height += 6.61;
        }

        // 여백
        if (height + 5.29 > 297) {
            tmpHtml += `</table></div><div class="sp-viewer"><table>`
            height = 30; //기본 padding 높이
        } else {
            tmpHtml += `<tr>
                <td class="sp-blank sp-border top-bold" colspan=8></td>
                </tr>`;
            height += 5.29;
        }


        if (briefs.length) {
            // 적요 header
            if (height + 5.82 > 297) {
                tmpHtml += `</table></div><div class="sp-viewer"><table>`
                height = 30; //기본 padding 높이
            }
            tmpHtml += `<tr>
                <td class="sp-briefs briefs-label sp-border top-bold bottom-dotted" colspan=8>적요</td>
                </tr>`;
            height += 5.82;

            // 적요
            for (let [idx, b] of briefs.entries()) {
                if (height + 5.82 > 297) {
                    tmpHtml += `</table></div><div class="sp-viewer"><table>`
                    height = 30; //기본 padding 높이
                }
                if (idx === briefs.length-1) {
                    tmpHtml += `<tr>
                        <td class="sp-briefs sp-border bottom-bold" colspan=8>${ idx+1 }.&nbsp;${ b }</td>
                        </tr>`;
                } else {
                    tmpHtml += `<tr>
                        <td class="sp-briefs sp-border bottom-dotted" colspan=8>${ idx+1 }.&nbsp;${ b }</td>
                        </tr>`;
                }
                height += 5.82;
            }
        } 

        tmpHtml += `</table>`;
        tmpHtml += `</div>`;

        setHtml(tmpHtml);
    }

    const deleteStamp = () => {
        dispatch({ type: "pubStatement/setStampFiles", payload: [] });
    }

    const onDragStop = (x: number, y: number) => {
        dispatch({ type: "pubStatement/setStampPosition", payload: { x, y }});
    }

    const onResizeStop = (width: number, height: number, position: any) => {
        dispatch({ type: "pubStatement/setStampSize", payload: { width, height }});
        dispatch({ type: "pubStatement/setStampPosition", payload: { x: position.x, y: position.y }});
    }

    const rndStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `dashed 2px ${colors.gray400}`,
        zIndex: "1"
    } as const;

    return (
        <PreviewContainer>
            {authorList.length ? 
                <PreviewWrapper>
                    <PdfWrapper>
                        {Boolean(stampFiles.length) && 
                            <Rnd
                                style={rndStyle}
                                size={{ width: stampSize.width, height: stampSize.height }}
                                position={{ x: stampPosition.x, y: stampPosition.y }}
                                bounds="parent"
                                onDragStop={(e, d) => onDragStop(d.x, d.y)}
                                onMouseDown={e => e.preventDefault()}
                                onResizeStop={(e, d, ref, delta, position) => onResizeStop(ref.offsetWidth, ref.offsetHeight, position)}
                            >
                                <ClearWrapper onClick={deleteStamp}>
                                    <Clear width={20} height={20} fill={colors.gray700} />
                                </ClearWrapper>
                                <StyledImg src={stampFiles[0].file} alt="stamp" />
                            </Rnd> 
                        }
                        <div id="spViewer" dangerouslySetInnerHTML={ { __html: html }}></div>
                    </PdfWrapper>
                </PreviewWrapper> :
                <NoPreviewWrapper>
                    <Letter />
                    <Text text="발급가능한 정산서가 없습니다." customStyle={{ fontSize: "28px", fontWeight: "700", margin: "54px 0 0 0" }} />
                    <Text text="불러올 수 있는 데이터가 없습니다. 다시 한 번 확인해주세요." customStyle={{ fontSize: "16px", fontWeight: "400", margin: "24px 0 0 0", color: colors.gray500 }} />
                </NoPreviewWrapper>
            }
        </PreviewContainer>
    )
}

const addComma = (str: number | string) => {
    if (str) {
        const regexp = /\B(?=(\d{3})+(?!\d))/g;
        return str.toString().replace(regexp, ',');
    } else {
        return "0";
    }
}

const PreviewContainer = styled.div`
    width: 960px;
    height: 1080px;
    margin-top: 14px;
`

const PreviewWrapper = styled.div`
    background-color: ${colors.gray300};
    border: 1px solid #EEE;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    padding: 15px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 12px;
    }

    #spViewer {
        transform-origin: 0 0;
        transform: scale(1.15);
        display: inline-block;
        position: relative;
        background-color: transparent;
        width: 210mm;
        height: 297mm;
        page-break-after: always;
        &:last-child {
            page-break-after: avoid;
        }

        .sp-viewer {
            padding: 15mm;
            width: 210mm;
            height: 295mm;
            margin-bottom: 30px;
            background-color: #FFF;
            
            &:last-child {
                margin-bottom: 0;
            }
        
            table {
                width: 180mm;
                border-collapse: separate;
        
                td {
                    vertical-align: middle;
                    white-space: nowrap;
                }
                .sp-blank {
                    height: 5.29mm;
                }
                .sp-title {
                    text-align: left;
                    font-weight: 600;
                    font-size: 6.61mm;
                }
                .sp-header {
                    font-size: 2.65mm;
                    padding-top: 1.32mm;
                    height: 5.82mm;
        
                    &.header-label {
                        font-weight: 600;
                    }
                    &.right {
                        text-align: right;
                    }
                    &.center {
                        text-align: center;
                    }
                    &.border-bottom {
                        border-bottom: 1px solid #999;
                    }
                    &.ceo-name {
                        display: flex;
                        justify-content: space-between;
                    }
                    &.author-name {
                        max-width: 200px;
                        padding-right: 10px;
                        @include ellipsis(1);
                    }
                }
                .sp-content {
                    font-size: 2.65mm;
                    padding: 0 1.32mm;
                    height: 6.61mm;
                    
                    &>.series-name {
                        display: inline-block;
                        width: 29mm;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    &.content-label {
                        padding: 0.26mm 0;
                        font-weight: 600;
                        height: 8.73mm;
                    }
                    &.right {
                        text-align: right;
                    }
                    &.center {
                        text-align: center;
                    }
                    &.header-label {
                        font-weight: 600;
                    }
                }
                .sp-subtitle {
                    font-size: 2.91mm;
                    font-weight: 600;
                    padding: 0 1.32mm;
                    height: 7.40mm;
                }
                .sp-subtotal {
                    font-size: 2.91mm;
                    font-weight: 600;
                    padding: 0 1.32mm;
                    height: 7.40mm;
                    background-color: #F2F2F2;
        
                    &.right {
                        text-align: right;
                    }
                }
                .sp-total {
                    font-size: 3.18mm;
                    font-weight: 600;
                    background-color: #CDCDCD;
                    padding: 0 1.32mm;
                    height: 7.94mm;
                    
                    &.right {
                        text-align: right;
                    }
                }
                .sp-briefs {
                    font-size: 2.65mm;
                    padding: 0.53mm 1.32mm;
                    height: 5.82mm;
        
                    &.briefs-label {
                        font-weight: 600;
                    }
                }
                .sp-border {
                    &.top {
                        border-top: 1px solid #333;
                    }
                    &.bottom {
                        border-bottom: 1px solid #333;
                    }
                    &.top-bold {
                        border-top: 2px solid #333;
                    }
                    &.bottom-bold {
                        border-bottom: 2px solid #333;
                    }
                    &.top-dotted {
                        border-top: 1px dotted #333;
                    }
                    &.bottom-dotted {
                        border-bottom: 1px dotted #333;
                    }
                }
            }
        }
        .stamp-resizer {
            border: none;
    
            &.active {
                outline: 1px dashed #333;
            }
            .stamp {
                width: 100%;
                height: 100%;
            }
        }
    }
`

const StyledImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`

const ClearWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -18px;
    right: -18px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${colors.gray500};
    cursor: pointer;
`

const NoPreviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${colors.gray50};
`

const PdfWrapper = styled.div``
