import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { updateRow } from "@/pages/common/dataTableSlice";

// components
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import TextInput from "@/components/atoms/input/TextInput";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import MgPayment from "@/components/molecules/sales-inquiry/publisher/mg/MgPayment";

// custom hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";

// utils
import { ratio } from "@/modules/utils/validate_modify";
import { addComma, removeComma } from "@/modules/utils/filter";
import { setItem } from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";

export default function MgTable() {
    const { items, fetchLoading } = useSelectorTyped((state: RootState) => state.inquiryMg);
    const dispatch = useAppDispatch();
    const { pageCount, totalVisible } = usePagination({ from: "mg" });
    
    const columns = {
        mg_type: ({ item }: TableItemProps) => {
            return <div>{item["mg_type"] === 'platform' ? '플랫폼' : '출판사'}</div>
        },
        contract_type: ({ item }: TableItemProps) => {
            return <div>{item["contract_type"] === 'MG01' ? '작가계약' : '작품계약'}</div>
        },
        target: ({ item }: TableItemProps) => {
            return <Eclipsis>{item["target"]}</Eclipsis>;
        },
        contractPrice: ({ item, index }: TableItemProps) => {
            const changePrice = (value: string) => {
                const newItem = { ...item, contractPrice: value };
                dispatch(setItem({ index, item: newItem }));
                dispatch(updateRow({ itemKey: "mg_id", updatedRow: newItem }));
            };
            const handleComma = (type:string) => () => {
                const newItem = { ...item, quantity: type === 'remove' ? removeComma(item.contractPrice) : addComma(item.contractPrice) };
                dispatch(setItem({ index, item: newItem }));
            }

            return (
                <TextInput
                    type={"main"}
                    value={item["contractPrice"]}
                    onChangeHandler={(e) => changePrice(e.target.value)}
                    onFocusHandler={handleComma("remove")}
                    onBlurHandler={handleComma('add')}
                    customStyle={InputStyle}
                />
            );
        },
        mg_ratio: ({ item, index }: TableItemProps) => {
            const changeRatio = (value: string) => {
                const res = ratio({ field: "정산비율", value, error: "mgRatioError", mandatory: true, decimal: true });
                const newItem = { ...item, mg_ratio: res.value, mgRatioError: res.mgRatioError };
                dispatch(setItem({ index, item: newItem}));
                dispatch(updateRow({ itemKey: "mg_id", updatedRow: newItem }));
            };
            const blurInput = () => () => {
                const newItem = { ...item, quantity: addComma(item.mg_ratio) };
                dispatch(setItem({ index, item: newItem }));
            }

            return (
                <ValidateWrapper dataError={item["mgRatioError"]}>
                    <TextInput
                        type={"main"}
                        value={item["mg_ratio"]}
                        onChangeHandler={(e) => changeRatio(e.target.value)}
                        onBlurHandler={() => blurInput}
                        customStyle={InputStyle}
                    />
                </ValidateWrapper>
            );
        },
        st_price: ({ item }: TableItemProps) => {
            return <div>{addComma(item["st_price"])}</div>
        },
        deductionPrice: ({ item }: TableItemProps) => {
            return <div>{addComma(item["deductionPrice"])}</div>
        },
        remainPrice: ({ item }: TableItemProps) => {
            return <div>{addComma(item["remainPrice"])}</div>
        },
        payment_dt: ({ item, index }: TableItemProps) => {
            return <MgPayment item={item} index={index} />;
        },
    };

    return (
        <>
            <TableContainer width="1320px">
                <DataTable
                    from="mg"
                    loading={fetchLoading}
                    itemKey="mg_id"
                    items={items}
                    headers={headers}
                    columns={columns} 
                />
                <ScrollToTopBtn />
            </TableContainer>
            <Pagination from="mg" pageCount={pageCount} totalVisible={totalVisible} />
        </>
    );
}

// 테이블 데이터
const headers = [
    { text: "계약시작일", value: "date", width: "100px" },
    { text: "선인세구분", value: "mg_type", width: "80px" },
    { text: "판매 플랫폼", value: "platform_name", width: "100px" },
    { text: "계약형태", value: "contract_type", width: "80px" },
    { text: "저자명", value: "author_name", width: "80px" },
    { text: "계약대상", value: "target", width: "200px" },
    { text: "계약금액", value: "contractPrice", width: "100px" },
    { text: "정산비율(%)", value: "mg_ratio", width: "100px" },
    { text: "정산금액", value: "st_price", width: "100px" },
    { text: "소진금액", value: "deductionPrice", width: "100px" },
    { text: "잔여금액(원)", value: "remainPrice", width: "100px" },
    { text: "입금/지급일", value: "payment_dt", width: "100px" },
];

const InputStyle = {
    fontSize: "12px",
    textAlign: "right",
    width: "100%",
    height: "32px",
};

const Eclipsis = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;
