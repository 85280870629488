import { useEffect } from "react";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getAuthorEtcData, setNoData } from "@/pages/settlement/saels-inquiry/author/etc/etcSlice";

// components
import TemplateLayout from "@/components/molecules/sales-inquiry/common/TemplateLayout";
import EtcTable from "@/components/molecules/sales-inquiry/author/etc/EtcTable";
import EtcSearchFilter from "@/components/molecules/sales-inquiry/author/etc/EtcSearchFilter";
import EtcTableTop from "@/components/molecules/sales-inquiry/author/etc/EtcTableTop";

// hooks
import useEtcApi from "./hooks/useEtcApi";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function Etc() {
    const { selectedPublisher, selectedAuthor, initialDataLoaded, changedParamsId } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorEtc);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    useEtcApi();

    useEffect(() => {
        getEtcData();
    }, [changedParamsId]);

    const getEtcData = async () => {
        try {
            loadingOn();
            if (initialDataLoaded && (!selectedPublisher.length || !selectedAuthor.length)) {
                return dispatch(setNoData());
            }
            await dispatch(getAuthorEtcData()).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    return (
        <TemplateLayout>
            <EtcSearchFilter />
            <EtcTableTop />
            <EtcTable />
        </TemplateLayout>
    );
}
