/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, EffectCallback, useEffect, useRef } from "react";

function useDidUpdateEffect(callback: EffectCallback, dependencies: DependencyList) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            callback();
        }
    }, dependencies);
}

export default useDidUpdateEffect;
