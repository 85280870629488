import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import useCheckToken from "@/modules/hooks/useCheckToken";
import styled from "styled-components";
import CoverContainer from "@/components/molecules/portfolio/publisher/edit/CoverContainer";
import BookInfoContainer from "@/components/molecules/portfolio/publisher/edit/BookInfoContainer";
import { useEffect } from "react";
import { useAppDispatch } from "@/modules/store";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";
import { getSalesPlatformList } from "@/pages/common/platformSlice";
import PromotionTable from "@/components/molecules/portfolio/publisher/edit/PromotionTable";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getPortfolioEditData, deletePortfolio } from "@/pages/portfolio/publisher/slice";
import Button from "@/components/atoms/button";

export default function PortfolioEdit() {
    const tokenStatus = useCheckToken();
    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const getPageData = async () => {
        try {
            await loadingOn();
            await dispatch(getSalesPlatformList()).unwrap();
            await dispatch(getPortfolioEditData(Number(searchParams.get("id")))).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    const removePortfolio = async () => {
        try {
            const answer = await alert("confirm", "삭제 확인", "삭제 하시겠습니까?");

            if (answer === "Y") {
                await loadingOn();
                await dispatch(deletePortfolio(Number(searchParams.get("id")))).unwrap();
                await alert("success", "삭제 완료", "삭제가 완료되었습니다.");
                navigate("/portfolio/list")
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    useEffect(() => {
        getPageData();

        return () => {
            dispatch({ type: "pubPortfolio/initState" });
        }
    }, [])

    if (!tokenStatus) {
        return <></>
    };
    
    return (
        <SectionLayout>
            <TitleWrapper>작품 수정</TitleWrapper>
            <UpperContainer>
                <CoverContainer />
                <BookInfoContainer />
            </UpperContainer>
            <LowerContainer>
                <PromotionTable />
            </LowerContainer>
            <DeleteBtnWrapper>
                <Button 
                    type="red-outline"
                    label="작품 삭제"
                    onClickHandler={removePortfolio}
                    customStyle={{}}
                />
            </DeleteBtnWrapper>
        </SectionLayout>
    )
}

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
`

const UpperContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 12px;
`

const LowerContainer = styled.div`

`

const DeleteBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 32px;
    margin-top: 24px;
`