import { useState, ChangeEvent, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { isArray } from "lodash";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setBatchDelete } from "@/pages/settlement/saels-inquiry/publisher/daily/dailyDeleteSlice";
import Checkbox from "@/components/atoms/input/Checkbox";
import SelectBox from "@/components/atoms/selectBox/index";
import AuthorSearch from "../../searchBar/authorSearch/AuthorSearch";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import ModalSearchBar from "@/components/molecules/common/searchBar/modalSearchBar/ModalSearchBar";
import DateRangePicker from "@/components/molecules/sales-inquiry/common/DateRangePicker";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";
import useValidateDate from "@/modules/hooks/useValidateDate";
import { getToday } from "@/modules/utils/date";
import { CheckboxState } from "../BatchDeleteDailyModal";
import { SearchSeriesResult } from "@/types/searchSeries";

export default function BatchDeleteDailyModalBody({ selected, setSelected }: ModalBodyProps) {
    const {
        startDate,
        endDate,
        platformId,
        validateError: { startDateError, endDateError },
    } = useSelectorTyped((state: RootState) => state.inquiryDailyDelete);
    const platformList = useSelectorTyped((state: RootState) => state.platform.platformList);
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);
    const [seriesName, setSeriesName] = useState("");
    const [authorName, setAuthorName] = useState("");
    const { validateStartDate, validateEndDate } = useValidateDate();

    const selectCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target.value as Partial<keyof CheckboxState>;

        if (target === "authorName") {
            setSelected((prev) => ({ ...prev, seriesName: false, [target]: !prev[target] }));
            setSeriesName("");
        } else if (target === "seriesName") {
            setSelected((prev) => ({ ...prev, authorName: false, [target]: !prev[target] }));
            setAuthorName("");
        } else {
            setSelected((prev) => ({ ...prev, [target]: !prev[target] }));
        }
    };

    const changeStartDate = (date: string) => {
        const { startDateError, endDateError } = validateStartDate(date, endDate);
        dispatch(setBatchDelete({ startDate: date, startDateError, endDateError }));
    };

    const changeEndDate = (date: string) => {
        const { startDateError, endDateError } = validateEndDate(date, startDate);
        dispatch(setBatchDelete({ endDate: date, startDateError, endDateError }));
    };

    const changePlatform = (value: any) => dispatch(setBatchDelete({ platformId: value.platform_id }));

    const handleSeriesModal = () => setShowModal((prev) => !prev);

    const applySeries = ({ selectedSeries }: SearchSeriesResult) => {
        if (isArray(selectedSeries)) return;
        setSeriesName(selectedSeries.series_name);
        dispatch(setBatchDelete({ seriesId: selectedSeries.id }));
    };

    const selectAuthor = (idx: number, authorInfo: any) => {
        setAuthorName(authorInfo.author_name);
        dispatch(setBatchDelete({ authorId: authorInfo.author_id }));
    };

    const deleteAuthor = () => {
        setAuthorName("");
        dispatch(setBatchDelete({ authorId: 0 }));
    };

    return (
        <>
            <BodyWrap>
                {/* 판매일 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox type="boolean" value="date" active={selected.date} onChangeHandler={selectCheckbox} />
                    </CheckboxWrapper>
                    <LabelWrapper>판매일</LabelWrapper>
                    <ContentWrapper>
                        <DateRangePicker
                            type="default"
                            disabled={!selected.date}
                            startDateToSet={startDate}
                            endDateToSet={endDate}
                            changeStartDate={changeStartDate}
                            changeEndDate={changeEndDate}
                            startDateError={startDateError}
                            endDateError={endDateError}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 플랫폼 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="platform"
                            active={selected.platform}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>플랫폼</LabelWrapper>
                    <ContentWrapper>
                        <SelectBox
                            disabled={!selected.platform}
                            empty={true}
                            labelKey="platform_name"
                            valueKey="platform_id"
                            selected={platformId || 0}
                            options={platformList}
                            onClickValueFunc={changePlatform}
                            customStyle={{ fontSize: "12px" }}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 시리즈명 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="seriesName"
                            active={selected.seriesName}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>시리즈명</LabelWrapper>
                    <ContentWrapper>
                        <ModalSearchBar
                            type="disabledInput"
                            placeholder="시리즈명"
                            value={seriesName}
                            disabled={!selected.seriesName}
                            onChange={() => {}}
                            onHandleModal={handleSeriesModal}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 저자명 선택 */}
                <ItemRow>
                    <CheckboxWrapper>
                        <Checkbox
                            type="boolean"
                            value="authorName"
                            active={selected.authorName}
                            onChangeHandler={selectCheckbox}
                        />
                    </CheckboxWrapper>
                    <LabelWrapper>저자명</LabelWrapper>
                    <ContentWrapper>
                        <AuthorSearch
                            from=""
                            idx={0}
                            placeholder="저자명"
                            value={authorName}
                            author={authorName}
                            disabled={!selected.authorName}
                            selectAuthorFunc={selectAuthor}
                            deleteAuthorFunc={deleteAuthor}
                            applyCoInfoFunc={() => {}}
                            customStyle={{ width: "180px" }}
                        />
                    </ContentWrapper>
                </ItemRow>
                {/* 안내문구 */}
                <GuideText>*시리즈명과 저자명은 둘 중 하나만 선택 가능합니다</GuideText>
            </BodyWrap>
            {/* 시리즈 선택 모달 */}
            <ModalPortal>
                <SearchSeriesModal
                    from=""
                    show={showModal}
                    close={handleSeriesModal}
                    applySeries={applySeries}
                    platformId={platformId}
                    withRatio={false}
                    multiSelect={false}
                />
            </ModalPortal>
        </>
    );
}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    height: 304px;
    max-height: 298px;
    overflow: auto;
`;

const ItemRow = styled.div<{ border?: boolean; active?: boolean }>`
    display: flex;
    align-items: center;
    height: 60px;
    border-top: ${({ theme, border }) => (border ? `1px solid ${theme.colors.gray300}` : "")};
    border-bottom: ${({ theme, border }) => (border ? `1px solid ${theme.colors.gray300}` : "")};
    background-color: ${({ theme, active }) => (active ? theme.colors.gray50 : "")};
`;

const BaseWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    width: 60px;
    height: 60px;
`;
const CheckboxWrapper = styled(BaseWrapper)`
    justify-content: center;
`;

const LabelWrapper = styled(BaseWrapper)`
    align-items: center;
    margin-right: 8px;
`;

const ContentWrapper = styled(BaseWrapper)`
    width: 320px;
    margin-left: 8px;

    .series-searchBar {
        width: auto;
        input {
            width: 180px;
            padding: 8px 16px 8px 8px;
        }
    }
    .search-field {
        width: 180px;
    }
`;

const GuideText = styled(BaseWrapper)`
    justify-content: center;
    width: 100%;
    height: 32px;
    margin-top: 4px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray600};
`;

interface ModalBodyProps {
    selected: CheckboxState;
    setSelected: Dispatch<SetStateAction<CheckboxState>>;
}
