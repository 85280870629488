import styled from "styled-components";
import _ from "lodash";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as ClearIcon } from "@/assets/icons/clear.svg";
import type { Atcmp, SearchHistory } from "@/types/autoComplete";

const splitAtcmptList = (type: "all" | "single", input: string, atcmpList: Atcmp[]) => {
    if (!input) return [];

    const matchKwd = _.escapeRegExp(input.trim());
    const regex = new RegExp(`((?!${matchKwd}).)+|(${matchKwd})`, "g");

    if (type === "all") {
        return atcmpList.slice(0,10).map((item, index) => ({ id: index, value: item.value.match(regex), type: item.type }));
    }
    return atcmpList.slice(0,10).map((item, index) => ({ id: index, value: item.value.match(regex) }));
};

function SearchField({
    type,
    dropdownType,
    showField,
    atcmpList,
    searchHistory,
    keyword,
    cursor,
    className,
    onSelected,
    onClearHistory,
    onClick,
}: SearchFieldProps) {
    const splitedAtcmpList: SplitList[] = splitAtcmptList(type, keyword, atcmpList);

    const clickKeyword = (item: SplitList | string) => {
        // 히스토리 키워드 클릭
        if (typeof item === "string") {
            return onSelected(item);
        }
        // 자동완성 키워드 클릭
        let keyword = item.value?.join("");
        let selectedItem = {
            id: item.id,
            value: keyword || "",
            type: item.type || "",
        };
        onSelected(selectedItem);
    };

    const deleteAllHistory = () => onClearHistory();

    const deleteHistory = (keyword: string) => {
        return (e: MouseEvent) => {
            e.stopPropagation();
            onClearHistory(keyword);
        };
    };

    switch (dropdownType) {
        case "atcmp":
            return (
                <BaseField showField={showField} className={className}>
                    {splitedAtcmpList.map((item, idx) => (
                        <RecommendKeywordField key={item.id} active={cursor === idx} onClick={() => clickKeyword(item)}>
                            {item.value &&
                                item.value.map((text, idx) => {
                                    return keyword.trim() === text.trim() ? (
                                        <span key={idx} className="matched">
                                            {text}
                                        </span>
                                    ) : (
                                        <span key={idx}>{text}</span>
                                    );
                                })}
                            {item.type === "author" && <span className="author-tag">#작가</span>}
                            {item.type === "series" && <span className="author-tag">#시리즈</span>}
                            {item.type === "realName" && <span className="author-tag">#본명</span>}
                            {item.type === "email" && <span className="author-tag">#글링아이디</span>}
                        </RecommendKeywordField>
                    ))}
                </BaseField>
            );
        // history
        default:
            return (
                <BaseField showField={showField} className={className}>
                    <HistoryKeywordField>
                        <HistoryHeader>
                            <span className="main">최근검색어</span>
                            <span onClick={deleteAllHistory}>비우기</span>
                        </HistoryHeader>
                    </HistoryKeywordField>
                    {searchHistory.map((item, idx) => (
                        <HistoryKeywordField
                            key={item.id}
                            active={cursor === idx}
                            onClick={() => clickKeyword(item.keyword)}
                        >
                            {item.keyword}
                            <ClearIcon
                                width={16}
                                height={16}
                                fill={colors.gray600}
                                onClick={deleteHistory(item.keyword)}
                            />
                        </HistoryKeywordField>
                    ))}
                </BaseField>
            );
    }
}

const BaseField = styled.div<{ showField: boolean }>`
    position: absolute;
    z-index: 100;
    width: 400px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    cursor: pointer;
    white-space: pre;
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;
    display: ${({ showField }) => (showField ? "block" : "none")};
`;

const BaseKeywordField = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 8px;
    background-color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    :hover {
        background-color: ${({ theme }) => theme.colors.blue50};
    }
`;

const HistoryKeywordField = styled(BaseKeywordField)<{ active?: boolean }>`
    position: relative;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.gray600};
    background-color: ${({ active, theme }) => active && theme.colors.blue50};
`;

const HistoryHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    :hover {
        background-color: ${({ theme }) => theme.colors.white};
    }

    .main {
        color: ${({ theme }) => theme.colors.black};
        font-weight: 500;
    }
`;

const RecommendKeywordField = styled(BaseKeywordField)<{ active: boolean }>`
    display: flex;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ active, theme }) => active && theme.colors.blue50};
    .author-tag {
        color: ${({ theme }) => theme.colors.blue500};
        font-weight: 500;
        margin-left: 8px;
    }
    .matched {
        color: ${({ theme }) => theme.colors.blue500};
    }
`;

interface SearchFieldProps {
    type: "all" | "single";
    dropdownType: string;
    showField: boolean;
    keyword: string;
    atcmpList: Atcmp[];
    searchHistory: SearchHistory;
    cursor: number;
    className?: string;
    onSelected: (value: Atcmp | string) => void;
    onClick: () => void;
    onClearHistory: (keyword?: string) => void;
}
interface SplitList {
    id: number;
    value: RegExpMatchArray | null;
    type?: string;
}

export default SearchField;
