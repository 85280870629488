import { useEffect, useState, useRef, KeyboardEvent } from "react";
import styled from "styled-components";
import theme from "@/assets/styles/theme";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import SearchField from "@/components/molecules/common/searchBar/multiSearch/SearchField";
import SimpleSearchBar from "@/components/molecules/common/searchBar/simpleSearchBar/SimpleSearchBar";
import useClickOutside from "@/modules/hooks/useClickOutside";
import type { Atcmp, SearchHistory } from "@/types/autoComplete";

export default function MultiSearchBar({
    type,
    atcmpList = [],
    searchHistory,
    reset,
    dataLoading,
    onKeyUp,
    onSelected,
    onClearHistory,
}: SearchBarProps) {
    const [dropdownType, setDropdownType] = useState("history");
    const [cursor, setCursor] = useState(-1);
    const [showDropdown, setDropdown] = useState(false);
    const [keyword, setKeyword] = useState("");
    const searchedKeyword = useRef("");
    const searchBarRef = useRef(null);
    const isOutsideClicked = useClickOutside({ ref: searchBarRef });

    useEffect(() => {
        keyword ? setDropdownType("atcmp") : setDropdownType("history");
    }, [keyword]);

    useEffect(() => {
        setCursor(-1);
    }, [showDropdown]);

    // 서치바 이외 클릭 시 드롭다운 닫기
    useEffect(() => {
        if (isOutsideClicked) setDropdown(false);
    }, [isOutsideClicked]);

    useEffect(() => {
        if (reset) {
            setKeyword("");
            searchedKeyword.current = "";
        }
    }, [reset]);

    // 입력된 키워드가 없는 채로 데이터 호출할 때
    useEffect(() => {
        if (!dataLoading) return;
        if (keyword === "" && searchedKeyword.current) {
            setKeyword(searchedKeyword.current);
        }
    }, [dataLoading]);

    const changeKeyword = (v: string) => {
        setKeyword(v.trim());
        setCursor(-1);
        keyword && setDropdown(true);
    };

    const handleKeyUp = () => {
        onKeyUp(keyword);
    };
    
    // 자동완성 | 히스토리 필드에서 키워드 선택
    const selectKeyword = (item: Atcmp | string) => {
        if (typeof item === "string") {
            const keyword = item.trim();
            setKeyword(keyword);
            searchedKeyword.current = keyword;
            onSelected(keyword);
            return;
        }

        setKeyword(item.value);
        searchedKeyword.current = item.value;

        onSelected(item);
        setDropdown(false);
        setCursor(-1);
    };

    const handleDropdown = () => setDropdown((prev) => !prev);
    const clickSearchIcon = () => selectKeyword(keyword);

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        let itemLength = 0;
        if (dropdownType === "history") {
            itemLength = searchHistory.length;
        } else {
            itemLength = atcmpList.length;
        }

        switch (e.key) {
            case "ArrowDown":
                if (cursor === -1 && showDropdown === false) {
                    setDropdown(true);
                    return;
                }
                cursor >= itemLength - 1 ? setCursor(0) : setCursor((prev) => prev + 1);
                break;
            case "ArrowUp":
                cursor < 1 ? setDropdown(false) : setCursor((prev) => prev - 1);
                break;
            case "Enter":
                setSelectedKeyword();
                break;
            default:
                return;
        }
    };

    const setSelectedKeyword = () => {
        // 드롭다운없이 검색
        if (!showDropdown) {
            if (!keyword) return;
            selectKeyword(keyword);
            return;
        }
        // 드롭다운이 열렸을 때
        if (cursor === -1) {
            selectKeyword(keyword);
        } else {
            let searchKey = "";
            if (dropdownType === "history") {
                searchKey = searchHistory[cursor].keyword;
                selectKeyword(searchKey);
            } else {
                if (type === "all") selectKeyword(atcmpList[cursor]);
                else selectKeyword(atcmpList[cursor].value);
            }
        }
        setDropdown(false);
    };

    return (
        <div ref={searchBarRef}>
            <SearchBarWrapper>
                <SimpleSearchBar
                    value={keyword}
                    placeholder="검색어를 입력해 주세요."
                    onClick={handleDropdown}
                    onChange={changeKeyword}
                    onSelectKeyword={() => {}}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyPress}
                    customStyle={SearchBarInputStyle}
                />
                <IconWrapper disabled={false} onClick={clickSearchIcon}>
                    <SearchIcon size={24} fill={theme.colors.blue500} />
                </IconWrapper>
            </SearchBarWrapper>
            <SearchField
                type={type}
                dropdownType={dropdownType}
                showField={showDropdown}
                atcmpList={atcmpList || []}
                searchHistory={searchHistory}
                keyword={keyword}
                cursor={cursor}
                className="search-field"
                onSelected={selectKeyword}
                onClearHistory={onClearHistory}
                onClick={() => {}}
            />
        </div>
    );
}

const SearchBarInputStyle = {
    width: "400px",
    height: "40px",
    padding: "8px 32px 8px 8px",
    borderColor: theme.colors.gray100,
};

const SearchBarWrapper = styled.div`
    position: relative;
`;
const IconWrapper = styled.div<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

interface SearchBarProps {
    type: "all" | "single";
    atcmpList?: Atcmp[];
    searchHistory: SearchHistory;
    reset?: boolean;
    dataLoading?: boolean;
    className?: string;
    onKeyUp: (keyword: string) => void;
    onSelected: (value: Atcmp | string) => void;
    onClearHistory: () => void;
}