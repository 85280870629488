import { useEffect } from "react";

// components
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import SetCoAuthorModalBody from "@/components/molecules/common/modal/bodyModal/SetCoAuthorModalBody";

// reducer
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import { updateRow } from "@/pages/common/dataTableSlice";
import { authorActions } from "@/pages/settlement/basedata-manage/author/authorSlice";

// types
import { BaseModalProps } from "@/pages/common/types";

// hooks
import useAlert from "@/modules/hooks/useAlert";

export default function SetCoAuthorModal({ show, from, close, index }:SetCoAuthorProps) {
    let { data, deleted_ae_id, authorAddDatas, needCoDataCheck, tmpJointArray } = useAppSelector((state:RootState) => state.author);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    useEffect(() => {
        return () => {
            dispatch(authorActions.setAuthorDatas({ key: "needCoDataCheck", value: false }));
        }
    }, [dispatch]);

    async function setCoAuthor() {
        let hasError = false;
        let email = "", realName = "", stEmail = "", taxType:"D"|"A"|"B" = "D", taxRatio = "";
        if (tmpJointArray.length > 1) {
            for (let i = 0; i < tmpJointArray.length; i++) {
                if (!tmpJointArray[i].realName) {
                    dispatch(authorActions.setTmpJointArrayDatas({
                        index: i, key: "realNameError", value: "본명을 입력해주세요."
                    }))
                    hasError = true;
                }
                if (
                    tmpJointArray[i].emailError ||
                    tmpJointArray[i].realNameError ||
                    tmpJointArray[i].stEmailError ||
                    tmpJointArray[i].taxTypeError ||
                    tmpJointArray[i].taxRatioError
                ) {
                    hasError = true;
                }
            }
        } else {
            if (!tmpJointArray[0].realName) {
                dispatch(authorActions.setTmpJointArrayDatas({
                    index: 0, key: "realNameError", value: "본명을 입력해주세요."
                }))
                hasError = true;
            }
            if (
                tmpJointArray[0].emailError ||
                tmpJointArray[0].stEmailError ||
                tmpJointArray[0].realNameError ||
                tmpJointArray[0].taxTypeError ||
                tmpJointArray[0].taxRatioError
            ) {
                hasError = true;
            } else {
                email = tmpJointArray[0].email;
				realName = tmpJointArray[0].realName;
				stEmail = tmpJointArray[0].stEmail;
				taxType = tmpJointArray[0].taxType;
				taxRatio = tmpJointArray[0].taxRatio;
            }
        }
        if (hasError) {
            return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
        }
        if (from === "author-update") {
            let deletedAeId = data[index]?.deletedAeId || [];
            // 데이터테이블 updatedRows에 넣어줌.
            let updatedRow = {
                            ...data[index],
                            'email': email,
                            'emailError': "",
                            'realName': realName,
                            'realNameError': "",
                            'stEmail': stEmail,
                            'stEmailError': "",
                            'taxType': taxType,
                            'taxTypeError': "",
                            'taxRatio': taxRatio,
                            'taxRatioError': "",
                            'joint': tmpJointArray,
                            'deletedAeId': deleted_ae_id.length
                                            ? deletedAeId.concat(deleted_ae_id) : deletedAeId,
                            'needCoDataCheck': needCoDataCheck
                        };
            dispatch(authorActions.setAuthorDatas({
                key: "data", index: index, value: updatedRow
            }))
            dispatch(updateRow({ itemKey: "id", updatedRow: updatedRow }));
        } else if (from === "author-enroll") {
            let deletedAeId = data[index]?.deletedAeId || [];
            dispatch(authorActions.setAuthorDatas({
                key: "authorAddDatas", index: index,
                value: {
                            ...authorAddDatas[index],
                            'jointArray': tmpJointArray,
                            'deletedAeId': deleted_ae_id.length
                                            ? deletedAeId.concat(deleted_ae_id) : deletedAeId,
                            'needCoDataCheck': needCoDataCheck,
                            'jointError': "",
                        }
            }));
        }
        dispatch(authorActions.setJointArray({ joint: tmpJointArray, from: from, needCoDataCheck: needCoDataCheck }));
        close();
    }

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["공저 설정"]}
                customStyle={[{ width: "960px", height: "376px" }]}
                applyFunc={setCoAuthor}
            >
                <SetCoAuthorModalBody
                    from={from}
                    index={index}
                    tmpJointArray={tmpJointArray}
                />
            </BaseModal>
        </>
    );
}

interface SetCoAuthorProps extends BaseModalProps {
    index: number;
}