import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addComma } from "@/modules/utils/filter";
import { getThisMonth } from "@/modules/utils/date";
import type { MonthlyState, UpdatedParams, DeleteParams, BatchDeleteParams } from "./types";

const name = "inquiry/publisher/monthly";
const initialState: MonthlyState = {
    items: [],
    total_cnt: 0,
    total_amt_pl: 0,
    total_amt_pu: 0,

    initialDataLoaded: false,
    fetchLoading: false,

    changedParamsId: 0, // 데이터 조회 params 변경시 업데이트
    startDate: getThisMonth() || "",
    endDate: getThisMonth() || "",
    selectedPlatform: [],
    series_name: "",
    author_name: "",
    book_name: "",

    // 자동 완성
    atcmpList: [],

    // 기타
    reset: false,
    startDateError: "",
    endDateError: "",
};

// 데이터 조회 - 첫 조회 / 필터 변경시
export const getMonthlyData = createAsyncThunk(`${name}/getMonthlyData`, async (_, { rejectWithValue, getState, signal }) => {
        try {
            const { inquiryMonthly }: any = getState();
            const apiParams = {
                startDate: inquiryMonthly.startDate,
                endDate: inquiryMonthly.endDate,
                selectedPlatform: inquiryMonthly.selectedPlatform,
                book_name: inquiryMonthly.book_name,
                series_name: inquiryMonthly.series_name,
                author_name: inquiryMonthly.author_name,
            }
            return (await api.post("/api/settlement/newSalesInquiry/monthly/publisher/select", apiParams, { signal })).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 데이터 저장
export const updateMonthlyData = createAsyncThunk(
    `${name}/updateMonthlyData`,
    async (apiParams: UpdatedParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/salesInquiry/monthly/update", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 데이터 삭제
export const deleteMonthlyData = createAsyncThunk(
    `${name}/deleteMonthlyData`,
    async (apiParams: DeleteParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/salesInquiry/monthly/delete", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 데이터 조건 삭제
export const batchMonthlyDelete = createAsyncThunk(
    `${name}/batchMonthlyDelete`,
    async (apiParams: BatchDeleteParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSalesInquiry/monthly/batch/remove", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 전체 검색 (시리즈 + 저자명 + 작품명)
export const getAtcmpByAll = createAsyncThunk(`${name}/getAtcmpByAll`,
    async ({ keyword }: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/dailyAndMonthly/select`, 
                { keyword, from: 'monthly' })).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 시리즈 검색
export const getAtcmpBySeries = createAsyncThunk(`${name}/getAtcmpBySeries`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/seriesName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 저자명 검색
export const getAtcmpByAuthor = createAsyncThunk(`${name}/getAtcmpByAuthor`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/authorName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 작품명 검색
export const getAtcmpByBookName = createAsyncThunk(`${name}/getAtcmpByBookName`,
    async ({ keyword }: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/bookName/select`, { keyword, from: 'monthly'})).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

export const monthlySlice = createSlice({
    name,
    initialState,
    reducers: {
        setFilterData: (state, { payload }) => {
            const { series_name = "", author_name = "", book_name = "" } = payload;

            if (series_name !== undefined) state.series_name = series_name;
            if (author_name !== undefined) state.author_name = author_name;
            if (book_name !== undefined) state.book_name = book_name;
        },
        setStartDate(state, { payload }) {
            state.startDate = payload;
        },
        setEndDate(state, { payload }) {
            state.endDate = payload;
        },
        setStartDateError(state, { payload }) {
            state.startDateError = payload;
        },
        setEndDateError(state, { payload }) {
            state.endDateError = payload;
        },
        setPlatforms(state, { payload }) {
            state.selectedPlatform = payload;
        },
        setNoData(state) {
            state.items = [];
            state.total_cnt = 0;
            state.total_amt_pl = 0;
            state.total_amt_pu = 0;
        },
        setItem: (state, action: PayloadAction<{ index:number; item:any }>) => {
            const { index, item } = action.payload;
            state.items[index] = item;
        },
        setParamsId(state){
            state.changedParamsId += 1;
        },
        setReset(state, { payload }) {
            state.reset = payload;
        },
        resetStore: () => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMonthlyData.pending, (state) => {
                state.fetchLoading = true;
            })
            .addCase(getMonthlyData.fulfilled, (state, { payload }) => {
                state.fetchLoading = false;
                state.initialDataLoaded = true;

                const { result, total_cnt, total_amt_pl, total_amt_pu } = payload.data;

                for (let i of result) {
                    i.amount_pl = addComma(i.amount_pl);
                    i.amount_pu = addComma(i.amount_pu);
                }

                state.items = result;
                state.total_cnt = total_cnt;
                state.total_amt_pl = Number(total_amt_pl.toFixed(2));
                state.total_amt_pu = Number(total_amt_pu.toFixed(2));
            });
        
        // 자동완성 - 전체 검색
        builder.addCase(getAtcmpByAll.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
        // 자동완성 - 시리즈 검색
        builder.addCase(getAtcmpBySeries.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
        // 자동완성 - 저자명 검색
        builder.addCase(getAtcmpByAuthor.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
        // 자동완성 - 작품명 검색
        builder.addCase(getAtcmpByBookName.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
    },
});

export const inquiryMonthlyActions = monthlySlice.actions;
export default monthlySlice.reducer;
