import styled, { css } from "styled-components";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import Text from "@/components/atoms/text";
import ChangePassword from "@/components/molecules/mypage/ChangePassword";
import InquiryList from "@/pages/support/inquiry/InquiryList";
import EditMyInfo from "@/components/molecules/mypage/EditMyInfo";
import AuthorInfo from "@/components/molecules/mypage/author/AuthorInfo";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { colors } from '@/assets/styles/theme';
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { authorInfo } from "./slice";

export default function AuthorMyPage() {
    const dispatch = useAppDispatch();
    const { user_id, user_nickname } = useAppSelector((state: RootState) => state.user);
    const [searchParams, setSearchParams] = useSearchParams();  
    const [template, setTemplate] = useState(<EditMyInfo />);  
    const [clickedItemId, setClickedItemId] = useState(0);

    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    }

    const menus = ["내 정보 수정", "비밀번호 변경", "내가 남긴 문의", "작가정보"];
    const types = ["EditMyInfo", "ChangePassword", "CheckQuestion", "AuthorInfo"];

    const clickHandler = (idx:number) => {
        setClickedItemId(idx);
        setSearchParams({ type: types[idx] });
    }

    useEffect(() => {
        dispatch(authorInfo(user_id));
    }, [dispatch, user_nickname]);

    useEffect(() => {
        switch (searchParams.get("type")) { 
            case "EditMyInfo":
                setTemplate(<EditMyInfo/>);
                break;
            case "ChangePassword":
                setTemplate(<ChangePassword/>);
                break;
            case "CheckQuestion":
                setTemplate(<InquiryList/>);
                break;
            case "AuthorInfo":
                setTemplate(<AuthorInfo/>);
                break;
        }
    }, [searchParams.get("type")]);

    useEffect(() => {
        setClickedItemId(types.indexOf(`${searchParams.get("type")}`) === -1 
        ? 0 : types.indexOf(`${searchParams.get("type")}`));
    },[]);
    
    return (
        <SectionLayout>
            <StyledTitleContainer>
                <Text text="My page" customStyle={titleStyled} />
            </StyledTitleContainer>
            <MainContainer>
                <MenuBar>
                    {menus.map((m, i) => (
                        <Container 
                            key={i}
                            isClicked={i === clickedItemId ? true : false}
                            onClick={()=>clickHandler(i)}
                        >
                        {m}
                        </Container>
                    ))}
                </MenuBar>
                {template}
            </MainContainer>
        </SectionLayout>
    )
}

const StyledTitleContainer = styled.div`
    margin-bottom: 24px;
`

const MenuBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 270px;
`

const MainContainer = styled.div`
    display: flex;
`

const Container = styled.div<{ isClicked:boolean }>`
    display: flex;
    align-items: center;
    width: fit-content;
    height: 40px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    ${({ theme }) => css
        `color: ${ theme.colors.gray600 };
    `}
    ${(props) => props.isClicked && css
        `color: ${ props.theme.colors.blue500 };
    `}
`