import styled, { css } from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ClearMark } from "@/assets/icons/clear.svg";
import theme from "@/assets/styles/theme";
import Button from "@/components/atoms/button";

/**
 * @param titles 헤더부분 텍스트, 탭부분때문에 배열로 받아옴
 * @param customStyle
 * @param children 바디부분 children
 * @param applyFunc tab 첫번째 템플릿 함수 또는 하나만 있을 경우 동작하는 함수
 * @param applyFuncTab tab 두 번째 템플릿 적용 눌렀을 시 동작하는 함수
 */

function BaseModal({ titles, customStyle, children, applyFunc, applyFuncTab, reset, footer, show, close }:BaseModalProps) {
    const child = React.Children.toArray(children);
    const [tabIdx, setTabIdx] = useState(0);
    const buttonRef = useRef(true);
    useEffect(() => {
        return () => {
            setTabIdx(0);
        }
    }, [])
    useEffect(() => {
        if (!buttonRef.current) buttonRef.current = true;
    }, [buttonRef.current])
    // function closeModal() {
    //     if (reset) {
    //         reset();
    //     }
    //     dispatch(modalAction.popModal());
    // }
    function closeModal() {
        if (reset) reset();
        if (close) {
            setTabIdx(0);
            close();
        }
    }

    function changeTab(i:number) {
        setTabIdx(i);
    }

    async function apply() {
        // tabIdx === 0 ? applyFunc : applyFuncTab
        buttonRef.current = false;
        if (tabIdx === 0) {
            if (applyFunc) await applyFunc();
        } else {
            if (applyFuncTab) await applyFuncTab();
        }
    }

    const renderFooter = () => {
        if (footer === false) return <></>;
        return footer || (
            <ModalFooterWrap>
                <Button
                    disabled={!buttonRef.current}
                    label="적용"
                    onClickHandler={apply}
                />                
            </ModalFooterWrap>
        )
    };

    return (
        <>
            { show && <ModalBackground>
                <ModalContainer width={customStyle?.[tabIdx].width} height={customStyle?.[tabIdx].height}>
                    {titles.length === 1 ?
                    <ModalHeaderWrap>
                        <TitleWrap>{titles[0]}</TitleWrap>
                        <CloseWrap onClick={closeModal}>
                            <ClearMark width="24px" height="24px" fill={theme.colors.gray600} />
                        </CloseWrap>
                    </ModalHeaderWrap>
                    :
                    <ModalHeaderWrap justifyContent="flex-first">
                        {titles.map((t, i) => (
                            <TabWrap key={i} active={i === tabIdx} onClick={() => changeTab(i)}>{t}</TabWrap>
                        ))}
                        <CloseWrap onClick={closeModal}>
                            <ClearMark width="24px" height="24px" fill={theme.colors.gray600} />
                        </CloseWrap>
                    </ModalHeaderWrap>
                    }
                    {child[tabIdx]}
                    { renderFooter() }
                </ModalContainer>
            </ModalBackground>}
        </>
    );
}

export default BaseModal;

const ModalHeaderWrap = styled.div<{ justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "space-between"};
    height: 40px;
`

const ModalFooterWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 48px;
    padding: 0px 20px;
`
const TabWrap = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.gray50};
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    color: ${({ theme }) => theme.colors.gray600};
    font-weight: 700;
    cursor: pointer;
    :first-child {
        border-top-left-radius: 4px;
    }
    ${({ active }) => 
        active && 
        css`
            color: ${({ theme }) => theme.colors.black};
            border: none;
            background-color: ${({ theme }) => theme.colors.white};
        `}
    
`

const TitleWrap = styled.div<StyledModalHeader>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: ${({ width }) => width ? width : ""};
    padding-left: 20px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 700;
    cursor: default;
`

const CloseWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
    cursor: pointer;
`

const ModalBackground = styled.div`
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.2);
    opacity: 1;
    z-index: 999;
`
const ModalContainer = styled.div<{ height?: string, width?: string }>`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: ${({ height }) => height ? height : "500px"};
    max-width: ${({ width }) => width ? width : "400px"};
    height: ${({ height }) => height ? height : "500px"};
    width: ${({ width }) => width ? width : "400px"};
    background: ${({ theme }) => theme.colors.white };
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 999;
`

interface StyledModal {
    width?: string;
    height?: string;
}

interface StyledModalHeader {
    width?: string;
}

interface BaseModalProps {
    titles: string[];
    children: React.ReactNode;
    customStyle?: StyledModal[];
    // applyFunc?: React.MouseEventHandler<HTMLButtonElement>;
    // applyFuncTab?: React.MouseEventHandler<HTMLButtonElement>;
    applyFunc?: Function;
    applyFuncTab?: Function;
    reset?: Function;
    footer?: boolean | React.ReactNode;
    show?: boolean;
    close?: Function;
}