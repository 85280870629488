import styled from "styled-components";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import { colors } from "@/assets/styles/theme";
import { dateFormat } from "@/modules/utils/filter";

export default function TimelineDetailModal({ show, close, from, modalData, modalTitle }: ComponentProps) {
    const reset = () => {
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={[modalTitle]}
            customStyle={[{ width: "480px", height: "auto" }]}
            reset={reset}
            footer={false}
        >
                <BodyWrapper>
                    {modalData.map((d: any, idx: number) => (
                        <DataWrapper key={idx}>
                            <RowWrapper>
                                <FieldNameWrapper>프로모션명</FieldNameWrapper>
                                <FieldContentWrapper>
                                    {d.name}
                                </FieldContentWrapper>
                            </RowWrapper>
                            <RowWrapper>
                                <FieldNameWrapper>프로모션 설명</FieldNameWrapper>
                            </RowWrapper>
                            <DescWrapper>
                                {d.desc}
                            </DescWrapper>
                            <RowWrapper>
                                <FieldNameWrapper>기간</FieldNameWrapper>
                                <FieldContentWrapper>
                                    {dateFormat("dot", d.start_at)} ~ {dateFormat("dot", d.end_at)}
                                </FieldContentWrapper>
                            </RowWrapper>
                        </DataWrapper>
                    ))}
                </BodyWrapper>
        </BaseModal>
    )
}

const BodyWrapper = styled.div`
    padding-bottom: 16px;
`

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 6px 20px;
`

const FieldNameWrapper = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.gray600};
`

const FieldContentWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
`

const DescWrapper = styled.div`
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24.5px;
    background-color: ${colors.gray50};
    white-space: pre-wrap;
`

const DataWrapper = styled.div`
    border-top: 1px solid ${colors.gray300};

    &:first-child {
        border-top: unset;
    }
`

interface ComponentProps {
    show: boolean;
    close: () => void;
    from: string;
    modalData: any;
    modalTitle: string;
}