import styled from "styled-components";
import { useEffect, useState } from "react";
import { colors } from "@/assets/styles/theme";
import Agreement1 from "./agreementPages/Agreement1";
import Agreement2 from "./agreementPages/Agreement2";
import Agreement3 from "./agreementPages/Agreement3";
import Agreement4 from "./agreementPages/Agreement4";
import Agreement5 from "./agreementPages/Agreement5";

export default function Agreement() {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
    const [titleNum, setTitleNum] = useState(0);
    const pageTitleText = ['제1장 총칙', '제2장 회원가입', '제3장 콘텐츠이용계약', '제4장 콘텐츠이용계약의 청약철회, 계약해제·해지 및 이용제한', '제5장 과오금, 피해보상금']
    const pageContent = [<Agreement1 />,<Agreement2 />,<Agreement3 />,<Agreement4 />,<Agreement5 />]
    
    const clickPage = (index: number) => {
        setTitleNum(index);
    }
    return(
        <AgreementStyled>
            <Title>이용약관</Title>
            <AgreementContent>
                <NavBar>
                    {pageTitleText.map((title, idx) => 
                        idx === titleNum ?
                        <NowPageBtn key={idx} onClick={() => clickPage(idx)}>{title}</NowPageBtn>
                        :<PageBtn key={idx} onClick={() => clickPage(idx)}>{title}</PageBtn>
                    )}
                </NavBar>
                {pageContent[titleNum]}
            </AgreementContent>
        </AgreementStyled>
    )
}

const AgreementStyled = styled.div`
    padding: 30px calc((100% - 1200px) / 2);
`

const AgreementContent = styled.div`
    margin-top: 40px;
`

const Title = styled.h3`
    font-weight: 700;
    line-height: 35px;
`

const NavBar = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    margin: 40px 0px;
`

const NowPageBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-right: 16px;
    border: 1px solid black;
    border-radius: 4px;
    height: 32px;
    background-color: ${colors.white};
    &:hover {
        cursor: pointer;
    }
`

const PageBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-right: 16px;
    color: ${colors.gray600};
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
    height: 32px;
    background-color: ${colors.white};
    &:hover {
        cursor: pointer;
    }
`
