import SectionLayout from '@/components/molecules/introduce/SectionLayout'
import FocusTitle from '@/components/molecules/introduce/focus/FocusTitle'
import FocusCards from '@/components/molecules/introduce/focus/FocusCards'


export default function FocusContainer() {
    return (
        <SectionLayout>
            <FocusTitle/>
            <FocusCards/>
        </SectionLayout>
    )
}