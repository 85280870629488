import styled from 'styled-components'
import Text from '@/components/atoms/text'
import theme from "@/assets/styles/theme";
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn'

export default function FocusTitle() {

    const animatedItem = useScrollFadeIn('up', 1, 0);
    const commonStyles = {
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "45px"
    };

    const defaultStyles = Object.assign({color: theme.colors.black}, commonStyles);
    const highlightStyles = Object.assign({color: theme.colors.blue500}, commonStyles);
    
    const texts: Text[] = [
        { 
            text: '글링은\u00a0',
            style: defaultStyles
        },
        {
            text: `'출판사'`,
            style: highlightStyles
        },
        {
            text: '와\u00a0',
            style: defaultStyles
        },
        {
            text: `'작가'`,
            style: highlightStyles
        },
        {
            text: '에\u00a0집중했습니다.',
            style: defaultStyles
        }
    ];
    
    return (
        <div>
            <TextWrapper {...animatedItem}>
                {texts.map((textObj, i) =>
                    <Text key={i} text={textObj.text} customStyle={textObj.style} />)}
            </TextWrapper>
        </div>
    )
}


const TextWrapper = styled.div`
    width: 606px;
    display: flex;
    margin-bottom: 40px;
`

interface Text {
    text: string,
    style: {}
}