import { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import {
    setAmount,
    setItems,
    setTotal,
    resetItems,
    saveDailySales,
    setUploaderMsg,
} from "@/pages/settlement/sales-entry/daily/dailySlice";
import Button from "@/components/atoms/button";
import SalesSummary from "@/components/molecules/sales-entry/common/SalesSummary";
import SelectBox from "@/components/atoms/selectBox/index";
import useDailyExcelUpload from "./hooks/useDailyExcelUpload";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import ExcelUploadModal from "@/components/molecules/common/modal/ExcelUploadSalesModal";
import BatchModal, { SeriesInfo } from "@/components/molecules/common/modal/BatchModal";
import SeriesMatchingModal from "@/components/molecules/common/modal/SeriesMatchingModal";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { removeComma } from "@/modules/utils/filter";
import { resetDataTable } from "@/pages/common/dataTableSlice";
import useUtils from "./hooks/useReset";
import { MatchedData, SeriesMatchingResult } from "@/types/excelUpload";

export default function DailyTableTop() {
    const { items, total_amt, total_cnt, date, afterExcelUpload, platformId } = useSelectorTyped(
        (state: RootState) => state.entryDaily,
    );
    const { isAuto, salesDate } = useSelectorTyped((state: RootState) => state.uploader);
    const { selectedItems } = useSelectorTyped((state: RootState) => state.dataTable);
    const dispatch = useAppDispatch();
    const [matchedData, setMatchedData] = useState<MatchedData[]>([]);
    const [showExcelModal, setShowExcelModal] = useState(false);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const { setItemsPerPage, itemsPerPage } = usePagination({ from: "daily", tableData: items });
    const { showMatchingModal, nonMatchedData, setShowMatchingModal, setNonMatchedData, applyExcelUpload } =
        useDailyExcelUpload();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const { platformForm, reset, platformQty } = useUtils(platformId, date);

    // 모달 컨트롤
    const handleBatchModal = () => setShowBatchModal((prev) => !prev);

    const handleSeriesMatching = () => setShowMatchingModal((prev) => !prev);

    const closeExcelModal = () => setShowExcelModal(false);

    const applyBatch = (data: SeriesInfo) => {
        let tmpItems = _.cloneDeep(items);
        tmpItems.forEach((l) => {
            if (data.bookNameCheck) {
                l.book_name = data.bookName;
                if (data.bookName !== "") {
                    l.bookNameError = "";
                }
            }
            if (data.seriesCheck) {
                l.series_id = data.series.id;
                l.series_name = data.series.name;
                l.author_name = data.authorName;
                l.seriesError = "";
            }
        });
        dispatch(setItems(tmpItems));
    };

    const applySeriesMatching = async ({ matchedData, forAddRatioList }: SeriesMatchingResult) => {
        try {
            window.scrollTo({ top: 0 });
            let newItems = [...items];

            for (let i of matchedData) {
                newItems[i.originIdx] = {
                    ...newItems[i.originIdx],
                    series_name: i.series || "",
                    series_id: i.series_id || 0,
                    author_name: i.author || "",
                    seriesError: "",
                };
            }
            dispatch(setItems(newItems));
            setMatchedData(matchedData);
            setNonMatchedData([]);
            dispatch(setUploaderMsg("EXCEL_UPLOADED"));
        } catch (err) {
            return await alert("error", "오류 발생", "적용 중 오류가 발생하였습니다.");
        }
    };

    // 이벤트
    const changeSelectBox = (v: any) => setItemsPerPage(Number(v.value));

    const clickUploadExcel = async () => {
        if (items.length && (items[0].book_name || items[0].series_name)) {
            const answer = await alert("confirm", "주의", "엑셀업로드시 기존 데이터는 모두 삭제됩니다.");
            if (answer === "Y") {
                if (platformId === 19) {
                    let comicoAnswer = await alert(
                        "confirm",
                        "안내",
                        '코미코 플랫폼은 판매량을 제공하지 않아\n임의로 "1"이 입력됩니다.\n진행하시겠습니까?',
                    );
                    if (comicoAnswer !== "Y") return;
                }
                reset();
                setShowExcelModal((prev) => !prev);
            }
        } else {
            if (platformId === 19) {
                let comicoAnswer = await alert(
                    "confirm",
                    "안내",
                    '코미코 플랫폼은 판매량을 제공하지 않아\n임의로 "1"이 입력됩니다.\n진행하시겠습니까?',
                );
                if (comicoAnswer !== "Y") {
                    dispatch(setUploaderMsg("ALERT_DENY"));
                    return;
                }
                dispatch(setUploaderMsg("ALERT_CONFIRM"));
            }
            setShowExcelModal((prev) => !prev);
        }
    };

    const clickSaveButton = () => {
        validateData();
    };

    const clickDeleteButton = async () => {
        if (selectedItems.length === 0) {
            return await alert("warning", "주의", "대상을 선택해주세요.");
        }
        const res = await alert("confirm", "삭제 확인", "삭제 하시겠습니까?");
        if (res === "Y") {
            loadingOn();
            const itemKeys = selectedItems.map((s) => s.id);
            dispatch(setItems(items.filter((item) => !itemKeys.includes(item.id))));

            if (afterExcelUpload) {
                let tmpAmt = Number(removeComma(total_amt));
                let tmpTotal = total_cnt - selectedItems.length;
                for (let i of selectedItems) {
                    tmpAmt -= Number(removeComma(i.amount));
                }
                dispatch(setTotal(tmpTotal));
                dispatch(setAmount(tmpAmt));
            }
            if (items.length === selectedItems.length) {
                dispatch(resetItems());
            }
            dispatch(resetDataTable());

            loadingOff();
            await alert("info", "삭제 완료", "삭제가 완료되었습니다.");
        }
    };

    // 저장 전 유효성 검사
    const validateData = async () => {
        dispatch(setUploaderMsg(""));

        let hasError = false;
        let tmpItems = _.cloneDeep(items);
        for (let item of tmpItems) {
            if (item.bookNameError || item.seriesError || item.quantityError || item.amountError) {
                hasError = true;
            }
            if (!item.book_name) {
                item.bookNameError = "작품명을 입력해주세요.";
                hasError = true;
            }
            if (!item.series_name) {
                item.seriesError = "시리즈명을 입력해주세요.";
                hasError = true;
            }
            if (item.quantity === "" && platformQty) {
                item.quantityError = "판매량을 입력해주세요.";
                hasError = true;
            }
            if (item.amount === "") {
                item.amountError = "판매금액을 입력해주세요.";
                hasError = true;
            }
            if (!item.series_id) {
                item.seriesError = "시리즈명을 다시 입력해주세요.";
                hasError = true;
            }
        }
        dispatch(setItems(tmpItems));
        if (hasError) return await alert("warning", "주의", "조건을 확인해주세요.");
        saveData();
    };

    const saveData = async () => {
        try {
            let answer = isAuto ? "Y" : await alert("confirm", "저장 확인", "저장 하시겠습니까?");

            if (answer === "Y") {
                try {
                    loadingOn();
                    let daySalesData = [];
                    for (let i of items) {
                        let amount = typeof i.amount == "string" ? removeComma(i.amount) : i.amount;
                        let item = {
                            date: date || salesDate,
                            platform_id: platformId,
                            series_id: i.series_id,
                            book_name: i.book_name,
                            quantity: platformQty ? Number(i.quantity) : 0,
                            amount: amount,
                        };
                        daySalesData.push(item);
                    }
                    // 시리즈 매칭시킨 데이터들중 중복된 데이터 필터링
                    let filteredMatchedData = matchedData.filter((item, i) =>
                        matchedData.some((item2) => item.seriesNo === item2.seriesNo),
                    );

                    const apiParams = {
                        salesData: daySalesData,
                        filteredMatchedData: filteredMatchedData,
                        matchedAllData: matchedData,
                    };

                    reset(true);
                    await dispatch(saveDailySales(apiParams)).unwrap();
                    dispatch(setUploaderMsg("SAVED"));
                    await alert("info", "저장 완료", "저장이 완료되었습니다.");
                } catch (err: any) {
                    await alert("error", "오류 발생", err.message);
                    dispatch(setUploaderMsg("ERROR"));
                } finally {
                    loadingOff();
                }
            }
        } catch (err) {
            await alert("error", "오류 발생", "저장 중 오류가 발생하였습니다.");
        }
    };

    return (
        <>
            <TableTopContainer>
                <TableOptions>
                    <Section>
                        <Button
                            type="red-outline"
                            label="선택 삭제"
                            onClickHandler={clickDeleteButton}
                            customStyle={{ margin: "0 8px 0 0" }}
                        />
                        {!afterExcelUpload && (
                            <Button
                                type="main-outline"
                                label="일괄 적용"
                                onClickHandler={handleBatchModal}
                                customStyle={{ margin: "0 8px 0 0" }}
                            />
                        )}
                        {afterExcelUpload && nonMatchedData.length ? (
                            <Button
                                type="main-outline"
                                label="시리즈 매칭"
                                onClickHandler={handleSeriesMatching}
                                customStyle={{ margin: "0 8px 0 0" }}
                            />
                        ) : (
                            <></>
                        )}
                    </Section>
                    <Section>
                        <SelectBox
                            labelKey="name"
                            valueKey="value"
                            selected={itemsPerPage}
                            options={options}
                            onClickValueFunc={changeSelectBox}
                            customStyle={selectBoxStyle}
                        />
                        <Button
                            id="btnDailyExcelUpload"
                            type="main-outline"
                            label="엑셀 업로드"
                            onClickHandler={clickUploadExcel}
                            customStyle={uploadBtnStyle}
                        />
                        <Button
                            id="btnDailyEntrySave"
                            type="main"
                            label="저장"
                            onClickHandler={clickSaveButton}
                            customStyle={btnStyle}
                        />
                    </Section>
                </TableOptions>
                {/* 매출 정보 */}
                {afterExcelUpload && (
                    <SalesSummary from="daily" total={total_cnt} amountName={"판매금합계"} amount={total_amt} />
                )}
            </TableTopContainer>
            {/* 일괄 업로드 모달 */}
            <ModalPortal>
                <BatchModal
                    from="daily"
                    show={showBatchModal}
                    close={handleBatchModal}
                    platformId={platformId}
                    onApplyBatch={applyBatch}
                />
            </ModalPortal>
            {/* 엑셀 업로드 모달 */}
            <ModalPortal>
                <ExcelUploadModal
                    from="daily"
                    show={showExcelModal}
                    close={closeExcelModal}
                    platformForm={platformForm}
                    onApplyExcelUpload={applyExcelUpload}
                />
            </ModalPortal>
            {/* 시리즈 매칭 모달 */}
            <ModalPortal>
                <SeriesMatchingModal
                    from="daily"
                    show={showMatchingModal}
                    close={handleSeriesMatching}
                    withRatio={false}
                    nonMatchedData={nonMatchedData}
                    applySeriesMatching={applySeriesMatching}
                />
            </ModalPortal>
        </>
    );
}

const options = [
    { name: "10개씩 보기", value: 10 },
    { name: "30개씩 보기", value: 30 },
    { name: "50개씩 보기", value: 50 },
    { name: "100개씩 보기", value: 100 },
];
const uploadBtnStyle = {
    margin: "0 0 0 8px",
    width: "113px",
};
const selectBoxStyle = {
    fontSize: "12px",
    fontWeight: "400",
};
const btnStyle = {
    margin: "0 0 0 8px",
};

const TableTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
const TableOptions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
`;
const Section = styled.div`
    display: flex;
    align-items: center;
`;
