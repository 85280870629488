import styled from "styled-components";
import Text from "@/components/atoms/text";
import { useState } from "react";
import { ReactComponent as Save } from "@/assets/icons/ebook-maker/save.svg";
import { ReactComponent as Delete } from "@/assets/icons/ebook-maker/delete.svg";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { deleteTemplate, updateTemplate, getTemplateList } from "@/pages/ebook-maker/slice";
import useAlert from "@/modules/hooks/useAlert";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import AddTemplateModal from "@/components/molecules/ebook-maker/copyright/AddTemplateModal";
import { ReactComponent as Write } from "@/assets/icons/ebook-maker/write.svg";
import EditTemplateNameModal from "./EditTemplateNameModal";
import useLoading from "@/modules/hooks/useLoading";

export default function TemplateListContainer() {
    const { bookInfo, templateList, selectedTemplateId, contentList } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const [showAddTemplate, setShowAddTemplate] = useState(false);

    const handleClick = (template_id: number) => {
        dispatch({ type: "ebookMaker/setSelectedTemplate", payload: template_id });
        dispatch({ type: "ebookMaker/setContentList", payload: template_id })
    }

    const saveTemplate = async (template_id: number) => {
        try {
            // 기본 템플릿인 경우 무시
            if (!template_id) return;
    
            const templateName = (templateList.find(t => t.template_id === template_id))?.template_name;
            // 현재 템플릿 저장
            const answer = await alert("confirm", "변경 확인", `${templateName}을 변경 하시겠습니까?`);
            if (answer === "Y") {
                loadingOn();
                await dispatch(updateTemplate({ template_id, contentList })).unwrap();
                await dispatch(getTemplateList(bookInfo)).unwrap();
                await alert("success", "변경 완료", "템플릿 변경이 완료되었습니다.");
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const removeTemplate = async (template_id: number) => {
        try {
            const templateName = (templateList.find(t => t.template_id === template_id))?.template_name;
    
            const answer = await alert("confirm", "삭제 확인", `${templateName}을 삭제 하시겠습니까?`);
            if (answer === "Y") {
                loadingOn();
                await dispatch(deleteTemplate(template_id)).unwrap();
                await dispatch(getTemplateList(bookInfo)).unwrap();
                alert("success", "삭제 완료", "템플릿 삭제가 완료되었습니다.");
            } 
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    return (
        <StyledContainer>
            <TopBarWrapper>
                <Text text="템플릿" customStyle={{ fontSize: "14px", fontWeight: "500" }} />
                <IconWrapper>
                    <Save onClick={() => saveTemplate(selectedTemplateId)} />
                    <Delete onClick={() => removeTemplate(selectedTemplateId)} width={24} height={24} fill={colors.error} />
                </IconWrapper>
            </TopBarWrapper>
            <ContentWrapper>
                <ButtonWrapper>
                    <AddTemplateBtn onClick={() => setShowAddTemplate(true)}>+ 현재 템플릿 추가</AddTemplateBtn>
                </ButtonWrapper>
                <TemplateListWrapper>
                    {templateList.map((t: any) => (
                        <TemplateCardWrapper 
                            key={t.template_id} 
                            template_id={t.template_id}
                            template_name={t.template_name}
                            created_at={t.created_at}
                            selected={selectedTemplateId === t.template_id}
                            handleClick={handleClick}
                        />
                    ))}
                </TemplateListWrapper>
            </ContentWrapper>
            <ModalPortal>
                <AddTemplateModal
                    from="copyright"
                    show={showAddTemplate}
                    close={() => setShowAddTemplate(false)}
                />
            </ModalPortal>
        </StyledContainer>
    )
}

function TemplateCardWrapper({ template_id, template_name, created_at, selected, handleClick }: TemplateCardProps) {
    const [showEditTemplateName, setShowEditTemplateName] = useState(false);

    return (
        <CardWrapper>
            <Card selected={selected} onClick={() => handleClick(template_id)}>
                <LeftBox>
                    <Text text={template_name} customStyle={{ fontSize: "14px", fontWeight: "400", margin: "0 10px 0 0" }} />
                    {template_id && selected ? <Write onClick={() => setShowEditTemplateName(true)} fill={colors.gray600} /> : <></>}
                </LeftBox>
                <Text text={created_at} customStyle={{ fontSize: "12px", fontWeight: "400", color: colors.gray600 }} />
            </Card>
            <ModalPortal>
                <EditTemplateNameModal
                    from="copyright"
                    show={showEditTemplateName}
                    close={() => setShowEditTemplateName(false)}
                    templateId={template_id}
                    oldTemplateName={template_name}
                />
            </ModalPortal>
        </CardWrapper>
    )
}


const StyledContainer = styled.div`
    width: 100%;
    height: 276px;
    margin: 16px 0;
`

const TopBarWrapper = styled.div`
    display: flex;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
`

const IconWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 54px;
    svg {
        cursor: pointer;
    }
`

const ContentWrapper = styled.div`
    height: 228px;
    background-color: ${colors.gray50};
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: right;
    height: 44px;
    padding: 16px;
`

const AddTemplateBtn = styled.div`
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.blue500};
`

const CardWrapper = styled.div`
    height: 60px;
    padding: 6px 10px;
`

const Card = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 16px;
    background-color: ${({ selected}) => selected ? colors.blue50 : colors.white};
    border: 1px solid ${({ selected}) => selected ? colors.blue300 : colors.gray300};
    cursor: pointer;
`

const LeftBox = styled.div`
    display: flex;
    align-items: center;
`

const TemplateListWrapper = styled.div`
    overflow-y: auto;
    max-height: 180px;
    &::-webkit-scrollbar {
        width: 6px;
    }
`

interface TemplateCardProps {
    template_id: number;
    template_name: string;
    created_at: string;
    selected: boolean;
    handleClick: (id: number) => void;
}