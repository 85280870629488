import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import Checkbox from "@/components/atoms/input/Checkbox";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import UploadContainer from "@/components/molecules/common/upload";

export default function StyleUploadContainer() {
    const { ignoreStyle, styleFile } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const checkIgnoreStyle = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "ebookMaker/setIgnoreStyle", payload: e.target.checked });
    }

    const deleteFile = (e: React.MouseEvent<HTMLDivElement>, idx: number) => {
        dispatch({ type: "ebookMaker/deleteStyleFile" });
    }

    const uploadHandler = (fileList: any[]) => {
        dispatch({ type: "ebookMaker/setStyleFile", payload: fileList[0]});
    }

    const checkboxLabelText = {
        fontSize: "13px",
        fontWeight: "400",
        padding: "0 0 2px 8px"
    }

    const headerText = {
        fontSize: "14px",
        fontWeight: "500",
        padding: "8px"
    }

    let fileUploadWrapper;
    if (styleFile.length) {
        fileUploadWrapper = (
            <HasFileWrapper>
                <StyledUl>
                    {styleFile.map(f => (
                        <StyledLi key={styleFile.indexOf(f)}>
                            <NameWrapper>{f.name}</NameWrapper>
                            <DeleteWrapper onClick={(e) => deleteFile(e, styleFile.indexOf(f))}>
                                <StyledClear width={24} height={24} fill={colors.gray600} />
                            </DeleteWrapper>
                        </StyledLi>
                    ))}
                </StyledUl>
            </HasFileWrapper>
        )
    } else {
        fileUploadWrapper = (
            <UploadContainer 
                accept={["css"]}
                multiple={false}
                height={148}
                uploadHandler={uploadHandler}
            />
        )
    }

    return (
        <StyledContainer>
            <FieldWrapper>
                <Text text="스타일 파일 업로드" customStyle={{ fontSize: "14px" }} />
            </FieldWrapper>
            <ContentWrapper>
                <StyledHeader>
                    <Text text="파일명" customStyle={headerText} />
                </StyledHeader>
                {fileUploadWrapper}
                <CheckboxWrapper>
                    <Checkbox type="boolean" active={ignoreStyle} onChangeHandler={checkIgnoreStyle} />
                    <Text text="원본파일의 모든 스타일(글씨크기, 색상, 정렬 등) 무시" customStyle={checkboxLabelText} />
                </CheckboxWrapper>
            </ContentWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    height: 240px;
`

const FieldWrapper = styled.div`
    display: flex;
    width: 280px;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
    margin-right: 20px;
`

const ContentWrapper = styled.div`
    width: 900px;
    padding: 12px 0;
`

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 12px 24px;
`

const StyledHeader = styled.div`
    display: flex;
    height: 40px;
`

const HasFileWrapper = styled.div``

const StyledUl = styled.ul`
    border-top: 1px solid ${colors.gray300};
`

const StyledLi = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
`

const NameWrapper = styled.div`
    width: 862px;
    padding: 8px;
    font-size: 12px;
    font-weight: 400;
`

const DeleteWrapper = styled.div`
    padding: 8px;
    font-size: 12px;
`

const StyledClear = styled(Clear)`
    cursor: pointer;
`