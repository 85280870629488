import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userActions } from "@/pages/common/userSlice";
import { IApiParams, SignInState, IPayload } from "./types";
import api from "@/api";

const name = "signin";
export const getAuth = createAsyncThunk<
    IPayload,
    IApiParams,
    {
        rejectValue: { message: string };
    }
>(`${name}/getAuth`, async (apiParams: IApiParams, thunkAPI) => {
    try {
        const res = await api.post('/api/user/local/login', apiParams);
        let userData = res.data.data.user;
        if (res.data.status === "success") {
            thunkAPI.dispatch(userActions.signIn());
            thunkAPI.dispatch(userActions.setUserInfo(userData));
        } else {
        }
        return res.data;
    } catch (err) {
        return thunkAPI.rejectWithValue({
            message: "로그인 오류 발생",
        });
        // 토큰 api 에러처리 필요
    }
});

// 업로더 로그인
export const uploaderLogin = createAsyncThunk(`${name}/uploader`, async (apiParams: IApiParams, thunkAPI) => {
    try {
        const res = await api.post('/api/uploader/local/login', apiParams);
        let userData = res.data.data.user;
        if (res.data.status === "success") {
            thunkAPI.dispatch(userActions.signIn());
            thunkAPI.dispatch(userActions.setUserInfo(userData));
        }
        return res.data;
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
})

const initialState: SignInState = {
    data: {
        auth: false,
        inputID: "",
        inputPW: "",
    },
    loading: false,
    error: false,
};

const signinSlice = createSlice({
    name: "getAuth",
    initialState,
    reducers: {
        updateInputID(state, action: PayloadAction<{ id: string }>) {
            state.data.inputID = action.payload.id;
        },
        updateInputPW(state, action: PayloadAction<{ pw: string }>) {
            state.data.inputPW = action.payload.pw;
        },
        clearInputData(state) {
            state.data.inputID = "";
            state.data.inputPW = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAuth.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAuth.fulfilled, (state, action: PayloadAction<IPayload>) => {
            state.loading = false;
            if (action.payload.status === "success") {
                state.data.auth = action.payload.data.auth;
                state.error = false;
            } else {
                state.data = initialState.data;
                state.error = action.payload.message;
            }
        });
        builder.addCase(getAuth.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload ? action.payload.message : false;
        });
        builder.addCase(uploaderLogin.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(uploaderLogin.fulfilled, (state, action: PayloadAction<IPayload>) => {
            state.loading = false;
            if (action.payload.status === "success") {
                // state.data.auth = action.payload.data.auth;
                // state.error = false;
            } else {
                state.data = initialState.data;
                state.error = action.payload.message;
            }
        });
        builder.addCase(uploaderLogin.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const signinActions = signinSlice.actions;
export default signinSlice.reducer;
