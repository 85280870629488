import styled from "styled-components"
import HomeSectionLayout from "@/components/molecules/home/HomeSectionLayout";
import { colors } from "@/assets/styles/theme";
import Text from "@/components/atoms/text";
import QuestionSearchBar from "@/components/molecules/home/question/QuestionSearchBar";

export default function Quiestion() {
    
    const titleStyle = {
        fontSize: "20px",
        fontWeight: "700"
    }

    const descriptionStyle = {
        fontSize: "14px",
        fontWeight: "400",
        color: colors.gray600
    }
    
    return (
        <HomeSectionLayout backColor={colors.blue50}>
            <StyledContainer>
                <Text text="무엇이든 물어보세요!" customStyle={titleStyle}/>
                <Text text="글링에 궁금한 모든 것을 알려드립니다." customStyle={descriptionStyle}/>
                <QuestionSearchBar/>
            </StyledContainer>            
        </HomeSectionLayout>
    )
}

const StyledContainer = styled.div`
    line-height: 36px;
`;