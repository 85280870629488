import styled from "styled-components";
import { NavLink} from "react-router-dom";
import Text from "@/components/atoms/text";
import Moneyticket from "@/assets/icons/moneyTicket.svg";
import { colors } from "@/assets/styles/theme";

export default function AdvertiseContainer() {
    const text1 = '지금 스탠다드 플랜을 무료로 이용하세요.';
    const text2 = '글링의 플랜을 구독하시고 싶으신가요?\u00a0';

    const textStyle1 = {
        fontWeight: "500",
        fontSize: "36px",
        lineHeight: "45.07px",
        color: colors.white,
        width: '315px',
        margin: '0px 0px 24px 0px'
    }

    const textStyle2 = {
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "15px",
        color: colors.white,
    }

    return(
        <AdvertiseContainerStyled>
            <AdvertiseBox>
                <TextBox>
                    <Text text={text1} customStyle={textStyle1}></Text>
                    <LinkBox>
                        <Text text={text2} customStyle={textStyle2}></Text>
                        <CustomNav title="구독문의" to="/consult"></CustomNav>
                    </LinkBox>
                </TextBox>
                <MoneyTicket src={Moneyticket}></MoneyTicket>
            </AdvertiseBox>
        </AdvertiseContainerStyled>
    )
};

export function CustomNav({ title, to } : { title?: string, to: string}) {
    const navStyle = {
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "15px",
        textDecoration: "underline",
        color: colors.blue200
    }
    return (
        <NavLink to={to} style={navStyle}>
            {title}
        </NavLink>
    )
}

const AdvertiseContainerStyled = styled.div`
    padding: 30px calc((100% - 1200px) / 2);
    height: 360px;
    background-color:${({ theme }) => theme.colors.gray800};
`

const AdvertiseBox = styled.div`
    display: flex;
    width: 1200px;
    justify-content: space-between;    
    align-items: center;
`

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
`

const LinkBox = styled.div`
    display: flex
`

const MoneyTicket = styled.img`

`

