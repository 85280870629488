import styled from "styled-components";
import { useEffect } from "react";
import { useAppDispatch } from "@/modules/store";
import { getSalesAnalysis, getSalesSummary, getSalesUpDown, getLatestLaunchingSeries, getCalendarEvent,
    getDailySeriesAnalysis, getDailyPlatformAnalysis, getEachPlatformDailyRank, getEachPlatformSettlement
} from "@/pages/dashboard/publisher/slice";
import { getSalesPlatformList } from "@/pages/common/platformSlice";

import {
    TitleContainer, SummaryCardContainer, SalesAnalysisContainer, CalendarContainer, SalesUpDownChartContainer, SalesUpDownTableContainer,
    LaunchingSeriesTableContainer, LaunchingSeriesChartContainer, DailySeriesAnalysisChartContainer, DailySeriesAnalysisTableContainer, 
    DailyPlatformAnalysisContainer, EachPlatformDailyRankContainer, EachPlatformSettlementContainer
} from "@/components/molecules/dashboard/publisher"
import moment from "moment";
import useCheckToken from "@/modules/hooks/useCheckToken";
import useAlert from "@/modules/hooks/useAlert";

export default function PubDashboard() {
    const dispatch = useAppDispatch();
    const nowYearmon = moment().format("YYYYMM");
    const tokenStatus = useCheckToken();
    const { alert } = useAlert();

    const getDashboardData = async () => {
        try {
            await dispatch(getSalesSummary());
            await dispatch(getSalesAnalysis());
            await dispatch(getSalesUpDown(0));
            await dispatch(getSalesPlatformList());
            await dispatch(getLatestLaunchingSeries(30));
            await dispatch(getDailySeriesAnalysis());
            await dispatch(getDailyPlatformAnalysis());
            await dispatch(getEachPlatformDailyRank());
            await dispatch(getEachPlatformSettlement());
            await dispatch(getCalendarEvent(nowYearmon));
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        }
    }

    useEffect(() => {
        if (tokenStatus) {
            getDashboardData();
        }
    }, [dispatch, tokenStatus, nowYearmon]);

    if (!tokenStatus) {
        return <></>
    }

    return (
        <DashboardSection>
            <TitleContainer />
            <SummaryCardContainer />
            <FlexDiv>
                <SalesAnalysisContainer />
                <CalendarContainer />
            </FlexDiv>
            <FlexDiv>
                <SalesUpDownChartContainer />
                <SalesUpDownTableContainer />
            </FlexDiv>
            <FlexDiv>
                <LaunchingSeriesChartContainer />
                <LaunchingSeriesTableContainer />
            </FlexDiv>
            <FlexDiv>
                <DailySeriesAnalysisChartContainer />
                <DailySeriesAnalysisTableContainer />
            </FlexDiv>
            <DailyPlatformAnalysisContainer />
            <EachPlatformDailyRankContainer />
            <EachPlatformSettlementContainer />
        </DashboardSection>
    );
}

const DashboardSection = styled.div`
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.gray50};
    padding: 30px calc((100% - 1200px) / 2);
`

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`