import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import SliderItem from "@/components/molecules/common/banner-slider/sliderItem"
import { ReactComponent as ArrowLeft } from "@/assets/icons/web-left-arrow.svg";
import { ReactComponent as ArrowRight } from "@/assets/icons/web-right-arrow.svg";

function isBackColor(backColorNum: number, items: Array<String>) {
  switch(backColorNum) {
    case 0:
      return `${items[0]}`
    case 1:
      return `${items[1]}`
    case 2:
      return `${items[2]}`
    default :
      return `${items[2]}`
  }
}

function useInterval(callback:()=>void, delay:number) {
    const savedCallback = useRef<()=> void>();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(()=> {
        function tick() {
            savedCallback.current && savedCallback.current() 
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    });
}


export default function Slider({isFull, height, rendingData, backItems }:SliderProps) {
    const items = backItems;
    const transitionTime = 300;
    const transitionStyle = `transform ${transitionTime}ms ease-in`;
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [currentIndex, setCurrentIndex] =  useState(0);
    const [slideTransition, setTransition] = useState(transitionStyle);
    const [active, setActive] = useState(false);

    useEffect(()=> {
      window.addEventListener("resize", resizeHandler);

      return () => {window.removeEventListener("resize", resizeHandler)}
    }, []);

    const resizeHandler = () => {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
    }

    useInterval(()=> {
        handleSlide(currentIndex+1);
    }, 3000)

    const dotClickHandler = (idx:number) => {
      setCurrentIndex(idx);
      setTransition(transitionStyle);
    }
    function replaceSlide(index:number) {
        setTimeout(()=> {
            setTransition('');
            setCurrentIndex(index);
        }, transitionTime);
    }

    function handleSlide(idx:number) {
      let index = idx%3;
        setCurrentIndex(index);
        replaceSlide(index);
        setTransition(transitionStyle);
    }

    const Dots = ({ index }: {index:number}) => {
      const clickbutton = [[0, 1, 2], [1, 0, 2], [2, 0, 1]]
  
      return (
        <>
          <Dot 
              className={`dot-0 dot-pos-${index}`}
              onClick={() => dotClickHandler(clickbutton[index][0])}
          />
          <Dot
              className={`dot-1 dot-pos-${index}`}
              onClick={() => dotClickHandler(clickbutton[index][1])}
          />
          <Dot 
              className={`dot-2 dot-pos-${index}`}
              onClick={() => dotClickHandler(clickbutton[index][2])}
          />
        </>
      )
    }

    return (
          <StyledSliderArea
            isFull={isFull}
            height={height}
            innerHeight={innerHeight}
            items={items}
            backColorNum={currentIndex-1}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
          >
              <StyledSlider style={{ overflow: "hidden"}}>
                  <StyledSliderList>
                    <StyledSliderTract isFull={isFull} currentIndex={currentIndex} style={{ transition: slideTransition }}>
                        {
                          rendingData.map((m, i) => {
                            return (
                              <SliderItem
                                    key={i}
                                    src={m.src}
                                    title={m.title}
                                    description={m.description}
                                    titleStyle= {m.titleStyle}
                                    descriptionStyle={m.descriptionStyle}
                                    innerWidth={innerWidth}
                                    buttonPath={m.buttonPath}
                              />
                            )
                          })
                        }
                    </StyledSliderTract>
                    <StyledButtonWrapper isFull={isFull} width={innerWidth}>
                        <StyledDotContainer>
                          <Dots index={currentIndex}/>
                        </StyledDotContainer>
                    </StyledButtonWrapper>
                </StyledSliderList>
            </StyledSlider>
            {
              active ?
              <ArrowButtonWrap width={innerWidth}>
                <ArrowBtn onClick={() => dotClickHandler(currentIndex-1 === -1 ? 2 : currentIndex-1)}>
                  <ArrowLeft width={60} height={60} />
                </ArrowBtn>
                <ArrowBtn onClick={() => dotClickHandler(currentIndex+1 === 3 ? 0 : currentIndex+1)}>
                  <ArrowRight width={60} height={60} />
                </ArrowBtn>
              </ArrowButtonWrap>
              :
              <></>
            }
        </StyledSliderArea>
    )
}


const StyledSliderArea = styled.div<{backColorNum: number, isFull: boolean, innerHeight: number, height?: string, items: Array<String>}>`
    display: flex;
    align-items: center;
    height: ${({ isFull, height, innerHeight }) => isFull ? `${innerHeight-72}px` : `${height}px`};
    background-color: ${({ backColorNum, items }) => isBackColor(backColorNum, items)};
    transition: all ease .3s 0s;
`;

const StyledSlider = styled.div``;
const StyledSliderList = styled.div``;
const StyledSliderTract = styled.div<{ currentIndex: number, isFull: boolean }>`
    display: flex;
    transform: ${({ isFull, currentIndex}) => isFull ? `translateX(${-100 * currentIndex}%)` : `translateX(${-101 * currentIndex}%)`};
`;

const ArrowButtonWrap = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => `${width - 20}px`}; ;
  padding: ${({ width }) => 300 - ((1920-width)/2) > 0 ? `0 ${300 - ((1920-width)/2)}px` : "0 10px"};
  position: absolute;
  min-width: 1200px;
`

const ArrowBtn = styled.div`
  cursor: pointer;
`

const StyledButtonWrapper = styled.div<{ isFull: boolean, width: number }>`
    position: absolute;
    margin-top: ${({ isFull }) => isFull ? "" : "20px"};
    transform: translateX(-50%);
    z-index: 0;

    left: ${({ width }) => 360 - ((1920-width)/2) > 0 ? `${470 - ((1920-width)/2)}px` : "120px"};
`

const StyledDotContainer = styled.div`
    width: 64px;
    display: flex;
`

const Dot = styled.div`
    width: 8px;
    height:8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    &.dot-0,
    &.dot-1,
    &.dot-2 {
      position: absolute;
      transition: all 0.3s;
    }

    &.dot-0 {
      background-color: ${({ theme }) => theme.colors.blue500};
      width: 24px;
      border-radius: 10px;
      &.dot-pos-0 {
        left: 0px;
      }

      &.dot-pos-1 {
        left: 14px;
      }
      
      &.dot-pos-2 {
        left: 28px;
      }
    }

    &.dot-1 {
      &.dot-pos-0 {
        left: 30px;
      }

      &.dot-pos-1 {
        left: 0px;
      }

      &.dot-pos-2 {
        left: 0px;
      }
    }

    &.dot-2 {
      &.dot-pos-0 {
        left: 44px;
      }

      &.dot-pos-1 {
        left: 44px;
      }

      &.dot-pos-2 {
        left: 14px;
      }
    }
`

interface titleStyle {
  fontWeight: string,
  fontSize: string,
  lineHeight: string,
  color: string
}

interface descriptionStyle {
  fontWeight: string,
  fontSize: string,
  lineHeight: string,
  color: string
}

interface DataProps {
  id: number,
  title: string,
  description: string,
  titleStyle: titleStyle,
  descriptionStyle: descriptionStyle,
  src: string,
  buttonPath: string
}

interface SliderProps {
  isFull: boolean,
  height?: string,
  rendingData: Array<DataProps>,
  backItems: Array<string>
}