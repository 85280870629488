import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "@/modules/store";
import { HelmetProvider } from "react-helmet-async";
import App from "@/App";

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLDivElement);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
);
