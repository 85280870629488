import styled from "styled-components";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import React, { useState } from "react";
import ReactCalendar from "react-calendar";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { Oval } from "react-loader-spinner";
import { getCalendarEvent } from "@/pages/dashboard/publisher/slice";

export default function CalendarWrapper () {
    const { calendarEvent, calendarLoading } = useAppSelector((state: RootState) => state.pubDashboard);
    const dispatch = useAppDispatch();
    const today = moment().format("YYYYMM");
    const [date, setDate] = useState(new Date());
    const [month, setMonth] = useState(new Date( Number(today.substring(0,4)), Number( today.substring(4,6))-1 ));

    if (calendarLoading) {
        return (
            <StyledCalendarWrapper>
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
            </StyledCalendarWrapper>
        )
    }

    const selectDate = (v: Date) => {
        const selectedDate = moment(v).format("YYYYMMDD")
        dispatch({ type: "pubDashboard/setSelectedDateEvent", payload: selectedDate })
        setDate(v);
    }

    const formatDay = (l: any, date: string) => {
        return moment(date).format("D");
    }

    const onMonthChange = ({ action, activeStartDate}: any) => {
        if (action !== "drillUp") {
            const yearmon = moment(activeStartDate).format("YYYYMM");
            setMonth( new Date( Number(yearmon.substring(0,4)), Number( yearmon.substring(4,6))-1 ) );
            dispatch(getCalendarEvent(yearmon))
        }
    }

    const colorList: any = {
        launching: colors.success,
        promotion: colors.success,
        MGS: colors.information,
        MRS: colors.information,
        notice: colors.error
    }

    const eventMarker = ({ date }: CalendarDateType) => {
        const formatDate = moment(date).format("YYYYMMDD");
        const eventList: string[] = [];
        for (let c of calendarEvent) {
            if (c.date === formatDate && !eventList.includes(c.type)) {
                eventList.push(c.type);
            }
        }
        return (
            <StyledEventMarkerWrapper>
                {eventList.map(e => (
                    <StyledEventMarker
                        key={e}
                        width="5px"
                        height="4px"
                        top="5px"
                        fillColor={colorList[e]}
                    />
                ))}
            </StyledEventMarkerWrapper>
        )
    }
    
    const eventMarkerDescStyle = {
        fontSize: "10px",
        fontWeight: "400"
    }

    return (
        <StyledCalendarWrapper>
            <StyledEventMarkerDescWrapper>
                <StyledEventMarkerDesc>
                    <StyledEventMarker fillColor={colors.success} />
                    <Text text="런칭, 프로모션" customStyle={eventMarkerDescStyle} />
                </StyledEventMarkerDesc>
                <StyledEventMarkerDesc>
                    <StyledEventMarker fillColor={colors.information} />
                    <Text text="정산, 선인세" customStyle={eventMarkerDescStyle} />
                </StyledEventMarkerDesc>
                <StyledEventMarkerDesc>
                    <StyledEventMarker fillColor={colors.error} />
                    <Text text="공지사항" customStyle={eventMarkerDescStyle} />
                </StyledEventMarkerDesc>
            </StyledEventMarkerDescWrapper>
            <StyledReactCalendar
                onChange={selectDate}
                onActiveStartDateChange	={onMonthChange}
                value={date}
                calendarType={"US"}
                formatDay={formatDay}
                showNeighboringMonth={false}
                prev2Label={null}
                next2Label={null}
                tileContent={eventMarker}
                defaultActiveStartDate={month}
            />
        </StyledCalendarWrapper>
    )
}

const StyledCalendarWrapper = styled.div`
    height: 340px;
    width: 376px;
`

const StyledReactCalendar = styled(ReactCalendar)`
    width: 376px !important;
    border: 0px solid #fff !important;

    .react-calendar__navigation {
        height: 41px;
        background-color: #FFFFFF;
        margin-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-weight: 600;

        .react-calendar__navigation__arrow {
            font-size: 26px;
            padding-bottom: 9px;
            font-weight: 600;
        }
    }

    .react-calendar__viewContainer {
        padding-top: 29px;
        background-color: #FFFFFF;
        height: 309px;
    }

    .react-calendar__month-view {
        height: 280px;
    }

    .react-calendar__month-view__weekdays {
        color: #868E96;
        height: 40px;
    }

    .react-calendar__month-view__days {
        font-size: 14px;
        height: 200px;
    }

    .react-calendar__tile {
        width: 40px;
        height: 40px;
    }

    .react-calendar__navigation__label__labelText {
        font-weight: 600;
    }
`

const StyledEventMarkerDescWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 376px;
    padding: 5px 60px;
`

const StyledEventMarkerDesc = styled.div`
    position: relative;
    top: 43px;
    display: flex;
`

const StyledEventMarker = styled.div<EventMarkerProps>`
    position: relative;
    width: ${({ width }) => width ? width : "11px"};
    height: ${({ height }) => height ? height : "10px"};
    border-radius: 50%;
    background-color: ${({ fillColor }) => fillColor ? fillColor : "black"};
    margin-right: 5px;
    top: ${({ top }) => top ? top : "0px"};
`

const StyledEventMarkerWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 26px;
    margin-left : 10px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`

interface CalendarDateType {
    activeStartDate: string;
    date: string;
    view: string;
}

interface EventMarkerProps {
    fillColor?: string;
    width?: string;
    height?: string;
    position?: string;
    top?: string;
}