import styled from 'styled-components'
import FocusCard from '@/components/molecules/introduce/focus/FocusCard'
import { ReactComponent as Time} from "@/assets/icons/time.svg"
import { ReactComponent as Search} from "@/assets/icons/search-landing.svg";
import { ReactComponent as Dashboard} from "@/assets/icons/dashboard.svg";
import { ReactComponent as Ebook} from "@/assets/icons/E-book.svg";
import { ReactComponent as Coin} from "@/assets/icons/coin.svg";
import { colors } from '@/assets/styles/theme';

const defaultStyle = {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "25px",
    color: colors.black
};

const descriptionStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "28px",
    color: colors.gray600
}


export const texts = [
    {
        text: '데이터 입력 시간 단축',
        style: Object.assign({marginTop: "47.5px", width: "180px"}, defaultStyle)
    },
    {
        text: '세분화 된 검색으로 빠른 데이터 조회',
        style: Object.assign({marginTop: "35px", width: "157px"}, defaultStyle)
    },
    {
        text: '데이터 기반 대시보드 제공',
        style: Object.assign({marginTop: "47.5px", width: "217px"}, defaultStyle)
    },
    {
        text: '출판사에 최적화 된 E-BOOK 제작 기능',
        style: Object.assign({marginTop: "35px", width: "163px"}, defaultStyle)
    },
    {
        text: '정산서도 글링에서!',
        style: Object.assign({marginTop: "47.5px", width: "160px"}, defaultStyle)
    },
    {
        text: "글링에서<br/>맞춤 정산 서비스를 이용해보세요!",
        style: Object.assign({marginTop: "62.5px", backgroundColor: colors.blue300, width: "157px"}, defaultStyle)
    }
]


export const descriptionTexts = [
    {
        text: '원장부를 그대로 가져와서<br/> 데이터 입력 시간을 줄이세요!',
        style: descriptionStyle
    },
    {
        text: '상세한 검색 필터로 필요한 데이터를<br/> 쉽고 빠르게 조회할 수 있습니다.',
        style: descriptionStyle
    },
    {
        text: '대시보드 데이터를 이용하여<br/> 프로모션을 진행할 수 있습니다.',
        style: descriptionStyle
    },
    {
        text: '글링에서는 정산부터<br/> E-BOOK까지 전부 가능해요',
        style: descriptionStyle
    },
    {
        text: '번거로운 정산서도<br/> 글링에서 조회·발급 하세요!',
        style: descriptionStyle
    },
    {
        text: '',
        style: {}
    }
]

export default function FocusCards() {
    const FocusData = texts;
    const descriptionData = descriptionTexts;
    const svgArray = [Time, Search, Dashboard, Ebook, Coin];
    return (
        <FlexBox>
            {FocusData.map((obj, i) => 
                <FocusCard 
                    key = {i}
                    text={obj.text} 
                    description={descriptionData[i].text}
                    textStyle={obj.style}
                    descriptionStyle={descriptionData[i].style}
                    icon ={svgArray[i]}    
                />
            )}
        </FlexBox>
    )
}

const FlexBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    height: 648px;
    justify-content: space-between;
    align-content: space-between;
`
