import styled, { css } from "styled-components";
import Text from "@/components/atoms/text";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import AdminAccount from "@/components/molecules/mypage/publisher/AdminAccount";
import AdminPublisherProfile from "@/components/molecules/mypage/publisher/AdminPublisherProfile";
import ChangePassword from "@/components/molecules/mypage/ChangePassword";
import InquiryList from "@/pages/support/inquiry/InquiryList";
import EditMyInfo from "@/components/molecules/mypage/EditMyInfo";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { colors } from '@/assets/styles/theme';
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { getPubData, accountAuthAdmin, checkMaster } from "@/pages/mypage/publisher/slice";

export default function PubMyPage() {
    const dispatch = useAppDispatch();
    const { user_id, user_email, user_nickname } = useAppSelector((state: RootState) => state.user);
    const user_id_string  = useAppSelector((state: RootState) => state.pubMyPage.user_id_string);
    const publisher_name = useAppSelector((state: RootState) => state.pubMyPage.pubProfile.publisher_name)
    const isMaster = useAppSelector((state: RootState) => state.pubMyPage.isMaster);
    const [searchParams, setSearchParams] = useSearchParams();  
    const [template, setTemplate] = useState(<EditMyInfo />);  
    const [clickedItemId, setClickedItemId] = useState(0);
    
    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    }

    const menus = ["내 정보 수정", "비밀번호 변경", "내가 남긴 문의", "출판사 프로필 관리", "계정/권한 관리"];
    const notMasterMenus = ["내 정보 수정", "비밀번호 변경", "내가 남긴 문의"]
    const types = ["EditMyInfo", "ChangePassword", "CheckQuestion", "AdminPublisherProfile", "AdminAccount"];

    const clickHandler = (idx:number) => {
        setClickedItemId(idx);
        setSearchParams({ type: types[idx] });
    }

    useEffect(() => {
        dispatch(checkMaster(user_id));
    },[])

    useEffect(() => {
        dispatch(getPubData(user_id));
    }, [dispatch, user_email, publisher_name]);

    useEffect(() => {
        dispatch(accountAuthAdmin(user_id_string));
    },[dispatch, user_id_string, user_nickname]);
    
    useEffect(() => {
        switch (searchParams.get("type")) { 
            case "EditMyInfo":
                setTemplate(<EditMyInfo/>);
                break;
            case "ChangePassword":
                setTemplate(<ChangePassword/>);
                break;
            case "CheckQuestion":
                setTemplate(<InquiryList/>);
                break;
            case "AdminPublisherProfile":
                setTemplate(<AdminPublisherProfile />);
                break;
            case "AdminAccount":
                setTemplate(<AdminAccount />);
                break;
        }
    }, [searchParams.get("type")]);

    useEffect(() => {
        setClickedItemId(types.indexOf(`${searchParams.get("type")}`) === -1 
        ? 0 : types.indexOf(`${searchParams.get("type")}`));
    },[]);
    
    return (
        <SectionLayout>
            <StyledTitleContainer>
                <Text text="My page" customStyle={titleStyled}/>
            </StyledTitleContainer>
            <MainContainer>
                <MenuBar>
                    {isMaster ? 
                        menus.map((m, i) => (
                            <Container 
                                key={i}
                                isClicked={i === clickedItemId ? true : false}
                                onClick={()=>clickHandler(i)}
                            >
                            {m}
                            </Container>
                        ))  
                        : notMasterMenus.map((m, i) => (
                            <Container 
                                key={i}
                                isClicked={i === clickedItemId ? true : false}
                                onClick={()=>clickHandler(i)}
                            >
                            {m}
                            </Container>
                        ))
                    }
                    
                </MenuBar>
                {template}
            </MainContainer>
        </SectionLayout>
    )
}

const StyledTitleContainer = styled.div`
    margin-bottom: 24px;
`

const MenuBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 270px;
`

const MainContainer = styled.div`
    display: flex;
`

const Container = styled.div<{ isClicked:boolean }>`
    display: flex;
    align-items: center;
    width: fit-content;
    height: 40px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    ${({ theme }) => css
        `color: ${ theme.colors.gray600 };
    `}
    ${(props) => props.isClicked && css`
        color: ${ props.theme.colors.blue500 };
    `}
`