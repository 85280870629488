import api from "@/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { StatementState, SpecificationType, Publisher } from "./types";

const initialState: StatementState = {
    specificationType: SpecificationType.Monthly,
    yearmon: "",
    monthList: [],

    publisherList: [],
    allCheck: true,
    currPublisher: {
        id: 0,
        publisher_id: 0,
        publisher_name: "",
        checked: true,
    },
}

export const getMonthList = createAsyncThunk("authorStatement/getMonthList", async (data, { getState, rejectWithValue }) => {
    try {
        const { authorStatement }: any = getState();
        return (await api.post(`/api/settlement/royalty-statement/statementMonth/author/select`, {
            type: authorStatement.specificationType === SpecificationType.Monthly ? "MRS" : "MGS"
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const getPublisherList = createAsyncThunk("authorStatement/getPublisherList", async (data, { getState, rejectWithValue }) => {
    try {
        const { authorStatement }: any = getState();
        return (await api.post(`/api/settlement/royalty-statement/statementPublisher/select`, {
            type: authorStatement.specificationType === SpecificationType.Monthly ? "MRS" : "MGS",
            month: authorStatement.yearmon,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const statementSlice = createSlice({
    name: "authorStatement",
    initialState,
    reducers: {
        initState: (state) => {
            Object.assign(state, initialState);
        },
        setSpecificationType: (state, { payload }) => {
            state.specificationType = payload;
        },
        setYearmon: (state, { payload }) => {
            state.yearmon = payload;
        },
        checkAllPublisherList: (state, { payload }) => {
            state.allCheck = payload;
            for (let p of state.publisherList) {
                p.checked = payload;
            }
        },
        checkPublisher: (state, { payload }) => {
            state.publisherList[payload.idx].checked = payload.checked;
            if (payload.checked) {
                if (state.publisherList.every(a => a.checked)) {
                    state.allCheck = true;
                }
            } else {
                state.allCheck = payload.checked;
            }
        },
        selectPublisher: (state, { payload }) => {
            state.currPublisher = payload;
        },
        setEmptyData: (state) => {
            state.allCheck = true;
            state.publisherList = [];
            state.currPublisher = initialState.currPublisher;
        },
    },
    extraReducers: {
        [getMonthList.fulfilled.type]: (state, { payload }) => {
            state.monthList = payload.data.map((d: { yearmon: string}) => d.yearmon);
            if (payload.data.length) {
                state.yearmon = payload.data[0].yearmon;
            }
        },

        [getPublisherList.fulfilled.type]: (state, { payload }) => {
            state.publisherList = payload.data.map((d: Publisher) => ({
                ...d,
                checked: true
            }));
            if (payload.data.length) {
                state.currPublisher = payload.data[0];
            } else {
                state.currPublisher = initialState.currPublisher;
            }
        },
    }
});

export default statementSlice.reducer;
