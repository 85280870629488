import styled, { css } from "styled-components";
import Text from "@/components/atoms/text";
import Checkbox from "@/components/atoms/input/Checkbox";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { Author, SpecificationType } from "@/pages/settlement/statement/publisher/types";
import useLoading from "@/modules/hooks/useLoading";
import { useSearchParams } from 'react-router-dom';
import { StatementType } from "@/pages/settlement/statement/publisher/types";
import Button from "@/components/atoms/button";
import useDownload from "@/modules/hooks/useDownload";
import useAlert from "@/modules/hooks/useAlert";
import { deleteStatement, getInquiryAuthor } from "@/pages/settlement/statement/publisher/slice";

export default function AuthorListContainer({ selectAuthorHandler }: { selectAuthorHandler: () => void; }) {
    const [searchParams] = useSearchParams();
    const { authorList, allCheck, currAuthor, specificationType, yearmon } = useAppSelector((state: RootState) => state.pubStatement);
    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();
    const statementType = searchParams.get("type");
    const download = useDownload();
    const { alert } = useAlert();

    const authorNameStyle = {
        fontSize: "14px",
        fontWeight: "400",
        margin: "0 0 0 8px",
        lineHeight: "18px"
    }

    const checkAuthor = (e: any, idx: number, checked: boolean) => {
        dispatch({ type: "pubStatement/checkAuthor", payload: {
            // checked: e.target.checked,
            checked: !checked,
            idx
        }})
    }

    const checkAll = (e: any) => {
        dispatch({ type: "pubStatement/checkAllAuthorList", payload: e.target.checked });
    }

    const selectAuthor = async (author: Author) => {
        try {
            await loadingOn();
            dispatch({ type: "pubStatement/selectAuthor", payload: author });
            await selectAuthorHandler();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    const downloadAll = () => {
        const selectedAuthorIds = authorList.filter(a => a.checked).map(a => a.user_id);
        if (selectedAuthorIds.length) {
            if (specificationType === SpecificationType.Monthly) {
                const author_list = selectedAuthorIds.join(",");
                download(`download/royalty-statement/publisher/all?month=${yearmon}&authorList=${author_list}`)
            } else {
                const key_list = authorList.map(a => `${a.id},${a.user_id}`).join("/");
                download(`download/mg-statement/publisher/all?month=${yearmon}&keyList=${key_list}`)
            }
        } else {
            alert("warning", "주의", "선택된 작가가 없습니다.");
        }
    }

    const removeStatement = async () => {
        try {
            const answer = await alert("confirm", "명세서 발급 취소", "선택하신 명세서 발급을 취소 하시겠습니까?");
            if (answer === "Y") {
                await loadingOn();
                await dispatch(deleteStatement()).unwrap();
                await dispatch(getInquiryAuthor()).unwrap();
                alert ("success", "완료", "명세서 발급이 취소되었습니다.");
            }
        } catch (err) {
            alert("error", "오류 발생", "명세서 발급 취소중 오류가 발생하였습니다.");
        } finally {
            await loadingOff();
        }
    }

    return (
        <StyledContainer>
            <Text text={statementType === StatementType.Issue ? "발급대상" : "조회대상"} customStyle={{ fontSize: "14px", fontWeight: "500" }} />
            {Boolean(statementType === StatementType.Inquiry) && 
                <ButtonWrapper>
                    <Button 
                        type="main-outline" 
                        label="발급 취소" 
                        onClickHandler={removeStatement} 
                        customStyle={{ margin: "0 8px 0 0" }}
                    />
                    <Button 
                        type="main-outline" 
                        label="선택 다운로드" 
                        onClickHandler={downloadAll} 
                    />
                </ButtonWrapper>
            }
            <ListWrapper>
                <HeaderWrapper>
                    <Checkbox 
                        type="boolean"
                        active={allCheck}
                        onChangeHandler={checkAll}
                    />
                    <Text text="작가 전체 선택" customStyle={authorNameStyle} />
                </HeaderWrapper>
                <StyledUl>
                    {authorList.map((a, idx) => (
                        <RowWrapper key={a.user_id} selected={a === currAuthor} onClick={() => selectAuthor(a)}>
                            <CheckboxWrapper>
                                <Checkbox 
                                    type="boolean"
                                    active={a.checked}
                                    onChangeHandler={(e) => checkAuthor(e, idx, a.checked)}
                                />
                            </CheckboxWrapper>
                            {specificationType === SpecificationType.Monthly ?
                                <Text text={`${a.user_name} (${a.author_names})`} customStyle={authorNameStyle} /> :
                                <Text text={`${a.out_date}_${a.user_name}(${a.author_name})`} customStyle={authorNameStyle} />
                            }
                        </RowWrapper>
                    ))}
                </StyledUl>
            </ListWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 220px;
    margin-top: 40px;
    margin-right: 20px;
`

const StyledUl = styled.ul`
    display: block;
    max-height: 800px;
    overflow-y: auto;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray400};
`

const RowWrapper = styled.li<{ selected?: boolean }>`
    display: flex;
    width: 100%;
    min-height: 40px;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue50};
        box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.colors.blue50};
    }

    ${({ selected }) => selected && css`
        background-color: ${({ theme }) => theme.colors.blue50};
    `}
`

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 16px;
`

const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 8px;
    border-top: 2px solid ${({ theme }) => theme.colors.gray400};
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray400};
    background-color: ${({ theme }) => theme.colors.gray100};
`

const ListWrapper = styled.div`
    margin-top: 17px;
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: 100%;
`