import styled from "styled-components";
import { useState } from "react";
import BeforeFind from "@/components/molecules/findid/BeforeFind";
import AfterFind from "@/components/molecules/findid/AfterFind";

export default function FindId() {
    const [find, setFind] = useState("");

    function setFindId(email:string) {
        setFind(email);
    }
    return (
        <FindIdContainer>
            <h2 className="title">아이디 찾기</h2>
            {
                find ?
                <AfterFind
                    findId={find}
                />
                :
                <BeforeFind
                    setFind={setFindId}
                />
            }
        </FindIdContainer>
    );
}

const FindIdContainer = styled.div`
    width: 400px;
    height: auto;
    margin: 0 auto;
    padding-top: 80px;
    .title {
        font-weight: 700;
        font-size: 28px;
        text-align: center;
        margin-bottom: 60px;
    }
`;