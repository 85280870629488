import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";
import { EbookMakerState, Step, BookInfoType } from "./types";
import { EbookMaker, Copyright } from "@/types/dataType";

const initialState: EbookMakerState = {
    // Step
    currStep: Step.Form,
    completeStepIdx: Step.Form, // 입력 완료된 step index

    // 1. 제작 형태 선택
    makeType: "",
    inFileType: EbookMaker.InfileType.HWP,
    outFileType: [],
    bookInfo: {
        series_name: "",
        series_name_error: "",
        author_name: "",
        author_name_error: "",
        publisher_name: "",
        isbn: "",
        published_at: ""
    },
    inFiles: [],
    addEnter: false,

    // 판권지
    copyrightType: EbookMaker.CopyrightType.NONE,
    selectedTemplateId: 0,
    contentList: [],
    templateList: [],
    copyrightHTML: "",
    copyrightJSON: [],

    // 2. 제작방식 선택
    subtitleType: "",
    subtitleMakeType: "",
    subtitleSize: Copyright.Size.H3,
    subtitleItalic: false,
    subtitleUnderline: false,
    subtitleAlign: Copyright.Align.LEFT,
    coverFile: [],
    styleFile: [],
    subtitleList: [],
    noCoverImg: false,
    ignoreStyle: false,
    totalSectionCnt: 0,
    tmpFolderName: "",

    // 3. 제작 완료
    resultFileList: [],
    time: 0,
}

// 템플릿 조회
export const getTemplateList = createAsyncThunk("ebookMaker/getTemplateList", async (data: BookInfoType, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/publisher/converter/copyright/select`, {
            title: data.series_name,
            author: data.author_name,
            publisher: data.publisher_name,
            isbn: data.isbn,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 템플릿 삭제
export const deleteTemplate = createAsyncThunk("ebookMaker/deleteTemplate", async (template_id: number, { rejectWithValue }) => {
    try {
        // 삭제
        await api.post(`/api/publisher/converter/copyright/delete`, { templateId: template_id });
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 템플릿 저장 (수정)
export const updateTemplate = createAsyncThunk("ebookMaker/updateTemplate", async (data: any, { rejectWithValue }) => {
    try {
        await api.post(`/api/publisher/converter/copyright/update`, {
            templateId: data.template_id,
            copyrightList: data.contentList
        });
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 템플릿 저장 (신규)
export const saveTemplate = createAsyncThunk("ebookMaker/saveTemplate", async (data: any, { rejectWithValue }) => {
    try {
        return await api.post(`/api/publisher/converter/copyright/insert`, {
            name: data.name,
            copyrightList: data.contentList
        });
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 템플릿 이름 변경
export const editTemplateName = createAsyncThunk("ebookMaker/editTemplateName", async (data: any, { rejectWithValue }) => {
    try {
        await api.post(`/api/publisher/converter/template/update`, {
            templateId: data.templateId,
            templateName: data.templateName
        });
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// json 파일로 변환
export const convert2Json = createAsyncThunk("ebookMaker/covert2Json", async (type: string, { rejectWithValue, getState }) => {
    try { 
        const { ebookMaker }: any = getState();
        const data = await api.post(`/api/publisher/converter/toJson`, {
            makeType: ebookMaker.makeType,
            inFileType: ebookMaker.inFileType,
            outFileType: ebookMaker.outFileType,
            copyrightType: ebookMaker.copyrightType,
            subtitleType: type,
            inFiles: ebookMaker.inFiles
        });
        return {
            ...data.data,
            subtitleType: type
        }
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 최종 출력 파일로 변환
export const convert2File = createAsyncThunk("ebookMaker/convert2File", async (data, { rejectWithValue, getState }) => {
    try {
        const { ebookMaker }: any = getState();
        return (await api.post(`/api/publisher/converter/toFile`, {
            tmpFolderName: ebookMaker.tmpFolderName,
            makeType: ebookMaker.makeType,
            inFileType: ebookMaker.inFileType,
            inFileLength: ebookMaker.inFiles.length,
            outFileType: ebookMaker.outFileType,
            copyrightType: ebookMaker.copyrightType,
            copyrightHTML: ebookMaker.copyrightHTML,
            copyrightJSON: ebookMaker.copyrightJSON,
            subtitleType: ebookMaker.subtitleType,
            subtitles: ebookMaker.subtitleList,
            subtitleStyle: {
                size: ebookMaker.subtitleSize,
                align: ebookMaker.subtitleAlign,
                underline: ebookMaker.subtitleUnderline,
                italic: ebookMaker.subtitleItalic,
            },
            subtitleSettingType: ebookMaker.subtitleMakeType,
            styles: ebookMaker.styleFile,
            ignoreOriginStyle: ebookMaker.ignoreStyle,
            cover: ebookMaker.coverFile,
            bookInfo: ebookMaker.bookInfo,
            addEnter: ebookMaker.addEnter,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const ebookMakerSlice = createSlice({
    name: "ebookMaker",
    initialState,
    reducers: {
        initState: (state, action) => {
            Object.assign(state, initialState);
        },
        setStep: (state, action) => {
            state.currStep = action.payload;
            if (state.completeStepIdx < action.payload) {
                state.completeStepIdx = action.payload;
            }
        },
        setMakeType: (state, action) => {
            state.makeType = action.payload;
        },
        setInFileType: (state, action) => {
            state.inFileType = action.payload;
        },
        setOutFileType: (state, action) => {
            state.outFileType = action.payload.filter((i: any) => i.checked).map((i: any) => i.value);
        },
        setBookInfo: (state, action) => {
            state.bookInfo[action.payload.type]= action.payload.value;
        },
        setAddEnter: (state, action) => {
            state.addEnter = action.payload;
        },
        setInFiles: (state, action) => {
            state.inFiles = state.inFiles.concat(action.payload);
        },
        sortInFile: (state, { payload }) => {
            const { from, to } = payload;
            state.inFiles.splice(to, 0, state.inFiles.splice(from, 1)[0]);
        },
        deleteInFile: (state, action) => {
            state.inFiles.splice(action.payload, 1);
        },
        deleteAllInFiles: (state, action) => {
            state.inFiles = [];
        },
        setCopyrightType: (state, action) => {
            state.copyrightType = action.payload;
        },
        sortCopyrightContent: (state, action) => {
            const { from, to } = action.payload;
            state.contentList.splice(to, 0, state.contentList.splice(from, 1)[0]);
            for (let [idx, c] of state.contentList.entries()) {
                c.order = idx+1;
            }
        },
        setCopyrightContent: (state, action) => {
            const { index, value } = action.payload;
            state.contentList[index].value = value;
        },
        setCopyrightContentCaseOption: (state, action) => {
            const { index, type, to } = action.payload;
            state.contentList[index][type] = to;
        },
        setSelectedTemplate: (state, action) => {
            state.selectedTemplateId = action.payload;
        },
        setCopyrightContentBooleanOption: (state, action) => {
            const { index, type } = action.payload;
            state.contentList[index][type] = !state.contentList[index][type];
        },
        setContentList: (state, action) => {
            for (let t of state.templateList) {
                if (t.template_id === action.payload) {
                    state.contentList = t.contentList;
                    break;
                }
            }
        },
        deleteCopyrightContent: (state, action) => {
            state.contentList.splice(action.payload, 1);
        },
        setCopyrightHTML: (state, action) => {
            state.copyrightHTML = action.payload;
        },
        setCopyrightJSON: (state, action) => {
            state.copyrightJSON = action.payload;
        },
        addContentList: (state, action) => {
            let newId = 0;
            for (let c of state.contentList) {
                if (c.id > newId) newId = c.id;
            }
            state.contentList.push({ 
                ...action.payload, 
                order: state.contentList.length+1,
                id: newId+1
            });
        },
        setSubtitleType: (state, action) => {
            state.subtitleType = action.payload;
        },
        setSubtitleMakeType: (state, action) => {
            state.subtitleMakeType = action.payload;
        },
        setSubtitleSize: (state, action) => {
            state.subtitleSize = action.payload;
        },
        toggleSubtitleItalic: (state, action) => {
            state.subtitleItalic = !state.subtitleItalic;
        },
        toggleSubtitleUnderline: (state, action) => {
            state.subtitleUnderline = !state.subtitleUnderline;
        },
        setSubtitleAlign: (state, action) => {
            state.subtitleAlign = action.payload;
        },
        setIgnoreStyle: (state, action) => {
            state.ignoreStyle = action.payload;
        },
        setSubtitleInput: (state, action) => {
            const { idx, value } = action.payload;
            state.subtitleList[idx].subtitle = value;
        },
        setNoCoverImg: (state, action) => {
            state.noCoverImg = action.payload;
        },
        setCoverFile: (state, action) => {
            state.coverFile = [action.payload];
        },
        deleteCoverFile: (state, action) => {
            state.coverFile = [];  
        },
        setStyleFile: (state, action) => {
            state.styleFile = [action.payload];
        },
        deleteStyleFile: (state, action) => {
            state.styleFile = [];
        },
        setTime: (state, action) => {
            state.time = state.time - 1;
        }
    },
    extraReducers: {
        // 판권지 템플릿 조회
        [getTemplateList.fulfilled.type]: (state, action) => {
            state.templateList = action.payload.data;
            if (!state.contentList.length) {
                state.contentList = action.payload.data[0].contentList;
            }
        },

        // 판권지 템플릿 생성
        [saveTemplate.fulfilled.type]: (state, action) => {
            state.selectedTemplateId = action.payload.data.templateId;
        },

        [convert2Json.fulfilled.type]: (state, action) => {
            state.tmpFolderName = action.payload.data.folderName;
            state.totalSectionCnt = action.payload.data.totalSectionCnt;
            if (action.payload.subtitleType === EbookMaker.SubtitleType.MAKE) {
                for (let i = 0; i < action.payload.data.totalSectionCnt; i++) {
                    state.subtitleList.push({
                        order: i+1,
                        subtitle: ""
                    })
                }
            }
        },

        [convert2File.fulfilled.type]: (state, action) => {
            state.tmpFolderName = action.payload.data.tmpFolderName;
            state.resultFileList = action.payload.data.resultFileList;
            state.time = 179;
        },
    }
});

export default ebookMakerSlice.reducer;