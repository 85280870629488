import React from 'react'
import styled from 'styled-components'
export default function UnderlineText({label, customStyle, onClickHandler}: UnderlineProps) {
    return (
        <StyledWrapper
            fontSize={customStyle?.fontSize}
            lineHeight={customStyle?.lineHeight}
            fontWeight={customStyle?.fontWeight}
            onClick={onClickHandler}
        >
            {label}
        </StyledWrapper>   
    )
}



const StyledWrapper = styled.div<StyleProps>`
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "21px")};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
    color: ${({ theme }) => theme.colors.blue500};
    text-decoration-line: underline;
    cursor: pointer;
`

interface StyleProps {
    fontSize?: string,
    lineHeight?: string,
    fontWeight?: string,
    color?: string,
    textDecorationLine?: string
}

interface UnderlineProps {
    label: string,
    customStyle?: StyleProps,
    onClickHandler: (e: React.MouseEvent<HTMLDivElement>) => void
}