import styled from "styled-components";
import api from "@/api";
import Text from "@/components/atoms/text";
import { colors } from '@/assets/styles/theme';
import camera_blue500 from "@/assets/icons/camera_blue500.svg"; 
import DefaultProfile from "@/assets/icons/defaultProfile.svg";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { pubMyPageActions, updatePubData } from "@/pages/mypage/publisher/slice"
import { ChangeEvent, useEffect, useState } from "react";
import Post from "@/components/molecules/common/post/Post";
import useAlert from "@/modules/hooks/useAlert";
import TextInput from "@/components/atoms/input/TextInput";
import Button from "@/components/atoms/button";
import { base64Encode } from "@/modules/utils/file";
import useLoading from "@/modules/hooks/useLoading";

export default function AdminPublisherProfile() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const pubData = useAppSelector((state: RootState) => state.pubMyPage.pubProfile);
    const { loadingOn, loadingOff } = useLoading();
    
    const [popup, setPopup] = useState(false);
    const [img, setImg] = useState<string|File>("");
    const [imgUrl, setImgUrl] = useState("");
    const [isDefaultImg, setIsDefaultImg] = useState(false);
    const [inputs, setInputs] = useState({
        publisher_name: pubData.publisher_name,
        publisher_ceo_name: pubData.publisher_ceo_name,
        publisher_email: pubData.publisher_email,
        publisher_tel: pubData.publisher_tel,
        publisher_url: pubData.publisher_url,
        publisher_zonecode: pubData.publisher_zonecode,
        publisher_addr: pubData.publisher_addr,
        publisher_addr_detail: pubData.publisher_addr_detail,
        publisher_reg: pubData.publisher_reg,
    });

    const [textArea, setTextArea] = useState({
        publisher_desc: pubData.publisher_desc
    });
    
    useEffect(() => {
        if(pubData.publisher_pic_path === '/user.jpg') {
            setImgUrl(pubData.publisher_pic_path);
        } else {
            imgConvert(pubData.publisher_pic_path);
        }
    },[pubData.publisher_pic_path]);

    useEffect(() => {
        setInputs({
            publisher_name: pubData.publisher_name,
            publisher_ceo_name: pubData.publisher_ceo_name,
            publisher_email: pubData.publisher_email,
            publisher_tel: pubData.publisher_tel,
            publisher_url: pubData.publisher_url,
            publisher_zonecode: pubData.publisher_zonecode,
            publisher_addr: pubData.publisher_addr,
            publisher_addr_detail: pubData.publisher_addr_detail,
            publisher_reg: pubData.publisher_reg,
        });

        setTextArea({
            publisher_desc: pubData.publisher_desc
        });
    },[pubData]);
    
    async function imgConvert(imgPath: string) {
        if(imgPath === '/user.jpg') {
            setImgUrl(imgPath);           
            return;
        }

        const res = await api.get<Blob>(`/file/download${imgPath}`, {responseType: 'blob'});
        setImgUrl(window.URL.createObjectURL(res.data));
    }

    function imageUpload() {
        document.getElementById("selectImage")?.click();
    }

    async function selectImage(e: ChangeEvent<HTMLInputElement>) {
        const file = e.target.files && e.target.files[0];
        
        if (file) {
            if (file.size > 2097152.0) {
                return await alert("warning", "주의", "2Mb 이하 파일만 등록 가능합니다.");
            }
            if (file.type == "image/png" || file.type == "image/jpeg") {
                setImgUrl(window.URL.createObjectURL(file));
                setImg(file);
            } else {
                setImg("");
                return await alert("warning", "주의", ".jpg 또는 .png 파일만 업로드 가능합니다.")
            }
        } else {
            setImg("");
        }
    }

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        setInputs({ ...inputs, [name]: value })
    }

    const onTextAreaChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        setTextArea({
            publisher_desc: value
        })
    }

    async function onCompletePost(data:any) {
        let fullAddress = data.address;
        let extraAddress = '';
        
        if (data.addressType === 'R') {
            if(data.bname !== '') {
                extraAddress += data.bname;
            }
            if(data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        setInputs({ ...inputs, 
                publisher_zonecode: data.zonecode,
                publisher_addr: fullAddress,
                publisher_addr_detail: extraAddress
            })
    };

    const defaultButtonHanlder = async () => {
        setIsDefaultImg(true);
        setImgUrl('/user.jpg');
    }

    const submitHandler = async () => {
        const pic_path = isDefaultImg ? '/user.jpg' 
        : typeof img !== 'string' ? `/publisherpic/${pubData.publisher_id}.${img.type.split("/")[1]} ` : pubData.publisher_pic_path;

        if(inputs.publisher_name === "") {
            await alert("warning", "오류", "출판사명을 입력해주세요.");
            return;
        } else if(inputs.publisher_reg === "") {
            await alert("warning", "오류", "사업자 등록번호를 입력해주세요.");
            return;
        } else if(inputs.publisher_email === "") {
            await alert("warning", "오류", "출판사 이메일을 입력해주세요.");
            return;
        } else if(inputs.publisher_tel === "") {
            await alert("warning", "오류", "대표 전화번호를 입력해주세요.");
            return;
        } else if(inputs.publisher_addr === "") {
            await alert("warning", "오류", "주소를 입력해주세요.");
            return;
        }

        const apiData = {
            ...inputs,
            publisher_status: pubData.publisher_status,
            publisher_desc: textArea.publisher_desc,
            publisher_created_at: pubData.publisher_created_at,
            publisher_id: pubData.publisher_id,
            user_id: pubData.user_id,
            data: img ? await base64Encode(img as File) : null,
            publisher_pic_path: pic_path,
        }
    
        try {
            loadingOn();    
            setIsDefaultImg(false);
            dispatch(pubMyPageActions.tmpUpdatePublisher({apiData}));
            await dispatch(updatePubData(apiData)).unwrap();
            await alert("success", "수정완료 ","정보가 수정되었습니다.");            
        } catch (err: any) {
            await alert("error", "오류발생 ", err.message);
        } finally {
            loadingOff();
        }
    }

    const subContentTitleStyled = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17.5px",
        color: colors.gray600,
    }

    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    }

    const inputStyled = {
        color: colors.black,
        fontSize: '12px',
        fontWeigth: '400',
        width: '400px',
        height: '40px',
        borderColor: colors.gray300,
        borderRadius: '4px',  
        margin: "8px 0px 0px 0px"      
    }

    const buttonStyled = {
        width: '400px',
        height: '40px',
        margin: '0px 0px 8px 0px',
        borderRadius: '4px',
        fontWeight: '500',
        fontSize: '16px',
    }

    const defaultImgButtonStyled = {
        width: '100px',
        height: '32px',
    }

    return (
        <MypageSectionLayout>
            <StyledTitleContainer>
                <Text text="출판사 프로필 관리" customStyle={titleStyled}/>
            </StyledTitleContainer>
            <ContainerStyled>
                <SubContainer>
                    <ProfileImgContainer>
                        <ProfileImg src={imgUrl === '/user.jpg' ? DefaultProfile : imgUrl} alt="프로필"/>
                        <CameraImg src={camera_blue500} onClick={imageUpload} />
                        <DefaultButtonStyled>
                            <Button 
                                type={"gray-outline"} 
                                label="기본 이미지" 
                                customStyle={defaultImgButtonStyled} 
                                onClickHandler={defaultButtonHanlder} 
                            />
                        </DefaultButtonStyled>
                        <HiddenImgInput 
                            id="selectImage"
                            type="file" hidden 
                            onChange={(e) => selectImage(e)} 
                            accept="image/jpeg, image/png" 
                        />
                    </ProfileImgContainer>
                    <SubContentContainer>
                        <Text text="출판사명*" customStyle={subContentTitleStyled} />
                        <TextInput textName='publisher_name' value={inputs.publisher_name} onChangeHandler={onChange} customStyle={inputStyled} />
                    </SubContentContainer>
                    <SubContentContainer>
                        <Text text="사업자 등록번호*" customStyle={subContentTitleStyled} />
                        <TextInput textName='publisher_reg' value={inputs.publisher_reg} onChangeHandler={onChange} customStyle={inputStyled} />
                    </SubContentContainer>
                    <SubContentContainer>
                        <Text text="출판사 이메일*" customStyle={subContentTitleStyled} />
                        <TextInput textName='publisher_email' value={inputs.publisher_email} onChangeHandler={onChange} customStyle={inputStyled} />
                    </SubContentContainer>
                    <SubContentContainer>
                        <Text text="대표 이름" customStyle={subContentTitleStyled} />
                        <TextInput textName='publisher_ceo_name' value={inputs.publisher_ceo_name} onChangeHandler={onChange} customStyle={inputStyled} />
                    </SubContentContainer>
                    <SubContentContainer>
                        <Text text="대표 전화번호*" customStyle={subContentTitleStyled} />
                        <TextInput textName='publisher_tel' value={inputs.publisher_tel} onChangeHandler={onChange} customStyle={inputStyled} />
                    </SubContentContainer>
                    <SubContentContainer>
                        <Text text="홈페이지" customStyle={subContentTitleStyled} />
                        <TextInput textName='publisher_url' value={inputs.publisher_url} onChangeHandler={onChange} customStyle={inputStyled} />
                    </SubContentContainer>
                    <Button type="main" label="저장" onClickHandler={submitHandler} customStyle={buttonStyled} />
                </SubContainer>
                <SubContainer>
                    <SubContentContainer>   
                        <Text text="주소*" customStyle={subContentTitleStyled} />
                        <AdressSearchStyled>
                            <TextInput type="readOnly" textName='publisher_zonecode' value={inputs.publisher_zonecode} onChangeHandler={onChange} customStyle={inputStyled} />
                            <Button
                                type="main-outline"
                                label="주소 검색"
                                customStyle={{ width: "100px",
                                                height: "40px",
                                                margin: "8px 0 0",
                                                fontSize: "16px",
                                                fontWeight: "500" }}
                                onClickHandler={() => setPopup(!popup)}
                             />
                             { popup &&
                                <Post
                                    onCompletePost={onCompletePost}
                                    onClose={() => setPopup(false)}
                                />
                            }
                        </AdressSearchStyled>
                        <TextInput type="readOnly" textName='publisher_addr' value={inputs.publisher_addr} onChangeHandler={onChange} customStyle={inputStyled} />
                        <TextInput textName='publisher_addr_detail' value={inputs.publisher_addr_detail} onChangeHandler={onChange} customStyle={inputStyled} />
                    </SubContentContainer>
                    <SubContentContainer>
                        <Text text="출판사 소개" customStyle={subContentTitleStyled} />
                        <TextAreaStyled name='publisher_desc' value={textArea.publisher_desc} onChange={onTextAreaChange} />
                    </SubContentContainer>
                </SubContainer>
            </ContainerStyled>
        </MypageSectionLayout>
    )
}

const MypageSectionLayout = styled.div`
`

const StyledTitleContainer = styled.div`
    display: flex;
    margin: 0px 0px 24px 0px;
`

const ContainerStyled = styled.div`
    display: flex;
    width: 900px;
    height: 711px;
    justify-content: space-between;
    align-items: center
`

const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ProfileImgContainer = styled.div`
    width: 400px;
    height: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
`

const ProfileImg = styled.img`
    width: 100px;
    height: 100px;
`

const CameraImg = styled.img`
    width: 48px;
    height: 48px;
    position: absolute;
    top: 62px;
    left: 220px;
    cursor: pointer;
`

const HiddenImgInput = styled.input`
`

const DefaultButtonStyled = styled.div`
    position: absolute;
    top: 70px;
    left: 300px;
    cursor: pointer;
`

const SubContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0px 0px 24px 0px;
`

const AdressSearchStyled = styled.div`
    display: flex;
    width: 400px;
    height: 40px;
    align-items:center;
`

const TextAreaStyled = styled.textarea`
    width: 400px;
    height: 215px;
    resize: none;
    margin: 8px 0px 0px 0px;
    border: 1px solid ${colors.gray300};
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
`