import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import Text from "@/components/atoms/text";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import moment from "moment";
import TextInput from "@/components/atoms/input/TextInput";
import { useEffect, useState } from "react";
import Checkbox from "@/components/atoms/input/Checkbox";
import { seriesActions } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import { RatiosData } from "@/pages/settlement/basedata-manage/series/types";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetRatioPeriodModal from "@/components/molecules/common/modal/SetRatioPeriodModal";
import { ratio, Validation } from "@/modules/utils/validate_modify";

export default function SetSettlementRatioModalBody({ from }:SetSettlementRatioBodyProps ) {
    const dispatch = useAppDispatch();
    let [ ratios, allRatios, allCheck ] = useAppSelector((state:RootState) => [
        state.series.ratios,
        state.series.allRatios,
        state.series.allCheck
    ]);
    const [showSetRatioPeriodModal, setShowSetRatioPeriodModal] = useState(false);
    useEffect(()=>{
        dispatch(seriesActions.setSeriesDatas({ key: "allCheck", value: false}));
        dispatch(seriesActions.setSeriesDatas({ key: "allRatios", value: {
            period: [{endYearmon: "999999", startYearmon: "000000", ratio: 0, endYearmonError: ""}],
            ratio: 0,
            platform_name: "전체",
            platform_id: 99999,
            ratioError: ""
        }}));
    }, [dispatch])

    function ratioValidate(e: React.ChangeEvent<HTMLInputElement>, item: RatiosData, index?: number) {
        let value = e.target.value;
        const validateRatio = ratio({ value, error: "ratioError", mandatory: false, field: "정산비율", decimal: false }) as Validation;
        if (validateRatio.ratioError) {
            if (typeof index !== "undefined") {
                dispatch(seriesActions.setRatios({
                    key: "period", value: [{ startYearmon: "000000",
                                             endYearmon: "999999",
                                             ratio: "", }],
                    index: index
                }));
            } else {
                dispatch(seriesActions.setPeriod({
                    key: "period", value: [{ startYearmon: "000000",
                                             endYearmon: "999999",
                                             ratio: "", }]
                }));
            }
        } else {
            if (typeof index !== "undefined") {
                dispatch(seriesActions.setRatios({
                    key: "period", value: [{
                                                startYearmon: "000000",
                                                endYearmon: "999999",
                                                ratio: validateRatio.value,
                                            }],
                    index: index
                }));
            } else {
                dispatch(seriesActions.setPeriod({
                    key: "period", value: [{
                                                startYearmon: "000000",
                                                endYearmon: "999999",
                                                ratio: validateRatio.value,
                                            }]
                }));
            }
        }
        if (typeof index !== "undefined") {
            dispatch(seriesActions.setRatios({
                key: "ratio", value: validateRatio.value, index: index
            }));
            dispatch(seriesActions.setRatios({
                key: "ratioError", value: validateRatio.ratioError, index: index
            }));
        } else {
            dispatch(seriesActions.setPeriod({
                key: "ratio", value: validateRatio.value
            }));
            dispatch(seriesActions.setPeriod({
                key: "ratioError", value: validateRatio.ratioError
            }));
        }
    }

    function checkHandler (e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(seriesActions.setSeriesDatas({
            key: "allCheck", value: e.target.checked
        }))
    }

    function setRatioPeriodModal(idx?:number) {
        let currPeriod;
        let tmpRatio = typeof idx === "number" ? ratios[idx] : allRatios;
        if (
            tmpRatio.period.length === 1 &&
            tmpRatio.period[0].endYearmon === "999999"
        ) {
            currPeriod = [
                {
                    startYearmon: "000000",
                    endYearmon: moment().format("YYYYMM"),
                    ratio: tmpRatio.period[0].ratio,
                    endYearmonError: "",
                    ratioError: "",
                },
                {
                    startYearmon: moment().add(1, "months").format("YYYYMM"),
                    endYearmon: "999999",
                    ratio: tmpRatio.period[0].ratio,
                    endYearmonError: "",
                    ratioError: "",
                },
            ];
        } else if (!tmpRatio.period.length) {
            currPeriod = [
                {
                    startYearmon: "000000",
                    endYearmon: moment().format("YYYYMM"),
                    ratio: "",
                    endYearmonError: "",
                    ratioError: "",
                },
                {
                    startYearmon: moment().add(1, "months").format("YYYYMM"),
                    endYearmon: "999999",
                    ratio: "",
                    endYearmonError: "",
                    ratioError: "",
                },
            ];
        } else {
            currPeriod = JSON.parse(JSON.stringify(tmpRatio.period));
        }
        dispatch(seriesActions.setSeriesDatas({
            key: "currPeriod", value: currPeriod
        }))
        dispatch(seriesActions.setSeriesDatas({
            key: "currIdx", value: idx
        }))
        setShowSetRatioPeriodModal(true);
    }

    function closeModal() {
        setShowSetRatioPeriodModal(false);
    }

    return (
        <>
            <BodyWrap id="setSettlementRatioBody">
                <FieldRow>
                    <ColumnName width="100px" />
                    <ColumnName width="120px">
                        <Text text="플랫폼명" customStyle={{ fontSize: "12px" }}/>
                    </ColumnName>
                    <ColumnName>
                        <Text text="정산비율(%)" customStyle={{ fontSize: "12px", textAlign: "center" }}/>
                    </ColumnName>
                </FieldRow>
                    <ItemRow border={true} active={allCheck}>
                        <Item width="100px" justifyContent="center">
                            <Checkbox  type="boolean" active={allCheck} onChangeHandler={(e) => checkHandler(e)} />
                        </Item>
                        <Item width="120px">
                            <Text
                                text="전체"
                                customStyle={{ fontSize: "14px", fontWeight: "400"}}
                            />
                        </Item>
                        <Item>
                            { allRatios.period.length > 1 ?
                                <TextInput
                                    type="readOnly"
                                    value="기간 별 설정"
                                    onChangeHandler={()=>{}}
                                    customStyle={{ width: "80px", height: "32px", fontSize: "12px", fontWeight: "400", padding: "0 8px" }}
                                />
                                :
                                <ValidateWrapper dataError={allRatios.ratioError} customStyle={{ display: "inline-flex" }}>
                                    <PercentInputText
                                        type="number"
                                        disabled={!allCheck}
                                        inText="%"
                                        value={allRatios.ratio}
                                        placeholder="정산 비율"
                                        customStyle={{ width: "80px", height: "32px", fontSize: "12px", fontWeight: "400", padding: "0 18px 0 0"}}
                                        onChange={(e) => ratioValidate(e, allRatios)}
                                    />
                                </ValidateWrapper>
                            }
                            {/* <Button
                                type={!allCheck ? "disabled" : "main-outline"}
                                disabled={!allCheck}
                                label="기간별 설정"
                                onClickHandler={(e) => setRatioPeriodModal()}
                            /> */}
                            <PeriodTooltipButton
                                all={!allCheck}
                                period={allRatios.period} 
                                onClickFunc={() => setRatioPeriodModal()}
                            />
                        </Item>
                    </ItemRow>
                {ratios.map((ratio:any, i:number) => 
                    <ItemRow key={i}>
                        <Item width="100px" />
                        <Item width="120px">
                            <Text
                                text={ratio.platform_name}
                                customStyle={{ fontSize: "14px", fontWeight: "400"}}
                            />
                        </Item>
                        <Item>
                            {ratio.period.length > 1 ?
                                <TextInput
                                    type="readOnly"
                                    value="기간 별 설정"
                                    onChangeHandler={()=>{}}
                                    customStyle={{ width: "80px", height: "32px", fontSize: "12px", fontWeight: "400", padding: "0 8px" }}
                                />
                                :
                                <ValidateWrapper dataError={ratio.ratioError} customStyle={{ display: "inline-flex" }}>
                                    <PercentInputText
                                        type="number"
                                        disabled={allCheck}
                                        inText="%"
                                        value={ratio.ratio}
                                        placeholder="정산 비율"
                                        customStyle={{ width: "80px", height: "32px", fontSize: "12px", fontWeight: "400", padding: "0 18px 0 0"}}
                                        onChange={(e) => ratioValidate(e, ratio, i)}
                                    />
                                </ValidateWrapper>
                            }
                            {/* <Button
                                type={allCheck ? "disabled" : "main-outline"}
                                disabled={allCheck}
                                label="기간별 설정"
                                onClickHandler={(e) => setRatioPeriodModal(i)}
                            /> */}
                            <PeriodTooltipButton
                                all={allCheck}
                                period={ratio.period}
                                index={i}
                                onClickFunc={() => setRatioPeriodModal(i)}
                            />
                        </Item>
                    </ItemRow>
                    )
                }
            </BodyWrap>
            <ModalPortal>
                <SetRatioPeriodModal
                    show={showSetRatioPeriodModal}
                    close={closeModal}
                    from={from}
                />
            </ModalPortal>
        </>
    )
}

const PeriodTooltipButton = ({all, period, index, onClickFunc}: {all: boolean, period: any[], index?: number, onClickFunc: Function}) => {
    useEffect(() => {
        scrollListener();
    }, [])
    useEffect(() => {
        summaryPeriod();
    }, [period])
    const [summary, setSummary] = useState("");

    const summaryPeriod = () => {
        if (
            period.length === 1 &&
            period[0].endYearmon === "999999"
        ) {
            setSummary(`판매시작 ~ ${moment().format("YYYY.MM")} | ${period[0].ratio}% \n${moment().add(1, "months").format("YYYY.MM")} ~ 판매종료 | ${period[0].ratio}%`);
        } else if (!period.length) {
            setSummary(`입력 된 정산비율이 없습니다`);
        } else {
            let temp = "";
            period.map((p) => {
                if (p.startYearmon === "000000") {
                    temp = `판매시작 ~ ${moment(p.endYearmon).format("YYYY.MM")} | ${p.ratio}%`
                } else if (p.endYearmon === "999999") {
                    temp = temp + `\n ${moment(p.startYearmon).format("YYYY.MM")} ~ 판매종료 | ${p.ratio}%`
                } else {
                    temp = temp + `\n ${moment(p.startYearmon).format("YYYY.MM")} ~ ${moment(p.endYearmon).format("YYYY.MM")} | ${p.ratio}%`
                }
            })
            setSummary(temp);
        }
    }

    const scrollListener = () => {
        let target = document.getElementById("setSettlementRatioBody");
        if (target) target.addEventListener("scroll", () => {
            onMouseLeave();
        });
    }

    const onMouseEnter = () => {
        let target = document.getElementById(`p-${index}`);
        if (target) {
            let targetTop = target.getBoundingClientRect().top;
            let targetRight = target.getBoundingClientRect().right;
            let layout = document.getElementById(`root`)
            let tooltip = document.createElement("span");
            let content = document.createTextNode(summary);
            tooltip.appendChild(content);
            tooltip.setAttribute('id', 'period-summary-tooltip');
    
            tooltip.style.top = targetTop + "px";
            tooltip.style.left = targetRight + 15 + "px";
    
            if (layout) layout.insertBefore(tooltip, null);
        }
    }

    const onMouseLeave = () => {
        let tooltip = document.getElementById("period-summary-tooltip");
        if (tooltip) tooltip.remove();
    }

    return (
        <HoverButton
            id={`p-${index}`}
            disabled={all}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={() => onClickFunc()}
        >
            기간별 설정
        </HoverButton>
    )
}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 348px;
    max-height: 348px;
    overflow: auto;

`
const ItemRow = styled.div<{ border?: boolean, active?: boolean }>`
    display: flex;
    align-items: center;
    height: 60px;
    border-top: ${({ theme, border }) => border ? `1px solid ${theme.colors.gray300}` : ""};
    border-bottom: ${({ theme, border }) => border ? `1px solid ${theme.colors.gray300}` : ""};
    background-color: ${({ theme, active }) => active ? theme.colors.gray50 : ""};
`

const Item = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "200px"};
    height: 60px;
    font-size: 12px;
    padding-left: 8px;
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
`

const ColumnName = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "200px"};
    height: 48px;
    font-size: 12px;
    padding: 8px;
`

const HoverButton = styled.button<{ disabled?: boolean, }>`
    width: 100px;
    height: 32px;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
    color: ${({ disabled, theme }) => (disabled ? theme.colors.gray600 : theme.colors.blue500)};
    border: 1px solid ${({ disabled, theme }) => disabled ? theme.colors.gray200 : theme.colors.blue300 };
    background-color: ${({ disabled, theme }) => disabled ? theme.colors.gray50 : theme.colors.white};
    user-select: none;
`

interface SetSettlementRatioBodyProps {
    from: string
}