import { createSlice } from "@reduxjs/toolkit";

interface Message {
    id?: string,
    type: "information" | "warning" | "success" | "error",
    title: string,
    content?: string,
    link?: string,
}

interface SocketState {
    socketId: string,
    message: Message[],
    messageId: string,
}

const initialUserState: SocketState = {
    socketId: "",
    message: [],
    messageId: "",
};

const socketSlice = createSlice({
    name: "socket",
    initialState: initialUserState,
    reducers: {
        setSocketId(state, action) {
            state.socketId = action.payload;
        },
        setMessagetId(state, action) {
            state.messageId = action.payload;
        },
        setMessage(state, action) {
            let m = action.payload;
            state.message.push({...m, "type": m.type === "info" ? "infomation" : m.type });
        },
        removeMessage(state, action) {
            let index = state.message.findIndex(m => m.id === action.payload);
            state.message.splice(index, 1);
        }
    },
    extraReducers: {
        
    }
});

export const socketActions = socketSlice.actions;
export default socketSlice.reducer;
