import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setLastId, setItems, uploadEtcExcelData } from "@/pages/settlement/sales-entry/etc/etcSlice";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { FileData } from "@/components/molecules/common/modal/ExcelUploadSalesModal";
import usePlatformSetting from "../../common/hooks/usePlatformSetting";

const useEtcExcelUpload = () => {
    const { lastId, platformId } = useSelectorTyped((state: RootState) => state.entryEtc);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const { selectBoxOptions } = usePlatformSetting({ platformId });

    const applyExcelUpload = async (fileData: FileData) => {
        try {
            loadingOn();
            const apiParams = {
                file: fileData.file,
                fileName: fileData.fileName,
                lastId,
            };

            const res = await dispatch(uploadEtcExcelData(apiParams)).unwrap();

            let temp = [];
            for (let d of res.data) {
                for (let option of selectBoxOptions) {
                    if (d.platform_id === option.platform_id) {
                        temp.push(d);
                    }
                }
            }
            
            if (!temp.length) {
                return await alert("warning", "주의", "파일 형식 또는 내용을 확인해주세요.");
            }
            dispatch(setItems(temp));
            dispatch(setLastId(temp.length - 1));
        } catch (err) {
            return await alert(
                "error",
                "오류 발생",
                "업로드중 오류가 발생하였습니다.\n파일 형식 또는 내용이 선택한 플랫폼과 맞는지 확인해주세요.",
            );
        } finally {
            loadingOff();
        }
    };

    return {
        applyExcelUpload,
    };
};
export default useEtcExcelUpload;
