import styled from "styled-components";
import { colors } from "@/assets/styles/theme";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setItem } from "@/pages/settlement/saels-inquiry/publisher/etc/etcSlice";
import DataTable, { Item, TableItemProps } from "@/components/molecules/common/table/DataTable";
import { updateRow } from "@/pages/common/dataTableSlice";

// components
import Text from "@/components/atoms/text";
import TextInput from "@/components/atoms/input/TextInput";
import Tooltip from "@/components/molecules/common/tooltip/Tooltip";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import SelectBox from "@/components/atoms/selectBox/index";
import CustomDatePicker from "@/components/molecules/common/date-picker/CustomDatePicker";
import EtcAuthorSearchBox from "./EtcAuthorSearch";
import EtcTaxAmount from "./EtcTaxAmount";
import EtcPreAmount from "./EtcPreAmount";
import EtcStRatio from "./EtcStRatio";
import EtcTaxType from "@/components/molecules/sales-entry/etc/EtcTaxType";
import EtcReason from "@/components/molecules/sales-entry/etc/EtcReason";

// custom hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";
import usePlatformSetting from "../hooks/usePlatformSetting";

export default function EtcTable() {
    const dispatch = useAppDispatch();
    const { items, fetchLoading } = useSelectorTyped((state: RootState) => state.inquiryEtc);
    const { pageCount, totalVisible } = usePagination({ from: "etc", tableData: items });
    const { selectBoxOptions } = usePlatformSetting();

    const customHeaders = {
        tax_amount: () => (
            <TaxAmountWrapper>
                <Text text="적용 세액" customStyle={textStyle} />
                <Tooltip
                    iconWidth={20}
                    iconHeight={20}
                    textBoxWidth={240}
                    fill={colors.gray600}
                    text="원천세 (국내 거주 개인)의 경우 각 항목에 대한 예상 세액으로
                        실제 정산서 발급 시와는 다를 수 있습니다."
                />
            </TaxAmountWrapper>
        ),
    };

    const columns = {
        // 플랫폼
        platform_name: ({ item, index }: TableItemProps) => {
            const changePlatform = (v: any) => {
                const newItem = { ...item, platform_id: v.platform_id };
                dispatch(setItem({ index, item: newItem }));
                dispatch(updateRow({ itemKey: "etc_id", updatedRow: newItem }));
            };
            return (
                <SelectBox
                    valueKey="platform_id"
                    labelKey="platform_name"
                    options={selectBoxOptions}
                    selected={item["platform_id"]}
                    onClickValueFunc={changePlatform}
                />
            );
        },
        // 입금일자
        in_date: ({ item, index }: TableItemProps) => {
            const changeInDate = (in_date: string) => {
                const newItem = { ...item, in_date };

                if (in_date !== item["in_date"]) {
                    dispatch(updateRow({ itemKey: "etc_id", updatedRow: newItem }));
                }
                dispatch(setItem({ index, item: newItem }));
            };
            return (
                <CustomDatePicker
                    settingDate={item["in_date"]}
                    unsetButton
                    onChange={changeInDate}
                    customStyle={datePickerStyle}
                />
            );
        },
        // 지급일자
        date: ({ item, index }: TableItemProps) => {
            const changeDate = (date: string) => {
                const newItem = { ...item, date };

                if (date !== item["date"]) {
                    dispatch(updateRow({ itemKey: "etc_id", updatedRow: newItem }));
                }
                dispatch(setItem({ index, item: newItem }));
            };
            return <CustomDatePicker settingDate={item["date"]} onChange={changeDate} customStyle={datePickerStyle} />;
        },
        // 저자명
        author_name: ({ item, index }: TableItemProps) => (
            <EtcAuthorSearchBox item={item} index={index} />
        ),
        // 금액
        pre_amount: ({ item, index }: TableItemProps) => (
            <EtcPreAmount item={item} index={index} />
        ),
        // 정산 비율
        st_ratio: ({ item, index }: TableItemProps) => (
            <EtcStRatio item={item} index={index} />
        ),
        // 적용 세목
        tax_type: ({ item, index }: TableItemProps) => {
            const dispatchItem = (newItem:Item) => dispatch(setItem({ index, item: newItem }));
            return <EtcTaxType item={item} changeItem={dispatchItem} />
        },
        // 적용 세액
        tax_amount: ({ item, index }: TableItemProps) => (
            <EtcTaxAmount item={item} index={index} />
        ),
        // 지급 금액
        amount: ({ item, index }: TableItemProps) => {
            const changeAmount = (amount: string) => {
                const newItem = { ...item, amount };
                dispatch(setItem({ index, item: newItem }));
                dispatch(updateRow({ itemKey: "etc_id", updatedRow: newItem }));
            };
            return (
                <TextInput
                    type="readOnly"
                    value={item["amount"]}
                    onChangeHandler={(e) => changeAmount(e.target.value)}
                    customStyle={inputStyle}
                />
            );
        },
        // 지급 사유
        reason: ({ item, index }: TableItemProps) => {
            const dispatchItem = (newItem:Item) => dispatch(setItem({ index, item: newItem }));
            return <EtcReason item={item} changeItem={dispatchItem}/>
        },
    };

    return (
        <>
            <TableContainer width="1600px">
                <DataTable
                    from="etc"
                    loading={fetchLoading}
                    itemKey="etc_id"
                    items={items}
                    headers={headers}
                    customHeaders={customHeaders}
                    columns={columns}
                />
                <ScrollToTopBtn />
            </TableContainer>
            <Pagination pageCount={pageCount} totalVisible={totalVisible} from="etc" />
        </>
    );
}

// 테이블 데이터
const headers = [
    { text: "플랫폼", value: "platform_name", width: "100px" },
    { text: "입금 일자", value: "in_date", width: "80px" },
    { text: "지급 일자", value: "date", width: "100px" },
    { text: "저자명", value: "author_name", width: "180px" },
    { text: "금액(원)", value: "pre_amount", width: "120px" },
    { text: "정산비율(%)", value: "st_ratio", width: "96px" },
    { text: "적용 세목", value: "tax_type", width: "300px" },
    { text: "적용 세액", value: "tax_amount", width: "120px" },
    { text: "지급 금액(원)", value: "amount", width: "120px" },
    { text: "지급 사유", value: "reason", width: "160px" },
];

const inputStyle = {
    fontSize: "12px",
    textAlign: "right",
    width: "100%",
    height: "32px",
};
const textStyle = {
    fontSize: "12px",
    margin: "0 8px 0 0",
};
const datePickerStyle = {
    width: "110px",
};

const TaxAmountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
