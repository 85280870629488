import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { updateRow } from "@/pages/common/dataTableSlice";
import { resetDataTable } from "@/pages/common/dataTableSlice";
import SalesSummary from "@/components/molecules/sales-inquiry/publisher/common/SalesSummary";
import TableOptions from "@/components/molecules/sales-inquiry/publisher/common/TableOptions";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useExcelDownload from "@/modules/hooks/useExcelDownload";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import {
    deleteEtcData,
    getEtcData,
    setItems,
    updateEtcData,
} from "@/pages/settlement/saels-inquiry/publisher/etc/etcSlice";
import { removeComma } from "@/modules/utils/filter";
import { Item } from "@/pages/settlement/saels-inquiry/publisher/etc/types";
import _ from "lodash";

export default function EtcTableTop() {
    const { items, total_cnt, total_amt, reset } = useSelectorTyped((state: RootState) => state.inquiryEtc);
    const { selectedItems, updatedRows } = useSelectorTyped((state: RootState) => state.dataTable);
    const dispatch = useAppDispatch();
    const { setItemsPerPage } = usePagination({ from: "etc" });
    const downloadExcel = useExcelDownload();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    // itemPerPage 변경
    const changeItemPerPage = (itemPerPage: number) => setItemsPerPage(itemPerPage);

    // 업데이트
    const saveData = async () => {
        let tmpUpdatedRows = _.cloneDeep(updatedRows) as Item[];
        let tmpItems = [...items];
        let hasError = false;

        for (let row of tmpUpdatedRows) {
            if (row.tax_type === "E" && row.tax_desc === "") {
                row.taxDescError = "적용 세목을 입력해주세요.";
                hasError = true;
            }
            if (!row.isCoAuthor && row.tax_type === "E" && row.tax_amount === "") {
                row.taxAmountError = "적용 세액을 입력해주세요.";
                hasError = true;
            }
            if (row.isCoAuthor && row.tax_type === "E") {
                for (let j of row.author_tax_info) {
                    if (j.tax_amount === "") {
                        row.taxAmountError = "공저별 설정을 완료해주세요.";
                        hasError = true;
                    }
                }
            }
            if (row.isCoAuthor && row.needCoRatio) {
                row.authorError = "공저간 비율을 설정해주세요.";
                hasError = true;
            }
            if (
                row.authorError ||
                row.amountError ||
                row.reasonError ||
                row.preAmountError ||
                row.stRatioError ||
                row.taxError ||
                row.taxDescError ||
                row.taxAmountError
            ) {
                hasError = true;
            }
            tmpItems = tmpItems.map((item) => (item.etc_id === row.etc_id ? row : item));
        }

        dispatch(setItems(tmpItems));

        if (hasError) return await alert("info", "경고", "조건을 확인해주세요.");

        const answer = await alert("confirm", "저장 확인", "저장 하시겠습니까?");
        if (answer !== "Y") return;
        
        try {
            loadingOn();
            const items = updatedRows.map((d) => ({ ...d, amount: Number(removeComma(d.amount)) }));
            const apiParams = { items };
            await dispatch(updateEtcData(apiParams)).unwrap();
            await alert("info", "저장 완료", "저장 되었습니다.");
            await dispatch(getEtcData()).unwrap();
            dispatch(resetDataTable());
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    // 삭제
    const deleteData = async () => {
        try {
            loadingOn();
            const apiParams = { items: selectedItems };
            const keysToDelete = selectedItems.map((item) => item.etc_id);

            dispatch(updateRow({ itemKey: "etc_id", keysToDelete }));
            await dispatch(deleteEtcData(apiParams)).unwrap();
            await alert("info", "삭제 완료", "삭제가 완료되었습니다");
            await dispatch(getEtcData()).unwrap();
            dispatch(resetDataTable());
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    // 엑셀 다운로드
    const downloadEtcExcel = () => {
        if (items.length > 0) {
            let rows: any[] = [];
            items.forEach((item) => {
                let taxType = "";
                if (item.tax_type === "I") taxType = "원천세(국내 거주 개인)";
                else if (item.tax_type === "E") taxType = "기타";
                else if (item.tax_type === "N") taxType = "해당없음";

                let row = {
                    플랫폼: item.platform_name || "해당없음",
                    "입금 일자": item.in_date || "해당없음",
                    "지급 일자": item.date,
                    저자: item.author_name,
                    금액: item.pre_amount,
                    "정산 비율(%)": item.st_ratio,
                    "적용 세목": taxType,
                    "": item.tax_desc,
                    "적용세액(원)": item.tax_amount,
                    지급금액: item.amount,
                    지급사유: item.reason,
                };
                rows.push(row);
            });
            downloadExcel({ json: rows, excelFileName: "(판매현황조회) 기타 지급금 정산", sheetNames: "기타지급금" });
        } else {
            return alert("info", "조회 완료", "조회된 데이터가 없습니다.");
        }
    };

    return (
        <TableTopContainer>
            <SalesSummary from="etc" total={total_cnt} amountName="지급 합계" amount={Number(total_amt.toFixed(2))} />
            <TableOptions
                from="etc"
                reset={reset}
                changeItemPerPage={changeItemPerPage}
                saveData={saveData}
                deleteData={deleteData}
                downloadExcel={downloadEtcExcel}
            />
        </TableTopContainer>
    );
}

const TableTopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
