import { useState } from "react";
import styled from "styled-components";
import Text from "@/components/atoms/text";

import OnBtn from "@/assets/icons/but-on.svg";
import OffBtn from "@/assets/icons/but-off.svg";

import PlanCardContainer from '@/components/molecules/landing/pricing/PlanCardContainer' 
import { colors } from "@/assets/styles/theme";

export default function PriceGuideContainer() {
    const [onBtn, setBtn] = useState(true);

    const text = '요금 안내';

    const textStyle = {
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "45px",
        color: colors.white
    }

    const onBtnText = '연 결제';
    const offBtnText = '월 결제';

    const btnTextStyle = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "20px",
        color: colors.white
    }

    const changeBtn = () => {
        if (onBtn) setBtn(false);
        else setBtn(true);
    }

    return (
        <StyledPriceGuideContainer>
            <MainBanner>
                <Text text={text} customStyle={textStyle}></Text>
                <BtnBox className="butBox">
                    <Text text={onBtnText} customStyle={btnTextStyle}></Text>
                    {onBtn ? 
                    <BtnImg onClick={changeBtn} src={OnBtn}/>
                    : <BtnImg onClick={changeBtn} src={OffBtn}/>}
                    <Text text={offBtnText} customStyle={btnTextStyle}></Text>
                </BtnBox>
            </MainBanner>
            <PlanCardContainer btnState={onBtn}/>
        </StyledPriceGuideContainer>
    )
}

const StyledPriceGuideContainer = styled.div`
    padding: 0px calc((100% - 1200px) / 2);
    height: 821px;
    margin: 100px 0px;
`

const MainBanner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const BtnBox = styled.div`
    width: 216px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BtnImg = styled.img`
    width: 80px;
    height: 40px;
    margin: 0px 8px
`;

