import styled from 'styled-components'
import { colors } from "@/assets/styles/theme";
import { ReactComponent as ArrowRight } from '@/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';
import { ReactComponent as ZoomIn } from '@/assets/icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from '@/assets/icons/zoom-out.svg';
import React from 'react';

interface Iprops {
    pageNumber: number,
    setPageNumber: (prev: any) => void,
    pageNumInputChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
    numPages: number,
    zoomOutHandler: () => void,
    percentInputRef: React.RefObject<HTMLInputElement>,
    pageRatioChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
    zoomInHandler: () => void,
    ratio: number,
    download: () => void
}

export default function PageTool({
        pageNumber,
        setPageNumber, 
        pageNumInputChangeHandler,
        numPages,
        zoomOutHandler,
        percentInputRef,
        pageRatioChangeHandler,
        zoomInHandler,
        ratio,
        download
     }: Iprops) {

    return (
        <PageToolContainer>
            <PageArrowContainer>
                {pageNumber > 1 ? <ArrowLeft fill={colors.blue500} width="25" height="24" onClick={()=> setPageNumber(()=> pageNumber - 1)}/>
                                : <ArrowLeft fill={colors.black} width="25" height="24" />}
                <InputPageContainer>
                    <InputWrapper placeholder={pageNumber.toString()} value={pageNumber} onChange={pageNumInputChangeHandler}>
                    </InputWrapper>
                    <PageNumText>
                        / {numPages}
                    </PageNumText>
                </InputPageContainer>
                {pageNumber < numPages ? <ArrowRight width="25" height="24" fill={colors.blue500} onClick={() => setPageNumber(() => pageNumber + 1)}/>
                                        : <ArrowRight width="25" height="24" fill={colors.black}/>}
            </PageArrowContainer>
            <PageZoomContainer>
                <ZoomOut fill={colors.gray600} onClick={() => zoomOutHandler()}/>
                <PercentLabel>
                    <InputPercentWrapper 
                        placeholder="100" 
                        ref={percentInputRef} 
                        onChange={pageRatioChangeHandler} 
                        value={Math.round(ratio*100)}>
                    </InputPercentWrapper>
                </PercentLabel>
                <ZoomIn fill={colors.gray600} onClick={() => zoomInHandler()}/>
            </PageZoomContainer>
            <PdfDownload onClick={download}>
                다운로드
            </PdfDownload>
        </PageToolContainer>
    )
}

const PageArrowContainer = styled.div`
    display: flex;
    align-items: center;
`

const PageToolContainer = styled.div`
    width: 900px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const InputPageContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
`;

const InputWrapper = styled.input.attrs({ type: 'text'})`
    width: 32px;
    height: 32px;
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
    text-align: center;
`;

const PageNumText = styled.div`
    margin-left: 8px;
`;

const PageZoomContainer = styled.div`
    display: flex;
    align-items: center;
`;

const PercentLabel = styled.div`
    position: relative;
    &::before {
        content: '%';
        font-size: 10px;
        position:absolute;
        z-index: 2;
        top: 12px;
        right: 22px;
    }
`;

const InputPercentWrapper = styled.input`
    width: 60px;
    height: 32px;
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
    margin: 0 8px;
    padding-right:8px;
    font-size: 10px;
    text-align: center;
    position: relative;
`;

const PdfDownload = styled.button`
    cursor: pointer;
    width: 100px;
    height: 32px;
    border-radius: 4px;
    background-color: ${colors.blue300};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`;
