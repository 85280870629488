import { useEffect } from "react";

// components
import SeriesSearchBar from "@/components/molecules/basedata-manage/SeriesSearchBar";
import OptionButtons from "@/components/molecules/basedata-manage/SeriesOptionButtons";
import SeriesTableContainer from "@/components/molecules/basedata-manage/SeriesTableContainer";

// reducer
import { useAppDispatch } from "@/modules/store";
import { seriesActions, getSeriesData } from "@/pages/settlement/basedata-manage/series/seriesSlice";

// hooks
import useAlert from "@/modules/hooks/useAlert";

export default function Series() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    useEffect(() => {
        getInitDatas();
        return () => {
            dispatch(seriesActions.reset());
        };
    }, [dispatch])

    const getInitDatas = async() => {
        try {
            dispatch(getSeriesData({
                searchType: "all",
                searchKeyword: "",
            })).unwrap();
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    return (
        <>
            <SeriesSearchBar />
            <OptionButtons />
            <SeriesTableContainer />
        </>
    );
}