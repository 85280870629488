import { CommonType } from "@/types/dataType";

export interface PortfolioState {
    loading: boolean,

    // 작품목록
    sortOption: SortOptions;
    viewOption: ViewOptions;
    portfolioList: Portfolio[];

    // 작품 상세
    contentBaseInfo: ContentBaseInfo;
    contentPlatformInfo: ContentPlatformInfo[];
    contentSalesInfo: ContentSalesInfo;
    latestPromotion: LatestPromotion[];
    timelineData: TimelineData;
}

export enum SearchOptions {
    ALL = "all",
    SERIES_NAME = "series_name",
    AUTHOR_NAME = "author_name"
}

export enum SortOptions {
    NEW = "new",
    ABC = "abc"
}

export enum ViewOptions {
    CARD,
    LIST
}

export interface Portfolio {
    author_name: string;
    content_id: number;
    coverPath: string;
    thumbnailTop: string;
    hover: boolean;
    publish_at: string | null;
    publisher_name: string;
    title: string;
}

interface ContentBaseInfo {
    author_name: string;
    content_id: number;
    cover_path: string | null;
    isbn: string | null;
    publish_at: string;
    publisher_name: string;
    series_id: number;
    series_name: string;
}

interface ContentPlatformInfo {
    PP_id: number;
    platform_color: string;
    platform_id: number;
    platform_name: string;
}

interface ContentSalesInfo {
    accumulatedSales: {
        amount: number; 
        quantity: number;
    };
    dailySalesHistory: {
        labels: string[]; 
        salesHistory: { 
            data: number[];
            name: string;
            type: string;
        }[];
    };
    monthlySalesHistory: {
        labels: string[]; 
        salesHistory: { 
            data: number[];
            name: string;
            type: string;
        }[];
    };
}

export interface LatestPromotion {
    icon_path: string;
    platform_id: number;
    platform_name: string;
    promotion_desc: string;
    promotion_end_at: string;
    promotion_name: string;
    promotion_start_at: string;
}

interface TimelineData {
    totalYearmonList: { yearmon: string; isFirst: boolean; active: boolean; }[];
    eachPlatformHistory: EachPlatformHistory[];
}

export interface EachPlatformHistory {
    platform_id: number;
    platform_name: string;
    platform_color: string;
    platform_icon_path: string;
    circulation_period: {
        start_circulate_at: string;
        end_circulate_at: string|null;
    }[];
    timelineData: {
        yearmon: string;
        isEmpty: boolean;
        hover: boolean;
        active: boolean;
        data: {
            isCirculate: boolean;
            name: string;
            desc: string;
            start_at: string;
            end_at: string;
        }[];
    }[];
}







