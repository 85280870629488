import React from "react";
import styled from "styled-components";

function Text({ text, customStyle }: TextProps) {
    return (
        <StyledText 
            fontWeight={customStyle?.fontWeight}
            fontSize={customStyle?.fontSize}
            lineHeight={customStyle?.lineHeight}
            color={customStyle?.color}
            margin={customStyle?.margin}
            textAlign={customStyle?.textAlign}
            padding={customStyle?.padding}
            width={customStyle?.width}
            height={customStyle?.height}
        >
            {text}
        </StyledText>
    );
}

const StyledText = styled.div<StyleProps>`
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : "500"};
    font-size: ${({ fontSize }) => fontSize ? fontSize : "10px"};
    line-height: ${({ lineHeight }) => lineHeight ? lineHeight : ""};
    color: ${({ color }) => color ? color : "#000000"};
    margin: ${({ margin }) => margin ? margin : "0"};
    text-align: ${({ textAlign }) => textAlign ? textAlign : "left"};
    padding: ${({ padding }) => padding ? padding : "0"};
    height: ${({ height }) => height ? height : "auto"};
    width: ${({ width }) => width ? width : "auto"};
`;

interface TextProps {
    text: string;
    customStyle?: StyleProps;
}

interface StyleProps {
    fontWeight?: string; 
    fontSize?: string;
    lineHeight?: string;
    color?: string;
    margin?: string;
    textAlign?: string;
    padding?: string;
    height?: string;
    width?: string;
}

export default Text;
