import MainContainer from "@/components/molecules/introduce/main/MainContainer";
import FocusContainer from "@/components/molecules/introduce/focus/FocusContainer";
import SettlementContainer from "@/components/molecules/introduce/settlement/SettlementContainer";
import PortfolioContainer from "@/components/molecules/introduce/portfolio/PortfolioContainer";
import ReviewContainer from "@/components/molecules/introduce/review/ReviewContainer";
import StartFreeContainer from "@/components/molecules/introduce/startCard/StartFreeContainer";
import PageSlider from '@/components/molecules/introduce/PageSlider'
import Footer from "@/components/molecules/common/footer/Footer";

function Landing() {
    const anchors = ['#page1', '#page2', '#page3', '#page4', '#page5', '#page6', '#footer'];

    return (
        <PageSlider
            anchors={anchors}
            duration='800'
        >
            <MainContainer key="sec1"/>
            <FocusContainer key="sec2"/>
            <SettlementContainer key="sec3"/>
            <PortfolioContainer key="sec4"/>
            <ReviewContainer key="sec5"/>
            <StartFreeContainer key="sec6"/>
            <Footer key="sec7" pathname={'/'}/>
        </PageSlider>
    );
}

export default Landing;
