import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { BulkEnrollData, ErrorList, BulkEnrollState } from './types';
import api from "@/api";

// 데이터 대량 등록하기
export const enrollBulkExcelData = createAsyncThunk("bulkenroll/enrollBulkExcelData", async (apiParams:BulkEnrollData, { rejectWithValue }) => {
	try {
		const res = await api.post(`/api/settlement/excelUpload/bulkEnroll/${apiParams.type}`, apiParams);

		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

// Define the initial state using that type
const initialState: BulkEnrollState = {
	// data: [],
	loading: false,
	error: false,
	
	file: { file: "", fileName: "" },
	type: "monthly",
	errList: [],
}

export const bulkSlice = createSlice({
	name: 'bulk',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		reset: () => {
			return {...initialState};
		},
		setFile: (state, action: PayloadAction<{ file: string, fileName: string}>) => {
			state.file.file = action.payload.file;
			state.file.fileName = action.payload.fileName
		},
		setType: (state, action: PayloadAction<"monthly"|"daily">) => {
			state.type = action.payload;
		},
		setErrList: (state, action: PayloadAction<ErrorList[]>) => {
			state.errList = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(enrollBulkExcelData.pending, (state, action) => {
			// 프로미스반환전
			state.loading = true;
			state.error = false;
		});
		builder.addCase(enrollBulkExcelData.fulfilled, (state: any, action) => {
			//프로미스 이행된상태
			if (action.payload.status === "success") {
			} else {
				state.hasError = true;
			}
			state.loading = false;
		})
		builder.addCase(enrollBulkExcelData.rejected, (state, action) => {
			//프로미스 거부된상태
			state.loading = false;
			state.error = true;
		});
	}
})

export const bulkActions = bulkSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.author

export default bulkSlice.reducer