import styled from "styled-components";

import SalesAnalysisWrapper from "@/components/molecules/dashboard/author/SalesAnalysisWrapper";
import ChartTypeBtnWrapper from "@/components/molecules/dashboard/author/ChartTypeBtnWrapper";
import Text from "@/components/atoms/text";

function SalesAnalysisContainer() {
    return (
        <StyledContainer>
            <TopLineWrapper />
            <SalesAnalysisWrapper />
        </StyledContainer>
    )
}

function TopLineWrapper() {
    const textStyle = {
        fontSize: "20px",
        fontWeight: "700"
    }
    return (
        <StyledTopLineWrapper>
            <Text text="판매 금액 분석" customStyle={textStyle}/>
            <ChartTypeBtnWrapper />
        </StyledTopLineWrapper>
    )
} 

const StyledTopLineWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
`

const StyledContainer = styled.div`
    width: 800px;
    height: 560px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

export default SalesAnalysisContainer;