import styled from "styled-components";
import SignInForm from "@/components/molecules/signin/SignInForm";
import SNSLoginContainer from "@/components/molecules/signin/SNSLoginContainer";
import LoginMenu from "@/components/molecules/signin/LoginMenu";

export default function SignIn() {
    return (
        <SignFormContainer>
            <h2 className="title">로그인</h2>
            <SignInForm />
            <LoginMenu />
            <SNSLoginContainer />
        </SignFormContainer>
    );
}

export const SignFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: auto;
    margin: 0 auto;
    padding-top: 80px;
    .title {
        font-weight: 700;
        font-size: 28px;
        text-align: center;
        margin-bottom: 60px;
    }
`;
