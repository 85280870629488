import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useSearchParams } from 'react-router-dom';

export default function TypeNavBar({ menus, types, customStyle }: { menus: string[], types: string[], customStyle?: { width?: string } }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [clickedItemId, setClickedItemId] = useState(0);
    useEffect(()=>{
      setClickedItemId(types.indexOf(`${searchParams.get("type")}`));
    }, [searchParams])
    
    const clickHandler = (idx:number) => {
        setClickedItemId(idx);
        setSearchParams({ type: types[idx] });
    }
  
    return (
        <MenuWrapper>
            {menus.map((m, i) => (
                <Container
                  key={i}
                  width={customStyle?.width}
                  isClicked={i === clickedItemId ? true : false}
                  onClick={()=>clickHandler(i)}
                >
                  {m}
                </Container>
            ))}
        </MenuWrapper>
    );
}

const MenuWrapper = styled.div`
  display: table;
  background-color: ${({ theme }) => theme.colors.gray50};
`;
const Container = styled.div<{ isClicked: boolean, width?: string }>`
  display: table-cell;
  width: ${({ width }) => width ? width : "300px"};
  height: 40px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray300};
    border-bottom: 1px solid ${theme.colors.black};
    border-radius: 4px 4px 0 0;
    color: ${theme.colors.gray600};
  `}

  ${(props) => props.isClicked && css`
    background-color: ${props.theme.colors.white};
    border: 1px solid black;
    border-bottom: hidden;
    color: ${props.theme.colors.black};
  `}
`;
