import styled from "styled-components";
import Checkbox from "@/components/atoms/input/Checkbox";
import Text from "@/components/atoms/text";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { colors } from "@/assets/styles/theme";
import natsort from "natsort";
import { useState } from "react";
import useAlert from "@/modules/hooks/useAlert";
import { ReactComponent as Upload } from "@/assets/icons/upload.svg";
import { EbookMaker } from "@/types/dataType";
import { base64Encode } from "@/modules/utils/file";
import UploadContainer from "@/components/molecules/common/upload";
import DraggableTable from "./DraggableTable";

export default function FileUploadContainer() {
    const { addEnter, outFileType, inFiles, inFileType } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();
    const [ active, setActive ] = useState(false);
    const { alert } = useAlert();

    const onDragover = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setActive(true);
        e.dataTransfer.dropEffect = "move";
    }

    const onDragleave = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setActive(false);
    }

    const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const event = {
            target: {
                files: e.dataTransfer.files
            }
        }
        inputFile(event);
        setActive(false);
    }

    const checkAddEnter = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "ebookMaker/setAddEnter", payload: e.target.checked });
    }

    const inputFile = async (e: any) => {
        if (e.target.files?.length) {
            const fileList = [];

            for (let i = 0; i < e.target.files.length; i++) {
                // unique한 id값 설정
                let maxId = 0;
                if (inFiles.length) {
                    for (let f of inFiles) {
                        if (f.id > maxId) {
                            maxId = f.id;
                        }
                    }
                }
                maxId++;

                const ext = e.target.files[i].name.split(".").pop();
                if (ext === inFileType.toLowerCase()) {
                    fileList.push({
                        id: maxId,
                        name: e.target.files[i].name,
                        file: String(await base64Encode(e.target.files[i]))
                    })
                } else {
                    return await alert("warning", "주의", `.${inFileType.toLowerCase()} 파일만 업로드 가능합니다.`);
                }
            }

            // 파일명 기준으로 정렬
            const sorter = natsort();
            fileList.sort((a, b) => sorter(a.name, b.name));
            dispatch({ type: "ebookMaker/setInFiles", payload: fileList });
        }
    }
    const uploadHandler = (fileList: { name: string, file: any, id?: number }[]) => {
        // unique한 id값 설정
        let id = 1;
        for (let f of fileList) {
            f.id = id;
            id++;
        }
        dispatch({ type: "ebookMaker/setInFiles", payload: fileList });
    }

    const checkboxLabelText = {
        fontSize: "13px",
        fontWeight: "400",
        padding: "0 0 2px 8px"
    }

    const headerText = {
        fontSize: "14px",
        fontWeight: "500",
        padding: "8px"
    }

    let fileUploadWrapper;
    if (inFiles.length) {
        fileUploadWrapper = (
            <HasFileWrapper 
                onDrop={(e) => onDrop(e)}
                onDragOver={(e) => onDragover(e)}
                onDragLeave={(e) => onDragleave(e)}
                active={active}
            >
                <DraggableTable 
                    list={inFiles}
                />
            </HasFileWrapper>
        )
    } else {
        fileUploadWrapper = (
            <UploadContainer 
                accept={[inFileType]}
                multiple={true}
                uploadHandler={uploadHandler}
                height={128}
            />
        )
    }

    return (
        <StyledContainer>
            <FieldWrapper>변환할 파일 업로드</FieldWrapper>
            <ContentWrapper>
                <StyledHeader>
                    <EmptyDiv />
                    <Text text="순서" customStyle={{ ...headerText, width: "50px", textAlign: "center" }} />
                    <Text text="파일명" customStyle={{ ...headerText, padding: "8px 12px" }} />
                </StyledHeader>
                {fileUploadWrapper}
                {Boolean(outFileType.includes(EbookMaker.OutfileType.TXT)) &&(
                    <CheckboxWrapper>
                        <Checkbox type="boolean" active={addEnter} onChangeHandler={checkAddEnter} />
                        <Text text="TXT 파일 단락사이 간격 추가" customStyle={checkboxLabelText} />
                    </CheckboxWrapper>) 
                }    
                {Boolean(inFiles.length) && (
                    <label htmlFor="fileUploadInput">
                        <input
                            type="file"
                            id="fileUploadInput"
                            multiple={true}
                            accept={`.${inFileType}`}
                            style={{ display: "none" }}
                            onChange={e => inputFile(e)}
                        />
                        <FileUploadButton>
                            파일 업로드
                            <Upload fill={colors.blue500} />
                        </FileUploadButton>
                    </label>
                )}
            </ContentWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
`

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    width : 270px;
    padding: 24px;
    margin-right: 23px;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid ${colors.gray300};
`

const ContentWrapper = styled.div`
    width: 900px;
    padding: 16px 0;
`

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 12px 24px;
`

const StyledHeader = styled.div`
    display: flex;
    height: 40px;
`

const EmptyDiv = styled.div`
    width: 40px;
    height: 100%;
`

const HasFileWrapper = styled.div<{ active: boolean; }>`
    background-color: ${({ active }) => active ? colors.blue50 : "inherit"};
`

const FileUploadButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border: 1px solid ${colors.blue500};
    color: ${colors.blue500};
    cursor: pointer;
`