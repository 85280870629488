import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { inquiryDailyActions } from "@/pages/settlement/saels-inquiry/publisher/daily/dailySlice";

// components
import DataTable, { TableItemProps, TableProps } from "@/components/molecules/common/table/DataTable";
import Text from "@/components/atoms/text/index";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import Pagination from "@/components/molecules/common/pagination/Pagination";

// custom hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";

// utils
import { addComma } from "@/modules/utils/filter";

export default function DailyTable() {
    const { items, fetchLoading } = useSelectorTyped((state: RootState) => state.inquiryDaily);
    const dispatch = useAppDispatch();
    const { pageCount, totalVisible } = usePagination({ from: "daily-inquiry" });

    const columns = {
        sum_qty: ({ item }: TableItemProps) => <Text text={item["sum_qty"]} customStyle={TextStyle} />,
        sum_amt: ({ item }: TableItemProps) => <Text text={addComma(item["sum_amt"])} customStyle={TextStyle} />
    };

    const changeSortOptions:TableProps["onGetSortOptions"] = (sortBy, sortDir) => {
        dispatch(inquiryDailyActions.setSortOptions({ sortBy, sortDir }));
    };

    return (
        <>
            <TableContainer>
                <DataTable
                    from="daily-inquiry"
                    loading={fetchLoading}
                    itemKey="id"
                    items={items}
                    headers={headers}
                    columns={columns}
                    onGetSortOptions={changeSortOptions}
                />
                <ScrollToTopBtn />
            </TableContainer>
            <Pagination 
                from="daily-inquiry"
                pageCount={pageCount} 
                totalVisible={totalVisible} 
            />
        </>
    );
}

// 테이블 데이터
const headers = [
    { text: "판매일자", value: "date", width: "150px" },
    { text: "판매플랫폼", value: "platform_name", width: "150px" },
    { text: "시리즈명", value: "series_name", width: "350px" },
    { text: "저자명", value: "author_name", width: "170px" },
    { text: "판매량", value: "sum_qty", width: "150px" },
    { text: "판매금액(원)", value: "sum_amt", width: "150px" },
];

const TextStyle = {
    fontSize: "12px",
    textAlign: "right",
    fontWeight: "400",
    padding: "8px",
};