import { addComma } from "@/modules/utils/filter";
import styled from "styled-components";

export default function SalesSummary({ total, amountName, amount }: SalesSummaryProps) {
    return (
        <SummaryContainer>
            <SummaryWrapper>
                <TotalLabel>전체</TotalLabel>
                <TotalSum>{total || ` - `}건</TotalSum>
            </SummaryWrapper>
            <SummaryWrapper>
                <TotalLabel>{amountName}</TotalLabel>
                <TotalSum>{amount ? addComma(amount) : `- `}원</TotalSum>
            </SummaryWrapper>
        </SummaryContainer>
    );
}

const SummaryContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const SummaryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    & + & {
        margin-left: 16px;
    }
`;
const TotalLabel = styled.div`
    color: ${({ theme }) => theme.colors.gray600};
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
    white-space: nowrap;
`;
const TotalSum = styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
`;

interface SalesSummaryProps {
    total: number;
    amountName: string;
    amount: number;
}
