import { useState, useEffect } from "react";
import { colors } from "@/assets/styles/theme";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setFilterOption, resetStore } from "@/pages/settlement/saels-inquiry/author/monthly/monthlySlice";

// components
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import FilterContainer from "@/components/molecules/common/searchFilter/FilterContainer";
import MonthlySearchBar from "@/components/molecules/sales-inquiry/author/monthly/MonthlySearchBar";
import DateSelector from "@/components/molecules/common/form/DateSelector";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";

// custom hooks
import useTagController from "@/components/molecules/sales-inquiry/author/useTagController";
import useFilterSetting from "@/components/molecules/sales-inquiry/author/hooks/useFilterSetting";

// utils
import { getThisMonth } from "@/modules/utils/date";

// type
import type { CommonType } from "@/types/dataType";

export default function MonthlySearchFilter() {
    const { totalDate } = useSelectorTyped((state: RootState) => state.inquiryAuthorMonthly);
    const dispatch = useAppDispatch();
    const [reset, setReset] = useState(false);
    const { platformList, publisherList, authorList, seriesList, platformIds, publisherIds, authorIds, seriesIds } = useFilterSetting();
    const { showMoreTag, handleClickTag } = useTagController(["platform", "publisher", "author", "series"]); // tagfiler 여러개 제어
    const initialtDate = getThisMonth();

    // 초기화
    useEffect(() => {
        return () => { dispatch(resetStore()); };
    }, []);

    useEffect(() => {
        dispatch(
            setFilterOption({
                selectedPlatform: platformIds,
                selectedPublisher: publisherIds,
                selectedSeries: seriesIds,
                selectedAuthor: authorIds,
            }),
        );
    }, [platformList, publisherList, authorList, seriesList]);

    // 리셋
    useEffect(() => {
        if (reset) setReset(false);
    }, [reset]);

    const clickResetButton = () => {
        setReset(true);
        dispatch(
            setFilterOption({
                totalDate: initialtDate,
                selectedPlatform: platformIds,
                selectedPublisher: publisherIds,
                selectedAuthor: authorIds,
                selectedSeries: seriesIds,
            }),
        );
    };

    const setTotalDate = (totalDate: string) => dispatch(setFilterOption({ totalDate }));

    const changePlatformTag = (platformList: CommonType.Id[]) =>
        dispatch(setFilterOption({ selectedPlatform: platformList }));

    const changePublisherTag = (publisherList: CommonType.Id[]) =>
        dispatch(setFilterOption({ selectedPublisher: publisherList }));

    const changeAuthorTag = (authorList: CommonType.Id[]) => dispatch(setFilterOption({ selectedAuthor: authorList }));

    const changeSeriesTag = (seriesList: CommonType.Id[]) => dispatch(setFilterOption({ selectedSeries: seriesList }));

    return (
        <FilterContainer>
            <FilterRow
                label="검색"
                filterContent={[<MonthlySearchBar reset={reset} />]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="정산 월"
                filterContent={[
                    <DateSelector onChangeHandler={setTotalDate} initialDate={totalDate} />,
                    <ResetFilter onClick={clickResetButton} />,
                ]}
            />
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMoreTag.platform, onClick: () => handleClickTag("platform") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={platformList}
                        keyName="id"
                        valueName="platform_name"
                        onChange={changePlatformTag}
                        showMore={showMoreTag.platform}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="출판사"
                plusIcon={{ showPlusIcon: showMoreTag.publisher, onClick: () => handleClickTag("publisher") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={publisherList}
                        keyName="publisher_id"
                        valueName="publisher_name"
                        onChange={changePublisherTag}
                        showMore={showMoreTag.publisher}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="저자명"
                plusIcon={{ showPlusIcon: showMoreTag.author, onClick: () => handleClickTag("author") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={authorList}
                        keyName="id"
                        valueName="name"
                        onChange={changeAuthorTag}
                        showMore={showMoreTag.author}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="시리즈명"
                plusIcon={{ showPlusIcon: showMoreTag.series, onClick: () => handleClickTag("series") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={seriesList}
                        keyName="id"
                        valueName="series_name"
                        onChange={changeSeriesTag}
                        showMore={showMoreTag.series}
                        reset={reset}
                    />,
                ]}
            />
        </FilterContainer>
    );
}
