import { RootState, useAppSelector } from "@/modules/store";
import styled from "styled-components";
import { ReactComponent as Download } from "@/assets/icons/download.svg";
import { colors } from "@/assets/styles/theme";

export default function FileListTable() {
    const { resultFileList, tmpFolderName, time } = useAppSelector((state: RootState) => state.ebookMaker);

    const downloadFile = (fileName: string) => {
        if (time > 0) {
            const url = `download/newEpub?fileName=${fileName}&tmpFolder=${tmpFolderName}`;
            let link = document.createElement("a");
            document.body.appendChild(link);
            link.download = fileName;
            link.href = `/file/${url}`;
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <StyledTable>
            <StyledThead>
                <tr>
                    <OrderField>순서</OrderField>
                    <FileTypeField>파일형식</FileTypeField>
                    <FileNameField align="left">파일명</FileNameField>
                    <DownloadField></DownloadField>
                </tr>
            </StyledThead>
            <StyledTbody>
                {resultFileList.map((fileName, idx) => (
                    <StyledTr key={idx}>
                        <OrderField>{idx+1}</OrderField>
                        <FileTypeField>{fileName.split(".")?.pop()?.toUpperCase()}</FileTypeField>
                        <FileNameField align="left">{fileName}</FileNameField>
                        <DownloadField onClick={() => downloadFile(fileName)}>
                            {time > 0 ? 
                                <StyledDownload fill={colors.blue500} /> :
                                <Download fill={colors.gray300} />
                            }
                        </DownloadField>
                    </StyledTr>
                ))}
            </StyledTbody>
        </StyledTable>
    )
}

const StyledTable = styled.table`
    width: 100%;
`

const StyledThead = styled.thead`
    width: 100%;
    border-top: 2px solid ${({ theme }) => theme.colors.gray300};
    height: 48px;
    font-size: 12px;
    font-weight: 500;
`

const StyledTbody = styled.tbody`
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray300};
    td {
        font-size: 14px;
        font-weight: 400;
    }
`

const StyledTr = styled.tr`
    height: 60px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
`

const StyledTd = styled.td<{ align?: string; }>`
    padding: 0 8px;
    vertical-align: middle;
    text-align: ${({ align }) => align ? align : "center"};
`

const OrderField = styled(StyledTd)`
    width: 48px;
`

const FileTypeField = styled(StyledTd)`
    width: 80px;
`

const FileNameField = styled(StyledTd)`
    width: 1012px;
`

const DownloadField = styled(StyledTd)`
    width: 60px;
`

const StyledDownload = styled(Download)`
    cursor: pointer;
`
