import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { calcPeriod } from "@/modules/utils/date";
import type { EtcState } from "./types";
import type { AtcmpParams } from "@/types/autoComplete";

const name = "inquiry/author/etc";
const initialState: EtcState = {
    items: [],
    total_amt: 0,
    total_cnt: 0,

    initialDataLoaded: false,
    fetchLoading: false,

    changedParamsId: 0,
    startDate: calcPeriod("thisMonth").startDate || "",
    endDate: calcPeriod("thisMonth").endDate || "",
    selectedAuthor: [],
    selectedPublisher: [],
    startAmount: 0,
    endAmount: 0,
    reason: "",
    
    startDateError: "",
    endDateError: "",

    sliderMinValue: 0,
    sliderMaxValue: 0,

    // 자동완성
    atcmpList: [],
};

// 정산 조회
export const getAuthorEtcData = createAsyncThunk(`${name}/getAuthorEtcData`, async (_, { rejectWithValue, getState, signal }) => {
        try {
            const { inquiryAuthorEtc }: any = getState();
            const apiParams = {
                startDate: inquiryAuthorEtc.startDate,
                endDate: inquiryAuthorEtc.endDate,
                selectedAuthor: inquiryAuthorEtc.selectedAuthor,
                selectedPublisher: inquiryAuthorEtc.selectedPublisher,
                startAmount: inquiryAuthorEtc.startAmount,
                endAmount: inquiryAuthorEtc.endAmount,
                reason: inquiryAuthorEtc.reason,
            }
            return (await api.post(`/api/settlement/newSalesInquiry/etc/author/select`, apiParams, { signal })).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 자동완성 조회 - 지급 사유
export const getAtcmpByAuthorEtc = createAsyncThunk(
    `${name}/getAtcmpByAuthorEtc`,
    async (apiParams: AtcmpParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSearchKeyword/getAtcmp/author/reason/select", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const etcSlice = createSlice({
    name,
    initialState,
    reducers: {
        setFilterOption(state, action: PayloadAction<Partial<EtcState>>) {
            const {
                reason,
                startDate,
                endDate,
                selectedAuthor,
                selectedPublisher,
                startAmount,
                endAmount,
            } = action.payload;

            state.reason = reason ?? state.reason;
            state.startDate = startDate ?? state.startDate;
            state.endDate = endDate ?? state.endDate;
            state.selectedAuthor = selectedAuthor ?? state.selectedAuthor;
            state.selectedPublisher = selectedPublisher ?? state.selectedPublisher;
            state.startAmount = startAmount ?? state.startAmount;
            state.endAmount = endAmount ?? state.endAmount;
        },
        setError(state, action) {
            const { startDateError, endDateError } = action.payload;
            state.startDateError = startDateError ?? state.startDateError;
            state.endDateError = endDateError ?? state.endDateError;
        },
        setParamsId(state){
            state.changedParamsId += 1;
        },
        setNoData(state) {
            state.items = [];
            state.total_cnt = 0;
            state.total_amt = 0;
        },
        resetStore: () => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAuthorEtcData.pending, (state) => {
                state.fetchLoading = true;
            })
            .addCase(getAuthorEtcData.fulfilled, (state, action) => {
                state.fetchLoading = false;
                state.initialDataLoaded = true;

                const { result, total_cnt, total_amt, sliderMinValue, sliderMaxValue } = action.payload.data;

                state.items = result;
                state.total_cnt = total_cnt;
                state.total_amt = Number(total_amt.toFixed(2));

                // 금액 필터 값 있을 때 기존 min, max값 유지
                if (state.startAmount || state.endAmount) return;

                state.sliderMinValue = sliderMinValue;
                state.sliderMaxValue = sliderMaxValue;
            });

        // 자동완성 - 지급사유
        builder.addCase(getAtcmpByAuthorEtc.fulfilled, (state, action) => {
            state.atcmpList = action.payload.data;
        });
    },
});

export const { setFilterOption, setError, setParamsId, setNoData, resetStore } = etcSlice.actions;
export default etcSlice.reducer;
