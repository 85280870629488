/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, ReactElement, useRef, Fragment } from "react";
import styled from "styled-components";

// assets
import { colors } from "@/assets/styles/theme";
import { ReactComponent as UpArrow } from "@/assets/icons/up_arrow.svg";

// components
import Checkbox from "@/components/atoms/input/Checkbox";
import Skeleton from "@/components/molecules/common/loading/TableSkeleton";

// modules
import { useAppDispatch } from "@/modules/store";
import { resetDataTable } from "@/pages/common/dataTableSlice";

// hooks
import useCheckbox from "./hooks/useCheckbox";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import EmptyDataTable from "./EmptyDataTable";
import useDataTableSort, { SortDirType } from "./hooks/useDataTableSort";
import ModalPortal from "../modal/Portal";
import DailyDetailModal from "../modal/DailyDetailModal";

export default function DataTable({
    from = "",
    itemKey = "id",
    items: propsItems,
    headers,
    customHeaders = {}, // 헤더에 기본 문자열이 아닌 커스텀 컴포넌트가 들어가야할 경우
    columns = {},
    loading = false,

    onGetSortOptions, // 일별 조회처럼 테이블 정렬 정보 필요할 때
    options = {
        noSort: false,
        showCheckbox: true,
    },
}: TableProps) {
    const dispatch = useAppDispatch();

    const { page, itemsPerPage } = usePagination({ from });
    
    // 페이지에 따라 보여질 데이터 처음, 끝 인덱스 값
    const sliceIndex1 = (page - 1) * itemsPerPage;
    const sliceIndex2 = sliceIndex1 + itemsPerPage;
    let items:Item[] = propsItems.map((item, index) => ({ ...item, originIndex:index })); // 테이블에 보여질 items
    // 테이블 아이템 순서 배열 : 정렬 시 화면 업데이트를 위해 따로 지정 (테이블에 보여질 items는 일반 변수로 두어야 한글 조합이 가능함)
    const [itemsOrder, setItemsOrder] = useState<number[]>([]);

    // 테이블 정렬 훅
    const { sortBy, sortDir, sorting } = useDataTableSort({ items, setItemsOrder, options });
    // 테이블 체크박스 훅
    const { selectedKeys, selectItem, selectAll, checkSelected, checkSelectedAll } = useCheckbox({
        chunkItem: items.slice(sliceIndex1,sliceIndex2),
        itemKey
    });

    // 테이블 Expanded(detail) Row
    const [showModal, setShowModal] = useState(false);
    const clickedRowId = useRef(0);
    const clickRow = (originIndex: number) => { 
        if (from !== 'daily-inquiry') return;
        else {
            setShowModal(true); 
            clickedRowId.current = originIndex;
        }
    }

    useEffect(() => {
        changeOrder();
    }, [propsItems])

    useEffect(() => {
        // 데이터 새로 받아온 경우
        if (!loading) {
            dispatch(resetDataTable());
            setItemsOrder(Array.from(Array(propsItems.length || 1).keys()));
            return;
        }
    }, [loading])
    
    useEffect(() => {
        // 정렬 옵션 변경시 일별 조회 값 변경
        onGetSortOptions && onGetSortOptions(sortBy, sortDir);
    }, [sortBy, sortDir]);

    useEffect(() => {
        return () => { dispatch(resetDataTable()); }
    }, []);

    const changeOrder = () => {
        if (propsItems.length !== itemsOrder.length) {
            if (!sortBy) {
                setItemsOrder(Array.from(Array(propsItems.length || 1).keys()));
                return;
            }
            if (propsItems.length < itemsOrder.length) {
                sorting(sortBy, false);
            } else {
                // 기존 itemsOrder 뒤에 인덱스만 추가
                setItemsOrder((prev) => {
                    const length = propsItems.length - prev.length;
                    return [...prev, ...Array.from({ length }, (_,i) => i + length)]
                });
            }
        } else if (sortBy && from !== 'daily-inquiry') {
            // 입력으로 items 변경시 재정렬. 일별 조회는 서버에서 정렬
            sorting(sortBy, false);
        }
    }
    
    /* 
    #=========================================#
    | 테이블 컴포넌트                             |
    #=========================================# 
    */
    if (loading) return <Skeleton />;
    if (!propsItems.length) {
        return <EmptyDataTable headers={headers} customHeaders={customHeaders} />;
    }
    return (
        <>
            <Table>
                <colgroup span={headers.length + 1} style={{ minWidth: "50px" }}></colgroup>
                <Header>
                    <HeaderTr noSort={options.noSort}>
                        {/* 헤더 체크박스 */}
                        <CheckboxTh show={options.showCheckbox}>
                            <Checkbox type="boolean" active={checkSelectedAll()} onChangeHandler={selectAll} />
                        </CheckboxTh>
                        {customHeaders["customCheckbox"] && customHeaders["customCheckbox"]()}
                        {/* 헤더 컬럼 */}
                        {headers.map((header) => (
                            <Th
                                key={header.text}
                                width={header.width}
                                sort={header.sort}
                                onClick={() => (header.sort === false ? {} : sorting(header.value, true))}
                            >
                                <ThContent textAligns={header.textAlign}>
                                    <ThContentHeader>
                                        {customHeaders[header.value] ? customHeaders[header.value]() : header.text}
                                    </ThContentHeader>
                                    <ThContentUpArrow sortDir={sortBy === header.value ? sortDir : "none"}>
                                        <UpArrow fill={colors.red600} />
                                    </ThContentUpArrow>
                                </ThContent>
                            </Th>
                        ))}
                    </HeaderTr>
                </Header>
                <Body>
                    {itemsOrder.slice(sliceIndex1, sliceIndex2).map((originIndex) => {
                            if (!items[originIndex]) return <></>;
                            return (
                                <Fragment key={items[originIndex][itemKey]}>
                                    <Tr
                                        selectedRow={checkSelected(items[originIndex][itemKey])}
                                        hasDetails={from === 'daily-inquiry' ? true : false}
                                        onClick={() => clickRow(originIndex)}
                                    >
                                        <CheckboxTd show={options.showCheckbox}>
                                            <Checkbox
                                                type="object"
                                                selected={selectedKeys}
                                                valueName={itemKey}
                                                value={items[originIndex][itemKey]}
                                                onChangeHandler={() => selectItem(items[originIndex])}
                                            />
                                        </CheckboxTd>
                                        {headers.map((header, index) => (
                                            <TDItem 
                                                key={index}
                                                header={header} 
                                                columns={columns}
                                                item={items[originIndex]} 
                                                idx={originIndex}
                                            />
                                        ))}
                                    </Tr>
                                </Fragment>
                            );
                        })}
                </Body>
            </Table>
            {/* 일별 조회 세부 데이터 모달 */}
            {from === 'daily-inquiry' && items[clickedRowId.current] && (
                <ModalPortal>
                    <DailyDetailModal 
                        from='daily'
                        show={showModal}
                        items={items[clickedRowId.current].detail_series}
                        close={() => setShowModal(false)}
                    />
                </ModalPortal>
            )}
        </>
    );
}

function TDItem({ header, item, columns, idx }:TDItemProps) {
    let comp: ReactElement;
    if (columns && columns[header.value]) {
        comp = columns[header.value]({ item, index: idx });
    } else if (item[header.value] === undefined 
            || item[header.value] === null 
            || typeof item[header.value] === 'object') 
    {
        comp = <div></div>;
    } else {
        comp = item[header.value];
    }

    return <Td key={header.value} className={header.value}>{comp}</Td>;
}

interface TDItemProps {
    header: HeaderType;
    item: Item;
    columns: TableProps["columns"];
    idx: number;
}

const Table = styled.table`
    width: 100%;
    font-size: 12px;
`;
const Header = styled.thead`
    border-top: 1px solid ${({ theme }) => theme.colors.gray600};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
    height: 48px;
    Tr {
        border-bottom: none;
    }
`;
const Body = styled.tbody`
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
`;
const Tr = styled.tr<{ hasDetails?: boolean; selectedRow?: boolean }>`
    cursor: ${({ hasDetails }) => (hasDetails ? "pointer" : "default")};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
    :nth-last-child(1) {
        border-bottom: none;
    }
    background-color: ${({ selectedRow, theme }) => selectedRow && theme.colors.blue50};
`;
const HeaderTr = styled(Tr)<{ noSort?: boolean }>`
    cursor: ${({ noSort }) => (noSort ? "auto" : "pointer")};
`;
const Th = styled.th<{ width?: string; sort?: boolean }>`
    width: ${({ width }) => width || "auto"};
    height: 48px;
    padding: 14px 8px;
    text-align: left;
    vertical-align: middle;
    font-weight: 500;
    cursor: ${({ sort }) => (sort === false ? "default" : "pointer")};
`;
const ThContent = styled.div<{ textAligns?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ textAligns }) => (textAligns ? textAligns : "flex-start")};
`;
const ThContentHeader = styled.div`
    margin-right: 4px;
`;
const ThContentUpArrow = styled.div<{ sortDir: SortDirType }>`
    opacity: ${({ sortDir }) => (sortDir === "none" ? 0 : 1)};
    transform: ${({ sortDir }) => (sortDir === "desc" ? "rotate(180deg)" : "none")};
    transition: all ease-in-out 0.3s;
`;
const CheckboxTh = styled(Th)<{ show?: boolean }>`
    display: ${({ show }) => (show ? "table-cell" : "none")};
    width: 1%;
    max-width: 50px;
    text-align: center;
`;
const Td = styled.td<{ width?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 60px;
    padding: 14px 8px;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
`;
const CheckboxTd = styled(Td)<{ show?: boolean }>`
    display: ${({ show }) => (show ? "table-cell" : "none")};
    width: 1%;
    max-width: 50px;
    text-align: unset;
`;

export interface TableProps {
    itemKey: string;
    items: Item[];
    headers: HeaderType[];
    customHeaders?: {
        [headerKey: string]: () => ReactElement;
    };
    columns?: {
        [columnKey: string]: (itemProps: TableItemProps) => ReactElement;
    };
    loading?: boolean;

    // 테이블 세부데이터(expanded)

    from?: string; // 페이지네이션 연동
    options?: {
        // 기타 옵션
        noSort?: boolean;
        showCheckbox?: boolean;
    };
    customStyles?: { [key: string]: string };
    onChangeModalState?: (state: boolean) => void;
    onGetSortOptions?: (sortBy: string, sortDir: SortDirType) => void;
}

export interface TableItemProps {
    item: Item;
    index: number;
}

export type HeaderType = { text: string; value: string; width?: string; textAlign?: string; sort?: boolean };
export type Item = { [key: number | string]: any };