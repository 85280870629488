import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import IconButton from "@/components/molecules/basedata-manage/IconButton";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";

export default function Done() {
    const navigate = useNavigate();

    return (
        <>
            <Text
                text="비밀번호 변경이 완료되었습니다."
                customStyle={{ fontSize: "16px", lineHeight: "28px", margin: "0 0 120px", textAlign: "center" }}
            />
            <BtnWrap>
                <IconButton
                    type="left"
                    label="메인 페이지"
                    customStyle={{ width: "194px",
                                    height: "60px",
                                    fontSize: "20px",
                                    fontWeight: "500" }}
                    onClickHandler={() => navigate("/")}
                />
                <Button
                    type="main"
                    label="로그인"
                    customStyle={{ width: "194px",
                                    height: "60px",
                                    fontSize: "20px",
                                    fontWeight: "500" }}
                    onClickHandler={() => navigate("/signin")}
                />
            </BtnWrap>
                
        </>
    );
}

const BtnWrap = styled.div`
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
`