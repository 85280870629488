import { addComma } from "@/modules/utils/filter";
import styled from "styled-components";

export default function SalesSummary({ from, total, amountName, amount, pubAmount }: SalesSummaryProps) {
    return (
        <SummaryContainer>
            <SummaryWrapper>
                <TotalLabel>전체</TotalLabel>
                <TotalSum>{total || ` - `}건</TotalSum>
            </SummaryWrapper>
            {from === "monthly" ? (
                <>
                    <SummaryWrapper>
                        <TotalLabel>{amountName}</TotalLabel>
                        <TotalSum>{amount ? addComma(amount) : `- `}원</TotalSum>
                    </SummaryWrapper>
                    <SummaryWrapper>
                        <TotalLabel>출판사 정산금</TotalLabel>
                        <TotalSum>{pubAmount ? addComma(pubAmount) : ` - `}원</TotalSum>
                    </SummaryWrapper>
                </>
            ) : (
                <SummaryWrapper>
                    <TotalLabel>{amountName}</TotalLabel>
                    <TotalSum>{amount ? addComma(amount) : `- `}원</TotalSum>
                </SummaryWrapper>
            )}
        </SummaryContainer>
    );
}

const SummaryContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;
const SummaryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    & + & {
        margin-left: 16px;
    }
`;
const TotalLabel = styled.div`
    color: ${({ theme }) => theme.colors.gray600};
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
`;
const TotalSum = styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
`;

export interface SalesSummaryProps {
    from: "daily" | "monthly" | "mg" | "etc";
    total: number;
    amountName: string;
    amount: number;
    pubAmount?: number;
}
