import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';

// components
import TypeNavBar from "@/components/molecules/common/typeNavBar/TypeNavBar";
import Deposit from "@/components/templates/totalSales/Deposit";
import Withdraw from "@/components/templates/totalSales/Withdraw";
import DefaultTotal from "@/components/molecules/total-sales/DefaultTotal";
import PageTitleWrapper from "@/components/molecules/basedata-manage/PageTitleWrapper";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";

// reducer
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { withdrawInitData, depositInitData, getPlatformList, totalSalesActions } from "@/pages/settlement/total-sales/slice";

// hooks
import useAlert from "@/modules/hooks/useAlert";

function TotalSales() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const [searchParams] = useSearchParams();
    const [template, setTemplate] = useState(<></>);
    let { platformList, yearmon } = useAppSelector((state: RootState) => state.totalSales);
    useEffect(() => {
        dataSetting();
    }, [dispatch, platformList, yearmon])

    useEffect(()=> {
        switch (searchParams.get("type")) {
            case "Deposit":
                setTemplate(<Deposit />);
                break;
            case "Withdraw":
                setTemplate(<Withdraw />);
                break;
        }
    }, [searchParams])

    useEffect(() => {
        return () => {
            dispatch(totalSalesActions.reset());
        }
    }, [dispatch])

    async function dataSetting() {
        try {
            if (!platformList.length) {
                await dispatch(getPlatformList()).unwrap();
            } else {
                await getDatas();

            }
        } catch (error: any) {
            await await alert("error", "오류 발생", error.message);
        }
    }

    async function getDatas() {
        try {
            let apiParams = {
                totalDate: yearmon,
                selectedPlatform: platformList.map(p => p.platform_id)
            }
            await dispatch(withdrawInitData(apiParams)).unwrap();
            await dispatch(depositInitData(apiParams)).unwrap();
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    return (
        <SectionLayout>
            <PageTitleWrapper style={{ margin: "0 0 24px" }} text="입출금 종합" />
            <DefaultTotal />
            <TypeNavBar
                menus={["입금", "출금"]}
                types={["Deposit", "Withdraw"]}
                customStyle={{ width: "600px" }}
            />
            {template}
        </SectionLayout>
    );
}

export default TotalSales;