import api from "@/api";
import { CommonType } from "@/types/dataType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const name = "author/common";
const initialState: CommonState = {
    // 플랫폼 조회
    platformList: [],
    platformListLoading: false,

    // 출판사 조회
    publisherList: [],
    publisherListLoading: false,

    // 작가 조회
    authorList: [],
    authorListLoading: false,

    // 시리즈 조회
    seriesList: [],
    seriesListLoading: false,

    // 선택된 필터 데이터
    selectedPlatform: [],
    selectedPublisher: [],
    selectedSeries: [],
    selectedAuthor: [],

    // 데이터 로드 여부
    dataloaded: false,

    // 판매량 제외 플랫폼
    platformNoQty: [],
};
// 플랫폼 조회
export const getPlatformList = createAsyncThunk(`${name}/getPlatformList`, async (_, { rejectWithValue }) => {
    try {
        const res = await api.post(`/api/settlement/common/platforms/select`);
        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});
// 출판사 조회
export const getPublisherList = createAsyncThunk(`${name}/getPublisherList`, async (_, { rejectWithValue }) => {
    try {
        const res = await api.post(`/api/settlement/salesInquiry/author/publisherList/select`);
        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});
// 작가 조회
export const getAuthorList = createAsyncThunk(`${name}/getAuthorList`, async (_, { rejectWithValue }) => {
    try {

        const res = await api.post(`/api/settlement/salesInquiry/author/authorList/select`);
        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});
// 시리즈 조회
export const getSeriesList = createAsyncThunk(`${name}/getSeriesList`, async (_, { rejectWithValue }) => {
    try {
        const res = await api.post(`/api/settlement/common/author/allSeries/select`);
        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

const commonSlice = createSlice({
    name,
    initialState,
    reducers: {
        setPlatform(state, action) {
            state.selectedPlatform = action.payload;
        },
        setPublisher(state, action) {
            state.selectedPublisher = action.payload;
        },
        setAuthor(state, action) {
            state.selectedAuthor = action.payload;
        },
        setSeries(state, action) {
            state.selectedSeries = action.payload;
        },
        setDataLoaded(state, action) {
            state.dataloaded = action.payload;
        },
    },
    extraReducers: (builder) => {
        // 플랫폼 조회
        builder
            .addCase(getPlatformList.pending, (state) => {
                state.platformListLoading = true;
            })
            .addCase(getPlatformList.fulfilled, (state, action) => {
                state.platformListLoading = false;
                state.platformList = action.payload.data;
                state.platformNoQty = action.payload.data.filter((p:PlatformList) => !p.quantity).map((p:PlatformList) => p.platform_name);
            });
        // 출판사 조회
        builder
            .addCase(getPublisherList.pending, (state) => {
                state.publisherListLoading = true;
            })
            .addCase(getPublisherList.fulfilled, (state, action) => {
                state.publisherListLoading = false;
                state.publisherList = action.payload.data;
            });
        // 저자명 조회
        builder
            .addCase(getAuthorList.pending, (state) => {
                state.authorListLoading = true;
            })
            .addCase(getAuthorList.fulfilled, (state, action) => {
                state.authorListLoading = false;
                state.authorList = action.payload.data;
            });
        // 시리즈 조회
        builder
            .addCase(getSeriesList.pending, (state) => {
                state.seriesListLoading = true;
            })
            .addCase(getSeriesList.fulfilled, (state, action) => {
                state.seriesListLoading = false;
                state.seriesList = action.payload.data;
            });
    },
});

export interface CommonState {
    // 플랫폼 리스트
    platformList: PlatformList[];
    platformListLoading: boolean;

    // 출판사 리스트
    publisherList: PublisherList[];
    publisherListLoading: boolean;

    // 저자명 리스트
    authorList: AuthorList[];
    authorListLoading: boolean;

    // 시리즈 리스트
    seriesList: SeriesList[];
    seriesListLoading: boolean;

    // 선택한 필터 데이터
    selectedPlatform: CommonType.Id[];
    selectedPublisher: CommonType.Id[];
    selectedSeries: CommonType.Id[];
    selectedAuthor: CommonType.Id[];

    // 데이터 로드 여부
    dataloaded: boolean;
    
    // 판매량 제공 안 하는 플랫폼 이름
    platformNoQty: string[];
}

export interface PlatformList {
    id: CommonType.Id;
    daily: number;
    monthly: number;
    quantity: number;
    platform_color: CommonType.Platform.Color;
    platform_name: CommonType.Platform.Name;
}
export interface PublisherList {
    publisher_id: CommonType.Id;
    publisher_name: CommonType.Publisher.Name;
}
export interface AuthorList {
    id: CommonType.Id;
    name: CommonType.Author.Name;
}
export interface SeriesList {
    id: CommonType.Id;
    series_name: CommonType.Series.Name;
    author_id: CommonType.Id;
    author_name: CommonType.Author.Name;
}

export const inquiryAuthorCommonActions = commonSlice.actions;
export default commonSlice.reducer;
