import { useEffect, useState } from "react";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { inquiryDailyActions } from "@/pages/settlement/saels-inquiry/publisher/daily/dailySlice";
import FilterContainer from "@/components/molecules/common/searchFilter/FilterContainer";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import DateRangePicker from "@/components/molecules/sales-inquiry/common/DateRangePicker";
import DateButtons from "@/components/molecules/sales-inquiry/publisher/daily/DateButtons";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import useValidateDate from "@/modules/hooks/useValidateDate";
import { calcPeriod } from "@/modules/utils/date";
import DailySearchBar from "@/components/molecules/sales-inquiry/publisher/daily/DailySearchBar";
import usePlatformSetting from "@/components/molecules/sales-inquiry/publisher/hooks/usePlatformSetting";

export default function DailySearchFilter() {
    const { startDate, endDate, startDateError, endDateError, reset } = useSelectorTyped(
        (state: RootState) => state.inquiryDaily,
    );
    const { setStartDate, setEndDate, setStartDateError, setEndDateError, setPlatforms, setReset, resetStore } =
        inquiryDailyActions;

    const dispatch = useAppDispatch();
    const [showMore, setShowMore] = useState(false);
    const { startDate: initialStartDate, endDate: initialEndDate } = calcPeriod("yesterday");
    const { validateStartDate, validateEndDate } = useValidateDate();
    const { platformList, platformIds } = usePlatformSetting();

    useEffect(() => {
        return () => { dispatch(resetStore()); };
    }, []);

    // 플랫폼ID 초기화
    useEffect(() => {
        dispatch(setPlatforms(platformIds));
    }, [platformList]);

    // 필터 변경 이벤트
    const clickPlusIcon = () => setShowMore((prev) => !prev);

    const clickResetButton = () => {
        dispatch(setReset(true));
        dispatch(setStartDate(initialStartDate));
        dispatch(setEndDate(initialEndDate));
        dispatch(setStartDateError(""));
        dispatch(setEndDateError(""));
        dispatch(setPlatforms(platformIds));
    };

    // 디스패치 액션
    const changeStartDate = (date: string) => {
        const { startDateError, endDateError } = validateStartDate(date, endDate);
        dispatch(setStartDateError(startDateError));
        dispatch(setEndDateError(endDateError));
        dispatch(setStartDate(date));
    };

    const changeEndDate = (date: string) => {
        const { startDateError, endDateError } = validateEndDate(date, startDate);
        dispatch(setStartDateError(startDateError));
        dispatch(setEndDateError(endDateError));
        dispatch(setEndDate(date));
    };

    const changePlatform = (platformList: number[]) => dispatch(setPlatforms(platformList));

    return (
        <FilterContainer>
            <FilterRow
                label="검색"
                filterContent={[<DailySearchBar />]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="판매일"
                filterContent={[
                    <DateRangePicker
                        type="default"
                        changeStartDate={changeStartDate}
                        changeEndDate={changeEndDate}
                        startDateToSet={startDate}
                        endDateToSet={endDate}
                        startDateError={startDateError}
                        endDateError={endDateError}
                    />,
                    <DateButtons reset={reset} />,
                    <ResetFilter onClick={clickResetButton} />,
                ]}
            />
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMore, onClick: clickPlusIcon }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={platformList}
                        keyName={"platform_id"}
                        valueName={"platform_name"}
                        onChange={changePlatform}
                        showMore={showMore}
                        reset={reset}
                    />,
                ]}
            />
        </FilterContainer>
    );
}
