import styled from "styled-components";
import Button from "@/components/atoms/button";
import { Step } from "@/pages/ebook-maker/types";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import { validatePage } from "../common/NavBar";
import { convert2File } from "@/pages/ebook-maker/slice";
import useLoading from "@/modules/hooks/useLoading";

export default function StepBtnWrapper({ stepHandler }: { stepHandler: (step: number, disabled: boolean) => void; }) {
    const { currStep, subtitleType, subtitleMakeType, subtitleList, noCoverImg, coverFile } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOff, loadingOn } = useLoading();

    const goPrev = () => {
        stepHandler(Step.Copyright, false);
    }

    const goNext = async () => {
        try {
            const param = { 
                subtitleType,
                subtitleMakeType,
                subtitleList,
                noCoverImg,
                coverFile
            };
    
            const validate = validatePage(currStep, param);
            if (validate.status === "fail") {
                return alert("warning", "주의", validate.message);
            }
            loadingOn();
            await dispatch(convert2File()).unwrap();
            stepHandler(Step.Complete, false);
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    return (
        <StyledWrapper>
            <Button type="red-outline" label="이전으로" onClickHandler={goPrev} customStyle={{ width: "120px" }} />
            <Button type="main" label="다음" onClickHandler={goNext} customStyle={{ width: "120px" }} />
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    display: flex;
    float: right;
    justify-content: space-between;
    width: 248px;
    height: 40px;
`