import styled from "styled-components";
import Text from "@/components/atoms/text";
import MakeTypeCard from "./MakeTypeCard";
import { EbookMaker } from "@/types/dataType";

export const cardData = [
    { label: "통파일 > 분할파일", type: EbookMaker.MakeType.SPLIT, desc: "하나의 파일을 여러 개로 분할\n하여 생성합니다. 쪽 나눔(Ctrl+Enter)\n으로 구분하여 생성합니다.\n지원 파일 : hwp, docx" },
    { label: "분할파일 > 통파일", type: EbookMaker.MakeType.MERGE, desc: "여러개의 파일을 합쳐\n하나의 파일로 생성합니다." },
    { label: "파일 별 개별 생성", type: EbookMaker.MakeType.SEPARATE, desc: "하나 또는 여러개의 파일별로\n각각 생성합니다." },
]

export default function MakeTypeConainter() {

    const titleText = {
        fontSize: "16px",
        fontWeight: "700"
    }

    return (
        <StyledContainer>
            <Text text="제작 형태 선택" customStyle={titleText}/>
            <CardWrapper>
                {cardData.map(c => (
                    <MakeTypeCard
                        key={c.type}
                        label={c.label}
                        type={c.type}
                        desc={c.desc}
                    />
                ))}
            </CardWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    height: 268px;
    padding: 24px 0;
`

const CardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding: 20px 60px;
`