import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";

export interface InquiryData {
    id: number,
    category: string,
    title: string,
    content: string,
    publisher_name: string,
    userId: number,
    complete: number
    secret: number
};

export interface authPublist {
    publisher_name: string,
    publisher_id: number,
};

export interface InquiryDetail {
    category: string,
    complete: number,
    content: string,
    InquiryId: number,
    publisher_id: number,
    publisher_name: string,
    secret: number,
    title: string,
    user_id: number,
    user_nickname: string,
    is_mine?: boolean,
    currentLoginedId?: number,
    created_at: string
}

export interface InquiryCommentType {
    comment_content: string,
    id: number,
    inquiry_id: string,
    user_id: number,
    created_at: string
}

export interface InquiryDetailData {
    detail: InquiryDetail,
    comments: Array<InquiryCommentType>,
    auth: string | null
}

export interface InquiryState {
    data: Array<InquiryData>;
    loading: boolean;
    filteredData: Array<InquiryData>;
    inquiryDetail: InquiryDetailData;
    authPublists: Array<authPublist>;
}

export interface detailParams {
    inquiryId: number,
    target_class: string,
    target_cd: string,
    target_id: number,
    log_activity: string,
    withCredentials: boolean,
    credentials: string
}

export interface replyAuthParams {
    withCredentials: boolean,
    credentials: string,
    publisher_id: number
}

export interface GetDataParams {
    categoryParams: string
}

export interface AddCommentParams {
    comment_content?: string,
    inquiry_id: number,
    is_mine?: boolean
}

export interface dataFormat {
    status: string,
    data: InquiryData[],
    message: string
}

export interface InquiryPayload {
    data: dataFormat,
    category: string
}

export interface WriteParams {
    title?: string,
    content?: string,
    category: string,
    publisher_id: number,
    secret: boolean,
    id?: string | null,
    url?: string
}

export interface deleteParams {
    id?: string,
    class: string
}

export const getInquiryData = createAsyncThunk("inquiry/getInquiryData", async (_, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/inquiry/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const getUserInquiry = createAsyncThunk("support/inquiry/select", async(user_id: number, {rejectWithValue}) => {
    const userInfo = {
        user_id
    }

    try {
        const res = await api.post(`/api/support/myInquiry/select`, userInfo);
        return res.data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const getAuthPublist = createAsyncThunk("inquiry/getAuth/Publist", async (_, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/settlement/publisher/list`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const writeInquiry = createAsyncThunk("inquiry/write", async(apiParams: WriteParams, { rejectWithValue }) => {
    try {
        if (apiParams.url === "support/board/insert") {
            const {url, ...updatedApiParams} = apiParams;
            updatedApiParams.id = undefined;
            return (await api.post(`/api/support/board/insert`, updatedApiParams)).data;
        } else {   
            return (await api.post(`/api/support/board/update`, apiParams)).data;
        }
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const getInquiryDetail = createAsyncThunk("inquiry/detail", async(apiParams: detailParams, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/inquiryDetail/select`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const replyAuth = createAsyncThunk("inquiry/detail/replyAuth", async(apiParams: replyAuthParams, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/inquiry/detail/replyAuth`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const deleteInquiry = createAsyncThunk("inquiry/detail/delete", async(apiParams: deleteParams, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/board/remove`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const addComment = createAsyncThunk("inquiry/comment/insert", async(apiParams: AddCommentParams, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/support/inquiry/comment/insert`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const removeComment = createAsyncThunk("inquiry/comment/remove", async(apiParams: {comment_id: number}, { rejectWithValue } ) => {
    try {
        return (await api.post(`/api/support/inquiry/comment/remove`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

const initialState: InquiryState = {
    data: [],
    loading: false,
    filteredData: [],
    inquiryDetail: {
        comments: [],
        detail: {
            category: '',
            complete: 0,
            content: '',
            InquiryId: 0,
            publisher_id: -1,
            publisher_name: '',
            secret: 0,
            title: '',
            user_id: 0,
            user_nickname: '',
            is_mine: false,
            currentLoginedId: 0,
            created_at: '',
        }, 
        auth: ''
    },
    authPublists: [],
}

export const InquirySlice = createSlice({
    name: 'inquiry',
    initialState,
    reducers: {
        reset() {
            return {...initialState};
        },
        filterInquiry(state, action) {
            let category = action.payload.category;
            if (category === "전체") {
                state.filteredData = state.data;
            } else {
                state.filteredData = state.data.filter((n) => n.category === category);
            }
        },
        cleanAuth(state) {
            state.inquiryDetail.auth = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInquiryData.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getInquiryData.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                state.data = action.payload.data;
                state.filteredData = action.payload.data;
            }
            state.loading = false;
        });
        //마이페이지의 문의사항일 경우
        builder.addCase(getUserInquiry.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getUserInquiry.fulfilled, (state, action) => {
            const payload = action.payload;
            if (payload.status === "success") {
                state.data = payload.data;
            } 
            state.loading = false;
        });
        builder.addCase(getAuthPublist.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                state.authPublists = action.payload.data;
            } 
        });
        builder.addCase(getInquiryDetail.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                const data = action.payload.data;
                state.inquiryDetail.comments = data.comment;
                state.inquiryDetail.detail = data.detail;
                if (data.detail.is_mine) {
                    state.inquiryDetail.auth = "q";
                }
            } else if (action.payload.status === "fail") {
                state.inquiryDetail.auth = null;
            } 
        });
        builder.addCase(replyAuth.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                state.inquiryDetail.auth = action.payload.data;
            } 
        });
    }
})

export const InquiryActions = InquirySlice.actions;

export default InquirySlice.reducer;