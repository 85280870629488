export const addComma = (v: number | string) => {
    let regexp = /\B(?=(\d{3})+(?!\d))/g;
    return v.toString().replace(regexp, ",");
};

export const removeComma = (v: number | string) => {
    if (!v || (v as string).length === 0) return "";
    let ret = Number(v.toString().replace(/,/gi, ""));
    if (!isNaN(ret)) return ret;
    else return v;
};

export const hangulParticle = (word: string) => {
    const lastChar = word.charCodeAt(word.length - 1);
    // 44032:가, 55203:힣
    if (lastChar >= 44032 && lastChar <= 55203) {
        return (lastChar - 44032) % 28 > 0 ? "을" : "를";
    } else {
        return "을(를)";
    }
};

export const round = (num: string|number) => {
    return Math.round(Number(num));
}

export const dateFormat = (type: "dot" | "dash", date: string | null) => {
    if (!date) return "";
    
    switch (type) {
        case "dot":
            return `${date.substring(0,4)}.${date.substring(4,6)}.${date.substring(6,8)}`
        case "dash":
            return `${date.substring(0,4)}-${date.substring(4,6)}-${date.substring(6,8)}`
        default:
            return "";
    }
}

// export const addDateDash = (v: string) => moment(v).format("YYYY-MM-DD");

// export const removeDateDash = (v: string) => v.replace(/-/gi, "");
