import InputLabel from "@/components/molecules/common/inputLabel/InputLabel";
import SignInFormButton from "@/components/molecules/signin/SignInFormButton";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { signinActions } from "@/pages/auth/signIn/signinSlice";
import { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import useAlert from "@/modules/hooks/useAlert";
import { getAuth, uploaderLogin } from "@/pages/auth/signIn/signinSlice";
import { useNavigate } from "react-router-dom";
import { loginLog } from "@/pages/auth/authSlice";

export default function SignInForm() {
    let navigate = useNavigate();
    let [userData, socketId] = useSelectorTyped((state: RootState) => [
        state.signin.data,
        state.socket.socketId
    ]);
    let dispatch = useAppDispatch();
    const { alert } = useAlert();
    let [formValid, setFormValid] = useState(false);

    useEffect(() => {
        function checkFormValid() {
            if (userData) {
                if (userData.inputID && userData.inputPW) {
                    setFormValid(true);
                } else {
                    setFormValid(false);
                }
            }
        }
        checkFormValid();
    }, [userData]);

    
    useEffect(() => {
        return () => {
            dispatch(signinActions.clearInputData());
        };
    }, [dispatch]);

    function IDChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        dispatch(signinActions.updateInputID({ id: e.target.value }));
    }
    function PWChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        dispatch(signinActions.updateInputPW({ pw: e.target.value }));
    }

    async function submitHandler(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const apiData = {
            email: userData?.inputID as string,
            password: userData?.inputPW as string,
            sid: socketId,
        };

        try {
            const { status, data } = await dispatch(uploaderLogin(apiData)).unwrap();
            if (status === "success") {
                let apiParams = {
                    target_cd: "LI",
                    target_id: "0",
                    log_activity: "",
                }
                await dispatch(loginLog(apiParams)).unwrap();
                window.location.href = `https://m.gling.co.kr?data=${data.publisher_id}`
            } else {
                await alert("error", "로그인 오류", "아이디 또는 비밀번호를 확인해주세요.");
            }
        } catch (err: any) {
            return await alert("error", "로그인 오류", err.message);
        }
    }

    return (
        <SignInFormWrapper onSubmit={submitHandler}>
            <InputLabel
                labelName="아이디(이메일)"
                inputPlaceholder="이메일을 입력해주세요"
                inputID="emailID"
                inputType="text"
                value={userData ? userData.inputID : ""}
                onChange={IDChangeHandler}
            />
            <InputLabel
                labelName="비밀번호"
                inputPlaceholder="비밀번호를 입력해주세요"
                inputID="password"
                inputType="password"
                value={userData ? userData.inputPW : ""}
                onChange={PWChangeHandler}
            />
            <SignInFormButton formValid={formValid} />
        </SignInFormWrapper>
    );
}

const SignInFormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px auto;
`;
