import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import api from "@/api";
import { PlatformData, PlatformState } from './types';
import { KeyValue, IndexNum, IndexString } from "../types"
import moment from 'moment';

// 플랫폼 관리 데이터 가져오기
export const getPlatformData = createAsyncThunk("platform/getPlatformData", async () => {
	try {
		const res = await api.post(`/api/settlement/baseData/platformSetting/select`);
		// 비동기 요청 오류 발생시키기
		// throw "err!!!"
		return res.data;
	} catch (err) {
		throw err;
	}
})

// 플랫폼 관리 데이터 저장
export const savePlatformData = createAsyncThunk("platform/savePlatformData", async (apiParams:Array<PlatformData>) => {
	try {
		let items = JSON.parse(JSON.stringify(apiParams));
		for (let i of items) {
			if (i.paymonth === "99") {
				i.paymonth = i.p_input;
			}
		}
		const params = {
			items: items
		}
		const res = await api.post(`/api/settlement/baseData/platformSetting/replace`, params);
		return res.data;
	} catch (err) {
		throw err;
	}
})

// Define the initial state using that type
const initialState: PlatformState = {
	data: [],
	loading: false,
	hasError: false
}

export const platformSlice = createSlice({
	name: 'platform',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		reset() {
			return {...initialState};
		},
		setUsable: (state, action: PayloadAction<IndexNum>) => {
			state.data[action.payload.idx].usable = action.payload.value;
		},
		setPaymonth: (state, action: PayloadAction<IndexString>) => {
			state.data[action.payload.idx].paymonth = action.payload.value;
		},
		setPInput: (state, action: PayloadAction<IndexString>) => {
			state.data[action.payload.idx].p_input = action.payload.value;
		},
		setPlatformDatas: (state, action: PayloadAction<KeyValue>) => {
			let key = action.payload.key;
			let index = action.payload.index;
			let value = action.payload.value;
			state[key][index] = value;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPlatformData.pending, (state, action) => {
			state.loading = true;
			state.hasError = false;
		});
		builder.addCase(getPlatformData.fulfilled, (state: any, action) => {
			if (action.payload.status === "success") {
				const temp = action.payload.data;
				for (let t of temp) {
					if (Number(t.paymonth) > 2) {
						t.p_input = t.paymonth;
						t.paymonth = "99";
					}
					if (t.circulation_stop_date) {
						t.circulation_stop_date = moment(t.circulation_stop_date).format('YYYY-MM-DD');
					}
				}
				state.data = temp;
				state.hasError = false;
			} else {
				state.hasError = true;
			}
			state.loading = false;
		})
		builder.addCase(getPlatformData.rejected, (state, action) => {
			state.loading = false;
			state.hasError = true;
		});
		builder.addCase(savePlatformData.fulfilled, (state: any, action) => {
			if (action.payload.status === "sucess") {
				state.loading = false;
				state.hasError = false;
			} else {
				state.hasError = true;
			}
		})
		builder.addCase(savePlatformData.rejected, (state, action) => {
			state.loading = false;
			state.hasError = true;
		});
	}
})

export const basePlatformActions = platformSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.author

export default platformSlice.reducer