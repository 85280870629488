import { useState, memo, useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { 
    inquiryMonthlyActions,
    getAtcmpByAll,
    getAtcmpBySeries,
    getAtcmpByAuthor,
    getAtcmpByBookName
} from "@/pages/settlement/saels-inquiry/publisher/monthly/monthlySlice";
import SelectBox from "@/components/atoms/selectBox/index";
import MultiSearchBar from "@/components/molecules/common/searchBar/multiSearch/MultiSearchBar";
import useSearchHistory from "@/components/molecules/common/searchBar/multiSearch/useSearchHistory";
import type { Atcmp } from "@/types/autoComplete";

export default memo(function MonthlySearchBar() {
    const { fetchLoading, reset } = useSelectorTyped((state: RootState) => state.inquiryMonthly);
    const { atcmpList } = useSelectorTyped((state: RootState) => state.inquiryMonthly);
    const { searchHistory } = useSelectorTyped((state: RootState) => state.searchHistory);
    const dispatch = useAppDispatch();
    const [searchType, setSearchType] = useState("all");
    const { getAtcmp, getHistory, updateHistory, deleteHistory } = useSearchHistory({ template: "inquiry-monthly" });

    useEffect(() => {
        if (reset) {
            dispatch(inquiryMonthlyActions.setFilterData({
                series_name:"",
                author_name:"",
                book_name:"",
            }));
            dispatch(inquiryMonthlyActions.setReset(false));
            setSearchType("all");
        }
    }, [reset]);

    const changeSearchType = (value: any) => setSearchType(value.value);

    const search = async (item: Atcmp | string) => {
        if (typeof item === "string") {
            if (searchType === "series") {
                dispatch(inquiryMonthlyActions.setFilterData({ series_name: item }));
            } else if (searchType === "author") {
                dispatch(inquiryMonthlyActions.setFilterData({ author_name: item }));
            } else if (searchType === "bookName") {
                dispatch(inquiryMonthlyActions.setFilterData({ book_name: item }));
            } else {
                dispatch(
                    inquiryMonthlyActions.setFilterData({
                        series_name: item,
                        author_name: item,
                        book_name: item,
                    }),
                );
            }

            if (!item) return;
            await updateHistory(item);
            return await getHistory();
        }

        if (item.type === "series") {
            dispatch(inquiryMonthlyActions.setFilterData({ series_name: item.value }));
        }
        if (item.type === "author") {
            dispatch(inquiryMonthlyActions.setFilterData({ author_name: item.value }));
        }
        if (item.type === "bookName") {
            dispatch(inquiryMonthlyActions.setFilterData({ book_name: item.value }));
        }

        await updateHistory(item.value);
        await getHistory();
    };

    const clearHistory = async (keyword?: string) => {
        await deleteHistory(keyword);
        await getHistory();
    };

    const getAtcmpList = (keyword: string) => {
        switch (searchType) {
            case "all":
                searchByAll(keyword);
                break;
            case "series":
                searchBySeries(keyword);
                break;
            case "author":
                searchByAuthor(keyword);
                break;
            case "bookName":
                searchByBookName(keyword);
                break;
        }
    };

    // 전체 자동완성
    const searchByAll = (keyword: string) => getAtcmp(getAtcmpByAll({ keyword }));

    // 시리즈 자동완성
    const searchBySeries = (keyword: string) => getAtcmp(getAtcmpBySeries({ keyword }));

    // 저자명 자동완성
    const searchByAuthor = (keyword: string) => getAtcmp(getAtcmpByAuthor({ keyword }));

    // 작품명 자동완성
    const searchByBookName = (keyword: string) => getAtcmp(getAtcmpByBookName({ keyword }));

    return (
        <>
            <SelectBox
                labelKey="name"
                valueKey="value"
                options={[
                    { name: "전체", value: "all" },
                    { name: "시리즈명", value: "series" },
                    { name: "저자명", value: "author" },
                    { name: "작품명", value: "bookName" },
                ]}
                selected={searchType}
                onClickValueFunc={changeSearchType}
                customStyle={{
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "400",
                    margin: "0px 8px 0px 0px",
                }}
            />
            <MultiSearchBar
                type={searchType === "all" ? "all" : "single"}
                atcmpList={atcmpList}
                searchHistory={searchHistory}
                reset={reset}
                dataLoading={fetchLoading && !reset}
                onKeyUp={getAtcmpList}
                onSelected={search}
                onClearHistory={clearHistory}
            />
        </>
    );
});
