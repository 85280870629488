import styled from 'styled-components'
import IconButton from "@/components/molecules/common/banner-slider/IconButton";
import Text from '@/components/atoms/text'
import theme from '@/assets/styles/theme';

export default function SliderItem({src, title, description, titleStyle, descriptionStyle, innerWidth, buttonPath}: SlideProps) {
    const filteredTitle = title.split("</br>").map((txt, idx) => <Text key={idx} text={txt} customStyle={titleStyle}/>)
    const filteredDescription = description.split("</br>").map((txt, idx) => <Text key={idx} text={txt} customStyle={descriptionStyle}/>)
    const btnStyle = {
        width: "160px",
        height: "40px",
        fontSize: "16px",
        borderRadius: "4px",
        fontWeight: "500",
        marginTop: "60px",
        color: theme.colors.black
      }
      
    const buttonLabel = "글링 시작하기";
    
    const goPath = () => document.location.href = `/${buttonPath}`
    

    return (
        <StyledSliderItem width={innerWidth}>
            <StyledCardContainer>
                <StyledTextContainer>
                    <StyledTitleWrapper>
                        {filteredTitle}
                    </StyledTitleWrapper>
                    {filteredDescription}
                    <IconButton label={buttonLabel} customStyle={btnStyle} onClickHandler={goPath}/>
                </StyledTextContainer>
                <img src={src} alt="slideImg"/>
            </StyledCardContainer>
        </StyledSliderItem>
    )
}

const StyledSliderItem = styled.div<{ width: number }>`
    display: flex;
    height: 100%;
    padding: ${({ width }) => 360 - ((1920-width)/2) > 0 ? `0 ${360 - ((1920-width)/2)}px` : "0"};
`;

const StyledCardContainer = styled.div`
    display: flex;
    margin: 0 auto;
    width: 1200px;
    height: 380px;
    justify-content: space-between;
    padding: 20px 80px;
`

const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
`

const StyledTitleWrapper = styled.div`
    margin-bottom: 16px;
`;

interface titleStyle {
    fontWeight: string,
    fontSize: string,
    lineHeight: string,
    color: string
}

interface descriptionStyle {
    fontWeight: string,
    fontSize: string,
    lineHeight: string,
    color: string
}


interface SlideProps {
    src: string,
    title: string,
    description: string,
    titleStyle: titleStyle,
    descriptionStyle: descriptionStyle,
    innerWidth: number,
    buttonPath?: string
}