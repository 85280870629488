import styled, { css } from "styled-components";
import { RootState, useAppSelector } from "@/modules/store";
import { Step, OutFileType, BookInfoType, InFile } from "@/pages/ebook-maker/types";
import { EbookMaker } from "@/types/dataType";

export default function NavBar({ data, selected, onClickHandler }: NavBarProps) {
    const { completeStepIdx } = useAppSelector((state: RootState) => state.ebookMaker);

    return (
        <MenuWrapper>
            {data.map((d: NavBarEntry, idx) => (
                <Container
                    key={d.step}
                    isClicked={d.step === selected}
                    disabled={idx > completeStepIdx || completeStepIdx === Step.Complete}
                    onClick={() => onClickHandler(d.step, idx > completeStepIdx || completeStepIdx === Step.Complete)}
                >
                    {d.label}
                </Container>
            ))}
        </MenuWrapper>
    );
}

// 페이지 validation
export const validatePage = (step: number, param: any) => {
    switch (step) {
        case Step.Form:
            return validateFormPage(param);
        case Step.Copyright:
            return validateCopyrightPage(param);
        case Step.Method:
            return validateMethodPage(param);
        case Step.OnlyTxt:
            return validateOnlyTxt(param);
        default:
            return { status: "", message: "" };
    }
}

// 제작형태 선택 페이지 valiate
const validateFormPage = (param: ValidateFormPageParam): ValidatePageReturnType => {
    if (!param.makeType) {
        return { status: "fail", message: "제작 형태를 선택해주세요." }
    }
    if (!param.outFileType.length) {
        return { status: "fail", message: "출력파일 형식을 선택해주세요." }
    }
    if (!param.bookInfo.series_name) {
        return { status: "fail", type: "series_name", message: "제목을 입력해주세요" }
    }
    if (!param.bookInfo.author_name) {
        return { status: "fail", type: "author_name", message: "저자명을 입력해주세요" }
    }
    if (!param.inFiles.length) {
        return { status: "fail", message: "변환할 파일을 업로드 해주세요." }
    }

    return { status: "success", message: "" };
}

// 판권지 페이지 validate
const validateCopyrightPage = (param: ValidateCopyrightPageParam): ValidatePageReturnType => {
    if (!param.contentList.length) {
        return { status: "fail", message: "판권지 정보를 입력해주세요." }
    }

    return { status: "success", message: "" }
}

// 제작방식 선택 페이지 validate
const validateMethodPage = (param: any): ValidatePageReturnType => {
    if (!param.subtitleType) {
        return { status: "fail", message: "소제목 포함 및 생성 여부를 선택해주세요." };
    }
    if (param.subtitleType === EbookMaker.SubtitleType.MAKE) {
        if (!param.subtitleMakeType) {
            return { status: "fail", message: "소제목 생성방식을 선택해주세요." };
        }
        for (let s of param.subtitleList) {
            if (!s.subtitle) {
                return { status: "fail", message: `${s.order}번 소제목 제목을 입력해주세요.` };
            }
        }
    }
    if (!param.noCoverImg && !param.coverFile.length) {
        return { status: "fail", message: "표지 파일을 업로드해주세요." };
    }

    return {
        status: "success",
        message: ""
    }
}

// 텍스트파일로만 변환할 경우 validate
const validateOnlyTxt = (param: any): ValidatePageReturnType => {
    if (!param.makeType) {
        return { status: "fail", message: "제작 형태를 선택해주세요." }
    }
    if (!param.inFiles.length) {
        return { status: "fail", message: "변환할 파일을 업로드 해주세요." }
    }

    return {
        status: "success",
        message: ""
    }
}

const MenuWrapper = styled.div`
    display: table;
    /* border-collapse: collapse; */
    background-color: ${({ theme }) => theme.colors.gray50};
`;
const Container = styled.div<{ isClicked: boolean, disabled: boolean }>`
    display: table-cell;
    width: 300px;
    height: 40px;
    cursor: ${({ disabled }) => disabled ? "unset" : "pointer" };
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    ${({ theme }) => css`
        border: 1px solid ${theme.colors.gray300};
        border-bottom: 1px solid ${theme.colors.black};
        border-radius: 4px 4px 0 0;
        color: ${theme.colors.gray600};
    `}

    ${(props) =>
        props.isClicked &&
        css`
            background-color: ${props.theme.colors.white};
            border: 1px solid black;
            border-bottom: hidden;
            color: ${props.theme.colors.black};
        `} /* box-shadow: 1px 0 0 0px black, 0px 1px 0 0 black, 0px 0 1px 0 black, inset 1px 0px black, inset 0px 1px black; */
`;

interface NavBarProps {
    data: NavBarEntry[];
    selected: number;
    onClickHandler: (step: number, disabled: boolean) => void;
}

interface NavBarEntry {
    label: string;
    step: number;
}

interface ValidatePageReturnType {
    status: string;
    type?: string;
    message: string;
}

interface ValidateFormPageParam {
    makeType: string;
    outFileType: OutFileType[];
    bookInfo: BookInfoType;
    inFiles: InFile[]
}

interface ValidateCopyrightPageParam {
    contentList: any[];
}