import styled from "styled-components";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import Text from "@/components/atoms/text";
import moment from "moment";
import Checkbox from "@/components/atoms/input/Checkbox";
import CustomDatePicker from "@/components/molecules/portfolio/publisher/edit/CustomDatePicker";

export default function CirculationStopModalBody({ from }:SetSettlementRatioBodyProps ) {
    const dispatch = useAppDispatch();
    const { allDate, allPlatform, salesPlatform } = useAppSelector((state: RootState) => state.pubPortfolio);
    const [platform, setPlatform] = useState(salesPlatform.map((p) => { return {...p, check: true }}));
    const [allCheck, setAllCheck] = useState(true);

    useEffect(() => {
        dispatch({ type: "pubPortfolio/setAllDate", payload: moment().format('YYYYMMDD') });
    }, [])

    useEffect(() => {
        dispatch({ type: "pubPortfolio/setAllPlatform", payload: salesPlatform.map(p => p.platform_id) });
    }, [salesPlatform])

    const allCheckHandler = () => {
        if (allCheck) {
            setPlatform(salesPlatform.map((p) => { return {...p, check: false }}));
            dispatch({ type: "pubPortfolio/setAllPlatform", payload: [] });
        } else {
            setPlatform(salesPlatform.map((p) => { return {...p, check: true }}));
            dispatch({ type: "pubPortfolio/setAllPlatform", payload: salesPlatform.map(p => p.platform_id) });
        }
        setAllCheck(!allCheck);
    }

    const checkHandler = (idx: number) => {
        const tmpPlatform = JSON.parse(JSON.stringify(platform));
        tmpPlatform[idx].check = !tmpPlatform[idx].check;
        const checkPlatform = tmpPlatform.filter((p:any) => p.check).map(((p:any) => p.platform_id));
        dispatch({ type: "pubPortfolio/setAllPlatform", payload: checkPlatform });
        if (!checkPlatform.length) {
            setAllCheck(false);
        } else if (checkPlatform.length === salesPlatform.length) {
            setAllCheck(true);
        }
        setPlatform(tmpPlatform);
    }

    const changeDate = (date:string) => {
        dispatch({ type: "pubPortfolio/setAllDate", payload: date });
    }

    return (
        <>
            <BodyWrap id="circulationStopBody">
                <FieldRow>
                    <ColumnName width="60px" />
                    <ColumnName>
                        <Text text="플랫폼명" customStyle={{ fontSize: "12px" }}/>
                    </ColumnName>
                    <ColumnName width="212px">
                        <Text text="유통 종료일" customStyle={{ fontSize: "12px", textAlign: "center" }}/>
                    </ColumnName>
                </FieldRow>
                {/* 전체 */}
                <ItemRow border={true} active={allCheck}>
                    <Item width="60px" justifyContent="center">
                        <Checkbox  type="boolean" active={allCheck} onChangeHandler={() => allCheckHandler()} />
                    </Item>
                    <Item>
                        <Text
                            text="전체"
                            customStyle={{ fontSize: "14px", fontWeight: "400" }}
                        />
                    </Item>
                    <Item width="212px">
                        <CustomDatePicker
                            settingDate={allDate}
                            onChange={(date) => changeDate(date)}
                            customStyle={{ width: "160px" }}
                        />
                    </Item>
                </ItemRow>
                {/* 플랫폼 개별 */}
                {platform.map((p:any, i:number) => 
                    <ItemRow key={i}>
                        <Item width="60px" justifyContent="center">
                            <Checkbox  type="boolean" active={p.check} onChangeHandler={() => checkHandler(i)} />
                        </Item>
                        <Item>
                            <Text
                                text={p.platform_name}
                                customStyle={{ fontSize: "14px", fontWeight: "400"}}
                            />
                        </Item>
                        <Item width="212px" />
                    </ItemRow>
                    )
                }
            </BodyWrap>
        </>
    )
}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 348px;
    max-height: 348px;
    overflow: auto;

`
const ItemRow = styled.div<{ border?: boolean, active?: boolean }>`
    display: flex;
    align-items: center;
    height: 60px;
    border-top: ${({ theme, border }) => border ? `1px solid ${theme.colors.gray300}` : ""};
    border-bottom: ${({ theme, border }) => border ? `1px solid ${theme.colors.gray300}` : ""};
    background-color: ${({ theme, active }) => active ? theme.colors.gray50 : ""};
`

const Item = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "160px"};
    height: 60px;
    font-size: 12px;
    padding: 8px;
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
`

const ColumnName = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "160px"};
    height: 48px;
    font-size: 12px;
    padding: 8px;
`

interface SetSettlementRatioBodyProps {
    from: string
}