import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as Merge } from "@/assets/icons/ebook-maker/merge.svg";
import { ReactComponent as MergeGray } from "@/assets/icons/ebook-maker/merge-gray.svg";
import { ReactComponent as Split } from "@/assets/icons/ebook-maker/split.svg";
import { ReactComponent as SplitGray } from "@/assets/icons/ebook-maker/split-gray.svg";
import { ReactComponent as Separate } from "@/assets/icons/ebook-maker/separate.svg";
import { ReactComponent as SeparateGray } from "@/assets/icons/ebook-maker/separate-gray.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/modules/store";
import { EbookMaker } from "@/types/dataType";

export default function MakeTypeCard(props: CardPropsType) {
    const { makeType } = useSelector((state: RootState) => state.ebookMaker);
    const dispatch = useDispatch();
    
    const isActive = makeType === props.type;

    let icon;
    switch (props.type) {
        case EbookMaker.MakeType.SPLIT:
            icon = isActive ? <Split /> : <SplitGray />;
            break;
        case EbookMaker.MakeType.MERGE:
            icon = isActive ? <Merge /> : <MergeGray />;
            break;
        case EbookMaker.MakeType.SEPARATE:
            icon = isActive ? <Separate /> : <SeparateGray />;
            break;
        default:
            break;
    }

    const selectMakeType = () => {
        dispatch({ type: "ebookMaker/setMakeType", payload: props.type });
    }

    const cardTypeText = {
        fontSize: "14px",
        textAlign: "center",
        color: isActive ? colors.blue500 : colors.gray600,
    }

    return (
        <CardWrapper>
            <ColoredBox onClick={selectMakeType} isActive={isActive}>
                <ImgWrapper>
                    {icon}
                </ImgWrapper>
                <DescWrapper>
                    {props.desc}
                </DescWrapper>
            </ColoredBox>
            <Text text={props.label} customStyle={cardTypeText}/>
        </CardWrapper>
    )
}

const CardWrapper = styled.div`
    width : 300px;
    height: 160px;
`

const ColoredBox = styled.div<ColoredBoxProps>`
    display: flex;
    height: 120px;
    background-color: ${({ isActive }) => isActive ? colors.blue50 : colors.gray50};
    border: 1px solid ${({ isActive }) => isActive ? colors.gray500 : colors.gray600};
    border-radius: 4px;
    margin-bottom: 11px;
    cursor: pointer;
`

const ImgWrapper = styled.div`
    width: 120px;
    height: 120px;
`

const DescWrapper = styled.div`
    width: 180px;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    white-space: pre-line;
    color: ${colors.gray600};
`

interface CardPropsType {
    label: string;
    type: string;
    desc: string;
}

interface ColoredBoxProps {
    isActive: boolean;
}