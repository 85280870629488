import styled from "styled-components";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import { updateRow } from "@/pages/common/dataTableSlice";
import { inquiryMonthlyActions } from "@/pages/settlement/saels-inquiry/publisher/monthly/monthlySlice";

// components
import Text from "@/components/atoms/text";
import TextInput from "@/components/atoms/input/TextInput";
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import CoAuthorTooltip from "@/components/molecules/sales-inquiry/publisher/monthly/CoAuthorTooltip";
import TextTooltip from "@/components/molecules/common/tooltip/TextTooltip";

// custom hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";

// utils
import { addComma, removeComma } from "@/modules/utils/filter";
import { amount, integer } from "@/modules/utils/validate_modify";
import usePlatformSetting from "@/components/molecules/sales-inquiry/publisher/hooks/usePlatformSetting";

export default function MonthlyTable() {
    const { items, fetchLoading } = useSelectorTyped((state: RootState) => state.inquiryMonthly);
    const { pageCount, totalVisible } = usePagination({ from: "monthly", tableData: items });
    const { platformNoQty } = usePlatformSetting();
    const dispatch = useAppDispatch();

    const columns = {
        book_name: ({ item }: TableItemProps) => {
            return <TextTooltip text={item.book_name} customStyle={{ width: "220px"}} />;
        },
        series_name: ({ item }: TableItemProps) => {
            return <TextTooltip text={item.series_name} />;
        },
        quantity: ({ item, index }: TableItemProps) => {
            if (platformNoQty.find(p => item.platform_id === p)) {
                return (
                    <TextInput
                        type="readOnly"
                        value={"정보없음"}
                        onChangeHandler={() => {}}
                        customStyle={{...inputStyle, textAlign: "left"}}
                    />
                );
            }
            const changeQuantity = (value: string) => {
                const res = integer({
                    field: "판매량",
                    value,
                    error: "quantityError",
                    min: -9999999,
                    max: 9999999,
                    mandatory: true,
                });
                const newItem = { ...item, quantity: res.value, quantityError: res.quantityError };
                dispatch(inquiryMonthlyActions.setItem({ index, item: newItem }));
                dispatch(updateRow({ itemKey: "id", updatedRow: newItem }));
            };
            const handleComma = (type:string) => () => {
                const newItem = { ...item, quantity: type === 'remove' ? removeComma(item.quantity) : addComma(item.quantity) };
                dispatch(inquiryMonthlyActions.setItem({ index, item: newItem }));
            }

            return (
                <ValidateWrapper dataError={item["quantityError"]}>
                    <TextInput
                        type={"main"}
                        value={item["quantity"]}
                        onChangeHandler={(e) => changeQuantity(e.target.value)}
                        onFocusHandler={handleComma("remove")}
                        onBlurHandler={handleComma('add')}
                        customStyle={inputStyle}
                    />
                </ValidateWrapper>
            );
        },
        amount_pl: ({ item, index }: TableItemProps) => {
            const changeAmountPl = (value: string) => {
                const res = amount({
                    field: "판매금액",
                    value,
                    error: "amountPlError",
                    decimal: true,
                    mandatory: true,
                });
                const newItem = { ...item, amount_pl: res.value, amountPlError: res.amountPlError };
                dispatch(inquiryMonthlyActions.setItem({ index, item: newItem }));
                dispatch(updateRow({ itemKey: "id", updatedRow: newItem }));
            };
            const handleComma = (type:string) => () => {
                const newItem = { ...item, amount_pl: type === 'remove' ? removeComma(item.amount_pl) : addComma(item.amount_pl) };
                dispatch(inquiryMonthlyActions.setItem({ index, item: newItem }));
            }

            return (
                <ValidateWrapper dataError={item["amountPlError"]}>
                    <TextInput
                        type={"main"}
                        value={item["amount_pl"]}
                        onChangeHandler={(e) => changeAmountPl(e.target.value)}
                        onFocusHandler={handleComma("remove")}
                        onBlurHandler={handleComma('add')}
                        customStyle={inputStyle}
                    />
                </ValidateWrapper>
            );
        },
        amount_pu: ({ item, index }: TableItemProps) => {
            const changeAmountPu = (value: string) => {
                const res = amount({
                    field: "출판사정산금",
                    value,
                    error: "amountPuError",
                    decimal: true,
                    mandatory: true,
                });
                const newItem = { ...item, amount_pu: res.value, amountPuError: res.amountPuError };
                dispatch(inquiryMonthlyActions.setItem({ index, item: newItem}));
                dispatch(updateRow({ itemKey: "id", updatedRow: newItem }));
            };
            const handleComma = (type:string) => () => {
                const newItem = { ...item, amount_pu: type === 'remove' ? removeComma(item.amount_pu) : addComma(item.amount_pu) };
                dispatch(inquiryMonthlyActions.setItem({ index, item: newItem }));
            }

            return (
                <ValidateWrapper dataError={item["amountPuError"]}>
                    <TextInput
                        type={"main"}
                        value={item["amount_pu"]}
                        onChangeHandler={(e) => changeAmountPu(e.target.value)}
                        onFocusHandler={handleComma("remove")}
                        onBlurHandler={handleComma('add')}
                        customStyle={inputStyle}
                    />
                </ValidateWrapper>
            );
        },
        ratio: ({ item }: TableItemProps) => {
            return <Text text={item.ratio} customStyle={inputCenterStyle} />;
        },
        author_pu: ({ item }: TableItemProps) => (
            <TdWrapper align={!!item["co_info"].length}>
                {item["co_info"].length ? <CoAuthorTooltip data={item["co_info"]} /> : <></>}
                <Amount>{addComma(Math.round(item["author_pu"]))}</Amount>
            </TdWrapper>
        ),
    };

    return (
        <>
            <TableContainer width="1380px">
                <DataTable 
                    from="monthly"
                    loading={fetchLoading}
                    itemKey="id"
                    items={items}
                    headers={headers}
                    columns={columns} 
                />
                <ScrollToTopBtn />
            </TableContainer>
            <Pagination pageCount={pageCount} totalVisible={totalVisible} from="monthly" />
        </>
    );
}

// 테이블 데이터
const headers = [
    { text: "판매플랫폼", value: "platform_name", width: "100px" },
    { text: "정산월", value: "yearmon_st", width: "100px" },
    { text: "실제판매월", value: "yearmon", width: "100px" },
    { text: "작품명", value: "book_name", width: "240px" },
    { text: "시리즈명", value: "series_name", width: "100px" },
    { text: "저자명", value: "author_name", width: "100px" },
    { text: "판매량", value: "quantity", width: "80px" },
    { text: "판매금액(원)", value: "amount_pl", width: "100px" },
    { text: "출판사정산금(원)", value: "amount_pu", width: "120px" },
    { text: "작가정산비율(%)", value: "ratio", width: "120px", textAlign: "center" },
    { text: "작가정산금액(원)", value: "author_pu", width: "120px" },
];

const inputStyle = {
    fontSize: "12px",
    textAlign: "right",
    width: "100%",
    height: "32px",
};
const inputCenterStyle = {
    ...inputStyle,
    textAlign: "center",
}
const Amount = styled.div`
    margin-left: 10px;
`;
const TdWrapper = styled.div<{ align: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${({ align }) => (align ? "space-between" : "end")}; ;
`;
