import { createSlice, PayloadAction, createAsyncThunk, } from "@reduxjs/toolkit";
import api from "@/api";

interface ModalState {
    modalList: { name: string }[];

	// searchSeries모달 selected된 데이터
	searchSeriesSelected: any[];

	[key: string]: any|any[];
}

interface KeyValue {
	key: string;
	index?: number;
	value: any;
	objectKey?: string;
	objectIndex?: number;
}

const initialState : ModalState = {
    modalList: [],

	searchSeriesSelected: [],

};

// 시리즈 검색
export const getSeriesAll = createAsyncThunk("modal/getSeriesAll", async (apiParams:{
	keyword: string,
	platform_id: number|undefined,
	stYearmon: string|undefined,
}, { rejectWithValue }) => {
	try {
		// let apiParams = {
		// 	keyword: this.keyword,
		// 	platform_id: this.platformId,
		// 	stYearmon: this.stYearmon
		// };
		const res = await api.post("/api/settlement/excelUpload/searchSeries/select", apiParams);

		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

const modalSlice = createSlice({
    name: "modal",
    initialState: initialState,
    reducers: {
		reset() {
			return {...initialState};
		},
        /* Modal push */
        pushModal(state, action) {
			const filtered = state.modalList.filter(modal => modal.name === action.payload.name);
			if (filtered.length > 0) return;
			state.modalList.push(action.payload);
		},
		/* Modal pop */
		popModal(state) {
			const index = state.modalList.length - 1;
			if (index >= 0) {
				state.modalList.splice(index, 1);
			}
		},
		setFrom(state, action: PayloadAction<string>) {
			state.from = action.payload;
		},
		setModalData(state, action: PayloadAction<KeyValue>) {
			let key = action.payload.key;
			let value = action.payload.value;
			let index = action.payload.index;
			let objectKey = action.payload.objectKey;
			if (typeof index === "number") {
				if (objectKey) {
					state[key][index][objectKey] = value;
				} else {
					state[key][index] = value;
				}
			} else {
				state[key] = value;
			}
		},
    },
	extraReducers: (builder) => {
		builder.addCase(getSeriesAll.pending, (state, action) => {
			// 프로미스반환전
			// state.loading = true;
			// state.error = false;
		});
		builder.addCase(getSeriesAll.fulfilled, (state: any, action) => {
			//프로미스 이행된상태
			if (action.payload.status === "success") {
			} else {
				// state.hasError = true;
			}
			// state.loading = false;
		})
		builder.addCase(getSeriesAll.rejected, (state, action) => {
			//프로미스 거부된상태
			// state.loading = false;
			// state.error = true;
		});
	}
});

export const modalAction = modalSlice.actions;
export default modalSlice.reducer;
