import styled from "styled-components";
import { Portfolio } from "@/pages/portfolio/publisher/types";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "@/modules/utils/filter";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as Gling } from "@/assets/icons/gling_logo.svg";

export default function ListViewContainer({ currList }: ComponentProps) {
    const navigate = useNavigate();
    const random = Math.random();
    
    const goDetail = (c: Portfolio) => {
        navigate(`/portfolio/detail?id=${c.content_id}`);
    }

    return (
        <StyledContainer>
            {currList.map((c, i) => (
                <ListWrapper key={i} onClick={() => goDetail(c)}>
                    <ImgWrapper>
                        {c.coverPath ?
                            <img src={`${c.coverPath}?a=${random}`} alt="cover" /> :
                            (<NoImg>
                                <Gling width={52} height={24} fill={colors.gray300} />
                            </NoImg>)
                        }
                    </ImgWrapper>
                    <InfoWrapper>
                        <TitleWrapper>{c.title}</TitleWrapper>
                        <AuthorPublisherWrapper>{c.author_name} | {c.publisher_name}</AuthorPublisherWrapper>
                        <PublishAtWrapper>{dateFormat("dash", c.publish_at)} 출간</PublishAtWrapper>
                    </InfoWrapper>
                </ListWrapper>
            ))}      
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`

const ListWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 580px;
    height: 120px;
    padding: 10px 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    margin-bottom: 16px;
    cursor: pointer;
`

const ImgWrapper = styled.div`
    width: 72px;
    height: 100px;
    margin-right: 20px;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`

const InfoWrapper = styled.div`
    width: 456px;
    height: 77px;
`

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
    height: 25px;
`

const AuthorPublisherWrapper = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
`

const PublishAtWrapper = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray600};
    margin-top: 10px;
`

const NoImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${colors.gray50};
`

interface ComponentProps {
    currList: Portfolio[];
}