import styled from "styled-components";
import { useAppDispatch } from "@/modules/store";
import { ReactComponent as ClearMark } from "@/assets/icons/clear.svg";
import { ReactComponent as AddMark } from "@/assets/icons/add-data.svg";
import theme from "@/assets/styles/theme";
import TextInput from "@/components/atoms/input/TextInput";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import SelectBox from "@/components/atoms/selectBox/index";
import { authorActions } from "@/pages/settlement/basedata-manage/author/authorSlice";
import Text from "@/components/atoms/text";
import ModalTooltip from "@/components/molecules/common/tooltip/ModalTooltip";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { user, text, ratio, email, UserValidation, Validation } from "@/modules/utils/validate_modify";

export default function SetCoAuthorModalBody({ from, index, tmpJointArray }:SetCoAuthorProps) {
    const dispatch = useAppDispatch();

    const changeValue = async(e: React.ChangeEvent<HTMLInputElement>, key: string, index: number, item: any) => {
        let result:any, changeItem:any;
        let value = e.target.value;
        switch (key) {
            case "email":
                dispatch(authorActions.setTmpJointArrayDatas({
                    index: index, key: key, value: value
                }));
                result = await user({value: value, error: "emailError", field: "이메일", mandatory: false}) as UserValidation;
                changeItem = {...item, [key]: result.value, "emailError": result.emailError, "user_id": result.user_id ? result.user_id : null }
                dispatch(authorActions.setTmpJointArrayDatas({
                    index: index, value: changeItem
                }));
                break;
            case "realName":
                result = text({value: value, error: "realNameError", field: "본명", max: 20, mandatory: true}) as Validation;
                changeItem = {...item, [key]: result.value, "realNameError": result.realNameError }
                dispatch(authorActions.setTmpJointArrayDatas({
                    index: index, value: changeItem
                }));
                break;
            case "taxRatio":
                result = ratio({value: value, error: "taxRatioError", field: "적용세율", decimal: true, mandatory: true}) as Validation;
                changeItem = {...item, [key]: result.value, "taxRatioError": result.taxRatioError }
                dispatch(authorActions.setTmpJointArrayDatas({
                    index: index, value: changeItem
                }));
                break;
            case "stEmail":
                result = email({value: value, error: "stEmailError", field: "이메일", mandatory: false}) as Validation;
                changeItem = {...item, [key]: result.value, "stEmailError": result.stEmailError }
                dispatch(authorActions.setTmpJointArrayDatas({
                    index: index, value: changeItem
                }));
                break;
            default:
                break;
        }
        if (key === "taxRatio") dispatch(authorActions.setAuthorDatas({ key: "needCoDataCheck", value: true }));
    }

    function changeTaxType(value: any, index: number, item: any) {
        let taxRatio:string = "0"
        switch (value.value) {
            case "D":
                taxRatio = "3.3";
                break;
            case "A":
            case "B":
                taxRatio = "0.00";
                break;
            default:
                break;
            } 
        dispatch(authorActions.setTmpJointArrayDatas({
            index: index, value: {...item, "taxType": value.value, "taxTypeError": "", "taxRatio": taxRatio, "taxRatioError": ""}
        }));
        dispatch(authorActions.setAuthorDatas({ key: "needCoDataCheck", value: true }));
    }

    function addRow() {
        dispatch(authorActions.addJointRow());
        dispatch(authorActions.setAuthorDatas({ key: "needCoDataCheck", value: true }));
    }

    async function deleteRow(idx:number) {
        dispatch(authorActions.deleteJointRow(idx));
        dispatch(authorActions.setAuthorDatas({ key: "needCoDataCheck", value: true }));
    }

    const options = [{name: "개인(국내 거주자)", value: "D"},
                     {name: "개인(국내 비거주자)", value: "A"},
                     {name: "기타사업자", value: "B"}];

    return (
        <BodyWrap id="setCoAuthorModalBody">
            <FieldRow>
                <ColumnName>
                    <Text text="글링 아이디(이메일)" customStyle={{ fontSize: "12px" }}/>
                </ColumnName>
                <ColumnName width="140px">
                    <Text text="본명" customStyle={{ fontSize: "12px", margin: "0 8px 0 0"}}/>
                    <ModalTooltip
                        id={"coAuhtor0"}
                        scrollId={"setCoAuthorModalBody"}
                        iconWidth={20} 
                        iconHeight={20} 
                        textBoxWidth={240}
                        fill={theme.colors.gray600} 
                        text="정산서에 표시되는 작가의 본명입니다." 
                    />    
                </ColumnName>
                <ColumnName width="270px">
                    <Text text="적용세율" customStyle={{ fontSize: "12px", margin: "0 8px 0 0"}}/>
                    <ModalTooltip
                        id={"coAuhtor1"}
                        scrollId={"setCoAuthorModalBody"}
                        iconWidth={20} 
                        iconHeight={20} 
                        textBoxWidth={320}
                        fill={theme.colors.gray600} 
                        text={`정산서 지급 시 적용할 원천징수 세율입니다.\n-국내 거주자 (국내에 주소를 두거나 183일 이상 체류 중인 개인) : 3.3%\n-국내 비거주자 (국내 거주자를 제외한 개인) : 국가별 상이\n-기타 사업자 (개인/법인 사업자) : 0%`}
                    />
                </ColumnName>
                <ColumnName width="220px">
                    <Text text="정산서 발급 이메일" customStyle={{ fontSize: "12px", margin: "0 8px 0 0"}}/>
                    <ModalTooltip
                        id={"coAuhtor2"}
                        scrollId={"setCoAuthorModalBody"}
                        iconWidth={20} 
                        iconHeight={20} 
                        textBoxWidth={240}
                        fill={theme.colors.gray600} 
                        text="정산서 메일 전달시 전달 받을 메일 주소입니다."
                    />
                </ColumnName>
            </FieldRow>
            {tmpJointArray.map((j, i) => 
                <ItemRow key={i}>
                    <Item>
                        <ValidateWrapper dataError={j.emailError}>
                            <TextInput
                                value={j.email}
                                placeholder="글링 아이디(이메일)"
                                customStyle={{ width: "200px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                onChangeHandler={(e) => changeValue(e, "email", i, j)}
                            />
                        </ValidateWrapper>
                    </Item>
                    <Item width="140px">
                        <ValidateWrapper dataError={j.realNameError}>
                            <TextInput
                                    value={j.realName}
                                    placeholder="본명"
                                    customStyle={{ width: "120px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                    onChangeHandler={(e) => changeValue(e, "realName", i, j)}
                            />
                        </ValidateWrapper>
                    </Item>
                    <Item width="270px">
                        <ValidateWrapper dataError={j.taxTypeError}>
                            <SelectBox
                                type="modal"
                                modalOptions={{
                                    id: `coAuthor${i}`,
                                    index: i,
                                    scrollModalId: "setCoAuthorModalBody",
                                    emptyHeight: 88,
                                    halfRowEmpty: 14,
                                }}
                                labelKey="name"
                                valueKey="value"
                                selected={j.taxType}
                                onClickValueFunc={(v:any) => changeTaxType(v, i, j)}
                                // onChange={(e) => changeTaxType(e, i, j)}
                                options={options}
                                customStyle={{ width: "140px", height: "32px" }}
                            />
                        </ValidateWrapper>
                        <ValidateWrapper dataError={j.taxRatioError}>
                            <PercentInputText 
                                type="number"
                                inText="%"
                                value={j.taxRatio}
                                customStyle={{width: "120px", height: "32px", padding: "0 18px 2px 0", fontSize: "12px", fontWeight: "400"}}
                                disabled={String(j.taxType) !== "A" ? true : false}
                                onChange={(e) => changeValue(e, "taxRatio", i, j)}
                            />
                        </ValidateWrapper>
                    </Item>
                    <Item width="170px">
                        <ValidateWrapper dataError={j.stEmailError}>
                            <TextInput
                                value={j.stEmail}
                                placeholder="정산서 발급 이메일"
                                customStyle={{ width: "150px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                                onChangeHandler={(e) => changeValue(e, "stEmail", i, j)}
                            />
                        </ValidateWrapper>
                    </Item>
                    { tmpJointArray.length === 1 ?
                    <IconWrap disable={true} />
                    :
                    <IconWrap onClick={() => deleteRow(i)}>
                        <ClearMark width="24px" height="24px" fill={theme.colors.gray600} />
                    </IconWrap>}
                    { i === 0 &&
                        <IconWrap direction="column" onClick={addRow}>
                            <AddMark width="24px" height="24px" fill={theme.colors.blue500} />
                            <Text text="행 추가" customStyle={{ fontSize: "12px", fontWeight: "500", color: theme.colors.blue500 }}/>
                        </IconWrap>
                    }
                </ItemRow>
                )
            }
        </BodyWrap>
    )

}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;
    height: 304px;
    max-height: 298px;
    overflow: auto;

`
const ItemRow = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`

const Item = styled.div<{ width?: string }>`
    display: flex;
    align-items: center;
    width: ${({ width }) => width ? width : "220px"};
    height: 60px;
    font-size: 12px;
    padding: 8px;
`

const IconWrap = styled.div<{ direction?: string, disable?: boolean }>`
    display: flex;
    flex-direction: ${({ direction }) => direction ? direction : "row"};
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 12px;
    padding: 8px;
    cursor: ${({ disable }) => disable ? "default" : "pointer"};
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
`

const ColumnName = styled.div<{ width?: string }>`
    display: flex;
    align-items: center;
    width: ${({ width }) => width ? width : "220px"};
    height: 48px;
    font-size: 12px;
    padding: 8px;
`

interface SetCoAuthorProps {
    from: string;
    index: number;
    tmpJointArray: any[];
}