import { useEffect, useState } from "react";
import styled from "styled-components";

// components
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import SearchInput from "@/components/molecules/basedata-manage/SearchInput";
import TextTooltip from "@/components/molecules/basedata-manage/TextTooltip";

// types
import type { BaseDataSeries, SearchSeriesResult } from "@/types/searchSeries";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import { updateRow } from "@/pages/common/dataTableSlice";
import { excelUploadActions } from "@/pages/settlement/basedata-manage/excelUploadManagement/exceluploadSlice";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";

// hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";

function ExcelUploadTableContainer() {
    const dispatch = useAppDispatch();
    const { setDataCount, setPage, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "excelupload" });

    let { data, totalCnt } = useSelectorTyped((state: RootState) => state.excelupload);
    const [show, setShow] = useState(false);
    const [matchingIdx, setMatchingIdx] = useState(0);
    
    useEffect(() => {
        setDataCount(Number(totalCnt));
        setPage(1);
    }, [itemsPerPage, totalCnt, dispatch])

    function seriesSearchModal(idx:number) {
        let index = ((page-1) * itemsPerPage) + idx;
        dispatch(excelUploadActions.setExcelUploadDatas({ key: "matchingIdx", value: index }));
        setShow(true);
        setMatchingIdx(idx);
    }

    function closeSearchSeriesModal() {
        setShow(false);
    }

    let columns = {
        content_name: ({ item }: TableItemProps) => (
            <TextTooltip text={item["content_name"]} splitedText={item["content_name_list"]} />
        ),
        series_name: ({ item, index }: TableItemProps) => (
            <SearchInput
                type="readOnly"
                value={item["series_name"]}
                placeholder={item["series_name"]}
                onClickHandler={() => seriesSearchModal(index)}
                customStyle={{ width: "280px", fontSize: "12px", fontWeight: "400" }}
            />
        ),
    };
    
    // 시리즈 모달에서 시리즈 선택
    const applySeries = async (props: SearchSeriesResult) => {
        const selectedSeries = props.selectedSeries as BaseDataSeries;
        const matchingIdx = props.matchingIdx;

        const updatedRow = {...data[matchingIdx], "series_id": selectedSeries.id, "series_name": selectedSeries.series_name };
        dispatch(excelUploadActions.setExcelUploadDatas({
            key: "data", index: matchingIdx,
            value: updatedRow
        }))
        dispatch(updateRow({ itemKey: "id", updatedRow: updatedRow }));
    }

    return (
        <>
            <StyledWrapper>
                {data &&
                <>
                    <TableContainer>
                        <DataTable
                            from="excelupload"
                            items={data}
                            headers={headers}
                            columns={columns}
                            itemKey="id"
                        />
                        { data.length !== 0 ? <ScrollToTopBtn /> : <></>}
                    </TableContainer>
                    <Pagination pageCount={pageCount} totalVisible={totalVisible} from={"excelupload"} />
                </>
                }
            </StyledWrapper>
            <ModalPortal>
                <SearchSeriesModal
                    from="basedata"
                    show={show}
                    close={closeSearchSeriesModal}
                    platformId={0}
                    withRatio={false}
                    multiSelect={false}
                    applySeries={applySeries}
                    matchingIdx={matchingIdx}
                />
            </ModalPortal>
        </>
    )
}

export default ExcelUploadTableContainer;

const headers = [
    { text: "판매플랫폼", value: "platform_name", width: "220px" },
    { text: "작품명", value: "content_name", width: "500px" },
    { text: "시리즈명", value: "series_name", width: "400px" },
];

const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
