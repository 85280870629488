import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";

export default function Agreement4() {
    return(
        <StyledContainer>
            <Title>제4장 콘텐츠이용계약의 청약철회, 계약해제·해지 및 이용제한</Title>
            <SubTitle>제26조(&quot;이용자&quot;의 청약철회와 계약해제·해지)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;와 &quot;콘텐츠&quot;의 이용에 관한 계약을 체결한 &quot;이용자&quot;는 수신확인의 통지를 받은 날로부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, &quot;회사&quot;가 다음 각 호중 하나의 조치를 취한 경우에는 &quot;이용자&quot;의 청약철회권이 제한될 수 있습니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 청약의 철회가 불가능한 &quot;콘텐츠&quot;에 대한 사실을 표시사항에 포함한 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. 시용상품을 제공한 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 한시적 또는 일부이용 등의 방법을 제공한 경우</LiStyled>
                </OlStyled>
                <LiStyled>② &quot;이용자&quot;는 다음 각 호의 사유가 있을 때에는 당해 &quot;콘텐츠&quot;를 공급받은 날로부터 3월 이내 또는 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 콘텐츠이용계약을 해제·해지할 수 있습니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 이용계약에서 약정한 &quot;콘텐츠&quot;가 제공되지 않는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. 제공되는 &quot;콘텐츠&quot;가 표시·광고 등과 상이하거나 현저한 차이가 있는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 기타 &quot;콘텐츠&quot;의 결함으로 정상적인 이용이 현저히 불가능한 경우</LiStyled>
                </OlStyled>
                <LiStyled>③ 제1항의 청약철회와 제2항의 계약해제·해지는 &quot;이용자&quot;가 전화, 전자우편 또는 모사전송으로 &quot;회사&quot;에 그 의사를 표시한 때에 효력이 발생합니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;는 제3항에 따라 &quot;이용자&quot;가 표시한 청약철회 또는 계약해제·해지의 의사표시를 수신한 후 지체 없이 이러한 사실을 &quot;이용자&quot;에게 회신합니다.</LiStyled>
                <LiStyled>⑤ &quot;이용자&quot;는 제2항의 사유로 계약해제·해지의 의사표시를 하기 전에 상당한 기간을 정하여 완전한 &quot;콘텐츠&quot; 혹은 서비스이용의 하자에 대한 치유를 요구할 수 있습니다.</LiStyled>
            </UlStyled>
            <SubTitle>제27조(&quot;이용자&quot;의 청약철회와 계약해제·해지의 효과)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 &quot;이용자&quot;가 청약철회의 의사표시를 한 날로부터 또는 &quot;이용자&quot;에게 계약해제·해지의 의사표시에 대하여 회신한 날로부터 3영업일 이내에 대금의 결제와 동일한 방법으로 이를 환급하여야 하며, 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지하여야 합니다. 이 경우 &quot;회사&quot;가 &quot;이용자&quot;에게 환급을 지연한 때에는 그 지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;가 제1항에 따라 환급할 경우에 &quot;이용자&quot;가 서비스 이용으로부터 얻은 이익에 해당하는 금액을 공제하고 환급할 수 있습니다.</LiStyled>
                <LiStyled>③ &quot;회사&quot;는 위 대금을 환급함에 있어서 &quot;이용자&quot;가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 제2항의 금액공제가 필요한 경우에는 그러하지 아니할 수 있습니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;, &quot;콘텐츠 등의 대금을 지급 받은 자&quot; 또는 &quot;이용자와 콘텐츠이용계약을 체결한 자&quot;가 동일인이 아닌 경우에 각자는 청약철회 또는 계약해제·해지로 인한 대금환급과 관련한 의무의 이행에 있어서 연대하여 책임을 집니다.</LiStyled>
                <LiStyled>⑤ &quot;회사&quot;는 &quot;이용자&quot;에게 청약철회를 이유로 위약금 또는 손해배상을 청구하지 않습니다. 그러나 &quot;이용자&quot;의 계약해제·해지는 손해배상의 청구에 영향을 미치지 않습니다.</LiStyled>
            </UlStyled>
            <SubTitle>제28조(회사의 계약해제·해지 및 이용제한)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 &quot;이용자&quot;가 제12조 제2항에서 정한 행위를 하였을 경우 사전통지 없이 계약을 해제·해지하거나 또는 기간을 정하여 서비스이용을 제한할 수 있습니다.</LiStyled>
                <LiStyled>② 제1항의 해제·해지는 &quot;회사&quot;가 자신이 정한 통지방법에 따라 &quot;이용자&quot;에게 그 의사를 표시한 때에 효력이 발생합니다.</LiStyled>
                <LiStyled>③ &quot;회사&quot;의 해제·해지 및 이용제한에 대하여 &quot;이용자&quot;는 &quot;회사&quot;가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 &quot;회사&quot;가 인정하는 경우, &quot;회사&quot;는 즉시 서비스의 이용을 재개합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제29조(회사의 계약해제·해지의 효과)</SubTitle>
            <UlStyled>
                <LiStyled>&quot;이용자&quot;의 귀책사유에 따른 이용계약의 해제·해지의 효과는 제27조를 준용합니다. 다만, &quot;회사&quot;는 &quot;이용자&quot;에 대하여 계약해제·해지의 의사표시를 한 날로부터 14영업일 이내에 대금의 결제와 동일한 방법으로 이를 환급합니다.</LiStyled>
            </UlStyled>
        </StyledContainer>
        
    )
}

const StyledContainer = styled.div`

`

const Title = styled.h4`
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 40px;
    width: auto;
`

const SubTitle = styled.h6`
    font-weight: 700;
    line-height: 20px;
    margin: 16px 0px;
`

const UlStyled = styled.ul`
`

const LiStyled = styled.li`
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${colors.gray600}
`

const OlStyled = styled.ol`
    margin: 16px 0px;
`