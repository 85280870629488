import { useState } from "react";
import styled from "styled-components";
import Text from "@/components/atoms/text/index";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import SideMenu from "@/components/molecules/landing/guide/SideMenu";
import View from "@/components/molecules/landing/guide/View";

export default function Guide() {
    const [menu, setMenu] = useState({page: "dashboard", name: "대시보드"});

    const clickSubMenu = (menu: Menu) => setMenu(menu);

    return (
        <SectionLayout>
            <Text text="이용 가이드" customStyle={{ fontSize: "20px", fontWeight: "700" }} />
            <GuideBody>
                <SideMenu menu={menu} onClick={clickSubMenu} />
                <View menu={menu} />
            </GuideBody>
        </SectionLayout>
    );
}

const GuideBody = styled.div`
    display: flex;
    margin-top: 24px;
`;

interface Menu {
    page: string;
    name: string;
}
