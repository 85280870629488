import styled from "styled-components";
import DashboardTable from "@/components/molecules/dashboard/author/DashboardTable";
import { useAppSelector, RootState } from "@/modules/store";
import { Oval } from "react-loader-spinner";
import { colors } from "@/assets/styles/theme";
import Text from "@/components/atoms/text";

export default function DailyContentAnalysisTableContainer() {
    const { dailySeriesAnalysisData, dailySeriesAnalysisLoading } = useAppSelector((state: RootState) => state.authorDashboard);

    if (dailySeriesAnalysisLoading) {
        return (
            <StyledContainer>
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
            </StyledContainer>
        )
    }

    const noDataTextStyle = {
        fontSize: "16px",
        textAlign: "center",
        margin: "160px 0 0 0",
        color: colors.gray500
    }

    if (!dailySeriesAnalysisData.length) {
        return (
            <StyledContainer>
                <Text text="전일 판매내역이 존재하지 않습니다." customStyle={noDataTextStyle} />
            </StyledContainer>
        )
    }

    const headers = [
        { text: "순위", value: "rank", width: "70px", align: "center" },
        { text: "작품명", value: "series_name", width: "240px" },
        { text: "저자명", value: "author_name", width: "100px", align: "center" },
        { text: "최대매출", value: "platform_name", width: "150px", align: "center" },
        { text: "판매량", value: "quantity", width: "80px", align: "center" },
        { text: "판매금액(원)", value: "amount_label", width: "160px", align: "right" },
    ];

    return (
        <StyledContainer>
            <DashboardTable headers={headers} items={dailySeriesAnalysisData.slice(0,30)} />
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 808px;
    height: 348px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`