/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setFilterOption, setError, resetStore, setReset } from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";
import FilterContainer from "@/components/molecules/common/searchFilter/FilterContainer";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import DateRangePicker from "@/components/molecules/sales-inquiry/common/DateRangePicker";
import DateSelector from "@/components/molecules/common/form/DateSelector";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import Slider from "@/components/molecules/common/slider/Slider";
import useValidateDate from "@/modules/hooks/useValidateDate";
import { getEndDay, getStartDay, getThisMonth } from "@/modules/utils/date";
import MgSearchBar from "./MgSearchBar";
import usePlatformSetting from "../hooks/usePlatformSetting";

export default function MgSearchFilter() {
    const {
        contractStartDate,
        contractEndDate,
        complete,
        sliderMaxValue,
        sliderMinValue,
        startDateError,
        endDateError,
        reset,
    } = useSelectorTyped((state: RootState) => state.inquiryMg);
    const dispatch = useAppDispatch();
    const [showMore, setShowMore] = useState(false);
    const { platformList, platformIds } = usePlatformSetting();
    const { validateStartDate, validateEndDate } = useValidateDate();
    const initialTotalDate = getThisMonth();
    const initialContractStartDate = getStartDay("");
    const initialContractEndDate = getEndDay("");

    // 초기화
    useEffect(() => {
        dispatch(
            setFilterOption({
                totalDate: initialTotalDate,
                contractStartDate: initialContractStartDate,
                contractEndDate: initialContractEndDate,
            }),
        );
        return () => {
            dispatch(resetStore());
        };
    }, []);

    // 플랫폼리스트 초기화
    useEffect(() => {
        dispatch(setFilterOption({ selectedPlatform: platformIds }));
    }, [platformList]);

    useEffect(() => {
        if (reset) dispatch(setReset(false));
    }, [reset]);

    const clickPlusIcon = () => setShowMore((prev) => !prev);

    const clickResetButton = () => {
        dispatch(setReset(true));
        dispatch(
            setFilterOption({
                target: "",
                totalDate: initialTotalDate,
                mgType: "platform",
                selectedPlatform: platformIds,
                contractStartDate: initialContractStartDate,
                contractEndDate: initialContractEndDate,
                complete: "all",
                contractType: "all",
                contractPrice: { start: "", end: "" },
                deductionPrice: { start: "", end: "" },
                remainPrice: { start: "", end: "" },
            }),
        );
        dispatch(setError({ startDateError: "", endDateError: "" }));
    };

    const changeTotalDate = (date: string) => dispatch(setFilterOption({ totalDate: date }));

    const changeMgType = (id: string) => dispatch(setFilterOption({ mgType: id }));

    const changePlatform = (ids: number[]) => dispatch(setFilterOption({ selectedPlatform: ids }));

    const changeComplete = (id: string) => dispatch(setFilterOption({ complete: id }));

    const changeContract = (id: string) => dispatch(setFilterOption({ contractType: id }));

    const changeContractStartDate = (date: string) => {
        const totalDate = `${date}01`;
        const { startDateError, endDateError } = validateStartDate(date, contractEndDate);
        dispatch(setFilterOption({ contractStartDate: totalDate }));
        dispatch(setError({ startDateError, endDateError }));
    };

    const changeContracEndDate = (date: string) => {
        const year = Number(date.slice(0, 4));
        const month = Number(date.slice(4, 6));
        const lastDay = new Date(year, month, 0).getDate();
        const totalDate = `${date}${lastDay}`;
        const { startDateError, endDateError } = validateEndDate(totalDate, contractStartDate);

        dispatch(setFilterOption({ contractEndDate: totalDate }));
        dispatch(setError({ startDateError, endDateError }));
    };

    const changeContractPrice = (start: number, end: number) => {
        dispatch(setFilterOption({ contractPrice: { start, end } }));
    };

    const changeDeductionPrice = (start: number, end: number) => {
        dispatch(setFilterOption({ deductionPrice: { start, end } }));
    };

    const changeRemainPrice = (start: number, end: number) => {
        dispatch(setFilterOption({ remainPrice: { start, end } }));
    };

    return (
        <FilterContainer>
            <FilterRow
                label={"검색"}
                filterContent={[<MgSearchBar />]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="정산월"
                filterContent={[
                    <DateSelector onChangeHandler={changeTotalDate} />,
                    <ResetFilter onClick={clickResetButton} />,
                ]}
            />
            <FilterRow
                label="선인세 구분"
                filterContent={[
                    <TagFilter
                        type="radio"
                        items={mgTypeItems}
                        keyName="id"
                        valueName="name"
                        showMore={false}
                        onChange={changeMgType}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMore, onClick: clickPlusIcon }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={platformList}
                        keyName={"platform_id"}
                        valueName={"platform_name"}
                        onChange={changePlatform}
                        showMore={showMore}
                        reset={reset}
                    />,
                ]}
            />
            {/* <FilterRow
                label="계약 시작일"
                filterContent={[
                    <DateRangePicker
                        type="yearAndMonth"
                        changeStartDate={changeContractStartDate}
                        changeEndDate={changeContracEndDate}
                        startDateToSet={contractStartDate}
                        endDateToSet={contractEndDate}
                        startDateError={startDateError}
                        endDateError={endDateError}
                    />,
                ]}
            /> */}
            <FilterRow
                label="소진여부"
                filterContent={[
                    <TagFilter
                        type="radio"
                        items={mgCompleteItems}
                        keyName="id"
                        valueName="name"
                        showMore={false}
                        onChange={changeComplete}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="계약형태"
                filterContent={[
                    <TagFilter
                        type="radio"
                        items={mgContractItems}
                        keyName="id"
                        valueName="name"
                        showMore={false}
                        onChange={changeContract}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="계약금액"
                filterContent={[
                    <Slider
                        min={sliderMinValue.contractPrice}
                        max={sliderMaxValue.contractPrice}
                        reset={reset}
                        onChange={changeContractPrice}
                    />,
                ]}
            />
            {complete !== "complete" ? (
                <>
                    <FilterRow
                        label="소진금액"
                        filterContent={[
                            <Slider
                                min={sliderMinValue.deductionPrice}
                                max={sliderMaxValue.deductionPrice}
                                reset={reset}
                                onChange={changeDeductionPrice}
                            />,
                        ]}
                    />
                    <FilterRow
                        label="잔여금액"
                        filterContent={[
                            <Slider
                                min={sliderMinValue.remainPrice}
                                max={sliderMaxValue.remainPrice}
                                reset={reset}
                                onChange={changeRemainPrice}
                            />,
                        ]}
                    />
                </>
            ) : (
                <></>
            )}
        </FilterContainer>
    );
}

// 선인세 타입
const mgTypeItems = [
    {
        id: "platform",
        name: "플랫폼 선인세",
    },
    {
        id: "publisher",
        name: "출판사 선인세",
        disabled: true,
    },
];

// 선인세 소진 여부
const mgCompleteItems = [
    {
        id: "all",
        name: "전체",
    },
    {
        id: "incomplete",
        name: "소진미완료",
    },
    {
        id: "complete",
        name: "소진완료",
    },
];

// 선인세 계약
const mgContractItems = [
    {
        id: "all",
        name: "전체",
    },
    {
        id: "MG01",
        name: "작가계약",
    },
    {
        id: "MG02",
        name: "작품계약",
    },
];
