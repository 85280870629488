
import styled from 'styled-components';
import HomeSectionLayout from "@/components/molecules/home/HomeSectionLayout"
import QuickItem from "@/components/molecules/home/quick-menu/QuickItem"
import { authorQuickDatas } from "@/components/molecules/home/quick-menu/QuickData"
import { publisherQuickDatas } from "@/components/molecules/home/quick-menu/QuickData"
import { useAppSelector ,RootState } from '@/modules/store';

export default function QuickMenu() {
    
    const { isPublisher } = useAppSelector((state: RootState) => state.user);
    const quickDatas = isPublisher ? publisherQuickDatas : authorQuickDatas;

    return (
        <HomeSectionLayout>
            <StyledContainer>
                { quickDatas.map((quickData)=> 
                    <QuickItem
                        key={quickData.id}
                        Svg={quickData.svg}
                        title={quickData.title}
                        description={quickData.description}
                        titleStyle={quickData.titleStyle}
                        descriptionStyle={quickData.descriptionStyle}
                        buttonPath={quickData.buttonPath}
                    />
                )}
            </StyledContainer>
        </HomeSectionLayout>
    )
}

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 38px 0;
`;
