import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import Text from "@/components/atoms/text";
import { useEffect } from "react";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { getPortfolioList } from "@/pages/portfolio/publisher/slice";

export default function CirculationErrListModalBody() {
    const { errorList } = useAppSelector((state: RootState) => state.pubPortfolio);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    useEffect(() => {
        return () => {
            close();
        }
    }, [])

    const close = async () => {
        try {
            loadingOn();
            dispatch({ type: "pubPortfolio/setErrorList", payload: [] });
            await dispatch(getPortfolioList()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }

    }

    return (
        <BodyWrap>
            <GuideWrap>
                <Text 
                    text="※ 엑셀 파일에서 다음 항목들을 수정 후 재업로드 해주세요."
                    customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px" }}
                />
            </GuideWrap>
            <ErrWrap>
                <Header>
                    <Item width="48px" justifyContent="center">행</Item>
                    <Item>필드</Item>
                    <Item>오류 내용</Item>
                </Header>
                {errorList.map((err, i) =>(
                    <List key={i}>
                        <Item width="48px" justifyContent="center" fontWeight="400">{err.row}</Item>
                        <Item fontWeight="400">{err.field}</Item>
                        <Item lineHeight="15px" fontWeight="400">{err.message}</Item>
                    </List>
                ))}
            </ErrWrap>
        </BodyWrap>
    )

}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px;
    height: 340px;
    max-height: 340px;
`
const GuideWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
`

const ErrWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-itmes: center;
    height: 288px;
    max-height: 288px;
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    min-height: 48px;
    height: 48px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`

const List = styled.div`
    display: flex;
    min-height: 48px;
    height: 48px;
`

const Item = styled.div<{ width?: string, justifyContent?: string, fontWeight?: string, lineHeight?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "200px"};
    padding: 0 8px;
    font-size: 12px;
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : "500"};
    line-height: ${({ lineHeight }) => lineHeight ? lineHeight : "12px"};
`