import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import { colors } from "@/assets/styles/theme";
import React, { useEffect, useState } from "react";
import { signupActions } from "@/pages/auth/signUp/signupSlice";

export default function StepBox() {
    const dispatch = useAppDispatch();
    const { publisherJoin, step, fromSocial } = useAppSelector((state: RootState) => state.signup);
    const [stepArr, setStepArr] = useState(["아이디/비밀\n번호 입력", "기본 정보\n입력", "회원 가입\n완료"]);

    useEffect(() => {
        if (publisherJoin) {
            setStepArr(["아이디/비밀\n번호 입력", "기본 정보\n입력", "출판사 등록\n확인", "출판사 정보\n입력", "회원 가입\n완료"]);
        }
    }, [publisherJoin])

    function changeStep(i:number) {
        if (step >= i) {
            if (fromSocial && i === 0) return;
            dispatch(signupActions.changeStep(i));
        }
    }

    return (
        <StepBoxContainer type={publisherJoin ? "publish" : "author"}>
            {stepArr.map((s, i) => (
                <TextWrap key={i} type={publisherJoin ? "publish" : "author"}>
                    <StepText color={step === i ? colors.blue500 : colors.gray600} cursor={step >= i ? "pointer" : "default"} onClick={()=>changeStep(i)}>
                        {s}
                    </StepText>
                    {((publisherJoin && i !== 4) || (!publisherJoin && i !== 2)) &&
                        <StepText className="arrow" color={step === i ? colors.blue500 : colors.gray600} cursor={step >= i ? "pointer" : "default"} onClick={()=>changeStep(i)}>
                            {">"}
                        </StepText>
                    }
                </TextWrap>
            ))}
        </StepBoxContainer>
    );
}

const StepBoxContainer = styled.div<{ type?: string }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    height: 40px;
    margin-bottom: 40px;
    padding: ${({ type }) => type === "publish" ? "0" : "0 60px"};
`;

const StepText = styled.div<{ color: string, cursor: string }>`
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    white-space: pre-line;
    color: ${({ color }) => color };
    cursor: ${({ cursor }) => cursor };
`

const TextWrap = styled.div<{ type?: string }>`
    display: flex;
    align-items: center;
    .arrow {
        padding: ${({ type }) => type === "publish" ? "0 6px" : "0 20px"};
    }
`