import { useState, useEffect } from "react";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { inquiryMonthlyActions } from "@/pages/settlement/saels-inquiry/publisher/monthly/monthlySlice";
import FilterContainer from "@/components/molecules/common/searchFilter/FilterContainer";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import DateRangePicker from "@/components/molecules/sales-inquiry/common/DateRangePicker";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import MonthlySearchBar from "./MonthlySearchBar";
import useValidateDate from "@/modules/hooks/useValidateDate";
import { getThisMonth } from "@/modules/utils/date";

function MonthlySearchFilter() {
    const platformList = useSelectorTyped((state: RootState) => state.platform.platformList);
    const { startDate, endDate, startDateError, endDateError, reset } = useSelectorTyped(
        (state: RootState) => state.inquiryMonthly,
    );
    const { setStartDate, setEndDate, setPlatforms, setStartDateError, setEndDateError, setReset, resetStore } =
        inquiryMonthlyActions;
    const dispatch = useAppDispatch();
    const [showMore, setShowMore] = useState(false);
    const initialDate = getThisMonth();
    const { validateStartDate, validateEndDate } = useValidateDate();

    // 초기화
    useEffect(() => {
        dispatch(setStartDate(initialDate));
        dispatch(setEndDate(initialDate));
        return () => {
            dispatch(resetStore());
        };
    }, []);

    // 리셋
    useEffect(() => {
        if (reset) setReset(false);
    }, [reset]);

    // 필터 변경 이벤트
    const clickPlusIcon = () => setShowMore((prev) => !prev);

    const clickResetButton = () => {
        dispatch(setReset(true));
        dispatch(setStartDate(initialDate));
        dispatch(setEndDate(initialDate));
        dispatch(setStartDateError(""));
        dispatch(setEndDateError(""));
        dispatch(setPlatforms([]));
    };

    // 디스패치 액션
    const changeStartDate = (date: string) => {
        const { startDateError, endDateError } = validateStartDate(date, endDate);
        dispatch(setStartDate(date));
        dispatch(setStartDateError(startDateError));
        dispatch(setEndDateError(endDateError));
    };

    const changeEndDate = (date: string) => {
        const { startDateError, endDateError } = validateEndDate(date, startDate);
        dispatch(setEndDate(date));
        dispatch(setStartDateError(startDateError));
        dispatch(setEndDateError(endDateError));
    };

    const setPlatform = (id: number[]) => dispatch(setPlatforms(id));

    return (
        <FilterContainer>
            <FilterRow
                label={"검색"}
                filterContent={[<MonthlySearchBar />]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="정산 월"
                filterContent={[
                    <DateRangePicker
                        type="yearAndMonth"
                        changeStartDate={changeStartDate}
                        changeEndDate={changeEndDate}
                        startDateToSet={startDate}
                        endDateToSet={endDate}
                        startDateError={startDateError}
                        endDateError={endDateError}
                    />,
                    <ResetFilter onClick={clickResetButton} />,
                ]}
            />
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMore, onClick: clickPlusIcon }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={platformList}
                        keyName={"platform_id"}
                        valueName={"platform_name"}
                        onChange={setPlatform}
                        showMore={showMore}
                        reset={reset}
                    />,
                ]}
            />
        </FilterContainer>
    );
}

export default MonthlySearchFilter;
