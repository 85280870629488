import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from '@/assets/styles/theme';
import MemoizedCheckbox from "@/components/atoms/input/Checkbox";
import { useState, ChangeEvent } from "react";
import Button from "@/components/atoms/button";
import BaseInput from "../../signup/BaseInput"
import { ReactComponent as RightArrowIcon} from "@/assets/icons/right-arrow.svg"
import useAlert from "@/modules/hooks/useAlert";
import { useAppDispatch } from "@/modules/store";
import { sendConsultEmail } from "@/pages/landing/slice";
import useLoading from "@/modules/hooks/useLoading";
import { useNavigate } from "react-router-dom";

export default function ConsultContent() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        name: "",
        company: "",
        mobile: "",
        email: "",
    });

    const [nameError, setNameError] = useState("");
    const [companyError, setCompanyError] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [emailError, setEmailError] = useState("");
    const workNumber = ['1 ~ 99', '100 ~ 299', '300 ~ 999', '1000 ~ 4999', '5000 ~ 9999', '10000이상']
    const [isClicked, setIsClicked] = useState([false, false, false, false, false, false]);

    const [textArea, setTextArea] = useState("")
    const [isChecked, setIsChecked] = useState(false);
    
    const checkText1 = {id: 0, text: '상담 및 문의안내를 위해 글링의'};
    const checkText2 = {id: 1, text: '개인정보처리방침' }
    const checkText3 = {id: 2, text: '에 동의합니다.(필수)' }

    const changeHandlerName = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputs({...inputs, name: value});

        if (value.length > 1) {
            setNameError("");
        } else {
            setNameError("이름을 입력해주세요.");
        }
    }

    const formatPhoneNumber = (value: string) => {
        if(value === "") return value;
        
        const phoneNumber = value.replace(/[^\d]/g,'');
        const phoneNumberLength = phoneNumber.length;

        if(phoneNumberLength < 4) return phoneNumber;

        if(phoneNumberLength < 8) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }

        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
    }

    const changeHandlerCompany = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputs({...inputs, company: value});

        if (value.length > 1) {
            setCompanyError("");
        } else {
            setCompanyError("회사명을 입력해주세요.");
        }
    }

    const changeHandlerMobile = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const formateValue = formatPhoneNumber(value);
        setInputs({...inputs, mobile: formateValue});

        if (value.length > 1) {
            setMobileError("");
        } else {
            setMobileError("연락처를 입력해주세요.");
        }
    }

    function changeHandlerEmail(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        setInputs({...inputs, email: value});
        
        if (/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/i.test(value)) {
            setEmailError("");
        } else {
            setEmailError("이메일 형식을 확인해주세요.");
        }
    }

    function keyPressHandler(key: any) {
        if (key === "Enter") {
            next();
        }
    };

    async function next() {
        let hasError = false;
        if (!inputs.name) {
            setNameError("이름을 입력해주세요.");
            hasError = true;
        }
        if (!inputs.company) {
            setCompanyError("회사명을 입력해주세요.");
            hasError = true;
        }
        if (!inputs.mobile) {
            setMobileError("연락처를 입력해주세요.");
            hasError = true;
        }
        if (!inputs.email) {
            setEmailError("수신 이메일을 입력해주세요.");
            hasError = true;
        }
        return hasError;
    }

    function onChange(num: number) {
        const newIsClick = isClicked.map((d, i) => i === num ? true : false);
        setIsClicked(newIsClick);
    }

    function changeTextArea(e: ChangeEvent<HTMLTextAreaElement>) {
        let value = e.target.value;
        setTextArea(value);
    }

    async function submitHandler() {
        const hasError = await next();
        if (hasError) return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");

        const navBarIndex = isClicked.indexOf(true);
        if(navBarIndex === -1) {
            return await alert("warning", "주의", "관리 작품수를 선택해주세요.");
        }
        if(isChecked === false) {
            return await alert("warning", "주의", "개인정보처리방침에 동의가 필요합니다.")
        }
        if(textArea === "") {
            return await alert("warning", "주의", "도입목적이나 기 타 문의사항을 적어주세요.");
        }

        const data = {
            name: inputs.name,
            companyName: inputs.company,
            phoneNumber: inputs.mobile,
            email: inputs.email,
            workNumber: workNumber[navBarIndex],
            message: textArea,
            consentValue: isChecked
        }

        await alert("confirm", "이메일 전송", "이메일을 전송하시겠습니까?");
        try {
            loadingOn();
            await dispatch(sendConsultEmail(data)).unwrap();
        } catch(err: any) {
            await alert("error", "오류발생", "전송 중 오류가 발생하였습니다. 지속적인 오류 발생 시 help@gling.co.kr로 문의바랍니다.")
        } finally {
            loadingOff();
            navigate("/consult/sendComplete");
        }
    }

    const checkTextStyled = {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '14px',   
    }
    const buttonBoxStyled = {
        margin: '0px 8px 0px 0px'
    }

    return (
        <StyledContainer>
            <InfoContainerStyled>
                <SubStyled>
                    <BaseInput
                        label="이름"
                        value={inputs.name}
                        placeholder="문의하시는 분의 성함을 적어주세요."
                        errorMsg={nameError}
                        onChange={changeHandlerName}
                        onKeyPress={keyPressHandler}
                    />
                    <BaseInput
                        label="회사명"
                        value={inputs.company}
                        placeholder="회사 또는 단체명을 입력해주세요."
                        errorMsg={companyError}
                        onChange={changeHandlerCompany}
                        onKeyPress={keyPressHandler}
                    />
                </SubStyled>
                <SubStyled>
                    <BaseInput
                        label="담당자 연락처"
                        value={inputs.mobile}
                        placeholder="010-1234-5678"
                        errorMsg={mobileError}
                        onChange={changeHandlerMobile}
                        onKeyPress={keyPressHandler}
                    />
                    <BaseInput
                        label="이메일"
                        value={inputs.email}
                        placeholder="example@gling.co.kr"
                        errorMsg={emailError}
                        onChange={changeHandlerEmail}
                        onKeyPress={keyPressHandler}
                    />
                </SubStyled>
            </InfoContainerStyled>
            <SeriesCountContainerStyled>
                <Text text="관리 작품수" customStyle={{fontWeight:"500", fontSize:"14px", lineHeight: "17.5px", color: colors.gray600, width: '68px'}}></Text>
                <SeriesCountButtonContainerStyled>
                    {workNumber.map((data, i) => (
                        <Button key={i} type={isClicked[i] ? "main-outline" : "gray-outline"} label={data} onClickHandler={() => onChange(i)} customStyle={buttonBoxStyled}/>
                    ))}
                </SeriesCountButtonContainerStyled>
            </SeriesCountContainerStyled>
             <QuestionContainerStyled>
                <Text text="도입목적 및 문의사항" customStyle={{fontWeight:"500", fontSize:"14px", lineHeight: "17.5px", color: colors.gray600, width: "123px"}}></Text>
                <TextArea placeholder="도입목적이나 기타 문의 사항을 적어주세요." onChange={changeTextArea}></TextArea>
                <CheckboxContainer onClick={() => setIsChecked(!isChecked)}>
                    <MemoizedCheckbox type="boolean" active={isChecked} onChangeHandler={() => setIsChecked(!isChecked)} />
                    <Text key={checkText1.id} text={checkText1.text} customStyle={{...checkTextStyled, color: colors.gray600, margin: "0px 4px 0px 8px"}} />
                    <Text key={checkText2.id} text={checkText2.text} customStyle={{...checkTextStyled, color: colors.blue500}} />
                    <Text key={checkText3.id} text={checkText3.text} customStyle={{...checkTextStyled, color: colors.gray600}} />
                </CheckboxContainer>
                <ButtonContainerStyled>
                    <ButtonStyled onClick={submitHandler}>
                        문의글 전송하기
                        <RightArrowIcon size={24}/>
                    </ButtonStyled>
                </ButtonContainerStyled>
            </QuestionContainerStyled>
        </StyledContainer>
        
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const InfoContainerStyled = styled.div`
    width: 900px;
`

const SubStyled = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 40px 0px;
`

const SeriesCountContainerStyled = styled.div`

`

const SeriesCountButtonContainerStyled = styled.div`
    display: flex;
    margin-top: 8px;
    width: 610px;
`

const QuestionContainerStyled = styled.div`
    margin-top: 40px;
`

const TextArea = styled.textarea`
    background-color: ${colors.gray50};
    width: 900px;
    height: 200px;
    border: 1px solid ${colors.gray300};
    resize: none;
    font-size: 14px;
    font-weight: 400;
    color: ${colors.gray600};
    margin-top: 8px;
    border-radius: 4px;
`

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
    height: 40px;
    width: 430px;
`

const ButtonContainerStyled = styled.div`
    display: flex;
    width: 900px;
    justify-content: flex-end;
`

const ButtonStyled = styled.button`
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.black};
    background: ${colors.white};
    border-radius: 4px;
    margin-bottom: 33px;
    cursor: pointer;
`