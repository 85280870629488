import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "@/api";
import { MypageState } from "./types";

const initialMypageState: MypageState = {
    userProfile: {
        isLogin: false,
        isPublisher: false,
        sociallogin_provider: "",
        user_created_at: "",
        user_email: "",
        user_nickname: "",
        user_pic_path: "",
        user_status: "",
        user_mobile: "",
        user_emailagree: 0,
        user_id: 0,
        user_sex: "",
        kcp_di: "",
        user_adult: 0,
        user_birth_dt: "",
    },

    password: {
        userInfo: {
            user_id: 0
        },
        user_original_pw: "",
        user_pw: "",
    },

    questionData: {
        inquiryList: [],
    },

    resignAnswer: "",
    resignComplete: false,
}

export const updatePassword = createAsyncThunk("user/password", async(apiParams: MypageState["password"], { rejectWithValue }) => {
    try {
        return (await api.post(`/api/user/mypage/password/update`, apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const deleteUser = createAsyncThunk(`user/delete`, async(user_id: number, thunkAPI) => {

    try {
        const res = await api.post(`/api/author/mypage/deleteAuthorAccount`);
        return res.data;
    } catch (err: any) {
        throw thunkAPI.rejectWithValue(err.response.data);
    }
});

export const myPageSlice = createSlice({
    name: "mypage",
    initialState: initialMypageState,
    reducers: {
        updateMobile: (state, action: PayloadAction<any>) => {
            state.userProfile.user_mobile = `${action.payload.user_mobile}`;
            state.userProfile.user_birth_dt = `${action.payload.user_birth_dt}`;
            state.userProfile.user_sex = `${action.payload.user_sex}`;
            state.userProfile.kcp_di = `${action.payload.kcp_di}`;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.resignComplete = true;
            } else {
                state.resignComplete = false;
            }
        });
    }
});

export const myPageActions = myPageSlice.actions;
export default myPageSlice.reducer;