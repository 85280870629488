import styled from "styled-components";
import React, { useEffect } from "react";
import useLoading from "@/modules/hooks/useLoading";

export default function TableSkeleton() {
    const { loadingOn, loadingOff } = useLoading();
    useEffect(() => {
        loadingOn("table");
        return() => {
            loadingOff();
        }
    }, [])
    const headers = [{ width: "100px", hskWidth: "57px", skWidth: "57px" },
                    { width: "100px", hskWidth: "33px", skWidth: "65px" },
                    { width: "100px", hskWidth: "65px", skWidth: "65px" },
                    { width: "240px", hskWidth: "80px", skWidth: "150px" },
                    { width: "100px", hskWidth: "80px", skWidth: "80px" },
                    { width: "80px", hskWidth: "57px", skWidth: "57px" },
                    { width: "100px", hskWidth: "45px", skWidth: "70px" },
                    { width: "120px", hskWidth: "57px", skWidth: "57px" },
                    { width: "80px", hskWidth: "33px", skWidth: "33px" },
                    { width: "100px", hskWidth: "60px", skWidth: "60px" }]
    return (
        <React.Fragment>
            <Table>
                <colgroup
                    span={11}
                    style={{
                        minWidth: "50px",
                    }}
                ></colgroup>
                <Header>
                    <HeaderTr>
                        <CheckboxTh>
                            <Checkbox />
                        </CheckboxTh>
                        {headers.map((header, i) => (
                            <Th key={i} width={header.width} onClick={() => {}}>
                                <ThContent>
                                    <ThContentHeader>
                                        <SkeletonContainer width={header.hskWidth} height="20px">
                                            <SkeletonAnimation width={header.skWidth} height="20px" />
                                        </SkeletonContainer>
                                    </ThContentHeader>
                                </ThContent>
                            </Th>
                        ))}
                    </HeaderTr>
                </Header>
                <Body>
                    {[0,1,2,3,4].map((item, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <Tr>
                                    <CheckboxTd>
                                        <Checkbox />
                                    </CheckboxTd>
                                    {headers.map((h, i) => {
                                        return (
                                            <Td key={i}>
                                                <SkeletonContainer width={h.skWidth} height="20px">
                                                    <SkeletonAnimation width={h.skWidth} height="20px" />
                                                </SkeletonContainer>
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            </React.Fragment>
                        );
                    })}
                </Body>
            </Table>
        </React.Fragment>
    )
}

const SkeletonContainer = styled.div<{ width?: string, height?: string }>`
    width: ${({ width }) => width ? width : "320px"};
    height: ${({ height }) => height ? height : "14px"};
    background-image: -webkit-linear-gradient( lightgray ${({ height }) => height ? height : "14px"}, transparent 0 );
`

const SkeletonAnimation = styled.div<{ width?: string, height?: string }>`
    animation: placeHolderShimmer 1s linear forwards infinite;
    // background: lightgray;
    // background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
    background: #f1f3f5;
    background-image: linear-gradient(to right, #e9ecef 0%, #dee2e6 20%, #e9ecef 40%, #e9ecef 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: ${({ height }) => height ? height : "14px"};
    position: relative;
    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0
        }
        100% {
            background-position: 468px 0
        }
    }
`

// const SkeletonAnimation = styled.div<{ width?: string, height?: string }>`
//     width: ${({ width }) => width ? width : "320px"};
//     height: ${({ height }) => height ? height : "14px"};
//     position: absolute;
//     animation: skeleton 1.5s ease-in infinite;
//     @keyframes skeleton {
//         0% {
//             transform: translateX(0);
//             opacity: 0;
//         }
      
//         20% {
//             opacity: 0.25;
//         }
      
//         50% {
//             opacity: 1;
//         }
      
//         80% {
//             opacity: 0.5;
//         }
      
//         100% {
//             transform: translateX(100%);
//             opacity: 0;
//         }
//     }
//     // box-shadow: 0 0 75px ${({ height }) => height ? height : "14px"} white;
//     box-shadow: inset 0 0 30px 20px white;
// `

const Table = styled.table`
    width: 100%;
    font-size: 12px;
`;
const Header = styled.thead`
    border-top: 1px solid ${({ theme }) => theme.colors.gray600};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
    height: 48px;
    Tr {
        border-bottom: none;
    }
`;
const Body = styled.tbody`
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
`;
const Tr = styled.tr`
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
    :nth-last-child(1) {
        border-bottom: none;
    }
`;
const HeaderTr = styled(Tr)<{ noSort?: boolean }>`
    cursor: ${({ noSort }) => (noSort ? "auto" : "pointer")};
`;

const Th = styled.th<{ width?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 48px;
    padding: 14px 8px;
    text-align: left;
    vertical-align: middle;
    font-weight: 500;
`;
const ThContent = styled.div<{ textAligns?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ textAligns }) => (textAligns ? textAligns : "flex-start")};
`;
const ThContentHeader = styled.div`
    margin-right: 4px;
`;
const CheckboxTh = styled(Th)`
    display: table-cell;
    width: 1%;
    max-width: 50px;
    text-align: center;
`;

const Td = styled.td<{ width?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 60px;
    padding: 14px 8px;
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;
`;
const CheckboxTd = styled(Td)`
    display: table-cell;
    width: 1%;
    max-width: 50px;
    text-align: unset;
`;
const Checkbox = styled.div`
    display: inline-block;
    height: 16px;
    width: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
`