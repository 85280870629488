import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

import TextInput from "@/components/atoms/input/TextInput";
import Button from "@/components/atoms/button";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import AuthorSearch from "@/components/molecules/basedata-manage/AuthorSearch";
import { useEffect, useState } from "react";
import Text from "@/components/atoms/text";
import ModalTooltip from "@/components/molecules/common/tooltip/ModalTooltip";
import { colors } from "@/assets/styles/theme";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { seriesActions } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import { getPlatformData } from "@/pages/settlement/basedata-manage/platform/platformSlice";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetSettlementRatioModal from "@/components/molecules/common/modal/SetSettlementRatioModal";
import useAlert from "@/modules/hooks/useAlert";

import { ratio, newSeries, Validation } from "@/modules/utils/validate_modify";

export default function EnrollSeriesBodyModal({ from }:EnrollSeriesBodyModalProps) {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    let [ seriesAddDatas, platformList ] = useAppSelector((state:RootState) => [
        state.series.seriesAddDatas,
        state.basePlatformdata.data,
    ]);
    const [showSetSettlementRatioModal, setShowSetSettlementRatioModal] = useState(false);
    useEffect(() => {
        dataSetting();
    }, [platformList, seriesAddDatas, dispatch])

    const dataSetting = async () => {
        try {
            if (!platformList.length) {
                dispatch(getPlatformData()).unwrap();
            }
            if (platformList.length && !seriesAddDatas[0].ratios.length) {
                dispatch(seriesActions.setEnrollSeriesDatas({
                    index: 0, key: "ratios",
                    value: platformList.filter(l => l.usable ).map(l => {
                        return {
                            ...l,
                            ratio: '',
                            ratioError: "",
                            period: [{ startYearmon: "000000", endYearmon: "999999", ratio: "", endYearmonError: "", ratioError: "" },]
                        }
                    })
                }))
            }
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        }
    }


    const changeValue = async(e: React.ChangeEvent<HTMLInputElement>, key: string, item?: any) => {
        let value = e.target.value;
        switch (key) {
            case "ratioAll":
                const res = ratio({ value, error: "ratioError", mandatory: false, field: "정산비율", decimal: false }) as Validation;
                let ratios = JSON.parse(JSON.stringify(item.ratios));
                ratios = ratios.map((r:any) => {
                    return {
                        ...r, "ratio": res.value, "period": r.period.map((p:any) => {
                                                                                    return {...p, "ratio": res.value};
                                                                                })
                    }
                });
                dispatch(seriesActions.setSeriesDatas({
                    key: "seriesAddDatas", index: 0, value: {...item, 'ratioAll': res.value, 'ratioError': res.ratioError, "ratios": ratios}
                }))
                break;
            case "name":
                dispatch(seriesActions.setEnrollSeriesDatas({
                    index: 0, key: key, value: value
                }))
                const validateName = (await newSeries({ value, error: "seriesError", mandatory: true, id: item["id"] })) as Validation;
                dispatch(seriesActions.setEnrollSeriesDatas({
                    index: 0, key: "seriesError", value: validateName.seriesError
                }))
                break;
            default:
                break;
        }
    }

    async function setSettlementRatioModal(idx:number) {
        if (seriesAddDatas[idx].ratioError) {
            return await alert("warning", "주의", "정산비율의 에러메시지를 확인해주세요.");
        } else {
            dispatch(seriesActions.setSeriesDatas({
                key: "ratios", value: seriesAddDatas[idx].ratios
            }))
            dispatch(seriesActions.setIndex(idx));
            setShowSetSettlementRatioModal(true);
        }
    }

    function closeModal() {
        setShowSetSettlementRatioModal(false);
    }

    function selectAuthor(index:number, author:any) {
        let updatedRow = {
            ...seriesAddDatas[index],
            'author': author,
            'authorError': "",
        } 
        dispatch(seriesActions.setSeriesDatas({
            key: "seriesAddDatas", index: index, value: updatedRow
        }));
    }

    function setCoRatio(idx:number, co_info: any) {
        dispatch(seriesActions.setIndex(idx));
        dispatch(seriesActions.setSeriesDatas({
            // key: "tmpCoInfo", value: author.co_info
            key: "tmpCoInfo", value: co_info
        }));
    }

    function deleteAuthor(idx: number) {
        let updatedRow = {
            ...seriesAddDatas[idx],
            'author': {
                author_id: 0,
                author_name: "",
                co_info: [],
            },
            'authorError': "저자명을 선택해주세요.",
        } 
        dispatch(seriesActions.setSeriesDatas({
            key: "seriesAddDatas", index: idx, value: updatedRow
        }));
    }

    function applyCoInfo(co_info:any, idx:number) {
        let updatedRow = {
            ...seriesAddDatas[idx],
            "author": {
                ...seriesAddDatas[idx].author,
                "co_info": co_info,
            },
            "authorError": ""
        } 
        dispatch(seriesActions.setSeriesDatas({
            key: "seriesAddDatas", index: idx, value: updatedRow
        }));
    }

    return (
        <>
            <BodyWrap>
                <ItemRow>
                    <RowName>시리즈명</RowName>
                    <ValidateWrapper dataError={seriesAddDatas[0].seriesError}>
                        <TextInput
                            value={seriesAddDatas[0].name}
                            placeholder="시리즈명"
                            customStyle={{ width: "260px", height: "32px", fontSize: "12px", fontWeight: "400"}}
                            onChangeHandler={(e) => changeValue(e, "name", seriesAddDatas[0])}
                        />
                    </ValidateWrapper>
                </ItemRow>
                <ItemRow>
                    <RowName>저자명</RowName>
                    <ValidateWrapper dataError={seriesAddDatas[0].authorError}>
                        <AuthorSearch
                            idx={0}
                            from="series-enroll"
                            placeholder="저자명"
                            value={seriesAddDatas[0].author.author_name}
                            author={seriesAddDatas[0].author}
                            customStyle={{ width: "160px", height: "32px", fontSize: "12px", fontWeight: "400" }}
                            buttonCustomStyle={{ type: "main-outline", label: "공저 설정", customStyle: { width: "100px", height: "32px", fontSize: "14px", fontWeight: "500", color: colors.blue500}}}
                            selectAuthorFunc={selectAuthor}
                            deleteAuthorFunc={deleteAuthor}
                            applyCoInfoFunc={applyCoInfo}
                        />
                    </ValidateWrapper>
                </ItemRow>
                    <ItemRow>
                        <RowName>
                            <Text text="정산비율" customStyle={{ fontSize: "14px", fontWeight: "400", margin: "0 8px 0 0"}}/>
                            <ModalTooltip
                                id={"enrollSeires0"}
                                iconWidth={20} 
                                iconHeight={20}
                                textBoxWidth={240}
                                fill={colors.gray600}
                                text="출판사가 작가에게 지급하는 비율입니다." 
                            />
                        </RowName>
                        {
                            seriesAddDatas[0].ratioAll !== "플랫폼 별 설정" ?
                            <ValidateWrapper dataError={seriesAddDatas[0].ratioError}>
                                <PercentInputText
                                    placeholder="정산비율"
                                    type={seriesAddDatas[0].ratioAll === "" ? "text" : "number"}
                                    value={seriesAddDatas[0].ratioAll || ""}
                                    inText="%"
                                    customStyle={{ width: "260px", height: "32px", fontSize: "12px", fontWeight: "400", padding: "0 18px 2px 10px" }}
                                    onChange={(e) => changeValue(e, "ratioAll", seriesAddDatas[0])}
                                />
                            </ValidateWrapper>
                            :
                            <TextInput
                                type="readOnly"
                                value="플랫폼 별 설정"
                                onChangeHandler={() => {}}
                                customStyle={{ width: "260px", height: "32px", fontSize: "12px", fontWeight: "400" }}
                            />
                        }
                </ItemRow>
                <ItemRow>
                    <RowName>비율 상세설정</RowName>
                    <Button
                        label="상세설정"
                        type="main-outline"
                        onClickHandler={(e)=> setSettlementRatioModal(0)}
                    />
                </ItemRow>
            </BodyWrap>
            <ModalPortal>
                <SetSettlementRatioModal
                    from={from}
                    show={showSetSettlementRatioModal}
                    close={closeModal}
                />
            </ModalPortal>
        </>
    )

}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    height: 272px;
`
const ItemRow = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`

const RowName = styled.div`
    display: flex;
    align-items: center;
    width: 160px;
    font-size: 14px;
    padding: 17px 8px;
`

interface EnrollSeriesBodyModalProps {
    from: string;
}