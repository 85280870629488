import styled from "styled-components";
import { useAppDispatch, RootState } from "@/modules/store";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";
import { colors } from "@/assets/styles/theme";
import { useState, ChangeEvent } from "react";
import useAlert from "@/modules/hooks/useAlert";
import PwWrap from "@/components/molecules/signup/PwWrap";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { findpwActions, updatePw } from "@/pages/auth/findPw/findpwSlice";

export default function ChangePw() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const kcp_di = useSelectorTyped((state: RootState) => state.findpw.kcp_di);
    const [pw, setPw] = useState("");
    const [pwError, setPwError] = useState("");
    const [checkPw, setCheckPw] = useState("");
    const [checkPwError, setCheckPwError] = useState("");

    function changeHandlerPW(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        setPw(value);
        if (value.length > 7 && value.length < 21 && /^.*(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(value)) {
            setPwError("");
        } else {
            setPwError("영문 / 숫자 포함 8~20자 조합으로 입력해주세요.");
        }
        if (!checkPw || value === checkPw) {
            setCheckPwError("");
        } else {
            setCheckPwError("비밀번호가 같지 않습니다");
        }
    }

    function changeHandlerCheckPW(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        setCheckPw(value);
        if (value === pw) {
            setCheckPwError("");
        } else {
            setCheckPwError("비밀번호가 같지 않습니다");
        }
    }

    const keyPressHandler = (key: any) => {
        if (key === "Enter") {
            updatePassword();
        }
    };

    async function updatePassword() {
        let hasError = false;
        if (!pw) {
            setPwError("비밀번호를 입력해주세요.");
            hasError = true;
        }
        if (!checkPw) {
            setCheckPwError("비밀번호를 재입력해주세요.");
            hasError = true;
        }
        if (pwError || checkPwError || hasError) {
            return await alert("warning", "주의", "유효하지 않은 항목이 있습니다."); 
        }

        const answer = await alert("confirm", "확인", "비밀번호를 변경하시겠습니까?");

        if (answer === "Y") {
            let apiParams = {
                user_pw: pw,
                kcp_di: kcp_di
            }
            dispatch(updatePw(apiParams)).unwrap();
            dispatch(findpwActions.setDone(true));
        }
    }

    return (
        <>
            <PwWrap
                labelName="새 비밀번호 입력"
                inputPlaceholder="8~20자리 영문 대·소문자, 숫자, 특수문자 조합"
                inputID="password"
                value={pw}
                errorMsg={pwError}
                onChange={changeHandlerPW}
                onKeyPress={keyPressHandler}
            />
            <PwWrap
                labelName="새 비밀번호 확인"
                inputPlaceholder="새 비밀번호를 재입력해주세요"
                inputID="password"
                value={checkPw}
                errorMsg={checkPwError}
                onChange={changeHandlerCheckPW}
                onKeyPress={keyPressHandler}
            />
            <Button
                type="main"
                label="비밀번호 변경"
                customStyle={{ width: "400px",
                                height: "60px",
                                fontSize: "20px",
                                fontWeight: "500",
                                margin: "0 0 24px"
                            }}
                onClickHandler={updatePassword}
            />
            <Text
                text="*비밀번호를 변경하지 않은 경우 이용이 불가능 합니다."
                customStyle={{ fontSize: "14px", fontWeight: "500", color: colors.gray600 }}
            />
        </>
    );
}