import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate, NavLink, Outlet } from "react-router-dom";
import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { convertImg } from "@/pages/common/userSlice";
import Button from "@/components/atoms/button";
import { UserDropDown, MenuDropDown } from "@/components/molecules/common/dropdown/Dropdown";
import useClickOutside from "@/modules/hooks/useClickOutside";
import { ReactComponent as Logo } from "@/assets/icons/gling-biz-logo.svg";
import profile from "@/assets/images/introduce-image/author-profile.svg";
import { SearchOptions } from "@/pages/portfolio/publisher/types";

const NavBar = ({pathname} : {pathname: string}) => {
    const { isLogin, isPublisher, user_pic_path, user_nickname } = useAppSelector((state: RootState) => state.user);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [imgUrl, setImgUrl] = useState("");
    const menuDropDownRef = useRef(null);
    const profileDropDownRef = useRef(null);
    const isOutsideClicked = useClickOutside({ ref: menuDropDownRef });
    const isOutsideProfileClicked = useClickOutside({ ref: profileDropDownRef });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isOutsideClicked) setShowMenu(false);
    }, [isOutsideClicked]);

    useEffect(() => {
        if (isOutsideProfileClicked) setShowUserMenu(false);
    }, [isOutsideProfileClicked]);

    useEffect(() => {
        if (user_pic_path) imgConvert(user_pic_path)
        else imgConvert("/static/user.jpg");
    }, [user_pic_path]);

    function goPage(event: React.MouseEvent<HTMLButtonElement>, to: string) {
        event.preventDefault();
        navigate(to);
    }

    function profileClickHandler() {
        setShowUserMenu((state) => !state);
    }

    function menuClickHandler() {
        setShowMenu((state) => !state);
    }

    const imgConvert = async (imgPath: string) => {
        try {
            const res = await dispatch(convertImg(imgPath)).unwrap();
            setImgUrl(window.URL.createObjectURL(res));
        } catch (err: any) {
            setImgUrl(profile);
        }
    };

    const btnStyle = {
        borderRadius: "40px",
        margin: "0 0 0 8px",
    };

    // 앱 PC업로드 화면일 경우
    if (pathname === "/app/fileUpload") {
        return (
            <AppNavBarWrapper>
                <AppHeaderWrapper>
                    <StyledLogo />
                </AppHeaderWrapper>
            </AppNavBarWrapper>
        )
    }
    

    return (
        <NavBarWrapper>
            <HeaderContainer pathname={pathname} isLogin={isLogin}>
                <HeaderContents>
                    <CustomNav to="/">
                        <Logo />
                    </CustomNav>
                    {isLogin ? (
                        <Fragment>
                            {isPublisher ? (
                                <MidMenu>
                                    <li>
                                        <CustomNav title="대시보드" to="dashboard" />
                                    </li>
                                    <li onClick={menuClickHandler} ref={menuDropDownRef}>
                                        <div style={{ "fontWeight": pathname.includes("/settlement/") ? "600" : "400"}}>정산관리</div>
                                        <MenuDropDown showMenu={showMenu} />
                                    </li>
                                    <li onClick={() => navigate(`/portfolio/list?page=1&option=${SearchOptions.ALL}&keyword=`)}>
                                        <div style={{ "fontWeight": pathname.includes("/portfolio/") ? "600" : "400"}}>포트폴리오</div>
                                    </li>
                                    <li>
                                        <CustomNav title="E-BOOK제작" to="ebook-maker" />
                                    </li>
                                    <li>
                                        <CustomNav title="고객센터" to="support" />
                                    </li>
                                </MidMenu>
                            ) : (
                                <MidMenu>
                                <li>
                                    <CustomNav title="대시보드" to="dashboard" />
                                </li>
                                <li>
                                    <CustomNav title="정산 조회" to="settlement/sales-inquiry?type=Daily" />
                                </li>
                                <li>
                                    <CustomNav title="내 포트폴리오" to="portfolio/list" />
                                </li>
                                <li>
                                    <CustomNav title="정산서" to="settlement/statement" />
                                </li>
                                <li>
                                    <CustomNav title="고객센터" to="support" />
                                </li>
                            </MidMenu>
                            )}
                            <ProfileMenu onClick={profileClickHandler} ref={profileDropDownRef}>
                                <Profile>
                                    <ImgWrapper>
                                        <img src={imgUrl} width="100%" alt="유저프로필" />
                                    </ImgWrapper>
                                    <div className="profile-name">{user_nickname}</div>
                                </Profile>
                                <UserDropDown showMenu={showUserMenu} />
                            </ProfileMenu>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <MidMenu>
                                <li>
                                    <CustomNav title="서비스 소개" to="/" />
                                </li>
                                <li>
                                    <CustomNav title="요금 안내" to="pricing" />
                                </li>
                                <li>
                                    <CustomNav title="이용가이드" to="guide" />
                                </li>
                                <li>
                                    <CustomNav title="문의하기" to="consult" />
                                </li>
                            </MidMenu>
                            <RightMenu>
                                <Button
                                    type="main-outline"
                                    label="로그인"
                                    onClickHandler={(e: React.MouseEvent<HTMLButtonElement>) => goPage(e, "/signin")}
                                    customStyle={btnStyle}
                                />
                                <Button
                                    type="main"
                                    label="시작하기"
                                    onClickHandler={(e: React.MouseEvent<HTMLButtonElement>) => goPage(e, "/signup")}
                                    customStyle={btnStyle}
                                />
                            </RightMenu>
                        </Fragment>
                    )}
                </HeaderContents>
            </HeaderContainer>
            <Outlet />
        </NavBarWrapper>
    );
};

export function CustomNav({ title, to, children }: { title?: string; to: string; children?: React.ReactNode }) {
    const navStyle = {
        fontWeight: 600,
    };
    return (
        <NavLink to={to} style={({ isActive }) => (isActive ? navStyle : {})}>
            {children ? children : title}
        </NavLink>
    );
}

const NavBarWrapper = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000!important;
`

export const HeaderContainer = styled.div<{pathname: string, isLogin: boolean}>`
    min-width: 1200px;
    width: 100%;
    height: 72px;
    position: ${({ pathname, isLogin }) => pathname === '/' && !isLogin ? 'sticky' : 'relative'};
    z-index: 2;
    box-shadow: ${({ pathname, isLogin }) => pathname === '/' && !isLogin ? '0px 6px 12px 0px #7090B033' : ''};
    border-bottom: ${({ pathname, isLogin, theme }) => pathname === '/' && !isLogin ? '' : `1px solid ${theme.colors.gray300}`};
    background-color: ${({ theme }) => theme.colors.white};
`;

export const HeaderContents = styled.div`
    font-family: "Spoqa Han Sans", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 100%;
    margin: 0 auto;

    .logo-wrap {
        /* border: 1px solid salmon; */
        display: flex;
        align-items: center;
    }
`;

export const MidMenu = styled.ul`
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 40%; */
    font-size: 16px;
    cursor: pointer;
    /* 30px */
    a.selected {
        color: red;
    }
    li + li {
        margin-left: 30px;
    }
`;

export const RightMenu = styled.div`
    /* border: 1px solid blue; */
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const ProfileMenu = styled(RightMenu)`
    flex-direction: column;
    position: relative;
    align-items: center;
`;

export const Profile = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    .profile-name {
        padding-left: 5px;
        line-height: 21px;
    }
`;
const ImgWrapper = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
`;
export const BizTitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 18px;
    padding: 2px;
    border-radius: 16px;
    transform: scale(0.8);
    background-color: #495057;
    color: #fff;
    font-size: 10px;
`;

export const SettlementMenu = styled.li`
    flex-direction: column;
    position: relative;
    align-items: center;
`;

const AppNavBarWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 72px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
`

const AppHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 1200px;
    height: 100%;
`

const StyledLogo = styled(Logo)`
    margin-left: 16px;
`

export default NavBar;
