import { newAuthor, user } from "@/modules/utils/author";
import { newSeries } from "@/modules/utils/series";

const validate = async(key: string, errorKey: ErrorKey, value: string, item: any, field: string) => {
    switch (errorKey) {
        case "authorError":
            return await newAuthor(key, errorKey, value, item["id"], true);
        case "emailError":
            return await user(field, errorKey, value, false);
        case "seriesError":
            return await newSeries(key, errorKey, value, item["id"], true);
        default:
            return { msg: "", value: "" };
    }
};

type ErrorKey = "authorError" | "emailError" | "seriesError";

export default validate;
