import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";
import { AuthorMypageState } from "./types";

const initialAuthorMypageState: AuthorMypageState = {
    AuthorInfo: {
        contentList: [],
        authorList: [],
    }
}

export const authorInfo = createAsyncThunk("author/mypage/info/getContentAndAuthor", async(user_id: number, { rejectWithValue } ) => {
    try {
        const res = await api.post(`/api/author/mypage/info/getContentAndAuthor`);
        return (res.data);
    } catch (err: any){
        throw rejectWithValue(err.response.data);
    }
});

export const AuthorMypageSlice = createSlice({
    name: "authorMyPage",
    initialState: initialAuthorMypageState,
    reducers: {
    }, 
    extraReducers: (builder) => {
        builder.addCase(authorInfo.fulfilled, (state, action) => {
            state.AuthorInfo.contentList = action.payload.data.contentList;
            state.AuthorInfo.authorList = action.payload.data.authorList;
        })
    }
});

export const authorMyPageActions = AuthorMypageSlice.actions;
export default AuthorMypageSlice.reducer;