import styled, { css } from "styled-components";
import { ChangeEvent } from "react";
import React from "react";

const MemoizedCheckbox = React.memo(function Checkbox({
    type,
    selected,
    value = "",
    valueName = "id",
    active,
    onChangeHandler,
    show = true,
    disabled = false,
    customStyle,
}: CheckboxProps) {
    // utils
    const stopPropagation = (e: React.MouseEvent<HTMLLabelElement>) => e.stopPropagation();
    const compare = (value: valueType) => {
        if (!selected) return false;
        const isObj = typeof selected[0] === "object";
        const hasValue = isObj
            ? selected.findIndex((s) => s[valueName] === value)
            : selected.findIndex((s) => s === value);
        return hasValue !== -1;
    };

    // selected에 저장될 데이터 type에 따라 분류
    switch (type) {
        case "boolean": // 단일 선택시 사용(true/false)
            return (
                <Label active={active} disabled={Boolean(disabled)} onClick={stopPropagation} show={show}>
                    <Input type="checkbox" value={value} onChange={disabled ? ()=>{} : onChangeHandler} />
                </Label>
            );
        case "object":
            return (
                <Label active={compare(value)} onClick={stopPropagation} show={show}>
                    <Input type="checkbox" value={value} onChange={onChangeHandler} />
                </Label>
            );
        default:
            // string or number
            return (
                <Label active={compare(value)} onClick={stopPropagation} show={show}>
                    <Input type="checkbox" value={value} onChange={onChangeHandler} />
                </Label>
            );
    }
});

const Label = styled.label<{ active?: boolean; show?: boolean; disabled?: boolean; }>`
    display: ${({ show }) => (show ? "inline-block" : "none")};
    position: relative;
    width: 16px;
    height: 16px;
    padding: 2px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    background-color: ${({ theme }) => theme.colors.white};
    cursor: ${({ disabled }) => (disabled ? "defulat" : "pointer")};
    ${({ active, disabled }) =>
        active &&
        css`
            border: none;
            background-color: ${({ theme }) => disabled ? theme.colors.gray300 : theme.colors.blue500};
            &:after {
                content: "";
                position: absolute;
                border: solid ${({ theme }) => theme.colors.white};
                border-width: 0px 2px 2px 0;
                transform: rotate(45deg);
                width: 4px;
                height: 8px;
                left: 5px;
                top: 1px;
            }
        `}
`;

const Input = styled.input`
    display: none;
`;
interface StyleProps {
    color?: string;
}

interface CheckboxProps {
    type?: "default" | "boolean" | "object";
    active?: boolean;
    selected?: any[];
    value?: string | number;
    valueName?: string;
    onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    show?: boolean;
    customStyle?: StyleProps;
    disabled?: boolean;
}
type valueType = string | number;
export default MemoizedCheckbox;
