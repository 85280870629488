import styled from "styled-components";

// components
import WithdrawFilterBox from "@/components/molecules/total-sales/WithdrawFilterBox";
import Withdrawtable from "@/components/molecules/total-sales/WithdrawTable";
import TotalSalesSkeleton from "@/components/molecules/common/loading/TotalSalesSkeleton";

// reducer
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState } from "@/modules/store";

export default function Withdraw() {
    const { loading } = useSelectorTyped((state: RootState) => state.totalSales);

    return (
        <WithdrawWrap>
            <WithdrawFilterBox />
            {loading ? <TotalSalesSkeleton /> : <Withdrawtable />}
        </WithdrawWrap>
    )
}

const WithdrawWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`