import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@/api";

const name = "common/search/history/";
const initialState: State = {
    searchHistory: [],
};

// 최근 검색어 조회
export const getSearchHistory = createAsyncThunk(
    `${name}getSearchHistory`,
    async (apiParams: GetApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSearchKeyword/searchHistory/select", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);
// 최근 검색어 저장 & 업데이트
export const updateSearchHistory = createAsyncThunk(
    `${name}updateSearchHistory`,
    async (apiParams: UpdateApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSearchKeyword/searchHistory/update", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);
// 최근 검색어 삭제
export const deleteSearchHistory = createAsyncThunk(
    `${name}deleteSearchHistory`,
    async (apiParams: DeleteApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSearchKeyword/searchHistory/delete", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const searchHistorySlice = createSlice({
    name,
    initialState,
    reducers: {
        resetStore: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSearchHistory.fulfilled, (state, action) => {
            state.searchHistory = action.payload.data;
        });
    },
});

interface State {
    searchHistory: {
        id: number;
        keyword: string;
    }[];
}
interface GetApiParams {
    template: string;
}
interface UpdateApiParams {
    template: string;
    keyword: string;
}
interface DeleteApiParams {
    template: string;
    keyword?: string;
}

export const { resetStore } = searchHistorySlice.actions;
export default searchHistorySlice.reducer;
