import _ from "lodash";
import { useAppDispatch } from "@/modules/store";
import { updateRow } from "@/pages/common/dataTableSlice";
import { setItem } from "@/pages/settlement/saels-inquiry/publisher/etc/etcSlice";
import TextInput from "@/components/atoms/input/TextInput";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import useCalculateAmount from "@/components/molecules/sales-inquiry/publisher/etc/hooks/useCalculateAmount";
import { amount } from "@/modules/utils/validate_modify";
import { addComma, removeComma } from "@/modules/utils/filter";
import type { Item } from "@/components/molecules/common/table/DataTable";

export default function EtcPreAmount({ item, index }: ColumnProps) {
    const { calculateAmount } = useCalculateAmount();
    const dispatch = useAppDispatch();

    const changeAmount = (value: string) => {
        const res = amount({ field: "금액", value, error: "preAmountError", decimal: true, mandatory: true });
        let newItem = _.cloneDeep(item);
        newItem.pre_amount = res.value;
        newItem.preAmountError = res.preAmountError;

        calculateAmount(newItem);
        dispatch(setItem({ index, item: newItem }));
        dispatch(updateRow({ itemKey: "id", updatedRow: newItem }));
    };

    const handleComma = (type:string) => () => {
        const newItem = { ...item, pre_amount: type === 'remove' ? removeComma(item.pre_amount) : addComma(item.pre_amount) };
        dispatch(setItem({ index, item: newItem }));
    }

    return (
        <ValidateWrapper dataError={item["preAmountError"]}>
            <TextInput
                type="main"
                placeholder="금액(원)"
                value={item["pre_amount"]}
                onChangeHandler={(e) => changeAmount(e.target.value)}
                onFocusHandler={handleComma("remove")}
                onBlurHandler={handleComma('add')}
                customStyle={{
                    fontSize: "12px",
                    textAlign: "right",
                    width: "100%",
                    height: "32px",
                }}
            />
        </ValidateWrapper>
    );
}

interface ColumnProps {
    item: Item;
    index: number;
}
