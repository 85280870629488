import Macbook from '@/assets/images/introduce-image/01.png'
import Enter from '@/assets/images/introduce-image/01-2.png'
import Discount from '@/assets/images/introduce-image/01-3.png'
import { colors } from '@/assets/styles/theme'

const titleStyle = {
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "45px"
}

const descriptionStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "28px"
}

export const dataArr = [
    {
        id: 1,
        src: Macbook,
        title: '글링에서 시작하는</br>명쾌한 정산',
        description: '힘든 정산관리 과정을 간단하게!</br>글링에서 지금 무료로 경험해보세요!',
        titleStyle: Object.assign({color: colors.black, ...titleStyle}),
        descriptionStyle: Object.assign({color: colors.black, ...descriptionStyle}),
        buttonPath: 'signin'
    },
    {
        id: 2,
        src: Enter,
        title: 'All-in-One 정산관리</br>글링에서 체험하세요',
        description: '여러 출판사에서 유통한 인터넷 서점 판매액을 한 눈에 보실 수</br>있습니다. 원장부 확인, 월정산, 판매량 분석을 쉽게 도와줍니다.',
        titleStyle: Object.assign({color: colors.black, ...titleStyle}),
        descriptionStyle: Object.assign({color: colors.black, ...descriptionStyle}),
        buttonPath: 'signin'
    },
    {
        id: 3,
        src: Discount,
        title: '우리 회사에 딱맞는 플랜</br>지금 바로 문의하세요!',
        description: '힘든 정산관리 과정을 간단하게!</br>글링에서 지금 무료로 경험해보세요!',
        titleStyle: Object.assign({color: colors.white, ...titleStyle}),
        descriptionStyle: Object.assign({color: colors.white, ...descriptionStyle}),
        buttonPath: 'signin'
    }
]