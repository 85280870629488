import styled from "styled-components";
import AddRow from "@/assets/icons/add-data_white.svg";
import AddRowRound from "@/assets/icons/add_round_white.svg";
import ModalPortal from "../../common/modal/Portal";
import AddLineModal from "../../common/modal/sales-entry/AddLineModal";
import { useState } from "react";

export default function AddLine({ onApplyAddLine }: AddLineProps) {
    const [showModal, setShowModal] = useState(false);
    const handleModal = () => setShowModal((prev) => !prev);
    const changeRowNum = (rowNum: number) => onApplyAddLine(rowNum);

    return (
        <>
            <AddLineContainer onClick={handleModal}>
                <AddRowIcon className="add-row-icon" />
            </AddLineContainer>
            <ModalPortal>
                <AddLineModal show={showModal} close={handleModal} onChange={changeRowNum} from={"addLine"} />
            </ModalPortal>
        </>
    );
}

const AddLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 100%;
    width: 60px;
    height: 60px;
    padding: 8px;
    transform: translateY(-100%);
    background-color: ${({ theme }) => theme.colors.blue500};
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
    ::after {
        content: "";
        display: block;
        font-size: 12px;
        font-weight: 500px;
        margin-top: 4px;
        color: transparent;
    }
    :hover {
        .add-row-icon {
            background-image: url(${AddRow});
            transition: 0.1s ease-in-out;
        }
        ::after {
            content: "행 추가";
            display: block;
            font-size: 12px;
            font-weight: 500px;
            margin-top: 4px;
            color: white;
            transition: 0.1s ease-in-out;
        }
    }
`;

const AddRowIcon = styled.div`
    background-image: url(${AddRowRound});
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
`;

interface AddLineProps {
    onApplyAddLine: (rowNum: number) => void;
}
