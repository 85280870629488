import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import styled from "styled-components";
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

export default function DraggableTable() {
    const { contentList } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragEnd = ({ active, over }: any) => {
        if (!over) {
            return;
        }

        if (active.id !== over.id) {
            const activeIndex = active.data.current.sortable.index;
            const overIndex = over.data.current?.sortable.index || 0;
            dispatch({ type: "ebookMaker/sortCopyrightContent", payload: { from: activeIndex, to: overIndex } });
        }
    };

    return (
        <TableContainer>
            <HeaderRowWrapper>
                <Li width="40px"></Li>
                <Li width="60px" align="center">순서</Li>
                <Li width="120px">항목</Li>
                <Li width="630px">내용</Li>
                <Li width="50px"></Li>
            </HeaderRowWrapper>
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <SortableContext items={contentList} strategy={rectSortingStrategy}>
                    {contentList.map((item: any) => (
                        <SortableItem key={item.id} item={item}/>
                    ))}
                </SortableContext>
            </DndContext>
        </TableContainer>
    )
}

const TableContainer = styled.div`
    width: 900px;
`

const HeaderRowWrapper = styled.ul`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`

const Li = styled.li<{ width: string; align?: string;}>`
    width: ${({ width }) => width};
    height: 100%;
    padding: 16px 8px;
    text-align: ${({ align }) => align ? align : "left"};
    font-size: 14px;
    font-weight: 500;
`