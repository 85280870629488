import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import _ from "lodash";
import styled, { css } from "styled-components";
import Button from "@/components/atoms/button";
import Text from "@/components/atoms/text";
import TextInput from "@/components/atoms/input/TextInput";
import ModalPortal from "../Portal";
import ModalSearchBar from "../../searchBar/modalSearchBar/ModalSearchBar";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import useAlert from "@/modules/hooks/useAlert";
import { ratio } from "@/modules/utils/validate_modify";
import { NonMatchedData } from "@/types/excelUpload";

export default function SeriesMatchingModalBody({
    items,
    matchingItems,
    withRatio,
    addedRatioList,
    setMatchingItems,
    setAddedRatioList,
}: ModalBodyProps) {
    const [showModal, setShowModal] = useState(false);
    const [matchingIdx, setMatchingIdx] = useState(0);
    const [platformId, setPlatformId] = useState(0);
    const { alert } = useAlert();

    // 초기화
    useEffect(() => {
        if (!matchingItems.length) sameIsbnCheck();
    }, [items.current]);

    // 초기화
    const sameIsbnCheck = () => {
        let tmpItems = [];
        for (let i of items.current) {
            let sameIsbn = false;

            i.splitBookName = [i.book_name];

            for (let k of tmpItems) {
                if (i.seriesNo && i.seriesNo === k.seriesNo) {
                    // 작품 isbn은 같지만, 추천 시리즈명이 각 작품마다 다르게 입력된 경우에
                    // 같은 isbn끼리는 같은 추천시리즈명이 입력되도록 수정하는 코드
                    i.recommended_author = k.recommended_author;
                    i.recommended_author_id = k.recommended_author_id;
                    i.recommended_ratio = k.recommended_ratio;
                    i.recommended_series_id = k.recommended_series_id;
                    i.recommended_series_name = k.recommended_series_name;
                    //------------------------------------------------------
                    k.book_name += `, ${i.book_name}`;
                    k.splitBookName.push(i.book_name);
                    sameIsbn = true;
                    break;
                }
            }
            if (!sameIsbn) {
                if (i.recommended_series_name && i.recommended_ratio == null) {
                    i.needRatio = true;
                }
                tmpItems.push(JSON.parse(JSON.stringify(i)));
            }
        }

        for (let i of tmpItems) {
            i.splitBookName = i.splitBookName.sort();
        }
        setMatchingItems(tmpItems);
    };

    // 일괄 확인
    const confirmAll = async () => {
        for (let i of matchingItems) {
            if (!i.recommended_series_name) {
                return await alert("warning", "주의", "시리즈명이 입력되지 않은 작품이 있습니다.");
            }
            if (withRatio && i.recommended_ratio === null) {
                return await alert("warning", "주의", "정산비율이 입력되지 않은 작품이 있습니다.");
            }
            if (withRatio && i.ratioError) {
                return await alert("warning", "주의", `[${i.book_name}]의 정산비율을 확인해주세요.`);
            }
        }
        for (let row of items.current) {
            if (!row.recommended_series_name) {
                return await alert("warning", "주의", "시리즈명이 입력되지 않은 작품이 있습니다.");
            }
            if (withRatio && row.recommended_ratio === null) {
                return await alert("warning", "주의", "정산비율이 입력되지 않은 작품이 있습니다.");
            }
            if (withRatio && row.ratioError) {
                return await alert("warning", "주의", `[${row.book_name}]의 정산비율을 확인해주세요.`);
            }
        }
        setMatchingItems((prev) => prev.map((p) => ({ ...p, isChecked: true })));
        items.current = items.current.map((item) => ({ ...item, isChecked: true }));
    };

    // 개별 확인
    const confirm = async (row: any, idx: number) => {
        // 수정 -> 확인
        if (row.isChecked) {
            for (let i of items.current) {
                if (row.seriesNo === i.seriesNo) {
                    i.isChecked = false;
                }
            }
            setMatchingItems((prev) => prev.map((p, pidx) => (pidx === idx ? { ...p, isChecked: false } : p)));
            return;
        }
        // 확인 -> 수정
        if (!row.recommended_series_name) {
            return await alert("warning", "주의", "시리즈명을 입력해주세요.");
        }
        if (withRatio && row.recommended_ratio == null) {
            return await alert("info", "주의", "정산비율을 입력해주세요.");
        }
        if (row.ratioError) {
            return await alert("info", "주의", "조건을 확인해주세요.");
        }
        for (let i of items.current) {
            if (row.seriesNo === i.seriesNo) {
                i.isChecked = true;
            }
        }
        setMatchingItems((prev) => prev.map((p, pidx) => (pidx === idx ? { ...p, isChecked: true } : p)));
    };

    // 시리즈 검색 및 적용
    const openSeriesModal = (row: any, idx: number) => {
        setShowModal(true);
        setPlatformId(row.platform_id || row.platforms);
        setMatchingIdx(idx);
    };

    const closeSeriesModal = () => setShowModal(false);

    const applySeries = (data: any) => {
        const { selectedSeries: series, matchingIdx } = data;
        let tmpItems = _.cloneDeep(matchingItems);

        tmpItems[matchingIdx].recommended_series_name = series.series_name;
        tmpItems[matchingIdx].recommended_series_id = series.id;
        tmpItems[matchingIdx].recommended_ratio = series.ratio;
        tmpItems[matchingIdx].recommended_author = series.author_name;
        tmpItems[matchingIdx].recommended_author_id = series.author_id;

        // 정산비율 입력이 필요한 시리즈인지 판별 (플랫폼 새로 추가된 경우)
        if (series.ratio === null) tmpItems[matchingIdx].needRatio = true;
        else tmpItems[matchingIdx].needRatio = false;
        setMatchingItems(tmpItems);

        for (let i of items.current) {
            if (tmpItems[matchingIdx].seriesNo === i.seriesNo) {
                i.recommended_series_name = series.series_name;
                i.recommended_series_id = series.id;
                i.recommended_ratio = series.ratio;
                i.recommended_author = series.author_name;
                i.recommended_author_id = series.author_id;
            }
        }
    };

    // 정산 비율 설정
    const addRatio = (data: any) => {
        let tmpRatioList = [...addedRatioList];

        // 기존 시리즈 정산비율 변경
        for (let t of tmpRatioList) {
            if (t.series_id === data.series_id) {
                t.ratio = data.ratio;
                setAddedRatioList(tmpRatioList);
                return;
            }
        }
        tmpRatioList.push(data);
        setAddedRatioList(tmpRatioList);
    };

    const validateRatio = (row: any, idx: number, value: string) => {
        let newMatchingItems = [...matchingItems];
        const res = ratio({
            field: "정산비율",
            value,
            error: "ratioError",
            mandatory: true,
            decimal: true,
        });

        newMatchingItems[idx] = { ...newMatchingItems[idx], recommended_ratio: res.value, ratioError: res.ratioError };
        setMatchingItems(newMatchingItems);

        for (let i of items.current) {
            if (row.seriesNo === i.seriesNo) {
                i.recommended_ratio = row.recommended_ratio;
            }
        }

        addRatio({
            ratio: row.recommended_ratio,
            series_id: row.recommended_series_id,
            platform_id: row.platform_id || row.platforms,
        });
    };

    const applyRatio = (row: any) => {
        let tmpMatchingItems = _.cloneDeep(matchingItems);
        for (let t of tmpMatchingItems) {
            if (row.recommended_series_id === t.recommended_series_id) {
                for (let i of items.current) {
                    if (t.seriesNo === i.seriesNo) {
                        i.recommended_ratio = row.recommended_ratio;
                    }
                }
                t.recommended_ratio = row.recommended_ratio;
            }
        }
        setMatchingItems(tmpMatchingItems);
        addRatio({
            ratio: row.recommended_ratio,
            series_id: row.recommended_series_id,
            platform_id: row.platform_id || row.platforms,
        });
    };

    return (
        <>
            <ModalBodyContainer>
                <FixedHeader>
                    <HeaderField>작품명</HeaderField>
                    <HeaderField>추천 시리즈명</HeaderField>
                    {withRatio && <HeaderField width="200px">정산비율(%)</HeaderField>}
                    <HeaderField button>
                        <Button type="main-outline" label="일괄 확인" onClickHandler={confirmAll} />
                    </HeaderField>
                </FixedHeader>
                <Body>
                    {matchingItems.map((item, idx) => (
                        <BodyRow key={item.id}>
                            {/* 작품명 */}
                            <BookNameField item={item} />

                            {/* 시리즈 검색창 */}
                            {item.isChecked ? (
                                <BodyField>{item.recommended_series_name}</BodyField>
                            ) : (
                                <BodyField>
                                    <ModalSearchBar
                                        type={"disabledInput"}
                                        value={item.recommended_series_name}
                                        placeholder=""
                                        customStyle={{ width: "100%" }}
                                        onHandleModal={() => openSeriesModal(item, idx)}
                                    />
                                </BodyField>
                            )}
                            {/* 정산 비율 */}
                            {withRatio && item.needRatio && (
                                <BodyField width="200px">
                                    <ValidateWrapper dataError={item.ratioError}>
                                        <TextInput
                                            type={item.isChecked ? "readOnly" : "main"}
                                            value={item.recommended_ratio || ""}
                                            placeholder="미적용"
                                            onChangeHandler={(e) => {
                                                validateRatio(item, idx, e.target.value);
                                            }}
                                            onBlurHandler={() => applyRatio(item)}
                                            customStyle={{ width: "100%", height: "32px" }}
                                        />
                                    </ValidateWrapper>
                                </BodyField>
                            )}
                            {withRatio && !item.needRatio && (
                                <BodyField width="200px">
                                    <Text
                                        text={item.recommended_ratio}
                                        customStyle={{ margin: "0 0 0 8px", fontSize: "12px" }}
                                    />
                                </BodyField>
                            )}
                            <BodyField button>
                                <Button
                                    type={item.isChecked ? "gray-outline" : "main-outline"}
                                    label={item.isChecked ? "수정" : "확인"}
                                    onClickHandler={() => confirm(item, idx)}
                                />
                            </BodyField>
                        </BodyRow>
                    ))}
                </Body>
            </ModalBodyContainer>
            <ModalPortal>
                <SearchSeriesModal
                    from="match"
                    show={showModal}
                    platformId={platformId}
                    close={closeSeriesModal}
                    withRatio={true}
                    multiSelect={false}
                    matchingIdx={matchingIdx}
                    applySeries={applySeries}
                    applySeriesRatio={addRatio}
                />
            </ModalPortal>
        </>
    );
}

// 작품명
function BookNameField({ item }: { item: { book_name: string; splitBookName: string[] } }) {
    const target = useRef<HTMLDivElement>(null);
    const [tooltip, setTooltip] = useState(false);
    const [pos, setPos] = useState({ top: "", left: "" });

    useEffect(() => {
        if (target.current) {
            let targetTop = target.current.getBoundingClientRect().top;
            let targetRight = target.current.getBoundingClientRect().right;
            setPos({ top: String(targetTop) + "px", left: String(targetRight) + "px" });
        }
    }, [target]);

    const enterMouse = () => {
        if (target.current) {
            let targetTop = target.current.getBoundingClientRect().top + window.pageYOffset + 40;
            let targetLeft = target.current.getBoundingClientRect().left;
            setPos({ top: String(targetTop) + "px", left: String(targetLeft + 40) + "px" });
            setTooltip(true);
        }
    };

    const leaveMouse = () => setTooltip(false);

    return (
        <BaseField ref={target} onMouseEnter={enterMouse} onMouseLeave={leaveMouse}>
            <EllipsisText>{item.book_name}</EllipsisText>
            {tooltip &&
                createPortal(
                    <Tooltip top={pos.top} left={pos.left}>
                        {item.splitBookName.map((text, i) => (
                            <Text key={i} text={text} customStyle={textStyle} />
                        ))}
                    </Tooltip>,
                    document.getElementById("root") || document.body,
                )}
        </BaseField>
    );
}

const ModalBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 348px;
    overflow: auto;
`;
const FixedHeader = styled.div`
    display: flex;
    position: sticky;
    height: 48px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.white};
`;
const BaseField = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;
    width: 360px;
    font-size: 12px;
    overflow: hidden;
`;
const HeaderField = styled(BaseField)<{ width?: string; button?: boolean }>`
    font-weight: 500;
    width: ${({ width }) => width || "360px"};
    height: 48px;
    ${({ button }) =>
        button &&
        css`
            width: 200px;
            margin: 0;
            justify-content: center;
        `}
`;
const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const BodyRow = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`;
const BodyField = styled(BaseField)<{ width?: string; button?: boolean }>`
    width: ${({ width }) => width || "360px"};

    ${({ button }) =>
        button &&
        css`
            width: 200px;
            margin: 0;
            justify-content: center;
        `}
`;
// 툴팁
const textStyle = {
    lineHeight: "21px",
    fontSize: "12px",
    fontWeight: "400",
};
const EllipsisText = styled.div`
    width: 100%;
    max-width: 340px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
`;
const Tooltip = styled.div<{ top: string; left: string }>`
    position: absolute;
    min-width: 200px;
    max-height: 340px;
    padding: 3px 17px;
    z-index: 1000;
    background-color: ${({ theme }) => theme.colors.blue50};
    top: 120%;
    left: 0;
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    border-radius: 4px;
    overflow-y: auto;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
`;

interface ModalBodyProps {
    items: MutableRefObject<NonMatchedData[]>;
    matchingItems: any[];
    withRatio: boolean;
    addedRatioList: any[];
    setMatchingItems: Dispatch<SetStateAction<any[]>>;
    setAddedRatioList: Dispatch<SetStateAction<any[]>>;
}
