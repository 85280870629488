import { useEffect } from "react";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setFilterOption, setError, resetStore } from "@/pages/settlement/saels-inquiry/author/daily/dailySlice";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import FilterContainer from "@/components/molecules/common/searchFilter/FilterContainer";
import DailySearchBar from "./DailySearchBar";
import DateRangePicker from "@/components/molecules/sales-inquiry/common/DateRangePicker";
import DateButtons from "@/components/molecules/sales-inquiry/author/daily/DateButtons";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import useValidateDate from "@/modules/hooks/useValidateDate";
import useTagController from "@/components/molecules/sales-inquiry/author/useTagController";
import useFilterSetting from "@/components/molecules/sales-inquiry/author/hooks/useFilterSetting";
import { calcPeriod } from "@/modules/utils/date";
import type { CommonType } from "@/types/dataType";

export default function DailySearchFilter() {
    const { startDate, endDate, startDateError, endDateError, filterReset } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorDaily,
    );
    const dispatch = useAppDispatch();
    const { platformList, publisherList, authorList, seriesList, platformIds, publisherIds, authorIds, seriesIds } =
        useFilterSetting();
    const { validateStartDate, validateEndDate } = useValidateDate();
    const { showMoreTag, handleClickTag } = useTagController(["platform", "publisher", "author", "series"]); // tagfiler 여러개 제어
    const { startDate: initialStartDate, endDate: initialEndDate } = calcPeriod("yesterday");

    // 초기화
    useEffect(() => {
        return () => { dispatch(resetStore()); };
    }, []);

    useEffect(() => {
        dispatch(
            setFilterOption({
                selectedPlatform: platformIds,
                selectedPublisher: publisherIds,
                selectedSeries: seriesIds,
                selectedAuthor: authorIds,
            }),
        );
    }, [platformList, publisherList, authorList, seriesList]);


    const clickResetButton = () => {
        dispatch(
            setFilterOption({
                filterReset: true,
                startDate: initialStartDate,
                endDate: initialEndDate,
                selectedPlatform: platformIds,
                selectedPublisher: publisherIds,
                selectedSeries: seriesIds,
                selectedAuthor: authorIds,
            }),
        );
        dispatch(setError({ startDateError: "", endDateError: "" }));
    };

    const changePlatformTag = (platformList: CommonType.Id[]) =>
        dispatch(setFilterOption({ selectedPlatform: platformList }));

    const changePublisherTag = (publisherList: CommonType.Id[]) =>
        dispatch(setFilterOption({ selectedPublisher: publisherList }));

    const changeAuthorTag = (authorList: CommonType.Id[]) => dispatch(setFilterOption({ selectedAuthor: authorList }));

    const changeSeriesTag = (seriesList: CommonType.Id[]) => dispatch(setFilterOption({ selectedSeries: seriesList }));

    const changeStartDate = (startDate: string) => {
        const { startDateError, endDateError } = validateStartDate(startDate, endDate);
        dispatch(setFilterOption({ startDate }));
        dispatch(setError({ startDateError, endDateError }));
    };

    const changeEndDate = (endDate: string) => {
        const { startDateError, endDateError } = validateEndDate(endDate, startDate);
        dispatch(setFilterOption({ endDate }));
        dispatch(setError({ startDateError, endDateError }));
    };

    return (
        <FilterContainer>
            <FilterRow
                label="검색"
                filterContent={[<DailySearchBar />]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="판매일"
                filterContent={[
                    <DateRangePicker
                        type="default"
                        changeStartDate={changeStartDate}
                        changeEndDate={changeEndDate}
                        startDateToSet={startDate}
                        endDateToSet={endDate}
                        startDateError={startDateError}
                        endDateError={endDateError}
                    />,
                    <DateButtons />,
                    <ResetFilter onClick={clickResetButton} />,
                ]}
            />
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMoreTag.platform, onClick: () => handleClickTag("platform") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={platformList}
                        keyName="id"
                        valueName="platform_name"
                        onChange={changePlatformTag}
                        showMore={showMoreTag.platform}
                        reset={filterReset}
                    />,
                ]}
            />
            <FilterRow
                label="출판사"
                plusIcon={{ showPlusIcon: showMoreTag.publisher, onClick: () => handleClickTag("publisher") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={publisherList}
                        keyName="publisher_id"
                        valueName="publisher_name"
                        onChange={changePublisherTag}
                        showMore={showMoreTag.publisher}
                        reset={filterReset}
                    />,
                ]}
            />
            <FilterRow
                label="저자명"
                plusIcon={{ showPlusIcon: showMoreTag.author, onClick: () => handleClickTag("author") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={authorList}
                        keyName="id"
                        valueName="name"
                        onChange={changeAuthorTag}
                        showMore={showMoreTag.author}
                        reset={filterReset}
                    />,
                ]}
            />
            <FilterRow
                label="시리즈명"
                plusIcon={{ showPlusIcon: showMoreTag.series, onClick: () => handleClickTag("series") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={seriesList}
                        keyName="id"
                        valueName="series_name"
                        onChange={changeSeriesTag}
                        showMore={showMoreTag.series}
                        reset={filterReset}
                    />,
                ]}
            />
        </FilterContainer>
    );
}
