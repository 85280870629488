import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import Text from "@/components/atoms/text";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import Tooltip from "@/components/molecules/common/tooltip/Tooltip";
import TextTooltip from "@/components/molecules/common/tooltip/TextTooltip";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { addComma } from "@/modules/utils/filter";

export default function MonthlyTable() {
    const { items, fetchLoading } = useSelectorTyped((state: RootState) => state.inquiryAuthorMonthly);
    const { platformNoQty } = useSelectorTyped((state: RootState) => state.inquiryAuthorCommon);
    const { pageCount, totalVisible } = usePagination({ from: "monthly" });

    const columns = {
        book_name: ({ item }: TableItemProps) => {
            return <TextTooltip text={item.book_name} customStyle={{ width: "220px" }} />;
        },
        series_name: ({ item }: TableItemProps) => {
            return <TextTooltip text={item.series_name} />;
        },
        quantity: ({ item }: TableItemProps) => {
            const text = platformNoQty.find(p => p === item.platform_name) ? '정보없음' : item.quantity;
            return <Text text={text} customStyle={tableStyle} />;
        },
        amount_pl: ({ item }: TableItemProps) => {
            return <Text text={addComma(item.amount_pl)} customStyle={tableStyle} />;
        },
        amount_pu: ({ item }: TableItemProps) => {
            return <Text text={addComma(item.amount_pu)} customStyle={tableStyle} />;
        },
        ratio: ({ item }: TableItemProps) => {
            return <Text text={item.ratio} customStyle={tableCenterStyle} />;
        },
        author_pu: ({ item }: TableItemProps) => {
            return <Text text={addComma(Math.round(item.author_pu))} customStyle={tableStyle} />;
        },
    };

    return (
        <>
            <TableContainer width="1380px">
                <DataTable
                    from="monthly"
                    itemKey="id"
                    items={items}
                    loading={fetchLoading}
                    headers={headers}
                    customHeaders={customHeaders}
                    columns={columns}
                    options={tableOptions}
                />
                <ScrollToTopBtn />
            </TableContainer>
            <Pagination from="monthly" pageCount={pageCount} totalVisible={totalVisible} />
        </>
    );
}

const headers = [
    { text: "판매플랫폼", value: "platform_name" },
    { text: "정산월", value: "st_month" },
    { text: "실제판매월", value: "yearmon" },
    { text: "출판사", value: "publisher_name" },
    { text: "작품명", value: "book_name" },
    { text: "시리즈명", value: "series_name" },
    { text: "저자명", value: "author_name" },
    { text: "판매량", value: "quantity" },
    { text: "판매금액(원)", value: "amount_pl" },
    { text: "출판사정산금(원)", value: "amount_pu" },
    { text: "작가정산비율(%)", value: "ratio", textAlign: "center" },
    { text: "작가정산금액(원)", value: "author_pu" },
];

const customHeaders = {
    quantity: () => (
        <QuantityWrapper>
            <Text text="판매량" customStyle={tooltipStyle} />
            <Tooltip
                iconWidth={20}
                iconHeight={20}
                textBoxWidth={280}
                fill={colors.gray600}
                text="일부 플랫폼의 경우 판매량 정보를 제공하지 않거나
          대여권 판매, 환불 등의 항목을 판매량에 반영하지 않아
          정확하지 않을 수도 있습니다."
            />
        </QuantityWrapper>
    ),
};

const tableOptions = {
    showCheckbox: false,
};
const tooltipStyle = {
    fontSize: "12px",
    margin: "0 8px 0 0",
};
const tableStyle = {
    textAlign: "right",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px",
};
const tableCenterStyle = {
    ...tableStyle,
    textAlign: "center",
};

const QuantityWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
