import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ExcelUploadSeriesNameInfo, ExcelUploadState, SearchParams } from './types';
import { Key } from "../types";
import api from "@/api";

// 시리즈/작품명 연동 검색
export const getExcelUploadData = createAsyncThunk("excelUpload/getExcelUploadData", async (apiParams:SearchParams, { rejectWithValue }) => {
	try {
		// let apiParams = {
		// 	platform_id: this.platform_id,
		// 	platform_name: platform_name,
		// 	name: this.name || null,
		// 	series_id_list: this.series_id_list.length ? this.series_id_list : null,
		// }
		const res = await api.post(`/api/settlement/baseData/excelUpload/select`, apiParams);
		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

// 시리즈/작품명 연동 변경사항 저장
export const saveExcelUploadData = createAsyncThunk("excelUpload/saveExcelUploadData", async (apiParams:{ seriesMatchingData: ExcelUploadSeriesNameInfo[] }, { rejectWithValue }) => {
	try {
		// let apiParams = {
		// 	seriesMatchingData: this.updatedRows
		// }
		const res = await api.post(`/api/settlement/baseData/excelUpload/update`, apiParams);
		// 비동기 요청 오류 발생시키기
		// throw "err!!!"
		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

// 시리즈/작품명 연동 삭제
export const deleteExcelUploadData = createAsyncThunk("excelUpload/deleteExcelUploadData", async (apiParams:{ seriesMatchingData: ExcelUploadSeriesNameInfo[]}, { rejectWithValue }) => {
	try {

		const res = await api.post(`/api/settlement/baseData/excelUpload/delete`, apiParams);

		return res.data;
	} catch (err: any) {
		throw rejectWithValue(err.response.data);
	}
})

// Define the initial state using that type
const initialState: ExcelUploadState = {
	data: [],
	totalCnt: 0,
	loading: false,
	error: false,
	seriesName: "",
	contentName: "",
	platformId: 0,
	matchingIdx: 0,
	tooltipSeriesName: "",

	series_id_list: [],

}

export const excelUploadSlice = createSlice({
	name: 'excelUpload',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		reset() {
			return {...initialState};
		},
		setSearchKeyword(state, action: PayloadAction<string>) {
			state.seriesName = action.payload;
        },
		setContentName(state, action: PayloadAction<string>) {
			state.contentName = action.payload;
        },
		setPlatformId(state, action: PayloadAction<number>) {
			state.platformId = action.payload;
        },
		setExcelUploadDatas(state, action: PayloadAction<Key>) {
			let key = action.payload.key;
			let value = action.payload.value;
			let index = action.payload.index;
			if (typeof index === "number") {
				state[key][index] = value;
			} else {
				state[key] = value;
			}
        },
		setTooltipSeriesName(state, action: PayloadAction<string>) {
			state.tooltipSeriesName = action.payload;
        },
	},
	extraReducers: (builder) => {
		builder.addCase(getExcelUploadData.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(getExcelUploadData.fulfilled, (state: any, action) => {
			state.loading = false;
			if (action.payload.status === "success") {
				state.data = action.payload.data.seriesNameInfo;
				state.totalCnt = action.payload.data.totalCnt;
				state.error = false;
			} else {
				state.data = initialState.data;
				state.totalCnt = initialState.totalCnt;
				state.error = action.payload.message;
			}
		})
		builder.addCase(getExcelUploadData.rejected, (state, action) => {
			state.loading = false;
			state.error = true;
		});

		builder.addCase(deleteExcelUploadData.fulfilled, (state: any, action) => {
			state.loading = false;
			if (action.payload.status === "success") {
				state.error = false;
			} else {
				// state.data = action.payload.data;
				state.error = action.payload.message;
			}
		})

		builder.addCase(saveExcelUploadData.fulfilled, (state: any, action) => {
			if (action.payload.status === "success") {
				state.error = false;
			} else {
				state.error = action.payload.message;
			}
			state.loading = false;
		})
	}
})

// export const { setUsable, setPaymonth } = excelUploadSlice.actions
export const excelUploadActions = excelUploadSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.author

export default excelUploadSlice.reducer