import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import TextInput from "@/components/atoms/input/TextInput";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import { useEffect, useState } from "react";
import Text from "@/components/atoms/text";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import moment from "moment";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { authorActions } from "@/pages/settlement/basedata-manage/author/authorSlice";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { ratio, amount, Validation } from "@/modules/utils/validate_modify";
import { addComma, removeComma } from "@/modules/utils/filter";

export default function EditCoRelatedDataModalBody({ coRelatedData, setCoRelatedData }:{ coRelatedData: any[], setCoRelatedData: Function }) {
    const dispatch = useAppDispatch();
    const { setDataCount, page, pageCount, setItemsPerPage } = usePagination({ from: "EditCoRelatedData" });

    // let [coRelatedData] = useAppSelector((state:RootState) => [
    //     state.author.tmpCoRelatedData
    // ]);
    // const [currData, setCurrData] = useState<any>({});
    const [tmpCoRelatedData, setTmpCoRelatedData] = useState<any[]>([]);

    useEffect(() => {
        let total = 0;
        let tmp = [];
        for (let c of coRelatedData) {
            if (c.needInput) {
                total++;
                tmp.push(c);
            }
        }
        setDataCount(total);
        setItemsPerPage(1);
        setTmpCoRelatedData(tmp);
    }, [coRelatedData])

    function changeRatioValues(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        let value = e.target.value;
        const res = ratio({ value, error: "ratioError", mandatory: true, field: "정산비율", decimal: false }) as Validation;
        let update = JSON.parse(JSON.stringify(tmpCoRelatedData));
        update[page-1].new_co_info[index]["coRatio"] = Number(res.value);
        update[page-1].new_co_info[index]["coRatioError"] = res.ratioError;
        setTmpCoRelatedData(update);
        // setCoRelatedData(update);
    }

    function changeAmountValues(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        let value = removeComma(e.target.value);
        console.log(value)
        const res = amount({ value, error: "taxAmountError", mandatory: true, field: "적용 세액", decimal: true }) as Validation;
        let update = JSON.parse(JSON.stringify(tmpCoRelatedData));
        // update[page-1].new_co_info[index]["taxAmount"] = res.value;
        update[page-1].new_co_info[index]["taxAmount"] = addComma(res.value);
        update[page-1].new_co_info[index]["taxAmountError"] = res.taxAmountError;
        setTmpCoRelatedData(update);
        // setCoRelatedData(update);
    }

    function valueBlur() {
        setCoRelatedData(tmpCoRelatedData);
    }

    return (
        <>
        { tmpCoRelatedData.slice((page - 1), page).map((currData, i) =>
            <BodyWrap key={i}>
                <CheckDataWrap>
                    {
                        currData.type === "etc" &&
                        <StyledUl>
                            <StyledLi>
                                <FieldName>
                                    <Text text="저자명" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.author_name} customStyle={{ fontSize: "12px", fontWeight: "400" }}/>
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="기타지급금" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="플랫폼" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.platform_name? currData.platform_name : "해당없음"} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="입금일자" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.in_date === null ? "해당없음" : moment(currData.in_date).format("YYYY-MM-DD")} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="지급일자" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={moment(currData.date).format("YYYY-MM-DD")} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="금액(원)" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.pre_amount.toLocaleString()} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="정산 비율(%)" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.st_ratio} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="적용 세목" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.etc_tax_type_desc} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="지급 사유" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.reason} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="기타지급금" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                        </StyledUl>
                    }
                    {
                        currData.type === "mg" &&
                        <StyledUl>
                            <StyledLi>
                                <FieldName>
                                    <Text text="저자명" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.author_name} customStyle={{ fontSize: "12px", fontWeight: "400" }}/>
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="선인세" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="계약시작일" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={moment(currData.date).format("YYYY-MM-DD")} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="선인세구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.mg_type === "platform" ? "플랫폼" : "출판사"} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="판매플랫폼" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.platform_name} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="계약형태" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.contract_type} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="계약대상" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="계약대상" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="계약금액" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.contract_price.toLocaleString()} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="정산비율" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.mg_ratio} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                        </StyledUl>
                    }
                    {
                        currData.type === "series" &&
                        <StyledUl>
                            <StyledLi>
                                <FieldName>
                                    <Text text="저자명" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.author_name} customStyle={{ fontSize: "12px", fontWeight: "400" }}/>
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="시리즈" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="시리즈명" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.series_name} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                        </StyledUl>
                    }
                </CheckDataWrap>
                <UpdateDataWrap>
                    <Theader>
                        <Tr height="48px">
                            <Th width="100px">본명</Th>
                            <Th width={(currData.type === "etc" && currData.etc_tax_type === "E")? "110px": "220px"}
                                justifyContent="center">공저간 정산 비율</Th>
                            { (currData.type === "etc" && currData.etc_tax_type === "E")
                            && <Th width="110px" justifyContent="flex-end">적용 세액</Th>}
                        </Tr>
                    </Theader>
                    <TBody>
                        {currData.new_co_info.map((c:any, i:number) => (
                            <Tr key={i}>
                                <Td>
                                    <Text text={c.realName} customStyle={{ fontSize: "14px", fontWeight: "400" }} />
                                </Td>
                                <Td width={(currData.type === "etc" && currData.etc_tax_type === "E") ? "110px" : "220px"} justifyContent="center">
                                    <ValidateWrapper dataError={c.coRatioError}>
                                        <PercentInputText
                                            type="number"
                                            value={c.coRatio}
                                            placeholder="정산 비율"
                                            inText="%"
                                            customStyle={{ width: "90px", height: "32px", padding: "0 18px 2px 0", fontSize: "12px" }}
                                            onChange={(e)=> { changeRatioValues(e, i) }}
                                            onBlur={valueBlur}
                                        />
                                    </ValidateWrapper>
                                </Td>
                                { (currData.type === "etc" && currData.etc_tax_type === "E")
                                &&
                                <Td width="110px" justifyContent="flex-end">
                                    <TextInput
                                        type={"main"}
                                        value={c.taxAmount}
                                        placeholder="적용 세액"
                                        onChangeHandler={(e) => { changeAmountValues(e, i) }}
                                        onBlurHandler={valueBlur}
                                        customStyle={{ width: "90px", height: "32px", fontSize: "12px", fontWeight: "400" }}
                                    />
                                </Td>}
                            </Tr>
                        ))}
                    </TBody>
                </UpdateDataWrap>
                <Pagination pageCount={pageCount} totalVisible={5} customStyle={{ margin: "0" }} from="EditCoRelatedData"/>
            </BodyWrap>
            )
        }
        {/* { (currData?.type && currData?.needInput) &&
            <BodyWrap>
                <CheckDataWrap>
                    {
                        currData.type === "etc" &&
                        <StyledUl>
                            <StyledLi>
                                <FieldName>
                                    <Text text="저자명" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.author_name} customStyle={{ fontSize: "12px", fontWeight: "400" }}/>
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="기타지급금" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="플랫폼" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.platform_name? currData.platform_name : "해당없음"} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="입금일자" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.in_date === null ? "해당없음" : moment(currData.in_date).format("YYYY-MM-DD")} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="지급일자" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={moment(currData.date).format("YYYY-MM-DD")} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="금액(원)" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.pre_amount.toLocaleString()} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="정산 비율(%)" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.st_ratio} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="적용 세목" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.etc_tax_type_desc} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="지급 사유" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.reason} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="기타지급금" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                        </StyledUl>
                    }
                    {
                        currData.type === "mg" &&
                        <StyledUl>
                            <StyledLi>
                                <FieldName>
                                    <Text text="저자명" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.author_name} customStyle={{ fontSize: "12px", fontWeight: "400" }}/>
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="선인세" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="계약시작일" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={moment(currData.date).format("YYYY-MM-DD")} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="선인세구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.mg_type === "platform" ? "플랫폼" : "출판사"} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="판매플랫폼" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.platform_name} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="계약형태" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.contract_type} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="계약대상" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="계약대상" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="계약금액" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.contract_price.toLocaleString()} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="정산비율" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.mg_ratio} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                        </StyledUl>
                    }
                    {
                        currData.type === "series" &&
                        <StyledUl>
                            <StyledLi>
                                <FieldName>
                                    <Text text="저자명" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.author_name} customStyle={{ fontSize: "12px", fontWeight: "400" }}/>
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="구분" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text="시리즈" customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                            <StyledLi>
                                <FieldName>
                                    <Text text="시리즈명" customStyle={{ fontSize: "14px", fontWeight: "400" }}/>
                                </FieldName>
                                <FieldValue>
                                    <Text text={currData.series_name} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                                </FieldValue>
                            </StyledLi>
                        </StyledUl>
                    }
                </CheckDataWrap>
                <UpdateDataWrap>
                    <Theader>
                        <Tr height="48px">
                            <Th width="100px">본명</Th>
                            <Th width={(currData.type === "etc" && currData.etc_tax_type === "E")? "110px": "220px"}
                                justifyContent="center">공저간 정산 비율</Th>
                            { (currData.type === "etc" && currData.etc_tax_type === "E")
                            && <Th width="110px" justifyContent="flex-end">적용 세액</Th>}
                        </Tr>
                    </Theader>
                    <TBody>
                        {currData.new_co_info.map((c:any, i:number) => (
                            <Tr key={i}>
                                <Td>
                                    <Text text={c.realName} customStyle={{ fontSize: "14px", fontWeight: "400" }} />
                                </Td>
                                <Td width={(currData.type === "etc" && currData.etc_tax_type === "E") ? "110px" : "220px"} justifyContent="center">
                                    <ValidateWrapper dataError={c.coRatioError}>
                                        <PercentInputText
                                            type="number"
                                            value={c.coRatio}
                                            placeholder="정산 비율"
                                            inText="%"
                                            customStyle={{ width: "90px", height: "32px", padding: "0 18px 2px 0", fontSize: "12px" }}
                                            onChange={(e)=> { changeValues(e, i) }}
                                            // onChange={(e) => {
                                            //     changeItem(idx, {...item, "taxRatio": e.target.value, "joint": [{...item.joint[0], "taxRatio": e.target.value}]});
                                            //     changeDatas(e, "taxRatio", idx);
                                            // }}
                                        />
                                    </ValidateWrapper>
                                </Td>
                                { (currData.type === "etc" && currData.etc_tax_type === "E")
                                &&
                                <Td width="110px" justifyContent="flex-end">
                                    <TextInput
                                        type={"main"}
                                        value={c.taxAmount}
                                        placeholder="적용 세액"
                                        onChangeHandler={(e) => {}}
                                        // onChangeHandler={(e) => validateValue("amount", idx, e.target.value, "amountError")}
                                        // onFocusHandler={() => removeComma("amount", idx)}
                                        // onBlurHandler={() => addCommaEvent("amount", idx)}
                                        customStyle={{ width: "90px", height: "32px", fontSize: "12px", fontWeight: "400" }}
                                    />
                                </Td>}
                            </Tr>
                        ))}
                    </TBody>
                </UpdateDataWrap>
                <Pagination pageCount={pageCount} totalVisible={5} customStyle={{ margin: "0" }} from="EditCoRelatedData"/>
            </BodyWrap>} */}
        </>
    )

}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 484px;
    max-height: 484px;
    overflow: auto;
`

const CheckDataWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const UpdateDataWrap = styled.table`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

const Theader = styled.thead`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
`;

const Tr = styled.tr<{height?: string, active?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => height ? height : "60px"};
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    cursor: ${({ active }) => active ? "pointer" : "default"};
`;

const Th = styled.th<{ width?: string; height?: string; padding?: string; margin?: string; justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "100%")};
    padding: ${({ padding }) => (padding ? padding : "8px")};
    margin: ${({ margin }) => (margin ? margin : "")};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`;

const TBody = styled.tbody<{ backGroundColor?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 280px;
    background-color: ${({ theme, backGroundColor }) => backGroundColor ? theme.colors.gray50 : ""};
`;

const Td = styled.td<{width?: string, padding?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    height: 100%;
    width: ${({ width }) => width ? width : "100px"};
    padding: ${({ padding }) => padding ? padding : "10px"};
`;

const StyledUl = styled.ul`

`

const StyledLi = styled.li`
    display: flex;
    align-items: center;
    height: 32px;
`

const FieldName = styled.div`
    width: 100px;
`

const FieldValue = styled.div`

`