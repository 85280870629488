import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { useAppSelector, RootState, useAppDispatch } from "@/modules/store";
import { useState } from "react";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import TimelineDetailModal from "./TimelineDetailModal";
import ScrollContainer from "react-indiana-drag-scroll";
import TimelineRowWrapper from "./TimelineRowWrapper";
import { ReactComponent as Down } from "@/assets/icons/down.svg";

export default function TimelineContainer() {
    const { timelineData } = useAppSelector((state: RootState) => state.authorPortfolio);
    const { totalYearmonList, eachPlatformHistory } = timelineData;
    const [detailModal, showDetailModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [modalTitle, setModalTitle] = useState("");
    const [timelineLimit, setTimelineLimit] = useState(5);
    const dispatch = useAppDispatch();

    const dotHoverHandler = (platformIdx: number, timelineIdx: number, value: boolean) => {
        const tmpList = JSON.parse(JSON.stringify(eachPlatformHistory));
        tmpList[platformIdx].timelineData[timelineIdx].hover = value;
        dispatch({ type: "authorPortfolio/setTimelineEachPlatformHistory", payload: tmpList })
    }

    const dotClickHandler = (detailData: any, platform_name: string, timelineIdx: number) => {
        // 상세 모달 open
        setModalData(detailData);
        setModalTitle(platform_name)
        showDetailModal(true);
        // 해당월 active
        yearmonHandler(timelineIdx);
    }

    const yearmonHandler = (idx: number) => {
        const tmpList = JSON.parse(JSON.stringify(totalYearmonList));
        for (let t of tmpList) {
            t.active = false;
        }
        tmpList[idx].active = true;
        dispatch({ type: "authorPortfolio/setTimelineTotalYearmon", payload: tmpList });

        const tmpList2 = JSON.parse(JSON.stringify(eachPlatformHistory));
        for (let t of tmpList2) {
            for (let d of t.timelineData) {
                d.active = false;
            }
            t.timelineData[idx].active = true;
        }
        dispatch({ type: "authorPortfolio/setTimelineEachPlatformHistory", payload: tmpList2 })
    }

    return (
        <Container>
            <TitleWrapper>타임라인</TitleWrapper>
            <ScrollContainer>
                <TimelineWrapper>
                    <YearmonListWrapper>
                        <YearmonWrapper></YearmonWrapper>
                        {totalYearmonList.map((y, idx) => (
                            <YearmonWrapper key={idx}>
                                <Year>{y.isFirst ? y.yearmon.substring(0,4) : ""}</Year>
                                <MonthWrapper>
                                    <Month
                                        active={y.active}
                                        onClick={() => yearmonHandler(idx)}
                                    >
                                        {y.yearmon.substring(4,6)}
                                    </Month>
                                </MonthWrapper>
                            </YearmonWrapper>
                        ))}
                    </YearmonListWrapper>
                    <TimelineListWrapper>
                        {eachPlatformHistory.map((p, pIdx) => {
                            if (pIdx < timelineLimit) {
                                return (
                                    <TimelineRowWrapper 
                                        history={p}
                                        key={pIdx}
                                        idx={pIdx}
                                        dotClickHandler={dotClickHandler}
                                        dotHoverHandler={dotHoverHandler}
                                    />
                                )
                            }
                        })}
                    </TimelineListWrapper>
                </TimelineWrapper>
            </ScrollContainer>
            <ModalPortal>
                <TimelineDetailModal
                    show={detailModal}
                    close={() => showDetailModal(false)}
                    from="portfolio-detail"
                    modalData={modalData}
                    modalTitle={modalTitle}
                />
            </ModalPortal>
            {Boolean(timelineLimit < eachPlatformHistory.length) &&
                <MoreBtnWrapper>
                    <MoreBtn onClick={() => setTimelineLimit(timelineLimit+5)}>
                        더보기
                        <Down fill={colors.blue500}/>
                    </MoreBtn>
                </MoreBtnWrapper>
            }
        </Container>
    )
}

const Container = styled.div`
    margin-top: 24px;
`

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
`

const TimelineWrapper = styled.div`
    width: 100%;
    margin-top: 26px;
    cursor: grab;
`

const YearmonListWrapper = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100px;

`

const YearmonWrapper = styled.li`
    min-width: 130px;
    height: 100%;
    padding-right: 80px;
`

const Year = styled.div`
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
`

const MonthWrapper = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
`

const Month = styled.div<{ active?: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 32px;
    background-color: ${({ active }) => active ? colors.blue500 : colors.gray50};
    color: ${({ active }) => active ? colors.white : colors.gray600};
    border-radius: 99px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        box-shadow: 0px 0px 15px ${colors.blue500};
        background-color: ${colors.blue500};
        color: ${colors.white}
    }
`

const TimelineListWrapper = styled.ul``

const MoreBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    color: ${colors.blue500};
`

const MoreBtn = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 76px;

`
