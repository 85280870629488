import { hangulParticle } from "./filter";

export const integer = ({ field, item, error, min, max, mandatory }: Integer) => {
    let errorInfo = {
        [error]: "",
        value: item,
    };

    let v: string | number = "";
    v = item.replace(/[^\d-]/g, "").replace(/(?!^)-/g, "");
    if ((!v || v === "-") && mandatory) {
        errorInfo.value = v;
        errorInfo[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return errorInfo;
    }
    if (v.length > 11) {
        v = v.slice(0, 11);
    }

    v = Number(v);
    errorInfo.value = String(v);

    if (v < min) {
        errorInfo[error] = `${min} 이상의 수를 입력해주세요.`;
        return errorInfo;
    } else if (v > max) {
        errorInfo[error] = `${max} 이하의 수를 입력해주세요.`;
        return errorInfo;
    }

    errorInfo.msg = "";
    return errorInfo;
};

export const amount = ({ field, item, error, decimal, mandatory }: Amount) => {
    let errorInfo = {
        [error]: "",
        value: item,
    };
    if (item === undefined || typeof item === "number") return errorInfo;
    let v = decimal
        ? item.replace(/[^\d.-]/g, "").replace(/(?!^)-/g, "")
        : item.replace(/[^\d-]/g, "").replace(/(?!^)-/g, "");

    if ((!v || v === "-" || v === "-.") && mandatory) {
        if (v === "-.") v = "-";
        errorInfo.value = v;
        errorInfo[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return errorInfo;
    }

    const hasPoint = v.indexOf(".") > -1 ? true : false;
    let int = 0;
    // let dec = 0;
    let dec = "";
    if (decimal && hasPoint) {
        let tmp = v.split(".");
        int = Number(tmp.shift());
        dec = tmp.join("");
    } else {
        int = Number(v);
    }

    v = `${String(int).slice(0, 11)}${hasPoint ? "." : ""}${dec !== "" ? dec.slice(0, 2) : ""}`;

    errorInfo.value = v;
    if (String(int).length > 11) {
    } else if (decimal && hasPoint && !dec) {
        errorInfo[error] = "소수점 이하를 입력해주세요.";
    } else {
        errorInfo[error] = "";
    }
    return errorInfo;
};

export const ratio = ({ field, item, error, decimal, mandatory }: Amount) => {
    let errorInfo = {
        [error]: "",
        value: item,
    };
    if (item === undefined || typeof item === "number") return errorInfo;
    let v = decimal ? item.replace(/[^\d.]/g, "").replace(/(?!^)-/g, "") : item.replace(/[^\d]/g, "");
    if ((!v || v === ".") && mandatory) {
        errorInfo.value = v;
        errorInfo[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return errorInfo;
    }

    if (!mandatory && v === "") {
        return errorInfo;
    }

    const hasPoint = v.indexOf(".") > -1 ? true : false;
    let int = 0;
    // let dec = 0;
    let dec = "";
    if (decimal && hasPoint) {
        let tmp = v.split(".");
        int = Number(tmp.shift());
        dec = String(tmp.join("")).slice(0, 2);
    } else {
        int = Number(v);
    }

    v = `${String(int).slice(0, 3)}${hasPoint ? "." : ""}${dec !== "" ? dec : ""}`;
    errorInfo.value = v;
    if (Number(String(int).slice(0, 3)) > 100 || (Number(String(int).slice(0, 3)) === 100 && dec)) {
        errorInfo[error] = "100 이하의 수를 입력해주세요.";
    } else if (decimal && hasPoint && !dec) {
        errorInfo[error] = "소수점 이하를 입력해주세요.";
    } else {
        errorInfo[error] = "";
    }
    return errorInfo;
};

export const text = ({ field, item, error, max, mandatory }: Text) => {
    let errorInfo = {
        [error]: "",
        value: item,
    };

    if (!item.length && mandatory) {
        errorInfo[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return errorInfo;
    } else if (max && item.length > max) {
        errorInfo[error] = `${max}자 이내로 입력해주세요.`;
        return errorInfo;
    } else {
        errorInfo[error] = "";
        return errorInfo;
    }
};

export const email = ({ field, item, error, mandatory }: Email) => {
    const exp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    let errorInfo = {
        [error]: "",
        value: item,
    };
    if (!item && mandatory) {
        errorInfo[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return errorInfo;
    } else if (!item && !mandatory) {
        errorInfo[error] = "";
        return errorInfo;
    } else if (exp.test(item) === false ) {
        errorInfo[error] = "올바른 이메일 형식이 아닙니다.";
        return errorInfo;
    } else {
        errorInfo[error] = "";
        return errorInfo;
    };      
};

// TYPE DEFINITION
interface Integer {
    field: string;
    item: string;
    error: string;
    min: number;
    max: number;
    mandatory: boolean;
}

interface Amount {
    field: string;
    item: string;
    error: string;
    decimal: boolean;
    mandatory: boolean;
}

interface Text {
    field: string;
    item: string;
    error: string;
    max: number;
    mandatory: boolean;
}

interface Email {
    field: string;
    item: string;
    error: string;
    mandatory: boolean;
}
