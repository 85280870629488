import styled from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { signupActions, checkId, regCheck } from "@/pages/auth/signUp/signupSlice";
import { ChangeEvent, useEffect, useState } from "react";
import Button from "@/components/atoms/button";
import BaseInput from "@/components/molecules/signup/BaseInput";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import useAlert from "@/modules/hooks/useAlert";

export default function PublisherCheck() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    let step = useSelectorTyped((state:RootState)=>state.signup.step)
    const [publisherNumber, setPublisherNumber] = useState<string>("");

    function changeHandlerCompanyNumber(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        value = value.replace(/[^\d]/g, "");
        if (value.length > 10) {
            value = value.slice(0,10);
        }
        value = value.length > 5 ? value.replace(/\(?(\d{3})\)?[- ]?(\d{2})[- ]?(\d)/g, "$1-$2-$3") : 
        value.length > 3 ? value.replace(/\(?(\d{3})\)?[- ]?(\d)/g, "$1-$2") : value;
        // return value;
        setPublisherNumber(value);
    }

    const keyPressHandler = (key: any) => {
        if (key === "Enter") {
            next();
        }
    };

    async function next() {
        try {
            if (publisherNumber.length < 12) {
                return await alert("warning", "주의", "사업자 등록번호를 확인해주세요.");
            }
            let apiParams = {
                regNo: publisherNumber
            }
    
            const checkYn = await dispatch(regCheck(apiParams)).unwrap();
    
            if (checkYn.status === "fail") {
                return await alert("warning", "계정생성 제한 초과", "출판사 계정 생성 제한을 초과하였습니다. 출판사 관리자 계정 소유자에게 문의해주세요.")
            }
    
            if (checkYn.status === "success" && checkYn.data) {
                dispatch(signupActions.updatePublisherInfo(checkYn.data))
            }
            dispatch(signupActions.updateCompanyRegNo(publisherNumber));
            if (!checkYn.data) {
                const answer = await alert("confirm", "신규등록", "등록되지 않은 출판사입니다. 신규로 등록하시겠습니까?");
                if (answer === "Y") {
                    dispatch(signupActions.changeStep(++step));
                }
            } else {
                dispatch(signupActions.changeStep(++step));
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    function prev() {
        dispatch(signupActions.changeStep(--step));
    }

    return (
        <>
            <BaseInput
                label="사업자 등록번호"
                value={publisherNumber}
                placeholder="사업자 등록번호"
                errorMsg={""}
                onChange={changeHandlerCompanyNumber}
                onKeyPress={keyPressHandler}
            />
            <Button
                type="main"
                label="다음"
                customStyle={{ width: "400px",
                                height: "60px",
                                margin: "24px 0 0 0",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={next}
            />
            <Button
                type="red-outline"
                label="이전으로"
                customStyle={{ width: "400px",
                                height: "60px",
                                margin: "24px 0 0 0",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={prev}
            />
        </>

    )
}

const IconWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TextWrap = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-top: 40px;
`

const BtnWrap = styled.div`
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
`