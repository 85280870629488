import styled from "styled-components";
import { Oval } from "react-loader-spinner";
import Text from "@/components/atoms/text";
import Checkbox from "@/components/atoms/input/Checkbox";
import theme, { colors } from "@/assets/styles/theme";
import { useAppSelector, RootState } from "@/modules/store";
import { useState } from "react";


function SummaryCard(props: SummaryCardProps) {
    const { salesSummaryLoading } = useAppSelector((state: RootState) => state.pubDashboard);
    const [afterTax, setWithTax] = useState(false);

    if (salesSummaryLoading) {
        return (
            <StyledCard>
                <LoaderWrapper>
                    <Oval 
                        color={theme.colors.blue600} 
                        secondaryColor={theme.colors.blue100} 
                        width="50"
                        height="50"
                    />
                </LoaderWrapper>
            </StyledCard>
        )
    }

    const typeStyle = {
        color: props.isFirst ? theme.colors.blue500 : theme.colors.gray600,
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "20.03px"
    }

    const amountStyle = {
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "25px",
        margin: "0 0 4px 0"
    }

    const descStyle = {
        fontSize: "12px",
        lineHeight: "12px",
        color: props.cardData.rate >= 0 ? theme.colors.error : theme.colors.information
    }

    const checkBoxLabel = {
        fontSize: "12px",
        fontWeight: "400",
        color: colors.gray600,
        margin: "1px 0 0 8px"
    }

    const checkWithTax = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWithTax(e.target.checked);
    }

    let afterTaxCheck = <></>;
    if (props.isLast) {
        afterTaxCheck = (
            <FlexBox>
                <Checkbox type="boolean" active={afterTax} onChangeHandler={checkWithTax} />
                <Text text="세액 제외" customStyle={checkBoxLabel} />
            </FlexBox>
        )
    }

    return (
        <StyledCard>
            <LeftWrapper>
                <Text text={props.cardData.type} customStyle={typeStyle}/>
            </LeftWrapper>
            <div>
                <Text text={afterTax ? props.cardData.amountAfterTax : props.cardData.amount} customStyle={amountStyle}/>
                <Text text={props.cardData.desc} customStyle={descStyle}/>
                {afterTaxCheck}
            </div>
        </StyledCard>
    )
}

const StyledCard = styled.li`
    display: flex;
    align-items: center;
    width: 280px;
    height: 100px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

const LeftWrapper = styled.div`
    display: flex;
    padding-left: 21px;
    margin-right: 10px;
    width: 110px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
`

const FlexBox = styled.div`
    display: flex;
    margin-top: 8px;
`

interface SummaryCardProps {
    cardData: Card2;
    isFirst: boolean;
    isLast?: boolean;
}

interface Card2 {
    type: string;
    amount: string;
    amountAfterTax: string;
    desc: string;
    rate: number;
}

export default SummaryCard;