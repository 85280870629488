// 한 페이지 단위로 적용되는 컴포넌트들
import React from "react";
import styled from "styled-components";

function SectionLayout({ children, customStyle }: { children: React.ReactNode, customStyle?: any }) {
    return <SectionContainer position={customStyle?.position}>{children}</SectionContainer>;
}

const SectionContainer = styled.div<{ position?: string }>`
    position: ${({ position }) => position || "static" };
    width: 1200px;
    height: auto;
    margin: 0 auto;
    padding: 30px 0;
`;

export default SectionLayout;
