import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";

export default function Agreement5() {
    return(
        <StyledContainer>
            <Title>제5장 과오금, 피해보상 등</Title>
            <SubTitle>제30조(과오금)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환불하여야 합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;의 책임 있는 사유로 과오금이 발생한 경우 &quot;회사&quot;는 계약비용, 수수료 등에 관계없이 과오금 전액을 환불합니다. 다만, &quot;이용자&quot;의 책임 있는 사유로 과오금이 발생한 경우, &quot;회사&quot;가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 &quot;이용자&quot;가 부담하여야 합니다.</LiStyled>
                <LiStyled>③ 회사는 &quot;이용자&quot;가 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게 이용대금이 부과되었음을 입증할 책임을 집니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;는 과오금의 환불절차를 디지털콘텐츠이용자보호지침에 따라 처리합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제31조(콘텐츠하자 등에 의한 이용자피해보상)</SubTitle>
            <UlStyled>
                <LiStyled>&quot;회사&quot;는 콘텐츠하자 등에 의한 이용자피해보상의 기준·범위·방법 및 절차에 관한 사항을 디지털콘텐츠이용자보호지침에 따라 처리합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제32조(면책조항)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 천재지변 또는 이에 준하는 불가항력으로 인하여 &quot;콘텐츠&quot;를 제공할 수 없는 경우에는 &quot;콘텐츠&quot; 제공에 관한 책임이 면제됩니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;는 &quot;이용자&quot;의 귀책사유로 인한 콘텐츠이용의 장애에 대하여는 책임을 지지 않습니다.</LiStyled>
                <LiStyled>③ &quot;회사&quot;는 &quot;회원&quot;이 &quot;콘텐츠&quot;와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;는 &quot;이용자&quot; 상호간 또는 &quot;이용자&quot;와 제3자 간에 &quot;콘텐츠&quot;를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다.</LiStyled>
            </UlStyled>
            <SubTitle>제33조(분쟁의 해결)</SubTitle>
            <UlStyled>
                <LiStyled>&quot;회사&quot;는 분쟁이 발생하였을 경우에 &quot;이용자&quot;가 제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가 곤란한 경우에 &quot;회사&quot;는 &quot;이용자&quot;에게 그 사유와 처리일정을 통보합니다.</LiStyled>
            </UlStyled>
        </StyledContainer>
        
    )
}

const StyledContainer = styled.div`

`

const Title = styled.h4`
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 40px;
    width: auto;
`

const SubTitle = styled.h6`
    font-weight: 700;
    line-height: 20px;
    margin: 16px 0px;
`

const UlStyled = styled.ul`
`

const LiStyled = styled.li`
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${colors.gray600}
`

const OlStyled = styled.ol`
    margin: 16px 0px;
`