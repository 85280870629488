import styled from "styled-components";
import Text from "@/components/atoms/text";
import Tooltip from "@/components/molecules/common/tooltip/Tooltip";
import { colors } from "@/assets/styles/theme";
import MgHistoryTableWrapper from "@/components/molecules/dashboard/author/MgHistoryTableWrapper";
import { useAppSelector, RootState } from "@/modules/store";
import { Oval } from "react-loader-spinner";

export default function MgHistoryTableContainer() {
    const { mgDeductionHistoryLoading } = useAppSelector((state: RootState) => state.authorDashboard);

    if (mgDeductionHistoryLoading) {
        return (
            <StyledContainer>
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
            </StyledContainer>
        )
    }

    return (
        <StyledContainer>
            <TitleWrapper />
            <MgHistoryTableWrapper />
        </StyledContainer>
    )
}

function TitleWrapper() {
    const titleStyle = {
        fontSize: "16px",
        fontWeight: "700"
    }

    return (
        <StyledTitleWrapper>
            <Text text="선인세 소진 현황" customStyle={titleStyle} />
            <Tooltip 
                iconWidth={20} 
                iconHeight={20} 
                textBoxWidth={160}
                fill={colors.gray600} 
                text="최근 3개월동안의\n 선인세 소진 현황입니다." 
            />
        </StyledTitleWrapper>
    )
}

const StyledTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 14px;
    width: 168px;
    height: 48px;
`

const StyledContainer = styled.div`
    width: 1200px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`