import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";

export default function Agreement3() {
    return(
        <StyledContainer>
            <Title>제3장 콘텐츠이용계약</Title>
            <SubTitle>제14조(&quot;콘텐츠&quot;의 내용 등의 게시)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 다음 사항을 해당 &quot;콘텐츠&quot;의 이용초기화면이나 그 포장에 &quot;이용자&quot;가 알기 쉽게 표시합니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 가입신청 시에 허위내용을 등록한 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. &quot;회사&quot;의 서비스이용대금, 기타 &quot;회사&quot;의 서비스이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 다른 사람의 &quot;회사&quot;의 서비스이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. &quot;회사&quot;를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</LiStyled>
                </OlStyled>
                <LiStyled>③ &quot;회사&quot;가 회원자격을 제한·정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 &quot;회사&quot;는 회원자격을 상실시킬 수 있습니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 &quot;회원&quot;에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제15조(이용계약의 성립 등)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;이용자&quot;는 &quot;회사&quot;가 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. &quot;회사&quot;는 계약 체결 전에 각 호의 사항에 관하여 &quot;이용자&quot;가 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. &quot;콘텐츠&quot; 목록의 열람 및 선택</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. 성명, 주소, 전화번호(또는 이동전화번호), 전자우편주소 등의 입력</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 약관내용, 청약철회가 불가능한 &quot;콘텐츠&quot;에 대해 &quot;회사&quot;가 취한 조치에 관련한 내용에 대한 확인</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. 이 약관에 동의하고 위 제3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;5. &quot;콘텐츠&quot;의 이용신청에 관한 확인 또는 &quot;회사&quot;의 확인에 대한 동의</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;6. 결제방법의 선택</LiStyled>
                </OlStyled>
                <LiStyled>② &quot;회사&quot;는 &quot;이용자&quot;의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 실명이 아니거나 타인의 명의를 이용한 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. 허위의 정보를 기재하거나, &quot;회사&quot;가 제시하는 내용을 기재하지 않은 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 미성년자가 청소년보호법에 의해서 이용이 금지되는 &quot;콘텐츠&quot;를 이용하고자 하는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우</LiStyled>
                </OlStyled>
                <LiStyled>③ &quot;회사&quot;의 승낙이 제16조 제1항의 수신확인통지형태로 &quot;이용자&quot;에게 도달한 시점에 계약이 성립한 것으로 봅니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;의 승낙의 의사표시에는 &quot;이용자&quot;의 이용신청에 대한 확인 및 서비스제공 가능여부, 이용신청의 정정·취소 등에 관한 정보 등을 포함합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제16조(미성년자 이용계약에 관한 특칙)</SubTitle>
            <UlStyled>
                <LiStyled>&quot;회사&quot;는 만 20세 미만의 미성년이용자가 유료서비스를 이용하고자 하는 경우에 부모 등 법정 대리인의 동의를 얻거나, 계약체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 계약체결 전에 고지하는 조치를 취합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제17조(수신확인통지·이용신청 변경 및 취소)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 &quot;이용자&quot;의 이용신청이 있는 경우 &quot;이용자&quot;에게 수신확인통지를 합니다.</LiStyled>
                <LiStyled>② 수신확인통지를 받은 &quot;이용자&quot;는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 이용신청 변경 및 취소를 요청할 수 있고, &quot;회사&quot;는 서비스제공 전에 &quot;이용자&quot;의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 청약철회 등에 관한 제27조의 규정에 따릅니다.</LiStyled>
            </UlStyled>
            <SubTitle>제18조(&quot;회사&quot;의 의무)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 법령과 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실하게 하여야 합니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;는 &quot;이용자&quot;가 안전하게 &quot;콘텐츠&quot;를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보보호정책을 공시하고 준수합니다.</LiStyled>
                <LiStyled>③ &quot;회사&quot;는 &quot;이용자&quot;가 콘텐츠이용 및 그 대금내역을 수시로 확인할 수 있도록 조치합니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;는 콘텐츠이용과 관련하여 &quot;이용자&quot;로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체없이 처리합니다. 이용자가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 그 처리과정 및 결과를 전달합니다.</LiStyled>
                <LiStyled>⑤ &quot;회사&quot;는 이 약관에서 정한 의무 위반으로 인하여 &quot;이용자&quot;가 입은 손해를 배상합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제19조(&quot;이용자&quot;의 의무)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;이용자&quot;는 다음 행위를 하여서는 안 됩니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 신청 또는 변경 시 허위내용의 기재</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. 타인의 정보도용</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. &quot;회사&quot;에 게시된 정보의 변경 및 게시물, 상품, 서비스, 콘텐츠, 자료, 정보, 소프트웨어 등의 전부 또는 일부를 케이티의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. &quot;회사&quot;가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;5. &quot;회사&quot;와 기타 제3자의 저작권 등 지적재산권에 대한 침해</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;6. &quot;회사&quot; 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;7. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 &quot;회사&quot;의 사이트에 공개 또는 게시하는 행위</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;8. “회사”의 시스템, 소프트웨어, 콘텐츠 등에 대한 일체의 가공행위나 복제, 분해, 모방 기타 변형행위 등을 통해 서비스의 안정적 운영을 방해하는 모든 행위</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;9. 타인의 명예 훼손이 되는 행위 및 지식재산권, 초상권, 퍼블리시티권 등 기타 권리를 침해하는 행위</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;8. 기타 불법적이거나 부당한 행위</LiStyled>
                </OlStyled>
                <LiStyled>② &quot;이용자&quot;는 관계법령, 이 약관의 규정, 이용안내 및 &quot;콘텐츠&quot;와 관련하여 공지한 주의사항, &quot;회사&quot;가 통지하는 사항 등을 준수하여야 하며, 기타 &quot;회사&quot;의 업무에 방해되는 행위를 하여서는 안 됩니다.</LiStyled>
            </UlStyled>
            <SubTitle>제20조(지급방법)</SubTitle>
            <UlStyled>
                <LiStyled>&quot;콘텐츠&quot;의 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가능한 방법으로 할 수 있습니다.</LiStyled>
                <LiStyled>&quot;회사&quot;는 &quot;이용자&quot;의 지급방법에 대하여 일정 명목의 수수료도 추가하여 징수할 수 있습니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. 선불카드, 직불카드, 신용카드 등의 각종 카드결제</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 온라인무통장입금</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. 전자화폐에 의한 결제</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;5. 마일리지 등 &quot;회사&quot;가 지급한 포인트에 의한 결제</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;6. &quot;회사&quot;와 계약을 맺었거나 &quot;회사&quot;가 인정한 상품권에 의한 결제</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;7. 전화 또는 휴대전화를 이용한 결제</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;8. 기타 전자적 지급방법에 의한 대금지급 등</LiStyled>
                </OlStyled>
            </UlStyled>
            <SubTitle>제21조(콘텐츠서비스의 제공 및 중단)</SubTitle>
            <UlStyled>
                <LiStyled>① 콘텐츠서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 콘텐츠서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 &quot;회사&quot;는 제11조[&quot;회원&quot;에 대한 통지]에 정한 방법으로 &quot;이용자&quot;에게 통지합니다. 다만, &quot;회사&quot;가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</LiStyled>
                <LiStyled>③ &quot;회사&quot;는 상당한 이유 없이 콘텐츠서비스의 제공이 일시적으로 중단됨으로 인하여 &quot;이용자&quot;가 입은 손해에 대하여 배상합니다. 다만, &quot;회사&quot;가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;는 콘텐츠서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.</LiStyled>
                <LiStyled>⑤ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 콘텐츠서비스를 제공할 수 없게 되는 경우에는 &quot;회사&quot;는 제11조[&quot;회원&quot;에 대한 통지]에 정한 방법으로 &quot;이용자&quot;에게 통지하고 당초 &quot;회사&quot;에서 제시한 조건에 따라 &quot;이용자&quot;에게 보상합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제22조(정보의 제공 및 광고의 게재)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 &quot;이용자&quot;가 콘텐츠이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 &quot;회원&quot;에게 제공할 수 있습니다. 다만, &quot;회원&quot;은 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.</LiStyled>
                <LiStyled>② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 &quot;회원&quot;의 사전 동의를 받아서 전송합니다.</LiStyled>
                <LiStyled>③ &quot;회사&quot;는 &quot;콘텐츠&quot;서비스 제공과 관련하여 콘텐츠화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 &quot;회원&quot;은 수신거절을 &quot;회사&quot;에게 할 수 있습니다.</LiStyled>
            </UlStyled>
            <SubTitle>제23조(게시물의 삭제)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 게시판에 정보통신망이용촉진 및 정보보호 등에 관한 법률을 위반한 청소년유해매체물이 게시되어 있는 경우에는 이를 지체 없이 삭제 합니다. 다만, 19세 이상의 &quot;이용자&quot;만 이용할 수 있는 게시판은 예외로 합니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 자는 &quot;회사&quot;에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 &quot;회사&quot;는 지체 없이 필요한 조치를 취하고 이를 즉시 신청인에게 통지합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제24조(저작권 등의 귀속)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회원&quot;이 작성한 저작물에 대한 저작권 기타 지적재산권은 &quot;회원&quot;에 귀속합니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;가 제공하는 서비스 중 제휴계약에 의해 제공되는 저작물에 대한 저작권 기타 지적재산권은 해당 제공업체에 귀속합니다.</LiStyled>
                <LiStyled>③ &quot;이용자&quot;는 &quot;회사&quot;가 제공하는 서비스를 이용함으로써 얻은 정보 중 &quot;회사&quot; 또는 제공업체에 지적재산권이 귀속된 정보를 &quot;회사&quot; 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;는 약정에 따라 &quot;이용자&quot;의 저작물을 사용하는 경우 당해 &quot;이용자&quot;의 허락을 받습니다..</LiStyled>
            </UlStyled>
            <SubTitle>제25조(개인정보보호)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;는 제7조 제2항의 신청서 기재사항 이외에 &quot;이용자&quot;의 콘텐츠이용에 필요한 최소한의 정보를 수집할 수 있습니다. 이를 위해 &quot;회사&quot;가 문의한 사항에 관해 &quot;이용자&quot;는 진실한 내용을 성실하게 고지하여야 합니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;가 &quot;이용자&quot;의 개인 식별이 가능한 &quot;개인정보&quot;를 수집하는 때에는 당해 &quot;이용자&quot;의 동의를 받습니다.</LiStyled>
                <LiStyled>③ &quot;회사&quot;는 &quot;이용자&quot;가 이용신청 등에서 제공한 정보와 제1항에 의하여 수집한 정보를 당해 &quot;이용자&quot;의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없으며, 이를 위반한 경우에 모든 책임은 &quot;회사&quot;가 집니다. 다만, 다음의 경우에는 예외로 합니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. &quot;콘텐츠&quot; 제공에 따른 요금정산을 위하여 필요한 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 도용방지를 위하여 본인확인에 필요한 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. 약관의 규정 또는 법령에 의하여 필요한 불가피한 사유가 있는 경우</LiStyled>
                </OlStyled>
                <LiStyled>④ &quot;회사&quot;가 제2항과 제3항에 의해 &quot;이용자&quot;의 동의를 받아야 하는 경우에는 &quot;개인정보&quot;관리책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공관련사항(제공받는 자, 제공목적 및 제공할 정보의 내용)등에 관하여 정보통신망이용촉진 및 정보보호 등에 관한 법률 제22조 제2항이 규정한 사항을 명시하고 고지하여야 합니다.</LiStyled>
                <LiStyled>⑤ &quot;이용자&quot;는 언제든지 제3항의 동의를 임의로 철회할 수 있습니다.</LiStyled>
                <LiStyled>⑥ &quot;이용자&quot;는 언제든지 &quot;회사&quot;가 가지고 있는 자신의 &quot;개인정보&quot;에 대해 열람 및 오류의 정정을 요구할 수 있으며, &quot;회사&quot;는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. &quot;이용자&quot;가 오류의 정정을 요구한 경우에는 &quot;회사&quot;는 그 오류를 정정할 때까지 당해 &quot;개인정보&quot;를 이용하지 않습니다.</LiStyled>
                <LiStyled>⑦ &quot;회사&quot;는 개인정보보호를 위하여 관리자를 한정하여 그 수를 최소화하며, 신용카드, 은행계좌 등을 포함한 &quot;이용자&quot;의 &quot;개인정보&quot;의 분실, 도난, 유출, 변조 등으로 인한 &quot;이용자&quot;의 손해에 대하여 책임을 집니다.</LiStyled>
                <LiStyled>⑧ &quot;회사&quot; 또는 그로부터 &quot;개인정보&quot;를 제공받은 자는 &quot;이용자&quot;가 동의한 범위 내에서 &quot;개인정보&quot;를 사용할 수 있으며, 목적이 달성된 경우에는 당해 &quot;개인정보&quot;를 지체 없이 파기합니다.</LiStyled>
                <LiStyled>⑨ &quot;회사&quot;는 정보통신망이용촉진 및 정보보호에 관한 법률 등 관계 법령이 정하는 바에 따라 &quot;이용자&quot;의 &quot;개인정보&quot;를 보호하기 위해 노력합니다. &quot;개인정보&quot;의 보호 및 사용에 대해서는 관련법령 및 &quot;회사&quot;의 개인정보보호정책이 적용됩니다.</LiStyled>
            </UlStyled>
        </StyledContainer>
        
    )
}

const StyledContainer = styled.div`

`

const Title = styled.h4`
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 40px;
    width: auto;
`

const SubTitle = styled.h6`
    font-weight: 700;
    line-height: 20px;
    margin: 16px 0px;
`

const UlStyled = styled.ul`
`

const LiStyled = styled.li`
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${colors.gray600}
`

const OlStyled = styled.ol`
    margin: 16px 0px;
`