import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import Text from "@/components/atoms/text";
import { ReactComponent as ErrorMark } from "@/assets/icons/alert_error.svg";
import { ReactComponent as InfoMark } from "@/assets/icons/alert_info.svg";
import { ReactComponent as SuccessMark } from "@/assets/icons/alert_success.svg";
import { ReactComponent as WarningMark} from "@/assets/icons/alert_warning.svg";
import { ReactComponent as CloseMark} from "@/assets/icons/clear.svg";
import { colors } from "@/assets/styles/theme";
import { useEffect } from "react";
import { socketActions } from "@/pages/common/socketSlice";

export default function Toast() {
    const dispatch = useAppDispatch();
    let [message] = useSelectorTyped((state:RootState) => [
        state.socket.message
    ]);

    useEffect(()=> {
    }, [message])

    function close(id: string) {
        dispatch(socketActions.removeMessage(id))
    }

    return (
        <>
        { message.length ?
            <ToastContainer>
            {
                message.map((m, i) => { 
                let background:string;
                switch (m.type) {
                    case "error":
                        background = "#FFDFE2";
                        break;
                    case "information":
                        background = "#E1EFFF";
                        break;
                    case "success":
                        background = "#E2FFE1";
                        break;
                    case "warning":
                        background = "#FFF9EA";
                        break;
                    default:
                        background = "#E2FFE1";
                        break;
                }
                return (
                    <ToastWrap key={m.id} borderColor={colors[m.type]} backgroundColor={background}>
                        <ToastLeft>
                            <ToastIconWrap>
                                {m.type === "information" ? <InfoMark width="48px" height="48px" />
                                : m.type === "success" ? <SuccessMark width="48px" height="48px" fill="#00D422"/>
                                : m.type === "error" ? <ErrorMark width="48px" height="48px" />
                                : <WarningMark width="48px" height="48px" />}
                            </ToastIconWrap>
                            <ToastTextWrap>
                                { m.title ?
                                    <Text text={m.title} customStyle={{ color: colors[m.type], fontSize: "16px", fontWeight: "700", lineHeight: "20px", margin: "0 0 8px" }} />
                                    :
                                    <></>
                                }
                                <Content content={m.content || ""} />
                            </ToastTextWrap>
                        </ToastLeft>
                        <ToastCloseBtnWrap onClick={() => m.id ? close(m.id) : {}}>
                            <CloseMark width="24px" height="24px" fill={colors.gray600} />
                        </ToastCloseBtnWrap>
                    </ToastWrap>
                )})
            }
            </ToastContainer>
            :
            <></>    
        }
        </>
    )
}

function Content({content}:{ content: string}) {
    let string = content.split("\n");
    return (
        <>
            {string.map((m, i) => (
                <Text key={i} text={m} customStyle={{ fontSize: "14px", fontWeight: "400", lineHeight: "24.5px" }} />
            ))}
        </>
    )
} 

const ToastContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 44px;
    z-index: 9999;
`

const ToastWrap = styled.div<{ borderColor: string, backgroundColor: string }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${({ borderColor }) => borderColor};
    border-radius: 4px;
    width: 500px;
    padding: 8px 20px;
    min-height: 65px;
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    background-color: ${({ backgroundColor }) => backgroundColor};
    margin-bottom: 16px;
`

const ToastLeft = styled.div`
    display: flex;
    align-items: center;
`

const ToastIconWrap = styled.div`
    margin-right: 24px;
`

const ToastTextWrap = styled.div`

`

const ToastCloseBtnWrap = styled.div`

`