import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from '@/assets/styles/theme';
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import usePagination from "../../common/pagination/usePagination";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import SimpleDataTable from "../../common/table/SimpleDataTable";
import { useState, useEffect } from "react";
import Button from "@/components/atoms/button";
import { Content } from "@/pages/mypage/author/types";

export default function AuthorInfo() {
    const [authorList] = useSelectorTyped((state: RootState) => [state.authorMyPage.AuthorInfo.authorList]);
    const [contentList] = useSelectorTyped((state: RootState) => [state.authorMyPage.AuthorInfo.contentList]);
    const [filterData, setFilterData] = useState<Content[]>(contentList);
    const { setDataCount, setPage, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "authorInfo" });
    const [isClicked, setClicked] = useState('all');

    useEffect(() => {
        setFilterData(contentList)
    },[contentList]);

    useEffect(() => {
        if(filterData.length) {
            setDataCount(filterData.length);
        }
    },[contentList, itemsPerPage, setDataCount, filterData]);
    
    const authorText1 = '총 작품수는';
    const authorText2 = '입니다.';

    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    }

    const textstyled = {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "24.5px",
        color: colors.black,
        margin: "0px 0px 24px 0px"
    }

    const navbarTextStyled = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17.5px",
        margin: "0px 16px 0px 0px",
    }

    const headerTextStyled = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24.5px",
        width: '300px',
    }

    const columnsTextStyled = {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "24.5px",
    }

    const buttonStyled = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "14px",
        margin: "0px 8px 0px 0px",
        color: colors.blue500,
    }

    async function clickButton(label: string, num: number) {
        setClicked(label);
        setPage(1);
        if(num === 0) {
            setFilterData(contentList);
        } else {
            const newFilterData = contentList.filter(data => data.author_name === label);
            setFilterData(newFilterData);
        }
    }

    const headers = [
        {text: '작품명', value: 'seriesName', width: '300px'},
        {text: '저자명', value: 'authorName', width: '300px'},
        {text: '출판사', value: 'publisherName', width: '300px'},
    ]

    const customHeaders = {
        seriesName: () => <Text text="작품명" customStyle={headerTextStyled} />,
        authorName: () => <Text text="저자명" customStyle={headerTextStyled} />,
        publisherName: () => <Text text="출판사" customStyle={headerTextStyled} />,
    }

    const columns = {
        seriesName: (item: any, idx: number) => (
            <Text key={(page - 1) * itemsPerPage + idx} text={item["series_name"]} customStyle={columnsTextStyled} />
        ),
        authorName: (item: any, idx: number) => (
            <Text key={(page - 1) * itemsPerPage + idx} text={item["author_name"]} customStyle={columnsTextStyled} />
        ),
        publisherName: (item: any, idx: number) => (
            <Text key={(page - 1) * itemsPerPage + idx} text={item["publisher_name"]} customStyle={columnsTextStyled} />
        ),
    }

    return(
        <MypageSectionLayout>
            <StyledTitleContainer>
                <Text text="작가정보" customStyle={titleStyled} />
            </StyledTitleContainer>
            {(authorList && authorList.length !== 0) && <NavBar>
                <Text text="저자명" customStyle={navbarTextStyled} />
                <Button 
                    type={isClicked === 'all' ? "main-outline" : "gray-outline"} 
                    label="전체" 
                    customStyle={{ ...buttonStyled, width: '58px' }} 
                    onClickHandler={() => clickButton("all", 0)}
                />
                {(authorList && authorList.length !== 0) && authorList.map((d, i) => (
                    <Button 
                        key={d.author_id} 
                        type={isClicked === d.author_name ? "main-outline" : "gray-outline"} 
                        label={d.author_name} 
                        customStyle={buttonStyled} 
                        onClickHandler={() => clickButton(d.author_name, i + 1)} 
                    />
                ))}
            </NavBar>
            }   
            <TextBox>
                <Text text={authorText1} customStyle={textstyled} />
                <Text text={`${contentList.length}건`} customStyle={{ ...textstyled, color: colors.blue500, margin:"0px 0px 24px 3px" }} />
                <Text text={authorText2} customStyle={textstyled} />
            </TextBox>
            {
                <SimpleDataTable 
                    from="authInfo"
                    itemKey="authInfo"
                    headers={headers}
                    customHeaders={customHeaders}
                    needHeader={true}
                    columns={columns}
                    items={contentList}
                />
            }
            <PaginationStyled>
                <Pagination pageCount={pageCount} totalVisible={totalVisible} from="authorInfo" />
            </PaginationStyled>
        </MypageSectionLayout>
    )
}


const MypageSectionLayout = styled.div` 

`

const StyledTitleContainer = styled.div`
    display: flex;
    margin: 0px 0px 24px 0px;
`

const TextBox = styled.div`
    display: flex;
`

const NavBar = styled.div`
    margin: 0px 0px 24px 0px;
    width: 900px;
    height: 48px;
    display: flex;
    align-items: center;
`

const PaginationStyled = styled.div`
    width: 900px;
    display: flex;
    justify-content: center;
`

