import Slider from "@/components/molecules/common/banner-slider/slider"
import { dataArr } from '@/components/molecules/introduce/main/MainData';

export default function MainContainer() {
    // const backItems = ['#4DCFE1', '#7DD1FF', '#6A26DA'];
    const backItems = ['#7DD1FF', '#6A26DA', '#4DCFE1'];

    return (
            <Slider
                isFull={true}
                rendingData={dataArr}
                backItems={backItems}
            />
    )
}
