import styled from "styled-components";
import DownloadIcon from "@/assets/icons/download.svg";

function Button({ type, label, disabled, onClickHandler, customStyle, id }: ButtonProps) {
    switch (type) {
        case "main-outline":
            return (
                <MainOutlineButton
                    id={id}
                    disabled={disabled}
                    onClick={onClickHandler}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    padding={customStyle?.padding}
                    margin={customStyle?.margin}
                >
                    {label}
                </MainOutlineButton>
            );
        case "no-outline" : 
                return (
                    <NoOutlineButton
                        id={id}
                        disabled={disabled}
                        onClick={onClickHandler}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderRadius={customStyle?.borderRadius}
                        color={customStyle?.color}
                        padding={customStyle?.padding}
                        margin={customStyle?.margin}
                    >
                        {label}
                    </NoOutlineButton>
                );
        case "black-outline": 
                return (
                    <BlackOutlineButton
                        id={id}
                        disabled={disabled}
                        onClick={onClickHandler}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderRadius={customStyle?.borderRadius}
                        color={customStyle?.color}
                        padding={customStyle?.padding}
                        margin={customStyle?.margin}
                    >
                        {label}
                    </BlackOutlineButton>
                )
        case "red-outline":
            return (
                <RedOutlineButton
                    id={id}
                    disabled={disabled}
                    onClick={onClickHandler}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    padding={customStyle?.padding}
                    margin={customStyle?.margin}
                >
                    {label}
                </RedOutlineButton>
            );
        case "gray-outline":
            return (
                <GrayOutlineButton
                    id={id}
                    disabled={disabled}
                    onClick={onClickHandler}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    padding={customStyle?.padding}
                    margin={customStyle?.margin}
                >
                    {label}
                </GrayOutlineButton>
            );
        case "red-outline-icon":
            return (
                <RedOutlineIconButton
                    id={id}
                    disabled={disabled}
                    onClick={onClickHandler}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    padding={customStyle?.padding}
                    margin={customStyle?.margin}
                >
                    {label}
                </RedOutlineIconButton>
            );
        case "black-fill": 
            return (
                <BlackFillButton
                    id={id}
                    disabled={disabled}
                    onClick={onClickHandler}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    padding={customStyle?.padding}
                    margin={customStyle?.margin}
                >
                    {label}
                </BlackFillButton>
            );
        case "main-fill":
            return (
                <MainFillButton
                    id={id}
                    disabled={disabled}
                    onClick={onClickHandler}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    padding={customStyle?.padding}
                    margin={customStyle?.margin}
                >
                    {label}
                </MainFillButton>
            );
        case "disabled":
            return (
                <DisabledButton
                    id={id}
                    disabled={disabled}
                    onClick={onClickHandler}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    padding={customStyle?.padding}
                    margin={customStyle?.margin}
                >
                    {label}
                </DisabledButton>
            );
        default:
            // "main"인 경우
            return (
                <MainButton
                    id={id}
                    disabled={disabled}
                    onClick={onClickHandler}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    padding={customStyle?.padding}
                    margin={customStyle?.margin}
                >
                    {label}
                </MainButton>
            );
    }
}

const BaseButton = styled.button<StyleProps>`
    width: ${({ width }) => (width ? width : "100px")};
    height: ${({ height }) => (height ? height : "32px")};
    padding: ${({ padding }) => (padding ? padding : "0")};
    margin: ${({ margin }) => (margin ? margin : "0")};
    background-color: ${({ theme }) => theme.colors.white};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
    cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
    color: ${({ color, theme }) => (color ? color : theme.colors.black)};
    user-select: none;
`;

const MainButton = styled(BaseButton)`
    background-color: ${({ theme }) => theme.colors.blue300};
`;

const MainOutlineButton = styled(BaseButton)`
    border: 1px solid ${({ theme }) => theme.colors.blue300};
    color: ${({ theme }) => theme.colors.blue500};
`;

const NoOutlineButton = styled(BaseButton)`
    border: none;
    color: ${({ theme, disabled }) => disabled ? theme.colors.gray600 : theme.colors.black};
`

const BlackOutlineButton = styled(BaseButton)`
    border: 1px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
`

const RedOutlineButton = styled(BaseButton)`
    border: 1px solid ${({ theme }) => theme.colors.red400};
    color: ${({ theme }) => theme.colors.red400};
`;

const GrayOutlineButton = styled(BaseButton)`
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    color: ${({ theme }) => theme.colors.gray600};
`;

const DisabledButton = styled(BaseButton)`
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    background-color: ${({ theme }) => theme.colors.gray50};
    color: ${({ theme }) => theme.colors.gray600};
    cursor: default;
`;

const RedOutlineIconButton = styled(RedOutlineButton)`
    background-image: url(${DownloadIcon});
    background-repeat: no-repeat;
    background-position: right center;
    text-align: left;
    padding: 0px 6px;
`

const BlackFillButton = styled(BaseButton)`
    border: 1px solid ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
`

const MainFillButton = styled(BaseButton)`
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    background-color: ${({ theme }) => theme.colors.blue50};
`

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    borderRadius?: string;
    color?: string;
    disabled? : boolean;
}

interface ButtonProps {
    type?: "main" | "main-outline" | "no-outline" | "black-outline" |"red-outline" | "gray-outline" | "disabled" | "red-outline-icon" | "black-fill" | "main-fill";
    label: string;
    disabled?: boolean;
    onClickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    customStyle?: StyleProps;
    icon?: React.ReactNode;
    id?: string;
}

export default Button;