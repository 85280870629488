import styled from "styled-components";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import TableOptions from "@/components/molecules/sales-inquiry/author/TableOptions";
import SalesSummary from "@/components/molecules/sales-inquiry/author/SalesSummary";
import useExcelDownload from "@/modules/hooks/useExcelDownload";
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { addComma } from "@/modules/utils/filter";

export default function MgTableTop() {
    const { items, total_amt, total_cnt } = useSelectorTyped((state: RootState) => state.inquiryAuthorMg);
    const { setItemsPerPage } = usePagination({ from: "mg", tableData:items });
    const downloadExcel = useExcelDownload();
    const { alert } = useAlert();

    const changeItemPerPage = (itemPerPage: number) => setItemsPerPage(itemPerPage);
    const downloadMgExcel = () => {
        if (items.length > 0) {
            let rows: any[] = [];
            items.forEach((item) => {
                let row = {
                    계약시작일: item.date,
                    선인세구분: item.mg_type,
                    판매플랫폼: item.platform_name,
                    출판사: item.publisher_name,
                    계약형태: item.contract_type === "MG01" ? "작가계약" : "작품계약",
                    계약대상: item.target,
                    계약금액: item.contractPrice,
                    "정산비율(%)": item.mg_ratio,
                    정산금액: addComma(Math.round(item.st_price)),
                    소진금액: addComma(item.deductionPrice),
                    "잔여금액(원)": addComma(item.remainPrice),
                };
                rows.push(row);
            });
            downloadExcel({ json: rows, excelFileName: "[판매현황조회] 선인세 정산", sheetNames: "선인세소진현황" });
        } else {
            alert("info", "조회 완료", "조회된 데이터가 없습니다.");
        }
    };

    return (
        <StyledTableTop>
            <SalesSummary total={total_cnt} amountName={"소진합계"} amount={total_amt} />
            <TableOptions onChangeItemPerPage={changeItemPerPage} onExcelDownload={downloadMgExcel} />
        </StyledTableTop>
    );
}

const StyledTableTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
