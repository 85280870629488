import CalendarDescWrapper from "@/components/molecules/dashboard/publisher/CalendarDescWrapper";
import CalendarWrapper from "@/components/molecules/dashboard/publisher/CalendarWrapper";
import styled from "styled-components";

export default function CalendarContainer () {
    return (
        <StyledCalendarContainer>
            <CalendarWrapper />
            <CalendarDescWrapper />
        </StyledCalendarContainer>
    )
}

const StyledCalendarContainer = styled.div`
    width: 376px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};;
`
