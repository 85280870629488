/* eslint-disable @typescript-eslint/ban-types */
import { useState, useEffect, useCallback } from "react";
import { throttle } from 'lodash';
import { useAppDispatch } from "@/modules/store";
import {
    deleteSearchHistory,
    getSearchHistory,
    resetStore,
    updateSearchHistory,
} from "@/pages/common/search/searchHistorySlice";
import useAlert from "@/modules/hooks/useAlert";
import type { AsyncThunkAction } from "@reduxjs/toolkit";
import type { AtcmpParams } from "@/types/autoComplete";

const useSearchHistory = ({ template }: UseSearchHistoryProps) => {
    const [requestPromise, setRequestPromise] = useState<ReturnType<AsyncThunkAction<any, any, {}>>>();
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    useEffect(() => {
        getHistory();
        return () => {
            dispatch(resetStore());
        };
    }, []);

    // 자동완성 데이터 조회
    const _getAtcmp = throttle(async (func:AsyncThunkAction<any, AtcmpParams, {}>) => {
        try {
            if (requestPromise) requestPromise?.abort();
            const promise = dispatch(func);
            setRequestPromise(promise);
            await promise.unwrap();
        } catch (err: any) {
            if (err.name === "AbortError") return;
            if (err.message) {
                return await alert("error", "오류 발생", err.message);
            }
        }
    }, 200);
    const getAtcmp = useCallback(_getAtcmp, []);

    // 최근검색어 조회
    const getHistory = async () => {
        try {
            const apiParams = { template };
            await dispatch(getSearchHistory(apiParams)).unwrap();
        } catch (err: any) {
            if (err.message) {
                return await alert("error", "오류 발생", err.message);
            }
        }
    };

    // 최근검색어 저장 & 업데이트
    const updateHistory = async (keyword: string) => {
        try {
            if (!keyword) return;

            const apiParams = {
                template,
                keyword,
            };
            await dispatch(updateSearchHistory(apiParams)).unwrap();
        } catch (err: any) {
            if (err.message) {
                return await alert("error", "오류 발생", err.message);
            }
        }
    };

    // 최근검색어 삭제
    const deleteHistory = async (keyword?: string) => {
        try {
            const apiParams = {
                template,
                keyword,
            };
            await dispatch(deleteSearchHistory(apiParams)).unwrap();
            await getHistory();
        } catch (err: any) {
            if (err.message) {
                return await alert("error", "오류 발생", err.message);
            }
        }
    };

    return {
        getAtcmp,
        getHistory,
        updateHistory,
        deleteHistory,
    };
};

interface UseSearchHistoryProps {
    template:
        | "inquiry-daily"
        | "inquiry-monthly"
        | "inquiry-mg"
        | "inquiry-etc"
        | "author-inquiry-daily"
        | "author-inquiry-monthly"
        | "author-inquiry-mg"
        | "author-inquiry-etc"
        | "basedata-author"
        | "basedata-series";
}

export default useSearchHistory;
