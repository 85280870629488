import styled from "styled-components";

export default function TimerContainer({ time }: { time: number; }) {
    return (
        <StyledContainer>
            남은 다운로드 가능 시간 : {time}초
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    justify-content: right;
    height: 28px;
    width: 100%;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.error};
    font-weight: 500;
`