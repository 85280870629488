import styled from "styled-components";
import { ReactComponent as NoSearchResultMark } from "@/assets/graphic/search_no_result.svg";
import theme from "@/assets/styles/theme";
import Text from "@/components/atoms/text";

export default function NoSearchResult({ customStyle }:NoDataProps) {
    return (
        <NoSearchResultWrap width={customStyle?.width} height={customStyle?.height} >
            <NoSearchResultMark />
            <Text text="검색결과가 없습니다." customStyle={{ fontSize: "16px", fontWeight: "700" }}/>
            <Text text="입력하신 검색어와 일치하는 결과가 없습니다." customStyle={{ fontSize: "12px", fontWeight: "400", color: theme.colors.gray500, margin: "16px 0 0" }}/>
            <Text text="다시 한번 검색어를 확인해주세요." customStyle={{ fontSize: "12px", fontWeight: "400", color: theme.colors.gray500, margin: "8px 0 0" }}/>
        </NoSearchResultWrap>
    )

}

const NoSearchResultWrap = styled.div<StyleProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => height ? height : "358px"};
    width: ${({ width }) => width ? width : "100%"};
    background-color: ${({ theme }) => theme.colors.gray50 };
`

interface StyleProps {
    width?: string;
    height?: string;
}

interface NoDataProps {
    customStyle?: StyleProps;
}