import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@/api";
import { logout } from "@/pages/common/userSlice";

const name = "auth";

interface AuthState {
    loading: boolean;
}

const initialState: AuthState = {
    loading: false,
};

// 중복 로그인 체크
export const loginCheck = createAsyncThunk(`${name}/loginCheck`, async (socketId: string, { rejectWithValue }) => {
    try {
        let apiParams = {
            sid: socketId
        }
        const { status } = (await api.post('/api/user/logincheck', apiParams)).data;

        // 강제 로그아웃
        if (status === "fail") {
            throw "logout";         
        }
    } catch (err: any) {
        throw rejectWithValue(err);
    }
})

// 로그인 로그
export const loginLog = createAsyncThunk(`${name}/loginLog`, async (apiParams: {target_cd:string, target_id: string, log_activity: string}, { rejectWithValue }) => {
    try {
        // let apiParams = {
        //     target_cd: "LI",
        //     target_id: "0",
        //     log_activity: "",
        // }
        return (await api.post('/api/user/userLog/insert', apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 중복 가입 확인 및 아이디 찾기
export const findEmail = createAsyncThunk(`${name}/findEmail`, async (apiParams: any, { rejectWithValue }) => {
    try {
        return (await api.post('/api/user/findEmail/select', apiParams)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

const authSlice = createSlice({
    name: name,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(findEmail.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(findEmail.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
            } else {
                // state.error = action.payload.message;
            }
            state.loading = false;
        });
        builder.addCase(findEmail.rejected, (state, action) => {
            console.error("오류 발생 :", action.error);
            state.loading = false;
        });
        builder.addCase(loginLog.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(loginLog.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
            } else {
                // state.error = action.payload.message;
            }
            state.loading = false;
        });
        builder.addCase(loginLog.rejected, (state, action) => {
            console.error("오류 발생 :", action.error);
            state.loading = false;
        });
        builder.addCase(loginCheck.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(loginCheck.fulfilled, (state, action) => {
            // if (action.payload.status === "success") {
            // } else {
            //     state.error = action.payload.message;
            // }
            state.loading = false;
        });
        builder.addCase(loginCheck.rejected, (state, action) => {
            console.error("오류 발생 :", action.error);
            state.loading = false;
        });
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
