import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setParamsId } from "@/pages/settlement/saels-inquiry/author/daily/dailySlice";

function useDailyApi() {
    const {
        startDate,
        endDate,
        bookName,
        selectedPlatform,
        selectedPublisher,
        selectedSeries,
        selectedAuthor,
        startDateError,
        endDateError,
        initialDataLoaded,
        fetchLoading,
    } = useSelectorTyped((state: RootState) => state.inquiryAuthorDaily);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!initialDataLoaded && fetchLoading) return;
        if (startDateError !== "" || endDateError !== "") return;

        dispatch(setParamsId());
        return () => { }

    }, [
        startDate,
        endDate,
        selectedPlatform,
        selectedPublisher,
        selectedSeries,
        selectedAuthor,
        bookName,      
        dispatch
    ]);
}

export default useDailyApi;
