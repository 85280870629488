import styled from 'styled-components'

export default function ReviewProfileContainer({profileObj}: {profileObj:profileProps}) {
    const profileImage = <img src={profileObj.image} className={profileObj.job === "출판사 뷰컴즈" ? 'image image-border' : 'image'}/>;
    return (
        <StyledContainer>
            {profileImage}
            <TextWrapper>
                <JobTextWrapper>
                    { profileObj.job }
                </JobTextWrapper>
                <NameTextWrapper>
                    { profileObj.name }
                </NameTextWrapper>
            </TextWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width:100px;
    height:148px;
    color: ${({ theme }) => theme.colors.black};
    .image {
        width: 100px;
        height: 100px;
    }
    .image-border {
        border: 1px solid ${({ theme }) => theme.colors.gray300};
        border-radius: 99px;
    }
`

const TextWrapper = styled.div`
    line-height: 20.03px;
`

const JobTextWrapper = styled.div`
    font-weight: 700;
    font-size: 16px;
    margin-top: 8px;
    text-align: center;
`

const NameTextWrapper = styled.div`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
`

interface profileProps {
    image: string,
    job: string,
    name: string
}