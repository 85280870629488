import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import { RootState, useAppSelector } from "@/modules/store";
import LoadingPortal from "@/components/molecules/common/loading/Portal";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";

export default function FullLoading() {
    const [loading] = useAppSelector((state: RootState) => [state.loading.fullLoading]);
    const [text, setText] = useState("잠시만 기다려주세요.");
    const time = useRef(0);
    useEffect(() => {
        if (loading) {
            const timer = setInterval(() => {
                if (time.current > 9) {
                    setText(text2[time.current%3])
                } else {
                    setText(text1[time.current%3])
                }
                time.current += 1;
            }, 1000);
            return() => {
                clearInterval(timer);
                time.current = 0;
            }
        }
    }, [loading])

    const text1 = ["잠시만 기다려주세요..", "잠시만 기다려주세요...", "잠시만 기다려주세요."];
    const text2 = ["데이터를 가져오는데 시간이 더 걸릴 수 있습니다...", "데이터를 가져오는데 시간이 더 걸릴 수 있습니다.", "데이터를 가져오는데 시간이 더 걸릴 수 있습니다.."];

    return (
        <>
            {loading ?
            <LoadingPortal>
                <LoadingContainer show={loading||"table"||"portfolio"||"total"} type={typeof loading === "string" ? loading : ""}>
                    <LoadingWrap type={typeof loading === "string" ? loading : ""}>
                        <div dangerouslySetInnerHTML={ {__html: codes} } />
                        <Text text={text} customStyle={{ fontSize: "16px", fontWeight: "400", lineHeight: "28px", color: colors.blue500, margin: "8px 0 0" }}/>
                    </LoadingWrap>
                </LoadingContainer>
            </LoadingPortal>
            :
            <></>
            }
        </>
    )
}

const LoadingContainer = styled.div<{ show: boolean|"table"|"portfolio"|"total", type?: string }>`
    display: ${({ show }) => show ? "block" : "none"};
    position: fixed;
    top: ${({ type }) => type === "table" ? "186px" : type === "portfolio" || type === "total" ? "72px" : "0"};
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    backdrop-filter: grayscale(1);
    -webkit-backdrop-filter: grayscale(1);
`

const LoadingWrap = styled.div<{ type?: string }>`
    position: fixed;
    left: 50%;
    top: ${({ type }) => type === "table" ? "calc(50% - 186px)" : type === "portfolio" || type === "total" ? "calc(50% - 72px)" : "50%"};
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const codes = `
<?xml version="1.0" encoding="utf-8"?>
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd">

<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="ko">
    <head>
        <style>
            #glingChars path {
                fill: #BBB;
            }
            .gling-path {
                stroke: url(#glingStrokeGradient);
            }

            #glingPath1, #glingPath2, #glingPath3, #glingPath4, #glingPath5 {
                stroke-dasharray: 20 210;
                stroke-dashoffset: 230;
                stroke-width: 13;
                stroke-linecap: round;
            }

            #glingPath1 {
                animation: gling-loding-1 8s ease-in-out forwards infinite;
            }

            #glingPath2 {
                animation: gling-loding-2 8s ease-in-out forwards infinite;
            }

            #glingPath3 {
                animation: gling-loding-3 8s ease-in-out forwards infinite;
            }

            #glingPath4 {
                animation: gling-loding-4 8s ease-in-out forwards infinite;
            }

            #glingPath5 {
                animation: gling-loding-5 8s ease-in-out forwards infinite;
            }

            @keyframes gling-loding-1 {
                0% {
                    stroke-dashoffset: 250;
                }
                20% {
                    stroke-dashoffset: 20;
                }
                75%{
                    stroke-dashoffset: 20;
                }
                100% {
                    stroke-dashoffset: 250;
                }
            }

            @keyframes gling-loding-2 {
                0% {
                    stroke-dashoffset: 250;
                }
                12% {
                    stroke-dashoffset: 250;
                }
                30% {
                    stroke-dashoffset: 20;
                }
                69% {
                    stroke-dashoffset: 20;
                }
                83% {
                    stroke-dashoffset: 250;
                }
                100% {
                    stroke-dashoffset: 250;
                }
            }

            @keyframes gling-loding-3 {
                0% {
                    stroke-dashoffset: 250;
                }
                18% {
                    stroke-dashoffset: 250;
                }
                35% {
                    stroke-dashoffset: 20;
                }
                60% {
                    stroke-dashoffset: 20;
                }
                80% {
                    stroke-dashoffset: 250;
                }
                100% {
                    stroke-dashoffset: 250;
                }
            }

            @keyframes gling-loding-4 {
                0% {
                    stroke-dashoffset: 250;
                }
                22% {
                    stroke-dashoffset: 250;
                }
                42% {
                    stroke-dashoffset: 20;
                }
                55% {
                    stroke-dashoffset: 20;
                }
                75% {
                    stroke-dashoffset: 250;
                }
                100% {
                    stroke-dashoffset: 250;
                }
            }
            
            @keyframes gling-loding-5 {
                0% {
                    stroke-dashoffset: 250;
                }
                32% {
                    stroke-dashoffset: 250;
                }
                50% {
                    stroke-dashoffset: 55;
                }
                65% {
                    stroke-dashoffset: 250;
                }
                100% {
                    stroke-dashoffset: 250;
                }
            }
        </style>
    </head>

    <body>
        <div class="loading-container">
            <svg id="glingLoading" width="183" height="83">
                <defs>
                    <linearGradient id="glingStrokeGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stop-color="#4DCFE1" />
                        <stop offset="100%" stop-color="#00ABC1" />
                    </linearGradient>
                    <clipPath id="glingClipPath1">
                        <path
                            d="m19.332 18.388q4.1628 0 7.2672 1.6933 3.175 1.6933 4.8683 4.445v-5.5739h12.065v39.299q0 5.4328-2.1872 9.8072-2.1167 4.445-6.5617 7.0556-4.3744 2.6106-10.936 2.6106-8.7489 0-14.182-4.1628-5.4328-4.0922-6.2089-11.148h11.924q0.56444 2.2578 2.6811 3.5278 2.1167 1.3406 5.2211 1.3406 3.7394 0 5.9267-2.1872 2.2578-2.1167 2.2578-6.8439v-5.5739q-1.7639 2.7517-4.8683 4.5156-3.1044 1.6933-7.2672 1.6933-4.8683 0-8.8194-2.4694-3.9511-2.54-6.2794-7.1261-2.2578-4.6567-2.2578-10.724t2.2578-10.654q2.3283-4.5861 6.2794-7.0556 3.9511-2.4694 8.8194-2.4694zm12.136 20.249q0-4.5156-2.54-7.1261-2.4694-2.6106-6.0678-2.6106-3.5983 0-6.1383 2.6106-2.4694 2.54-2.4694 7.0556t2.4694 7.1967q2.54 2.6106 6.1383 2.6106 3.5983 0 6.0678-2.6106 2.54-2.6106 2.54-7.1261z"
                        />
                    </clipPath>
                    <clipPath id="glingClipPath2">
                        <path
                            d="m64.347 6.1113v52.211h-12.065v-52.211z"
                        />
                    </clipPath>
                    <clipPath id="glingClipPath3">
                        <path
                            d="m79.163 14.86q-3.175 0-5.2211-1.8344-1.9756-1.905-1.9756-4.6567 0-2.8222 1.9756-4.6567 2.0461-1.905 5.2211-1.905 3.1044 0 5.08 1.905 2.0461 1.8344 2.0461 4.6567 0 2.7517-2.0461 4.6567-1.9756 1.8344-5.08 1.8344zm5.9972 4.0922v39.37h-12.065v-39.37z"
                        />
                    </clipPath>
                    <clipPath id="glingClipPath4">
                        <path
                            d="m117.9 18.529q6.9144 0 11.007 4.5156 4.1628 4.445 4.1628 12.277v23.001h-11.994v-21.378q0-3.9511-2.0461-6.1383-2.0461-2.1872-5.5033-2.1872-3.4572 0-5.5033 2.1872-2.0461 2.1872-2.0461 6.1383v21.378h-12.065v-39.37h12.065v5.2211q1.8344-2.6106 4.9389-4.0922 3.1044-1.5522 6.985-1.5522z"
                        />
                    </clipPath>
                    <clipPath id="glingClipPath5">
                        <path
                            d="m156.42 18.388q4.1628 0 7.2672 1.6933 3.175 1.6933 4.8683 4.445v-5.5739h12.065v39.299q0 5.4328-2.1872 9.8072-2.1167 4.445-6.5617 7.0556-4.3744 2.6106-10.936 2.6106-8.7489 0-14.182-4.1628-5.4328-4.0922-6.2089-11.148h11.924q0.56444 2.2578 2.6811 3.5278 2.1167 1.3406 5.2211 1.3406 3.7394 0 5.9267-2.1872 2.2578-2.1167 2.2578-6.8439v-5.5739q-1.7639 2.7517-4.8683 4.5156-3.1044 1.6933-7.2672 1.6933-4.8683 0-8.8194-2.4694-3.9511-2.54-6.2794-7.1261-2.2578-4.6567-2.2578-10.724t2.2578-10.654q2.3283-4.5861 6.2794-7.0556 3.9511-2.4694 8.8194-2.4694zm12.136 20.249q0-4.5156-2.54-7.1261-2.4694-2.6106-6.0678-2.6106-3.5983 0-6.1383 2.6106-2.4694 2.54-2.4694 7.0556t2.4694 7.1967q2.54 2.6106 6.1383 2.6106 3.5983 0 6.0678-2.6106 2.54-2.6106 2.54-7.1261z"
                        />
                    </clipPath>
                </defs>
                
                <g>
                    <g id="glingChars">
                        <path
                            d="m19.332 18.388q4.1628 0 7.2672 1.6933 3.175 1.6933 4.8683 4.445v-5.5739h12.065v39.299q0 5.4328-2.1872 9.8072-2.1167 4.445-6.5617 7.0556-4.3744 2.6106-10.936 2.6106-8.7489 0-14.182-4.1628-5.4328-4.0922-6.2089-11.148h11.924q0.56444 2.2578 2.6811 3.5278 2.1167 1.3406 5.2211 1.3406 3.7394 0 5.9267-2.1872 2.2578-2.1167 2.2578-6.8439v-5.5739q-1.7639 2.7517-4.8683 4.5156-3.1044 1.6933-7.2672 1.6933-4.8683 0-8.8194-2.4694-3.9511-2.54-6.2794-7.1261-2.2578-4.6567-2.2578-10.724t2.2578-10.654q2.3283-4.5861 6.2794-7.0556 3.9511-2.4694 8.8194-2.4694zm12.136 20.249q0-4.5156-2.54-7.1261-2.4694-2.6106-6.0678-2.6106-3.5983 0-6.1383 2.6106-2.4694 2.54-2.4694 7.0556t2.4694 7.1967q2.54 2.6106 6.1383 2.6106 3.5983 0 6.0678-2.6106 2.54-2.6106 2.54-7.1261z"
                        />
                        <path
                            d="m64.347 6.1113v52.211h-12.065v-52.211z"
                        />
                        <path
                            d="m79.163 14.86q-3.175 0-5.2211-1.8344-1.9756-1.905-1.9756-4.6567 0-2.8222 1.9756-4.6567 2.0461-1.905 5.2211-1.905 3.1044 0 5.08 1.905 2.0461 1.8344 2.0461 4.6567 0 2.7517-2.0461 4.6567-1.9756 1.8344-5.08 1.8344zm5.9972 4.0922v39.37h-12.065v-39.37z"
                        />
                        <path
                            d="m117.9 18.529q6.9144 0 11.007 4.5156 4.1628 4.445 4.1628 12.277v23.001h-11.994v-21.378q0-3.9511-2.0461-6.1383-2.0461-2.1872-5.5033-2.1872-3.4572 0-5.5033 2.1872-2.0461 2.1872-2.0461 6.1383v21.378h-12.065v-39.37h12.065v5.2211q1.8344-2.6106 4.9389-4.0922 3.1044-1.5522 6.985-1.5522z"
                        />
                        <path
                            d="m156.42 18.388q4.1628 0 7.2672 1.6933 3.175 1.6933 4.8683 4.445v-5.5739h12.065v39.299q0 5.4328-2.1872 9.8072-2.1167 4.445-6.5617 7.0556-4.3744 2.6106-10.936 2.6106-8.7489 0-14.182-4.1628-5.4328-4.0922-6.2089-11.148h11.924q0.56444 2.2578 2.6811 3.5278 2.1167 1.3406 5.2211 1.3406 3.7394 0 5.9267-2.1872 2.2578-2.1167 2.2578-6.8439v-5.5739q-1.7639 2.7517-4.8683 4.5156-3.1044 1.6933-7.2672 1.6933-4.8683 0-8.8194-2.4694-3.9511-2.54-6.2794-7.1261-2.2578-4.6567-2.2578-10.724t2.2578-10.654q2.3283-4.5861 6.2794-7.0556 3.9511-2.4694 8.8194-2.4694zm12.136 20.249q0-4.5156-2.54-7.1261-2.4694-2.6106-6.0678-2.6106-3.5983 0-6.1383 2.6106-2.4694 2.54-2.4694 7.0556t2.4694 7.1967q2.54 2.6106 6.1383 2.6106 3.5983 0 6.0678-2.6106 2.54-2.6106 2.54-7.1261z"
                        />
                    </g>
                    <g clip-path="url(#glingClipPath1)" stroke-width="1" >
                        <path
                            id="glingPath1"
                            class="gling-path"
                            fill="none"
                            stroke="#555"
                            stroke-miterlimit="1"
                            d="m9.2604 62.177 3.0238 6.8036 5.2917 3.0238 6.6146 0.75595 7.1815-1.3229 4.7247-4.5357 1.5119-5.6696v-10.016l-0.56696-15.875-4.3467-6.2366-9.0714-5.1027-8.8824 1.3229-5.2917 6.2366-1.3229 9.2604 2.6458 8.3155 7.5595 4.5357 7.5595-0.37798 8.5045-6.6146 3.2128-8.3155 0.37798-9.6384-0.18899-10.583"
                        />
                    </g>
                    <g clip-path="url(#glingClipPath2)" stroke-width="40">
                        <path
                            id="glingPath2"
                            class="gling-path"
                            fill="none"
                            stroke="#555"
                            stroke-miterlimit="10"
                            d="m58.019 5.4807 0.37798 53.106"
                        />
                    </g>
                    <g clip-path="url(#glingClipPath3)" stroke-width="40">
                        <path
                            id="glingPath3"
                            class="gling-path"
                            fill="none"
                            stroke="#555"
                            stroke-miterlimit="10"
                            d="m79.375 59.342-0.37798-58.586"
                        />
                    </g>
                    <g clip-path="url(#glingClipPath4)" stroke-width="40">
                        <path
                            id="glingPath4"
                            class="gling-path"
                            fill="none"
                            stroke="#555"
                            stroke-miterlimit="10"
                            d="m99.786 17.954 0.56697 41.955-0.18899-27.214 6.6146-6.0476 7.9375-2.8348 7.3705 1.7009 5.2917 5.8586v9.8274l0.18899 12.473 0.18899 5.8586"
                        />
                    </g>
                    <g clip-path="url(#glingClipPath5)" stroke-width="40">
                        <path
                            id="glingPath5"
                            class="gling-path"
                            fill="none"
                            stroke="#555"
                            stroke-miterlimit="10"
                            d="m146.09 61.799 2.2678 6.4256 6.0476 4.1577 8.6934 0.37798 7.1816-3.4018 4.9137-6.2366 0.56697-8.8824-0.18899-10.961-1.3229-8.3155-6.4256-7.3705-8.5045-3.4018-9.6384 2.0789-4.1577 9.0714 0.94494 9.8274 5.4807 6.6146 6.9926 2.2679 8.5045-3.9688 6.2366-6.0476 1.8899-9.6384-0.37798-9.0714-0.37797-7.5595"
                        />
                    </g>
                </g>
            </svg>
        </div>
    </body>
</html>
`