import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { State, ExcelUploadApiParams, ApiParams, Item } from "./types";

const name = "salesEntry/daily";
export const initialItem = {
    id: 0,
    platformId: 0,
    series_id: 0,
    series_name: "",
    author_name: "",
    book_name: "",
    quantity: "",
    amount: "",
    bookNameError: "",
    seriesError: "",
    quantityError: "",
    amountError: "",
};
const initialState: State = {
    lastId: 0,
    items: [initialItem],
    date: "",
    platformId: 0,
    total_amt: 0, // total_amt
    total_cnt: 0,
    uploadLoading: false,
    updateLoading: false,

    salesData: [],
    filteredMatchedData: [],
    matchedAllData: [],
    afterExcelUpload: false,
    uploaderMsg: "",
    filterClicked: false,

    excelResult: [],

    reset: false,
    // 업로더
    isAuto: false,
    salesDate: "",
};

// 엑셀 데이터 업로드
export const uploadExcelData = createAsyncThunk(
    `${name}/uploadExcelData`,
    async (apiParams: ExcelUploadApiParams, { rejectWithValue }) => {
        try {
            if (apiParams.form === "글링") {
                return (await api.post(`/api/settlement/newExcelUpload/glingUploadDaily/insert`, apiParams)).data;
            } else {
                return (await api.post(`/api/settlement/newExcelUpload/uploadDaily/insert`, apiParams)).data;
            }
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 일별 판매 데이터 등록
export const saveDailySales = createAsyncThunk(
    `${name}/saveDailySales`,
    async (apiParams: ApiParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSalesEntry/daily/insert`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const dailySlice = createSlice({
    name,
    initialState,
    reducers: {
        setDate: (state, { payload }) => {
            state.date = payload;
        },
        setPlatformId: (state, { payload }) => {
            state.platformId = payload;
        },
        setLastId: (state, { payload }) => {
            state.lastId = payload;
        },
        setItemValue: (state, action: PayloadAction<{ index:number; key:string; value:any }>) => {
            const { index, key, value } = action.payload;
            state.items[index][key as keyof Item] = value as never;
        },
        setItem: (state, action: PayloadAction<{ index:number; item:any }>) => {
            const { index, item } = action.payload;
            state.items[index] = item;
        },
        setItems: (state, { payload }) => {
            state.items = payload;
        },
        setAmount: (state, { payload }) => {
            state.total_amt = payload;
        },
        setTotal: (state, { payload }) => {
            state.total_cnt = payload;
        },
        setAfterExcelUpload: (state, { payload }) => {
            state.afterExcelUpload = payload;
        },
        setUploaderMsg: (state, { payload }) => {
            state.uploaderMsg = payload;
        },
        resetItems: (state) => {
            state.items = [initialItem];
            state.total_cnt = 0;
            state.total_amt = 0;
        },
        setReset: (state, { payload }) => {
            state.reset = payload;
        },
        resetStore: () => {
            return { ...initialState, reset: true };
        },
    },
    extraReducers: (builder) => {
        // 엑셀 업로드
        builder
            .addCase(uploadExcelData.pending, (state) => {
                state.uploadLoading = true;
            })
            .addCase(uploadExcelData.fulfilled, (state) => {
                state.uploadLoading = false;
            });
        // 일정산 입력
        builder
            .addCase(saveDailySales.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(saveDailySales.fulfilled, (state) => {
                state.updateLoading = false;
            });
    },
});

export const {
    setDate,
    setPlatformId,
    setItemValue,
    setItem,
    setItems,
    setLastId,
    setAmount,
    setTotal,
    setAfterExcelUpload,
    setUploaderMsg,
    resetItems,
    setReset,
    resetStore,
} = dailySlice.actions;
export default dailySlice.reducer;
