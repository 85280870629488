import api from "@/api";

let newSeriesDebounce:any;

export const newSeries = async(key:string, error:string, value:string, id:number, mandatory:boolean) => {
    let errorInfo = {
        [error]: "",
        value: value,
    };
    return new Promise((resolve) => {
        if (!value && mandatory) {
            if (newSeriesDebounce) clearTimeout(newSeriesDebounce);
            errorInfo[error] = "시리즈명을 입력해주세요.";
            return resolve(errorInfo);
        }

        if (newSeriesDebounce) clearTimeout(newSeriesDebounce);
        newSeriesDebounce = setTimeout(async () => {
            const apiParams = {
                series_name: value
            }

            const { data } = await api.post(`/api/settlement/common/validate/series/select`, apiParams);
            if (data.status === "fail") {
                // return alert("error", "오류 발생", res.message);
            }

            if (data.data.length && id !== data.data[0].id) {
                errorInfo[error] = "이미 등록된 시리즈명입니다.";
                return resolve(errorInfo);
            } else {
                errorInfo[error] = "";
                return resolve(errorInfo);
            }
        }, 500);
    })
};