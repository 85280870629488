import { useEffect } from "react";
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";

// components
import ExcelUpload from "@/components/molecules/common/excelUpload/ExcelUpload";
import BulkEnrollOptionsRadio from "@/components/molecules/basedata-manage/BulkEnrollOptionsRadio";
import BulkEnrollButtons from "@/components/molecules/basedata-manage/BulkEnrollButtons";

// reducer
import { bulkActions } from "@/pages/settlement/basedata-manage/bulkEnroll/bulkenrollSlice";

export default function BulkEnroll() {
    const dispatch = useAppDispatch();
    let { file } = useAppSelector((state:RootState) => state.bulkenroll)

    useEffect(()=>{
        return () => {
            dispatch(bulkActions.reset());
        }
    }, [])

    return (
        <>
            <BulkEnrollOptionsRadio />
            <ExcelUpload
                file={file}
                setFileAction={bulkActions.setFile}
                customStyle={{ width: "1200px" }}
            />
            <BulkEnrollButtons />
        </>
    );
}