import { ChangeEvent, Fragment, useEffect } from "react";
import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import TextInput from "@/components/atoms/input/TextInput";
import SelectBox from "@/components/atoms/selectBox/index";
import CustomDatePicker from "@/components/molecules/common/date-picker/CustomDatePicker";
import { colors } from "@/assets/styles/theme";
import Tooltip from "@/components/molecules/common/tooltip/Tooltip";
import { setEnrollData, setMgError, setReset } from "@/pages/settlement/sales-entry/mg/mgSlice";
import { getToday } from "@/modules/utils/date";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { addComma } from "@/modules/utils/filter";
import AuthorSearch from "../../basedata-manage/AuthorSearch";
import usePlatformSetting from "../common/hooks/usePlatformSetting";

export default function MgEnrollFilter() {
    const {
        contractStartDate,
        platformId,
        authorInfo,
        contractPrice,
        mgRatio,
        preDeduction,
        remark,
        contractPriceError,
        mgRatioError,
        reset,
    } = useSelectorTyped((state: RootState) => state.entryMg);
    const dispatch = useAppDispatch();
    const { initialPlatformId, platformList } = usePlatformSetting({ platformId });
    const today = getToday();

    // 초기화
    useEffect(() => {
        dispatch(setEnrollData({ contractStartDate: today, platformId: initialPlatformId }));
        return () => {};
    }, [reset]);

    // 리셋
    useEffect(() => {
        if (reset) dispatch(setReset(false));
    }, [reset]);

    const changeDate = (date: string) => dispatch(setEnrollData({ contractStartDate: date }));

    const changeMgType = (id: string) => dispatch(setEnrollData({ mgType: id }));

    const changePlatform = (data: any) => dispatch(setEnrollData({ platformId: data.platform_id }));

    const changeContractType = (type: string) => dispatch(setEnrollData({ contractType: type }));

    const changeContractPrice = (event: ChangeEvent<HTMLInputElement>) => {
        let v = event.target.value.replace(/(^0+)/, "");
        let amount = v.replace(/[^0-9]/g, "");

        if (!amount) {
            dispatch(setEnrollData({ contractPrice: amount }));
            dispatch(setMgError({ contractPriceError: "계약금액을 입력해주세요." }));
        } else {
            dispatch(setMgError({ contractPriceError: "" }));
            amount = addComma(String(amount).slice(0, 9));
            dispatch(setEnrollData({ contractPrice: amount }));
        }
    };

    const changeRatio = (event: ChangeEvent<HTMLInputElement>) => {
        let v = event.target.value.replace(/(^0+)/, "");
        let ratio = Number(v.replace(/[^0-9]/g, ""));

        if (ratio < 0) {
            ratio *= -1;
        } else if (ratio > 100) {
            ratio = 100;
        }
        dispatch(setEnrollData({ mgRatio: ratio }));

        if (!ratio) {
            dispatch(setMgError({ mgRatioError: "정산비율을 입력해주세요." }));
        } else {
            dispatch(setMgError({ mgRatioError: "" }));
        }
    };

    const changePreDeduction = (event: ChangeEvent<HTMLInputElement>) => {
        let v = event.target.value.replace(/(^0+)/, "");
        let preDeduction = v.replace(/[^0-9]/g, "");

        dispatch(setMgError({ preDeductionError: "" }));
        dispatch(setEnrollData({ preDeduction: addComma(preDeduction.slice(0, 9)) }));
    };

    const changeRemark = (e: ChangeEvent<HTMLInputElement>) => dispatch(setEnrollData({ remark: e.target.value }));

    const selectAuthor = (idx: number, authorInfo: any) => dispatch(setEnrollData({ authorInfo }));

    const deleteAuthor = () => {
        dispatch(
            setEnrollData({
                authorInfo: {
                    author_id: 0,
                    author_name: "",
                    co_info: [],
                },
            }),
        );
    };

    const applyCoInfo = (co_info: any, idx: number) => {
        let newAuthorInfo = { ...authorInfo, co_info };
        dispatch(setEnrollData({ authorInfo: newAuthorInfo }));
    };

    return (
        <FilterContainer>
            <FilterRow
                label="계약시작일"
                filterContent={[<CustomDatePicker settingDate={contractStartDate} onChange={changeDate} />]}
            />
            <FilterRow
                label="선인세 구분"
                filterContent={[
                    <TagFilter
                        type="radio"
                        items={mgTypeItems}
                        keyName="id"
                        valueName="name"
                        reset={reset}
                        onChange={changeMgType}
                    />,
                ]}
            />
            <FilterRow
                label="판매플랫폼"
                filterContent={[
                    <SelectBox
                        valueKey="platform_id"
                        labelKey="platform_name"
                        options={platformList}
                        selected={platformId || initialPlatformId}
                        onClickValueFunc={changePlatform}
                    />,
                ]}
            />
            <FilterRow
                label="계약형태"
                filterContent={[
                    <TagFilter
                        type="radio"
                        items={contractTypeItems}
                        keyName="id"
                        valueName="name"
                        reset={reset}
                        onChange={changeContractType}
                    />,
                ]}
            />
            <FilterRow
                label="저자명"
                filterContent={[
                    <AuthorSearch
                        from="series-update"
                        idx={0}
                        author={authorInfo}
                        value={authorInfo.author_name}
                        placeholder="저자명"
                        customStyle={{
                            width: "160px",
                            height: "32px",
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "400",
                        }}
                        buttonCustomStyle={{
                            customStyle: {
                                width: "120px",
                                height: "32px",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: colors.gray600,
                            },
                        }}
                        selectAuthorFunc={selectAuthor}
                        deleteAuthorFunc={deleteAuthor}
                        applyCoInfoFunc={applyCoInfo}
                    />,
                ]}
            />
            <FilterRow
                label="계약금액(원)"
                tooltipText="출판사와 작가 사이의 계약금액입니다."
                filterContent={[
                    <ValidateWrapper dataError={contractPriceError}>
                        <TextInput
                            value={contractPrice}
                            placeholder="계약금액"
                            onChangeHandler={changeContractPrice}
                            customStyle={textInputStyle}
                        />
                    </ValidateWrapper>,
                ]}
            />
            <FilterRow
                label="정산비율(%)"
                tooltipText="선인세를 차감할 때 계산되는 작가 입장에서의 비율입니다."
                filterContent={[
                    <ValidateWrapper dataError={mgRatioError}>
                        <TextInput
                            value={mgRatio}
                            placeholder="정산비율"
                            onChangeHandler={changeRatio}
                            customStyle={textInputStyle}
                        />
                    </ValidateWrapper>,
                ]}
            />
            <FilterRow
                label="초기 차감금액(원)"
                tooltipText="시스템 도입 이후 월정산 데이터 입력 하시기 이전까지의 차감 완료된 금액입니다."
                filterContent={[
                    <TextInput
                        value={preDeduction}
                        placeholder="초기 차감금액"
                        onChangeHandler={changePreDeduction}
                        customStyle={textInputStyle}
                    />,
                ]}
            />
            <FilterRow
                label="비고"
                filterContent={[
                    <TextInput
                        value={remark}
                        placeholder="비고"
                        onChangeHandler={changeRemark}
                        customStyle={textInputStyle}
                    />,
                ]}
            />
        </FilterContainer>
    );
}

function FilterRow({ label, tooltipText, filterContent, customStyle }: FilterRowProps) {
    return (
        <StyledRowWrapper backgroundColor={customStyle?.backgroundColor}>
            <Label>
                {label}
                {tooltipText && <TooltipWrapper tooltipText={tooltipText} />}
            </Label>
            <Content>
                {filterContent.map((content, idx) => (
                    <Fragment key={idx}>
                        {content}
                        <Margin />
                    </Fragment>
                ))}
            </Content>
        </StyledRowWrapper>
    );
}

function TooltipWrapper({ tooltipText }: { tooltipText: string }) {
    return (
        <>
            <TooltipMargin />
            <Tooltip iconWidth={20} iconHeight={20} textBoxWidth={280} fill={colors.gray600} text={tooltipText} />
        </>
    );
}

// 선인세 타입
const mgTypeItems = [
    {
        id: "platform",
        name: "플랫폼 선인세",
    },
    {
        id: "publisher",
        name: "출판사 선인세",
        disabled: true,
    },
];
// 계약 형태
const contractTypeItems = [
    {
        id: "MG01",
        name: "작가계약",
    },
    {
        id: "MG02",
        name: "작품계약",
    },
];

const textInputStyle = {
    height: "32px",
};

const FilterContainer = styled.div`
    width: 1200px;
    margin-top: 24px;
`;
const StyledRowWrapper = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 60px;
    height: auto;
    position: relative;
    background-color: ${({ backgroundColor, theme }) => backgroundColor && theme.colors.gray50};
`;
const Label = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    width: 200px;
    height: 100%;
    padding-left: 8px;
    font-size: 14px;
    font-weight: 500;
`;
const TooltipMargin = styled.div`
    margin-left: 8px;
`;
const Content = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: calc(100% - 200px);
    height: 100%;
    min-height: 60px;
    left: 200px;
    padding: 14px 0px 14px 8px;
`;
const Margin = styled.div`
    width: 20px;
`;

interface FilterRowProps {
    label: string;
    filterContent: JSX.Element[];
    tooltipText?: string;
    customStyle?: StyleProps;
}

interface StyleProps {
    backgroundColor?: string;
}
