import styled from "styled-components";
import ApexCharts from "react-apexcharts";
import { useAppSelector, RootState } from "@/modules/store";
import moment from "moment";
import { colors } from "@/assets/styles/theme";

export default function SalesUpDownChartWrapper() {
    const { salesUpDownChartData, salesUpDownChartSeriesName } = useAppSelector((state: RootState) => state.pubDashboard);
    const dataColors: Array<string> = [];
    for (let i = 0; i < salesUpDownChartData.length; i++) {
        if (i === salesUpDownChartData.length-1) dataColors.push(colors.blue500);
        else dataColors.push(colors.blue50);
    }

    let labels = [];
    for (let i = 7; i >= 1; i--) {
        labels.push(moment().subtract(i, "days").format("DD일"));
    }

    const soaringSalesData: any = {
        series: [
            {
                name: '판매금액',
                type: 'column',
                data: salesUpDownChartData
            }, 
            // {
            //     name: '전일대비(%)',
            //     type: 'line',
            //     data: [50, 40, 30, 25, 20, 10, 10]
            // }
        ],
        options: {
            chart: {
                height: 300,
                type: 'line',
                stacked: false,
                foreColor: "#868E96",
                toolbar: {
                    show: false,
                }
            },
            colors: [function({ value, dataPointIndex, w}: any) {
                if (dataPointIndex === salesUpDownChartData.length-1) {
                    return colors.blue500;
                } else {
                    return colors.blue50;
                }
            }],
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8
                    }
                }
            },
            stroke: {
                width: [0, 4, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '58%',
                    borderRadius: 12,
                }
            },
            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            labels,
            markers: {
                size: 0
            },
            xaxis: {
                type: 'string',
                tooltip: {
                    enabled: false
                },
                crosshairs: {
                    show: false
                },
            },
            yaxis: {
                labels: {
                    formatter: function(val: number) {
                        if (val > 10000) {
                            return `${(val / 10000).toFixed(0)}만원`
                        } else {
                            return val.toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원";
                        }
                    }
                }
            },
            tooltip: {
                shared: false,
                intersect: true,
                followCursor: false,
                custom: function(data: any) {
                    const { dataPointIndex } = data;
                    const color = dataColors[dataPointIndex];
                    return `
                        <div class="tooltip-box">
                            <div>${salesUpDownChartSeriesName}</div>
                            <div class="amount-wrap">
                                <span class="color-box" style="background-color: ${color};"></span>
                                <div>${salesUpDownChartData[dataPointIndex].toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원"}</div>
                            </div>
                        </div>
                    `
                },
            },
            grid: { show: false },
        },
    }

    return (
        <div>
            <StyledCharts
                options={soaringSalesData.options}
                series={soaringSalesData.series}
                types="line"
                width={360}
                height={290}
            />
        </div>   
    )
}

const StyledCharts = styled(ApexCharts)`
    .apexcharts-tooltip {
        box-shadow: 0px 0px 15px rgba(112, 144, 176, 0.2); !important;
    }
    .tooltip-box {
        display: flex;
        flex-direction: column;
        padding: 8px 10px;
        min-width: 120px;
        background: #FFFFFF;
        border-radius: 4px;
        color: #121417;
        font-size: 10px;
        line-height: 14px;
        .amount-wrap {
            display: flex;
            margin-top: 10px;
            align-items: center;
            .color-box {
                position: relative;
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
        }
    }
`
