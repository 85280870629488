import { useState } from "react";
import useCheckbox from "./hooks/useCheckbox";
import useDataTableSort from "@/components/molecules/common/table/hooks/useDataTableSort";
import type { Item } from "@/components/molecules/common/table/DataTable";

// 1. 체크박스
// 2. 정렬
// 3. 페이지네이션 => 추후 추가 / 체크박스 초기값 전달방식 -> setChunkItem 사용하는 방식으로 변경해야할듯
export default function useHeadlessTable({ itemKey, items }: HeadlessTableProps) {
    // 테이블 체크박스
    const { selectedKeys, selectItem, selectAll, checkSelected, checkSelectedAll } = useCheckbox({ itemKey, chunkItem: items });
    // 테이블 정렬
    const [sortedItems, setSortedItems] = useState<Item[]>([]);
    const setHeadlessItem = (items:any[]) => setSortedItems(items);
    const { sortBy, sortDir, sorting } = useDataTableSort({ items, setHeadlessItem });

    return {
        selectedKeys, selectItem, selectAll, checkSelected, checkSelectedAll,
        sortedItems, sortBy, sortDir, sorting
    }
}

// items & itemPerPage 받기 
interface HeadlessTableProps {
    itemKey: string;
    items: any[];
} 