import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, GetPaymonthApiParams, CheckDataApiParams, ExcelUploadApiParams, ApiParams, Item } from "./types";

const name = "salesEntry/monthly";
const initialItem = {
    id: 0,
    series_id: 0,
    series_name: "",
    book_name: "",
    author_id: 0,
    author_name: "",
    quantity: "",
    amount_pl: "",
    amount_pu: "",
    author_ratio: "",
    amount_au: "",
    ratio: 0,
    seriesError: "",
    bookNameError: "",
    quantityError: "",
    authorRatioError: "",
    amount_pl_error: "",
    amount_pu_error: "",
};
const initialState: State = {
    items: [initialItem],
    yearmon: "",
    yearmon_st: "",
    platformId: 0,
    lastId: 0,
    total_cnt: 0,
    total_amt_pl: 0,
    total_amt_pu: 0,
    nonMatchedData: [],
    matchedData: [],
    addedSeriesRatioList: [],

    salesData: [],
    filteredMatchedData: [],
    matchedAllData: [],
    afterExcelUpload: false,
    forAddRatioList: [],
    reset: false,

    // 업로더
    uploaderMsg: "",
};

// 플랫폼별 정산 달 계산
export const getPaymonth = createAsyncThunk(
    `${name}/getPaymonth`,
    async (apiParams: GetPaymonthApiParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/salesEntry/monthly/paymonth`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 해당 플랫폼, 해당 정산월에 이미 데이터가 존재하는지 체크
export const checkAlreayHasData = createAsyncThunk(
    `${name}/checkAlreayHasData`,
    async (apiParams: CheckDataApiParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSalesEntry/monthly/upload/validate`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 엑셀 데이터 업로드
export const uploadExcelData = createAsyncThunk(
    `${name}/uploadExcelData`,
    async (apiParams: ExcelUploadApiParams, { rejectWithValue }) => {
        try {
            if (apiParams.form === "글링") {
                return (await api.post(`/api/settlement/newExcelUpload/glingUploadMonthly/insert`, apiParams)).data;
            } else {
                return (await api.post(`/api/settlement/newExcelUpload/uploadMonthly/insert`, apiParams)).data;
            }
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 월별 판매 데이터 등록
export const saveMonthlySales = createAsyncThunk(
    `${name}/saveMonthlySales`,
    async (apiParams: ApiParams, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSalesEntry/monthly/insert`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const monthlySlice = createSlice({
    name,
    initialState,
    reducers: {
        setDate: (state, action: PayloadAction<Date>) => {
            const { yearmon, yearmon_st } = action.payload;
            if (yearmon) state.yearmon = yearmon;
            if (yearmon_st) state.yearmon_st = yearmon_st;
        },
        setPlatformId: (state, action) => {
            state.platformId = action.payload;
        },
        setLastId: (state, action) => {
            state.lastId = action.payload;
        },
        setItemValue: (state, action: PayloadAction<{ index:number; key:string; value:any }>) => {
            const { index, key, value } = action.payload;
            state.items[index][key as keyof Item] = value as never;
        },
        setItem: (state, action: PayloadAction<{ index:number; item:any }>) => {
            const { index, item } = action.payload;
            state.items[index] = item;
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setSummary: (state, action: PayloadAction<Summary>) => {
            const { total_amt_pl, total_amt_pu, total_cnt } = action.payload;
            state.total_amt_pl = total_amt_pl ?? state.total_amt_pl;
            state.total_amt_pu = total_amt_pu ?? state.total_amt_pu;
            state.total_cnt = total_cnt ?? state.total_cnt;
        },
        setNonMatchedData: (state, action) => {
            state.nonMatchedData = action.payload;
        },
        setMatchedData: (state, action) => {
            state.matchedData = action.payload;
        },
        setAddedSeriesRatioList: (state, action) => {
            state.addedSeriesRatioList = action.payload;
        },
        setAfterExcelUpload: (state, action) => {
            state.afterExcelUpload = action.payload;
        },
        setUploaderMsg: (state, action) => {
            state.uploaderMsg = action.payload;
        },
        setReset: (state, action) => {
            state.reset = action.payload;
        },
        resetItems: (state) => {
            state.items = [initialItem];
            state.total_amt_pl = 0;
            state.total_amt_pu = 0;
            state.total_cnt = 0;
        },
        resetStore: () => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPaymonth.fulfilled, (state, action) => {});
    },
});

interface Date {
    yearmon?: string;
    yearmon_st?: string;
}
interface Summary {
    total_cnt?: number;
    total_amt_pl?: number;
    total_amt_pu?: number;
}

export const {
    setDate,
    setPlatformId,
    setLastId,
    setItemValue,
    setItem,
    setItems,
    setSummary,
    setNonMatchedData,
    setAddedSeriesRatioList,
    setMatchedData,
    setAfterExcelUpload,
    setUploaderMsg,
    setReset,
    resetItems,
    resetStore,
} = monthlySlice.actions;
export default monthlySlice.reducer;
