import { useEffect } from "react";

// reducers
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import {
    getDailyData,
    getDailyDetails,
    inquiryDailyActions,
} from "@/pages/settlement/saels-inquiry/publisher/daily/dailySlice";

// components
import TemplateLayout from "@/components/molecules/sales-entry/common/TemplateLayout";
import DailySearchFilter from "@/components/molecules/sales-inquiry/publisher/daily/DailySearchFilter";
import DailyTable from "@/components/molecules/sales-inquiry/publisher/daily/DailyTable";
import DailyTableTop from "@/components/molecules/sales-inquiry/publisher/daily/DailyTableTop";

// hooks
import useDailyApi from "./hooks/useDailyApi";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function SalesInquiry() {
    const { items, selectedPlatform, initialDataLoaded, changedParamsId, changedDetailParamsId } = useSelectorTyped((state: RootState) => state.inquiryDaily);
    const dispatch = useAppDispatch();
    const { setNoData } = inquiryDailyActions;
    const { page, itemsPerPage } = usePagination({ from:'daily-inquiry', tableData: items });

    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    useDailyApi();

    useEffect(() => {
        getData();
    }, [changedParamsId]);

    useEffect(() => {
        getDetails();
    }, [changedDetailParamsId]);

    const getData = async () => {
        try {
            if (initialDataLoaded && !selectedPlatform.length) {
                return dispatch(setNoData());
            }
            
            loadingOn();
            await dispatch(getDailyData());
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    const getDetails = async () => {
        try {
            loadingOn();
            await dispatch(getDailyDetails({ page, itemsPerPage })).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    return (
        <TemplateLayout>
            <DailySearchFilter />
            <DailyTableTop />
            <DailyTable />
        </TemplateLayout>
    );
}
