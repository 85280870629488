import { useState } from "react";
import styled from "styled-components";

// components
import SelectBox from "@/components/atoms/selectBox/index";
import MultiSearchBar from "@/components/molecules/common/searchBar/multiSearch/MultiSearchBar";

// types
import type { Atcmp } from "@/types/autoComplete";

// reducer
import { useAppDispatch, RootState } from "@/modules/store";
import { getSeriesData, getAtcmpByAll, getAtcmpBySeries, getAtcmpByAuthor } from "@/pages/settlement/basedata-manage/series/seriesSlice";

// hooks
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import useSearchHistory from "@/components/molecules/common/searchBar/multiSearch/useSearchHistory";
import useAlert from "@/modules/hooks/useAlert";

export default function SeriesSearchBar() {
    const dispatch = useAppDispatch();
    const { searchType, atcmpList } = useSelectorTyped((state:RootState) => state.series);
    const { searchHistory } = useSelectorTyped((state: RootState) => state.searchHistory);
    const { getAtcmp, getHistory, updateHistory, deleteHistory } = useSearchHistory({ template: "basedata-series" });
    const [prevKeyword, setPrevKeyword] = useState("");
    const { alert } = useAlert();

    const changeValue = (value:any) => {
        dispatch({ type: "series/setSearchType", payload: value.value });
        search(prevKeyword, value.value);
    }

    const getAtcmpList = (keyword: string) => {
        switch (searchType) {
            case "all":
                searchByAll(keyword);
                break;
            case "series":
                searchBySeries(keyword);
                break;
            case "author":
                searchByAuthor(keyword);
                break;
        }
    };

    const search = async (item: Atcmp | string, type?: string) => {
        try {
            let apiParams = { searchType: "", searchKeyword: "" };

            if (typeof item === "string") {
                let tempType = type ? type : searchType;
                switch (tempType) {
                    case "series":
                        apiParams.searchType = "series";
                        break;
                    case "author":
                        apiParams.searchType = "author";
                        break;
                    default:
                        apiParams.searchType = "all";
                }
                apiParams.searchKeyword = item;
                dispatch(getSeriesData(apiParams)).unwrap();
    
                setPrevKeyword(item);
                if (!item) return;
                await updateHistory(item);
                return await getHistory();
            }
    
            if (item.type === "series") {
                apiParams.searchType = "series";
            }
            if (item.type === "author") {
                apiParams.searchType = "author";
            }
            apiParams.searchKeyword = item.value;
            dispatch(getSeriesData(apiParams)).unwrap();
    
            setPrevKeyword(item.value);
            await updateHistory(item.value);
            await getHistory();   
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        }
    };

    // 전체 자동완성
    const searchByAll = (keyword: string) => getAtcmp(getAtcmpByAll({ keyword }));

    // 시리즈 자동완성
    const searchBySeries = (keyword: string) => getAtcmp(getAtcmpBySeries({ keyword }));

    // 저자명 자동완성
    const searchByAuthor = (keyword: string) => getAtcmp(getAtcmpByAuthor({ keyword }));

    return (
        <>
            <FilterBoxWrap>
                <RowWrap>
                    <Label>검색</Label>
                    <Content>
                        <SelectBox
                            labelKey="name"
                            valueKey="value"
                            selected={searchType}
                            options={seriesOptions}
                            customStyle={{ height: "40px", margin: "0 10px 0 0" }}
                            onClickValueFunc={changeValue}
                        />
                        <MultiSearchBar
                            type={searchType === "all" ? "all" : "single"}
                            atcmpList={atcmpList}
                            searchHistory={searchHistory}
                            onKeyUp={getAtcmpList}
                            onSelected={search}
                            onClearHistory={deleteHistory}
                        />
                    </Content>
                </RowWrap>
            </FilterBoxWrap>
        </>
    );
}

const seriesOptions = [{ name: "전체", value: "all" },
                       { name: "시리즈명", value: "series" },
                       { name: "저자명", value: "author" }];

const FilterBoxWrap = styled.div`
    width: 1200px;
    background-color: ${({ theme }) => theme.colors.gray50};
    margin-top: 24px;
`;
const RowWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
`;
const Label = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 21px 8px;
    font-size: 14px;
    font-weight: 500;
    width: 200px;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 8px;
    height: 100%;
`;