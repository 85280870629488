import React from "react";
import styled from "styled-components";

// components
import Text from "@/components/atoms/text";
import Radio from "@/components/atoms/input/Radio";

// reducer
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";

export default function BulkEnrollOptionsRadio() {
    const dispatch = useAppDispatch();
    let { type } = useAppSelector((state:RootState) => state.bulkenroll)
    const changeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: "bulk/setFile", payload: { file: "", fileName: ""}});
        dispatch({type: "bulk/setType", payload: e.target.value})
    }

    return (
        <DataListWrap>
            <ListNameWrap>
                <Text text="데이터 항목" customStyle={{ fontSize: "14px" }}/>
            </ListNameWrap>
            <RadioWrap>
                <Radio
                    name="dataType"
                    checked={type === "monthly"}
                    value="monthly"
                    onChange={(e) => changeType(e)}
                    type="label"
                    label="월 정산 데이터"
                    customStyle={{ fontSize:"14px", margin: "0 24px 0 0"}}
                />
                <Radio
                    name="dataType"
                    checked={type === "daily"}
                    value="daily"
                    onChange={(e) => changeType(e)}
                    type="label"
                    label="일별 판매 데이터"
                    customStyle={{ fontSize:"14px" }}
                />
            </RadioWrap>
        </DataListWrap>
    );
}

const DataListWrap =styled.div<StyleProps>`
    display: flex;
    align-items: center;
    justify-content: flex-first;
    height: 60px;
    margin: 24px 0;
`

const ListNameWrap = styled.div`
    display: flex;
    width: 200px;
    padding: 21px 8px;
    background-color: ${({ theme }) => theme.colors.gray50};
`

const RadioWrap = styled.div`
    display: flex;
    width: 1000px;
    padding: 5px 8px;
`

interface StyleProps {
    direction?: string;
    alignItems?: string;
    justifyContent?: string;
}