import styled from "styled-components";
import SubtitleTypeContainer from "@/components/molecules/ebook-maker/method/SubtitleTypeContainer";
import SubtitleMakeTypeContainer from "@/components/molecules/ebook-maker/method/SubtitleMakeTypeContainer";
import SubtitleStyleContainer from "@/components/molecules/ebook-maker/method/SubtitleStyleContainer";
import SubtitleInputContainer from "@/components/molecules/ebook-maker/method/SubtitleInputContainer";
import CoverUploadContainer from "@/components/molecules/ebook-maker/method/CoverUploadContainer";
import StyleUploadContainer from "@/components/molecules/ebook-maker/method/StyleUploadContainer";
import StepBtnWrapper from "@/components/molecules/ebook-maker/method/StepBtnWrapper";
import { RootState, useAppSelector } from "@/modules/store";
import { EbookMaker } from "@/types/dataType";

export default function Method({ stepHandler }: MethodProps) {
    const { subtitleType } = useAppSelector((state: RootState) => state.ebookMaker);

    return (
        <MethodContainer>
            <SubtitleTypeContainer />
            {subtitleType === EbookMaker.SubtitleType.MAKE ? <SubtitleMakeTypeContainer /> : <></>}
            {subtitleType !== EbookMaker.SubtitleType.NONE ? subtitleType !== "" ? <SubtitleStyleContainer /> : <></> : <></>}
            {subtitleType === EbookMaker.SubtitleType.MAKE ? <SubtitleInputContainer /> : <></>}
            <CoverUploadContainer />
            <StyleUploadContainer />
            <StepBtnWrapper stepHandler={stepHandler} />
        </MethodContainer>
    )
}

const MethodContainer = styled.div``

interface MethodProps {
    stepHandler: (step: number, disabled: boolean) => void;
}


