import { useState } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import useDidUpdateEffect from "@/modules/hooks/useDidUpdateEffect";
import { addItems, deleteItems } from "@/pages/common/dataTableSlice";
import { Item } from "../DataTable";

// 테이블 아이템 체크박스
const useCheckbox = ({ chunkItem, itemKey }: UseCheckboxProps) => {
    const { selectedItems } = useSelectorTyped((state: RootState) => state.dataTable);
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const dispatch = useAppDispatch();

    const selectItem = (items: Item) => {
        const newKey = items[itemKey];
        const hasValue = selectedKeys.some((key) => key === newKey);

        // 아이템 삭제
        if (hasValue) {
            setSelectedKeys((prev) => prev.filter((key) => key !== newKey));
            return dispatch(deleteItems({ key: itemKey, value: items[itemKey] }));
        }

        // 아이템 추가
        setSelectedKeys((prev) => [...prev, newKey]);
        return dispatch(addItems({ key: itemKey, items }));
    };

    const selectAll = () => {
        const selectedAll = checkSelectedAll();
        const chunkItemKeys = chunkItem.map((c) => c[itemKey]);

        // chunkItems에 해당하는 모든 아이템 삭제
        if (selectedAll) {
            setSelectedKeys((prev) => prev.filter((key) => !chunkItemKeys.includes(key)));
            return dispatch(deleteItems({ key: itemKey, value: chunkItemKeys }));
        }

        // chunkItems에 해당하는 모든 아이템 추가
        setSelectedKeys((prev) => [...new Set([...prev, ...chunkItemKeys])]);
        dispatch(addItems({ key: itemKey, items: chunkItem }));
    };

    const checkSelected = (id: number) => selectedKeys.includes(id);

    const checkSelectedAll = () => {
        if (!chunkItem[0]) return false;
        const chunkItemKeys = chunkItem.map((c) => c[itemKey]);
        return chunkItemKeys.length === selectedKeys.filter((key) => chunkItemKeys.includes(key)).length;
    };

    // 테이블 리셋 시
    useDidUpdateEffect(() => {
        if (selectedItems.length === 0) setSelectedKeys([]);
    }, [selectedItems]);

    return {
        selectedKeys,
        selectItem,
        selectAll,
        checkSelected,
        checkSelectedAll,
    };
};

interface UseCheckboxProps {
    itemKey: string;
    chunkItem: Item[];
}

export default useCheckbox;
