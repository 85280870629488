import { useState, MouseEvent, KeyboardEvent, useEffect } from "react";
import styled from "styled-components";
import theme from "@/assets/styles/theme";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import SimpleSearchBar from "@/components/molecules/common/searchBar/simpleSearchBar/SimpleSearchBar";

/**
 * 모달과 연결된 검색바
 * @param settingKeyword 모달에서 검색된 키워드
 * @param onHandleModal 서치바 클릭시 모달 상태 변경
 * @param onKeyUp validation 함수 전달
 * @returns
 */
function ModalSearchBar({
    type,
    placeholder,
    disabled = false,
    value,
    className,
    onHandleModal,
    onChange,
    onKeyUp,
    customStyle,
}: SearchBarProps) {
    const [_value, setValue] = useState("");
    const iconColor = disabled ? theme.colors.gray600 : theme.colors.blue500;

    useEffect(() => {
        if (value && value !== _value) setValue(value);
    }, [value]);

    // 모달 연결 여부
    const handleModal = (e: MouseEvent<HTMLDivElement>) => {
        if (!onHandleModal || disabled) return;
        onHandleModal();
    };
    const changeKeyword = (v: string) => {
        setValue(v);
        if (onChange) onChange(v);
    };
    const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        if (onKeyUp) onKeyUp(e.currentTarget.value);
    };

    switch (type) {
        // 인풋 입력 불가. 클릭시 바로 모달 오픈
        case "disabledInput":
            return (
                <SearchBarWrapper
                    className={className}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    onClick={handleModal}
                >
                    <SimpleSearchBar 
                        value={_value}
                        placeholder={placeholder} 
                        disabled={true}
                        onChange={changeKeyword}
                    />
                </SearchBarWrapper>
            );
        // 인풋 입력 가능. 돋보기 아이콘 클릭시 모달 오픈
        case "default":
            return (
                <SearchBarWrapper
                    className={className}
                    width={customStyle?.width}
                    height={customStyle?.height}
                >
                    <SimpleSearchBar
                        value={_value}
                        placeholder={placeholder}
                        disabled={disabled}
                        className="simple-searchBar"
                        onChange={changeKeyword}
                        onKeyUp={handleKeyUp}
                    />
                    <IconWrapper disabled={disabled} onClick={handleModal}>
                        <SearchIcon size={24} fill={iconColor} />
                    </IconWrapper>
                </SearchBarWrapper>
            );
    }
}

const SearchBarWrapper = styled.div<StyleProps>`
    position: relative;
    width: ${({ width }) => (width ? width : "180px")};
    height: ${({ height }) => (height ? height : "32px")};
`;
const IconWrapper = styled.div<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

interface SearchBarProps {
    type: "disabledInput" | "default";
    value: string;
    placeholder: string;
    disabled?: boolean;
    className?: string;
    customStyle?: StyleProps;
    onChange?: (value: string) => void;
    onHandleModal?: () => void;
    onKeyUp?: (value: string) => void;
}
interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    borderRadius?: string;
    color?: string;
}

export default ModalSearchBar;
