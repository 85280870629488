import styled from "styled-components";
import { Portfolio } from "@/pages/portfolio/author/types";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "@/modules/utils/filter";
import { ReactComponent as Gling } from "@/assets/icons/gling_logo.svg";
import { colors } from "@/assets/styles/theme";

export default function CardViewContainer({ currList }: ComponentProps) {
    const navigate = useNavigate();
    const random = Math.random();

    const goDetail = (c: Portfolio) => {
        navigate(`/portfolio/detail?id=${c.content_id}`);
    }

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>, thumbnailTop: string, idx: number) => {
        const { height } = e.currentTarget;
        e.currentTarget.style.top = `${height * Number(thumbnailTop) / -100}px`;
    }

    return (
        <StyledContainer>
            {currList.map((c, i) => (
                <CardWrapper key={i} onClick={() => goDetail(c)}>
                    <ImageWrapper>
                        {c.coverPath ? 
                            <img 
                                src={`${c.coverPath}?a=${random}`} 
                                alt="cover" 
                                onLoad={(e) => onImageLoad(e, c.thumbnailTop, i)}
                            />  :
                            (<NoImg>
                                <Gling width={104} height={48} fill={colors.gray300} />
                            </NoImg>)
                        }
                    </ImageWrapper>
                    <InfoWrapper>
                        <TitleWrapper>{c.title}</TitleWrapper>
                        <DescWrapper>
                            <div>{c.author_name} | {c.publisher_name}</div>
                            <PublishedAtWrapper>{dateFormat("dash", c.publish_at)} 출간</PublishedAtWrapper>
                        </DescWrapper>
                    </InfoWrapper>
                </CardWrapper>
            ))}        
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

const CardWrapper = styled.div`
    width: 272px;
    height: 272px;
    margin-bottom: 24px;
    margin-right: 37px;
    cursor: pointer;

    &:nth-child(4n) {
        margin-right: 0;
    }
`

const ImageWrapper = styled.div`
    width: 100%;
    height: 200px;
    overflow: hidden;

    img {
        display: block;
        position: relative;
        width: 100%;
    }
`

const InfoWrapper = styled.div`
    width: 100%;
    height: 72px;
    padding: 16px 8px;
`

const TitleWrapper = styled.div`
    font-size: 16px;
    font-weight: 700;
`

const DescWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
`

const PublishedAtWrapper = styled.div`
    color: ${({ theme }) => theme.colors.gray600};
`

const NoImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${colors.gray50};
`

interface ComponentProps {
    currList: Portfolio[];
}