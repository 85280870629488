import styled from "styled-components";
import DraggableTable from "./DraggableTable";

export default function TemplateInputContainer() {
    return (
        <StyledTemplateInputContainer>
            <DraggableTable />
        </StyledTemplateInputContainer>
    )
}

const StyledTemplateInputContainer = styled.div`
    width: 900px;
`






