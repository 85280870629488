import { CommonType } from "@/types/dataType";

export interface PortfolioState {
    loading: boolean,

    // 작품목록
    sortOption: SortOptions;
    viewOption: ViewOptions;
    portfolioList: Portfolio[];

    // 작품 등록 (수정)
    coverFiles: CoverFile[];
    seriesSearchResult: SeriesSearchResult[];
    series_id: number;
    series_name: string;
    publisher_name: string;
    author_name: string;
    isbnSearchResult: IsbnSearchResult[];
    isbnList: string[];
    isbnLabel: string;
    publishAt: CommonType.Date;
    salesPlatform: SalesPlatform[];
    thumbnailActive: boolean;
    thumbnailTop: number;
    promotionList: Promotion[];

    // 작품 상세
    contentBaseInfo: ContentBaseInfo;
    contentPlatformInfo: ContentPlatformInfo[];
    contentSalesInfo: ContentSalesInfo;
    latestPromotion: LatestPromotion[];
    timelineData: TimelineData;

    errorList: ErrorData[];
    allDate: string;
    allPlatform: number[],
}

export enum SearchOptions {
    ALL = "all",
    SERIES_NAME = "seriesName",
    AUTHOR_NAME = "authorName"
}

export enum SortOptions {
    NEW = "new",
    ABC = "abc"
}

export enum ViewOptions {
    CARD,
    LIST
}

export interface Portfolio {
    author_name: string;
    content_id: number;
    coverPath: string;
    thumbnailTop: string;
    created_at: string;
    hover: boolean;
    publish_at: string | null;
    publisher_name: string;
    title: string;
}

export interface CoverFile {
    file: string;
    name: string;
    ext?: string;
}

export interface SeriesSearchResult {
    id: number;
    series_name: string;
    publisher_name: string;
    author_name: string;
    checked: boolean;
    isDuplicate?: boolean;
}

export interface IsbnSearchResult {
    name: string;
    author_name: string;
    publisher: string;
    isbn: string;
    checked: boolean;
}

export interface SalesPlatform {
    platform_id: number;
    platform_name: string;
    circulation_period: CirculationData[];
}

export interface Promotion {
    platform_id: number;
    platform_name: string;
    promotion_name: string;
    promotion_desc: string;
    promotion_start_at: string;
    promotion_end_at: string;
    promotion_no_end_date: boolean;
    [key: string]: string | number | boolean;
}

interface ContentBaseInfo {
    author_name: string;
    content_id: number;
    cover_path: string | null;
    isbn: string | null;
    publish_at: string;
    publisher_name: string;
    series_id: number;
    series_name: string;
}

interface ContentPlatformInfo {
    PP_id: number;
    circulation_period: CirculationData[];
    platform_color: string;
    platform_id: number;
    platform_name: string;
}

export interface CirculationData {
    start_circulate_at: string;
    end_circulate_at: string|null;
}

interface ContentSalesInfo {
    accumulatedSales: {
        amount: number; 
        quantity: number;
    };
    dailySalesHistory: {
        labels: string[]; 
        salesHistory: { 
            data: number[];
            name: string;
            type: string;
        }[];
    };
    monthlySalesHistory: {
        labels: string[]; 
        salesHistory: { 
            data: number[];
            name: string;
            type: string;
        }[];
    };
}

export interface LatestPromotion {
    icon_path: string;
    platform_id: number;
    platform_name: string;
    promotion_desc: string;
    promotion_end_at: string;
    promotion_name: string;
    promotion_start_at: string;
}

interface TimelineData {
    totalYearmonList: { yearmon: string; isFirst: boolean; active: boolean; }[];
    eachPlatformHistory: EachPlatformHistory[];
}

export interface EachPlatformHistory {
    platform_id: number;
    platform_name: string;
    platform_color: string;
    platform_icon_path: string;
    circulation_period: CirculationData[];
    timelineData: {
        yearmon: string;
        isEmpty: boolean;
        hover: boolean;
        active: boolean;
        data: {
            isCirculate: boolean;
            name: string;
            desc: string;
            start_at: string;
            end_at: string;
        }[];
    }[];
}

export interface ErrorData {
    row: number;
    field: string;
    message: string;
}







