import { useEffect, useState } from "react";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import TypeNavBar from "@/components/molecules/common/typeNavBar/TypeNavBar";
import Author from "@/components/templates/basedataManage/Author";
import Series from "@/components/templates/basedataManage/Series";
import Platform from "@/components/templates/basedataManage/Platform";
import BulkEnroll from "@/components/templates/basedataManage/BulkEnroll";
import ExcelUpload from "@/components/templates/basedataManage/ExcelUploadManagement";
import PageTitleWrapper from "@/components/molecules/basedata-manage/PageTitleWrapper";

import { useSearchParams } from 'react-router-dom';

function BasedataManage() {
    const [searchParams] = useSearchParams();
    const [template, setTemplate] = useState(<></>);
    useEffect(()=> {
        switch (searchParams.get("type")) {
            // case null:
            case "Author":
                setTemplate(<Author />);
                break;
            case "Series":
                setTemplate(<Series />);
                break;
            case "Platform":
                setTemplate(<Platform />);
                break;
            case "ConnectedSeries":
                setTemplate(<ExcelUpload />);
                break;
            case "BulkEnroll":
                setTemplate(<BulkEnroll />);
                break;
        }
    }, [searchParams])

    return (
        <SectionLayout>
            <PageTitleWrapper style={{ margin: "0 0 24px" }} text="기초데이터관리" />
            <TypeNavBar
                menus={["저자명 관리", "시리즈명 관리", "판매플랫폼 관리", "시리즈/작품명 연동", "데이터 대량 등록"]}
                types={["Author", "Series", "Platform", "ConnectedSeries", "BulkEnroll"]}
            />
            {template}
        </SectionLayout>
    );
}

export default BasedataManage;
