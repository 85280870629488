import Button from "@/components/atoms/button";

function SignInFormButton({ formValid }: { formValid: boolean }) {
    const btnStyle = {
        width: "100%",
        height: "60px",
        margin: "24px 0 0 0",
        fontSize: "20px",
        fontWeight: "500",
    }
    return (
        <Button type="main" label="로그인" disabled={!formValid} customStyle={btnStyle} />
    )

}
export default SignInFormButton;
