import { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import {
    setItems,
    setSummary,
    resetItems,
    saveMonthlySales,
    setMatchedData,
    setNonMatchedData,
    setAddedSeriesRatioList,
    setPlatformId,
    setUploaderMsg,
    resetStore,
} from "@/pages/settlement/sales-entry/monthly/monthlySlice";
import { resetDataTable } from "@/pages/common/dataTableSlice";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import ExcelUploadModal from "@/components/molecules/common/modal/ExcelUploadSalesModal";
import BatchModal, { SeriesInfo } from "@/components/molecules/common/modal/BatchModal";
import SeriesMatchingModal from "@/components/molecules/common/modal/SeriesMatchingModal";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import Button from "@/components/atoms/button";
import SelectBox from "@/components/atoms/selectBox/index";
import SalesSummary from "@/components/molecules/sales-entry/common/SalesSummary";
import useMonthlyExcelUpload from "./hooks/useMonthlyExcelUpload";
import usePlatformSetting from "@/components/molecules/sales-entry/common/hooks/usePlatformSetting";
import useUtils from "./hooks/useUtils";
import { addComma, removeComma } from "@/modules/utils/filter";
import { SeriesMatchingResult } from "@/types/excelUpload";

export default function MonthlyTableTop() {
    const {
        items,
        yearmon,
        yearmon_st,
        total_cnt,
        total_amt_pl,
        total_amt_pu,
        nonMatchedData,
        matchedData,
        addedSeriesRatioList,
        afterExcelUpload,
        platformId,
    } = useSelectorTyped((state: RootState) => state.entryMonthly);
    const { isAuto } = useSelectorTyped((state: RootState) => state.uploader);
    const { selectedItems } = useSelectorTyped((state: RootState) => state.dataTable);
    const dispatch = useAppDispatch();
    const [showExcelModal, setShowExcelModal] = useState(false);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const { showMatchingModal, setShowMatchingModal, applyExcelUpload } = useMonthlyExcelUpload();
    const { initialPlatformId, platformForm, platformName, platformQty } = usePlatformSetting({ uploadType: "monthly", platformId });
    const { calcAmountAu, checkHasData } = useUtils();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const { setItemsPerPage, itemsPerPage } = usePagination({ from: "monthly", tableData: items });

    // 모달 컨트롤
    const handleBatchModal = () => setShowBatchModal((prev) => !prev);

    const handleMatchingModal = () => setShowMatchingModal((prev) => !prev);

    const handleExcelModal = () => setShowExcelModal((prev) => !prev);

    const applyBatch = (data: SeriesInfo) => {
        const newItems = items.map((item) => {
            let tmpItem = {...item};
            if (data.bookNameCheck) {
                tmpItem.book_name = data.bookName;
                if (data.bookName !== "") {
                    tmpItem.bookNameError = "";
                }
            }
            if (data.seriesCheck) {
                tmpItem.series_id = data.series.id;
                tmpItem.series_name = data.series.name;
                tmpItem.author_name = data.authorName;
                tmpItem.author_ratio = data.ratio;
                tmpItem.seriesError = "";
                let amount_pu = Number(removeComma(tmpItem.amount_pu));
                let author_ratio= Number(tmpItem.author_ratio);
                let amount_au =(amount_pu * author_ratio) / 100;
                tmpItem.amount_au = addComma(Math.round(amount_au));
            }
            return tmpItem;
        })
        dispatch(setItems(newItems));
    };

    const applySeriesMatching = async ({ matchedData, forAddRatioList }: SeriesMatchingResult) => {
        try {
            window.scrollTo({ top: 0 });
            let newItems = [...items];

            for (let i of matchedData) {
                newItems[i.originIdx] = {
                    ...newItems[i.originIdx],
                    series_name: i.series || "",
                    series_id: i.series_id || 0,
                    author_name: i.author || "",
                    author_id: i.author_id || 0,
                    author_ratio: i.ratio || 0,
                    seriesError: "",
                };
                calcAmountAu(newItems[i.originIdx]);
            }

            dispatch(setItems(newItems));
            dispatch(setMatchedData(matchedData));
            setNonMatchedData([]);
            // 시리즈 매칭중 새로 비율 입력한 시리즈들
            dispatch(setAddedSeriesRatioList([...addedSeriesRatioList, ...forAddRatioList]));
            dispatch(setUploaderMsg("EXCEL_UPLOADED"));
        } catch (err) {
            return await alert("error", "오류 발생", "적용 중 오류가 발생하였습니다.");
        }
    };

    const changeSelectBox = (value: any) => setItemsPerPage(Number(value.value));

    const clickExcelUpload = async () => {
        if (items.length && (items[0].book_name || items[0].series_name)) {
            const answer = await alert("confirm", "주의", "엑셀업로드시 기존 데이터는 모두 삭제됩니다.");
            if (answer === "Y") {
                dispatch(resetItems());
                dispatch(resetDataTable());
            } else return;
        }
        if (platformId === 19) {
            let comicoAnswer = await alert(
                "confirm",
                "안내",
                '코미코 플랫폼은 판매량을 제공하지 않아\n임의로 "1"이 입력됩니다.\n진행하시겠습니까?',
            );
            if (comicoAnswer !== "Y") {
                dispatch(setUploaderMsg("ALERT_DENY"));
                return;
            }
            dispatch(setUploaderMsg("ALERT_CONFIRM"));
        }
        // 기존 정산 내역 확인
        const check = await checkHasData(yearmon_st);
        if (check) {
            let answer = isAuto
                ? "Y"
                : await alert("confirm", "주의", "해당 정산월 자료를 이미 입력한 플랫폼입니다.\n 계속 하시겠습니까?");
            if (answer !== "Y") return;
        }

        handleExcelModal();
    };

    const clickSaveButton = () => validateData();

    const clickDeleteButton = async () => {
        if (selectedItems.length === 0) {
            return await alert("warning", "주의", "대상을 선택해주세요.");
        }
        const res = await alert("confirm", "삭제 확인", "삭제 하시겠습니까?");
        if (res === "Y") {
            loadingOn();
            const itemKeys = selectedItems.map((s) => s.id);
            dispatch(setItems(items.filter((item) => !itemKeys.includes(item.id))));

            if (afterExcelUpload) {
                let tmpAmtPl = Number(removeComma(total_amt_pl));
                let tmpAmtPu = Number(removeComma(total_amt_pu));
                let tmpCnt = total_cnt - selectedItems.length;
                for (let i of selectedItems) {
                    tmpAmtPl -= Number(removeComma(i.amount_pl));
                    tmpAmtPu -= Number(removeComma(i.amount_pu));
                }
                dispatch(setSummary({ total_cnt: tmpCnt, total_amt_pl: tmpAmtPl, total_amt_pu: tmpAmtPu }));
            }

            if (items.length === selectedItems.length) {
                dispatch(resetItems());
            }
            dispatch(resetDataTable());

            loadingOff();
            await alert("info", "삭제 완료", "삭제가 완료되었습니다.");
        }
    };

    // 월별 판매 데이터 등록
    const validateData = async () => {
        dispatch(setUploaderMsg(""));

        let hasError = false;
        let tmpItems = _.cloneDeep(items);

        for (let item of tmpItems) {
            if (item.book_name === "") {
                item.bookNameError = "작품명을 입력해주세요.";
            }
            if (item.series_name === "") {
                item.seriesError = "시리즈명을 입력해주세요.";
            }
            if (!item.series_id) {
                item.seriesError = "시리즈명을 다시 입력해주세요.";
            }
            if (item.quantity === "" && platformQty) {
                item.quantityError = "판매량을 입력해주세요.";
            }
            if (item.amount_pl === "") {
                item.amount_pl_error = "판매금액을 입력해주세요.";
            }
            if (item.amount_pu === "") {
                item.amount_pu_error = "출판사정산금을 입력해주세요.";
            }
            if (!item.author_ratio) {
                item.authorRatioError = "작가 정산비율을 입력해주세요.";
            }
            if (
                item.bookNameError ||
                item.seriesError ||
                item.quantityError ||
                item.amount_pl_error ||
                item.amount_pu_error ||
                item.authorRatioError
            ) {
                hasError = true;
            }
        }
        dispatch(setItems(tmpItems));
        if (hasError) return await alert("warning", "주의", "조건을 확인해주세요.");
        saveData();
    };

    const saveData = async () => {
        try {
            let answer = isAuto ? "Y" : await alert("confirm", "저장 확인", "저장 하시겠습니까?");

            if (answer === "Y") {
                try {
                    loadingOn();
                    let monthSalesData = [];
                    for (let i of items) {
                        let amount_pl = typeof i.amount_pl == "string" ? removeComma(i.amount_pl) : i.amount_pl;
                        let amount_pu = typeof i.amount_pu == "string" ? removeComma(i.amount_pu) : i.amount_pu;
                        let item = {
                            yearmon, //판매 월
                            yearmon_st,
                            platform_id: platformId,
                            series_id: i.series_id,
                            author_id: i.author_id,
                            book_name: i.book_name,
                            quantity: platformQty ? Number(i.quantity) : 0,
                            amount_pl,
                            amount_pu,
                        };
                        monthSalesData.push(item);
                    }

                    // 시리즈 매칭시킨 데이터들중 중복된 데이터 필터링
                    const filteredMatchedData = _.uniqBy(matchedData, "seriesNo");
                    // 새로 정산비율 입력된 시리즈들 정리
                    const series_id_list = monthSalesData.map((m) => m.series_id);
                    let forAddRatioList = [];
                    for (let a of addedSeriesRatioList) {
                        if (series_id_list.includes(a.series_id)) {
                            forAddRatioList.push(a);
                        }
                    }

                    const apiParams = {
                        salesData: monthSalesData,
                        filteredMatchedData: filteredMatchedData,
                        matchedAllData: matchedData,
                        forAddRatioList,
                    };

                    dispatch(resetStore());
                    dispatch(setPlatformId(initialPlatformId));

                    await dispatch(saveMonthlySales(apiParams)).unwrap();
                    dispatch(setUploaderMsg("SAVED"));
                    await alert("info", "저장 완료", "저장이 완료되었습니다.");
                } catch (err: any) {
                    dispatch(setUploaderMsg("ERROR"));
                    await alert("error", "오류 발생", err.message);
                } finally {
                    loadingOff();
                }
            }
        } catch (err) {
            await alert("error", "오류 발생", "저장 중 오류가 발생하였습니다.");
        }
    };

    return (
        <>
            <TableTop>
                <TableOptions>
                    <OptionWrapper>
                        <Button
                            type="red-outline"
                            label="선택 삭제"
                            onClickHandler={clickDeleteButton}
                            customStyle={{ margin: "0 8px 0 0" }}
                        />
                        {afterExcelUpload ? (
                            <Button
                                type="main-outline"
                                label="시리즈 매칭"
                                onClickHandler={handleMatchingModal}
                                customStyle={{ margin: "0 8px 0 0" }}
                            />
                        ) : (
                            <Button
                                type="main-outline"
                                label="일괄 적용"
                                onClickHandler={handleBatchModal}
                                customStyle={{ margin: "0 8px 0 0" }}
                            />
                        )}
                    </OptionWrapper>
                    <OptionWrapper>
                        <SelectBox
                            labelKey="name"
                            valueKey="value"
                            selected={itemsPerPage}
                            options={options}
                            onClickValueFunc={changeSelectBox}
                            customStyle={selectBoxStyle}
                        />
                        <Button
                            id="btnMonthlyExcelUpload"
                            type="main-outline"
                            label="엑셀 업로드"
                            onClickHandler={clickExcelUpload}
                            customStyle={uploadBtnStyle}
                        />
                        <Button
                            id="btnMonthlyEntrySave"
                            type="main"
                            label="저장"
                            onClickHandler={clickSaveButton}
                            customStyle={btnStyle}
                        />
                    </OptionWrapper>
                </TableOptions>
                {afterExcelUpload && (
                    <SalesSummary
                        from="monthly"
                        total={total_cnt}
                        amountName={"판매금합계"}
                        amount={total_amt_pl}
                        pubAmount={total_amt_pu}
                    />
                )}
            </TableTop>
            {/* 일괄 업로드 모달 */}
            <ModalPortal>
                <BatchModal
                    from="monthly"
                    show={showBatchModal}
                    close={handleBatchModal}
                    platformId={platformId}
                    platformName={platformName}
                    onApplyBatch={applyBatch}
                />
            </ModalPortal>
            {/* 엑셀 업로드 모달 */}
            <ModalPortal>
                <ExcelUploadModal
                    from="monthly"
                    show={showExcelModal}
                    close={handleExcelModal}
                    platformForm={platformForm}
                    onApplyExcelUpload={applyExcelUpload}
                />
            </ModalPortal>
            {/* 시리즈 매칭 모달 */}
            <ModalPortal>
                <SeriesMatchingModal
                    from="monthly"
                    show={showMatchingModal}
                    close={handleMatchingModal}
                    withRatio={true}
                    nonMatchedData={nonMatchedData}
                    applySeriesMatching={applySeriesMatching}
                />
            </ModalPortal>
        </>
    );
}

const options = [
    { name: "10개씩 보기", value: 10 },
    { name: "30개씩 보기", value: 30 },
    { name: "50개씩 보기", value: 50 },
    { name: "100개씩 보기", value: 100 },
];
const uploadBtnStyle = {
    margin: "0 0 0 8px",
    width: "113px",
};
const selectBoxStyle = {
    fontSize: "12px",
    fontWeight: "400",
};
const btnStyle = {
    margin: "0 0 0 8px",
};

const TableTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
const TableOptions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
`;
const OptionWrapper = styled.div`
    display: flex;
    align-items: center;
`;
