import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { useState, useEffect } from "react";

// components
import { ReactComponent as ClearIcon } from "@/assets/icons/clear.svg";
import Text from "@/components/atoms/text";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import AuthorEnrollModal from "@/components/molecules/common/modal/AuthorEnrollModal";

function AuthorSearchBox({ boxOn, from, author, searchResult, idx, initFunc, customStyle, selectAuthorFunc, deleteAuthorFunc, setCoInfoFunc }:AuthorSearchBoxProps) {
    const [showEnrollAuthor, setShowEnrollAuthor] = useState(false);

    function enrollAuthor() {
        setShowEnrollAuthor(true);
    }

    function closeEnrollAuthorModal() {
        setShowEnrollAuthor(false);
    }

    function deleteAuthor() {
        deleteAuthorFunc(idx);
        let tooltip = document.getElementById("author-name-tooltip");
        if (tooltip) tooltip.remove();
    }

    function selectAuthor(selectA?:any) {
        if (author.author_name) {
            deleteAuthor();
        }

        let tmpAuthor;
        if (selectA) tmpAuthor = JSON.parse(JSON.stringify(selectA));
        else tmpAuthor = JSON.parse(JSON.stringify(searchResult.find(s => s.focus)));

        for (let c of tmpAuthor.co_info) {
            c.coRatio = "";
        }
        setCoInfoFunc(tmpAuthor.co_info);
        selectAuthorFunc(idx, tmpAuthor);
        initFunc();
    }

    return (
        <>
        {
            boxOn ?
            <AuthorSearchBoxWrap width={customStyle?.width}>
                {
                    author?.author_name ?
                    <AuthorTagWrap>
                        <AuthorTag>
                            <AuthorNameText>{author.author_name}</AuthorNameText>
                            <IconWrap onClick={deleteAuthor}>
                                <ClearIcon width={16} height={16} fill={colors.black} />
                            </IconWrap>
                        </AuthorTag>
                    </AuthorTagWrap>
                    :
                    <></>
                }
                <SearchResultWrap id="searchResultList" width={customStyle?.width}>
                {   
                    searchResult.length ?
                    <>
                        {searchResult.map((r, i)=> (
                            <SearchResult
                                active={r.focus}
                                key={i}
                                onClick={() => selectAuthor(r)}
                            >
                                <AuthorName name={r.author_name} index={i} style={customStyle}/>
                            </SearchResult>
                        ))}
                    </>    
                    :
                    <SearchResult noHover={true}>
                        <Text text="저자명을 검색해주세요." customStyle={{ color: colors.gray600, fontSize: "12px", fontWeight: "400", padding: "0 8px" }} />
                    </SearchResult>
                }
                </SearchResultWrap>
                <AuthorEnrollWrap>
                    <UnderLineText onClick={enrollAuthor}>
                    저자 신규 등록
                    </UnderLineText>
                </AuthorEnrollWrap>
            </AuthorSearchBoxWrap>
            :
            <></>
        }
            <ModalPortal>
                <AuthorEnrollModal
                    from={from}
                    show={showEnrollAuthor}
                    close={closeEnrollAuthorModal}
                />
            </ModalPortal>
        </>
    );
}

export default AuthorSearchBox;

const AuthorName = ({name, index, style}: {name: string, index: number, style: any}) => {
    useEffect(() => {
        scrollListener();
    }, [])

    const scrollListener = () => {
        let target = document.getElementById("searchResultList");
        if (target) target.addEventListener("scroll", () => {
            onMouseLeave();
        });
    }

    const onMouseEnter = () => {
        let target = document.getElementById(`name-${index}`);
        if (target) {
            let targetTop = target.getBoundingClientRect().top;
            let targetRight = target.getBoundingClientRect().right;
            let layout = document.getElementById(`root`)
            let tooltip = document.createElement("span");
            let content = document.createTextNode(name);
            tooltip.appendChild(content);
            tooltip.setAttribute('id', 'author-name-tooltip');
    
            tooltip.style.top = targetTop + "px";
            tooltip.style.left = targetRight + 15 + "px";
    
            if (layout) layout.insertBefore(tooltip, null);
        }
    }

    const onMouseLeave = () => {
        let tooltip = document.getElementById("author-name-tooltip");
        if (tooltip) tooltip.remove();
    }

    return (
        <AuthorNameWrap id={`name-${index}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} width={style?.width}>
            {name}
        </AuthorNameWrap>
    )
}

interface AuthorSearchBoxProps {
    boxOn: boolean;
    from: string;
    author: any;
    searchResult: any[];
    idx: number;
    initFunc: Function;
    customStyle?: StyleProps;

    selectAuthorFunc: Function;
    deleteAuthorFunc: Function;
    setCoInfoFunc: Function;
}

const AuthorSearchBoxWrap = styled.div<StyleProps>`
    z-index: 999;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: ${({ width }) => (width ? width : "160px")};
    border: 1px solid ${colors.gray300};
    background-color: ${colors.white};
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    border-radius: 0px 0px 4px 4px;
`

const AuthorTagWrap = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
`

const AuthorTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${colors.blue300};
    color: ${colors.black};
    font-size: 12px;
`

const AuthorNameText = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin: 0;
    text-align: left;
    padding: 0;
    height: auto;
    line-height: 16px;
    max-width: 110px;
    word-break: break-all;
    white-space: break-spaces;
`

const SearchResultWrap = styled.ul<{ width?: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-width: 158px; */
    width: ${({ width }) => (width ? width : "158px")};
    max-height: 160px;
    min-height: 32px;
    overflow: auto;
    border-top: 1px solid ${colors.gray300};
    border-bottom: 1px solid ${colors.gray300};
`

const SearchResult = styled.li<{ noHover?: boolean, active?: number }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 32px;
    /* max-width: 158px; */
    width: 100%;
    background-color: ${({ active }) => active ? colors.blue50 : ""};
    :hover {
        cursor: ${({ noHover }) => noHover ? "" : "pointer"};
        background-color: ${({ noHover }) => noHover ? "" : colors.blue50};
    }
`

const AuthorNameWrap = styled.div<{ width?: string }>`
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    text-align: left;
    padding: 0 8px;
    height: 32px;
    width: ${({ width }) => width ? width : "120px"};
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const AuthorEnrollWrap= styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    height: 32px;
    background-color: ${colors.gray50};
`

const UnderLineText = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${colors.blue500};
    padding-bottom: 1px;
    border-bottom: 1px solid ${colors.blue500};
    cursor: pointer;
`

const IconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
`

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
}