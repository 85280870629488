
export default function useDownload() {

    const download = (url: string, fileName?: string, urlType?: string) => {
        let link = document.createElement("a");
        document.body.appendChild(link);
        if (fileName) {
            link.download = fileName;
        }
        link.href = urlType === "base64" ? url : `/file/${url}`;
        link.click();
        document.body.removeChild(link)
    }
    return download;
}
