import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { useAppDispatch } from "@/modules/store";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { ReactComponent as More } from "@/assets/icons/more.svg";

export default function SortableItem ({ item, idx }: any) {
    const dispatch = useAppDispatch();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: item.id });

    const deleteFile = (e: React.MouseEvent<HTMLElement>, idx: number) => {
        dispatch({ type: "ebookMaker/deleteInFile", payload: idx });
    }

    return (
        <Ul 
            ref={setNodeRef} 
            {...attributes} 
            transform={transform}
            transition={transition}
        >
            <MoreWrapper>
                <StyledMoreIcon width={18} height={18} fill={colors.gray500} {...listeners}/>
            </MoreWrapper>
            <OrderWrapper>{idx+1}</OrderWrapper>
            <NameWrapper>{item.name}</NameWrapper>
            <DeleteWrapper onClick={(e) => deleteFile(e, idx)}>
                <Clear width={24} height={24} fill={colors.gray600} />
            </DeleteWrapper>
        </Ul>
    );
};

const Ul = styled.ul<{ transform: any; transition: any; }>`
    transform: ${({ transform }) => CSS.Transform.toString(transform)};
    transition: ${({ transition }) => transition};
    user-select: none;
    box-sizing: border-box;
    
    border-top: 1px solid ${colors.gray300};
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
`

const Li = styled.li`
    display: flex;
    align-items: center;
    height: 100%;
`

const MoreWrapper = styled(Li)`
    width: 40px;
    justify-content: center;
`

const OrderWrapper = styled(Li)`
    width: 50px;
    padding: 8px;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
`

const NameWrapper = styled(Li)`
    width: 762px;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 400;
`

const DeleteWrapper = styled(Li)`
    padding: 8px;
    font-size: 12px;
`

const StyledMoreIcon = styled(More)`
    cursor: grab;
`