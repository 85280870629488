import styled from "styled-components";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import TableOptions from "@/components/molecules/sales-inquiry/author/TableOptions";
import SalesSummary from "@/components/molecules/sales-inquiry/author/SalesSummary";
import useExcelDownload from "@/modules/hooks/useExcelDownload";
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { addComma } from "@/modules/utils/filter";

export default function DailyTableTop() {
    const { items, total_amt, total_cnt } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorDaily,
    );
    const { platformNoQty } = useSelectorTyped((state: RootState) => state.inquiryAuthorCommon);
    const { setItemsPerPage } = usePagination({ from: "daily" });

    const downloadExcel = useExcelDownload();
    const { alert } = useAlert();

    const changeItemPerPage = (itemPerPage: number) => setItemsPerPage(itemPerPage);

    // 엑셀 다운로드
    const downloadDailyExcel = () => {
        if (items.length > 0) {
            let rows: any[] = [];
            items.forEach((data) => {
                let row = {
                    판매일자: data.date,
                    판매플랫폼: data.platform_name,
                    출판사: data.publisher_name,
                    작품명: data.name,
                    시리즈명: data.name,
                    저자명: data.author_name,
                    판매량: platformNoQty.find(p => p === data.platform_name) ? '정보없음' : data.quantity,
                    "판매금액(원)": addComma(data.amount),
                };
                rows.push(row);
            });
            downloadExcel({ json: rows, excelFileName: "(판매현황조회) 일 정산", sheetNames: "일별데이터" });
        } else {
            alert("info", "조회 완료", "조회된 데이터가 없습니다.");
        }
    };

    return (
        <TableTopContainer>
            <SalesSummary total={total_cnt} amountName={"판매금합계"} amount={total_amt} />
            <TableOptions onChangeItemPerPage={changeItemPerPage} onExcelDownload={downloadDailyExcel} />
        </TableTopContainer>
    );
}

const TableTopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
