import { RootState, useAppSelector } from "@/modules/store";
import { Copyright } from "@/types/dataType";
import styled from "styled-components";

export default function TemplatePreviewContainer() {
    const { contentList } = useAppSelector((state: RootState) => state.ebookMaker);
    
    return (
        <StyledContainer>
            <TopBarWrapper>미리보기</TopBarWrapper>
            <ContentWrapper>
                <PreviewWrapper>
                    {contentList.map((c: any, idx: number) => (
                        <RowWrapper
                            key={idx}
                            size={c.size}
                            align={c.align}
                            bold={c.bold}
                            italic={c.italic}
                            underline={c.underline}
                        >
                            {
                                c.isEmpty ? <br /> :
                                c.title ?
                                <>
                                    <TitleSpan>{c.title}</TitleSpan>
                                    <MarginSpan>|</MarginSpan>
                                </> 
                                    :
                                <></>
                            }
                            <StyledSpan
                                link={c.link !== Copyright.Link.NONE}
                            >{c.value}</StyledSpan>
                        </RowWrapper>
                    ))}
                </PreviewWrapper>
            </ContentWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 100%;
    min-height: 500px;
`

const TopBarWrapper = styled.div`
    height: 48px;
    padding: 16px;
    font-size: 14px;
    font-weight: 500;
`

const ContentWrapper = styled.div`
    height: 452px;
    background-color: ${({ theme }) => theme.colors.gray50};
    padding: 16px;
`

const PreviewWrapper = styled.div`
    background-color: ${({ theme }) =>  theme.colors.white};
    padding: 8px 16px;
    min-height: 420px; 
`

const RowWrapper = styled.div<StyledSpanProps>`
    line-height: 1.6;
    text-align: ${({ align }) => align};
    font-weight: ${({ bold, size }) => bold || size !== Copyright.Size.P ? "600" : "400"};
    font-style: ${({ italic }) => italic ? "italic" : ""};
    text-decoration: ${({ underline }) => underline ? "underline" : ""};
    font-size: ${({ size }) => {
        switch (size) {
            case Copyright.Size.P:
                return "10px";
            case Copyright.Size.H1:
                return "26px";
            case Copyright.Size.H2:
                return "21px";
            case Copyright.Size.H3:
                return "18px";
            case Copyright.Size.H4:
                return "15px";
            case Copyright.Size.H5:
                return "12px";
            case Copyright.Size.H6:
                return "10px";
        }
    }}
`

const StyledSpan = styled.span<StyledSpanProps>`
    color: ${({ link }) => link ? "blue" : ""};
`

const TitleSpan = styled.span`
    font-weight: 600;
`

const MarginSpan = styled.span`
    margin: 0 4px;
`

interface StyledSpanProps {
    size?: string;
    align?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    link?: boolean;
}
