import { useEffect } from "react";

// reducers
import { RootState, useAppDispatch } from "@/modules/store";
import { getMgData, setNoData } from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";

// components
import TemplateLayout from "@/components/molecules/sales-entry/common/TemplateLayout";
import MgSearchFilter from "@/components/molecules/sales-inquiry/publisher/mg/MgSearchFilter";
import MgTable from "@/components/molecules/sales-inquiry/publisher/mg/MgTable";
import MgTableTop from "@/components/molecules/sales-inquiry/publisher/mg/MgTableTop";

// custom hooks
import useMgApi from "./hooks/useMgApi";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function Mg() {
    const { selectedPlatform, initialDataLoaded, changedParamsId } = useSelectorTyped((state: RootState) => state.inquiryMg);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    useMgApi();

    useEffect(() => {
        getData();
    }, [changedParamsId]);

    const getData = async () => {
        try {
            if (initialDataLoaded && !selectedPlatform.length) {
                return dispatch(setNoData());
            }

            loadingOn();
            await dispatch(getMgData()).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    return (
        <TemplateLayout>
            <MgSearchFilter />
            <MgTableTop />
            <MgTable />
        </TemplateLayout>
    );
}
