import styled from "styled-components";
import Button from "@/components/atoms/button";
import { Step } from "@/pages/ebook-maker/types";
import { RootState, useAppSelector } from "@/modules/store";

export default function ButtonContainer({ stepHandler }: { stepHandler: (step: number, disabled: boolean) => void; }) {
    const { outFileType } = useAppSelector((state: RootState) => state.ebookMaker);

    const onlyConvert2Txt = outFileType.length === 1 && outFileType[0] === "txt";

    const clickNext = () => {
        if (onlyConvert2Txt) {
            stepHandler(Step.OnlyTxt, false);
        } else {
            stepHandler(Step.Copyright, false);
        }
    }

    return (
        <SyledContainer>
            <Button type="main" label="다음" onClickHandler={clickNext} />
        </SyledContainer>
    )
}

const SyledContainer = styled.div`
    display: flex;
    justify-content: right;
    margin-top: 24px;
`