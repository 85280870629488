import styled from "styled-components";
import SelectBox from "@/components/atoms/selectBox/index";
import { useEffect, useState } from "react";
import moment from "moment";
import useDidUpdateEffect from "@/modules/hooks/useDidUpdateEffect";

export default function DateSelector({ type, initialDate, index, onChangeHandler, reset, yearCustom, monCustom }:DateSelectorProps) {
    let [year, setYear] = useState("");
    let [month, setMonth] = useState("");
    let [day, setDay] = useState("");
    let [yearList, setYearList] = useState<{ value: string }[]>([]);
    let [monthList, setMonthList] = useState<{ value: string }[]>([]);
    let [dayList, setDayList] = useState<{ value: string }[]>([]);
    useEffect(()=> {
       setInitialDate();
       setDateList();
    }, [type, initialDate ])

    useDidUpdateEffect(() => {
        setInitialDate();
    }, [reset]);

    function setInitialDate() {
        if (initialDate) {
            setYear(moment(initialDate).format("YYYY"));
            setMonth(moment(initialDate).format("MM"));
            setDay(moment(initialDate).format("DD"))
        } else {
            getToday();
        }
    }

    function setDateList() {
        let tmpYearList = [], tmpMonthList = [];
        for ( let year:any = moment().subtract(10,'year').format('YYYY'); year <= (Number(moment().format('YYYY'))+1).toString(); year++ ) {
            tmpYearList.unshift({ value: `${year}` });
            // this.yearList.unshift(`${year}`);
        }
        setYearList(tmpYearList);
        for ( let month:any = 1; month <= 12; month++ ) {
            if (month < 10) {
                month = `0${month}`;
            }
            tmpMonthList.push({ value: `${month}` });
        }
        setMonthList(tmpMonthList);
        // 시작 시 연도월 현재기준
        if (type === "day") setDayListFunc();
    }

    function getToday() {
        const date = new Date();
        let year = String(date.getFullYear());
        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());

        if (date.getMonth() + 1 < 10) {
            month = String('0' + (date.getMonth() + 1));
        }
        if (date.getDate() < 10) {
            day = String('0' + date.getDate());
        }
    
        setYear(year);
        setMonth(month);
        setDay(day);

        if (!initialDate) {
            if (type === "day") {
                onChangeHandler(year+month+day, index);
            } else {
                onChangeHandler(year+month, index);
            }
        }
    }

    function setDayListFunc() {
        let lastDate = new Date(Number(year), Number(month), 0).getDate();
        let tmpdayList = [];
        for (let i = 1; i <= lastDate; i++) {
            if (i < 10) {
                tmpdayList.push({ value: `0${i}` });
            } else {
                tmpdayList.push({ value: `${i}` });
            }
        }
        setDayList(tmpdayList);
    }

    function changeDate(v: any, changeType?: string) {
        let value = v.value;
        switch (changeType) {
            case "month":
                if (type === "day") setDayListFunc();
                if (type === "day") {
                    onChangeHandler(year + value + day, index);
                } else {
                    onChangeHandler(year + value, index);
                }
                setMonth(value);
                break;
            case "day":
                setDayListFunc();
                onChangeHandler(year + month + value, index);
                setDay(value);
                break;
            default:
                setDayListFunc();
                if (type === "day") {
                    onChangeHandler(value + month + day, index);
                } else {
                    onChangeHandler(value + month, index);
                }
                setYear(value);
                break;
        }
    }

    return (
        <DateSelectorWrap>
            <SelectBox
                type="modal"
                modalOptions={{
                    id: `year${index}`,
                    index: index ? index : 0,
                    scrollModalId: "setRatioPeriodModalBody",
                    emptyHeight: 88,
                    halfRowEmpty: 14,
                }}
                labelKey="value"
                valueKey="value"
                selected={year}
                options={yearList}
                onClickValueFunc={(v: any) => changeDate(v, "year")}
                customStyle={{ width: yearCustom?.width ? yearCustom.width : "120px", height: yearCustom?.height ? yearCustom.height : "32px" }}
            />
            <Margin />
            <SelectBox
                type="modal"
                modalOptions={{
                    id: `month${index}`,
                    index: index ? index : 0,
                    scrollModalId: "setRatioPeriodModalBody",
                    emptyHeight: 88,
                    halfRowEmpty: 14,
                }}
                labelKey="value"
                valueKey="value"
                selected={month}
                options={monthList}
                onClickValueFunc={(v: any) => changeDate(v, "month")}
                customStyle={{ width: monCustom?.width ? monCustom.width : "80px", height: monCustom?.height ? monCustom.height : "32px" }}
            />
            {
                type === "day" &&
                <>
                    <Margin />
                    <SelectBox
                        type="modal"
                        modalOptions={{
                            id: `day${index}`,
                            index: index ? index : 0,
                            scrollModalId: "setRatioPeriodModalBody",
                            emptyHeight: 88,
                            halfRowEmpty: 14,
                        }}
                        labelKey="value"
                        valueKey="value"
                        selected={day}
                        options={dayList}
                        onClickValueFunc={(v: any) => changeDate(v, "day")}
                        customStyle={{ width: "80px", height: "32px" }}
                    />
                </>
            }
        </DateSelectorWrap>
    )

}

const DateSelectorWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

const Margin = styled.div`
    margin-right: 8px;
`

interface DateSelectorProps {
    type?: "day" | "month";
    initialDate?: string;
    index?: number;
    // onChangeHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    // onChangeHandler?: (event: string) => void;
    onChangeHandler: Function;
    reset?: boolean;
    yearCustom?: {
        width?: string;
        height?: string;
    };
    monCustom?: {
        width?: string;
        height?: string;
    };
}