import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMgAtcmp } from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";
import { getThisMonth } from "@/modules/utils/date";
import { addComma } from "@/modules/utils/filter";
import type { MgState } from "./types";

const name = "inquiry/author/mg";
const initialState: MgState = {
    fetchLoading: false,
    items: [],
    total_amt: 0,
    total_cnt: 0,

    initialDataLoaded: false,

    changedParamsId: 0,
    target: "",
    totalDate: getThisMonth() || "",
    mgType: "platform",
    selectedPlatform: [],
    selectedPublisher: [],
    complete: "all",
    contractType: "all",
    contractStartDate: getThisMonth() || "",
    contractEndDate: getThisMonth() || "",
    contractPrice: {
        start: 0,
        end: 0,
    },
    deductionPrice: {
        start: 0,
        end: 0,
    },
    remainPrice: {
        start: 0,
        end: 0,
    },

    // 데이터 에러
    contractStartDateError: "",
    contractEndDateError: "",

    sliderMaxValue: {
        contractPrice: 0,
        deductionPrice: 0,
        remainPrice: 0,
    },
    sliderMinValue: {
        contractPrice: 0,
        deductionPrice: 0,
        remainPrice: 0,
    },

    // 자동완성
    atcmpList: [],
};

// 선인세 소진현황 조회
export const getAuthorMgData = createAsyncThunk(`${name}/getAuthorMgData`, async (_, { rejectWithValue, getState, signal }) => {
    try {
        const { inquiryAuthorMg }: any = getState();
        const apiParams = {
            totalDate: inquiryAuthorMg.totalDate,
            selectedPlatform: inquiryAuthorMg.selectedPlatform,
            selectedPublisher: inquiryAuthorMg.selectedPublisher,
            contractStartDate: inquiryAuthorMg.contractStartDate,
            contractEndDate: inquiryAuthorMg.contractEndDate,
            mgType: inquiryAuthorMg.mgType,
            complete: inquiryAuthorMg.complete,
            contractType: inquiryAuthorMg.contractType,
            contractPrice: inquiryAuthorMg.contractPrice,
            deductionPrice: inquiryAuthorMg.deductionPrice,
            remainPrice: inquiryAuthorMg.remainPrice,
            target: inquiryAuthorMg.target,
        }
        return (await api.post(`/api/settlement/newSalesInquiry/mg/author/select`, apiParams, { signal })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

const mgSlice = createSlice({
    name,
    initialState,
    reducers: {
        setFilterOption: (state, action: PayloadAction<Partial<MgState>>) => {
            const {
                target,
                totalDate,
                mgType,
                selectedPlatform,
                selectedPublisher,
                contractStartDate,
                contractEndDate,
                complete,
                contractType,
                contractPrice,
                remainPrice,
                deductionPrice,
            } = action.payload;

            state.target = target ?? state.target;
            state.totalDate = totalDate ?? state.totalDate;
            state.mgType = mgType ?? state.mgType;
            state.selectedPlatform = selectedPlatform ?? state.selectedPlatform;
            state.selectedPublisher = selectedPublisher ?? state.selectedPublisher;
            state.contractStartDate = contractStartDate ?? state.contractStartDate;
            state.contractEndDate = contractEndDate ?? state.contractEndDate;
            state.complete = complete ?? state.complete;
            state.contractType = contractType ?? state.contractType;
            state.contractPrice = contractPrice ?? state.contractPrice;
            state.deductionPrice = deductionPrice ?? state.deductionPrice;
            state.remainPrice = remainPrice ?? state.remainPrice;
        },
        setError: (state, action) => {
            const { contractStartDateError, contractEndDateError } = action.payload;

            state.contractStartDateError = contractStartDateError ?? state.contractStartDateError;
            state.contractEndDateError = contractEndDateError ?? state.contractEndDateError;
        },
        setParamsId(state){
            state.changedParamsId += 1;
        },
        setNoData(state) {
            state.items = [];
            state.total_cnt = 0;
            state.total_amt = 0;
        },
        resetStore: () => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAuthorMgData.pending, (state) => {
                state.fetchLoading = true;
            })
            .addCase(getAuthorMgData.fulfilled, (state, action) => {
                state.fetchLoading = false;
                state.initialDataLoaded = true;

                const { result, total_cnt, total_amt, sliderMinValue, sliderMaxValue } = action.payload.data;

                for (let item of result) {
                    item.date = `${item.date.substr(0, 4)}-${item.date.substr(4, 2)}-${item.date.substr(6, 2)}`;
                    item.st_price = ((item.contractPrice * item.mg_ratio) / 100).toString();
                    item.contractPrice = addComma(item.contractPrice);
                    item.mg_type = item.mg_type === "platform" ? "플랫폼" : "출판사";
                    item.contract_type = item.contract_type === "MG01" ? "작가계약" : "작품계약";
                    item.className = "";
                }

                state.items = result;
                state.total_cnt = total_cnt;
                state.total_amt = Number(total_amt.toFixed(2));

                // 금액 필터 값 있을 때 기존 min, max값 유지
                if (
                    state.contractPrice.start ||
                    state.contractPrice.end ||
                    state.deductionPrice.start ||
                    state.deductionPrice.end ||
                    state.remainPrice.start ||
                    state.remainPrice.end
                )
                    return;

                state.sliderMaxValue = sliderMaxValue;
                state.sliderMinValue = sliderMinValue;
            });

        // 자동완성 - 전체
        builder.addCase(getMgAtcmp.fulfilled, (state, { payload }) => {
            state.atcmpList = payload.data;
        });
    },
});

export const { setFilterOption, setError, setParamsId, setNoData, resetStore } = mgSlice.actions;
export default mgSlice.reducer;
