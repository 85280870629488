import { useState, useEffect } from "react";
import BaseModal from "../BaseModal";
import AddLineModalBody from "./AddLineModalBody";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";

export default function AddLineModal({ show, close, onChange }: AddLineModalProps) {
    const [rowNum, setRowNum] = useState("");
    const [rowNumError, setRowNumError] = useState("");
    const { alert } = useAlert();

    const reset = () => setRowNum("");
    const apply = async () => {
        if (rowNumError) {
            return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
        }

        onChange(Number(rowNum));
        close();
    };

    useEffect(() => {
        reset();
    }, [show]);

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["행추가"]}
            customStyle={[{ width: "366px", height: "177px" }]}
            applyFunc={apply}
        >
            <AddLineModalBody
                rowNum={rowNum}
                setRowNum={setRowNum}
                rowNumError={rowNumError}
                setRowNumError={setRowNumError}
            />
        </BaseModal>
    );
}

interface AddLineModalProps extends BaseModalProps {
    onChange: (rowNum: number) => void;
}
