import styled from "styled-components";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { Rnd } from "react-rnd";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { ReactComponent as Letter } from "@/assets/icons/letter.svg";
import Text from "@/components/atoms/text";

export default function MgPreview() {
    const { publisherInfo, authorList, currAuthor, mgPayment, briefs, stampFiles, stampSize, stampPosition } = useAppSelector((state: RootState) => state.pubStatement);
    const [html, setHtml] = useState("<div className='nodata'>왼쪽 리스트에서 작가를 선택해주세요.</div>");
    const dispatch = useAppDispatch();

    useEffect(() => {
        render();
    }, [currAuthor, mgPayment, briefs]);

    const render = () => {
        const payment = Math.round(mgPayment.payment_amount * mgPayment.mg_ratio / 100 * mgPayment.co_ratio / 100);
        const taxIncome = Math.floor(payment * 0.03) > 1000 ? Math.floor(payment * 0.03) : 0;
        const taxLocal = Math.floor(taxIncome * 0.1);
        const expenseTotal = taxIncome + taxLocal;
        const total = payment - expenseTotal;

        let tmpHtml = "";

        // 기본 정보 및 판매 header 설정
        tmpHtml += `<div class="sp-viewer">
            <table><tr>
            <td class="sp-title" rowspan=3 colspan=4>선인세 지급 명세서</td>
            <td class="sp-header header-label border-bottom">공급자 상호</td>
            <td class="sp-header border-bottom">${ publisherInfo.publisher_name }</td>
            <td class="sp-header center header-label border-bottom">대표자</td>
            <td class="sp-header ceo-name right border-bottom"><div>&nbsp;${ publisherInfo.publisher_ceo_name }</div><div>(인)</div></td>
            </tr><tr>
            <td class="sp-header header-label border-bottom">사업자번호</td>
            <td class="sp-header border-bottom" colspan=4>${ publisherInfo.publisher_reg }</td>
            </tr><tr>
            <td class="sp-header header-label border-bottom">주소</td>
            <td class="sp-header border-bottom" colspan=4>${ publisherInfo.publisher_addr }</td>
            </tr><tr>
            <td class="sp-header header-label">지급일자</td>
            <td class="sp-header" colspan=3>${ `${mgPayment.out_date.substring(0,4)}.${mgPayment.out_date.substring(4,6)}.${mgPayment.out_date.substring(6,8)}` }</td>
            <td class="sp-header header-label border-bottom">전화</td>
            <td class="sp-header border-bottom" colspan=4>${ publisherInfo.publisher_tel }</td>
            </tr><tr>
            <td class="sp-header header-label">계약자명(필명)</td>`;
        if (currAuthor.user_name) {
            tmpHtml += `<td class="sp-header author-name" colspan=3>${ currAuthor.user_name }(${ currAuthor.author_name })</td>`;
        } else {
            tmpHtml += `<td class="sp-header author-name" colspan=3>${ currAuthor.author_name }</td>`;
        }
        tmpHtml += `<td class="sp-header header-label border-bottom">E-mail</td>
            <td class="sp-header border-bottom" colspan=4>${ publisherInfo.publisher_email }</td>
            </tr><tr>
            <td class="sp-blank" colspan=8></td>
            </tr>`;

        // 계약 정보
        tmpHtml += `<tr>
            <td class="sp-content header-label sp-border top-bold">계약 플랫폼</td>
            <td class="sp-content sp-border top-bold" colspan=7>${ mgPayment.platform_name }</td>
            </tr><tr>
            <td class="sp-content header-label">계약 시리즈</td>
            <td class="sp-content" colspan=7>${ mgPayment.series_name }</td>
            </tr><tr>
            <td class="sp-content header-label">계약 시작일</td>
            <td class="sp-content" colspan=7>${ `${mgPayment.date.substring(0,4)}.${mgPayment.date.substring(4,6)}.${mgPayment.date.substring(6,8)}` }</td>
            </tr>`;

        // 계약/지급금 
        tmpHtml += `<tr>
            <td class="sp-content sp-border top-bold" colspan=6>계약금</td>
            <td class="sp-content sp-border top-bold right" colspan=2>${ addComma(mgPayment.contract_amount) }</td>
            </tr><tr>
            <td class="sp-content" colspan=6>지급금</td>
            <td class="sp-content right" colspan=2>${ addComma(mgPayment.payment_amount) }</td>
            </tr><tr>
            <td class="sp-content" colspan=6>잔여 지급금</td>
            <td class="sp-content right" colspan=2>${ addComma(mgPayment.remain_amount) }</td>
            </tr>`;

        // 계약/지급금 subtotal
        tmpHtml += `<tr>
            <td class="sp-subtotal sp-border top-dotted" colspan=4>지급금 소계(A)</td>`;
        if (mgPayment.co_ratio !== 100) {
            tmpHtml += `<td class="sp-subtotal right sp-border top-dotted" colspan=3>${ `(정산비율: ${mgPayment.mg_ratio}% | 공저간 ${mgPayment.co_ratio}%)` }</td>`;
        } else {
            tmpHtml += `<td class="sp-subtotal right sp-border top-dotted" colspan=3>${ `(정산비율: ${mgPayment.mg_ratio}%)` }</td>`;
        }
        tmpHtml += `<td class="sp-subtotal right sp-border top-dotted">${ addComma(payment) }</td></tr>`;

        // 기타경비
        tmpHtml += `<tr>
            <td class="sp-content sp-border top-bold" colspan=7>저작권 사업소득 원천세(소득세)  3.0%</td>
            <td class="sp-content sp-border top-bold right">${ addComma(taxIncome) }</td>
            </tr>`;
        tmpHtml += `<tr>
            <td class="sp-content" colspan=7>저작권 사업소득 원천세(지방세)  0.3% (소득세의 10%)</td>
            <td class="sp-content right">${ addComma(taxLocal) }</td>
            </tr>`;

        // 기타경비 subtotal
        tmpHtml += `<tr>
            <td class="sp-subtotal sp-border top-dotted bottom-bold" colspan=6>경비 소계(B)</td>
            <td class="sp-subtotal right sp-border top-dotted bottom-bold" colspan=2>${ addComma(expenseTotal) }</td>
            </tr>`;

        // 정산금 total
        tmpHtml += `<tr>
            <td class="sp-total sp-border bottom-bold" colspan=6>정산 금액(A-B)</td>
            <td class="sp-total right sp-border bottom-bold" colspan=2>${ addComma(total) }</td>
            </tr>`;
        // 여백
        tmpHtml += `<tr>
            <td class="sp-blank" colspan=8></td>
            </tr>`;


        if (briefs.length) {
            // 적요 header
            tmpHtml += `<tr>
                <td class="sp-briefs briefs-label sp-border top-bold bottom-dotted" colspan=8>적요</td>
                </tr>`;

            // 적요
            for (let [idx, b] of briefs.entries()) {
                if (idx === briefs.length-1) {
                    tmpHtml += `<tr>
                        <td class="sp-briefs sp-border bottom-bold" colspan=8>${ idx+1 }.&nbsp;${ b }</td>
                        </tr>`;
                } else {
                    tmpHtml += `<tr>
                        <td class="sp-briefs sp-border bottom-dotted" colspan=8>${ idx+1 }.&nbsp;${ b }</td>
                        </tr>`;
                }
            }
        } 
        tmpHtml += `</table></div>`;

        setHtml(tmpHtml);
    }

    const deleteStamp = () => {
        dispatch({ type: "pubStatement/setStampFiles", payload: [] });
    }

    const onDragStop = (x: number, y: number) => {
        dispatch({ type: "pubStatement/setStampPosition", payload: { x, y }});
    }

    const onResizeStop = (width: number, height: number, position: any) => {
        dispatch({ type: "pubStatement/setStampSize", payload: { width, height }});
        dispatch({ type: "pubStatement/setStampPosition", payload: { x: position.x, y: position.y }});
    }

    const rndStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `dashed 2px ${colors.gray400}`,
        zIndex: "1"
    } as const;

    return (
        <PreviewContainer>
            {authorList.length ? 
                <PreviewWrapper>
                    <PdfWrapper>
                        {Boolean(stampFiles.length) &&
                            <Rnd
                                style={rndStyle}
                                size={{ width: stampSize.width, height: stampSize.height }}
                                position={{ x: stampPosition.x, y: stampPosition.y }}
                                bounds="parent"
                                onDragStop={(e, d) => onDragStop(d.x, d.y)}
                                onMouseDown={e => e.preventDefault()}
                                onResizeStop={(e, d, ref, delta, position) => onResizeStop(ref.offsetWidth, ref.offsetHeight, position)}
                            >
                                <ClearWrapper onClick={deleteStamp}>
                                    <Clear width={20} height={20} fill={colors.gray700} />
                                </ClearWrapper>
                                <StyledImg src={stampFiles[0].file} alt="stamp" />
                            </Rnd> 
                        }
                        <div id="spViewer" dangerouslySetInnerHTML={ { __html: html }}></div> :
                    </PdfWrapper>
                </PreviewWrapper> :
                <NoPreviewWrapper>
                    <Letter />
                    <Text text="발급가능한 정산서가 없습니다." customStyle={{ fontSize: "28px", fontWeight: "700", margin: "54px 0 0 0" }} />
                    <Text text="불러올 수 있는 데이터가 없습니다. 다시 한 번 확인해주세요." customStyle={{ fontSize: "16px", fontWeight: "400", margin: "24px 0 0 0", color: colors.gray500 }} />
                </NoPreviewWrapper>
            }
        </PreviewContainer>
    )
}

const addComma = (str: number | string) => {
    if (str) {
        const regexp = /\B(?=(\d{3})+(?!\d))/g;
        return str.toString().replace(regexp, ',');
    } else {
        return "0";
    }
}

const PreviewContainer = styled.div`
    width: 960px;
    height: 1080px;
    margin-top: 14px;
`

const PreviewWrapper = styled.div`
    background-color: ${colors.gray300};
    border: 1px solid #EEE;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    padding: 15px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 12px;
    }

    #spViewer {
        transform-origin: 0 0;
        transform: scale(1.15);
        display: inline-block;
        position: relative;
        background-color: transparent;
        width: 210mm;
        height: 297mm;
        page-break-after: always;
        &:last-child {
            page-break-after: avoid;
        }

        .sp-viewer {
            padding: 15mm;
            width: 210mm;
            height: 295mm;
            box-shadow: 5px 5px 28px -4px rgba(0,0,0,0.5);
            margin-bottom: 30px;
            background-color: #FFF;
            
            &:last-child {
                margin-bottom: 0;
            }
        
            table {
                width: 180mm;
                border-collapse: separate;
        
                td {
                    vertical-align: middle;
                    white-space: nowrap;
                }
                .sp-blank {
                    height: 5.29mm;
                }
                .sp-title {
                    text-align: left;
                    font-weight: 600;
                    font-size: 6.61mm;
                }
                .sp-header {
                    font-size: 2.65mm;
                    padding-top: 1.32mm;
                    height: 5.82mm;
        
                    &.header-label {
                        font-weight: 600;
                    }
                    &.right {
                        text-align: right;
                    }
                    &.center {
                        text-align: center;
                    }
                    &.border-bottom {
                        border-bottom: 1px solid #999;
                    }
                    &.ceo-name {
                        display: flex;
                        justify-content: space-between;
                    }
                    &.author-name {
                        max-width: 200px;
                        padding-right: 10px;
                        @include ellipsis(1);
                    }
                }
                .sp-content {
                    font-size: 2.65mm;
                    padding: 0 1.32mm;
                    height: 6.61mm;
                    
                    &>.series-name {
                        display: inline-block;
                        width: 29mm;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    &.content-label {
                        padding: 0.26mm 0;
                        font-weight: 600;
                        height: 8.73mm;
                    }
                    &.right {
                        text-align: right;
                    }
                    &.center {
                        text-align: center;
                    }
                    &.header-label {
                        font-weight: 600;
                    }
                }
                .sp-subtitle {
                    font-size: 2.91mm;
                    font-weight: 600;
                    padding: 0 1.32mm;
                    height: 7.40mm;
                }
                .sp-subtotal {
                    font-size: 2.91mm;
                    font-weight: 600;
                    padding: 0 1.32mm;
                    height: 7.40mm;
                    background-color: #F2F2F2;
        
                    &.right {
                        text-align: right;
                    }
                }
                .sp-total {
                    font-size: 3.18mm;
                    font-weight: 600;
                    background-color: #CDCDCD;
                    padding: 0 1.32mm;
                    height: 7.94mm;
                    
                    &.right {
                        text-align: right;
                    }
                }
                .sp-briefs {
                    font-size: 2.65mm;
                    padding: 0.53mm 1.32mm;
                    height: 5.82mm;
        
                    &.briefs-label {
                        font-weight: 600;
                    }
                }
                .sp-border {
                    &.top {
                        border-top: 1px solid #333;
                    }
                    &.bottom {
                        border-bottom: 1px solid #333;
                    }
                    &.top-bold {
                        border-top: 2px solid #333;
                    }
                    &.bottom-bold {
                        border-bottom: 2px solid #333;
                    }
                    &.top-dotted {
                        border-top: 1px dotted #333;
                    }
                    &.bottom-dotted {
                        border-bottom: 1px dotted #333;
                    }
                }
            }
        }
        .stamp-resizer {
            border: none;
    
            &.active {
                outline: 1px dashed #333;
            }
            .stamp {
                width: 100%;
                height: 100%;
            }
        }
    }
`

const StyledImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`

const ClearWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -18px;
    right: -18px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${colors.gray500};
    cursor: pointer;
`

const NoPreviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${colors.gray50};
`

const PdfWrapper = styled.div``
