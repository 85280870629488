import { useEffect } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import SetRatioPeriodModalBody from "@/components/molecules/common/modal/bodyModal/SetRatioPeriodModalBody";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";
import { seriesActions } from "@/pages/settlement/basedata-manage/series/seriesSlice";

export default function SetRatioPeriodModal({ show, close, from }:SetRatioPeriodProps) {
    let [currPeriod, currIdx] = useAppSelector((state:RootState) => [
        state.series.currPeriod,
        state.series.currIdx,
    ]);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    useEffect(() => {
        return() => {
        }
    }, []);

    async function setRatioPeriod() {
        // 기간 등록 전 최종 체크
        periodValidate();
        let hasError = false;
        for (let p of currPeriod) {
            let index = currPeriod.indexOf(p);
            if (!p.ratio) {
                dispatch(seriesActions.setSeriesDatas({
                    key: "currPeriod", value: "정산비율을 입력해주세요.", objectKey: "ratioError", index: index
                }))
                hasError = true;
            }
            if (p.ratioError || p.endYearmonError) {
                hasError = true;
            }
        }
        if (hasError) return await alert("warning", "주의", "조건을 확인해주세요.");

        if (typeof currIdx !== "undefined") {
            dispatch(seriesActions.setRatios({
                key: "ratio", index: currIdx, value: ""
            }));
            dispatch(seriesActions.setRatios({
                key: "ratioError", index: currIdx, value: ""
            }));
            dispatch(seriesActions.setRatios({
                key: "period", index: currIdx, value: currPeriod
            }));
        } else {
            // 전체 기간설정 하는 경우
            dispatch(seriesActions.setPeriod({
                key: "ratio", value: ""
            }))
            dispatch(seriesActions.setPeriod({
                key: "ratioError", value: ""
            }))
            dispatch(seriesActions.setPeriod({
                key: "period", value: currPeriod
            }))
        }
        close();
        // this.$emit("applyPeriod", this.period);
        // close();
    }

    function periodValidate() {
        for (let p of currPeriod) {
            const currIdx = currPeriod.indexOf(p);
            const nextPeriod = currPeriod[currIdx+1];
            let hasError = false;

            // 현재 종료월이 시작월 이후인지 확인
            if (p.startYearmon && Number(p.endYearmon) < Number(p.startYearmon)) {
                dispatch(seriesActions.setSeriesDatas({
                    key: "currPeriod", value: "시작월 이후의 월을 선택해주세요.", index: currIdx, objectKey: "endYearmonError"
                }))
                hasError = true;
            }

            // 현재 종료월이 다음기간 종료월 이전인지 확인
            if (nextPeriod && nextPeriod.endYearmon && Number(p.endYearmon) >= Number(nextPeriod.endYearmon)) {
                dispatch(seriesActions.setSeriesDatas({
                    key: "currPeriod", value: "다음 종료월 이전의 월을 선택해주세요.", index: currIdx, objectKey: "endYearmonError"
                }))
                // p.nuw = true;
                hasError = true;
            }

            if (!hasError) {
                dispatch(seriesActions.setSeriesDatas({
                    key: "currPeriod", value: "", index: currIdx, objectKey: "endYearmonError"
                }))
            }
        }
    }

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["정산비율 기간별 설정"]}
                customStyle={[{ width: "960px", height: "408px" }]}
                applyFunc={setRatioPeriod}
            >
                <SetRatioPeriodModalBody
                    from={from}
                    close={close}
                />
            </BaseModal>
        </>
    );
}

interface SetRatioPeriodProps extends BaseModalProps {
}