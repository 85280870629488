import styled from "styled-components";

export default function FilterContainer({ children }: FilterContainerProps) {
    return <SearchFilterWrapper>{children}</SearchFilterWrapper>;
}

const SearchFilterWrapper = styled.div`
    width: 1200px;
    border-radius: 8px;
    box-shadow: 0px 6px 12px rgb(112 144 176 / 20%);
    margin-top: 24px;
    margin-bottom: 24px;
`;

interface FilterContainerProps {
    children: React.ReactElement | React.ReactElement[];
}
