import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { useAppDispatch } from "@/modules/store";
import {
    getSeriesAll,
    getSeriesByAuthor,
    getSeriesForMg,
    getSeriesForPortfolio,
} from "@/pages/common/search/searchSeries";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SeriesEnrollModal from "@/components/molecules/common/modal/SeriesEnrollModal";
import SimpleSearchBar from "../../searchBar/simpleSearchBar/SimpleSearchBar";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import NoSearchResult from "@/components/molecules/basedata-manage/NoSearchResult";
import Checkbox from "@/components/atoms/input/Checkbox";
import Text from "@/components/atoms/text";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { CommonType } from "@/types/dataType";

export default function SearchSeriesBody({
    from,
    platformId,
    authorId,
    multiSelect,
    withRatio,
    stYearmon,
    selectedSeries,
    setSelectedSeries,
}: SearchSeriesBodyProps) {
    const dispatch = useAppDispatch();
    const [beforeSearch, setBeforeSearch] = useState(true);
    const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
    const [currList, setCurrList] = useState<SearchResult[]>([]);
    const [showModal, setShowModal] = useState(false);
    const { page, itemsPerPage, pageCount, totalVisible, setDataCount, setPage } = usePagination({from: "searchSeries", initialValue: {
        itemsPerPage: 5,
        totalVisible: 6,
        page: 1,
    }});

    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    const key = from === "mgSeries" ? "series_id" : "id";
    const headers = withRatio
        ? [
              { text: "", value: "", width: "48px" },
              { text: "시리즈명", value: "series_name", width: "252px" },
              { text: "저자명", value: "author_name", width: "160px" },
              { text: "정산비율(%)", value: "quantity", width: "100px" },
          ]
        : [
              { text: "", value: "", width: "48px" },
              { text: "시리즈명", value: "series_name", width: "312px" },
              { text: "저자명", value: "author_name", width: "200px" },
          ];

    useEffect(() => {
        if (from === "mgSeries") getMgSeries();
    }, [authorId]);

    useEffect(() => {
        const tmpList = searchResult.slice().splice((page - 1) * itemsPerPage, itemsPerPage);
        setCurrList(tmpList);
    }, [page, searchResult]);

    // 체크박스 선택
    const selectAuthors = (item: { [key: string]: any }) => {
        if (multiSelect) {
            const hasValue = selectedSeries.findIndex((s) => s[key] === item[key]);
            if (hasValue < 0) {
                setSelectedSeries((prev) => [...prev, item]);
            } else {
                setSelectedSeries((prev) => prev.filter((p) => p[key] !== item[key]));
            }
            return;
        }
        isSelected(item[key]) ? setSelectedSeries([]) : setSelectedSeries([item]);
    };

    const isSelected = (id: number) => (selectedSeries.findIndex((s) => s[key] === id) < 0 ? false : true);

    // 검색
    const search = async (keyword: string) => {
        if (!keyword) {
            return alert("warning", "주의", "검색어를 입력해주세요.");
        }
        try {
            loadingOn();
            let res: any;
            if (from === "portfolio") {
                const apiParams = {
                    keyword,
                };
                res = await dispatch(getSeriesForPortfolio(apiParams)).unwrap();
            } else {
                if (authorId) {
                    const apiParams = {
                        keyword,
                        author_id: authorId,
                    };
                    res = await dispatch(getSeriesByAuthor(apiParams)).unwrap();
                } else {
                    const apiParams = {
                        keyword,
                        platform_id: platformId || 0,
                        stYearmon: stYearmon || "",
                    };
                    res = await dispatch(getSeriesAll(apiParams)).unwrap();
                }
            }
            setBeforeSearch(false);
            setSearchResult(res.data);
            setSelectedSeries([]);
            setDataCount(res.data.length);
            setPage(1);
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    // 선인세 작품계약
    const getMgSeries = async () => {
        try {
            if (!(authorId && platformId)) return;
            loadingOn();
            const apiParams = {
                author_id: authorId,
                platform_id: platformId,
            };
            const res = await dispatch(getSeriesForMg(apiParams)).unwrap();
            setBeforeSearch(false);
            setSearchResult(res.data);
            setSelectedSeries([]);
            setDataCount(res.data.length);
            setPage(1);
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    const handleModal = () => setShowModal((prev) => !prev);

    const renderTableBody = () => {
        if (beforeSearch) return <TBody backGroundColor /> // 검색 전
        if (!currList.length) return <NoSearchResultView /> // 검색 결과 없음
        return (
            <TBody>
            {(currList.map((r) => (
                <Tr key={r[key]} active={isSelected(r[key])} onClick={() => selectAuthors(r)}>
                    <Td style={{ width: headers[0].width }}>
                        <Checkbox
                            type="boolean"
                            active={isSelected(r[key])}
                            onChangeHandler={() => selectAuthors(r)}
                        />
                    </Td>
                    <Td style={{ width: headers[1].width }}>
                        <Text
                            text={r.series_name}
                            customStyle={{ fontSize: "12px", fontWeight: "400" }}
                        />
                    </Td>
                    <Td style={{ width: headers[2].width }}>
                        <Text
                            text={r.author_name}
                            customStyle={{ fontSize: "12px", fontWeight: "400" }}
                        />
                    </Td>
                    {/* 정산비율 칸 */}
                    {withRatio && (
                        <Td style={{ width: headers[3].width }}>
                            {r.ratio || (
                                <Text
                                    text="미적용"
                                    customStyle={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: colors.gray600,
                                    }}
                                />
                            )}
                        </Td>
                    )}
                </Tr>
            )))}
            </TBody>
        )
    }

    return (
        <>
            <BodyWrap>
                <SimpleSearchBar
                    placeholder="검색어를 입력해주세요."
                    onSelectKeyword={search}
                    customStyle={{ width: "560px", height: "40px" }}
                />
                <TableContainer>
                    <Theader>
                        <Tr>
                            {headers.map((h, idx) => (
                                <Th key={idx} width={h?.width}>
                                    {h.text}
                                </Th>
                            ))}
                        </Tr>
                    </Theader>
                    { renderTableBody() }
                </TableContainer>
                {!beforeSearch && Boolean(currList.length) && (
                    <Pagination
                        pageCount={pageCount}
                        totalVisible={totalVisible}
                        customStyle={{ margin: "0" }}
                        from="searchSeries"
                    />)}
                <GuideWrap>
                    <Text
                        text="찾으시는 시리즈가 없으신가요?"
                        customStyle={{ fontSize: "12px", fontWeight: "400", padding: "2px" }}
                    />
                    <UnderLineText onClick={handleModal}>신규 시리즈 등록</UnderLineText>
                </GuideWrap>
            </BodyWrap>
            <ModalPortal>
                <SeriesEnrollModal from={from} show={showModal} close={handleModal} />
            </ModalPortal>
        </>
    );
}

function NoSearchResultView() {
    return (
        <TBody>
            <Tr height="280px">
                <Td padding="0">
                    <NoSearchResult customStyle={{ height: "280px", width: "600px" }} />
                </Td>
            </Tr>
        </TBody>
    );
}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
`;
const GuideWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 32px;
`;
const UnderLineText = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: #00bcd4;
    margin: 0 0 0 4px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue500};
    padding: 2px 0;
    cursor: pointer;
`;
const TableContainer = styled.table`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 240px;
`;
const Theader = styled.thead`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 20px;
`;
const Tr = styled.tr<{ height?: string; active?: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => (height ? height : "48px")};
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${({ active, theme }) => active ? theme.colors.blue50 : theme.colors.white};
`;
const Th = styled.th<{ width?: string; height?: string; padding?: string; margin?: string }>`
    display: flex;
    align-items: center;
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "100%")};
    padding: ${({ padding }) => (padding ? padding : "10px")};
    margin: ${({ margin }) => (margin ? margin : "")};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`;
const TBody = styled.tbody<{ backGroundColor?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 280px;
    padding: 0 20px;
    background-color: ${({ theme, backGroundColor }) => (backGroundColor ? theme.colors.gray50 : "")};
`;
const Td = styled.td<{ padding?: string }>`
    display: flex;
    align-items: center;
    height: 100%;
    padding: ${({ padding }) => (padding ? padding : "10px")};
`;

export interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
}
interface SearchSeriesBodyProps {
    from: string;
    platformId?: number;
    authorId?: number;
    multiSelect?: boolean;
    withRatio?: boolean;
    stYearmon?: string;
    selectedSeries: any[];
    setSelectedSeries: Dispatch<SetStateAction<any[]>>;
}
interface SearchResult {
    id: CommonType.Id;
    series_id: CommonType.Id;
    series_name: CommonType.Series.Name;
    author_name: CommonType.Author.Name;
    ratio: CommonType.Ratio;
}
