import { CommonType, EbookMaker, Copyright } from "@/types/dataType";

export interface EbookMakerState {
    // Step
    currStep: number;
    completeStepIdx: number;

    // 1. 제작 형태 선택
    makeType: MakeType;
    inFileType: InFileType;
    outFileType: OutFileType[];
    bookInfo: BookInfoType;
    inFiles: InFile[];
    addEnter: boolean;

    // 판권지
    copyrightType: EbookMaker.CopyrightType;
    selectedTemplateId: number;
    contentList: CopyrightContent[]
    templateList: Template[];
    copyrightHTML: string;
    copyrightJSON: any[];

    // 2. 제작방식 선택
    subtitleType: EbookMaker.SubtitleType | "";
    subtitleMakeType: EbookMaker.SubtitleMakeType | "";
    subtitleSize: Copyright.Size | "";
    subtitleItalic: Copyright.Italic;
    subtitleUnderline: Copyright.Underline;
    subtitleAlign: Copyright.Align | "";
    coverFile: CoverFile[];
    styleFile: CoverFile[];
    subtitleList: { order: number; subtitle: string }[];
    noCoverImg: boolean;
    ignoreStyle: boolean;
    totalSectionCnt: number;
    tmpFolderName: string;

    // 3. 제작 완료
    resultFileList: string[];
    time: number;
}

export interface BookInfoType {
    series_name: CommonType.Series.Name;
    author_name: CommonType.Author.Name;
    publisher_name: CommonType.Publisher.Name;
    isbn: CommonType.Isbn;
    published_at: CommonType.Date;
    [key: string]: string;
}

type MakeType = EbookMaker.MakeType;
export type OutFileType = EbookMaker.OutfileType;
type InFileType = EbookMaker.InfileType;


export interface InFile {
    id: number;
    file: string;
    name: string;
}

export interface CoverFile {
    file: string;
    name: string;
}

export enum Step {
    Form,
    Copyright,
    Method,
    Complete,
    OnlyTxt
}

export enum CopyrightContentType {
    Title,
    Text,
    Empty
}

export interface CopyrightContent {
    id: number;
    order: number;
    isEmpty?: boolean;
    title: string;
    value: Copyright.Content;
    size: Copyright.Size;
    fontSize: string;
    align: Copyright.Align;
    bold: Copyright.Bold;
    italic: Copyright.Italic;
    underline: Copyright.Underline;
    link: Copyright.Link;
    [key: string]: string | number | boolean | undefined;
}

interface Template {
    template_id: CommonType.Id;
    template_name: Copyright.TemplateName;
    created_at: string;
    contentList: CopyrightContent[]
}
