import styled from "styled-components";
import FileListTable from "@/components/molecules/ebook-maker/complete/FileListTable";
import ButtonContainer from "@/components/molecules/ebook-maker/complete/ButtonContainer";
import TimerContainer from "@/components/molecules/ebook-maker/complete/TimerContainer";

export default function Complete({ time, stepHandler }: CompleteProps) {
    return (
        <CompleteContainer>
            <TimerContainer time={time} />
            <FileListTable />
            {time > 0 ?
                <></> : 
                <MessageContainer>
                    다운로드 기한이 만료되었습니다
                </MessageContainer>
            }
            <ButtonContainer stepHandler={stepHandler} />
        </CompleteContainer>
    )
}

const CompleteContainer = styled.div`
    width: 1200px;
    margin-top: 24px;
`

const MessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-top: 24px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.error};
`

interface CompleteProps {
    time: number;
    stepHandler: (step: number, disabled: boolean) => void;
}
