import styled from "styled-components";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch } from "@/modules/store";
import { ReactComponent as ClearMark } from "@/assets/icons/clear.svg";
import theme from "@/assets/styles/theme";
import { useEffect, useState } from "react";
import Text from "@/components/atoms/text";
import { fileAction } from "@/pages/common/fileSlice";

function ExcelUpload({ file, setFileAction, customStyle }:ExcelUploadProp) {
    const dispatch = useAppDispatch();
    const [ active, setActive ] = useState(false);
    const [ zIndex, setZIndex ] = useState(-1);
    useEffect(() =>{
        return() => {
            dispatch(fileAction.setFile({ name: "", file: "" }));
        }
    }, [])
    function base64Encode(file:File) {
        return new Promise((resolve) => {
            let a = new FileReader();
            a.onload = (e:ProgressEvent<FileReader>) => resolve(e.target?.result);
            a.readAsDataURL(file);
        });
    }

    async function inputFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files?.length) {
            let tmpF = e.target.files[0];
            const f = String(await base64Encode(tmpF));
            dispatch(setFileAction({
                file: f,
                fileName: tmpF.name,
            }))
        } else {
            dispatch(setFileAction({
                file: "",
                fileName: "",
            }))
        }
    }

    function deleteFile() {
        dispatch(setFileAction({
            file: "",
            fileName: "",
        }))
    }

    function onDragover(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        setActive(true);
        setZIndex(9999);
        e.dataTransfer.dropEffect = "move";
    }
    function onDragleave(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        setZIndex(9999);
        setActive(false);
    }
    async function onDrop(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        let tmpF = e.dataTransfer.files[0];
        const f = String(await base64Encode(e.dataTransfer.files[0]));
        dispatch(setFileAction({
            file: f,
            fileName: tmpF?.name,
        }))
        setZIndex(-1);
        setActive(false);
    }

    function onDragover2(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "none";
        setZIndex(9999);
    }
    function onDragleave2(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        setZIndex(-1);
    }

    return (
        <>
            {file.fileName
            ? 
            <AfterBoxWrap>
                <AfterBoxText>파일 업로드</AfterBoxText>
                <FileWrap>
                    <Text text={file.fileName} customStyle={{ fontSize: "14px", fontWeight: "400" }} />
                    <ClearMark width={24} height={24} fill={theme.colors.gray600} onClick={deleteFile} />
                </FileWrap>
            </AfterBoxWrap>
            :
            <ExcelUploadBoxWrap>
                <ExcelPrevent
                    zIndex={zIndex}
                    onDragOver={(e) => onDragover2(e)}
                    onDragLeave={(e) => onDragleave2(e)}
                />
                <BoxWrap
                    onDrop={(e) => onDrop(e)}
                    onDragOver={(e) => onDragover(e)}
                    onDragLeave={(e) => onDragleave(e)}
                    draggable={true}
                    active={active}
                    width={customStyle?.width}
                    height={customStyle?.height}
                >
                    <BoxText
                        active={active}
                        width={customStyle?.width}
                        height={customStyle?.height}
                    >
                        파일을 끌어다 놓거나&nbsp;
                        <label htmlFor="Aaaa">
                            <input
                                type="file"
                                id="Aaaa"
                                style={{ display: "none"}}
                                onChange={(e)=> inputFile(e)}
                            ></input>
                            <Text text="업로드" customStyle={{ fontSize: "14px", color: "#0077FF" }}/>
                        </label>
                        를 누르세요
                    </BoxText>
                </BoxWrap>
            </ExcelUploadBoxWrap>
            }
        </>
    )

}

export default ExcelUpload;

const ExcelUploadBoxWrap = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    // background-color: ${({ theme }) => theme.colors.gray50};
    // background-color: ${({ active, theme }) => active ? theme.colors.blue50 : theme.colors.gray50};
`

const ExcelPrevent = styled.div<{ zIndex?: number }>`
    position: fixed;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    z-index: ${({ zIndex }) => zIndex ? zIndex : ""};
`

const BoxWrap = styled.div<{ active?: boolean, width?: string; height?: string; }>`
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ height }) => height ? height : "120px"};
    width: ${({ width }) => width ? width : "440px"};
    background-color: ${({ active, theme }) => active ? theme.colors.blue50 : theme.colors.gray50};
    position: absolute;
`

const BoxText = styled.div<{ active?: boolean, width?: string; height?: string; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed ${({ active, theme }) => active ? theme.colors.blue500 : theme.colors.gray300};
    height: ${({ height }) => height ? (Number(height.replace(/[^0-9]/g, ""))-20)+"px" : "100px"};
    width: ${({ width }) => width ? (Number(width.replace(/[^0-9]/g, ""))-20)+"px" : "420px"};
    font-size: 14px;
    color: ${({ active, theme }) => active ? theme.colors.black : theme.colors.gray600};
`

const AfterBoxWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 108px;
`

const AfterBoxText = styled.div`
    display: flex;
    flex-dirextion: row;
    align-items: center;
    padding: 0px 16px;
    height: 48px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`

const FileWrap = styled.div`
    display: flex;
    flex-dirextion: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    height: 60px;
    width: 100%;
`

interface StyleProps {
    width?: string;
    height?: string;
}

interface ExcelUploadProp {
    file: SetFile;
    setFileAction: ActionCreatorWithPayload<SetFile, string>;
    customStyle?: StyleProps;
}

interface SetFile {
    file: string;
    fileName: string;
}