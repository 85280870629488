import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserState } from "./types";
import api from "@/api";
import io from "socket.io-client";

const serverUrl = process.env.NODE_ENV === "production" ? "https://api.gling.co.kr" : "http://localhost:4000";

const socket = io(
    serverUrl, 
    {
        transports: ["websocket"]
    }
);

const initialUserState: UserState = {
    isLogin: false,
    isPublisher: false,
    sociallogin_provider: "",
    user_created_at: "",
    user_email: "",
    mailing_address: "",
    user_nickname: "",
    user_pic_path: "",
    user_status: "",
    user_id: 0,
    user_emailagree: 0,
    user_mobile: "",
};

export const updateUserInfo = createAsyncThunk("user/userInfo/update", async(apiParams: any, { rejectWithValue }) => {
    const apiData = {
        userInfo: {
            user_id: apiParams.user_id
        },
        user_nickname: apiParams.user_nickname,
        mailing_address: apiParams.mailing_address,
        user_mobile: apiParams.user_mobile,
        user_emailagree: apiParams.user_emailagree,
        profile_changed: apiParams.profile_changed,
        profile_img: apiParams.profile_img,
        kcp_di: apiParams.kcp_di
    }
    try {
        const res = await api.post(`/api/user/userInfo/update`, apiData); 
        const data = {
            res,
            apiParams
        }
        return data;
    } catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});

export const checkToken = createAsyncThunk("user/check/token", async (data, { rejectWithValue }) => {
    try {
        return (await api.get(`/api/user/check/token`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const logout = createAsyncThunk("user/logout", async (data, { rejectWithValue }) => {
    try {
        // 로그아웃 시 deleteUserRoom
        socket.emit("logout");
        return (await api.post(`/api/user/logout`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

export const convertImg = createAsyncThunk("user/convertImg", async (imgPath:string, { rejectWithValue }) => {
    try {
        return (await api.get(`/file/download${imgPath}`, { responseType: 'blob'})).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        setUserInfo(state, { payload }) {
            state.isPublisher = payload.isPublisher;
            state.sociallogin_provider = payload.sociallogin_provider;
            state.user_created_at = payload.user_created_at;
            state.user_email = payload.user_email;
            state.mailing_address = payload.mailing_address;
            state.user_nickname = payload.user_nickname;
            state.user_pic_path = payload.user_pic_path;
            state.user_status = payload.user_status;
            state.user_id = payload.user_id;
            state.user_mobile = payload.user_mobile;
            state.user_emailagree = payload.user_emailagree;
        },
        deleteUserInfo(state) {
            state.isLogin = false;
            state.isPublisher = false;
            state.sociallogin_provider = "";
            state.user_created_at = "";
            state.user_email = "";
            state.user_nickname = "";
            state.user_pic_path = "";
            state.user_status = "";
            state.user_id = 0;
            state.user_mobile = "";
            state.user_emailagree = 0;
        },
        signIn(state) {
            state.isLogin = true;
        },
    },
    extraReducers: {
        // 토큰 유효성 검사
        [checkToken.fulfilled.type]: (state, action) => {
            if (action.payload.status === "fail") {
                state.isLogin = false;
                state.isPublisher = false;
                state.sociallogin_provider = "";
                state.user_created_at = "";
                state.user_email = "";
                state.user_nickname = "";
                state.user_pic_path = "";
                state.user_status = "";
                state.user_emailagree = 0;
                state.user_id = 0;
                state.user_mobile = "";
            } 
        },
        [checkToken.rejected.type]: (state, action) => {
            console.error("오류 발생(토큰만료) :", action.payload.message);
        },

        // 로그아웃
        [logout.fulfilled.type]: (state, action) => {
            state.isLogin = false;
            state.isPublisher = false;
            state.sociallogin_provider = "";
            state.user_created_at = "";
            state.user_email = "";
            state.user_nickname = "";
            state.user_pic_path = "";
            state.user_status = "";
            state.user_emailagree = 0;
            state.user_id = 0;
            state.user_mobile = "";
        },
        [logout.rejected.type]: (state, action) => {
            console.error("오류 발생 :", action.payload.message);
        },

        //유저 정보 업데이트
        [updateUserInfo.fulfilled.type]: (state, action) => {
            const res = action.payload.res.data;
            const data = action.payload.apiParams
            if(res.status === 'success') {
                state.sociallogin_provider = data.sociallogin_provider;
                state.mailing_address = data.mailing_address;
                state.user_nickname = data.user_nickname;
                state.user_pic_path = data.user_pic_path;
                state.user_id = data.user_id;
                state.user_mobile = data.user_mobile;
                state.user_emailagree = data.user_emailagree;
            } 
        },

    }
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
