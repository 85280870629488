import BaseModal from "@/components/molecules/common/modal/BaseModal";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";
import CirculationStopModalBody from "@/components/molecules/common/modal//bodyModal/CirculationStopModalBody";

export default function CirculationStopModal({ from, show, close }:CirculationStopProps) {
    const { allDate, allPlatform, salesPlatform } = useAppSelector((state: RootState) => state.pubPortfolio);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    const applyCirculationStop = () => {
        if (!allPlatform.length) {
            return alert('warning', '주의', '변경사항이 없습니다.');
        }
        // 유통 종료일 변경할 사항이 없을 경우
        let circulationPlatform = false;
        for (let p of salesPlatform) {
            if (p.circulation_period[p.circulation_period.length-1].end_circulate_at === null) {
                circulationPlatform = true;
            }
        }
        if (!circulationPlatform) {
            return alert('warning', '주의', '유통 진행중인 플랫폼이 없습니다.');
        }

        const tempSalesPlatform = JSON.parse(JSON.stringify(salesPlatform));
        for (let p of tempSalesPlatform) {
            if (allPlatform.findIndex(a => a === p.platform_id) > -1 && p.circulation_period.length) {
                if (p.circulation_period[p.circulation_period.length-1].start_circulate_at > allDate) {
                    // 유통시작일이 유통 중지일보다 클 경우
                    return alert('warning', '유통종료일 오류', `${p.platform_name}의 유통 시작일을 확인해주세요.`);
                }
                if (p.circulation_period[p.circulation_period.length-1].end_circulate_at === null) {
                    // 유통진행중일 때만 변경
                    p.circulation_period[p.circulation_period.length-1].end_circulate_at = allDate;
                }
            }
        }
        dispatch({ type: "pubPortfolio/setSalesPlatform", payload: tempSalesPlatform });
        close();
    }

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["유통 중지"]}
                customStyle={[{ width: "480px", height: "468px" }]}
                applyFunc={applyCirculationStop}
            >
                <CirculationStopModalBody
                    from={from}
                />
            </BaseModal>
        </>
    );
}

interface CirculationStopProps extends BaseModalProps {
}