import { useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser'
import { colors } from "@/assets/styles/theme";
import styled from 'styled-components'
import Text from "@/components/atoms/text";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store"
import { getNoticeDetail, removeNotice, noticeActions } from '@/pages/support/notice/slice';

export default function NoticeDetail() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch(); 
    const params = useParams();
    const id = Number(params.id);
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const { noticeDetail, auth } = useAppSelector((state: RootState) => state.notice)
    const { isPublisher } = useAppSelector((state: RootState) => state.user);

    const titleStyle = {
        fontSize: "24px",
        fontWeight: "700",
        color: colors.black
    }

    const requireAuth = async () => {
        dispatch(noticeActions.cleanAuth());
        await alert("info", "주의", "출판사 권한이 필요한 페이지입니다.");
        navigate('/support/notice');
    }

    const deleteHandler = async (id?: number) => {
        try {
            let answer = await alert("confirm", "삭제 확인", "게시글을 삭제 하시겠습니까?");
            if (answer === "Y") {
                loadingOn();
                let apiParams = {
                    id,
                    class: '공지사항'
                };
                await dispatch(removeNotice(apiParams)).unwrap();
                await alert("success", "삭제 완료", "삭제가 완료되었습니다.");
                navigate('/support/notice');
            } 
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const fetchNoticeDetail = async () => {
        try {
            loadingOn();
            let apiParams = {
                noticeId: id,
                isPublisher,
                target_class: "공지사항",
                target_cd: "BV",
                target_id: id,
                log_activity: "VU"
            }
            await dispatch(getNoticeDetail(apiParams)).unwrap();
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    useEffect(() => {
        if (auth === null) {
            requireAuth();
        } else {
            fetchNoticeDetail();
        }
        return () => {dispatch(noticeActions.reset())}
    }, [auth]);

    return (
            <>
                <StyledContainer>
                    <StyledPulisherWrapper>
                        {noticeDetail.publisher_name || "글링"} 
                    </StyledPulisherWrapper>
                    <StyledTitle>
                        <Text text={noticeDetail.title} customStyle={titleStyle}/>
                        <UpdateWrapper isMine={noticeDetail.is_mine}>
                            <ModifyWrapper onClick={()=> {navigate(`/support/notice/write?type=update&id=${id}`)}}>
                                수정
                            </ModifyWrapper>
                            <DeleteWrapper onClick={()=> deleteHandler(id)}>
                                삭제
                            </DeleteWrapper>
                        </UpdateWrapper>                                                                                                                                                                       
                    </StyledTitle>
                    <StyledContentWrapper>
                        <pre>{ ReactHtmlParser(noticeDetail.content) }</pre>
                    </StyledContentWrapper>
                </StyledContainer>
                <StyledBackButton onClick={() =>{navigate(-1)}}>
                    뒤로가기
                </StyledBackButton>
            </>
    )
}

const StyledContainer = styled.div`
    width: 900px;
    height: 342px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
`

const StyledPulisherWrapper = styled.div`
    color: ${({ theme }) => theme.colors.blue500};
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
`;

const StyledTitle = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
`;

const UpdateWrapper = styled.div<{isMine: boolean | undefined}>`
    display: ${({ isMine }) => isMine === true ? 'flex' : 'none'};
    gap: 8px;
`;

const ModifyWrapper = styled.div`
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
`;

const DeleteWrapper = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.error};
    cursor: pointer;
`

const StyledContentWrapper = styled.div`
    color: ${({ theme }) => theme.colors.gray600};
    min-height: 240px;
    margin-top: 24px;
    padding: 24px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`;

const StyledBackButton = styled.button`
    color: #FF001A;
    border: 1px solid #FF001A;
    border-radius: 4px;
    padding: 9px 24px;
    background-color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
`;
