import TitleContainer from "@/components/molecules/landing/pricing/TitleContainer";
import PriceGuideContainer from "@/components/molecules/landing/pricing/PriceGuideContainer";
import styled from "styled-components";
import FunctionContainer from "@/components/molecules/landing/pricing/FunctionContainer";
import QuestionContainer from "@/components/molecules/landing/pricing/QuestionContainer";
import AdvertiseContainer from "@/components/molecules/landing/pricing/AdvertiseContainer";

export default function Pricing() {
    return (
        <PricingSection>
            <TitleContainer /> 
            <PriceGuideContainer />
            <FunctionContainer />
            <QuestionContainer />
            <AdvertiseContainer />
        </PricingSection>
    )
}


const PricingSection = styled.div`
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.black};
`

