import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";

// components
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import InputText from "@/components/atoms/input/TextInput";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import SelectBox from "@/components/atoms/selectBox/index";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SetCoAuthorModal from "@/components/molecules/common/modal/SetCoAuthorModal";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import Skeleton from "@/components/molecules/common/loading/TableSkeleton";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";

// reducer
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { authorActions } from "@/pages/settlement/basedata-manage/author/authorSlice";
import { updateRow } from "@/pages/common/dataTableSlice";
import { user, text, ratio, email, UserValidation, newAuthor, Validation } from "@/modules/utils/validate_modify";

function AuthorTableContainer() {
    const dispatch = useAppDispatch();
    const { setDataCount, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "Author" });
    
    let { data, loading, tmpJointArray, index } = useAppSelector((state: RootState) => state.author);
    const [show, setShow] = useState(false);

    useEffect(()=> {
        if (data?.length || data?.length === 0) {
            setDataCount(data.length);
        }
    }, [data, setDataCount])

    function closeModal() {
        setShow(false);
    }

    function setCoAuthor(item: any, idx: number) {
        let index = ((page-1) * itemsPerPage) + idx;
        dispatch(authorActions.setIndex(index));
        dispatch(authorActions.setTmpJointArray(item.joint));
        setShow(true);
    }

    const customHeaders = {
        name: () => <Text text="저자명" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
        email: () => <Text text="글링 아이디(이메일)" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
        realName: () => <Text text="본명" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
        taxRatio: () => <Text text="적용세율" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
        seEmail: () => <Text text="정산서 발급 이메일" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
        created_at: () => <Text text="등록일시" customStyle={{ fontWeight: "500", fontSize: "12px" }} />,
    };

    const columns = {
        name: ({ item, index }: TableItemProps) => {
            const validateName = async(value: string) => {
                dispatch(authorActions.setDataValue({ key: "name", index, value }));

                const { authorError } = (await newAuthor({ value, error: "authorError", mandatory: true, id: item["id"]})) as Validation;

                dispatch(authorActions.setDataValue({ key: "authorError", index, value:authorError }));
                dispatch(updateRow({ itemKey: "id", updatedRow: {...item, "name": value, "authorError": authorError} }));
            }
            return (
                <ValidateWrapper dataError={item["authorError"]}>
                    <InputText
                        value={item["name"]}
                        onChangeHandler={(e) => {validateName(e.target.value);}}
                        customStyle={{ width: "100px", height: "32px", padding: "10px", fontSize: "12px", fontWeight: "400" }}
                    />
                </ValidateWrapper> 
            )
        },
        email: ({ item, index }: TableItemProps) => {
            const validateEmail = async(value: string) => {
                const res = await user({value: value, error: "emailError", field: "이메일", mandatory: false}) as UserValidation;
                const newItem = {...item, "email": value, "emailError": res.emailError, "joint": [{...item.joint[0], "email": value, "emailError": res.emailError, "user_id": res.user_id ? res.user_id : null }]};
                
                dispatch(authorActions.setAuthorDatas({ key: "data", index, value: newItem }));
                dispatch(updateRow({ itemKey: "id", updatedRow: {...item, "email": value, "emailError": res.emailError, "joint": [{...item.joint[0], "email": value, "emailError": res.emailError, "user_id": res.user_id ? res.user_id : null }]} }));
            }
            const changeEmail = async(value: string) => {
                dispatch(authorActions.setAuthorDatas({ key: "data", index, value: {...item, "email": value, "joint": [{...item.joint[0], "email": value}]} }));
                dispatch(updateRow({ itemKey: "id", updatedRow: {...item, "email": value, "joint": [{...item.joint[0], "email": value}]} }));
            }

            return (<ValidateWrapper dataError={item["emailError"]}>
                <InputText
                    type={item["joint"] && item["joint"].length > 1 ? "readOnly" : "main"}
                    value={typeof item["email"] === "string" ? item["email"] : ""}
                    onChangeHandler={(e) => {
                        changeEmail(e.target.value);
                    }}
                    onKeyUp={(e)=>{validateEmail(e.currentTarget.value)}}
                    customStyle={{ width: "200px", height: "32px", padding: "10px", fontSize: "12px", fontWeight: "400" }}
                    placeholder={item["joint"].length > 1 ? "공저별 설정" : "글링 아이디(이메일)"}
                />
            </ValidateWrapper>)
        },
        realName: ({ item, index  }: TableItemProps) => {
            const validateRealName = (value:string) => {
                const res = text({value: value, error: "realNameError", field: "본명", max: 20, mandatory: true}) as Validation;
                const newItem = {...item, "realName": value, "realNameError": res.realNameError, "joint": [{...item.joint[0], "realName": value, "realNameError": res.emailError }]};
               
                dispatch(authorActions.setAuthorDatas({ key: "data", index, value: newItem }));
                dispatch(updateRow({ itemKey: "id", updatedRow: {...item, "realName": value, "realNameError": res.realNameError, "joint": [{...item.joint[0], "realName": value, "realNameError": res.emailError }]}}));
            }
            return (
                <ValidateWrapper dataError={item["realNameError"]}>
                    <InputText
                        type={item["joint"] && item["joint"].length > 1 ? "readOnly" : "main"}
                        value={typeof item["realName"] === "string" ? item["realName"] : ""}
                        onChangeHandler={(e) => {
                            validateRealName(e.target.value);
                        }}
                        customStyle={{ width: "100px", height: "32px", padding: "10px", fontSize: "12px", fontWeight: "400" }}
                        placeholder={item["joint"] && item["joint"].length > 1 ? "공저별 설정" : "본명"}
                    />
                </ValidateWrapper>
            )
        },
        taxRatio: ({ item, index }: TableItemProps) => {
            const taxRatioValidate = (value: string) => {
                const res = ratio({value: value, error: "taxRatioError", field: "적용세율", decimal: true, mandatory: true}) as Validation;
                const newItem = {...item, "taxRatio": res.value, "taxRatioError": res.taxRatioError, "joint": [{...item.joint[0], "taxRatio": res.value, "taxRatioError": res.taxRatioError }]};

                dispatch(authorActions.setAuthorDatas({ key: "data", index, value: newItem }));
                dispatch(updateRow({ itemKey: "id", updatedRow: {...item, "taxRatio": res.value, "taxRatioError": res.taxRatioError, "joint": [{...item.joint[0], "taxRatio": res.value, "taxRatioError": res.taxRatioError }]}}))
            }
            return (
                <>
                    {
                        item["joint"]&& item["joint"].length > 1 ?
                        (
                            <SelectBox
                                disabled={true}
                                options={[{ name: "공저별 설정", value: "공저별 설정" }]}
                                selected={"공저별 설정"}
                                labelKey="name"
                                valueKey="value"
                                onClickValueFunc={()=>{}}
                                customStyle={{ height: "32px", width: "140px", fontSize: "12px", fontWeight: "400" }}
                            />
                        ) : (
                            <>
                                <SelectBox
                                    selected={item["taxType"]}
                                    labelKey="name"
                                    valueKey="value"
                                    options={options}
                                    onClickValueFunc={(v:any) => {
                                        const newItem = {...item, "taxType": v.value, "taxRatio": v.value === "D" ? "3.3" : 0.00, "taxRatioError": "",
                                        "joint": [{...item.joint[0], "taxType": v.value, "taxRatio": v.value === "D" ? "3.3" : 0.00, "taxRatioError": ""}]};

                                        dispatch(authorActions.setAuthorDatas({ key: "data", index, value: newItem }));
                                        dispatch(updateRow({ itemKey: "id", updatedRow: {...item, "taxType": v.value, "taxRatio": v.value === "D" ? "3.3" : 0.00, "taxRatioError": "",
                                        "joint": [{...item.joint[0], "taxType": v.value, "taxRatio": v.value === "D" ? "3.3" : 0.00, "taxRatioError": ""}]}}))
                                    }}
                                    customStyle={{
                                        height: "32px",
                                        width: "140px",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                    }}
                                />
                                <ValidateWrapper dataError={item["taxRatioError"]} customStyle={{ display: "inline-flex"}}>
                                    <PercentInputText
                                        type="number"
                                        value={item["taxRatio"]}
                                        inText="%"
                                        customStyle={{ width: "120px", height: "32px", padding: "0 18px 2px 0" }}
                                        disabled={String(item["taxType"]) !== "A" ? true : false}
                                        onChange={(e) => {
                                            taxRatioValidate(e.target.value);
                                        }}
                                    />
                                </ValidateWrapper>
                            </>
                        )
                    }
                </>
            );
        },
        stEmail: ({ item, index }: TableItemProps) =>
        {
            const stEmailValidate = (value: string) => {
                const res = email({value: value, error: "stEmailError", field: "이메일", mandatory: false}) as Validation;
                const newItem = {...item, "stEmail": res.value, "stEmailError": res.stEmailError, "joint": [{...item.joint[0], "stEmail": res.value, "stEmailError": res.stEmailError }]};
                
                dispatch(authorActions.setAuthorDatas({ key: "data", index, value: newItem }));
                dispatch(updateRow({ itemKey: "id", updatedRow: {...item, "stEmail": res.value, "stEmailError": res.stEmailError, "joint": [{...item.joint[0], "stEmail": res.value, "stEmailError": res.stEmailError }]}}))
            }
            return (
                <>
                    <ValidateWrapper dataError={item["stEmailError"]} customStyle={{ display: "inline-flex"}}>
                        <InputText
                            type={item["joint"]&& item["joint"].length > 1 ? "readOnly" : "main"}
                            value={typeof item["stEmail"] === "string" ? item["stEmail"] : ""}
                            placeholder={item["joint"] && item["joint"].length > 1 ? "공저별 설정" : "이메일을 입력해주세요"}
                            onChangeHandler={(e) => {
                                stEmailValidate(e.target.value);
                            }}
                            customStyle={{
                                width: "150px",
                                height: "32px",
                                padding: "10px",
                                fontSize: "12px",
                                fontWeight: "400",
                            }}
                        />
                    </ValidateWrapper>
                    <Button
                        type="main-outline"
                        label="공저 설정"
                        customStyle={{
                            width: "80px",
                            height: "32px",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                        onClickHandler={() => setCoAuthor(item, index)}
                    />
                </> 
            )    
        },
        created_at: ({ item }: TableItemProps) => (
            <Text
                text={moment(item["created_at"]).format("YYYY.MM.DD HH:mm")}
                customStyle={{ fontSize: "12px", fontWeight: "400" }}
            />
        ),
    };

    return (
        <React.Fragment>
            <StyledWrapper>
                {
                loading && data ?
                <Skeleton />
                :
                <>
                    <TableContainer>
                        <DataTable
                            from="Author"
                            itemKey="id"
                            items={data}
                            headers={headers}
                            columns={columns}
                            customHeaders={customHeaders}
                        />
                        <ScrollToTopBtn />
                    </TableContainer>
                    <Pagination pageCount={pageCount} totalVisible={totalVisible} from="Author" /> 
                </>
                }
            </StyledWrapper>
            <ModalPortal>
                <SetCoAuthorModal
                    show={show}
                    from="author-update"
                    close={closeModal}
                    index={index as number}
                />
            </ModalPortal>
        </React.Fragment>
    );
}

export default AuthorTableContainer;

const options = [
    { name: "개인(국내 거주자)", value: "D" },
    { name: "개인(국내 비거주자)", value: "A" },
    { name: "기타사업자", value: "B" },
];

const headers = [
    { text: "저자명", value: "name", width: "120px" },
    { text: "글링 아이디(이메일)", value: "email", width: "220px", sort: false },
    { text: "본명", value: "realName", width: "120px", sort: false },
    { text: "적용세율", value: "taxRatio", width: "270px", sort: false },
    { text: "정산서 발급 이메일", value: "stEmail", width: "250px", sort: false },
    { text: "등록일시", value: "created_at", width: "140px" },
];

const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
