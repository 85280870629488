import styled from "styled-components";
import { useAppSelector, RootState } from "@/modules/store";
import SummaryCard from "@/components/molecules/dashboard/publisher/SummaryCard";

export default function SummaryCardContainer() {
    const { salesSummary } = useAppSelector((state: RootState) => state.pubDashboard);

    const summaryCardData: Card[] = [
        { type: "전일", amount: salesSummary.yesterdaySales.amount, desc: `전일 대비 ${salesSummary.yesterdaySales.ratio}%`, rate: salesSummary.yesterdaySales.ratio },
        { type: "7일 누적", amount: salesSummary.weekSales, desc: "", rate: 0 },
        { type: "당월 누적", amount: salesSummary.currMonthSales, desc: "", rate: 0 },
        { type: "전월 정산금 총계", amount: salesSummary.lastMonthSales, desc: "", rate: 0 },
    ]

    return (
        <StyledContainer>
            {summaryCardData.map(s => (
                <SummaryCard 
                    cardData={s} 
                    key={summaryCardData.indexOf(s)} 
                    isFirst={summaryCardData.indexOf(s) === 0}
                />
            ))}
        </StyledContainer>
    )
}

const StyledContainer = styled.ul`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    li {
        &:first-child {
            border: 2px solid ${({ theme }) => theme.colors.blue500};
        }
    }
`

interface Card {
    type: string;
    amount: string;
    desc: string;
    rate: number;
}