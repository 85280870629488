import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as NaverIcon } from "@/assets/icons/naver.svg";
import { ReactComponent as KakaoIcon } from "@/assets/icons/kakao.svg";
import { ReactComponent as GoogleIcon } from "@/assets/icons/google.svg";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import { userActions } from "@/pages/common/userSlice";
import useAlert from "@/modules/hooks/useAlert";
import { useNavigate } from "react-router-dom";

declare global {
    interface Window {
        login?: Function;
        customAlert?: Function;
        confirmAlert?: Function;
    }
}

function SNSLoginContainer() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { alert } = useAlert();

    window.customAlert = function(data: any) {
        return alert("error", data.title, data.message);
    }
    window.confirmAlert = async function(data: any) {
        const answer = await alert("confirm", data.title, data.message);

        if (answer === "Y") {
            dispatch({ type: "signup/setSocialData", payload: {
                provider: data.social_provider,
                id: data.social_id,
                email: data.email   
            }});
            navigate("/signup");
        }
    }
    return (
        <>
            <SnsLoginLine>
                <Line />
                <Text text="SNS 로그인" customStyle={{ fontSize: "16px", fontWeight: "400", color: colors.gray600, margin: "0 20px" }} />
                <Line />
            </SnsLoginLine>
            <SnsFormWrap>
                <SnsSigninForm snsName={"kakao"} />
                <SnsSigninForm snsName={"naver"} />
                <SnsSigninForm snsName={"google"} />
            </SnsFormWrap>
        </>
    );
}

function SnsSigninForm({ snsName }: { snsName: string }) {
    const { socketId } = useSelectorTyped((state:RootState) => state.socket);
    let snsLogo;
    if (snsName === "google") {
        snsLogo = <GoogleIcon width="60" height="60" />
    } else if (snsName === "kakao") {
        snsLogo = <KakaoIcon />
    } else snsLogo = <NaverIcon width="60" height="60" />;

    function snsLogin(site:string) {
        const url = `/uploader/social/login` + 
                    `/${site}?sid=${socketId}` + 
                    `&caller=${window.location.origin}`;
        window.open(url, "login", "width=480,height=600");
    }

    return (
        <SnsLogo className={snsName} onClick={()=> snsLogin(snsName)}>
            {snsLogo}
        </SnsLogo>
    );
}

const SnsLoginLine = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`

const Line = styled.div`
    width: 140px;
    height: 1px;
    background: ${({ theme }) => theme.colors.gray300};
`
const SnsFormWrap = styled.div`
    display: flex;
    align-items: center;
`

const SnsLogo = styled.div`
    width: 60px;
    height: 60px;
    cursor: pointer;
    svg {
        border-radius: 50%;
        padding: 10px;
    }
    &.google {
        border: 1px solid ${({ theme }) => theme.colors.gray300};
        border-radius: 50%;
    }
    &.kakao {
        border-radius: 50%;
        background: #ffe200;
    }
    &.naver {
        border-radius: 50%;
        background: #00c825;
    }
    :not(:last-of-type) { margin-right: 24px; }
`

export default SNSLoginContainer;
