import { useEffect } from "react";

// reducer
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getAuthorDailyData, setNoData } from "@/pages/settlement/saels-inquiry/author/daily/dailySlice";

// components
import TemplateLayout from "@/components/molecules/sales-inquiry/common/TemplateLayout";
import DailySearchFilter from "@/components/molecules/sales-inquiry/author/daily/DailySearchFilter";
import DailyTableTop from "@/components/molecules/sales-inquiry/author/daily/DailyTableTop";
import DailyTable from "@/components/molecules/sales-inquiry/author/daily/DailyTable";

// custom hooks
import useDailyApi from "./hooks/useDailyApi";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function Daily() {
    const { selectedPlatform, selectedPublisher, selectedAuthor, selectedSeries, initialDataLoaded, changedParamsId } = useSelectorTyped(
        (state: RootState) => state.inquiryAuthorDaily,
    );
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    useDailyApi();

    useEffect(() => {
        getDailyData();
    }, [changedParamsId]);

    const getDailyData = async () => {
        try {
            if (
                initialDataLoaded &&
                (!selectedPlatform.length || !selectedPublisher.length || !selectedAuthor.length ||!selectedSeries.length)
            ) {
                return dispatch(setNoData());
            }

            loadingOn();
            await dispatch(getAuthorDailyData()).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    return (
        <TemplateLayout>
            <DailySearchFilter />
            <DailyTableTop />
            <DailyTable />
        </TemplateLayout>
    );
}
