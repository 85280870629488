import BaseModal from "@/components/molecules/common/modal/BaseModal";
import { BaseModalProps } from "@/pages/common/types";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import SetAuthModalBody from "@/components/molecules/common/modal/bodyModal/SetAuthModalBody";
import { updateAuthInfo } from "@/pages/mypage/publisher/slice";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";

export default function SetAuthAdmin({ show, from, close }: SetAuthModalProps) {
    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();

    const authData = useAppSelector((state: RootState) => state.pubMyPage.authData);

    async function changeAuth() {  //모달에서 적용을 누르면 권한 설정이 변경됨
        try {
            await loadingOn();
            await dispatch(updateAuthInfo({authData}));
            close();
        } catch (err) {
            alert("error", "오류 발생", "권한설정을 저장하는 중 오류가 발생하였습니다.")
        } finally {
            loadingOff();
        }
    }
    
    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={['권한설정']}
                customStyle={[{ width: "480px", height:"542px"}]}
                applyFunc={changeAuth}
            >
                <SetAuthModalBody></SetAuthModalBody>
            </BaseModal>
        </>
    )
}

interface SetAuthModalProps extends BaseModalProps {
    
}
