import axios from "axios";
import { logout } from "@/pages/common/userSlice";

// export const baseURL = process.env.NODE_ENV === "production" ? "https://testapi.gling.co.kr" : "/api";
const api = axios.create({
  // baseURL,
  timeout: 300000,
  withCredentials: true,
});

let sourceRequest:any = {};

// api.interceptors.request.use(
//   request => {
//     if (!request.url) return request;
//     if (sourceRequest[request.url]) {
//       sourceRequest[request.url].cancel("Automatic cancellation");
//     }

//     const axiosSource = axios.CancelToken.source();
//     sourceRequest[request.url] = { cancel: axiosSource.cancel };
//     request.cancelToken = axiosSource.token;

//     return request;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

api.interceptors.response.use(
  response => {
    return response;
    // if (response.data.status === "success") {
    //   return {
    //     status: "success",
    //     message: response.data.message,
    //     data: response.data.data,
    //   } as ServerResponse;
    // } else {
    //   return {
    //     status: "fail",
    //     message: response.data.message,
    //     data: null,
    //   } as ServerResponse;
    // }
  },
  error => {
    if (error.response && error.response.status === 401) {
      logout(); // 토큰이 유효하지 않을 시
    }
    throw error;
    // const message =
    //   error.response && error.response.data && error.response.data.message
    //         ? error.response.data.message
    //         : "서버 에러가 발생하였습니다.";
    //   return {
    //       status: "fail",
    //       message: message,
    //       data: null,
    //   };
  }
);
export interface ServerResponse {
  status: string;
  message: string;
  data: any;
}
export default api;