import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState } from "@/modules/store";
import Radio from "@/components/atoms/input/Radio";
import useAlert from "@/modules/hooks/useAlert";
import useSampleDown from "@/modules/hooks/useSampleDown";
import { base64Encode } from "@/modules/utils/file";
import { BaseModalProps } from "@/pages/common/types";
import BaseModal from "./BaseModal";
import ExcelUploadModalBody from "./bodyModal/ExcelUploadModalBody";

export default function ExcelUploadSalesModal({
    from,
    show,
    close,
    platformForm,
    onApplyExcelUpload,
}: ExcelUploadModalProps) {
    const { isAuto } = useSelectorTyped((state: RootState) => state.uploader);
    const [formType, setFormType] = useState("platform");
    const file = useRef<File>();
    const { alert } = useAlert();
    const { saveAs } = useSampleDown();

    const changeFormType = (e: ChangeEvent<HTMLInputElement>) => setFormType(e.target.value);

    const updateFile = (uploadedFile: File) => {
        file.current = uploadedFile;
    };

    const apply = async () => {
        if (!file.current?.name) {
            return await alert("warning", "주의", "파일을 업로드해주세요.");
        }
        const answer = isAuto ? "Y" : await alert("confirm", "업로드 확인", "업로드를 진행 하시겠습니까?");
        if (answer === "Y") {
            onApplyExcelUpload({
                file: String(await base64Encode(file.current)),
                fileName: file.current.name,
                form: formType === "platform" ? "플랫폼" : "글링"
            });
        }
        close();
    };

    const downloadGlingSample = () => {
        switch (from) {
            case "daily":
                saveAs("download/excel/sample/salesEntry/daily", "(gling)일별_판매_등록_양식.xlsx");
                break;
            case "monthly":
                saveAs("download/excel/sample/salesEntry/monthly", "(gling)월_정산_등록_양식.xlsx");
                break;
            case "etc":
                saveAs("download/excel/sample/salesEntry/etc", "(gling)기타지급금_등록_양식.xlsx");
                break;
        }
    };

    useEffect(() => {
        platformForm === 1 ? setFormType("platform") : setFormType("gling");
        return () => {
            file.current = {} as File;
        };
    }, [show]);

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["엑셀 업로드"]}
            applyFunc={apply}
            customStyle={[{ width: "480px", height: "auto" }]}
        >
            <>
                {from !== "etc" && (
                    <SelectFormType>
                        <Radio
                            type={platformForm === 1 ? "label" : "label-disabled"}
                            name="filetype"
                            label="플랫폼 양식"
                            value="platform"
                            checked={formType === "platform"}
                            onChange={changeFormType}
                            customStyle={{ fontSize: "14px" }}
                        />
                        <Radio
                            type="label"
                            name="filetype"
                            label="글링 양식"
                            value="gling"
                            checked={formType === "gling"}
                            onChange={changeFormType}
                            customStyle={{ fontSize: "14px", margin: "0 0 0 16px" }}
                        />
                    </SelectFormType>
                )}
                <ExcelUploadModalBody onUploadFile={updateFile} onDownloadSample={downloadGlingSample} />
            </>
        </BaseModal>
    );
}

const SelectFormType = styled.div`
    display: flex;
    padding: 8px 20px 4px;
`;
interface ExcelUploadModalProps extends BaseModalProps {
    platformForm: number;
    onApplyExcelUpload: (fileData: FileData) => void;
}

export interface FileData {
    file: string;
    fileName: string;
    form: string;
}
