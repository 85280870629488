import styled from "styled-components";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import TableOptions from "@/components/molecules/sales-inquiry/author/TableOptions";
import SalesSummary from "@/components/molecules/sales-inquiry/author/SalesSummary";
import useExcelDownload from "@/modules/hooks/useExcelDownload";
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { addComma } from "@/modules/utils/filter";

export default function MonthlyTableTop() {
    const { items, total_cnt, total_amt } = useSelectorTyped((state: RootState) => state.inquiryAuthorMonthly);
    const { platformNoQty } = useSelectorTyped((state: RootState) => state.inquiryAuthorCommon);
    const { setItemsPerPage } = usePagination({ from: "monthly", tableData:items });
    const downloadExcel = useExcelDownload();
    const { alert } = useAlert();

    const changeItemPerPage = (itemPerPage: number) => setItemsPerPage(itemPerPage);

    const downloadMonthlyExcel = () => {
        if (items.length > 0) {
            let rows: any[] = [];
            items.forEach((item) => {
                let row = {
                    판매플랫폼: item.platform_name,
                    정산월: item.st_month,
                    실제판매월: item.yearmon,
                    작품명: item.book_name,
                    시리즈명: item.series_name,
                    저자명: item.author_name,
                    판매량: platformNoQty.find(p => p === item.platform_name) ? '정보없음' : item.quantity,
                    "판매금액(원)": addComma(item.amount_pl),
                    "출판사정산금(원)": addComma(item.amount_pu),
                    "작가정산비율(%)": item.ratio,
                    "작가정산금액(원)": addComma(Math.round(item.author_pu)),
                };
                rows.push(row);
            });
            downloadExcel({ json: rows, excelFileName: "[판매현황조회] 월 정산", sheetNames: "월별판매현황" });
        } else {
            alert("info", "조회 완료", "조회된 데이터가 없습니다.");
        }
    };

    return (
        <TableTopContainer>
            <SalesSummary total={total_cnt} amountName={"작가정산금 합계"} amount={total_amt} />
            <TableOptions onChangeItemPerPage={changeItemPerPage} onExcelDownload={downloadMonthlyExcel} />
        </TableTopContainer>
    );
}

const TableTopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
