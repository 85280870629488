import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";

// components
import DataTable, { TableItemProps } from "@/components/molecules/common/table/DataTable";
import Text from "@/components/atoms/text/index";
import TableContainer from "@/components/molecules/sales-inquiry/common/TableContainer";
import ScrollToTopBtn from "@/components/molecules/common/scrollToTopBtn/ScrollToTopBtn";
import Pagination from "@/components/molecules/common/pagination/Pagination";

// hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";

// types
import { addComma } from "@/modules/utils/filter";

export default function MgTable() {
    const { items, fetchLoading } = useSelectorTyped((state: RootState) => state.inquiryAuthorMg);
    const { pageCount, totalVisible } = usePagination({ from: "mg" });

    const columns = {
        contractPrice: ({ item }: TableItemProps) => {
            return <Text text={addComma(item.contractPrice)} customStyle={textStyle} />;
        },
        mg_ratio: ({ item }: TableItemProps) => {
            return <Text text={item.mg_ratio} customStyle={textCenterStyle} />;
        },
        st_price: ({ item }: TableItemProps) => {
            return <Text text={addComma(item.st_price)} customStyle={textStyle} />;
        },
        deductionPrice: ({ item }: TableItemProps) => {
            return <Text text={addComma(item.deductionPrice)} customStyle={textStyle} />;
        },
        remainPrice: ({ item }: TableItemProps) => {
            return <Text text={addComma(item.remainPrice)} customStyle={textStyle} />;
        },
    };

    return (
        <>
            <TableContainer width="1320px">
                <DataTable
                    from="mg"
                    itemKey="mg_id"
                    items={items}
                    loading={fetchLoading}
                    headers={headers}
                    columns={columns}
                    options={tableOptions}
                />
                <ScrollToTopBtn />
            </TableContainer>
            <Pagination pageCount={pageCount} totalVisible={totalVisible} from={"mg"} />
        </>
    );
}

const headers = [
    { text: "계약시작일", value: "date", width: "100px" },
    { text: "선인세구분", value: "mg_type", width: "80px" },
    { text: "판매 플랫폼", value: "platform_name", width: "100px" },
    { text: "출판사", value: "publisher_name", width: "100px" },
    { text: "계약형태", value: "contract_type", width: "80px" },
    { text: "저자명", value: "author_name", width: "80px" },
    { text: "계약대상", value: "target", width: "200px" },
    { text: "계약금액", value: "contractPrice", width: "100px" },
    { text: "정산비율(%)", value: "mg_ratio", width: "100px", textAlign: "center" },
    { text: "정산금액", value: "st_price", width: "100px" },
    { text: "소진금액", value: "deductionPrice", width: "100px" },
    { text: "잔여금액(원)", value: "remainPrice", width: "100px" },
];

const tableOptions = {
    showCheckbox: false,
};
const textStyle = {
    textAlign: "right",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px",
};
const textCenterStyle = {
    ...textStyle,
    textAlign: "center",
};