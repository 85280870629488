import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setParamsId } from "@/pages/settlement/saels-inquiry/author/mg/mgSlice";

const useMgApi = () => {
    const {
        totalDate,
        mgType,
        selectedPlatform,
        selectedPublisher,
        complete,
        contractType,
        contractStartDate,
        contractEndDate,
        contractPrice,
        deductionPrice,
        remainPrice,
        target,
        contractStartDateError,
        contractEndDateError,
        initialDataLoaded,
        fetchLoading,
    } = useSelectorTyped((state: RootState) => state.inquiryAuthorMg);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!initialDataLoaded && fetchLoading) return;
        if (contractStartDateError !== "" || contractEndDateError !== "") return;

        dispatch(setParamsId());
        return () => { }
    }, [
        totalDate,
        selectedPlatform,
        selectedPublisher,
        contractStartDate,
        contractEndDate,
        mgType,
        complete,
        contractType,
        contractPrice,
        deductionPrice,
        remainPrice,
        target,
        dispatch
    ]);
};

export default useMgApi;
