import { useState, useEffect } from "react";
import styled from "styled-components"
import { colors } from "@/assets/styles/theme";
import Text from "@/components/atoms/text";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";
import HomeSectionLayout from "@/components/molecules/home/HomeSectionLayout";
import NoticeCardsContainer from "@/components/molecules/home/update/NoticeCardsContainer";
import { getNoticeData } from "@/pages/support/notice/slice"
import { ReactComponent as ArrowLeft } from "@/assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "@/assets/icons/arrow-right.svg";

const slideDistance = 302;

export default function Update() {
    
    const { data } = useAppSelector((state: RootState) => state.notice);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const slideQuantity = data.length;
    const slideWidth = 284 * slideQuantity + (slideQuantity + 1) * 18;
    const hiddenSlideWidth = slideWidth - 1200 > 0 ? slideWidth - 1200 : 0;
    const [lastGap, setLastGap] = useState(0);
    const [slideSpot, SetSlideSpot] = useState(0);

    const titleStyle = {
        color: colors.black,
        fontWeight: "700",
        fontSize: "24px"
    }

    const noticeDatas = data.map((notice) => ({id: notice.id, title: notice.title, content: notice.content}));

    const prevHandler = () => {
        if (Math.abs(slideSpot) < slideDistance) {
            SetSlideSpot(0);
        } else if (Math.abs(slideSpot) === hiddenSlideWidth) {
            SetSlideSpot((prev)=> prev + lastGap);
        } else {
            SetSlideSpot((prev)=> prev + slideDistance);
        }
    }

    const nextHandler = () => {
        if (hiddenSlideWidth - Math.abs(slideSpot) < slideDistance) {
            setLastGap(() => (hiddenSlideWidth - Math.abs(slideSpot)));
            SetSlideSpot((prev) => prev - (hiddenSlideWidth - Math.abs(slideSpot)));
        } else {
            SetSlideSpot((prev) => prev - slideDistance);
        }
    }

    const fetchData = async () => {
        try {
            loadingOn();
            await dispatch(getNoticeData()).unwrap();
        } catch(err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    useEffect(()=> {
        fetchData();
    }, []);

    return (
        <>
            <HomeSectionLayout padding={320}>
                <StyledContainer>
                    <IconWrapper>
                        {slideSpot !== 0 ? 
                            <ArrowLeft onClick={prevHandler} fill={colors.blue600} width="60" height="60"/> :
                            <ArrowLeft fill={colors.gray600} width="60" height="60" />
                        }
                    </IconWrapper>
                    <Container>
                        <TitleWrapper>
                            <Text text="업데이트" customStyle={titleStyle}/>
                        </TitleWrapper>
                        <NoticeCardsContainer slideSpot={slideSpot} noticeDatas={noticeDatas}/>
                    </Container>
                    <IconWrapper>
                        {Math.abs(slideSpot) !== hiddenSlideWidth ? 
                            <ArrowRight onClick={nextHandler} fill={colors.blue600} width="60" height="60"/> :
                            <ArrowRight fill={colors.gray600} width="60" height="60"/> 
                        }
                    </IconWrapper>
                </StyledContainer>
            </HomeSectionLayout>
        </>
    )
}

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const IconWrapper =  styled.div`
    cursor:pointer;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 370px;
    gap: 24px;
`

const TitleWrapper = styled.div`
    margin-left: 18px;
`