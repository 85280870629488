import styled, { css, keyframes } from "styled-components";
import Text from "@/components/atoms/text";
import { ReactComponent as Visability } from "@/assets/icons/visability.svg";
import { cardData } from "@/components/molecules/ebook-maker/form/MakeTypeContainer";
import { RootState, useAppSelector } from "@/modules/store";
import Button from "@/components/atoms/button";
import { EbookMaker } from "@/types/dataType";

export default function Preview({ disappear }: { disappear: boolean }) {
    const { makeType, inFileType, outFileType, bookInfo, copyrightType, 
        subtitleType, coverFile, styleFile, noCoverImg } = useAppSelector((state: RootState) => state.ebookMaker);
    const makeTypeLabel = cardData.find(c => c.type === makeType)?.label;

    let copyrightTypeLabel = "";
    switch (copyrightType) {
        case EbookMaker.CopyrightType.INFILE:
            copyrightTypeLabel = "본문 내 포함";
            break;
        case EbookMaker.CopyrightType.MAKE:
            copyrightTypeLabel = "신규 생성";
            break;
        case EbookMaker.CopyrightType.NONE:
            copyrightTypeLabel = "만들지 않음";
            break;
        default:
            break;
    }

    let subtitleLabel = "";
    switch (subtitleType) {
        case EbookMaker.SubtitleType.INPAGE:
        case EbookMaker.SubtitleType.INBODY:
            subtitleLabel = "원고파일 내 포함";
            break;
        case EbookMaker.SubtitleType.MAKE:
            subtitleLabel = "신규 생성";
            break;
        case EbookMaker.SubtitleType.NONE:
            subtitleLabel = "없음";
            break;
        default:
            break;
    }

    const bookData = [
        { name: "파일 변환 방식", value: `${inFileType} > ${outFileType.length? outFileType[0] : ""} ${outFileType.length > 1 ? `외${outFileType.length-1}` : "" }`},
        { name: "제목", value: bookInfo.series_name },
        { name: "저자", value: bookInfo.author_name },
        { name: "펴낸곳", value: bookInfo.publisher_name },
        { name: "ISBN", value: bookInfo.isbn },
        { name: "발행일", value: bookInfo.published_at },
        { name: "판권지", value: copyrightTypeLabel },
        { name: "소제목", value: subtitleLabel },
    ]

    return (
        <StyledContainer disappear={disappear}>
            <TitleWrapper>
                <Visability />
                <Text text="미리보기" customStyle={{ fontSize: "14px", fontWeight: "500", padding: "11px 8px" }} />
            </TitleWrapper>
            <ContentWrapper>
                <MakeTypeWrapper>{makeTypeLabel}</MakeTypeWrapper>
                <BookDatarapper>
                    {bookData.map(b => (
                        <RowWrapper key={bookData.indexOf(b)}>
                            <FieldName>{b.name}</FieldName>
                            <FieldValue>{b.value}</FieldValue>
                        </RowWrapper>
                    ))}
                    <ImgRowWrapper>
                        <FieldName>표지</FieldName>
                        <FieldValue>
                            {coverFile.length ? 
                                <img src={coverFile[0].file} alt="cover" /> :
                                noCoverImg ? "표지 없음" : <EmptyCover />
                            }
                        </FieldValue>
                    </ImgRowWrapper>
                    <RowWrapper>
                        <FieldName>스타일 파일</FieldName>
                        <FieldValue>
                            {styleFile.length ? styleFile[0].name : ""}
                        </FieldValue>
                    </RowWrapper>
                </BookDatarapper>
            </ContentWrapper>
            <Button type="main" label="제작 가이드" customStyle={{ width: "260px", height: "40px" }} />
        </StyledContainer>
    )
}

const fadeIn = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
`

const fadeOut = keyframes`
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
`

const StyledContainer = styled.div<{ disappear: boolean }>`
    position: absolute;
    top: 240px;
    left: calc((100% - 1200px) / 2 + 1200px);
    width: 260px;
    height: 632px;
    background: #FFFFFF;
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    border-radius: 4px;

    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-name: ${fadeIn};
    animation-fill-mode: forwards;

    ${({ disappear }) => disappear && css`
        animation-name: ${fadeOut};
    `}
`

const TitleWrapper = styled.div`
    display: flex;
    height: 40px;
`

const ContentWrapper = styled.div`
    height: 552px;
    padding: 8px 10px 16px 10px;
`

const MakeTypeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    margin-bottom: 8px;

    background-color: ${({ theme }) => theme.colors.blue50};
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.blue500};
    font-size: 12px;
    font-weight: 500;
`

const BookDatarapper = styled.ul`
    height: 528px;
`

const RowWrapper = styled.li`
    display: flex;
    height: 40px;
`

const ImgRowWrapper = styled.li`
    display: flex;
    align-items: center;
    height: 108px;
    img {
        width: 62px;
        height: 92px;
    }
`

const FieldName = styled.div`
    width: 100px;
    padding: 7px 12.5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
`

const FieldValue = styled.div`
    width: 140px;
    font-size: 12px;
    font-weight: 400;
    padding: 7px 9.5px;
    line-height: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
`

const EmptyCover = styled.div`
    width: 62px;
    height: 92px;
    background-color: ${({ theme }) => theme.colors.gray100};
`