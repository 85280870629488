import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import useAlert from "@/modules/hooks/useAlert";
import natsort from "natsort";
import { useState } from "react";
import { base64Encode } from "@/modules/utils/file";

export default function UploadContainer({ accept, multiple, uploadHandler, width, height, disabled, maxSize = 20000000 }: UploadProps) {
    const { alert } = useAlert();
    const [ active, setActive ] = useState(false);

    const onDragover = (e: React.DragEvent<HTMLDivElement>) => {
        if (disabled) return;
        e.stopPropagation();
        e.preventDefault();
        setActive(true);
        e.dataTransfer.dropEffect = "move";
    }

    const onDragleave = (e: React.DragEvent<HTMLDivElement>) => {
        if (disabled) return;
        e.stopPropagation();
        e.preventDefault();
        setActive(false);
    }

    const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        if (disabled) return;
        e.stopPropagation();
        e.preventDefault();
        if (!multiple && e.dataTransfer.files.length > 1) {
            return alert("warning", "주의", "하나의 파일만 업로드 가능합니다.");
        }
        const event = {
            target: {
                files: e.dataTransfer.files
            }
        }
        inputFile(event);
        setActive(false);
    }

    const inputFile = async (e: any) => {
        if (disabled) return;
        const fileList = [];
        for (let f of e.target.files) {
            if (f.size > maxSize) {
                return alert("warning", "주의", `${maxSize / 1000000}Mb 이하의 파일만 업로드 가능합니다.`);
            }
            const ext = f.name.split(".").pop();
            if (accept.includes(ext)) {
                fileList.push({
                    name: f.name,
                    file: await base64Encode(f)
                })
            } else {
                return alert("warning", "주의", `${accept.join(", ")} 파일만 업로드 가능합니다.`);
            }
        }

        // 파일명 기준으로 정렬
        const sorter = natsort();
        fileList.sort((a, b) => sorter(a.name, b.name));
        uploadHandler(fileList);
    }

    return (
        <OuterWrapper 
            width={width} 
            height={height} 
            onDrop={(e) => onDrop(e)}
            onDragOver={(e) => onDragover(e)}
            onDragLeave={(e) => onDragleave(e)}
            active={active}
        >
            <InnerWrapper active={active}>
                파일을 끌어다 놓거나 
                <label htmlFor="fileUploadInput">
                    <input
                        type="file"
                        id="fileUploadInput"
                        multiple={multiple}
                        accept={accept.map(a => `.${a}`).join(", ")}
                        style={{ display: "none" }}
                        onChange={e => inputFile(e)}
                        disabled={disabled}
                    />
                    <Text text="업로드" customStyle={{ fontSize: "14px", padding: "0 1px 0 4px", color: colors.information }} />
                </label>
                를 누르세요
            </InnerWrapper>
        </OuterWrapper>
    )
}

const OuterWrapper = styled.div<{ width?: number; height?: number; active: boolean;}>`
    display: flex;
    width: ${({ width }) => width ? `${width}px` : "100%"};
    height: ${({ height }) => height ? `${height}px` : "100%"};
    padding: 6px 8px;
    border 1px solid ${colors.gray300};
    background-color: ${({ active }) => active ? colors.blue50 : colors.gray50};
`

const InnerWrapper = styled.div<{ active: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed ${({ active }) => active ? colors.blue500 : colors.gray300};
    width: 100%;
    height: 100%;
    color: ${colors.gray600};
    font-size: 14px;
    font-weight: 400;
`

interface UploadProps {
    accept: FileType[]; 
    multiple?: boolean;
    uploadHandler: (fileList: FileListEntry[]) => void;
    width?: number;
    height?: number;
    disabled?: boolean;
    maxSize?: number;
}

type FileType = "txt" | "hwp" | "hwpx" | "docx" | "doc" | "pdf" | "jpg" | "jpeg" | "png" | "css" | "ppt" | "psd" | "epub";

interface FileListEntry {
    name: string;
    file: any;
}