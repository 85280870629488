import TextInput from "@/components/atoms/input/TextInput";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import Text from "@/components/atoms/text";
import { ratio as validateRatio } from "@/modules/utils/validate_modify";
import { CommonType } from "@/types/dataType";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
// 
export default function EnrollSeriesRatioModalBody({
    platformName,
    ratio,
    ratioError,
    setRatio,
    setRatioError,
}: ModalBodyProps) {
    const changeRatio = (e: ChangeEvent<HTMLInputElement>) => {
        const res = validateRatio({
            value: e.target.value,
            error: "ratioError",
            field: "정산비율",
            mandatory: true,
            decimal: true,
        });
        setRatio(res.value);
        setRatioError(res.ratioError);
    };
    return (
        <ModalBodyContainer>
            <Text text={`선택하신 시리즈의 [${platformName}] 정산비율을 입력해주세요.`} 
            customStyle={{
                fontSize:"14px"
            }}/>
            <Body>
                <Label>정산비율</Label>
                <Content>
                    <ValidateWrapper dataError={ratioError}>
                        <TextWrapper>
                            <TextInput value={ratio} placeholder="정산비율" onChangeHandler={changeRatio} customStyle={{height:"32px"}}/>
                            <Sub> %</Sub>
                        </TextWrapper>
                    </ValidateWrapper>
                </Content>
            </Body>
        </ModalBodyContainer>
    );
}

const ModalBodyContainer = styled.div`
    padding: 0 20px;
`;
const Body = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;
`;
const Label = styled.div`
    display: flex;
    align-items: center;
    width: 120px;
    height: 32px;
`;
const Content = styled.div`
    border: 1px red;
    width: 200px;
`;
const TextWrapper = styled.div`
    display: flex;
    align-items: center;
`
const Sub = styled.div`
    color: ${({ theme }) => theme.colors.gray600};
    margin-left: 8px;
`
interface ModalBodyProps {
    platformName: string;
    ratio: CommonType.Ratio | string;
    setRatio: Dispatch<SetStateAction<CommonType.Ratio | string>>;
    ratioError: string;
    setRatioError: Dispatch<SetStateAction<string>>;
}