import styled from "styled-components";
import { ChangeEvent, useEffect } from "react";

function InputLabel({
    labelName,
    inputPlaceholder,
    inputID = "test",
    inputType = "text",
    value,
    onChange,
}: {
    labelName: string;
    inputPlaceholder: string;
    inputID: string;
    inputType: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
    useEffect(() => {}, [value]);

    return (
        <InputBox>
            <label htmlFor={inputID}>{labelName}</label>
            <Input
                type={inputType}
                id={inputID}
                value={value}
                placeholder={inputPlaceholder}
                onChange={onChange}
            />
        </InputBox>
    );
}

export default InputLabel;

const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.gray600};
    }
    & + & {
        margin-top: 24px;
    }
`;

const Input = styled.input`
    margin-top: 10px;
    width: 100%;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    padding: 8px;
    height: 60px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
`