import styled from "styled-components";
import PublisherTagWrapper from "@/components/molecules/dashboard/author/PublisherTagWrapper";
import { useAppSelector, RootState } from "@/modules/store";

export default function PublisherTagContainer() {
    const { publisherList } = useAppSelector((state: RootState) => state.authorDashboard);
    
    // const publisherList = [
    //     { publisher_id: 0, publisher_name: "전체", imgPath: "" },
    //     { publisher_id: 20, publisher_name: "뷰컴즈", imgPath: "viewcommz" },
    //     { publisher_id: 5, publisher_name: "연필", imgPath: "pencil" },
    //     { publisher_id: 4, publisher_name: "코핀북스", imgPath: "copin" },
    // ]

    return (
        <StyledContainer>
            {publisherList.map(p => (
                <PublisherTagWrapper 
                    key={publisherList.indexOf(p)} 
                    publisher_id={p.publisher_id}
                    publisher_name={p.publisher_name}
                    imgPath={p.imgPath}
                />
            ))}
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    height: 60px;
`