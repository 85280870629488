import styled from "styled-components";
import { NavLink } from "react-router-dom";

function LoginMenu() {
    return (
        <ButtonWrap>
            <TextButton text="아이디 찾기" to="/findid"/>
            <Line />
            <TextButton text="비밀번호 찾기" to="/findpw"/>
            <Line />
            <TextButton text="회원가입" to="/signup"/>
        </ButtonWrap>
    );
}

function TextButton({ text, to }: { text: string, to: string }) {
    
    return (
        <ButtonStyle onClick={() => window.open(`${window.location.origin}${to}`)}>
            {/* <NavLink to={to}> */}
                {text}
            {/* </NavLink> */}
        </ButtonStyle>
    );
}

const ButtonWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 32px;
    margin: 24px 0;
`

const ButtonStyle = styled.button`
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    background: none;
    cursor: pointer;
    a {
        color: ${({ theme }) => theme.colors.gray600};
    }
`

const Line = styled.div`
    height: 15px;
    width: 1px;
    background: ${({ theme }) => theme.colors.gray600};
`

export default LoginMenu;
