import styled from "styled-components";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import TableOptions from "@/components/molecules/sales-inquiry/author/TableOptions";
import SalesSummary from "@/components/molecules/sales-inquiry/author/SalesSummary";
import useExcelDownload from "@/modules/hooks/useExcelDownload";
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { addComma } from "@/modules/utils/filter";

export default function EtcTableTop() {
    const { items, total_amt, total_cnt } = useSelectorTyped((state: RootState) => state.inquiryAuthorEtc);
    const { setItemsPerPage } = usePagination({ from: "etc" });
    const downloadExcel = useExcelDownload();
    const { alert } = useAlert();

    const changeItemPerPage = (itemPerPage: number) => setItemsPerPage(itemPerPage);
    const downloadEtcExcel = () => {
        if (items.length > 0) {
            let rows: any[] = [];
            items.forEach((item) => {
                let row = {
                    플랫폼: item.platform_name === null ? "해당없음" : item.platform_name,
                    지급일자: item.date,
                    "지급 출판사": item.publisher_name,
                    저자: item.author_name,
                    금액: addComma(item.pre_amount),
                    정산비율: item.st_ratio,
                    "적용 세목":
                        item.tax_type === "N"
                            ? "해당없음"
                            : item.tax_type === "E"
                            ? "기타"
                            : item.author_tax_type === "D"
                            ? "개인(국내 거주자)"
                            : item.author_tax_type === "A"
                            ? "개인(국내 비거주자)"
                            : "기타사업자",
                    "적용 세액": addComma(item.tax_amount),
                    지급금액: addComma(item.amount),
                    지급사유: item.reason,
                };
                rows.push(row);
            });
            downloadExcel({ json: rows, excelFileName: "[정산] 기타 지급금", sheetNames: "기타 지급금" });
        } else {
            alert("info", "조회 완료", "조회된 데이터가 없습니다.");
        }
    };

    return (
        <TableTopContainer>
            <SalesSummary total={total_cnt} amountName={"지급 합계"} amount={total_amt} />
            <TableOptions onChangeItemPerPage={changeItemPerPage} onExcelDownload={downloadEtcExcel} />
        </TableTopContainer>
    );
}

const TableTopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
