import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";

export default function Agreement2() {
    return(
        <StyledContainer>
            <Title>제2장 회원가입</Title>
            <SubTitle>제8조(회원가입)</SubTitle>
            <UlStyled>
                <LiStyled>① 회원가입은 &quot;이용자&quot;가 약관의 내용에 대하여 동의를 하고 회원가입신청을 한 후 &quot;회사&quot;가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</LiStyled>
                <LiStyled>② 회원가입신청서에는 다음 사항을 기재해야 합니다. 1호 내지 3호의 사항은 필수 사항이며, 그 외의 사항은 선택 사항입니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. &quot;회원&quot;의 성명과 주민등록번호 또는 인터넷상 개인식별번호</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. &quot;아이디&quot;와 &quot;비밀번호&quot;</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 전자우편주소</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. 이용하려는 &quot;콘텐츠&quot;의 종류</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;5. 기타 &quot;회사&quot;가 필요하다고 인정하는 사항</LiStyled>
                </OlStyled>
                <LiStyled>③ &quot;회사&quot;는 상기 &quot;이용자&quot;의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, &quot;회사&quot;는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. 실명이 아니거나 타인의 명의를 이용한 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</LiStyled>
                </OlStyled>
                <LiStyled>④ &quot;회사&quot;는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</LiStyled>
                <LiStyled>⑤ 제3항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, &quot;회사&quot;는 이를 신청자에게 알려야 합니다. &quot;회사&quot;의 귀책사유 없이 신청자에게 통지할 수 없는 경우에는 예외로 합니다.</LiStyled>
                <LiStyled>⑥ 회원가입계약의 성립 시기는 &quot;회사&quot;의 승낙이 &quot;이용자&quot;에게 도달한 시점으로 합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제9조(미성년자의 회원가입에 관한 특칙)</SubTitle>
            <UlStyled>
                <LiStyled>① 만 14세 미만의 &quot;이용자&quot;는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입을 신청하고 본인의 개인정보를 제공하여야 합니다.</LiStyled>
                <LiStyled>② 회사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 14세 미만 이용자에 대하여는 가입을 취소 또는 불허합니다.</LiStyled>
                <LiStyled>③ 만 14세 미만 &quot;이용자&quot;의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 &quot;회사&quot;는 지체 없이 필요한 조치를 취해야 합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제10조(회원정보의 변경)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회원&quot;은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.</LiStyled>
                <LiStyled>② &quot;회원&quot;은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 &quot;회사&quot;에 대하여 그 변경사항을 알려야 합니다.</LiStyled>
                <LiStyled>③ 제2항의 변경사항을 &quot;회사&quot;에 알리지 않아 발생한 불이익에 대하여 &quot;회사&quot;는 책임지지 않습니다.</LiStyled>
            </UlStyled>
            <SubTitle>제11조(&quot;회원&quot;의 &quot;아이디&quot; 및 &quot;비밀번호&quot;의 관리에 대한 의무)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회원&quot;의 &quot;아이디&quot;와 &quot;비밀번호&quot;에 관한 관리책임은 &quot;회원&quot;에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</LiStyled>
                <LiStyled>② &quot;회원&quot;은 &quot;아이디&quot; 및 &quot;비밀번호&quot;가 도용되거나 제3자에 의해 사용되고 있음을 인지한 경우에는 이를 즉시 &quot;회사&quot;에 통지하고 &quot;회사&quot;의 안내에 따라야 합니다.</LiStyled>
                <LiStyled>③ 제2항의 경우에 해당 &quot;회원&quot;이 &quot;회사&quot;에 그 사실을 통지하지 않거나, 통지한 경우에도 &quot;회사&quot;의 안내에 따르지 않아 발생한 불이익에 대하여 &quot;회사&quot;는 책임지지 않습니다.</LiStyled>
            </UlStyled>
            <SubTitle>제12조(&quot;회원&quot;에 대한 통지)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회사&quot;가 &quot;회원&quot;에 대한 통지를 하는 경우 &quot;회원&quot;이 지정한 전자우편주소로 할 수 있습니다.</LiStyled>
                <LiStyled>② &quot;회사&quot;는 &quot;회원&quot; 전체에 대한 통지의 경우 7일 이상 &quot;회사&quot;의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, &quot;회원&quot; 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 통지를 합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제13조(회원탈퇴 및 자격 상실 등)</SubTitle>
            <UlStyled>
                <LiStyled>① &quot;회원&quot;은 &quot;회사&quot;에 언제든지 탈퇴를 요청할 수 있으며 &quot;회사&quot;는 즉시 회원탈퇴를 처리합니다.</LiStyled>
                <LiStyled>② &quot;회원&quot;이 다음 각호의 사유에 해당하는 경우, &quot;회사&quot;는 회원자격을 제한 및 정지시킬 수 있습니다.</LiStyled>
                <OlStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;1. 가입신청 시에 허위내용을 등록한 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;2. &quot;회사&quot;의 서비스이용대금, 기타 &quot;회사&quot;의 서비스이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;3. 다른 사람의 &quot;회사&quot;의 서비스이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</LiStyled>
                    <LiStyled>&nbsp;&nbsp;&nbsp;&nbsp;4. &quot;회사&quot;를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</LiStyled>
                </OlStyled>
                <LiStyled>③ &quot;회사&quot;가 회원자격을 제한·정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 &quot;회사&quot;는 회원자격을 상실시킬 수 있습니다.</LiStyled>
                <LiStyled>④ &quot;회사&quot;가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 &quot;회원&quot;에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</LiStyled>
            </UlStyled>
        </StyledContainer>
        
    )
}

const StyledContainer = styled.div`

`

const Title = styled.h4`
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 40px;
    width: auto;
`

const SubTitle = styled.h6`
    font-weight: 700;
    line-height: 20px;
    margin: 16px 0px;
`

const UlStyled = styled.ul`
`

const LiStyled = styled.li`
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${colors.gray600}
`

const OlStyled = styled.ol`
    margin: 16px 0px;
`