import styled from "styled-components";
import Text from "@/components/atoms/text";
import { ReactComponent as SizeH1 } from "@/assets/icons/ebook-maker/sizeH1.svg";
import { ReactComponent as SizeH2 } from "@/assets/icons/ebook-maker/sizeH2.svg";
import { ReactComponent as SizeH3 } from "@/assets/icons/ebook-maker/sizeH3.svg";
import { ReactComponent as SizeH4 } from "@/assets/icons/ebook-maker/sizeH4.svg";
import { ReactComponent as SizeH5 } from "@/assets/icons/ebook-maker/sizeH5.svg";
import { ReactComponent as SizeH6 } from "@/assets/icons/ebook-maker/sizeH6.svg";
import { ReactComponent as SizeH1Gray } from "@/assets/icons/ebook-maker/sizeH1-gray.svg";
import { ReactComponent as SizeH2Gray } from "@/assets/icons/ebook-maker/sizeH2-gray.svg";
import { ReactComponent as SizeH3Gray } from "@/assets/icons/ebook-maker/sizeH3-gray.svg";
import { ReactComponent as SizeH4Gray } from "@/assets/icons/ebook-maker/sizeH4-gray.svg";
import { ReactComponent as SizeH5Gray } from "@/assets/icons/ebook-maker/sizeH5-gray.svg";
import { ReactComponent as SizeH6Gray } from "@/assets/icons/ebook-maker/sizeH6-gray.svg";
import { ReactComponent as AlignLeftGray } from "@/assets/icons/ebook-maker/alignLeft-gray.svg";
import { ReactComponent as AlignCenterGray } from "@/assets/icons/ebook-maker/alignCenter-gray.svg";
import { ReactComponent as AlignRightGray } from "@/assets/icons/ebook-maker/alignRight-gray.svg";
import { ReactComponent as AlignLeft } from "@/assets/icons/ebook-maker/alignLeft.svg";
import { ReactComponent as AlignCenter } from "@/assets/icons/ebook-maker/alignCenter.svg";
import { ReactComponent as AlignRight } from "@/assets/icons/ebook-maker/alignRight.svg";
import { ReactComponent as ItalicGray } from "@/assets/icons/ebook-maker/italic-gray.svg";
import { ReactComponent as UnderlineGray } from "@/assets/icons/ebook-maker/underline-gray.svg";
import { ReactComponent as Italic } from "@/assets/icons/ebook-maker/italic.svg";
import { ReactComponent as Underline } from "@/assets/icons/ebook-maker/underline.svg";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { Copyright } from "@/types/dataType";
import { colors } from "@/assets/styles/theme";

export default function SubtitleStyleContainer() {
    const { subtitleSize, subtitleItalic, subtitleUnderline, subtitleAlign } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const labelTextStyle = { fontSize: "14px", width: "120px" }

    const selectSubtitleSize = (size: Copyright.Size) => {
        dispatch({ type: "ebookMaker/setSubtitleSize", payload: size });
    }

    const toggleItalic = () => {
        dispatch({ type: "ebookMaker/toggleSubtitleItalic" });
    }

    const toggleUnderline = () => {
        dispatch({ type: "ebookMaker/toggleSubtitleUnderline" });
    }

    const selectSubtitleAlign = (align: Copyright.Align) => {
        dispatch ({ type: "ebookMaker/setSubtitleAlign", payload: align });
    }

    return (
        <StyledContainer>
            <FieldWrapper>
                <Text text="소제목 스타일" customStyle={{ fontSize: "14px" }} />
            </FieldWrapper>
            <ContentWrapper>
                <RowWrapper>
                    <Text text="소제목 폰트 크기" customStyle={labelTextStyle} />
                    <IconWrapper width="280px">
                        {subtitleSize === Copyright.Size.H1 ? <SizeH1 /> : <SizeH1Gray onClick={() => selectSubtitleSize(Copyright.Size.H1)} />}
                        {subtitleSize === Copyright.Size.H2 ? <SizeH2 /> : <SizeH2Gray onClick={() => selectSubtitleSize(Copyright.Size.H2)} />}
                        {subtitleSize === Copyright.Size.H3 ? <SizeH3 /> : <SizeH3Gray onClick={() => selectSubtitleSize(Copyright.Size.H3)} />}
                        {subtitleSize === Copyright.Size.H4 ? <SizeH4 /> : <SizeH4Gray onClick={() => selectSubtitleSize(Copyright.Size.H4)} />}
                        {subtitleSize === Copyright.Size.H5 ? <SizeH5 /> : <SizeH5Gray onClick={() => selectSubtitleSize(Copyright.Size.H5)} />}
                        {subtitleSize === Copyright.Size.H6 ? <SizeH6 /> : <SizeH6Gray onClick={() => selectSubtitleSize(Copyright.Size.H6)} />}
                    </IconWrapper>
                </RowWrapper>
                <RowWrapper>
                    <Text text="소제목 스타일" customStyle={labelTextStyle} />
                    <IconWrapper width="88px">
                        {subtitleItalic ? <Italic fill={colors.blue500} onClick={toggleItalic} /> : <ItalicGray onClick={toggleItalic} />}
                        {subtitleUnderline ? <Underline fill={colors.blue500} onClick={toggleUnderline} /> : <UnderlineGray onClick={toggleUnderline} />}
                    </IconWrapper>
                </RowWrapper>
                <RowWrapper>
                    <Text text="소제목 정렬" customStyle={labelTextStyle} />
                    <IconWrapper width="136px">
                        {subtitleAlign === Copyright.Align.LEFT ? <AlignLeft /> : <AlignLeftGray onClick={() => selectSubtitleAlign(Copyright.Align.LEFT)} />}
                        {subtitleAlign === Copyright.Align.CENTER ? <AlignCenter /> : <AlignCenterGray onClick={() => selectSubtitleAlign(Copyright.Align.CENTER)} />}
                        {subtitleAlign === Copyright.Align.RIGHT ? <AlignRight /> : <AlignRightGray onClick={() => selectSubtitleAlign(Copyright.Align.RIGHT)} />}
                    </IconWrapper>
                </RowWrapper>
            </ContentWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    height: 180px;
`

const FieldWrapper = styled.div`
    display: flex;
    width: 280px;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    margin-right: 20px;
`

const ContentWrapper = styled.div`
    width: 448px;
    height: 100%;
    padding: 0 24px;
`

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`

const IconWrapper = styled.div<{ width: string; }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${({ width }) => width};
    height: 60px;
    svg {
        cursor: pointer;
    }
`