import styled from "styled-components";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { EbookMaker } from "@/types/dataType";

export default function SubtitleMakeTypeContainer() {
    const { subtitleMakeType } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const selectSubtitleMakeType = (type: EbookMaker.SubtitleMakeType) => {
        dispatch({ type: "ebookMaker/setSubtitleMakeType", payload: type });
    }

    return (
        <StyledContainer>
            <FieldWrapper>
                <Text text="소제목 생성 방식" customStyle={{ fontSize: "14px", fontWeight: "500" }} />
            </FieldWrapper>
            <ContentWrapper>
                <Button 
                    type={subtitleMakeType === EbookMaker.SubtitleMakeType.NEWPAGE ? "main-outline" : "gray-outline"} 
                    label="별도페이지" 
                    onClickHandler={() => selectSubtitleMakeType(EbookMaker.SubtitleMakeType.NEWPAGE)} 
                    customStyle={{ width: "120px" }}
                />
                <Button 
                    type={subtitleMakeType === EbookMaker.SubtitleMakeType.NEWBODY ? "main-outline" : "gray-outline"} 
                    label="본문에 포함" 
                    onClickHandler={() => selectSubtitleMakeType(EbookMaker.SubtitleMakeType.NEWBODY)} 
                    customStyle={{ width: "120px" }} 
                />
            </ContentWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    height: 60px;
`

const FieldWrapper = styled.div`
    display: flex;
    width: 280px;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    margin-right: 20px;
`

const ContentWrapper = styled.div`
    display: flex;
    width: 296px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
`