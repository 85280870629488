import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import Text from "@/components/atoms/text";
import ConsultContent from "@/components/molecules/landing/consult/ConsultContent";
import { ReactComponent as WarningIcon } from "@/assets/icons/warning-line.svg";

export default function Consult() {
    return (
        <ConsultContainer>
            <TitleContainer>
                <Text text="문의하기" customStyle={titleStyle}></Text>
                <BlockClick />
                <ReadyTextBox>
                    <WarningIcon width="32" height="32" style={{ marginBottom: "16px" }} />
                    서비스 준비중입니다.
                </ReadyTextBox>
                <Text
                    text="아래 양식을 입력 후 제출해주시면 빠른 시일내로 안내해 드리겠습니다."
                    customStyle={descStyle}
                />
            </TitleContainer>
            <ConsultContent />
        </ConsultContainer>
    );
}

// 임시 차단
const BlockClick = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background: linear-gradient(to bottom, #ffffff94,#ffffffb5, #ffffff);
    z-index: 1;
`;
const ReadyTextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 24px;
    font-weight: 700;
`;

const titleStyle = {
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "35px",
    margin: "0px 0px 16px 0px",
};
const descStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "28px",
    color: colors.gray600,
    margin: "0px 0px 16px 0px",
    width: "460px",
};

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;
const ConsultContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 900px;
    margin: 40px auto;
`;
