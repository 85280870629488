import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import Button from "@/components/atoms/button";
import { addComma } from "@/modules/utils/filter";
import ApexCharts from 'react-apexcharts';
import { RootState, useAppSelector } from "@/modules/store";
import { useState } from "react";

export default function SalesContainer() {
    const { contentSalesInfo } = useAppSelector((state: RootState) => state.pubPortfolio);
    const { dailySalesHistory, monthlySalesHistory } = contentSalesInfo;
    const [type, setType] = useState("daily");

    let salesHistory: any;
    if (type === "daily") {
        salesHistory = {
            name: dailySalesHistory.salesHistory[0] ? dailySalesHistory.salesHistory[0].name : [],
            labels: dailySalesHistory.labels,
            data: dailySalesHistory.salesHistory[0] ? dailySalesHistory.salesHistory[0].data : [],
        }
    } else {
        salesHistory = {
            name: monthlySalesHistory.salesHistory[0] ? monthlySalesHistory.salesHistory[0].name : [],
            labels: monthlySalesHistory.labels,
            data: monthlySalesHistory.salesHistory[0] ? monthlySalesHistory.salesHistory[0].data : []
        }
    }

    const salesHistoryData: ChartOptions = {
        series: [
            {
                name: '이번주',
                type: 'column',
                data: salesHistory.data
            }, 
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                foreColor: colors.gray600,
                toolbar: {
                    show: false
                },
                events: {
                    // dataPointSelection: function(event: Event, chartContext: any, config: any) {
                    // },
                }
            },
            colors: [function({ value, dataPointIndex, w}: any) {
                if (dataPointIndex === salesHistory.data.length-1) {
                    return colors.blue500;
                } else {
                    return colors.blue50;
                }
            }],
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8
                    }
                }
            },
            stroke: {
                width: [0, 4, 5],
                curve: 'smooth',
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    borderRadius: 10,
                }
            },
            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            labels: salesHistory.labels,
            markers: {
                size: 0
            },
            xaxis: {
                type: 'string',
                crosshairs: {
                    show: false
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                labels: {
                    formatter: function(val: number) {
                        return `${(val / 10000).toFixed(0)}만원`
                    }
                }
            },
            tooltip: {
                shared: false,
                intersect: true,
                followCursor: false,
                custom: function({ series, seriesIndex, dataPointIndex, w }: any) {
                    return `
                        <div class="tooltip-box">
                            <span>${series[seriesIndex][dataPointIndex].toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원"}</span>
                        </div>
                    `
                },
            },
            grid: { show: false },
        },
    };

    return (
        <Container>
            <LeftWrapper>
                <AccumulatedSalesWrapper>
                    <SalesLabelWrapper>누적 판매금액</SalesLabelWrapper>
                    <SalesValueWrapper>{addComma(contentSalesInfo.accumulatedSales.amount || 0)}원</SalesValueWrapper>
                </AccumulatedSalesWrapper>
                <AccumulatedSalesWrapper>
                    <SalesLabelWrapper>누적 판매량</SalesLabelWrapper>
                    <SalesValueWrapper>{addComma(contentSalesInfo.accumulatedSales.quantity || 0)}건</SalesValueWrapper>
                </AccumulatedSalesWrapper>
            </LeftWrapper>
            <SalesHistoryWrapper>
                <HistoryTopLineWrapper>
                    <HistoryTitleWrapper>판매추이</HistoryTitleWrapper>
                    <TypeBtnWrapper>
                        <Button
                            type={type === "daily" ? "main-fill" : "gray-outline"}
                            label="일별"
                            onClickHandler={() => setType("daily")}
                            customStyle={{ width: "60px", fontSize: "12px" }}
                        />
                        <Button
                            type={type === "monthly" ? "main-fill" : "gray-outline"}
                            label="월별"
                            onClickHandler={() => setType("monthly")}
                            customStyle={{ width: "60px", fontSize: "12px" }}
                        />
                    </TypeBtnWrapper>
                </HistoryTopLineWrapper>
                <StyledCharts 
                    options={salesHistoryData.options}
                    series={salesHistoryData.series}
                    types="line"
                    width={364}
                    height={190}
                />
            </SalesHistoryWrapper>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    padding-left: 8px;
    margin-top: 8px;
`
const LeftWrapper = styled.div`
    width: 200px;
`

const SalesHistoryWrapper = styled.div`
    width: 380px;
    height: 250px;
    margin-left: 8px;
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
    padding: 8.5px 8px;
`

const AccumulatedSalesWrapper = styled.div`
    width: 200px;
    height: 100px;
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 24px 20px;
`

const SalesLabelWrapper = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${colors.gray600};
    margin-bottom: 8px;
`

const SalesValueWrapper = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: ${colors.black};
`

const HistoryTopLineWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 44px;
`

const HistoryTitleWrapper = styled.div`
    font-size: 16px;
    font-weight: 700;
    padding-left: 8px;
`

const TypeBtnWrapper = styled.div`
    display: flex;
    align-items: center;
`

const StyledCharts = styled(ApexCharts)`
    .tooltip-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F5EEF8;
        border: 1px solid #AF7AC5;
        width: 85px;
        height: 32px;
        font-size: 10px;
    }
`

interface ChartOptions {
    series: Array<SeriesType>;
    options: object;
}

interface SeriesType {
    name: string;
    type: string;
    data: Array<number>
}