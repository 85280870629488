import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setDate, setPlatformId, resetStore } from "@/pages/settlement/sales-entry/monthly/monthlySlice";
import TemplateLayout from "@/components/molecules/sales-inquiry/common/TemplateLayout";
import MonthlyTable from "@/components/molecules/sales-entry/monthly/MonthlyTable";
import MonthlyTableTop from "@/components/molecules/sales-entry/monthly/MonthlyTableTop";
import MonthlyTableFilter from "@/components/molecules/sales-entry/monthly/MonthlyTableFilter";

export default function Monthly() {
    const { uploaderMsg } = useSelectorTyped((state: RootState) => state.entryMonthly);
    const { platform, salesYearmon } = useSelectorTyped((state: RootState) => state.uploader);
    const dispatch = useAppDispatch();

    // 업로더
    useEffect(() => {
        if (platform) dispatch(setPlatformId(Number(platform)));
        if (salesYearmon) dispatch(setDate({ yearmon: salesYearmon }));
    }, [platform, salesYearmon]);

    useEffect(() => {
        return () => {
            dispatch(resetStore());
        };
    }, []);

    return (
        <TemplateLayout>
            <input type="hidden" id="uploaderMsg" value={uploaderMsg} />
            <MonthlyTableFilter />
            <MonthlyTableTop />
            <MonthlyTable />
        </TemplateLayout>
    );
}
