import { colors } from "@/assets/styles/theme";
import { RootState, useAppSelector } from "@/modules/store";
import styled from "styled-components";
import { dateFormat } from "@/modules/utils/filter";
import { ReactComponent as More } from "@/assets/icons/portfolio/more.svg"
import PromotionDetailModal from "./PromotionDetailModal";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import { useState } from "react";
import { LatestPromotion } from "@/pages/portfolio/author/types";

export default function PromotionContainer() {
    const { latestPromotion } = useAppSelector((state: RootState) => state.authorPortfolio);
    const [promotionModal, showPromotionModal] = useState(false);
    const [promotionInfo, setPromotionInfo] = useState({});

    const promotionModalOn = (p: LatestPromotion) => {
        setPromotionInfo(p);
        showPromotionModal(true);
    }

    return (
        <Container>
            <TitleWrapper>최신 프로모션</TitleWrapper>
            <CardListWrapper>
                {latestPromotion.map((p, idx) => (
                    <CardWrapper key={idx}>
                        <Img src={p.icon_path} />
                        <CenterWrapper>
                            <PromotionNameWrapper>{p.promotion_name}</PromotionNameWrapper>
                            <PromotionPeriodWrapper>
                                {dateFormat("dash", p.promotion_start_at)} ~ {dateFormat("dash", p.promotion_end_at)}
                            </PromotionPeriodWrapper>
                        </CenterWrapper>
                        <MoreBtnWrapper>
                            <More onClick={() => promotionModalOn(p)}/>
                        </MoreBtnWrapper>
                    </CardWrapper>
                ))}
            </CardListWrapper>
            <ModalPortal>
                <PromotionDetailModal
                    show={promotionModal}
                    close={() => showPromotionModal(false)}
                    from="portfolio-detail"
                    promotionInfo={promotionInfo}
                />
            </ModalPortal>
        </Container>
    )
}

const Container = styled.div`
    margin-top: 40px;
`

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
`

const CardListWrapper = styled.ul`
    display: flex;
    margin-top: 26px;
`

const CardWrapper = styled.li`
    display: flex;  
    align-items: center;
    width: 375px;
    height: 120px;
    padding: 14px 20px;
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
    margin-right: 40px;
    
    &:last-child {
        mragin-right: 0;
    }
`

const Img = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 100%;
`

const CenterWrapper = styled.div`
    padding: 34px 8px;
    width: 278px;
`

const PromotionNameWrapper = styled.div`
    font-size: 14px;
    font-weight: 700;
`

const PromotionPeriodWrapper = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: ${colors.gray600};
    margin-top: 10px;
`

const MoreBtnWrapper = styled.div`
    width: 24px;
    height: 24px;

    svg {
        cursor: pointer;
    }
`