import styled from "styled-components";
import Text from "@/components/atoms/text";
import DailyPlatformAnalysisWrapper from "@/components/molecules/dashboard/publisher/DailyPlatformAnalysisWrapper";

export default function DailyPlatformAnalysisContainer() {
    return (
        <StyledContainer>
            <TitleWrapper />
            <DailyPlatformAnalysisWrapper />
        </StyledContainer>
    )
}

function TitleWrapper() {
    const titleStyle = {
        fontSize: "16px",
        fontWeight: "700",
    }

    return (
        <StyledTitleWrapper>
            <Text text="일일 플랫폼 분석" customStyle={titleStyle}/>
        </StyledTitleWrapper>
    )
}

const StyledContainer = styled.div`
    width: 1200px;
    height: 170px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    margin-bottom: 24px;
`

const StyledTitleWrapper = styled.div`
    padding: 16px 14px;
    height: 48px;
`
