import styled from "styled-components";
import OptionContainer from "@/components/molecules/statement/publisher/common/OptionContainer";
import AuthorListContainer from "@/components/molecules/statement/publisher/common/AuthorListContainer";
import { useEffect } from "react";
import useLoading from "@/modules/hooks/useLoading";
import { getIssueStMonth, getIssueStAuthor, getIssueStData, getIssueMgMonth, getIssueMgAuthor, getIssueMgData, getPublisherInfo } from "@/pages/settlement/statement/publisher/slice";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import ButtonContainer from "@/components/molecules/statement/publisher/issue/ButtonContainer";
import MonthlyPreview from "@/components/molecules/statement/publisher/issue/MonthlyPreview";
import MgPreview from "@/components/molecules/statement/publisher/issue/MgPreview";
import { SpecificationType } from "@/pages/settlement/statement/publisher/types";
import useAlert from "@/modules/hooks/useAlert";

export default function Issue() {
    const { specificationType } = useAppSelector((state: RootState) => state.pubStatement);
    const { loadingOn, loadingOff } = useLoading()
    const { alert } = useAlert();
    const dispatch = useAppDispatch();

    const loadPulisherInfo = async () => {
        try {
            await dispatch(getPublisherInfo()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        }
    }

    const loadMonthlyData = async () => {
        try {
            await loadingOn();
            dispatch({ type: "pubStatement/setEmptyData" });
            await dispatch(getIssueStMonth()).unwrap();
            await dispatch(getIssueStAuthor()).unwrap();
            await dispatch(getIssueStData()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    const loadMgData = async () => {
        try {
            await loadingOn();
            dispatch({ type: "pubStatement/setEmptyData" });
            await dispatch(getIssueMgMonth()).unwrap();
            await dispatch(getIssueMgAuthor()).unwrap();
            await dispatch(getIssueMgData()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }
    
    const dateChangeHandler = async () => {
        try {
            await loadingOn();
            if (specificationType === SpecificationType.Monthly) {
                await dispatch(getIssueStAuthor()).unwrap();
                await dispatch(getIssueStData()).unwrap();
            } else {
                await dispatch(getIssueMgAuthor()).unwrap();
                await dispatch(getIssueMgData()).unwrap();
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    const selectAuthorHandler = async () => {
        try {
            await loadingOn();
            if (specificationType === SpecificationType.Monthly) {
                await dispatch(getIssueStData()).unwrap();
            } else {
                await dispatch(getIssueMgData()).unwrap();
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    useEffect(() => {
        loadPulisherInfo();
    }, []);


    useEffect(() => {
        if (specificationType === SpecificationType.Monthly) {
            loadMonthlyData();
        } else {
            loadMgData();
        }
    }, [specificationType]);

    return (
        <IssueTemplate>
            <OptionContainer dateChangeHandler={dateChangeHandler} />
            <DisplayBox>
                <AuthorListContainer selectAuthorHandler={selectAuthorHandler} />
                <RightBox>
                    <ButtonContainer />
                    {specificationType === SpecificationType.Monthly ?
                        <MonthlyPreview /> :
                        <MgPreview />
                    }
                </RightBox>
            </DisplayBox>
        </IssueTemplate>
    )
}

const IssueTemplate = styled.div``

const DisplayBox = styled.div`
    display: flex;
`

const RightBox = styled.div`
    width: 960px;
    margin-top: 24px;
`