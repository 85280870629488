import styled from "styled-components";
import EachPlatformDailyRankWrapper from "@/components/molecules/dashboard/publisher/EachPlatformDailyRankWrapper";
import { RootState, useAppSelector } from "@/modules/store";
import { Oval } from "react-loader-spinner";
import Text from "@/components/atoms/text";
import { colors } from '@/assets/styles/theme';


export default function EachPlatformDailyRankContainer() {
    const { eachPlatformDailyRankData, eachPlatformDailyRankLoading } = useAppSelector((state: RootState) => state.pubDashboard);
    
    if (eachPlatformDailyRankLoading) {
        return (
            <StyledContainer>
                <LoaderWrapper>
                    <Oval color={colors.blue600} secondaryColor={colors.blue100} />
                </LoaderWrapper>
            </StyledContainer>
        )
    }

    const noDataTextStyle = {
        fontSize: "16px",
        textAlign: "center",
        margin: "90px 0 0 0",
        color: colors.gray500
    }

    if (!eachPlatformDailyRankData.length) {
        return (
            <StyledContainer>
                <TitleWrapper />
                <Text text="전일 판매내역이 존재하지 않습니다." customStyle={noDataTextStyle} />
            </StyledContainer>
        )
    }

    return (
        <StyledContainer>
            <TitleWrapper />
            <FlexBox>
                {eachPlatformDailyRankData.map(p => (
                    <EachPlatformDailyRankWrapper 
                        key={p.platform_id} 
                        platform_name={p.platform_name} 
                        color={p.platform_color}
                        salesData={p.salesData}
                    />
                ))}
            </FlexBox>
        </StyledContainer>
    )
}

function TitleWrapper() {
    const titleStyle = {
        fontSize: "16px",
        fontWeight: "700",
    }

    return (
        <StyledTitleWrapper>
            <Text text="플랫폼별 일별 판매순위 top3" customStyle={titleStyle}/>
        </StyledTitleWrapper>
    )
}

const FlexBox = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledContainer = styled.div`
    width: 1200px;
    height: 290px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    margin-bottom: 24px;
`

const StyledTitleWrapper = styled.div`
    padding: 16px 14px;
    height: 48px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`
