import styled from "styled-components";
import Text from "@/components/atoms/text";
import theme from "@/assets/styles/theme";
import { RootState, useAppSelector } from "@/modules/store";

export default function EachPlatformSettlementTable() {
    const { eachPlatformSettlementData } = useAppSelector((state: RootState) => state.pubDashboard);
    const tableHeader = ["", "출판사 정산금", "선인세 차감", "선인세 추가", "기타 지급금", "총 정산금"]
    const headerTextStyle = {
        fontSize: "12px",
        fontWeight: "500",
    }

    const bodyTextStyle = {
        fontSize: "12px",
        fontWeight: "500",
        textAlign: "center"
    }

    return (
        <StyledTable>
            <StyledThead>
                <StyledTr>
                    {tableHeader.map(h => (
                        <StyledTh key={h}>
                            <Text 
                                text={h} 
                                customStyle={{
                                    ...headerTextStyle,
                                    textAlign: tableHeader.indexOf(h) === tableHeader.length-1 ? "right" : "center"
                                }} />
                        </StyledTh>
                    ))}
                </StyledTr>
            </StyledThead>
            <StyledTbody>
                {eachPlatformSettlementData.map(p => (
                    <StyledTr key={p.platform_name}>
                        <StyledTd><Text text={p.platform_name} customStyle={bodyTextStyle} /></StyledTd>
                        <StyledTd><Text text={p.amount_pu_label} customStyle={{ ...bodyTextStyle, color: theme.colors.error }} /></StyledTd>
                        <StyledTd><Text text={p.mg_deduction_label} customStyle={{ ...bodyTextStyle, color: theme.colors.information }} /></StyledTd>
                        <StyledTd><Text text={p.mg_add_label} customStyle={bodyTextStyle} /></StyledTd>
                        <StyledTd><Text text={p.etc_label} customStyle={bodyTextStyle} /></StyledTd>
                        <StyledTd><Text text={p.totalAmount_label} customStyle={{ ...bodyTextStyle, textAlign: "right", fontWeight: "700" }} /></StyledTd>
                    </StyledTr>
                ))}
            </StyledTbody>
        </StyledTable>
    )
}

const StyledTable = styled.table`
    width: 100%;
    height: 288px;
`

const StyledThead = styled.thead`
    display: table;
    width: calc(100% - 8px);
    height: 48px;
`

const StyledTh = styled.th`
    padding: 18px 8px;
    width: calc(100% / 6);
    &:last-child {
        padding-right: 24px;
    }
`

const StyledTbody = styled.tbody`
    display: block;
    max-height: 240px;
    overflow-y: auto;
`

const StyledTr = styled.tr`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
`

const StyledTd = styled.td`
    padding: 18px 8px;
    width: calc(100% / 6);
    &:last-child {
        padding-right: 24px;
    }
`