import styled from "styled-components";
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";

import Text from "@/components/atoms/text";
import { alertActions } from "@/pages/common/alertSlice";
import { ReactComponent as ErrorMark } from "@/assets/icons/alert_error.svg";
import { ReactComponent as InfoMark } from "@/assets/icons/alert_info.svg";
import { ReactComponent as SuccessMark } from "@/assets/icons/alert_success.svg";
import { ReactComponent as WarningMark} from "@/assets/icons/alert_warning.svg";

function Alert() {
    const dispatch = useAppDispatch();
    let { show, type, title, text } = useAppSelector((state:RootState) => state.alert);

    function confirm() {
        dispatch(alertActions.setAlertState("resolve"));
    }

    function close() {
        dispatch(alertActions.setAlertState("reject"));
    }

    return (
        <>
            {show ?
            <ModalBackground show={show}>
                <ModalContainer>
                    <AlertHeader>
                        {type === "info" ? <InfoMark width="24px" height="24px" />
                        : type === "success" ? <SuccessMark width="24px" height="24px" fill="#00D422"/>
                        : type === "error" ? <ErrorMark width="24px" height="24px" />
                        : <WarningMark width="24px" height="24px" />}
                        <Text text={title} customStyle={{ fontSize: "16px", fontWeight: "700", margin: "0 0 0 8px"}}/>
                    </AlertHeader>
                    <AlertBody>
                        {text}
                    </AlertBody>
                    <AlertFooter>
                        {
                            type === "confirm" ?
                            <>
                                <AlertButton id="alertY" onClick={confirm}>예</AlertButton>
                                <AlertButton id="alertN" onClick={close}>아니오</AlertButton>
                            </>
                            :
                            <AlertButton alertType="info" onClick={close}>확인</AlertButton>
                        }
                    </AlertFooter>
                </ModalContainer>
            </ModalBackground> 
            :
            <></>
            }
        </>
    )
}

export default Alert;

const ModalBackground = styled.div<{ show: boolean }>`
    display: ${({ show }) => show ? "block" : "none"};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.2);
    opacity: 1;
    z-index: 999999;
`
const ModalContainer = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 153px;
    width: 360px;
    background: ${({ theme }) => theme.colors.white };
    box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
    border-radius: 4px;
`

const AlertHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 20px;
    width: 100%;
    height: 40px;
`

const AlertBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 65px;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
    line-height: 1.4;
`

const AlertFooter = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
`

const AlertButton = styled.button<{ alertType?: "confirm" | "info" | "success" | "error" | "warning" }>`
    width: ${({ alertType }) => alertType === "confirm" ? "180px" : "360px"};
    height: 48px;
    padding: 10px 8px;
    background-color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
    user-select: none;
    border-radius: 4px;
`;