import styled from "styled-components";
import Text from "@/components/atoms/text";
import SelectBox from "@/components/atoms/selectBox/index";
import { SearchOptions } from "@/pages/portfolio/author/types";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { colors } from "@/assets/styles/theme";
import { useState } from "react";
import { searchPortfolioList } from "@/pages/portfolio/author/slice";
import { useAppDispatch } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function SearchBar() {
    const [keyword, setKeyword] = useState("");
    const [searchOption, setSearchOption] = useState(SearchOptions.ALL);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    const options = [
        { name: "전체", value: SearchOptions.ALL },
        { name: "작품명", value: SearchOptions.SERIES_NAME },
        { name: "저자명", value: SearchOptions.AUTHOR_NAME },
    ]

    const changeOption = (v: any) => {
        setSearchOption(v.value);
    }

    const changeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    }

    const search = async () => {
        try {
            await loadingOn();
            await dispatch(searchPortfolioList({
                keyword,
                searchOption
            })).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message)
        } finally {
            await loadingOff();
        }
    }

    const searchTextStyle = {
        width: "200px",
        height: "100%",
        padding: "21px 8px",
        fontSize: "14px",
        fontWeight: "500"
    }

    return (
        <StyledSearchBar>
            <Text text="검색" customStyle={searchTextStyle} />
            <SelectBox
                labelKey="name"
                valueKey="value"
                selected={searchOption}
                options={options}
                onClickValueFunc={changeOption}
                customStyle={{ height: "40px" }}
            />
            <SearchInputWrapper>
                <SearchInput 
                    value={keyword}
                    onChange={changeKeyword}
                    placeholder="검색어를 입력해주세요."
                    onKeyPress={(e) => e.key === "Enter" ? search() : () => {}}
                />
                <IconWrapper>
                    <SearchIcon 
                        size={24} 
                        fill={colors.blue500} 
                        onClick={search}
                    />
                </IconWrapper>
            </SearchInputWrapper>
        </StyledSearchBar>
    )
}

const StyledSearchBar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: ${colors.gray50};
    margin-top: 16px;
`

const SearchInputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 400px;
    height: 40px;
    background-color: ${colors.white};
    margin-left: 8px;
    padding-left: 8px;
    border-radius: 4px;
`

const SearchInput = styled.input`
    font-size: 12px;
    width: 360px;
    border: unset;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 40px;
    height: 100%;
    padding: 8px;
    
    svg {
        cursor: pointer;
    }
`
