import { useEffect, useState } from "react";
import { useAppDispatch } from "@/modules/store";
import { batchMonthlyDelete, getMonthlyData } from "@/pages/settlement/saels-inquiry/publisher/monthly/monthlySlice";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import BatchDeleteMonthlyModalBody from "./bodyModal/BatchDeleteMonthlyModalBody";
import useAlert from "@/modules/hooks/useAlert";
import type { BaseModalProps } from "@/pages/common/types";
import type { CommonType } from "@/types/dataType";

export default function BatchDeleteMonthlyModal({ show, close }: BaseModalProps) {
    const dispatch = useAppDispatch();
    const initialChecked = {
        salesMonth: false,
        stMonth: false,
        platform: false,
        series: false,
        author: false,
    };
    const [checked, setChecked] = useState(initialChecked);
    const [data, setData] = useState({
        salesMonth: "",
        stMonth: "",
        platformId: 0,
        seriesId: 0,
        authorId: 0,
    });
    const { alert } = useAlert();

    useEffect(() => {
        setChecked(initialChecked);
    }, [show]);

    const validateData = async () => {
        if (!checked.salesMonth && !checked.stMonth && !checked.platform && !checked.series && !checked.author) {
            return await alert("warning", "주의", "선택된 항목이 없습니다.");
        }
        if (checked.platform && !data.platformId) return await alert("warning", "주의", "플랫폼을 선택해주세요.");
        if (checked.series && !data.seriesId) return await alert("warning", "주의", "시리즈명을 입력해주세요.");
        if (checked.author && !data.authorId) return await alert("warning", "주의", "저자명을 입력해주세요.");
        if (!checked.salesMonth && !checked.stMonth)
            return await alert("warning", "주의", "정산월 또는 판매월을 선택해주세요.");

        return await alert("confirm", "주의", `선택하신 조건에 해당하는 모든 데이터가 삭제됩니다.\n계속 하시겠습니까?`);
    };

    const apply = async () => {
        const answer = await validateData();
        if (answer !== "Y") return;

        try {
            const apiParams = {
                salesMonth: checked.salesMonth ? data.salesMonth : "",
                stMonth: checked.stMonth ? data.stMonth : "",
                platformId: checked.platform ? data.platformId : 0,
                seriesId: checked.series ? data.seriesId : 0,
                authorId: checked.author ? data.authorId : 0,
            };

            await dispatch(batchMonthlyDelete(apiParams)).unwrap();
            await dispatch(getMonthlyData());
            await alert("info", "완료", "일괄 삭제가 완료되었습니다.");
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        }
        close();
    };

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["조건 삭제"]}
            customStyle={[{ width: "480px", height: "440px" }]}
            applyFunc={apply}
        >
            <BatchDeleteMonthlyModalBody data={data} setData={setData} checked={checked} setChecked={setChecked} />
        </BaseModal>
    );
}
export interface Data {
    salesMonth: CommonType.Date;
    stMonth: CommonType.Date;
    platformId: CommonType.Id;
    seriesId: CommonType.Id;
    authorId: CommonType.Id;
}
export interface CheckboxState {
    salesMonth: boolean;
    stMonth: boolean;
    platform: boolean;
    series: boolean;
    author: boolean;
}
