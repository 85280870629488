import styled from "styled-components"
import DashboardTable from "@/components/molecules/dashboard/publisher/DashboardTable";
import { useAppSelector, useAppDispatch, RootState } from "@/modules/store";
import { Oval } from "react-loader-spinner";
import theme from '@/assets/styles/theme';
import Text from "@/components/atoms/text";

export default function SalesUpDownTableContainer() {
    const { salesUp, salesDown, salesUpDownIsLoading, upDownType } = useAppSelector((state: RootState) => state.pubDashboard);
    const dispatch = useAppDispatch();

    const handleClickRow = (series_name: string) => {
        dispatch({ type: "pubDashboard/setUpDownChart", payload: series_name });
    }

    if (salesUpDownIsLoading) {
        return (
            <StyledContainer>
                <LoaderWrapper>
                    <Oval color={theme.colors.blue600} secondaryColor={theme.colors.blue100} />
                </LoaderWrapper>
            </StyledContainer>
        )
    }

    const headers = [
        { text: "순위", value: "rank", width: "70px", align: "center" },
        { text: "작품명", value: "series_name", width: "230px" },
        { text: `${upDownType === "incline" ? "상승폭" : "하락폭"}(일주일 평균대비)`, value: "ratio", width: "200px" },
        { text: `최대${upDownType === "incline" ? "상승" : "하락"}`, value: "platform_name", width: "140px", align: "center" },
        { text: "판매금액(원)", value: "amount", width: "165px", align: "right" },
    ];

    const noDataTextStyle = {
        fontSize: "16px",
        textAlign: "center",
        margin: "160px 0 0 0",
        color: theme.colors.gray500
    }

    if ((upDownType === "incline" && !salesUp.length) || (upDownType === "decline" && !salesDown.length)) {
        return (
            <StyledContainer>
                <Text text="판매 급상승 데이터가 존재하지 않습니다." customStyle={noDataTextStyle} />
            </StyledContainer>
        )
    }

    return (
        <StyledContainer>
            <DashboardTable 
                headers={headers} 
                items={upDownType === "incline" ? salesUp : salesDown}
                handleClickRow={handleClickRow} 
            />
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 808px;
    height: 348px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`