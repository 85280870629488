import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Meta = ({title, description, url}: MetaProps) => {
    const siteTitle: string = `${title ? title + " :: " : ""}글링에서 시작하는 명쾌한 정산`;
    const siteDesc: string = "글링 정산관리는 전자책 출판사와 작가들을 위한 정산관리 서비스입니다. " +
                             "쉽고 빠르고 정확한 정산관리 서비스를 이용해보세요";
    const siteUrl: string = `https://biz.gling.co.kr${url ? "/" + url : ""}`;
    const ogimg: string = "https://biz.gling.co.kr/og_gling.png";

    const location = useLocation();
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        const naverScript = document.createElement("script");
        naverScript.innerHTML = `
            if(!wcs_add) var wcs_add = {};
            wcs_add["wa"] = "167483c2b5165b";
            if(window.wcs) {wcs_do();}  
        `;
        document.head.appendChild(naverScript);

        // ReactGA.initialize("UA-138046847-1");
        ReactGA.initialize("G-TD2JK9H9P0");
        setInitialized(true);
    }, [])
    
    useEffect(() => {
        if (initialized) {
            ReactGA.send({ 
                hitType: "pageview",
                page: `${location.pathname}${location.search}`
            });
        }
    }, [initialized, location])

    return (
        <Helmet>
            <title>{siteTitle}</title>

            <meta name="description" content={description || siteDesc} />
            <meta name="keywords" content="글링, 정산, 정산관리, 웹소설, 출판사, 출판정산" />

            <meta name="naver-site-verification" content="938a3a1781e5f802f8aa72339ea377512caadc4d" />
            <meta name="google-site-verification" content="T25Cc5K3bjsU2cH9p3J2TGaEImb27Jh85s" />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={siteTitle} />
            <meta property="og:site_name" content={siteTitle} />
            <meta property="og:description" content={description || siteDesc} />
            <meta property="og:image" content={ogimg} />
            <meta property="og:url" content={siteUrl} />

            <meta property="twitter:card" content="summary" />
            <meta property="twitter:title" content={siteTitle} />
            <meta property="twitter:description" content={description || siteDesc} />
            <meta property="twitter:image" content={ogimg} />

            <link rel="canonical" href={siteUrl} />

            <script src="https://wcs.naver.net/wcslog.js" />
        </Helmet>
    );
};

interface MetaProps {
    title?: string;
    description?: string;
    url?: string;
}

export default Meta;