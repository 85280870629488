import styled, { css, keyframes} from "styled-components";
import { useEffect, useState } from "react";
import useAlert from "@/modules/hooks/useAlert";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import Text from "@/components/atoms/text";
import NavBar, { validatePage } from "@/components/molecules/ebook-maker/common/NavBar";
import Form from "@/components/templates/ebook-maker/Form";
import Copyright from "@/components/templates/ebook-maker/Copyright";
import Method from "@/components/templates/ebook-maker/Method";
import Complete from "@/components/templates/ebook-maker/Complete";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { Step } from "./types";
import Preview from "@/components/molecules/ebook-maker/preview";
import useCheckToken from "@/modules/hooks/useCheckToken";
import { convert2Json, convert2File } from "@/pages/ebook-maker/slice";
import useLoading from "@/modules/hooks/useLoading";

export default function EbookMaker() {
    const { 
            currStep, makeType, outFileType, bookInfo, inFiles, contentList, subtitleType,
            subtitleMakeType, subtitleList, noCoverImg, coverFile, time
    } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const tokenStatus = useCheckToken();
    const [disappear, setDisappear] = useState(false);
    const [animateType, setAnimateType] = useState("");

    useEffect(() => {
        // 페이지 처음 진입시 ebookMaker Slice 초기화
        dispatch({ type: "ebookMaker/initState" });
    }, [dispatch])

    useEffect(() => {
        const timer = setInterval(() => {
            if (time > 0) {
                dispatch({ type: "ebookMaker/setTime"});
            } else {
                clearInterval(timer);
            }
        }, 1000)

        return () => clearInterval(timer)
    }, [time, dispatch]);

    if (!tokenStatus) {
        return <></>
    };
    
    const stepHandler = async (step: number, disabled: boolean) => {
        try {
            if (disabled || step === currStep) return;
            
            if (step > currStep) {
                setAnimateType("next");
            } else {
                setAnimateType("prev");
            }
            
            let param;
            switch (currStep) {
                case Step.Form:
                    param = { makeType, outFileType, bookInfo, inFiles };
                    break;
                case Step.Copyright:
                    param = { contentList };
                    break;
                case Step.Method:
                    param = { subtitleType, subtitleMakeType, subtitleList, noCoverImg, coverFile };
                    break;
                case Step.Complete:
                    break;
                default:
                    break;
            }
    
            let validate;
            if (step === Step.OnlyTxt) {
                // 출력파일이 TXT 한개만 선택인 경우, 바로 파일변환
                validate = validatePage(step, param);
                if (validate.status === "success") {
                    await loadingOn();
                    await dispatch(convert2Json(""));
                    await dispatch(convert2File());
                    step = Step.Complete;
                }
            } else {
                validate = validatePage(currStep, param);
            }
                
            if (validate.status === "fail") {
                if (validate.type) {
                    dispatch({ type: "ebookMaker/setBookInfo", payload: {
                        type: `${validate.type}_error`,
                        value: validate.message
                    }});
                }
        
                return alert("warning", "주의", validate.message);
            }
            
            setDisappear(true);
            setTimeout(() => {
                setDisappear(false);
                dispatch({ type: "ebookMaker/setStep", payload: step });
            }, 250);
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
        
    }

    let template: any;
    switch (currStep) {
        case Step.Form:
            template = <Form stepHandler={stepHandler} />;
            break;
        case Step.Copyright:
            template = <Copyright stepHandler={stepHandler} />;
            break;
        case Step.Method:
            template = <Method stepHandler={stepHandler} />;
            break;
        case Step.Complete:
            template = <Complete time={time} stepHandler={stepHandler} />;
            break;
        default:
            break;
    }

    const navBarData = [
        { label: "1. 제작형태 선택", step: Step.Form },
        { label: "2. 판권지", step: Step.Copyright },
        { label: "3. 제작방식 선택", step: Step.Method },
        { label: "4. 제작완료", step: Step.Complete },
    ]

    return (
        <SectionLayout>
            <StyledTitleContainer>
                <Text text="E-BOOK 제작" customStyle={{ fontSize: "20px", fontWeight: "700" }} />
            </StyledTitleContainer>
            <NavBar 
                data={navBarData}
                selected={currStep}
                onClickHandler={stepHandler}
            />
            <TransitionContainer disappear={disappear} animateType={animateType} >
                {template}
            </TransitionContainer>
            {currStep === Step.Copyright ? <EmptyContainer /> : <Preview disappear={disappear} />}
        </SectionLayout>
    )
}

const StyledTitleContainer = styled.div`
    margin-bottom: 20px;
`;

const EmptyContainer = styled.div`
    width: 260px;
    height: 632px;
`

const FlexContainer = styled.div`
    display: flex;
    width: 1720px;
    margin: auto;
`

const nextSlideIn = keyframes`
    from {
        transform: translateX(600px);
        opacity: 0
    }
    to {
        transform: translateX(0px);
        opacity: 1
    }
`

const prevSlideIn = keyframes`
    from {
        transform: translateX(-600px);
        opacity: 0
    }
    to {
        transform: trnaslateX(0px);
        opacity: 1
    }
`

const currSlideLeftOut = keyframes`
    from {
        transform: translateX(0px);
        opacity: 1
    }
    to {
        transform: translateX(-600px);
        opacity: 0
    }
`

const currSlideRightOut = keyframes`
    from {
        transform: trnaslateX(0px);
        opacity: 1
    }
    to {
        transform: translateX(600px);
        opacity: 0
    }
`

const empty = keyframes`
    from {}
    to {}
`

const TransitionContainer = styled.div<{ disappear: boolean, animateType: string }>`
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: ${({ animateType }) => animateType === "next" ? nextSlideIn : animateType === "prev" ? prevSlideIn : empty};
    animation-fill-mode: forwards;

    ${({ disappear, animateType }) => disappear && (animateType === "next" ? 
        css`
            animation-name: ${currSlideLeftOut};
        ` : 
        css`
            animation-name: ${currSlideRightOut};
        `)
    }
`