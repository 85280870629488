import { ReactElement } from "react";
import styled from "styled-components";

function ValidateWrapper({ children, dataError, customStyle }: ValidateProps) {
    return <StyledValidateWrapper
                data-error={dataError ? dataError : ""}
                display={customStyle?.display}
                width={customStyle?.width}
            >
            {children}
            </StyledValidateWrapper>;
}

const StyledValidateWrapper = styled.div<StyleProps>`
    display: ${({ display }) => display ? display : "flex"};
    position: relative;
    width: ${({ width }) => width ? width : "auto"};
    :after {
        font-size: 10px;
        color: #ff0101;
        padding-left: 5px;
        position: absolute;
        bottom: -12px;
        white-space: nowrap;
        content: attr(data-error);
    }
`;

interface ValidateProps {
    children: ReactElement;
    dataError?: string;
    customStyle?: StyleProps;
}

interface StyleProps {
    display?: string;
    width?: string;
}
export default ValidateWrapper;
