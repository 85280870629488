import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";

const initialState: AppFileState = {
    fileList: [],
}

// 파일 업로드
export const uploadAppFile = createAsyncThunk("appFile/uploadAppFile", async (data: any, { getState, rejectWithValue }) => {
    try {
        const { appFile }: any = getState();
        return (await api.post(`/api/publish/${data.plan}/upload/pc/file/${data.type}`, {
            fileList: appFile.fileList,
            fileKey: data.fileKey,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const appFileSlice = createSlice({
    name: "appFile",
    initialState,
    reducers: {
        initState: (state) => {
            Object.assign(state, initialState);
        },
        setFileList: (state, { payload }) => {
            state.fileList = payload;
        }
    },
});

interface AppFileState {
    fileList: File[];
}

interface File {
    file: string;
    name: string;
}

export const appFileActions = appFileSlice.actions;
export default appFileSlice.reducer;