import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Calendar } from "@/assets/icons/mob-landing/settlement-calendar.svg";
import useFadeIn from "@/modules/hooks/useFadeIn";

export default function SettlementSection() {
    const [total, setTotal] = useState("???,???");
    const [counting, setCounting] = useState(false); // countUp 함수 진행 중

    const goalAmount = 4528600;
    const nowAmount = useRef(goalAmount);
    const intervalID = useRef<ReturnType<typeof setInterval>>();
    const containerRef = useRef<HTMLDivElement>(null);

    const { FadeInWrapper, active } = useFadeIn(containerRef);

    useEffect(() => {
        const onScroll = () => {
            const containerRect = containerRef.current?.getBoundingClientRect();
            const containerAbsoluteTop = window.scrollY + (containerRect?.top || 0);
            const PADDING = window.innerHeight * 0.5;
            const startAt = containerAbsoluteTop - PADDING;
            const endAt = startAt + 500;
            const containerHeight = containerRect?.height || 0;

            if (startAt > 0 && (window.scrollY > startAt && window.scrollY < endAt)) {
                if (counting) return;
                setCounting(true);
                countUp()
            } else if (window.scrollY< containerAbsoluteTop - containerHeight || window.scrollY > containerAbsoluteTop + containerHeight) {
                nowAmount.current = goalAmount;
                setTotal("???,???");
                setCounting(false);
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => { window.removeEventListener("scroll", onScroll); }
    }, [counting])

    // 처음엔 빠르게 올라가다 나중엔 느리게 올라가게
    const countUp = () => {
        const regexp = /\B(?=(\d{3})+(?!\d))/g;

        intervalID.current = setInterval(() => {
            if (nowAmount.current < 1) clearTimeout(intervalID.current);

            const step = nowAmount.current / 3;
            nowAmount.current -= step;
            const newTotal = Math.ceil(goalAmount - nowAmount.current);

            setTotal(newTotal.toString().replace(regexp, ","));
        }, 50);
    }

    return (
        <Container ref={containerRef}>
            <FadeInWrapper active={active}>
                <SectionName>글링 정산</SectionName>
                <Title>글링에서{`\n`}정산을 투명하게</Title>
                <Desc>하루, 한 주, 한 달까지{`\n`}모든 데이터를 투명하게 확인하세요!</Desc>
            </FadeInWrapper>
            <CardBox>
                <CardTop>
                    <div>
                        <CardText>전체 1,060건의 매출이 있어요</CardText>
                        <CardTotal>{total}원</CardTotal>
                    </div>
                    <Calendar />
                </CardTop>
                {platformItems.map(item => (
                    <CardPlatformBox key={item.name}>
                        <CardPlatform 
                            platformInitial={item.initial} 
                            backgroundColor={item.color}
                            color={item.textColor}
                        />
                        <CardPlatformName>{item.name}</CardPlatformName>
                        <CardPlatformAmount>{item.amount}</CardPlatformAmount>
                    </CardPlatformBox>))}
            </CardBox>
        </Container>
    )
}

const platformItems = [
    { name: '리디북스', initial: 'R', amount: '2,356,000원', color: '#339AF0', textColor:'white' },
    { name: '카카오페이지', initial: 'K', amount: '1,252,300원', color: '#FCC419', textColor:'black' },
    { name: '시리즈', initial: 'S', amount: '820,300원', color: '#51CF66', textColor:'white' },
];

const Container = styled.div`
    color: ${({ theme }) => theme.colors.black};
    margin-top: 160px;
`
const SectionName = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.blue500};
`
const Title = styled.p`
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    line-height: 30.05px;
    white-space: pre-wrap;
    margin-top: 16px;
`
const Desc = styled.p`
    color: ${({ theme }) => theme.colors.white};
    margin-top: 16px;
    line-height: 28px;
    white-space: pre-wrap;
`

const CardBox = styled.div`
    width: 100%;
    height: 280px;
    margin-top: 24px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 24px 20px 0px;
`
const CardTop = styled.div`
    display: flex;
    justify-content: space-between;
`
const CardText = styled.p`
    font-size: 14px;
`
const CardTotal = styled.p`
    font-size: 24px;
    font-weight: 700;
    margin: 8px 0px 24px;
`
const CardPlatformBox = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px 0px;
`
const CardPlatform = styled.div.attrs((props: { platformInitial:string }) => ({ 'data-initial': props.platformInitial }))<{ 
    platformInitial: string; color: string; backgroundColor: string }
>`
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background-color: ${({ backgroundColor, theme }) => backgroundColor || '#339AF0'};

    ::after {
        content: attr(data-initial);
        display: block;
        color: ${({ theme, color }) => color === 'black' ? theme.colors.black : theme.colors.white};
        font-size: 14px;
        font-weight: 700;
    }
`
const CardPlatformName = styled.span`
    font-size: 14px;
    font-weight: 700;
    margin-left: 8px;
`
const CardPlatformAmount = styled.span`
    position: absolute;
    font-weight: 700;
    right: 0px;
`