import { useRef } from "react";
import styled from "styled-components";
import useFadeIn from "@/modules/hooks/useFadeIn";

export default function PubSection() {
    const containerRef = useRef<HTMLDivElement>(null);
    const { FadeInWrapper, active } = useFadeIn(containerRef);
    const WIDTH = window.innerWidth <= 480 ? window.innerWidth : 480;
    const HEIGHT = (WIDTH - 80) * 487 / 234; // width 80%일 때 이미지 비율에 맞는 height 계산
    
    return (
        <Container ref={containerRef}>
            <ScrollBox>
                <FadeInWrapper active={active}>
                    <SectionName>글링 출간</SectionName>
                    <Title>글링에서{`\n`}출간을 쉽게</Title>
                    <Desc>이제 출간도 모바일로!{`\n`}복잡했던 출간, 한번에 시작하세요!</Desc>
                </FadeInWrapper>
                <VideoBox height={HEIGHT}>
                    <video
                        id="pub-section-video"
                        autoPlay
                        muted
                        loop
                        playsInline
                    >
                        <source src="/landing/pub-section-video.mp4" type="video/mp4" />
                    </video>
                </VideoBox>
            </ScrollBox>
        </Container>
    )
}

const Container = styled.div`
    height: 1200px;
    margin-top: 120px;
    color: ${({ theme }) => theme.colors.black};
`
const ScrollBox = styled.div`
    position: sticky;
    top: 80px;
`
const SectionName = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.blue500};
`
const Title = styled.p`
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    line-height: 30.05px;
    white-space: pre-wrap;
    margin-top: 16px;
`
const Desc = styled.p`
    color: ${({ theme }) => theme.colors.white};
    line-height: 28px;
    white-space: pre-wrap;
    margin-top: 16px;
`
const VideoBox = styled.div<{ height: number }>`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: ${({ height }) => height + 'px' || '440px'};
    margin-top: 20px;
    overflow: hidden;

    #pub-section-video {
        position: absolute;
        width: 100%;
        max-width: 480px;
    }
`