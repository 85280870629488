import React from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme"; 
import { ReactComponent as ArrowIcon} from  "@/assets/icons/arrow-right.svg";

export default function IconButton({ label, customStyle, onClickHandler }: ButtonProps) {
    return(
        <ButtonWrap marginTop={customStyle?.marginTop} onClick={onClickHandler}>
            <BaseButton
                width={customStyle?.width}
                height={customStyle?.height}
                padding={customStyle?.padding}
                fontSize={customStyle?.fontSize}
                borderRadius={customStyle?.borderRadius}
                fontWeight={customStyle?.fontWeight}
                color={customStyle?.color}
            >
                {label}
            </BaseButton>
            <IconWrap onClick={onClickHandler}>
                <ArrowIcon fill={colors.black} width="25" height="24" />
            </IconWrap>
        </ButtonWrap>
    )
}

const IconWrap = styled.div`
    position: absolute;
    top: 8px;
    right: 20px;
    cursor: pointer;
`

const ButtonWrap = styled.div<StyleProps>`
    position: relative;
    margin-top: ${({marginTop})=> (marginTop ? marginTop : "0px")};
    width: 164px;
    display: flex;
`;

const BaseButton = styled.button<StyleProps>`
    width: ${({ width }) => (width ? width : "160px")};
    height: ${({ height }) => (height ? height: "32px")};
    padding: ${({padding}) => (padding ? padding : "0")};
    background-color: ${({theme}) => theme.colors.white};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
    color: ${({ color, theme }) => (color ? color : theme.colors.black)};
    text-align: left;
    display: inline-block;
    cursor: pointer;
    padding-left:16.5px;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
    `;

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    fontSize?: string;
    borderRadius?: string;
    fontWeight?: string;
    marginTop?: string;
    color?: string;
}

interface ButtonProps {
    label: string;
    customStyle?: StyleProps;
    onClickHandler?: (e: React.MouseEvent<HTMLDivElement>) => void;
}