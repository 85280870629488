import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

export default function PlatformTagList({ items, keyName, valueName, showMore = false }: TagFilterProps) {
    const { salesPlatform } =  useAppSelector((state: RootState) => state.pubPortfolio);
    const [selectAll, setSelectAll] = useState(false);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        const allCheck = items.every(i => i.checked);
        if (allCheck) setSelectAll(true);
        else setSelectAll(false);
    }, [items])

    const selectAllCheckboxHandler = (checked: boolean) => {
        const tmpList = JSON.parse(JSON.stringify(salesPlatform));
        if (checked) {
            for (let i of items) {
                const ret = tmpList.find((t: any) => t.platform_id === i.platform_id);
                if (!ret) {
                    tmpList.push({
                        platform_id: i.platform_id,
                        platform_name: i.platform_name,
                        circulation_period: [{ start_circulate_at: "", end_circulate_at: null }],
                    })
                } 
            }
            dispatch({ type: "pubPortfolio/setSalesPlatform", payload: tmpList });
        } else {
            dispatch({ type: "pubPortfolio/setSalesPlatform", payload: [] });
        }
        setSelectAll(checked);
    };

    const checkPlatform = (idx: number) => {
        const tmpList = JSON.parse(JSON.stringify(salesPlatform));
        const targetIdx = tmpList.findIndex(((t: any) => t.platform_id === items[idx].platform_id));
        if (targetIdx === -1) {
            tmpList.push({
                platform_id: items[idx].platform_id,
                platform_name: items[idx].platform_name,
                circulation_period: [{ start_circulate_at: "", end_circulate_at: null }],
            })
        } else {
            tmpList.splice(targetIdx, 1);

        }
        dispatch({ type: "pubPortfolio/setSalesPlatform", payload: tmpList });
    }

    return (
        <TagFilterWrapper showMore={showMore}>
            <Label key="전체" checked={selectAll}>
                <input
                    type="checkbox"
                    value="전체"
                    onChange={(e) => selectAllCheckboxHandler(e.target.checked)}
                />
                {"전체"}
            </Label>
            {items.map((item, idx) => (
                <Label 
                    key={item[keyName]} 
                    checked={Boolean(salesPlatform.find((p: any) => p.platform_id === item.platform_id))}
                >
                    <input
                        type="checkbox"
                        value={item[valueName]}
                        onChange={(e) => checkPlatform(idx)}
                    />
                    {item[valueName]}
                </Label>
            ))}
        </TagFilterWrapper>
    );
}

const TagFilterWrapper = styled.div<{ showMore?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 500px;
    max-height: ${({ showMore }) => (showMore ? "400px" : "32px")};
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
`;
const Label = styled.label<{ checked: boolean }>`
    input {
        display: none;
    }
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    height: 32px;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    ${({ theme }) => css`
        font-size: ${theme.fontSizes.xs}px;
        background-color: ${theme.colors.gray50};
        border: 1px solid ${theme.colors.gray200};
        color: ${theme.colors.gray600};
    `}
    ${({ checked, theme }) => {
        return (
            checked &&
            css`
                border: 1px solid ${theme.colors.blue500};
                background-color: ${theme.colors.white};
                color: ${theme.colors.blue500};
            `
        );
    }}
`;

interface TagFilterProps {
    items: any[];
    keyName: string;
    valueName: string;
    showMore?: boolean;
}

