import styled from "styled-components";
import { useAppSelector, RootState } from "@/modules/store";
import ExcelUpload from "@/components/molecules/common/excelUpload/ExcelUpload";
import { fileAction } from "@/pages/common/fileSlice";
import Text from "@/components/atoms/text";
import useSampleDown from "@/modules/hooks/useSampleDown";

export default function EnrollSeriesExcelBodyModal() {
    const { saveAs } = useSampleDown();
    const [file] = useAppSelector((state:RootState) => [ state.file.file ]);

    function download() {
        saveAs("download/excel/sample/series", "(gling)시리즈명_등록_양식");
    }

    return (
        <SeriesExcelBodyWrap>
            <ExcelUploadWrap>
                <ExcelUpload
                    file={file}
                    setFileAction={fileAction.setFile}
                />
            </ExcelUploadWrap>
            <DownGuideWrap>
                <Text text="글링에서 사용하는 엑셀 양식이 필요하다면," customStyle={{ fontSize: "12px", fontWeight: "400", margin: "0 6px 0 0" }} />
                <UnderLineText onClick={download}>
                글링 양식 다운로드
                </UnderLineText>
            </DownGuideWrap>
        </SeriesExcelBodyWrap>
    )

}

const SeriesExcelBodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 184px;
    width: 480px;
`

const ExcelUploadWrap = styled.div`
    padding: 16px 20px;
`

const DownGuideWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
`

const UnderLineText = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.blue500};
    padding-bottom: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue500};
    cursor: pointer;
`