import styled from "styled-components";
import SelectBox from "@/components/atoms/selectBox/index";
import CheckBox from "@/components/atoms/input/Checkbox";
import { RootState, useAppSelector } from "@/modules/store";
import { useDispatch } from "react-redux";
import { ReactComponent as RightArrow } from "@/assets/icons/right-arrow.svg";
import Text from "@/components/atoms/text";
import { useState } from "react";
import { EbookMaker } from "@/types/dataType";


export default function FileTypeContainer() {
    const { inFileType, outFileType } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useDispatch();

    const inFileTypes = [
        { name: "HWP", value: EbookMaker.InfileType.HWP },
        { name: "HWPX", value: EbookMaker.InfileType.HWPX },
        { name: "DOCX", value: EbookMaker.InfileType.DOCX },
        { name: "TXT", value: EbookMaker.InfileType.TXT },
    ];

    const tmpOutFileTypes = [
        { label: "EPUB 2.0", value: EbookMaker.OutfileType.EPUB, checked: outFileType.includes(EbookMaker.OutfileType.EPUB) },
        { label: "TXT", value: EbookMaker.OutfileType.TXT, checked: outFileType.includes(EbookMaker.OutfileType.TXT) },
        // { label: "JPG", value: EbookMaker.OutfileType.JPG, checked: outFileType.includes(EbookMaker.OutfileType.JPG) },
        // { label: "PNG", value: EbookMaker.OutfileType.PNG, checked: outFileType.includes(EbookMaker.OutfileType.PNG) },
    ]
    const [outFileTypes, setOutFileTypes] = useState(tmpOutFileTypes)


    const selectInFileType = (value:any) => {
        dispatch({ type: "ebookMaker/setInFileType", payload: value.value });
        dispatch({ type: "ebookMaker/deleteAllInFiles" });
    }

    const selectOutFileType = (e: any, idx: number) => {
        const tmpOutFileTypes = outFileTypes.slice();
        tmpOutFileTypes[idx].checked = e.target.checked;
        setOutFileTypes(tmpOutFileTypes);
        dispatch({ type: "ebookMaker/setOutFileType", payload: outFileTypes });
    }

    const checkBoxLabelText = {
        fontSize: "12px",
        fontWeight: "400",
        margin: "0 10px 0 8px"
    }
    
    return (
        <StyledContainer>
            <FieldWrapper>파일형식 선택</FieldWrapper>
            <ContentWrapper>
                <InFileTypeWrapper>
                    <LabelWrapper>입력파일</LabelWrapper>
                    <SelectBox
                        labelKey="name"
                        valueKey="value"
                        options={inFileTypes}      
                        selected={inFileType}
                        onClickValueFunc={selectInFileType}
                    />
                </InFileTypeWrapper>
                <RightArrow />
                <OutFileTypeWrapper>
                    <LabelWrapper>출력파일</LabelWrapper>
                    {outFileTypes.map(t => (
                        <CheckBoxWrapper key={t.label}>
                            <CheckBox 
                                type="boolean"
                                active={t.checked}
                                onChangeHandler={(e) => selectOutFileType(e, outFileTypes.indexOf(t))}
                            />
                            <Text text={t.label} customStyle={checkBoxLabelText} />
                        </CheckBoxWrapper>
                    ))}
                </OutFileTypeWrapper>
            </ContentWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    height: 60px;
`

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    width : 270px;
    padding: 24px;
    margin-right: 23px;
    font-weight: 500;
    font-size: 14px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 900px;
    height: 60px;
    padding-left: 24px;
`

const InFileTypeWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 16px;
    select {
        font-size: 12px;
    }
`

const LabelWrapper = styled.div`
    display: flex;
    width: 64px;
    height: 100%;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
`

const OutFileTypeWrapper =styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 16px;
`

const CheckBoxWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    padding-top: 2px;
`