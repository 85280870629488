import { useEffect } from "react";
import { useAppDispatch } from "@/modules/store";
import { resetStore } from "@/pages/settlement/sales-entry/etc/etcSlice";
import EtcTable from "@/components/molecules/sales-entry/etc/EtcTable";
import TemplateLayout from "@/components/molecules/sales-inquiry/common/TemplateLayout";
import EtcTableTop from "@/components/molecules/sales-entry/etc/EtcTableTop";

export default function Etc() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        return () => {
            dispatch(resetStore());
        };
    }, []);

    return (
        <TemplateLayout>
            <EtcTableTop />
            <EtcTable />
        </TemplateLayout>
    );
}
