import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getMgData, updateMgData, deleteData } from "@/pages/settlement/saels-inquiry/publisher/mg/mgSlice";
import SalesSummary from "@/components/molecules/sales-inquiry/publisher/common/SalesSummary";
import TableOptions from "@/components/molecules/sales-inquiry/publisher/common/TableOptions";
import useExcelDownload from "@/modules/hooks/useExcelDownload";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { addComma } from "@/modules/utils/filter";

export default function MgTableTop() {
    const { items, total_cnt, total_amt, reset } = useSelectorTyped((state: RootState) => state.inquiryMg);
    const { selectedItems, updatedRows } = useSelectorTyped((state: RootState) => state.dataTable);
    const dispatch = useAppDispatch();
    const { setItemsPerPage } = usePagination({ from: "mg", tableData: items });
    const downloadExcel = useExcelDownload();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    // itemPerPage 변경
    const changeItemPerPage = (itemPerPage: number) => setItemsPerPage(itemPerPage);

    // 업데이트
    const saveData = async () => {
        const hasError = updatedRows.findIndex((d: any) => d["contractPriceError"] || d["mg_ratioError"]);
        if (hasError > -1) return await alert("info", "경고", "조건을 확인해주세요.");

        const answer = await alert("confirm", "저장 확인", "저장 하시겠습니까?");
        if (answer !== "Y") return;

        try {
            loadingOn();
            const apiParams = { selected: updatedRows };
            await dispatch(updateMgData(apiParams)).unwrap();
            await alert("info", "저장 완료", "저장 되었습니다.");
            await dispatch(getMgData()).unwrap();
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    // 삭제
    const deleteMgData = async () => {
        try {
            loadingOn();
            const apiParams = {
                selected: selectedItems,
            };
            await dispatch(deleteData(apiParams)).unwrap();
            await alert("info", "삭제 완료", "삭제가 완료되었습니다");
            await dispatch(getMgData()).unwrap();
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    // 엑셀 다운로드
    const downloadMgExcel = () => {
        if (items.length > 0) {
            let rows: any[] = [];
            items.forEach((item) => {
                let row = {
                    계약시작일: item.date,
                    선인세구분: item.mg_type,
                    판매플랫폼: item.platform_name,
                    계약형태: item.contract_type === "MG01" ? "작가계약" : "작품계약",
                    저자명: item.author_name,
                    계약대상: item.target,
                    계약금액: item.contractPrice,
                    정산비율: item.mg_ratio,
                    정산금액: addComma(Math.round(Number(item.st_price))),
                    소진금액: addComma(item.deductionPrice),
                    "잔여금액(원)": addComma(item.remainPrice),
                };
                rows.push(row);
            });
            downloadExcel({ json: rows, excelFileName: "(판매현황조회) 선인세 정산", sheetNames: "일별데이터" });
        } else {
            alert("info", "조회 완료", "조회된 데이터가 없습니다.");
        }
    };

    return (
        <TableTopContainer>
            <SalesSummary from="mg" total={total_cnt} amountName="소진 합계" amount={total_amt} />
            <TableOptions
                from="mg"
                reset={reset}
                changeItemPerPage={changeItemPerPage}
                saveData={saveData}
                deleteData={deleteMgData}
                downloadExcel={downloadMgExcel}
            />
        </TableTopContainer>
    );
}

const TableTopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
