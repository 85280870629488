import { useState, useEffect } from "react";
import { useAppDispatch } from "../store";
import { checkToken } from "@/pages/common/userSlice";

export default function useCheckToken() {
    const dispatch = useAppDispatch();
    const [tokenStatus, setTokenStatus] = useState(false);

    useEffect(() => {
        dispatch(checkToken())
        .then((data) => {
            if (data.payload.status === "fail") {
                setTokenStatus(false);
            } else {
                setTokenStatus(true);
            }
        })
    }, [dispatch])

    return tokenStatus;
}