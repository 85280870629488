import styled from "styled-components";
import SelectBox from "@/components/atoms/selectBox/index";
import moment from "moment";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";

export default function DateSelector({ dateChangeHandler }: { dateChangeHandler: () => void; }) {
    const { yearmon, monthList } = useAppSelector((state: RootState) => state.pubStatement);
    const { alert } = useAlert();
    const year = yearmon ? yearmon.substring(0,4) : moment().format("YYYY");
    const month = yearmon ? yearmon.substring(4,6) : moment().format("MM");
    const dispatch = useAppDispatch();
    const [yearList, setYearList] = useState<{ value: string }[]>([]);
    const [tmpMonthList, setTmpMonthList] = useState<{ value: string }[]>([]);

    useEffect(() => {
        let tmpYearList: { value: string }[] = [];
        for (let year = Number(moment().subtract(10, "year").format("YYYY")); year <= (Number(moment().add(1, "year").format("YYYY"))); year++) {
            tmpYearList.unshift({ value: `${year}`});
        }
        setYearList(tmpYearList);
        setTmpMonthList([{ value: "01" }, { value: "02" }, { value: "03" }, { value: "04" }, { value: "05" }, { value: "06" },
                         { value: "07" }, { value: "08" }, { value: "09" }, { value: "10" }, { value: "11" }, { value: "12" }]);
    }, [])

    const dateChange = (value: string, type: string) => {
        let tmpYearmon = "";
        switch (type) {
            case "year":
                tmpYearmon = `${value}${month}`;
                break;
            case "month":
                tmpYearmon = `${year}${value}`;
                break;
            default:
                break;
        }
        
        dispatch({ type: "pubStatement/setYearmon", payload: tmpYearmon});
        if (monthList.includes(tmpYearmon)) {
            dateChangeHandler();
        } else {
            alert("warning", "주의", "판매데이터가 입력되지 않은 월입니다.");
            // 빈 데이터 입력
            dispatch({ type: "pubStatement/setEmptyData"});
        }
    }

    return (
        <StyledContainer>
            <SelectBox
                labelKey="value"
                valueKey="value"
                selected={year}
                options={yearList}
                onClickValueFunc={(v:any) => dateChange(v.value, "year")}
                customStyle={{ width: "80px" }}
            />
            <SelectBox
                labelKey="value"
                valueKey="value"
                selected={month}
                options={tmpMonthList}
                onClickValueFunc={(v:any) => dateChange(v.value, "month")}
                customStyle={{ width: "80px" }}
            />
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    width: 168px;
    justify-content: space-between;
`