import { useState, useLayoutEffect } from "react";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";

const usePlatformSetting = () => {
    const platformList = useSelectorTyped((state: RootState) => state.platform.platformList);
    const platformIds = platformList && platformList.map((d) => d.platform_id);
    const platformNoQty = platformList && platformList.filter(p => !p.quantity).map((d) => d.platform_id);

    const [selectBoxOptions, setSelectBoxOptions] = useState<any[]>([]);
    const [tagFilterOptions, setTagFilterOptions] = useState<any[]>([]);

    useLayoutEffect(() => {
        if (platformList && platformList.length) {
            setSelectBoxOptions([{ platform_id: null, platform_name: "해당없음" }, ...platformList]);
            setTagFilterOptions([{ platform_id: 99, platform_name: "해당없음" }, ...platformList]);
        }
    }, [platformList]);

    return {
        platformList,
        selectBoxOptions,
        tagFilterOptions,
        platformIds,
        platformNoQty, // 판매량 제공 안 하는 플랫폼
    };
};

export default usePlatformSetting;
