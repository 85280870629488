import { useEffect, useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import BatchEtcModalBody from "./bodyModal/BatchEtcModalBody";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";
import { CommonType } from "@/types/dataType";

export default function BatchEtcModal({ show, close, from, platformList, onApplyBatchEtc }: ModalProps) {
    const [batchEtcData, setBatchEtcData] = useState<BatchEtcResult>({
        platformId: 0,
        inDate: "",
        outDate: "",
    });
    // 체크박스
    const [checked, setChecked] = useState<Checked>({
        platform: false,
        inDate: false,
        outDate: false,
    });
    const { alert } = useAlert();

    const validate = async () => {
        if (!checked.platform && !checked.inDate && !checked.outDate)
            return await alert("warning", "주의", "선택된 항목이 없습니다.");
        if (checked.platform && batchEtcData.platformId === 0)
            return await alert("warning", "주의", "플랫폼을 선택해주세요.");
        if (checked.inDate && batchEtcData.inDate === "")
            return await alert("warning", "주의", "입금 일자를 선택해주세요.");
        if (checked.outDate && batchEtcData.outDate === "")
            return await alert("warning", "주의", "지급 일자를 선택해주세요.");

        return await alert("confirm", "확인", "적용 하시겠습니까?");
    };

    const apply = async () => {
        const answer = await validate();
        if (answer === "Y") {
            onApplyBatchEtc(batchEtcData);
            close();
        }
    };

    useEffect(() => {
        return () => {
            setBatchEtcData({
                platformId: 0,
                inDate: "",
                outDate: "",
            });
            setChecked({
                platform: false,
                inDate: false,
                outDate: false,
            });
        };
    }, [show]);

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["일괄적용"]}
                customStyle={[{ width: "360px", height: "268px" }]}
                applyFunc={apply}
            >
                <BatchEtcModalBody
                    platformList={platformList}
                    checked={checked}
                    batchEtcData={batchEtcData}
                    setChecked={setChecked}
                    setBatchEtcData={setBatchEtcData}
                />
            </BaseModal>
        </>
    );
}

interface ModalProps extends BaseModalProps {
    platformList: {
        platform_id: CommonType.Id;
        platform_name: CommonType.Platform.Name;
    }[];
    onApplyBatchEtc: (batchEtcData: BatchEtcResult) => void;
}

export interface BatchEtcResult {
    platformId: CommonType.Id;
    inDate: CommonType.Date;
    outDate: CommonType.Date;
}

export interface Checked {
    platform: boolean;
    inDate: boolean;
    outDate: boolean;
}
