import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import useCheckToken from "@/modules/hooks/useCheckToken";
import styled from "styled-components";
import { useAppDispatch } from "@/modules/store";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";
import { getPortfolioDetail } from "@/pages/portfolio/author/slice";
import { useSearchParams } from 'react-router-dom';
import { useEffect } from "react";
import CoverContainer from "@/components/molecules/portfolio/author/detail/CoverContainer";
import BookInfoContainer from "@/components/molecules/portfolio/author/detail/BookInfoContainer";
import PromotionContainer from "@/components/molecules/portfolio/author/detail/PromotionContainer";
import TimelineContainer from "@/components/molecules/portfolio/author/detail/TimelineContainer";

export default function PortfolioDetail() {
    const tokenStatus = useCheckToken();
    const dispatch = useAppDispatch();
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const [searchParams] = useSearchParams();

    const getPageData = async () => {
        try {
            await loadingOn();
            await dispatch(getPortfolioDetail(Number(searchParams.get("id")))).unwrap();
        } catch (err) {
            alert("error", "오류 발생", "데이터를 불러오는 중 오류가 발생하였습니다.");
        } finally {
            await loadingOff();
        }
    }

    useEffect(() => {
        getPageData();

        return () => {
            dispatch({ type: "authorPortfolio/initState" });
        }
    }, [])

    if (!tokenStatus) {
        return <></>
    };
    
    return (
        <SectionLayout>
            <TitleWrapper>작품 상세 정보</TitleWrapper>
            <UpperContainer>
                <CoverContainer />
                <BookInfoContainer />
            </UpperContainer>
            <PromotionContainer />
            <TimelineContainer />
        </SectionLayout>
    )
}

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
`

const UpperContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
`
