import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as UpArrowIcon } from "@/assets/icons/up_arrow.svg";

export default function ScrollToTopBtn() {
    const clickUpBtn = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <ScrollToTopWrapper>
            <UpButton onClick={clickUpBtn}>
                <UpArrowIcon fill={colors.gray600} />
            </UpButton>
        </ScrollToTopWrapper>
    );
}

const ScrollToTopWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: calc(100% + 64px);
    height: 100%;
    top: 0;
    pointer-events: none;
`;
const UpButton = styled.div`
    position: sticky;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.gray50};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 90vh;
    cursor: pointer;
    pointer-events: auto;
`;
