import styled from "styled-components";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";
import { ReactComponent as EmailIcon} from "@/assets/icons/consulte-mail.svg"
import { colors } from "@/assets/styles/theme";
import { useNavigate } from "react-router-dom";

export default function SendComplete() {
    const navigate = useNavigate();
    
    const moveToHome = () => {
        navigate("/");
    }
    return (
        <StyledContainer>
            <SendStyled>
                <SendContainer>
                    <EmailIcon />
                    <Text text="전송완료" customStyle={{fontWeight: "700", fontSize: "28px", lineHeight: "35px", margin: "24px 0px"}}/>
                    <Text text="성공적으로 전송되었습니다." customStyle={{fontWeight: "400", fontSize: "16px", lineHeight: "28px", color: colors.gray500, margin: "0px 0px 24px 0px"}}/>
                    <Button label="홈으로 이동" onClickHandler={moveToHome} customStyle={{width: "160px", height: "40px", fontSize: "16px"}}/>
                </SendContainer>
            </SendStyled>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

const SendStyled = styled.div`
    width: 100%;
    height: 688px;
`

const SendContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 200px;
    height: 378px;
    left: 860px;
    top: 165px;
`