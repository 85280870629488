import styled from "styled-components";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import useAlert from "@/modules/hooks/useAlert";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { useState } from "react";
import TextInput from "@/components/atoms/input/TextInput";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as AddData } from "@/assets/icons/add-data.svg";
import Text from "@/components/atoms/text";

export default function AddBriefModal({ show, close }: any) {
    const { alert } = useAlert();
    const { briefs } = useAppSelector((state: RootState) => state.pubStatement);
    const [tmpBriefs, setTmpBriefs] = useState(briefs.length ? briefs : [""]);
    const dispatch = useAppDispatch();

    const reset = () => {
        setTmpBriefs(briefs.length ? briefs : [""]);
    }

    const apply = async () => {
        const answer = await alert("confirm", "적용 확인", "적용 하시겠습니까?");
        if (answer === "Y") {
            dispatch({ type: "pubStatement/setBriefs", payload: tmpBriefs});
            close();
        }
    }

    const inputOnChange = (e: any, idx: number) => {
        const tmpArr = JSON.parse(JSON.stringify(tmpBriefs));
        tmpArr.splice(idx, 1, e.target.value);
        setTmpBriefs(tmpArr);
    }

    const addBrief = () => {
        const tmpArr = JSON.parse(JSON.stringify(tmpBriefs));
        tmpArr.push("");
        setTmpBriefs(tmpArr);
    }

    const deleteBrief = (idx: number) => {
        const tmpArr = JSON.parse(JSON.stringify(tmpBriefs));
        tmpArr.splice(idx, 1);
        setTmpBriefs(tmpArr);
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["적요 추가"]}
            customStyle={[{ width: "480px", height: "auto" }]}
            applyFunc={() => {apply();}}
            reset={reset}
        >
            <ModalBodyWrapper>
                <StyledTable>
                    <thead>
                        <tr>
                            <StyledTh width="60px">번호</StyledTh>
                            <StyledTh width="320px">내용</StyledTh>
                            <StyledTh width="60px"></StyledTh>
                        </tr>
                    </thead>     
                    <tbody>
                        {tmpBriefs.map((b, idx) => (
                            <tr key={idx}>
                                <StyledTd width="60px" align="center">{idx+1}</StyledTd>
                                <StyledTd width="320px">
                                    <TextInput 
                                        value={b}
                                        onChangeHandler={(e) => inputOnChange(e, idx)}
                                        placeholder="적요를 입력해주세요."
                                        customStyle={{ width: "300px", height: "32px" }}
                                    />
                                </StyledTd>
                                <StyledTd width="60px" align="center">
                                    <Clear 
                                        fill={colors.gray600} 
                                        width={24} 
                                        height={24} 
                                        onClick={() => deleteBrief(idx)}
                                    />
                                </StyledTd>
                            </tr>
                        )) }
                    </tbody>
                </StyledTable>
                <ButtonWrapper onClick={addBrief}>
                    <AddData fill={colors.blue500} width={24} height={24} />
                    <Text text="구간 추가" customStyle={{ fontSize: "12px", fontWeight: "500", color: colors.blue500, margin: "0 0 0 8px" }} />
                </ButtonWrapper>
            </ModalBodyWrapper>
        </BaseModal>
    )
}

const ModalBodyWrapper = styled.div`
    width: 100%;
    padding: 0 20px;
`

const StyledTable = styled.table`
    width: 100%;
`

const StyledTh = styled.th<{ width: string }>`
    width: ${({ width }) => width};
    height: 48px;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid ${colors.gray300};
`

const StyledTd = styled.td<{ width: string; align?: string; }>`
    width: ${({ width }) => width};
    text-align: ${({ align }) => align ? align : "left"};
    height: 60px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    padding: 0 8px;
    svg {
        cursor: pointer;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 80px;
    height: 60px;
    margin: auto;
    cursor: pointer;
`