import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";

export default function Agreement1() {
    return(
        <StyledContainer>
            <Title>제 1장 총칙</Title>
            <SubTitle>제1조(목적)</SubTitle>
            <UlStyled>
                <LiStyled> 이 약관은 뷰컴즈 주식회사(이하 ‘회사’)가 온라인으로 제공하는 디지털콘텐츠(이하 &quot;콘텐츠&quot;라고 한다) 및 제반서비스의 이용과 관련하여 </LiStyled>
                <LiStyled> 회사와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제2조(정의)</SubTitle>
            <UlStyled>
                <LiStyled> 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</LiStyled>
                <LiStyled> &nbsp;&nbsp;1. &quot;회사&quot;라 함은 &quot;콘텐츠&quot; 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 제반서비스를 제공하는 자를 말합니다.</LiStyled>
                <LiStyled> &nbsp;&nbsp;2. &quot;이용자&quot;라 함은 &quot;회사&quot;의 사이트에 접속하여 이 약관에 따라 &quot;회사&quot;가 제공하는 &quot;콘텐츠&quot; 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.</LiStyled>
                <LiStyled> &nbsp;&nbsp;3. &quot;회원&quot;이라 함은 &quot;회사&quot;와 이용계약을 체결하고 &quot;이용자&quot; 아이디(ID)를 부여받은 &quot;이용자&quot;로서 &quot;회사&quot;의 정보를 지속적으로 제공받으며 &quot;회사&quot;가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다</LiStyled>
                <LiStyled> &nbsp;&nbsp;4. &quot;비회원&quot;이라 함은 &quot;회원&quot;이 아니면서 &quot;회사&quot;가 제공하는 서비스를 이용하는 자를 말합니다.</LiStyled>
                <LiStyled> &nbsp;&nbsp;5. &quot;콘텐츠&quot;라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호·문자·음성·음향·이미지 또는 영상 등으로 표현된 자료 또는 정보로서</LiStyled>
                <LiStyled> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.</LiStyled>
                <LiStyled> &nbsp;&nbsp;6. &quot;아이디(ID)&quot;라 함은 &quot;회원&quot;의 식별과 서비스이용을 위하여 &quot;회원&quot;이 정하고 &quot;회사&quot;가 승인하는 문자 또는 숫자의 조합을 말합니다.</LiStyled>
                <LiStyled> &nbsp;&nbsp;7. &quot;비밀번호(PASSWORD)&quot;라 함은 &quot;회원&quot;이 부여 받은 &quot;아이디&quot;와 일치되는 &quot;회원&quot;임을 확인하고 비밀보호를 위해 &quot;회원&quot; 자신이 정한 문자 또는 숫자의 조합을 말합니다.</LiStyled>
                <LiStyled> &nbsp;&nbsp;8. “서비스”라 함은 구현되는 단말기(PC, 이동전화, 휴대형 단말기 등의 각종 유무선 기기 또는 프로그램을 포함)와 상관없이 회원이 이용할 수 있도록 회사가 제공하는 모든 종류의 서비스 일체를 말합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제3조(신원정보 등의 제공)</SubTitle>
            <UlStyled>
                <LiStyled> &quot;회사&quot;는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소, 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록</LiStyled>
                <LiStyled> 온라인 서비스초기화면에 게시합니다. 다만, 약관은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.</LiStyled>
            </UlStyled>
            <SubTitle>제4조(약관의 게시 등)</SubTitle>
            <UlStyled>
                <LiStyled> ① &quot;회사&quot;는 이 약관을 &quot;회원&quot;이 그 전부를 인쇄할 수 있고 거래과정에서 해당 약관의 내용을 확인할 수 있도록 기술적 조치를 취합니다.</LiStyled>
                <LiStyled> ② &quot;회사&quot;는 &quot;이용자&quot;가 &quot;회사&quot;와 이 약관의 내용에 관하여 질의 및 응답할 수 있도록 기술적 장치를 설치합니다.</LiStyled>
                <LiStyled> ③ &quot;회사&quot;는 &quot;이용자&quot;가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 환불조건, 등과 같은 중요한 내용을 이용자가 쉽게 이해할 수 있도록</LiStyled>
                <LiStyled> &nbsp;&nbsp;&nbsp;별도의 연결화면 또는 팝업화면 등을 제공하여 &quot;이용자&quot;의 확인을 구합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제5조(약관의 개정 등)</SubTitle>
            <UlStyled>
                <LiStyled> ① &quot;회사&quot;는 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</LiStyled>
                <LiStyled> ② &quot;회사&quot;가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스초기화면에 그 적용일자 7일 이전부터 적용일 후 상당한 기간동안 공지하고,</LiStyled>
                <LiStyled> &nbsp;&nbsp;&nbsp; 기존회원에게는 개정약관을 전자우편주소로 발송합니다.</LiStyled>
                <LiStyled> ③ &quot;회사&quot;가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 &quot;이용자&quot;의 동의 여부를 확인합니다. &quot;이용자&quot;가 개정약관의 적용에 동의하지 않는 경우</LiStyled>
                <LiStyled> &nbsp;&nbsp;&nbsp;&quot;회사&quot; 또는 &quot;이용자&quot;는 콘텐츠 이용계약을 해지할 수 있습니다. 이때, &quot;회사&quot;는 계약해지로 인하여 &quot;이용자&quot;가 입은 손해를 배상합니다.</LiStyled>
            </UlStyled>
            <SubTitle>제6조(약관의 해석)</SubTitle>
            <UlStyled>
                <LiStyled> 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률,</LiStyled>
                <LiStyled> 문화체육관광부장관이 정하는 디지털콘텐츠이용자보호지침, 기타 관계법령 또는 상관례에 따릅니다.</LiStyled>
            </UlStyled>
            <SubTitle>제7조(약관의 효력 및 변경)</SubTitle>
            <UlStyled>
                <LiStyled> 1. 본 약관은 서비스를 이용하고자 하는 회원이 본 약관에 동의함으로써 효력이 발생합니다.</LiStyled>
                <LiStyled> 2. 회사는 본 약관의 내용에 있어 합리적인 변경 사유가 발생할 시에 이를 변경할 수 있습니다. 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 변경된 약관의 적용일자 7일 전</LiStyled>
                <LiStyled> &nbsp;&nbsp;&nbsp;(회원에게 불리하거나 중대한 사항의 변경은 30일 전)부터 적용일 경과 후 상당한 기간이 경과할 때까지 공지합니다. 약관의 변경내용은 사이트 및 서비스 화면에 이를 공지하거나 기타의 방법으로 회원에게 공지합니다.</LiStyled>
                <LiStyled> 3. 회사는 제2항에 따라 약관을 변경할 경우 회원이 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 간주하겠다는 내용도 함께 공지할 수 있고, 이러한 공지를 한 경우 회원이</LiStyled>
                <LiStyled> &nbsp;&nbsp;&nbsp;변경약관 시행일까지 명시적으로 거부의사를 표시하지 않으면 변경약관에 동의한 것으로 간주합니다.</LiStyled>
                <LiStyled> 4. 회원은 변경된 약관 사항에 동의하지 않을 경우 이용 계약을 해지할 수 있습니다. 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사도 이용계약을 해지할 수 있습니다.</LiStyled>
            </UlStyled>
        </StyledContainer>
        
    )
}

const StyledContainer = styled.div`

`

const Title = styled.h4`
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 40px;
    width: auto;
`

const SubTitle = styled.h6`
    font-weight: 700;
    line-height: 20px;
    margin: 16px 0px;
`

const UlStyled = styled.ul`
`

const LiStyled = styled.li`
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${colors.gray600}
`