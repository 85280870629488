import { useState } from "react";
import styled from "styled-components";
import Button from "@/components/atoms/button";
import SelectBox from "@/components/atoms/selectBox/index";

export default function TableOptions({ onChangeItemPerPage, onExcelDownload }: TableOptionsProps) {
    const [itemPerPage, setItemPerPage] = useState(10); // 셀렉트박스 selected

    const changeSelectBox = (value: any) => {
        setItemPerPage(value.value);
        onChangeItemPerPage(value.value);
    }
    
    const handleClickExcelButton = () => onExcelDownload();

    return (
        <TableOptionsWrapper>
            <SelectBox
                labelKey="name"
                valueKey="value"
                options={[
                    { name: "10개씩 보기", value: 10 },
                    { name: "30개씩 보기", value: 30 },
                    { name: "50개씩 보기", value: 50 },
                    { name: "100개씩 보기", value: 100 },
                ]}
                selected={itemPerPage}
                onClickValueFunc={changeSelectBox}
                customStyle={selectBoxStyle}
            />
            <Button
                type="main-outline"
                label="엑셀 다운로드"
                customStyle={downloadBtnStyle}
                onClickHandler={handleClickExcelButton}
            />
        </TableOptionsWrapper>
    );
}

const selectBoxStyle = {
    fontSize: "12px",
    fontWeight: "400",
};
const downloadBtnStyle = {
    margin: "0 0 0 8px",
    width: "113px",
};

const TableOptionsWrapper = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

interface TableOptionsProps {
    onChangeItemPerPage: (itemPerPage: number) => void;
    onExcelDownload: () => void;
}
