import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
export default function SectionLayout({ children }: { children: React.ReactNode }) {
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    useEffect(()=> {
        window.addEventListener("resize", resizeHandler);
  
        return () => {window.removeEventListener("resize", resizeHandler)}
      }, []);

    const resizeHandler = () => {
        setInnerHeight(window.innerHeight);
        setInnerWidth(window.innerWidth);
    }

    return (
        <SectionStyle height={innerHeight} width={innerWidth}>
            {children}
        </SectionStyle>
    )
}

const SectionStyle = styled.div<{ height: number, width: number }>`
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc((100vh - 72px));
    overflow: ${({ height, width }) => 800 > height || 1200 > width ? "auto" : "hidden"};
    padding: ${({ height }) => 800 > height ? `${800-height+72}px calc((100% - 1200px) / 2) 0` : "0px calc((100% - 1200px) / 2) 0"};
    justify-content: center;
`;