import styled from 'styled-components'
import SettlementFunction from '@/components/molecules/introduce/settlement/SettlementFunction'
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn'
import { ReactComponent as Enter} from "@/assets/icons/enter.svg";
import { ReactComponent as Check} from "@/assets/icons/check.svg";
import { ReactComponent as BaseData} from "@/assets/icons/base-data.svg";
import { ReactComponent as TotalSales} from "@/assets/icons/totalSales.svg";
import { ReactComponent as RoyaltyStatement} from "@/assets/icons/royaltyStatement.svg";
import { colors } from "@/assets/styles/theme";

export default function SettlementFunctionContainer({tab, onIdChange}: functionProps) {
    const textStyle = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
        color: colors.black
    }
    
    const functionText = [{
        id: 1,
        text: '판매 입력',
        style: textStyle
    },
    {
        id: 2,
        text: '판매 조회',
        style: textStyle
    },
    {
        id: 3,
        text: '기초데이터 관리',
        style: textStyle
    },
    {
        id: 4,
        text: '입출금 종합',
        style: textStyle
    },
    {
        id: 5,
        text: '정산서',
        style: textStyle
    }];
    
    const fadeInFunction = useScrollFadeIn('up', 1, 0, 0.1);
    const texts = functionText;
    const svgArray = [Enter, Check, BaseData, TotalSales, RoyaltyStatement];
    
    return (
        <StyledContainer {...fadeInFunction}>
            <FunctionWrapper>
                {texts.map((textObj, idx) => 
                    <SettlementFunction
                        key={textObj.id}
                        tab={tab}
                        id={idx} 
                        label={textObj.text} 
                        customStyle={textObj.style}
                        icon={svgArray[idx]}
                        onIdChange={onIdChange}
                        />
                )}
            </FunctionWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FunctionWrapper = styled.div`
`

interface functionProps {
    tab: number,
    onIdChange: (e: React.MouseEvent<HTMLDivElement>, id: number) => void;
}

