import styled from "styled-components";
import { useEffect } from "react";
import moment from "moment";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { getSalesSummary, getSalesAnalysis, getCalendarEvent, getDailySeriesAnalysis, getDailyPlatformAnalysis, getMgDeductionHistory, getRelatedPubList } from "@/pages/dashboard/author/slice";

import { 
    TitleContainer, PublisherTagContainer, SummaryCardContainer, SalesAnalysisContainer, CalendarContainer,
    DailySeriesAnalysisChartContainer, DailySeriesAnalysisTableContainer, DailyPlatformAnalysisContainer, MgHistoryTableContainer
} from "@/components/molecules/dashboard/author";
import useCheckToken from "@/modules/hooks/useCheckToken";
import useAlert from "@/modules/hooks/useAlert";

export default function AuthorDashboard() {
    const dispatch = useAppDispatch();
    const tokenStatus = useCheckToken();
    const { selectedPublisherId } = useAppSelector((state: RootState) => state.authorDashboard);
    const { alert } = useAlert();
    const nowYearmon = moment().format("YYYYMM");

    const getData = async () => {
        try {
            await dispatch(getRelatedPubList()).unwrap();
            dispatch(getSalesSummary(selectedPublisherId));
            dispatch(getSalesAnalysis(selectedPublisherId))
            dispatch(getCalendarEvent({ yearmon: nowYearmon, publisher_id: selectedPublisherId}));
            dispatch(getDailySeriesAnalysis(selectedPublisherId));
            dispatch(getDailyPlatformAnalysis(selectedPublisherId));
            dispatch(getMgDeductionHistory(selectedPublisherId));
        } catch (err) {
            alert("error", "오류 발생", "데이터를 받아오는 중 오류가 발생하였습니다.");
        }
    }

    useEffect(() => {
        if (tokenStatus) {
            getData();
        }
    }, [dispatch, tokenStatus, nowYearmon, selectedPublisherId]);

    if (!tokenStatus) {
        return <></>
    }

    return (
        <DashboardSection>
            <FlexDiv2>
                <TitleContainer />
                <PublisherTagContainer />
            </FlexDiv2>
            <SummaryCardContainer />
            <FlexDiv>
                <SalesAnalysisContainer />
                <CalendarContainer />
            </FlexDiv>
            <FlexDiv>
                <DailySeriesAnalysisChartContainer />
                <DailySeriesAnalysisTableContainer />
            </FlexDiv>
            <DailyPlatformAnalysisContainer />
            <MgHistoryTableContainer />
        </DashboardSection>
    );
}

const DashboardSection = styled.div`
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.gray50};
    padding: 30px calc((100% - 1200px) / 2);
`

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`

const FlexDiv2 = styled.div`
    display: flex;
`