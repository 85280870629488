import styled from "styled-components";
import ApexCharts from "react-apexcharts";
import { RootState, useAppSelector } from "@/modules/store";
import { colors } from "@/assets/styles/theme";

export default function LaunchingSeriesChartWrapper() {
    const { launchingSeries } = useAppSelector((state: RootState) => state.pubDashboard);
    const dataColors: Array<string> = []
    const dataCnt = launchingSeries.slice(0, 5).length;
    let radius;
    switch (dataCnt) {
        case 1:
            radius = 55;
            break;
        case 2:
            radius = 42;
            break;
        case 3:
            radius = 24;
            break;
        case 4:
            radius = 18;
            break;
        default:
            radius = 12;
    }
    for (let i = 0; i < launchingSeries.length; i++) {
        if (i === 0) dataColors.push(colors.blue500);
        else dataColors.push(colors.blue50);
    }

    const soaringSalesData: any = {
        series: [
            {
                name: '판매금액',
                type: 'column',
                data: launchingSeries.slice(0,5).map(l => l.amount)
            }, 
        ],
        options: {
            chart: {
                height: 300,
                type: 'line',
                stacked: false,
                foreColor: "#868E96",
                toolbar: {
                    show: false,
                }
            },
            colors: [function({ value, dataPointIndex, w}: any) {
                if (dataPointIndex === 0) {
                    return colors.blue500;
                } else {
                    return colors.blue50;
                }
            }],
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8
                    }
                }
            },
            stroke: {
                width: [0, 4, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    borderRadius: radius,
                }
            },
            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            labels: launchingSeries.slice(0,5).map(l => l.series_name),
            markers: {
                size: 0
            },
            xaxis: {
                labels: {
                    rotate: 0,
                    style: {
                        fontSize: "8px",
                        fontWeight: 400,
                    },
                    trim: true,
                },
                tooltip: {
                    enabled: false
                },
                crosshairs: {
                    show: false
                },
                // floating: true,
            },
            yaxis: {
                labels: {
                    formatter: function(val: number) {
                        if (val > 10000) {
                            return `${(val / 10000).toFixed(0)}만원`
                        } else {
                            return val.toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원";
                        }
                    }
                }
            },
            grid: { show: false },
            tooltip: {
                shared: false,
                intersect: true,
                followCursor: false,
                custom: function(data: any) {
                    const { dataPointIndex } = data;
                    const color = dataColors[dataPointIndex];
                    return `
                        <div class="tooltip-box">
                            <div>${launchingSeries[dataPointIndex].series_name}</div>
                            <div>${launchingSeries[dataPointIndex].author_name ? `/ ${launchingSeries[dataPointIndex].author_name}` : ""}</div>
                            <div class="amount-wrap">
                                <span class="color-box" style="background-color: ${color};"></span>
                                <div>${launchingSeries[dataPointIndex].amount.toFixed(0).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,") + " 원"}</div>
                            </div>
                        </div>
                    `
                },
            }
        },
    }

    return (
        <div>
            <StyledCharts
                options={soaringSalesData.options}
                series={soaringSalesData.series}
                types="line"
                width={350}
                height={290}
            />
        </div>   
    )
}

const StyledCharts = styled(ApexCharts)`
    .apexcharts-tooltip {
        box-shadow: 0px 0px 15px rgba(112, 144, 176, 0.2); !important;
    }
    .tooltip-box {
        display: flex;
        flex-direction: column;
        padding: 8px 10px;
        min-width: 120px;
        background: #FFFFFF;
        border-radius: 4px;
        color: #121417;
        font-size: 10px;
        line-height: 14px;
        .amount-wrap {
            display: flex;
            margin-top: 10px;
            align-items: center;
            .color-box {
                position: relative;
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
        }
    }
`