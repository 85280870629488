import { useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import { useAppDispatch } from "@/modules/store";
import styled from "styled-components";
import TextInput from "@/components/atoms/input/TextInput";
import { CopyrightContentType } from "@/pages/ebook-maker/types";
import Radio from "@/components/atoms/input/Radio";
import useAlert from "@/modules/hooks/useAlert";

export default function AddContentModal({ show, close }: any) {
    const [title, setTitle] = useState("");
    const [contentType, setContentType] = useState(CopyrightContentType.Title);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    const reset = () => {
        setContentType(CopyrightContentType.Title);
        setTitle("");
    }

    const apply = () => {
        const payload = {
            title,
            value: "",
            size: "P",
            align: "left",
            bold: false,
            italice: false,
            underline: false,
            link: "none",
            isEmpty: false,
        }
        switch (contentType) {
            case CopyrightContentType.Title:
                if (!title) return alert("warning", "주의", "항목을 입력해주세요.");
                dispatch({ type: "ebookMaker/addContentList", payload })
                break;
            case CopyrightContentType.Text:
                dispatch({ type: "ebookMaker/addContentList", payload: { ...payload, title: "" } })
                break;
            case CopyrightContentType.Empty:
                dispatch({ type: "ebookMaker/addContentList", payload: { isEmpty: true } })
                break;
            default:
                break;
        }
        close();
    }

    const inputOnChange = (e: any) => {
        setTitle(e.target.value);
    }

    const changeType = (e: any) => {
        setContentType(Number(e.target.value));
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["항목 추가"]}
            customStyle={[{ width: "320px", height: "268px" }]}
            applyFunc={() => {apply();}}
            reset={reset}
        >
           <ModalBodyWrapper>
               <RowWrapper>
                    <RadioWrapper>
                        <Radio
                            name="dataType"
                            checked={contentType === CopyrightContentType.Title}
                            value={CopyrightContentType.Title}
                            onChange={(e) => changeType(e)}
                            type="label"
                            label="항목"
                            customStyle={{ fontSize:"14px", margin: "0 24px 0 0"}}
                        />
                    </RadioWrapper>
                    <InputWrapper>
                        <TextInput 
                            type={contentType !== CopyrightContentType.Title ? "readOnly" : undefined}
                            value={title}
                            onChangeHandler={inputOnChange}
                            placeholder="항목을 입력해주세요"
                            customStyle={{ width: "180px", height: "32px", fontSize: "12px", padding: "4px 8px" }}
                        /> 
                    </InputWrapper>
               </RowWrapper>
               <RowWrapper>
                    <Radio
                        name="dataType"
                        checked={contentType === CopyrightContentType.Text}
                        value={CopyrightContentType.Text}
                        onChange={(e) => changeType(e)}
                        type="label"
                        label="텍스트만"
                        customStyle={{ fontSize:"14px" }}
                    />
               </RowWrapper>
               <RowWrapper>
                    <Radio
                        name="dataType"
                        checked={contentType === CopyrightContentType.Empty}
                        value={CopyrightContentType.Empty}
                        onChange={(e) => changeType(e)}
                        type="label"
                        label="공백"
                        customStyle={{ fontSize:"14px" }}
                    />
               </RowWrapper>
           </ModalBodyWrapper>
        </BaseModal>
    );
}

const ModalBodyWrapper = styled.div`
    padding: 0 20px;
    width: 100%;
`

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
`

const RadioWrapper = styled.div`
    display: flex;
    align-items: center;
`

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`