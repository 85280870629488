import { useEffect, useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import EnrollSeriesRatioModalBody from "@/components/molecules/common/modal/bodyModal/EnrollSeriesRatioModalBody";
import { BaseModalProps } from "@/pages/common/types";
import { CommonType } from "@/types/dataType";
import useAlert from "@/modules/hooks/useAlert";

export default function EnrollSeriesRatioModal({
    show,
    close,
    platformName,
    applySeriesRatio,
}: EnrollSeriesRatioProps) {
    const [ratio, setRatio] = useState<CommonType.Ratio | string>("");
    const [ratioError, setRatioError] = useState("");
    const { alert } = useAlert();

    useEffect(() => {
        setRatio("");
    }, [show]);

    const apply = async () => {
        if (ratioError) {
            return await alert("warning", "확인", "조건을 확인해주세요");
        }
        applySeriesRatio(Number(ratio));
        close();
    };

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["정산비율 입력"]}
                applyFunc={apply}
                customStyle={[{ width: "450px", height: "200px" }]}
            >
                <EnrollSeriesRatioModalBody
                    platformName={platformName}
                    ratio={ratio}
                    setRatio={setRatio}
                    ratioError={ratioError}
                    setRatioError={setRatioError}
                />
            </BaseModal>
        </>
    );
}

interface EnrollSeriesRatioProps extends BaseModalProps {
    platformName: CommonType.Platform.Name;
    applySeriesRatio: (ratio: CommonType.Ratio) => void;
}
