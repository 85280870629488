import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@/api";

const name = "common/salesPlatform";

const initialState: State = {
    platformList: [],
    loading: false,
};

// 판매 플랫폼 리스트 조회
export const getSalesPlatformList = createAsyncThunk(`${name}/getSalesPlatformList`, async (_, { rejectWithValue }) => {
    try {
        return (await api.post(`/api/settlement/common/platformSettings/publisher/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

const salesPlatformSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetStore: () => {
            return initialState;
        },
        checkPlatform: (state, { payload }) => {
            for (let p of state.platformList) {
                if (p.platform_id === payload.platform.platform_id) {
                    p.checked = false;
                }
            }
        },
        setPlatformList: (state, { payload }) => {
            state.platformList = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSalesPlatformList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSalesPlatformList.fulfilled, (state, action) => {
            if (action.payload) {
                state.loading = false;
                state.platformList = action.payload.data;
            }
        });
    },
});

export interface State {
    platformList: Platform[];
    loading: boolean;
}

export interface Platform {
    daily: number;
    id: number;
    monthly: number;
    paymonth: number;
    quantity: number;
    platform_id: number;
    platform_name: string;
    usable: number;
    checked: boolean;
    circulation_stop_date: string;
}

export const salesPlatformActions = salesPlatformSlice.actions;
export default salesPlatformSlice.reducer;
