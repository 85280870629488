import { useState } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import {
    setAfterExcelUpload,
    setAmount,
    setItems,
    setLastId,
    setTotal,
    uploadExcelData,
    setUploaderMsg,
} from "@/pages/settlement/sales-entry/daily/dailySlice";

// utils
import { addComma } from "@/modules/utils/filter";

// custom hooks
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

// types
import type { Item } from "@/pages/settlement/sales-entry/daily/types";
import type { NonMatchedData } from "@/types/excelUpload";
import type { FileData } from "@/components/molecules/common/modal/ExcelUploadSalesModal";

const useDailyExcelUpload = () => {
    const { lastId, platformId, date } = useSelectorTyped((state: RootState) => state.entryDaily);
    const { salesDate } = useSelectorTyped((state: RootState) => state.uploader);
    const { platformList } = useSelectorTyped((state: RootState) => state.platform);
    const [showMatchingModal, setShowMatchingModal] = useState(false);
    const [nonMatchedData, setNonMatchedData] = useState<Partial<NonMatchedData>[]>([]);

    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    // 업로드된 엑셀 데이터중 시리즈 매칭 안된것들 선별
    const filterNonMatchedData = (data: Item[]) => {
        let filteredData: Partial<NonMatchedData>[] = [];
        data.forEach((row, idx) => {
            if (!row.series_name) {
                filteredData.push({
                    originIdx: idx,
                    isChecked: false,
                    ...row,
                });
            }
        });
        return filteredData;
    };

    const applyExcelUpload = async (fileData: FileData) => {
        try {
            loadingOn();
            dispatch(setUploaderMsg(""));
            let platformName = "";

            for (let i of platformList) {
                if (i.platform_id === platformId) platformName = i.platform_name;
            }
            const apiParams = {
                ...fileData,
                type: "Daily",
                platformId,
                platformName,
                date: date || salesDate,
            };
            const { data } = await dispatch(uploadExcelData(apiParams)).unwrap();

            let amount = 0;
            let tmpLastId = lastId;

            if (data.length) {
                for (let i of data) {
                    amount += i.amount;
                    i.amount = addComma(i.amount);
                    tmpLastId += 1;
                    i.id = tmpLastId;
                }
                dispatch(setTotal(data.length));
                dispatch(setItems(data));
                dispatch(setLastId(tmpLastId));

                if (fileData.form === "플랫폼") {
                    const filteredData = filterNonMatchedData(data);
                    if (filteredData.length) {
                        setShowMatchingModal(true);
                        setNonMatchedData(filteredData);
                    } else {
                        dispatch(setUploaderMsg("EXCEL_UPLOADED"));
                    }
                }
                dispatch(setAfterExcelUpload(true));
            } else {
                // 엑셀 파일에 데이터가 없거나 판매일자가 없는 경우
                const items = [
                    {
                        id: (tmpLastId += 1),
                        name: "",
                        series_id: "",
                        series_name: "",
                        author_name: "",
                        quantity: "",
                        amount: "",
                        contentError: "",
                        seriesError: "",
                        quantityError: "",
                        amountError: "",
                    },
                ];
                dispatch(setItems(items));
                dispatch(setLastId(tmpLastId));
                dispatch(setLastId(tmpLastId));
                dispatch(setUploaderMsg("NO_DATA"));

                return await alert("warning", "주의", "입력하신 판매 일에 데이터가 존재하지 않습니다.");
            }
            dispatch(setAmount(Number(amount.toFixed(2))));
        } catch (err) {
            dispatch(setUploaderMsg("ERROR"));
            return await alert(
                "error",
                "오류 발생",
                "업로드중 오류가 발생하였습니다.\n파일 형식 또는 내용이 선택한 플랫폼과 맞는지 확인해주세요.",
            );
        } finally {
            loadingOff();
        }
    };

    return {
        showMatchingModal,
        nonMatchedData,
        setShowMatchingModal,
        setNonMatchedData,
        applyExcelUpload,
    };
};

export default useDailyExcelUpload;
