import { useEffect, useRef } from 'react';
import useSelectorTyped from '@/modules/hooks/useSelectorTyped';
import { RootState, useAppDispatch } from '@/modules/store';
import { paginationActions } from '@/pages/common/paginationSlice';
import type { Item } from '@/components/molecules/common/table/DataTable';

// 컴포넌트와 페이지네이션 슬라이스의 브릿지 역할
// 언마운트 시 페이지네이션 슬라이스 리셋 => 페이지 변경으로 인해 언마운트되는 곳에서는 pagination slice에서 직접 데이터 호출
const usePagination = ({ from, tableData, initialValue }:PaginationProps) => {
    const dispatch = useAppDispatch();
    const prevInitialValue = useRef({
        dataCount: 1,
        page: 1
    });
    const defaultInitialValue = {
        itemsPerPage: 10,
        pageCount: 1,
        totalVisible: 11,
        page: 1,
        dataCount: 1
    };

    const data = useSelectorTyped((state: RootState) => state.pagination.data[from]);
    const isInitialized = !!data;
    const page = data ? data.page : 1;
    const itemsPerPage = data ? data.itemsPerPage : 10;
    const pageCount = data ? data.pageCount : 1;
    const totalVisible = data ? data.totalVisible : 10;

    const initialize = (value: PaginationProps['initialValue']) => dispatch(paginationActions.initialize({ initialValue: value, from }));
    const setPage = (page: number) => dispatch(paginationActions.setPage({ page, from }));
    const setDataCount = (dataCount: number) => dispatch(paginationActions.setDataCount({ dataCount, from }));
    const setItemsPerPage = (itemPerPage: number) => dispatch(paginationActions.setItemsPerPage({ itemPerPage, from }));
    const setTotalVisible = (totalVisible: number) => {
        dispatch(paginationActions.setTotalVisible({ totalVisible, from }));
    }
    const reset = (from: string) => dispatch(paginationActions.reset({ from }));

    useEffect(() => {
        if (!isInitialized) {
            const value = initialValue ? { ...initialValue, pageCount: 1, dataCount:1 } : defaultInitialValue;
            initialize(value);
            return;
        }
        
        // 초기 페이지 설정이 있는경우 (ex. url querystring을 사용할경우 등)
        if (initialValue && prevInitialValue.current.page !== initialValue.page) {
            setPage(initialValue.page);
            prevInitialValue.current.page = initialValue.page;
        }

    }, [initialValue]);

    useEffect(() => {
        if (!tableData) return;
        if (prevInitialValue.current.dataCount !== tableData.length) {
            setDataCount(tableData.length);
            prevInitialValue.current.dataCount = tableData.length;
        }

    }, [tableData])

    useEffect(() => {
        return () => { reset(from); };
    }, []);

    return {
        page,
        itemsPerPage,
        pageCount,
        totalVisible,
        setPage,
        setDataCount,
        setItemsPerPage,
        setTotalVisible,
    };
};

interface PaginationProps {
    from: string;
    tableData?: Item[]; // 테이블에 보여질 데이터 state
    initialValue?: {
        itemsPerPage: number; // 한 페이지 내 아이템 수
        totalVisible: number; // 페이지네이션으로 보여질 페이지 개수
        page: number; // 현재 페이지
    };
}

export default usePagination;