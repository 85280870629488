import { ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components";
import SearchIcon from "@/assets/icons/search_blue.svg";

function TextInput({
    inputType,
    type,
    textName,
    value,
    onChangeHandler,
    onClickHandler,
    onKeyDownHandler,
    onKeyPressHandler,
    onKeyUp,
    onFocusHandler,
    onBlurHandler,
    placeholder,
    customStyle,
}: TextInputProps) {
    switch (type) {
        case "readOnly":
            return (
                <ReadOnlyInput
                    disabled
                    type={inputType ? inputType : "text"}
                    name={textName ? textName : ""}
                    value={value}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    placeholder={placeholder}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    padding={customStyle?.padding}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    textAlign={customStyle?.textAlign}
                    margin={customStyle?.margin}
                />
            );
        case "search":
            return (
                <>
                    {/* <img src={SearchIcon} alt={"logo"} /> */}
                    <SearchInput
                        type={inputType ? inputType : "text"}
                        name={textName ? textName : ""}
                        value={value}
                        onChange={onChangeHandler}
                        onClick={onClickHandler}
                        onBlur={onBlurHandler}
                        placeholder={placeholder}
                        width={customStyle?.width}
                        height={customStyle?.height}
                        fontSize={customStyle?.fontSize}
                        fontWeight={customStyle?.fontWeight}
                        borderColor={customStyle?.borderColor}
                        borderRadius={customStyle?.borderRadius}
                        color={customStyle?.color}
                        padding={customStyle?.padding}
                        textAlign={customStyle?.textAlign}
                        margin={customStyle?.margin}
                    />
                </>
            );
        default:
            return (
                <BaseInput
                    type={inputType ? inputType : "text"}
                    name={textName ? textName : ""}
                    value={value}
                    onChange={onChangeHandler}
                    onClick={onClickHandler}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDownHandler}
                    onKeyPress={onKeyPressHandler}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    placeholder={placeholder}
                    width={customStyle?.width}
                    height={customStyle?.height}
                    padding={customStyle?.padding}
                    fontSize={customStyle?.fontSize}
                    fontWeight={customStyle?.fontWeight}
                    borderColor={customStyle?.borderColor}
                    borderRadius={customStyle?.borderRadius}
                    color={customStyle?.color}
                    textAlign={customStyle?.textAlign}
                    margin={customStyle?.margin}
                />
            );
    }
}

const BaseInput = styled.input<StyleProps>`
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "40px")};
    padding: ${({ padding }) => (padding ? padding : "8px")};
    margin: ${({ margin }) => (margin ? margin : "")};
    background-color: ${({ theme }) => theme.colors.white};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
    border: ${({ theme, borderColor }) => `1px solid ${borderColor ? borderColor : theme.colors.gray300}`};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "5px")};
    color: ${({ color, theme }) => (color ? color : theme.colors.black)};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};

    ::placeholder {
        color: ${({ theme }) => theme.colors.gray600};
    }
`;

const ReadOnlyInput = styled(BaseInput)`
    background-color: ${({ theme }) => theme.colors.gray50};
    color: ${({ color, theme }) => (color ? color : theme.colors.gray600)};
`;

const SearchInput = styled(BaseInput)`
    width: ${({ width }) => (width ? width : "200px")};
    height: ${({ height }) => (height ? height : "40px")};
    border: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
    padding: ${({ padding }) => (padding ? padding : "4px 32px 4px 4px")};
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: right center;
`;

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    borderColor?: string;
    borderRadius?: string;
    color?: string;
    textAlign?: string;
    
}

interface TextInputProps {
    type?: "main" | "readOnly" | "search";
    textName?: string;
    inputType?: string;
    value: number | string;
    placeholder?: string;
    disabled?: boolean;
    onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    onClickHandler?: () => void;
    onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onKeyDownHandler?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onKeyPressHandler?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onFocusHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlurHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
    customStyle?: StyleProps;
}

export default TextInput;
