import React from "react";
import styled, { css } from "styled-components";

function Radio({ type, id, value, label, name, checked, onChange, onClick, customStyle }: RadioProps) {
    switch (type) {
        case "label":
            return (
                <Label htmlFor={id} margin={customStyle?.margin}>
                    <RadioLabel active={checked}>
                        <Input id={id} type="radio" name={name} value={value} onChange={onChange} onClick={onClick} />
                    </RadioLabel>
                    <LabelText fontSize={customStyle?.fontSize} fontWeight={customStyle?.fontWeight}>
                    {label}
                    </LabelText>
                </Label>
            );
        case "label-disabled":
            return (
                <Label htmlFor={id} margin={customStyle?.margin}>
                    <RadioLabel active={checked} disabled={true}>
                        <Input id={id} type="radio" disabled={true} name={name} value={value} onChange={onChange} onClick={onClick} />
                    </RadioLabel>
                    <LabelText fontSize={customStyle?.fontSize} fontWeight={customStyle?.fontWeight} disabled={true}>
                    {label}
                    </LabelText>
                </Label>
            );
        case "disabled":
            return (
                <RadioLabel active={checked} disabled={true}>
                    <Input id={id} type="radio" disabled={true} name={name} value={value} onChange={onChange} />
                </RadioLabel>
            )
        default:
            return (
                <RadioLabel active={checked}>
                    <Input id={id} type="radio" name={name} value={value} onChange={onChange} />
                </RadioLabel>
            );
    }
}
const LabelText = styled.div<StyleProps>`
    margin-left: 8px;
    font-size: ${({ fontSize }) => fontSize ? fontSize : "12px"};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : "400"};
    color: ${({ disabled, theme }) => disabled ?  theme.colors.gray300 : "#000000" };
`
const Label = styled.label<StyleProps>`
    display: flex;
    align-items: center;
    margin: ${({ margin }) => margin ? margin : ""}
`

const RadioLabel = styled.label<{ active?: boolean, disabled?: boolean }>`
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 50px;
    cursor: pointer;
    ${({ active }) =>
        active &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.blue500};
            background-color: ${({ theme }) => theme.colors.white};
            &:after {
                content: "";
                position: absolute;
                background-color: ${({ theme }) => theme.colors.blue500};
                border-radius: 50px;
                width: 10px;
                height: 10px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);   
            }
        `}
    ${({ disabled }) =>
        disabled &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.gray300};
            cursor: default;
            &:after {
                background-color: ${({ theme }) => theme.colors.gray300};
            }
        `}
`;

// const ActiveLabel
const Input = styled.input`
    display: none;
`;

export interface RadioProps {
    type?: "main" | "label" | "label-disabled" | "disabled";
    label?: string;
    id?: string;
    name: string;
    value: string | number;
    checked?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    customStyle?: StyleProps;
}

interface StyleProps {
    width?: string;
    height?: string;
    margin?: string;
    padding?: string;
    fontSize?: string;
    fontWeight?: string;
    disabled?: boolean;
}

export default Radio;
