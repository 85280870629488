import { CommonType } from "@/types/dataType";

/**
 * 변경할 날짜가 적합한지 판단
 * @returns 시작, 종료일 검증 함수
 */
const useValidateDate = () => {
    const validateStartDate = (date: CommonType.Date, endDate: CommonType.Date) => {
        const startDateError = date > endDate ? "종료일자 이전의 날짜를 선택해주세요." : "";
        const endDateError = "";
        return { startDateError, endDateError };
    };
    const validateEndDate = (date: CommonType.Date, startDate: CommonType.Date) => {
        const endDateError = date < startDate ? "시작일자 이후의 날짜를 선택해주세요." : "";
        const startDateError = "";
        return { startDateError, endDateError };
    };

    return { validateStartDate, validateEndDate };
};

export default useValidateDate;
