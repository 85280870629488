import { createSlice } from "@reduxjs/toolkit";

const initialState : LoadingState = {
    fullLoading: false,
    progress: false,
};

const loadingSlice = createSlice({
    name: "loading",
    initialState: initialState,
    reducers: {
		reset() {
			return {...initialState};
		},
        setFullLoading(state, action) {
            state.fullLoading = action.payload;
		},
        setProgress(state, action) {
            state.progress = action.payload;
		},
    }
});

export const loadingActions = loadingSlice.actions;
export default loadingSlice.reducer;


interface LoadingState {
    fullLoading: boolean | "table" | "portfolio" | "total";
    progress: boolean|number;
}