import { ChangeEvent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { convertImg, updateUserInfo } from "@/pages/common/userSlice";
import { myPageActions } from "@/pages/mypage/slice";
import TextInput from "@/components/atoms/input/TextInput";
import Text from "@/components/atoms/text";
import BaseInput from "../signup/BaseInput";
import Button from "@/components/atoms/button";
import MemoizedCheckbox from "@/components/atoms/input/Checkbox";
import KcpForm from "@/components/molecules/signin/KcpForm";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { base64Encode } from "@/modules/utils/file";
import addIcon from "@/assets/icons/add_round_blue500.svg";

export default function EditMyInfo() {
    const { user_adult, user_sex, user_birth_dt, kcp_di } = useAppSelector(
        (state: RootState) => state.myPage.userProfile,
    );
    const {
        isLogin,
        user_id,
        user_nickname,
        mailing_address,
        user_pic_path,
        user_mobile,
        user_emailagree,
        user_created_at,
        sociallogin_provider,
    } = useAppSelector((state: RootState) => state.user);

    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const [isChecked, setIsChecked] = useState(user_emailagree === 0 ? false : true);
    const [inputs, setInputs] = useState({
        user_nickname,
        mailing_address,
        user_mobile,
    });
    const [userMobile, setMobile] = useState(user_mobile);
    const [img, setImg] = useState<string | File>("");
    //0: 서버에서 받아온 이미지(이미지 변경x), 1: 기본 이미지로 변경 2: 사용자 선택 이미지 변경
    const [isImgChange, setImgChange] = useState(0);
    const [imgUrl, setImgUrl] = useState("");
    const [nickNameError, setNickNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [authDone, setAuthDone] = useState<any>(null);

    const user_date = user_created_at.substring(0, 10).replace(/-/gi, ". ");
    const checkText = { id: 0, text: "광고성 이메일 수신에 동의합니다. (선택)" };

    useEffect(() => {
        if (user_pic_path) imgConvert(user_pic_path);
    }, [user_pic_path]);

    useEffect(() => {
        setMobile(user_mobile);
        setImgUrl(imgUrl);
    }, [user_mobile, img]);

    const imgConvert = async (imgPath: string) => {
        try {
            const res = await dispatch(convertImg(imgPath)).unwrap();
            setImgUrl(window.URL.createObjectURL(res));
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        }
    };

    function imageUpload() {
        document.getElementById("selectImage")?.click();
    }

    async function selectImage(e: ChangeEvent<HTMLInputElement>) {
        const file = e.target.files && e.target.files[0];

        if (file) {
            if (file.size > 2097152.0) {
                return await alert("warning", "주의", "2Mb 이하 파일만 등록 가능합니다.");
            }
            if (file.type === "image/png" || file.type === "image/jpeg") {
                setImgUrl(window.URL.createObjectURL(file));
                setImg(file);
                setImgChange(2);
            } else {
                setImg("");
                return await alert("warning", "주의", ".jpg 또는 .png 파일만 업로드 가능합니다.");
            }
        } else {
            setImg("");
        }
    }

    async function changeHandlerNickname(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;

        setInputs({ ...inputs, user_nickname: value });
        if (value.length > 1 && value.length < 11) {
            setNickNameError("");
        } else {
            setNickNameError("2~10글자 이내로 입력하세요.");
        }
    }

    function changeHandlerEmail(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;

        setInputs({ ...inputs, mailing_address: value });
        if (/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/i.test(value)) {
            setEmailError("");
        } else {
            setEmailError("이메일 형식을 확인해주세요.");
        }
    }

    const keyPressHandler = (key: any) => {
        if (key === "Enter") {
            submitHandler();
        }
    };

    const next = () => {
        let hasError = false;

        if (!inputs.user_nickname) {
            setNickNameError("별명을 입력해주세요.");
            hasError = true;
        }
        if (!inputs.mailing_address) {
            setEmailError("수신 이메일을 입력해주세요.");
            hasError = true;
        }

        return hasError;
    };

    const onMobileChange = () => {};

    async function openAuthPopup() {
        try {
            setAuthDone(true);
        } catch (err: any) {
            return await alert("error", "오류발생", err.message);
        }
    }

    async function setKcpData(param: any, joined: any) {
        try {
            setMobile(param.user_mobile);
            dispatch(myPageActions.updateMobile(param));
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        }
    }

    const defaultButtonHanlder = async () => {
        setImgChange(1); //기본 이미지
        imgConvert("/static/user.jpg");
    };

    const submitHandler = async () => {
        const hasError = next();

        if (hasError) {
            return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
        }

        const pic_path =
            isImgChange === 1
                ? "/static/user.jpg"
                : typeof img !== "string"
                ? `/userpic/${user_id}.${img.type.split("/")[1]} `
                : user_pic_path;

        const apiData = {
            sociallogin_provider: sociallogin_provider === null ? "" : sociallogin_provider,
            user_nickname: inputs.user_nickname,
            mailing_address: inputs.mailing_address,
            user_id,
            user_mobile: userMobile,
            user_adult,
            user_sex,
            user_birth_dt,
            kcp_di,
            isLogin,
            user_emailagree: isChecked ? 1 : 0,
            updating: false,
            profile_changed: isImgChange === 0 ? false : true,
            profile_img: img ? await base64Encode(img as File) : null,
            user_pic_path: pic_path,
        };

        try {
            loadingOn();
            await dispatch(updateUserInfo(apiData)).unwrap();
            alert("success", "수정완료 ", "정보가 수정되었습니다.");
            setImgChange(0);
        } catch (err: any) {
            alert("error", "오류발생", err.message);
        } finally {
            loadingOff();
        }
    };

    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    };

    const subContentTitleStyled = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17.5px",
        color: colors.gray600,
        margin: "0px 0px 8px 0px",
    };

    const inputStyled = {
        color: colors.black,
        fontSize: "12px",
        fontWeigth: "400",
        width: "400px",
        height: "40px",
        borderColor: colors.gray300,
        borderRadius: "4px",
    };

    const checkBoxTextStyled = {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "14px",
        margin: "13px 8px",
    };

    const joinDateStyled = {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "14px",
        color: colors.gray600,
        margin: "24px 0px",
    };

    const buttonStyled = {
        width: "400px",
        height: "40px",
        margin: "0px 0px 8px 0px",
        borderRadius: "4px",
        fontWeight: "500",
        fontSize: "16px",
    };

    const defaultImgButtonStyled = {
        width: "100px",
        height: "32px",
    };

    return (
        <MypageSectionLayout>
            <StyledTitleContainer>
                <Text text="내 정보 수정" customStyle={titleStyled} />
            </StyledTitleContainer>
            <ProfileImgContainer>
                <ProfileImg src={imgUrl} alt="프로필" />
                <CameraImg src={addIcon} onClick={imageUpload} />
                <DefaultButtonStyled>
                    <Button
                        type={"gray-outline"}
                        label="기본 이미지"
                        customStyle={defaultImgButtonStyled}
                        onClickHandler={defaultButtonHanlder}
                    />
                </DefaultButtonStyled>
                <HiddenImgInput
                    id="selectImage"
                    type="file"
                    hidden
                    onChange={(e) => selectImage(e)}
                    accept="image/jpeg, image/png"
                />
            </ProfileImgContainer>
            {sociallogin_provider ? <SnsType type={sociallogin_provider} /> : <></>}
            <ContentContainer>
                <BaseInput
                    label="별명"
                    value={inputs.user_nickname}
                    placeholder="별명"
                    errorMsg={nickNameError}
                    onChange={changeHandlerNickname}
                    onKeyPress={keyPressHandler}
                />
                <BaseInput
                    label="수신 이메일"
                    value={inputs.mailing_address}
                    placeholder="수신 가능한 이메일"
                    errorMsg={emailError}
                    onChange={changeHandlerEmail}
                    onKeyPress={keyPressHandler}
                />
                <SubContentContainer>
                    <Text text="휴대폰" customStyle={subContentTitleStyled} />
                    <TextInput
                        type="readOnly"
                        disabled={true}
                        value={userMobile}
                        onChangeHandler={onMobileChange}
                        customStyle={inputStyled}
                    />
                </SubContentContainer>
                <CheckContainer>
                    <MemoizedCheckbox
                        type="boolean"
                        active={isChecked}
                        onChangeHandler={() => setIsChecked(!isChecked)}
                    />
                    <Text text={checkText.text} customStyle={checkBoxTextStyled} />
                </CheckContainer>
                <Text text={`가입일 ${user_date}`} customStyle={joinDateStyled} />
                <Button
                    type="gray-outline"
                    label="휴대폰 인증"
                    customStyle={buttonStyled}
                    onClickHandler={openAuthPopup}
                />
                <Button label="수정" customStyle={buttonStyled} onClickHandler={submitHandler} />
            </ContentContainer>
            <NavbarStyled>
                <CustomNav title="탈퇴하기" to="/deleteUser" />
            </NavbarStyled>
            <KcpForm authDone={authDone} setResult={setKcpData} />
        </MypageSectionLayout>
    );
}

function SnsType({ type }: { type: string }) {
    let typeUpper = "";
    let backgroundColor = "";
    let color = "";
    let typeKorean = "";
    let borderColor = "";

    if (type === "kakao") {
        typeUpper = "KAKAO";
        backgroundColor = "#FAE100";
        color = colors.black;
        typeKorean = "카카오";
    } else if (type === "naver") {
        typeUpper = "NAVER";
        backgroundColor = "#1EC800";
        color = colors.white;
        typeKorean = "네이버";
    } else if (type === "google") {
        typeUpper = "GOOGLE";
        backgroundColor = colors.white;
        color = colors.black;
        typeKorean = "구글";
        borderColor = colors.gray300;
    }

    return (
        <SnstypeContainer>
            <Text
                text="가입구분"
                customStyle={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "17.5px",
                    color: colors.gray600,
                    height: "26px",
                }}
            />
            <SnstypeContent>
                <SnstypeBox backgroundColor={backgroundColor} color={color} borderColor={borderColor}>
                    {typeUpper}
                </SnstypeBox>
                <Text text={typeKorean} customStyle={{ fontWeight: "400", fontSize: "12px", lineHeight: "21px" }} />
            </SnstypeContent>
        </SnstypeContainer>
    );
}

export function CustomNav({ title, to }: { title?: string; to: string }) {
    const navStyle = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "14px",
        color: colors.gray600,
    };

    return (
        <NavLink to={to} style={navStyle}>
            {title}
        </NavLink>
    );
}

const MypageSectionLayout = styled.div``;

const StyledTitleContainer = styled.div`
    display: flex;
    margin: 0px 0px 24px 0px;
`;

const SnstypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 66px;
    margin-bottom: 24px;
`;

const SnstypeContent = styled.div`
    display: flex;
    align-items: center;
`;

const SnstypeBox = styled.div<{ backgroundColor: string; color?: string; borderColor?: string }>`
    width: 100px;
    height: 32px;
    background: ${(props) => props.backgroundColor};
    color: ${(props) => (props.color ? props.color : colors.black)};
    border-radius: 99px;
    border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : null)};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
`;

const ProfileImgContainer = styled.div`
    width: 400px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const ProfileImg = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
`;

const CameraImg = styled.img`
    width: 48px;
    height: 48px;
    position: absolute;
    top: 62px;
    left: 220px;
    cursor: pointer;
`;

const DefaultButtonStyled = styled.div`
    position: absolute;
    top: 70px;
    left: 300px;
    cursor: pointer;
`;

const HiddenImgInput = styled.input``;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const SubContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0px 0px 24px 0px;
`;

const CheckContainer = styled.label`
    display: flex;
    width: 400px;
    height: 40px;
    position: relative;
    align-items: center;
`;

const NavbarStyled = styled.div`
    width: 100px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
