import styled from "styled-components";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import UploadContainer from "@/components/molecules/common/upload";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/modules/store";
import { appFileActions, uploadAppFile } from "./appSlice";
import { colors } from "@/assets/styles/theme";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";
import { useState } from "react";

import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { ReactComponent as ArrowRight } from "@/assets/icons/appFileUpload/arrow-right.svg";
import { ReactComponent as Subtract } from "@/assets/icons/appFileUpload/subtract.svg";
import { ReactComponent as SuccessMark } from "@/assets/icons/alert_success.svg";

export default function AppFileUpload() {
    const { fileList } = useAppSelector(state => state.appFile);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    const [complete, setComplete] = useState(false);
    const uploadType = searchParams.get("type");
    const fileKey = searchParams.get("key");
    const plan = searchParams.get("plan");

    let acceptable: FileType[] = ["hwp"];
    let multiple = true;
    switch (uploadType) {
        case "RV": // 검수
            acceptable = ["txt", "hwp", "hwpx", "docx", "doc", "pdf"];
            break;
        case "CO": // 표지
            acceptable = ["jpg", "png", "jpeg", "psd"];
            break;
        case "CR": // 표지 제작의뢰
            acceptable = ["hwp", "hwpx"];
            multiple = false;
            break;
        case "MA": // Basic - 원고 or 작교본 파일
            acceptable = ["hwp", "hwpx", "docx", "doc", "txt"];
            multiple = false;
            break;
        case "F1": // 원고파일
            acceptable = ["hwp", "hwpx", "docx", "doc", "txt"];
            multiple = false;
            break;
        case "F2": // 이펍파일
            acceptable = ["epub"];
            multiple = false;
            break;
        case "F3": // 표지
            acceptable = ["jpg", "png", "jpeg"];
            multiple = false;
            break;
        default:
            break;
    }

    const uploadHandler = (fileList: any) => {
        dispatch(appFileActions.setFileList(fileList));
    }

    const deleteFile = (idx: number) => {
        const tmpFileList = JSON.parse(JSON.stringify(fileList));
        tmpFileList.splice(idx, 1);
        dispatch(appFileActions.setFileList(tmpFileList));
    }

    const enrollFile = async () => {
        try {
            const answer = await alert("confirm", "업로드 확인", "업로드 하시겠습니까?");
            if (answer === "Y") {
                loadingOn();
                await dispatch(uploadAppFile({
                    plan,
                    type: uploadType,
                    fileKey,
                })).unwrap();
                setComplete(true);
            }
        } catch (err) {
            alert("error", "오류 발생", "파일 업로드 중 오류가 발생하였습니다.");
        } finally {
            loadingOff();
        }
    }

    if (complete) {
        return (
            <SectionLayout>
                <ContentContainer>
                    <SuccessMark width={48} height={48} fill={colors.blue500} />
                    <CompleteText1>파일 업로드가 완료되었습니다.</CompleteText1>
                    <CompleteText2>글링 어플에서 다음 단계를 진행해주세요.</CompleteText2>
                </ContentContainer>
            </SectionLayout>
        )
    }

    return (
        <SectionLayout>
            <TitleText>글링에 업로드 할 파일을 올려주세요!</TitleText>
            {fileList.length 
                ? (
                    <>
                        <TableHeader>파일 업로드</TableHeader>
                        {fileList.map((f: any, idx: number) => (
                            <RowWrapper key={f.name} isBottom={idx === fileList.length-1}>
                                <FileNameWrapper>{f.name}</FileNameWrapper>
                                <IconWrapper onClick={() => deleteFile(idx)}>
                                    <Clear fill={colors.gray600} width={24} height={24} />
                                </IconWrapper>
                            </RowWrapper>
                        ))}
                    </>
                )
                : (
                    <UploadContainer 
                        accept={acceptable}
                        multiple={multiple}
                        uploadHandler={uploadHandler}
                        height={280}
                        maxSize={uploadType === "CO" ? 100000000 : 10000000}
                    />
                )
            }

            {Boolean(fileList.length) && (
                <>
                    <UploadButton onClick={enrollFile}>
                        <ButtonText>글링 업로드</ButtonText>
                        <ArrowRight />
                    </UploadButton>

                    <DescWrapper>
                        <Subtract />
                        <DescText>pc에서 업로드 후 어플에서 업로드 완료 버튼을 눌러주세요!</DescText>
                    </DescWrapper>
                </>
            )}
        </SectionLayout>
    )
}

const TitleText = styled.div`
    font-size: 28px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 68px;
`

const TableHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-left: 16px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 12px;
    font-weight: 500;
`

const RowWrapper = styled.div<{ isBottom: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 16px;
    border-bottom: ${({ isBottom }) => `1px solid ${isBottom ? colors.black : colors.gray300}`};
`

const FileNameWrapper = styled.div`
    font-size: 14px;
`

const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const UploadButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    border-radius: 7px;
    background-color: ${colors.blue300};
    margin: 24px 0;
    cursor: pointer;
`

const ButtonText = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-right: 8px;
`

const DescWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #E7F5FF;
    padding: 0 20px;
`

const DescText = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: ${colors.information};
    margin-left: 16px;
`

const ContentContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 300px;
`

const CompleteText1 = styled.div`
    font-weight: 700;
    font-size: 28px;
    margin: 16px 0;
`

const CompleteText2 = styled.div`
    font-weight: 400;
    font-size: 16px;
    color: ${colors.gray600};
`

type FileType = "txt" | "hwp" | "hwpx" | "docx" | "doc" | "pdf" | "jpg" | "jpeg" | "png" | "css" | "ppt" | "psd" | "epub";
