import { useCallback, useState } from "react";

// reducers
import { useAppDispatch } from "@/modules/store";
import { setItemValue, setItem } from "@/pages/settlement/sales-entry/daily/dailySlice";

// components
import ModalPortal from "@/components/molecules/common/modal/Portal";
import SearchSeriesModal from "@/components/molecules/common/modal/SearchSeriesModal";
import ModalSearchBar from "../../common/searchBar/modalSearchBar/ModalSearchBar";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";

// custom hooks
import useAlert from "@/modules/hooks/useAlert";

// utils
import { series, Validation } from "@/modules/utils/validate_modify";

// types
import type { SearchSeriesResult, SelectedSeries } from "@/types/searchSeries";
import type { CommonType } from "@/types/dataType";

export default function SeriesNameField({ item, idx, platformId }: SeriesNameFieldProps) {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);
    const handleModal = useCallback(() => setShowModal((prev) => !prev), []);
    const { alert } = useAlert();

    const applySeries = (data: SearchSeriesResult) => {
        const { selectedSeries } = data;
        dispatch(setItem({ index:idx, item: {
            ...item,
            series_id: (selectedSeries as SelectedSeries).id,
            series_name: (selectedSeries as SelectedSeries).series_name,
            author_name: (selectedSeries as SelectedSeries).author_name,
            seriesError: "",
        }}));
    };

    const validateSeries = async (keyword: string) => {
        try {
            const res = (await series({
                value: keyword,
                error: "seriesError",
                mandatory: true,
                platform: 21,
            })) as Validation;
    
            let newItem = {
                ...item,
                series_id: res.value.series_id,
                series_name: res.value.series_name || keyword,
                author_name: res.value.author_name || "",
                seriesError: res.seriesError,
            };
            dispatch(setItem({ index:idx, item:newItem }));
        } catch (err) {
            if (err) alert("error", "오류 발생", "시리즈 조회 중 오류가 발생하였습니다.");
        }
    };

    const changeSeries = (keyword:string) => {
        dispatch(setItemValue({ index:idx, key:'series_name', value:keyword }));
    }

    return (
        <>
            <ValidateWrapper dataError={item.seriesError}>
                <ModalSearchBar
                    type="default"
                    placeholder="시리즈명"
                    value={item.series_name}
                    className="search-series-modal"
                    onChange={changeSeries}
                    onHandleModal={handleModal}
                    onKeyUp={validateSeries}
                    customStyle={{ width: "100%" }}
                />
            </ValidateWrapper>
            <ModalPortal>
                <SearchSeriesModal
                    from="daily"
                    show={showModal}
                    close={handleModal}
                    platformId={platformId}
                    withRatio={false}
                    multiSelect={false}
                    applySeries={applySeries}
                />
            </ModalPortal>
        </>
    );
}

interface SeriesNameFieldProps {
    item: any;
    idx: number;
    platformId: CommonType.Id;
}
