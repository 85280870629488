import styled from "styled-components";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import { ReactComponent as NeedAuthIcon } from "@/assets/icons/empty/need-auth.svg"
import Button from "@/components/atoms/button";
import { useNavigate } from "react-router-dom";

export default function NeedAuth() {
    const navigate = useNavigate();

    const title = "출판사만 접근할 수 있는 페이지입니다.";
    const desc = "출판사 계정이 아닌 경우, 출판사 계정으로 회원가입 또는 로그인 해주세요.";

    const goBack = () => {
        navigate(-1);
    }

    const goHome = () => {
        navigate("/");
    }

    return (
        <SectionLayout>
            <EmptyPageConatiner>
                <NeedAuthIcon />
                <TitleWrapper>{title}</TitleWrapper>
                <DescWrapper>{desc}</DescWrapper>
                <ButtonWrapper>
                    <Button 
                        type="gray-outline"
                        label="&#60;&nbsp;&nbsp;&nbsp;&nbsp;이전 페이지"
                        onClickHandler={goBack}
                        customStyle={{ width: "160px" }}
                    />
                    <Button 
                        type="main"
                        label="홈으로 이동"
                        onClickHandler={goHome}
                        customStyle={{ width: "160px" }}
                    />
                </ButtonWrapper>
            </EmptyPageConatiner>
        </SectionLayout>
    )
}

const EmptyPageConatiner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 262px);
    align-items: center;
    justify-content: center;
`

const TitleWrapper = styled.div`
    font-size: 28px;
    font-weight: 700;
    margin-top: 48px;
    margin-bottom: 24px;
`

const DescWrapper = styled.div`
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.gray500};
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 328px;
    height: 40px;
    margin-top: 12px;
`