import {useRef, useCallback, useEffect} from 'react'
import styled from 'styled-components'
import { colors } from '@/assets/styles/theme';
import { useAppDispatch } from '@/modules/store';
import { questionActions } from '@/pages/support/question/slice'
import { ReactComponent as UP} from '@/assets/icons/up.svg';
import { ReactComponent as DOWN} from '@/assets/icons/down.svg';

export interface Iprops {
    name: string,
    id: number,
    title: string,
    content: string,
    toggle?: boolean,
    onCardClick: (id:number) => void
}

export default function QuestionCard({ name, id, title, content, toggle }: Iprops) {
    const dispatch = useAppDispatch();
    const parentRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);

    const handleButtonClick = useCallback(
        (event: any) => {
            event.stopPropagation();
            dispatch(questionActions.setToggle(id));
        }
      , [toggle]);

      const icon = !toggle ? <DOWN fill={colors.black}/> : <UP fill={colors.blue500}/>
    
      useEffect(()=> {
        if (parentRef.current === null || childRef.current === null) {
            return;
        }
        if (toggle) {
            parentRef.current.style.height = `${childRef.current.clientHeight}px`;
            parentRef.current.style.background = `${colors.gray50}`;
        } else {
            parentRef.current.style.height = "0";
            parentRef.current.style.background = `${colors.white}`
        }
      }, [toggle]);
    

    return (
            <QuestionCardContainer>
                <StyledHeader onClick={handleButtonClick}>
                    <QuestionCategory>
                        {name}
                    </QuestionCategory>
                    <QuestionTitle>
                        {title}
                    </QuestionTitle>
                    <IconWrapper>
                        {icon}
                    </IconWrapper>
                </StyledHeader>
                <StyledContentsWrapper ref={parentRef}>
                    <StyledContents ref={childRef}>{content}</StyledContents>
                </StyledContentsWrapper>
            </QuestionCardContainer>
    )
}

const QuestionCardContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
`;

const QuestionCategory = styled.div`
    display: table-cell;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.blue500};
    font-weight: 500;
    font-size: 14px;
`;

const QuestionTitle = styled.div`
    display: table-cell;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 8px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 16px;
    font-weight: 700;
`;

const IconWrapper = styled.div`
    position: absolute;
    right: 12px;
    top: 20px;
`;

const StyledHeader = styled.div`
    display: flex;
    width: 900px;
    height: 60px;
    margin-top: -1px;
    cursor: pointer;
    position: relative;
`;

const StyledContentsWrapper = styled.div`
    height: 0;
    width: 100%;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
`

const StyledContents = styled.div`
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    line-height: 24.5px;
`;
