import { CommonType } from "@/types/dataType";
import { createSlice } from "@reduxjs/toolkit";

const initialState: UploaderTypes = {
    isAuto: false,
    platform: 0,
    salesDate: "",
    salesYearmon: "",
};

const uploaderSlice = createSlice({
    name: "uploader",
    initialState,
    reducers: {
        setIsAuto: (state, action) => {
            state.isAuto = action.payload;
        },
        setPlatform: (state, action) => {
            state.platform = action.payload;
        },
        setSalesDate: (state, action) => {
            state.salesDate = action.payload;
        },
        setSalesYearmon: (state, action) => {
            state.salesYearmon = action.payload;
        },
    },
});

export interface UploaderTypes {
    isAuto: boolean;
    platform: CommonType.Id;
    salesDate: CommonType.Date;
    salesYearmon: CommonType.Date;
}

export const { setIsAuto, setPlatform, setSalesDate, setSalesYearmon } = uploaderSlice.actions;
export default uploaderSlice.reducer;
