import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getDailyData, inquiryDailyActions, downloadExcel } from "@/pages/settlement/saels-inquiry/publisher/daily/dailySlice";
import { deleteData } from "@/pages/settlement/saels-inquiry/publisher/daily/dailyDeleteSlice";
import { resetDataTable } from "@/pages/common/dataTableSlice";

// components
import SalesSummary from "@/components/molecules/sales-inquiry/publisher/common/SalesSummary";
import TableOptions from "@/components/molecules/sales-inquiry/publisher/common/TableOptions";

// custom hooks
import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useLoading from "@/modules/hooks/useLoading";

export default function DailyTableTop() {
    const { items, total_cnt, total_amt, reset } = useSelectorTyped((state: RootState) => state.inquiryDaily);
    const { selectedItems } = useSelectorTyped((state: RootState) => state.dataTable);
    const dispatch = useAppDispatch();
    const { setItemsPerPage } = usePagination({ from: "daily-inquiry" });
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    // itemPerPage 변경
    const changeItemPerPage = (itemPerPage: number) => {
        dispatch(inquiryDailyActions.setItemsPerPage(itemPerPage));
        setItemsPerPage(itemPerPage);
    }

    // 삭제
    const deleteDailyData = async () => {
        try {
            loadingOn();
            let selectRows:any = [];
            for (let item of selectedItems) {
                item.detail_series.map((detail:any) => selectRows.push(detail));
            }
            const apiParams = { selected: selectRows };
            await dispatch(deleteData(apiParams)).unwrap();
            await alert("info", "삭제 완료", "삭제가 완료되었습니다");
            await dispatch(getDailyData()).unwrap();
            dispatch(resetDataTable());
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    // 엑셀 다운로드
    const downloadDailyExcel = async () => {
        if (items.length > 0) {
            await dispatch(downloadExcel()).unwrap();
        } else {
            alert("info", "조회 완료", "조회된 데이터가 없습니다.");
        }
    };

    return (
        <TableTopContainer>
            <SalesSummary from="daily" total={total_cnt} amountName="판매 합계" amount={total_amt} />
            <TableOptions
                from="daily"
                reset={reset}
                changeItemPerPage={changeItemPerPage}
                saveData={()=> {}}
                deleteData={deleteDailyData}
                downloadExcel={downloadDailyExcel}
            />
        </TableTopContainer>
    );
}

const TableTopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
