import { useState, useEffect } from "react";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { setFilterOption, setError, resetStore } from "@/pages/settlement/saels-inquiry/author/mg/mgSlice";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import FilterContainer from "@/components/molecules/common/searchFilter/FilterContainer";
import MgSearchBar from "./MgSearchBar";
import DateRangePicker from "@/components/molecules/sales-inquiry/common/DateRangePicker";
import DateSelector from "@/components/molecules/common/form/DateSelector";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";
import Slider from "@/components/molecules/common/slider/Slider";
import useValidateDate from "@/modules/hooks/useValidateDate";
import useTagController from "../useTagController";
import useFilterSetting from "../hooks/useFilterSetting";
import { getEndDay, getStartDay, getThisMonth } from "@/modules/utils/date";

export default function MgSearchFilter() {
    const {
        totalDate,
        contractStartDate,
        contractEndDate,
        complete,
        contractStartDateError,
        contractEndDateError,
        sliderMaxValue,
        sliderMinValue,
    } = useSelectorTyped((state: RootState) => state.inquiryAuthorMg);
    const { platformList, publisherList, platformIds, publisherIds } = useFilterSetting();

    const dispatch = useAppDispatch();
    const [reset, setReset] = useState(false);
    const { showMoreTag, handleClickTag } = useTagController(["platform", "publisher"]);
    const { validateStartDate, validateEndDate } = useValidateDate();
    const initialTotalDate = getThisMonth();
    const initialContractStartDate = getStartDay("");
    const initialContractEndDate = getEndDay("");

    useEffect(() => {
        return () => { dispatch(resetStore()); };
    }, []);

    useEffect(() => {
        dispatch(
            setFilterOption({
                selectedPlatform: platformIds,
                selectedPublisher: publisherIds,
            }),
        );
    }, [platformList, publisherList]);

    // 리셋
    useEffect(() => {
        if (reset) setReset(false);
    }, [reset]);

    const clickResetButton = () => {
        setReset(true);
        dispatch(
            setFilterOption({
                target: "",
                totalDate: initialTotalDate,
                mgType: "platform",
                selectedPlatform: platformIds,
                selectedPublisher: publisherIds,
                contractStartDate: initialContractStartDate,
                contractEndDate: initialContractEndDate,
                complete: "all",
                contractType: "all",
                contractPrice: { start: 0, end: 0 },
                deductionPrice: { start: 0, end: 0 },
                remainPrice: { start: 0, end: 0 },
            }),
        );
        dispatch(setError({ startDateError: "", endDateError: "" }));
    };

    const changeTotalDate = (totalDate: string) => dispatch(setFilterOption({ totalDate }));

    const changeMgType = (mgType: string) => {
        if (mgType !=="platform") dispatch(setFilterOption({ mgType: mgType as any }));
    }

    const changePlatform = (ids: number[]) => dispatch(setFilterOption({ selectedPlatform: ids }));

    const changePublisher = (ids: number[]) => dispatch(setFilterOption({ selectedPublisher: ids }));

    const changeComplete = (complete: string) => dispatch(setFilterOption({ complete: complete as any }));

    const changeContract = (contractType: string) => dispatch(setFilterOption({ contractType: contractType as any }));

    const changeContractStartDate = (date: string) => {
        const totalDate = `${date}01`;
        const { startDateError, endDateError } = validateStartDate(date, contractEndDate);
        dispatch(setFilterOption({ contractStartDate: totalDate }));
        dispatch(setError({ contractStartDateError: startDateError, contractEndDateError: endDateError }));
    };

    const changeContractEndDate = (date: string) => {
        const year = Number(date.slice(0, 4));
        const month = Number(date.slice(4, 6));
        const lastDay = new Date(year, month, 0).getDate();
        const totalDate = `${date}${lastDay}`;
        const { startDateError, endDateError } = validateEndDate(totalDate, contractStartDate);

        dispatch(setFilterOption({ contractEndDate: totalDate }));
        dispatch(setError({ contractStartDateError: startDateError, contractEndDateError: endDateError }));
    };

    const changeContractPrice = (start: number, end: number) => {
        dispatch(setFilterOption({ contractPrice: { start, end } }));
    };

    const changeDeductionPrice = (start: number, end: number) => {
        dispatch(setFilterOption({ deductionPrice: { start, end } }));
    };

    const changeRemainPrice = (start: number, end: number) => {
        dispatch(setFilterOption({ remainPrice: { start, end } }));
    };

    return (
        <FilterContainer>
            <FilterRow
                label="검색"
                filterContent={[<MgSearchBar reset={reset} />]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="정산월"
                filterContent={[
                    <DateSelector onChangeHandler={changeTotalDate} initialDate={totalDate} />,
                    <ResetFilter onClick={clickResetButton} />,
                ]}
            />
            <FilterRow
                label="선인세 구분"
                filterContent={[
                    <TagFilter
                        type="radio"
                        items={mgTypeItems}
                        keyName="id"
                        valueName="name"
                        showMore={false}
                        onChange={changeMgType}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMoreTag.platform, onClick: () => handleClickTag("platform") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={platformList}
                        keyName={"id"}
                        valueName={"platform_name"}
                        onChange={changePlatform}
                        showMore={showMoreTag.platform}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="출판사"
                plusIcon={{ showPlusIcon: showMoreTag.publisher, onClick: () => handleClickTag("publisher") }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={publisherList}
                        keyName={"publisher_id"}
                        valueName={"publisher_name"}
                        onChange={changePublisher}
                        showMore={showMoreTag.publisher}
                        reset={reset}
                    />,
                ]}
            />
            {/* <FilterRow
                label="계약 시작일"
                filterContent={[
                    <DateRangePicker
                        type="yearAndMonth"
                        changeStartDate={changeContractStartDate}
                        changeEndDate={changeContractEndDate}
                        startDateToSet={contractStartDate}
                        endDateToSet={contractEndDate}
                        startDateError={contractStartDateError}
                        endDateError={contractEndDateError}
                    />,
                ]}
            /> */}
            <FilterRow
                label="소진여부"
                filterContent={[
                    <TagFilter
                        type="radio"
                        items={mgCompleteItems}
                        keyName="id"
                        valueName="name"
                        showMore={false}
                        onChange={changeComplete}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="계약형태"
                filterContent={[
                    <TagFilter
                        type="radio"
                        items={mgContractItems}
                        keyName="id"
                        valueName="name"
                        showMore={false}
                        onChange={changeContract}
                        reset={reset}
                    />,
                ]}
            />
            <FilterRow
                label="계약금액"
                filterContent={[
                    <Slider
                        min={sliderMinValue.contractPrice}
                        max={sliderMaxValue.contractPrice}
                        reset={reset}
                        onChange={changeContractPrice}
                    />,
                ]}
            />
            {complete !== "complete" ? (
                <FilterRow
                    label="소진금액"
                    filterContent={[
                        <Slider
                            min={sliderMinValue.deductionPrice}
                            max={sliderMaxValue.deductionPrice}
                            reset={reset}
                            onChange={changeDeductionPrice}
                        />,
                    ]}
                />
            ) : (
                <></>
            )}
            {complete !== "complete" ? (
                <FilterRow
                    label="잔여금액"
                    filterContent={[
                        <Slider
                            min={sliderMinValue.remainPrice}
                            max={sliderMaxValue.remainPrice}
                            reset={reset}
                            onChange={changeRemainPrice}
                        />,
                    ]}
                />
            ) : (
                <></>
            )}
        </FilterContainer>
    );
}

// 선인세 타입
const mgTypeItems = [
    {
        id: "platform",
        name: "플랫폼 선인세",
    },
    {
        id: "publisher",
        name: "출판사 선인세",
        disabled: true,
    },
];
// 선인세 소진 여부
const mgCompleteItems = [
    {
        id: "all",
        name: "전체",
    },
    {
        id: "incomplete",
        name: "소진미완료",
    },
    {
        id: "complete",
        name: "소진완료",
    },
];
// 선인세 계약
const mgContractItems = [
    {
        id: "all",
        name: "전체",
    },
    {
        id: "MG01",
        name: "작가계약",
    },
    {
        id: "MG02",
        name: "작품계약",
    },
];
