/* eslint-disable react-hooks/exhaustive-deps */
import useDidUpdateEffect from "@/modules/hooks/useDidUpdateEffect";
import { ChangeEvent, useCallback, useLayoutEffect, useState } from "react";
import styled, { css } from "styled-components";

function TagFilter({
    type = "checkbox",
    items = [],
    keyName,
    valueName,
    showMore = false,
    onChange,
    reset,
    defaultValue = true,
}: TagFilterProps) {
    const [selectAll, setSelectAll] = useState(true);
    const [selected, setSelected] = useState<{ key: number; value: string }[]>([]);
    const [radioSelected, setRadioSelected] = useState<{ key: number; value: string }>({
        key: 0,
        value: "",
    });
    const handleChangeCheckbox = onChange as (v: number[] | string[]) => void;
    const handleChangeRadio = onChange as (v: number | string) => void;

    /* 
    #=========================================#
    | 체크박스 이벤트 핸들러                        |
    #=========================================# 
    */
    const selectAllCheckboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
        let checked = !e.target.checked;
        setSelectAll(checked);
        if (checked) {
            const initValue = items.map((item) => ({ key: item[keyName], value: item[valueName] }));
            setSelected(initValue);
        } else {
            setSelected([]);
        }
    };
    const selectCheckboxHandler = (e: ChangeEvent<HTMLInputElement>, key: number) => {
        let hasValue = selected.findIndex((s) => s.key === key);

        if (hasValue > -1) {
            setSelected(selected.filter((s) => s.key !== key));
            setSelectAll(false);
        } else {
            setSelected((prev) => [...prev, { key, value: e.target.value }]);
            if (items.length === (selected.length+1)) setSelectAll(true);
        }
    };
    const checkCheckboxSelected = useCallback(
        (item: any) => {
            return selected.findIndex((s) => s.key === item[keyName]) > -1 ? true : false;
        },
        [keyName, selected],
    );

    /* 
    #=========================================#
    | 라디오 이벤트 핸들러                         |
    #=========================================# 
    */
    const selectRadioHandler = (e: ChangeEvent<HTMLInputElement>, key: number) => {
        setRadioSelected({ key, value: e.target.value });
    };
    const checkRadioSelected = (item: any) => (radioSelected.key === item[keyName] ? true : false);

    // 초기화
    const initState = () => {
        if (type === "checkbox") {
            const initValue = items.map((item) => ({ key: item[keyName], value: item[valueName] }));
            if (defaultValue) {
                setSelected(initValue);
                handleChangeCheckbox(initValue.map((s) => s.key));
            }
        } else {
            if (!items[0]) return;
            const initValue = { key: items[0][keyName], value: items[0][valueName] };
            setRadioSelected(initValue);
            handleChangeRadio(initValue.key);
        }
        if (defaultValue) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    };
    useLayoutEffect(() => {
        initState();
        return () => {};
    }, [items]);

    // 업데이트
    useDidUpdateEffect(() => {
        handleChangeCheckbox(selected.map((s) => s.key));
    }, [selected]);
    useDidUpdateEffect(() => {
        handleChangeRadio(radioSelected.key);
    }, [radioSelected]);

    // 리셋
    useDidUpdateEffect(() => {
        initState();
    }, [reset]);

    switch (type) {
        case "radio":
            return (
                <TagFilterWrapper>
                    {items.map((item) => (
                        <Label key={item[keyName]} checked={checkRadioSelected(item)}>
                            <input
                                type="checkbox"
                                value={item[valueName]}
                                onChange={(e) => selectRadioHandler(e, item[keyName])}
                                disabled={item["disabled"]}
                            />
                            {item[valueName]}
                        </Label>
                    ))}
                </TagFilterWrapper>
            );
        default:
            return (
                <TagFilterWrapper showMore={showMore}>
                    <Label key="전체" checked={selectAll}>
                        <input
                            type="checkbox"
                            value="전체"
                            onChange={(e) => selectAllCheckboxHandler(e)}
                        />
                        {"전체"}
                    </Label>
                    {items.map((item) => (
                        <Label key={item[keyName]} checked={checkCheckboxSelected(item)}>
                            <input
                                type="checkbox"
                                value={item[valueName]}
                                onChange={(e) => selectCheckboxHandler(e, item[keyName])}
                            />
                            {item[valueName]}
                        </Label>
                    ))}
                </TagFilterWrapper>
            );
    }
}

const TagFilterWrapper = styled.div<{ showMore?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 500px;
    max-height: ${({ showMore }) => (showMore ? "400px" : "32px")};
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
`;
const Label = styled.label<{ checked: boolean }>`
    input {
        display: none;
    }
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    height: 32px;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    ${({ theme }) => css`
        font-size: ${theme.fontSizes.xs}px;
        background-color: ${theme.colors.gray50};
        border: 1px solid ${theme.colors.gray200};
        color: ${theme.colors.gray600};
    `}
    ${({ checked, theme }) => {
        return (
            checked &&
            css`
                border: 1px solid ${theme.colors.blue500};
                background-color: ${theme.colors.white};
                color: ${theme.colors.blue500};
            `
        );
    }}
`;

interface TagFilterProps {
    type: "radio" | "checkbox";
    items: any[];
    keyName: string;
    valueName: string;
    onChange: ((v: number[]) => void) | ((v: number) => void) | ((v: string[]) => void) | ((v: string) => void);
    // onChangeHandler: A | B;
    showMore?: boolean;
    reset?: boolean;
    defaultValue?: boolean; // 처음 모두 선택된 상태 or 모두 선택 안된상태를 위한 플래그
}

export default TagFilter;
