import styled from "styled-components";
import TextInput from "@/components/atoms/input/TextInput"
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";

export default function BaseDataContainer() {
    const contentData = [
        { label: "제목*", type: "series_name", placeholder: "제목을 입력해주세요." },
        { label: "저자*", type: "author_name", placeholder: "저자명을 입력해주세요." },
        { label: "펴낸곳", type: "publisher_name", placeholder: "출판사를 입력해주세요." },
        { label: "ISBN", type: "isbn", placeholder: "ISBN을 입력해주세요. (ex. 123-45-6789-012-3)" },
        { label: "발행일", type: "published_at", placeholder: "발행일을 입력해주세요. (ex. 20220101)" },
    ]

    return (
        <StyledContainer>
            <FieldWrapper>기본 정보 입력</FieldWrapper>
            <ContentWrapper>
                {contentData.map(c => (
                    <RowWrapper 
                        key={c.type}
                        label={c.label}
                        type={c.type}
                        placeholder={c.placeholder}
                    />
                ))}
            </ContentWrapper>
        </StyledContainer>
    )
}

function RowWrapper(props: RowWrapperProps) {
    const { bookInfo, outFileType } = useAppSelector((state: RootState) => state.ebookMaker);
    const dispatch = useAppDispatch();

    const onlyConvert2Txt = outFileType.length === 1 && outFileType[0] === "txt";

    const inputOnChange = (e: any) => {
        let value = e.target.value;
        if (props.type === "isbn") {
            value = value.replace(/[^0-9-]/g, "").substr(0, 17);
        } else if (props.type === "published_at") {
            value = value.replace(/[^0-9]/g, "");
            value = value.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, "$1-$2-$3");
        }

        dispatch({ type: "ebookMaker/setBookInfo", payload: {
            type: props.type,
            value
        }});
        if ((props.label.includes("*")) && e.target.value === "") {
            dispatch({ type: "ebookMaker/setBookInfo", payload: {
                type: `${props.type}_error`,
                value: `${props.type === "series_name" ? "제목" : "저자명"}을 입력해주세요`
            }});
        } else {
            dispatch({ type: "ebookMaker/setBookInfo", payload: {
                type: `${props.type}_error`,
                value: ""
            }});
        }
    }

    const inputStyle = {
        width: "400px",
        height: "32px",
        padding: "5px 8px",
        fontSize: "14px",
        fontWeight: "400"
    }

    return (
        <StyledWrapper>
            <LabelWrapper>{props.label}</LabelWrapper>
            <ValidateWrapper dataError={bookInfo[`${props.type}_error`]}>
                <TextInput
                    type={onlyConvert2Txt ? "readOnly" : "main"}
                    value={bookInfo[props.type]}
                    onChangeHandler={inputOnChange}
                    placeholder={props.placeholder}
                    customStyle={inputStyle}
                />
            </ValidateWrapper>
        </StyledWrapper>
    )
}

const StyledContainer = styled.div`
    display: flex;
    height: 300px;
`

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    width : 270px;
    padding: 24px;
    margin-right: 23px;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`

const ContentWrapper = styled.div`
    width: 900px;
    padding-left: 24px;
`

const StyledWrapper = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
    input::placeholder {
        color: ${({ theme }) => theme.colors.gray500};
        font-size: 13px;
    }
`

const LabelWrapper = styled.div`
    display: flex;
    width: 64px;
    height: 100%;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
`

interface RowWrapperProps {
    label: string;
    type: string;
    placeholder: string;
    [key: string]: string;
}