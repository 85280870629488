import styled from "styled-components";
import Text from "@/components/atoms/text";
import Button from "@/components/atoms/button";
import { useNavigate } from "react-router-dom";

export default function AfterFind({ findId }: { findId: string }) {
    const navigate = useNavigate();

    return (
        <AfterFindWrap>
            <Text text="회원님의 아이디(이메일)는" customStyle={{ fontSize: "16px", fontWeight: "400", lineHeight: "28px" }} />
            <FindIdWrap>
                <Text text={findId} customStyle={{ fontSize: "16px", fontWeight: "700", lineHeight: "28px" }} />
                <Text text="입니다." customStyle={{ fontSize: "16px", fontWeight: "400", lineHeight: "28px" }} />
            </FindIdWrap>
            <Button
                type="main"
                label="로그인"
                customStyle={{ width: "400px",
                                height: "60px",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={() => navigate("/signin")}
            />
            <Button
                type="main-outline"
                label="비밀번호 찾기"
                customStyle={{ width: "400px",
                                height: "60px",
                                margin: "24px 0 0 0",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={() => navigate("/findpw")}
            />
        </AfterFindWrap>
    );
}

const AfterFindWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FindIdWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
`