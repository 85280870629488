import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api";
import { StatementState, Author, SpecificationType } from "./types";

const initialState: StatementState = {
    specificationType: SpecificationType.Monthly,
    yearmon: "",
    publisherInfo: {
        publisher_addr: "",
        publisher_ceo_name: "",
        publisher_email: "",
        publisher_name: "",
        publisher_pic_path: "",
        publisher_reg: "",
        publisher_tel: "",
        publisher_url: "",
    },
    platformList: [],
    monthList: [],
    allCheck: true,
    authorList: [],
    currAuthor: {
        author_names: "",
        user_id: 0,
        user_name: "",
        checked: false
    },
    salesList: [],
    mgList: [],
    etcList: [],
    totalData: {
        etcSubTotal: 0,
        salesSubTotal: 0,
        taxSubTotal: 0,
        total: 0,
    },
    taxList: [],
    briefs: [],
    stampFiles: [],
    stampSize: {
        width: 150,
        height: 150
    },
    stampPosition: {
        x: 0,
        y: 0
    },
    mgPayment: {
        co_ratio: 0,
        contract_amount: 0,
        date: "",
        mg_ratio: 0,
        out_date: "",
        payment_amount: 0,
        platform_name: "",
        remain_amount: 0,
        series_name: "",
    },
}

////////////////////////////
/* 정산서 발급 페이지 */
////////////////////////////
export const getPublisherInfo = createAsyncThunk("pubStatement/getPublisherInfo", async (data, { getState, rejectWithValue }) => {
    try {
        return (await api.post(`/api/settlement/royalty-statement/publisherInfo/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 출판사가 정산 데이터를 입력한 월들만 가져오기
export const getIssueStMonth = createAsyncThunk("pubStatement/getIssueStMonth", async (data, { getState, rejectWithValue }) => {
    try {
        return (await api.post(`/api/settlement/royalty-statement/issue/settlement/month/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 월정산 명세서 발급대상 조회
export const getIssueStAuthor = createAsyncThunk("pubStatement/getIssueStAuthor", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        return (await api.post(`/api/settlement/royalty-statement/issue/settlement/author/select`, {
            month: pubStatement.yearmon,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 선택한 작가의 정산 데이터 조회
export const getIssueStData = createAsyncThunk("pubStatement/getIssueStData", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        return (await api.post(`/api/settlement/royalty-statement/issue/settlement/data/select`, {
            month: pubStatement.yearmon,
            author: pubStatement.currAuthor.user_id,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

// 월정산 명세서 발급
export const issueMonthlyStatement = createAsyncThunk("pubStatement/issueMonthlyStatement", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        const { yearmon, authorList, stampFiles, stampPosition, stampSize, briefs } = pubStatement;
        const selectedAuthors = authorList.filter((a: Author) => a.checked).map((a: Author) => a.user_id);

        return (await api.post(`/api/settlement/royalty-statement/issue/monthly`, {
            month: yearmon,
            authors: selectedAuthors,
            stamp: stampFiles.length ? stampFiles[0].file : null,
            stampPos: {
                x: stampPosition.x * 100 / 115, // pdfViewer scales 확대한만큼 다시 축소
                y: stampPosition.y * 100 / 115,
                w: stampSize.width * 100 / 115,
                h: stampSize.height * 100 / 115
            },
            briefs: briefs,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

// 선인세 지급 월 조회
export const getIssueMgMonth = createAsyncThunk("pubStatement/getIssueMgMonth", async (data, { getState, rejectWithValue }) => {
    try {
        return (await api.post(`/api/settlement/royalty-statement/issue/mg/month/select`)).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 선인세 지급 대상 조회
export const getIssueMgAuthor = createAsyncThunk("pubStatement/getIssueMgAuthor", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        return (await api.post(`/api/settlement/royalty-statement/issue/mg/author/select`, {
            month: pubStatement.yearmon,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

// 선택한 작가의 선인세 지급 데이터 조회
export const getIssueMgData = createAsyncThunk("pubStatement/getIssueMgData", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        return (await api.post(`/api/settlement/royalty-statement/issue/mg/data/select`, {
            mg_id: pubStatement.currAuthor.mg_id,
            out_date: pubStatement.currAuthor.out_date,
            user_id: pubStatement.currAuthor.user_id,
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

// 선인세 지급 명세서 발급
export const issueMgStatement = createAsyncThunk("pubStatement/issuMgStatement", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        const { authorList, yearmon, stampFiles, stampPosition, stampSize, briefs } = pubStatement;
        const selectedAuthors = authorList.filter((a: Author) => a.checked);

        return (await api.post(`/api/settlement/royalty-statement/issue/mg`, {
            month: yearmon,
            authors: selectedAuthors,
            stamp: stampFiles.length ? stampFiles[0].file : null,
            stampPos: {
                x: stampPosition.x * 100 / 115, // pdfViewer scales 확대한만큼 다시 축소
                y: stampPosition.y * 100 / 115,
                w: stampSize.width * 100 / 115,
                h: stampSize.height * 100 / 115
            },
            briefs
        }))
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

////////////////////////////
/* 정산서 조회 페이지 */
////////////////////////////
export const getInquiryMonth = createAsyncThunk("pubStatement/getInquiryMonth", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        return (await api.post(`/api/settlement/royalty-statement/inquiry/month/select`, {
            type: pubStatement.specificationType === SpecificationType.Monthly ? "MRS" : "MGS"
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const getInquiryAuthor = createAsyncThunk("pubStatement/getInquiryAuthor", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        return (await api.post(`/api/settlement/royalty-statement/statementAuthor/select`, {
            type: pubStatement.specificationType === SpecificationType.Monthly ? "MRS" : "MGS",
            month: pubStatement.yearmon
        })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const deleteStatement = createAsyncThunk("pubStatement/deleteStatement", async (data, { getState, rejectWithValue }) => {
    try {
        const { pubStatement }: any = getState();
        let authors = [];
        if (pubStatement.specificationType === SpecificationType.Monthly) {
            authors = pubStatement.authorList.filter((a: Author) => a.checked).map((a: Author) => a.user_id);
        } else {
            authors = pubStatement.authorList.filter((a: Author) => a.checked);
        }
        return (await api.post(`/api/settlement/royalty-statement/cancel/${pubStatement.specificationType}`, {
            month: pubStatement.yearmon,
            authors
        }));
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
})

export const statementSlice = createSlice({
    name: "pubStatement",
    initialState,
    reducers: {
        initState: (state) => {
            Object.assign(state, initialState);
        },
        setSpecificationType: (state, { payload }) => {
            state.specificationType = payload;
        },
        setYearmon: (state, { payload }) => {
            state.yearmon = payload;
        },
        checkAllAuthorList: (state, { payload }) => {
            state.allCheck = payload;
            for (let a of state.authorList) {
                a.checked = payload;
            }
        },
        checkAuthor: (state, { payload }) => {
            state.authorList[payload.idx].checked = payload.checked;
            if (payload.checked) {
                if (state.authorList.every(a => a.checked)) {
                    state.allCheck = true;
                }
            } else {
                state.allCheck = payload.checked;
            }
        },
        selectAuthor: (state, { payload }) => {
            state.currAuthor = payload;
        },
        setEmptyData: (state) => {
            state.allCheck = true;
            state.authorList = [];
            state.currAuthor = initialState.currAuthor;
            state.salesList = [];
            state.mgList = [];
            state.etcList = [];
            state.totalData = initialState.totalData;
            state.taxList = [];
            state.briefs = [];
            state.stampFiles = [];
            state.stampSize = initialState.stampSize;
            state.stampPosition = initialState.stampPosition;
        },
        setBriefs: (state, { payload }) => {
            state.briefs = payload;
        },
        setStampFiles: (state, { payload }) => {
            state.stampFiles = payload;
            if (!payload.length) {
                state.stampSize = initialState.stampSize;
                state.stampPosition = initialState.stampPosition;
            }
        },
        setStampSize: (state, { payload }) => {
            state.stampSize = payload;
        },
        setStampPosition: (state, { payload }) => {
            state.stampPosition = payload;
        }
    },
    extraReducers: {
        [getPublisherInfo.fulfilled.type]: (state, { payload }) => {
            state.publisherInfo = payload.data;
        },

        [getIssueStMonth.fulfilled.type]: (state, { payload }) => {
            state.monthList = payload.data.map((d: { yearmon_st: string }) => d.yearmon_st);
            if (payload.data.length) {
                state.yearmon = payload.data[0].yearmon_st;
            }
        },

        [getIssueStAuthor.fulfilled.type]: (state, { payload }) => {
            state.authorList = payload.data.map((d: any) => {
                return {
                  ...d,
                  checked: true  
                }
            });
            state.currAuthor = state.authorList.length ? state.authorList[0] : initialState.currAuthor;
        },

        [getIssueStData.fulfilled.type]: (state, { payload }) => {
            const { sales, mg, etc, totalData, taxList } = payload.data;
            state.salesList = sales;
            state.mgList = mg;
            state.etcList = etc;
            state.totalData = totalData;
            state.taxList = taxList;
        },

        [getIssueMgMonth.fulfilled.type]: (state, { payload }) => {
            state.monthList = payload.data.map((d: { yearmon: string }) => d.yearmon);
            if (payload.data.length) {
                state.yearmon = payload.data[0].yearmon;
            }
        },

        [getIssueMgAuthor.fulfilled.type]: (state, { payload }) => {
            state.authorList = payload.data.map((d: any) => {
                return {
                  ...d,
                  checked: true  
                }
            });
            state.currAuthor = state.authorList.length ? state.authorList[0] : initialState.currAuthor;
        },

        [getIssueMgData.fulfilled.type]: (state, { payload }) => {
            state.mgPayment = payload.data ? payload.data : initialState.mgPayment;
        },

        [getInquiryMonth.fulfilled.type]: (state, { payload }) => {
            if (payload.data.length) {
                state.monthList = payload.data.map((d: { yearmon: string }) => d.yearmon);
                state.yearmon = payload.data[0].yearmon;
            } else {
                state.monthList = [];
            }
        },

        [getInquiryAuthor.fulfilled.type]: (state, { payload }) => {
            state.authorList = payload.data.map((d: any) => {
                return {
                  ...d,
                  checked: true  
                }
            });
            state.currAuthor = state.authorList.length ? state.authorList[0] : initialState.currAuthor;
        },
    }
});

export default statementSlice.reducer;
