import { createSlice } from "@reduxjs/toolkit";

const initialState: InitialState = {
    data: {},
};

const paginationSlice = createSlice({
    name: "pagination",
    initialState,
    reducers: {
        initialize: (state, { payload }) => {
            const { initialValue, from } = payload;
            if (!state.data[from]) state.data[from] = initialValue;
        },
        setPage: (state, { payload }) => {
            const { page, from } = payload;
            if (!state.data[from]) return;
            state.data[from].page = page;
        },
        setItemsPerPage: (state, { payload }) => {
            const { itemPerPage, from } = payload;
            if (!state.data[from]) return;

            state.data[from].itemsPerPage = itemPerPage;
            state.data[from].pageCount = Math.ceil(state.data[from].dataCount / itemPerPage);

            if (state.data[from].pageCount < state.data[from].page) {
                state.data[from].page = state.data[from].pageCount || 1;
            }
        },
        setTotalVisible(state, { payload }) {
            const { totalVisible, from } = payload;
            if (!state.data[from]) return;

            state.data[from].totalVisible = totalVisible;
        },
        setDataCount(state, { payload }) {
            const { dataCount, from } = payload;
            if (!state.data[from]) return;
            state.data[from].dataCount = dataCount;
            state.data[from].pageCount = Math.ceil(dataCount / state.data[from].itemsPerPage);
            
            if (state.data[from].pageCount < state.data[from].page) {
                state.data[from].page = 1;
            }
        },
        reset(state, action) {
            const { from } = action.payload;
            delete state.data[from];
        },
    },
});

interface InitialState {
    data: { 
        [key:string|number] : {
            itemsPerPage: number; // 한 페이지 내 아이템 수
            pageCount: number; // 총 페이지 수
            totalVisible: number; // 페이지네이션으로 보여질 페이지 개수
            page: number; // 현재 페이지
            dataCount: number; //전체 데이터 개수
        }
    }
}

export const paginationActions = paginationSlice.actions;
export default paginationSlice.reducer;