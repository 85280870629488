import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addComma } from "@/modules/utils/filter";
import { calcPeriod } from "@/modules/utils/date";
import type { EtcState, UpdateApiParams, DeleteApiParams } from "./types";
import type { AtcmpParams } from "@/types/autoComplete";

const name = "inquiry/publisher/etc";
const initialState: EtcState = {
    items: [],
    total_amt: 0,
    total_cnt: 0,

    initialDataLoaded: false,
    fetchLoading: false,

    changedParamsId: 0, // 데이터 조회 params 변경시 업데이트
    startDate: calcPeriod("thisMonth").startDate || "",
    endDate: calcPeriod("thisMonth").endDate || "",
    selectedPlatform: [],
    author_name: "",
    startAmount: 0,
    endAmount: 0,
    reason: "",

    sliderMinValue: 0,
    sliderMaxValue: 0,

    // 자동완성
    atcmpList: [],

    // 기타
    startDateError: "",
    endDateError: "",
    reset: false,
};

// 데이터 조회
export const getEtcData = createAsyncThunk(`${name}/getEtcData`, async (_, { rejectWithValue, getState, signal  }) => {
    try {
        const { inquiryEtc }: any = getState();
        const apiParams = {
            startDate: inquiryEtc.startDate,
            endDate: inquiryEtc.endDate,
            selectedPlatform: inquiryEtc.selectedPlatform,
            author_name: inquiryEtc.author_name,
            startAmount: inquiryEtc.startAmount,
            endAmount: inquiryEtc.endAmount,
            reason: inquiryEtc.reason,
        }
        return (await api.post(`/api/settlement/newSalesInquiry/etc/publisher/select`, apiParams, { signal })).data;
    } catch (err: any) {
        throw rejectWithValue(err.response.data);
    }
});

// 데이터 저장
export const updateEtcData = createAsyncThunk(
    `${name}/updateEtcData`,
    async (apiParams: UpdateApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/salesInquiry/etc/update", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 데이터 삭제
export const deleteEtcData = createAsyncThunk(
    `${name}/deleteEtcData`,
    async (apiParams: DeleteApiParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/salesInquiry/etc/delete", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 전체 자동완성 조회 (저자명 + 지급사유)
export const getAtcmpByAll = createAsyncThunk(
    `${name}/getAtcmpByAll`,
    async (apiParams: AtcmpParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSearchKeyword/getAtcmp/etc/all/select", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 자동완성 조회 - 지급 사유
export const getAtcmpByReason = createAsyncThunk(
    `${name}/getAtcmpByReason`,
    async (apiParams: AtcmpParams, { rejectWithValue }) => {
        try {
            return (await api.post("/api/settlement/newSearchKeyword/getAtcmp/reason/select", apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

// 자동완성 조회 - 저자명
export const getAtcmpByAuthor = createAsyncThunk(`${name}/getAtcmpByAuthor`,
    async (apiParams: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/authorName/select`, apiParams)).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

const etcSlice = createSlice({
    name,
    initialState,
    reducers: {
        setFilterOption(state, action: PayloadAction<Partial<EtcState>>) {
            const { author_name, reason, startDate, endDate, selectedPlatform, startAmount, endAmount } = action.payload;

            state.author_name = author_name ?? state.author_name;
            state.reason = reason ?? state.reason;
            state.startDate = startDate ?? state.startDate;
            state.endDate = endDate ?? state.endDate;
            state.selectedPlatform = selectedPlatform ?? state.selectedPlatform;
            state.startAmount = startAmount ?? state.startAmount;
            state.endAmount = endAmount ?? state.endAmount;
        },
        setError(state, { payload }) {
            const { startDateError, endDateError } = payload;
            
            state.startDateError = startDateError ?? state.startDateError;
            state.endDateError = endDateError ?? state.endDateError;
        },
        setItem: (state, action: PayloadAction<{ index:number; item:any }>) => {
            const { index, item } = action.payload;
            state.items[index] = item;
        },
        setItems: (state, { payload }) => {
            state.items = payload;
        },
        setParamsId(state){
            state.changedParamsId += 1;
        },
        setNoData(state) {
            state.items = [];
            state.total_cnt = 0;
            state.total_amt = 0;
        },
        setReset(state, { payload }) {
            state.reset = payload;
        },
        resetStore: () => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        // 데이터 조회
        builder
            .addCase(getEtcData.pending, (state) => {
                state.fetchLoading = true;
            })
            .addCase(getEtcData.fulfilled, (state, { payload }) => {
                state.fetchLoading = false;
                state.initialDataLoaded = true;

                const { result, total_cnt, total_amt, sliderMinValue, sliderMaxValue } = payload.data;

                for (let item of result) {
                    item["amount"] = addComma(item["amount"]);
                    item["pre_amount"] = addComma(item["pre_amount"]);
                    item["tax_amount"] = addComma(item["tax_amount"]);
                }

                state.items = result;
                state.total_cnt = total_cnt;
                state.total_amt = Number(total_amt.toFixed(2));

                // 금액 필터 값 있을 때 기존 min, max값 유지
                if (state.startAmount || state.endAmount) return;

                state.sliderMinValue = sliderMinValue;
                state.sliderMaxValue = sliderMaxValue;
            });

        // 자동완성 - 전체
        builder.addCase(getAtcmpByAll.fulfilled, (state, { payload }) => {
            state.atcmpList = payload.data;
        });
        // 자동완성 - 지급사유
        builder.addCase(getAtcmpByReason.fulfilled, (state, { payload }) => {
            state.atcmpList = payload.data;
        });
        // 자동완성 - 저자명
        builder.addCase(getAtcmpByAuthor.fulfilled, (state, { payload }) => {
            state.atcmpList = payload.data;
        });
    },
});

export const { setFilterOption, setError, setItem, setItems, setParamsId, setNoData, setReset, resetStore } = etcSlice.actions;
export default etcSlice.reducer;
