import { useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import {
    setDate,
    setPlatformId,
    getPaymonth,
    setReset,
    resetStore,
} from "@/pages/settlement/sales-entry/monthly/monthlySlice";
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";
import Text from "@/components/atoms/text";
import SelectBox from "@/components/atoms/selectBox/index";
import ResetFilter from "@/components/molecules/sales-inquiry/common/ResetFilter";
import DateSelector from "@/components/molecules/common/form/DateSelector";
import usePlatformSetting from "@/components/molecules/sales-entry/common/hooks/usePlatformSetting";

export default function MontlyTableFilter() {
    const { platformId, yearmon, yearmon_st, reset, afterExcelUpload } = useSelectorTyped((state: RootState) => state.entryMonthly);
    const { isAuto, salesYearmon } = useSelectorTyped((state: RootState) => state.uploader);
    const dispatch = useAppDispatch();
    let paymonth = useRef(0); // 서버에서 받아온 플랫폼 별 paymonth 설정
    let originPayMonth = useRef(""); // paymonth 적용된 정산월
    const { initialPlatformId, platformList, entrySalesPlatformList } = usePlatformSetting({ platformId, date: yearmon });
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();

    useEffect(() => {
        if (isAuto) return; //자동 업로더인경우

        dispatch(setPlatformId(initialPlatformId));
    }, [initialPlatformId]);

    useEffect(() => {
        if (platformId) paymonthLoad();
        if (reset) dispatch(setReset(false));
    }, [platformId, reset]);

    const changePlatform = (data: any) => dispatch(setPlatformId(data.platform_id));

    const clickResetButton = async () => {
        const answer = await alert("confirm", "초기화 확인", "초기화 하시겠습니까?");
        if (answer === "Y") {
            dispatch(setDate({ yearmon: "", yearmon_st: "" }));
            dispatch(resetStore());
            dispatch(setPlatformId(initialPlatformId));
            paymonthLoad();
        }
    };

    // 정산월 초기화
    const paymonthLoad = async () => {
        try {
            // 플랫폼에 따라서 현재 월에 따른 정산 월 및 판매월 설정
            // 정산월 = 현재월 + 1
            // 판매월 = 정산월 - paymonth
            loadingOn();
            let stMonth = salesYearmon
                ? moment(salesYearmon).format("YYYYMM")
                : moment().add(1, "months").format("YYYYMM");

            let apiParams = {
                platformId,
                stMonth,
            };

            const { data } = await dispatch(getPaymonth(apiParams)).unwrap();
            paymonth.current = Number(data[0].paymonth);

            // 판매월 설정
            const yearmon = data[0].totalDate.slice(0, 4) + data[0].totalDate.slice(4, 6);
            changeDate(yearmon);

            // 정산월 설정
            const yearmon_st = stMonth.slice(0, 4) + stMonth.slice(4, 6);
            changePayDate(yearmon_st);
            dispatch(setDate({ yearmon, yearmon_st }));
        } catch (err: any) {
            return await alert("error", "오류 발생", "플랫폼을 불러오는 중 오류가 발생하였습니다.");
        } finally {
            loadingOff();
        }
    };

    const changeDate = (date: string) => {
        const year = Number(date.slice(0, 4));
        const month = Number(date.slice(4, 6));
        let payMonth: number | string = month + paymonth.current;
        let payYear = payMonth > 12 ? String(year + 1) : String(year);
        payMonth = payMonth > 12 ? payMonth - 12 : payMonth;
        payMonth = `0${payMonth}`.slice(-2);
        const originDate = `${payYear}${payMonth}`;

        originPayMonth.current = originDate;
        dispatch(setDate({ yearmon: date, yearmon_st: originDate }));
    };

    const changePayDate = (date: string) => {
        dispatch(setDate({ yearmon_st: date }));
    };

    return (
        <TableFilterWrapper>
            <LeftFilter>
                <Content>
                    <Text text="판매 플랫폼" customStyle={textStyle} />
                    <SelectBox
                        labelKey="platform_name"
                        valueKey="platform_id"
                        options={entrySalesPlatformList}
                        selected={platformId || initialPlatformId}
                        disabled={afterExcelUpload}
                        onClickValueFunc={changePlatform}
                    />
                </Content>
                <Content>
                    <Text text="판매 월" customStyle={textStyle} />
                    <DateSelector
                        onChangeHandler={changeDate}
                        initialDate={yearmon}
                        yearCustom={{ width: "80px" }}
                        monCustom={{ width: "60px" }}
                        disabled={afterExcelUpload}
                    />
                </Content>
                <Content>
                    <Text text="정산 월" customStyle={textStyle} />
                    <DateErrorWrapper showError={originPayMonth.current !== yearmon_st}>
                        <DateSelector
                            onChangeHandler={changePayDate}
                            initialDate={yearmon_st}
                            yearCustom={{ width: "80px" }}
                            monCustom={{ width: "60px" }}
                            disabled={afterExcelUpload}
                        />
                    </DateErrorWrapper>
                </Content>
            </LeftFilter>
            <ResetFilter text="초기화" onClick={clickResetButton} />
        </TableFilterWrapper>
    );
}

const textStyle = {
    fontSize: "14px",
    fontWeight: "500",
    margin: "0 8px 0 0",
};

const TableFilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin: 24px 0;

    .init-filter {
        justify-content: space-around;
    }
`;
const LeftFilter = styled.div`
    display: flex;
`;
const Content = styled.div`
    display: flex;
    align-items: center;
    & + & {
        margin-left: 16px;
    }
`;
const DateErrorWrapper = styled.div<{ showError: boolean }>`
    :after {
        font-size: 10px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.blue500};
        padding-left: 5px;
        position: absolute;
        bottom: -16px;
        white-space: nowrap;
        content: ${({ showError }) => (showError ? `"설정된 인세지급월과 다릅니다."` : "")};
    }
`;
