import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import useCheckToken from "@/modules/hooks/useCheckToken";
import SearchBar from "@/components/molecules/portfolio/publisher/list/SearchBar";
import FilterContainer from "@/components/molecules/portfolio/publisher/list/FilterConatiner";
import SeriesListContainer from "@/components/molecules/portfolio/publisher/list/SeriesListContainer";
import { useEffect } from "react";
import { getPortfolioList } from "../slice";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import styled from "styled-components";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import PortfolioSkeleton from "@/components/molecules/common/loading/PortfolioSkeleton";
import useLoading from "@/modules/hooks/useLoading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchOptions } from "../types";
import { searchPortfolioList } from "@/pages/portfolio/publisher/slice";

export default function PortfolioList() {
    const { portfolioList, loading } = useAppSelector((state: RootState) => state.pubPortfolio);
    const [searchParams] = useSearchParams();
    const queryPage = searchParams.get("page") || 1;
    const queryOption = searchParams.get("option") || SearchOptions.ALL;
    const queryKeyword = searchParams.get("keyword") || "";
    const { setPage, setDataCount, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "portfolio", initialValue: {
        itemsPerPage: 12,
        totalVisible: 10,
        page: Number(queryPage) ?? 1,
    }});
    const tokenStatus = useCheckToken();
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const navigate = useNavigate();

    const getInitData = async () => {
        try {
            loadingOn();
            if (queryKeyword) {
                setPage(1);
                await dispatch(searchPortfolioList({
                    keyword: queryKeyword,
                    searchOption: queryOption
                })).unwrap();
            } else {
                await dispatch(getPortfolioList()).unwrap();
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const initPage = async () => {
        navigate(`/portfolio/list?page=1&option=${SearchOptions.ALL}&keyword=`);
        navigate(0);
    }

    useEffect(() => {
        getInitData();
        return () => {
            dispatch({ type: "pubPortfolio/initState" });
        }
    }, [])

    useEffect(() => {
        setDataCount(portfolioList.length);
    }, [portfolioList])

    useEffect(() => {
        navigate(`/portfolio/list?page=${page}&option=${queryOption}&keyword=${queryKeyword}`);
    }, [page])

    if (!tokenStatus) {
        return <></>
    };

    return (
        <SectionLayout>
            <TitleWrapper onClick={initPage}>포트폴리오</TitleWrapper>
            <SearchBar initPage={() => setPage(1)} />
            <FilterContainer />
            {loading 
                ? <PortfolioSkeleton /> 
                : <SeriesListContainer 
                    page={page}
                    itemPerPage={itemsPerPage}
                  />
            }
            <PaginationWrapper>
                <Pagination 
                    pageCount={pageCount} 
                    totalVisible={totalVisible} 
                    from="portfolio" 
                    customStyle={{ margin: "16px 0 0 0" }}
                />
            </PaginationWrapper>
        </SectionLayout>
    )
}

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
`

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
`



