import styled from "styled-components";
import Radio from "@/components/atoms/input/Radio";
import DateSelector from "./DateSelector";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

export default function OptionContainer({ dateChangeHandler } : { dateChangeHandler: () => void;}) {
    const { specificationType } = useAppSelector((state: RootState) => state.authorStatement);
    const dispatch = useAppDispatch();

    const changeType = (e: any) => {
        dispatch({ type: "authorStatement/setSpecificationType", payload: e.target.value });
    }

    return (
        <StyledContainer>
            <RowWrapper>
                <FieldWrapper>명세서 구분</FieldWrapper>
                <ValueWrapper>
                    <Radio
                        name="dataType"
                        checked={specificationType === SpecificationType.Monthly}
                        value={SpecificationType.Monthly}
                        onChange={(e) => changeType(e)}
                        type="label"
                        label="월 정산 명세서"
                        customStyle={{ fontSize:"14px", margin: "0 24px 0 0"}}
                    />
                    <Radio
                        name="dataType"
                        checked={specificationType === SpecificationType.Mg}
                        value={SpecificationType.Mg}
                        onChange={(e) => changeType(e)}
                        type="label"
                        label="선인세 지급 명세서"
                        customStyle={{ fontSize:"14px", margin: "0 24px 0 0"}}
                    />
                </ValueWrapper>
            </RowWrapper>
            <RowWrapper>
                <FieldWrapper>{specificationType === SpecificationType.Monthly ? "정산월" : "지급(예정)월"}</FieldWrapper>
                <ValueWrapper>
                    <DateSelector dateChangeHandler={dateChangeHandler} />
                </ValueWrapper>
            </RowWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    dispaly: flex;
    width: 100%;
    height: 120px;
    margin-top: 24px;
`

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 60px;
`

const FieldWrapper = styled.div`
    display: flex;
    width: 200px;
    height: 60px;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray50};
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
`  

const ValueWrapper = styled.div`
    display: flex;
    width: 1000px;
    height: 60px;
    align-items: center;
    padding: 8px 16px;
    label {
        cursor: pointer;
    }
`

// 명세서 구분 (월정산 명세서 / 선인세 지급 명세서)
enum SpecificationType {
    Monthly = "monthly",
    Mg = "mg"
}