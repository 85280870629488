import { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { colors } from "@/assets/styles/theme";

// components
import Text from "@/components/atoms/text";
import TextInput from "@/components/atoms/input/TextInput";
import Radio from "@/components/atoms/input/Radio";
import Checkbox from "@/components/atoms/input/Checkbox";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import Tooltip from "@/components/molecules/common/tooltip/Tooltip";
import DateSelector from "@/components/molecules/common/form/DateSelector";

// reducer
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";
import { basePlatformActions, getPlatformData } from "@/pages/settlement/basedata-manage/platform/platformSlice";

// hooks
import useLoading from "@/modules/hooks/useLoading";
import useAlert from "@/modules/hooks/useAlert";

// utils
import { integer } from "@/modules/utils/validate";


function PlatformTable() {
    const { loadingOn, loadingOff } = useLoading();
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { data } = useAppSelector((state:RootState) => state.basePlatformdata);

    useEffect(() => {
        if (!data.length) getData();
    }, [data.length]);

    async function getData() {
        try {
            loadingOn();
            await dispatch(getPlatformData()).unwrap();
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }

    const changeUsable = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const check = data[idx].usable === 1 ? 0 : 1;
        dispatch({type: "platform/setUsable", payload: {
            idx,
            value: check
        }})
    }

    const changePaymonth = (idx: number, e?: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.target.value !== "99") {
            dispatch(basePlatformActions.setPlatformDatas({
                key: "data",
                index: idx,
                value: {...data[idx], "paymonth": e.target.value, "valueError": ""}
            }))
        } else {
            if (data[idx].paymonth !== "99") {
                dispatch(basePlatformActions.setPlatformDatas({
                    key: "data",
                    index: idx,
                    value: {...data[idx], "p_input": "3", "paymonth": "99"}
                }))
            }
        }
    }

    function changePInput(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        let item = e.target.value;
        let result = integer({ field: "인세지급월", item, error: "valueError", min: 3, max: 12, mandatory: true });

        dispatch(basePlatformActions.setPlatformDatas({
            key: "data",
            index: idx,
            value: {...data[idx], "p_input": result.value, "valueError": result.valueError}
        }))
    }
    
    const clickPaymonth = (idx: number, e?: React.MouseEvent<HTMLInputElement>) => {
        const target = e && e.target as HTMLTextAreaElement;
        if (target) {
            dispatch(basePlatformActions.setPlatformDatas({
                key: "data",
                index: idx,
                value: {...data[idx], "paymonth": target.value, "valueError": ""}
            }))
        }
    }

    const setCirculationDate = (idx: number) => {
        let value = data[idx].circulation_stop_date ? null : moment(new Date()).format("YYYY-MM-DD");
        dispatch(basePlatformActions.setPlatformDatas({
            key: "data",
            index: idx,
            value: {...data[idx], "circulation_stop_date": value}
        }))
    }

    function changeEndCirculationDate(date:string, index:number) {
        dispatch(basePlatformActions.setPlatformDatas({
            key: "data",
            index: index,
            value: {...data[index], "circulation_stop_date": moment(date).format("YYYY-MM-DD")}
        }))
    }

    return (
        <TableContainer>
            <StyledTheader>
                <StyledTr>
                    {headers.map((h, i) => (
                        <StyledTh
                            key={i}
                            width={h.customStyle?.width}
                        >
                            {h?.tooltip ? 
                            <TooltipWrap>
                                <div style={{ padding: "5px"}}>{h.text}</div>
                                <Tooltip
                                    iconWidth={20}
                                    iconHeight={20}
                                    textBoxWidth={240}
                                    fill={colors.gray600}
                                    text={h.tooltip}
                                />
                            </TooltipWrap>
                            :
                            h.text
                            }
                        </StyledTh>
                    ))}
                </StyledTr>
            </StyledTheader>
            <TBody>
                {data &&
                data.map((d, i) => (
                    <Tr key={i}>
                        <Td width={"80px"} padding="18px" justifyContent={"center"}>
                            <Checkbox type="boolean" active={Boolean(d.usable)} onChangeHandler={(e) => changeUsable(e, data.indexOf(d))} />
                        </Td>
                        <Td>
                            <Text text={d.platform_name} customStyle={{ fontSize: "12px", lineHeight: "21px" }}/>
                        </Td>
                        <Td width="400px">
                            <Radio
                                type={d.usable ? "label" : "label-disabled"}
                                id={d.platform_id+"0"}
                                label="당월"
                                name={d.platform_name}
                                checked={d.paymonth === "0"}
                                value="0"
                                onChange={(e) => changePaymonth(data.indexOf(d), e)}
                                onClick={(e) => clickPaymonth(data.indexOf(d), e)}
                                customStyle={{ margin: "0 15px 0 0", fontSize: "14px" }}
                            />
                            <Radio
                                type={d.usable ? "label" : "label-disabled"}
                                id={d.platform_id+"1"}
                                name={d.platform_name}
                                label="익월"
                                checked={d.paymonth === "1"}
                                value="1"
                                onChange={(e) => changePaymonth(data.indexOf(d), e)}
                                onClick={(e) => clickPaymonth(data.indexOf(d), e)}
                                customStyle={{ margin: "0 15px 0 0", fontSize: "14px" }}
                            />
                            <Radio
                                type={d.usable ? "label" : "label-disabled"}
                                id={d.platform_id+"2"}
                                name={d.platform_name}
                                label="익익월"
                                checked={d.paymonth === "2"}
                                value="2"
                                onChange={(e) => changePaymonth(data.indexOf(d), e)}
                                onClick={(e) => clickPaymonth(data.indexOf(d), e)}
                                customStyle={{ margin: "0 15px 0 0", fontSize: "14px" }}
                            />
                            <Radio type={d.usable ? "label" : "label-disabled"} name={d.platform_name} checked={Number(d.paymonth) > 2} value="99" onChange={(e) => changePaymonth(data.indexOf(d), e)} />
                            <TextWrapper onClick={() => d.usable ? changePaymonth(i) : {}}>
                                <ValidateWrapper dataError={d.valueError}>
                                    <TextInput
                                        type={Number(d.paymonth) > 2 ? "main" : "readOnly"}
                                        value={Number(d.paymonth) > 2 ? d.p_input : ""}
                                        onChangeHandler={(e) => changePInput(e, data.indexOf(d))}
                                        customStyle={{
                                                width: "60px",
                                                height: "32px",
                                                padding: "8px",
                                                borderRadius: "4px",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                textAlign: "right",
                                                margin: "0 8px 0 0",
                                                color: d.usable ? "#000000" : colors.gray300,
                                        }}
                                    />
                                </ValidateWrapper>
                                <Text
                                    text="개월 뒤"
                                    customStyle={{ fontSize: "14px", fontWeight: "400", color: d.usable ? "#000000" : colors.gray300 }}
                                />
                            </TextWrapper>
                        </Td>
                        <Td width="420px">
                            <Checkbox type="boolean" disabled={!d.usable} active={Boolean(d.circulation_stop_date)} onChangeHandler={() => setCirculationDate(i)} />
                            {d.circulation_stop_date ?
                                <>
                                    <Text text="유통종료일" customStyle={{ fontWeight: "400", fontSize: "14px", padding: "0 8px 0", color: d.usable ? colors.black : colors.gray300}}/>
                                    <DateSelector
                                        type="day"
                                        disabled={Boolean(!d.usable)}
                                        initialDate={d.circulation_stop_date}
                                        index={i}
                                        onChangeHandler={changeEndCirculationDate}
                                        yearCustom={{ width: "80px" }}
                                        monCustom={{ width: "60px" }}
                                        dayCustom={{ width: "60px" }}
                                    />
                                </>
                                :
                                <Text text="유통종료" customStyle={{ fontWeight: "400", fontSize: "14px", padding: "0 0 0 8px" }}/>
                            }
                        </Td>
                    </Tr>
                ))
                }
            </TBody>
        </TableContainer>
    );
}

export default PlatformTable;

const headers = [ { text: "사용여부", customStyle: { width: "80px" } },
                    { text: "판매플랫폼", customStyle: { width: "300px" } },
                    { text: "인세지급일", customStyle: { width: "400px" }, tooltip: "출판사가 판매월로부터 작가에게 정산금을 지급 하기까지의 기간을 뜻합니다." },
                    { text: "유통종료", customStyle: { width: "420px" }, tooltip: "유통 종료 시 해당 플랫폼의 판매조회는 가능하지만, 종료일 이후 판매 입력 또는 정산서 발행이 불가능합니다." }]

const TableContainer = styled.table`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    table-layout: fixed;
`;

const StyledTheader = styled.thead`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
`;

const StyledTr = styled.tr`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    border-top: 1px solid ${colors.gray300};
    border-bottom: 1px solid ${colors.gray300};
`;

const StyledTh = styled.th<StyleProps>`
    display: flex;
    align-items: center;
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "100%")};
    padding: ${({ padding }) => (padding ? padding : "8px 24px")};
    margin: ${({ margin }) => (margin ? margin : "")};
`;

const TBody = styled.tbody`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Tr = styled.tr`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
`;

const Td = styled.td<TdStyleProps>`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-first"};
    align-items: center;
    height: 100%;
    width: ${({ width }) => width ? width : "300px"};
    padding: ${({ padding }) => padding ? padding : "8px 24px"};
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
`

const TooltipWrap = styled.div`
    display: flex;
    align-items: center;
`

export interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
}

interface TdStyleProps {
    justifyContent?: string;
    width?: string;
    padding?: string;
}