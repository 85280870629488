import { ChangeEvent } from 'react'
import _ from 'lodash';
import { text } from "@/modules/utils/validate_modify";
import { useAppDispatch } from '@/modules/store';
import { updateRow } from "@/pages/common/dataTableSlice";
import TextInput from "@/components/atoms/input/TextInput";
import ValidateWrapper from '@/components/atoms/input/ValidateWrapper';
import { Item } from '@/components/molecules/common/table/DataTable';

export default function EtcReason({ item, changeItem }: EtcReasonProps) {
    const dispatch = useAppDispatch();

    const changeReason = (e: ChangeEvent<HTMLInputElement>) => {
        const res = text({ field: "지급사유", value: e.target.value, error: "reasonError", max: 100, mandatory: true });
        let newItem = _.cloneDeep(item);
        newItem.reason = res.value;
        newItem.reasonError = res.reasonError;
        changeItem(newItem);
        dispatch(updateRow({ itemKey: "etc_id", updatedRow: newItem }));
    };
    
    return (
        <ValidateWrapper dataError={item.reasonError}>
            <TextInput
                type="main"
                value={item.reason}
                placeholder="지급사유 (100자 이내)"
                onChangeHandler={changeReason}
                customStyle={{
                    width: "100%",
                    height: "32px",
                    padding: "8px",
                    fontSize: "12px",
                }}
            />
        </ValidateWrapper>
    );
}

interface EtcReasonProps {
    item: Item;
    changeItem: (item: Item) => void;
}