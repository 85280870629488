import { useState } from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";

// components
import Checkbox from "@/components/atoms/input/Checkbox";
import SearchInput from "@/components/molecules/basedata-manage/SearchInput";
import SelectBox from "@/components/atoms/selectBox/index";
import FilterRow from "@/components/molecules/common/searchFilter/FilterRow";
import TagFilter from "@/components/molecules/common/tagFilter/TagFilter";

// reducer
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { totalSalesActions } from "@/pages/settlement/total-sales/slice";

// hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";

export default function WithdrawFilterBox() {
    const dispatch = useAppDispatch();
    const [keyword, setKeyword] = useState("");
    const [showMore, setShowMore] = useState(false);
    const { setItemsPerPage, itemsPerPage } = usePagination({ from: "Withdraw" });
    let { platformList, onlyUser } = useAppSelector((state: RootState) => state.totalSales);

    const handlerClickPlusIcon = () => setShowMore((prev) => !prev);

    function setPlatform(list: number[]) {
        dispatch(totalSalesActions.selectedPlatform(list));
    }

    const onChangeSearchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    }

    function search() {
        dispatch(totalSalesActions.setTotalSalesDatas({
            key: "searchUser", value: keyword
        }))
    }

    const keyPressHandler = (e: any) => {
        if (e.keyCode === 229) return;
        if (e.key === "Enter") {
            search();
        }
    };

    function selectItemPerPage(value:any) {
        setItemsPerPage(Number(value.value));
    }

    return (
        <>
        <FilterContainer>
            <FilterRow
                label="검색"
                filterContent={[
                    <SearchInput
                        value={keyword}
                        placeholder="저자명"
                        onChangeHandler={(e) => onChangeSearchUser(e)}
                        onKeyDownHandler={keyPressHandler}
                        clickIconHandler={search}
                        customStyle={{ width: "200px", fontSize: "12px", fontWeight: "400" }}
                    />,
                    <CheckboxWrap onClick={() => dispatch(totalSalesActions.setOnlyUser(!onlyUser))}>
                        <Checkbox type="boolean" active={onlyUser} onChangeHandler={(e) => dispatch(totalSalesActions.setOnlyUser(e.target.checked))} />
                        <CheckboxLabel>가입 작가만 보기</CheckboxLabel>
                    </CheckboxWrap>
                ]}
                customStyle={{ backgroundColor: colors.gray50 }}
            />
            <FilterRow
                label="판매 플랫폼"
                plusIcon={{ showPlusIcon: showMore, onClick: handlerClickPlusIcon }}
                filterContent={[
                    <TagFilter
                        type="checkbox"
                        items={platformList}
                        keyName={"platform_id"}
                        valueName={"platform_name"}
                        onChange={setPlatform}
                        showMore={showMore}
                    />
                ]}
            />
        </FilterContainer>
        <ItemPerPageWrap>
            <SelectBox
                labelKey="name"
                valueKey="value"
                selected={itemsPerPage}
                options={options}
                onClickValueFunc={selectItemPerPage}
            />
        </ItemPerPageWrap>
        </>
    )
}

const options = [{ name: "10개씩 보기", value: "10" },
                     { name: "30개씩 보기", value: "30" },
                     { name: "50개씩 보기", value: "50" },
                     { name: "100개씩 보기", value: "100" }];

const FilterContainer = styled.div`
    width: 1200px;
    border-radius: 4px;
`

const CheckboxWrap = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

const CheckboxLabel = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    margin-left: 8px;
`

const ItemPerPageWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
    width: 100%;
`