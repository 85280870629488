import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowDropDown } from "@/assets/icons/arrow_drop_down.svg";
import { ReactComponent as DisArrowDropDown } from "@/assets/icons/arrow_drop_down_gray600.svg";
import useClickOutside from "@/modules/hooks/useClickOutside";

/* labelKey 보여주는 value
 * valueKey 실제 value값의 key
 * empty 기본 default 값이 없을 때 true로 설정
 * disabled
 * options 옵션들 배열
 * selected 선택되는 값
 * onClickValueFunc 옵션 클릭했을 시 실행되는 함수
 *
**/

export default function SelectBox({ labelKey, valueKey, type, empty, disabled, options, selected, onClickValueFunc, customStyle, modalOptions }: SelectBoxProps) {
	const [show, setShow] = useState(false);
    const [selectedV, setSelectedV] = useState<string|number>("");
    const selectBoxRef = useRef(null);
    const isOutsideClicked = useClickOutside({ ref: selectBoxRef });
    useEffect(() => {
        if (type === "modal") {
            scrollListener();
        }
    }, [type])
    useEffect(() => { 
        if (options.length && typeof selected !== undefined) {
            if (empty && !selected) {
                setSelectedV("선택");
            } else {
                if ((options.find(o => String(o[valueKey]) === String(selected)) as Options)) {
                    setSelectedV((options.find(o => String(o[valueKey]) === String(selected)) as Options)[labelKey]);
                } else {
                    setSelectedV("");
                }
            }
        }
    }, [selected, options, labelKey, valueKey, empty])

    useEffect(() => {
        if (isOutsideClicked) setShow(false);
    }, [isOutsideClicked]);

    const clickValue = (value:any) => {
        onClickValueFunc(value);
    }

    const onClickHandler = () => {
        setShow(!show);
        // let modalbody = document.getElementById("setRatioPeriodModalBody");
        let modalbody = document.getElementById(`${modalOptions?.scrollModalId}`);
        if (modalbody && modalOptions?.index) {
            let list:any = document.getElementById(`optionsList-${modalOptions.id}`);
            let modalScrollTop = modalbody.scrollTop;
            let itemHeight = customStyle?.height ? Number(customStyle.height.replace(/[^0-9]/g, "")) : 32;
            
            // (모달 헤더 height + 테이블 헤더 height) + ( (아이템 height+ row의 공백 / 2) * (modalOptions.index + 1)) + (modalOptions.index * (row의 공백 / 2) ) - modalbody.scrollTop + "px";
            // list.style.top = 88 + (46 * (modalOptions.index + 1)) + (modalOptions.index * 14) - modalScrollTop + "px";
            list.style.top = modalOptions.emptyHeight + ((itemHeight + modalOptions.halfRowEmpty) * (modalOptions.index + 1)) + (modalOptions.index * modalOptions.halfRowEmpty) - modalScrollTop + "px";

        }
    }

    const scrollListener = () => {
        // let target = document.getElementById(`setRatioPeriodModalBody`);
        let target = document.getElementById(`${modalOptions?.scrollModalId}`);
        if (target) target.addEventListener("scroll", () => {
            setShow(false);
        });
    }

    switch (type) {
		case "modal":
			return (
                <>
                {
                    (options.length && labelKey && valueKey) &&   
                    <SelectBoxWrap
                        width={customStyle?.width}
                        height={customStyle?.height}
                        margin={customStyle?.margin}
                        cursor={disabled ? "default" : "pointer"}
                        onClick={()=> disabled ? {} : onClickHandler()}
                        ref={selectBoxRef}
                    >
                        <ModalSelectBoxWrapper
                            width={customStyle?.width}
                            height={customStyle?.height}
                            fontSize={customStyle?.fontSize}
                            fontWeight={customStyle?.fontWeight}
                            color={customStyle?.color}
                            disabled={disabled}
                        >
                            <div className="selected-value">
                                {selectedV}
                            </div>
                            <ul id={`optionsList-${modalOptions?.id}`} style={{ display: show ? "block" : "none"}}>
                                {options.map((o, i) => (
                                    <li key={i} onClick={() => {clickValue(o)}}>
                                        {o[labelKey]}
                                    </li>
                                ))}
                            </ul>
                        </ModalSelectBoxWrapper>
                        <IconWrap height={customStyle?.height}>
                            <TransformArrow active={show}>
                                { disabled ? <DisArrowDropDown /> : <ArrowDropDown />}
                            </TransformArrow>
                        </IconWrap>
                    </SelectBoxWrap>
                }
                </>  
			);
		default:
			// default
			return (
                <>
                {
                    (options.length && labelKey && valueKey)   
                    ?   (<SelectBoxWrap
                            width={customStyle?.width}
                            height={customStyle?.height}
                            margin={customStyle?.margin}
                            cursor={disabled ? "default" : "pointer"}
                            onClick={()=> disabled ? {} : setShow(!show)}
                            ref={selectBoxRef}
                        >
                            <SelectBoxWrapper
                                width={customStyle?.width}
                                height={customStyle?.height}
                                fontSize={customStyle?.fontSize}
                                fontWeight={customStyle?.fontWeight}
                                color={customStyle?.color}
                                disabled={disabled}
                            >
                                <div className="selected-value">
                                    {selectedV}
                                </div>
                                <ul style={{ display: show ? "block" : "none"}}>
                                    {options.map((o, i) => (
                                        <li key={i} onClick={() => {clickValue(o)}}>
                                            {o[labelKey]}
                                        </li>
                                    ))}
                                </ul>
                            </SelectBoxWrapper>
                            <IconWrap height={customStyle?.height}>
                                <TransformArrow active={show}>
                                    { disabled ? <DisArrowDropDown /> : <ArrowDropDown />}
                                </TransformArrow>
                            </IconWrap>
                        </SelectBoxWrap>)
                    :   (<SelectBoxWrap
                            width={customStyle?.width}
                            height={customStyle?.height}
                            margin={customStyle?.margin}
                            cursor={disabled ? "default" : "pointer"}
                            onClick={()=> disabled ? {} : setShow(!show)}
                            ref={selectBoxRef}
                        ></SelectBoxWrap>)
                }
                </>  
			);
	}
}

export interface Options {
    [key: string]: any;
}

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    color?: string;
    cursor?: string;

    disabled?: boolean;
}

interface ModalOptions {
    id: string;
    index: number;
    scrollModalId: string;

    // (모달 헤더 height + 테이블 헤더 height)
    emptyHeight: number;
    // (row의 공백 / 2)
    halfRowEmpty: number;
}

interface SelectBoxProps {
	type?: "default" | "modal";
    labelKey: string;
    valueKey: string;
    empty?: boolean;
    disabled?: boolean;
	options: Options[];
	selected: string | number;
	onClickValueFunc: Function;
	customStyle?: StyleProps;

    modalOptions?: ModalOptions;
}

const IconWrap = styled.div<{ height?: string }>`
    position: absolute;
    top: ${({ height }) => height ? `calc(${height}-24px)`: "4px"};
    right: 2.5px;
`;

const TransformArrow = styled.div<{active?: boolean}>`
    width: 25px;
    height: 25px;
    margin-left: 5px;
    transition: transform .3s;
    transform: ${({ active }) => active ? "rotate(180deg)" : ""};
`

const SelectBoxWrap = styled.div<{ width?: string, height?: string, margin?: string, cursor?: string }>`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: ${({ width }) => width ? width : "120px"};
    height: ${({ height }) => (height ? height : "32px")};
    margin: ${({ margin }) => (margin ? margin : "")};
    cursor: ${({ cursor }) => (cursor ? cursor : "default")};
`;

const SelectBoxWrapper = styled.div<StyleProps>`
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "32px")};
    color: ${({ theme }) => theme.colors.white};
    
    .selected-value {
        position: relative;
        display: flex;
        align-items: center;
        width: ${({ width }) => (width ? width : "120px")};
        height: ${({ height }) => (height ? height : "32px")};
        padding: ${({ padding }) => (padding ? padding : "8px")};
        margin: ${({ margin }) => (margin ? margin : "")};
        font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
        font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
        color: ${({ disabled, theme }) => (disabled ? theme.colors.gray600 : theme.colors.black)};
        background-color: ${({ disabled, theme }) => (disabled ? theme.colors.gray50 : theme.colors.white)};
        border: 1px solid ${({ theme }) => theme.colors.gray300};
        border-radius: 4px;
        cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    }

    ul {
        position: absolute;
        top: ${({ height }) => (height ? height : "32px")};
        left: 0;
        box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
        z-index: 999;

        border: 1px solid ${({ theme }) => theme.colors.gray300};
        width: ${({ width }) => (width ? width : "120px")};
        max-height: 160px;
        overflow-y: auto;
    }

    li {
        display: flex;
        align-items: center;
        padding: ${({ padding }) => (padding ? padding : "8px")};
        // width: ${({ width }) => (width ? width : "120px")};
        height: ${({ height }) => (height ? height : "32px")};
        font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
        font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
        color: ${({ theme }) => theme.colors.black};
        background: ${({ theme }) => theme.colors.white};
        :hover {
            cursor: ${({ cursor }) => (cursor ? "default" : "pointer")};
            background: ${({ theme }) => theme.colors.blue50};
        }
    }
`;

const ModalSelectBoxWrapper = styled.div<StyleProps>`
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "32px")};
    color: ${({ theme }) => theme.colors.white};
    
    .selected-value {
        position: relative;
        display: flex;
        align-items: center;
        width: ${({ width }) => (width ? width : "120px")};
        height: ${({ height }) => (height ? height : "32px")};
        padding: ${({ padding }) => (padding ? padding : "8px")};
        margin: ${({ margin }) => (margin ? margin : "")};
        font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
        font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
        color: ${({ disabled, theme }) => (disabled ? theme.colors.gray600 : theme.colors.black)};
        background-color: ${({ disabled, theme }) => (disabled ? theme.colors.gray50 : theme.colors.white)};
        border: 1px solid ${({ theme }) => theme.colors.gray300};
        border-radius: 4px;
        cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    }

    ul {
        position: fixed;
        box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
        z-index: 999;

        border: 1px solid ${({ theme }) => theme.colors.gray300};
        width: ${({ width }) => (width ? width : "120px")};
        max-height: 160px;
        overflow-y: auto;
    }

    li {
        display: flex;
        align-items: center;
        padding: ${({ padding }) => (padding ? padding : "8px")};
        // width: ${({ width }) => (width ? width : "120px")};
        height: ${({ height }) => (height ? height : "32px")};
        font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
        font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
        color: ${({ theme }) => theme.colors.black};
        background: ${({ theme }) => theme.colors.white};
        :hover {
            cursor: ${({ cursor }) => (cursor ? "default" : "pointer")};
            background: ${({ theme }) => theme.colors.blue50};
        }
    }
`;