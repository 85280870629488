import React, { useState } from 'react'
import styled from 'styled-components'
import SectionLayout from '@/components/molecules/introduce/SectionLayout'
import PortfolioTitle from '@/components/molecules/introduce/portfolio/PortfolioTitle'
import PortfolioFunctionContainer from '@/components/molecules/introduce/portfolio/PortfolioFunctionContainer';
import PortfolioImageContainer from '@/components/molecules/introduce/portfolio/PortfolioImageContainer';
import UnderlineText from '@/components/molecules/introduce/UnderlineText';
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn';

export default function PortfolioContainer() {
    const fadeInText = useScrollFadeIn('up', 1, 0);
    const [tab, setTab] = useState<number>(0);
    
    const idChangeHandler = (e:React.MouseEvent<HTMLDivElement>, id:number) => {
        setTab(()=>id);
    }

    const toPricingHandler = () => {
        window.location.href = '/pricing'
    }

    return (
        <SectionLayout>
            <PortfolioTitle/>
            <FlexBox>
                <PortfolioFunctionContainer
                    tab={tab}
                    onIdChange= {idChangeHandler}
                />
                <PortfolioImageContainer
                    tab={tab}
                />
            </FlexBox>
            <StyledDetailWrapper {...fadeInText}>
                <UnderlineText label="출판사 전용 기능 자세히 보기" onClickHandler={toPricingHandler}/>
            </StyledDetailWrapper>
        </SectionLayout>
    )
}

const FlexBox = styled.div`
    display: flex;
`

const StyledDetailWrapper = styled.div`
    margin-top: 60px;
    cursor:pointer;
`