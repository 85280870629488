import { Item } from "@/components/molecules/common/table/DataTable";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: TableState = {
    selectedItems: [],
    updatedRows: [],
};

const dataTableSlice = createSlice({
    name: "dataTable",
    initialState,
    reducers: {
        addItems: (state, action: PayloadAction<{ key: string; items: Item | Item[] }>) => {
            const { key, items } = action.payload;

            if (Array.isArray(items)) {
                // 중복 아이템 제거
                const filteredItems = items.filter(
                    (item) => !state.selectedItems.some((sitem) => item[key] === sitem[key]),
                );
                state.selectedItems.push(...filteredItems);
                return state;
            }
            // items가 객체일 때
            const hasValue = state.selectedItems.some((item) => item[key] === items[key]);
            if (!hasValue) state.selectedItems.push(items);
        },
        deleteItems: (state, action: PayloadAction<{ key: string; value: number | number[] }>) => {
            const { key, value } = action.payload;

            if (Array.isArray(value)) {
                state.selectedItems = state.selectedItems.filter((item) => !value.includes(item[key]));
            } else {
                state.selectedItems = state.selectedItems.filter((item) => item[key] !== value);
            }
        },
        updateRow: (
            state,
            action: PayloadAction<{ itemKey: string; updatedRow?: { [key: string]: any }; keysToDelete?: number[] }>,
        ) => {
            const { itemKey, updatedRow, keysToDelete } = action.payload;

            if (!updatedRow) {
                if (!keysToDelete) return;
                state.updatedRows = state.updatedRows.filter((r) => !keysToDelete.includes(r[itemKey]));
                return state;
            }
            for (let i = 0; i < state.updatedRows.length; i++) {
                if (state.updatedRows[i][itemKey] === updatedRow[itemKey]) {
                    state.updatedRows[i] = updatedRow;
                    return state;
                }
            }
            state.updatedRows.push(updatedRow);
        },
        // 페이지를 벗어나면 전체 리셋
        resetDataTable: () => ({ ...initialState }),
    },
});

// TYPE DEFINITION
export interface TableState {
    selectedItems: { [key: string]: any }[];
    updatedRows: { [key: string]: any }[];
}
export interface Data {
    items: any[];
    details: {
        [parentId: number]: any[];
    };
}
export interface Keys {
    itemKey: string;
    detailItemKey: string;
}

export const { addItems, deleteItems, updateRow, resetDataTable } = dataTableSlice.actions;
export default dataTableSlice.reducer;
