import { useEffect, useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import SetCoAuthorTaxBody from "@/components/molecules/common/modal/bodyModal/SetCoAuthorTaxBody";
import { BaseModalProps } from "@/pages/common/types";

function SetCoAuthorTaxModal({ show, close, coAuthorInfo, onApply }: SearchAuthorModalProps) {
    const [coAuthorItems, setcoAuthorItems] = useState<CoAuthorInfo[]>([]);

    const apply = async () => {
        let hasError = false;
        for (let i = 0; i < coAuthorItems.length; i++) {
            if (coAuthorItems[i].tax_amount === "" || coAuthorItems[i].tax_amount === 0) {
                setcoAuthorItems((prev) =>
                    prev.map((p, idx) => (idx === i ? { ...p, taxAmountError: "적용 세액을 입력해주세요." } : p)),
                );
                hasError = true;
            }
        }
        onApply(coAuthorItems, hasError);
        close();
    };

    useEffect(() => {
        if (coAuthorInfo.length) {
            setcoAuthorItems(coAuthorInfo);
        }
        return () => {};
    }, [coAuthorInfo]);

    return (
            <BaseModal
                show={show}
                close={close}
                titles={["공저별 세금 설정"]}
                customStyle={[{ width: "360px", height: "316px" }]}
                applyFunc={apply}
            >
                <SetCoAuthorTaxBody coAuthorItems={coAuthorItems} setcoAuthorItems={setcoAuthorItems} />
            </BaseModal>
    );
}

interface SearchAuthorModalProps extends BaseModalProps {
    coAuthorInfo: CoAuthorInfo[];
    onApply: (data: CoAuthorInfo[], error: boolean) => void;
}

export interface CoAuthorInfo {
    ae_id: number;
    co_st_ratio: number;
    real_name: string;
    taxAmountError: string;
    tax_amount: string | number;
    tax_ratio: number;
    tax_type: string;
}

export default SetCoAuthorTaxModal;
