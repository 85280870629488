import styled from "styled-components";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState } from "@/modules/store";
import Text from "@/components/atoms/text/index";
import { 
        pubDashboardItems, pubSalesEntryItems, pubSalesInquiryItems, 
        pubBasedataItems, pubTotalSalesItems, pubStatementItems, 
        pubPortfolioItems, pubEbookItems, pubMypageItems, pubSupportItems, pubUploaderItems,
        dashboardItems, salesInquiryItems, statementItems, portfolioItems, mypageItems, supportItems 
} from "./viewData";
 
export default function View({ menu }: ViewProps) {
    const { isPublisher } = useSelectorTyped((state:RootState) => state.user);

    if (isPublisher) {
        switch (menu.page) {
            case "dashboard":
                return <Content menu={menu.name} items={pubDashboardItems} />;
            case "settlement/salesEntry":
                return <Content menu="판매 입력" items={pubSalesEntryItems} />;
            case "settlement/salesInquiry":
                return <Content menu={menu.name} items={pubSalesInquiryItems} />;
            case "settlement/basedata":
                return <Content menu={menu.name} items={pubBasedataItems} />;
            case "settlement/totalSales":
                return <Content menu={menu.name} items={pubTotalSalesItems} />;
            case "settlement/statement":
                return <Content menu={menu.name} items={pubStatementItems} />;
            case "portfolio":
                return <Content menu={menu.name} items={pubPortfolioItems} />;
            case "ebook":
                return <Content menu={menu.name} items={pubEbookItems} />;
            case "mypage":
                return <Content menu={menu.name} items={pubMypageItems} />;
            case "support":
                return <Content menu={menu.name} items={pubSupportItems} />;
            case "uploader":
                return <Content menu={menu.name} items={pubUploaderItems} />;
            default:
                return <></>;
        }
    }

    switch (menu.page) {
        case "dashboard":
            return <Content menu={menu.name} items={dashboardItems} />;
        case "salesInquiry":
            return <Content menu={menu.name} items={salesInquiryItems} />;
        case "statement":
            return <Content menu={menu.name} items={statementItems} />;
        case "portfolio":
            return <Content menu={menu.name} items={portfolioItems} />;
        case "mypage":
            return <Content menu={menu.name} items={mypageItems} />;
        case "support":
            return <Content menu={menu.name} items={supportItems} />;
        default:
            return <></>;
    }

}

function Content({ menu, items }: ContentProps) {
    return (
        <ContentContainer>
            <Text text={menu} customStyle={{ fontSize: "24px", fontWeight: "700" }} />
            <Line />
            {items.map((item, idx) => (
                <ContentBody key={idx}>
                    <Text text={item.title || ""} customStyle={{ fontSize: "16px", fontWeight: "700" }} />
                    <ImgWrapper>
                        {item.src && item.src.map((s, idx) => (
                            <Img 
                                key={idx} 
                                src={s} 
                                alt="이용가이드 사진" 
                                width={item.srcWidth? item.srcWidth[idx] : "100%"} 
                            />
                        ))}
                    </ImgWrapper>
                    <DescWrapper>
                        {item.desc && item.desc.map((d, idx) => (
                            <Desc key={idx}>
                                <p>{d[0]}</p>
                                {d[1]}
                            </Desc>
                        ))}
                    </DescWrapper>
                </ContentBody>
            ))}
        </ContentContainer>
    );
}

// 가이드 컨텐츠
const ContentContainer = styled.div`
    width: 100%;
`;
const Line = styled.div`
    width: 100%;
    height: 1px;
    margin: 24px 0 26px;
    background-color: ${({ theme }) => theme.colors.gray300};
`;
const ContentBody = styled.div`
    margin-top: 24px;
`;
const ImgWrapper = styled.div`
    margin-top: 24px;
`;
const Img = styled.img`
    & + img {
        margin-top:24px;
    }
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    backface-visibility: hidden;
`;
const DescWrapper = styled.div`
    margin-top: 24px;
`;
const Desc = styled.div`
    display: flex;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray800};
    line-height: 24.5px;
    white-space: pre-line;
    p {
        white-space: pre-wrap;
        color: ${({ theme }) => theme.colors.blue500};
    }
`;

interface ViewProps {
    menu : {
        page: string;
        name: string;
    }
}
interface ContentProps {
    menu: string;
    items: {
        title?: string;
        src?: any[];
        srcWidth?: string[];
        desc?: string[][];
    }[];
}
