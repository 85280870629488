import styled from "styled-components";
import { isIOS } from "react-device-detect";
import { ReactComponent as Logo } from "@/assets/icons/mob-landing/bottom-banner-logo.svg";

export default function BottomBanner() {
    const storeURL = isIOS ? 'https://apps.apple.com/kr/app/%EA%B8%80%EB%A7%81/id6443822020' : 'https://play.google.com/store/apps/details?id=com.glingmobile&hl=ko';
    
    const goDownload = () => window.location.href = storeURL;

    return (
        <Container>
            <VideoBox>
                <video
                    id="gling-video"
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src="/landing/bottom-banner-video.mp4" type="video/mp4" />
                </video>
                <TopShadow />
            </VideoBox>
            <ContentBox>
                <Logo />
                <Text>지금 글링에서{`\n`}시작하세요!</Text>
                <Button onClick={goDownload}>다운로드</Button>
            </ContentBox>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-top: 90px;
    color: ${({ theme }) => theme.colors.white};
`
const VideoBox = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    overflow: hidden;

    #gling-video {
        position: absolute;
        height: 100%;
        left: -50%;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000090;
    }
`
const TopShadow = styled.div`
    position: absolute;
    width: 100%;
    height: 15%;
    background:linear-gradient(to bottom, #000000, #00000050, #00000000);
`
const ContentBox = styled.div`
    position: relative;
    text-align: center;
`
const Text = styled.p`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-top: 16px;
    white-space: pre-wrap;
    text-align: center;
`
const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 44px;
    margin-top: 30px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
`