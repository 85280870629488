import styled from "styled-components";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch } from "@/modules/store";
import TextInput from "@/components/atoms/input/TextInput";
import { colors } from "@/assets/styles/theme";
import Button from "@/components/atoms/button";

import Post from "@/components/molecules/common/post/Post";
import { signupActions } from "@/pages/auth/signUp/signupSlice";

export default function BaseInput({
    label,
    value,
    placeholder,
    errorMsg,
    onChange,
    onKeyPress,
    customStyle,
    disabled,
}: {
    label: string;
    value: string;
    placeholder: string;
    errorMsg: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: Function;
    customStyle?: { width?: string, margin?: string }
    disabled?: boolean
}) {
    const dispatch = useAppDispatch();
    const [popup, setPopup] = useState(false);
    useEffect(() => {}, [value]);

    function onCompletePost(data:any) {
        dispatch(signupActions.updateAddress({ address: data.address, zonecode: data.zonecode }));
    }

    function keyPress(e: any) {
        if (e.keyCode === 229) return;
        if (e.key === "Enter") {
            if (onKeyPress) {
                onKeyPress("Enter");
            }
        }
    }

    return (
        <>
            <InputBox width={customStyle?.width} margin={customStyle?.margin}>
                <label htmlFor={label} style={{ color: errorMsg ? colors.error : colors.gray600 }}>{label}</label>
                {label !== "주소" ?
                <ValidateWrap data-error={errorMsg}>
                    <TextInput
                        type={disabled ? "readOnly" : "main"}
                        disabled={disabled}
                        placeholder={placeholder}
                        value={value}
                        onChangeHandler={onChange}
                        onKeyDownHandler={(e) => keyPress(e)}
                        customStyle={{ width: customStyle?.width ? customStyle.width : "400px", height: "40px", borderColor: errorMsg ? colors.error : colors.gray300 }}
                    />
                </ValidateWrap>
                :
                <AddressWrap>
                    <TextInput
                        type="readOnly"
                        placeholder={placeholder}
                        value={value}
                        onChangeHandler={onChange}
                        onKeyDownHandler={(e) => keyPress(e)}
                        customStyle={{ width: customStyle?.width ? customStyle.width : "300px", height: "40px", borderColor: errorMsg ? colors.error : colors.gray300 }}
                    />
                    <Button
                        type={disabled ? "disabled" : "main-outline"}
                        label="주소 검색"
                        customStyle={{ width: "100px",
                                        height: "40px",
                                        margin: "8px 0 0",
                                        fontSize: "16px",
                                        fontWeight: "500" }}
                        onClickHandler={() => setPopup(!popup)}
                    />
                </AddressWrap>
                }
            </InputBox>
            { popup &&
                <Post
                    onCompletePost={onCompletePost}
                    onClose={() => setPopup(false)}
                />
            }
        </>
    );
}

const ValidateWrap = styled.div`
    position: relative;
    :after {
        font-size: 10px;
        color: ${({ theme }) => theme.colors.error};
        position: absolute;
        left: 0px;
        bottom: -14px;
        white-space: nowrap;
        content: attr(data-error);
    }
`

const InputBox = styled.div<{ width?: string, margin?: string }>`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width ? width : "400px"};
    ${({ margin }) => margin ? `margin: ${margin}`: `margin-bottom: 24px`};
    label {
        font-size: 14px;
        font-weight: 500;
    }
    input {
        margin-top: 8px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 12px;
        padding: 8px;
    }
`;

const AddressWrap = styled.div`
    display: flex;
    align-items: center;
    width: 400px;
`