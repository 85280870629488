import { useState } from "react";
import { TableProps } from "../DataTable";

/**
 *  데이터 테이블 정렬 관련 훅
 *  @returns 
 */
const useDataTableSort = ({ items, options, setItemsOrder, setHeadlessItem }: UseDataTableSortProps) => {
    const [sortBy, setSortBy] = useState(""); // 정렬 기준
    const [sortDir, setSortDir] = useState<SortDirType>("none"); // 정렬 방향
    const regexp = /^[0-9\,\-]*$/; // 숫자와 comma만으로 구성됐는지 여부

    const sorting = (valueName:string, changeDir:boolean) => {
        if (options && options.noSort) return;

        let tmpSortBy = sortBy;
        let tmpSortDir = sortDir;
        let tmpSortedItems = [];
        
        // 새로운 컬럼 정렬
        if (tmpSortBy !== valueName) {
            tmpSortBy = valueName;
            tmpSortDir = "none";
        }

        // 테이블 헤더 클릭으로 sorting할 때 방향 변경
        if (changeDir) {
            switch (tmpSortDir) {
                case "asc":
                    tmpSortDir = "desc";
                    break;
                case "desc":
                    tmpSortDir = "none";
                    break;
                default:
                    tmpSortDir = "asc";
                    break;
            }
        }

        switch (tmpSortDir) {
            case "asc": 
                tmpSortedItems = items.slice().sort(function (a, b) {
                    let p = a[valueName],
                        n = b[valueName];
                    if (regexp.test(p) && regexp.test(n)) {
                        p = Number(p.toString().replace(/,/gi, ""));
                        n = Number(n.toString().replace(/,/gi, ""));
                    }
                    return p < n ? -1 : p > n ? 1 : 0;
                });
                break;
            case "desc":
                tmpSortedItems = items.slice().sort(function (a, b) {
                    let p = a[valueName],
                        n = b[valueName];
                    if (regexp.test(p) && regexp.test(n)) {
                        p = Number(p.toString().replace(/,/gi, ""));
                        n = Number(n.toString().replace(/,/gi, ""));
                    }
                    return p < n ? 1 : p > n ? -1 : 0;
                });
                break;
            default:
                tmpSortedItems = items;
                tmpSortBy = "";
                break;
        }

        setSortBy(tmpSortBy);
        setSortDir(tmpSortDir);
        setItemsOrder && setItemsOrder(tmpSortedItems.map((item) => item.originIndex)); // 일반 테이블 컴포넌트용
        setHeadlessItem && setHeadlessItem(tmpSortedItems); // 헤드리스 테이블 용
    };

    return { sortBy, sortDir, sorting }
}

interface UseDataTableSortProps {
    items: any[];
    options?: TableProps["options"];
    setItemsOrder?: React.Dispatch<React.SetStateAction<number[]>>;
    setHeadlessItem?: (item: any[]) => void;
};
export type SortDirType = "none" | "asc" | "desc";

export default useDataTableSort;