import styled from "styled-components";
import { useState } from "react";
import { addComma } from "@/modules/utils/filter";

export default function CoAuthorTooltip({ data }: TooltipProps) {
    const [tooltip, setTooltip] = useState(false);
    const onMouseEnter = () => {
        setTooltip(true);
    };
    const onMouseLeave = () => {
        setTooltip(false);
    };

    return (
        <TooltipWrapper textAlign={data.length}>
            <CoAuthorStyle onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                공저
            </CoAuthorStyle>
            {tooltip && <CoAuthorTable data={data} />}
        </TooltipWrapper>
    );
}

function CoAuthorTable({ data }: { data: CoAuthorProps[] }) {
    return (
        <TableContainer>
            <StyledTriangle />
            <StyledTable>
                <thead>
                    <Tr>
                        <Th>본명</Th>
                        <Th>비율(%)</Th>
                        <Th>정산금액(원)</Th>
                    </Tr>
                </thead>
                <tbody>
                    {data.map((d) => (
                        <Tr key={`coAuthor-${d.real_name}`}>
                            <Td>{d.real_name}</Td>
                            <Td>{addComma(d.co_ratio)}</Td>
                            <Td className="td-amount">{addComma(d.co_amount_au)}</Td>
                        </Tr>
                    ))}
                </tbody>
            </StyledTable>
        </TableContainer>
    );
}

const TooltipWrapper = styled.div<{ textAlign: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;
const CoAuthorStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 24px;
    padding: 8px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.blue300};
    color: ${({ theme }) => theme.colors.black};
    font-size: 12px;
    text-align: center;
`;

const TableContainer = styled.div`
    position: absolute;
    width: 200px;
`;
const StyledTriangle = styled.div`
    position: relative;
    top: 28px;
    left: 50%;
    z-index: 100;

    :before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-bottom: 10px solid ${({ theme }) => theme.colors.blue500};
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        transform: translateX(-50%);
        position: absolute;
    }
    :after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-bottom: 11px solid ${({ theme }) => theme.colors.blue50};
        border-top: 11px solid transparent;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        transform: translateX(-50%);
        position: absolute;
    }
`;

const StyledTable = styled.table`
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    background-color: ${({ theme }) => theme.colors.blue50};
    width: 200px;
    top: 48px;
    position: absolute;
    z-index: 99;
`;

const Th = styled.th`
    padding: 8px;
    text-align: center;
`;
const Td = styled.td`
    padding: 8px;
    text-align: center;
`;
const Tr = styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue500};
    .td-amount {
        text-align: right;
    }
`;

interface TooltipProps {
    data: CoAuthorProps[];
}

interface CoAuthorProps {
    real_name: string;
    co_ratio: number;
    co_amount_au: number;
}
