import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { signupActions, userInsert } from "@/pages/auth/signUp/signupSlice";
import { ChangeEvent, useEffect, useState } from "react";
import Button from "@/components/atoms/button";
import Checkbox from "@/components/atoms/input/Checkbox";
import styled from "styled-components";
import useAlert from "@/modules/hooks/useAlert";
import BaseInput from "@/components/molecules/signup/BaseInput";
import { ReactComponent as PersonalIcon } from "@/assets/icons/login/personal.svg";
import { ReactComponent as AddRoundIcon } from "@/assets/icons/add_round_blue500.svg";
import { ReactComponent as CameraIcon } from "@/assets/icons/camera_blue500.svg";
import { colors } from "@/assets/styles/theme";
import { useNavigate } from "react-router-dom";
import KcpForm from "@/components/molecules/signin/KcpForm";
import { base64Encode } from "@/modules/utils/file";
import useLoading from "@/modules/hooks/useLoading";

export default function BaseInfoForm() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    let { data, check, step, publisherJoin, fromSocial, socialData } = useAppSelector((state: RootState) => state.signup);
    const [image, setImage] = useState<string|File>("");
    const [mailing, setMailing] = useState(false);
    const [wete, setWete] = useState(true);
    const [wepr, setWepr] = useState(true);
    const [userData, setUserData] = useState({ nickName: "", email: "" });
    const [nickError, setNickError] = useState("");
    const [emailError, setEmailError] = useState("");

    const [authDone, setAuthDone] = useState(false);

    useEffect(() => {
        setUserData({ nickName: check.nickName, email: check.mailing ? check.mailing : data.id });
    }, [data, check])

    async function changeHandlerNickname(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        setUserData({...userData, nickName: value});
        if (value.length > 1 && value.length < 11) {
            setNickError("");
        } else {
            setNickError("2~10글자 이내로 입력하세요.");
        }
    }

    function changeHandlerEmail(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        setUserData({...userData, email: value});
        if (/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/i.test(value)) {
            setEmailError("");
        } else {
            setEmailError("이메일 형식을 확인해주세요.");
        }
    }

    const keyPressHandler = (key: any) => {
        if (key === "Enter") {
            next();
        }
    };

    async function next() {
        let hasError = false;
        if (!userData.nickName) {
            setNickError("별명을 입력해주세요.");
            hasError = true;
        }
        if (!userData.email) {
            setEmailError("수신 이메일을 입력해주세요.");
            hasError = true;
        }
        if (hasError) return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.");
        if (!wete) return await alert("warning", "주의", "이용약관에 동의하지 않을 시 서비스를 이용하실 수 없습니다.");
        if (!wepr) return await alert("warning", "주의", "개인정보 처리방침에 동의하지 않을 시 서비스를 이용하실 수 없습니다.");
        
        dispatch(signupActions.updateCheck({
            image: image, nickName: userData.nickName, mailing: userData.email, email: mailing, wete: wete, wepr: wepr
        }));

        setAuthDone(true);
    }

    async function setKcpData(param:any, joined:any) {
        try {
            if (joined) {
                const answer = await alert("confirm", "가입된 회원", "이미 다른 아이디로 가입된 회원입니다. 아이디 찾기로 이동하시겠습니까?");
                if (answer === "Y") {
                    navigate("/findId");
                }
                return;
            }
    
            dispatch(signupActions.setKcp(param));

            if (!publisherJoin) {
                let apiParams = {
                    ...param,
                    user_email: data.id,
                    user_pw: data.pw,
                    user_nickname: userData.nickName,
                    mailing_address: userData.email,
                    user_emailagree: mailing,
                    sociallogin_provider: fromSocial ? socialData.provider : "",
                    sociallogin_id: fromSocial ? socialData.id : "",
                    profile_img: image ? await base64Encode(image as File) : ""
                }
                await loadingOn();
                await dispatch(userInsert(apiParams)).unwrap();
            }

            dispatch(signupActions.changeStep(++step));

        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    function prev() {
        dispatch(signupActions.changeStep(--step));
    }

    function imageUpload() {
        document.getElementById("selectImage")?.click();
    }

    async function selectImage(e: ChangeEvent<HTMLInputElement>) {
        const file = e.target.files && e.target.files[0];
        if (file) {
            if (file.size > 2097152.0) {
                return await alert("warning", "주의", "2Mb 이하 파일만 등록 가능합니다.");
            }
            if (file.type == "image/png" || file.type == "image/jpeg") {
                setImage(window.URL.createObjectURL(file));
            } else {
                setImage("");
                return await alert("warning", "주의", ".jpg 또는 .png 파일만 업로드 가능합니다.")
            }
        } else {
            setImage("");
        }
    }

    function openUrl(type: string) {
        const baseUrl = process.env.NODE_ENV == "production" ? "https://biz.gling.co.kr" : "http://localhost:3000";
        if (type === "wete") {
            window.open(`${baseUrl}/info/Agreement`);
        } else {
            window.open(`${baseUrl}/info/Privacy`);
        }
    }

    return (
        <>
            <ImageWrap>
                {image ?
                <>
                    <img className="profile" src={String(image)} onClick={imageUpload} />
                    <CameraIcon className="camera-icon" width={40} height={40} onClick={imageUpload} />
                </>
                :
                <>
                    <PersonalIcon className="persion-icon" fill={colors.gray300} onClick={imageUpload} />
                    <AddRoundIcon className="add-icon" width={48} height={48} onClick={imageUpload} />
                </>
                }
                <input id="selectImage" type="file" hidden onChange={(e) => selectImage(e)} accept="image/jpeg, image/png" />
            </ImageWrap>
            <BaseInput
                label="별명"
                value={userData.nickName}
                placeholder="별명"
                errorMsg={nickError}
                onChange={changeHandlerNickname}
            />
            <BaseInput
                label="수신 이메일"
                value={userData.email}
                placeholder="수신 가능한 이메일"
                errorMsg={emailError}
                onChange={changeHandlerEmail}
            />
            <CheckBoxwrap>
                <LabelWrap onClick={() => setMailing(!mailing)}>
                    <Checkbox type="boolean" active={mailing} onChangeHandler={(e) => setMailing(e.target.checked)} />
                    <label className="label-text" htmlFor="mailing">광고성 이메일 수신에 동의합니다. (선택)</label>
                </LabelWrap>
                <RowWrap>
                    <LabelWrap onClick={() => setWete(!wete)}>
                        <Checkbox type="boolean" active={wete} onChangeHandler={(e) => setWete(e.target.checked)} />
                        <label className="label-text" htmlFor="wete">이용약관 동의 (필수)</label>
                    </LabelWrap>
                    <Button
                        type="main-outline"
                        label="상세보기"
                        customStyle={{ width: "80px" }}
                        onClickHandler={() => {openUrl("wete")}}
                    />
                </RowWrap>
                <RowWrap>
                    <LabelWrap onClick={() => setWepr(!wepr)}>
                        <Checkbox type="boolean" active={wepr} onChangeHandler={(e) => setWepr(e.target.checked)} />
                        <label className="label-text" htmlFor="wepr">개인정보처리방침 동의 (필수)</label>
                    </LabelWrap>
                    <Button
                        type="main-outline"
                        label="상세보기"
                        customStyle={{ width: "80px" }}
                        onClickHandler={() => {openUrl("wepr")}}
                    />
                </RowWrap>
            </CheckBoxwrap>
            <Button
                type="main"
                label="본인 인증 후 가입하기"
                customStyle={{ width: "400px",
                                height: "60px",
                                margin: "24px 0 0 0",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={next}
            />
            <Button
                type="red-outline"
                label="이전으로"
                customStyle={{ width: "400px",
                                height: "60px",
                                margin: "24px 0 60px",
                                fontSize: "20px",
                                fontWeight: "500" }}
                onClickHandler={prev}
            />
            <KcpForm
                authDone={authDone}
                setResult={setKcpData}
            />
        </>
    );
}

const ImageWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 120px;
    margin-bottom: 24px;
    position: relative;
    .persion-icon {
        background: ${({ theme }) => theme.colors.gray50};
        border-radius: 50%;
        cursor: pointer;
    }
    .add-icon {
        position: absolute;
        bottom: 10px;
        right: 130px;
        cursor: pointer;
    }
    .profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        cursor: pointer;
    }
    .camera-icon {
        position: absolute;
        bottom: 10px;
        right: 135px;
        background: ${({ theme }) => theme.colors.blue500};
        border-radius: 50%;
    }
`

const CheckBoxwrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
`

const LabelWrap = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    margin-right: 16px;
    .label-text {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        cursor: pointer;
    }
    input {
        margin-top: 10px;
        width: 400px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 12px;
        padding: 8px;
    }
`;

const RowWrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
`