import styled from "styled-components";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import SalesSummary from "@/components/molecules/sales-inquiry/publisher/common/SalesSummary";
import TableOptions from "@/components/molecules/sales-inquiry/publisher/common/TableOptions";
import useExcelDownload from "@/modules/hooks/useExcelDownload";

import useAlert from "@/modules/hooks/useAlert";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useLoading from "@/modules/hooks/useLoading";
import { addComma, removeComma } from "@/modules/utils/filter";
import {
    deleteMonthlyData,
    getMonthlyData,
    updateMonthlyData,
} from "@/pages/settlement/saels-inquiry/publisher/monthly/monthlySlice";
import usePlatformSetting from "@/components/molecules/sales-inquiry/publisher/hooks/usePlatformSetting";

export default function MonthlyTableTop() {
    const { items, total_cnt, total_amt_pl, total_amt_pu, reset } = useSelectorTyped(
        (state: RootState) => state.inquiryMonthly,
    );
    const { selectedItems, updatedRows } = useSelectorTyped((state: RootState) => state.dataTable);
    const dispatch = useAppDispatch();
    const { setItemsPerPage } = usePagination({ from: "monthly" });
    const { platformNoQty } = usePlatformSetting();
    const downloadExcel = useExcelDownload();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    // itemPerPage 변경
    const changeItemPerPage = (itemPerPage: number) => setItemsPerPage(itemPerPage);

    // 업데이트
    const saveData = async () => {
        const hasError = updatedRows.findIndex((d: any) => d["quantityError"] || d["amountError"]);
        if (hasError > -1) return await alert("info", "경고", "조건을 확인해주세요.");

        const answer = await alert("confirm", "저장 확인", "저장 하시겠습니까?");
        if (answer !== "Y") return;

        try {
            loadingOn();
            const selected = updatedRows.map((d) => ({
                ...d,
                amount_pl: removeComma(d.amount_pl),
                amount_pu: removeComma(d.amount_pu),
            }));
            const apiParams = { selected };

            await dispatch(updateMonthlyData(apiParams)).unwrap();
            await alert("info", "저장 완료", "저장 되었습니다.");
            await dispatch(getMonthlyData()).unwrap();
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    // 삭제
    const deleteData = async () => {
        try {
            loadingOn();
            const apiParams = {
                selected: selectedItems,
            };
            await dispatch(deleteMonthlyData(apiParams)).unwrap();
            await alert("info", "삭제 완료", "삭제가 완료되었습니다");
            await dispatch(getMonthlyData()).unwrap();
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    // 엑셀 다운로드
    const downloadMonthlyExcel = () => {
        if (items.length > 0) {
            let rows: any[] = [];
            items.forEach((item) => {
                let row = {
                    판매플랫폼: item.platform_name,
                    정산월: item.yearmon_st,
                    실제판매월: item.yearmon,
                    작품명: item.name,
                    시리즈명: item.series_name,
                    저자명: item.author_name,
                    판매량: platformNoQty.find(p => item.platform_id === p) ? '정보없음' : item.quantity,
                    "판매금액(원)": item.amount_pl,
                    "출판사정산금(원)": item.amount_pu,
                    "작가정산비율(%)": item.ratio,
                    "작가정산금액(원)": addComma(Math.round(item.author_pu)),
                };
                rows.push(row);
            });
            downloadExcel({ json: rows, excelFileName: "(판매현황조회) 월 정산", sheetNames: "일 정산 데이터" });
        } else {
            alert("info", "조회 완료", "조회된 데이터가 없습니다.");
        }
    };

    return (
        <TableTopContainer>
            <SalesSummary
                from="monthly"
                total={total_cnt}
                amountName="판매 합계"
                amount={total_amt_pl}
                pubAmount={total_amt_pu}
            />
            <TableOptions
                from="monthly"
                reset={reset}
                changeItemPerPage={changeItemPerPage}
                saveData={saveData}
                deleteData={deleteData}
                downloadExcel={downloadMonthlyExcel}
            />
        </TableTopContainer>
    );
}

const TableTopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;
