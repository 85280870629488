import { useEffect } from "react";

// components
import PlatformOptionButtons from "@/components/molecules/basedata-manage/PlatformOptionButtons";
import PlatformTable from "@/components/molecules/basedata-manage/PlatformTable";

// reducer
import { useAppDispatch } from "@/modules/store";
import { basePlatformActions } from "@/pages/settlement/basedata-manage/platform/platformSlice";

function Platform() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        return () => {
            dispatch(basePlatformActions.reset());
        }
    }, [])

    return (
        <>
            <PlatformOptionButtons
                customStyle={{ justifyContent: "flex-end", height: "80px"}}
            />
            <PlatformTable />
        </>
    );
}

export default Platform;
