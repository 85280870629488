import { useEffect, useState } from 'react';
import styled from 'styled-components';

// reducer
import { useAppDispatch } from '@/modules/store';
import { getDailyData, updateDailyData } from '@/pages/settlement/saels-inquiry/publisher/daily/dailySlice';
import { deleteData } from '@/pages/settlement/saels-inquiry/publisher/daily/dailyDeleteSlice';

// components
import BaseModal from '@/components/molecules/common/modal/BaseModal';
import DailyDetailModalBody from './bodyModal/DailyDetailModalBody';
import Button from '@/components/atoms/button';

// hooks
import useAlert from '@/modules/hooks/useAlert';
import useLoading from '@/modules/hooks/useLoading';

// utils
import { removeComma } from '@/modules/utils/filter';

// type
import type { BaseModalProps } from '@/pages/common/types';
import type { Item } from '@/components/molecules/common/table/DataTable';

export default function DailyDetailModal({ show, close, items }: DailyDetailModalProps) {
    const dispatch = useAppDispatch();
    
    const [updatedItems, setUpdatedItems] = useState<Item[]>([]);
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);

    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    useEffect(() => {
        return () => { setUpdatedItems([]); } // 초기화
    }, [show])
    
    const deleteDailyData = async () => {
        if (!selectedItems.length) return await alert('info', '주의', '대상을 선택해주세요.');

        const answer = await alert('confirm', '주의', '삭제 하시겠습니까?');
        if (answer !== 'Y') return;

        try {
            loadingOn();
            const apiParams = { selected: selectedItems };

            await dispatch(deleteData(apiParams)).unwrap();
            await alert('info', '삭제 완료', '삭제가 완료되었습니다');
            await dispatch(getDailyData()).unwrap();
            close();
        } catch (err: any) {
            await alert('error', '오류 발생', err.message);
        } finally {
            loadingOff();
        }
    }

    const apply = async () => {
        if (!updatedItems.length) return await alert('info', '알림', '변경사항이 없습니다.');

        const hasError = updatedItems.find((d: any) => d.quantityError || d.amountError);
        if (hasError) return await alert("info", "경고", "조건을 확인해주세요.");

        const answer = await alert('confirm', '저장 확인', '저장 하시겠습니까?');
        if (answer !== 'Y') return;

        try {
            loadingOn();
            const selected = updatedItems.map((item) => ({ ...item, amount: removeComma(item.amount) }));
            const apiParams = { selected };

            await dispatch(updateDailyData(apiParams)).unwrap();
            await alert("info", "저장 완료", "저장 되었습니다.");
            await dispatch(getDailyData()).unwrap();
            close();
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const renderFooter = () => {
        return (
            <ModalFooterWrap>
                <Button
                    type={ true ? 'red-outline' : 'disabled'}
                    label='삭제'
                    onClickHandler={deleteDailyData}
                />  
                <Button
                    label='저장'
                    customStyle={{ margin: '0 0 0 8px' }}
                    onClickHandler={apply}
                />    
            </ModalFooterWrap>
        )
    };

    return (
        <BaseModal
            show={show}
            close={close}
            titles={['판매 상세 내역']}
            customStyle={[{ width: '960px', height: '468px' }]}
            footer={renderFooter()}
        >
            <DailyDetailModalBody 
                items={items} 
                onAddSelectedItems={setSelectedItems}
                onAddUpdatedItems={setUpdatedItems}
            />
        </BaseModal>
    )
}

const ModalFooterWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    padding: 8px 20px;
`

interface DailyDetailModalProps extends BaseModalProps {
    items: Item[];
}