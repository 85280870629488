import { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Window } from '@/assets/icons/window.svg'
import { ReactComponent as Mac } from '@/assets/icons/mac.svg'
import { RootState, useAppDispatch, useAppSelector } from '@/modules/store'
import useLoading from '@/modules/hooks/useLoading'
import useAlert from '@/modules/hooks/useAlert'
import { getReleaseInfo } from '@/pages/support/download/slice'

export default function Download() {

    const dispatch = useAppDispatch();
    let [ver, url] = useAppSelector((state: RootState) => [
        state.download.ver,
        state.download.url
    ]);
    const { loadingOn, loadingOff } = useLoading();
    const { alert } = useAlert();
    
    const download = () => {
        let fileUrl = `/file/download/support/download/manual`;
        let link = document.createElement("a");
        if (typeof link.download === "string") {
            document.body.appendChild(link);
            link.download = 'pdf 다운로드';
            link.href = fileUrl;
            link.click();
            document.body.removeChild(link);
        }
    }

    const getFile = (url: string) => {
        let link = document.createElement("a");
        if (typeof link.download === "string") {
            document.body.appendChild(link);
            link.href = url;
            link.click();
            document.body.removeChild(link);
        } 
    }

    const fetchData = async () => {
        try {
            loadingOn();
            const apiParams = {
                withCredentials: true,
                credentials: "include"
            }
            await dispatch(getReleaseInfo(apiParams)).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message)
        } finally {
            loadingOff();
        }
    }

    useEffect(() => {
        fetchData();
    }, [dispatch])
    
    return (
        <StyledContainer>
            <StyledTitleContainer>
                <StyledTitleWrapper>
                    판매 데이터 자동 업로더 (ver {ver.replace('v', '')})
                </StyledTitleWrapper>
                <StyledDownloadWrapper onClick={download}>
                    메뉴얼 다운로드
                </StyledDownloadWrapper>
            </StyledTitleContainer>
            <StyledContentContainer>
                <StyledWindowSvg onClick={() => getFile(url.exe)}>
                    <Window/>
                </StyledWindowSvg>
                <StyledMacSvg onClick={() => getFile(url.dmg)}>
                    <Mac/>
                </StyledMacSvg>
            </StyledContentContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display:flex;
    flex-direction: column;
`

const StyledTitleContainer = styled.div`
    display:flex;
    justify-content: space-between;
`;

const StyledTitleWrapper = styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-weight: 700;
    font-size: 20px;
`;

const StyledDownloadWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 32px;
    font-size: 14px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.blue500};
    cursor: pointer;
`;

const StyledContentContainer = styled.div`
    display: flex;
    margin-top: 31px; 
`;

const StyledWindowSvg = styled.div`
    margin-right: 20px;
    cursor: pointer;
    svg .rect {
        fill: ${({ theme }) => theme.colors.gray50};
        transition: all ease-in-out .5s;
    }

    svg .icon, svg .osType {
        fill: ${({ theme }) => theme.colors.gray600};
        transition: all ease-in-out .5s;
    }

    :hover {
        svg .rect {
            fill: ${({ theme }) => theme.colors.blue50};
        }

        svg .icon, svg .osType {
            fill: ${({ theme }) => theme.colors.blue500};
        }
    }
`;

const StyledMacSvg = styled.div`
    cursor: pointer;
    svg .rect {
        fill: ${({ theme }) => theme.colors.gray50};
        transition: all ease-in-out .5s;
    }

    svg .icon, svg .osType {
        fill: ${({ theme }) => theme.colors.gray600};
        transition: all ease-in-out .5s;
    }

    :hover {
        svg .rect {
            fill: ${({ theme }) => theme.colors.blue50};
        }

        svg .icon, svg .osType {
            fill: ${({ theme }) => theme.colors.blue500};
        }
    }
`;
