import { useEffect, useState } from "react";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import EnrollSeriesBodyModal from "@/components/molecules/common/modal/bodyModal/EnrollSeriesBodyModal";
import EnrollSeriesExcelBody from "@/components/molecules/common/modal/bodyModal/EnrollSeriesExcelBodyModal";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import { seriesActions, getSeriesData, enrollSeries, excelUploadSeries } from "@/pages/settlement/basedata-manage/series/seriesSlice";
import { BaseModalProps } from "@/pages/common/types";
import ModalPortal from "@/components/molecules/common/modal//Portal";
import ExcelUploadSeriesModal from "@/components/molecules/common/modal/ExcelUploadSeriesModal";
import useLoading from "@/modules/hooks/useLoading";

export default function SeriesEnrollModal({ show, close, from }:SeriesEnrollProps) {
    let [seriesAddDatas, file] = useAppSelector((state:RootState) => [
        state.series.seriesAddDatas,
        state.file.file
    ]);
    const { loadingOn, loadingOff } = useLoading();
    const [showExcelUploadSeries, setShowExcelUploadSeries] = useState(false);
    function closeModal() {
        setShowExcelUploadSeries(false);
    }
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    useEffect(() => {
    }, []);

    async function seriesEnroll() {
        try {
            if (seriesAddDatas.length === 0) return await alert("warning", "주의", "적용 할 데이터가 없습니다.");
            let hasError = false;
            for (let i of seriesAddDatas) {
                let index = seriesAddDatas.indexOf(i);
                if (!i.name && !i.author && !i.ratioAll && !i.ratios[0].ratio ) continue;
                if (!i.name) {
                    dispatch(seriesActions.setEnrollSeriesDatas({
                        index: index, key: "seriesError", value: "시리즈명을 입력해주세요."
                    }))
                    // i.seriesError = "시리즈명을 입력해주세요.";
                    hasError = true;
                }
                if (!i.author.author_name) {
                    dispatch(seriesActions.setEnrollSeriesDatas({
                        index: index, key: "authorError", value: "저자명을 입력해주세요."
                    }))
                    hasError = true;
                }
                if (i.author.co_info.length > 1 && i.author.co_info.every(c => !c.coRatio)) {
                    dispatch(seriesActions.setEnrollSeriesDatas({
                        index: index, key: "authorError", value: "공저간 비율을 설정해주세요."
                    }))
                    hasError = true;
                }
                if (i.seriesError !== "" || i.authorError !== "" || i.ratioError !== "") {
                    hasError = true;
                }
                if (hasError) {
                    return await alert("warning", "주의", "유효하지 않은 항목이 있습니다.")
                } else {
                    loadingOn();
                    await dispatch(enrollSeries(seriesAddDatas)).unwrap();
                    await dispatch(getSeriesData({})).unwrap();
                    await alert("success", "저장 완료", "저장이 완료되었습니다.");
                    close();
                }
            }
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        } finally {
            loadingOff();
        }
    }

    async function excelUpload() {
        try {
            if (!file.file || !file.fileName) return await alert("warning", "주의", "파일을 업로드해주세요.");
            let answer = await alert("confirm", "업로드 확인", "업로드를 진행하시겠습니까?");
            if (answer === "Y") {
                let apiParams = {
                    file: file.file,
                    fileName: file.fileName
                }
                await dispatch(excelUploadSeries(apiParams)).unwrap();
                setShowExcelUploadSeries(true);
                // await dispatch(modalAction.pushModal({ name: "ExcelUploadSeries" }));
            }
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    function reset() {
        dispatch(seriesActions.resetEnrollSeries());
    }

    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["직접 입력", "엑셀 업로드"]}
                customStyle={[{ width: "480px", height: "360px" },
                            { width: "480px", height: "272px" }]}
                applyFunc={seriesEnroll}
                applyFuncTab={excelUpload}
                reset={reset}
            >
                <EnrollSeriesBodyModal
                    from={from}
                />
                <EnrollSeriesExcelBody />
            </BaseModal>
            <ModalPortal>
                <ExcelUploadSeriesModal
                    from={from}
                    show={showExcelUploadSeries}
                    close={closeModal}
                    seriesEnrollFunc={seriesEnroll}
                />
            </ModalPortal>
        </>
    );
}

interface SeriesEnrollProps extends BaseModalProps {

}