import styled from 'styled-components'
import Text from "@/components/atoms/text";
import theme from "@/assets/styles/theme";
import { useScrollFadeIn } from "@/modules/hooks/useScrollFadeIn";

export default function PortfolioTitle() {

    const fadeInTitle = useScrollFadeIn('up', 1, 0);

    const commonStyles = {
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "45px"
    }

    const defaultStyles = Object.assign({color:theme.colors.black}, commonStyles);
    const highlightStyles = Object.assign({color: theme.colors.blue500}, commonStyles);
    
    const texts: Text[] = [
        {
            text: '작가\u00a0',
            style: defaultStyles
        },
        {
            text: '포트폴리오\u00a0',
            style: highlightStyles
        },
        {
            text: 'E-BOOK제작',
            style: highlightStyles
        },
        {
            text: '까지',
            style: defaultStyles
        }
    ]

    return (
        <TextWrapper {...fadeInTitle}>
            {texts.map((textObj, idx) => 
                <Text key={idx} text={textObj.text} customStyle={textObj.style}/>)}
        </TextWrapper>
    )
}

const TextWrapper = styled.div`
    display: flex;
    // width: 590px;
    margin-bottom: 60px;
`

interface Text {
    text: string,
    style: {}
}