import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "@/pages/common/userSlice";
import styled, { css } from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

interface DropDownProp {
    id: number;
    menuName: string;
}
interface DropDownProps {
    menuItems: DropDownProp[];
    showMenu: boolean;
}

function DropDown({ menuItems, showMenu }: DropDownProps) {
    return (
        <Container showMenu={showMenu}>
            <div className="menu-wrap">
                {menuItems.map((item) => (
                    <NavLink to="/" key={item.id} className="menu-item">
                        {item.menuName}
                    </NavLink>
                ))}
            </div>
        </Container>
    );
}

export default DropDown;

export function MenuDropDown({ showMenu }: { showMenu: boolean }) {
    return (
        <MenuContainer showMenu={showMenu}>
            <div className="menu-wrap">{MenuItems()}</div>
        </MenuContainer>
    );
}

function MenuItems() {
    const { isPublisher } = useAppSelector((state: RootState) => state.user);

    return (
        <>
            <NavLink className="menu-item" to="settlement/sales-entry?type=Daily">
                판매입력
            </NavLink>
            <NavLink className="menu-item" to="settlement/sales-inquiry?type=Daily">
                판매 조회 / 변경
            </NavLink>
            <NavLink className="menu-item" to="settlement/basedata-manage?type=Author">
                기초데이터 관리
            </NavLink>
            <NavLink className="menu-item" to="settlement/total-sales?type=Deposit">
                입출금 종합
            </NavLink>
            <NavLink className="menu-item" to={`settlement/statement${isPublisher ? "?type=issue" : ""}`}>
                정산서
            </NavLink>
        </>
    );
}

export function UserDropDown({ showMenu }: { showMenu: boolean }) {
    return (
        <Container showMenu={showMenu}>
            <div className="menu-wrap">{ProfileMenuItems()}</div>
        </Container>
    );
}

function ProfileMenuItems() {
    // const dispatch = useDispatch();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    function signOut() {
        dispatch(logout());
        navigate("/");
    }
    return (
        <>
            <NavLink className="menu-item" to="/mypage">
                마이페이지
            </NavLink>
            {/* <NavLink className="menu-item" to="/support">
                문의하기
            </NavLink> */}
            <div className="menu-item" onClick={signOut}>
                로그아웃
            </div>
        </>
    );
}

const Container = styled.div<{ showMenu: boolean }>`
    display: ${({ showMenu }) => (showMenu ? "block" : "none")};
    position: absolute;
    top: 52px;
    width: 124px;
    border-radius: 10px;
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        box-shadow: 0px 10px 12px ${theme.colors.gray300};
    `};
    .menu-wrap {
        display: flex;
        flex-direction: column;
        .menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            padding: 8px 20px;
            font-size: 14px;
        }
    }
`;

const MenuContainer = styled.div<{ showMenu: boolean }>`
    display: ${({ showMenu }) => (showMenu ? "block" : "none")};
    position: absolute;
    width: 140px;
    top: 76px;
    right: 51%;
    border-radius: 10px;
    z-index: 999;
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        box-shadow: 0px 10px 12px ${theme.colors.gray300};
    `};
    .menu-wrap {
        display: flex;
        flex-direction: column;
        .menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            padding: 8px 20px;
            font-size: 14px;
        }
    }
`;
