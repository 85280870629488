import styled from "styled-components";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Text from "@/components/atoms/text";
import TextInput from "@/components/atoms/input/TextInput";
import SelectBox from "@/components/atoms/selectBox/index";
import { colors } from "@/assets/styles/theme";
import { ReactComponent as ArrowDropDown } from "@/assets/icons/arrow_drop_down.svg";
import useClickOutside from "@/modules/hooks/useClickOutside";

export default function IdWrap({
    value,
    email,
    errorMsg,
    onChange,
    onKeyPress,
    onChangeEmail,
}: {
    value: string;
    email: string;
    errorMsg: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyPress: Function;
    onChangeEmail: Function;
}) {
    useEffect(() => {}, [value, email]);
    const [input, setInput] = useState(false);
    const [domain, setDomain] = useState("");
    const [show, setShow] = useState(false);
    const listRef = useRef(null);
    const isOutsideClicked = useClickOutside({ ref: listRef });

    useEffect(() => {
        if (isOutsideClicked) setShow(false);
    }, [isOutsideClicked]);

    function changeSelect(value:any) {
        if (value.value === "직접입력") {
            setInput(true);
            setDomain("");
            onChangeEmail("");
        } else {
            onChangeEmail(value.value);
            setInput(false);
            setShow(false);
        }
    }

    function changeDomain(e: React.ChangeEvent<HTMLInputElement>) {
        setDomain(e.target.value)
        onChangeEmail(e.target.value);
    }

    function keyPress(e: any) {
        if (e.keyCode === 229) return;
        if (e.key === "Enter") {
            onKeyPress("Enter");
        }
    }

    const domainList = [
        { name: "naver.com", value: "naver.com" },
        { name: "hanmail.net", value: "hanmail.net" },
        { name: "daum.net", value: "daum.net" },
        { name: "nate.com", value: "nate.com" },
        { name: "gmail.com", value: "gmail.com" },
        { name: "hotmail.com", value: "hotmail.com" },
        { name: "empal.com", value: "empal.com" },
        { name: "yahoo.com", value: "yahoo.com" },
        { name: "paran.com", value: "paran.com" },
        { name: "dreamwiz.com", value: "dreamwiz.com" },
        { name: "직접입력", value: "직접입력" },
    ]

    return (
        <InputBox>
            <label htmlFor="emailID" style={{ color: errorMsg ? colors.error : value ? colors.blue500 : colors.gray600 }}>아이디(이메일)</label>
                <InputWrap>
                    <ValidateWrap data-error={errorMsg}>
                        <TextInput placeholder="이메일을 입력해주세요" value={value} onChangeHandler={onChange} onKeyDownHandler={(e) => keyPress(e)} customStyle={{ width: "200px", height: "40px", borderColor: errorMsg ? colors.error : value ? colors.blue500 : colors.gray300 }} />
                    </ValidateWrap>
                    <Text text="@" customStyle={{ fontSize: "16px", fontWeight: "400", lineHeight: "28px", margin: "8px", width: "40px", textAlign: "center" }} />
                    { input ?
                    <InputSelectBoxWrap ref={listRef}>
                        <InputSelectBox>
                            <TextInput placeholder="직접입력" value={domain} onChangeHandler={(e) => changeDomain(e)} onKeyDownHandler={(e) => keyPress(e)} customStyle={{ width: "160px", height: "40px", borderColor: colors.gray300 }} />
                            <ul style={{ display: show ? "block" : "none"}}>
                                {domainList.slice(0, -1).map((o, i) => (
                                    <li key={i} onClick={() => {changeSelect(o)}}>
                                        {o.name}
                                    </li>
                                ))}
                            </ul>
                        </InputSelectBox>
                        <IconWrap onClick={() => setShow(!show)}>
                            <TransformArrow active={show}>
                                <ArrowDropDown />
                            </TransformArrow>
                        </IconWrap>    
                    </InputSelectBoxWrap>
                    :
                    <SelectBox
                        labelKey="name"
                        valueKey="value"
                        selected={email}
                        options={domainList}
                        customStyle={{ height: "40px", width: "160px" }}
                        onClickValueFunc={changeSelect}
                    />}
                </InputWrap>
        </InputBox>
    );
}

const ValidateWrap = styled.div`
    position: relative;
    :after {
        font-size: 10px;
        color: ${({ theme }) => theme.colors.error};
        position: absolute;
        left: 0px;
        bottom: -14px;
        white-space: nowrap;
        content: attr(data-error);
    }
`

const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    label {
        font-size: 14px;
        font-weight: 500;
    }
    input {
        border-radius: 4px;
        font-weight: 400;
        font-size: 12px;
        padding: 8px;
    }
`;

const InputWrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 24px;
`

const InputSelectBoxWrap = styled.div`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: 160px;
    height: 40px;
`;

const IconWrap = styled.div<{ height?: string }>`
    position: absolute;
    top: 8px;
    right: 2.5px;
    cursor: pointer;
`;

const TransformArrow = styled.div<{active?: boolean}>`
    width: 25px;
    height: 25px;
    margin-left: 5px;
    transition: transform .3s;
    transform: ${({ active }) => active ? "rotate(180deg)" : ""};
`

interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    color?: string;
    cursor?: string;

    disabled?: boolean;
}

const InputSelectBox = styled.div<StyleProps>`
    width: 160px;
    height: 40px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.white};

    ul {
        position: absolute;
        top: 40px;
        left: 0;
        box-shadow: 0px 6px 12px rgba(112, 144, 176, 0.2);
        z-index: 999;
        border: 1px solid ${({ theme }) => theme.colors.gray300};
        width: 160px;
        max-height: 160px;
        overflow-y: auto;
    }

    li {
        display: flex;
        align-items: center;
        padding: 8px;
        height: 40px;
        font-size: 12px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.black};
        background: ${({ theme }) => theme.colors.white};
        :hover {
            cursor: ${({ cursor }) => (cursor ? "default" : "pointer")};
            background: ${({ theme }) => theme.colors.blue50};
        }
    }
`;