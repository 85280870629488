import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import { useAppDispatch } from '@/modules/store';
import Text from '@/components/atoms/text';
import { colors } from '@/assets/styles/theme';
import { InquiryDetail, deleteInquiry } from '@/pages/support/inquiry/slice'
import useLoading from '@/modules/hooks/useLoading'
import useAlert from '@/modules/hooks/useAlert';
import ReactHtmlParser from 'html-react-parser';

interface Iprops {
    inquiryDetail : InquiryDetail,
    id?: string,
    auth: string | null
}

const isOverTen = (value: number) => {
    if (value < 10) {
        return `0${value}`
    } else return value;
}

export default function InquiryPost({inquiryDetail, id, auth}: Iprops) {
    const { loadingOn, loadingOff } = useLoading()
    const { alert } = useAlert();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const date = new Date(inquiryDetail.created_at);
    const filteredDate = `${date.getFullYear()}-${isOverTen((date.getMonth()+1))}-${isOverTen(date.getDate())}`;

    const goBack = () => {
        navigate(-1);
    }

    const deleteHandler = async (id?: string) => {
        try {
            let answer = await alert("confirm", "삭제 확인", "게시글을 삭제 하시겠습니까?");
            if (answer === "Y") {
                loadingOn();
                let apiParams = {
                    id,
                    class: '문의게시판'
                };
                await dispatch(deleteInquiry(apiParams)).unwrap();
                await alert("success", "삭제 완료", "삭제가 완료되었습니다.");
                goBack();
            }
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const categoryStyle = {
        fontSize: "16px",
        fontWeight: "500",
        color: colors.blue500
    };

    const publisherStyle = {
        fontSize: "16px",
        fontWeight: "500",
        color: colors.black
    };

    const titleStyle = {
        fontSize: "24px",
        fontWeight: "700",
        color: colors.black
    };

    const nickNameStyle = {
        fontSize: "16px",
        fontWeight: "400",
        color: colors.gray600
    };

    return (
        <StyledContainer>
            <StyledCategory>
                <Text text={inquiryDetail.category} customStyle={categoryStyle}/>
                <Text text={inquiryDetail.publisher_name} customStyle={publisherStyle}/>
            </StyledCategory>
            <StyledTitle>
                <Text text={inquiryDetail.title} customStyle={titleStyle}/>
                <UpdateWrapper auth={auth}>
                    <ModifyWrapper onClick={()=> {navigate(`/support/inquiry/write?type=update&id=${id}`)}}>
                        수정
                    </ModifyWrapper>
                    <DeleteWrapper onClick={()=> deleteHandler(id)}>
                        삭제
                    </DeleteWrapper>
                </UpdateWrapper>
            </StyledTitle>
            <StyledNickName>
                <Text text={inquiryDetail.user_nickname} customStyle={nickNameStyle}/>
                <Text text={filteredDate} customStyle={nickNameStyle}/>
            </StyledNickName>
            <StyledContent>
                <pre> {ReactHtmlParser(inquiryDetail.content)}</pre>
            </StyledContent>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledCategory = styled.div`
    display: flex;
    gap: 16px;
`;

const StyledTitle = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
`;

const UpdateWrapper = styled.div<{auth: string | null}>`
    display: ${({ auth }) => auth === 'q' ? 'flex' : 'none'};
    gap: 8px;
`;

const ModifyWrapper = styled.div`
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
`;

const DeleteWrapper = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.error};
    cursor: pointer;
`

const StyledNickName = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
`;

const StyledContent = styled.div`
    margin: 24px 0px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.gray600};
    min-height: 240px;
    padding: 24px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
    pre {
        white-space: pre-line;
        word-break: break-all;
    }
`;
