import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import { addComma, removeComma } from "@/modules/utils/filter";
import { ratio } from "@/modules/utils/validate_modify";
import {
    setItems,
    setSummary,
    setAddedSeriesRatioList,
    checkAlreayHasData,
    setItem,
} from "@/pages/settlement/sales-entry/monthly/monthlySlice";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

// 월 정산 계산에 필요한 함수들 모음
const useUtils = () => {
    const { items, platformId, addedSeriesRatioList, afterExcelUpload } = useSelectorTyped(
        (state: RootState) => state.entryMonthly,
    );
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    // 해당 플랫폼, 해당 정산월에 이미 데이터가 존재하는지 체크
    const checkHasData = async (yearmonSt: string) => {
        try {
            loadingOn();
            const apiParams = {
                platformId,
                yearmonSt,
            };
            const { data } = await dispatch(checkAlreayHasData(apiParams)).unwrap();

            if (data.count > 0) return true;
            else return false;
        } catch (err) {
            await alert("error", "오류 발생", "업로드 중 오류가 발생하였습니다.");
        } finally {
            loadingOff();
        }
    };

    // 작가정산금 계산
    const calcAmountAu = (item: any) => {
        // 작가정산금액 = (출판사정산금*작가정산비율)/100;
        if (item.amount_pu === "" || item.author_ratio === null) return;
        const amount_pu = Number(removeComma(item.amount_pu));
        const author_ratio = Number(item.author_ratio);
        const amount_au = (amount_pu * author_ratio) / 100;
        item.amount_au = addComma(Math.round(amount_au));
    };

    // 판매금 합계
    const sumAmountPl = (amount: number | string) => {
        if (!afterExcelUpload || amount === 0 || amount === "0") return;
        let totalAmountPl = 0;
        items.forEach((i) => {
            totalAmountPl += Number(removeComma(i.amount_pl)) || 0;
        });

        dispatch(
            setSummary({
                total_amt_pl: Number(totalAmountPl.toFixed(2)),
            }),
        );
    };

    // 출판사정산금 합계
    const sumAmountPu = (amount: number | string) => {
        if (!afterExcelUpload || amount === 0 || amount === "0") return;
        let totalAmountPu = 0;
        items.forEach((i) => {
            totalAmountPu += Number(removeComma(i.amount_pu)) || 0;
        });
        dispatch(
            setSummary({
                total_amt_pu: Number(totalAmountPu.toFixed(2)),
            }),
        );
    };

    const addSeriesRatio = (data: any) => {
        
        let newRatioList = [...addedSeriesRatioList];
        for (let i = 0; i < newRatioList.length; i++) {
            if (newRatioList[i].series_id === data.series_id) {
                newRatioList[i] = { ...newRatioList[i], ratio: data.ratio };
                dispatch(setAddedSeriesRatioList(newRatioList));
                return;
            }
        }
        dispatch(setAddedSeriesRatioList([...addedSeriesRatioList, data]));
    };

    // 작가 정산비율 검증
    const validateAuthorRatio = (item: any, index: number) => {
        if (item.author_ratio === null) item.author_ratio = "";
        const res = ratio({
            field: "작가 정산비율",
            value: item["author_ratio"],
            error: "authorRatioError",
            mandatory: true,
            decimal: true,
        });

        let newItem = {...item};
        newItem.author_ratio = res.value;
        newItem.authorRatioError = res.authorRatioError;

        // 작가 정산금 추가
        calcAmountAu(newItem);
        dispatch(setItem({ index, item:newItem }));

        if (afterExcelUpload) {
            // 같은 isbn 가진 작품들 시리즈도 변경
            let newItems = items.map((item) => {
                if (item.series_id === newItem.series_id) {
                    const res = ratio({
                        field: "작가 정산비율",
                        value: newItem.author_ratio,
                        error: "authorRatioError",
                        mandatory: true,
                        decimal: true,
                    });
                    let tmpItem = {...item};

                    tmpItem.author_ratio = res.value;
                    tmpItem.authorRatioError = res.authorRatioError;
                    calcAmountAu(tmpItem);
                    return tmpItem;
                }
                return item;
            });
            dispatch(setItems(newItems));
        }

        addSeriesRatio({
            ratio: item.author_ratio,
            series_id: item.series_id,
            platform_id: platformId,
        });
    };

    return {
        checkHasData,
        calcAmountAu,
        sumAmountPl,
        sumAmountPu,
        addSeriesRatio,
        validateAuthorRatio,
    };
};
export default useUtils;
