import styled from "styled-components"
import { ReactComponent as Logo } from "@/assets/icons/mob-landing/gling-logo.svg";
import TopBanner from "@/components/templates/mob-landing/TopBanner";
import CellPhoneSection from "@/components/templates/mob-landing/CellPhoneSection";
import PubSection from "@/components/templates/mob-landing/PubSection";
import SettlementSection from "@/components/templates/mob-landing/SettlementSection";
import ServiceSection from "@/components/templates/mob-landing/ServiceSection";
import BottomBanner from "@/components/templates/mob-landing/BottomBanner";

export default function MobLanding() {
    return (
        <Container>
            <Logo width="58" height="24" />
            <TopBanner />
            <CellPhoneSection />
            <PubSection />
            <SettlementSection />
            <ServiceSection />
            <BottomBanner />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: black;
`
