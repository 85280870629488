import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import Text from "@/components/atoms/text";
import NavBar from "@/components/molecules/statement/publisher/common/NavBar";
import Issue from "@/components/templates/statement/publisher/Issue";
import Inquiry from "@/components/templates/statement/publisher/Inquiry";
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { StatementType } from "@/pages/settlement/statement/publisher/types";
import { useAppDispatch } from "@/modules/store";
import useCheckToken from "@/modules/hooks/useCheckToken";

export default function PubStatement() {
    const [searchParams] = useSearchParams();
    const [template, setTemplate] = useState(<></>);
    const dispatch = useAppDispatch();
    const tokenStatus = useCheckToken();

    useEffect(() => {
        dispatch({ type: "pubStatement/setEmptyData" });
        switch (searchParams.get("type")) {
            case StatementType.Issue:
                setTemplate(<Issue />);
                break;
            case StatementType.Inquiry:
                setTemplate(<Inquiry />);
                break;
            default:
                setTemplate(<Issue />);
                break;
        }

        return () => {
            dispatch({ type: "pubStatement/initState" });
        }
    }, [searchParams, dispatch])

    if (!tokenStatus) {
        return <></>
    };
    
    const navBarData = [
        { label: "정산서 발급", type: StatementType.Issue },
        { label: "정산서 조회", type: StatementType.Inquiry}
    ]

    return (
        <SectionLayout>
            <Text text="정산서" customStyle={{ fontSize: "20px", fontWeight: "700" }} />
            <NavBar 
                data={navBarData}
                selected={searchParams.get("type")}
            />
            {template}
        </SectionLayout>
    );
}


