import { useEffect } from "react";

// reducers
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getEtcData, setNoData } from "@/pages/settlement/saels-inquiry/publisher/etc/etcSlice";

// components
import TemplateLayout from "@/components/molecules/sales-inquiry/common/TemplateLayout";
import EtcSearchFilter from "@/components/molecules/sales-inquiry/publisher/etc/EtcSearchFilter";
import EtcTableTop from "@/components/molecules/sales-inquiry/publisher/etc/EtcTableTop";
import EtcTable from "@/components/molecules/sales-inquiry/publisher/etc/EtcTable";

// custom hooks
import useEtcApi from "./hooks/useEtcApi";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function Etc() {
    const { selectedPlatform, initialDataLoaded, changedParamsId } = useSelectorTyped((state: RootState) => state.inquiryEtc);
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    useEtcApi();

    useEffect(() => {
        getData();
    }, [changedParamsId]);

    const getData = async () => {
        try {
            if (initialDataLoaded && !selectedPlatform.length) {
                return dispatch(setNoData());
            }

            loadingOn();
            await dispatch(getEtcData()).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    return (
        <TemplateLayout>
            <EtcSearchFilter />
            <EtcTableTop />
            <EtcTable />
        </TemplateLayout>
    );
}
