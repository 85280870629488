import styled from "styled-components";
import PercentInputText from "@/components/molecules/basedata-manage/PercentInputText";
import Text from "@/components/atoms/text";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { ratio, Validation } from "@/modules/utils/validate_modify";

export default function SetCoRatioModalBody({ coInfo, changeCoInfoFunc }:SetCoRatioBodyProps) {
    const changeValue = (e: React.ChangeEvent<HTMLInputElement>, item: any, index: number) => {
        const { value, ratioError } = ratio({value: e.target.value, error: "ratioError", field: "정산 비율", decimal: true, mandatory: true}) as Validation;
        let temp = JSON.parse(JSON.stringify(coInfo));
        temp[index] = {...item, "coRatio": value, "coRatioError": ratioError };
        changeCoInfoFunc(temp);
    }

    return (
        <BodyWrap>
            <FieldRow>
                <ColumnName width="100px">
                    <Text text="본명" customStyle={{ fontSize: "12px" }}/>
                </ColumnName>
                <ColumnName justifyContent="center">
                    <Text text="공저간 정산 비율" customStyle={{ fontSize: "12px", textAlign: "center" }}/>
                </ColumnName>
            </FieldRow>
            {coInfo.map((c:any, i:number) => 
                <ItemRow key={i}>
                    <Item width="100px">
                        <Text
                            text={c.realName}
                            customStyle={{ fontSize: "14px", fontWeight: "400"}}
                        />
                    </Item>
                    <Item justifyContent="center">
                        <ValidateWrapper dataError={c.coRatioError}>
                            <PercentInputText
                                type="number"
                                inText="%"
                                value={c.coRatio}
                                placeholder="정산 비율"
                                customStyle={{ width: "80px", height: "32px", fontSize: "12px", fontWeight: "400", padding: "0 18px 0 0"}}
                                onChange={(e) => changeValue(e, c, i)}
                            />
                        </ValidateWrapper>
                    </Item>
                    
                </ItemRow>
                )
            }
        </BodyWrap>
    )

}

interface SetCoRatioBodyProps {
    coInfo: any;
    changeCoInfoFunc: Function;
}


const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 228px;
    max-height: 228px;
    overflow: auto;

`
const ItemRow = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`

const Item = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "220px"};
    height: 60px;
    font-size: 12px;
    padding: 8px;
`

const FieldRow = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`

const ColumnName = styled.div<{ width?: string, justifyContent?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "220px"};
    height: 48px;
    font-size: 12px;
    padding: 8px;
`