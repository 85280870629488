import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// assets
import { colors } from "@/assets/styles/theme";
import { ReactComponent as UpArrowIC } from "@/assets/icons/up_arrow.svg";

// components
import TextInput from "@/components/atoms/input/TextInput";
import Checkbox from "@/components/atoms/input/Checkbox";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";

// hooks
import useHeadlessTable from "@/components/molecules/common/table/useHeadlessTable";

// utils
import { addComma, removeComma } from "@/modules/utils/filter";
import { integer, amount } from "@/modules/utils/validate_modify";
import usePlatformSetting from "@/components/molecules/sales-inquiry/publisher/hooks/usePlatformSetting";

// type
import type { Item } from "@/components/molecules/common/table/DataTable";
import type { SortDirType } from "@/components/molecules/common/table/hooks/useDataTableSort";

export default function DailyDetailModalBody({ items, onAddSelectedItems, onAddUpdatedItems }:ModalBodyProps) {
    const { selectedKeys, selectItem, checkSelected, checkSelectedAll, selectAll,
            sortedItems, sortBy, sortDir, sorting } = useHeadlessTable({ itemKey: "id", items });
    const [tableItems, setTableItems] = useState<{[key:string]: any}[]>(items); // 저장하지 않고 나가면 원상태로 돌아가야해서 별도 스테이트 추가
    const updatedItems = useRef<Item[]>([]);
    const { platformNoQty } = usePlatformSetting();

    useEffect(() => {
        // selectedKeys만 필요한 일반 테이블 컴포넌트와 함께 쓰고있어서 keys로 selectedItem 따로 추출
        const selectedItems = tableItems.filter(item => selectedKeys.includes(item.id));
        onAddSelectedItems(selectedItems);
    }, [selectedKeys]);

    useEffect(() => {
        sortedItems.length && setTableItems(sortedItems);
    }, [sortedItems])
    

    const changeQuantity = (index:number, value:string) => {
        const res = integer({
            value,
            error: "quantityError",
            field: "판매량",
            min: -9999999,
            max: 9999999,
            mandatory: true,
        });

        setTableItems(prev => prev.map((item,idx) => idx === index 
            ? { ...item, 'quantity': res.value, quantityError: res.quantityError } 
            : item
        ))
    }

    const changeAmount = (index:number, value:string) => {
        const res = amount({ value, error: "amountError", field: "판매금액", decimal: true, mandatory: true });

        setTableItems(prev => prev.map((item,idx) => idx === index 
            ? { ...item, 'amount': res.value, amountError: res.amountError } 
            : item
        ))
    }

    const addUpdatedItems = (newItem:Item) => {
        const hasAlready = updatedItems.current.findIndex(item => item.id === newItem.id) !== -1;
        
        if (hasAlready) {
            updatedItems.current = updatedItems.current.map(item => item.id === newItem.id ? newItem : item);
        } else {
            updatedItems.current.push(newItem);
        }
        
        onAddUpdatedItems(updatedItems.current);
    }

    const handleComma = (index:number, item:Item, type:'add' | 'remove') => {
        const newItem = { ...item, 'amount': type === 'add' ? addComma(item.amount) : removeComma(item.amount) };
        setTableItems(prev => prev.map((item,idx) => idx === index ? newItem : item));
    }

    return (
        <Container>
            <Table>
                <THead>
                    <Tr>
                        <Th width="16%" textAlign="center">
                            <Checkbox 
                                type="boolean" 
                                active={checkSelectedAll()} 
                                onChangeHandler={selectAll} 
                            />
                        </Th>
                        {headers.map(h => (
                            <Th
                                key={h.text} 
                                width={h.width}
                                onClick={() => sorting(h.value, true)}
                            >
                                {h.text}
                                <ICWrapper sortDir={sortBy === h.value ? sortDir : "none"}>
                                    <UpArrowIC fill={colors.red600}/>
                                </ICWrapper>
                            </Th>
                            )) 
                        }
                    </Tr>
                </THead>
                <TBody>
                    {tableItems.map((item, index) => (
                        <Tr key={item.id} active={checkSelected(item["id"])}>
                            <Td textAlign="center" noPadding>
                                <Checkbox
                                    type="object"
                                    selected={selectedKeys}
                                    valueName={"id"}
                                    value={item["id"]}
                                    onChangeHandler={() => selectItem(item)}
                                />
                            </Td>
                            <Td>{item.book_name}</Td>
                            <Td>
                            {platformNoQty.find(p => item.platform_id === p) ?
                                <TextInput
                                    type="readOnly"
                                    value={"정보없음"}
                                    onChangeHandler={() => {}}
                                    customStyle={{ width:'100%', height: '32px', textAlign:'right' }}
                                />
                            :
                                <ValidateWrapper dataError={item.quantityError}>
                                    <TextInput
                                        value={item.quantity}
                                        customStyle={{ width:'100%', height: '32px', textAlign:'right' }}
                                        onChangeHandler={(e) => changeQuantity(index, e.target.value)}
                                        onBlurHandler={() => addUpdatedItems(item)}
                                    />
                                </ValidateWrapper>
                            }
                            </Td>
                            <Td>
                                <TextInput
                                    value={item.amount}
                                    customStyle={{ width:'100%', height: '32px', textAlign:'right' }}
                                    onChangeHandler={(e) => changeAmount(index, e.target.value)}
                                    onFocusHandler={() => handleComma(index, item, "remove")}
                                    onBlurHandler={() => {
                                        addUpdatedItems(item);
                                        handleComma(index, item, "add");                                        
                                    }}
                                />
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>
        </Container>
    )
}

const headers = [
    { text: "시리즈명", value: "book_name", width: "44%" },
    { text: "판매량", value: "quantity", width: "20%" },
    { text: "판매금액(원)", value: "amount", width: "20%" },
];

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    height: calc(100% - 88px);
    overflow: auto;
`
const Table = styled.table`
    position: relative;
    width: 100%;
    font-size: 12px;
`
const THead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
    background-color: ${({ theme }) => theme.colors.white};
`
const TBody = styled.tbody`
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
`;
const Tr = styled.tr<{ active?: boolean }>`
    background-color: ${({ active, theme }) => active && theme.colors.blue50 };
`
const Th = styled.th<{ width:string; textAlign?: string }>`
    width: ${({ width }) => width || '200px'};
    height: 48px;
    padding: 0px 20px;
    vertical-align: middle;
    text-align: ${({ textAlign }) => textAlign || 'left'};
    font-weight: 500;
`
const Td = styled.td<{ textAlign?: string; noPadding?: boolean }>`
    height: 60px;
    vertical-align: middle;
    text-align: ${({ textAlign }) => textAlign || 'left'};
    padding: ${({ noPadding }) => noPadding ? 0 : `0 20px`};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`
const ICWrapper = styled.div<{ sortDir: SortDirType }>`
    display: inline-block;
    margin-left: 4px;
    opacity: ${({ sortDir }) => (sortDir === "none" ? 0 : 1)};
    transform: ${({ sortDir }) => (sortDir === "desc" ? "rotate(180deg)" : "none")};
    transition: all ease-in-out 0.3s;
`;

interface ModalBodyProps {
    items : Item[];
    onAddSelectedItems: (item:Item[]) => void; 
    onAddUpdatedItems: (item:Item[]) => void;
}