import styled from "styled-components";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import { colors } from "@/assets/styles/theme";
import { dateFormat } from "@/modules/utils/filter";

export default function PromotionDetailModal({ show, close, from, promotionInfo }: ComponentProps) {
    let start_at = "", end_at = "", platform_icon = "";
    if (Object.keys(promotionInfo).length) {
        platform_icon = promotionInfo.icon_path;
        start_at = dateFormat("dot", promotionInfo.promotion_start_at);
        end_at = dateFormat("dot", promotionInfo.promotion_end_at);
    }

    const reset = () => {
        start_at = "";
        end_at = "";
        platform_icon = "";
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={[promotionInfo.promotion_name]}
            customStyle={[{ width: "480px", height: "auto" }]}
            reset={reset}
            footer={false}
        >
            <BodyWrapper>
                <RowWrapper>
                    <FieldNameWrapper>플랫폼</FieldNameWrapper>
                    <FieldContentWrapper>
                        <Img src={platform_icon} />
                        {promotionInfo.platform_name}
                    </FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>프로모션 설명</FieldNameWrapper>
                </RowWrapper>
                <DescWrapper>
                    {promotionInfo.promotion_desc}
                </DescWrapper>
                <RowWrapper>
                    <FieldNameWrapper>기간</FieldNameWrapper>
                    <FieldContentWrapper>
                        {start_at} ~ {end_at}
                    </FieldContentWrapper>
                </RowWrapper>
            </BodyWrapper>
        </BaseModal>
    )
}

const BodyWrapper = styled.div`
    padding-bottom: 16px;
`

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 6px 20px;
`

const FieldNameWrapper = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.gray600};
`

const FieldContentWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
`

const DescWrapper = styled.div`
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24.5px;
    background-color: ${colors.gray50};
    white-space: pre-wrap;
`

const Img = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 8px;
`

interface ComponentProps {
    show: boolean;
    close: () => void;
    from: string;
    promotionInfo: any;
}