import styled from "styled-components";
import { RootState } from "@/modules/store";
import { useAppSelector, useAppDispatch } from "@/modules/store";
import Button from "@/components/atoms/button";
import theme from "@/assets/styles/theme";

function ChartTypeBtnWrapper() {
    const { analysisType } = useAppSelector((state: RootState) => state.pubDashboard);
    const dispatch = useAppDispatch();

    const inactiveBtnStyle = {
        width: "60px",
        height: "30px",
        borderRadius: "unset",
        fontSize: "10px",
    }

    const activeBtnStyle = {
        ...inactiveBtnStyle,
        fontWeight: "500",
        color: theme.colors.black
    }

    const changeType = (type: string) => {
        dispatch({ type: "pubDashboard/setAnalysisType", payload: type })
    }

    return (
        <StyledBtnWrapper>
            <Button 
                type={analysisType === "daily" ? "main-fill" : "gray-outline"}
                label="일별" 
                onClickHandler={() => changeType("daily")}
                customStyle={analysisType === "daily" ? activeBtnStyle : inactiveBtnStyle} 
            />
            <Button 
                type={analysisType === "monthly" ? "main-fill" : "gray-outline"}
                label="월별" 
                onClickHandler={() => changeType("monthly")}
                customStyle={analysisType === "monthly" ? activeBtnStyle : inactiveBtnStyle} 
            />
        </StyledBtnWrapper>
    )
}

const StyledBtnWrapper = styled.div`
    display: flex;
`

export default ChartTypeBtnWrapper;