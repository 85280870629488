import styled from "styled-components";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import useAlert from "@/modules/hooks/useAlert";
import UploadContainer from "@/components/molecules/common/upload";
import { useAppDispatch, useAppSelector, RootState } from "@/modules/store";
import { FileType } from "@/pages/settlement/statement/publisher/types"
import { useEffect, useState } from "react";
import Text from "@/components/atoms/text";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { colors } from "@/assets/styles/theme";

export default function UploadStampModal({ show, close }: any) {
    const { alert } = useAlert();
    const { stampFiles } = useAppSelector((state: RootState) => state.pubStatement);
    const [tmpStamp, setTmpStamp] = useState<FileType[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setTmpStamp([]);
    }, [stampFiles])

    const reset = () => {
        setTmpStamp([]);
    }   

    const apply = async () => {
        const answer = await alert("confirm", "적용 확인", "적용 하시겠습니까?");
        if (answer === "Y") {
            dispatch({ type: "pubStatement/setStampFiles", payload: tmpStamp });
            close();
        }
    }

    const stampUpload = (fileList: FileType[]) => {
        setTmpStamp(fileList);
    }

    const deleteStamp = () => {
        setTmpStamp([]);
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["인감 업로드"]}
            customStyle={[{ width: "480px", height: "240px" }]}
            applyFunc={() => {apply();}}
            reset={reset}
        >
           <ModalBodyWrapper>
                {tmpStamp.length ?
                    <FileNameConainer>
                        <FieldNameWrapper>파일명</FieldNameWrapper>
                        <FileNameWrapper>
                            <Text text={tmpStamp[0].name} customStyle={{ fontSize: "12px", fontWeight: "400" }} />
                            <Clear fill={colors.gray600} width={24} height={24} onClick={deleteStamp} />
                        </FileNameWrapper>
                    </FileNameConainer> : 
                    <UploadContainer 
                        accept={["jpg", "png"]}
                        uploadHandler={stampUpload}
                    />
                }
           </ModalBodyWrapper>
        </BaseModal>
    )
}

const ModalBodyWrapper = styled.div`
    padding: 16px 20px;
    height: 152px;
`

const FileNameConainer = styled.div`
    width: 100%;
`

const FieldNameWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`

const FileNameWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
    svg {
        cursor: pointer;
    }
`
