import React, { useEffect, useState, ReactElement } from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import { Transition, TransitionStatus } from "react-transition-group";

// components
import { ReactComponent as RightIcon } from "@/assets/icons/right.svg";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import Text from "@/components/atoms/text";

// types
import { WithdrawItems } from "@/pages/settlement/total-sales/types";

// reducer
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";

// hooks
import usePagination from "@/components/molecules/common/pagination/usePagination";

// utils
import { round } from "@/modules/utils/filter";

export default function Withdrawtable() {
    let { withdrawItems, selectedPlatform, onlyUser, searchUser } = useSelectorTyped((state: RootState) => state.totalSales);
    const { setDataCount, setPage, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "Withdraw" });
    const [filterData, setFilterData] = useState<WithdrawItems[]>([])
    const [sum, setSum] = useState({
        amount_pu: 0,
        mg_deduction: 0,
        amount_au: 0,
        mg_add: 0,
        etc: 0,
        tax: 0,
        total: 0
    });
    const [expandedIds, setExpandedIds] = useState<number[]>([]);

    useEffect(() => {
        filterAuthor();
        setPage(1);
    }, [selectedPlatform, withdrawItems, searchUser, onlyUser])

    const expandedClickHandler = (id: number) => {
        let index = ((page - 1) * itemsPerPage) + id;
        setExpandedIds((prev) => (prev.indexOf(index) < 0 ? [...prev, index] : prev.filter((p) => p !== index)));
    }

    const showExpanded = (id: number) => {
        let index = ((page - 1) * itemsPerPage) + id;
        return expandedIds.findIndex((e) => e === index) !== -1;
    }

    async function filterAuthor() {
        let filter:any[];
        if (onlyUser) {
            filter = withdrawItems.filter(
                (i:any) => (i.real_name.includes(searchUser) || i.author_name.join().includes(searchUser)) && i.user_id != null
            );
        } else {
            filter = withdrawItems.filter(
                (i:any) => (i.real_name.includes(searchUser) || i.author_name.join().includes(searchUser))
            )
        }

        for (let i of filter) {
            let index = filter.indexOf(i);
            filter[index] = {...i, "author_name": [...new Set(i.author_name)], "detailSales": i.detailSales.filter((d:any) => selectedPlatform.concat([99]).includes(d.platform_id))};
        }

        let sumData = {
            amount_pu: 0,
            mg_deduction: 0,
            amount_au: 0,
            mg_add: 0,
            etc: 0,
            tax: 0,
            total: 0
        }

        for (let i of filter) {
            let index = filter.indexOf(i);
            let amountAu = i.detailSales.map((item:any) => item.amountAu).reduce((prev:any, curr:any) => prev + curr, 0);
            let etc = i.detailSales.map((item:any) => item.etc).reduce((prev:any, curr:any) => prev + curr, 0);
            let mgAdd = i.detailSales.map((item:any) => item.mgAdd).reduce((prev:any, curr:any) => prev + curr, 0);
            let addTax = i.detailSales.map((item:any) => item.tax).reduce((prev:any, curr:any) => prev + curr, 0);
            let addTotal = i.detailSales.map((item:any) => item.total).reduce((prev:any, curr:any) => prev + curr, 0);

            for (let d of i.detailSales) {
                sumData.amount_pu += d.amountPu ? d.amountPu : 0;
                sumData.mg_deduction += d.mgDeduction ? d.mgDeduction : 0;
                sumData.amount_au += d.amountAu ? d.amountAu : 0;
                sumData.mg_add += d.mgAdd ? d.mgAdd : 0;
                sumData.etc += d.etc ? d.etc : 0;
            }
            // 판매정산금, 기타지급금 세금 계산
            let taxRatio = i.tax_type === "D" ? 0.03 : i.tax_ratio / 100;
            let tmpSubTotal = amountAu + etc;

            if (tmpSubTotal > -1 && tmpSubTotal < 1) {
                tmpSubTotal = 0;
            }
            
            let subTotal = Math.ceil(tmpSubTotal);

            // 소득세
            let tmpTax = Math.floor((subTotal * taxRatio) / 10) * 10;
            let tax = tmpTax <= 1000 ? 0 : tmpTax;

            // 지방세
            let localTax = i.tax_type === "D" ? Math.floor(tax / 10 / 10) * 10 : 0;

            // 선인세 지급금 세금 계산
            // 소득세
            let tmpMgAddTax = Math.floor((mgAdd * taxRatio) / 10) * 10;
            let mgAddTax = tmpMgAddTax <= 1000 ? 0: tmpMgAddTax;
            // 지방세
            let localMgAddTax = i.tax_type === "D" ? Math.floor(mgAddTax / 10 / 10) * 10 : 0;

            // 세금 합계
            addTax = tax + localTax + mgAddTax + localMgAddTax;
            let total = subTotal + mgAdd - addTax;
            // 기타지급금이 마이너스인 경우 지급총계가 마이너스가 될 수도 있어서 우선 마이너스도 보이게 함.
            addTotal = total ? total : 0;
            filter[index] = {...filter[index], "tax": addTax, "total": addTotal};

            // 총 합계의 세금, 지급총계
            sumData.tax += addTax ? addTax : 0;
            sumData.total += addTotal ? addTotal : 0;
        }

        let result = filter.filter(f => f.detailSales.length);
        setSum(sumData);
        setFilterData(result);
        setDataCount(result.length);
    }

    return (
        <TableContainer>
            <Table>
                <Header>
                    <HeaderTr>
                        {headers.map((h, i) => (
                            <Th
                                key={i}
                                width={h.customStyle?.width}
                                textAlign={i === 1 ? "left" : "right"}
                            >
                                {h.text}
                            </Th>
                        ))}
                    </HeaderTr>
                </Header>
                <Body>
                    <Tr backGroundcolor={true}>
                        <Td width="80px"/>
                        <Td width="140px">
                            <Text text={"총 합계"} customStyle={{ fontSize: "12px", lineHeight: "12px" }}/>
                        </Td>
                        <Td width="140px">
                            <Text text={comma(sum.amount_pu)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/>
                        </Td>
                        <Td width="140px">
                            <Text text={comma(sum.mg_deduction)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", color: colors.error, textAlign: "right" }}/>
                        </Td>
                        <Td width="140px">
                            <Text text={comma(sum.amount_au)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/>
                        </Td>
                        <Td width="140px">
                            <Text text={comma(sum.mg_add)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/>
                        </Td>
                        <Td width="140px">
                            <Text text={comma(sum.etc)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/>
                        </Td>
                        <Td width="140px">
                            <Text text={comma(sum.tax)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.blue500, textAlign: "right" }}/>
                        </Td>
                        <Td width="140px">
                            <Text text={comma(sum.total)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.blue500, textAlign: "right" }}/>
                        </Td>
                    </Tr>
                    {filterData &&
                    filterData.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((d, i) => (
                        <React.Fragment key={i}>
                            <Tr onClick={() => expandedClickHandler(i)}>
                                <Td width="80px">
                                    <IconWrapper>
                                        <Arrow active={expandedIds.findIndex((e) => e === ((page-1)*itemsPerPage)+i) !== -1}>
                                            <RightIcon fill={showExpanded(i) ? colors.blue500 : colors.black}/>
                                        </Arrow>
                                    </IconWrapper>
                                </Td>
                                <Td width="140px">
                                    <AuthorName name={d.user_id ? `${d.real_name}(${d.author_name.join(", ")})` : d.real_name} index={d.key}/>
                                </Td>
                                <Td width="140px">
                                    <Text text={comma(d.detailSales.map(item => item.amountPu).reduce((prev, curr) => prev + curr, 0))} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/>
                                    {/* <Text text={comma(d.amountPu)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/> */}
                                </Td>
                                <Td width="140px">
                                    <Text text={comma(d.detailSales.map(item => item.mgDeduction).reduce((prev, curr) => prev + curr, 0))} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", color: colors.error, textAlign: "right" }}/>
                                    {/* <Text text={comma(d.mgDeduction)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", color: colors.error, textAlign: "right" }}/> */}
                                </Td>
                                <Td width="140px">
                                    <Text text={comma(d.detailSales.map(item => item.amountAu).reduce((prev, curr) => prev + curr, 0))} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/>
                                    {/* <Text text={comma(d.amountAu)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/> */}
                                </Td>
                                <Td width="140px">
                                    <Text text={comma(d.detailSales.map(item => item.mgAdd).reduce((prev, curr) => prev + curr, 0))} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/>
                                    {/* <Text text={comma(d.mgAdd)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/> */}
                                </Td>
                                <Td width="140px">
                                    <Text text={comma(d.detailSales.map(item => item.etc).reduce((prev, curr) => prev + curr, 0))} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/>
                                    {/* <Text text={comma(d.etc)} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", textAlign: "right" }}/> */}
                                </Td>
                                <Td width="140px">
                                    {/* <Text text={comma(d.detailSales.map(item => item.tax).reduce((prev, curr) => prev + curr, 0))} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.blue500, textAlign: "right" }}/> */}
                                    <Text text={comma(d.tax)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.blue500, textAlign: "right" }}/>
                                </Td>
                                <Td width="140px">
                                    {/* <Text text={comma(d.detailSales.map(item => item.total).reduce((prev, curr) => prev + curr, 0))} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.blue500, textAlign: "right" }}/> */}
                                    <Text text={comma(d.total)} customStyle={{ fontSize: "12px", fontWeight: "700", lineHeight: "12px", color: colors.blue500, textAlign: "right" }}/>
                                </Td>
                            </Tr>
                            {d.detailSales.length &&
                            <ExpandedRow
                                detailItemKey="platform_id"
                                detailHeaders={expandedHeaders}
                                detailItems={d.detailSales}
                                detailColumns={expandedColumns}
                                showExpanded={showExpanded(i)}
                                selectedPlatform={selectedPlatform.concat([99])}
                            />
                            }
                        </React.Fragment>
                    ))
                    }
                </Body>
            </Table>
            <Pagination pageCount={pageCount} totalVisible={totalVisible} from="Withdraw" />
        </TableContainer>
    )
}

const AuthorName = ({name, index}: {name: string, index: string}) => {
    useEffect(() => {
        scrollListener();
    }, [])

    const scrollListener = () => {
        window.addEventListener("scroll", () => {
            onMouseLeave();
        });
    }

    const onMouseEnter = () => {
        let target = document.getElementById(`author-name-${index}`);
        if (target) {
            let targetTop = target.getBoundingClientRect().top;
            let targetLeft = target.getBoundingClientRect().left;
            let layout = document.getElementById(`root`)
            let tooltip = document.createElement("span");
            let content = document.createTextNode(name);
            tooltip.appendChild(content);
            tooltip.setAttribute('id', 'author-name-tooltip');
    
            tooltip.style.top = targetTop + 20 + "px";
            tooltip.style.left = targetLeft - 5 + "px";
    
            if (layout) layout.insertBefore(tooltip, null);
        }
    }

    const onMouseLeave = () => {
        let tooltip = document.getElementById("author-name-tooltip");
        if (tooltip) tooltip.remove();
    }

    return (
        <div
            id={`author-name-${index}`}
            className="ellipsis-text"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {name}
        </div>
    )
}

const comma = (num:number|null|undefined) => {
    if (num === null || typeof num === "undefined") {
        return String(0)
    } else {
        return round(num).toLocaleString();
    }
}

const headers = [
    { text: '', value: '', customStyle: { width: "80px" } },
    { text: '저자명', value: 'real_name', customStyle: { width: "140px" } },
    { text: '출판사정산금(원)', value: 'amountPu', customStyle: { width: "140px" } },
    { text: '선인세차감(원)', value: 'mgDeduction', customStyle: { width: "140px" } },
    { text: '작가정산금(원)', value: 'amountAu', customStyle: { width: "140px" } },
    { text: '선인세지급(원)', value: 'mgAdd', customStyle: { width: "140px" } },
    { text: '기타지급금(원)', value: 'etc', customStyle: { width: "140px" } },
    { text: '세금(원)', value: 'tax', customStyle: { width: "140px" } },
    { text: '지급총계(원)', value: 'total', customStyle: { width: "140px" } },
]

const expandedHeaders = [
    { value: "", width: "80px" },
    { value: "platform_name", width: "140px" },
    { value: "amountPu", width: "140px" },
    { value: "mgDeductio", width: "140px" },
    { value: "amountAu", width: "140px" },
    { value: "mgAdd", width: "140px" },
    { value: "etc", width: "140px" },
    { value: "empty2", width: "140px" },
    { value: "empty3", width: "140px" },
];

const expandedColumns:{
    [columnKey: string]: (columns?: any, idx?: any) => ReactElement;
} = {
    empty1: () => (<></>),
    platform_name: (item, idx) => (
        <Text text={item["platform_name"]} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px" }}/>
    ),
    amountPu: (item, idx) => (
        <Text text={comma(item["amountPu"])} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px", textAlign: "right" }}/>
    ),
    mgDeductio: (item, idx) => (
        <Text text={comma(item["mgDeductio"])} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px", color: colors.error, textAlign: "right" }}/>
    ),
    amountAu: (item, idx) => (
        <Text text={comma(item["amountAu"])} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px", textAlign: "right" }}/>
    ),
    mgAdd: (item, idx) => (
        <Text text={comma(item["mgAdd"])} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px", textAlign: "right" }}/>
    ),
    etc: (item, idx) => (
        <Text text={comma(item["etc"])} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px", textAlign: "right" }}/>
    ),
    empty2: (item, idx) => (
        <Text text={"-"} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px", textAlign: "right" }}/>
    ),
    empty3: (item, idx) => (
        <Text text={"-"} customStyle={{ fontSize: "12px", fontWeight: "400", lineHeight: "21px", textAlign: "right" }}/>
    ),
};

function ExpandedRow({
    detailItems,
    detailHeaders,
    detailItemKey,
    detailColumns,
    showExpanded,
    selectedPlatform,
}: ExpandedProps) {
    return (
        <Transition in={showExpanded} timeout={{ appear: 0, enter: 0, exit: 300 }} unmountOnExit>
            {(mountState) => {
                return (
                    <ExpandedTr showRow={showExpanded} mountState={mountState}>
                        <Td colSpan={10} className="test-td">
                            {detailItems.map((item, idx) => (
                                selectedPlatform.includes(item["platform_id"]) ?
                                <div key={item[detailItemKey]} className="test-div">
                                    <Table>
                                        <colgroup span={headers.length+1} style={{ minWidth: "50px" }}></colgroup>
                                        <Body>
                                            <Tr>
                                                {detailHeaders.map((h, i) => {
                                                    return (
                                                        <Td key={i} colSpan={1} width={h.width}>
                                                            { detailColumns[h.value]
                                                                ? detailColumns[h.value](
                                                                      item,
                                                                      idx,
                                                                  )
                                                                : <></>}
                                                        </Td>
                                                    );
                                                })}
                                            </Tr>
                                        </Body>
                                    </Table>
                                </div>
                                :
                                <React.Fragment key={idx}></React.Fragment>
                            ))}
                        </Td>
                    </ExpandedTr>
                );
            }}
        </Transition>
    );
}

const Arrow = styled.div<{active?: boolean}>`
    width: 25px;
    height: 25px;
    margin-left: 5px;
    transition: transform .3s;
    transform: ${({ active}) => active ? "rotate(90deg)" : ""};
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Table = styled.table`
    width: 100%;
    font-size: 12px;
`;
const Header = styled.thead`
    border-top: 1px solid ${({ theme }) => theme.colors.gray600};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
    height: 48px;
    Tr {
        border-bottom: none;
    }
`;
const Body = styled.tbody`
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray600};
`;
const Tr = styled.tr<{ expanded?: boolean; selectedRow?: boolean, backGroundcolor?: boolean }>`
    cursor: ${({ expanded }) => (expanded ? "pointer" : "default")};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
    :nth-last-child(1) {
        border-bottom: none;
    }
    background-color: ${({ backGroundcolor, selectedRow, theme }) => backGroundcolor && theme.colors.blue50};
`;
const HeaderTr = styled(Tr)<{ noSort?: boolean }>`
    cursor: ${({ noSort }) => (noSort ? "auto" : "pointer")};
`;
const ExpandedTr = styled.tr<{ showRow?: boolean; mountState?: TransitionStatus }>`
    display: ${({ showRow, mountState }) => mountState === "exited" && (showRow === false ? "none" : "table-row")};
    background-color: ${({ theme }) => theme.colors.gray100};
    border-top: 2px solid ${({ theme }) => theme.colors.gray600};
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray600};
    .test-div {
        height: ${({ showRow, mountState }) => {
            switch (mountState) {
                case "entered":
                    return showRow === true ? "60px" : "0px";
                case "exiting":
                    return showRow === false ? "0px" : "60px";
                default:
                    return "0px";
            }
        }};
        transition: height 0.3s cubic-bezier(0.4, 0, 0.6, 1);
        overflow: hidden;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
    }

    .test-td {
        padding: 0 !important;
        margin: 0 !important;
        height: auto !important;
        border-bottom: none;
    }
`;

const Th = styled.th<{ width?: string, textAlign?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 48px;
    padding: 14px 8px;
    text-align: ${({ textAlign }) => textAlign || "left"};
    vertical-align: middle;
    font-weight: 500;
`;

const Td = styled.td<{ width?: string, textAlign?: string }>`
    width: ${({ width }) => width || "auto"};
    height: 60px;
    padding: 14px 8px;
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;
    max-width: ${({ width }) => width || "auto"};
    
    .ellipsis-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 500;
    }
`;

export interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    textAlign?: string;
}

interface ExpandedProps {
    detailItemKey: string;
    detailHeaders: { text?: string; value: string; width?: string; textAlign?: string }[];
    detailItems: { [key: string]: any }[];
    showExpanded: boolean;
    detailColumns: {
        [columnKey: string]: (columns: any, idx: any) => ReactElement;
    };
    selectedPlatform: number[];
}