export enum PriceType {
    Free,
    Standard,
    Enterprise
}

//요금안내 카드(PlanCard, PlanCardContainer)
export interface Card {
    type: PriceType;
    planType: string;
    typeExplain: string;
    typeExplainColor: string;
    typeExplainTextColor: string;
    originalPrice?: string;
    nowPriceText: string;
    nowPrice: string;
    nowPriceTextColor: string;
    nowPriceExplain: string;
    explainText: string;
    explainTextArray: string[];
}

//공통기능, 추가기능 테이블(FunctionContainer, FunctionTable)
export interface TableHeaderData {
    type: string;
    free: string;
    standard: string;
    enterprise: string;
}

//공통기능, 추가기능 테이블(FunctionContainer, FunctionTable)
export interface TableData {
    type: string;
    free?: boolean;
    freeText?: string
    standard: boolean;
    enterprise: boolean;
}