import { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from '@/assets/styles/theme';
import styled from 'styled-components';
import Text from '@/components/atoms/text';
import Button from '@/components/atoms/button';
import { RootState, useAppSelector, useAppDispatch } from '@/modules/store';
import usePagination from '@/components/molecules/common/pagination/usePagination';
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import SimpleDataTable, { TableEventHandler } from "@/components/molecules/common/table/SimpleDataTable"
import Pagination from '@/components/molecules/common/pagination/Pagination';
import { NoticeData, getNoticeData, getAuthPubList, checkPubMaster } from '@/pages/support/notice/slice'

export default function NoticeList() {
    const dispatch = useAppDispatch();
    const { data, authPublists, isPubMaster, loading } = useAppSelector((state: RootState) => state.notice);
    const { setDataCount, setPage, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "notice" });
    const [clickedItem, setClickedItem] = useState(0);
    const [filteredData, setFilteredData] = useState<NoticeData[]>(data);
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const navigate = useNavigate();
    
    const options = authPublists.map((auth) => ({name: auth.publisher_name, id: auth.publisher_id}));
    const pubListOptions = [{name: '전체', id: 0}, {name: '글링', id: 1}, ...options];

    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    };

    const textStyled = {
        fontSize:"14px", 
        fontWeight:"400", 
        lineHeight:"24.5px"
    }

    const buttonStyled = {
        width: "auto",
        padding: "5px 27px",
        margin: "0 16px 16px 0",
    }

    const headers = [
        {text: '출판사', value: "publisher_name"},
        {text: '제목', value: "title"},
    ];
    
    const columns = {
        publisher_name: (item: any, idx: number, eventHandler: TableEventHandler) => (
            <Text key={(page - 1) * itemsPerPage + idx} text={item["publisher_name"]} customStyle={{ ...textStyled, width: "80px", color: colors.blue500}}/>
        ),
        title: (item: any, idx: number, eventHandler: TableEventHandler) => (
            <TitleTableStyled display="flex" width="600px">
                <Text key={(page - 1) * itemsPerPage + idx} text={item["title"]} customStyle={textStyled}/>
            </TitleTableStyled>
        )
    }
    
    const option = {
        movePage: true
    }
    
    const categoryChangeHandler = (id: number) => {
        setClickedItem(id);
        setPage(1);
        if (id === 0) {
            setFilteredData(data);
        } else if (id === 1) {
            const newFilteredData = data.filter((n) => n.publisher_name === '글링');
            setFilteredData(newFilteredData);
            setDataCount(newFilteredData.length);
        } else {
            const newFilteredData = data.filter((n) => Number(n.publisher_id) === id);
            setFilteredData(newFilteredData);
            setDataCount(newFilteredData.length);
        }
    }

    const fetchData = async () => {
        loadingOn();
        try {
            await dispatch(getNoticeData()).unwrap();
            await dispatch(getAuthPubList()).unwrap();
            await dispatch(checkPubMaster()).unwrap();
        } catch (err: any) {
            await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    useLayoutEffect(() => {
        fetchData();
    }, [dispatch]);

    useLayoutEffect(() => {
        setFilteredData(data);
    }, [data]);

    useLayoutEffect(() => {
        if (filteredData?.length) {
            setDataCount(filteredData.length);
        }
    }, [data, itemsPerPage, setDataCount, filteredData]);

   

    return (
        <StyledContainer>
            <Text text="공지사항" customStyle={titleStyled}/>
            <StyledButtonsContainer>
                { pubListOptions.map((publisher, idx) => {
                    return <Button 
                    key={idx} 
                    label={publisher.name} 
                    type={publisher.id === clickedItem ? 'black-outline' : 'gray-outline'}
                    customStyle={(buttonStyled)}
                    onClickHandler={()=> categoryChangeHandler(publisher.id)} />
                })}
            </StyledButtonsContainer>
            {!loading && <SimpleDataTable
                from="notice"
                headers={headers}
                needHeader={false}
                itemKey="notice"
                columns={columns}
                items={filteredData}
                options={option}
                type="notice"
            />}
            <PaginationStyled>
                <Pagination pageCount={pageCount} totalVisible={totalVisible} from="notice" />
            </PaginationStyled>
            { isPubMaster && <WriteButtonWrapper>
                <Button label="글쓰기" onClickHandler={()=>navigate('/support/notice/write?type=insert')}/>
            </WriteButtonWrapper> }
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 900px;
`;

const TitleTableStyled = styled.div<{display: string, width: string}>`
    display: ${({ display }) => display};
    width: ${({ width }) => width};
`;

const StyledButtonsContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    line-height: 100px;
    margin-bottom: 8px;
`;

const PaginationStyled = styled.div`
      display: flex;
      justify-content: center;
`;

const WriteButtonWrapper = styled.div`
    display: flex;
    justify-content: right;
`;