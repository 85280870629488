import styled from "styled-components";
import OptionContainer from "@/components/molecules/statement/publisher/common/OptionContainer";
import AuthorListContainer from "@/components/molecules/statement/publisher/common/AuthorListContainer";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import useLoading from "@/modules/hooks/useLoading";
import { useEffect } from "react";
import { getPublisherInfo, getInquiryMonth, getInquiryAuthor } from "@/pages/settlement/statement/publisher/slice";
import useAlert from "@/modules/hooks/useAlert";
import PdfViewer from "@/components/molecules/statement/publisher/inquiry/PdfViewer";

export default function Inquire() {
    const { specificationType } = useAppSelector((state: RootState) => state.pubStatement);
    const { loadingOn, loadingOff } = useLoading()
    const dispatch = useAppDispatch();
    const { alert } = useAlert();

    const loadPulisherInfo = async () => {
        try {
            await dispatch(getPublisherInfo()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        }
    }

    const loadData = async () => {
        try {
            await loadingOn();
            dispatch({ type: "pubStatement/setEmptyData" });
            await dispatch(getInquiryMonth()).unwrap();
            await dispatch(getInquiryAuthor()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            await loadingOff();
        }
    }

    const dateChangeHandler = async () => {
        try {
            await loadingOn();
            await dispatch(getInquiryAuthor()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    }

    const selectAuthorHandler = async () => {}

    useEffect(() => {
        loadPulisherInfo();
    }, [])

    useEffect(() => {
        loadData();
    }, [specificationType]);

    return (
        <InquireTemplate>
            <OptionContainer dateChangeHandler={dateChangeHandler} />
            <DisplayBox>
                <AuthorListContainer selectAuthorHandler={(selectAuthorHandler)} />
                <RightBox>
                    <PdfViewer />
                </RightBox>
            </DisplayBox>
        </InquireTemplate>
    )
}

const InquireTemplate = styled.div``

const DisplayBox = styled.div`
    display: flex;
`

const RightBox = styled.div`
    width: 960px;
    margin-top: 24px;
`