import { useState } from "react";
import styled from "styled-components";

// components
import SelectBox from "@/components/atoms/selectBox/index";
import MultiSearchBar from "@/components/molecules/common/searchBar/multiSearch/MultiSearchBar";

// types
import type { Atcmp } from "@/types/autoComplete";

// hooks
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import useSearchHistory from "@/components/molecules/common/searchBar/multiSearch/useSearchHistory";
import useAlert from "@/modules/hooks/useAlert";

// reducer
import { useAppDispatch, RootState } from "@/modules/store";
import { getAuthorData, getAtcmpByAll, getAtcmpByAuthor, getAtcmpByRealName, getAtcmpByEmail } from "@/pages/settlement/basedata-manage/author/authorSlice";

export default function AuthorSearchBar() {
    const dispatch = useAppDispatch();
    const { searchType, atcmpList } = useSelectorTyped((state:RootState) => state.author);
    const { searchHistory } = useSelectorTyped((state: RootState) => state.searchHistory);
    const { getAtcmp, getHistory, updateHistory, deleteHistory } = useSearchHistory({ template: "basedata-author" });
    const [prevKeyword, setPrevKeyword] = useState("");
    const { alert } = useAlert();

    const changeValue = (value:any) => {
        dispatch({ type: "author/setSearchType", payload: value.value });
        search(prevKeyword, value.value);
    }
                           
    const getAtcmpList = async (keyword: string) => {
        switch (searchType) {
            case "all":
                searchByAll(keyword);
                break;
            case "author":
                searchByAuthor(keyword);
                break;
            case "realName":
                searchByRealName(keyword);
                break;
            case "email":
                searchByEmail(keyword);
                break;
        }
    };

    const search = async (item: Atcmp | string, type?: string) => {
        try {
            let apiParams = { searchType: "", searchKeyword: "" };

            if (typeof item === "string") {
                let tempType = type ? type : searchType;
                switch (tempType) {
                    case "author":
                        apiParams.searchType = "author";
                        break;
                    case "realName":
                        apiParams.searchType = "realName";
                        break;
                    case "email":
                        apiParams.searchType = "email";
                        break;
                    default:
                        apiParams.searchType = "all";
                }
                apiParams.searchKeyword = item;
                dispatch(getAuthorData(apiParams)).unwrap();
    
                setPrevKeyword(item);
                if (!item) return;
                await updateHistory(item);
                return await getHistory();
            }
    
            if (item.type === "author") {
                apiParams.searchType = "author";
            }
            if (item.type === "realName") {
                apiParams.searchType = "realName";
            }
            if (item.type === "email") {
                apiParams.searchType = "email";
            }
            apiParams.searchKeyword = item.value;
            dispatch(getAuthorData(apiParams)).unwrap();
    
            setPrevKeyword(item.value);
            await updateHistory(item.value);
            await getHistory();   
        } catch (error:any) {
            await alert("error", "오류 발생", error.message);
        }
    };

    // 전체 자동완성
    const searchByAll = (keyword: string) => getAtcmp(getAtcmpByAll({ keyword }));

    // 저자명 자동완성
    const searchByAuthor = (keyword: string) => getAtcmp(getAtcmpByAuthor({ keyword }));

    // 본명 자동완성
    const searchByRealName = (keyword: string) => getAtcmp(getAtcmpByRealName({ keyword }));

    // 이메일 자동완성
    const searchByEmail = (keyword: string) => getAtcmp(getAtcmpByEmail({ keyword }));

    return (
        <>
            <FilterBoxWrap>
                <RowWrap>
                    <Label>검색</Label>
                    <Content>
                        <SelectBox
                            labelKey="name"
                            valueKey="value"
                            selected={searchType}
                            options={authorOptions}
                            customStyle={{ height: "40px", margin: "0 10px 0 0" }}
                            onClickValueFunc={changeValue}  
                        />
                        <MultiSearchBar
                            type={searchType === "all" ? "all" : "single"}
                            atcmpList={atcmpList}
                            searchHistory={searchHistory}
                            onKeyUp={getAtcmpList}
                            onSelected={search}
                            onClearHistory={deleteHistory}
                        />
                    </Content>
                </RowWrap>
            </FilterBoxWrap>
        </>
    );
}

const authorOptions = [{ name: "전체", value: "all" },
                       { name: "저자명", value: "author" },
                       { name: "본명", value: "realName" },
                       { name: "글링아이디(이메일)", value: "email" }];

const FilterBoxWrap = styled.div`
    width: 1200px;
    background-color: ${({ theme }) => theme.colors.gray50};
    margin-top: 24px;
`;
const RowWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
`;
const Label = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 21px 8px;
    font-size: 14px;
    font-weight: 500;
    width: 200px;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 8px;
    height: 100%;
`;