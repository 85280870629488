import Button from "@/components/atoms/button";
import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from "@/assets/styles/theme";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { useState } from "react";
import SelectBox from "@/components/atoms/selectBox/index";
import TextInput from "@/components/atoms/input/TextInput";
import ModalPortal from "@/components/molecules/common/modal/Portal";
import PromotionDescModal from "./PromotionDescModal";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import Checkbox from "@/components/atoms/input/Checkbox";
import CustomDatePicker from "@/components/molecules/common/date-picker/CustomDatePicker";

export default function PromotionTable() {
    const { promotionList } = useAppSelector((state: RootState) => state.pubPortfolio);
    const { platformList } = useAppSelector((state: RootState) => state.platform);
    const [promotionDescModal, showPromotionDescModal] = useState(false);
    const [promotionIdx, setPromotionIdx] = useState(0);
    const [promotionDesc, setPromotionDesc] = useState("");
    const dispatch = useAppDispatch();

    const tmpPlatformList = platformList.map(p => ({
        name: p.platform_name,
        value: p.platform_id
    }))
    tmpPlatformList.unshift({ name: "전체", value: 0 });

    const addPromotion = () => {
        const tmpList = JSON.parse(JSON.stringify(promotionList));
        tmpList.unshift({
            platform_id: 0,
            platform_name: "",
            promotion_name: "",
            promotion_desc: "",
            promotion_start_at: "",
            promotion_end_at: "",
            promotion_no_end_date: false,
        });
        dispatch({ type: "pubPortfolio/setPromotionList", payload: tmpList });
    }

    const changePlatform = (v: any, idx: number) => {
        dispatch({ type: "pubPortfolio/setPromotionData", payload: {
            key: "platform_id",
            idx,
            value: v.value
        }})
    }

    const changePromotionName = (e: any, idx: number) => {
        dispatch({ type: "pubPortfolio/setPromotionData", payload: {
            key: "promotion_name",
            idx,
            value: e.target.value
        }})
    }

    const descClickHandler = (idx: number) => {
        setPromotionIdx(idx);
        setPromotionDesc(promotionList[idx].promotion_desc);
        showPromotionDescModal(true);
    }

    const toggleNoEndDate = (e: any, idx: number) => {
        dispatch({ type: "pubPortfolio/setPromotionData", payload: {
            key: "promotion_end_at",
            idx,
            value: null
        }})
        dispatch({ type: "pubPortfolio/setPromotionData", payload: {
            key: "promotion_no_end_date",
            idx,
            value: e.target.checked
        }})
    }

    const deletePromotion = (idx: number) => {
        const tmpList = JSON.parse(JSON.stringify(promotionList));
        tmpList.splice(idx, 1);
        dispatch({ type: "pubPortfolio/setPromotionList", payload: tmpList });
    }

    const changeStartDate = (date: string, idx: number) => {
        dispatch({ type: "pubPortfolio/setPromotionData", payload: {
            key: "promotion_start_at",
            idx,
            value: date
        }})
    }

    const changeEndDate = (date: string, idx: number) => {
        dispatch({ type: "pubPortfolio/setPromotionData", payload: {
            key: "promotion_end_at",
            idx,
            value: date
        }})
    }

    return (
        <TableContainer>
            <TitleWrapper>
                <Text text="프로모션 정보" customStyle={{ fontSize: "20px", fontWeight: "700" }} />
                <Button
                    type="main"
                    label="추가"
                    onClickHandler={addPromotion}
                    customStyle={{  }}
                />
            </TitleWrapper>
            <Table>
                <Thead>
                    <Tr>
                        <Th width="140px">플랫폼</Th>
                        <Th width="240px">프로모션명</Th>
                        <Th width="240px">프로모션 설명</Th>
                        <Th width="200px">시작일</Th>
                        <Th width="280px">종료일</Th>
                        <Th width="100px" align="center">삭제</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {promotionList.map((p, idx) => (
                        <Tr key={idx}>
                            <Td width="140px">
                                <SelectBox
                                    labelKey="name"
                                    valueKey="value"
                                    options={tmpPlatformList}
                                    selected={p.platform_id}
                                    onClickValueFunc={(v:any) => changePlatform(v, idx)}
                                />
                            </Td>
                            <Td width="240px">
                                <TextInput 
                                    value={p.promotion_name}
                                    placeholder="프로모션명"
                                    onChangeHandler={(e) => changePromotionName(e, idx)}
                                    customStyle={{ width: "220px" }}
                                />
                            </Td>
                            <Td width="240px" onClick={() => descClickHandler(idx)}>
                                <TextInput 
                                    type="readOnly"
                                    value={p.promotion_desc}
                                    placeholder="프로모션 설명"
                                    onChangeHandler={() => {}}
                                    customStyle={{ width: "220px" }}
                                />
                            </Td>
                            <Td width="200px">
                                <DatePickerWrapper>
                                    <CustomDatePicker 
                                        onChange={(date) => changeStartDate(date, idx)} 
                                        customStyle={{ width: "160px"}}
                                        settingDate={p.promotion_start_at}
                                    />
                                </DatePickerWrapper>
                            </Td>
                            <Td width="280px">
                                <EndDateWrapper>
                                    <DatePickerWrapper>
                                        <CustomDatePicker 
                                            onChange={(date) => changeEndDate(date, idx)} 
                                            customStyle={{ width: "160px"}}
                                            settingDate={p.promotion_end_at}
                                            disabled={p.promotion_no_end_date}
                                        />
                                    </DatePickerWrapper>
                                    <Checkbox
                                        type="boolean"
                                        active={p.promotion_no_end_date}
                                        onChangeHandler={(e) => toggleNoEndDate(e, idx)}
                                    />
                                    <Text text="종료일 없음" customStyle={{ fontSize: "14px", fontWeight: "400", margin: "0 0 0 8px" }} />
                                </EndDateWrapper>
                            </Td>
                            <Td width="100px" align="center">
                                <DeleteIconWrapper>
                                    <Clear 
                                        width={24} 
                                        height={24} 
                                        fill={colors.gray600} 
                                        onClick={() => deletePromotion(idx)}
                                    />
                                </DeleteIconWrapper>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <ModalPortal>
                <PromotionDescModal
                    show={promotionDescModal}
                    close={() => showPromotionDescModal(false)}
                    from="edit-portfolio"
                    promotionIdx={promotionIdx}
                    promotionDesc={promotionDesc}
                />
            </ModalPortal>
        </TableContainer>
    )
}

const TableContainer = styled.div`
    width: 100%;
    margin-top: 60px;
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const Table = styled.table`
    width: 100%;
`

const Thead = styled.thead`
    border-bottom: 1px solid ${colors.gray300};
`

const Tr = styled.tr`

`

const Th = styled.th<{ width: string; align?: string;}>`
    width: ${({ width }) => width};
    height: 48px;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 500;
    text-align: ${({ align }) => align ? align : "left"};
    padding-left: 8px;
`

const Tbody = styled.tbody`

`

const Td = styled.td<{ width: string; align?: string;}>`
    width: ${({ width }) => width};
    height: 60px;
    vertical-align: middle;
    text-align: ${({ align }) => align ? align : "left"};
    padding-left: 8px;
`

const EndDateWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`

const DatePickerWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.gray50};
    border: 1px solid ${colors.gray300};
    height: 32px;
    width: 160px;
    border-radius: 4px;
    margin-right: 12px;
`

const StyledInput = styled.input`
    border: unset;
    font-size: 12px;
    font-weight: 400;
    padding: 9.5px 8px;
    background-color: ${colors.gray50};
    width: 100%;
    height: 30px;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 32px;
    height: 100%;
`

const DeleteIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    svg {
        cursor: pointer;
    }
`