import styled from "styled-components";
import SectionLayout from "@/components/molecules/common/UI/SectionLayout";
import SearchBar from "@/components/molecules/portfolio/author/list/SearchBar";
import FilterContainer from "@/components/molecules/portfolio/author/list/FilterContainer";
import { RootState, useAppSelector, useAppDispatch } from "@/modules/store";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import useCheckToken from "@/modules/hooks/useCheckToken";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { getPortfolioList } from "../slice";
import { useEffect } from "react";
import PortfolioSkeleton from "@/components/molecules/common/loading/PortfolioSkeleton";
import SeriesListContainer from "@/components/molecules/portfolio/author/list/SeriesListContainer";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import { ReactComponent as WarningIcon } from "@/assets/icons/warning-line.svg";

export default function PortfolioList() {
    const { portfolioList, loading } = useAppSelector((state: RootState) => state.authorPortfolio);
    const { setPage, setDataCount, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "portfolio", initialValue: {
        itemsPerPage: 12,
        totalVisible: 10,
        page: 1,
    }});
    const tokenStatus = useCheckToken();
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();

    const getInitData = async () => {
        try {
            loadingOn();
            await dispatch(getPortfolioList()).unwrap();
        } catch (err: any) {
            alert("error", "오류 발생", err.message)
        } finally {
            loadingOff();
        }
    }

    useEffect(() => {
        getInitData();
        return () => {
            dispatch({ type: "authorPortfolio/initState" });
        }
    }, [])

    useEffect(() => {
        if (portfolioList.length) {
            setDataCount(portfolioList.length);
            setPage(1);
        }
    }, [portfolioList])

    if (!tokenStatus) {
        return <></>
    };

    return (
        <SectionLayout customStyle={{position: "relative"}}>
            <TitleWrapper>내 포트폴리오</TitleWrapper>
            <BlockClick />
            <ReadyTextBox>
                <WarningIcon width="32" height="32" style={{ marginBottom: "16px" }} />
                서비스 준비중입니다.
            </ReadyTextBox>
            <SearchBar />
            <FilterContainer />
            { loading 
                ? <PortfolioSkeleton />
                : <SeriesListContainer
                    page={page}
                    itemPerPage={itemsPerPage}
                  />
            }
            <PaginationWrapper>
                <Pagination 
                    pageCount={pageCount} 
                    totalVisible={totalVisible} 
                    from="portfolio" 
                    customStyle={{ margin: "16px 0 0 0" }}
                />
            </PaginationWrapper>
        </SectionLayout>
    )
}

// 임시 차단
const BlockClick = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background: linear-gradient(to bottom, #ffffff94,#ffffffb5, #ffffff);
    z-index: 1;
`;
const ReadyTextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 24px;
    font-weight: 700;
`;

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
`

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
`