import styled from "styled-components";
import BaseModal from "@/components/molecules/common/modal/BaseModal";
import useAlert from "@/modules/hooks/useAlert";
import React, { useEffect, useState } from "react";
import { colors } from "@/assets/styles/theme";
import Checkbox from "@/components/atoms/input/Checkbox";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";

export default function EditIsbnModal({ show, close, from }: ComponentProps) {
    const { isbnList } = useAppSelector((state: RootState) => state.pubPortfolio);
    const [currList, setCurrList] = useState<Isbn[]>([]);
    const [allCheck, setAllCheck] = useState(false);
    const { alert } = useAlert();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const tmpList = isbnList.map(i => ({
            isbn: i,
            checked: false
        }))
        setCurrList(tmpList)
    }, [isbnList])

    const apply = async () => {
        try {
            const selected = currList.find(c => c.checked);
            if (!selected) return alert("warning", "확인", "isbn을 선택해주세요.");

            const answer = await alert("confirm", "삭제 확인", "선택항목을 삭제 하시겠습니까?");
            if (answer === "Y") {
                dispatch({ type: "pubPortfolio/editIsbn", payload: currList.filter(c => !c.checked) });
                reset();
                close();
            }
        } catch (err) {
            alert("error", "오류 발생", "적용중 오류가 발생하였습니다.");
        }
    }

    const reset = () => {
        setCurrList([]);
        setAllCheck(false);
    }

    const selectedIsbn = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const tmpList = currList.slice();
        if (e.target.checked) {
            // 모두 체크인 경우 전체체크 활성화
            const ret = tmpList.filter((t: Isbn) => !t.checked);
            if (!ret.length) setAllCheck(true);
        } else {
            setAllCheck(false);
        }
        
        tmpList[idx].checked = e.target.checked;
        setCurrList(tmpList);
    }

    const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAllCheck(e.target.checked);
        const tmpList = currList.slice();
        tmpList.every(t => t.checked = e.target.checked);
        setCurrList(tmpList);
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["ISBN 수정"]}
            customStyle={[{ width: "320px", height: "440px" }]}
            applyFunc={apply}
            reset={reset}
        >
            <BodyWrapper>
                <Table>
                    <Thead>
                        <Tr>
                            <Th width="48px" align="center">
                                <Checkbox
                                    type="boolean"
                                    active={allCheck}
                                    onChangeHandler={(e) => handleAllCheck(e)}
                                />
                            </Th>
                            <Th width="292px">ISBN</Th>
                        </Tr>
                    </Thead>
                    {currList.length ? 
                        <Tbody>
                            {currList.map((c, i) => (
                                <Tr key={i}>
                                    <Td width="48px" align="center">
                                        <Checkbox
                                            type="boolean"
                                            active={c.checked}
                                            onChangeHandler={(e) => selectedIsbn(e, i)}
                                        />
                                    </Td>
                                    <Td width="292px">
                                        {c.isbn}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody> : 
                        <Nodata />
                    }
                </Table>
            </BodyWrapper>
        </BaseModal>
    )
}


const BodyWrapper = styled.div`
    padding: 0 20px;
`

const Table = styled.table`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 350px;
    // height: 288px;
    // min-height: 288px;
    // max-height: 288px;
`;

const Thead = styled.thead`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid ${colors.gray300};
`;

const Tr = styled.tr`
    display: flex;
    height: 48px;
    width: 100%;
`;

const Th = styled.th<{ width?: string; align?: string; }>`
    display: flex;
    align-items: center;
    justify-content: ${({ align }) => align ? align : "unset"};
    height: 100%;
    width: ${({ width }) => (width ? width : "120px")};
    font-size: 12px;
    font-weight: 500;
`;

const Tbody = styled.tbody`
    align-items: center;
    width: 100%;
    height: 290px;
    overflow: auto;
`;

const Nodata = styled(Tbody)`
    background-color: ${colors.gray50};
`

const Td = styled.td<{ width: string; align?: string; }>`
    display: flex;
    align-items: center;
    justify-content: ${({ align }) => align ? align : "unset"};
    height: 100%;
    width: ${({ width }) => width};
    font-size: 12px;
    font-weight: 400;
`;

interface ComponentProps {
    show: boolean;
    close: () => void;
    from: string;
}

interface Isbn {
    isbn: string; 
    checked: boolean;
}