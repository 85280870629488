import _ from "lodash";
import api from "@/api";
import { CommonType } from "@/types/dataType";
import { hangulParticle } from "./filter";

let seriesDebounce: ReturnType<typeof setTimeout>;
let userDebounce: ReturnType<typeof setTimeout>;
let newAuthorDebounce: ReturnType<typeof setTimeout>;
let newSeriesDebounce: ReturnType<typeof setTimeout>;

export const integer = ({ value, error, field, min, max, mandatory }: IntegerProps) => {
    let validataion: Validation = {
        [error]: "",
        value: value,
    };

    let v: string | number = "";
    v = value.replace(/[^\d-]/g, "").replace(/(?!^)-/g, "") as string;
    if ((!v || v === "-") && mandatory) {
        validataion.value = v;
        validataion[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return validataion;
    }
    if (v.length > 11) {
        v = v.slice(0, 11);
    }

    v = Number(v);
    validataion.value = String(v);

    if (v < min) {
        validataion[error] = `${min} 이상의 수를 입력해주세요.`;
        return validataion;
    } else if (v > max) {
        validataion[error] = `${max} 이하의 수를 입력해주세요.`;
        return validataion;
    }

    validataion[error] = "";
    return validataion;
};

export const amount = ({ field, value, error, decimal, mandatory }: AmountProps) => {
    let validataion: Validation = {
        [error]: "",
        value: value,
    };

    if (value === undefined || typeof value === "number") return validataion;
    let v = decimal
        ? value.replace(/[^\d.-]/g, "").replace(/(?!^)-/g, "")
        : value.replace(/[^\d-]/g, "").replace(/(?!^)-/g, "");

    if ((!v || v === "-" || v === "-.") && mandatory) {
        if (v === "-.") v = "-";
        validataion.value = v;
        validataion[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return validataion;
    }

    const hasPoint = v.indexOf(".") > -1 ? true : false;
    let int = 0;
    let dec = "";
    if (decimal && hasPoint) {
        let tmp = v.split(".");
        int = Number(tmp.shift());
        dec = tmp.join("");
    } else {
        int = Number(v);
    }

    v = `${String(int).slice(0, 11)}${hasPoint ? "." : ""}${dec !== "" ? dec.slice(0, 2) : ""}`;

    validataion.value = v;
    if (String(int).length > 11) {
    } else if (decimal && hasPoint && !dec) {
        validataion[error] = "소수점 이하를 입력해주세요.";
    } else {
        validataion[error] = "";
    }
    return validataion;
};

export const series = async ({ value, error, mandatory, platform, yearmonSt }: SeriesProps) => {
    let validataion: Validation = {
        [error]: "",
        value: value,
    };
    return new Promise((resolve, reject) => {
        if (!value && mandatory) {
            if (seriesDebounce) clearTimeout(seriesDebounce);
            validataion[error] = "시리즈명을 입력해주세요.";
            return resolve(validataion);
        }
        if (seriesDebounce) clearTimeout(seriesDebounce);
        seriesDebounce = setTimeout(async () => {
            const timerID = seriesDebounce;
            const apiParams = {
                series_name: value,
                platform_id: platform,
                stYearmon: yearmonSt || null,
            };
            const { data: { status, data } } = await api.post(`/api/settlement/common/applySeries/select`, apiParams);

            if (timerID !== seriesDebounce) reject();
            if (status === "fail") {
                reject(new Error("시리즈명 오류"));
            }
            if (data.length) {
                validataion[error] = "";
                validataion.value = data[0];
                resolve(validataion);
            } else {
                validataion[error] = "등록되지 않은 시리즈입니다.";
                validataion.value = "";
                resolve(validataion);
            }
        }, 500);
    });
};

export const user = async ({ value, error, field, mandatory }: UserProps) => {
    let validataion: UserValidation = {
        [error]: "",
        value: value,
        user_id: "",
    };

    return new Promise((resolve, reject) => {
        const exp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
        if (userDebounce) clearTimeout(userDebounce);

        if (!value && mandatory) {
            validataion[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
            return resolve(validataion);
        } else if (!value && !mandatory) {
            validataion[error] = "";
            return resolve(validataion);
        } else if (exp.test(value) === false) {
            validataion[error] = "올바른 이메일 형식이 아닙니다.";
            return resolve(validataion);
        } else {
            userDebounce = setTimeout(async () => {
                const timerID = userDebounce;
                const apiParams = {
                    user_email: value,
                };

                const { data } = await api.post(`/api/settlement/baseData/validate/email/select`, apiParams);
                if (timerID !== userDebounce) reject();
                if (data.data.length !== 0) {
                    validataion[error] = "";
                    validataion["user_id"] = data.data[0].user_id;
                    return resolve(validataion);
                } else {
                    validataion[error] = "글링 아이디가 등록되어 있지 않습니다.";
                    return resolve(validataion);
                }
            }, 500);
        }
    });
};

export const text = ({ field, value, error, max, mandatory }: TextProps) => {
    let validataion: Validation = {
        [error]: "",
        value: value,
    };

    if (!value && mandatory) {
        validataion[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return validataion;
    } else if (max && value.length > max) {
        validataion[error] = `${max}자 이내로 입력해주세요.`;
        return validataion;
    } else {
        validataion[error] = "";
        return validataion;
    }
};

export const ratio = ({ field, value, error, mandatory, decimal }: RatioProps) => {
    let v = decimal ? value.replace(/[^\d.]/g, "").replace(/(?!^)-/g, "") : value.replace(/[^\d]/g, "");
    let validataion: Validation = {
        [error]: "",
        value: v,
    };
    if (!v && mandatory) {
        validataion[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return validataion;
    }
    if ((!v || v === ".") && mandatory) {
        validataion.value = v;
        validataion[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return validataion;
    }

    if (!mandatory && v === "") {
        return validataion;
    }

    const hasPoint = v.indexOf(".") > -1 ? true : false;
    let int = 0;
    // let dec = 0;
    let dec = "";
    if (decimal && hasPoint) {
        let tmp = v.split(".");
        int = Number(tmp.shift());
        dec = String(tmp.join("")).slice(0, 2);
    } else {
        int = Number(v);
    }

    v = `${String(int).slice(0, 3)}${hasPoint ? "." : ""}${dec !== "" ? dec : ""}`;
    validataion.value = v;
    if (Number(String(int).slice(0, 3)) > 100 || (Number(String(int).slice(0, 3)) === 100 && dec)) {
        validataion[error] = "100 이하의 수를 입력해주세요.";
    } else if (decimal && hasPoint && !dec) {
        validataion[error] = "소수점 이하를 입력해주세요.";
    } else {
        validataion[error] = "";
    }
    return validataion;
};

export const email = ({ value, error, mandatory, field }: EmailProps) => {
    const exp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    let validataion: Validation = {
        [error]: "",
        value: value,
    };
    if (!value && mandatory) {
        validataion[error] = `${field}${hangulParticle(field)} 입력해주세요.`;
        return validataion;
    } else if (!value && !mandatory) {
        validataion[error] = "";
        return validataion;
    } else if (exp.test(value) === false) {
        validataion[error] = "올바른 이메일 형식이 아닙니다.";
        return validataion;
    } else {
        validataion[error] = "";
        return validataion;
    }
};

export const newAuthor = async ({ value, error, mandatory, id }: NewAuthorProps) => {
    let validataion: Validation = {
        [error]: "",
        value: value,
    };
    return new Promise((resolve, reject) => {
        if (!value && mandatory) {
            if (newAuthorDebounce) clearTimeout(newAuthorDebounce);
            validataion[error] = "저자명을 입력해주세요.";
            return resolve(validataion);
        }
        if (newAuthorDebounce) clearTimeout(newAuthorDebounce);

        return (newAuthorDebounce = setTimeout(async () => {
            const timerID = newAuthorDebounce;
            const apiParams = {
                name: value,
            };

            const {
                data: { status, data },
            } = await api.post(`/api/settlement/common/validate/author/select`, apiParams);

            if (timerID !== newAuthorDebounce) reject();
            if (status === "fail") {
                // return alert("error", "오류 발생", res.message);
            }

            if (data.length && id !== data[0].id) {
                validataion[error] = "이미 등록된 저자명입니다.";
                return resolve(validataion);
            } else {
                validataion[error] = "";
                return resolve(validataion);
            }
        }, 300));
    });
};

export const newSeries = async ({ value, error, mandatory, id }: NewSeriesProps) => {
    let validataion: Validation = {
        [error]: "",
        value: value,
    };
    return new Promise((resolve, reject) => {
        if (!value && mandatory) {
            if (newSeriesDebounce) clearTimeout(newSeriesDebounce);
            validataion[error] = "시리즈명을 입력해주세요.";
            return resolve(validataion);
        }

        if (newSeriesDebounce) clearTimeout(newSeriesDebounce);
        newSeriesDebounce = setTimeout(async () => {
            const timerID = newSeriesDebounce;
            const apiParams = {
                series_name: value,
            };

            const {
                data: { status, data },
            } = await api.post(`/api/settlement/common/validate/series/select`, apiParams);

            if (timerID !== newSeriesDebounce) reject();
            if (status === "fail") {
                // return alert("error", "오류 발생", res.message);
            }

            if (data.length && id !== data[0].id) {
                validataion[error] = "이미 등록된 시리즈명입니다.";
                return resolve(validataion);
            } else {
                validataion[error] = "";
                return resolve(validataion);
            }
        }, 500);
    });
};

interface DefaultProps {
    // item: any;
    value: any;
    error: string;
    mandatory: boolean;
}

interface SeriesProps extends DefaultProps {
    platform: CommonType.Id;
    yearmonSt?: CommonType.Date;
}

interface IntegerProps extends DefaultProps {
    field: string;
    min: number;
    max: number;
}

interface AmountProps extends DefaultProps {
    field: string;
    decimal: boolean;
}

interface UserProps extends DefaultProps {
    field: string;
}

interface TextProps extends DefaultProps {
    field: string;
    max: number;
}

interface RatioProps extends DefaultProps {
    field: string;
    decimal: boolean;
}

interface EmailProps extends DefaultProps {
    field: string;
}

interface NewAuthorProps extends DefaultProps {
    id: number;
}

interface NewSeriesProps extends DefaultProps {
    id: number;
}

export interface Validation {
    [error: string]: string;
    value: any;
}
export interface UserValidation extends Validation {
    user_id: string;
}
