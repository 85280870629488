import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { inquiryMonthlyActions } from "@/pages/settlement/saels-inquiry/publisher/monthly/monthlySlice";

function useMonthlyApi() {
    const {
        startDate,
        endDate,
        selectedPlatform,
        series_name,
        author_name,
        book_name,
        startDateError,
        endDateError,
        initialDataLoaded,
        fetchLoading,
    } = useSelectorTyped((state: RootState) => state.inquiryMonthly);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!initialDataLoaded && fetchLoading) return;
        if (startDateError !== "" || endDateError !== "") return;

        dispatch(inquiryMonthlyActions.setParamsId());
        return () => { }

    }, [
        startDate,
        endDate,
        selectedPlatform,
        series_name,
        author_name,
        book_name,        
        dispatch
    ]);
}
export default useMonthlyApi;
