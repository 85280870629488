import { useEffect } from "react";

// reducers
import { RootState, useAppDispatch } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { getMonthlyData, inquiryMonthlyActions } from "@/pages/settlement/saels-inquiry/publisher/monthly/monthlySlice";

// components
import TemplateLayout from "@/components/molecules/sales-entry/common/TemplateLayout";
import MonthlySearchFilter from "@/components/molecules/sales-inquiry/publisher/monthly/MonthlySearchFilter";
import MonthlyTable from "@/components/molecules/sales-inquiry/publisher/monthly//MonthlyTable";
import MonthlyTableTop from "@/components/molecules/sales-inquiry/publisher/monthly/MonthlyTableTop";

// custom hooks
import useMonthlyApi from "./hooks/useMonthlyApi";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";

export default function Monthly() {
    const dispatch = useAppDispatch();
    const { setNoData } = inquiryMonthlyActions;
    const { selectedPlatform, initialDataLoaded, changedParamsId } = useSelectorTyped((state: RootState) => state.inquiryMonthly);
    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    useMonthlyApi();

    useEffect(() => {
        getData();
    }, [changedParamsId]);

    const getData = async () => {
        try {
            if (initialDataLoaded && !selectedPlatform.length) {
                return dispatch(setNoData());
            }
            
            loadingOn();
            await dispatch(getMonthlyData()).unwrap();
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    return (
        <TemplateLayout>
            <MonthlySearchFilter />
            <MonthlyTableTop />
            <MonthlyTable />
        </TemplateLayout>
    );
}
