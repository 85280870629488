import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import TextInput from "@/components/atoms/input/TextInput";
import CustomDatePicker from "../../date-picker/CustomDatePicker";
import ValidateWrapper from "@/components/atoms/input/ValidateWrapper";
import { PaymentItem } from "../sales-inquiry/MgPaymentModal";
import { addComma } from "@/modules/utils/filter";
import { ReactComponent as Clear } from "@/assets/icons/clear.svg";
import { ReactComponent as AddRow } from "@/assets/icons/add-data.svg";
import { CommonType } from "@/types/dataType";

function MgPaymentModalBody({ mgId, mgRatio, rowValue, paymentItems, setPaymentItems }: MgPaymentModalBodyProps) {
    const [clickedIndex, setClickedIndex] = useState(-1);

    const changeInDate = (date: string) => {
        if (date) {
            setPaymentItems((prev) =>
                prev.map((p, idx) => (idx === clickedIndex ? { ...p, in_date: date, inDateError: "" } : p)),
            );
        } else {
            setPaymentItems((prev) =>
                prev.map((p, idx) =>
                    idx === clickedIndex ? { ...p, inDateError: "입금일자가 입력되지 않았습니다." } : p,
                ),
            );
        }
    };

    const changeOutDate = (date: string) => {
        setPaymentItems((prev) => prev.map((p, idx) => (idx === clickedIndex ? { ...p, out_date: date } : p)));
    };

    const changeAmount = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const v = e.target.value;
        const amount = Number(v.replace(/[^\d]/g, ""));
        const amountOut = Math.round((amount * mgRatio) / 100);

        setPaymentItems((prev) => prev.map((p, idx) => (idx === index ? { ...p, amount, amount_out: amountOut } : p)));

        if (amount !== 0 && paymentItems[index].in_date === "") {
            setPaymentItems((prev) =>
                prev.map((p, idx) => (idx === index ? { ...p, inDateError: "입금일자가 입력되지 않았습니다." } : p)),
            );
        } else {
            setPaymentItems((prev) => prev.map((p, idx) => (idx === index ? { ...p, inDateError: "" } : p)));
        }
    };

    const addRows = () => {
        setPaymentItems((prev) => [...prev, rowValue]);
    };

    const deleteRows = (index: number) => {
        setPaymentItems((prev) => prev.filter((p, idx) => idx !== index));
        if (index === 0) addRows();
    };

    return (
        <BodyWrap>
            <TableContainer>
                <Theader>
                    <Tr>
                        {headers.map((h, idx) =>
                            idx === 0 ? (
                                <FieldTh key={headers.indexOf(h)} width={h?.width}>
                                    {h.text}
                                </FieldTh>
                            ) : (
                                <Th key={headers.indexOf(h)} width={h?.width}>
                                    {h.text}
                                </Th>
                            ),
                        )}
                    </Tr>
                </Theader>
                <TBody>
                    {paymentItems.map((item, index) => (
                        <Tr key={`payment-${index}`} active={true}>
                            {/* 차수 */}
                            <Td>{index + 1}</Td>
                            {/* 입금일 */}
                            <Td onClick={() => setClickedIndex(index)}>
                                <ValidateWrapper dataError={paymentItems[index].inDateError}>
                                    <CustomDatePicker
                                        onChange={changeInDate}
                                        customStyle={datepickerStyle}
                                        settingDate={item.in_date || null}
                                        unsetButton={true}
                                    />
                                </ValidateWrapper>
                            </Td>
                            {/* 지급일 */}
                            <Td onClick={() => setClickedIndex(index)}>
                                <CustomDatePicker
                                    onChange={changeOutDate}
                                    customStyle={datepickerStyle}
                                    settingDate={item.out_date || null}
                                    unsetButton={true}
                                />
                            </Td>
                            {/* 입금액 */}
                            <Td style={{ width: "392px" }}>
                                <TextInput
                                    type="main"
                                    value={addComma(item.amount)}
                                    onChangeHandler={(e) => changeAmount(e, index)}
                                    customStyle={textInputStyle}
                                />
                            </Td>
                            {/* 지급(예정)금액 */}
                            <Td style={{ width: "392px" }}>
                                <TextInput
                                    type="readOnly"
                                    value={addComma(item.amount_out || 0)}
                                    onChangeHandler={() => {}}
                                    customStyle={textInputStyle}
                                />
                            </Td>
                            {/* 추가 & 삭제 */}
                            <Td>
                                <StyledClearIcon
                                    width={24}
                                    height={24}
                                    fill={colors.gray600}
                                    onClick={() => deleteRows(index)}
                                />
                            </Td>
                            <Td onClick={addRows}>
                                <AddRowIconWrapper>
                                    <AddRow width={24} height={24} fill={colors.blue500} />
                                    <p>행 추가</p>
                                </AddRowIconWrapper>
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </TableContainer>
        </BodyWrap>
    );
}

const headers = [
    { text: "차수", value: "fld_idx", width: "60px" },
    { text: "입금일(플랫폼->출판사)", value: "in_date", width: "210px" },
    { text: "지급일(출판사->작가)", value: "out_date", width: "210px" },
    { text: "입금액", value: "amount", width: "160px" },
    { text: "지급(예정)금액", value: "amount_out", width: "160px" },
];
const textInputStyle = {
    width: "100%",
    height: "32px",
};
const datepickerStyle = {
    width: "100%",
};
const BodyWrap = styled.div`
    padding: 0px 20px;
    height: 288px;
    overflow-y: auto;
`;
const TableContainer = styled.table``;

const Theader = styled.thead`
    height: 48px;
`;
const Tr = styled.tr<{ height?: string; active?: boolean }>`
    white-space: nowrap;
`;
const Th = styled.th<{ width?: string; height?: string; padding?: string; margin?: string }>`
    vertical-align: middle;
    font-size: 12px;
    width: ${({ width }) => (width ? width : "120px")};
    height: ${({ height }) => (height ? height : "100%")};
    padding: ${({ padding }) => (padding ? padding : "10px")};
    font-weight: 400;
    text-align: left;
`;
const FieldTh = styled(Th)`
    text-align: center;
`;
const TBody = styled.tbody<{ backGroundColor?: boolean }>``;
const Td = styled.td<{ padding?: string }>`
    padding: 12px 15px;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
`;
const StyledClearIcon = styled(Clear)`
    cursor: pointer;
`;
const AddRowIconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.blue500};
    cursor: pointer;
`;

export interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
}
interface MgPaymentModalBodyProps {
    from?: string;
    mgId: CommonType.Id;
    mgRatio: number;
    rowValue: PaymentItem;
    paymentItems: PaymentItem[];
    setPaymentItems: Dispatch<SetStateAction<PaymentItem[]>>;
}
export default MgPaymentModalBody;
