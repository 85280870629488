import api from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { calcPeriod } from "@/modules/utils/date";
import type { DailyState } from "./types";

const name = "inquiry/author/daily";
const initialState: DailyState = {
    // 일별 조회 데이터
    items: [],
    total_amt: 0,
    total_cnt: 0,

    // 조회 로딩
    initialDataLoaded: false,
    fetchLoading: false,

    // 데이터 조회
    changedParamsId: 0,
    startDate: calcPeriod("yesterday").startDate || "",
    endDate: calcPeriod("yesterday").endDate || "",
    selectedPlatform: [],
    selectedPublisher: [],
    selectedSeries: [],
    selectedAuthor: [],
    bookName: "",

    //자동완성
    atcmpList: [],

    // 기타
    startDateError: "",
    endDateError: "",
    filterReset: false,
};

// 일별 판매 현황
export const getAuthorDailyData = createAsyncThunk(`${name}/getAuthorDailyData`, async (_, { getState, rejectWithValue, signal }) => {
        try {
            const { inquiryAuthorDaily }: any = getState();
            const apiParams = {
                startDate: inquiryAuthorDaily.startDate,
                endDate: inquiryAuthorDaily.endDate,
                selectedPlatform: inquiryAuthorDaily.selectedPlatform,
                selectedPublisher: inquiryAuthorDaily.selectedPublisher,
                selectedSeries: inquiryAuthorDaily.selectedSeries,
                selectedAuthor: inquiryAuthorDaily.selectedAuthor,
                bookName: inquiryAuthorDaily.bookName,    
            }
            return (await api.post(`/api/settlement/newSalesInquiry/daily/author/select`, apiParams, { signal })).data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

// 작품명 검색
export const getAtcmpByBookName = createAsyncThunk(`${name}/getAtcmpByBookName`, async ({ keyword }: { keyword: string; }, { rejectWithValue }) => {
        try {
            return (await api.post(`/api/settlement/newSearchKeyword/getAtcmp/bookName/select`, { keyword, from: 'daily'})).data;
        } catch (err: any) {
            throw rejectWithValue(err.response.data);
        }
    },
);

export const dailySlice = createSlice({
    name,
    initialState,
    reducers: {
        setFilterOption(state, action: PayloadAction<Partial<DailyState>>) {
            const {
                startDate,
                endDate,
                bookName,
                selectedPlatform,
                selectedPublisher,
                selectedSeries,
                selectedAuthor,
                filterReset,
            } = action.payload;

            state.startDate = startDate ?? state.startDate;
            state.endDate = endDate ?? state.endDate;
            state.bookName = bookName ?? state.bookName;
            state.selectedPlatform = selectedPlatform ?? state.selectedPlatform;
            state.selectedPublisher = selectedPublisher ?? state.selectedPublisher;
            state.selectedSeries = selectedSeries ?? state.selectedSeries;
            state.selectedAuthor = selectedAuthor ?? state.selectedAuthor;
            state.filterReset = filterReset ?? state.filterReset;
        },
        setError(state, action) {
            const { startDateError, endDateError } = action.payload;
            state.startDateError = startDateError ?? state.startDateError;
            state.endDateError = endDateError ?? state.endDateError;
        },
        setParamsId(state){
            state.changedParamsId += 1;
        },
        setNoData(state) {
            state.items = [];
            state.total_cnt = 0;
            state.total_amt = 0;
        },
        resetStore() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        // 일별 판매 현황 조회
        builder
            .addCase(getAuthorDailyData.pending, (state) => {
                state.fetchLoading = true;
            })
            .addCase(getAuthorDailyData.fulfilled, (state, action) => {
                state.fetchLoading = false;
                state.initialDataLoaded = true;
                state.filterReset = false;

                const { result, total_cnt, total_amt } = action.payload.data;

                state.items = result;
                state.total_cnt = total_cnt;
                state.total_amt = Number(total_amt.toFixed(2));
            });

        // 자동완성 - 작품명 검색
        builder.addCase(getAtcmpByBookName.fulfilled, (state, { payload }) => {
            state.atcmpList = payload.data;
        });
    },
});

export const { setFilterOption, setError, setParamsId, setNoData, resetStore } = dailySlice.actions;
export default dailySlice.reducer;
