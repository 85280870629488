import styled from "styled-components";
import JoinWay from "@/components/molecules/signup/JoinWay";

export default function SignUp() {
    return (
        <SignFormContainer>
            <h2 className="title">회원가입</h2>
            <JoinWay />
        </SignFormContainer>
    );
}

const SignFormContainer = styled.div`
    width: 664px;
    height: auto;
    margin: 0 auto;
    padding-top: 80px;
    .title {
        font-weight: 700;
        font-size: 28px;
        text-align: center;
        margin-bottom: 60px;
    }
`;