import Button from "@/components/atoms/button/index"

interface buttonType {
    category: string,
    value: string
}

interface buttonStyles {
    width: string,
    margin: string,
    padding: string
}

interface Iprops {
    buttonOptions: Array<buttonType>,
    buttonStyles: buttonStyles,
    buttonClickHandler: (button:buttonType)=> void,
    clickedItem: string
}

export default function QuestionButtonWrapper ({ buttonOptions, buttonStyles, buttonClickHandler, clickedItem }:Iprops) {
    return (
        <>
            {buttonOptions.map((button, idx) => 
                <Button 
                    key={idx}
                    label={button.category} 
                    type={button.category === clickedItem ? 'black-outline' : 'gray-outline'}
                    customStyle={(buttonStyles)}
                    onClickHandler={()=> {buttonClickHandler(button)}} />
            )}
        </>
    )
}