import { RootState, useAppSelector } from "@/modules/store";
import styled from "styled-components";
import Button from "@/components/atoms/button";
import { useNavigate } from "react-router-dom";
import { colors } from "@/assets/styles/theme";
import { useState } from "react";
import { dateFormat } from "@/modules/utils/filter";
import SalesContainer from "./SalesContainer";

export default function BookInfoContainer() {
    const { contentBaseInfo, contentPlatformInfo } = useAppSelector((state: RootState) => state.authorPortfolio);
    const [isbnMore, showIsbnMore] = useState(false);
    const navigate = useNavigate();
    
    let isbn = "";
    let isbnList: string[] = [];
    if (contentBaseInfo.isbn) {
        isbnList = contentBaseInfo.isbn.split(",");
        isbn = `${isbnList[0]}`
        if (isbnList.length > 1) {
            isbn += ` (외${isbnList.length-1}개)`;
        }
    }

    const toggleIsbnMore = () => {
        showIsbnMore(!isbnMore)
    }

    return (
        <Container>
            <TopLineWrapper>
                <TitleWrapper>{contentBaseInfo.series_name}</TitleWrapper>
                <ButtonWrapper>
                    <Button
                        type="red-outline"
                        label="뒤로가기"
                        onClickHandler={() => navigate("/portfolio/list")}
                    />
                </ButtonWrapper>
            </TopLineWrapper>
            <ContentWrapper>
                <RowWrapper>
                    <FieldNameWrapper>저자</FieldNameWrapper>
                    <FieldContentWrapper>{contentBaseInfo.author_name}</FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>출판사</FieldNameWrapper>
                    <FieldContentWrapper>{contentBaseInfo.publisher_name}</FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>출간일</FieldNameWrapper>
                    <FieldContentWrapper>{dateFormat("dot", contentBaseInfo.publish_at)}</FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>ISBN</FieldNameWrapper>
                    <FieldContentWrapper>
                        {isbn}
                        {Boolean(isbnList.length > 1) && 
                            <ShowAllButton onClick={toggleIsbnMore}>
                                전체보기
                                {Boolean(isbnMore) && 
                                    <IsbnMoreWrapper>
                                        {isbnList.map(i => (
                                            <Isbn key={i}>{i}</Isbn>
                                        ))}
                                    </IsbnMoreWrapper> 
                                }
                            </ShowAllButton> 
                        }
                    </FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper>판매처</FieldNameWrapper>
                    <FieldContentWrapper>
                        <PlatformCardWrapper>
                            {contentPlatformInfo.map(p => (
                                <PlatformCard color={p.platform_color} key={p.platform_id}>
                                    {p.platform_name}
                                </PlatformCard>
                            ))}
                        </PlatformCardWrapper>
                    </FieldContentWrapper>
                </RowWrapper>
                <RowWrapper>
                    <FieldNameWrapper></FieldNameWrapper>
                    <SalesContainer />
                </RowWrapper>
            </ContentWrapper>
        </Container>
    )
}

const Container = styled.div`
    width: 790px;
`

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 700;
`

const TopLineWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 208px;
`

const ContentWrapper = styled.div`
    margin-top: 16px;
    width: 100%;
`

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
`

const FieldNameWrapper = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 400;
    width: 120px;
    height: 40px;
    padding-left: 8px;
    color: ${colors.gray600};
`

const FieldContentWrapper = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 500;
    padding-left: 8px;
`

const PlatformCardWrapper = styled.ul`
    position: relative;
    top: -10px;
    display: flex;
    flex-wrap: wrap;
`

const PlatformCard = styled.li<{ color: string; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 86px;
    height: 32px;
    border-radius: 99px;
    background-color: ${({ color }) => color};
    font-size: 13px;
    font-weight: 400;
    color: ${colors.white};
    margin-right: 8px;
    margin-bottom: 8px;
`

const ShowAllButton = styled.div`
    margin-left: 10px;
    color: ${colors.gray600};
    cursor: pointer;
`

const IsbnMoreWrapper = styled.div`
    position: absolute;
    border: 1px solid ${colors.gray500};
    background-color: ${colors.white};
    margin-top: 8px;
    padding: 8px 10px;
    z-index: 1;
`

const Isbn = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
`