import { RouteObject, useRoutes } from "react-router-dom";
import { RootState, useAppSelector } from "@/modules/store";

// 기본 페이지
import Main from "@/pages/landing/main";
import Consult from "@/pages/landing/consult";
import Guide from "@/pages/landing/guide";
import Pricing from "@/pages/landing/pricing";
import Support from "@/pages/support";
import SendComplete from "@/components/molecules/landing/consult/SendComplete";
import Agreement from "@/pages/info/Agreement";
import Privacy from "@/pages/info/Privacy";
import PubMyPage from "@/pages/mypage/publisher";
import AuthorMyPage from "@/pages/mypage/author";
import DeleteUser from "@/components/molecules/mypage/DeleteUser";

// 고객센터
import Notice from "@/pages/support/notice/Notice";
import NoticeList from "@/pages/support/notice/NoticeList";
import NoticeDetail from "@/pages/support/notice/NoticeDetail";
import NoticeWrite from "@/pages/support/notice/NoticeWrite";
import GuidePage from "@/pages/support/guide/index";
import Download from "@/pages/support/download/index";
import Question from "@/pages/support/question/index";
import Inquiry from "@/pages/support/inquiry/Inquiry";
import InquiryList from "@/pages/support/inquiry/InquiryList";
import InquiryDetail from "@/pages/support/inquiry/InquiryDetail";
import InquiryWrite from "@/pages/support/inquiry/InquiryWrite";

// 대시보드
import PubDashboard from "@/pages/dashboard/publisher";
import AuthorDashboard from "@/pages/dashboard/author";

// 회원가입 & 로그인
import Signin from "@/pages/auth/signIn";
import FindId from "@/pages/auth/findId";
import FindPw from "@/pages/auth/findPw";
import SignUp from "@/pages/auth/signUp";
import SignUpAuthor from "@/pages/auth/signUp/author";
import SignUpPublisher from "@/pages/auth/signUp/publisher";

// 이북메이커
import Ebook from "@/pages/ebook-maker";

// 정산관리
import SalesEntry from "@/pages/settlement/sales-entry";
import SalesInquiry from "@/pages/settlement/saels-inquiry/publisher";
import AuthorSalesInquiry from "@/pages/settlement/saels-inquiry/author";
import BasedataManage from "@/pages/settlement/basedata-manage";
import TotalSales from "@/pages/settlement/total-sales";
import PubStatement from "@/pages/settlement/statement/publisher";
import AuthorStatement from "@/pages/settlement/statement/author";

// 포트폴리오
import PubPortfolioList from "@/pages/portfolio/publisher/list";
import PubPortfolioEnroll from "@/pages/portfolio/publisher/enroll";
import PubPortfolioDetail from "@/pages/portfolio/publisher/detail";
import PubPortfolioEdit from "@/pages/portfolio/publisher/edit";
import AuthorPortfolioList from "@/pages/portfolio/author/list";
import AuthorPortfolioDetail from "@/pages/portfolio/author/detail";

// 에러 페이지
import NotFound from "@/pages/empty/NotFound";
import NeedLogin from "@/pages/empty/NeedLogin";
import NeedAuth from "@/pages/empty/NeedAuth";
import NotReady from "@/pages/empty/NotReady";

// 업로더
import UploaderLogin from "@/pages/uploader";

// 어플
import AppFileUpload from "./pages/app/AppFileUpload";

export default function Element() {
    const { isLogin, isPublisher } = useAppSelector((state: RootState) => state.user);

    // routes 설정
    let routes: RouteObject[];

    // 1. 회원 아닌 유저 (로그인 안한상태)
    routes = [
        { path: "/", element: <Main /> },
        { path: "pricing", element: <Pricing /> },
        // { path: "guide", element: <NotReady /> },
        { path: "guide", element: <Guide /> },
        { path: "consult", element: <Consult /> },
        { path: "consult/sendComplete", element: <SendComplete /> },
        { path: "dashboard", element: <NeedLogin /> },
        {
            path: "settlement",
            children: [
                { path: "sales-entry", element: <NeedLogin /> },
                { path: "sales-inquiry", element: <NeedLogin /> },
                { path: "basedata-manage", element: <NeedLogin /> },
                { path: "total-sales", element: <NeedLogin /> },
                { path: "statement", element: <NeedLogin /> },
            ],
        },
        {
            path: "portfolio",
            children: [
                { path: "list", element: <NeedLogin /> },
                { path: "detail", element: <NeedLogin /> },
                { path: "enroll", element: <NeedLogin /> },
                { path: "edit", element: <NeedLogin /> },
            ],
        },
        { path: "ebook-maker", element: <NeedLogin /> },
        { path: "support", element: <NeedLogin /> },
        { path: "findid", element: <FindId /> },
        { path: "findpw", element: <FindPw /> },
        { path: "signin", element: <Signin /> },
        { path: "signup", element: <SignUp /> },
        { path: "signup/publisher", element: <SignUpPublisher /> },
        { path: "signup/author", element: <SignUpAuthor /> },
        { path: "*", element: <NotFound /> },
        {
            path: "info",
            children: [
                { path: "agreement", element: <Agreement /> },
                { path: "privacy", element: <Privacy /> },
            ],
        },
        { path: "uploader", element: <UploaderLogin /> },
        { 
            path: "app", 
            children: [
                { path: "fileUpload", element: <AppFileUpload /> }
            ]
        }
    ];

    if (isLogin) {
        if (isPublisher) {
            // 2. 출판사
            routes = [
                { path: "/", element: <Main /> },
                { path: "pricing", element: <Pricing /> },
                { path: "guide", element: <Guide /> },
                { path: "consult", element: <Consult /> },
                { path: "dashboard", element: <PubDashboard /> },
                {
                    path: "settlement",
                    children: [
                        { path: "sales-entry", element: <SalesEntry /> },
                        { path: "sales-inquiry", element: <SalesInquiry /> },
                        { path: "basedata-manage", element: <BasedataManage /> },
                        { path: "total-sales", element: <TotalSales /> },
                        { path: "statement", element: <PubStatement /> },
                    ],
                },
                {
                    path: "portfolio",
                    children: [
                        { path: "list", element: <PubPortfolioList /> },
                        { path: "detail", element: <PubPortfolioDetail /> },
                        { path: "enroll", element: <PubPortfolioEnroll /> },
                        { path: "edit", element: <PubPortfolioEdit /> },
                    ],
                },
                { path: "ebook-maker", element: <Ebook /> },
                {
                    path: "support",
                    element: <Support />,
                    children: [
                        {
                            path: "notice",
                            element: <Notice />,
                            children: [
                                { index: true, element: <NoticeList /> },
                                { path: ":id", element: <NoticeDetail /> },
                                { path: "write", element: <NoticeWrite /> },
                            ],
                        },
                        { path: "guide", element: <GuidePage /> },
                        { path: "download", element: <Download /> },
                        { path: "question", element: <Question /> },
                        {
                            path: "inquiry",
                            element: <Inquiry />,
                            children: [
                                { index: true, element: <InquiryList /> },
                                { path: ":id", element: <InquiryDetail /> },
                                { path: "write", element: <InquiryWrite /> },
                            ],
                        },
                    ],
                },
                { path: "findid", element: <FindId /> },
                { path: "findpw", element: <FindPw /> },
                { path: "signin", element: <Signin /> },
                { path: "signup", element: <SignUp /> },
                { path: "signup/publisher", element: <SignUpPublisher /> },
                { path: "signup/author", element: <SignUpAuthor /> },
                { path: "*", element: <NotFound /> },
                {
                    path: "info",
                    children: [
                        { path: "agreement", element: <Agreement /> },
                        { path: "privacy", element: <Privacy /> },
                    ],
                },
                { path: "mypage", element: <PubMyPage /> },
                { path: "deleteUser", element: <DeleteUser /> },
                { path: "uploader", element: <UploaderLogin /> },
                { 
                    path: "app", 
                    children: [
                        { path: "fileUpload", element: <AppFileUpload /> }
                    ]
                }
            ];
        } else {
            // 3. 작가
            routes = [
                { path: "/", element: <Main /> },
                { path: "pricing", element: <Pricing /> },
                { path: "guide", element: <Guide /> },
                { path: "consult", element: <Consult /> },
                { path: "dashboard", element: <AuthorDashboard /> },
                {
                    path: "settlement",
                    children: [
                        { path: "sales-entry", element: <NeedAuth /> },
                        { path: "sales-inquiry", element: <AuthorSalesInquiry /> },
                        { path: "basedata-manage", element: <NeedAuth /> },
                        { path: "total-sales", element: <NeedAuth /> },
                        { path: "statement", element: <AuthorStatement /> },
                    ],
                },
                {
                    path: "portfolio",
                    children: [
                        { path: "list", element: <AuthorPortfolioList /> },
                        { path: "detail", element: <AuthorPortfolioDetail /> },
                        { path: "enroll", element: <NeedAuth /> },
                        { path: "edit", element: <NeedAuth /> },
                    ],
                },
                { path: "ebook-maker", element: <NeedAuth /> },
                {
                    path: "support",
                    element: <Support />,
                    children: [
                        {
                            path: "notice",
                            element: <Notice />,
                            children: [
                                { index: true, element: <NoticeList /> },
                                { path: ":id", element: <NoticeDetail /> },
                                { path: "write", element: <NoticeWrite /> },
                            ],
                        },
                        { path: "guide", element: <GuidePage /> },
                        { path: "download", element: <Download /> },
                        { path: "question", element: <Question /> },
                        {
                            path: "inquiry",
                            element: <Inquiry />,
                            children: [
                                { index: true, element: <InquiryList /> },
                                { path: ":id", element: <InquiryDetail /> },
                                { path: "write", element: <InquiryWrite /> },
                            ],
                        },
                    ],
                },
                { path: "findid", element: <FindId /> },
                { path: "findpw", element: <FindPw /> },
                { path: "signin", element: <Signin /> },
                { path: "signup", element: <SignUp /> },
                { path: "signup/publisher", element: <SignUpPublisher /> },
                { path: "signup/author", element: <SignUpAuthor /> },
                { path: "*", element: <NotFound /> },
                {
                    path: "info",
                    children: [
                        { path: "agreement", element: <Agreement /> },
                        { path: "privacy", element: <Privacy /> },
                    ],
                },
                { path: "mypage", element: <AuthorMyPage /> },
                { path: "deleteUser", element: <DeleteUser /> },
                { path: "uploader", element: <UploaderLogin /> },
                { 
                    path: "app", 
                    children: [
                        { path: "fileUpload", element: <AppFileUpload /> }
                    ]
                }
            ];
        }
    }

    const element = useRoutes(routes);

    return element;
}