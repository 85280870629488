import styled from 'styled-components'
import Text from '@/components/atoms/text';
import UnderlineText from '@/components/molecules/introduce/UnderlineText';
import { colors } from '@/assets/styles/theme';
export default function FreeTextContainer() {
    const titleStyle = {
        fontSize: "36px",
        color: colors.black,
    }

    const texts = [
        {
            text: '지금 스탠다드 플랜을',
            style: Object.assign({fontWeight: "500"}, titleStyle)
        },
        {
            text: '무료로 이용하세요',
            style: Object.assign({fontWeight: "700"}, titleStyle)
        }
    ];

    const descriptionStyle = {
        fontSize: "12px",
        fontWeight: "500",
        color: colors.black,
    }
    const descriptions = [
        {
            text: '글링의 플랜을 구독하시고 싶으신가요?\u00a0',
            underlineText: '구독문의',
            style: descriptionStyle,
            routeHandler() {
                window.location.href = '/consult'
            }
        },
        {
            text: '상세한 요금이 궁금하시면\u00a0',
            underlineText: '요금안내',
            style: descriptionStyle,
            routeHandler() {
                window.location.href = '/pricing'
            }
        }
    ];

    const filteredDescription = descriptions.map((textObj, idx)=> {
        return (
            <DescriptionDetailWrapper 
                key={idx} >
                <Text
                    text={textObj.text}
                    customStyle={textObj.style}
                    />
                <UnderlineText 
                    label={textObj.underlineText}
                    customStyle={textObj.style}
                    onClickHandler={textObj.routeHandler}    
                />
            </DescriptionDetailWrapper>
    )});

    return (
        <StyledContainer>
            <TitleWrapper>
                {texts.map((textObj,idx)=><Text key={idx} text={textObj.text} customStyle={textObj.style}/>)}
            </TitleWrapper>
            <DescriptionWrapper>
                { filteredDescription }
            </DescriptionWrapper>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const TitleWrapper = styled.div`
    line-height: 45.07px;
`

const DescriptionWrapper = styled.div`
    margin-top: 24px;
`

const DescriptionDetailWrapper = styled.div`
    display: flex;
    align-items: center;
`
