import styled from "styled-components";
import Text from "@/components/atoms/text";
import { ReactComponent as Up } from "@/assets/icons/up-color.svg";
import { ReactComponent as Down } from "@/assets/icons/down.svg";
import { useState } from "react";
import { colors } from "@/assets/styles/theme";

export default function QuestionContainer() {
    const headerText = '자주 묻는 질문'
    const headerTextStyle = {
        fontSize: "36px",
        fontWeight: "700", 
        lineHeight: "45px",
        color: colors.white
    }

    const [toggles, setToggles] = useState([false, false, false, false, false])

    const questionTexts = ['글링을 무료로 이용할 수 있나요? 유료 플랜과 차이점이 무엇인가요?',
    '스탠다드 무료 이벤트는 언제까지 유효한가요?', '구독 취소는 어떻게 해야하나요?', '이용시 궁금한 점은 어떻게 하나요?', '구독이 끝나면 데이터는 어떻게 되나요?'                    
    ]

    const answerTexts = ['글링을 무료로 이용할 수 있나요? 유료 플랜과 차이점이 무엇인가요?',
    '스탠다드 무료 이벤트는 언제까지 유효한가요?', '구독 취소는 어떻게 해야하나요?', '이용시 궁금한 점은 어떻게 하나요?', '구독이 끝나면 데이터는 어떻게 되나요?'                    
    ]

    const questionTextStyle = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
        color: colors.white,
    }

    const clickIconHandler = (index: number) => {
        let newToggles = [...toggles];
        newToggles[index] = !newToggles[index];
        setToggles(newToggles);
    }

    return(
        <StyledQuestionContainer>
            <HeaderBox>
                <Text text={headerText} customStyle={headerTextStyle}></Text>
            </HeaderBox>
            {questionTexts.map((item, index) => 
                <ContentBox key={index}>
                    <QuestionRow key={index}>
                        <Text key={index} text={item} customStyle={questionTextStyle}></Text>
                        {toggles[index] ? 
                        <StyledUp onClick={() => clickIconHandler(index)} fill={colors.white} />  
                        :<StyledDown onClick={() => clickIconHandler(index)} fill={colors.white} />}
                    </QuestionRow>
                    {toggles[index] ? <AnaswerRow>{answerTexts[index]}</AnaswerRow> : <></> }
                </ContentBox>
            )}
        </StyledQuestionContainer>
    )
}

const StyledQuestionContainer = styled.div`
    padding: 30px calc((100% - 1200px) / 2);
    margin: 60px 0px 70px 0px;
    display: flex;
    flex-direction: column;
`

const HeaderBox = styled.div`
    heigth: 45px;
    margin-bottom: 40px;
    margin-top: 60.5px;
`
const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
`

const QuestionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.white};
    height: 60px;
`

const StyledUp = styled(Up)`
    margin: 18px 20px;
`

const StyledDown = styled(Down)`
    margin: 18px 20px;
`

const AnaswerRow = styled.div`
    padding: 16px;
    font-size: 14px;
    color: ${colors.white};
    line-height: 24.5px;
    font-weight: 400;
    background-color: ${colors.gray900};
`