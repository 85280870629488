import styled from 'styled-components'
import ReviewProfileContainer from '@/components/molecules/introduce/review/ReviewProfileContainer';
import ReviewDetailContainer from '@/components/molecules/introduce/review/ReviewDetailContainer';
import { useScrollFadeIn } from '@/modules/hooks/useScrollFadeIn';

export default function ReviewCardContainer({id, reviewCard}: CardProps) {
    const profileData = (({image, job, name}) => ({image, job, name}))(reviewCard);
    const descriptionData = (({summary, description}) => ({summary, description}))(reviewCard);
    const isRight = id === 1;
    let fadeInCard = useScrollFadeIn('up', 1, 0);

    return (
        <StyledContainer isRight={isRight} {...fadeInCard}>
            <ReviewProfileContainer
                profileObj={profileData}/>
            <ReviewDetailContainer
                detailObj={descriptionData}/>
        </StyledContainer>
    )
}

const StyledContainer = styled.div<{isRight: boolean}>`
    display: flex;
    width: 800px;
    height: 200px;
    padding: 26px 6px;
    justify-content: ${(props)=> (props.isRight ? "right" : "")};
`


interface objProps {
    image: string,
    job: string,
    name: string,
    summary: string,
    description: string
}

interface CardProps {
    id: number,
    reviewCard: objProps
}