import styled from "styled-components";
import Text from "@/components/atoms/text";
import { colors } from '@/assets/styles/theme';
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import usePagination from "../../common/pagination/usePagination";
import Pagination from "@/components/molecules/common/pagination/Pagination";
import { useEffect, useState } from "react";
import SimpleDataTable from "../../common/table/SimpleDataTable";
import Button from "@/components/atoms/button";
import moment from "moment";
import { getAuthInfo, pubMyPageActions } from "@/pages/mypage/publisher/slice";
import ModalPortal from "../../common/modal/Portal";
import SetAuthModal from "../../common/modal/SetAuthModal"
import { disConnect } from "@/pages/mypage/publisher/slice";
import useAlert from "@/modules/hooks/useAlert";

export default function AdminAccount() {
    const dispatch = useAppDispatch();
    const { alert } = useAlert();
    
    const [data] = useSelectorTyped((state: RootState) => [state.pubMyPage.accontAuthAdmin.data]);
    const user_id_string = useAppSelector((state: RootState) => state.pubMyPage.user_id_string);
    const { setDataCount, page, itemsPerPage, pageCount, totalVisible } = usePagination({ from: "accountAuthAdmin" });
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(data?.length) setDataCount(data.length);
    },[data, itemsPerPage]);

    function closeModal() {
        setShow(false);
    }

    async function setAuthAdmin(id: number) {
        await dispatch(getAuthInfo(id));
        setShow(true);
    }

    async function setConnected(id: number, idx: number, nickName: string) {
        const new_string_arr = user_id_string.split(',').filter((data) => String(id) !== data);
        const new_user_id_string = new_string_arr.join(',');
        const user_id = id;
        const newData = data.filter((data, i) => i !== idx);

        const res = await alert('confirm', '출판사 연결 해제', `${nickName}의 출판사 연결을 해제 하시겠습니까?`);
        if (res === 'N') {
            return;
        }
        
        await dispatch(disConnect({ new_user_id_string, user_id }));
        dispatch(pubMyPageActions.updateAccountAdmin({ newData }));
    }

    const titleStyled = {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "25px",
        color: colors.black,
    }

    const emailTextStyled = {
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "21px",
    }

    const textStyled = {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "24.5px",
    }

    const headers = [
        { text: '계정', value: 'account', width: '240px'},
        { text: '닉네임', value: 'nickName', width: '180px'},
        { text: '가입일시', value: 'createdAt', width: '180px'},
        { text: '메뉴권한', value: 'authAdmin'},
        { text: '출판사 연결', value: 'connectPub'},
    ]

    const customHeaders = {
        account: () => <Text text="계정" customStyle={textStyled} />,
        nickName: () => <Text text="닉네임" customStyle={textStyled} />,
        createdAt: () => <Text text="가입일시" customStyle={textStyled} />,
        authAdmin: () => <Text text="메뉴권한" customStyle={{ ...textStyled, width: "140px", textAlign: "center" }} />,
        connectPub: () => <Text text="출판사 연결" customStyle={{ ...textStyled, width: "160px", textAlign: "center" }} />,
    }

    const columns = {
        account: (item: any, idx: number) => (
            <Text key={(page - 1) * itemsPerPage + idx} text={item["user_email"]} customStyle={emailTextStyled} />
        ),
        nickName: (item: any, idx: number) => (
            <Text key={(page - 1) * itemsPerPage + idx} text={item["user_nickname"]} customStyle={{fontSize:"14px", fontWeight:"400", lineHeight:"24.5px"}} />
       ),
        createdAt: (item: any, idx: number) => (
            <Text key={(page - 1) * itemsPerPage + idx} text={moment(item["user_created_at"]).format("YYYY.MM.DD HH:mm")} customStyle={{fontSize:"14px", fontWeight:"400", lineHeight:"24.5px"}} />
        ),
        authAdmin: (item: any) => (
            <ButtonStyled>
                <Button
                    type="main"
                    label="권한설정"
                    customStyle={{
                        width: "100px",
                        height:"32px",
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                    onClickHandler={() => setAuthAdmin(item["u_id"])}
                />
            </ButtonStyled>
        ),
        connectPub: (item: any, idx: number) => (
            <ButtonStyled>
                <Button
                    type="black-outline"
                    label="출판사 연결 해제"
                    customStyle={{
                        width: "120px",
                        height:"32px",
                        fontSize: "14px",
                        fontWeight: "500"
                    }}
                    onClickHandler={() => setConnected(item["u_id"], idx, item["user_nickname"])}
                />
            </ButtonStyled>
        )
    };
    
    return (
        <MypageSectionLayout>
                <StyledTitleContainer>
                    <Text text="계정 / 권한 관리" customStyle={titleStyled}/>
                </StyledTitleContainer>
                <ContainerStyled>
                {
                    <SimpleDataTable 
                        from="accountAuthAdmin"
                        itemKey="authAdmin"
                        needHeader={true}
                        headers={headers}
                        columns={columns}
                        items={data}
                        customHeaders={customHeaders}
                    />
                } 
                <Pagination pageCount={pageCount} totalVisible={totalVisible} from={"accountAuthAdmin"} />
            </ContainerStyled>
            <ModalPortal>
                <SetAuthModal
                    show={show}
                    from="auth"
                    close={closeModal}
                />
            </ModalPortal>
        </MypageSectionLayout>
    )
}


const MypageSectionLayout = styled.div`
`

const ContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;
    box-sizing: border-box;
`

const StyledTitleContainer = styled.div`
    display: flex;
    margin: 0px 0px 24px 0px;
`

const ButtonStyled = styled.div`
    display: flex;
    justify-content: center;
`