import { ChangeEvent, MouseEvent, KeyboardEvent, FocusEvent, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import theme from "@/assets/styles/theme";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";

/**
 * 기본 서치바 컴포넌트
 * @param onClick click 이벤트 함수
 * @param onChange 입력값 전달
 * @param onKeyUp keyUp 이벤트 함수
 * @param onKeyDown keyDown 이벤트 함수
 * @param onSelectKeyword 검색할 키워드 선택
 * @returns
 */
export default function SimpleSearchBar({
    placeholder,
    disabled = false,
    value = "",
    customStyle,
    className,
    onClick,
    onChange,
    onKeyUp,
    onKeyDown,
    onKeyPress,
    onFocus,
    onSelectKeyword = () => {},
}: SearchBarProps) {
    const [_value, setValue] = useState("");
    const iconColor = disabled ? theme.colors.gray600 : theme.colors.blue500;
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // validate 등의 이유로 부모 value가 변경될 때
        if (value === undefined) return;
        if (value !== _value) {
            // IME BUFFER 초기화
            inputRef.current?.blur();
            inputRef.current?.focus();

            setValue(value);
        } 
    }, [value]);

    const changeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if (onChange) onChange(e.target.value);
    };

    const handleKeyUP = (e: KeyboardEvent<HTMLInputElement>) => {
        if (onKeyUp) onKeyUp(e);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing === true) return;
        if (onKeyDown) onKeyDown(e);
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") onSelectKeyword(_value);
        if (onKeyPress) onKeyPress(e);
    };

    const handleFocusOn = (e: FocusEvent<HTMLInputElement>) => {
        if (onFocus) onFocus(e);
    };

    const clickSearchIcon = () => onSelectKeyword(_value);

    const clickInput = (e: MouseEvent<HTMLInputElement>) => {
        if (onClick) onClick(e);
    };

    return (
        <SearchBarWrapper className={className} width={customStyle?.width}>
            <SearchBar
                ref={inputRef}
                value={_value}
                placeholder={placeholder}
                disabled={disabled}
                onClick={clickInput}
                onChange={changeKeyword}
                onKeyPress={handleKeyPress}
                onKeyUp={handleKeyUP}
                onKeyDown={handleKeyDown}
                onFocus={handleFocusOn}
                padding={customStyle?.padding}
                height={customStyle?.height}
                fontSize={customStyle?.fontSize}
                fontWeight={customStyle?.fontWeight}
                borderRadius={customStyle?.borderRadius}
                color={customStyle?.color}
            />
            <IconWrapper disabled={disabled} onClick={clickSearchIcon}>
                <SearchIcon size={24} fill={iconColor} />
            </IconWrapper>
        </SearchBarWrapper>
    );
}

const SearchBarWrapper = styled.div<SearchBarStyleProps>`
    position: relative;
    width: ${({ width }) => width || "100%"};
`;
const SearchBar = styled.input<SearchBarStyleProps>`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${({ height }) => height || "32px"};
    padding: ${({ padding }) => padding || "8px 32px 8px 8px"};
    font-size: ${({ fontSize }) => fontSize || "12px"};
    border: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
    font-weight: ${({ fontWeight }) => fontWeight || "400"};
    border-radius: ${({ borderRadius }) => borderRadius || "4px"};
    color: ${({ color, theme }) => color || theme.colors.black};
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.gray50 : theme.colors.white)};
    ::placeholder {
        color: ${({ color, theme }) => color || theme.colors.gray600};
    }
`;
const IconWrapper = styled.div<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

interface SearchBarProps {
    placeholder: string;
    value?: string;
    disabled?: boolean;
    customStyle?: StyleProps;
    className?: string;
    onChange?: (value: string) => void;
    onClick?: (e: MouseEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onSelectKeyword?: (value: string) => void;
}
interface StyleProps {
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    borderRadius?: string;
    color?: string;
}
interface SearchBarStyleProps extends StyleProps {
    disabled?: boolean;
}