import { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import useSelectorTyped from '@/modules/hooks/useSelectorTyped';
import { RootState, useAppDispatch } from '@/modules/store';
import { setLastId, setSeriesList } from '@/pages/settlement/sales-entry/mg/mgSlice';

// components
import ModalPortal from '@/components/molecules/common/modal/Portal';
import SearchSeriesModal from '@/components/molecules/common/modal/SearchSeriesModal';
import ModalSearchBar from '@/components/molecules/common/searchBar/modalSearchBar/ModalSearchBar';
import ValidateWrapper from '@/components/atoms/input/ValidateWrapper';
import Pagination from '@/components/molecules/common/pagination/Pagination';
import DataTable, { TableItemProps } from '@/components/molecules/common/table/DataTable';
import AddLine from '@/components/molecules/sales-entry/common/AddLine';

// custom hooks
import useAlert from '@/modules/hooks/useAlert';
import usePagination from '@/components/molecules/common/pagination/usePagination';

// types
import type { SearchSeriesResult, SelectedSeries } from '@/types/searchSeries';

export default function MgTable() {
    const dispatch = useAppDispatch();
    const { lastId, contractType, platformId, seriesList, authorInfo, authorError } = useSelectorTyped((state: RootState) => state.entryMg);
    const [showModal, setShowModal] = useState(false);
    const [matchingIdx, setMatchingIdx] = useState(-1);
    const { pageCount, totalVisible } = usePagination({ from: 'mg', tableData: seriesList });
    const { alert } = useAlert();
    
    const headers = [{ text: '시리즈명', value: 'series_name' }];
    const columns = {
        series_name: ({ item, index }: TableItemProps) => {
            return (
                <ValidateWrapper dataError={item.seriesError}>
                    <ModalSearchBar
                        value={item.series_name}
                        type='disabledInput'
                        placeholder='시리즈명'
                        onChange={() => {}}
                        onHandleModal={() => hanndleModal(index)}
                    />
                </ValidateWrapper>
            );
        },
    };

    const applyAddLine = (rowAdd: number) => {
        let tmpLastId = lastId;
        let tmpItems = [];
        for (let i = 0; i < rowAdd; i++) {
            let item = {
                id: (tmpLastId += 1),
                series_id: 0,
                series_name: '',
                seriesError: '시리즈명을 입력해주세요.',
            };
            tmpItems.push(item);
        }

        dispatch(setSeriesList([...seriesList, ...tmpItems]));
        dispatch(setLastId(tmpLastId));
    };

    const hanndleModal = async (index: number) => {
        if (authorInfo.author_name === '' || authorError !== '')
            return await alert('warning', '경고', '저자명을 먼저 입력해주세요.');
        setShowModal((prev) => !prev);
        setMatchingIdx(index);
    };

    const applySeries = async (data: SearchSeriesResult) => {
        const { selectedSeries, matchingIdx } = data;

        const newList = _.cloneDeep(seriesList);
        for (let i of newList) {
            if ((selectedSeries as SelectedSeries).series_id === i.series_id) {
                return await alert('warning', '경고', '이미 추가한 시리즈입니다.');
            }
        }
        newList[matchingIdx].series_id = (selectedSeries as SelectedSeries).series_id;
        newList[matchingIdx].series_name = (selectedSeries as SelectedSeries).series_name;
        newList[matchingIdx].seriesError = '';
        dispatch(setSeriesList(newList));
        setMatchingIdx(-1);
    };

    return (
        <TableContainer showTable={contractType === 'MG02'}>
            <TableWrapper>
                <DataTable
                    from='mg'
                    itemKey='id'
                    items={seriesList}
                    headers={headers}
                    columns={columns} 
                />
                <AddLine onApplyAddLine={applyAddLine} />
            </TableWrapper>
            <Pagination 
                from='mg' 
                pageCount={pageCount} 
                totalVisible={totalVisible} 
            />
            <ModalPortal>
                <SearchSeriesModal
                    from='mgSeries'
                    show={showModal}
                    close={hanndleModal}
                    matchingIdx={matchingIdx}
                    withRatio={false}
                    multiSelect={false}
                    authorId={authorInfo.author_id}
                    platformId={platformId}
                    applySeries={applySeries}
                />
            </ModalPortal>
        </TableContainer>
    );
}

const TableContainer = styled.div<{ showTable: boolean }>`
    display: ${({ showTable }) => (showTable ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
`;

const TableWrapper = styled.div`
    position: relative;
    width: 100%;
`;
