import styled from "styled-components";

import { RootState, useAppSelector } from "@/modules/store";
import Text from "@/components/atoms/text";

export default function BulkErrListModalBody() {
    const [ errList ] = useAppSelector((state:RootState) => [
        state.bulkenroll.errList
    ])

    return (
        <BodyWrap>
            <GuideWrap>
                <Text 
                    text="※ 엑셀 파일에서 다음 항목들을 수정 후 재업로드 해주세요."
                    customStyle={{ fontSize: "12px", fontWeight: "400" }}
                />
            </GuideWrap>
            <ErrWrap>
                <Header>
                    <Item width="48px" justifyContent="center">행</Item>
                    <Item>필드</Item>
                    <Item width="292px">오류 내용</Item>
                </Header>
                {errList.map((err, i) =>(
                    <List key={i}>
                        <Item width="48px" justifyContent="center" fontWeight="400">{err.row}</Item>
                        <Item fontWeight="400">{err.field}</Item>
                        <Item width="292px" fontWeight="400">{err.message}</Item>
                    </List>
                ))}
            </ErrWrap>
        </BodyWrap>
    )

}

const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px;
    // height: 320px;
    // max-height: 320px;
    height: 340px;
    max-height: 340px;
`
const GuideWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
`

const ErrWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-itmes: center;
    height: 288px;
    max-height: 288px;
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    min-height: 48px;
    height: 48px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
`

const List = styled.div`
    display: flex;
    min-height: 48px;
    height: 48px;
`

const Item = styled.div<{ width?: string, justifyContent?: string, fontWeight?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
    width: ${({ width }) => width ? width : "100px"};
    padding: 0 8px;
    font-size: 12px;
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : "500"};
`