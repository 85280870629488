import { RefObject, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

/**
 * return FadeIn 효과 적용된 Wrapper 컴포넌트
 */
const useFadeIn = (targetRef: RefObject<HTMLDivElement>) => {
    const [active, setActive] = useState(false);
    const observer = useRef<IntersectionObserver>();
    
    useEffect(() => {
        if (!observer.current) {
            const observerCallback = (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    // entry.target.classList.add('active');
                    setActive(true);
                  }
                })
              }
        
            observer.current = new window.IntersectionObserver(observerCallback, { threshold: 0 })
        }
        if (targetRef.current) observer.current.observe(targetRef.current);
      
        return () => { observer.current && observer.current.disconnect(); }
    }, [])

    return { FadeInWrapper, active };
}

const fadeIn = keyframes`
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
`

const FadeInWrapper = styled.div`
    opacity: 0;
    animation-name: ${({ active }:{ active: boolean}) => active && fadeIn};
    animation-duration: 1.2s;
    animation-delay: 0;
    animation-timing-function: ease-in;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
`

export default useFadeIn;