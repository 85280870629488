import { Fragment, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import io from "socket.io-client";
import styled, { ThemeProvider } from "styled-components";
import theme from "@/assets/styles/theme";
import GlobalStyle from "@/assets/styles/GlobalStyle";
import "@/assets/styles/App.css";
import { RootState, useAppDispatch, useAppSelector } from "@/modules/store";
import { logout } from "@/pages/common/userSlice";
import { socketActions } from "@/pages/common/socketSlice";
import { loginCheck } from "@/pages/auth/authSlice";
import Meta from "@/components/atoms/meta/Meta";
import NavBar from "@/components/molecules/common/navBar/NavBar";
import Footer from "@/components/molecules/common/footer/Footer";
import FullLoading from "@/components/molecules/common/loading/FullLoading";
import Alert from "@/components/molecules/common/alert/Alert";
import Toast from "@/components/molecules/common/toast/Toast";
import MobLanding from "@/pages/mob-landing/MobLanding";
import MobFooter from "@/components/templates/mob-landing/MobFooter";
import Element from "@/Element";
import useAlert from "@/modules/hooks/useAlert";

const serverUrl = process.env.NODE_ENV === "production" ? "https://api.gling.co.kr" : "http://localhost:4000";
const socket = io(serverUrl, {
    transports: ["websocket"],
});
export default function App() {
    const { socketId, messageId } = useAppSelector((state: RootState) => state.socket);
    const { isLogin } = useAppSelector((state: RootState) => state.user);
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const { alert } = useAlert();
    
    const path = window.location.href.split(".kr/").pop();

    const checkToken = async () => {
        try {
            await dispatch(loginCheck(socketId)).unwrap();
        } catch (err) {
            if (err === "logout") {
                dispatch(logout());
            }
        }
    }

    // useEffect(() => {
    //     socket.on("socketId", (socketId: string) => {
    //         dispatch(socketActions.setSocketId(socketId));
    //         checkToken();
    //     });
    // }, [socketId, dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(socketActions.removeMessage(messageId));
            clearTimeout(timer);
        }, 5000);
    }, [messageId, dispatch]);

    useEffect(() => {
        socket.on("toastMessage", (message) => {
            dispatch(socketActions.setMessage(message));
            dispatch(socketActions.setMessagetId(message.id));
        });
        socket.on("forceLogout", (ip) => {
            dispatch(logout());
            return alert("info", "로그아웃됨", `다른 기기에서 로그인 하였습니다. 로그인 IP : ${ip}`);
        });
        socket.on("socketId", (socketId: string) => {
            dispatch(socketActions.setSocketId(socketId));
            checkToken();
        });
    }, []);

    if (pathname === "/uploader") {
        return (
            <DefaultLayout>
                <Element />
                <Alert />
            </DefaultLayout>
        );
    }

    if (isMobile && !isTablet) {
        return (
            <DefaultLayout>
                <MobLanding />
                <MobFooter />
            </DefaultLayout>
        )
    }

    return (
        <DefaultLayout>
            <NavBar pathname={pathname} />
            <MainContent>
                <Element />
            </MainContent>
            { pathname === "/app/fileUpload" || (pathname === "/" && !isLogin) 
                ? null : <Footer pathname={pathname} /> }
            <Alert />
            <Toast />
            <FullLoading />
        </DefaultLayout>
    );
}

function DefaultLayout({ children }: DefaultLayoutProps) {
    return (
        <>
            <Meta title="Home" />
            <Fragment>
                <GlobalStyle />
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </Fragment>
        </>
    );
}

const MainContent = styled.div`
    min-height: calc(100vh - 100px);
`;

interface DefaultLayoutProps {
    children: ReactNode;
}
