import { useSelector } from "react-redux";
import { RootState } from "@/modules/store";
import Home from "@/components/templates/home/Home";
import Landing from "@/components/templates/home/Landing";
import styled from 'styled-components'

function Main() {
    const { isLogin } = useSelector((state: RootState) => state.user);
    return <HomeSection>{isLogin ? <Home /> : <Landing />}</HomeSection>;
}

export default Main;

const HomeSection = styled.div`
    height: auto;
    margin: 0 auto;
    min-width: 1200px;
`;