import BaseModal from "@/components/molecules/common/modal/BaseModal";
import BulkErrListModalBody from "@/components/molecules/common/modal/bodyModal/BulkErrListModalBody";
import { BaseModalProps } from "@/pages/common/types";

export default function BulkErrListModal({ show, from, close }:BulkErrListProps) {
    return (
        <>
            <BaseModal
                show={show}
                close={close}
                titles={["엑셀파일 오류 목록"]}
                customStyle={[{ width: "480px", height: "380px" }]}
                footer={false}
            >
                <BulkErrListModalBody />
            </BaseModal>
        </>
    );
}

interface BulkErrListProps extends BaseModalProps {
}