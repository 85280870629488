import BaseModal from "@/components/molecules/common/modal/BaseModal";
import useAlert from "@/modules/hooks/useAlert";
import { BaseModalProps } from "@/pages/common/types";
import PortfolioCirculationExcel from "@/components/molecules/common/modal/bodyModal/PortfolioCirculationExcel";

export default function ExcelUploadPortfolioCirculationModal({ show, close, from, circulationExcelUpload }:ExcelUploadCirculationProps) {
    const { alert } = useAlert();

    const excelUpload = async () => {
        try {
            await circulationExcelUpload();
            close();
        } catch (error: any) {
            await alert("error", "오류 발생", error.message);
        }
    }

    return (
        <BaseModal
            show={show}
            close={close}
            titles={["엑셀 업로드"]}
            customStyle={[{ width: "480px", height: "272px" }]}
            applyFunc={excelUpload}
        >
            <PortfolioCirculationExcel /> 
        </BaseModal>
    );
}

interface ExcelUploadCirculationProps extends BaseModalProps {
    circulationExcelUpload: Function;
}