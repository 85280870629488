import styled from "styled-components"
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { colors } from "@/assets/styles/theme";

export default function ReadOnlyInput({ type, value, placeholder, width, onClickHandler }: Props) {
    switch (type) {
        case "search":
            return (
                <StyledWrapper onClick={onClickHandler} width={width}>
                    <StyledInput 
                        value={value}
                        placeholder={placeholder}
                        readOnly
                    />
                    <IconWrapper>
                        <SearchIcon 
                            size={24} 
                            fill={colors.gray500} 
                        />
                    </IconWrapper>
                </StyledWrapper>
            )
        case "date":
            return (
                <StyledWrapper></StyledWrapper>
            )
        default:
            return (
                <StyledWrapper>
                    <StyledInput 
                        value={value}
                        placeholder={placeholder}
                        readOnly
                    />
                </StyledWrapper>
            )
    }
}

const StyledWrapper = styled.div<{ width?: string; }>`
    display: flex;
    aling-items: center;
    background-color: ${({ theme }) => theme.colors.gray50};
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    height: 40px;
    width: ${({ width }) => width ? width : "200px"};
    border-radius: 4px;
`

const StyledInput = styled.input`
    border: unset;
    font-size: 12px;
    font-weight: 400;
    padding: 9.5px 8px;
    background-color: ${({ theme }) => theme.colors.gray50};
    width: 100%;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 40px;
    height: 100%;
    padding: 8px;
    
    svg {
        cursor: pointer;
    }
`

interface Props {
    type?: "search" | "date";
    value: string;
    placeholder: string;
    width?: string;
    onClickHandler?: () => void;
}
