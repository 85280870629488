import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { isIOS } from "react-device-detect";
import { ReactComponent as Logo } from "@/assets/icons/mob-landing/gling-logo.svg";

export default function TopBanner() {
    const [delta, setDelta] = useState(0); 
    const indexRef = useRef(0);
    const isDeleting = useRef(false); 
    const titleText = useRef("");
    const titleContents = ["내 손안의 출판사", "내 정산의 매니저", "내 작품의 첫 시작"];
    const storeURL = isIOS ? 'https://apps.apple.com/kr/app/%EA%B8%80%EB%A7%81/id6443822020' : 'https://play.google.com/store/apps/details?id=com.glingmobile&hl=ko';

    useEffect(() => {
        const intervalID = setInterval(() => {
            addTypingEffect();
        }, delta);

        return () => { clearInterval(intervalID) }
    }, [delta]);

    const addTypingEffect = () => {
        const currentTitle = titleContents[indexRef.current % 3];
        // 타이틀 텍스트 추가
        if (!isDeleting.current) {
            if (titleText.current !== currentTitle) {
                titleText.current = currentTitle.substring(0, titleText.current.length + 1);
                setDelta(150 - Math.random() * 100);
                return;
            }

            setDelta(1500);
            isDeleting.current = true;
        } else {
        // 타이틀 텍스트 삭제 
            if (titleText.current !== '') {
                titleText.current = currentTitle.substring(0, titleText.current.length - 1);
                setDelta(150 - Math.random() * 100);
                return;
            }

            indexRef.current += 1;
            isDeleting.current = false;
        }
    }

    const goDownload = () => window.location.href = storeURL;

    return (
        <Container>
            <VideoBg
                id="top-banner-video"
                autoPlay
                muted
                loop
                playsInline
            >
                <source src="/landing/top-banner-bg.mp4" type="video/mp4" />
            </VideoBg>
            <Contents>
                <Title data-title={titleText.current}>{titleText.current}</Title>
                <Bar />
                <Logo height="48" style={{ zIndex:1 }}/>
                <DownloadButton onClick={goDownload}>
                    <ButtonText>앱 다운로드</ButtonText>
                </DownloadButton>
            </Contents>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
`
const VideoBg = styled.video`
    position: absolute;
    width: 100vw;
    height: 100%;
`
const Contents = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 60px 10px;
    ::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(#000000, #00000060, #00000060, #00000000, transparent);
    }
`
const Title = styled.span.attrs((props:{'data-title': string}) => ({'data-title': props["data-title"]}))`
    position: sticky;
    font-size: 36px;
    font-weight: 700;
    color: transparent;
    min-height: 36px;

    ::after {
        content: attr(data-title);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        color: ${({ theme }) => theme.colors.white };
        white-space: nowrap;
        border-right: 1px solid white;
    }
`
const Bar = styled.div`
    width: 260px;
    height: 4px;
    margin: 8px 0px 16px;
    background-color: ${({ theme }) => theme.colors.blue500};
    z-index: 1;
`
const DownloadButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 44px;
    margin-top: 40px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.blue500};
    z-index: 1;
`
const ButtonText = styled.p`
    color: ${({ theme }) => theme.colors.white};
`