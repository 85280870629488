import Button from "@/components/atoms/button";
import { ReactComponent as RefreshIcon } from "@/assets/icons/refresh.svg";
import styled from "styled-components";
import { colors } from "@/assets/styles/theme";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import { RootState, useAppDispatch } from "@/modules/store";
import useAlert from "@/modules/hooks/useAlert";
import useLoading from "@/modules/hooks/useLoading";
import { removeComma } from "@/modules/utils/filter";
import usePagination from "@/components/molecules/common/pagination/usePagination";
import { setEnrollData, resetStore, enrollMgData, setReset } from "@/pages/settlement/sales-entry/mg/mgSlice";
import { getToday } from "@/modules/utils/date";

export default function MgButtons() {
    const {
        seriesList,
        contractStartDate,
        mgType,
        platformId,
        contractType,
        authorInfo,
        contractPrice,
        mgRatio,
        preDeduction,
        remark,
        authorError,
        contractPriceError,
        mgRatioError,
    } = useSelectorTyped((state: RootState) => state.entryMg);
    const platformList = useSelectorTyped((state: RootState) => state.platform.platformList);

    const { alert } = useAlert();
    const { loadingOn, loadingOff } = useLoading();
    const { setDataCount } = usePagination({ from: "mg" });
    const dispatch = useAppDispatch();

    const deleteSeries = () => {};
    const clickResetButton = () => resetData();

    const clickSave = async () => {
        const answer = await validateData();
        if (answer === "Y") saveData();
    };

    const validateData = async () => {
        if (
            !authorInfo.author_name ||
            !contractPrice ||
            !mgRatio ||
            authorError ||
            contractPriceError ||
            mgRatioError
        ) {
            return await alert("warning", "주의", "계약 조건을 확인해주세요.");
        } else if (contractType === "MG02") {
            for (let item of seriesList) {
                if (item.seriesError !== "") {
                    return await alert("warning", "주의", "시리즈명을 확인해주세요.");
                }
            }
        }
        if (authorInfo.co_info.length > 1) {
            if (!authorInfo.co_info[0].coRatio) {
                return await alert("warning", "주의", "공저간 비율을 설정해주세요.");
            }
        }
        return await alert(
            "confirm",
            "등록확인",
            "선인세를 등록하시겠습니까?\n선인세를 차감할 판매데이터가 입력되어있는 경우, 시간이 소요될 수 있습니다.",
        );
    };

    const saveData = async () => {
        try {
            loadingOn();
            let sendData = {
                date: contractStartDate,
                contract_type: contractType,
                mg_type: mgType,
                platform_id: mgType === "platform" ? platformId : 99,
                author_id: authorInfo.author_id,
                amount: Number(removeComma(contractPrice)),
                mg_ratio: Number(mgRatio),
                remark: remark,
            };

            let apiParams = {
                param: sendData,
                co_info: authorInfo.co_info,
                seriesLists: seriesList,
                preDeduction: Number(removeComma(preDeduction)),
            };
            await dispatch(enrollMgData(apiParams)).unwrap();
            resetData();
            return await alert("info", "등록 완료", "선인세 등록이 완료되었습니다.");
        } catch (err: any) {
            return await alert("error", "오류 발생", err.message);
        } finally {
            loadingOff();
        }
    };

    const resetData = () => {
        setDataCount(1);
        // setSelectedDate(getToday());
        // setSelectedPlatform(initialPlatformId);
        dispatch(setEnrollData({ contractStartDate: getToday(), platformId: platformList[0].platform_id }));
        dispatch(resetStore());
        dispatch(setReset(true));
    };

    return (
        <MgButtonsWrapper>
            {contractType === "MG02" && (
                <Button
                    type="red-outline"
                    label="선택삭제"
                    onClickHandler={deleteSeries}
                    customStyle={{ margin: "0 8px 0 0" }}
                />
            )}
            <CustomButton onClick={clickResetButton}>
                <Text>초기화</Text>
                <RefreshIcon fill={colors.red400} />
            </CustomButton>
            <Button type="main" label="저장" onClickHandler={clickSave} customStyle={{ margin: "0 8px 0 0" }} />
        </MgButtonsWrapper>
    );
}

const MgButtonsWrapper = styled.div`
    display: flex;
    margin-top: 30px;
`;
const CustomButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 32px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.red400};
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.red400};
    margin-right: 8px;
    cursor: pointer;
`;

const Text = styled.div`
    margin-left: 8px;
`;
