import styled from "styled-components";
import { useState } from "react";
import { RootState } from "@/modules/store";
import useSelectorTyped from "@/modules/hooks/useSelectorTyped";
import BeforeFind from "@/components/molecules/findpw/BeforeFind";
import ChangePw from "@/components/molecules/findpw/ChangePw";
import Done from "@/components/molecules/findpw/Done";

export default function FindPw() {
    const [email, setEmail] = useState("");
    const done = useSelectorTyped((state: RootState) => state.findpw.done);

    function setEmailFunc(email:string) {
        setEmail(email);
    }

    return (
        <FindIdContainer>
            <h2 className="title">비밀번호 찾기</h2>
            {
                email ?
                    done ?
                    <Done />
                    :
                    <ChangePw />
                :
                <BeforeFind
                    setFind={setEmailFunc}
                />
            }
        </FindIdContainer>
    );
}

const FindIdContainer = styled.div`
    width: 400px;
    height: auto;
    margin: 0 auto;
    padding-top: 80px;
    .title {
        font-weight: 700;
        font-size: 28px;
        text-align: center;
        margin-bottom: 120px;
    }
`;