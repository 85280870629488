import { ReactNode } from "react"
import styled from "styled-components"

export default function HomeSectionLayout({children, backColor, padding}: {children: ReactNode, backColor?: string, padding?: number}) {
    return (
        <StyledContainer backColor={backColor} padding={padding}>
            { children }
        </StyledContainer>
    )
}

const StyledContainer = styled.div<{backColor?: string, padding?: number}>`
    padding: 30px ${({ padding }) => padding ? `calc((100% - ${1200+padding}px)/2)` : 'calc((100% - 1200px)/2)'};
    background-color: ${({ backColor }) => backColor};
`