import { amount, integer, ratio, text, email } from "@/modules/utils/validate";


const validate = (item: any, type: Validtaion, fieldName?:string, validateType?:ValidateType) => {

    // 아래와 같이 수정예정
    if (fieldName&&validateType) {
        switch (validateType) {
            case "amount":
                return integer({ field: fieldName, item, error:"errorMsg", min: -9999999, max: 9999999, mandatory: true });
            case "integer":
                return integer({ field: fieldName, item, error:"errorMsg", min: -9999999, max: 9999999, mandatory: true });
            case "ratio":
                return integer({ field: fieldName, item, error:"errorMsg", min: -9999999, max: 9999999, mandatory: true });
            case "text":
                return text({ field: fieldName, item, error:"errorMsg", max: 30, mandatory: true });
            default:
                return { msg: "", value: "" };
        }
    }

    switch (type) {
        case "quantityError":
            return integer({ field: "판매량", item, error:"quantityError", min: -9999999, max: 9999999, mandatory: true });
        case "amountError":
            return amount({ field: "판매금액", item, error:"amountError", decimal: true, mandatory: true });
        case "ratioError":
            return ratio({ field: "정산비율", item, error:"ratioError", decimal: false, mandatory: true });
        case "taxRatioError":
            return ratio({ field: "적용세율", item, error:"taxRatioError", decimal: true, mandatory: true });
        case "realNameError":
            return text({ field: "본명", item, error:"realNameError", max: 20, mandatory: true });
        case "stEmailError":
            return email({ field: "이메일", item, error:"stEmailError", mandatory: false });
        default:
            return { msg: "", value: "" };
    }

};
type ValidateType = "integer" | "amount" | "ratio" | "text";

type Validtaion = "quantityError" | "amountError" | "ratioError" | "taxRatioError" | "realNameError" | "stEmailError" | "textError";

export default validate;
